import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import Consignments from "./Consignments";
import Tracking from "./Tracking";
const AclView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
      };
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
        <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/consolidations")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/consolidations")}
              >
                Consolidations
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/consolidations")}
              >
                Acls
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
              >
                {params?.id}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section  mb_12">
        <button
          className={`filterBtn ${tab == 1 &&  `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined) {
              tabHandler(1);
            }
          }}
        >
          Consignments
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined) {
              tabHandler(2);
            }
          }}
        >
          Tracking
        </button>
      </div>
      <div>
         { (tab == 1 ? (
            <Consignments edit={setEditPage} setTab={setTab}/>
          ) :
            <Tracking edit={setEditPage} />)}
      </div>
    </div>
  )
}

export default AclView