import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import Product from "./Product";
import Origin from "./Origin";
import Destination from "./Destination";
import Transittime from "./Transittime";
const ProdcutAdd = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [tab, setTab] = useState(1);
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const globalproductCode = useAoneStore((state) => state?.globalproductCode);
  const setOriginData = useAoneStore((state) => state?.setOriginData);
  const setDestinationData = useAoneStore((state) => state?.setDestinationData);
  const setglobalproductCode = useAoneStore(
    (state) => state?.setglobalproductCode
  );
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  setglobalproductCode("");
                  navigate("/masters/products");
                  setOriginData([])
                  setDestinationData([])
                }}
              >
                Master
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  setglobalproductCode("");
                  navigate("/masters/products");
                  setOriginData([])
                  setDestinationData([])
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                }}
              >
                Products
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
                {params?.id ? params?.id : globalproductCode ? globalproductCode : "New"}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section  mb_12">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => tabHandler(1)}
        >
          Product
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalproductCode !== '') {
              tabHandler(2);
            }
          }}
        >
          Origins
        </button>
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalproductCode !== '') {
              tabHandler(3);
            }
          }}
        >
          Destinations
        </button>
        {/* <button
          className={`filterBtn ${tab == 4 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalproductCode !== '') {
              tabHandler(4);
            }
          }}
        >
          Transit Time
        </button> */}
      </div>
      <div>
        {tab == 1 ? (
          <Product edit={setEditPage} setTab={setTab} />
        ) : tab == 2 ? (
          <Origin edit={setEditPage} setTab={setTab} />
        ) : tab == 3 ? (
          <Destination edit={setEditPage} setTab={setTab} />
        ) : (
          <Transittime edit={setEditPage} />
        )}
      </div>
    </div>
  );
};

export default ProdcutAdd;
