import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../store/AoneStore";
import { useOrderStore } from "../../store/OrderMgt/OrderStore";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { roleHandler } from "../../helper/roleHandler";
import { UserContext } from "../../helper/ProtectedRoute";
import StepperComponent from "../../components/stepper/stepper";
import externalLink from "../../../src/assets/img/externalLink.svg";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import editIcon from "../../../src/assets/img/EditingIcon.svg";
import cancelIcon from "../../../src/assets/img/CancelDesIcon.svg";
import saveIcon from "../../../src/assets/img/saveIcon.svg";
const OrdersDetail = ({
  isIncoUpdated,
  setIsIncoUpdated,
  orderDetails,
  setOrderDetails,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const getConsignmentOrdersbyId = useAoneStore(
    (state) => state?.getConsignmentOrdersbyId
  );
  const getCountryByCityOrdersDes = useAoneStore(
    (state) => state?.getCountryByCityOrdersDes
  );
  const citybyCodeDes = useAoneStore((state) => state?.citybyCodeDes);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const districtbyDescode = useAoneStore((state) => state?.districtbyDescode);
  const getDistrictDesbyCode = useAoneStore(
    (state) => state?.getDistrictDesbyCode
  );
  const editOrderIncoterms = useAoneStore((state) => state?.editOrderIncoterms);
  const updateSingleCod = useOrderStore((state) => state?.updateSingleCod);
  const editOrderDdu = useAoneStore((state) => state?.editOrderDdu);
  const getCurrency = useAoneStore((state) => state?.getCurrency);
  const currencyData = useAoneStore((state) => state?.currencyData);
  const editOrderDesitiantion = useAoneStore(
    (state) => state?.editOrderDesitiantion
  );
  const consignemntStatus = useAoneStore((state) => state?.consignemntStatus);
  const getCityDistrict = async () => {
    try {
      const response = await getCountryByCityOrdersDes(
        orderDetails?.deliveryAddress?.countryCode
      );

      if (response?.data?.data?.length > 0) {
        let regionCode = response.data.data[0].regionCode;

        const districtData = await getDistrictDesbyCode(
          orderDetails?.deliveryAddress?.countryCode,
          regionCode,
          orderDetails?.deliveryAddress?.cityCode
        );
      } else {
        console.error("No region code found for the specified country code");
      }
    } catch (error) {
      console.error("Error fetching city district data:", error);
    }
  };

  const getOrderDetail = async () => {
    const data = await getConsignmentOrdersbyId(params?.id);
    setOrderDetails(data?.data?.data);
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      currencyData?.data?.data?.content?.length < 1 ||
      currencyData?.data?.data?.content === undefined
    ) {
      getCurrency(paramslink);
    }
  }, []);
  const [expanded, setExpanded] = useState(false);
  const handleExpandAll = () => {
    const newExpandedState = !expanded;
    if (newExpandedState) {
      setExpanded(["panel2", "panel3", "panel4", "panel5", "panel6", "panel7"]);
    } else {
      setExpanded(false);
    }
  };
  const getOrderIncoterms = useAoneStore((state) => state?.getOrderIncoterms);
  const orderIncoterms = useAoneStore((state) => state?.orderIncoterms);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (
      orderIncoterms?.data?.data?.length < 1 ||
      orderIncoterms?.data?.data?.length == undefined
    ) {
      getOrderIncoterms();
    }
  }, []);
  const steps = [
    "Pickup Completed",
    "Received at Origin Hub",
    "In Transit to Destination Hub",
    "Reached destination Hub",
    orderDetails?.rto == false ? "Delivered" : "RTO Delivered",
  ];
  if (
    orderDetails?.pickupAddress?.countryCode !==
    orderDetails?.deliveryAddress?.countryCode
  ) {
    steps?.splice(1, 0, "Departed Origin Country", "Customs Cleared");
  }
  // Initialize activeStep to 0
  let activeStep = 0;
  // Define the logic to determine the active step based on event codes
  if (consignemntStatus && consignemntStatus.length > 0) {
    const firstEventCode = orderDetails?.statusCode; // Get the first eventCode
    if (
      orderDetails?.pickupAddress?.countryCode ==
      orderDetails?.deliveryAddress?.countryCode
    ) {
      if (firstEventCode >= 201 && firstEventCode < 300) {
        activeStep = 1;
      } else if (firstEventCode === 300) {
        activeStep = 2;
      } else if (firstEventCode === 301) {
        activeStep = 3;
      } else if (firstEventCode >= 302 && firstEventCode < 604) {
        activeStep = 4;
      } else if (firstEventCode === 604) {
        activeStep = 5;
      }
    } else {
      if (firstEventCode >= 201 && firstEventCode < 300) {
        activeStep = 1;
      } else if (firstEventCode === 300) {
        activeStep = 3;
      } else if (firstEventCode === 301) {
        activeStep = 4;
      } else if (firstEventCode >= 302 && firstEventCode < 604) {
        activeStep = 5;
      } else if (firstEventCode === 604) {
        activeStep = 6;
      }
    }
  }
  const handleClick = (latitude, longitude) => {
    if (latitude && longitude) {
      // Construct the Google Maps URL using the latitude and longitude
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      // Open a new tab with the constructed URL
      window.open(googleMapsUrl, "_blank");
    } else {
      console.warn("Latitude and/or longitude are missing.");
    }
  };
  const [editClicked, setEditClicked] = useState(false);
  const [editCodClicked, setEditCodClicked] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editDesClicked, setEditDesClicked] = useState(false);
  const [editDesMode, setEditDesMode] = useState(false);
  const [selectedIncoterms, setSelectedIncoterms] = useState("");
  const [phoneNumberDesRegex, setphoneNumberDesRegex] = useState("");
  useEffect(() => {
    setSelectedIncoterms(orderDetails?.incoterms);
    countryRegexPhone();
    updateDesDetails.setFieldValue(
      "phone",
      orderDetails?.deliveryAddress?.phone
    );
    updateDesDetails.setFieldValue(
      "alternatePhone",
      orderDetails?.deliveryAddress?.alternatePhone
    );
    updateDesDetails.setFieldValue(
      "city",
      orderDetails?.deliveryAddress?.cityCode
    );
    updateDesDetails.setFieldValue(
      "district",
      orderDetails?.deliveryAddress?.districtCode
    );
    updateDesDetails.setFieldValue(
      "addressLine1",
      orderDetails?.deliveryAddress?.addressLine1
    );
    updateDesDetails.setFieldValue(
      "addressLine2",
      orderDetails?.deliveryAddress?.addressLine2
    );
  }, [orderDetails]);
  const countryRegexPhone = async () => {
    let countryParam = `?page=${0}&size=20`;
    if (orderDetails?.deliveryAddress?.countryCode) {
      countryParam += `&isoCode3=${orderDetails?.deliveryAddress?.countryCode}`;
      let response = await getCountries(countryParam);
      updateDesDetails.setFieldValue(
        "phonecodeprefix",
        response?.data?.data?.content[0].phoneCodePrefix
          ? response?.data?.data?.content[0]?.phoneCodePrefix
          : ""
      );
      setphoneNumberDesRegex(
        response?.data?.data?.content[0]?.phoneNumberRegex
      );
    }
  };
  const [dduAmount, setDDuAmount] = useState(orderDetails?.dduAmount);
  const [codAmount, setCodAmount] = useState(orderDetails?.codAmount);
  const handleInputChange = (event) => {
    setDDuAmount(event.target.value);
  };
  const handleInputCodChange = (event) => {
    setCodAmount(event.target.value);
  };
  const handleEditClick = (event) => {
    event.stopPropagation();
    setEditMode(true);
    setEditClicked(true);
  };
  const handleEditCodClick = (event) => {
    setExpanded("panel6");
    event.stopPropagation();
    setEditCodClicked(true);
    setEditCodClicked(true);
  };
  const handleCancelCodClick = (event) => {
    setExpanded("panel6");
    event.stopPropagation();
    setEditCodClicked(false);
    setEditCodClicked(false);
  };
  const handleEditDesClick = (event) => {
    getCityDistrict();
    setExpanded("panel2");
    event.stopPropagation();
    setEditDesMode(true);
    setEditDesClicked(true);
  };
  const handleCancelDesClick = (event) => {
    setExpanded("panel2");
    event.stopPropagation();
    setEditDesMode(false);
    setEditDesClicked(false);
  };
  const [isCityChanged, setIsCityChanged] = useState(false);
  const updateDesDetails = useFormik({
    initialValues: {
      phone: "",
      alternatePhone: "",
      city: "",
      district: "",
      addressLine1: "",
      addressLine2: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Phone is required"),
      city: Yup.string().required("City is required"),
      addressLine1: Yup.string().required("Address Line 1 is required"),
    }),
    onSubmit: async (values) => {
      const originalValues = {
        pickupPhone: updateDesDetails.values.phone,
        pickupAlternatePhone: updateDesDetails.values.alternatePhone,
      };
      const valuestoSend = { ...values };
      valuestoSend.phone =
        updateDesDetails.values.phonecodeprefix + updateDesDetails.values.phone;
      if (updateDesDetails.values.alternatePhone !== "") {
        valuestoSend.alternatePhone =
          updateDesDetails.values.phonecodeprefix +
          updateDesDetails.values.alternatePhone;
      } else {
        valuestoSend.alternatePhone = updateDesDetails.values.alternatePhone;
      }
      await editOrderDesitiantion(params?.id, valuestoSend).then((response) => {
        if (response.status == "201") {
          toast.success("Destination details updated");
          setEditDesMode(false);
          setEditDesClicked(false);
          setIsIncoUpdated(!isIncoUpdated);
        } else if (response?.data?.data?.responseCode == 100) {
          toast.success("Destination details updated");
          setEditDesMode(false);
          setEditDesClicked(false);
          setIsIncoUpdated(!isIncoUpdated);
          updateDesDetails.values.phone = originalValues.pickupPhone;
          updateDesDetails.values.alternatePhone =
            originalValues.pickupAlternatePhone;
        } else if (
          response?.data?.data?.responseCode !== "" &&
          response?.data?.data?.responseCode !== 100
        ) {
          const errorMessage = response?.data?.data?.responseMessage;
          toast.error(errorMessage);
          setEditDesMode(false);
          setEditDesClicked(false);
          setIsIncoUpdated(!isIncoUpdated);
          updateDesDetails.values.phone = originalValues.pickupPhone;
          updateDesDetails.values.alternatePhone =
            originalValues.pickupAlternatePhone;
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response.data.msg.split(",")[0];
          toast.error(errorMessage);
          setEditDesMode(false);
          setEditDesClicked(false);
          setIsIncoUpdated(!isIncoUpdated);
          updateDesDetails.values.phone = originalValues.pickupPhone;
          updateDesDetails.values.alternatePhone =
            originalValues.pickupAlternatePhone;
        } else {
          const errorMessage = response.response.data.msg.split(",")[0];
          toast.error(errorMessage);
          setEditDesMode(false);
          setEditDesClicked(false);
          setIsIncoUpdated(!isIncoUpdated);
          updateDesDetails.values.phone = originalValues.pickupPhone;
          updateDesDetails.values.alternatePhone =
            originalValues.pickupAlternatePhone;
        }
      });
    },
  });
  useEffect(() => {
    const { phonecodeprefix, phone } = updateDesDetails.values;
    if (phonecodeprefix && phone.startsWith(phonecodeprefix)) {
      const updatedPhone = phone.replace(phonecodeprefix, "");
      updateDesDetails.setFieldValue("phone", updatedPhone);
    }
  }, [updateDesDetails.values.phonecodeprefix, updateDesDetails.values.phone]);
  useEffect(() => {
    if (isCityChanged === true) {
      updateDesDetails.setFieldValue("district", "");
    }
  }, [updateDesDetails?.values?.city, isCityChanged]);
  const handleSaveDesClick = (event) => {
    event.stopPropagation();
    const phoneNumber = updateDesDetails?.values?.phone;
    const alternatePhoneNumber = updateDesDetails?.values?.alternatePhone;
    // Convert phoneNumberDesRegex string to a regular expression object
    const regexPattern = phoneNumberDesRegex; // assuming phoneNumberDesRegex already contains the regex pattern
    const phoneNumberRegex = new RegExp(regexPattern);
    // Validate the primary phone number only if it's provided
    const isPhoneNumberValid = phoneNumber
      ? phoneNumberRegex.test(
          updateDesDetails.values.phonecodeprefix + phoneNumber
        )
      : true;
    // Validate the alternate phone number only if it's provided
    const isAlternatePhoneNumberValid = alternatePhoneNumber
      ? phoneNumberRegex.test(
          updateDesDetails.values.phonecodeprefix + alternatePhoneNumber
        )
      : true;
    // Check both phone numbers; proceed if both are valid or not provided
    if (isPhoneNumberValid && isAlternatePhoneNumberValid) {
      // If both phone numbers are valid or not provided, submit the form
      updateDesDetails?.handleSubmit();
    } else {
      // Show error messages based on which input is invalid
      if (!isPhoneNumberValid && phoneNumber) {
        // Only show error if there is a primary phone number and it's invalid
        toast.error("Invalid phone number format.");
      }
      if (!isAlternatePhoneNumberValid && alternatePhoneNumber) {
        // Only show error if there is an alternate phone number and it's invalid
        toast.error("Invalid alternate phone number format.");
      }
    }
  };
  const [selectedCurrency, setSelectedCurrency] = useState("");
  useEffect(() => {
    setCodAmount(orderDetails?.codAmount);
    getDesCurrency();
  }, [orderDetails]);
  const getDesCurrency = async () => {
    let countryParam = `?page=${0}&size=20&isoCode3=${
      orderDetails?.deliveryAddress.countryCode
    }`;
    let response = await getCountries(
      orderDetails?.deliveryAddress?.countryCode
    );
    setSelectedCurrency(response?.data?.data?.currency?.code);
  };
  const updateCod = async () => {
    try {
      let payload = {
        amount: codAmount,
        currency: selectedCurrency,
      };
      const res = await updateSingleCod(params?.id, payload);
      if (res?.data?.status === "SUCCESS") {
        toast.success("Cod value updated");
        setEditCodClicked(false);
        setEditCodClicked(false);
        getConsignmentOrdersbyId(params?.id);
      } else if (res?.data?.status == "ERROR") {
        const errorMessage = res?.data?.msg;
        toast.error(`${errorMessage}`);
      } else if (res?.response?.data?.errors?.length > 0) {
        const errorMessages = res?.response?.data?.errors
          ?.map((item) => `${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
      } else {
        const errorMessage = res?.data?.msg.split(",")[0];
        toast.error(errorMessage);
      }
    } catch (error) {}
  };
  const handleSaveCodClick = (event) => {
    event.stopPropagation();
    updateCod();
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      orderIncoterms?.data?.data?.length < 1 ||
      orderIncoterms?.data?.data?.length == undefined
    ) {
      getOrderIncoterms();
    }
  }, []);
  const handleTickClick = async () => {
    try {
      let payload = {
        incoterms: selectedIncoterms,
      };
      if (payload?.incoterms) {
        const res = await editOrderIncoterms(params?.id, payload);
        if (res?.data?.errorCode == "500") {
          const errorMessage = res?.data?.msg;
          setEditMode(false);
          setEditClicked(false);
          setIsIncoUpdated(!isIncoUpdated);
          toast.error(errorMessage);
        } else if (res?.data?.status == "ERROR") {
          const errorMessage = res?.data?.data?.responseMessage;
          setEditMode(false);
          setEditClicked(false);
          toast.error(errorMessage);
        } else if (res?.response?.data?.status == "ERROR") {
          const errorMessage = res?.response?.data?.msg;
          toast.error(errorMessage);
          setEditMode(false);
          setEditClicked(false);
        } else {
          toast.success("Incoterms Updated");
          setIsIncoUpdated(!isIncoUpdated);
          setEditMode(false);
          setEditClicked(false);
        }
      } else {
        toast.error("Select the Incoterms");
      }
    } catch (err) {}
  };
  const handleCancelClick = (event) => {
    event.stopPropagation();
    setEditMode(false);
    setEditClicked(false);
  };
  const handleTickDDuClick = async () => {
    try {
      let payload = {
        dduAmount: dduAmount,
      };
      const res = await editOrderDdu(params?.id, payload);
      if (res?.data?.errorCode == "500") {
        const errorMessage = res?.data?.msg;
        setEditMode(false);
        setEditClicked(false);
        setIsIncoUpdated(!isIncoUpdated);
        toast.error(errorMessage);
      } else if (res?.data?.status == "ERROR") {
        const errorMessage = res?.data?.data?.responseMessage;
        setEditMode(false);
        setEditClicked(false);
        toast.error(errorMessage);
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
        setEditMode(false);
        setEditClicked(false);
      } else {
        toast.success("Incoterms Updated");
        setIsIncoUpdated(!isIncoUpdated);
        setEditMode(false);
        setEditClicked(false);
      }
    } catch (err) {}
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div style={{ position: "relative" }}>
      {orderDetails?.status !== "Consignment Canceled" &&
        orderDetails?.status !== "CANCELLED" && (
          <Button
            onClick={handleExpandAll}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "8px 16px",
              backgroundColor: "#ec171c",
              color: "#ffffff",
              borderRadius: "4px",
              cursor: "pointer",
              border: "none",
              outline: "none",
            }}
          >
            {expanded ? "Collapse" : "Expand"}
          </Button>
        )}
      <div className="displayFlex tabletab_section"></div>
      {orderDetails?.status !== "Consignment Canceled" &&
        orderDetails?.status !== "CANCELLED" && (
          <div className="mt_20">
            <StepperComponent
              activeStep={activeStep}
              steps={steps}
              className="mt_20"
            />
          </div>
        )}
      <Accordion defaultExpanded className="mt_20">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Consignment Summary
          </Typography>
          {!editClicked &&
            (roleHandler(
              role,
              "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount"
            ) ||
              roleHandler(
                role,
                "AONE_CONSIGNMENTS_CONSIGNMENT.edit_incoterms"
              )) &&
            orderDetails?.status !== "Consignment Canceled" && (
              <div style={{ marginLeft: "auto" }}>
                <Button onClick={handleEditClick} size="small">
                  <img
                    src={editIcon}
                    title="Edit"
                    className="turnBlack"
                    alt="Edit"
                  />
                </Button>
              </div>
            )}
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Customer:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.customerSummary?.name
                        ? orderDetails?.customerSummary?.name
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Customer Account:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.customerSummary?.accountNumber
                        ? orderDetails?.customerSummary?.accountNumber
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Customer reference number:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.referenceNumber}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Created at:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.createdAt
                        ? new Date(orderDetails?.createdAt).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }
                          )
                        : null}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      To collect amount:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.toCollectAmount
                        ? orderDetails?.toCollectAmount
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Product:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.productCode}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Content Type:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.contentType}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Is Cod:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.cod ? "true" : "false"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Is International:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.international ? "true" : "false"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Incoterms:</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {editMode ? (
                        <SearchInput
                          id="incoterms"
                          label="incoterms"
                          name="incoterms"
                          options={orderIncoterms?.data?.data || []}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.key}
                          isClearable={true}
                          handleChange={(selectedOption) => {
                            setSelectedIncoterms(
                              selectedOption ? selectedOption.key : ""
                            );
                          }}
                          value={
                            selectedIncoterms
                              ? orderIncoterms?.data?.data?.find(
                                  (inco) => inco.key === selectedIncoterms
                                )
                              : null
                          }
                          isSearchable={true}
                          placeholder="Incoterms"
                          customStyles={{ control: { width: "200px" } }}
                          className="mainInput"
                          style={{ whiteSpace: "nowrap" }}
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.incoterms
                            ? orderDetails?.incoterms
                            : "-"}
                        </Typography>
                      )}
                      {editMode && (
                        <>
                          <Button
                            onClick={handleTickClick}
                            size="small"
                            title="Save"
                            style={{ marginLeft: "10px", color: "#EC171C" }}
                          >
                            <CheckIcon />
                          </Button>
                          <Button
                            onClick={handleCancelClick}
                            size="small"
                            title="Cancel"
                            style={{ marginLeft: "-17px", color: "#EC171C" }}
                          >
                            <CloseIcon />
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <Typography className="mt_10">DDU Amount:</Typography>
                    {editMode && selectedIncoterms === "DDU" ? (
                      <input
                        type="text"
                        className="mainInput"
                        maxLength={5}
                        style={{ width: "200px" }}
                        name="dduAmount"
                        value={dduAmount || ""} // Ensure the input is controlled
                        onChange={handleInputChange}
                        placeholder="DDU Amount"
                      />
                    ) : (
                      <Typography className="greycol">
                        {orderDetails?.dduAmount
                          ? orderDetails?.dduAmount
                          : "-"}
                      </Typography>
                    )}
                    {editMode && selectedIncoterms === "DDU" && (
                      <>
                        <Button
                          onClick={handleTickDDuClick}
                          size="small"
                          title="Save"
                          style={{ marginLeft: "10px", color: "#EC171C" }}
                        >
                          <CheckIcon />
                        </Button>
                        <Button
                          onClick={handleCancelClick}
                          size="small"
                          title="Cancel"
                          style={{ marginLeft: "10px", color: "#EC171C" }}
                        >
                          <CloseIcon />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Current Status:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.status}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Last updated date:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.updatedAt
                        ? new Date(orderDetails?.updatedAt).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }
                          )
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Current Hub:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.currentHubCode
                        ? orderDetails?.currentHubCode
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Delivery Attempts:
                    </Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Notes:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.note ? orderDetails?.note : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Origin Country:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.country}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Destination Country:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.country}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Is RTO:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.rto ? "true" : "false"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Shipment Description:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.description
                        ? orderDetails?.description
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Is RPU:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.rpu ? "true" : "false"}
                    </Typography>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel2")
            : expanded === "panel2"
        }
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Destination Details
          </Typography>
          {!editDesClicked ? (
            roleHandler(
              role,
              "AONE_CONSIGNMENTS_CONSIGNMENT.edit_destination_address"
            ) &&
            orderDetails?.status !== "Consignment Canceled" && (
              <div style={{ marginLeft: "auto" }}>
                <Button onClick={handleEditDesClick} size="small">
                  <img
                    src={editIcon}
                    title="Edit"
                    className="turnBlack"
                    alt="Edit"
                  />
                </Button>
              </div>
            )
          ) : (
            <div style={{ marginLeft: "auto" }}>
              <Button onClick={handleSaveDesClick} size="small">
                <img
                  src={saveIcon}
                  title="Save"
                  className="turnBlack"
                  alt="Save"
                />
              </Button>
              <Button onClick={handleCancelDesClick} size="small">
                <img
                  src={cancelIcon}
                  title="Cancel"
                  className="turnBlack"
                  alt="Cancel"
                />
              </Button>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Receiver Name:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Phone:</Typography>
                    <Typography className="greycol">
                      {editDesMode ? (
                        <input
                          type="text"
                          className="mainInput"
                          style={{ width: "200px" }}
                          name="phone"
                          value={updateDesDetails?.values?.phone || ""} // Ensure the input is controlled
                          onChange={(e) =>
                            updateDesDetails.setFieldValue(
                              "phone",
                              e.target.value
                            )
                          }
                          placeholder="Phone "
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.deliveryAddress?.phone}
                        </Typography>
                      )}
                    </Typography>
                    {updateDesDetails.touched.phone &&
                    updateDesDetails.errors.phone ? (
                      <p className="requiredText">
                        {updateDesDetails.errors.phone}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Typography className="mt_10">Alternate Phone:</Typography>
                    <Typography className="greycol">
                      {editDesMode ? (
                        <input
                          type="text"
                          className="mainInput"
                          style={{ width: "200px" }}
                          name="dduAmount"
                          value={updateDesDetails?.values?.alternatePhone || ""} // Ensure the input is controlled
                          onChange={(e) =>
                            updateDesDetails.setFieldValue(
                              "alternatePhone",
                              e.target.value
                            )
                          }
                          placeholder="Alternate Phone "
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.deliveryAddress?.alternatePhone
                            ? orderDetails?.deliveryAddress?.alternatePhone
                            : "-"}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Email:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.email}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      National Short Address:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.nationalShortAddress
                        ? orderDetails?.deliveryAddress?.nationalShortAddress
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Country:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.country}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Region/State:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.region}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">City:</Typography>
                    <Typography className="greycol">
                      {editDesMode ? (
                        <SearchInput
                          id="cityName"
                          label="City"
                          name="cityName"
                          options={citybyCodeDes || []}
                          getOptionLabel={(city) => city.name}
                          getOptionValue={(city) => city.name}
                          value={
                            updateDesDetails?.values?.city
                              ? citybyCodeDes?.find(
                                  (city) =>
                                    city?.code ===
                                    updateDesDetails?.values?.city
                                )
                              : null
                          }
                          handleChange={async (selectedOption) => {
                            updateDesDetails.setFieldValue(
                              "city",
                              selectedOption ? selectedOption.name : ""
                            );
                            try {
                              const districtData = await getDistrictDesbyCode(
                                orderDetails?.deliveryAddress?.countryCode,
                                selectedOption?.regionCode,
                                selectedOption.code
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          customStyles={{ control: { width: "200px" } }}
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Select City"
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.deliveryAddress?.city}
                        </Typography>
                      )}
                    </Typography>
                    {updateDesDetails.touched.city &&
                    updateDesDetails.errors.city ? (
                      <p className="requiredText">
                        {updateDesDetails.errors.city}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Typography className="mt_10">District:</Typography>
                    <Typography className="greycol">
                      {editDesMode ? (
                        <SearchInput
                          id="districtName"
                          label="districtName"
                          name="districtName"
                          options={districtbyDescode || []}
                          getOptionLabel={(district) => district.name}
                          getOptionValue={(district) => district.code}
                          customStyles={{ control: { width: "200px" } }}
                          value={
                            updateDesDetails?.values?.district
                              ? districtbyDescode?.find(
                                  (district) =>
                                    district?.code ===
                                    updateDesDetails?.values?.district
                                )
                              : null
                          }
                          handleChange={(selectedOption) => {
                            updateDesDetails.setFieldValue(
                              "district",
                              selectedOption ? selectedOption.name : ""
                            );
                          }}
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Select District"
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.deliveryAddress?.district
                            ? orderDetails?.deliveryAddress?.district
                            : "-"}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Location:</Typography>
                    <Typography className="greycol">
                      <div className="row">
                        <div className="col-md-4">
                          {orderDetails &&
                          orderDetails.deliveryAddress &&
                          orderDetails.deliveryAddress.latitude != null
                            ? `${orderDetails.deliveryAddress.latitude}, ${
                                orderDetails.deliveryAddress.longitude ?? "-"
                              }`
                            : "-"}
                        </div>
                        {orderDetails?.deliveryAddress?.latitude && (
                          <div className="col-md-3 mt_-5">
                            <button className="cancel_btn">
                              <span
                                onClick={() =>
                                  handleClick(
                                    orderDetails?.deliveryAddress?.latitude,
                                    orderDetails?.deliveryAddress?.longitude
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Address Line 1:</Typography>
                    <Typography className="greycol">
                      {editDesMode ? (
                        <input
                          type="text"
                          className="mainInput"
                          style={{ width: "200px" }}
                          name="addressLine1"
                          value={updateDesDetails?.values?.addressLine1 || ""} // Ensure the input is controlled
                          onChange={(e) =>
                            updateDesDetails.setFieldValue(
                              "addressLine1",
                              e.target.value
                            )
                          }
                          placeholder="Address Line 1"
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.deliveryAddress?.addressLine1}
                        </Typography>
                      )}
                    </Typography>
                    {updateDesDetails.touched.addressLine1 &&
                    updateDesDetails.errors.addressLine1 ? (
                      <p className="requiredText">
                        {updateDesDetails.errors.addressLine1}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Typography className="mt_10">Address Line 2:</Typography>
                    <Typography className="greycol">
                      {editDesMode ? (
                        <input
                          type="text"
                          className="mainInput"
                          style={{ width: "200px" }}
                          name="addressLine2"
                          value={updateDesDetails?.values?.addressLine2 || ""} // Ensure the input is controlled
                          onChange={(e) =>
                            updateDesDetails.setFieldValue(
                              "addressLine2",
                              e.target.value
                            )
                          }
                          placeholder="Address Line 2"
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.deliveryAddress?.addressLine2
                            ? orderDetails?.deliveryAddress?.addressLine2
                            : "-"}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Postal Code:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.postalCode
                        ? orderDetails?.deliveryAddress?.postalCode
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Destination Zone:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.destinationZoneCode
                        ? orderDetails?.destinationZoneCode
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography>Destination Hub:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.destinationHubCode}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Address updated:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.updated ? "Yes" : "No"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Address updated by:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.updatedBy
                        ? orderDetails?.deliveryAddress?.updatedBy
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Address updated date:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.updatedAt
                        ? new Date(
                            orderDetails?.deliveryAddress?.updatedAt
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel3")
            : expanded === "panel3"
        }
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Delivery Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Attempts:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">OTP Validated:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Collection Mode:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Transaction id:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Receiver name:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Receiver signature:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.signature ? (
                        <div className="row">
                          <div className="col-md-6 mt_-5">
                            <button className="cancel_btn">
                              <span>
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Location:</Typography>
                    <Typography className="greycol">
                      <div className="row">
                        <div className="col-md-4">
                          {orderDetails &&
                          orderDetails.deliveryAddress &&
                          orderDetails.deliveryAddress.latitude != null
                            ? `${orderDetails.deliveryAddress.latitude}, ${
                                orderDetails.deliveryAddress.longitude ?? "-"
                              }`
                            : "-"}
                        </div>
                        {orderDetails?.deliveryAddress?.latitude && (
                          <div className="col-md-3 mt_-5">
                            <button className="cancel_btn">
                              <span
                                onClick={() =>
                                  handleClick(
                                    orderDetails?.deliveryAddress?.latitude,
                                    orderDetails?.deliveryAddress?.longitude
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Last attempt date:
                    </Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                </div>
                <div className="col-md-2">
                  <div>
                    <Typography>Proof Images:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.images ? (
                        <div className="row">
                          <div className="col-md-6 mt_-5">
                            <button className="cancel_btn">
                              <span>
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Proof of payment:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.deliveryAddress?.proof ? (
                        <div className="row">
                          <div className="col-md-6 mt_-5">
                            <button className="cancel_btn">
                              <span>
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-2">
                  <div>
                    <Typography>Payment Date:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Collected Amount:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div></div>
                </div>
                <div className="col-md-2">
                  <div>
                    <Typography>Delivery date:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Delivery failure reason:
                    </Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Courier Id:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.courierCode}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Courier Name:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.courierName}
                    </Typography>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel4")
            : expanded === "panel4"
        }
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Origin Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Shipper Name:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Alias:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.alias
                        ? orderDetails?.pickupAddress?.alias
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Phone:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.phone}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Alternate Phone:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.alternatePhone
                        ? orderDetails?.pickupAddress?.alternatePhone
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Email:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.email}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>National Short Address:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.shortAddress
                        ? orderDetails?.pickupAddress?.shortAddress
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Country:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.country}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Region/State:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.region}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">City:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.city}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">District:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.district
                        ? orderDetails?.pickupAddress?.district
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Address Line 1:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.addressLine1}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Address Line 2:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.addressLine2
                        ? orderDetails?.pickupAddress?.addressLine2
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Postal Code:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupAddress?.postalCode
                        ? orderDetails?.pickupAddress?.postalCode
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Location:</Typography>
                    <Typography className="greycol">
                      <div className="row">
                        <div className="col-md-4">
                          {orderDetails &&
                          orderDetails.pickupAddress &&
                          orderDetails.pickupAddress.latitude != null
                            ? `${orderDetails.pickupAddress.latitude}, ${
                                orderDetails.pickupAddress.longitude ?? "-"
                              }`
                            : "-"}
                        </div>
                        {orderDetails?.pickupAddress?.latitude && (
                          <div className="col-md-3 mt_-5">
                            <button className="cancel_btn">
                              <span
                                onClick={() =>
                                  handleClick(
                                    orderDetails?.pickupAddress?.latitude,
                                    orderDetails?.pickupAddress?.longitude
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography className="mt_10">Origin Zone:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.originZoneCode}
                    </Typography>
                  </div>
                  <div>
                    <Typography>Origin Hub:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.originHubCode}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Proof Images:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.proofPaymentUrl ? (
                        <div className="row">
                          <div className="col-md-6 mt_-5">
                            <button className="cancel_btn">
                              <span>
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </Typography>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel5")
            : expanded === "panel5"
        }
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Pickup Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Pickup Type:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.pickupMethod
                        ? orderDetails?.pickupDetails?.pickupMethod
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Fulfillment System:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.fulfillmentSystem
                        ? orderDetails?.pickupDetails?.fulfillmentSystem
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Fulfillment Reference number:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.fulfillmentReferenceNumber
                        ? orderDetails?.pickupDetails
                            ?.fulfillmentReferenceNumber
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Drop off hub:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.dropOffHubCode
                        ? orderDetails?.pickupDetails?.dropOffHubCode
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography className="mt_10">
                      Shipper signature:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.shipperSignature
                        ? orderDetails?.pickupDetails?.shipperSignature
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Location:</Typography>
                    <Typography className="greycol">
                      <div className="row">
                        <div className="col-md-4">
                          {orderDetails &&
                          orderDetails?.pickupDetails &&
                          orderDetails?.pickupDetails?.latitude != null
                            ? `${orderDetails.pickupDetails.latitude}, ${
                                orderDetails.pickupDetails.longitude ?? "-"
                              }`
                            : "-"}
                        </div>
                        {orderDetails?.pickupDetails?.latitude && (
                          <div className="col-md-3 mt_-5">
                            <button className="cancel_btn">
                              <span
                                onClick={() =>
                                  handleClick(
                                    orderDetails?.pickupDetails?.latitude,
                                    orderDetails?.pickupDetails?.longitude
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img src={externalLink} alt="Link" />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>
                  <div>
                    <Typography>Pickup Date:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.pickupDate
                        ? new Date(
                            orderDetails?.pickupDetails?.pickupDate
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Pickup failure reason:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.failureReason
                        ? orderDetails?.pickupDetails?.failureReason
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Pickup Request:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.pickupRequestId
                        ? orderDetails?.pickupDetails?.pickupRequestId
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Courier Id:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.courierCode
                        ? orderDetails?.pickupDetails?.courierCode
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Courier Name:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.pickupDetails?.courierName
                        ? orderDetails?.pickupDetails?.courierName
                        : "-"}
                    </Typography>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel6")
            : expanded === "panel6"
        }
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Consignment Details
          </Typography>
          {orderDetails?.rto !== true &&
            (!editCodClicked ? (
              (roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") ||
                roleHandler(
                  role,
                  "AONE_CONSIGNMENTS_CONSIGNMENT.edit_cod"
                )) && (
                <div style={{ marginLeft: "auto" }}>
                  <Button onClick={handleEditCodClick} size="small">
                    <img
                      src={editIcon}
                      title="Edit"
                      className="turnBlack"
                      alt="Edit"
                    />
                  </Button>
                </div>
              )
            ) : (
              <div style={{ marginLeft: "auto" }}>
                <Button onClick={handleSaveCodClick} size="small">
                  <img
                    src={saveIcon}
                    title="Save"
                    className="turnBlack"
                    alt="Save"
                  />
                </Button>
                <Button onClick={handleCancelCodClick} size="small">
                  <img
                    src={cancelIcon}
                    title="Cancel"
                    className="turnBlack"
                    alt="Cancel"
                  />
                </Button>
              </div>
            ))}
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Declared Value Currency:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.declaredValueCurrency}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Declared Value Amount:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.declaredValue}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Cash on delivery currency:
                    </Typography>
                    <Typography className="greycol">
                      {editCodClicked ? (
                        <SearchInput
                          id="codCurrency"
                          label="codCurrency"
                          name="codCurrency"
                          options={currencyData?.data?.data?.content || []}
                          getOptionLabel={(city) => city.code}
                          getOptionValue={(city) => city.code}
                          isClearable={true}
                          handleChange={(selectedOption) => {
                            setSelectedCurrency(
                              selectedOption ? selectedOption.code : ""
                            );
                          }}
                          value={
                            selectedCurrency
                              ? currencyData?.data?.data?.content?.find(
                                  (currency) =>
                                    currency.code === selectedCurrency
                                )
                              : null
                          }
                          isSearchable={true}
                          isDisabled={true}
                          placeholder="COD currency"
                          customStyles={{ control: { width: "200px" } }}
                          className="mainInput"
                          style={{ whiteSpace: "nowrap" }}
                        />
                      ) : (
                        <Typography className="greycol">
                          {orderDetails?.codCurrency
                            ? orderDetails?.codCurrency
                            : selectedCurrency}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Cash on delivery amount:
                    </Typography>
                    {editCodClicked ? (
                      <input
                        type="text"
                        className="mainInput"
                        maxLength={5}
                        style={{ width: "200px" }}
                        name="codAmount"
                        value={codAmount || ""} // Ensure the input is controlled
                        onChange={handleInputCodChange}
                        placeholder="COD Amount"
                      />
                    ) : (
                      <Typography className="greycol">
                        {codAmount
                          ? codAmount
                          : orderDetails?.codAmount
                          ? orderDetails?.codAmount
                          : "-"}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <Typography className="mt_10">Incoterms:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.incoterms}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Packages count:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.packages?.length}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Items count:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.items?.length
                        ? orderDetails?.items?.length
                        : 0}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Volumetric Weight:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.volumetricWeight
                        ? orderDetails?.volumetricWeight
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Actual Volumetric Weight:
                    </Typography>
                    <Typography className="greycol">
                      {orderDetails?.actualVolumetricWeight
                        ? orderDetails?.actualVolumetricWeight
                        : ""}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Is Insured:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.insured ? "Yes" : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-2">
                  <div>
                    <Typography>Weight Unit:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.weightUnit}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Input Weight:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.weight ? orderDetails?.weight : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Actual Weight:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.actualWeight
                        ? orderDetails?.actualWeight
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Chargeable Weight:
                    </Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography>Dimension Unit:</Typography>
                    <Typography className="greycol">
                      {orderDetails?.packages?.[0]?.dimensionUnit}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-2">
                  <div>
                    <Typography>Weight updated by:</Typography>
                    <Typography className="greycol">
                      {" "}
                      {orderDetails?.weightUpdatedBy
                        ? orderDetails?.weightUpdatedBy
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Dimensions updated by:
                    </Typography>
                    <Typography className="greycol">
                      {" "}
                      {orderDetails?.dimensionsUpdatedBy
                        ? orderDetails?.dimensionsUpdatedBy
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Dimensions updated date:
                    </Typography>
                    <Typography className="greycol">
                      {" "}
                      {orderDetails?.dimensionsUpdatedAt
                        ? new Date(
                            orderDetails?.dimensionsUpdatedAt
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Weight updated data:
                    </Typography>
                    <Typography className="greycol">
                      {" "}
                      {orderDetails?.weightUpdatedAt
                        ? new Date(
                            orderDetails?.weightUpdatedAt
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                  <div></div>
                </div>
                <div className="col-md-2">
                <div>
                    <Typography>Content:</Typography>
                    <Typography
  className="greycol greycolnew"
>
                      {" "}
                      {orderDetails?.content
                        ? orderDetails?.content
                        : "-"} 
                    </Typography>
                  </div>
                </div>
                  {/* <div>
                    <Typography className='mt_10'>
                      Input Dimensions:
                    </Typography>
                    <Typography className='greycol'>
                      {orderDetails?.deliveryAddress?.countryCode}
                    </Typography>
                  </div>
                  <div>
                    <Typography className='mt_10'>
                      Actual Dimensions:
                    </Typography>
                    <Typography className='greycol'>
                      {orderDetails?.rto ? "true" : "false"}
                    </Typography>
                  </div> */}
                  <div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel7")
            : expanded === "panel7"
        }
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Package Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt_15 table_wrapper mb_8 showFiveRows">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Package Tracking Id</th>
                  <th>Weight Unit</th>
                  <th>Weight</th>
                  <th>Dimension Unit</th>
                  <th>Height</th>
                  <th>Width</th>
                  <th>Length</th>
                </tr>
              </thead>
              {orderDetails?.packages?.map((addedpackage, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{addedpackage.packageTrackingId}</td>
                  <td>{addedpackage.weightUnit}</td>
                  <td>{addedpackage.weight}</td>
                  <td>{addedpackage.dimensionUnit}</td>
                  <td>{addedpackage.height}</td>
                  <td>{addedpackage.width}</td>
                  <td>{addedpackage.length}</td>
                </tr>
              ))}
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel8")
            : expanded === "panel8"
        }
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Items Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt_15 table_wrapper mb_8 showFiveRows">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Description</th>
                  <th>Quantity</th>
                  <th>Item Price</th>
                  <th>Currency</th>
                  <th>SKU</th>
                  <th>HS Code</th>
                  <th>Country of Origin</th>
                  <th>Package Number</th>
                </tr>
              </thead>
              {orderDetails?.items?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity}</td>
                  <td>{item.unitPrice}</td>
                  <td>{item.currency}</td>
                  <td>{item.sku}</td>
                  <td>{item.hsCode}</td>
                  <td>{item.countryOfOrigin}</td>
                  <td>{item.packageSequence}</td>
                </tr>
              ))}
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel9")
            : expanded === "panel9"
        }
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7-content"
          id="panel7-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Invoice details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Invoice Id:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Invoice Date:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography className="mt_10">Invoiced By:</Typography>
                    <Typography className="greycol">-</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default OrdersDetail;
