import React, { useState, useEffect } from 'react'
import editicon from "../../assets/img/editicon.svg";
import DeleteIcon from "../../assets/img/delete_icon.svg"
import { DatePicker } from 'rsuite'
import { useParams,useNavigate } from 'react-router'
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useAoneStore } from '../../store/AoneStore';
import fileDownload from 'js-file-download';
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg"

const DetailedOrder = () => {

    const params = useParams();
    const navigate = useNavigate();

    const postJobOrders = useAoneStore((state) => state?.postJobOrders)
    const getJobOrderById = useAoneStore((state) => state?.getJobOrderById)
    const putJobOrderById = useAoneStore((state) => state?.putJobOrderById)
    const postJobOrdersItemById = useAoneStore((state) => state?.postJobOrdersItemById)
    const putJobOrderItemById = useAoneStore((state) => state?.putJobOrderItemById)
    const deleteJobOrderItemById = useAoneStore((state) => state?.deleteJobOrderItemById)
    const printJobOrderQR = useAoneStore((state) => state?.printJobOrderQR)

    const [show, setShow] = useState(false);
    const [editHandler, setEditHandler] = useState(false);

    const handleClose = () => {
        setShow(false);
        addItem.resetForm();
    }

    const handleShow = ( postHandler, data, i ) => {
        if(postHandler){
            addItem.setFieldValue("name",data.name);
            addItem.setFieldValue("sku",data.sku);
            addItem.setFieldValue("quantity",data.quantity);
            addItem.setFieldValue("receivedQuantity",data.receivedQuantity);
            addItem.setFieldValue("reference",i)
        }
        setEditHandler(postHandler);
        setShow(true);
    }  
    
    const handleDeletion = async ( value ) => {
        if(params?.id){
            await deleteJobOrderItemById(params?.id,value);
            getData();
        }else{
            let data = [...items];
            data.splice(value, 1);
            console.log("DATA",data);
            setItems(data);
        }
    }

    const exportQrCode = () => {
        printJobOrderQR(addOrders.values.qrCode).then((res)=>{
            fileDownload(res.data, `${addOrders.values.qrCode}.pdf`)
        })
    }

    const [order, setOrder] = useState({})
    const [items, setItems] = useState([])

    const [dropdownOne, setDropdownOne] = useState(false)
    const [dropdownTwo, setDropdownTwo] = useState(false)
    const options = ["IN_PROGRESS", "DONE"]

    const addItem = useFormik({
        initialValues: {
            sku: "",
            name: "",
            quantity: 0,
            receivedQuantity: 0,
            reference: 0
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            sku: Yup.string().required("SKU is Required"),
            name: Yup.string().required("Name is Required"),
            quantity: Yup.string().required("Quantity is Required"),
            receivedQuantity: Yup.string().required("Received Quantity is Required"),
        }),
        onSubmit: async (values) => {
            let reference = values.reference
            delete values["reference"]
            console.log("ITEM", values);
            if(!params?.id && !editHandler){
                let data = [...items]
                data.push(values)
                setItems(data)
            }else if(!params?.id && editHandler){
                const data = [...items]
                Object.keys(values).map((e) => data[reference][e] = values[e])
                setItems(data)
            }else if(params?.id && !editHandler){
                await postJobOrdersItemById(params?.id, values);
                getData();
            }else if(params?.id && editHandler){
                await putJobOrderItemById(params?.id, reference, values);
                getData();
            }
            handleClose();
        }
    })

    const [ orderParams, setOrderParams ] = useState(
        !params?.id ?
        {
            customerAccount: "",
            estimatedReceiveDate: null,
            boxCount: 0,
            qrCode: "",
        } :
        {
            code: "",
            customerAccount: "",
            estimatedReceiveDate: null,
            scheduledDeliverDate: null,
            receivedDate: null,
            boxCount: 0,
            qrCode: "",
            fulfillmentStatus: "",
            status: ""
        }
    )

    const addOrders = useFormik({
        initialValues: orderParams,
        enableReinitialize: true,
        validationSchema: Yup.object( !params?.id ? {
            customerAccount: Yup.string().required("Customer Account is Required"),
            estimatedReceiveDate: Yup.string().required("Estimation is Required"),
            boxCount: Yup.string().required("Box Count is Required"),
            qrCode: Yup.string().required("QR Code is Required"),
        } : {
            customerAccount: Yup.string().required("Customer Account is Required"),
            estimatedReceiveDate: Yup.string().required("Estimation is Required"),
            scheduledDeliverDate: Yup.string().required("Delivery Date is Required"),
            receivedDate: Yup.string().required("Receive Date is Required"),
            boxCount: Yup.string().required("Box Count is Required"),
            qrCode: Yup.string().required("Qr Code is Required"),
            fulfillmentStatus: Yup.string().required("Fulfillment Status is Required"),
            status: Yup.string().required("Status is Required")
        }),
        onSubmit: async (values) => {
            console.log("ORDERS", values);
            if(!params?.id){
                values["orderItems"] = items;
                values["estimatedReceiveDate"] = values?.estimatedReceiveDate.toISOString();
                await postJobOrders(values);
                navigate("/projects/addon/orders")
            }else{
                const data = {...values}
                data["estimatedReceiveDate"] = data?.estimatedReceiveDate.toISOString();
                data["scheduledDeliverDate"] = data?.scheduledDeliverDate.toISOString();
                data["receivedDate"] = data?.receivedDate.toISOString();
                await putJobOrderById(params?.id,data)
                getData();
            }
        }
    })

    const getData = async () => {
        const data = await getJobOrderById(params?.id)
        addOrders.setFieldValue("code",data?.data?.data?.code)
        addOrders.setFieldValue("customerAccount",data?.data?.data?.customerAccount)
        addOrders.setFieldValue("estimatedReceiveDate",data?.data?.data?.estimatedReceiveDate ? new Date(data?.data?.data?.estimatedReceiveDate) : null)
        addOrders.setFieldValue("scheduledDeliverDate",data?.data?.data?.scheduledDeliverDate ? new Date(data?.data?.data?.scheduledDeliverDate) : null)
        addOrders.setFieldValue("receivedDate",data?.data?.data?.receivedDate ? new Date(data?.data?.data?.receivedDate) : null)
        addOrders.setFieldValue("boxCount",data?.data?.data?.boxCount)
        addOrders.setFieldValue("qrCode",data?.data?.data?.qrCode)
        addOrders.setFieldValue("fulfillmentStatus",data?.data?.data?.fulfillmentStatus)
        addOrders.setFieldValue("status",data?.data?.data?.status)
        setItems(data?.data?.data?.orderItems)
    }

    useEffect(()=>{
        params?.id && getData();
    },[])

    console.log("RENDERS", addOrders.values);

  return (
    <div>
        <div className='row alignItemCenter mb_16'>
            <ul className="page_breadcrumb">
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500"}}>
                  Projects
                  </span>
                  </li>
                  <li style={{marginLeft:"-10px",marginBottom:"5px"}}>
                  <span><img src={breadArrow} className="turnBlack" alt="breadcrumbarrow"/></span>
                  </li>
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px"}}>
                  Add On
                  </span>
                  </li>
                  <li style={{marginLeft:"-10px",marginBottom:"5px"}}>
                  <span><img src={breadArrow} className="turnBlack" alt="breadcrumbarrow"/></span>
                  </li>
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px"}}>
                  Job Orders
                  </span>
                  </li>
                  <li style={{marginLeft:"-10px",marginBottom:"5px"}}>
                  <span><img src={breadArrow} className="turnBlack" alt="breadcrumbarrow"/></span>
                  </li>
                  {params?.id ?
                  <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px",fontWeight:"bolder"}}>
                  COD-001
                  </span> :
                   <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px",fontWeight:"bolder"}}>
                  New
                   </span> }
                  </ul>
            {/* { params?.id ? <span className='mainheadlink'>COD-001</span> : <span className='mainheadlink'>New</span> } */}
        </div>

        { params?.id &&
        <div className='displayFlex alignItemCenter justifyContent_end'>
            <button className='filterBtn' onClick={()=>exportQrCode()}>Print QR</button>
        </div>
        }

        <div className='row'>
            <div className='col-md-6' style={{ padding: "12px"}}>
                { params?.id &&
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Code:</label>
                    </div>
                    <div className='col-md-8'>
                        <input 
                            type='text' placeholder='Code' className='mainInput' readOnly value={addOrders.values.code}
                        />
                    </div>
                </div>
                }
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Customer: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                    </div>
                    <div className='col-md-8'>
                        <input 
                            type='text' placeholder='Customer' className='mainInput'
                            onBlur={addOrders.handleBlur}
                            name="customerAccount" value={addOrders.values.customerAccount}
                            onChange={(e)=> addOrders.setFieldValue("customerAccount",e.target.value)}
                        />
                        {addOrders.touched.customerAccount &&
                            addOrders.errors.customerAccount ? (
                                <p className="requiredText">
                            {addOrders.errors.customerAccount}
                        </p>
                        ) : null}
                    </div>
                </div>
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Estimated Receive Date: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                    </div>
                    <div className='col-md-8'>
                        <div>
                            <div className="customDateRange" style={{ color: "black"}}>
                                <DatePicker
                                    placeholder="Select Date"
                                    placement="rightStart"
                                    onChange={(date)=>addOrders.setFieldValue("estimatedReceiveDate",date)}
                                    onClean={()=>addOrders.setFieldValue("estimatedReceiveDate",null)}
                                    value={addOrders.values.estimatedReceiveDate}
                                />
                            </div>
                            {addOrders.touched.estimatedReceiveDate &&
                                addOrders.errors.estimatedReceiveDate ? (
                                    <p className="requiredText">
                                {addOrders.errors.estimatedReceiveDate}
                            </p>
                            ) : null}
                        </div>
                    </div>
                </div>
                { params?.id &&
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Scheduled Received Date:</label>
                    </div>
                    <div className='col-md-8'>
                        <div>
                            <div className="customDateRange" style={{ color: "black"}}>
                                <DatePicker
                                    placeholder="Select Date"
                                    placement="rightStart"
                                    onChange={(date)=>addOrders.setFieldValue("scheduledDeliverDate",date)}
                                    onClean={()=>addOrders.setFieldValue("scheduledDeliverDate",null)}
                                    value={addOrders.values.scheduledDeliverDate}
                                />
                            </div>
                            {addOrders.touched.scheduledDeliverDate &&
                                addOrders.errors.scheduledDeliverDate ? (
                                    <p className="requiredText">
                                {addOrders.errors.scheduledDeliverDate}
                            </p>
                            ) : null}
                        </div>
                    </div>
                </div>    
                } 
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Box Numbers:</label>
                    </div>
                    <div className='col-md-8'>
                        <input 
                            type='text' placeholder='Numbers' className='mainInput'
                            onBlur={addOrders.handleBlur}
                            name="boxCount" value={addOrders.values.boxCount}
                            onChange={(e)=> addOrders.setFieldValue("boxCount",e.target.value.replace(/[^0-9]/g, ""))}
                        />
                        {addOrders.touched.boxCount &&
                            addOrders.errors.boxCount ? (
                                <p className="requiredText">
                            {addOrders.errors.boxCount}
                        </p>
                        ) : null}
                    </div>
                </div>
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>QR: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                    </div>
                    <div className='col-md-8'>
                        <input 
                            type='text' placeholder='QR Code' className='mainInput'
                            onBlur={addOrders.handleBlur}
                            name="qrCode" value={addOrders.values.qrCode}
                            onChange={(e)=> addOrders.setFieldValue("qrCode",e.target.value)}
                        />
                        {addOrders.touched.qrCode &&
                            addOrders.errors.qrCode ? (
                                <p className="requiredText">
                            {addOrders.errors.qrCode}
                        </p>
                        ) : null}
                    </div>
                </div>
                { params?.id &&
                <>
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Received Date:</label>
                    </div>
                    <div className='col-md-8'>
                        <div>
                            <div className="customDateRange" style={{ color: "black"}}>
                                <DatePicker
                                    placeholder="Select Date"
                                    placement="rightEnd"
                                    onChange={(date)=>addOrders.setFieldValue("receivedDate",date)}
                                    onClean={()=>addOrders.setFieldValue("receivedDate",null)}
                                    value={addOrders.values.receivedDate}
                                />
                            </div>
                            {addOrders.touched.receivedDate &&
                                addOrders.errors.receivedDate ? (
                                    <p className="requiredText">
                                {addOrders.errors.receivedDate}
                            </p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Fullfillment Status:</label>
                    </div>
                    <div className='col-md-8'>
                        <input 
                            type='text' placeholder='Fullfillment' className='mainInput'
                            onBlur={addOrders.handleBlur} onClick={()=>setDropdownOne(!dropdownOne)}
                            name="fulfillmentStatus" value={addOrders.values.fulfillmentStatus}
                            readOnly
                        />
                        {dropdownOne && (
                            <div className="dropdownResult">
                                { options.map((e,i) => {
                                    return <div key={i}
                                    className="dropdownResultItem"
                                    onClick={() => {
                                        setDropdownOne(!dropdownOne);
                                        addOrders.setFieldValue("fulfillmentStatus",e);
                                    }}
                                    >
                                    {e}
                                    </div>
                                }) }
                            </div>
                        )} 
                        {addOrders.touched.fulfillmentStatus &&
                            addOrders.errors.fulfillmentStatus ? (
                                <p className="requiredText">
                            {addOrders.errors.fulfillmentStatus}
                        </p>
                        ) : null}
                    </div>
                </div>
                <div className='row mb_24'>
                    <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500'>
                        <label>Status:</label>
                    </div>
                    <div className='col-md-8'>
                        <input 
                            type='text' placeholder='Status' className='mainInput'
                            onBlur={addOrders.handleBlur} onClick={()=>setDropdownTwo(!dropdownTwo)}
                            name="status" value={addOrders.values.status}
                            readOnly
                        />
                        {dropdownTwo && (
                            <div className="dropdownResult">
                                { options.map((e,i) => {
                                    return <div key={i}
                                    className="dropdownResultItem"
                                    onClick={() => {
                                        setDropdownTwo(!dropdownTwo);
                                        addOrders.setFieldValue("status",e);
                                    }}
                                    >
                                    {e}
                                    </div>
                                }) }
                            </div>
                        )} 
                        {addOrders.touched.status &&
                            addOrders.errors.status ? (
                                <p className="requiredText">
                            {addOrders.errors.status}
                        </p>
                        ) : null}
                    </div>
                </div>
                </>
                }
                <div className='row mb_24'>
                    <div className='col-md-12 displayFlex alignItemCenter justifyContent_end gap_16'>
                        <button className='blue_btn' onClick={addOrders.handleSubmit}>
                        Save
                        </button>
                        {/* <button  className='cancel_btn'>
                        Cancel
                        </button> */}
                    </div>
                </div>                                                               
            </div>
            <div className='col-md-6' style={{ padding: "12px"}}>
            <div className="borderFrame mb_16">
                <div className="borderFrameTitle">Items</div>
                <div className='displayFlex alignItemCenter justifyContent_end mb_16'>    
                    <button className="filterBtn" onClick={()=>handleShow(false)}>
                    +
                    </button>
                </div>
                <div className="table_wrapper mb_16 showFiveRows">
                    <table>
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>SKU</th>
                            <th>Quantity</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((e, i) => {
                            return <tr key={i}>
                                <td>{e.name}</td>
                                <td>{e.sku}</td>
                                <td>{e.quantity}</td>
                                <td>
                                    <button className='tableBtn' title="View" onClick={()=>handleShow(true,e,(params?.id ? e.id : i))}>
                                        <span><img src={editicon} className="turnBlack" /></span>
                                    </button> &nbsp;
                                    <button className='tableBtn' title="Delete" onClick={()=>handleDeletion(params?.id ? e.id : i)}>
                                        <span><img src={DeleteIcon} className='h18 turnBlack'/></span>
                                    </button>    
                                </td>
                            </tr>
                        })}
                        {
                            items.length == 0 &&
                            <tr>
                                <td>No Data</td>
                            </tr>
                        }
                    </tbody>
                    </table>
                    {/* <div className='col-md-12 displayFlex alignItemCenter justifyContent_end gap_16 mt_20'>
                        <button className='blue_btn'>
                        Update
                        </button>
                        <button  className='cancel_btn'>
                        Discard
                        </button>
                    </div>         */}
                </div>
            </div>
            </div>            
        </div>
        {show && (
        <Modal 
            show={show} 
            onHide={handleClose}
            className="addnewpopup"
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                    <h4 className="fontSize16 blackText fontWeight500">Item</h4>
                </Modal.Header>
                <div className="modelScroll">
                <Modal.Body>
                        <form>
                            <div>
                                <div className="row mb_24">
                                    <div className='col-md-12'>
                                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">SKU: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                                        <input
                                            type="text"
                                            className="washMe_btn"
                                            placeholder="SKU"
                                            name="sku"
                                            onChange={(e)=> addItem.setFieldValue("sku",e.target.value)}
                                            onBlur={addItem.handleBlur}
                                            value={addItem.values.sku}
                                        />
                                        {addItem.touched.sku &&
                                            addItem.errors.sku ? (
                                                <p className="requiredText">
                                            {addItem.errors.sku}
                                        </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mb_24">
                                    <div className='col-md-12'>
                                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Name: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                                        <input
                                            type="text"
                                            className="washMe_btn"
                                            placeholder="Name"
                                            name="name"
                                            onChange={(e)=> addItem.setFieldValue("name",e.target.value)}
                                            onBlur={addItem.handleBlur}
                                            value={addItem.values.name}
                                        />
                                        {addItem.touched.name &&
                                            addItem.errors.name ? (
                                                <p className="requiredText">
                                            {addItem.errors.name}
                                        </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mb_24">
                                    <div className='col-md-12'>
                                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Quantity:</label>
                                        <input
                                            type="text"
                                            className="washMe_btn"
                                            placeholder="Quantity"
                                            name="quantity"
                                            onChange={(e)=> addItem.setFieldValue("quantity",e.target.value.replace(/[^0-9]/g, ""))}
                                            onBlur={addItem.handleBlur}
                                            value={addItem.values.quantity}
                                        />
                                        {addItem.touched.quantity &&
                                            addItem.errors.quantity ? (
                                                <p className="requiredText">
                                            {addItem.errors.quantity}
                                        </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mb_24">
                                    <div className='col-md-12'>
                                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Received Quantity:</label>
                                        <input
                                            type="text"
                                            className="washMe_btn"
                                            placeholder="Received Quantity"
                                            name="receivedQuantity"
                                            onChange={(e)=> addItem.setFieldValue("receivedQuantity",e.target.value.replace(/[^0-9]/g, ""))}
                                            onBlur={addItem.handleBlur}
                                            value={addItem.values.receivedQuantity}
                                        />
                                        {addItem.touched.receivedQuantity &&
                                            addItem.errors.receivedQuantity ? (
                                                <p className="requiredText">
                                            {addItem.errors.receivedQuantity}
                                        </p>
                                        ) : null}
                                    </div>
                                </div>                                
                            </div>
                        </form>
                </Modal.Body>
                </div>
                <Modal.Footer>
                <button className='cancel_btn' onClick={()=>handleClose()}>
                    Cancel
                </button>
                <button className='blue_btn' onClick={addItem.handleSubmit}>
                    Save
                </button>
            </Modal.Footer>
        </Modal>
        )}
    </div>    
  )
}

export default DetailedOrder