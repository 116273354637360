import React,{useState} from "react";
import Modal from "react-bootstrap/Modal";
import deleteIcon from "../../assets/img/delete_icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import { usepickupStore } from "../../store/PickupRequest/pickuprequestStore";
import { useroutesStore } from "../../store/RoutesMgt/RoutesStore";
import { usevehicleStore } from "../../store/VehicleMgt/VehicleStore";
import { useLinehaulStore } from "../../store/ReasonsMgt/LinehaulStore";
import { useNotificationStore } from "../../store/NotificationsMgt/NotificationStore";
import { useAclStore } from "../../store/AclMgt/Aclstore";
import { useDriverStore } from "../../store/DriverMgt/driverStore";
import { useTripStore } from "../../store/TripMgt/TripStore";
import { useRatesStore } from "../../store/Rates/RateStore";
import { useDlFlowStore } from "../../store/DeliveryFlowMgt/DeliveryFlowStore";
import { useShortAddressStore } from "../../store/ShortAddress/ShortAddressStore";
import { useRtoStore } from "../../store/RtoMgt/RtoStore";
import { toast } from "react-toastify";
import { resetPasswordEmployee } from "../../services/aone_services";
import { useDeliveryStore } from "../../store/DeliveryMgt/DeliveryStore";
import { usesettingStore } from "../../store/Settings/SettingStore";
const DeletingModal = ({
  deleteHandler,
  setDeleteHandler,
  deleteParams,
  setDeleteParams,
  deleteParamsId,
  setDeleteParamsId,
  selectAll,
  setSelectAll,
  setShowActions,
  showActions,
  setselectZone,
}) => {
  const params = useParams();
  const deleteRoleById = useAoneStore((state) => state?.deleteRoleById);
  const deleteGroupById = useAoneStore((state) => state?.deleteGroupById);
  const deleteUserById = useAoneStore((state) => state?.deleteUserById);
  const deleteChuteConfigs = useAoneStore((state) => state?.deleteChuteConfigs);
  const deleteThirdparty = useAoneStore((state) => state?.deleteThirdparty);
  const deleteCountryById = useAoneStore((state) => state?.deleteCountryById);
  const deleteRegionById = useAoneStore((state) => state?.deleteRegionById);
  const deleteCityById = useAoneStore((state) => state?.deleteCityById);
  const deleteDistrictById = useAoneStore((state) => state?.deleteDistrictById);
  const deleteMapping = useAoneStore((state) => state?.deleteMapping);
  const deleteDrivers = useDriverStore((state) => state?.deleteDrivers);
  const deleteTrips = useTripStore((state) => state?.deleteTrips);
  const deleteVat = useRatesStore((state) => state?.deleteVat);
  const deleteLocalization = usesettingStore((state) => state?.deleteLocalization);
  const deleteTemplates = useNotificationStore(
    (state) => state?.deleteTemplates
  );
  const deleteRules = useNotificationStore((state) => state?.deleteRules);
  const deleteGpa = useRatesStore((state) => state?.deleteGpa);
  const cancelRto = useRtoStore((state) => state?.cancelRto);
  const removeAclConsignments = useAclStore(
    (state) => state?.removeAclConsignments
  );
  const deleteDeliveryflowbyId = useDlFlowStore(
    (state) => state?.deleteDeliveryflowbyId
  );
  const deleteCountryMappingById = useAoneStore(
    (state) => state?.deleteCountryMappingById
  );
  const deleteRegionMappingById = useAoneStore(
    (state) => state?.deleteRegionMappingById
  );
  const deleteCityMappingById = useAoneStore(
    (state) => state?.deleteCityMappingById
  );
  const deleteDistrictMappingById = useAoneStore(
    (state) => state?.deleteDistrictMappingById
  );
  const deleteCurrencyById = useAoneStore((state) => state?.deleteCurrencyById);
  const deleteCurrencyConversionById = useAoneStore(
    (state) => state?.deleteCurrencyConversionById
  );
  const deleteZoneById = useAoneStore((state) => state.deleteZoneById);
  const deleteCustomer = useAoneStore((state) => state.deleteCustomer);
  const deleteHubs = useAoneStore((state) => state.deleteHubs);
  const deleteServiceareas = useAoneStore((state) => state.deleteServiceareas);
  const deleteAreas = useAoneStore((state) => state.deleteAreas);
  const deleteProducts = useAoneStore((state) => state.deleteProducts);
  const deleteOrigin = useAoneStore((state) => state.deleteOrigin);
  const deleteRoutes = useroutesStore((state) => state.deleteRoutes);
  const deleteDestination = useAoneStore((state) => state.deleteDestination);
  const deleteTransittime = useAoneStore((state) => state.deleteTransittime);
  const deleteFreight = useAoneStore((state) => state.deleteFreight);
  const deleteRto = useAoneStore((state) => state.deleteRto);
  const deleteCod = useAoneStore((state) => state.deleteCod);
  const deleteOda = useAoneStore((state) => state.deleteOda);
  const deleteFuel = useAoneStore((state) => state.deleteFuel);
  const deleteDeliveryValidation = useDeliveryStore(
    (state) => state.deleteDeliveryValidation
  );
  const countTasks = useDeliveryStore((state) => state.countTasks);
  const deleteInsurance = useAoneStore((state) => state.deleteInsurance);
  const deleteAttempts = useAoneStore((state) => state.deleteAttempts);
  const deleteShortAddressbyId = useShortAddressStore((state) => state.deleteShortAddressbyId);
  const deletePickupConsignment = usepickupStore(
    (state) => state?.deletePickupConsignment
  );
  const deleteHoldReasonsById = useAoneStore(
    (state) => state.deleteHoldReasonsById
  );
  const deleteZonegroupById = useAoneStore(
    (state) => state.deleteZonegroupById
  );
  const deletepickupaddressbyId = useAoneStore(
    (state) => state.deletepickupaddressbyId
  );
  const deletePickupReasonsById = useAoneStore(
    (state) => state.deletePickupReasonsById
  );
  const deleteDeliveryReasonsById = useAoneStore(
    (state) => state.deleteDeliveryReasonsById
  );
  const deleteLineHaulsbyId = useLinehaulStore(
    (state) => state.deleteLineHaulsbyId
  );
  const deleteTiers = useAoneStore((state) => state.deleteTiers);
  const deleteOdTier = useAoneStore((state) => state.deleteOdTier);
  const deleteEmployees = useAoneStore((state) => state.deleteEmployees);
  const deleteVehicles = usevehicleStore((state) => state.deleteVehicles);
  const deleteVehicleDocuments = usevehicleStore(
    (state) => state.deleteVehicleDocuments
  );
  const deleteVehicleCategory = usevehicleStore(
    (state) => state.deleteVehicleCategory
  );
  const deleteVehicleVendor = usevehicleStore(
    (state) => state.deleteVehicleVendor
  );
  const deleteOdoMeter = usevehicleStore((state) => state.deleteOdoMeter);
  const deleteGpsvendor = usevehicleStore((state) => state.deleteGpsvendor);
  const setispostAdded = useAoneStore((state) => state.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const deleteWaybillByBagId = useAoneStore(
    (state) => state?.deleteWaybillByBagId
  );
  const deleteBagById = useAoneStore((state) => state?.deleteBagById);
  const deleteChuteConfigsById = useAoneStore(
    (state) => state?.deleteChuteConfigsById
  );
  const deleteChuteConfigsBulkById = useAoneStore(
    (state) => state?.deleteChuteConfigsBulkById
  );
  const [countryParam,setCountryParam] = useState("SAU")
  const countCourierTasks = async (courierId) => {
    const res = await countTasks(courierId);
    sessionStorage.setItem("assignedCount", res?.data?.data?.assigned);
    sessionStorage.setItem("scannedCount", res?.data?.data?.scanned);
  };
  const setSaveHandlerAction = useAoneStore((state) => state?.setSaveHandler);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const deleteAgentConfigs = useAoneStore((state) => state?.deleteAgentConfigs);
  const handleClose = () => {
    setDeleteHandler(false);
    setDeleteParams({ id: "", name: "", title: "" });
    setSaveHandlerAction(!saveHandler);
    confirmation.resetForm();
  };
  const confirmation = useFormik({
    initialValues: {
      value: "",
      confirm: `Delete ${deleteParams?.name}`,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value: Yup.string().test(
        "confirm-text",
        "Please enter the text exactly as displayed to confirm",
        (value) => {
          if (deleteParams?.title === "Chutes") {
            return true; // No validation for "Chutes"
          } else {
            return value === confirmation.values.confirm;
          }
        }
      ),
    }),
    onSubmit: async () => {
      if (deleteParams?.title == "Agent") {
        await deleteAgentConfigs(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Agent Deleted");
          }
        });
      } else if (deleteParams?.title == "Chute") {
        const zoneParams = `zoneCodes=${deleteParams?.id}`;
        await deleteChuteConfigsById(params?.id, zoneParams).then(
          (response) => {
            handleClose();
            if (response.status == "200") {
              toast.success("Chute Deleted");
            }
          }
        );
      } else if (deleteParams?.title == "Chutes") {
        const zoneParams = `zoneCodes=${deleteParams?.id}`;
        await deleteChuteConfigsById(params?.id, zoneParams).then(
          (response) => {
            handleClose();
            if (response.status === 200) {
              toast.success("Chutes Deleted");
            }
          }
        );
      } else if (deleteParams?.title == "Config") {
        await deleteChuteConfigs(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Config Deleted");
          }
        });
      } else if (deleteParams?.title == "Country") {
        await deleteCountryById(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title == "Region") {
        await deleteRegionById(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title == "City") {
        await deleteCityById(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title == "Localization") {
        await deleteLocalization(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title == "ACL Consignments") {
        await removeAclConsignments(deleteParams?.id, deleteParams?.name).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
              setispostAdded(!ispostAdded);
            }
          }
        );
      } else if (deleteParams?.title == "SMS Templates") {
        await deleteTemplates(deleteParams?.id, deleteParams?.name).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
              setispostAdded(!ispostAdded);
            }
          }
        );
      } else if (deleteParams?.title == "SMS Rules") {
        await deleteRules(deleteParams?.id, deleteParams?.name).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
              setispostAdded(!ispostAdded);
            }
          }
        );
      } else if (deleteParams?.title == "District") {
        await deleteDistrictById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("District Deleted");
          }
        });
      } else if (deleteParams?.title === "Thirdparty") {
        await deleteThirdparty(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Thirdparty Deleted");
          }
        });
      } else if (deleteParams?.title === "Mapping") {
        await deleteMapping(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Mapping Deleted");
          }
        });
      } else if (deleteParams?.title === "Bags") {
        await deleteBagById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Customers") {
        await deleteCustomer(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "CountriesMap") {
        await deleteCountryMappingById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "RegionsMap") {
        await deleteRegionMappingById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "CitiesMap") {
        await deleteCityMappingById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "PickupAddress") {
        await deletepickupaddressbyId(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "RTO") {
        await cancelRto(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Currency") {
        await deleteCurrencyById(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Tiers") {
        await deleteTiers(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "OD-Tiers") {
        await deleteOdTier(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Vehicles") {
        await deleteVehicles(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams?.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Vehicle Category") {
        await deleteVehicleCategory(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams?.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Vendors") {
        await deleteVehicleVendor(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams?.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "ShortAddress") {
        await deleteShortAddressbyId(countryParam,deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams?.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Odometer") {
        await deleteOdoMeter(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams?.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Gps vendors") {
        await deleteGpsvendor(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams?.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Vehicle Documents") {
        await deleteVehicleDocuments(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams?.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Currencyconversions") {
        await deleteCurrencyConversionById(deleteParams?.id).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
            }
          }
        );
      } else if (deleteParams?.title === "Products") {
        await deleteProducts(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Origins") {
        let paramslink = `?ProductCode=${params?.id}`;
        await deleteOrigin(params?.id, deleteParams?.id, paramslink).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
            }
          }
        );
      } else if (deleteParams?.title === "Transit Time") {
        let paramslink = `?ProductCode=${params?.id}`;
        await deleteTransittime(params?.id, deleteParams?.id, paramslink).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
            }
          }
        );
      } else if (deleteParams?.title === "Freight Rate") {
        await deleteFreight(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "RTO Rate") {
        await deleteRto(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "ODA Rate") {
        await deleteOda(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Delivery Validation") {
        await deleteDeliveryValidation(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            if (sessionStorage.getItem("SelectedCourier") !== null) {
              countCourierTasks(sessionStorage.getItem("SelectedCourier"));
              setispostAdded(!ispostAdded);
            }
          }
        });
      } else if (deleteParams?.title === "Routes") {
        await deleteRoutes(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "COD Rate") {
        await deleteCod(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Fuel Rate") {
        await deleteFuel(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Insurance Rate") {
        await deleteInsurance(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Attempts Rate") {
        await deleteAttempts(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Vat Rate") {
        await deleteVat(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Gpa Rate") {
        await deleteGpa(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Consignments") {
        await deletePickupConsignment(params?.id, deleteParams?.id).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
              setispostAdded(!ispostAdded);
            }
          }
        );
      } else if (deleteParams?.title === "Destinations") {
        let paramslink = `?ProductCode=${params?.id}`;
        await deleteDestination(params?.id, deleteParams?.id, paramslink).then(
          (response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else {
              toast.success(`${deleteParams.name} has been Deleted`);
            }
          }
        );
      } else if (deleteParams?.title === "Hubs") {
        await deleteHubs(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Employees") {
        await deleteEmployees(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Areas") {
        await deleteAreas(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Service Areas") {
        await deleteServiceareas(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "NPR") {
        await deletePickupReasonsById(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "NDR") {
        await deleteDeliveryReasonsById(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Drivers") {
        await deleteDrivers(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Trip") {
        await deleteTrips(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Delivery Flow") {
        await deleteDeliveryflowbyId(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
            setispostAdded(!ispostAdded);
          }
        });
      } else if (deleteParams?.title === "Linehauls") {
        await deleteLineHaulsbyId(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Hold Reasons") {
        await deleteHoldReasonsById(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "DistrictsMap") {
        await deleteDistrictMappingById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Zones") {
        await deleteZoneById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            setispostAdded(!ispostAdded);
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Zonesgroup") {
        await deleteZonegroupById(deleteParams?.id).then((response) => {
          handleClose();
          if (response.status == "200") {
            setispostAdded(!ispostAdded);
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title === "Waybill") {
        await deleteWaybillByBagId(
          deleteParamsId?.idTwo,
          deleteParamsId?.idOne
        ).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response.data.msg;
            const firstCommaIndex = errorMessage.indexOf(",");
            const toastMessage =
              firstCommaIndex !== -1
                ? errorMessage.substring(0, firstCommaIndex)
                : errorMessage;
            toast.error(toastMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title == "User") {
        await deleteUserById(deleteParams?.id).then((response)=> {
        handleClose();
        if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg.split(",")[0];
          toast.error(errorMessage);
        } else {
          toast.success(`${deleteParams.name} has been Deleted`);
        }
      });
      } else if (deleteParams?.title == "Role") {
        await deleteRoleById(deleteParams?.id).then((response)=> {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      } else if (deleteParams?.title == "Group") {
        await deleteGroupById(deleteParams?.id).then((response)=> {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      }
      handleClose();
    },
  });
  const confirmationRto = useFormik({
    initialValues: {
      value: "",
      confirm: `Cancel ${deleteParams?.name}`,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value: Yup.string().test(
        "confirm-text",
        "Please enter the text exactly as displayed to confirm",
        (value) => {
          if (deleteParams?.title === "Chutes") {
            return true; // No validation for "Chutes"
          } else {
            return value === confirmationRto.values.confirm;
          }
        }
      ),
    }),
    onSubmit: async () => {
      if (deleteParams?.title === "RTO") {
        await cancelRto(deleteParams?.id).then((response) => {
          handleClose();
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success(`${deleteParams.name} has been Deleted`);
          }
        });
      }
      handleClose();
    },
  });
  return (
    <>
      <Modal
        className="deleteAccpopup"
        show={deleteHandler}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <div className="popupheadinglogo">
            <img src={deleteIcon} alt="delete" />
          </div>

          <h2 className="fontSize20 fontWeight600 textAlignCenter blackText mb_8">
            {deleteParams?.title !== "RTO" ? (
              <>Delete {deleteParams?.title}?</>
            ) : (
              <>Cancel {deleteParams?.title}?</>
            )}
          </h2>
          {deleteParams?.title !== "Chutes" && (
            <>
              {deleteParams?.title && (
                <>
                  <p className="fontSize14 fontWeight400 blackText textAlignCenter mb_16">
                    The below resources will be affected:
                  </p>
                  <div className="displayFlex alignItemCenter justifyContent_center">
                    <ul className="emptyUnorderList mb_24">
                      {deleteParams?.title == "Agent" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Agent resources
                        </li>
                      ) : deleteParams?.title == "Chute" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Chute resources
                        </li>
                      ) : deleteParams?.title == "Country" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Deleting the country will delete related regions,
                          cities and districts. Do you want to continue?
                        </li>
                      ) : deleteParams?.title == "Region" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Region resources
                        </li>
                      ) : deleteParams?.title == "City" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - City resources
                        </li>
                      ) : deleteParams?.title == "District" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - District resources
                        </li>
                      ) : deleteParams?.title == "CountriesMap" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Country Mapping resources
                        </li>
                      ) : deleteParams?.title == "RegionsMap" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Region Mapping resources
                        </li>
                      ) : deleteParams?.title == "CitiesMap" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - City Mapping resources
                        </li>
                      ) : deleteParams?.title == "Currency" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Currency resources
                        </li>
                      ) : deleteParams?.title == "Currencyconversions" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Currency conversion resources
                        </li>
                           ) : deleteParams?.title == "Localization" ? (
                            <li className="fontSize14 fontWeight400 blackText ">
                              - Localization resources
                            </li>
                      ) : deleteParams?.title == "DistrictsMap" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - District Mapping resources
                        </li>
                      ) : deleteParams?.title == "Config" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Config resources
                          </li>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Chute resources
                          </li>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Agent resources
                          </li>
                        </>
                      ) : deleteParams?.title == "User" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - User resources
                        </li>
                      ) : deleteParams?.title == "Drivers" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Driver resources
                        </li>
                      ) : deleteParams?.title == "Trip" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Trip resources
                        </li>
                      ) : deleteParams?.title == "Tiers" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Tier resources
                        </li>
                      ) : deleteParams?.title == "Customers" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Customer resources
                        </li>
                      ) : deleteParams?.title == "Role" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Role resources
                          </li>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - User resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Hubs" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Hubs resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Vehicles" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Vehicle resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Odometer" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Odometer resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Gps vendors" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - GPS Vendors resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Vehicle Category" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Vehicle Category resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Vendors" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Vendor resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Vehicle Documents" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Vehicle Document resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Consignments" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Consignment resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Routes" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Routes resources
                          </li>
                        </>
                      ) : deleteParams?.title == "OD-Tiers" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - OD Pair resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Service Areas" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Service area resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Delivery Flow" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Delivery flow resources
                          </li>
                        </>
                      ) : deleteParams?.title == "NPR" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Pickup Reasons resources
                          </li>
                        </>
                      ) : deleteParams?.title == "NDR" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Delivery Reasons resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Delivery Validation" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Delivery Validation resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Linehauls" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Linehaul Reasons resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Hold Reasons" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Hold Reasons resources
                          </li>
                        </>
                      ) : deleteParams?.title == "PickupAddress" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Pickup Address resources
                          </li>
                        </>
                      ) : deleteParams?.title == "RTO" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Cancel RTO Request
                          </li>
                        </>
                      ) : deleteParams?.title == "Freight Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Freight Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "COD Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - COD Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "RTO Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - RTO Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "ODA Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - ODA Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Fuel Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Fuel Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "ACL Consignments" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Consignments in ACL
                          </li>
                        </>
                      ) : deleteParams?.title == "Insurance Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Insurance Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Attempts Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Attempts Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Vat Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Vat Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Gpa Rate" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - GPA Rate resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Products" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Products resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Destinations" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Destination resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Origins" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Origin resources
                          </li>
                        </>
                      ) : deleteParams?.title == "Transit Time" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Transit Time resources
                          </li>
                        </>
                      ) : deleteParams?.title === "Thirdparty" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Third Party
                          </li>
                        </>
                      ) : deleteParams?.title === "Mapping" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Mapping Config
                          </li>
                        </>
                      ) : deleteParams?.title === "Zones" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Zones resources
                          </li>
                        </>
                      ) : deleteParams?.title === "SMS Templates" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - SMS Template resources
                          </li>
                        </>
                      ) : deleteParams?.title === "SMS Rules" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - SMS Rules resources
                          </li>
                        </>
                      ) : deleteParams?.title === "Zonesgroup" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Zone Group resources
                          </li>
                        </>
                      ) : deleteParams?.title === "Bags" ? (
                        <li className="fontSize14 fontWeight400 blackText ">
                          - Bag
                        </li>
                      ) : deleteParams?.title === "Waybill" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Waybill
                          </li>
                        </>
                          ) : deleteParams?.title === "ShortAddress" ? (
                            <>
                              <li className="fontSize14 fontWeight400 blackText ">
                                - Short Address Mapping
                              </li>
                            </>
                      ) : deleteParams?.title === "Employees" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Employee resources
                          </li>
                        </>
                      ) : deleteParams?.title === "Areas" ? (
                        <>
                          <li className="fontSize14 fontWeight400 blackText ">
                            - Area resources
                          </li>
                        </>
                      ) : (
                        deleteParams?.title == "Group" && (
                          <>
                            <li className="fontSize14 fontWeight400 blackText ">
                              - Group resources
                            </li>
                            <li className="fontSize14 fontWeight400 blackText ">
                              - Role resources
                            </li>
                            <li className="fontSize14 fontWeight400 blackText ">
                              - User resources
                            </li>
                          </>
                        )
                      )}
                    </ul>
                  </div>
                </>
              )}
            </>
          )}

          {(deleteParams?.title !== "Chutes" && deleteParams?.title !== "RTO") && (
            <div>
                <p className="fontSize14 fontWeight400 blackText textAlignCenter mb_12">
                  Type "{confirmation.values.confirm}" to confirm
                </p>
              <input
                type="text"
                className="deleteAcc_input"
                placeholder=""
                style={{
                  border: confirmation?.errors?.value && "2px solid red",
                }}
                name="value"
                onChange={(e) =>
                  confirmation.setFieldValue("value", e.target.value)
                }
                onBlur={confirmation.handleBlur}
                value={confirmation.values.value}
              />
              {confirmation.touched.value && confirmation.errors.value && (
                <p
                  className="requiredText"
                  style={{ textAlign: "center", marginTop: "5px" }}
                >
                  {confirmation.errors.value}
                </p>
              )}
            </div>
          )}
           {( deleteParams?.title == "RTO" &&
            <div>
                <p className="fontSize14 fontWeight400 blackText textAlignCenter mb_12">
                  Type "{confirmationRto.values.confirm}" to confirm
                </p>
              <input
                type="text"
                className="deleteAcc_input"
                placeholder=""
                style={{
                  border: confirmationRto?.errors?.value && "2px solid red",
                }}
                name="value"
                onChange={(e) =>
                  confirmationRto.setFieldValue("value", e.target.value)
                }
                onBlur={confirmationRto.handleBlur}
                value={confirmationRto.values.value}
              />
              {confirmationRto.touched.value && confirmationRto.errors.value && (
                <p
                  className="requiredText"
                  style={{ textAlign: "center", marginTop: "5px" }}
                >
                  {confirmationRto.errors.value}
                </p>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ flexWrap: "unset" }}>
          <div>
            <button
              className="deletebtn cancelPrimary"
              onClick={() => {
                handleClose();
                if (setSelectAll !== undefined) {
                  setSelectAll(false);
                  setShowActions(false);
                  setselectZone([]);
                }
              }}
            >
              Cancel
            </button>
          </div>
          <div>
          {( deleteParams?.title !== "RTO" ?
            <button
              className="deletebtn confirmPrimary"
              onClick={confirmation.handleSubmit}
            >
              Confirm
            </button> :
            <button
            className="deletebtn confirmPrimary"
            onClick={confirmationRto.handleSubmit}
          >
            Confirm
          </button>)}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeletingModal;
