import React, { useState, useEffect, useContext } from "react";
import { useAoneStore } from "../../store/AoneStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
const Product = ({ setTab }) => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const postProducts = useAoneStore((state) => state?.postProducts);
  const getProductsbyId = useAoneStore((state) => state?.getProductsbyId);
  const editProducts = useAoneStore((state) => state?.editProducts);
  const editProductsbypatch = useAoneStore(
    (state) => state?.editProductsbypatch
  );
  const getProductsDimension = useAoneStore(
    (state) => state?.getProductsDimension
  );
  const getProductsEvent = useAoneStore((state) => state?.getProductsEvent);
  const getProductsWeight = useAoneStore((state) => state?.getProductsWeight);
  const getProductCategory = useAoneStore((state) => state?.getProductCategory);
  const getProductsContent = useAoneStore((state) => state?.getProductsContent);
  const setglobalproductCode = useAoneStore(
    (state) => state?.setglobalproductCode
  );
  const dimensionData = useAoneStore((state) => state?.dimensionData);
  const productEventData = useAoneStore((state) => state?.productEventData);
  const productWeightData = useAoneStore((state) => state?.productWeightData);
  const productCategoryData = useAoneStore(
    (state) => state?.productCategoryData
  );
  const productContentData = useAoneStore((state) => state?.productContentData);
  const [data, setData] = useState([]);
  const handleCancelClick = () => {
    navigate("/masters/products");
    setglobalproductCode("");
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isHide, isNotHide] = useState(false);
  //save account
  const addProduct = useFormik({
    initialValues: {
      code: params?.id ? data?.code : "",
      name: params?.id ? data?.name : "",
      weightUnit: params?.id ? data?.weightUnit : "",
      maxWeight: params?.id ? data?.maxWeight : "",
      minWeight: params?.id
        ? data?.minWeight !== undefined
          ? data.minWeight
          : 0
        : "",
      contentType: params?.id ? data?.contentType : "",
      dimensionUnit: params?.id ? data?.dimensionUnit || "" : "",
      maxHeight: params?.id ? data?.maxHeight : "",
      maxLength: params?.id ? data?.maxLength : "",
      maxWidth: params?.id ? data?.maxWidth : "",
      productCategory: params?.id ? data?.productCategory : "",
      changeProductEvent: params?.id ? data?.changeProductEvent : "",
      maxAttempts: params?.id ? data?.maxAttempts : "",
      rpu: params?.id
      ? data?.rpu !== undefined
        ? Boolean(data?.rpu)
        : false
      : false,
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/,
          "Code should only contain English characters"
        )
        .required("Code is required")
        .min(1, "Code must be at least 1 character long")
        .max(21, "Code must be at most 21 characters long"),
      name: Yup.string()
        .required("Name is required")
        .min(1, "Name must be at least 1 characters long")
        .max(50, "Name must be at most 50 characters long"),
      weightUnit: Yup.string()
        .trim()
        .required("Weight Unit is required")
        .min(1, "Weight Unit is required"),
      maxWeight: Yup.number()
        .required("Max weight is required")
        .when(["minWeight"], (minWeight, schema) =>
          schema.test({
            test: function (maxWeight) {
              if (minWeight && maxWeight) {
                return maxWeight > minWeight;
              }
              return true;
            },
            message: "MaxWeight must be greater than  Minweight",
          })
        ),
      contentType: Yup.string()
        .trim()
        .required("Content Type is required")
        .min(1, "Content Type is required"),
      maxHeight: Yup.number().typeError("Max height must be a number"),
      maxLength: Yup.number().typeError("Max length must be a number"),
      maxWidth: Yup.number().typeError("Max width must be a number"),
      maxAttempts: Yup.number()
        .typeError("Max attempts must be a number")
        .max(10, "Max attempts cannot be more than 10")
        .required("Max attempts is required"),
      productCategory: Yup.string().required("Product Category is required"),
      changeProductEvent: Yup.string().required(
        "Change Product Event is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const serializedValues = JSON.stringify(values);
      // Store the serialized values in sessionStorage
      sessionStorage.setItem("products", serializedValues);
      const valuesToSend = { ...values };
      if (valuesToSend.dimensionUnit === "") {
        valuesToSend.dimensionUnit = null;
      }
      if (valuesToSend.maxHeight === "" && valuesToSend.dimensionUnit === "") {
        valuesToSend.maxHeight = null;
      }
      if (valuesToSend.maxLength === "" && valuesToSend.dimensionUnit === "") {
        valuesToSend.maxLength = null;
      }
      if (valuesToSend.maxWidth === "" && valuesToSend.dimensionUnit === "") {
        valuesToSend.maxWidth = null;
      }
      setglobalproductCode(values?.code);
      const changedValues = Object.keys(valuesToSend).reduce((result, key) => {
        if (values[key] !== data[key]) {
          result[key] = values[key];
        }
        return result;
      }, {});
      if (params.id) {
        if (Object.keys(changedValues).length === 0) {
          toast.error("Make changes to update");
        } else {
          await editProducts(params?.id, valuesToSend).then((response) => {
            console.log("response--->", response);
            if (
              response?.data?.status == "ERROR" &&
              response?.data?.errors?.length < 1
            ) {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else if (response?.data?.errors?.length >= 1) {
              const errorMessages = response?.data?.errors
                .map((item) => `${item.key}: ${item.msg}`)
                .join(", ");
              toast.error(`${errorMessages}`);
            } else if (response?.response?.data?.errors?.length >= 1) {
              const errorMessages = response?.response?.data?.errors
                .map((item) => `${item.key}: ${item.msg}`)
                .join(", ");
              toast.error(`${errorMessages}`);
            } else if (response?.response?.data?.status === "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
            } else {
              toast.success("Product Updated");
              setTab(2);
              resetForm();
            }
          });
        }
      } else {
        await postProducts(valuesToSend).then((response) => {
          if (response.status == "201") {
            toast.success("Product Added");
            setTab(2);
            resetForm();
          } else if (
            response?.data?.status == "ERROR" &&
            response?.data?.errors?.length < 1
          ) {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.data?.errors?.length >= 1) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else if (response?.response?.data?.errors?.length >= 1) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  const maxAttemptsOptions = Array.from({ length: 10 }, (v, i) => {
    const value = i + 1;
    return { label: value.toString(), value: value.toString() };
  });
  const [dimensionUnitError, SetDimensionUnitError] = useState("");
  const [maxheightError, SetmaxHeightError] = useState("");
  const [maxlengthError, SetmaxLengthError] = useState("");
  const [maxweightError, SetmaxWidthError] = useState("");
  const [minWeightError, SetMinweightError] = useState("");
  const handleSaveProduct = (initialValues) => {
    if (
      addProduct.values.dimensionUnit !== "" &&
      addProduct.values.maxHeight === ""
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxHeight: "Max height is required",
      };
      addProduct?.setErrors(newErrors);
      SetmaxHeightError("Max height is required");
    } else if (
      addProduct.values.dimensionUnit !== "" &&
      addProduct.values.maxHeight <= 0
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxHeight: "Max height should not be 0 or below",
      };
      addProduct?.setErrors(newErrors);
      SetmaxHeightError("Max height should not be 0 or below");
    } else if (
      addProduct.values.dimensionUnit !== "" &&
      addProduct.values.maxLength === ""
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxLength: "Max length is required",
      };
      addProduct?.setErrors(newErrors);
      SetmaxLengthError("Max length is required");
    } else if (
      addProduct.values.dimensionUnit !== "" &&
      addProduct.values.maxLength <= 0
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxLength: "Max length should not be 0 or below",
      };
      addProduct?.setErrors(newErrors);
      SetmaxLengthError("Max length should not be 0 or below");
    } else if (
      addProduct.values.dimensionUnit !== "" &&
      addProduct.values.maxWidth === ""
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxWidth: "Max width is required",
      };
      SetmaxWidthError("Max width is required");
    } else if (
      addProduct.values.dimensionUnit !== "" &&
      addProduct.values.maxWidth <= 0
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxWidth: "Max width should not be 0 or below",
      };
      addProduct?.setErrors(newErrors);
      SetmaxWidthError("Max width should not be 0 or below");
    } else if (
      (addProduct.values.maxHeight !== "" ||
        addProduct.values.maxLength !== "" ||
        addProduct.values.maxWidth !== "") &&
      addProduct.values.dimensionUnit === ""
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        dimensionData: "Dimension Unit is required",
      };
      SetDimensionUnitError(
        "Dimension Unit is required if any of max height,max length or max width added"
      );
    } else if (
      addProduct.values.weightUnit === "GRAM" &&
      addProduct.values.maxWeight > 70000
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxWeight: "Max weight should not be greater than 70000 grams",
      };
      addProduct?.setErrors(newErrors);
      SetMinweightError("Max weight should not be greater than 70000 grams");
    } else if (
      addProduct.values.weightUnit === "KG" &&
      addProduct.values.maxWeight > 70
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxWeight: "Max weight should not be greater than 70 Kg",
      };
      addProduct?.setErrors(newErrors);
      SetMinweightError("Max weight should not be greater than 70 Kg");
    } else if (
      addProduct.values.weightUnit === "POUNDS" &&
      addProduct.values.maxWeight > 154
    ) {
      const existingErrors = { ...addProduct.errors };
      const newErrors = {
        ...existingErrors,
        maxWeight: "Max weight should not be greater than 154 Pounds",
      };
      addProduct?.setErrors(newErrors);
      SetMinweightError("Max weight should not be greater than 154 Pounds");
    } else {
      SetmaxHeightError("");
      SetmaxLengthError("");
      SetMinweightError("");
      SetmaxWidthError("");
      SetDimensionUnitError("");
      // Proceed with form submission or other actions
      addProduct?.handleSubmit();
    }
  };
  useEffect(() => {
    if (params?.id !== undefined) {
      getData();
      isNotHide(true);
    }
  }, [params?.id]);
  useEffect(() => {
    const storedProducts = sessionStorage.getItem("products");
    if (storedProducts) {
      const parsedProducts = JSON.parse(storedProducts);
      setData(parsedProducts);
    }
  }, []);
  useEffect(() => {
    if (!params.id) {
      addProduct.setFieldValue("cityCode", "");
    }
  }, [addProduct?.values?.countryIsoCode3]);
  useEffect(() => {
    const mappedFields = Object.keys(addProduct.values).reduce((acc, field) => {
      acc[field] = data?.[field] || "";
      return acc;
    }, {});
    // Set form field values based on data
    addProduct.setValues((prevValues) => ({
      ...prevValues,
      ...mappedFields,
    }));
  }, [data]);

  const getData = async () => {
    const response = await getProductsbyId(params.id);
    setData(response?.data?.data);
    setglobalproductCode(response?.data?.data?.code);
  };
  useEffect(() => {
    if (dimensionData?.data?.data === undefined) {
      getProductsDimension();
    }
    if (productEventData?.data?.data === undefined) {
      getProductsEvent();
    }
    if (productWeightData?.data?.data === undefined) {
      getProductsWeight();
    }
    if (productCategoryData?.data?.data === undefined) {
      getProductCategory();
    }
    if (productContentData?.data?.data === undefined) {
      getProductsContent();
    }
  }, []);
  return (
    <div className="row">
      <div className="col-md-4" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Code:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                placeholder="Code"
                className="mainInput"
                name="code"
                value={addProduct.values.code}
                disabled={params?.id !== undefined ?? true}
                onChange={(e) =>
                  addProduct.setFieldValue("code", e.target.value)
                }
              />
            </div>
            {addProduct.touched.code && addProduct.errors.code ? (
              <p className="requiredText">{addProduct.errors.code}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Weight Unit:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <SearchInput
                id="weightUnit"
                label="weightUnit"
                name="weightUnit"
                isClearable={true}
                options={productWeightData?.data?.data || []}
                getOptionLabel={(region) => region.label}
                getOptionValue={(region) => region.key}
                handleChange={(selectedOption) => {
                  addProduct.setFieldValue(
                    "weightUnit",
                    selectedOption ? selectedOption.key : ""
                  );
                  SetMinweightError("");
                }}
                customStyles={{ control: { width: "268px" } }}
                value={
                  productWeightData?.data?.data?.find(
                    (account) => account.key === addProduct.values.weightUnit
                  ) || null
                }
                isSearchable={true}
                placeholder="Weight Unit"
              />
            </div>
            {addProduct.touched.weightUnit && addProduct.errors.weightUnit ? (
              <p className="requiredText">{addProduct.errors.weightUnit}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="row">
            <div className="col-md-4">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Min Weight:
              </label>
              <div className="col-md-12">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="Min Weight"
                    className="mainInput"
                    name="minWeight"
                    value={addProduct.values.minWeight}
                    autoComplete="off"
                    onChange={(e) => {
                      addProduct.setFieldValue("minWeight", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mt_-5">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Max Weight:
                <span className="bloodDonortext fontWeight600 fontSize16">
                  *
                </span>
              </label>
              <div className="col-md-12">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="Max Weight"
                    className="mainInput"
                    name="maxWeight"
                    value={addProduct.values.maxWeight}
                    autoComplete="off"
                    onChange={(e) => {
                      addProduct.setFieldValue("maxWeight", e.target.value);
                    }}
                  />
                </div>
                {addProduct.touched.maxWeight && addProduct.errors.maxWeight ? (
                  <p className="requiredText">{addProduct.errors.maxWeight}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Dimension Unit:
          </label>
          <div className="col-md-8">
            <SearchInput
              id="dimensionUnit"
              label="dimensionUnit"
              name="dimensionUnit"
              isClearable={true}
              options={dimensionData?.data?.data || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) => {
                addProduct.setFieldValue(
                  "dimensionUnit",
                  selectedOption ? selectedOption.key : ""
                );
                SetDimensionUnitError("");
              }}
              customStyles={{ control: { width: "268px" } }}
              value={
                dimensionData?.data?.data?.find(
                  (account) => account.key === addProduct.values.dimensionUnit
                ) || null
              }
              isSearchable={true}
              placeholder="Dimension Unit"
            />
            {dimensionUnitError ? (
              <p className="requiredText">{dimensionUnitError}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="row">
            <div className="col-md-4">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Max Height:
              </label>
              <div className="w_110">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="Max Height"
                    className="mainInput"
                    maxLength={3}
                    name="maxHeight"
                    value={addProduct.values.maxHeight}
                    onChange={(e) => {
                      addProduct.setFieldValue("maxHeight", e.target.value);
                      SetmaxHeightError("");
                    }}
                  />
                </div>
                {addProduct.touched.maxHeight &&
                addProduct.errors.maxHeight &&
                !maxheightError ? (
                  <p className="requiredText">{addProduct.errors.maxHeight}</p>
                ) : null}
                {maxheightError ? (
                  <p className="requiredText">{maxheightError}</p>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Max Length:
              </label>
              <div className="w_110">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="Max Length"
                    className="mainInput"
                    maxLength={3}
                    name="maxLength"
                    value={addProduct.values.maxLength}
                    onChange={(e) => {
                      addProduct.setFieldValue("maxLength", e.target.value);
                      SetmaxLengthError("");
                    }}
                  />
                </div>
                {addProduct.touched.maxLength &&
                addProduct.errors.maxLength &&
                !maxlengthError ? (
                  <p className="requiredText">{addProduct.errors.maxLength}</p>
                ) : null}
                {maxlengthError ? (
                  <p className="requiredText">{maxlengthError}</p>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Max Width:
              </label>
              <div className="w_110">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="Max Width"
                    className="mainInput"
                    maxLength={3}
                    name="maxWidth"
                    value={addProduct.values.maxWidth}
                    onChange={(e) => {
                      addProduct.setFieldValue("maxWidth", e.target.value);
                      SetmaxWidthError("");
                    }}
                  />
                </div>
                {addProduct.touched.maxWidth &&
                addProduct.errors.maxWidth &&
                !maxweightError ? (
                  <p className="requiredText">{addProduct.errors.maxWidth}</p>
                ) : null}
                {maxweightError ? (
                  <p className="requiredText">{maxweightError}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Content type:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <SearchInput
              id="contentType"
              label="contentType"
              name="contentType"
              isClearable={true}
              options={productContentData?.data?.data || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) =>
                addProduct.setFieldValue(
                  "contentType",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                productContentData?.data?.data?.find(
                  (account) => account.key === addProduct.values.contentType
                ) || null
              }
              isSearchable={true}
              placeholder="Content type"
            />
            {addProduct.touched.contentType && addProduct.errors.contentType ? (
              <p className="requiredText">{addProduct.errors.contentType}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-md-5" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Name:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-7">
            <div className="Grpinput">
              <input
                type="text"
                placeholder="Name"
                className="mainInput"
                name="name"
                value={addProduct.values.name}
                onChange={(e) =>
                  addProduct.setFieldValue("name", e.target.value)
                }
                autoComplete="off"
              />
            </div>
            {addProduct.touched.name && addProduct.errors.name ? (
              <p className="requiredText">{addProduct.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Product Category:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-7">
            <SearchInput
              id="productCategory"
              label="productCategory"
              name="productCategory"
              isClearable={true}
              options={productCategoryData?.data?.data || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) =>
                addProduct.setFieldValue(
                  "productCategory",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                productCategoryData?.data?.data?.find(
                  (account) => account.key === addProduct.values.productCategory
                ) || null
              }
              isSearchable={true}
              placeholder="Product Category"
            />
            {addProduct.touched.productCategory &&
            addProduct.errors.productCategory ? (
              <p className="requiredText">
                {addProduct.errors.productCategory}
              </p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Change Product Event:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-7">
            <SearchInput
              id="changeProductEvent"
              label="changeProductEvent"
              name="changeProductEvent"
              isClearable={true}
              options={productEventData?.data?.data || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) =>
                addProduct.setFieldValue(
                  "changeProductEvent",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                productEventData?.data?.data?.find(
                  (account) =>
                    account.key === addProduct.values.changeProductEvent
                ) || null
              }
              isSearchable={true}
              placeholder="Change Product Event"
            />
            {addProduct.touched.changeProductEvent &&
            addProduct.errors.changeProductEvent ? (
              <p className="requiredText">
                {addProduct.errors.changeProductEvent}
              </p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Max attempts:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-4">
            <div className="Grpinput">
            <SearchInput
      id="maxAttempts"
      label="Max Attempts"
      name="maxAttempts"
      options={maxAttemptsOptions}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      value={
        addProduct.values.maxAttempts
          ? maxAttemptsOptions.find(
              (option) => option.value == addProduct.values.maxAttempts
            )
          : null
      }
      handleChange={(selectedOption) => {
        // Update form field when an option is selected
        addProduct.setFieldValue(
          "maxAttempts",
          selectedOption ? selectedOption.value : ""
        );
      }}
      isClearable={true}
      isSearchable={true}
      placeholder="Max Attempts"
    />
            </div>
            {addProduct.touched.maxAttempts && addProduct.errors.maxAttempts ? (
              <p className="requiredText">{addProduct.errors.maxAttempts}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-8">
          <div className="smallCheckbox">
                        <div className="fontSize14 menacingCloudsText fontWeight500 mt_8">
                          <input
                            type="checkbox"
                            id="rpu"
                            style={{ marginLeft: "5px" }}
                            checked={
                              addProduct.values
                                .rpu
                            }
                            onChange={() => {
                              addProduct.setFieldValue(
                                "rpu",
                                !addProduct.values
                                  .rpu
                              );
                              if (
                                !addProduct.values
                                  .rpu
                              ) {
                                addProduct.setFieldValue(
                                  "rpu",
                                  true
                                );
                              }
                            }}
                          />
                          <label
                            className="margintop7"
                            htmlFor="rpu"
                          >
                              Reverse Pickup
                          </label>
                        </div>
                      </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
        {(roleHandler(role, "AONE_MASTERS.write") ||
          roleHandler(role, "AONE_MASTERS.admin") ||
          roleHandler(role, "AONE_MASTERS_PRODUCT.admin") ||
          roleHandler(role, "AONE_MASTERS_PRODUCT.write")) && (
          <button
            type="button"
            className="blue_btn"
            onClick={handleSaveProduct}
          >
            Save
          </button>
        )}
        <button onClick={handleCancelClick} className="cancel_btn">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Product;
