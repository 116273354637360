import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import { useFormik } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
import editIcon from "../../../src/assets/img/EditingIcon.svg";
import removeIcon from "../../../src/assets/img/cancelIcon.svg";
const Orders = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [title, sertTitle] = useState("");
  const [data, setData] = useState("");
  const [pagecustomer, setPageCustomer] = useState(-1);
  const [page, setPage] = useState(-1);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleCancel = () => {
    handleClose();
  };
  const addPackage = (event) => {
    event.preventDefault();
    setShow(true);
  };
  const [showItem, setShowItem] = useState(false);
  const handleShowItem = () => setShowItem(true);
  const handleCloseItem = () => {
    setShowItem(false);
  };
  const handleCancelItem = () => {
    handleClose();
  };
  const addItem = (event) => {
    event.preventDefault();
    setShowItem(true);
  };
  const [loadingMore, setLoadingMore] = useState(false);
  const postConsignmentOrders = useAoneStore(
    (state) => state?.postConsignmentOrders
  );
  const getCustomer = useAoneStore((state) => state?.getCustomer);
  const getProducts = useAoneStore((state) => state?.getProducts);
  const editConsignmentOrders = useAoneStore(
    (state) => state?.editConsignmentOrders
  );
  const [originCountries, setOriginCounties] = useState([]);
  const [destinationCountries, setDestinationCountries] = useState([]);
  const getDestination = useAoneStore((state) => state?.getDestination);
  const setDestinationData = useAoneStore((state) => state?.setDestinationData);
  const setOriginData = useAoneStore((state) => state?.setOriginData);
  const setcustomerAccount = useAoneStore((state) => state?.setcustomerAccount);
  const getRegionbyCode = useAoneStore((state) => state?.getRegionbyCode);
  const getRegionbyCodeDes = useAoneStore((state) => state?.getRegionbyCodeDes);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const getCountryArray = useAoneStore((state) => state?.getCountryArray);
  const getCitybyCode = useAoneStore((state) => state?.getCitybyCode);
  const getCitybyCodeDes = useAoneStore((state) => state?.getCitybyCodeDes);
  const getDistrictbyCode = useAoneStore((state) => state?.getDistrictbyCode);
  const getProductsbyId = useAoneStore((state) => state?.getProductsbyId);
  const countryArrayData = useAoneStore((state) => state?.countryArrayData);
  const getOrigin = useAoneStore((state) => state?.getOrigin);
  const getDistrictDesbyCode = useAoneStore(
    (state) => state?.getDistrictDesbyCode
  );
  const getOrderAddressType = useAoneStore(
    (state) => state?.getOrderAddressType
  );
  const getOrderContentType = useAoneStore(
    (state) => state?.getOrderContentType
  );
  const getOrderDimensionUnit = useAoneStore(
    (state) => state?.getOrderDimensionUnit
  );
  const getOrderIncoterms = useAoneStore((state) => state?.getOrderIncoterms);
  const getOrderPickupMethod = useAoneStore(
    (state) => state?.getOrderPickupMethod
  );
  const [productCategory, setProductCategory] = useState("");
  const [productContentType, setProductContentType] = useState("");
  const getOrderWeightUnit = useAoneStore((state) => state?.getOrderWeightUnit);
  const getCurrency = useAoneStore((state) => state?.getCurrency);
  const citybyCode = useAoneStore((state) => state?.citybyCode);
  const citybyCodeDes = useAoneStore((state) => state?.citybyCodeDes);
  const districtbyDescode = useAoneStore((state) => state?.districtbyDescode);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const districtbyCode = useAoneStore((state) => state?.districtbyCode);
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const regionbyCodeDes = useAoneStore((state) => state?.regionbyCodeDes);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const orderContentType = useAoneStore((state) => state?.orderContentType);
  const orderIncoterms = useAoneStore((state) => state?.orderIncoterms);
  const orderWeightUnit = useAoneStore((state) => state?.orderWeightUnit);
  const orderDimensionUnit = useAoneStore((state) => state?.orderDimensionUnit);
  const currencyData = useAoneStore((state) => state?.currencyData);
  const orderAddressType = useAoneStore((state) => state?.orderAddressType);
  const orderPickupMethod = useAoneStore((state) => state?.orderPickupMethod);
  const getCountryByCityOrders = useAoneStore(
    (state) => state?.getCountryByCityOrders
  );
  const getCountryByCityOrdersDes = useAoneStore(
    (state) => state?.getCountryByCityOrdersDes
  );
  const printBulkConsignments = useAoneStore(
    (state) => state?.printBulkConsignments
  );
  const handleRegionChange = async (selectedOption, form) => {
    addOrderManagement.setFieldValue(
      "pickupAddress.regionCode",
      selectedOption ? selectedOption.code : ""
    );
    const regionCode = selectedOption.name; // Use the correct property for the region code
    // Call your API with isoCode3
    try {
      const cityData = await getCitybyCode(
        selectedOption.countryIsoCode3,
        selectedOption.code
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  const handleRegionDeliveryChange = async (selectedOption, form) => {
    if (selectedOption === null) {
      addOrderManagement.setFieldValue("deliveryAddress?.regionCode", "");
    } else {
      addOrderManagement.setFieldValue(
        "deliveryAddress.regionCode",
        selectedOption ? selectedOption.code : ""
      );
      const regionCode = selectedOption.name; // Use the correct property for the region code
      form.setFieldValue("regionName", regionCode);
      // Call your API with isoCode3
      try {
        const cityData = await getCitybyCodeDes(
          selectedOption.countryIsoCode3,
          selectedOption.code
        );
      } catch (error) {
        console.error("Error fetching region data:", error);
      }
    }
  };
  const handleCtyChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    addOrderManagement.setFieldValue(
      "pickupAddress.cityCode",
      selectedOption ? selectedOption.code : ""
    );
    try {
      const districtData = await getDistrictbyCode(
        selectedOption.countryIsoCode3,
        selectedOption.regionCode,
        selectedOption.code
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  const handleCtyDesChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    addOrderManagement.setFieldValue(
      "deliveryAddress.cityCode",
      selectedOption ? selectedOption.code : ""
    );
    try {
      const districtData = await getDistrictDesbyCode(
        selectedOption.countryIsoCode3,
        selectedOption.regionCode,
        selectedOption.code
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  const handleDistrictChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    addOrderManagement.setFieldValue(
      "pickupAddress.districtCode",
      selectedOption ? selectedOption.code : ""
    );
  };
  const handleDeliveryDistrictChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    addOrderManagement.setFieldValue(
      "deliveryAddress.districtCode",
      selectedOption ? selectedOption.code : ""
    );
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : pagecustomer + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPageCustomer(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await getCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, pagecustomer]
  );
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countryArrayData?.data?.data?.content?.length < 1 ||
      countryArrayData?.data?.data?.content?.length == undefined
    ) {
      getCountryArray(paramslink);
    }
    if (
      currencyData?.data?.data?.content?.length < 1 ||
      currencyData?.data?.data?.content?.length == undefined
    ) {
      getCurrency(paramslink);
    }
    if (
      orderAddressType?.data?.data?.length < 1 ||
      orderAddressType?.data?.data?.length == undefined
    ) {
      getOrderAddressType();
    }
    if (
      orderContentType?.data?.data?.length < 1 ||
      orderContentType?.data?.data?.length == undefined
    ) {
      getOrderContentType();
    }
    if (
      orderDimensionUnit?.data?.data?.length < 1 ||
      orderDimensionUnit?.data?.data?.length == undefined
    ) {
      getOrderDimensionUnit();
    }
    if (
      orderIncoterms?.data?.data?.length < 1 ||
      orderIncoterms?.data?.data?.length == undefined
    ) {
      getOrderIncoterms();
    }
    if (
      orderPickupMethod?.data?.data?.length < 1 ||
      orderPickupMethod?.data?.data?.length == undefined
    ) {
      getOrderPickupMethod();
    }
    if (
      orderWeightUnit?.data?.data?.length < 1 ||
      orderWeightUnit?.data?.data?.length == undefined
    ) {
      getOrderWeightUnit();
    }
  }, []);
  const addOrderManagement = useFormik({
    initialValues: {
      referenceNumber: title === "Edit Orders" ? data?.referenceNumber : "",
      customerAccount: title === "Edit Orders" ? data?.customerAccount : "",
      productCode: title === "Edit Orders" ? data?.productCode : "",
      contentType: title === "Edit Orders" ? data?.contentType : "",
      pickupAddress: {
        name: title === "Edit Orders" ? data.pickupAddress.name : "",
        alias: title === "Edit Orders" ? data.pickupAddress.alias : "",
        phone: title === "Edit Orders" ? data.pickupAddress.phone : "",
        countryCode:
          title === "Edit Orders" ? data.pickupAddress.countryCode : "",
        cityCode: title === "Edit Orders" ? data.pickupAddress.cityCode : "",
        regionCode:
          title === "Edit Orders" ? data.pickupAddress.regionCode : "",
        districtCode:
          title === "Edit Orders" ? data.pickupAddress.districtCode : "",
        addressLine1:
          title === "Edit Orders" ? data.pickupAddress.addressLine1 : "",
        addressLine2:
          title === "Edit Orders" ? data.pickupAddress.addressLine2 : "",
        longitude: title === "Edit Orders" ? data.pickupAddress.longitude : "",
        latitude: title === "Edit Orders" ? data.pickupAddress.latitude : "",
        email: title === "Edit Orders" ? data.pickupAddress.email : "",
        alternatePhone:
          title === "Edit Orders" ? data.pickupAddress.alternatePhone : "",
        postalCode:
          title === "Edit Orders" ? data.pickupAddress.postalCode : "",
        shortAddress:
          title === "Edit Orders"
            ? data.pickupAddress.shortAddress
            : "",
      },
      deliveryAddress: {
        name: title === "Edit Orders" ? data.deliveryAddress.name : "",
        phone: title === "Edit Orders" ? data.deliveryAddress.phone : "",
        alternatePhone:
          title === "Edit Orders" ? data.deliveryAddress.alternatePhone : "",
        countryCode:
          title === "Edit Orders" ? data.deliveryAddress.countryCode : "",
        cityCode: title === "Edit Orders" ? data.deliveryAddress.cityCode : "",
        regionCode:
          title === "Edit Orders" ? data.deliveryAddress.regionCode : "",
        districtCode:
          title === "Edit Orders" ? data.deliveryAddress.districtCode : "",
        postalCode:
          title === "Edit Orders" ? data.deliveryAddress.postalCode : "",
        addressLine1:
          title === "Edit Orders" ? data.deliveryAddress.addressLine1 : "",
        addressLine2:
          title === "Edit Orders" ? data.deliveryAddressaddressLine2 : "",
        longitude:
          title === "Edit Orders" ? data.deliveryAddress.longitude : "",
        latitude: title === "Edit Orders" ? data.deliveryAddress.latitude : "",
        email: title === "Edit Orders" ? data.deliveryAddress.email : "",
        shortAddress:
          title === "Edit Orders"
            ? data.deliveryAddress.shortAddress
            : "",
      },
      packages: [],
      items: [],
      declaredValue: title === "Edit Orders" ? data?.declaredValue : "",
      declaredValueCurrency:
        title === "Edit Orders" ? data?.declaredValueCurrency : "",
      cod: title === "Edit Orders" ? data?.cod : false,
      codAmount: title === "Edit Orders" ? data?.codAmount : "",
      incoterms: title === "Edit Orders" ? data?.incoterms : "",
      insured: title === "Edit Orders" ? data?.insured : false,
      note: title === "Edit Orders" ? data?.note : "",
      content: title === "Edit Orders" ? data?.content : "",
    },
    validationSchema: Yup.object({
      referenceNumber: Yup.string()
        .matches(/^[^\s]+$/, "Reference Number cannot contain spaces"),
      customerAccount: Yup.string().required("Customer Account is required"),
      productCode: Yup.string().required("Product Code is required"),
      contentType: Yup.string().required("Content Type is required"),
      declaredValue: Yup.number()
        .required("Declared Value is required")
        .integer("Declared Value must be an integer")
        .min(0, "Declared Value must be a non-negative integer")
        .positive("Declared Value must be a positive integer")
        .typeError("Declared Value must be a number"),
      declaredValueCurrency: Yup.string().required(
        "Declared Value Currency is required"
      ),
      incoterms: Yup.string().required("Incoterms is required"),
      pickupAddress: Yup.object().shape({
        name: Yup.string()
          .required("Shipper name is required")
          .matches(
            /^\S(?:.*\S)?$/,
            "Shipper name must not have spaces before or after"
          )
          .matches(/^[^\d]*$/, "Shipper name must not contain numbers")
          .matches(
            /^[a-zA-Z\s]*$/,
            "Shipper name must not contain special characters"
          )
          .max(50, "Shipper name must not be more than 50 characters long")
          .min(2, "Shipper name must be at least 2 characters long"),
        phone: Yup.string()
          .required("Phone is required")
          .matches(/^[^\s]+$/, "Phone cannot contain spaces")
          .matches(/^[0-9+]+$/, "Phone must contain only numbers"),
        alternatePhone: Yup.string()
          .matches(/^[^\s]+$/, "Alternate Phone cannot contain spaces")
          .matches(/^[0-9+]+$/, "Alternate Phone must contain only numbers"),
        countryCode: Yup.string().required("Country is required"),
        cityCode: Yup.string().required("City is required"),
        postalCode: Yup.number()
          .integer("Postal code must be an integer")
          .min(0, "Postal code must be a non-negative integer")
          .positive("Postal code must be a positive integer")
          .typeError("Postal code must be a number"),
        addressLine1: Yup.string().required("Address Line 1 is required"),
        latitude: Yup.number().typeError("Latitude must be a number"),
        longitude: Yup.number().typeError("Longitude must be a number"),
        email: Yup.string()
          .email("Invalid email address"),
      }),
      deliveryAddress: Yup.object().shape({
        name: Yup.string()
          .required("Receiver name is required")
          .matches(
            /^\S(?:.*\S)?$/,
            "Receiver name must not have spaces before or after"
          )
          .matches(/^[^\d]*$/, "Receiver name must not contain numbers")
          .matches(
            /^[a-zA-Z\s]*$/,
            "Receiver name must not contain special characters"
          )
          .max(50, "Receiver name must not be more than 50 characters long")
          .min(2, "Receiver name must be at least 2 characters long"),
        phone: Yup.string()
          .required("Phone is required")
          .matches(/^[^\s]+$/, "Phone cannot contain spaces")
          .matches(/^[0-9+]+$/, "Phone must contain only numbers"),
        alternatePhone: Yup.string()
          .matches(/^[^\s]+$/, "Alternate Phone cannot contain spaces")
          .matches(/^[0-9+]+$/, "Alternate Phone must contain only numbers"),
        countryCode: Yup.string().required("Country is required"),
        cityCode: Yup.string().required("City is required"),
        postalCode: Yup.number()
          .integer("Postal code must be an integer")
          .min(0, "Postal code must be a non-negative integer")
          .positive("Postal code must be a positive integer")
          .typeError("Postal code must be a number"),
        addressLine1: Yup.string().required("Address Line 1 is required"),
        latitude: Yup.number().typeError("Latitude must be a number"),
        longitude: Yup.number().typeError("Longitude must be a number"),
        email: Yup.string()
          .email("Invalid email address"),
      }),
      codAmount: Yup.number().typeError(
        "Cash on delivery amount must be a number"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const originalValues = {
        pickupPhone: addOrderManagement.values.pickupAddress.phone,
        pickupAlternatePhone: addOrderManagement.values.pickupAddress.alternatePhone,
        deliveryPhone: addOrderManagement.values.deliveryAddress.phone,
        deliveryAlternatePhone: addOrderManagement.values.deliveryAddress.alternatePhone
      };
      const valuestoSend = { ...values };
      valuestoSend.pickupAddress.phone = addOrderManagement.values.phonecodeprefix + addOrderManagement.values.pickupAddress.phone;
      if (addOrderManagement.values.pickupAddress.alternatePhone !== "") {
        valuestoSend.pickupAddress.alternatePhone = addOrderManagement.values.phonecodeprefix + addOrderManagement.values.pickupAddress.alternatePhone;
      } else {
        valuestoSend.pickupAddress.alternatePhone = addOrderManagement.values.pickupAddress.alternatePhone;
      }
      valuestoSend.deliveryAddress.phone = addOrderManagement.values.phonecodedesprefix + addOrderManagement.values.deliveryAddress.phone;
      if (addOrderManagement.values.pickupAddress.alternatePhone !== "") {
        valuestoSend.deliveryAddress.alternatePhone = addOrderManagement.values.phonecodeprefix + addOrderManagement.values.deliveryAddress.alternatePhone;
      } else {
        valuestoSend.deliveryAddress.alternatePhone = addOrderManagement.values.deliveryAddress.alternatePhone;
      }
      if (title === "Edit Orders") {
        await editConsignmentOrders(data.id, valuestoSend).then((response) => {
          if (response.status == 200) {
            toast.success("Consignment Updated");
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postConsignmentOrders(valuestoSend).then((response) => {
          if (response.status == "201") {
            toast.success("Consignment Created");
            resetForm();
            navigate("/consignments/orders");
          } else if (response?.data?.errorCode == "500") {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.data?.errorCode !== "" && response?.data?.errorCode !== undefined) {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.data?.responseCode !== "" && response?.response?.data?.data?.responseCode !== undefined) {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessage = response?.response?.data?.data?.responseMessage;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errors?.length >= 1) {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key} ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.data?.data?.responseCode !== "" && response?.response?.data?.data?.responseCode !== undefined) {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessage =
              response?.response?.data?.data?.responseMessage;
            toast.error(errorMessage);
          } else if (response?.data?.responseCode !== "" && response?.data?.responseCode !== undefined) {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessage =
              response?.data?.responseMessage;
            toast.error(errorMessage);
          } else if (response?.data?.data?.responseCode !== "" && response?.data?.data?.responseCode !== undefined) {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessage =
              response?.data?.data?.responseMessage.split(",")[0];
            toast.error(errorMessage);
          } else {
            addOrderManagement.values.pickupAddress.phone = originalValues.pickupPhone;
            addOrderManagement.values.pickupAddress.alternatePhone = originalValues.pickupAlternatePhone;
            addOrderManagement.values.deliveryAddress.phone = originalValues.deliveryPhone;
            addOrderManagement.values.deliveryAddress.alternatePhone = originalValues.deliveryAlternatePhone;
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  const [productMaxWeight, setProductMaxWeight] = useState("");
  useEffect(() => {
    addOrderManagement.setFieldValue("pickupAddress.regionCode", "");
    addOrderManagement.setFieldValue("pickupAddress.cityCode", "");
    addOrderManagement.setFieldValue("pickupAddress.districtCode", "");
  }, [addOrderManagement.values.pickupAddress.countryCode]);
  useEffect(() => {
    addOrderManagement.setFieldValue("pickupAddress.cityCode", "");
    addOrderManagement.setFieldValue("pickupAddress.districtCode", "");
  }, [addOrderManagement.values.pickupAddress.regionCode]);
  useEffect(() => {
    addOrderManagement.setFieldValue("pickupAddress.districtCode", "");
  }, [addOrderManagement.values.pickupAddress.cityCode]);
  useEffect(() => {
    addOrderManagement.setFieldValue("deliveryAddress.regionCode", "");
    addOrderManagement.setFieldValue("deliveryAddress.cityCode", "");
    addOrderManagement.setFieldValue("deliveryAddress.districtCode", "");
  }, [addOrderManagement.values.deliveryAddress.countryCode]);
  useEffect(() => {
    addOrderManagement.setFieldValue("deliveryAddress.cityCode", "");
    addOrderManagement.setFieldValue("deliveryAddress.districtCode", "");
  }, [addOrderManagement.values.deliveryAddress.regionCode]);
  useEffect(() => {
    addOrderManagement.setFieldValue("deliveryAddress.districtCode", "");
  }, [addOrderManagement.values.deliveryAddress.cityCode]);
  //phone regex validation for pickup
  const [error, setError] = useState(null);
  const [phoneNumberRegex, setphoneNumberRegex] = useState("");
  const [alternatePhoneerror, setAlternatePhoneError] = useState(null);
  const [alternatephoneNumberRegex, setAlternatephoneNumberRegex] =
    useState("");
  useEffect(() => {
    if (phoneNumberRegex === null) {
      setError("");
      return;
    }
    const regex = new RegExp(phoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addOrderManagement.values.pickupAddress.phone &&
      !regex.test(addOrderManagement.values.phonecodeprefix + addOrderManagement.values.pickupAddress.phone)
    ) {
      // If it doesn't match, set the error message
      setError("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setError("");
    }
  }, [addOrderManagement.values.pickupAddress.phone, phoneNumberRegex,addOrderManagement.values.phonecodeprefix]);
  const getOriginCountry = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
      // Check if the current countryCode from addOrderManagement is in the uniqueCountries array
      const currentCountryCode =
        addOrderManagement.values.pickupAddress.countryCode;
      const isCountryCodeValid = uniqueCountries.some(
        (country) => country.countryCode === currentCountryCode
      );
      if (!isCountryCodeValid) {
        // If the current countryCode is not found, reset the countryCode field to empty
        addOrderManagement.setFieldValue("pickupAddress.countryCode", "");
      }
    } else {
      // If no origin content is found, also reset the countryCode
      addOrderManagement.setFieldValue("pickupAddress.countryCode", "");
    }
    // Fetch destination data
    const response = await getDestination(productCode, paramslink);
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
      // Check if the current countryCode from addOrderManagement is in the uniqueCountries array
      const currentCountryCodeDes =
        addOrderManagement.values.deliveryAddress.countryCode;
      const isCountryCodeValid = uniqueDesCountries.some(
        (country) => country.countryCode === currentCountryCodeDes
      );

      if (!isCountryCodeValid) {
        // If the current countryCode is not found, reset the countryCode field to empty
        addOrderManagement.setFieldValue("deliveryAddress.countryCode", "");
      }
    } else {
      // If no origin content is found, also reset the countryCode
      addOrderManagement.setFieldValue("deliveryAddress.countryCode", "");
    }
  };
  useEffect(() => {
    if (alternatephoneNumberRegex === null) {
      setAlternatePhoneError("");
      return;
    }
    const regex = new RegExp(alternatephoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addOrderManagement.values.pickupAddress.alternatePhone &&
      !regex.test(addOrderManagement.values.phonecodeprefix + addOrderManagement.values.pickupAddress.alternatePhone)
    ) {
      // If it doesn't match, set the error message
      setAlternatePhoneError("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setAlternatePhoneError("");
    }
  }, [
    addOrderManagement.values.pickupAddress.alternatePhone,
    alternatephoneNumberRegex,
  ]);
  //phone regex validation for delivery
  const [phoneNumberDesRegex, setphoneNumberDesRegex] = useState("");
  const [errorPhone, setErrorPhone] = useState(null);
  const [altphoneNumberDesRegex, setAltphoneNumberDesRegex] = useState("");
  const [errorAltPhone, setErrorAltPhone] = useState(null);
  useEffect(() => {
    if (phoneNumberDesRegex === null) {
      setErrorPhone("");
      return;
    }
    const regex = new RegExp(phoneNumberDesRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addOrderManagement.values.deliveryAddress.phone &&
      !regex.test(addOrderManagement.values.phonecodedesprefix + addOrderManagement.values.deliveryAddress.phone)
    ) {
      // If it doesn't match, set the error message
      setErrorPhone("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setErrorPhone("");
    }
  }, [addOrderManagement.values.deliveryAddress.phone, phoneNumberDesRegex]);
  useEffect(() => {
    if (altphoneNumberDesRegex === null) {
      setErrorAltPhone("");
      return;
    }
    const regex = new RegExp(altphoneNumberDesRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addOrderManagement.values.deliveryAddress.alternatePhone &&
      !regex.test(addOrderManagement.values.phonecodedesprefix + addOrderManagement.values.deliveryAddress.alternatePhone)
    ) {
      // If it doesn't match, set the error message
      setErrorAltPhone("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setErrorAltPhone("");
    }
  }, [
    addOrderManagement.values.deliveryAddress.alternatePhone,
    altphoneNumberDesRegex,
  ]);
  const [isPhonecodePrefix, setIsPhonecodeprefix] = useState(false);
  const [pickupPostalErr, setPickuppostalErr] = useState("");
  const [deliveryPostaErr, setDeliveryPostalErr] = useState("");
  const [emailPickupErr, setEmailPickupErr] = useState("");
  const [emailDeliveryupErr, setEmailDeliveryErr] = useState("");
  const [codError, setCodErr] = useState("");
  const handleSaveOrderMgt = (initialValues) => {
    if (/\s/g.test(addOrderManagement.values.pickupAddress.postalCode)) {
      setPickuppostalErr(
        "Postal Code cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (
      /\s/g.test(addOrderManagement.values.deliveryAddress.postalCode)
    ) {
      setDeliveryPostalErr(
        "Postal Code cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addOrderManagement.values.pickupAddress.email)) {
      setEmailPickupErr(
        "Email cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addOrderManagement.values.deliveryAddress.email)) {
      setEmailDeliveryErr(
        "Email cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (
      addOrderManagement.values.cod &&
      addOrderManagement.values.codAmount === ""
    ) {
      setCodErr("Cash on delivery amount is required");
    } else {
      setPickuppostalErr("");
      setDeliveryPostalErr("");
      setEmailPickupErr("");
      setEmailDeliveryErr("");
      addOrderManagement?.handleSubmit();
    }
  };
  const [selectedOptionProduct, setSelectedOptionProduct] = useState(null);
  const loadMoreProductsOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await getProducts(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  const [editPackageValues, setEditPackageValues] = useState(null);
  const handleEditPackage = (packageData) => {
    // Set the values of the clicked package in the editPackageValues state
    setEditPackageValues(packageData);
    // Show the modal
    setShow(true);
  };
  const addPackageForm = useFormik({
    initialValues: {
      weightUnit: "",
      weight: "",
      dimensionUnit: "",
      height: "",
      width: "",
      length: "",
    },
    validationSchema: Yup.object().shape({
      weightUnit: Yup.string().required("Weight Unit is required"),
      weight: Yup.number()
        .min(0.1, "Weight should be greater than zero")
        .typeError("Weight must be a number")
        .required("Weight is required"),
      height: Yup.number()
        .min(0.1, "Height should be greater than zero")
        .typeError("Height must be a number"),
      width: Yup.number()
        .min(0.1, "Width should be greater than zero")
        .typeError("Width must be a number"),
      length: Yup.number()
        .min(0.1, "Length should be greater than zero")
        .typeError("Length must be a number"),
    }),
    onSubmit: (values) => handleAddPackage(values),
  });
  const [resetKey, setResetKey] = useState(0);
  const [shipmentWeight, SetShipmentWeight] = useState(0);
  const [packages, setPackages] = useState(
    addOrderManagement?.values?.packages || []
  );
  useEffect(() => {
    // const totalWeight = packages.reduce((sum, packagee) => sum + parseFloat(packagee.weight || 0), 0);
    // SetShipmentWeight(totalWeight);
    const packageWeight = addOrderManagement?.values?.packages || []; // Ensure packages is an array or fallback to an empty array
    const totalWeight = packageWeight.reduce((sum, packagee) => {
      return sum + parseFloat(packagee.weight) || 0;
    }, 0);
    SetShipmentWeight(totalWeight);
  }, [addPackageForm?.values?.weight]);
  const handleAddPackage = () => {
    const packageWeight = addOrderManagement?.values?.packages || []; // Ensure packages is an array or fallback to an empty array
    const totalWeight = packageWeight.reduce((sum, packagee) => {
      return sum + parseFloat(packagee.weight) || 0;
    }, 0);
    SetShipmentWeight(totalWeight);
    if (
      addPackageForm.values.weightUnit === "KG" &&
      addPackageForm.values.weight > 70
    ) {
      toast.error("Weight should not be greater than 70 kgs");
    } else if (
      addPackageForm.values.weightUnit === "GRAM" &&
      addPackageForm.values.weight > 70000
    ) {
      toast.error("Weight should not be greater than 70000 grams");
    } else if (
      addPackageForm.values.weightUnit === "POUNDS" &&
      addPackageForm.values.weight > 154
    ) {
      toast.error("Weight should not be greater than 154 Pounds");
    } else {
      // Check if the form is valid
      addPackageForm.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          // Form is valid, add the package
          const sequence = addOrderManagement?.values?.packages?.length + 1; // Calculate sequence number
          const newPackage = {
            sequence: sequence,
            weightUnit: addPackageForm.values.weightUnit,
            weight: addPackageForm.values.weight,
            dimensionUnit: addPackageForm.values.dimensionUnit,
            height: addPackageForm.values.height,
            width: addPackageForm.values.width,
            length: addPackageForm.values.length,
          };
          addOrderManagement.setFieldValue("packages", [
            ...addOrderManagement.values.packages,
            newPackage,
          ]);
          // Reset the form
          setResetKey((prevKey) => prevKey + 1);
          addPackageForm.resetForm();
          handleClose();
        } else {
          const errorMessage = Object.values(errors).join(", ");
          toast.error(errorMessage);
        }
      });
    }
  };

  const handleRemovePackage = (index) => {
    const updatedPackages = addOrderManagement.values.packages.filter(
      (_, i) => i !== index
    );
    addOrderManagement.setFieldValue("packages", updatedPackages);
    const newTotalWeight = updatedPackages.reduce(
      (sum, packagee) => sum + parseFloat(packagee.weight || 0),
      0
    );
    SetShipmentWeight(newTotalWeight);
  };
  const handleRemoveItem = (e, itemIndex) => {
    e.preventDefault(); // Stop any default actions such as form submission if any

    // Create a new array without the item at `itemIndex`
    const updatedItems = [
      ...addOrderManagement.values.items.slice(0, itemIndex),
      ...addOrderManagement.values.items.slice(itemIndex + 1),
    ];

    // Update the formik values
    addOrderManagement.setFieldValue("items", updatedItems);
  };

  const addItemsForm = useFormik({
    initialValues: {
      description: "",
      quantity: "",
      unitPrice: "",
      currency: "",
      sku: "",
      hsCode: "",
      countryOfOrigin: "",
      packageSequence: "",
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().max(
        250,
        "Description must not be more than 250 characters long"
      ),
      quantity: Yup.number()
        .typeError("Quantity must be a number")
        .integer("Quantity must be a whole number")
        .min(1, "Quantity should be greater than zero")
        .required("Quantity is required"),
      currency: Yup.string().required("Currency is required"),
      unitPrice: Yup.number()
        .min(0.1, "Item Price should be greater than zero")
        .typeError("Item Price must be a number")
        .required("Item Price is required"),
    }),
    onSubmit: (values) => handleAddItem(values),
  });
  useEffect(() => {
    const contentTypeOption = orderContentType?.data?.data.find(
      (option) => option.key === productContentType
    );
    addOrderManagement.setFieldValue(
      "contentType",
      contentTypeOption ? contentTypeOption.key : ""
    );
  }, [productContentType, addOrderManagement?.values?.productCode]);
  const handleAddItem = () => {
    // Check if the form is valid
    addItemsForm.validateForm().then((errors) => {
      if (
        addOrderManagement?.values?.pickupAddress?.countryCode !==
          addOrderManagement?.values?.deliveryAddress?.countryCode &&
        addItemsForm?.values?.sku === ""
      ) {
        toast.error("SKU is required for International shipment");
      } else if (
        addOrderManagement?.values?.pickupAddress?.countryCode !==
          addOrderManagement?.values?.deliveryAddress?.countryCode &&
        addItemsForm?.values?.hsCode === ""
      ) {
        toast.error("HS Code is required for International shipment");
      } else if (
        productCategory !== "DOMESTIC" &&
        addItemsForm?.values?.countryOfOrigin === ""
      ) {
        toast.error(
          `Country of origin is required for ${productCategory} Product`
        );
      } else if (Object.keys(errors).length === 0) {
        // Form is valid, create the new item
        const newItem = {
          description: addItemsForm.values.description,
          quantity: addItemsForm.values.quantity,
          unitPrice: addItemsForm.values.unitPrice,
          currency: addItemsForm.values.currency,
          sku: addItemsForm.values.sku,
          hsCode: addItemsForm.values.hsCode,
          countryOfOrigin: addItemsForm.values.countryOfOrigin,
          packageSequence: addItemsForm.values.packageSequence,
        };

        // Find the index of the package with the corresponding packageSequence
        const packageIndex = addOrderManagement.values.packages.findIndex(
          (packageItm) =>
            packageItm.sequence === addItemsForm.values.packageSequence
        );

        if (packageIndex !== -1) {
          // Package found, update the packages array immutably
          const updatedPackages = addOrderManagement.values.packages.map(
            (pkg, index) => {
              if (index === packageIndex) {
                // Add new item to the package's items array
                const updatedItems = pkg.items
                  ? [...pkg.items, newItem]
                  : [newItem];
                return {
                  ...pkg,
                  items: updatedItems,
                };
              }
              return pkg;
            }
          );
          // Extract all items from all packages and combine them into a single array
          const allItems = updatedPackages.reduce((acc, pkg) => {
            if (pkg.items) {
              acc.push(...pkg.items);
            }
            return acc;
          }, []);
          const allExistingItems = addOrderManagement.values.items || [];
          const allItemsnew = [...allExistingItems, ...allItems];
          // Update addOrderManagement.values.items with the combined items array
          addOrderManagement.setFieldValue("items", allItemsnew);
          // UpdatedOrderManagement.values.items with the combined items array
          setResetKey((prevKey) => prevKey + 1);
          // Reset the form
          addItemsForm.resetForm();
          handleCloseItem();
        } else {
          newItem.packageSequence = ""; // Set packageSequence to empty
          // Add to items directly
          const updatedItems = addOrderManagement.values.items
            ? [...addOrderManagement.values.items, newItem]
            : [newItem];
          addOrderManagement.setFieldValue("items", updatedItems);
        }

        // Reset and close
        setResetKey((prevKey) => prevKey + 1);
        addItemsForm.resetForm();
        handleCloseItem();
        // toast.error("Package not found for the provided package sequence.");
      } else {
        const errorMessage = Object.values(errors).join(", ");
        toast.error(errorMessage);
      }
    });
  };
  const handleCountryChange = async (selectedOption) => {
    if (selectedOption === null) {
      addOrderManagement.setFieldValue("pickupAddress.countryCode", "");
    } else {
      let countryParam = `?page=${0}&size=20&isoCode3=${
        selectedOption.countryCode
      }`;
      let response = await getCountries(countryParam);
      // Update maxWeight field with the selected phoneCodePrefix value
      addOrderManagement.setFieldValue(
        "phonecodeprefix",
        response?.data?.data?.content[0].phoneCodePrefix
          ? response?.data?.data?.content[0]?.phoneCodePrefix
          : ""
      );
      addOrderManagement.setFieldValue(
        "pickupAddress.countryCode",
        selectedOption ? selectedOption.countryCode : ""
      );
      // Disable the maxWeight input if phoneCodePrefix is selected
      setIsPhonecodeprefix(!!selectedOption);
      setphoneNumberRegex(response?.data?.data?.content[0].phoneNumberRegex);
      setAlternatephoneNumberRegex(
        response?.data?.data?.content[0].phoneNumberRegex
      );
      try {
        const response = await getCountryByCityOrders(
          selectedOption.countryCode
        );
      } catch (error) {}
      // Check if a country is selected before making the API call
      try {
        const regionData = await getRegionbyCode(selectedOption.countryCode);
      } catch (error) {
        console.error("Error fetching region data:", error);
      }
    }
  };
  const [isdeliveryPhonecodePrefix, setIsDeliveryPhonecodeprefix] =
    useState(false);

  const handleDeliveryCountryChange = async (selectedOption) => {
    if (selectedOption === null) {
      addOrderManagement.setFieldValue("deliveryAddress.countryCode", "");
    } else {
      let countryParam = `?page=${0}&size=20&isoCode3=${
        selectedOption.countryCode
      }`;
      let response = await getCountries(countryParam);
      // Update maxWeight field with the selected phoneCodePrefix value
      addOrderManagement.setFieldValue(
        "phonecodedesprefix",
        response?.data?.data?.content[0].phoneCodePrefix
          ? response?.data?.data?.content[0]?.phoneCodePrefix
          : ""
      );
      setphoneNumberDesRegex(response?.data?.data?.content[0].phoneNumberRegex);
      setAltphoneNumberDesRegex(
        response?.data?.data?.content[0].phoneNumberRegex
      );
      addOrderManagement.setFieldValue(
        "deliveryAddress.countryCode",
        selectedOption ? selectedOption.countryCode : ""
      );
      // Disable the maxWeight input if isoNumeric is selected
      setIsDeliveryPhonecodeprefix(!!selectedOption);
      try {
        const response = await getCountryByCityOrdersDes(
          selectedOption.countryCode
        );
      } catch (error) {}
      // Check if a country is selected before making the API call
      try {
        const regionData = await getRegionbyCodeDes(selectedOption.countryCode);
      } catch (error) {
        console.error("Error fetching region data:", error);
      }
    }
  };
  return (
    <div>
      <div>
        <form>
          <div className="displayFlex mleft_5 mb_15">
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Customer:
                  <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                </label>
                <div className="displayFlex alignItemCenter">
                  <AsyncSearchInput
                    id="customerAccount"
                    label="customerAccount"
                    name="customerAccount"
                    value={selectedOption}
                    loadMoreOptions={loadMoreOptions}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(customer) => customer.label}
                    getOptionValue={(customer) => customer.value}
                    customStyles={{ control: { width: "180px" } }}
                    onChange={(selectedOption) => {
                      setSelectedOption(selectedOption);
                      addOrderManagement.setFieldValue(
                        "customerAccount",
                        selectedOption ? selectedOption.value : ""
                      );
                    }}
                    placeholder="Customer"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                </div>
                {addOrderManagement.touched.customerAccount &&
                addOrderManagement.errors.customerAccount ? (
                  <p className="requiredText">
                    {addOrderManagement.errors.customerAccount}
                  </p>
                ) : null}
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500 ws_nowrap">
                  Customer reference number:
                </label>
                <div className="displayFlex alignItemCenter">
                  <div className="GrpinputOrders">
                    <input
                      type="text"
                      value={addOrderManagement.values.referenceNumber}
                      className="mainInput"
                      onChange={(e) =>
                        addOrderManagement.setFieldValue(
                          "referenceNumber",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Product:
                  <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                </label>
                <div className="displayFlex alignItemCenter">
                  <AsyncSearchInput
                    id="productCode"
                    label="productCode"
                    name="productCode"
                    value={selectedOptionProduct}
                    loadMoreOptions={loadMoreProductsOptions}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    customStyles={{ control: { width: "200px" } }}
                    onChange={async (selectedOptionProduct) => {
                      const resp = await getProductsbyId(
                        selectedOptionProduct?.value
                      );
                      setProductCategory(resp?.data?.data?.productCategory);
                      setProductContentType(resp?.data?.data?.contentType);
                      setSelectedOptionProduct(selectedOptionProduct);
                      setProductMaxWeight(resp?.data?.data?.maxWeight);
                      addOrderManagement.setFieldValue(
                        "productCode",
                        selectedOptionProduct ? selectedOptionProduct.value : ""
                      );
                      getOriginCountry(selectedOptionProduct?.value);
                    }}
                    placeholder="Product"
                  />
                </div>
                {addOrderManagement.touched.productCode &&
                addOrderManagement.errors.productCode ? (
                  <p className="requiredText">
                    {addOrderManagement.errors.productCode}
                  </p>
                ) : null}
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Content Type:
                  <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                </label>
                <div className="displayFlex alignItemCenter">
                  <SearchInput
                    id="contentType"
                    label="contentType"
                    name="contentType"
                    options={orderContentType?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    isClearable={true}
                    handleChange={async (selectedOption) => {
                      addOrderManagement.setFieldValue(
                        "contentType",
                        selectedOption ? selectedOption.key : ""
                      );
                    }}
                    value={
                      addOrderManagement?.values?.contentType
                        ? orderContentType?.data?.data?.find(
                            (ctype) =>
                              ctype.key ===
                              addOrderManagement?.values?.contentType
                          )
                        : null
                    }
                    isSearchable={true}
                    isDisabled={
                      !!addOrderManagement?.values?.contentType &&
                      productContentType !== "BOTH"
                    }
                    placeholder="Content Type"
                    customStyles={{ control: { width: "200px" } }}
                    className="mainInput"
                    style={{ whiteSpace: "nowrap" }}
                  />
                </div>
                {addOrderManagement.touched.contentType &&
                addOrderManagement.errors.contentType ? (
                  <p className="requiredText">
                    {addOrderManagement.errors.contentType}
                  </p>
                ) : null}
              </Col>
            </Row>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="borderFrame mb_16">
                <div className="borderFrameTitle">Pickup Address</div>
                <div className="row mb_22 mt_-25">
                <div className="col-md-12 mr_45">
                  <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                    Shipper Name:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                  </label>
                  <div className="col-md-11">
                    <div className="GrpinputOrders">
                      <input
                        type="text"
                        placeholder="Shipper Name"
                        className="mainInput"
                        name="name"
                        value={addOrderManagement.values.pickupAddress.name}
                        onChange={(e) => {
                          addOrderManagement.setFieldValue(
                            "pickupAddress.name",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                    {addOrderManagement?.touched?.pickupAddress?.name &&
                    addOrderManagement?.errors?.pickupAddress?.name ? (
                      <p className="requiredText">
                        {addOrderManagement?.errors?.pickupAddress?.name}
                      </p>
                    ) : null}
                  </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Country:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="countryCode"
                            label="countryCode"
                            name="countryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            value={
                              addOrderManagement?.values?.pickupAddress
                                ?.countryCode
                                ? originCountries?.find(
                                    (reg) =>
                                      reg.name ===
                                      addOrderManagement?.values?.pickupAddress
                                        ?.countryCode
                                  )
                                : null
                            }
                            handleChange={handleCountryChange}
                            isSearchable={true}
                            placeholder="Country"
                            customStyles={{ control: { width: "240px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                        {addOrderManagement?.touched?.pickupAddress
                          ?.countryCode &&
                        addOrderManagement?.errors?.pickupAddress
                          ?.countryCode ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.pickupAddress
                                ?.countryCode
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Short address:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Short address"
                            className="mainInput"
                            name="maxWeight"
                            value={
                              addOrderManagement.values.pickupAddress
                                .shortAddress
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "pickupAddress.shortAddress",
                                e.target.value
                              );
                            }}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Region:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="regionCode"
                            label="regionCode"
                            name="regionCode"
                            options={regionbyCode || []}
                            value={
                              addOrderManagement?.values?.pickupAddress
                                ?.regionCode
                                ? regionbyCode?.find(
                                    (reg) =>
                                      reg.name ===
                                      addOrderManagement?.values?.pickupAddress
                                        ?.regionCode
                                  )
                                : null
                            }
                            getOptionLabel={(region) => region.name}
                            getOptionValue={(region) => region.name}
                            isClearable={true}
                            handleChange={(selectedOption) => {
                              if (selectedOption === null) {
                                addOrderManagement.setFieldValue(
                                  "pickupAddress.regionCode",
                                  ""
                                );
                              } else {
                                handleRegionChange(
                                  selectedOption,
                                  addOrderManagement
                                );
                              }
                            }}
                            isSearchable={true}
                            placeholder="Region"
                            customStyles={{ control: { width: "240px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Phone Number:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="GrpinputOrders">
                            <input
                              className="mainInput"
                              name="phonecodeprefix"
                              value={addOrderManagement.values.phonecodeprefix}
                              autoComplete="off"
                              disabled={isPhonecodePrefix}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="GrpinputOrders">
                            <input
                              type="text"
                              placeholder="Phone Number"
                              className="mainInput"
                              name="phone"
                              autoComplete="off"
                              value={
                                addOrderManagement.values.pickupAddress.phone
                              }
                              onChange={(e) => {
                                addOrderManagement.setFieldValue(
                                  "pickupAddress.phone",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          {addOrderManagement?.touched?.pickupAddress?.phone &&
                          addOrderManagement?.errors?.pickupAddress?.phone &&
                          error === "" ? (
                            <p className="requiredText">
                              {addOrderManagement?.errors?.pickupAddress?.phone}
                            </p>
                          ) : null}
                          {error !== "" && (
                            <p className="requiredText">{error}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        City:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="cityName"
                            label="City"
                            name="cityName"
                            options={citybyCode || []}
                            value={
                              addOrderManagement?.values?.pickupAddress
                                ?.cityCode
                                ? citybyCode.find(
                                    (city) =>
                                      city?.name ===
                                      addOrderManagement?.values?.pickupAddress
                                        ?.cityCode
                                  )
                                : null
                            }
                            getOptionLabel={(city) => city.name}
                            getOptionValue={(city) => city.name}
                            handleChange={(selectedOption) => {
                              if (selectedOption === null) {
                                addOrderManagement.setFieldValue(
                                  "pickupAddress.cityCode",
                                  ""
                                );
                              } else {
                                handleCtyChange(
                                  selectedOption,
                                  addOrderManagement
                                );
                              }
                            }}
                            customStyles={{ control: { width: "240px" } }}
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Select City"
                          />
                        </div>
                        {addOrderManagement?.touched?.pickupAddress?.cityCode &&
                        addOrderManagement?.errors?.pickupAddress?.cityCode ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.pickupAddress
                                ?.cityCode
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Alternate Phone Number:
                      </label>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="GrpinputOrders">
                            <input
                              className="mainInput"
                              name="phonecodeprefix"
                              value={addOrderManagement.values.phonecodeprefix}
                              autoComplete="off"
                              onChange={(e) =>
                                addOrderManagement.setFieldValue(
                                  "phonecodeprefix",
                                  e.target.value
                                )
                              }
                              disabled={isPhonecodePrefix}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="GrpinputOrders">
                            <input
                              type="text"
                              placeholder="Alternate Phone Number"
                              className="mainInput"
                              name="alternatePhone"
                              autoComplete="off"
                              value={
                                addOrderManagement.values.pickupAddress
                                  .alternatePhone
                              }
                              onChange={(e) => {
                                addOrderManagement.setFieldValue(
                                  "pickupAddress.alternatePhone",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          {addOrderManagement?.touched?.pickupAddress
                            ?.alternatePhone &&
                          addOrderManagement?.errors?.pickupAddress
                            ?.alternatePhone &&
                          alternatePhoneerror === "" ? (
                            <p className="requiredText">
                              {
                                addOrderManagement?.errors?.pickupAddress
                                  ?.alternatePhone
                              }
                            </p>
                          ) : null}
                          {alternatePhoneerror !== "" && (
                            <p className="requiredText">
                              {alternatePhoneerror}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        District:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="districtName"
                            label="districtName"
                            name="districtName"
                            options={districtbyCode || []}
                            getOptionLabel={(district) => district.name}
                            getOptionValue={(district) => district.name}
                            customStyles={{ control: { width: "240px" } }}
                            handleChange={(selectedOption) =>
                              handleDistrictChange(
                                selectedOption,
                                addOrderManagement
                              )
                            }
                            value={
                              addOrderManagement?.values?.pickupAddress
                                ?.districtCode
                                ? districtbyCode?.find(
                                    (district) =>
                                      district?.name ===
                                      addOrderManagement?.values?.pickupAddress
                                        ?.districtCode
                                  )
                                : null
                            }
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Select District"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Postal Code:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Postal Code"
                            maxLength={8}
                            className="mainInput"
                            name="postalCode"
                            autoComplete="off"
                            value={
                              addOrderManagement.values.pickupAddress.postalCode
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "pickupAddress.postalCode",
                                e.target.value
                              );
                              setPickuppostalErr("");
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.pickupAddress
                          ?.postalCode &&
                        addOrderManagement?.errors?.pickupAddress?.postalCode &&
                        pickupPostalErr === "" ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.pickupAddress
                                ?.postalCode
                            }
                          </p>
                        ) : null}
                        {pickupPostalErr !== "" ? (
                          <p className="requiredText">{pickupPostalErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                    Address Line 1:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                  </label>
                  <div className="col-md-11">
                    <div className="GrpinputOrders">
                      <input
                        type="text"
                        placeholder="Address Line 1"
                        className="mainInput"
                        name="addressLine1"
                        maxLength={100}
                        value={
                          addOrderManagement.values.pickupAddress.addressLine1
                        }
                        onChange={(e) => {
                          addOrderManagement.setFieldValue(
                            "pickupAddress.addressLine1",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                    {addOrderManagement?.touched?.pickupAddress?.addressLine1 &&
                    addOrderManagement?.errors?.pickupAddress?.addressLine1 ? (
                      <p className="requiredText">
                        {
                          addOrderManagement?.errors?.pickupAddress
                            ?.addressLine1
                        }
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row mb_22">
                  <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                    Address Line 2:
                  </label>
                  <div className="col-md-11">
                    <div className="GrpinputOrders">
                      <input
                        type="text"
                        placeholder="Address Line 2"
                        className="mainInput"
                        name="addressLine2"
                        maxLength={100}
                        value={
                          addOrderManagement.values.pickupAddress.addressLine2
                        }
                        onChange={(e) => {
                          addOrderManagement.setFieldValue(
                            "pickupAddress.addressLine2",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                  <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Email address:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="email"
                            placeholder="Email address"
                            className="mainInput"
                            name="pickupAddress.email"
                            autoComplete="off"
                            value={
                              addOrderManagement.values.pickupAddress.email
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "pickupAddress.email",
                                e.target.value
                              );
                              setEmailPickupErr("");
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.pickupAddress?.email &&
                        addOrderManagement?.errors?.pickupAddress?.email &&
                        emailPickupErr === "" ? (
                          <p className="requiredText">
                            {addOrderManagement?.errors?.pickupAddress?.email}
                          </p>
                        ) : null}
                        {emailPickupErr !== "" ? (
                          <p className="requiredText">{emailPickupErr}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                      Alias:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                        <input
                            type="text"
                            placeholder="Alias"
                            className="mainInput"
                            name="alias"
                            value={
                              addOrderManagement.values.pickupAddress
                                .alias
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "pickupAddress.alias",
                                e.target.value
                              );
                            }}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Longitude:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Longitude"
                            className="mainInput"
                            maxLength={5}
                            name="pickupAddress.longitude"
                            autoComplete="off"
                            value={
                              addOrderManagement.values.pickupAddress.longitude
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "pickupAddress.longitude",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.pickupAddress
                          ?.longitude &&
                        addOrderManagement?.errors?.pickupAddress?.longitude ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.pickupAddress
                                ?.longitude
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Latitude:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Latitude"
                            className="mainInput"
                            name="pickupAddress.latitude"
                            maxLength={5}
                            autoComplete="off"
                            value={
                              addOrderManagement.values.pickupAddress.latitude
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "pickupAddress.latitude",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.pickupAddress?.latitude &&
                        addOrderManagement?.errors?.pickupAddress?.latitude ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.pickupAddress
                                ?.latitude
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="borderFrame mb_16">
                <div className="borderFrameTitle">Delivery Address</div>
                <div className="row mb_22 mt_-25">
                  <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                    Receiver Name:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                  </label>
                  <div className="col-md-11">
                    <div className="GrpinputOrders">
                      <input
                        type="text"
                        placeholder="Receiver Name"
                        className="mainInput"
                        name="name"
                        value={addOrderManagement.values.deliveryAddress.name}
                        onChange={(e) => {
                          addOrderManagement.setFieldValue(
                            "deliveryAddress.name",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                    {addOrderManagement?.touched?.deliveryAddress?.name &&
                    addOrderManagement?.errors?.deliveryAddress?.name ? (
                      <p className="requiredText">
                        {addOrderManagement?.errors?.deliveryAddress?.name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Country:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="countryCode"
                            label="countryCode"
                            name="countryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            value={
                              addOrderManagement?.values?.deliveryAddress
                                ?.countryCode
                                ? destinationCountries?.find(
                                    (reg) =>
                                      reg.name ===
                                      addOrderManagement?.values
                                        ?.deliveryAddress?.countryCode
                                  )
                                : null
                            }
                            handleChange={handleDeliveryCountryChange}
                            isSearchable={true}
                            placeholder="Country"
                            customStyles={{ control: { width: "240px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                        {addOrderManagement?.touched?.deliveryAddress
                          ?.countryCode &&
                        addOrderManagement?.errors?.deliveryAddress
                          ?.countryCode ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.deliveryAddress
                                ?.countryCode
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Short address:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Short address"
                            className="mainInput"
                            name="shortAddress"
                            autoComplete="off"
                            value={
                              addOrderManagement.values.deliveryAddress
                                .shortAddress
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "deliveryAddress.shortAddress",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Region:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="regionCode"
                            label="regionCode"
                            name="regionCode"
                            options={regionbyCodeDes || []}
                            value={
                              addOrderManagement?.values?.deliveryAddress
                                ?.regionCode
                                ? regionbyCodeDes?.find(
                                    (reg) =>
                                      reg.name ===
                                      addOrderManagement?.values
                                        ?.deliveryAddress?.regionCode
                                  )
                                : null
                            }
                            getOptionLabel={(region) => region.name}
                            getOptionValue={(region) => region.name}
                            isClearable={true}
                            handleChange={(selectedOption) => {
                              if (selectedOption === null) {
                                addOrderManagement.setFieldValue(
                                  "deliveryAddress.regionCode",
                                  ""
                                );
                              } else {
                                handleRegionDeliveryChange(
                                  selectedOption,
                                  addOrderManagement
                                );
                              }
                            }}
                            isSearchable={true}
                            placeholder="Region"
                            customStyles={{ control: { width: "240px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Phone Number:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="GrpinputOrders">
                            <input
                              className="mainInput"
                              name="phonecodedesprefix"
                              value={
                                addOrderManagement.values.phonecodedesprefix
                              }
                              autoComplete="off"
                              disabled={isdeliveryPhonecodePrefix}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="GrpinputOrders">
                            <input
                              type="text"
                              placeholder="Phone Number"
                              className="mainInput"
                              name="phone"
                              autoComplete="off"
                              value={
                                addOrderManagement.values.deliveryAddress.phone
                              }
                              onChange={(e) => {
                                addOrderManagement.setFieldValue(
                                  "deliveryAddress.phone",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          {addOrderManagement?.touched?.deliveryAddress
                            ?.phone &&
                          addOrderManagement?.errors?.deliveryAddress?.phone &&
                          errorPhone === "" ? (
                            <p className="requiredText">
                              {
                                addOrderManagement?.errors?.deliveryAddress
                                  ?.phone
                              }
                            </p>
                          ) : null}
                          {errorPhone !== "" ? (
                            <p className="requiredText">{errorPhone}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        City:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="cityName"
                            label="City"
                            name="cityName"
                            options={citybyCodeDes || []}
                            getOptionLabel={(city) => city.name}
                            getOptionValue={(city) => city.name}
                            value={
                              addOrderManagement?.values?.deliveryAddress
                                ?.cityCode
                                ? citybyCodeDes?.find(
                                    (city) =>
                                      city?.name ===
                                      addOrderManagement?.values
                                        ?.deliveryAddress?.cityCode
                                  )
                                : null
                            }
                            handleChange={(selectedOption) => {
                              if (selectedOption === null) {
                                addOrderManagement.setFieldValue(
                                  "deliveryAddress.cityCode",
                                  ""
                                );
                              } else {
                                handleCtyDesChange(
                                  selectedOption,
                                  addOrderManagement
                                );
                              }
                            }}
                            customStyles={{ control: { width: "240px" } }}
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Select City"
                          />
                        </div>
                        {addOrderManagement?.touched?.deliveryAddress
                          ?.cityCode &&
                        addOrderManagement?.errors?.deliveryAddress
                          ?.cityCode ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.deliveryAddress
                                ?.cityCode
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Alternate Phone Number:
                      </label>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="GrpinputOrders">
                            <input
                              className="mainInput"
                              name="phonecodedesprefix"
                              value={
                                addOrderManagement.values.phonecodedesprefix
                              }
                              autoComplete="off"
                              disabled={isdeliveryPhonecodePrefix}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="GrpinputOrders">
                            <input
                              type="number"
                              placeholder="Alternate Phone Number"
                              className="mainInput"
                              name="alternatePhone"
                              autoComplete="off"
                              value={
                                addOrderManagement.values.deliveryAddress
                                  .alternatePhone
                              }
                              onChange={(e) => {
                                addOrderManagement.setFieldValue(
                                  "deliveryAddress.alternatePhone",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          {addOrderManagement?.touched?.deliveryAddress
                            ?.alternatePhone &&
                          addOrderManagement?.errors?.deliveryAddress
                            ?.alternatePhone &&
                          errorAltPhone === "" ? (
                            <p className="requiredText">
                              {
                                addOrderManagement?.errors?.deliveryAddress
                                  ?.alternatePhone
                              }
                            </p>
                          ) : null}
                          {errorAltPhone !== "" ? (
                            <p className="requiredText">{errorAltPhone}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        District:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="districtName"
                            label="districtName"
                            name="districtName"
                            options={districtbyDescode || []}
                            getOptionLabel={(district) => district.name}
                            getOptionValue={(district) => district.name}
                            customStyles={{ control: { width: "240px" } }}
                            value={
                              addOrderManagement?.values?.deliveryAddress
                                ?.districtCode
                                ? districtbyDescode?.find(
                                    (district) =>
                                      district?.name ===
                                      addOrderManagement?.values
                                        ?.deliveryAddress?.districtCode
                                  )
                                : null
                            }
                            handleChange={(selectedOption) =>
                              handleDeliveryDistrictChange(
                                selectedOption,
                                addOrderManagement
                              )
                            }
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Select District"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Postal Code:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Postal Code"
                            className="mainInput"
                            maxLength={8}
                            name="pickupAddress.postalCode"
                            autoComplete="off"
                            value={
                              addOrderManagement.values.deliveryAddress
                                .postalCode
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "deliveryAddress.postalCode",
                                e.target.value
                              );
                              setDeliveryPostalErr("");
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.deliveryAddress
                          ?.postalCode &&
                        addOrderManagement?.errors?.deliveryAddress
                          ?.postalCode &&
                        deliveryPostaErr === "" ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.deliveryAddress
                                ?.postalCode
                            }
                          </p>
                        ) : null}
                        {deliveryPostaErr !== "" ? (
                          <p className="requiredText">{deliveryPostaErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                    Address Line 1:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                  </label>
                  <div className="col-md-11">
                    <div className="GrpinputOrders">
                      <input
                        type="text"
                        placeholder="Address Line 1"
                        className="mainInput"
                        name="addressLine1"
                        maxLength={100}
                        value={
                          addOrderManagement.values.deliveryAddress.addressLine1
                        }
                        onChange={(e) => {
                          addOrderManagement.setFieldValue(
                            "deliveryAddress.addressLine1",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                    {addOrderManagement?.touched?.deliveryAddress
                      ?.addressLine1 &&
                    addOrderManagement?.errors?.deliveryAddress
                      ?.addressLine1 ? (
                      <p className="requiredText">
                        {
                          addOrderManagement?.errors?.deliveryAddress
                            ?.addressLine1
                        }
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row mb_22">
                  <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                    Address Line 2:
                  </label>
                  <div className="col-md-11">
                    <div className="GrpinputOrders">
                      <input
                        type="text"
                        placeholder="Address Line 2"
                        className="mainInput"
                        name="addressLine2"
                        maxLength={100}
                        value={
                          addOrderManagement.values.deliveryAddress.addressLine2
                        }
                        onChange={(e) => {
                          addOrderManagement.setFieldValue(
                            "deliveryAddress.addressLine2",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Email address:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="email"
                            placeholder="Email address"
                            className="mainInput"
                            name="email"
                            autoComplete="off"
                            value={
                              addOrderManagement.values.deliveryAddress.email
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "deliveryAddress.email",
                                e.target.value
                              );
                              setEmailDeliveryErr("");
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.deliveryAddress?.email &&
                        addOrderManagement?.errors?.deliveryAddress?.email &&
                        emailDeliveryupErr === "" ? (
                          <p className="requiredText">
                            {addOrderManagement?.errors?.deliveryAddress?.email}
                          </p>
                        ) : null}
                        {emailDeliveryupErr !== "" ? (
                          <p className="requiredText">{emailDeliveryupErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Longitude:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Longitude"
                            className="mainInput"
                            name="longitude"
                            maxLength={5}
                            autoComplete="off"
                            value={
                              addOrderManagement.values.deliveryAddress
                                .longitude
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "deliveryAddress.longitude",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.deliveryAddress
                          ?.longitude &&
                        addOrderManagement?.errors?.deliveryAddress
                          ?.longitude ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.deliveryAddress
                                ?.longitude
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Latitude:
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Latitude"
                            className="mainInput"
                            name="latitude"
                            maxLength={5}
                            autoComplete="off"
                            value={
                              addOrderManagement.values.deliveryAddress.latitude
                            }
                            onChange={(e) => {
                              addOrderManagement.setFieldValue(
                                "deliveryAddress.latitude",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        {addOrderManagement?.touched?.deliveryAddress
                          ?.latitude &&
                        addOrderManagement?.errors?.deliveryAddress
                          ?.latitude ? (
                          <p className="requiredText">
                            {
                              addOrderManagement?.errors?.deliveryAddress
                                ?.latitude
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row paddingRight0">
              <div className="col-md-12 paddingRight0">
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Packages Details<span className='bloodDonortext fontWeight600 fontSize16'>*</span></div>
                  <div className="row margin-top-10">
                    <form>
                      <div className="modal_table_wrapper overflowScroll mb_12 showTenRows">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Weight Unit<span className='bloodDonortext fontWeight600 fontSize16'>*</span></th>
                              <th>Weight<span className='bloodDonortext fontWeight600 fontSize16'>*</span></th>
                              <th>Dimension Unit</th>
                              <th>Height</th>
                              <th>Width</th>
                              <th>Length</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {addOrderManagement?.values?.packages.map(
                              (addedpackage, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{addedpackage.weightUnit}</td>
                                  <td>{addedpackage.weight}</td>
                                  <td>{addedpackage.dimensionUnit}</td>
                                  <td>{addedpackage.height}</td>
                                  <td>{addedpackage.width}</td>
                                  <td>{addedpackage.length}</td>
                                  <td>
                                    <button
                                      className="cancel_btn"
                                      type="button"
                                      onClick={() => handleRemovePackage(index)}
                                    >
                                      <span>
                                        <img src={removeIcon} alt="Remove" />
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td>
                                {addOrderManagement?.values?.packages?.length +
                                  1}
                              </td>
                              <td>
                                <SearchInput
                                  key={resetKey}
                                  id="weightUnit"
                                  label="weightUnit"
                                  name="weightUnit"
                                  isClearable={true}
                                  options={orderWeightUnit?.data?.data || []}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.key}
                                  handleChange={async (selectedOption) => {
                                    addPackageForm.setFieldValue(
                                      "weightUnit",
                                      selectedOption ? selectedOption.key : ""
                                    );
                                  }}
                                  isSearchable={true}
                                  placeholder="Weight Unit"
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  className="mainInput"
                                  maxLength={5}
                                  value={addPackageForm.values.weight}
                                  onChange={addPackageForm.handleChange}
                                  name="weight"
                                  placeholder="Weight"
                                />
                              </td>
                              <td>
                                <SearchInput
                                  key={resetKey}
                                  id="dimensionUnit"
                                  label="dimensionUnit"
                                  name="dimensionUnit"
                                  isClearable={true}
                                  options={orderDimensionUnit?.data?.data || []}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.key}
                                  handleChange={async (selectedOption) => {
                                    addPackageForm.setFieldValue(
                                      "dimensionUnit",
                                      selectedOption ? selectedOption.key : ""
                                    );
                                  }}
                                  // isSearchable={true}
                                  placeholder="Dimension Unit"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="mainInput"
                                  value={addPackageForm.values.height}
                                  onChange={addPackageForm.handleChange}
                                  maxLength={5}
                                  name="height"
                                  placeholder="Height"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="mainInput"
                                  value={addPackageForm.values.width}
                                  onChange={addPackageForm.handleChange}
                                  maxLength={5}
                                  name="width"
                                  placeholder="Width"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="mainInput"
                                  value={addPackageForm.values.length}
                                  onChange={addPackageForm.handleChange}
                                  maxLength={5}
                                  name="length"
                                  placeholder="Length"
                                />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="filterRouteBtn plus_icon"
                                  title="Add"
                                  onClick={handleAddPackage}
                                >
                                  +
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row paddingRight0">
              <div className="col-md-12 paddingRight0">
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Items Details<span className='bloodDonortext fontWeight600 fontSize16'>*</span></div>
                  <div className="row margin-top-10">
                    <form>
                      <div className="modal_table_wrapper overflowScroll mb_12 showTenRows">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Package Number<span className='bloodDonortext fontWeight600 fontSize16'>*</span></th>
                              <th>Product Description</th>
                              <th>Quantity<span className='bloodDonortext fontWeight600 fontSize16'>*</span></th>
                              <th>Item Price<span className='bloodDonortext fontWeight600 fontSize16'>*</span></th>
                              <th>Currency</th>
                              <th>SKU {addOrderManagement?.values?.pickupAddress?.countryCode !== 
   addOrderManagement?.values?.deliveryAddress?.countryCode && (
    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
  )}</th>
                              <th>HS Code {addOrderManagement?.values?.pickupAddress?.countryCode !== 
   addOrderManagement?.values?.deliveryAddress?.countryCode && (
    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
  )}</th>
                              {productCategory !== "DOMESTIC" && (
                                <th>Country of Origin</th>
                              )}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {addOrderManagement?.values?.items?.map(
                              (item, itemIndex) => (
                                <tr key={`item-${itemIndex}`}>
                                  <td>{itemIndex + 1}</td>
                                  <td>{item?.packageSequence}</td>
                                  <td>{item?.description}</td>
                                  <td>{item?.quantity}</td>
                                  <td>{item?.unitPrice}</td>
                                  <td>{item?.currency}</td>
                                  <td>{item?.sku}</td>
                                  <td>{item?.hsCode}</td>
                                  {productCategory !== "DOMESTIC" && (
                                    <td>{item?.countryOfOrigin}</td>
                                  )}
                                  <td>
                                    <button className="cancel_btn">
                                      <span>
                                        <img
                                          src={removeIcon}
                                          alt="Remove"
                                          onClick={(e) =>
                                            handleRemoveItem(e, itemIndex)
                                          }
                                        />
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td>
                                {addOrderManagement?.values?.items?.length + 1}
                              </td>
                              <td>
                                <SearchInput
                                  key={resetKey}
                                  id="packageSequence"
                                  label="packageSequence"
                                  name="packageSequence"
                                  isClearable={true}
                                  options={
                                    addOrderManagement?.values?.packages || []
                                  }
                                  getOptionLabel={(option) => option.sequence}
                                  getOptionValue={(option) => option.sequence}
                                  handleChange={async (selectedOption) => {
                                    addItemsForm.setFieldValue(
                                      "packageSequence",
                                      selectedOption
                                        ? selectedOption.sequence
                                        : ""
                                    );
                                  }}
                                  isSearchable={true}
                                  customStyles={{ control: { width: "155px" } }}
                                  placeholder="Package Number"
                                />
                              </td>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  name="description"
                                  className="mainInputOrder"
                                  value={addItemsForm.values.description}
                                  onChange={addItemsForm.handleChange}
                                  placeholder="Product Description"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="quantity"
                                  maxLength={5}
                                  className="mainInputOrder"
                                  value={addItemsForm.values.quantity}
                                  onChange={addItemsForm.handleChange}
                                  placeholder="Quantity"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="unitPrice"
                                  className="mainInputOrder"
                                  maxLength={7}
                                  value={addItemsForm.values.unitPrice}
                                  onChange={addItemsForm.handleChange}
                                  placeholder="Item Price"
                                />
                              </td>
                              <td>
                                <SearchInput
                                  key={resetKey}
                                  id="currency"
                                  label="currency"
                                  name="currency"
                                  options={
                                    currencyData?.data?.data?.content || []
                                  }
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.code}
                                  isClearable={true}
                                  handleChange={async (selectedOption) => {
                                    addItemsForm.setFieldValue(
                                      "currency",
                                      selectedOption ? selectedOption.code : ""
                                    );
                                  }}
                                  isSearchable={true}
                                  placeholder="Currency"
                                  className="mainInput"
                                  customStyles={{ control: { width: "145px" } }}
                                  style={{ whiteSpace: "nowrap" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="sku"
                                  className="mainInput"
                                  value={addItemsForm.values.sku}
                                  onChange={addItemsForm.handleChange}
                                  placeholder="SKU"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="mainInputOrder"
                                  name="hsCode"
                                  value={addItemsForm.values.hsCode}
                                  onChange={addItemsForm.handleChange}
                                  placeholder="HS Code"
                                />
                              </td>
                              {productCategory !== "DOMESTIC" && (
                                <td>
                                  <SearchInput
                                    key={resetKey}
                                    id="countryOfOrigin"
                                    label="countryOfOrigin"
                                    name="countryOfOrigin"
                                    options={
                                      countryArrayData?.data?.data?.content ||
                                      []
                                    }
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.isoCode3}
                                    isClearable={true}
                                    handleChange={async (selectedOption) => {
                                      addItemsForm.setFieldValue(
                                        "countryOfOrigin",
                                        selectedOption
                                          ? selectedOption.isoCode3
                                          : ""
                                      );
                                    }}
                                    customStyles={{
                                      control: { width: "155px" },
                                    }}
                                    isSearchable={true}
                                    placeholder="Country of Origin"
                                  />
                                </td>
                              )}
                              <td>
                                <button
                                  type="button"
                                  className="filterRouteBtn plus_icon"
                                  title="Add"
                                  onClick={handleAddItem}
                                >
                                  +
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row mb_22">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Declared Value:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Declared Value"
                            className="mainInput"
                            name="declaredValue"
                            value={addOrderManagement.values.declaredValue}
                            autoComplete="off"
                            onChange={(e) =>
                              addOrderManagement.setFieldValue(
                                "declaredValue",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {addOrderManagement?.touched?.declaredValue &&
                        addOrderManagement?.errors?.declaredValue ? (
                          <p className="requiredText">
                            {addOrderManagement?.errors?.declaredValue}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Declared Value Currency:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="declaredValueCurrency"
                            label="declaredValueCurrency"
                            name="declaredValueCurrency"
                            options={currencyData?.data?.data?.content || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addOrderManagement.setFieldValue(
                                "declaredValueCurrency",
                                selectedOption ? selectedOption.code : ""
                              );
                            }}
                            isSearchable={true}
                            placeholder="Declared Value Currency"
                            customStyles={{ control: { width: "268px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                        {addOrderManagement?.touched?.declaredValueCurrency &&
                        addOrderManagement?.errors?.declaredValueCurrency ? (
                          <p className="requiredText">
                            {addOrderManagement?.errors?.declaredValueCurrency}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt_45">
                      <div className="smallCheckbox col-md-12">
                        <input
                          type="checkbox"
                          id="cod"
                          style={{ marginLeft: "5px" }}
                          checked={addOrderManagement.values.cod}
                          onChange={() => {
                            addOrderManagement.setFieldValue(
                              "cod",
                              !addOrderManagement.values.cod
                            );
                            setCodErr("");
                          }}
                        />
                        <label htmlFor="cod">Cash on delivery</label>
                      </div>
                    </div>
                    {addOrderManagement?.values?.cod && (
                      <div className="col-md-6 mt_5">
                        <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                          Cash on delivery amount:
                        </label>
                        <div className="col-md-11">
                          <div className="GrpinputOrders">
                            <input
                              type="text"
                              placeholder="Cash on delivery amount"
                              className="mainInput"
                              name="codAmount"
                              autoComplete="off"
                              value={addOrderManagement.values.codAmount}
                              onChange={(e) => {
                                addOrderManagement.setFieldValue(
                                  "codAmount",
                                  e.target.value
                                );
                                setCodErr("");
                              }}
                            />
                          </div>
                          {addOrderManagement?.touched?.codAmount &&
                          addOrderManagement?.errors?.codAmount ? (
                            <p className="requiredText">
                              {addOrderManagement?.errors?.codAmount}
                            </p>
                          ) : null}
                          {codError !== "" ? (
                            <p className="requiredText">{codError}</p>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt_25">
                      <div className="smallCheckbox col-md-12">
                        <input
                          type="checkbox"
                          id="insured"
                          style={{ marginLeft: "5px" }}
                          checked={addOrderManagement.values.insured}
                          onChange={() => {
                            addOrderManagement.setFieldValue(
                              "insured",
                              !addOrderManagement.values.insured
                            );
                            if (!addOrderManagement.values.insured) {
                              addOrderManagement.setFieldValue("insured", true); // Set bagNumber to an empty string
                            }
                          }}
                        />
                        <label htmlFor="insured">Is Insured</label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt_20">
                    <div className="col-md-6 mt_5">
                      <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                        Incoterms:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="col-md-11">
                        <div className="GrpinputOrders">
                          <SearchInput
                            id="incoterms"
                            label="incoterms"
                            name="incoterms"
                            options={orderIncoterms?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addOrderManagement.setFieldValue(
                                "incoterms",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            isSearchable={true}
                            placeholder="Incoterms"
                            customStyles={{ control: { width: "268px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                        {addOrderManagement?.touched?.incoterms &&
                        addOrderManagement?.errors?.incoterms ? (
                          <p className="requiredText">
                            {addOrderManagement?.errors?.incoterms}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 mt_5">
                    <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                      Content:
                    </label>
                    <div className="col-md-12">
                      <div className="GrpinputOrders">
                        <textarea
                          className="washMe_btn"
                          placeholder="Content"
                          name="content"
                          maxLength={250}
                          onChange={(e) =>
                            addOrderManagement.setFieldValue(
                              "content",
                              e.target.value
                            )
                          }
                          onBlur={addOrderManagement.handleBlur}
                          value={addOrderManagement.values.content}
                          style={{ height: "90px", paddingTop: "6px" }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt_5">
                    <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                      Notes:
                    </label>
                    <div className="col-md-12">
                      <div className="GrpinputOrders">
                        <textarea
                          className="washMe_btn"
                          placeholder="Notes"
                          maxLength={250}
                          name="note"
                          onChange={(e) =>
                            addOrderManagement.setFieldValue(
                              "note",
                              e.target.value
                            )
                          }
                          onBlur={addOrderManagement.handleBlur}
                          value={addOrderManagement.values.note}
                          style={{ height: "90px", paddingTop: "6px" }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="row mt_20">
          <div className="col-md-6">
            <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={handleSaveOrderMgt}
              >
                Save
              </button>
              <button className="cancel_btn">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
