import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useAoneStore } from "../../store/AoneStore";
import { useNavigate, useLocation } from "react-router";
import PaginationTable from "../../components/Tables/paginationTable";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import removeIcon from "../../../src/assets/img/cancelIcon.svg";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import { DateRangePicker } from "rsuite";
import OpenModal from "../../components/modal/openModal";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
const Invoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [editPage, setEditPage] = useState(false);
  const prevent = useRef(false);
  const params = useParams();
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoicetemplateData, setInvoiceTemplateData] = useState([]);
  const [consignmentData, setConsignmentData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [trackingId, setTrackingId] = useState("");
  const [invoiceNum, setInvoiceNum] = useState("");
  const [name, setName] = useState("");
  const [customerAcc, setCustomerAcc] = useState("");
  const [invoicerequestId, setInvoicerequestId] = useState("");
  const [editHandler, setEditHandler] = useState(false);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isSelectClick, setIsselectClick] = useState(false);
  const [modalHandler, setModalHandler] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const [sortField, setSortField] = useState("-updatedAt");
  const [page, setPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [issaved, setissaved] = useState(false);
  const postCustomerClassification = useAoneStore(
    (state) => state?.postCustomerClassification
  );
  const postHighValue = useAoneStore((state) => state?.postHighValue);
  const postTiersClassification = useAoneStore(
    (state) => state?.postTiersClassification
  );
  const [deleteHandler, setDeleteHandler] = useState(false);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const customerData = useAoneStore((state) => state?.customerData);
  const [statusHandler, setStatusHandler] = useState(false);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const getInvoiceRequest = useAoneStore((state) => state?.getInvoiceRequest);
  const getInvoiceRequestTemplate = useAoneStore(
    (state) => state?.getInvoiceRequestTemplate
  );
  const getConsignments = useAoneStore((state) => state?.getConsignments);
  //bulk import docs
  const [selectedBulk, setSelectedBulk] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [isDropdownOpn, setIsDropdownOpn] = useState(false);
  const setReactDesBulk = useAoneStore((state) => state?.setReactDesBulk);
  const handlescanShow = () => {
    setShowUpload(true);
  };
  const handleListClick = (option) => {
    setSelectedBulk(option);
    setShowUpload(true);
  };
  const handleuploadClose = () => {
    setShowUpload(false);
  };
  //
  const isReactdesBulk = useAoneStore((state) => state?.isReactdesBulk);
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
    navigate("/finance/invoices");
  };
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const today = new Date();
  const startOfYesterday = new Date(today);
  startOfYesterday.setDate(today.getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);
  const defaultStartDate = startOfYesterday;
  const defaultEndDate = endOfToday;
  // const [dateRange, setDateRange] = useState([defaultStartDate, defaultEndDate]);
  const [dateRange, setDateRange] = useState([]);
  // const [dateRangefil, setDateRangefil] = useState({
  //   start: startOfYesterday.getTime(), // Convert to timestamp
  //   end: endOfToday.getTime(), // Convert to timestamp
  // });
  const [dateRangefil, setDateRangefil] = useState({});
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [selectedOption, setSelectedOption] = useState(null);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (
        dateRangefil.start !== null &&
        dateRangefil.end !== null &&
        !isNaN(dateRangefil.start) &&
        !isNaN(dateRangefil.end) &&
        dateRangefil.start !== dateRangefil.end
      ) {
        params += `&fromDate=${dateRangefil.start}&toDate=${dateRangefil.end}`;
      } else if (
        dateRangefil.start !== null &&
        dateRangefil.end !== null &&
        !isNaN(dateRangefil.start) &&
        !isNaN(dateRangefil.end)
      ) {
        const startOfDay = new Date(dateRangefil.start);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(dateRangefil.end);
        endOfDay.setHours(23, 59, 59, 999);

        // Check if dates are the same (today)
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (
          startOfDay.getTime() === endOfDay.getTime() &&
          startOfDay.getTime() === today.getTime()
        ) {
          // Set times to the beginning and end of the day
          startOfDay.setHours(0, 0, 0, 0);
          endOfDay.setHours(23, 59, 59, 999);
        } else {
          // Set times to the provided dates
          startOfDay.setHours(0, 0, 0, 0);
          endOfDay.setHours(23, 59, 59, 999);
        }

        params += `&fromDate=${startOfDay.getTime()}&toDate=${endOfDay.getTime()}`;
      }
      let paramslink = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      const data = await getInvoiceRequest(params);
      getInvoiceRequestTemplate(paramslink);
      setInvoiceData(data?.data?.data?.content);
      setTotalCount(data?.data?.data?.totalElements);
    } else if (tab === 2) {
      if (trackingId) {
        params += `&waybillNo=${trackingId}`;
      }
      if (invoiceNum) {
        params += `&customerReferenceNumber=${invoiceNum}`;
      }
      if (customerAcc) {
        params += `&customerAccountNo=${customerAcc}`;
      }
      if (invoicerequestId) {
        params += `&invoiceRequestId=${invoicerequestId}`;
      }
      // if(consignmentData?.length < 1){
      const data = await getConsignments(params);
      const filteredData = data?.data?.data?.content.filter(
        (e) => e?.deletedAt === null && e?.deletedBy === null
      );
      setConsignmentData(filteredData);
      setTotalCount(data.data.data.totalElements);
      // }
    } else {
      if (name) {
        params += `&name=${name}`;
      }
      const data = await getInvoiceRequestTemplate(params);
      setInvoiceTemplateData(data.data.data.content);
      setTotalCount(data.data.data.totalElements);
    }
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);
          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            let importFunction;
            switch (selectedBulk) {
              case "Customer classification":
                importFunction = postCustomerClassification;
                break;
              case "HV Clearance":
                importFunction = postHighValue;
                break;
              case "Tiers":
                importFunction = postTiersClassification;
                break;
              default:
                importFunction = postCustomerClassification;
            }
            importFunction(formData)
              .then((response) => {
                console.log("1",response)
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  toast.error(response.data.msg);
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  fileInput.value = "";
                } else if (response?.response?.status === "ERROR") {
                  toast.error(response.response.statusText);
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  fileInput.value = "";
                } else if (response?.response?.status == "500") {
                  toast.error(response.response.statusText);
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  handleuploadClose();
                  setIsDropdownOpn(false);
                  fileInput.value = "";
                }
              })
              .catch(() => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
                handleuploadClose();
                setIsDropdownOpn(false);
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            handleuploadClose();
            setIsDropdownOpn(false);
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          handleuploadClose();
          setIsDropdownOpn(false);
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        handleuploadClose();
        setIsDropdownOpn(false);
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  useEffect(() => {
    !editPage && getData();
  }, [
    newPage,
    size,
    sortField,
    deleteHandler,
    location,
    issaved,
    modalHandler,
    addModalHandler,
    statusHandler,
    saveHandler,
    name,
    isDelete,
    dateRangefil.start,
    dateRangefil.end,
    invoiceNum,
    trackingId,
    customerAcc,
    invoicerequestId,
  ]);
  const dropdownRef = useRef(null); // Reference for the dropdown
  // Toggle dropdown
  const toggleDropdown = () => setIsDropdownOpn(prev => !prev);

  // Handle clicks outside the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpn(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.length < 1
    ) {
      filterCountries(paramslink);
    }
  }, []);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/finance/invoices")}
              >
                Finance
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/finance/invoices")}
              >
                Invoices
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(1);
            setSelectedOption(null);
            setPage(-1);
            setSortField("-updatedAt");
          }}
        >
          Invoice Request
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(2);
            setSelectedOption(null);
            setPage(-1);
            setSortField("-updatedAt");
          }}
        >
          Consignments
        </button>
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(3);
            setSelectedOption(null);
            setPage(-1);
            setSortField("-updatedAt");
          }}
        >
          Invoice Request Templates
        </button>
      </div>
      <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
        {tab === 1 ? (
          <div className="displayFlex justifyContent_end mleft_5">
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12">Date Range:</label>
                <div className="displayFlex alignItemCenter">
                  <div className="customDateRange">
                    <DateRangePicker
                      format="yyyy-MM-dd"
                      placeholder="Select Date Range"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      placement="auto"
                      minDate={
                        new Date(new Date().setMonth(new Date().getMonth() - 3))
                      }
                      onOpen={handleMenuOpen}
                      onClose={handleMenuClose}
                      showMeridian
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : tab === 2 ? (
          <div className="displayFlex justifyContent_end mleft_5">
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Customer:
                </label>
                <AsyncSearchInput
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOption}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    if (selectedOption) {
                      setCustomerAcc(selectedOption.value);
                    } else {
                      setCustomerAcc(null);
                    }
                  }}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isDropdownselectOpen}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                  placeholder="Customer"
                />
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Tracking Id:
                </label>
                <div className="displayFlex alignItemCenter marginR10 position-relative">
                  <input
                    type="text"
                    value={trackingId}
                    className={
                      trackingId?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      trackingId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setTrackingId(e.target.value)}
                  />
               {trackingId && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setTrackingId("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Invoice Request Id:
                </label>
                <div className="displayFlex alignItemCenter marginR10 position-relative">
                  <input
                    type="text"
                    value={invoicerequestId}
                    className={
                      invoicerequestId?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      invoicerequestId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setInvoicerequestId(e.target.value)}
                  />
                 {invoicerequestId && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setInvoicerequestId("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
              </Col>
              {/* <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Invoice Number:
                </label>
                <div className="displayFlex alignItemCenter marginR10">
                  <input
                    type="text"
                    value={invoiceNum}
                    className={
                      invoiceNum?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      invoiceNum?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                    onChange={(e) => setInvoiceNum(e.target.value)}
                  />
                </div>
              </Col> */}
            </Row>
          </div>
        ) : (
          <div className="displayFlex justifyContent_end mleft_5">
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12">Name:</label>
                <div className="displayFlex alignItemCenter position-relative">
                  <div className="customDateRange">
                    <input
                      type="text"
                      value={name}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        name?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setName(e.target.value)}
                    />
                   {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        <div className="margin_top40">
          <div className="displayFlex gap_12">
            <div class="jira-dropdown mr_5 w115 ml_15" ref={dropdownRef}>
              <button
                className="jira-dropdown-button"
                onClick={() => {
                  setIsDropdownOpn(!isDropdownOpn);
                  setReactDesBulk(true);
                }}
              >
                Bulk Actions
              </button>
              <ul
                className="jira-dropdown-contentinvoice"
                style={{
                  display: isDropdownOpn ? "block" : "none",
                  borderRadius: "10px",
                }}
              >
                <li onClick={() => handleListClick("Customer classification")}>
                  Customer classification
                </li>
                <li onClick={() => handleListClick("HV Clearance")}>
                  HV Clearance
                </li>
                <li onClick={() => handleListClick("Tiers")}>Tiers</li>
              </ul>
            </div>
            <button
              className="filterBtn plus_icon"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
            {tab !== 2 &&
              (roleHandler(role, "AONE_FINANCE.write") ||
                roleHandler(role, "AONE_FINANCE.admin") ||
                roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
                roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                roleHandler(
                  role,
                  "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write"
                ) ||
                roleHandler(
                  role,
                  "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin"
                )) && (
                <button
                  className="filterBtn plus_icon"
                  onClick={() => setAddModalHandler(!addModalHandler)}
                  tabIndex="-1"
                >
                  +
                </button>
              )}
          </div>
        </div>
      </div>
      {!editPage && (
        <PaginationTable
          tableData={
            tab === 1
              ? invoiceData
              : tab === 2
              ? consignmentData
              : invoicetemplateData
          }
          tbl={
            tab === 1
              ? "Invoice Request"
              : tab === 2
              ? "Consignments"
              : "Invoice Request Templates"
          }
          modalHandler={editPage}
          setModalHandler={setEditPage}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          countriesArray={countriesData?.data?.data?.content}
          setSize={setSize}
          totalCount={totalCount}
          isDropdownselectOpen={isDropdownselectOpen}
          sortField={sortField}
          setSortField={setSortField}
          setissaved={setissaved}
          issaved={issaved}
        />
      )}
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={
            tab === 1
              ? "Create Invoice Request"
              : "Create Invoice Request Templates"
          }
          editHandler={editHandler}
          setEditHandler={setEditHandler}
          countriesData={countriesData}
        />
      )}
      {showUpload && (
        <Modal
          className="uploadConfirmpopup"
          show={showUpload}
          onHide={handleuploadClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
              Upload file for {selectedBulk}!
            </h2>
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <label className="upload-button">
              Import File
              <input
                type="file"
                id="uploadFile"
                onChange={handleFileChange}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </label>
            <button className="cancel_btn" onClick={handleuploadClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Invoices;
