import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useAoneStore } from "../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNotificationStore } from "../../store/NotificationsMgt/NotificationStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
const RulesModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  rulebyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postRules = useNotificationStore((state) => state?.postRules);
  const editRules = useNotificationStore((state) => state?.editRules);
  const dataTypeList = useNotificationStore((state) => state?.dataTypeList);
  const fieldList = useNotificationStore((state) => state?.fieldList);
  const operatorList = useNotificationStore((state) => state?.operatorList);
  const templateList = useNotificationStore((state) => state?.templateList);
  const ruleStatusList = useNotificationStore((state) => state?.ruleStatusList);
  const conditionLogicList = useNotificationStore(
    (state) => state?.conditionLogicList
  );
  const eventStatusList = useNotificationStore(
    (state) => state?.eventStatusList
  );
  const getTemplates = useNotificationStore((state) => state?.getTemplates);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [resetKey, setResetKey] = useState(0);
  const addRule = useFormik({
    initialValues: {
      eventCode: "",
      countryCode: "",
      templateId: "",
      status: "",
      conditionLogic: "AND",
      conditionList: [
        {
          field: "",
          operator: "",
          dataType: "",
          value: "",
        },
      ],
    },
    validationSchema: Yup.object({
      eventCode: Yup.string().trim().required("Event is required"),
      countryCode: Yup.string().required("Country is required"),
      templateId: Yup.string().required("Template is required"),
      status: Yup.string().required("Status is required"),
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit SMS Rule") {
        await editRules(rulebyCode.id, values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("SMS Rule updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response.status == 200) {
            toast.success("SMS Rule updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      } else {
        await postRules(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("SMS Rule has been added");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      }
    },
  });
  const addRow = () => {
    if (addRule.values.conditionList.length < 4) {
      addRule.setFieldValue("conditionList", [
        ...addRule.values.conditionList,
        { field: "", operator: "", dataType: "", value: "" },
      ]);
    }
  };
  const removeRow = (index) => {
    const updatedConditions = addRule.values.conditionList.filter(
      (_, i) => i !== index
    );
    addRule.setFieldValue("conditionList", updatedConditions);
  };

  const selectedKey = addRule.values.conditionLogic || "AND";
  const handleButtonClick = (event, key) => {
    event.preventDefault();
    addRule.setFieldValue("conditionLogic", key);
  };
  useEffect(() => {
    if (title !== "Create new SMS rule") {
      addRule.setFieldValue("eventCode", rulebyCode?.eventCode);
      addRule.setFieldValue("countryCode", rulebyCode?.countryCode);
      getTemplatebyCountry();
      addRule.setFieldValue("templateId", rulebyCode?.templateId);
      addRule.setFieldValue("status", rulebyCode?.status);
      addRule.setFieldValue("conditionLogic", rulebyCode?.conditionLogic);
      addRule.setFieldValue("conditionList", rulebyCode?.conditionList);
    }
  }, [title, rulebyCode]);
  const getTemplatebyCountry =async()=>{
    let params = `?page=${0}&size=${100}&fields=countryName&countryCode=${rulebyCode?.countryCode}`;
    const data = await getTemplates(params);
  }
  useEffect(() => {
    if (title == "Create new SMS rule") {
      addRule.setFieldValue("eventCode", "");
      addRule.setFieldValue("countryCode", "");
      addRule.setFieldValue("status", "");
    }
  }, [title, rulebyCode]);
  const validateValue = (value, dataType) => {
    switch (dataType) {
      case 'BOOLEAN':
        return value === 'true' || value === 'false' ? '' : 'Value must be a boolean (true/false).';
      case 'BYTE':
        return /^-?\d+$/.test(value) && Number(value) >= -128 && Number(value) <= 127 ? '' : 'Value must be a byte (-128 to 127).';
      case 'DOUBLE':
      case 'FLOAT':
        return /^-?\d+(\.\d+)?$/.test(value) ? '' : 'Value must be a float or double.';
      case 'INTEGER':
        return /^-?\d+$/.test(value) ? '' : 'Value must be an integer.';
      case 'LONG':
        return /^-?\d+$/.test(value) ? '' : 'Value must be a long integer.';
      case 'SHORT':
        return /^-?\d+$/.test(value) && Number(value) >= -32768 && Number(value) <= 32767 ? '' : 'Value must be a short (-32768 to 32767).';
      case 'STRING':
        return typeof value === 'string' ? '' : 'Value must be a string.';
        // case 'STRING':
        //   return /^[a-zA-Z]+$/.test(value) ? '' : 'Value must be a string containing only alphabetic characters.';
      default:
        return 'Invalid data type.';
    }
  };
  
  const validateConditions = (conditionList) => {
    // Check if all fields are present
    const allFieldsPresent = conditionList.every(
      (condition) =>
        condition.field &&
        condition.operator &&
        condition.dataType &&
        condition.value
    );
  
    if (!allFieldsPresent) {
      return 'All fields (field, operator, dataType, value) must be filled out in each condition.';
    }
  
    // Validate value based on data type
    for (let i = 0; i < conditionList.length; i++) {
      const { value, dataType } = conditionList[i];
      const validationError = validateValue(value, dataType);
      if (validationError) {
        return `Condition at index ${i} is invalid: ${validationError}`;
      }
    }
    return '';
  };
  
  const saveRule = (initialValues) => {
    const validationError = validateConditions(addRule.values.conditionList);
    if (!validationError) {
      addRule?.handleSubmit();
    } else {
      toast.error(validationError);
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleDocument">
        <Modal.Body>
          <form>
            <div className="borderFrame mb_16">
              <div className="borderFrameTitle">Conditions</div>
              {addRule?.values?.conditionList?.length > 1 && (
              <div className="row margin-top-10">
                <div className="button-groupsms">
                  {conditionLogicList?.data?.data?.map(({ key, label }) => (
                    <button
                      key={key}
                      className={`buttonSms ${
                        selectedKey === key ? "selected" : ""
                      }`}
                      onClick={(event) => handleButtonClick(event, key)}
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </div>
              )}
              {addRule?.values?.conditionList?.length > 0 &&
                addRule?.values?.conditionList?.map((condition, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Field
                            <span className="bloodDonortext fontWeight600 fontSize16">
                              *
                            </span>
                          </label>
                          <SearchInput
                            id={`conditionList.${index}.field`}
                            label="field"
                            name={`conditionList[${index}].field`}
                            options={fieldList?.data?.data}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            handleChange={(selectedOption) => {
                              addRule.setFieldValue(
                                `conditionList[${index}].field`,
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              condition.field
                                ? fieldList?.data?.data.find(
                                    (option) => option.key === condition.field
                                  )
                                : null
                            }
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Field"
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Operator
                            <span className="bloodDonortext fontWeight600 fontSize16">
                              *
                            </span>
                          </label>
                          <SearchInput
                            id={`conditionList.${index}.operator`}
                            label="operator"
                            name={`conditionList[${index}].operator`}
                            options={operatorList?.data?.data}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            handleChange={(selectedOption) => {
                              addRule.setFieldValue(
                                `conditionList[${index}].operator`,
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              condition.operator
                                ? operatorList?.data?.data.find(
                                    (option) =>
                                      option.key === condition.operator
                                  )
                                : null
                            }
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Operator"
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Data Type
                            <span className="bloodDonortext fontWeight600 fontSize16">
                              *
                            </span>
                          </label>
                          <SearchInput
                            id={`conditionList.${index}.dataType`}
                            label="dataType"
                            name={`conditionList[${index}].dataType`}
                            options={dataTypeList?.data?.data}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            handleChange={(selectedOption) => {
                              addRule.setFieldValue(
                                `conditionList[${index}].dataType`,
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              condition.dataType
                                ? dataTypeList?.data?.data.find(
                                    (option) =>
                                      option.key === condition.dataType
                                  )
                                : null
                            }
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Data Type"
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Value
                            <span className="bloodDonortext fontWeight600 fontSize16">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Value"
                            value={condition.value}
                            onChange={(e) => {
                              addRule.setFieldValue(
                                `conditionList[${index}].value`,
                                e.target.value
                              );
                            }}
                            name={`conditionList[${index}].value`}
                            className="mainInput"
                          />
                        </div>
                      </div>
                    </div>
                    {addRule?.values?.conditionList?.length > 1 && (
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={() => removeRow(index)}
                          className="total-failureSms"
                          style={{ marginBottom: "20px" }}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              {addRule?.values?.conditionList?.length < 4 && (
                <div className="row">
                  <div className="col-md-12">
                    <button
                      type="button"
                      onClick={addRow}
                      className="total-successSms"
                    >
                      Add Row
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="row mt_20">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Event
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="eventCode"
                      label="eventCode"
                      name="eventCode"
                      options={eventStatusList?.data?.data}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addRule.setFieldValue(
                          "eventCode",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      value={
                        addRule?.values?.eventCode
                          ? eventStatusList?.data?.data?.find(
                              (option) =>
                                option.key == addRule?.values?.eventCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Event"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addRule.touched.eventCode && addRule.errors.eventCode ? (
                    <p className="requiredText">{addRule.errors.eventCode}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={async(selectedOption) => {
                        addRule.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                        addRule?.setFieldValue("templateId","")
                        let params = `?page=${0}&size=${100}&fields=countryName&countryCode=${selectedOption?.isoCode3}`;
                        const data = await getTemplates(params);
                      }}
                      value={
                        addRule?.values?.countryCode
                          ? countriesData?.data?.data?.content.find(
                              (option) =>
                                option.isoCode3 === addRule?.values?.countryCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addRule.touched.countryCode && addRule.errors.countryCode ? (
                    <p className="requiredText">{addRule.errors.countryCode}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      SMS Template
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="templateId"
                      label="templateId"
                      name="templateId"
                      options={templateList?.data?.data?.content}
                      getOptionLabel={(option) => option.code}
                      getOptionValue={(option) => option.id}
                      handleChange={(selectedOption) => {
                        addRule.setFieldValue(
                          "templateId",
                          selectedOption ? selectedOption?.id : ""
                        );
                      }}
                      value={
                        addRule?.values?.templateId
                          ? templateList?.data?.data?.content.find(
                              (option) =>
                                option.id === addRule?.values?.templateId
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="SMS Template"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addRule.touched.templateId && addRule.errors.templateId ? (
                    <p className="requiredText">{addRule.errors.templateId}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Status
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="status"
                      label="status"
                      name="status"
                      options={ruleStatusList?.data?.data}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addRule.setFieldValue(
                          "status",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      value={
                        addRule?.values?.status
                          ? ruleStatusList?.data?.data?.find(
                              (option) => option.key === addRule?.values?.status
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Status"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addRule.touched.status && addRule.errors.status ? (
                    <p className="requiredText">{addRule.errors.status}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveRule}>
            {title === "Create new SMS rule" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default RulesModal;
