import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import deleteIcon from "../../../assets/img/delete_icon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const VehicleOdometerModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  vehiclebyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postOdoMeter = usevehicleStore((state) => state?.postOdoMeter);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const vehicleList = usevehicleStore((state) => state?.vehicleList);
  const [resetKey, setResetKey] = useState(0);
  const addVehicleOdometer = useFormik({
    initialValues: {
      vehicleCode: "",
      odometer: "",
    },
    validationSchema: Yup.object({
      vehicleCode: Yup.string().required("Vehicle is required"),
      odometer: Yup.number()
      .typeError("Odometer value must be a number")
      .required("Odometer is required")
      .positive("Odometer value must be a positive number")
      .integer("Odometer value must be an integer")
      .max(10000000, "Odometer value cannot exceed 10000000"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postOdoMeter(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Vehicle odometer added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    },
  });
useEffect(() => {
  if(title == "Add Vehicle Vendor"){
    addVehicleOdometer.setFieldValue("vehicleCode", "");
    addVehicleOdometer.setFieldValue("odometer", "");
  }
}, [title, vehiclebyCode]);
  const [vehicleCodeErr,setVehicleCodeErr] = useState("")
  const saveVehicleOdometer = (initialValues) => {
      addVehicleOdometer?.handleSubmit();
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleVendors">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Vehicle
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="vehicleCode"
                      label="vehicleCode"
                      name="vehicleCode"
                      options={vehicleList?.data?.data?.content}
                      getOptionLabel={(option) => option.plateNumber}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addVehicleOdometer.setFieldValue(
                          "vehicleCode",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addVehicleOdometer?.values?.vehicleCode
                          ? vehicleList?.data?.data?.content.find(
                              (option) => option.code === addVehicleOdometer?.values?.vehicleCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Vehicle"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {(addVehicleOdometer.touched.vehicleCode &&
                  addVehicleOdometer.errors.vehicleCode)  ? (
                    <p className="requiredText">
                      {addVehicleOdometer.errors.vehicleCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Odometer
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Odometer"
                      value={addVehicleOdometer.values.odometer}
                      onChange={(e) => {
                        addVehicleOdometer.setFieldValue("odometer", e.target.value);
                      }}
                      name="odometer"
                      className="mainInput"
                    />
                  </div>
                  {addVehicleOdometer.touched.odometer &&
                  addVehicleOdometer.errors.odometer ? (
                    <p className="requiredText">
                      {addVehicleOdometer.errors.odometer}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveVehicleOdometer}>
          {title === "Add Vehicle Odometer" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default VehicleOdometerModal;
