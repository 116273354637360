import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getTrips,postTrips,deleteTrips,
  getTripsbyId,editDrivers,editTripVehicle,getTripEvents,getTripStatus} from "../../services/TripMgt/Trip_services";
const initialValues = {
 tripsDetails: [],
 tripsStatus: [],
};
let tripStore = (set, get) => ({
  ...initialValues,
  getTrips: async (params) => {
        const response = await getTrips(params);
        return response;
      },
      postTrips: async (params) => {
        const response = await postTrips(params);
        return response;
      },
      deleteTrips: async (params) => {
        const response = await deleteTrips(params);
        return response;
      },
      getTripsbyId: async (params) => {
        const response = await getTripsbyId(params);
        set({ tripsDetails: response });
        return response;
      },
      getTripStatus: async (params) => {
        const response = await getTripStatus(params);
        set({ tripsStatus: response });
        return response;
      },
      editDrivers: async (params,data) => {
        const response = await editDrivers(params,data);
        return response;
      },
      editTripVehicle: async (params,params2) => {
        const response = await editTripVehicle(params,params2);
        return response;
      },
      getTripEvents: async (params,params2) => {
        const response = await getTripEvents(params,params2);
        return response;
      },
});

tripStore = devtools(tripStore);

export const useTripStore = create(tripStore);