import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import jwtDecode from "jwt-decode";
import { useAclStore } from "../../../store/AclMgt/Aclstore";
import { useroutesStore } from "../../../store/RoutesMgt/RoutesStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const AclModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  aclByCode,
  sealNum,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postAcls = useAclStore((state) => state?.postAcls);
  const editAcls = useAclStore((state) => state?.editAcls);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const AclTypeList = useAclStore((state) => state?.AclTypeList);
  const AclWeightList = useAclStore((state) => state?.AclWeightList);
  const filterZone = useAoneStore((state) => state.filterZone);
  const getZoneGroups = useAoneStore((state) => state.getZoneGroups);
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const [resetKey, setResetKey] = useState(0);
  const [selectedDesOption, setSelectedDesOption] = useState(null);
  const [selectedHubOption, setSelectedHubOption] = useState(null);
  const [selectedDesHubOption, setSelectedDesHubOption] = useState(null);
  const [selectedZoneOption, setSelectedZoneOption] = useState(null);
  const getHubCodeFromToken = () => {
    const token = sessionStorage.getItem("user_access_token");
    if (!token) {
      return "";
    }

    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.attributes?.hubCode || "";
    } catch (error) {
      return "";
    }
  };
  const [currentHub, setCurrentHubCode] = useState("");
  useEffect(() => {
    const hubCode = getHubCodeFromToken();
    setCurrentHubCode(hubCode);
  }, []);
  const addAcl = useFormik({
    initialValues: {
      type: "DESTINATION_ZONE",
      totalWeightUnit: "KG",
      destinationZoneCode: "",
      routeCode: "",
      originHubCode: "",
      destinationHubCode: "",
      sealNumber: "",
    },
    validationSchema: Yup.object({
      totalWeightUnit: Yup.string().trim().required("Weight Unit is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const updatedValues = { ...values };
      if (values.type === "DESTINATION_ZONE") {
        updatedValues.routeCode = null;
        updatedValues.originHubCode = null;
        updatedValues.destinationHubCode = null;
        updatedValues.currentHubCode = currentHub;
      } else if (values.type === "LINE_HAUL") {
        updatedValues.destinationZoneCode = null;
        updatedValues.routeCode = null;
        updatedValues.currentHubCode = currentHub;
      } else {
        updatedValues.originHubCode = null;
        updatedValues.destinationHubCode = null;
        updatedValues.destinationZoneCode = null;
        updatedValues.sealNumber = null;
        updatedValues.currentHubCode = currentHub;
      }
      if (title === "Edit ACL") {
        await editAcls(aclByCode?.number, updatedValues).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("ACL Updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.response?.data?.status == 500) {
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          }
        });
      } else {
        await postAcls(updatedValues).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("ACL Added");
            setispostAdded(!ispostAdded);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.response?.data?.status == 500) {
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          } else {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setResetKey((prevKey) => prevKey + 1);
            setShowModal(false);
          }
        });
      }
    },
  });
  useEffect(() => {
    if (title !== "Edit ACL") {
      addAcl.setFieldValue("type", "DESTINATION_ZONE");
      setResetKey((prevKey) => prevKey + 1);
    }
  }, []);
  const [showZone, setShowZone] = useState(title !== "Edit ACL");
  const [showZoneGroup, setShowZoneGroup] = useState(title !== "Edit ACL");
  const [showOriginHub, setShowOriginHub] = useState(title !== "Edit ACL");
  const [showDesHub, setShowDesHub] = useState(title !== "Edit ACL");
  const handleInputZoneClick = () => {
    if (title === "Edit ACL") {
      setShowZone(true);
    }
  };
  const handleInputZoneGroupClick = () => {
    if (title === "Edit ACL") {
      setShowZoneGroup(true);
    }
  };
  const handleInputOriginHubClick = () => {
    if (title === "Edit ACL") {
      setShowOriginHub(true);
    }
  };
  const handleInputDesHubClick = () => {
    if (title === "Edit ACL") {
      setShowDesHub(true);
    }
  };
  useEffect(() => {
    if (title === "Edit ACL") {
    addAcl.setFieldValue("type", aclByCode?.type);
    addAcl.setFieldValue("totalWeightUnit", aclByCode?.totalWeightUnit);
    addAcl.setFieldValue("destinationZoneCode", aclByCode?.destinationZoneCode);
    addAcl.setFieldValue("routeCode", aclByCode?.routeCode);
    addAcl.setFieldValue("originHubCode", aclByCode?.originHubCode);
    addAcl.setFieldValue("destinationHubCode", aclByCode?.destinationHubCode);
    addAcl.setFieldValue("currentHubCode", aclByCode?.currentHubCode);
    addAcl.setFieldValue("sealNumber", aclByCode?.sealNumber);
    }
  }, [title, aclByCode]);
  const [page, setPage] = useState(-1);
  const [desPage, setDesPage] = useState(-1);
  const [zonePage, setZonePage] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? page + 1 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await filterZone(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
            name: zone.name,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsDesGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : desPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDesPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desPage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsRoutes = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : zonePage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setZonePage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await getZoneGroups(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((route) => ({
            label: route.name,
            value: route.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, zonePage, prevSearch, setPrevSearch]
  );
  const [desZoneError,SetDesZoneError] = useState("");
  const [originHubError,SetOriginHubError] = useState("");
  const [desHubError,SetDesHubError] = useState("");
  const saveAcl = (initialValues) => {
    if(addAcl?.values?.type == "DESTINATION_ZONE" && (addAcl?.values?.destinationZoneCode == undefined || addAcl?.values?.destinationZoneCode == "")){
      SetDesZoneError("Destination Zone is required")
    } else if(addAcl?.values?.type == "LINE_HAUL" && (addAcl?.values?.originHubCode == undefined || addAcl?.values?.originHubCode == "")){ 
      SetOriginHubError("Origin Hub is required")
    } else if(addAcl?.values?.type == "LINE_HAUL" && (addAcl?.values?.destinationHubCode == undefined || addAcl?.values?.destinationHubCode == "")){ 
      SetDesHubError("Destination Hub is required")
    } else{
      addAcl?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollAcl">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-12">
                <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                  Type
                </label>
                <div className="custom-radio-container">
                  {AclTypeList?.data?.data
                    .map((type, index) => (
                      <div key={index} className="form-check custom-radio">
                        <input
                          className="form-check-input custom-radio-input"
                          type="radio"
                          name="aclType"
                          id={`radio-${type.key}`}
                          value={type.key}
                          disabled={title === "Edit ACL"}
                          checked={addAcl.values.type == type.key}
                          onChange={() => {
                            addAcl.setFieldValue("type", type.key);
                            setPage(-1);
                            setDesPage(-1);
                            setZonePage(-1);
                          }}
                        />
                        <label
                          className="form-check-label custom-radio-label"
                          htmlFor={`radio-${type.key}`}
                        >
                          {type.label}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Weight Unit
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="totalWeightUnit"
                      label="totalWeightUnit"
                      name="totalWeightUnit"
                      options={AclWeightList?.data?.data}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addAcl.setFieldValue(
                          "totalWeightUnit",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      value={
                        addAcl?.values?.totalWeightUnit
                          ? AclWeightList?.data?.data?.find(
                              (option) =>
                                option.key === addAcl?.values?.totalWeightUnit
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Weight Unit"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addAcl.touched.totalWeightUnit &&
                    addAcl.errors.totalWeightUnit ? (
                      <p className="requiredText">
                        {addAcl.errors.totalWeightUnit}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              {addAcl?.values?.type === "DESTINATION_ZONE" ? (
                  <>
                  <div className="col-md-6">
                    <div className="row alignItemCenter mb_12">
                      <div className="col-md-12">
                        <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                          Destination Zone
                          <span className="bloodDonortext fontWeight600 fontSize16">
                            *
                          </span>
                        </label>
                        {showZone ? (
                          <AsyncSearchInput
                            id="destinationZone"
                            label="destinationZone"
                            name="destinationZone"
                            value={selectedDesOption}
                            loadMoreOptions={loadMoreOptions}
                            shouldLoadMore={shouldLoadMore}
                            getOptionLabel={(zone) => zone.label}
                            getOptionValue={(zone) => zone.value}
                            onChange={(selectedDesOption) => {
                              setSelectedDesOption(selectedDesOption);
                              addAcl.setFieldValue(
                                "destinationZoneCode",
                                selectedDesOption ? selectedDesOption.value : ""
                              );
                              SetDesZoneError("");
                            } }
                            style={{ whiteSpace: "nowrap" }}
                            placeholder="Destination Zone"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuStyle={{ zIndex: 9999 }} />
                        ) : (
                          <input
                            type="text"
                            value={aclByCode?.destinationZoneName}
                            name="regionCode"
                            className="mainInput"
                            onClick={handleInputZoneClick} />
                        )}
                      </div>
                      {desZoneError !== "" ? (
                        <p className="requiredText">
                          {desZoneError}
                        </p>
                      ) : null}
                    </div>
                  </div>
                {/* <div className="row mt_20">
                    <div className="col-md-6">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Seal Number
                          </label>
                          <input
                            type="text"
                            placeholder="Seal Number"
                            value={addAcl.values.sealNumber}
                            onChange={(e) => {
                              addAcl.setFieldValue("sealNumber", e.target.value);
                            } }
                            name="sealNumber"
                            className="mainInput" />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </>
              ) : addAcl?.values?.type === "ROUTE" ? (
                <div className="col-md-6">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Route
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      {showZoneGroup ? (
                        <AsyncSearchInput
                          id="routeCode"
                          label="routeCode"
                          name="routeCode"
                          value={selectedZoneOption}
                          loadMoreOptions={loadMoreOptionsRoutes}
                          shouldLoadMore={shouldLoadMore}
                          getOptionLabel={(zone) => zone.label}
                          getOptionValue={(zone) => zone.value}
                          onChange={(selectedZoneOption) => {
                            setSelectedZoneOption(selectedZoneOption);
                            addAcl.setFieldValue(
                              "routeCode",
                              selectedZoneOption ? selectedZoneOption.value : ""
                            );
                          }}
                          style={{ whiteSpace: "nowrap" }}
                          placeholder="Route"
                          menuPlacement="auto"
                          menuPosition="fixed"
                          menuStyle={{ zIndex: 9999 }}
                        />
                      ) : (
                        <input
                          type="text"
                          value={aclByCode?.routeName}
                          name="routeName"
                          className="mainInput"
                          onClick={handleInputZoneGroupClick}
                        />
                      )}
                    </div>
                    {addAcl.touched.routeCode && addAcl.errors.routeCode ? (
                      <p className="requiredText">{addAcl.errors.routeCode}</p>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
            {/* {(addAcl?.values?.type === "DESTINATION_ZONE" ||
              addAcl?.values?.type === "ROUTE") && (
              <div className="row mt_20">
                <div className="col-md-6">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <div className="smallCheckbox">
                        <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                        <input
                          type="checkbox"
                          id="IsRto"
                          style={{ marginLeft: "5px" }}
                          checked={addAcl.values.isRto}
                          onChange={() => {
                            addAcl.setFieldValue("isRto", !addAcl.values.isRto);
                            if (!addAcl.values.isRto) {
                              addAcl.setFieldValue("isRto", true);
                            }
                          }}
                        />
                        <label className="margintop7" htmlFor="IsRto">
                          Is Rto?
                        </label>
                      </div>
                    </div>
                    {addAcl.touched.isRto && addAcl.errors.isRto ? (
                      <p className="requiredText">{addAcl.errors.isRto}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            )} */}
            {addAcl?.values?.type === "LINE_HAUL" && (
              <>
                <div className="row mt_20">
                  <div className="col-md-6">
                    <div className="row alignItemCenter mb_12">
                      <div className="col-md-12">
                        <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                          Origin Hub
                          <span className="bloodDonortext fontWeight600 fontSize16">
                            *
                          </span>
                        </label>
                        {showOriginHub ? (
                          <AsyncSearchInput
                            id="originHubCode"
                            label="originHubCode"
                            name="originHubCode"
                            value={selectedHubOption}
                            loadMoreOptions={loadMoreOptionsGroups}
                            shouldLoadMore={shouldLoadMore}
                            getOptionLabel={(zone) => zone.label}
                            getOptionValue={(zone) => zone.value}
                            onChange={(selectedHubOption) => {
                              setSelectedHubOption(selectedHubOption);
                              addAcl.setFieldValue(
                                "originHubCode",
                                selectedHubOption ? selectedHubOption.value : ""
                              );
                              SetOriginHubError("");
                            }}
                            style={{ whiteSpace: "nowrap" }}
                            placeholder="Origin Hub"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuStyle={{ zIndex: 9999 }}
                          />
                        ) : (
                          <input
                            type="text"
                            value={aclByCode?.originHubName}
                            name="originHubName"
                            className="mainInput"
                            onClick={handleInputOriginHubClick}
                          />
                        )}
                        {originHubError !== "" ? (
                      <p className="requiredText">
                        {originHubError}
                      </p>
                    ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row alignItemCenter mb_12">
                      <div className="col-md-12">
                        <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                          Destination Hub
                          <span className="bloodDonortext fontWeight600 fontSize16">
                            *
                          </span>
                        </label>
                        {showDesHub ? (
                          <AsyncSearchInput
                            id="destinationHubCode"
                            label="destinationHubCode"
                            name="destinationHubCode"
                            value={selectedDesHubOption}
                            loadMoreOptions={loadMoreOptionsDesGroups}
                            shouldLoadMore={shouldLoadMore}
                            getOptionLabel={(zone) => zone.label}
                            getOptionValue={(zone) => zone.value}
                            onChange={(selectedDesHubOption) => {
                              setSelectedDesHubOption(selectedDesHubOption);
                              addAcl.setFieldValue(
                                "destinationHubCode",
                                selectedDesHubOption
                                  ? selectedDesHubOption.value
                                  : ""
                              );
                              SetDesHubError("");
                            }}
                            style={{ whiteSpace: "nowrap" }}
                            placeholder="Destination Hub"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuStyle={{ zIndex: 9999 }}
                          />
                        ) : (
                          <input
                            type="text"
                            value={aclByCode?.destinationHubName}
                            name="destinationHubName"
                            className="mainInput"
                            onClick={handleInputDesHubClick}
                          />
                        )}
                          {desHubError !== "" ? (
                      <p className="requiredText">
                        {desHubError}
                      </p>
                    ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row mt_20">
                  <div className="col-md-6">
                    <div className="row alignItemCenter mb_12">
                      <div className="col-md-12">
                        <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                          Seal Number
                        </label>
                        <input
                          type="text"
                          placeholder="Seal Number"
                          value={addAcl.values.sealNumber}
                          onChange={(e) => {
                            addAcl.setFieldValue("sealNumber", e.target.value);
                          }}
                          name="sealNumber"
                          className="mainInput"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveAcl}>
            {title == "Edit ACL" ? "Update" : "Save"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AclModal;
