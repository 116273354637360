import React, { useEffect, useState } from 'react'
import MainTable from '../../components/Tables/mtable'
import { useParams, useNavigate } from 'react-router'
import { useAoneStore } from '../../store/AoneStore'
import { logout } from '../../helper/auth_helper'

const DetailedManagement = () => {

    const params = useParams();
    const navigate = useNavigate();

    const getCustomers = useAoneStore((state) => state?.getCustomers)
    const updateCustomer = useAoneStore((state) => state?.updateCustomers)

    const [tab, setTab] = useState(true)
    const [customer, setCustomer] = useState({})
    const [subAccounts, setSubAccounts] = useState([])

    const getData = async () => {
        const id = params.id
        const paramsQuery = `?page=${0}&size=${1}&mainAccount=${id}`
        const data = await getCustomers(paramsQuery)
        setCustomer(data.data[0])
        setSubAccounts(data.data[0]?.subAccountList)
    }

    const updateRole = async () => {
        await updateCustomer(customer)
        setEditHandler(!editHandler)
    }

    console.log("Customer", customer);

    const [editHandler,setEditHandler] = useState(false)

    const handleChange = (name, value) => {
        console.log("WHY SO",name,value);
        let data = {...customer}
        data[name] = value
        setCustomer(data)
    }

    useEffect(()=>{
        getData();
    },[editHandler,tab])

  return (
    <div>
        <div className='row alignItemCenter'>
          <div className='col-md-7'>
            <h3 className='mainheadtext' >
              <span className='mainheadlink' onClick={()=>navigate("/management")} >Customer Management</span>/ 
              <span className='mainheadlink'>{params.id}</span> 
            </h3>
          </div>
        </div>

        <div className='displayFlex tabletab_section mt_20 mb_24'>
          <button className={`filterBtn ${tab && `activeFilterBtn`}`} onClick={()=>setTab(true)}>Customer Details</button>
          <button className={`filterBtn ${!tab && `activeFilterBtn`}`} onClick={()=>setTab(false)}>Sub Accounts</button>
        </div>

        { tab ? 
        <>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Main Account Number:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' placeholder='Account Number' className='mainInput' onChange={(e)=>handleChange(e.target.name,e.target.value)}
                    name='mainAccount' value={customer?.mainAccount} readOnly
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Registration Number:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' placeholder='Registration Number' className='mainInput' onChange={(e)=>handleChange(e.target.name,e.target.value)}
                    name='companyRegistrationNumber' value={customer?.companyRegistrationNumber} disabled={!editHandler}
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Company Name:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' placeholder='Company Name' className='mainInput' onChange={(e)=>handleChange(e.target.name,e.target.value)}
                    name='companyName' value={customer?.companyName} disabled={!editHandler}
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>VAT Number:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' placeholder='VAT Number' className='mainInput' onChange={(e)=>handleChange(e.target.name,e.target.value)}
                    name='companyVatNumber' value={customer?.companyVatNumber} disabled={!editHandler}
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2'>
            </div>
            <div className='col-md-5 displayFlex alignItemCenter justifyContent_end gap_16'>
                { !editHandler &&
                <button className='blue_btn' onClick={()=>setEditHandler(!editHandler)}>
                Edit
                </button>
                }
                { editHandler &&
                <>
                    <button className='blue_btn' onClick={()=>updateRole()}>
                    Save
                    </button>
                    <button  className='cancel_btn' onClick={()=>setEditHandler(!editHandler)}>
                    Cancel
                    </button>
                </>
                }   
            </div>
        </div>
        </>
        :
        <div>
        {/* <div className="displayFlex alignItemCenter justifyContent_start mb_16">
          <div className='displayFlex justifyContent_end'>
            <div className='displayFlex alignItemCenter InputGroup '>
                <span className='inputsearch'>
                <img src={searchicon} className='turnBlack h18'/>
                </span>
                <input type='text' placeholder='Search' className='Maininput'/>
            </div>
          </div>
        </div>  */}

        <MainTable tableData={subAccounts} tbl={"Sub_Accounts"} modalHandler={editHandler} setModalHandler={setEditHandler} title={"Sub Accounts"}/>          
        </div>
        }
  
    </div>
  )
}

export default DetailedManagement