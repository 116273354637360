import React , {useState} from "react";
import Pagination from "react-js-pagination";

const PaginationCusTable = ({
  tableHeader,
  tableBody,
  tableData,
  tbl,
  newPage,
  setNewPage,
  size,
  setSize,
  totalCount,
  deleteHandler,
  setDeleteHandler,
  isDropdownOpen,
  isDropdownOpens,
  isDropdownselectOpen,
  isDropdownselectOpencurrency,
  isDropdownselectOpenconv,
  isReactselectOpen,
  isReactdesCountry,
  isReactdesBulk,
  isReactselectCity,
  isMenuOpen,
  isReactCurrency,
  isReactRatestate,
  isReactRates,
  isReactCharges,
  isReactdesCity,
  isReactdesRegion,
  isReactselectRegion,
  isReactoriginselectCity
}) => {
  const [mul, setMul] = useState([20, 50, 100]);
  const [customdropdownOne, setCustomdropdownone] = useState(null);
  const paginationHandler = (e) => {
    setNewPage(e - 1);
  };
  return (
    <div className="table_wrapper overflowScroll mb_24 showTenRows">
      <table>
        <thead
          className="cursorPointer"
          style={{
            zIndex:
              // Replace this logic with the specific conditions you need
              isDropdownOpen ||
              isDropdownOpens ||
              isDropdownselectOpen ||
              isDropdownselectOpencurrency ||
              isDropdownselectOpenconv ||
              isReactselectOpen ||
              isReactdesCountry ||
              isReactdesBulk ||
              isReactselectCity ||
              isReactselectRegion ||
              isReactoriginselectCity ||
              isReactdesCity ||
              isReactdesRegion ||
              isReactRates ||
              isReactCharges ||
              isReactCurrency ||
              isReactRatestate ||
              isMenuOpen ||
              ["Cod", "Freight", "Fuel_in_perc", "Fuel_in_kg", "Lval", "Hval", "Insurance", "ODA", "Rto", "VAT"].includes(tbl)
                ? "auto"
                : 999,
          }}
        >
          <tr>{tableHeader}</tr>
        </thead>
        <tbody>
          {tableData?.length > 0 ? (
            tableData?.map((e, i) => (
              <tr className="cursorPointer" key={i}>
                {tableBody(e, newPage * size + (i + 1))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={tableHeader.length}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="displayFlex alignItemCenter justifyContent_spacebetween">
        <div className="displayFlex gap_16 alignItemCenter">
          <label className="fontSize14 blackText">Show </label>
          <div className="positionRelative">
            <input
              type="text"
              readOnly
              placeholder={size}
              className="seletDropdownIcOnPage"
              onClick={() => setCustomdropdownone(!customdropdownOne)}
            />
            {customdropdownOne && (
              <div className="dropdownResult appearTop">
                {mul?.map((d, i) => (
                  <div
                    key={i}
                    className="showPerItem"
                    onClick={() => {
                      setCustomdropdownone(!customdropdownOne);
                      setSize(d);
                      setNewPage(0);
                    }}
                  >
                    {d}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="customPagination mt_10">
          <Pagination
            activePage={newPage + 1}
            itemsCountPerPage={size}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={paginationHandler}
          />
        </div>
        <div>
          <p>
            {totalCount === tableData?.length
              ? totalCount
              : newPage * size + 1}
            {(newPage + 1) * size < totalCount &&
              (newPage + 1) * size !== newPage * size + 1 && (
                <span>-{(newPage + 1) * size}</span>
              )}
            /{totalCount}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaginationCusTable;
