import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useShortAddressStore } from "../../../store/ShortAddress/ShortAddressStore";
import "react-datepicker/dist/react-datepicker.css";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
const ShortAddressModal = ({
  onSuccess,
  setShowModal,
  title,
  addressbyCode,
  paramCountry
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const EditVehicleDocument = usevehicleStore(
    (state) => state?.EditVehicleDocument
  );
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const getCitybyCode = useAoneStore((state) => state.getCitybyCode);
  const districtbyCode = useAoneStore((state) => state?.districtbyCode);
  const getCountryByCity = useAoneStore((state) => state?.getCountryByCity);
  const getDistrictbyCode = useAoneStore((state) => state?.getDistrictbyCode);
  const editShortAddressbyId = useShortAddressStore((state) => state?.editShortAddressbyId);
  const citybyCode = useAoneStore((state) => state?.citybyCode);
  const [showdesTransitCity, setShowDesTransitCity] = useState(
    title !== "Edit Transit Time"
  );
  const editAddress = useFormik({
    initialValues: {
      splRegionId: "",
      splRegionName: "",
      splGovernorate: "",
      splCityId: "",
      splCityName: "",
      splDistrictId: "",
      splDistrictName: "",
      regionCode: "",
      cityCode: "",
      districtCode: ""
    },
    validationSchema: Yup.object({
      regionCode: Yup.string().trim().required("Region is required"),
      cityCode: Yup.string().trim().required("City is required"),
      districtCode: Yup.string().required("District is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
        await editShortAddressbyId(paramCountry,addressbyCode?.id, values).then(
          (response) => {
            if (response?.data?.status == "SUCCESS") {
              toast.success("Address mapping updated");
              setispostAdded(!ispostAdded);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.errors?.length > 0) {
              const errorMessages = response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.status == "ERROR" || response?.data?.status == 500) {
              const errorMessage = response?.data?.error;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.status == "ERROR"  || response?.response?.data?.status == 500) {
              const errorMessage = response?.response?.data?.error;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.errors?.length > 0) {
              const errorMessages = response?.response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            }
          }
        );
    },
  });
  useEffect(()=>{
    getCities();
   editAddress.setFieldValue("splRegionId",addressbyCode?.splRegionId);
   editAddress.setFieldValue("splRegionName",addressbyCode?.splRegionName);
   editAddress.setFieldValue("splGovernorate",addressbyCode?.splGovernorate);
   editAddress.setFieldValue("splCityId",addressbyCode?.splCityId);
   editAddress.setFieldValue("splCityName",addressbyCode?.splCityName);
   editAddress.setFieldValue("splDistrictId",addressbyCode?.splDistrictId);
   editAddress.setFieldValue("splDistrictName",addressbyCode?.splDistrictName);
   editAddress.setFieldValue("regionCode",addressbyCode?.regionCode);
   editAddress.setFieldValue("cityCode",addressbyCode?.cityCode);
   editAddress.setFieldValue("districtCode",addressbyCode?.districtCode);
  },[addressbyCode])
  const getCities = async() =>{
    let res = getCitybyCode(paramCountry,addressbyCode?.regionCode);
    let resDistrict = getDistrictbyCode(paramCountry,addressbyCode?.regionCode,addressbyCode?.cityCode);
  }
  const handleInputdesTransitClick = () => {
    if (title === "Edit Transit Time") {
      setShowDesTransitCity(true);
    }
  };
  const saveAddressMapping = (initialValues) => {
      editAddress?.handleSubmit();
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">Edit address mapping</h4>
      </Modal.Header>
      <div className="modelScrollAddress">
        <Modal.Body>
          <form>
                <div className="row">
                  <div className="col-md-6 mt_20">
                    <div className="borderFrame mb_16">
                      <div className="borderFrameTitle">Short Address</div>
                      <div className="mt_-15">
                        <div>
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          SPL Region:
                        </label>
                        <input
                        type="text"
                        value={editAddress.values.splRegionId}
                        name="splRegionId"
                        placeholder="SPL Region"
                        className="mainInput"
                        disabled
                      />
                        </div>
                        <div className="mt_12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          SPL Governorate:
                        </label>
                        <input
                        type="text"
                        value={editAddress.values.splGovernorate}
                        name="splGovernorate"
                        placeholder="SPL Governorate"
                        className="mainInput"
                        disabled
                      />
                        </div>
                        <div className="mt_12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          SPL City:
                        </label>
                        <input
                        type="text"
                        value={editAddress.values.splCityName}
                        name="days"
                        placeholder="SPL City"
                        className="mainInput"
                        disabled
                      />
                        </div>
                        <div className="mt_12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          SPL District:
                        </label>
                        <input
                        type="text"
                        value={editAddress.values.splDistrictName}
                        name="splDistrictName"
                        placeholder="SPL District"
                        className="mainInput"
                        disabled
                      />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt_20">
                    <div className="borderFrame mb_16">
                      <div className="borderFrameTitle">AJEX Address</div>
                      <div>
                      <div className="mt_-15">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Region:
                        </label>
                        <SearchInput
                          id="regionCode"
                          label="regionCode"
                          name="regionCode"
                          isClearable={true}
                          options={regionsData?.data?.data?.content || []}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.code}
                          handleChange={async (selectedOption) => {
                            editAddress.setFieldValue(
                              "regionCode",
                              selectedOption ? selectedOption.code : ""
                            );
                            try {
                              const cityData = await getCitybyCode(
                                selectedOption.countryIsoCode3,
                                selectedOption.code
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          value={
                            regionsData?.data?.data?.content?.find(
                              (region) =>
                                region.code === editAddress.values.regionCode
                            ) || null
                          }
                          isSearchable={true}
                          placeholder="Region"
                        />
                        {editAddress.touched.regionCode &&
                        editAddress.errors.regionCode ? (
                          <p className="requiredText">
                            {editAddress.errors.regionCode}
                          </p>
                        ) : null}
                        </div>
                        <div className="mt_12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          City:
                        </label>
                        <SearchInput
                          id="cityCode"
                          label="cityCode"
                          name="cityCode"
                          isClearable={true}
                          options={citybyCode || []}
                          getOptionLabel={(region) => region.name}
                          getOptionValue={(region) => region.code}
                          handleChange={async(selectedOption) =>{
                            editAddress.setFieldValue(
                              "cityCode",
                              selectedOption ? selectedOption.code : ""
                            );
                            const districtData = await getDistrictbyCode(
                              selectedOption.countryIsoCode3,
                              selectedOption.regionCode,
                              selectedOption.code
                            );
                          }
                          }
                          value={
                            citybyCode?.find(
                              (city) =>
                                city.code === editAddress.values.cityCode
                            ) || null
                          }
                          isSearchable={true}
                          placeholder="City"
                        />
                        {editAddress.touched.cityCode &&
                        editAddress.errors.cityCode ? (
                          <p className="requiredText">
                            {editAddress.errors.cityCode}
                          </p>
                        ) : null}
                        </div>
                        <div className="mt_12 mb_80">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          District:
                        </label>
                          <SearchInput
                            id="districtCode"
                            label="districtCode"
                            name="districtCode"
                            isClearable={true}
                            options={districtbyCode || []}
                            getOptionLabel={(region) => region.name}
                            getOptionValue={(region) => region.code}
                            handleChange={(selectedOption) =>
                              editAddress.setFieldValue(
                                "districtCode",
                                selectedOption ? selectedOption.code : ""
                              )
                            }
                            value={
                                districtbyCode?.find(
                                (city) =>
                                  city.code ===
                                  editAddress.values.districtCode
                              ) || null
                            }
                            isSearchable={true}
                            placeholder="District"
                          />
                        {editAddress.touched.districtCode &&
                        editAddress.errors.districtCode ? (
                          <p className="requiredText">
                            {editAddress.errors.districtCode}
                          </p>
                        ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveAddressMapping}>
            Save
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Discard
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ShortAddressModal;
