import axios from "axios";
import { logout } from "../helper/auth_helper";
import SvgLoader from "../components/loader/Svgloader";
import { createRoot } from "react-dom/client";
const url = process.env.REACT_APP_BASE_API_URL;
const timezoneOffset = new Date().getTimezoneOffset() / 60;
const formattedTimezoneOffset = timezoneOffset.toString();
const AoneService = axios.create({
  baseURL: url,
  headers: {
    Connection: "keep-alive",
    Timezone: formattedTimezoneOffset,
  },
});
AoneService.interceptors.request.use(
  function (config) {
    let a = config.url;

    // Create a div element for the loader
    const loaderContainer = document.createElement("div");
    loaderContainer.setAttribute("class", "loaderContainer");
    loaderContainer.setAttribute("id", "Loader");
    // Append the loader container to the body
    const root = createRoot(loaderContainer);
    root.render(<SvgLoader />);
    // Append the loader container to the body
    document.body.appendChild(loaderContainer);

    // Add Authorization header if a token exists
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token ?? "notok"}`;
    }

    return config;
  },
  function (error) {
    // Remove the loader element on request failure
    const element = document.getElementById("Loader");
    element?.remove();
    return Promise.reject(error);
  }
);
AoneService.interceptors.response.use(
  function (response) {
    // spinning hide
    const element = document.getElementById("Loader");
    element?.remove();
    return response;
  },
  function (error) {
    const element = document.getElementById("Loader");
    element?.remove();

    if (error.response && error.response.status === 401) {
      console.log("error.response.status---->", error.response.status);
      logout();
    }
    // Place your additional error handling code here

    return Promise.reject(error);
  }
);

export default AoneService;
