import React, {
    useState,
    useRef,
    useEffect,
    useContext,
    useCallback,
  } from "react";
  import { useNavigate, useLocation } from "react-router";
  import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import templateIcon from "../../../assets/img/template_icon.svg";
  import importIcon from "../../../assets/img/importIcon.svg";
  import downloadicon from "../../../assets/img/download_icon.svg";
  import removeIcon from "../../../assets/img/cancelIcon.svg";
  import syncicon from "../../../../src/assets/img/sync.svg";
  import { toast } from "react-toastify";
  import { useAoneStore } from "../../../store/AoneStore";
  import fileDownload from "js-file-download";
  import * as XLSX from "xlsx";
  import { roleHandler } from "../../../helper/roleHandler";
  import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
  import PaginationTable from "../../../components/Tables/paginationTable";
  import CusModal from "../../../components/modal/CusModal";
  import { useDriverStore } from "../../../store/DriverMgt/driverStore";
  import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
  import DriversModal from "./DriversModel";
  import DeletingModal from "../../../components/modal/deletingModal";
  import { UserContext } from "../../../helper/ProtectedRoute";
const Drivers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [editPage, setEditPage] = useState(false);
    const [newPage, setNewPage] = useState(0);
    const [code,setCode] = useState("")
    const [tripId,setTripId] = useState("")
    const [payrollType,setPayrollType] = useState("")
    const [payrollCompany,setPayrollCompany] = useState("")
    const [VehicleActive,SetVehicleActive] = useState("")
    const [size, setSize] = useState(20);
    const [totalCount, setTotalCount] = useState(0);
    const [driverbyCode, setDriverbyCode] = useState([]);
    const [isActivated, SetisActivated] = useState(false);
    const [state] = useContext(UserContext);
    const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
    useEffect(() => {
      setRole(state.roles);
    }, [state.roles]);
    const saveHandler = useAoneStore((state) => state?.saveHandler);
    const [driversData, setDriversData] = useState([]);
    const [deleteHandler, setDeleteHandler] = useState(false);
    const [sortBy, setSortBy] = useState("-updatedAt");
    const [sortField, setSortField] = useState("-updatedAt");
    const getDrivers = useDriverStore((state) => state?.getDrivers);
    const driverTemplate = useDriverStore((state) => state?.driverTemplate);
    const importDriver = useDriverStore((state) => state?.importDriver);
    const exportDrivers = useDriverStore((state) => state?.exportDrivers);
    const getPayrollCompany = useDriverStore((state) => state?.getPayrollCompany);
    const getPayrollType = useDriverStore((state) => state?.getPayrollType);
    const payrollCompanyList = useDriverStore((state) => state?.payrollCompanyList);
    const payrollTypeList = useDriverStore((state) => state?.payrollTypeList);
    const ispostAdded = useAoneStore((state) => state?.ispostAdded);
    const setispostAdded = useAoneStore((state) => state?.setispostAdded);
    const getEmployee = useAoneStore((state) => state?.getEmployee);
    const getCountries = useAoneStore((state) => state?.getCountries);
    const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
    const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
    const vehicleList = usevehicleStore((state) => state?.vehicleList);
    const getVehicles = usevehicleStore((state) => state?.getVehicles);
    const isReactselectRegion = useAoneStore(
      (state) => state?.isReactselectRegion
    );
    useEffect(() => {
      let paramslink = `?page=${0}&size=${50}&jobTitle=Linehaul Driver`;
      let param = `?page=${0}&size=${30}`
      getEmployee(paramslink);
      if (
        vehicleList?.data?.data?.content?.length < 1 ||
        vehicleList?.length < 1
      ) {
        getVehicles(param);
      }
    }, []);
    const setReactSelectregion = useAoneStore(
      (state) => state?.setReactSelectregion
    );
    const handleMenudescountry = () => {
      setReactDesCountry(true);
    };
    const handleMenuClosedescountry = () => {
      setReactDesCountry(false);
    };
    const handleMenuOpencity = () => {
      setReactSelectcity(true);
    };
    const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
    const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
    const handleMenuClosecity = () => {
      setReactSelectcity(false);
    };
    const syncronizeRoles = async () => {
      setNewPage(0);
      getData();
    };
    const downloadTemplate = async () => {
      try {
        const res = await driverTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Drivers Template.xlsx`);
        }
      } catch (err) {}
    };
    const handleMenuOpenregion = () => {
      setReactSelectregion(true);
    };
    const handleMenuCloseregion = () => {
      setReactSelectregion(false);
    };
    const getData = async () => {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (code) {
        params += `&code=${code}`;
      }
      if (tripId) {
        params += `&tripId=${tripId}`;
      }
      if (payrollType) {
        params += `&payrollType=${payrollType}`;
      }
      if (payrollCompany) {
        params += `&payrollCompany=${payrollCompany}`;
      }
      if (VehicleActive) {
        params += `&activeVehicle=${VehicleActive}`;
      }
      const data = await getDrivers(params);
      setTotalCount(data?.data?.data?.totalElements);
      setDriversData(data?.data?.data?.content);
    };
    const exportToExcel = async () => {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (code) {
        params += `&code=${code}`;
      }
      if (tripId) {
        params += `&tripId=${tripId}`;
      }
      if (payrollType) {
        params += `&payrollType=${payrollType}`;
      }
      if (payrollCompany) {
        params += `&payrollCompany=${payrollCompany}`;
      }
      if (VehicleActive) {
        params += `&activeVehicle=${VehicleActive}`;
      }
        try {
          const res = await exportDrivers(params);
          if (res.status == 200) {
            fileDownload(res.data, `Drivers.xlsx`);
          }
        } catch (err) {}
       
    };
    useEffect(() => {
      if (payrollCompanyList?.data?.data === undefined) {
        getPayrollCompany();
      }
      if (payrollTypeList?.data?.data === undefined) {
        getPayrollType();
      }
    }, []);
    useEffect(() => {
      let paramslink = `?page=${0}&size=${20}`;
      getCountries(paramslink);
    }, []);
    //bulk import
    const handleFileChange = async (event) => {
      const fileInput = event.target;
      const file = fileInput.files[0];
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
  
          // Assume the first sheet is the one you're interested in
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
  
          // Check if the sheet has data
          if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
            let formData = new FormData();
            formData.append("file", file);
  
            // Check if the sheet has at least one row of data
            if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
              // Get the range of the Locales Name column (assuming it's the third column)
              const localesColumnRange = getColumnRange(sheet, 3);
              importDriver(formData)
                .then((response) => {
                  if (response?.data?.status === "SUCCESS") {
                    getData();
                    setispostAdded(!ispostAdded)
                    toast.success("File uploaded successfully!");
                    fileInput.value = "";
                  } else if (response?.data?.errorCode !== "" && response?.data?.errorCode !== undefined) {
                    const errorMessage = response?.data?.msg;
                    toast.error(errorMessage);
                    fileInput.value = "";
                  } else if (response?.data?.status === "ERROR") {
                    // Handle error response from the server
                    const errorMessages = response?.data?.data
                      .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                      .join(", ");
                    toast.error(errorMessages);
                    fileInput.value = "";
                  } else if (response?.data?.data?.status === "ERROR") {
                    // Handle error response from the server
                    const errorMessages = response?.data?.data
                      .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                      .join(", ");
                    toast.error(errorMessages);
                    fileInput.value = "";
                  } else if (
                    response &&
                    response?.response?.data?.status === "ERROR"
                  ) {
                    // Handle error response from the server
                    const errorMessage =
                      response?.response?.data?.msg?.split(",")[0];
                    toast.error(errorMessage);
                    fileInput.value = "";
                  } else if (response?.data?.status === "ERROR") {
                    const errorMessage = response?.data?.msg?.split(",")[0];
                    toast.error(errorMessage);
                    fileInput.value = "";
                  } else if (
                    response?.data?.data?.length > 0 &&
                    response?.data?.status === "ERROR"
                  ) {
                    // Handle error response from the server
                    const errorMessages = response?.data?.data
                      .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                      .join(", ");
                    const errorMessage =
                      response?.response?.data?.msg?.split(",")[0];
                    toast.error(errorMessage);
                    fileInput.value = "";
                  } else if (response?.response?.data?.status === "ERROR") {
                    const errorMessage =
                      response?.response?.data?.msg?.split("!")[0];
                    toast.error(errorMessage);
                    fileInput.value = "";
                  } else {
                    getData();
                    toast.success("File uploaded successfully!");
                    fileInput.value = "";
                  }
                })
                .catch((error) => {
                  toast.error("Error reading the file. Please try again.");
                  fileInput.value = "";
                });
            } else {
              // Show error toast for empty sheet
              toast.error(
                "Error: The sheet is empty. Please provide valid data."
              );
              fileInput.value = "";
            }
          } else {
            // Show error toast for invalid sheet
            toast.error(
              "Error: Invalid sheet. Please provide a valid Excel file."
            );
            fileInput.value = "";
          }
        } catch (error) {
          // Handle any errors during file reading
          toast.error("Error reading the file. Please try again.");
          fileInput.value = "";
        }
      };
  
      // Read the file as array buffer
      reader.readAsArrayBuffer(file);
    };
    // Helper function to check if the sheet has data
    function hasDataInSheet(sheet) {
      return Object.keys(sheet).some(
        (cell) => cell !== "!ref" && sheet[cell].v !== undefined
      );
    }
    function getColumnRange(sheet, columnNumber) {
      const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
      const range = sheet["!ref"].split(":");
      return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
    }
    useEffect(() => {
      getData();
    }, [
      newPage,
      sortBy,
      size,
      editPage,
      sortField,
      code,
      location,
      saveHandler,
      tripId,
      payrollType,
      VehicleActive,
      payrollCompany,
      ispostAdded,
      isActivated
    ]);
    const [title, setTitle] = useState("Create new driver");
    const [showModal, setShowModal] = useState(false);
    const [deleteParams, setDeleteParams] = useState({
      id: "",
      name: "",
      title: "",
    });
    const handleSuccess = () => {
      setShowModal(false);
    };
    const handleError = (error) => {
    };
    return (
      <div>
        <div>
          {!editPage && (
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Code:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                        type="text"
                        value={code}
                        className={
                            code?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                            code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                        }
                        onChange={(e) => setCode(e.target.value)}
                      />
                {code && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setCode("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Trip ID:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={tripId}
                        className={
                            tripId?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                            tripId?.length > 0 ? { border: "2px solid #ec171c" } : {}
                        }
                        onChange={(e) => setTripId(e.target.value)}
                      />
                         {tripId && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setTripId("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Payroll Type:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10">
                    <SearchInput
                      id="payrollType"
                      label="payrollType"
                      name="payrollType"
                      options={payrollTypeList?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        setPayrollType(selectedOption ? selectedOption.key : "");
                      }}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Payroll Type"
                      customStyles={{ control: { width: "165px" } }}
                      className="mainInput"
                    />
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Payroll Company:
                    </label>
                    <SearchInput
                      id="payrollCompany"
                      label="payrollCompany"
                      name="payrollCompany"
                      options={payrollCompanyList?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        setPayrollCompany(selectedOption ? selectedOption.key : "");
                      }}
                      onMenuOpen={handleMenudescountry}
                      onMenuClose={handleMenuClosedescountry}
                      menuIsOpen={isReactdesCountry}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Payroll Company"
                      customStyles={{ control: { width: "165px" } }}
                      className="mainInput"
                    />
                  </Col>
                  <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                  Active Vehicle:</label>
                  <div className="displayFlex alignItemCenter marginR10">
                <SearchInput
                  id="VehicleActive"
                  label="VehicleActive"
                  name="VehicleActive"
                  options={vehicleList?.data?.data?.content || []}
                  getOptionLabel={(option) => option.code}
                  getOptionValue={(option) => option.code}
                  handleChange={(selectedOption) => {
                    SetVehicleActive(selectedOption ? selectedOption.code : "");
                  }}
                  onMenuOpen={handleMenuOpencity}
                  onMenuClose={handleMenuClosecity}
                  menuIsOpen={isReactselectCity}
                  customStyles={{ control: { width: "165px" } }}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Active Vehicle"
                  className={
                    VehicleActive?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
                </div>
              </Col>
                </Row>
              </div>
              <div className="margin_top40">
                <div className="displayFlex gap_12">
                  <>
                    <>
                    {(roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.admin")) && (
                      <><button
                          style={{ marginLeft: "10px" }}
                          className="filterBtn plus_icon"
                          title="Driver Template"
                          onClick={downloadTemplate}
                        >
                          <span>
                            <img src={templateIcon} className="h18" />
                          </span>
                        </button><div title="Import Driver">
                            <label
                              className="filterBtn plus_icon"
                              htmlFor="uploadFile"
                            >
                              <span>
                                <img src={importIcon} className="h18" />
                              </span>
                              <input
                                type="file"
                                id="uploadFile"
                                onInput={(e) => handleFileChange(e)}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                hidden />
                            </label>
                          </div></>)}
                    </>
                  </>
                  <button
                className="filterBtn"
                title={"Export Drivers"}
                onClick={() => exportToExcel()}
              >
                <span>
                  <img src={downloadicon} className="h18" />
                </span>
              </button>
                  <button
                    className="filterBtn plus_icon"
                    title="Sync"
                    onClick={() => syncronizeRoles()}
                  >
                    <span>
                      <img src={syncicon} className="h18" />
                    </span>
                  </button>
                  {(roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    tabIndex="-1" 
                    onClick={() => {
                      setShowModal(true);
                      setTitle("Create new driver");
                    }}
                  >
                    +
                  </button>)}
                </div>
              </div>
            </div>
          )}
        </div>
        {deleteHandler && deleteParams?.title && (
          <DeletingModal
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            deleteParams={deleteParams}
            setDeleteParams={setDeleteParams}
          />
        )}
          <PaginationTable
          tbl="Drivers"
          tableData={driversData}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          isActivated={isActivated}
          SetisActivated={SetisActivated}
          size={size}
          setSortField={setSortField}
          sortField={sortField}
          setSize={setSize}
          totalCount={totalCount}
          setNewPage={setNewPage}
          newPage={newPage}
        />
        <CusModal show={showModal} onHide={() => setShowModal(false)} className="addnewpopup">
        <DriversModal
            onSuccess={handleSuccess}
            onError={handleError}
            setShowModal={setShowModal}
            title={title}
            driverbyCode={driverbyCode}
          />
        </CusModal>
      </div>
    );
  };
  
  export default Drivers;
  