import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

// Get ACL's
export const getAcls = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/acls${params}`)
    } catch (error) {
        return error;
    }
}

//Post ACL's
export const postAcls = async (data) => {
    try {
        return await AoneService.post(`ops/api/v1/acls`,data)
    } catch (error) {
        return error;
    }
}
//edit acl
export const editAcls = async (params,data) => {
  try {
    return await AoneService.put(`ops/api/v1/acls/${params}`,data)
  } catch (error) {
      return error;
  }
}
//Export ACL's
export const exportAcl = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/acls/export`,data, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };

//Bulk import consignments for all acl's
export const importConsignmentsAcl = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/acls/consignment/import`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };

//apply event request
export const editEventRequest = async (params,data) => {
    try {
      return await AoneService.post(`ops/api/v1/acl/events/${params}`,data);
    } catch (error) {
      return error;
    }
  };

//seal an acl
export const sealAcl = async (params,param2) => {
    try {
      return await AoneService.patch(`ops/api/v1/acls/${params}/seal/${param2}`);
    } catch (error) {
      return error;
    }
  };

//assign acl to courier
export const assignAcl = async (params,param2) => {
    try {
      return await AoneService.patch(`ops/api/v1/acls/${params}/courier/${param2}`);
    } catch (error) {
      return error;
    }
  };
//assign acl to courier from delivery task
export const assignAclDeliver = async (data) => {
  try {
    return await AoneService.post(`ops/api/v1/delivery/task/acl`,data);
  } catch (error) {
    return error;
  }
};
//apply event request
export const receiveAclEvent = async (data) => {
  try {
    return await AoneService.post(`ops/api/v1/acl/events/bulk`,data);
  } catch (error) {
    return error;
  }
};
//get acl labels by aclnumber
export const getLabelbyAcl = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/acls/${params}/label`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//export all consignments for current acl
export const exportConsignmentsforAcl = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/acls/${params}/consignment/export`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };

//download the template to upload consignments to an acl
export const AclconsignmentsTemplate = async () => {
    try {
      return await AoneService.get(
        `ops/api/v1/acls/consignment/template`,
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };

//get all acl events by aclNumber
export const getAclEventsbyaclNumber = async (params,params1) => {
    try {
        return await AoneService.get(`ops/api/v1/acl/events/${params}${params1}`)
    } catch (error) {
        return error;
    }
}

//unseal an acl
export const unsealAcl = async (params) => {
    try {
        return await AoneService.patch(`ops/api/v1/acls/${params}/seal`)
    } catch (error) {
        return error;
    }
}

//un assign an acl from courier
export const unAssignAcl = async (params) => {
    try {
        return await AoneService.patch(`ops/api/v1/acls/${params}/courier`)
    } catch (error) {
        return error;
    }
}

//remove the consignments from acl
export const removeAclConsignments = async (params,params2) => {
    try {
        return await AoneService.delete(`ops/api/v1/acls/${params}/consignment/${params2}`)
    } catch (error) {
        return error;
    }
}

//add a consignment to ACL
export const postConsignmentsAcl = async (params,data) => {
    try {
      return await AoneService.post(`ops/api/v1/acls/${params}/consignment`,data);
    } catch (error) {
      return error;
    }
  };

//transfer one consignment from ACL to another ACL
export const transferConsignmentAcl = async (params,data) => {
    try {
      return await AoneService.put(`ops/api/v1/acls/${params}/consignment`,data);
    } catch (error) {
      return error;
    }
  };

//query consignments by aclnumber
export const queryConsignmentByAcl = async (params,data) => {
    try  {
        return await AoneService.get(`ops/api/v1/acls/${params}/consignment${data}`)
    } catch (error) {
        return error;
    }
}

//update acl info by aclnumber
export const updateAclInfo = async (params) => {
    try  {
        return await AoneService.put(`ops/api/v1/acls/${params}`)
    } catch (error) {
        return error;
    }
}

//lookup type
export const getAclTypes = async () => {
  try {
    return await AoneService.get(`ops/api/v1/lookups/ACLType`);
  } catch (error) {
    return error;
  }
};
//acl status
export const getAclStatus = async () => {
  try {
    return await AoneService.get(`ops/api/v1/lookups/ACLStatus`);
  } catch (error) {
    return error;
  }
};
//weightunit
export const getWeightUnit = async () => {
  try {
    return await AoneService.get(`ops/api/v1/lookups/WeightUnit`);
  } catch (error) {
    return error;
  }
};