import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  postSla,
  editSla,
  getSla,
  deleteSla,
  getOrder,
  postOrders,
  postBulkOrder,
  downloadSample,
  printBill,
  postMsg,
  printBulkBill,
  downloadSampleSms,
  uploadReciver,
  getSmsType,
  downloadSamplebulk,
  uploadBulkprint,
  printBag,
  getZidProducts,
  fetchConsignments,
  syncZidProduct,
  putZidProduct,
  getZidProductsByStoreId,
  syncZidProductByStoreId,
  getBillingPeriod,
  getZidMerchants,
  getZidMerchantsByStoreId,
  postZidMerchants,
  putZidMerchants,
  getRoles,
  postRoles,
  getOrders,
  getEvents,
  exportEvents,
  getRoleById,
  updateRoleById,
  deleteRoleById,
  syncRoles,
  getCustomer,
  filterCustomer,
  getOperationCodes,
  getLookups,
  getCalculationType,
  getCustomerType,
  exportCustomer,
  syncCustomer,
  deleteCustomer,
  addCustomer,
  postBillingAddress,
  postBankAddress,
  getCustomerbyId,
  pushOracleCustomer,
  editCustomerbyId,
  editSalesPerson,
  editBillingAddress,
  getBillingbyId,
  getBankdetailbyId,
  editBankAddress,
  getBookingbyId,
  editBookingSystem,
  deletepickupaddress,
  getpickupaddressbyId,
  postpikcupAddress,
  deletepickupaddressbyId,
  editpickupaddress,
  getRtoaddressbyId,
  postRtoAddress,
  editRtoaddress,
  getCountries,
  filterCountries,
  exportCountries,
  exportRegion,
  exportCities,
  exportDistrict,
  exportDistrictMap,
  exportCountryMap,
  exportRegionMap,
  exportCityMap,
  exportUsers,
  getTiers,
  postTiers,
  getTiersbyId,
  updateTiersById,
  deleteTiers,
  getOdTier,
  postOdTier,
  validateOdpair,
  updateOdTierById,
  deleteOdTier,
  getRegions,
  getRegion,
  filterRegions,
  getDistricts,
  filterDistricts,
  getCities,
  filterCities,
  getZone,
  filterZone,
  getZoneById,
  postZones,
  updateZoneById,
  deleteZoneById,
  getPickupReasons,
  postPickupReasons,
  updatePickupReasonsById,
  deletePickupReasonsById,
  getPickupReasonsById,
  exportPickupReasons,
  getPickupCategory,
  getPickupEventCategory,
  getDeliveryEventCategory,
  getOpsCategory,
  getDeliveryReasons,
  postDeliveryReasons,
  updateDeliveryReasonsById,
  deleteDeliveryReasonsById,
  getDeliveryReasonsById,
  exportDeliveryReasons,
  getHoldReasons,
  postHoldReasons,
  updateHoldReasonsById,
  deleteHoldReasonsById,
  getHoldReasonsById,
  exportHoldReasons,
  getHubs,
  getHub,
  filterHubs,
  getHubsbyId,
  postHubs,
  editHubs,
  getHubTypes,
  deleteHubs,
  exportHubs,
  getHubTemplate,
  editHubsbypatch,
  importHub,
  getProducts,
  filterProducts,
  getProductsbyId,
  postProducts,
  editProducts,
  editProductsbypatch,
  getProductsDimension,
  getProductsEvent,
  getProductsWeight,
  getProductCategory,
  getProductsContent,
  deleteProducts,
  exportProducts,
  getProductsTemplate,
  importProducts,
  getFreight,
  getFreightbyId,
  postFreight,
  editFreight,
  editFreightsbypatch,
  deleteFreight,
  bulkFreightDelete,
  bulkRtoDelete,
  bulkCodDelete,
  bulkOdaDelete,
  bulkFuelDelete,
  bulkInsuranceDelete,
  bulkAttemptsDelete,
  exportrateFreight,
  getFreightsTemplate,
  importFreight,
  getCalType,
  getCollectionMethod,
  getContentType,
  getStateRates,
  getDeliveryStatus,
  getPaymentType,
  getState,
  getStructure,
  getRto,
  getRtobyId,
  postRto,
  editRto,
  editRtobypatch,
  deleteRto,
  exportrateRto,
  getRtosTemplate,
  importRto,
  getCod,
  getCodbyId,
  postCod,
  editCod,
  editCodbypatch,
  deleteCod,
  exportrateCod,
  getCodrateTemplate,
  importCod,
  getOda,
  getOdabyId,
  postOda,
  editOda,
  editOdabypatch,
  deleteOda,
  exportrateOda,
  getOdarateTemplate,
  importOda,
  getFuel,
  getFuelbyId,
  postFuel,
  editFuel,
  editFuelbypatch,
  deleteFuel,
  exportrateFuel,
  getFuelrateTemplate,
  importFuel,
  getInsurance,
  getInsurancebyId,
  postInsurance,
  editInsurance,
  editInsurancebypatch,
  deleteInsurance,
  exportrateInsurance,
  getInsurancerateTemplate,
  importInsurance,
  getAttempts,
  getAttemptsbyId,
  postAttempts,
  editAttempts,
  editAttemptsbypatch,
  editAttemptsState,
  editInsuranceState,
  editFuelState,
  editOdaState,
  editCodState,
  editRtoState,
  editFreightState,
  deleteAttempts,
  exportrateAttempts,
  getAttemptsrateTemplate,
  importAttempts,
  getIncoterms,
  getOrigin,
  postOrigin,
  editOrigin,
  deleteOrigin,
  exportOrigin,
  getOriginTemplate,
  importOrigin,
  getDestination,
  postDestination,
  editDestination,
  deleteDestination,
  exportDestination,
  getDestinationTemplate,
  importDestination,
  getTransittime,
  postTransittime,
  editTransittime,
  deleteTransittime,
  exportTransittime,
  getTransittimeTemplate,
  importTransittime,
  getServiceAreas,
  getAllAreasbyHub,
  getAllZonegroupbyHub,
  postServiceAreas,
  editServiceAreas,
  deleteServiceareas,
  allowedeventLookup,
  getServiceAreasTemplate,
  exportServiceAreas,
  importServiceAreas,
  getZoneTemplate,
  exportZones,
  importZones,
  getZoneGroups,
  getZoneGroupById,
  postZonegroup,
  updateZonegroupById,
  deleteZonegroupById,
  renameGroups,
  getZonegroupTemplate,
  exportZonegroup,
  importZonegroup,
  getCurrency,
  postCurrency,
  deleteCurrencyById,
  updateCurrencyById,
  getCurrencyTemplate,
  exportCurrency,
  importCurrency,
  getCurrencyConversion,
  postCurrencyConversion,
  deleteCurrencyConversionById,
  updateCurrencyConversionById,
  getCurrencyConversionTemplate,
  exportCurrencyConversion,
  importCurrencyConversion,
  getCustomers,
  updateCustomer,
  getInvoices,
  updateInvoiceApproveStatus,
  updateInvoiceRejectStatus,
  getGroups,
  getSubGroups,
  updateSubAccounts,
  getInvoiceRequest,
  downloadInvoiceRequest,
  CancelInvoiceRequest,
  getInvoiceRequestTemplate,
  postInvoiceRequestTemplate,
  getInvoiceRequestTemplatebyId,
  downloadConsignments,
  postInvoiceRequest,
  pushInvoicebyId,
  exportPickupInvoice,
  getCodCharge,
  getFreightCharge,
  getFuelCharge,
  getFuelkgcharge,
  getLowvaluecharge,
  getHighvaluecharge,
  getInusranceCharge,
  getOdacharge,
  getRtocharge,
  getVatCharge,
  getConsignments,
  postCountries,
  postCities,
  postDistrict,
  getCountryById,
  getCountryByCity,
  getDesCountryByCity,
  getDistrictById,
  getDistrictGeometry,
  editDistrictGeometry,
  getCityById,
  getRegionbyCode,
  getRegionbyCodeDes,
  getCitybyCode,
  getCitybyCodeDes,
  getDistrictDesbyCode,
  getDistrictbyCode,
  updateCountryById,
  deleteDistrictById,
  deleteCityById,
  updateDistrictById,
  updateCityById,
  postRegions,
  getRegionById,
  getEmployee,
  getEmployeebyId,
  postEmployee,
  getFieldName,
  editEmployees,
  deleteEmployees,
  exportEmployee,
  getEmployeesTemplate,
  importEmployees,
  employeeJobtitle,
  employeedepartment,
  employeePayrolltype,
  employeePayrollCompany,
  employeeLegacySystem,
  getJobtitlebyDept,
  employeeDepartments,
  getAreas,
  postAreas,
  editAreas,
  deleteAreas,
  exportAreas,
  getAreasTemplate,
  importAreas,
  postLoginInfoemployee,
  postAttributes,
  resetPasswordEmployee,
  toggleUserstatus,
  generatePassword,
  updateRegionById,
  deleteCountryById,
  deleteRegionById,
  getCountryMapping,
  postCountryMapping,
  getCountryMapTemplate,
  getRegionMapTemplate,
  getCityMapTemplate,
  getDistrictMapTemplate,
  deleteCountryMappingById,
  getCountryMappingById,
  updateCountryMappingById,
  getRegionMapping,
  postRegionMapping,
  deleteRegionMappingById,
  getRegionMappingById,
  updateRegionMappingById,
  getCityMapping,
  postCityMapping,
  deleteCityMappingById,
  getCityMappingById,
  updateCityMappingById,
  getDistrictMapping,
  postDistrictMapping,
  deleteDistrictMappingById,
  getDistrictMappingById,
  updateDistrictMappingById,
  getBags,
  getChuteConfigs,
  getChuteConfigsById,
  getBagsById,
  getAgentConfigs,
  getMachineScans,
  getSortingConfig,
  exportMachineScans,
  getMapping,
  getThirdparty,
  receiveMachineScan,
  outScanMachineScan,
  postChuteConfigs,
  putChuteConfigs,
  deleteMapping,
  deleteThirdparty,
  deleteChuteConfigs,
  deleteChuteConfigsById,
  deleteChuteConfigsBulkById,
  postChuteConfigsById,
  putChuteConfigsById,
  deleteAgentConfigs,
  postAgentConfigs,
  putAgentConfigs,
  deactivateAgentConfigs,
  postGroups,
  deleteGroupById,
  getChuteByIdTemplate,
  postChuteByIdTemplate,
  getUsers,
  postUsers,
  resetUsersPassword,
  deleteUserById,
  putUsersById,
  getUsersById,
  getAssignedRolesByUserId,
  assignRolesToUserId,
  unassignRolesOfUserId,
  downloadAgents,
  downloadChuteById,
  getUsersByRoleIdOrGroupId,
  syncGroups,
  downloadUsersByRoleId,
  postChildGroups,
  getGroupsTree,
  getJobOrders,
  postJobOrders,
  assignGroupsToUserId,
  unassignGroupsOfUserId,
  getUsersByGroupId,
  getGroupsByUserId,
  assignRolesToGroupId,
  unassignRolesOfGroupId,
  getAssignedRolesByGroupId,
  getAllModules,
  getSubModules,
  getModels,
  getPermissions,
  getJobOrderById,
  putJobOrderById,
  deleteJobOrderById,
  postJobOrdersItemById,
  putJobOrderItemById,
  deleteJobOrderItemById,
  getEmployeeDepartments,
  getEmployees,
  postEmployees,
  postMapping,
  postThirdParty,
  EditThirdparty,
  EditMapping,
  getEmployeeById,
  getMappingTypes,
  putEmployeeById,
  exportEmployees,
  printJobOrderQR,
  downloadJobOrder,
  postBags,
  postWaybill,
  postWaybillByTemplate,
  postCodTemplate,
  postFreightTemplate,
  postFuelTemplate,
  postFuelperkgTemplate,
  postLowvalueTemplate,
  postHighvalueTemplate,
  postInsuranceTemplate,
  postOdaTemplate,
  postRtoTemplate,
  postVatTemplate,
  exportCod,
  exportFreight,
  exportFuel,
  exportFuelperkg,
  exportLowvalue,
  exportHighvalue,
  exportInsurance,
  exportOda,
  exportRto,
  exportVat,
  getCodTemplate,
  getFreightTemplate,
  getFuelTemplate,
  getFuelperkgTemplate,
  getLowvalueTemplate,
  getHighvalueTemplate,
  getInsuranceTemplate,
  getOdaTempalte,
  getRtoTemplate,
  getVatTemplate,
  postCustomerClassification,
  postHighValue,
  postTiersClassification,
  getConsignmentOrders,
  postConsignmentOrders,
  printConsignments,  
  cancelConsignments,
  printBulkConsignments,
  getConsignmentStatus,
  editConsignmentOrders,
  getConsignmentOrdersbyId,
  importConsignmentOrders,
  getConsignmentOrdersTemplate,
  getOrderAddressType,
  getOrderContentType,
  getOrderEventStatus,
  getOrderDimensionUnit,
  getOrderIncoterms,
  getOrderPickupMethod,
  getOrderWeightUnit,
  editOrderIncoterms,
  editOrderDdu,
  editOrderDesitiantion,
  getPackageTrackingId,
  importDistrict,
  importCity,
  importRegion,
  importCountry,
  importCountryMap,
  importRegionMap,
  importCityMap,
  importDistrictMap,
  getCountryTemplate,
  getCitiesTemplate,
  getDistrictTemplate,
  getRegionTemplate,
  editBags,
  outScanBag,
  inScanBag,
  moveConsignment,
  labelBag,
  outScanBulkBag,
  exportBulkBag,
  cancelOrders,
  printOrders,
  assignOrders,
  consignmentBag,
  templateConsigment,
  editWaybillsbyBagId,
  deleteBagById,
  deleteWaybillByBagId,
} from "../services/aone_services";

const initialValues = {
  slaData: [],
  orderData: [],
  mappingData: [],
  zidProductsData: [],
  zidMerchantsData: [],
  rolesData: [],
  customerData: [],
  countriesData: [],
  countryArrayData: [],
  originData: [],
  destinationData: [],
  zoneServiceData: [],
  zoneGroupbyHubData:[],
  regionsData: [],
  districtData: [],
  citiesData: [],
  cityArray: [],
  ModuleList: [],
  SubmoduleList: [],
  ModelList: [],
  PermissionList: [],
  hubsData: [],
  hubTypes: [],
  jobsData: [],
  pickupReasonsData: [],
  deliveryReasonsData: [],
  holdReasonsData: [],
  departmentData: [],
  payrolltypeData: [],
  payrollcompanyData: [],
  legacyData: [],
  allowedeventData: [],
  jobTitleData: [],
  deptData: [],
  dimensionData: [],
  calculationData: [],
  collectionData: [],
  contentData: [],
  stateRateData:[],
  deliveryStatusData: [],
  paymentData: [],
  stateData: [],
  structureData: [],
  productEventData: [],
  productWeightData: [],
  productCategoryData: [],
  productContentData: [],
  selectedZoneCodes: [],
  sortingConfigData: [],
  countryData: [],
  invoiceTempData: [],
  currencyData: [],
  employeeData: [],
  cityData: [],
  districtDatas: [],
  geometryData: [],
  regionData: [],
  tiersData:[],
  zoneData: [],
  regionbyCode: [],
  citybyCode: [],
  consignemntStatus:[],
  regionbyCodeDes: [],
  citybyCodeDes: [],
  districtbyCode: [],
  districtbyDescode: [],
  zoneGroupData: [],
  operationCodes: [],
  serviceTypes: [],
  calculationTypeArray: [],
  billingperiodArray: [],
  customerType: [],
  citybyCountyData: [],
  citybyDesCountyData:[],
  billingAddressData: [],
  rtoAddressData: [],
  billingArrayData: [],
  customerAccountData: [],
  employeeAccountData: [],
  customerArrayData: [],
  bankAddressData: [],
  bankArrayData: [],
  thirdpartyCouriers: [],
  orderAddressType: [],
  orderContentType: [],
  orderEventType: [],
  orderDimensionUnit: [],
  orderIncoterms: [],
  orderPickupMethod: [],
  orderWeightUnit: [],
  packageTrack: [],
  productsData: [],
  tierCountry: "",
  tierName: "",
  globalHubCode: "",
  globalproductCode: "",
  globalcustomerAccount: "",
  globalEmployeeCode: "",
  globalFirstname: "",
  globalJobtitle: "",
  globalEmail: "",
  productsPage: -1,
  customersPage: -1,
  globalEmployeeStatus: false,
  globalUserStatus: false,
  globalHub: false,
  globalPrefferedLanguage: false,
  isEditPickup: false,
  saveHandler: false,
  isdropModalOpen: false,
  isReactselectOpen: false,
  isReactselectCity: false,
  isReactoriginselectCity: false,
  ispostAdded: false,
  isReactselectRegion: false,
  isReactdesCity: false,
  isReactdesCountry: false,
  isReactdesBulk: false,
  isReactdesRegion: false,
  isReactRates: false,
  isReactCharges: false,
  isReactCurrency: false,
  isReactRatestate: false,
  formState: {},
  bankState: {},
  isTabChanging: false,
};

let aoneStore = (set, get) => ({
  ...initialValues,
  getSla: async (data) => {
    const response = await getSla(data);
    set({ slaData: response });
    return response;
  },
  postSla: async (data) => {
    const response = await postSla(data);
    return response;
  },
  editSla: async (data) => {
    const response = await editSla(data);
    return response;
  },
  deleteSla: async (data) => {
    const response = await deleteSla(data);
    return response;
  },
  getOrder: async (params) => {
    const response = await getOrder(params);
    set({ orderData: response });
    return response;
  },
  postOrders: async (data) => {
    const response = await postOrders(data);
    return response;
  },
  postBulkOrder: async (data) => {
    const response = await postBulkOrder(data);
    return response;
  },
  printBag: async (data) => {
    const response = await printBag(data);
    return response;
  },
  downloadSample: async () => {
    const response = await downloadSample();
    return response;
  },
  printBill: async (data) => {
    const response = await printBill(data);
    return response;
  },
  postMsg: async (data) => {
    const response = await postMsg(data);
    return response;
  },
  printBulkBill: async (data) => {
    const response = await printBulkBill(data);
    return response;
  },
  downloadSampleSms: async () => {
    const response = await downloadSampleSms();
    return response;
  },
  uploadReciver: async (data) => {
    const response = await uploadReciver(data);
    return response;
  },
  getSmsType: async () => {
    const response = await getSmsType();
    return response;
  },
  downloadSamplebulk: async () => {
    const response = await downloadSamplebulk();
    return response;
  },
  uploadBulkprint: async (data) => {
    const response = await uploadBulkprint(data);
    return response;
  },
  getZidProducts: async (params) => {
    const response = await getZidProducts(params);
    set({ zidProductsData: response });
    return response;
  },
  syncZidProduct: async () => {
    const response = await syncZidProduct();
    return response;
  },
  putZidProduct: async (params, data) => {
    const response = await putZidProduct(params, data);
    return response;
  },
  getZidProductsByStoreId: async (params) => {
    const response = await getZidProductsByStoreId(params);
    set({ zidProductsData: response });
    return response;
  },
  syncZidProductByStoreId: async (params) => {
    const response = await syncZidProductByStoreId(params);
    return response;
  },
  getZidMerchants: async () => {
    const response = await getZidMerchants();
    set({ zidMerchantsData: response });
    return response;
  },
  getZidMerchantsByStoreId: async (params) => {
    const response = await getZidMerchantsByStoreId(params);
    set({ zidMerchantsData: response });
    return response;
  },
  postZidMerchants: async (data) => {
    const response = await postZidMerchants(data);
    return response;
  },
  putZidMerchants: async (params, data) => {
    const response = await putZidMerchants(params, data);
    return response;
  },
  getRoles: async (params) => {
    const response = await getRoles(params);
    set({ rolesData: response });
    return response;
  },
  postRoles: async (data) => {
    const response = await postRoles(data);
    return response;
  },
  getRoleById: async (params) => {
    const response = await getRoleById(params);
    return response;
  },
  updateRoleById: async (params, data) => {
    const response = await updateRoleById(params, data);
    return response;
  },
  deleteRoleById: async (params) => {
    const response = await deleteRoleById(params);
    return response;
  },
  syncRoles: async () => {
    const response = await syncRoles();
    return response;
  },
  getUsersByRoleIdOrGroupId: async (params) => {
    const response = await getUsersByRoleIdOrGroupId(params);
    return response;
  },
  getUsersByGroupId: async (params) => {
    const response = await getUsersByGroupId(params);
    return response;
  },
  downloadUsersByRoleId: async (params) => {
    const response = await downloadUsersByRoleId(params);
    return response;
  },
  getGroups: async (params) => {
    const response = await getGroups(params);
    return response;
  },
  getGroupsTree: async () => {
    const response = await getGroupsTree();
    return response;
  },
  postGroups: async (data) => {
    const response = await postGroups(data);
    return response;
  },
  postChildGroups: async (params, data) => {
    const response = await postChildGroups(params, data);
    return response;
  },
  deleteGroupById: async (params) => {
    const response = await deleteGroupById(params);
    return response;
  },
  getGroupsByUserId: async (params) => {
    const response = await getGroupsByUserId(params);
    return response;
  },
  syncGroups: async () => {
    const response = await syncGroups();
    return response;
  },
  getSubGroups: async (params) => {
    const response = await getSubGroups(params);
    return response;
  },
  getUsers: async (params) => {
    const response = await getUsers(params);
    return response;
  },
  postUsers: async (data) => {
    const response = await postUsers(data);
    return response;
  },
  resetUsersPassword: async (data) => {
    const response = await resetUsersPassword(data);
    return response;
  },
  putUsersById: async (params, data) => {
    const response = await putUsersById(params, data);
    return response;
  },
  getUsersById: async (params) => {
    const response = await getUsersById(params);
    return response;
  },
  deleteUserById: async (params) => {
    const response = await deleteUserById(params);
    return response;
  },
  getAssignedRolesUserById: async (params) => {
    const response = await getAssignedRolesByUserId(params);
    return response;
  },
  assignRolesToUserId: async (params1, params2) => {
    const response = await assignRolesToUserId(params1, params2);
    return response;
  },
  unassignRolesOfUserId: async (params1, params2) => {
    const response = await unassignRolesOfUserId(params1, params2);
    return response;
  },
  assignGroupsToUserId: async (params1, params2) => {
    const response = await assignGroupsToUserId(params1, params2);
    return response;
  },
  unassignGroupsOfUserId: async (params1, params2) => {
    const response = await unassignGroupsOfUserId(params1, params2);
    return response;
  },
  unassignRolesOfGroupId: async (params1, params2) => {
    const response = await unassignRolesOfGroupId(params1, params2);
    return response;
  },
  assignRolesToGroupId: async (params1, params2) => {
    const response = await assignRolesToGroupId(params1, params2);
    return response;
  },
  getAssignedRolesByGroupId: async (params) => {
    const response = await getAssignedRolesByGroupId(params);
    return response;
  },
  getAllModules: async () => {
    const response = await getAllModules();
    set({ ModuleList: response });
    return response;
  },
  getSubModules: async (params) => {
    const response = await getSubModules(params);
    set({ SubmoduleList: response });
    return response;
  },
  getModels: async (params) => {
    const response = await getModels(params);
    set({ ModelList: response });
    return response;
  },
  getPermissions: async (params) => {
    const response = await getPermissions(params);
    set({ PermissionList: response });
    return response;
  },
  renameGroups: async (params, data) => {
    const response = await renameGroups(params, data);
    set({ ModelList: response });
    return response;
  },
  exportCod: async (params) => {
    const response = await exportCod(params);
    return response;
  },
  exportFreight: async (params) => {
    const response = await exportFreight(params);
    return response;
  },
  exportFuel: async (params) => {
    const response = await exportFuel(params);
    return response;
  },
  exportFuelperkg: async (params) => {
    const response = await exportFuelperkg(params);
    return response;
  },
  exportLowvalue: async (params) => {
    const response = await exportLowvalue(params);
    return response;
  },
  exportHighvalue: async (params) => {
    const response = await exportHighvalue(params);
    return response;
  },
  exportInsurance: async (params) => {
    const response = await exportInsurance(params);
    return response;
  },
  exportOda: async (params) => {
    const response = await exportOda(params);
    return response;
  },
  exportRto: async (params) => {
    const response = await exportRto(params);
    return response;
  },
  exportVat: async (params) => {
    const response = await exportVat(params);
    return response;
  },
  getCodTemplate: async () => {
    const response = await getCodTemplate();
    return response;
  },
  getFreightTemplate: async () => {
    const response = await getFreightTemplate();
    return response;
  },
  getFuelTemplate: async () => {
    const response = await getFuelTemplate();
    return response;
  },
  getFuelperkgTemplate: async () => {
    const response = await getFuelperkgTemplate();
    return response;
  },
  getLowvalueTemplate: async () => {
    const response = await getLowvalueTemplate();
    return response;
  },
  getHighvalueTemplate: async () => {
    const response = await getHighvalueTemplate();
    return response;
  },
  getInsuranceTemplate: async () => {
    const response = await getInsuranceTemplate();
    return response;
  },
  getOdaTempalte: async () => {
    const response = await getOdaTempalte();
    return response;
  },
  getRtoTemplate: async () => {
    const response = await getRtoTemplate();
    return response;
  },
  getVatTemplate: async () => {
    const response = await getVatTemplate();
    return response;
  },
  getConsignmentOrders: async (params) => {
    const response = await getConsignmentOrders(params);
    return response;
  },
  postConsignmentOrders: async (params) => {
    const response = await postConsignmentOrders(params);
    return response;
  },
  printConsignments: async (params) => {
    const response = await printConsignments(params);
    return response;
  },
  cancelConsignments: async (params) => {
    const response = await cancelConsignments(params);
    return response;
  },
  printBulkConsignments: async (data) => {
    const response = await printBulkConsignments(data);
    return response;
  },
  getConsignmentStatus: async (data) => {
    const response = await getConsignmentStatus(data);
    set({ consignemntStatus: response?.data?.data?.content });
    return response;
  },
  editConsignmentOrders: async (params) => {
    const response = await editConsignmentOrders(params);
    return response;
  },
  getConsignmentOrdersbyId: async (params) => {
    const response = await getConsignmentOrdersbyId(params);
    return response;
  },
  importConsignmentOrders: async (params) => {
    const response = await importConsignmentOrders(params);
    return response;
  },
  getConsignmentOrdersTemplate: async () => {
    const response = await getConsignmentOrdersTemplate();
    return response;
  },
  getOrderAddressType: async () => {
    const response = await getOrderAddressType();
    set({ orderAddressType: response });
    return response;
  },
  getOrderContentType: async () => {
    const response = await getOrderContentType();
    set({ orderContentType: response });
    return response;
  },
  getOrderEventStatus: async () => {
    const response = await getOrderEventStatus();
    set({ orderEventType: response });
    return response;
  },
  getOrderDimensionUnit: async () => {
    const response = await getOrderDimensionUnit();
    set({ orderDimensionUnit: response });
    return response;
  },
  getOrderIncoterms: async () => {
    const response = await getOrderIncoterms();
    set({ orderIncoterms: response });
    return response;
  },
  getOrderPickupMethod: async () => {
    const response = await getOrderPickupMethod();
    set({ orderPickupMethod: response });
    return response;
  },
  getOrderWeightUnit: async () => {
    const response = await getOrderWeightUnit();
    set({ orderWeightUnit: response });
    return response;
  },
  editOrderIncoterms: async (params,data) => {
    const response = await editOrderIncoterms(params,data);
    return response;
  },
  editOrderDdu: async (params,data) => {
    const response = await editOrderDdu(params,data);
    return response;
  },
  editOrderDesitiantion: async (params,data) => {
    const response = await editOrderDesitiantion(params,data);
    return response;
  },
  getPackageTrackingId: async (params) => {
    const response = await getPackageTrackingId(params);
    set({ packageTrack: response });
    return response;
  },
  exportCountries: async (params) => {
    const response = await exportCountries(params);
    return response;
  },
  getCountries: async (params) => {
    const response = await getCountries(params);
    set({ countriesData: response });
    return response;
  },
  filterCountries: async (params) => {
    const response = await filterCountries(params);
    set({ countriesData: response });
    return response;
  },
  getCountryArray: async (params) => {
    const response = await getCountries(params);
    set({ countryArrayData: response });
    return response;
  },
  exportCountries: async (params) => {
    const response = await exportCountries(params);
    return response;
  },
  exportRegion: async (params) => {
    const response = await exportRegion(params);
    return response;
  },
  exportCities: async (params) => {
    const response = await exportCities(params);
    return response;
  },
  exportDistrict: async (params) => {
    const response = await exportDistrict(params);
    return response;
  },
  exportDistrictMap: async (params) => {
    const response = await exportDistrictMap(params);
    return response;
  },
  exportCountryMap: async (params) => {
    const response = await exportCountryMap(params);
    return response;
  },
  exportRegionMap: async (params) => {
    const response = await exportRegionMap(params);
    return response;
  },
  exportCityMap: async (params) => {
    const response = await exportCityMap(params);
    return response;
  },
  exportUsers: async (params) => {
    const response = await exportUsers(params);
    return response;
  },
  getTiers: async (params) => {
    const response = await getTiers(params);
    set({ tiersData: response });
    return response;
  },
  postTiers: async (params) => {
    const response = await postTiers(params);
    return response;
  },
  getTiersbyId: async (params) => {
    const response = await getTiersbyId(params);
    return response;
  },
  updateTiersById: async (params, data) => {
    const response = await updateTiersById(params, data);
    return response;
  },
  deleteTiers: async (params) => {
    const response = await deleteTiers(params);
    return response;
  },
  getOdTier: async (params) => {
    const response = await getOdTier(params);
    return response;
  },
  postOdTier: async (params) => {
    const response = await postOdTier(params);
    return response;
  },
  validateOdpair: async (params) => {
    const response = await validateOdpair(params);
    return response;
  },
  updateOdTierById: async (params, data) => {
    const response = await updateOdTierById(params, data);
    return response;
  },
  deleteOdTier: async (params) => {
    const response = await deleteOdTier(params);
    return response;
  },
  importCountry: async (data) => {
    const response = await importCountry(data);
    return response;
  },
  importRegion: async (data) => {
    const response = await importRegion(data);
    return response;
  },
  importCity: async (data) => {
    const response = await importCity(data);
    return response;
  },
  importDistrict: async (data) => {
    const response = await importDistrict(data);
    return response;
  },
  importCountryMap: async (data) => {
    const response = await importCountryMap(data);
    return response;
  },
  importRegionMap: async (data) => {
    const response = await importRegionMap(data);
    return response;
  },
  importCityMap: async (data) => {
    const response = await importCityMap(data);
    return response;
  },
  importDistrictMap: async (data) => {
    const response = await importDistrictMap(data);
    return response;
  },
  getInvoiceRequest: async (data) => {
    const response = await getInvoiceRequest(data);
    return response;
  },
  downloadInvoiceRequest: async (data) => {
    const response = await downloadInvoiceRequest(data);
    return response;
  },
  CancelInvoiceRequest: async (data) => {
    const response = await CancelInvoiceRequest(data);
    return response;
  },
  postCustomerClassification: async (params) => {
    const response = await postCustomerClassification(params);
    return response;
  },
  postHighValue: async (params) => {
    const response = await postHighValue(params);
    return response;
  },
  postTiersClassification: async (params) => {
    const response = await postTiersClassification(params);
    return response;
  },
  getInvoiceRequestTemplate: async (data) => {
    const response = await getInvoiceRequestTemplate(data);
    set({ invoiceTempData: response });
    return response;
  },
  postInvoiceRequestTemplate: async (data) => {
    const response = await postInvoiceRequestTemplate(data);
    return response;
  },
  getInvoiceRequestTemplatebyId: async (data) => {
    const response = await getInvoiceRequestTemplatebyId(data);
    return response;
  },
  downloadConsignments: async (data) => {
    const response = await downloadConsignments(data);
    return response;
  },
  postInvoiceRequest: async (data) => {
    const response = await postInvoiceRequest(data);
    return response;
  },
  pushInvoicebyId: async (data) => {
    const response = await pushInvoicebyId(data);
    return response;
  },
  exportPickupInvoice: async (data) => {
    const response = await exportPickupInvoice(data);
    return response;
  },
  getConsignments: async (data) => {
    const response = await getConsignments(data);
    return response;
  },
  fetchConsignments: async (data) => {
    const response = await fetchConsignments(data);
    return response;
  },
  postCountries: async (data) => {
    const response = await postCountries(data);
    return response;
  },
  postCities: async (data) => {
    const response = await postCities(data);
    return response;
  },
  postDistrict: async (data) => {
    const response = await postDistrict(data);
    return response;
  },
  getCountryById: async (params) => {
    const response = await getCountryById(params);
    set({ countryData: response });
    return response;
  },
  getCountryByCity: async (params) => {
    const response = await getCountryByCity(params);
    set({ citybyCountyData: response });
    return response;
  },
  getCountryByCityOrders: async (params) => {
    const response = await getCountryByCity(params);
    set({ citybyCode: response?.data?.data });
    return response;
  },
  getCountryByCityOrdersDes: async (params) => {
    const response = await getCountryByCity(params);
    set({ citybyCodeDes: response?.data?.data });
    return response;
  },
  getDesCountryByCity: async (params) => {
    const response = await getDesCountryByCity(params);
    set({ citybyDesCountyData: response });
    return response;
  },
  getCityById: async (params) => {
    const response = await getCityById(params);
    set({ cityData: response });
    return response;
  },
  getRegionbyCode: async (params) => {
    const response = await getRegionbyCode(params);
    set({ regionbyCode: response.data.data });
    return response;
  },
  getRegionbyCodeDes: async (params) => {
    const response = await getRegionbyCodeDes(params);
    set({ regionbyCodeDes: response.data.data });
    return response;
  },
  getCitybyCode: async (params, params2) => {
    const response = await getCitybyCode(params, params2);
    set({ citybyCode: response.data.data });
    return response;
  },
  getCitybyCodeDes: async (params, params2) => {
    const response = await getCitybyCodeDes(params, params2);
    set({ citybyCodeDes: response.data.data });
    return response;
  },
  getDistrictbyCode: async (params, params2, params3) => {
    const response = await getDistrictbyCode(params, params2, params3);
    set({ districtbyCode: response.data.data });
    return response;
  },
  getDistrictDesbyCode: async (params, params2, params3) => {
    const response = await getDistrictDesbyCode(params, params2, params3);
    set({ districtbyDescode: response.data.data });
    return response;
  },
  getDistrictById: async (params) => {
    const response = await getDistrictById(params);
    set({ districtData: response });
    return response;
  },
  getDistrictGeometry: async (params) => {
    const response = await getDistrictGeometry(params);
    set({ geometryData: response });
    return response;
  },
  editDistrictGeometry: async (params,data) => {
    const response = await editDistrictGeometry(params,data);
    return response;
  },
  getCountryTemplate: async () => {
    const response = await getCountryTemplate();
    return response;
  },
  getRegionTemplate: async () => {
    const response = await getRegionTemplate();
    return response;
  },
  getDistrictTemplate: async () => {
    const response = await getDistrictTemplate();
    return response;
  },
  getCitiesTemplate: async () => {
    const response = await getCitiesTemplate();
    return response;
  },
  getCountryMapTemplate: async () => {
    const response = await getCountryMapTemplate();
    return response;
  },
  getRegionMapTemplate: async () => {
    const response = await getRegionMapTemplate();
    return response;
  },
  getCityMapTemplate: async () => {
    const response = await getCityMapTemplate();
    return response;
  },
  getDistrictMapTemplate: async () => {
    const response = await getDistrictMapTemplate();
    return response;
  },
  getCodCharge: async (params) => {
    const response = await getCodCharge(params);
    return response;
  },
  getFreightCharge: async (params) => {
    const response = await getFreightCharge(params);
    return response;
  },
  getFuelCharge: async (params) => {
    const response = await getFuelCharge(params);
    return response;
  },
  getFuelkgcharge: async (params) => {
    const response = await getFuelkgcharge(params);
    return response;
  },
  getLowvaluecharge: async (params) => {
    const response = await getLowvaluecharge(params);
    return response;
  },
  getHighvaluecharge: async (params) => {
    const response = await getHighvaluecharge(params);
    return response;
  },
  getInusranceCharge: async (params) => {
    const response = await getInusranceCharge(params);
    return response;
  },
  getOdacharge: async (params) => {
    const response = await getOdacharge(params);
    return response;
  },
  getRtocharge: async (params) => {
    const response = await getRtocharge(params);
    return response;
  },
  getVatCharge: async (params) => {
    const response = await getVatCharge(params);
    return response;
  },
  updateCountryById: async (params, data) => {
    const response = await updateCountryById(params, data);
    return response;
  },
  updateCityById: async (params, data) => {
    const response = await updateCityById(params, data);
    return response;
  },
  updateDistrictById: async (params, data) => {
    const response = await updateDistrictById(params, data);
    return response;
  },
  deleteCountryById: async (params) => {
    const response = await deleteCountryById(params);
    return response;
  },
  deleteCityById: async (params) => {
    const response = await deleteCityById(params);
    return response;
  },
  deleteDistrictById: async (params) => {
    const response = await deleteDistrictById(params);
    return response;
  },
  getRegions: async (params) => {
    const response = await getRegions(params);
    set({ regionsData: response });
    return response;
  },
  getRegion: async (params) => {
    const response = await getRegion(params);
    return response;
  },
  filterRegions: async (params) => {
    const response = await filterRegions(params);
    set({ regionsData: response });
    return response;
  },
  postRegions: async (data) => {
    const response = await postRegions(data);
    return response;
  },
  getRegionById: async (params) => {
    const response = await getRegionById(params);
    set({ regionData: response });
    return response;
  },
  updateRegionById: async (params, data) => {
    const response = await updateRegionById(params, data);
    return response;
  },
  deleteRegionById: async (params) => {
    const response = await deleteRegionById(params);
    return response;
  },
  getDistricts: async (params) => {
    const response = await getDistricts(params);
    set({ districtDatas: response });
    return response;
  },
  filterDistricts: async (params) => {
    const response = await filterDistricts(params);
    set({ districtDatas: response });
    return response;
  },
  getCities: async (params) => {
    const response = await getCities(params);
    set({ citiesData: response });
    set({ cityArray: response });
    return response;
  },
  filterCities: async (params) => {
    const response = await filterCities(params);
    set({ citiesData: response });
    set({ cityArray: response });
    return response;
  },
  //Country mapping
  getCountryMapping: async (params) => {
    const response = await getCountryMapping(params);
    return response;
  },
  postCountryMapping: async (data) => {
    const response = await postCountryMapping(data);
    return response;
  },
  getCountryMappingById: async (params) => {
    const response = await getCountryMappingById(params);
    return response;
  },
  updateCountryMappingById: async (params, data) => {
    const response = await updateCountryMappingById(params, data);
    return response;
  },
  deleteCountryMappingById: async (params) => {
    const response = await deleteCountryMappingById(params);
    return response;
  },
  //Region mapping
  getRegionMapping: async (params) => {
    const response = await getRegionMapping(params);
    return response;
  },
  postRegionMapping: async (data) => {
    const response = await postRegionMapping(data);
    return response;
  },
  getRegionMappingById: async (params) => {
    const response = await getRegionMappingById(params);
    return response;
  },
  updateRegionMappingById: async (params, data) => {
    const response = await updateRegionMappingById(params, data);
    return response;
  },
  deleteRegionMappingById: async (params) => {
    const response = await deleteRegionMappingById(params);
    return response;
  },
  //City mapping
  getCityMapping: async (params) => {
    const response = await getCityMapping(params);
    return response;
  },
  postCityMapping: async (data) => {
    const response = await postCityMapping(data);
    return response;
  },
  getCityMappingById: async (params) => {
    const response = await getCityMappingById(params);
    return response;
  },
  updateCityMappingById: async (params, data) => {
    const response = await updateCityMappingById(params, data);
    return response;
  },
  deleteCityMappingById: async (params) => {
    const response = await deleteCityMappingById(params);
    return response;
  },
  //District mapping
  getDistrictMapping: async (params) => {
    const response = await getDistrictMapping(params);
    return response;
  },
  postDistrictMapping: async (data) => {
    const response = await postDistrictMapping(data);
    return response;
  },
  getDistrictMappingById: async (params) => {
    const response = await getDistrictMappingById(params);
    return response;
  },
  updateDistrictMappingById: async (params, data) => {
    const response = await updateDistrictMappingById(params, data);
    return response;
  },
  deleteDistrictMappingById: async (params) => {
    const response = await deleteDistrictMappingById(params);
    return response;
  },
  //get zones
  getZone: async (params) => {
    const response = await getZone(params);
    set({ zoneData: response });
    return response;
  },
  //filter zones
  filterZone: async (params) => {
    const response = await filterZone(params);
    set({ zoneData: response });
    return response;
  },
  //getbyId-zone
  getZoneById: async (params) => {
    const response = await getZoneById(params);
    return response;
  },
  //post zones
  postZones: async (data) => {
    const response = await postZones(data);
    return response;
  },
  //update zones
  updateZoneById: async (params, data) => {
    const response = await updateZoneById(params, data);
    return response;
  },
  //delete zones
  deleteZoneById: async (params) => {
    const response = await deleteZoneById(params);
    return response;
  },
  //zone template
  getZoneTemplate: async () => {
    const response = await getZoneTemplate();
    return response;
  },
  //zone export
  exportZones: async (params) => {
    const response = await exportZones(params);
    return response;
  },
  //zone import
  importZones: async (data) => {
    const response = await importZones(data);
    return response;
  },
  //zone groups
  getZoneGroups: async (params) => {
    const response = await getZoneGroups(params);
    set({ zoneGroupData: response });
    return response;
  },
  //getbyId-zone group
  getZoneGroupById: async (params) => {
    const response = await getZoneGroupById(params);
    return response;
  },
  //post zonegroup
  postZonegroup: async (data) => {
    const response = await postZonegroup(data);
    return response;
  },
  //update zonegroup
  updateZonegroupById: async (params, data) => {
    const response = await updateZonegroupById(params, data);
    return response;
  },
  //delete zonegroup
  deleteZonegroupById: async (params) => {
    const response = await deleteZonegroupById(params);
    return response;
  },
  //zone group template
  getZonegroupTemplate: async () => {
    const response = await getZonegroupTemplate();
    return response;
  },
  //zone group export
  exportZonegroup: async (params) => {
    const response = await exportZonegroup(params);
    return response;
  },
  //zone group import
  importZonegroup: async (params) => {
    const response = await importZonegroup(params);
    return response;
  },
  getPickupReasons: async (params) => {
    const response = await getPickupReasons(params);
    set({ pickupReasonsData: response });
    return response;
  },
  postPickupReasons: async (params) => {
    const response = await postPickupReasons(params);
    return response;
  },
  updatePickupReasonsById: async (params, data) => {
    const response = await updatePickupReasonsById(params, data);
    return response;
  },
  deletePickupReasonsById: async (params) => {
    const response = await deletePickupReasonsById(params);
    return response;
  },
  getPickupReasonsById: async (params) => {
    const response = await getPickupReasonsById(params);
    return response;
  },
  exportPickupReasons: async (params) => {
    const response = await exportPickupReasons(params);
    return response;
  },
  getPickupCategory: async () => {
    const response = await getPickupCategory();
    return response;
  },
  getPickupEventCategory: async () => {
    const response = await getPickupEventCategory();
    set({ OpsEventData: response });
    return response;
  },
  getDeliveryEventCategory: async () => {
    const response = await getDeliveryEventCategory();
    set({ OpsDeliveryEventData: response });
    return response;
  },
  getOpsCategory: async () => {
    const response = await getOpsCategory();
    set({ OpsCategoryData: response });
    return response;
  },
  getDeliveryReasons: async (params) => {
    const response = await getDeliveryReasons(params);
    set({ deliveryReasonsData: response });
    return response;
  },
  postDeliveryReasons: async (params) => {
    const response = await postDeliveryReasons(params);
    return response;
  },
  updateDeliveryReasonsById: async (params, data) => {
    const response = await updateDeliveryReasonsById(params, data);
    return response;
  },
  deleteDeliveryReasonsById: async (params) => {
    const response = await deleteDeliveryReasonsById(params);
    return response;
  },
  getDeliveryReasonsById: async (params) => {
    const response = await getDeliveryReasonsById(params);
    return response;
  },
  exportDeliveryReasons: async (params) => {
    const response = await exportDeliveryReasons(params);
    return response;
  },
  getHoldReasons: async (params) => {
    const response = await getHoldReasons(params);
    set({ holdReasonsData: response });
    return response;
  },
  postHoldReasons: async (params) => {
    const response = await postHoldReasons(params);
    return response;
  },
  updateHoldReasonsById: async (params,data) => {
    const response = await updateHoldReasonsById(params,data);
    return response;
  },
  deleteHoldReasonsById: async (params) => {
    const response = await deleteHoldReasonsById(params);
    return response;
  },
  getHoldReasonsById: async (params) => {
    const response = await getHoldReasonsById(params);
    return response;
  },
  exportHoldReasons: async (params) => {
    const response = await exportHoldReasons(params);
    return response;
  },
  //hubs
  getHubs: async (params) => {
    const response = await getHubs(params);
    set({ hubsData: response });
    return response;
  },
  getHub: async (params) => {
    const response = await getHub(params);
    set({ hubsfilterData: response });
    return response;
  },
  filterHubs: async (params) => {
    const response = await filterHubs(params);
    set({ hubsData: response });
    return response;
  },
  getHubsbyId: async (params) => {
    const response = await getHubsbyId(params);
    return response;
  },
  editHubs: async (params) => {
    const response = await editHubs(params);
    return response;
  },
  editHubsbypatch: async (params, data) => {
    const response = await editHubsbypatch(params, data);
    return response;
  },
  postHubs: async (params) => {
    const response = await postHubs(params);
    return response;
  },
  getHubTypes: async () => {
    const response = await getHubTypes();
    set({ hubTypes: response });
    return response;
  },
  deleteHubs: async (params) => {
    const response = await deleteHubs(params);
    return response;
  },
  exportHubs: async (params) => {
    const response = await exportHubs(params);
    return response;
  },
  importHub: async (params) => {
    const response = await importHub(params);
    return response;
  },
  getProducts: async (params) => {
    const response = await getProducts(params);
    set({ productsData: response });
    return response;
  },
  filterProducts: async (params) => {
    const response = await filterProducts(params);
    set({ productsData: response });
    return response;
  },
  getProductsbyId: async (params) => {
    const response = await getProductsbyId(params);
    return response;
  },
  postProducts: async (params) => {
    const response = await postProducts(params);
    return response;
  },
  editProducts: async (params, data) => {
    const response = await editProducts(params, data);
    return response;
  },
  editProductsbypatch: async (params, data) => {
    const response = await editProductsbypatch(params, data);
    return response;
  },
  getProductsDimension: async () => {
    const response = await getProductsDimension();
    set({ dimensionData: response });
    return response;
  },
  getProductsEvent: async () => {
    const response = await getProductsEvent();
    set({ productEventData: response });
    return response;
  },
  getProductsWeight: async () => {
    const response = await getProductsWeight();
    set({ productWeightData: response });
    return response;
  },
  getProductCategory: async () => {
    const response = await getProductCategory();
    set({ productCategoryData: response });
    return response;
  },
  getProductsContent: async () => {
    const response = await getProductsContent();
    set({ productContentData: response });
    return response;
  },
  getCalType: async () => {
    const response = await getCalType();
    set({ calculationData: response });
    return response;
  },
  getCollectionMethod: async () => {
    const response = await getCollectionMethod();
    set({ collectionData: response });
    return response;
  },
  getContentType: async () => {
    const response = await getContentType();
    set({ contentData: response });
    return response;
  },
  getStateRates: async () => {
    const response = await getStateRates();
    set({ stateRateData: response });
    return response;
  },
  getDeliveryStatus: async () => {
    const response = await getDeliveryStatus();
    set({ deliveryStatusData: response });
    return response;
  },
  getPaymentType: async () => {
    const response = await getPaymentType();
    set({ paymentData: response });
    return response;
  },
  getState: async () => {
    const response = await getState();
    set({ stateData: response });
    return response;
  },
  getStructure: async () => {
    const response = await getStructure();
    set({ structureData: response });
    return response;
  },
  exportProducts: async (params) => {
    const response = await exportProducts(params);
    return response;
  },
  getProductsTemplate: async () => {
    const response = await getProductsTemplate();
    return response;
  },
  importProducts: async (params) => {
    const response = await importProducts(params);
    return response;
  },
  deleteProducts: async (params) => {
    const response = await deleteProducts(params);
    return response;
  },
  getFreight: async (params) => {
    const response = await getFreight(params);
    return response;
  },
  getFreightbyId: async (params) => {
    const response = await getFreightbyId(params);
    return response;
  },
  postFreight: async (params) => {
    const response = await postFreight(params);
    return response;
  },
  editFreight: async (params, data) => {
    const response = await editFreight(params, data);
    return response;
  },
  editFreightsbypatch: async (params, data) => {
    const response = await editFreightsbypatch(params, data);
    return response;
  },
  deleteFreight: async (params) => {
    const response = await deleteFreight(params);
    return response;
  },
  bulkFreightDelete: async (params) => {
    const response = await bulkFreightDelete(params);
    return response;
  },
  bulkRtoDelete: async (params) => {
    const response = await bulkRtoDelete(params);
    return response;
  },
  bulkCodDelete: async (params) => {
    const response = await bulkCodDelete(params);
    return response;
  },
  bulkOdaDelete: async (params) => {
    const response = await bulkOdaDelete(params);
    return response;
  },
  bulkFuelDelete: async (params) => {
    const response = await bulkFuelDelete(params);
    return response;
  },
  bulkInsuranceDelete: async (params) => {
    const response = await bulkInsuranceDelete(params);
    return response;
  },
  bulkAttemptsDelete: async (params) => {
    const response = await bulkAttemptsDelete(params);
    return response;
  },
  exportrateFreight: async (params) => {
    const response = await exportrateFreight(params);
    return response;
  },
  getFreightsTemplate: async (params) => {
    const response = await getFreightsTemplate(params);
    return response;
  },
  importFreight: async (params) => {
    const response = await importFreight(params);
    return response;
  },
  getRto: async (params) => {
    const response = await getRto(params);
    return response;
  },
  getRtobyId: async (params) => {
    const response = await getRtobyId(params);
    return response;
  },
  postRto: async (params) => {
    const response = await postRto(params);
    return response;
  },
  editRto: async (params, data) => {
    const response = await editRto(params, data);
    return response;
  },
  editRtobypatch: async (params, data) => {
    const response = await editRtobypatch(params, data);
    return response;
  },
  deleteRto: async (params) => {
    const response = await deleteRto(params);
    return response;
  },
  exportrateRto: async (params) => {
    const response = await exportrateRto(params);
    return response;
  },
  getRtosTemplate: async (params) => {
    const response = await getRtosTemplate(params);
    return response;
  },
  importRto: async (params) => {
    const response = await importRto(params);
    return response;
  },
  getCod: async (params) => {
    const response = await getCod(params);
    return response;
  },
  getCodbyId: async (params) => {
    const response = await getCodbyId(params);
    return response;
  },
  editCodbypatch: async (params, data) => {
    const response = await editCodbypatch(params, data);
    return response;
  },
  editCod: async (params, data) => {
    const response = await editCod(params, data);
    return response;
  },
  postCod: async (params) => {
    const response = await postCod(params);
    return response;
  },
  deleteCod: async (params) => {
    const response = await deleteCod(params);
    return response;
  },
  exportrateCod: async (params) => {
    const response = await exportrateCod(params);
    return response;
  },
  getCodrateTemplate: async (params) => {
    const response = await getCodrateTemplate(params);
    return response;
  },
  importCod: async (params) => {
    const response = await importCod(params);
    return response;
  },
  getOda: async (params) => {
    const response = await getOda(params);
    return response;
  },
  getOdabyId: async (params) => {
    const response = await getOdabyId(params);
    return response;
  },
  postOda: async (params) => {
    const response = await postOda(params);
    return response;
  },
  editOda: async (params, data) => {
    const response = await editOda(params, data);
    return response;
  },
  editOdabypatch: async (params, data) => {
    const response = await editOdabypatch(params, data);
    return response;
  },
  deleteOda: async (params) => {
    const response = await deleteOda(params);
    return response;
  },
  exportrateOda: async (params) => {
    const response = await exportrateOda(params);
    return response;
  },
  getOdarateTemplate: async (params) => {
    const response = await getOdarateTemplate(params);
    return response;
  },
  importOda: async (params) => {
    const response = await importOda(params);
    return response;
  },
  getFuel: async (params) => {
    const response = await getFuel(params);
    return response;
  },
  getFuelbyId: async (params) => {
    const response = await getFuelbyId(params);
    return response;
  },
  postFuel: async (params) => {
    const response = await postFuel(params);
    return response;
  },
  editFuel: async (params, data) => {
    const response = await editFuel(params, data);
    return response;
  },
  editFuelbypatch: async (params, data) => {
    const response = await editFuelbypatch(params, data);
    return response;
  },
  deleteFuel: async (params) => {
    const response = await deleteFuel(params);
    return response;
  },
  exportrateFuel: async (params) => {
    const response = await exportrateFuel(params);
    return response;
  },
  getFuelrateTemplate: async (params) => {
    const response = await getFuelrateTemplate(params);
    return response;
  },
  importFuel: async (params) => {
    const response = await importFuel(params);
    return response;
  },
  getInsurance: async (params) => {
    const response = await getInsurance(params);
    return response;
  },
  getInsurancebyId: async (params) => {
    const response = await getInsurancebyId(params);
    return response;
  },
  postInsurance: async (params) => {
    const response = await postInsurance(params);
    return response;
  },
  editInsurance: async (params, data) => {
    const response = await editInsurance(params, data);
    return response;
  },
  editInsurancebypatch: async (params, data) => {
    const response = await editInsurancebypatch(params, data);
    return response;
  },
  deleteInsurance: async (params) => {
    const response = await deleteInsurance(params);
    return response;
  },
  exportrateInsurance: async (params) => {
    const response = await exportrateInsurance(params);
    return response;
  },
  getInsurancerateTemplate: async (params) => {
    const response = await getInsurancerateTemplate(params);
    return response;
  },
  importInsurance: async (params) => {
    const response = await importInsurance(params);
    return response;
  },
  getAttempts: async (params) => {
    const response = await getAttempts(params);
    return response;
  },
  getAttemptsbyId: async (params) => {
    const response = await getAttemptsbyId(params);
    return response;
  },
  postAttempts: async (params) => {
    const response = await postAttempts(params);
    return response;
  },
  editAttempts: async (params, data) => {
    const response = await editAttempts(params, data);
    return response;
  },
  editAttemptsbypatch: async (params, data) => {
    const response = await editAttemptsbypatch(params, data);
    return response;
  },
  editFreightState: async (params, data) => {
    const response = await editFreightState(params, data);
    return response;
  },
  editRtoState: async (params, data) => {
    const response = await editRtoState(params, data);
    return response;
  },
  editCodState: async (params, data) => {
    const response = await editCodState(params, data);
    return response;
  },
  editOdaState: async (params, data) => {
    const response = await editOdaState(params, data);
    return response;
  },
  editFuelState: async (params, data) => {
    const response = await editFuelState(params, data);
    return response;
  },
  editInsuranceState: async (params, data) => {
    const response = await editInsuranceState(params, data);
    return response;
  },
  editAttemptsState: async (params, data) => {
    const response = await editAttemptsState(params, data);
    return response;
  },
  deleteAttempts: async (params) => {
    const response = await deleteAttempts(params);
    return response;
  },
  exportrateAttempts: async (params) => {
    const response = await exportrateAttempts(params);
    return response;
  },
  getAttemptsrateTemplate: async (params) => {
    const response = await getAttemptsrateTemplate(params);
    return response;
  },
  importAttempts: async (params) => {
    const response = await importAttempts(params);
    return response;
  },
  getIncoterms: async (params) => {
    const response = await getIncoterms(params);
    return response;
  },
  getOrigin: async (params1, params) => {
    const response = await getOrigin(params1, params);
    return response;
  },
  postOrigin: async (params, data) => {
    const response = await postOrigin(params, data);
    return response;
  },
  editOrigin: async (params, params2, data) => {
    const response = await editOrigin(params, params2, data);
    return response;
  },
  deleteOrigin: async (params, params2, data) => {
    const response = await deleteOrigin(params, params2, data);
    return response;
  },
  exportOrigin: async (params1, params) => {
    const response = await exportOrigin(params1, params);
    return response;
  },
  getOriginTemplate: async (params) => {
    const response = await getOriginTemplate(params);
    return response;
  },
  importOrigin: async (params, data) => {
    const response = await importOrigin(params, data);
    return response;
  },
  getDestination: async (params1, params) => {
    const response = await getDestination(params1, params);
    return response;
  },
  postDestination: async (params, data) => {
    const response = await postDestination(params, data);
    return response;
  },
  editDestination: async (params, params2, data) => {
    const response = await editDestination(params, params2, data);
    return response;
  },
  deleteDestination: async (params, params2, data) => {
    const response = await deleteDestination(params, params2, data);
    return response;
  },
  exportDestination: async (params1, params) => {
    const response = await exportDestination(params1, params);
    return response;
  },
  getDestinationTemplate: async (params) => {
    const response = await getDestinationTemplate(params);
    return response;
  },
  importDestination: async (params, data) => {
    const response = await importDestination(params, data);
    return response;
  },
  getTransittime: async (params1, params) => {
    const response = await getTransittime(params1, params);
    return response;
  },
  postTransittime: async (params, data) => {
    const response = await postTransittime(params, data);
    return response;
  },
  editTransittime: async (params, params2, data) => {
    const response = await editTransittime(params, params2, data);
    return response;
  },
  deleteTransittime: async (params, params2) => {
    const response = await deleteTransittime(params, params2);
    return response;
  },
  exportTransittime: async (params1, params) => {
    const response = await exportTransittime(params1, params);
    return response;
  },
  getTransittimeTemplate: async (params) => {
    const response = await getTransittimeTemplate(params);
    return response;
  },
  importTransittime: async (params, data) => {
    const response = await importTransittime(params, data);
    return response;
  },
  getHubTemplate: async () => {
    const response = await getHubTemplate();
    return response;
  },
  getServiceAreas: async (params) => {
    const response = await getServiceAreas(params);
    return response;
  },
  getAllAreasbyHub: async (params) => {
    const response = await getAllAreasbyHub(params);
    set({ zoneServiceData: response });
    return response;
  },
  getAllZonegroupbyHub: async (params) => {
    const response = await getAllZonegroupbyHub(params);
    set({ zoneGroupbyHubData: response });
    return response;
  },
  postServiceAreas: async (params) => {
    const response = await postServiceAreas(params);
    return response;
  },
  editServiceAreas: async (params) => {
    const response = await editServiceAreas(params);
    return response;
  },
  deleteServiceareas: async (params) => {
    const response = await deleteServiceareas(params);
    return response;
  },
  getServiceAreasTemplate: async () => {
    const response = await getServiceAreasTemplate();
    return response;
  },
  exportServiceAreas: async (params) => {
    const response = await exportServiceAreas(params);
    return response;
  },
  importServiceAreas: async (params, data) => {
    const response = await importServiceAreas(params, data);
    return response;
  },
  allowedeventLookup: async () => {
    const response = await allowedeventLookup();
    set({ allowedeventData: response });
    return response;
  },
  getEmployee: async (params) => {
    const response = await getEmployee(params);
    set({ employeeData: response });
    return response;
  },
  getEmployeebyId: async (params) => {
    const response = await getEmployeebyId(params);
    return response;
  },
  postEmployee: async (params) => {
    const response = await postEmployee(params);
    return response;
  },
  editEmployees: async (params, data) => {
    const response = await editEmployees(params, data);
    return response;
  },
  getFieldName: async (params, data) => {
    const response = await getFieldName(params, data);
    return response;
  },
  deleteEmployees: async (params) => {
    const response = await deleteEmployees(params);
    return response;
  },
  exportEmployee: async (params) => {
    const response = await exportEmployee(params);
    return response;
  },
  importEmployees: async (params) => {
    const response = await importEmployees(params);
    return response;
  },
  getEmployeesTemplate: async (params) => {
    const response = await getEmployeesTemplate(params);
    return response;
  },
  employeeJobtitle: async () => {
    const response = await employeeJobtitle();
    set({ jobsData: response });
    return response;
  },
  employeedepartment: async () => {
    const response = await employeedepartment();
    set({ departmentData: response });
    return response;
  },
  employeePayrolltype: async () => {
    const response = await employeePayrolltype();
    set({ payrolltypeData: response });
    return response;
  },
  employeePayrollCompany: async () => {
    const response = await employeePayrollCompany();
    set({ payrollcompanyData: response });
    return response;
  },
  employeeLegacySystem: async () => {
    const response = await employeeLegacySystem();
    set({ legacyData: response });
    return response;
  },
  getJobtitlebyDept: async (params) => {
    const response = await getJobtitlebyDept(params);
    set({ jobTitleData: response });
    return response;
  },
  employeeDepartments: async () => {
    const response = await employeeDepartments();
    set({ deptData: response });
    return response;
  },
  getAreas: async (params, params1) => {
    const response = await getAreas(params, params1);
    return response;
  },
  postAreas: async (params, data) => {
    const response = await postAreas(params, data);
    return response;
  },
  editAreas: async (params, params2, data) => {
    const response = await editAreas(params, params2, data);
    return response;
  },
  deleteAreas: async (params) => {
    const response = await deleteAreas(params);
    return response;
  },
  exportAreas: async (params, params2) => {
    const response = await exportAreas(params, params2);
    return response;
  },
  getAreasTemplate: async (params) => {
    const response = await getAreasTemplate(params);
    return response;
  },
  importAreas: async (params, data) => {
    const response = await importAreas(params, data);
    return response;
  },
  postLoginInfoemployee: async (params,data) => {
    const response = await postLoginInfoemployee(params,data);
    return response;
  },
  postAttributes: async (data) => {
    const response = await postAttributes(data);
    return response;
  },
  resetPasswordEmployee: async (params) => {
    const response = await resetPasswordEmployee(params);
    return response;
  },
  toggleUserstatus: async (params, params2) => {
    const response = await toggleUserstatus(params, params2);
    return response;
  },
  generatePassword: async () => {
    const response = await generatePassword();
    return response;
  },
  getCurrency: async (params) => {
    const response = await getCurrency(params);
    set({ currencyData: response });
    return response;
  },
  postCurrency: async (params) => {
    const response = await postCurrency(params);
    return response;
  },
  deleteCurrencyById: async (params) => {
    const response = await deleteCurrencyById(params);
    return response;
  },
  updateCurrencyById: async (params, data) => {
    const response = await updateCurrencyById(params, data);
    return response;
  },
  getCurrencyTemplate: async () => {
    const response = await getCurrencyTemplate();
    return response;
  },
  exportCurrency: async (params) => {
    const response = await exportCurrency(params);
    return response;
  },
  importCurrency: async (params) => {
    const response = await importCurrency(params);
    return response;
  },
  getCurrencyConversion: async (params) => {
    const response = await getCurrencyConversion(params);
    return response;
  },
  postCurrencyConversion: async (params) => {
    const response = await postCurrencyConversion(params);
    return response;
  },
  deleteCurrencyConversionById: async (params) => {
    const response = await deleteCurrencyConversionById(params);
    return response;
  },
  updateCurrencyConversionById: async (params, data) => {
    const response = await updateCurrencyConversionById(params, data);
    return response;
  },
  getCurrencyConversionTemplate: async () => {
    const response = await getCurrencyConversionTemplate();
    return response;
  },
  exportCurrencyConversion: async (params) => {
    const response = await exportCurrencyConversion(params);
    return response;
  },
  importCurrencyConversion: async (params) => {
    const response = await importCurrencyConversion(params);
    return response;
  },
  getCustomers: async (params) => {
    const response = await getCustomers(params);
    return response;
  },
  filterCustomer: async (params) => {
    const response = await filterCustomer(params);
    return response;
  },
  getOperationCodes: async (params) => {
    const response = await getOperationCodes(params);
    set({ operationCodes: response });
    return response;
  },
  getLookups: async (params) => {
    const response = await getLookups(params);
    set({ serviceTypes: response.data.data });
    return response;
  },
  getBillingPeriod: async (params) => {
    const response = await getBillingPeriod(params);
    set({ billingperiodArray: response.data.data });
    return response;
  },
  getCalculationType: async () => {
    const response = await getCalculationType();
    set({ calculationTypeArray: response.data.data });
    return response;
  },
  getCustomerType: async () => {
    const response = await getCustomerType();
    set({ customerType: response.data.data });
    return response;
  },
  updateSubAccounts: async (params1, params2, data) => {
    const response = await updateSubAccounts(params1, params2, data);
    return response;
  },
  updateCustomers: async (data) => {
    const response = await updateCustomer(data);
    return response;
  },
  getInvoices: async (params) => {
    const response = await getInvoices(params);
    return response;
  },
  updateInvoiceApproveStatus: async (data) => {
    const response = await updateInvoiceApproveStatus(data);
    return response;
  },
  updateInvoiceRejectStatus: async (data) => {
    const response = await updateInvoiceRejectStatus(data);
    return response;
  },
  getCustomer: async (params) => {
    const response = await getCustomer(params);
    set({ customerData: response });
    return response;
  },
  exportCustomer: async (params) => {
    const response = await exportCustomer(params);
    return response;
  },
  syncCustomer: async (params) => {
    const response = await syncCustomer(params);
    return response;
  },
  deleteCustomer: async (params) => {
    const response = await deleteCustomer(params);
    return response;
  },
  addCustomer: async (params) => {
    const response = await addCustomer(params);
    return response;
  },
  getCustomerbyId: async (params) => {
    const response = await getCustomerbyId(params);
    set({ customerAccountData: response });
    return response;
  },
  setcustomerArrayData: (newState) => set({ customerArrayData: newState }),
  pushOracleCustomer: async (params) => {
    const response = await pushOracleCustomer(params);
    return response;
  },
  editCustomerbyId: async (params, data) => {
    const response = await editCustomerbyId(params, data);
    return response;
  },
  editSalesPerson: async (params, params2) => {
    const response = await editSalesPerson(params, params2);
    return response;
  },
  postBillingAddress: async (params, data) => {
    const response = await postBillingAddress(params, data);
    return response;
  },
  editBillingAddress: async (params, data) => {
    const response = await editBillingAddress(params, data);
    return response;
  },
  getBillingbyId: async (params) => {
    const response = await getBillingbyId(params);
    set({ billingAddressData: response });
    return response;
  },
  setbillingArrayData: (newState) => set({ billingArrayData: newState }),
  getBankdetailbyId: async (params) => {
    const response = await getBankdetailbyId(params);
    set({ bankAddressData: response });
    return response;
  },
  setbankArrayData: (newState) => set({ bankArrayData: newState }),
  postBankAddress: async (params, data) => {
    const response = await postBankAddress(params, data);
    return response;
  },
  editBankAddress: async (params, data) => {
    const response = await editBankAddress(params, data);
    return response;
  },
  getBookingbyId: async (params) => {
    const response = await getBookingbyId(params);
    return response;
  },
  editBookingSystem: async (params, data) => {
    const response = await editBookingSystem(params, data);
    return response;
  },
  getpickupaddressbyId: async (params, params2) => {
    const response = await getpickupaddressbyId(params, params2);
    return response;
  },
  postpikcupAddress: async (params, data) => {
    const response = await postpikcupAddress(params, data);
    return response;
  },
  deletepickupaddressbyId: async (params) => {
    const response = await deletepickupaddressbyId(params);
    return response;
  },
  editpickupaddress: async (params, params2, data) => {
    const response = await editpickupaddress(params, params2, data);
    return response;
  },
  getRtoaddressbyId: async (params) => {
    const response = await getRtoaddressbyId(params);
    set({ rtoAddressData: response });
    return response;
  },
  postRtoAddress: async (params,data) => {
    const response = await postRtoAddress(params,data);
    return response;
  },
  editRtoaddress: async (params,data) => {
    const response = await editRtoaddress(params,data);
    return response;
  },
  deletepickupaddress: async (params, data) => {
    const response = await deletepickupaddress(params, data);
    return response;
  },
  getBags: async (params) => {
    const response = await getBags(params);
    return response;
  },
  postBags: async (params) => {
    const response = await postBags(params);
    return response;
  },
  postWaybill: async (params, data) => {
    const response = await postWaybill(params, data);
    return response;
  },
  editBags: async (params, data) => {
    const response = await editBags(params, data);
    return response;
  },
  exportBulkBag: async (data) => {
    const response = await exportBulkBag(data);
    return response;
  },
  outScanBulkBag: async (data) => {
    const response = await outScanBulkBag(data);
    return response;
  },
  outScanBag: async (params) => {
    const response = await outScanBag(params);
    return response;
  },
  inScanBag: async (params) => {
    const response = await inScanBag(params);
    return response;
  },
  moveConsignment: async (params, data) => {
    const response = await moveConsignment(params, data);
    return response;
  },
  labelBag: async (params) => {
    const response = await labelBag(params);
    return response;
  },
  assignOrders: async (params) => {
    const response = await assignOrders(params);
    return response;
  },
  cancelOrders: async (params) => {
    const response = await cancelOrders(params);
    return response;
  },
  printOrders: async (params) => {
    const response = await printOrders(params);
    return response;
  },
  consignmentBag: async (params) => {
    const response = await consignmentBag(params);
    return response;
  },
  templateConsigment: async (params) => {
    const response = await templateConsigment(params);
    return response;
  },
  editWaybillsbyBagId: async (params1, params2, params3) => {
    const response = await editWaybillsbyBagId(params1, params2, params3);
    return response;
  },
  deleteBagById: async (params) => {
    const response = await deleteBagById(params);
    return response;
  },
  deleteWaybillByBagId: async (params1, params2) => {
    const response = await deleteWaybillByBagId(params1, params2);
    return response;
  },
  getChuteConfigs: async (params) => {
    const response = await getChuteConfigs(params);
    return response;
  },
  postChuteConfigs: async (data) => {
    const response = await postChuteConfigs(data);
    return response;
  },
  putChuteConfigs: async (params, data) => {
    const response = await putChuteConfigs(params, data);
    return response;
  },
  deleteChuteConfigs: async (params) => {
    const response = await deleteChuteConfigs(params);
    return response;
  },
  deleteThirdparty: async (params) => {
    const response = await deleteThirdparty(params);
    return response;
  },
  deleteMapping: async (params) => {
    const response = await deleteMapping(params);
    return response;
  },
  getChuteConfigsById: async (params1, params2) => {
    const response = await getChuteConfigsById(params1, params2);
    return response;
  },
  getBagsById: async (params1, params2) => {
    const response = await getBagsById(params1, params2);
    return response;
  },
  postWaybillByTemplate: async (params, data) => {
    const response = await postWaybillByTemplate(params, data);
    return response;
  },
  postCodTemplate: async (data) => {
    const response = await postCodTemplate(data);
    return response;
  },
  postFreightTemplate: async (data) => {
    const response = await postFreightTemplate(data);
    return response;
  },
  postFuelTemplate: async (data) => {
    const response = await postFuelTemplate(data);
    return response;
  },
  postFuelperkgTemplate: async (data) => {
    const response = await postFuelperkgTemplate(data);
    return response;
  },
  postLowvalueTemplate: async (data) => {
    const response = await postLowvalueTemplate(data);
    return response;
  },
  postHighvalueTemplate: async (data) => {
    const response = await postHighvalueTemplate(data);
    return response;
  },
  postInsuranceTemplate: async (data) => {
    const response = await postInsuranceTemplate(data);
    return response;
  },
  postOdaTemplate: async (data) => {
    const response = await postOdaTemplate(data);
    return response;
  },
  postRtoTemplate: async (data) => {
    const response = await postRtoTemplate(data);
    return response;
  },
  postVatTemplate: async (data) => {
    const response = await postVatTemplate(data);
    return response;
  },
  postChuteConfigsById: async (params, data) => {
    const response = await postChuteConfigsById(params, data);
    return response;
  },
  putChuteConfigsById: async (params, data) => {
    const response = await putChuteConfigsById(params, data);
    return response;
  },
  deleteChuteConfigsById: async (params1, params2) => {
    const response = await deleteChuteConfigsById(params1, params2);
    return response;
  },
  deleteChuteConfigsBulkById: async (params1, data) => {
    const response = await deleteChuteConfigsBulkById(params1, data);
    return response;
  },
  getChuteByIdTemplate: async () => {
    const response = await getChuteByIdTemplate();
    return response;
  },
  downloadChuteById: async (params) => {
    const response = await downloadChuteById(params);
    return response;
  },
  postChuteByIdTemplate: async (params, data) => {
    const response = await postChuteByIdTemplate(params, data);
    return response;
  },
  getAgentConfigs: async (params) => {
    const response = await getAgentConfigs(params);
    return response;
  },
  getMachineScans: async (params) => {
    const response = await getMachineScans(params);
    return response;
  },
  getSortingConfig: async (params) => {
    const response = await getSortingConfig(params);
    return response;
  },
  exportMachineScans: async (params) => {
    const response = await exportMachineScans(params);
    return response;
  },
  getThirdparty: async (params) => {
    const response = await getThirdparty(params);
    set({ thirdpartyCouriers: response?.data?.data?.content });
    return response;
  },
  getMapping: async (params) => {
    const response = await getMapping(params);
    return response;
  },
  getMappingTypes: async (params) => {
    const response = await getMappingTypes(params);
    set({ mappingData: response?.data?.data });
    return response;
  },
  receiveMachineScan: async (params) => {
    const response = await receiveMachineScan(params);
    return response;
  },
  outScanMachineScan: async (params) => {
    const response = await outScanMachineScan(params);
    return response;
  },
  postAgentConfigs: async (data) => {
    const response = await postAgentConfigs(data);
    return response;
  },
  putAgentConfigs: async (params, data) => {
    const response = await putAgentConfigs(params, data);
    return response;
  },
  deleteAgentConfigs: async (params) => {
    const response = await deleteAgentConfigs(params);
    return response;
  },
  deactivateAgentConfigs: async (params, data) => {
    const response = await deactivateAgentConfigs(params, data);
    return response;
  },
  downloadAgents: async () => {
    const response = await downloadAgents();
    return response;
  },
  getJobOrders: async (params) => {
    const response = await getJobOrders(params);
    return response;
  },
  postJobOrders: async (data) => {
    const response = await postJobOrders(data);
    return response;
  },
  getJobOrderById: async (params) => {
    const response = await getJobOrderById(params);
    return response;
  },
  putJobOrderById: async (params, data) => {
    const response = await putJobOrderById(params, data);
    return response;
  },
  deleteJobOrderById: async (params) => {
    const response = await deleteJobOrderById(params);
    return response;
  },
  postJobOrdersItemById: async (params, data) => {
    const response = await postJobOrdersItemById(params, data);
    return response;
  },
  putJobOrderItemById: async (params1, params2, data) => {
    const response = await putJobOrderItemById(params1, params2, data);
    return response;
  },
  deleteJobOrderItemById: async (params1, params2) => {
    const response = await deleteJobOrderItemById(params1, params2);
    return response;
  },
  downloadJobOrder: async (params) => {
    const response = await downloadJobOrder(params);
    return response;
  },
  printJobOrderQR: async (params) => {
    const response = await printJobOrderQR(params);
    return response;
  },
  getEmployeeDepartments: async () => {
    const response = await getEmployeeDepartments();
    return response;
  },
  getEmployees: async (params) => {
    const response = await getEmployees(params);
    return response;
  },
  getEvents: async (params) => {
    const response = await getEvents(params);
    return response;
  },
  exportEvents: async (params) => {
    const response = await exportEvents(params);
    return response;
  },
  getOrders: async (params) => {
    const response = await getOrders(params);
    return response;
  },
  postEmployees: async (data) => {
    const response = await postEmployees(data);
    return response;
  },
  postThirdParty: async (data) => {
    const response = await postThirdParty(data);
    return response;
  },
  postMapping: async (data) => {
    const response = await postMapping(data);
    return response;
  },
  EditThirdparty: async (params, data) => {
    const response = await EditThirdparty(params, data);
    return response;
  },
  EditMapping: async (params, data) => {
    const response = await EditMapping(params, data);
    return response;
  },
  getEmployeeById: async (params) => {
    const response = await getEmployeeById(params);
    set({ employeeAccountData: response });
    return response;
  },
  putEmployeeById: async (params, data) => {
    const response = await putEmployeeById(params, data);
    return response;
  },
  exportEmployees: async (params) => {
    const response = await exportEmployees(params);
    return response;
  },
  setSaveHandler: (data) => set({ saveHandler: data }),
  setisEditPickup: (data) => set({ isEditPickup: data }),
  setcustomerAccount: (data) => set({ globalcustomerAccount: data }),
  setglobalEmployeeCode: (data) => set({ globalEmployeeCode: data }),
  setglobalproductCode: (data) => set({ globalproductCode: data }),
  setglobalHubCode: (data) => set({ globalHubCode: data }),
  settierName: (data) => set({ tierName: data }),
  settierCountry: (data) => set({ tierCountry: data }),
  setglobalDepartment: (data) => set({ globalDepartment: data }),
  setglobalFirstname: (data) => set({ globalFirstname: data }),
  setglobalLastname: (data) => set({ globalLastname: data }),
  setglobalJobtitle: (data) => set({ globalJobtitle: data }),
  setglobalEmployeeStatus: (data) => set({ globalEmployeeStatus: data }),
  setglobalUserStatus: (data) => set({ globalUserStatus: data }),
  setglobalHub: (data) => set({ globalHub: data }),
  setglobalPrefferedLanguage: (data) => set({ globalPrefferedLanguage: data }),
  setglobalEmail: (data) => set({ globalEmail: data }),
  setProductsPage: (data) => set({ productsPage: data }),
  setCustomersPage: (data) => set({ customersPage: data }),
  isdropModalOpen: (data) => set({ isdropModalOpen: data }),
  setispostAdded: (data) => set({ ispostAdded: data }),
  setReactSelect: (data) => set({ isReactselectOpen: data }),
  setReactSelectcity: (data) => set({ isReactselectCity: data }),
  setReactSelectorigincity: (data) => set({ isReactoriginselectCity: data }),
  setReactSelectregion: (data) => set({ isReactselectRegion: data }),
  setReactDescity: (data) => set({ isReactdesCity: data }),
  setReactDesCountry: (data) => set({ isReactdesCountry: data }),
  setReactDesBulk: (data) => set({ isReactdesBulk: data }),
  setReactDesregion: (data) => set({ isReactdesRegion: data }),
  setReactRates: (data) => set({ isReactRates: data }),
  setOriginData: (data) => set({ originData: data }),
  setDestinationData: (data) => set({ destinationData: data }),
  setReactCharges: (data) => set({ isReactCharges: data }),
  setReactCurrency: (data) => set({ isReactCurrency: data }),
  setisReactRatestate: (data) => set({ isReactRatestate: data }),
  setSelectedZoneCode: (newSelectedZoneCodes) =>
    set({ selectedZoneCodes: newSelectedZoneCodes }),
  setFormState: (newState) => set({ formState: newState }),
  setBankState: (newState) => set({ bankState: newState }),
  toggleIsTabChanging: () =>
    set((state) => ({ isTabChanging: !state.isTabChanging })),
});

aoneStore = devtools(aoneStore);

export const useAoneStore = create(aoneStore);
