import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get drivers
export const getDrivers = async (params) => {
    try  {
        return await AoneService.get(`ops/api/v1/drivers${params}`)
    } catch (error) {
        return error;
    }
}
//post drivers
export const postDrivers = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/drivers`,data);
    } catch (error) {
      return error;
    }
  };
  //delete drivers
export const deleteDrivers = async (params) => {
  try {
    return await AoneService.delete(`ops/api/v1/drivers/${params}`);
  } catch (error) {
    return error;
  }
};
//export drivers
export const exportDrivers = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/drivers/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//download driver license
export const downloadDriverLicense = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/drivers/${params}/download`,
    {
     responseType: "blob"
    }
  );
  } catch (error) {
    return error;
  }
};
//edit drivers
export const editDrivers = async (params,data) => {
  try {
    return await AoneService.put(`ops/api/v1/drivers/${params}`,data);
  } catch (error) {
    return error;
  }
};
//activate drivers
export const activateDrivers = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/drivers/${params}/activate`);
    } catch (error) {
      return error;
    }
  };
//deactivate drivers
export const deactivateDrivers = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/drivers/${params}/deactive`);
    } catch (error) {
      return error;
    }
  };
  //login-information
export const postLoginInfoDriver = async (params,data) => {
  try {
    return await AoneService.post(
      `ops/api/v1/drivers/${params}/user-management`,
      data
    );
  } catch (error) {
    return error;
  }
};
//reset password
export const resetPasswordDriver = async (param,data) => {
  try {
    return await AoneService.post(
      `ops/api/v1/drivers/${param}/user-management/reset-password`,
      data
    );
  } catch (error) {
    return error;
  }
};
//toggle user status
export const toggleDriverstatus = async (param1, param2) => {
  try {
    return await AoneService.get(
      `ops/api/v1/drivers/${param1}/user-management/status/${param2}`
    );
  } catch (error) {
    return error;
  }
};
//Sensitive fields
export const showSensitiveFields = async (param1, param2) => {
  try {
    return await AoneService.get(
      `ops/api/v1/drivers/${param1}/view/${param2}`
    );
  } catch (error) {
    return error;
  }
};
//generate password
export const generatePassword = async () => {
  try {
    return await AoneService.get(
      "ops/api/v1/drivers/user-management/generate-password"
    );
  } catch (error) {
    return error;
  }
};
//get drivers by id
export const getDriversbyId = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/drivers/${params}`);
    } catch (error) {
      return error;
    }
  };

//drivers template
export const driverTemplate = async () => {
    try {
      return await AoneService.get(
        `ops/api/v1/drivers/download`,
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };

//drivers import
export const importDriver = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/drivers/import`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };
  //upload rc document
  export const importDriverDocument = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/drivers/licenses/upload`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };

//lookup-drivers module
  export const getPayrollCompany = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/PayrollCompany`);
    } catch (error) {
      return error;
    }
  };
  export const getPayrollType = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/PayrollType`);
    } catch (error) {
      return error;
    }
  };