import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getLedger,exportLedger,getTransactions,editTransactionMode,exportTransactions,sumTransactions,getSettlement,exportSettlements,getCollections,postCollections,
    uploadProof,postSettlement,downloadTransactionProof,downloadSettlementProof,sumCollections,exportCollections,getUnsettledCollection,getCollectionMode,getSettlementStatus
} from "../../services/CollectionsMgt/Collection_services";
const initialValues = {
  collectionModeList: [],
  settlementStatusList: [],
};
let collectionStore = (set, get) => ({
  ...initialValues,
  getLedger: async (params) => {
    const response = await getLedger(params);
    return response;
  },
  exportLedger: async (data) => {
    const response = await exportLedger(data);
    return response;
  },
  getTransactions: async (params) => {
    const response = await getTransactions(params);
    return response;
  },
  editTransactionMode: async (params,data) => {
    const response = await editTransactionMode(params,data);
    return response;
  },
  exportTransactions: async (params) => {
    const response = await exportTransactions(params);
    return response;
  },
  sumTransactions: async (params) => {
    const response = await sumTransactions(params);
    return response;
  },
  downloadTransactionProof: async (params) => {
    const response = await downloadTransactionProof(params);
    return response;
  },
  getSettlement: async (params) => {
    const response = await getSettlement(params);
    return response;
  },
  downloadSettlementProof: async (params) => {
    const response = await downloadSettlementProof(params);
    return response;
  },
  exportSettlements: async (params) => {
    const response = await exportSettlements(params);
    return response;
  },
  getCollections: async (params) => {
    const response = await getCollections(params);
    return response;
  },
  sumCollections: async (params) => {
    const response = await sumCollections(params);
    return response;
  },
  postCollections: async (params) => {
    const response = await postCollections(params);
    return response;
  },
  uploadProof: async (data) => {
    const response = await uploadProof(data);
    return response;
  },
  postSettlement: async (params) => {
    const response = await postSettlement(params);
    return response;
  },
  exportCollections: async (params) => {
    const response = await exportCollections(params);
    return response;
  },
  getUnsettledCollection: async (params,params2) => {
    const response = await getUnsettledCollection(params,params2);
    return response;
  },
  getCollectionMode: async () => {
    const response = await getCollectionMode();
    set({ collectionModeList: response });
    return response;
  },
  getSettlementStatus: async () => {
    const response = await getSettlementStatus();
    set({ settlementStatusList: response });
    return response;
  },
});

collectionStore = devtools(collectionStore);

export const useCollectionStore = create(collectionStore);
