import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useNavigate } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../assets/img/template_icon.svg";
import importIcon from "../../assets/img/importIcon.svg";
import removeIcon from "../../assets/img/cancelIcon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../../src/assets/img/dotsbold.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../store/AoneStore";
import { useOrderStore } from "../../store/OrderMgt/OrderStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import * as XLSX from "xlsx";
import jwtDecode from "jwt-decode";
import Modal from "react-bootstrap/Modal";
import { roleHandler } from "../../helper/roleHandler";
import { DateRangePicker } from "rsuite";
import { UserContext } from "../../helper/ProtectedRoute";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
const ConsignmentOrders = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [trackingId, setTrackingId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [receiverPhone, setReceiverPhone] = useState("");
  const [status, setStatus] = useState("");
  const [originCountry, setOriginCountry] = useState("");
  const [dateRangefil, setDateRangefil] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [resetKey, setResetKey] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [originCityPage, setOriginCitypage] = useState(-1);
  const [desCityPage, setDescityPage] = useState(-1);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [originRegion, setOriginRegion] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [destinationRegion, setDestinationRegion] = useState("");
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const [selectOrders, setSelectOrders] = useState([]);
  const [destinationCity, setDestinationCity] = useState("");
  const [isRpu, setisRpu] = useState("");
  const [isRto, setisRto] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [modalHandler, setModalHandler] = useState(false);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const setReactDesBulk = useAoneStore((state) => state?.setReactDesBulk);
  const isReactdesBulk = useAoneStore((state) => state?.isReactdesBulk);
  const [file, setFile] = useState(null);
  const [fileErr,setFileErr] = useState("");
  const codUpdateTemplate = useOrderStore((state) => state?.codUpdateTemplate);
  const importCod = useOrderStore((state) => state?.importCod);
  const printBulkConsignments = useAoneStore(
    (state) => state?.printBulkConsignments
  );
  const cancelConsignments = useAoneStore((state) => state?.cancelConsignments);
  const getOrderEventStatus = useAoneStore(
    (state) => state?.getOrderEventStatus
  );
  const orderEventType = useAoneStore((state) => state?.orderEventType);
  const [showScan, setShowscan] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const handleMenuState = () => {
    setisReactRatestate(true);
  };
  const handleMenuCloseState = () => {
    setisReactRatestate(false);
  };
  const setisReactRatestate = useAoneStore(
    (state) => state?.setisReactRatestate
  );
  const isReactRatestate = useAoneStore((state) => state?.isReactRatestate);
  const handlescanShow = () => {
    if (selectOrders.length >= 1) {
      handleBulkPrintOrders(selectOrders);
    } else {
      toast.error("Please select at least one consignment to print.");
    }
  };
  const handleCancelscanShow = () => {
    if (selectOrders.length >= 1) {
      setShowscan(true);
       handleBulkCancelOrders(selectOrders);
    } else {
      toast.error("Please select at least one consignment to cancel.");
    }
  };
  const gridId = "consignments.orders";
  const [column, SetCoulmn] = useState([
    "Tracking Id",
    "Original Tracking Id",
    "RTO Tracking Id",
    "Reference Number",
    "Product",
    "Status",
    "RTO",
    "RPU",
    "Origin Country",
    "Origin Region",
    "Origin City",
    "Origin District",
    "Destination Country",
    "Destination Region",
    "Destination City",
    "Destination District",
    "Receiver Phone",
    "COD Amount",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "Original Tracking Id",
    "RPU",
    "RTO Tracking Id",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
  ]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const dateFilterOptions = [
    { label: "Created Date", key: "createdAt" },
    { label: "Last Updated Date", key: "updatedAt" },
  ];
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      setFilterDate("");
      setResetKey((prevKey) => prevKey + 1);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const clearStates = () => {
    setReactDesBulk(false);
    setIsChanged(!isChanged);
    setShowscan(false);
    setIsDropdownOpen(false);
    setSelectOrders([]);
    setSelectAll(false);
  };
  const handleBulkPrintOrders = async (selectOrders) => {
    try {
      const res = await printBulkConsignments(selectOrders);
      // Create a Blob from the response data
      const blob = new Blob([res.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      // Open the PDF in a new window
      window.open(blobUrl, "_blank");
      clearStates();
    } catch (error) {}
  };
  const [username, setUsername] = useState("");
  // Decode the token using jwtDecode
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    // Decode the token using jwtDecode
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const { name } = decodedToken;
        setUsername(name);
      } catch (error) {
        console.error("Error decoding token:", error.message);
      }
    } else {
      console.error("Token not found in session storage");
    }
  }, []);
  const handleBulkCancelOrders = async (selectOrders) => {
    try {
      let payload = {
        trackingIds: selectOrders,
        canceledBy: username,
        canceledAt: Date.now(),
      };
      const res = await cancelConsignments(payload);
      if (res?.data?.status == "ERROR") {
        const errorMessage = res?.data?.msg;
        toast.error(errorMessage);
        clearStates();
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
        clearStates();
      } else {
        toast.success("Consignment cancelled");
        clearStates();
        getData();
      }
    } catch (err) {}
  };
  const handlescanClose = () => {
    setShowscan(false);
  };
  const handlescanCancel = () => {
    setShowscan(false);
  };
  const [sortField, setSortField] = useState("-updatedAt");
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const filterRegions = useAoneStore((state) => state?.filterRegions);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const filterCities = useAoneStore((state) => state?.filterCities);
  const getCurrency = useAoneStore((state) => state?.getCurrency);
  const currencyData = useAoneStore((state) => state?.currencyData);
  const orderAddressType = useAoneStore((state) => state?.orderAddressType);
  const orderContentType = useAoneStore((state) => state?.orderContentType);
  const orderDimensionUnit = useAoneStore((state) => state?.orderDimensionUnit);
  const orderIncoterms = useAoneStore((state) => state?.orderIncoterms);
  const orderPickupMethod = useAoneStore((state) => state?.orderPickupMethod);
  const orderWeightUnit = useAoneStore((state) => state?.orderWeightUnit);
  const [selectAll, setSelectAll] = useState(false);
  const getConsignmentOrders = useAoneStore(
    (state) => state?.getConsignmentOrders
  );
  const importConsignmentOrders = useAoneStore(
    (state) => state?.importConsignmentOrders
  );
  const getConsignmentOrdersTemplate = useAoneStore(
    (state) => state?.getConsignmentOrdersTemplate
  );
  const getOrderAddressType = useAoneStore(
    (state) => state?.getOrderAddressType
  );
  const getOrderContentType = useAoneStore(
    (state) => state?.getOrderContentType
  );
  const getOrderDimensionUnit = useAoneStore(
    (state) => state?.getOrderDimensionUnit
  );
  const getOrderIncoterms = useAoneStore((state) => state?.getOrderIncoterms);
  const getOrderPickupMethod = useAoneStore(
    (state) => state?.getOrderPickupMethod
  );
  const getOrderWeightUnit = useAoneStore((state) => state?.getOrderWeightUnit);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const setReactCharges = useAoneStore((state) => state?.setReactCharges);
  const setReactCurrency = useAoneStore((state) => state?.setReactCurrency);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };
  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [selectedOptionDesCity, setSelectedOptionDesCity] = useState(null);
  const [prevSearch, setPrevSearch] = useState("");
  const loadMoreCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : originCityPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setOriginCitypage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, originCityPage, prevSearch, setPrevSearch]
  );
  const [prevdesSearch, setPrevDesSearch] = useState("");
  const loadMoreDesCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : desCityPage + 1;
        if (prevdesSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevDesSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDescityPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desCityPage, setPrevDesSearch, prevdesSearch]
  );
  const importProducts = useAoneStore((state) => state?.importProducts);
  const downloadTemplate = async () => {
    try {
      const res = await getConsignmentOrdersTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Consignments Template.xlsx`);
      }
    } catch (err) {}
  };
  const downloadCodTemplate = async () => {
    try {
      const res = await codUpdateTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `COD Update Template.xlsx`);
      }
    } catch (err) {}
  };
  const [isDropdwnOpen, setIsDropdwnOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown
  // Toggle dropdown
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  // Handle clicks outside the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    // Add event listener for clicks outside of dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
//cod update bulk
const [showUpload, setShowUpload] = useState(false);
const handleCloseUpload = () => {
  setShowUpload(false);
  setIsDropdownOpen(false);
};
const openUpload = () => {
  setShowUpload(true);
};
function handleCodFileUpload(event) {
  const file = event.target.files[0];
  const fileName = file?.name;
  const fileNameParts = fileName?.split(".");
  const allowedExtensions = [".xlsx"];
  const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
  if (!allowedExtensions.includes("." + fileExtension)) {
    // Show toast error
    toast.error(
      "Invalid file format. Please upload a .xlsx file"
    );
    return; 
  }
  const fileSizeLimit = 1024 * 1024; // 1MB
  if (file?.size > fileSizeLimit) {
    toast.error("File size should not exceed 1MB.");
    return;
  }
  setFile(file);
  setFileErr("");
  // Assuming addVehicleDoc is a state or reference to Formik's form instance
  const fileInput = event.target;
  const fileNameDisplay = document.getElementById("insurance-file-name");
  if (fileNameDisplay) {
    const fileInput = event.target;
    if (fileInput?.files?.length > 0) {
      fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
    } else {
      fileNameDisplay.textContent = "";
    }
  }
}
const handleFileCod = async () => {
  if (!file) {
    setFileErr("Please select the file to update cod for consignments");
    return;
  }
  const reader = new FileReader();
  reader.onload = (e) => {
    try {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
        let formData = new FormData();
        formData.append("file", file);

        if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
          const localesColumnRange = getColumnRange(sheet, 3);
          importCod(formData)
            .then((response) => {
              if (response?.data?.status === "SUCCESS") {
                getData();
                setispostAdded(!ispostAdded)
                toast.success("Cod Updated successfully!");
                setFile("");
                setFileErr("");
                setShowUpload(false);
              } else if (response?.data?.errorCode !== "" && response?.data?.errorCode !== undefined) {
                const errorMessage = response?.data?.msg;
                toast.error(errorMessage);
                setFile("");
                setFileErr("");
                setShowUpload(false);
              } else if (response?.data?.status === "ERROR") {
                const errorMessages = response?.data?.data
                    .map((item) => {
                        const trackingIdMessage = item.trackingId ? `Tracking ID: ${item.trackingId}: ` : '';
                        const errorMsg = item.errorMsg.join("; ");
                        return `Row ${item.errorRow}: ${trackingIdMessage}${errorMsg}`;
                    })
                    .join(", ");
                toast.error(errorMessages);
                setFile("");
                setFileErr("");
                setShowUpload(false);
            } else if (response?.data?.data?.status === "ERROR") {
                const errorMessages = response?.data?.data
                    .map((item) => {
                        const trackingIdMessage = item.trackingId ? `Tracking ID: ${item.trackingId}: ` : '';
                        const errorMsg = item.errorMsg.join("; ");
                        return `Row ${item.errorRow}: ${trackingIdMessage}${errorMsg}`;
                    })
                    .join(", ");
                toast.error(errorMessages);
                setFile("");
                setFileErr("");
                setShowUpload(false);
              } else if (
                response &&
                response?.response?.data?.status === "ERROR"
              ) {
                // Handle error response from the server
                const errorMessage =
                  response?.response?.data?.msg?.split(",")[0];
                toast.error(errorMessage);
                setFile("");
                setFileErr("");
              } else if (response?.data?.status === "ERROR") {
                const errorMessage = response?.data?.msg?.split(",")[0];
                toast.error(errorMessage);
                setFile("");
                setFileErr("");
                setShowUpload(false);
              } else if (
                response?.data?.data?.length > 0 &&
                response?.data?.status === "ERROR"
              ) {
                // Handle error response from the server
                const errorMessages = response?.data?.data
                  .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                  .join(", ");
                const errorMessage =
                  response?.response?.data?.msg?.split(",")[0];
                toast.error(errorMessage);
                setFile("");
                setFileErr("");
                setShowUpload(false);
              } else if (response?.response?.data?.status === "ERROR") {
                const errorMessage =
                  response?.response?.data?.msg?.split("!")[0];
                toast.error(errorMessage);
                setFile("");
                setFileErr("");
                setShowUpload(false);
              } else {
                getData();
                toast.success("File uploaded successfully!");
                setFile("");
                setFileErr("");
                setShowUpload(false);
              }
            })
            .catch((error) => {
              toast.error("Error reading the file. Please try again.");
              setFile("");
              setFileErr("");
              setShowUpload(false);
            });
        } else {
          // Show error toast for empty sheet
          toast.error(
            "Error: The sheet is empty. Please provide valid data."
          );
          setFile("");
          setFileErr("");
          setShowUpload(false);
        }
      } else {
        // Show error toast for invalid sheet
        toast.error(
          "Error: Invalid sheet. Please provide a valid Excel file."
        );
        setFile("");
        setFileErr("");
        setShowUpload(false);
      }
    } catch (error) {
      // Handle any errors during file reading
      toast.error("Error reading the file. Please try again.");
      setFile("");
      setFileErr("");
      setShowUpload(false);
    }
  };

  // Read the file as array buffer
  reader.readAsArrayBuffer(file);
};
function getColumnRange(sheet, columnNumber) {
  const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
  const range = sheet["!ref"].split(":");
  return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
}
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const files = fileInput.files;

    if (!files || files.length === 0) {
      return; // No file selected, handle accordingly
    }

    // Prepare to read the file
    const file = files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        let formData = new FormData();
        formData.append("file", file);

        // Collect data for all sheets
        workbook.SheetNames.forEach((sheetName) => {
          const sheet = workbook.Sheets[sheetName];
          if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            formData.append(sheetName, JSON.stringify(sheetData));
          }
        });
        importConsignmentOrders(formData)
          .then((response) => {
            if (response && response?.response?.data?.status === "ERROR") {
              // Handle error response from the server
              const errorMessage = response?.response?.data?.msg?.split(",")[0];
              toast.error(errorMessage);
              fileInput.value = "";
            } else if (response?.response?.status === "ERROR") {
              toast.error(response.response.statusText);
              fileInput.value = "";
            } else if (response?.response?.status == "500") {
              toast.error(response.response.statusText);
              fileInput.value = "";
            } else if (response && response?.data?.status === "ERROR") {
              // Handle error response from the server
              const errorMessages = response?.data?.data
                .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                .join(", ");
              toast.error(`Error uploading the file: ${errorMessages}`);
              const errorMessage = response?.response?.data?.msg?.split(",")[0];
              toast.error(errorMessage);
              fileInput.value = "";
            } else if (response?.response?.data?.status === "ERROR") {
              const errorMessage = response?.response?.data?.msg?.split("!")[0];
              toast.error(errorMessage);
              fileInput.value = "";
            } else if (response?.data?.data?.length > 0) {
              const errorMessages = response?.data?.data
                .map(
                  (item) =>
                    `Sheet ${item?.errorSheetNumber + 1}: row ${
                      item?.errorRow
                    } ${item.errorMsg}`
                )
                .join(", ");
              toast.error(errorMessages);
              fileInput.value = "";
            } else if (response?.data?.status == "IMPORT_ERROR") {
              toast.error(response?.data?.status);
              fileInput.value = "";
            } else {
              // API call was successful
              getData();
              // Show success toast here
              toast.success("File uploaded successfully!");
              fileInput.value = "";
            }
          })
          .catch(() => {
            toast.error("Error reading the file. Please try again.");
            fileInput.value = "";
          });
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };
    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };

  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (trackingIdArray.length > 0) {
      trackingIdArray.forEach((id) => {
        params += `&trackingIds=${id}`;
      });
    }
    if (referenceNumber) {
      params += `&referenceNumber=${referenceNumber}`;
    }
    if (receiverPhone) {
      const encodedPhone = encodeURIComponent(receiverPhone);
      params += `&receiverPhoneNumber=${encodedPhone}`;
    }
    if (originCountry) {
      params += `&originCountryCode=${originCountry}`;
    }
    if (originCity) {
      params += `&originCityCode=${originCity}`;
    }
    if (originRegion) {
      params += `&originRegionCode=${originRegion}`;
    }
    if (destinationCountry) {
      params += `&destinationCountryCode=${destinationCountry}`;
    }
    if (destinationRegion) {
      params += `&destinationRegionCode=${destinationRegion}`;
    }
    if (destinationCity) {
      params += `&destinationCityCode=${destinationCity}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (isRpu) {
      params += `&rpu=${isRpu}`;
    }
    if (isRto) {
      params += `&rto=${isRto}`;
    }
    if (filterDate) {
      params += `&filterDate=${filterDate}`;
      if (dateRangefil?.start) {
        params += `&startTime=${dateRangefil?.start}`;
      }
      if (dateRangefil?.end) {
        params += `&endTime=${dateRangefil?.end}`;
      }
    }
    const data = await getConsignmentOrders(params);
    setTotalCount(data.data.data.totalElements);
    setOrdersData(data.data.data.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    ispostAdded,
    trackingId,
    status,
    isRpu,
    isRto,
    dateRangefil,
    referenceNumber,
    receiverPhone,
    originCountry,
    originCity,
    originRegion,
    destinationCountry,
    destinationRegion,
    destinationCity,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.data?.data?.content?.length == undefined
    ) {
      filterCountries(paramslink);
    }
    if (
      regionsData?.data?.data?.content?.length < 1 ||
      regionsData?.data?.data?.content?.length == undefined
    ) {
      filterRegions(paramslink);
    }
    if (
      citiesData?.data?.data?.content?.length < 1 ||
      citiesData?.data?.data?.content?.length == undefined
    ) {
      filterCities(paramslink);
    }
    if (
      currencyData?.data?.data?.content?.length < 1 ||
      currencyData?.data?.data?.content?.length == undefined
    ) {
      getCurrency(paramslink);
    }
    if (
      orderAddressType?.data?.data?.length < 1 ||
      orderAddressType?.data?.data?.length == undefined
    ) {
      getOrderAddressType();
    }
    if (
      orderContentType?.data?.data?.length < 1 ||
      orderContentType?.data?.data?.length == undefined
    ) {
      getOrderContentType();
    }
    if (
      orderEventType?.data?.data?.length < 1 ||
      orderEventType?.data?.data?.length == undefined
    ) {
      getOrderEventStatus();
    }
    if (
      orderDimensionUnit?.data?.data?.length < 1 ||
      orderDimensionUnit?.data?.data?.length == undefined
    ) {
      getOrderDimensionUnit();
    }
    if (
      orderIncoterms?.data?.data?.length < 1 ||
      orderIncoterms?.data?.data?.length == undefined
    ) {
      getOrderIncoterms();
    }
    if (
      orderPickupMethod?.data?.data?.length < 1 ||
      orderPickupMethod?.data?.data?.length == undefined
    ) {
      getOrderPickupMethod();
    }
    if (
      orderWeightUnit?.data?.data?.length < 1 ||
      orderWeightUnit?.data?.data?.length == undefined
    ) {
      getOrderWeightUnit();
    }
  }, []);
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };

  const handleMenuClosedescity = () => {
    setReactDescity(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleMenuRates = () => {
    setReactRates(true);
  };

  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const handleMenuCharges = () => {
    setReactCharges(true);
  };

  const handleMenuCloseCharges = () => {
    setReactCharges(false);
  };
  const handleMenuCurrency = () => {
    setReactCurrency(true);
  };

  const handleMenuCloseCurrency = () => {
    setReactCurrency(false);
  };
  const [trackingIdArray, setTrackingIdArray] = useState([]);
  const handleInputChange = (e) => {
    setTrackingId(e.target.value);
    const inputText = e.target.value;
    const hasSpacesOrCommas = /\s|,/.test(inputText);

    if (hasSpacesOrCommas) {
      const trackingArray = inputText.split(/[,\s]+/).filter(Boolean);
      setTrackingIdArray(trackingArray);
    } else {
      setTrackingIdArray([inputText]);
    }
  };
  const resetTrackingId = () => {
    setTrackingId("");
    setTrackingIdArray([]);
  };
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/consignments/orders")}
              >
                Consignments
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/consignments/orders")}
              >
                Orders
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <>
            <Row>
              <Col
                className={
                  trackingId?.length < 22
                    ? "col-20-percent"
                    : trackingId?.length < 44
                    ? "col-28-percent"
                    : trackingId?.length < 67
                    ? "col-40-percent"
                    : trackingId?.length < 87
                    ? "col-50-percent"
                    : "col-100-percent"
                }
                md={3}
              >
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Tracking Id:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={trackingId}
                    className={
                      trackingId?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      trackingId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={handleInputChange}
                  />
                  {trackingId && (
                    <button
                      className="cancel_btn clear-btn"
                      type="button"
                      onClick={resetTrackingId}
                    >
                      <span>
                        <img src={removeIcon} alt="Clear" />
                      </span>
                    </button>
                  )}
                </div>
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                  Reference Number:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={referenceNumber}
                    className={
                      referenceNumber?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      referenceNumber?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setReferenceNumber(e.target.value)}
                  />
                  {referenceNumber && (
                    <button
                      className="cancel_btn clear-btn"
                      type="button"
                      onClick={() => setReferenceNumber("")}
                    >
                      <span>
                        <img src={removeIcon} alt="Clear" />
                      </span>
                    </button>
                  )}
                </div>
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 w50">
                  Status:
                </label>
                <SearchInput
                  id="status"
                  label="status"
                  name="status"
                  options={orderEventType?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  handleChange={(selectedOption) => {
                    setStatus(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuState}
                  onMenuClose={handleMenuCloseState}
                  menuIsOpen={isReactRatestate}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Status"
                  className={
                    status?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Origin Country:
                </label>
                <SearchInput
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  handleChange={(selectedOption) => {
                    setOriginCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Origin Country"
                  className={
                    originCountry?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Origin Region:
                </label>
                <SearchInput
                  id="originRegion"
                  label="originRegion"
                  name="originRegion"
                  options={regionsData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  handleChange={(selectedOption) => {
                    setOriginRegion(selectedOption ? selectedOption.code : "");
                  }}
                  onMenuOpen={handleMenudescity}
                  onMenuClose={handleMenuClosedescity}
                  menuIsOpen={isReactdesCity}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Origin Region"
                  className={
                    originRegion?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Origin City:
                </label>
                <AsyncSearchInput
                  id="originCity"
                  label="originCity"
                  name="originCity"
                  value={selectedOptionCity}
                  loadMoreOptions={loadMoreCityOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  menuIsOpen={isReactRates}
                  onChange={(selectedOptionCity) => {
                    setSelectedOptionCity(selectedOptionCity);
                    if (selectedOptionCity) {
                      setOriginCity(selectedOptionCity.value);
                    } else {
                      setOriginCity(null);
                    }
                  }}
                  placeholder="Origin City"
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                  Destination Country:
                </label>
                <SearchInput
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  options={countriesData?.data?.data?.content || []}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenuCharges}
                  onMenuClose={handleMenuCloseCharges}
                  menuIsOpen={isReactCharges}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Destination Country"
                  className={
                    destinationCountry?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                  Destination Region:
                </label>
                <SearchInput
                  id="destinationRegion"
                  label="destinationRegion"
                  name="destinationRegion"
                  options={regionsData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  handleChange={(selectedOption) => {
                    setDestinationRegion(
                      selectedOption ? selectedOption.code : ""
                    );
                  }}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Destination Region"
                  className={
                    destinationRegion?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                  Destination City:
                </label>
                <AsyncSearchInput
                  id="destinationCity"
                  label="destinationCity"
                  name="destinationCity"
                  value={selectedOptionDesCity}
                  loadMoreOptions={loadMoreDesCityOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuCurrency}
                  onMenuClose={handleMenuCloseCurrency}
                  menuIsOpen={isReactCurrency}
                  onChange={(selectedOptionDesCity) => {
                    setSelectedOptionDesCity(selectedOptionDesCity);
                    if (selectedOptionDesCity) {
                      setDestinationCity(selectedOptionDesCity.value);
                    } else {
                      setDestinationCity(null);
                    }
                  }}
                  placeholder="Destination City"
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                  Receiver Phone:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={receiverPhone}
                    className={
                      receiverPhone?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      receiverPhone?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setReceiverPhone(e.target.value)}
                  />
                  {receiverPhone && (
                    <button
                      className="cancel_btn clear-btn"
                      type="button"
                      onClick={() => setReceiverPhone("")}
                    >
                      <span>
                        <img src={removeIcon} alt="Clear" />
                      </span>
                    </button>
                  )}
                </div>
              </Col>
              <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Date Filter:
              </label>
              <SearchInput
                key={`dateFilter-${resetKey}`}
                id="dateFilter"
                label="dateFilter"
                name="dateFilter"
                options={dateFilterOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.key}
                onMenuOpen={handleMenuOpencity}
                onMenuClose={handleMenuClosecity}
                menuIsOpen={isReactselectCity}
                handleChange={(selectedOption) => {
                  setFilterDate(selectedOption ? selectedOption.key : "");
                }}
                isClearable={true}
                isSearchable={true}
                placeholder="Select Date Filter"
                className={"mainInput-dropdown"}
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">Date:</label>
              <DateRangePicker
                format="yyyy-MM-dd"
                placeholder="Select Date"
                value={dateRange}
                onChange={handleDateRangeChange}
                placement="auto"
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 3))
                }
                onOpen={handleMenuState}
                onClose={handleMenuCloseState}
                showMeridian
                className="w200"
              />
            </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">RPU:</label>
                <div className="col-md-8">
                  <div className="smallCheckbox">
                    <input
                      type="checkbox"
                      id="isRpu"
                      checked={isRpu}
                      style={{ marginLeft: "5px" }}
                      onChange={() => setisRpu(!isRpu)}
                    />
                    <label className="margintop7" htmlFor="isRpu"></label>
                  </div>
                </div>
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">RTO:</label>
                <div className="col-md-8">
                  <div className="smallCheckbox">
                    <input
                      type="checkbox"
                      id="isRto"
                      checked={isRto}
                      style={{ marginLeft: "5px" }}
                      onChange={() => setisRto(!isRto)}
                    />
                    <label className="margintop7" htmlFor="isRto"></label>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              <div className="displayFlex gap_12">
                {(roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") ||
                  roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.edit_cod") ||
                  roleHandler(
                    role,
                    "AONE_CONSIGNMENTS_CONSIGNMENT.cancel"
                  )) && (
                  <>
                    <div class="jira-dropdown w115 ml_15 marginR-4" ref={dropdownRef}>
                        <>
                          <button
                            className="jira-dropdown-button"
                            onClick={() => {
                              setIsDropdownOpen(!isDropdownOpen);
                              setIsDropdwnOpen(!isDropdwnOpen);       
                              setReactDesBulk(!isReactdesBulk);
                            }}
                          >
                            Bulk Actions
                          </button>
                          <ul
                            className="jira-dropdown-content"
                            style={{
                              display: isDropdownOpen ? "block" : "none",
                              borderRadius: "10px",
                            }}
                          >
                            {roleHandler(
                              role,
                              "AONE_CONSIGNMENTS_CONSIGNMENT.create"
                            ) && (
                              <li onClick={handlescanShow}>
                                <a>Print</a>
                              </li>
                            )}
                             {roleHandler(
                              role,
                              "AONE_CONSIGNMENTS_CONSIGNMENT.create" ||
                              roleHandler(
                                role,
                                "AONE_CONSIGNMENTS_CONSIGNMENT.edit_cod")
                            ) && (
                              <li onClick={() => openUpload()}>
                                <a>Update COD</a>
                              </li>
                            )}
                            {roleHandler(
                              role,
                              "AONE_CONSIGNMENTS_CONSIGNMENT.cancel"
                            ) && (
                              <li onClick={handleCancelscanShow}>
                                <a>Cancel</a>
                              </li>
                            )}
                          </ul>
                        </>
                    </div>
                    {/* )} */}
                    <button
                      className="filterBtn plus_icon"
                      title={"Download Consignment Template"}
                      onClick={downloadCodTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div title={"Import Consignments"}>
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                )}
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => navigate("/consignments/orders/add")}
                  >
                    +
                  </button>
                )}
                <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        )}
        {!editPage && (
          <PaginationTable
            tableData={ordersData}
            restrict={checkedCol}
            tbl={"ConsignmentOrders"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            isDropdownOpen={isDropdownOpen}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            setSelectOrders={setSelectOrders}
            selectOrders={selectOrders}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
            <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">Upload the document to update COD for consignments</h4>
          </Modal.Header>
          <Modal.Body>
                <div className="row">
              <div className="col-md-8">
                <div className="custom-file-upload">
                  <input
                    type="file"
                    onChange={(e) => handleCodFileUpload(e)}
                    name="insuranceDocument"
                    className="mainInput"
                    id="insurance-upload" />
                  <label htmlFor="insurance-upload" className="upload-label">
                    Upload File to update COD for consignments
                  </label>
                  {file?.name &&
                  <><div className="document-already-uploaded">
                        <span className="document-status">
                          Document Status: Uploaded
                        </span>
                      </div><div className="file-info">
                          <p className="file-name">
                            <p>{file?.name}</p>
                          </p>
                        </div></>}
                        {fileErr ? (
                          <p className="requiredText">
                            {fileErr}
                          </p>
                        ) : null}
                </div>
              </div>
              <div className="col-md-4 mt_10">
              <button
                      className="filterBtn plus_icon mr_10 ml_10 mt_5"
                      title={"COD Update Template"}
                      onClick={downloadCodTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
              </div>
              </div>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={() => {
                    handleFileCod();
                  }}
                >
                  Upload
                </button>
              </div>
              <div>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseUpload()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ConsignmentOrders;
