import React, { useState, useEffect, useRef } from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg";
import { useNavigate, useParams } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import OrdersDetail from "./OrdersDetail";
import OrderTrack from "./orderTrack";
import PackageDetails from "./PackageDetails";
const OrderDetailsAdd = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [trackingData, setTrackingData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const getConsignmentStatus = useAoneStore(
    (state) => state?.getConsignmentStatus
  );
  const consignemntStatus = useAoneStore((state) => state?.consignemntStatus);
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [tab, setTab] = useState(1);
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const getConsignmentOrdersbyId = useAoneStore(
    (state) => state?.getConsignmentOrdersbyId
  );
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const getData = async () => {
    let paramsData = `?page=${newPage}&size=${size}&trackingIds=${params?.id}&fields=employeeName&fields=failureReason`;
    const data = await getConsignmentStatus(paramsData);
    setTotalCount(data.data.data.totalElements);
    setTrackingData(data.data.data.content);
  };
  useEffect(() => {
    getData();
  }, []);
  const [orderDetails, setOrderDetails] = useState([]);
  const getOrderDetail = async () => {
    const data = await getConsignmentOrdersbyId(params?.id);
    setOrderDetails(data?.data?.data);
  };
  const [isIncoUpdated, setIsIncoUpdated] = useState(false);
  useEffect(() => {
    getOrderDetail();
  }, [isIncoUpdated]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/consignments/orders")}
              >
                Consignments
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/consignments/orders")}
              >
                Orders
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/consignments/orders")}
              >
                {params?.id}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(1);
          }}
        >
          Details
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(2);
          }}
        >
          Tracking
        </button>
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(3);
          }}
        >
          Packages
        </button>
      </div>
      <div>
        {tab == 1 ? (
          <OrdersDetail
            edit={setEditPage}
            setTab={setTab}
            isIncoUpdated={isIncoUpdated}
            setIsIncoUpdated={setIsIncoUpdated}
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
          />
        ) : tab == 2 ? (
          <OrderTrack edit={setEditPage} totalCount={totalCount}/>
        ) : (
          <PackageDetails edit={setEditPage} orderDetails={orderDetails} />
        )}
      </div>
    </div>
  );
};

export default OrderDetailsAdd;
