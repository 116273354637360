import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../../store/AoneStore";
import { usepickupStore } from "../../../store/PickupRequest/pickuprequestStore";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import templateIcon from "../../../../src/assets/img/template_icon.svg";
import importIcon from "../../../../src/assets/img/importIcon.svg";
import Row from "react-bootstrap/Row";
import * as XLSX from "xlsx";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import downloadIcon from "../../../assets/img/download_icon.svg";
import removeIcon from "../../../../src/assets/img/cancelIcon.svg";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import fileDownload from "js-file-download";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { roleHandler } from "../../../helper/roleHandler";
import { DateRangePicker } from "rsuite";
const Pickups = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const paramsId = useParams();
  const prevent = useRef(false);
  const location = useLocation();
  const [editPage, setEditPage] = useState(false);
  const [tab, setTab] = useState(1);
  const [headerParams, setHeaderParams] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [cusPage, setCusPage] = useState(-1);
  const [size, setSize] = useState(20);
  const [isChange, setIsChange] = useState(false);
  const [page, setPage] = useState(-1);
  const [filterDate, setFilterDate] = useState("");
  const [originCityPage, setOriginCitypage] = useState(-1);
  const [courierFilPage, setCourierFilPage] = useState(-1);
  const [districtPage, setDistrictpage] = useState(-1);
  const [file, setFile] = useState(null);
  const [selecReq,setSelectReq] = useState("")
  const [fileErr,setFileErr] = useState("");
  const [courierFilterPage, setCourierFilterPage] = useState(-1);
  const [selectedOptionCouriers, setSelectedOptionCouriers] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pickupData, setPickupData] = useState([]);
  const gridId = "ops.pickupRequest"
  //pickup request columns
  const [columnPickupReq, SetCoulmnPickupreq] = useState([
    "Request Id",
    "Customer Account",
    "Customer Name",
    "Shipper Name",
    "Status",
    "Total Packages",
    "Total Count",
    "Success Count",
    "Failed Count",
    "RPU",
    "Reason",
    "Courier Id",
    "Origin Country",
    "Origin City",
    "Shipper Address",
    "Shipper Phone",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
    "Actions",
  ]);
  const [checkedColPickupReq, setCheckedColPickupReq] = useState([
    "RPU",
    "Reason",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
  ]);
  const handleSelectPickupReq = (d) => {
    let val = checkedColPickupReq.includes(d);
    if (val) {
      setCheckedColPickupReq(checkedColPickupReq.filter((dat) => dat !== d));
    } else {
      setCheckedColPickupReq([...checkedColPickupReq, d]);
    }
  };
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedColPickupReq(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedColPickupReq.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedColPickupReq, gridId]);
  const [dataPickup, setDataPickup] = useState([]);
  const [trackId, setTrackId] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [selectPickup, setSelectPickup] = useState([]);
  const [selectoriginHub, setSelectOriginHub] = useState([]);
  const [selectPickuprequest, setSelectPickuprequest] = useState([]);
  const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
  const productsData = useAoneStore((state) => state?.productsData);
  const [pickupTitle, setPickupTitle] = useState("Create new pickup request");
  const dateFilterOptions = [
    { label: "Created Date", key: "createdAt" },
    { label: "Last Updated Date", key: "updatedAt" },
  ];
  const [sortField, setSortField] = useState("-updatedAt");
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const filterProducts = useAoneStore((state) => state?.filterProducts);
  const filterCities = useAoneStore((state) => state?.filterCities);
  const getDistricts = useAoneStore((state) => state?.getDistricts);
  const setReactCurrency = useAoneStore((state) => state?.setReactCurrency);
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  //filters
  const [resetKey, setResetKey] = useState(0);
  const [trackingId, setTrackingId] = useState("");
  const [customer, setCustomer] = useState("");
  const [product, setProduct] = useState("");
  const [originCountry, setOriginCountry] = useState(null);
  const [originCity, setOriginCity] = useState(null);
  const [originDistrict, setOriginDistrict] = useState(null);
  const [courier, setCourier] = useState("");
  const [status, setStatus] = useState("");
  const [requestId, setRequestId] = useState("");
  const [isRpu, setisRpu] = useState("");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
  const [selOptionCustomer, setOptionCustomer] = useState(null);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [selectedOptionDistrict, setSelectedOptionDistrict] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const [seleczone, setselectZone] = useState([]);
  const [selecwaybill, setSelectWaybill] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const getConsignments = usepickupStore((state) => state?.getConsignments);
  const getPickupRequest = usepickupStore((state) => state?.getPickupRequest);
  const postPickupRequest = usepickupStore((state) => state?.postPickupRequest);
  const applyBulkPickupRequest = usepickupStore((state) => state?.applyBulkPickupRequest);
  const getPickupReasons = useAoneStore((state) => state?.getPickupReasons);
  const getPickupTemplate = usepickupStore((state) => state?.getPickupTemplate);
  const applyPickupRequest = usepickupStore((state) => state?.applyPickupRequest);
  const getEmployee = useAoneStore((state) => state?.getEmployee);
  const bulkapplyConsignments = usepickupStore(
    (state) => state?.bulkapplyConsignments
  );
  const employeeData = useAoneStore((state) => state?.employeeData);
  const pickupReasonsData = useAoneStore((state) => state?.pickupReasonsData);
  const cancelPickupRequest = usepickupStore(
    (state) => state?.cancelPickupRequest
  );
  const getConsignmentStatus = usepickupStore(
    (state) => state?.getConsignmentStatus
  );
  const getPickuprequestStatus = usepickupStore(
    (state) => state?.getPickuprequestStatus
  );
  const consignmentStatus = usepickupStore((state) => state?.consignmentStatus);
  const importPickupRequestsProof = usepickupStore((state) => state?.importPickupRequestsProof);
  const pickupStatus = usepickupStore((state) => state?.pickupStatus);
  const editPickupRequest = usepickupStore((state) => state?.editPickupRequest);
  const getPickupRequestbyId = usepickupStore(
    (state) => state?.getPickupRequestbyId
  );
  const exportPickupRequests = usepickupStore(
    (state) => state?.exportPickupRequests
  );
  const isPickupRequest = usepickupStore((state) => state?.isPickupRequest);
  const setisPickupRequest = usepickupStore(
    (state) => state?.setisPickupRequest
  );
  const importPickupRequests = usepickupStore(
    (state) => state?.importPickupRequests
  );
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [cancelRequestId, setCancelRequestId] = useState("");
  const [cancelCourierId, setCancelCourierId] = useState("");
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const [showPickup, setShowPickup] = useState(false);
  const handleShoworder = () => setShowPickup(true);
  const setisReactRatestate = useAoneStore(
    (state) => state?.setisReactRatestate
  );
  const handleMenuCurrency = () => {
    setReactCurrency(true);
  };
  const handleMenuCloseCurrency = () => {
    setReactCurrency(false);
  };
  const [dateRangefil, setDateRangefil] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      setResetKey((prevKey) => prevKey + 1);
      setFilterDate("");
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const isReactRatestate = useAoneStore((state) => state?.isReactRatestate);
  const handleClosePickup = () => {
    setShowPickup(false);
    setCourierPage(-1);
    setSelectedOptionCourier(null);
  };
  const [showCancel, setShowCancel] = useState(false);
  const handleShowCancel = () => setShowCancel(true);
  const handleCloseCancel = () => {
    setShowCancel(false);
  };
  const assignPickup = () => {
    setCustomer("");
    setShowPickup(true);
    setCusPage(-1);
    setSelectedOptionCustomer(null);
    addPickupRequest.resetForm();
    setResetKey((prevKey) => prevKey + 1);
    setPickupTitle("Create new pickup request");
    setOptionCustomer(null);
  };
  const tabHandler = (i) => {
    navigate("/ops/pickups");
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };
  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };
  const handleMenuClosedescity = () => {
    setReactDescity(false);
  };
  const handleMenuState = () => {
    setisReactRatestate(true);
  };
  const handleMenuCloseState = () => {
    setisReactRatestate(false);
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.length < 1
    ) {
      filterCountries(paramslink);
    }
    if (
      productsData?.data?.data?.content?.length < 1 ||
      productsData?.length < 1
    ) {
      filterProducts(paramslink);
    }
  }, []);
  const [showUpload, setShowUpload] = useState(false);
  const handleCloseUpload = () => {
    setShowUpload(false);
    setFileErr("");
  };
  const openUpload = () => {
    setShowUpload(true);
  };
  useEffect(() => {
    if (consignmentStatus?.data?.data === undefined) {
      getConsignmentStatus();
    }
    if (pickupStatus?.data?.data === undefined) {
      getPickuprequestStatus();
    }
    let paramsLink = `?page=${newPage}&size=100&sortBy=${sortField}`;
    getPickupReasons(paramsLink);
  }, []);
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const [selectedOptionCourier, setSelectedOptionCourier] = useState(null);
  const [selectedOptionCourierFil, setSelectedOptionCourierFil] =
    useState(null);
  const [courierPage, setCourierPage] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const [userHub,setUserHub] = useState("")
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  //Bulk assign pickup request
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setCourierFilPage(-1);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown
  // Toggle dropdown
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  // Handle clicks outside the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    // Add event listener for clicks outside of dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const loadMoreOptionCourierFilter = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierFilPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierFilPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierFilPage, prevSearch, setPrevSearch]
  );
  const assignBulkPickups = useFormik({
    initialValues: {
      requestId: [],
      employeeCode: "",
    },
    validationSchema: Yup.object({
      employeeCode: Yup.string().required("Courier is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await applyBulkPickupRequest(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Pickup requests created`);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          getData();
          setIsChange(!isChange);
          setShow(false);
          resetForm();
          setSelectAll(false);
          setIsDropdownOpen(false);
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShow(false);
          resetForm();
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsDropdownOpen(false);
          setIsChange(!isChange);
          toast.error(errorMessages);
          setSelectAll(false);
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsChange(!isChange);
          setIsDropdownOpen(false);
          setSelectAll(false);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsDropdownOpen(false);
          setIsChange(!isChange);
          resetForm();
          setSelectAll(false);
        } else if (response?.response?.data?.status == 500) {
          const errorMessage = response?.response?.data?.error;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsDropdownOpen(false);
          setIsChange(!isChange);
          resetForm();
          setSelectAll(false);
        } else {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsDropdownOpen(false);
          setIsChange(!isChange);
          setSelectAll(false);
          resetForm();
        }
      });
    },
  });
  const handleAssignCourier = () => {
    if (selectPickup.length < 1) {
      toast.error("Select Request id's to assign a courier");
    } else {
      setShow(true);
      setSelectedOptionCourier(null);
      assignBulkPickups.setFieldValue("requestId", selectPickup);
    }
  };
  const textareaRef = useRef(null);
  const requestIdsString = assignBulkPickups?.values?.requestId?.join("\n");
  useEffect(() => {
    const adjustHeight = () => {
      if (textareaRef.current) {
        // Reset the height first to recalculate the proper scroll height
        textareaRef.current.style.height = "auto";
        // Calculate height with 40% increase
        const newHeight = textareaRef.current.scrollHeight * 2.5;
        textareaRef.current.style.height = `${newHeight}px`;
      }
    };
    // Use setTimeout to ensure the height calculation happens after rendering
    setTimeout(adjustHeight, 0);
  }, [requestIdsString]);
  const downloadTemplate = async () => {
    try {
      const res = await getPickupTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Pickuprequest Template.xlsx`);
      }
    } catch (err) {}
  };
  const loadMoreOptionsCouriers = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub,loadingMore, courierPage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsCourierFilter = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierFilterPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierFilterPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub,loadingMore, courierFilterPage, prevSearch, setPrevSearch]
  );
  const addPickupRequest = useFormik({
    initialValues: {
      customerAccount: "",
      employeeCode: "",
      consignments: [],
    },
    validationSchema: Yup.object({
      customerAccount: Yup.string().required("Customer is required"),
      consignments: Yup.array()
        .of(Yup.string().required("Consignment is required"))
        .min(1, "At least one consignment is required,upload the consignments"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (pickupTitle == "Edit pickup request") {
        await editPickupRequest(trackId, values).then((response) => {
          if (response.data?.status == "SUCCESS") {
            toast.success("Pickup request Updated");
            setResetKey((prevKey) => prevKey + 1);
            setCourierPage(-1);
            getData();
            setShowPickup(false);
            resetForm();
          } else if (response?.data?.status === "ERROR") {
            setResetKey((prevKey) => prevKey + 1);
            setShowPickup(false);
            setCourierPage(-1);
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.status == 500) {
            setResetKey((prevKey) => prevKey + 1);
            setShowPickup(false);
            setCourierPage(-1);
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
            resetForm();
          } else {
            setResetKey((prevKey) => prevKey + 1);
            setShowPickup(false);
            setCourierPage(-1);
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postPickupRequest(values).then((response) => {
          if (response.data?.status == "SUCCESS") {
            setResetKey((prevKey) => prevKey + 1);
            toast.success("Pickup request Updated");
            setShowPickup(false);
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status === "ERROR") {
            setResetKey((prevKey) => prevKey + 1);
            setShowPickup(false);
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
          } else {
            setResetKey((prevKey) => prevKey + 1);
            setShowPickup(false);
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  const cancelPickRequest = useFormik({
    initialValues: {
      reasonCode: "",
    },
    validationSchema: Yup.object({
      reasonCode: Yup.string().required(
        "Please select the reason for cancellation"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      await cancelPickupRequest(cancelRequestId, values).then((response) => {
        if (response.data?.status == "SUCCESS") {
          setResetKey((prevKey) => prevKey + 1);
          toast.success("Pickup request Cancelled");
          getData();
          setShowCancel(false);
          resetForm();
        } else if (response?.data?.status === "ERROR") {
          setResetKey((prevKey) => prevKey + 1);
          setShowCancel(false);
          const errorMessage = response.data.msg;
          toast.error(errorMessage);
          resetForm();
        } else {
          setResetKey((prevKey) => prevKey + 1);
          setShowCancel(false);
          const errorMessage = response.response.data.msg;
          toast.error(errorMessage);
          resetForm();
        }
      });
    },
  });
  useEffect(() => {
    cancelPickRequest.setFieldValue("requestId", cancelRequestId);
    cancelPickRequest.setFieldValue("courierId", cancelCourierId);
  }, [cancelRequestId]);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  useEffect(() => {
    if (pickupTitle == "Edit pickup request") {
      getPickupbyId();
    } else {
      addPickupRequest.setFieldValue("employeeCode", "");
      addPickupRequest.setFieldValue("customerAccount", "");
      setEditCusName("");
      SetshowPrCustomer(true);
    }
  }, [showPickup]);
  const [showPrCustomer, SetshowPrCustomer] = useState(false);
  const handleInputCusClick = () => {
    SetshowPrCustomer(true);
  };
  const [editCusName, setEditCusName] = useState("");
  const getPickupbyId = async () => {
    const resp = await getPickupRequestbyId(trackId);
    setDataPickup(resp?.data?.data);
    let Cid = resp?.data?.data?.employeeCode;
    let CCustomerAcc = resp?.data?.data?.customerAccount;
    let CConsignment = resp?.data?.data?.consignmentList;
    let CcusName = resp?.data?.data?.customerName;
    setEditCusName(CcusName);
    addPickupRequest.setFieldValue("employeeCode", Cid ? Cid : "");
    addPickupRequest.setFieldValue(
      "customerAccount",
      CCustomerAcc ? CCustomerAcc : ""
    );

    if (CConsignment && CConsignment.length > 0) {
      let modifiedConsignments = [];
      // Iterate over each element in the consignmentList
      for (let consignment of CConsignment) {
        // Push the trackingId into the modifiedConsignments array
        modifiedConsignments.push(consignment.trackingId);
      }
      // Set the modifiedConsignments array to the consignments field in addPickupRequest
      addPickupRequest.setFieldValue("consignments", modifiedConsignments);
    } else {
      // If CConsignment is empty, set consignments field to an empty array
      addPickupRequest.setFieldValue("consignments", []);
    }
  };
  //customer async
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  const loadMoreCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : originCityPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setOriginCitypage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, originCityPage]
  );
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=customerName&fields=packagesCount`;
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (product) {
      params += `&productCode=${product}`;
    }
    if (originCountry) {
      params += `&originCountry=${originCountry}`;
    }
    if (originCity) {
      params += `&originCity=${originCity}`;
    }
    if (originDistrict) {
      params += `&originDistrict=${originDistrict}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (requestId) {
      params += `&requestId=${requestId}`;
    }
    if (courier) {
      params += `&employeeCode=${courier}`;
    }
    if (filterDate) {
      params += `&filterDate=${filterDate}`;
      if (dateRangefil?.start) {
        params += `&startTime=${dateRangefil?.start}`;
      }
      if (dateRangefil?.end) {
        params += `&endTime=${dateRangefil?.end}`;
      }
    }
    if (isRpu) {
      params += `&rpu=${isRpu}`;
    }
    const data = await getPickupRequest(params);
    setTotalCount(data.data.data.totalElements);
    setPickupData(data.data.data.content);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    tab,
    size,
    sortField,
    saveHandler,
    customer,
    product,
    ispostAdded,
    dateRangefil,
    isRpu,
    originCountry,
    originCity,
    originDistrict,
    courier,
    trackingId,
    requestId,
    status,
  ]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [currentTimeMillis, setCurrentTimeMillis] = useState(null);
  const [eventTimeZone, setEventTimeZone] = useState("");
  useEffect(() => {
    // Function to get user's location
    const requestUserLocation = () => {
      // Check if the browser supports Geolocation
      if (navigator.geolocation) {
        // Request user's permission to access their location
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Success: Get the latitude and longitude
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
          },
          (error) => {
            // Handle error (e.g., user denied permission)
            console.error("Error obtaining location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    // Request the user's location when the component mounts
    requestUserLocation();

    // Get the current time in milliseconds
    const currentDate = new Date();
    setCurrentTimeMillis(currentDate.getTime());

    // Determine the user's event time zone based on the browser's timezone offset
    const timeZoneOffset = currentDate.getTimezoneOffset(); // in minutes

    // Convert the offset to hours and reverse the sign
    const offsetHours = -timeZoneOffset / 60;
    // Format the event time zone
    const formattedTimeZone = `GMT${offsetHours >= 0 ? "+" : ""}${offsetHours}`;

    // Example mapping for different offsets to event time zones
    const timeZoneMap = {
      "+3": "KSA +3",
      // Add more mappings for different offsets if needed
    };

    // Set the event time zone based on the offset
    setEventTimeZone(timeZoneMap[formattedTimeZone] || formattedTimeZone);
  }, []);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };

  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };

  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);
          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            importPickupRequests(formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.status === "ERROR") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response?.response?.status == "500") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  addPickupRequest.setFieldValue(
                    "consignments",
                    response?.data?.data
                  );
                  fileInput.value = "";
                }
              })
              .catch(() => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  //upload proof and apply pickup
  const [proofUuid,setProofUuid] = useState("");
  function handlePickupFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".png", ".pdf", ".doc", ".docx", ".xlsx"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      // Show toast error
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .xlsx file or .png"
      );
      return; 
    }
    const fileSizeLimit = 1024 * 1024; // 1MB
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    setFile(file);
    setFileErr("");
    const fileInput = event.target;
    const fileNameDisplay = document.getElementById("puc-file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  useEffect(() => {
      let formData = new FormData();
      formData.append("file", file);
      importPickupRequestsProof(selecReq,formData)
        .then((response) => {
          setProofUuid(response?.data?.data)
        })
        .catch((error) => {
          // Handle API error
        });
  }, [file]);
  const handleSubmitProof = async() =>{
    if(proofUuid == "" || proofUuid == undefined){
      setFileErr("Upload proof to apply for pickup");
      return;
    }else{
    try {
      let eventObject = {
        eventCode: 201,
        proofImageUuid: proofUuid,
        latitude: latitude,
        longitude: longitude,
        eventDate: currentTimeMillis,
        eventDateZone: eventTimeZone,
        pickupType: "COURIER_PICKUP",
        // dropOffHub: userHub
      };
      const res = await applyPickupRequest(selecReq,eventObject);
      if (res?.data?.status === "SUCCESS") {
        toast.success("Pickup scanned successfully");
        setProofUuid("");
        setFile(null);
        setFileErr("");
        const fileInput = document.getElementById("insurance-upload");
        if (fileInput) {
          fileInput.value = "";
        }
      } else if (res?.data?.status == "ERROR") {
        const errorMessage = res?.data?.msg;
        toast.error(`${errorMessage}`);
        setProofUuid("");
        setFile(null);
        setFileErr("");
      } else if (res?.response?.data?.errors?.length > 0) {
        const errorMessages = res?.response?.data?.errors
          ?.map((item) => `${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
        setFile(null);
        setFileErr("");
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
        setProofUuid("");
        setFile(null);
        setFileErr("");
      } else {
        const errorMessage = res?.data?.msg.split(",")[0];
        toast.error(errorMessage);
        setProofUuid("");
        setFile(null);
        setFileErr("");
      }
    } catch (error) {
      toast.error("Error in revoking the consignment.");
      setProofUuid("");
      setFile(null);
      setFileErr("");
    }
  }
  }
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  // Handle changes in the textarea
  const handleConsignmentsChange = (event) => {
    const newConsignments = event.target.value;
    // Splitting the string by commas to create an array of consignments
    const consignmentsArray = newConsignments
      .split(",")
      .map((item) => item.trim()); // trimming to remove any extra spaces
    addPickupRequest.setFieldValue("consignments", consignmentsArray);
  };
  const exporttoExcel = async () => {
      try {
        let payload = {
          requestIds: selectPickup,
        };
        const res = await exportPickupRequests(payload);
        if (res.status == 200) {
          fileDownload(res.data, `Pickup Requests.zip`);
          setSelectPickup([]);
          setIsChange(!isChange);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
          setSelectPickup([]);
        } else {
          toast.error(res.response.statusText);
          setSelectPickup([]);
        }
      } catch (err) {}
  };
  const fileInputRef = useRef(null);
  const handleClick = () => {
    fileInputRef.current.click(); // Triggering click on file input
  };
  useEffect(() => {
    const hasOpsPermissions =
      roleHandler(role, "AONE_OPS.read") ||
      roleHandler(role, "AONE_OPS.write") ||
      roleHandler(role, "AONE_OPS.admin") ||
      roleHandler(role, "AONE_OPS_CONSIGNMENTS.read") ||
      roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup");

    const hasPickupPermissions =
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.read") ||
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.write") ||
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin") ||
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.cancel");
    if (!hasOpsPermissions && hasPickupPermissions) {
      setTab(2);
    }
  }, [role]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/pickups")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/pickups")}
              >
                Pickup Requests
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
            <Row>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Request Id:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={requestId}
                    className={
                      requestId?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      requestId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setRequestId(e.target.value)}
                  />
                    {requestId && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setRequestId("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
                </div>
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Customer:
                </label>
                <AsyncSearchInput
                  key={resetKey}
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOptionCustomer}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isReactselectOpen}
                  onChange={(selectedOptionCustomer) => {
                    setSelectedOptionCustomer(selectedOptionCustomer);
                    if (selectedOptionCustomer) {
                      setCustomer(selectedOptionCustomer.value);
                    } else {
                      setCustomer(null);
                    }
                  }}
                  placeholder="Customer"
                  className={
                    customer?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Origin Country:
                </label>
                <SearchInput
                  key={resetKey}
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  handleChange={(selectedOption) => {
                    setOriginCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Origin Country"
                  className={
                    originCountry?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Origin City:
                </label>
                <AsyncSearchInput
                  key={resetKey}
                  id="originCity"
                  label="originCity"
                  name="originCity"
                  value={selectedOptionCity}
                  loadMoreOptions={loadMoreCityOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpencity}
                  onMenuClose={handleMenuClosecity}
                  menuIsOpen={isReactselectCity}
                  onChange={(selectedOptionCity) => {
                    setSelectedOptionCity(selectedOptionCity);
                    if (selectedOptionCity) {
                      setOriginCity(selectedOptionCity.value);
                    } else {
                      setOriginCity(null);
                    }
                  }}
                  placeholder="Origin City"
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Courier:
                </label>
                  <AsyncSearchInput
                    id="courier"
                    label="courier"
                    name="courier"
                    value={selectedOptionCourier?.label}
                    loadMoreOptions={loadMoreOptionsCourierFilter}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(courier) => courier.label}
                    getOptionValue={(courier) => courier.value}
                    onChange={(selectedOptionCourierFil) => {
                      setSelectedOptionCourierFil(
                        selectedOptionCourierFil?.label
                      );
                      setCourier(
                        selectedOptionCourierFil
                          ? selectedOptionCourierFil.value
                          : ""
                      );
                    }}
                    onMenuOpen={handleMenudescity}
                    onMenuClose={handleMenuClosedescity}
                    menuIsOpen={isReactdesCity}
                    placeholder="Courier"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Status:
                </label>
                <SearchInput
                  key={resetKey}
                  id="event"
                  label="event"
                  name="event"
                  options={consignmentStatus?.data?.data || []}
                  getOptionLabel={(option) => option.key}
                  getOptionValue={(option) => option.key}
                  handleChange={(selectedOption) => {
                    setStatus(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Status"
                  className={
                    status?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="col-20-percent" md={3}>
            <label className="fontSize12 mb_12 marginR10 mt_10">
              Date Filter:
            </label>
            <SearchInput
              key={`dateFilter-${resetKey}`}
              id="dateFilter"
              label="dateFilter"
              name="dateFilter"
              options={dateFilterOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.key}
              onMenuOpen={handleMenuCurrency}
              onMenuClose={handleMenuCloseCurrency}
              menuIsOpen={isReactCurrency}
              handleChange={(selectedOption) => {
                setFilterDate(selectedOption ? selectedOption.key : "");
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Select Date Filter"
              className={"mainInput-dropdown"}
            />
          </Col>
          <Col className="col-20-percent" md={3}>
            <label className="fontSize12 mb_12 marginR10 mt_10">Date:</label>
            <DateRangePicker
              format="yyyy-MM-dd"
              placeholder="Select Date"
              value={dateRange}
              onChange={handleDateRangeChange}
              placement="auto"
              minDate={new Date(new Date().setMonth(new Date().getMonth() - 3))}
              onOpen={handleMenuState}
              onClose={handleMenuCloseState}
              showMeridian
              className="w200"
            />
          </Col>
              <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">RPU:</label>
                  <div className="col-md-8">
                    <div className="smallCheckbox">
                      <input
                        type="checkbox"
                        id="isRpu"
                        checked={isRpu}
                        style={{ marginLeft: "5px" }}
                        onChange={() => setisRpu(!isRpu)}
                      />
                      <label className="margintop7" htmlFor="isRpu"></label>
                    </div>
                  </div>
                </Col>
            </Row>
        <div className="displayFlex alignItemCenter justifyContent_end">
          <div className="displayFlex alignItemCenter justifyContent_end mb_16">
          {(roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_PICKUP-REQUEST.write") ||
              roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin")) && (
              <div
                class="jira-dropdown"
                style={{ marginLeft: "20px" }}
                ref={dropdownRef}
              >
                {(!isReactRates && !isReactCharges) && (
                  <button
                    className="jira-dropdown-button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    Bulk Actions
                  </button>
                )}
                <ul
                  className="jira-dropdown-content"
                  style={{
                    display: isDropdownOpen ? "block" : "none",
                    borderRadius: "10px",
                  }}
                >
                 {(roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_PICKUP-REQUEST.write") ||
              roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin")) && (
                    <li onClick={handleAssignCourier}>
                      <a style={{ whiteSpace: "nowrap" }}>
                        Assign to courier
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {selectPickup?.length >= 1 && (
              <button
                className="filterBtn plus_icon mleft_10"
                title="Export"
                onClick={() => exporttoExcel()}
              >
                <span>
                  <img src={downloadIcon} className="h18" />
                </span>
              </button>
            )}
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
            {(roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_PICKUP-REQUEST.write") ||
              roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin")) && (
              <button
                className="filterBtn plus_icon mleft_10"
                title="Add"
                onClick={() => assignPickup()}
                tabIndex="-1"
              >
                +
              </button>
            )}
            <div ref={ref} className="customFilter mleft_5p">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={DotboldIcon} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ul className="listColumn cursorPointer">
                      {columnPickupReq?.map((d, i) => (
                        <li key={d}>
                          <div className="smallCheckbox mb_4">
                            <input
                              id={`product${i}`}
                              type="checkbox"
                              checked={!checkedColPickupReq.includes(d)}
                            />
                            <label
                              htmlFor={`product${i}`}
                              onClick={() => handleSelectPickupReq(d)}
                            >
                              {d}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <PaginationTable
          tableData={pickupData}
          setHeader={setHeaderParams}
          tbl={"Pickup Request"}
          restrictPickupreq={checkedColPickupReq}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          showPickup={showPickup}
          isDropdownOpens={isDropdownOpen}
          setTrackId={setTrackId}
          latitude={latitude}
          longitude={longitude}
          currentTimeMillis={currentTimeMillis}
          eventTimeZone={eventTimeZone}
          setShowPickup={setShowPickup}
          pickupTitle={pickupTitle}
          setShowUpload={setShowUpload}
          setSelectReq={setSelectReq}
          setPickupTitle={setPickupTitle}
          setSelectPickup={setSelectPickup}
          setSelectOriginHub={setSelectOriginHub}
          selectPickup={selectPickup}
          sortBy={sortBy}
          setSortBy={setSortBy}
          addPickupRequest={addPickupRequest}
          setSize={setSize}
          seleczone={seleczone}
          SetshowPrCustomer={SetshowPrCustomer}
          setCancelRequestId={setCancelRequestId}
          setCancelCourierId={setCancelCourierId}
          setselectZone={setselectZone}
          setShowCancel={setShowCancel}
          setCusPage={setCusPage}
          selecwaybill={selecwaybill}
          setIsChange={setIsChange}
          isChange={isChange}
          setSelectWaybill={setSelectWaybill}
          selectPickuprequest={selectPickuprequest}
          setSelectPickuprequest={setSelectPickuprequest}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          totalCount={totalCount}
          setSortField={setSortField}
          sortField={sortField}
          statusHandler={statusHandler}
          setStatusHandler={setStatusHandler}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Assign courier
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Request Id's
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <textarea
                      ref={textareaRef}
                      className="washMe_btn"
                      style={{ paddingTop: "6px", height: "70px" }}
                      disabled
                      value={requestIdsString}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Courier
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <AsyncSearchInput
                      id="employeeCode"
                      label="employeeCode"
                      name="employeeCode"
                      value={selectedOptionCouriers?.label}
                      loadMoreOptions={loadMoreOptionCourierFilter}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onChange={(selectedOptionCouriers) => {
                        setSelectedOptionCouriers(
                          selectedOptionCouriers?.label
                        );
                        assignBulkPickups.setFieldValue(
                          "employeeCode",
                          selectedOptionCouriers
                            ? selectedOptionCouriers.value
                            : ""
                        );
                      }}
                      placeholder="Courier"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                    {assignBulkPickups.touched.employeeCode &&
                    assignBulkPickups.errors.employeeCode ? (
                      <p className="requiredText">
                        {assignBulkPickups.errors.employeeCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={assignBulkPickups.handleSubmit}
                >
                  Assign
                </button>
              </div>
              <div>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
        <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">Apply Pickup</h4>
          </Modal.Header>
          <Modal.Body>
                <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Request Id
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <textarea
                      className="washMe_btn"
                      style={{ paddingTop: "6px" }}
                      disabled
                      value={selecReq}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt_30">
  <div className="custom-file-upload">
    <input
      type="file"
      name="insuranceDocument"
      className="mainInput"
      onChange={(e) => handlePickupFileUpload(e)}
      id="insurance-upload"
    />
    <label htmlFor="insurance-upload" className="upload-labelPickup">
    <span className="mr_5">
                                <img src={importIcon} className="h18" />
                              </span>
      Upload Proof for pickup
    </label>
    {file?.name && (
      <>
        <div className="file-info">
          <p className="file-name">
            {file?.name}
          </p>
        </div>
        <div className="document-already-uploaded">
          <span className="document-status">
            Document Status: Uploaded
          </span>
        </div>
      </>
    )}
    {fileErr ? (
      <p className="requiredText">
        {fileErr}
      </p>
    ) : null}
  </div>
</div>

              </div>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={() => {
                    handleSubmitProof();
                  }}
                >
                  Confirm
                </button>
              </div>
              <div>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseUpload()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      {showPickup && (
        <Modal
          show={showPickup}
          onHide={handleClosePickup}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              {pickupTitle}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize20 menacingCloudsText fontWeight500 mb_12">
                    Customers:
                    <span className="bloodDonortext fontWeight600 fontSize16">
                      *
                    </span>
                  </label>
                  {showPrCustomer ? (
                    <AsyncSearchInput
                      key={resetKey}
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selOptionCustomer || []}
                      loadMoreOptions={loadMoreOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onChange={async (selOptionCustomer) => {
                        addPickupRequest.setFieldValue(
                          "customerAccount",
                          selOptionCustomer ? selOptionCustomer.value : ""
                        );
                        setOptionCustomer(selOptionCustomer);
                      }}
                      placeholder="Customer"
                    />
                  ) : (
                    <input
                      type="text"
                      value={editCusName}
                      name="editCusName"
                      className="mainInput"
                      onClick={handleInputCusClick}
                      disabled={
                        addPickupRequest?.values?.consignments?.length > 0
                      }
                    />
                  )}
                  {addPickupRequest.touched.customerAccount &&
                  addPickupRequest.errors.customerAccount ? (
                    <p className="requiredText">
                      {addPickupRequest.errors.customerAccount}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label className="fontSize20 menacingCloudsText fontWeight500 mb_12">
                    Courier:
                  </label>
                  <AsyncSearchInput
                    id="courier"
                    label="courier"
                    name="courier"
                    value={selectedOptionCourier}
                    loadMoreOptions={loadMoreOptionsCouriers}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(employee) => employee.label}
                    getOptionValue={(employee) => employee.value}
                    onChange={(selectedOptionCourier) => {
                      addPickupRequest.setFieldValue(
                        "employeeCode",
                        selectedOptionCourier
                          ? selectedOptionCourier?.value
                          : null
                      );
                      setSelectedOptionCourier(selectedOptionCourier);
                    }}
                    placeholder="Courier"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt_10">
                  <label className="fontSize20 menacingCloudsText fontWeight500 mb_12">
                    Consignments:
                    <span className="bloodDonortext fontWeight600 fontSize16">
                      *
                    </span>
                  </label>
                  <textarea
                    className="washMe_btn"
                    name="consignments"
                    placeholder="Upload consignments from excel by clicking below button"
                    value={addPickupRequest.values.consignments}
                    onChange={handleConsignmentsChange}
                    style={{ height: "150px", paddingTop: "6px" }}
                  />
                  {addPickupRequest.touched.consignments &&
                  addPickupRequest.errors.consignments ? (
                    <p className="requiredText">
                      {addPickupRequest.errors.consignments}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div title="Upload File" className="mleft_10">
                    <button
                      type="button"
                      className="blue_btn mt_10"
                      onClick={handleClick}
                    >
                      Upload File
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={(e) => handleFileChange(e)}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{ display: "none" }} // Hide the file input
                    />
                  </div>
                </div>
                <div className="col-md-1 mt_10">
                <button
                      style={{ marginLeft: "10px" }}
                      type="button"
                      className="filterBtn plus_icon"
                      title="Pickup request Template"
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                </button>
                    </div>
              </div>
                      <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={addPickupRequest.handleSubmit}
                >
                  {pickupTitle === "Create new pickup request"
                    ? "Create"
                    : "Update"}
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleClosePickup()}
                >
                  Cancel
                </button>
              </div>
              <div></div>
                      </div>
            </form>
          </Modal.Body>
          {/* </div> */}
        </Modal>
      )}
      {showCancel && (
        <Modal
          show={showCancel}
          onHide={handleCloseCancel}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Cancel pickup request
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize20 menacingCloudsText fontWeight500 mb_12">
                    Request ID:
                  </label>
                  <input
                    type="text"
                    className="mainInput"
                    value={cancelRequestId}
                    style={
                      cancelRequestId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="fontSize20 menacingCloudsText fontWeight500 mb_12">
                    Reason:
                    <span className="bloodDonortext fontWeight600 fontSize16">
                      *
                    </span>
                  </label>
                  <SearchInput
                    key={resetKey}
                    id="reasonCode"
                    label="reasonCode"
                    name="reasonCode"
                    options={pickupReasonsData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.message}
                    getOptionValue={(option) => option.code}
                    handleChange={(selectedOption) => {
                      cancelPickRequest.setFieldValue(
                        "reasonCode",
                        selectedOption ? selectedOption?.code : null
                      );
                    }}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select the reason for cancelling the pickup request"
                    className="mainInput"
                  />
                  {cancelPickRequest.touched.reasonCode &&
                  cancelPickRequest.errors.reasonCode ? (
                    <p className="requiredText">
                      {cancelPickRequest.errors.reasonCode}
                    </p>
                  ) : null}
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={cancelPickRequest.handleSubmit}
                >
                  Submit cancel
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseCancel()}
                >
                  Discard
                </button>
              </div>
              <div></div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
      )}
    </div>
  );
};

export default Pickups;
