import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../src/assets/img/sync.svg";
import removeIcon from "../../../src/assets/img/cancelIcon.svg";
import { useAoneStore } from "../../store/AoneStore";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import { UserContext } from "../../helper/ProtectedRoute";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
const Tiers = () => {
  const navigate = useNavigate();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code,setCode] = useState("")
  const [name,setName] = useState("")
  const [size, setSize] = useState(20);
  const [country, setCountry] = useState("");
  const [totalCount, setTotalCount] = useState(0);
const [deleteHandler, setDeleteHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [tiersData,setTiersData] = useState([])
  const [modalHandler, setModalHandler] = useState(false);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const getTiers = useAoneStore((state) => state?.getTiers);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [sortField, setSortField] = useState("createdAt");
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
      if (code) {
        params += `&code=${code}`;
      }
      if (name) {
        params += `&name=${name}`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      const data = await getTiers(params);
      setTotalCount(data.data.data.totalElements);
      setTiersData(data.data.data.content);
  }
  useEffect(() => {
    getData();
  }, [
    newPage,
    size,
    editPage,
    sortField,
    saveHandler,
    code,
    country,
    name,
    ispostAdded,
  ]);
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    filterCountries(paramslink)
  }, []);
  return (
    <div>
       <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/configs/tiers")}
              >
                Configs
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/configs/tiers")}
              >
                Tiers
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
      {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Code:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={code}
                        className={
                          code?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          code?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setCode(e.target.value)}
                      />
                        {code && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Name:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={name}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setName(e.target.value)}
                      />
                        {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Country:
                  </label>
                  <SearchInput
                    id="country"
                    label="country"
                    name="country"
                    options={countriesData?.data?.data?.content || []}
                    isClearable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.isoCode3 : "");
                    }}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    menuIsOpen={isReactselectOpen}
                    isSearchable={true}
                    placeholder="Country"
                    customStyles={{ control: { width: "160px" } }}
                    className={
                      country?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                </Row>
              </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => setAddModalHandler(!addModalHandler)}
                    tabIndex="-1"
                  >
                    +
                  </button>)}
              </div>
            </div>
            </div>
      )}
      {!editPage && (
          <PaginationTable
            tableData={tiersData}
            tbl={"Tiers"}
            title={"Edit tier"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
          {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Create new tier"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />)}
          </div>
    </div>
  )
}

export default Tiers