import React from 'react'
import MainTable from '../../components/Tables/mtable'

const ZidOrders = () => {

    const data = [
        {
            orderId: "123",
            storeId: "213456",
            waybillno: "AJ12312312",
            storeName: "Mohammed Store",
            status: "Delivered",
            createdAt: "12-01-23",
            updatedAt: "12-01-23"
        },
        {
            orderId: "124",
            storeId: "213456",
            waybillno: "AJ12312313",
            storeName: "Mohammed Store",
            status: "Ready",
            createdAt: "12-01-23",
            updatedAt: "12-01-23"
        },
    ]


  return (
    <div>
        {/* <div className="borderFrame mb_16"> */}
            {/* <div className="borderFrameTitle">Filters</div> */}
            <div className='row'>
                <div className='col-md-3'>
                    <div className="row alignItemCenter mb_12">
                        <div className='col-md-12'>
                            {/* <label className="fontSize14">Order Id: </label> */}
                            <input
                            type="text"
                            placeholder="Order ID"
                            style={{ width: "100%" }}
                            className="mainInput"/>
                        </div>    
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className="row alignItemCenter mb_12">
                        <div className='col-md-12'>
                            {/* <label className="fontSize14">Store Id: </label> */}
                            <input
                            type="text"
                            placeholder="Store Id"
                            style={{ width: "100%" }}
                            className="mainInput"/>
                        </div>    
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className="row alignItemCenter mb_12">
                        <div className='col-md-12'>
                            {/* <label className="fontSize14">Status: </label> */}
                            <input
                            type="text"
                            placeholder="Status"
                            style={{ width: "100%" }}
                            className="mainInput"/>
                        </div>    
                    </div>
                </div>
            </div>
        {/* </div>  */}

        <MainTable tableData={data} tbl="ZIO"/>       
    </div>
  )
}

export default ZidOrders