import React, { useState, useEffect, useCallback, useContext } from "react";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useAoneStore } from "../../../store/AoneStore";
import { useParams } from "react-router";
import { useDeliveryStore } from "../../../store/DeliveryMgt/DeliveryStore";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import removeIcon from "../../../assets/img/CancelDlIcon.svg";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
import syncicon from "../../../../src/assets/img/sync.svg";
const Debriefing = () => {
  const params = useParams();
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [trackId, setTrackId] = useState("");
  const [validationId, setValidationId] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [courierPage, setCourierPage] = useState(-1);
  const [courierdlPage, setCourierDlPage] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const [courierId, setCourierId] = useState(
    sessionStorage.getItem("SelectCourier")
  );
  const [assigned, setAssigned] = useState("");
  const [delivered, setDelivered] = useState("");
  const [remained, setRemained] = useState("");
  const [assignedPackage, setAssignedPackage] = useState("");
  const [scannedPackage, setscannedPackage] = useState("");
  const [remainedPackage, setRemainedPackage] = useState("");
  const [isMultiple, setIsMultiple] = useState(false);
  const [isMultipleFilter, setIsMultipleFilter] = useState(false);
  const [selectedOptionCourier, setSelectedOptionCourier] = useState(null);
  const [selectedOptionCourierDl, setSelectedOptionCourierDl] = useState(null);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const startCourierDebreif = useDeliveryStore(
    (state) => state?.startCourierDebreif
  );
  const countCourierDebreif = useDeliveryStore(
    (state) => state?.countCourierDebreif
  );
  const addDebreifing = useDeliveryStore((state) => state?.addDebreifing);
  const submitDebreifing = useDeliveryStore((state) => state?.submitDebreifing);
  const getdeliveryValidation = useDeliveryStore(
    (state) => state?.getdeliveryValidation
  );
  const getDebreif = useDeliveryStore(
    (state) => state?.getDebreif
  );
  const deleteDebreifing = useDeliveryStore(
    (state) => state?.deleteDebreifing
  );
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setCourierDlPage(-1);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const handleShow = () => setShow(true);
  const [showTransfer, setShowTransfer] = useState(false);
  const handleTransferClose = () => {
    setShowTransfer(false);
  };
  const handleShowTransfer = () => {
    setShowTransfer(true);
  };
  const [showSubmit, setShowSubmit] = useState(false);
  const handleSubmitClose = () => {
    setShowSubmit(false);
  };
  const handleShowSubmit = () => {
    setShowSubmit(true);
  };
  const [showAssign, setShowAssign] = useState(false);
  const handleassignShow = () => setShowAssign(true);
  const handleassignClose = () => {
    setShowAssign(false);
  };
  const handleassignCancel = () => {
    setShowAssign(false);
  };
  const [errorMsg, setErrMsg] = useState("");
  const [showOfd, setShowOfd] = useState(false);
  const handleOfdClose = () => {
    setShowOfd(false);
  };
  const handleShowOfd = () => {
    setShowOfd(true);
  };
  useEffect(() => {
    setValidationId(sessionStorage.getItem("DebriefingCode"));
    setAssigned(sessionStorage.getItem("assigned"));
    setDelivered(sessionStorage.getItem("delivered"));
    setRemained(sessionStorage.getItem("remaining"));
    setAssignedPackage(sessionStorage.getItem("assignedPacakges"));
    setRemainedPackage(sessionStorage.getItem("remainingPacakges"));
    setDelivered(sessionStorage.getItem("deliveredPacakges"));
    setIsValidated(sessionStorage.getItem("isValidated"));
    setCourierId(sessionStorage.getItem("SelectCourier"));
  }, []);
  const [isValidated, setIsValidated] = useState(false);
  useEffect(() => {
    if (
      validationId !== null &&
      sessionStorage.getItem("DebriefingCode") !== null
    ) {
      setIsValidated(true);
    }
  }, [validationId]);
  // Create an audio element
  const playErrorSound = () => {
    const audio = new Audio("/error-sound.mp3");
    audio.play().catch((error) => console.error("Error playing audio:", error));
  };
  const playSuccessSound = () => {
    const audio = new Audio("/success-sound.mp3");
    audio
      .play()
      .catch((error) => console.error("Success playing audio:", error));
  };
  const validateDl = useFormik({
    initialValues: {
      packageTrackingId: "",
      debriefingCode: validationId,
    },
    validationSchema: Yup.object({
      packageTrackingId: Yup.string().required(
        "Package tracking Id is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.validationCode = sessionStorage.getItem("ValidationId");
      await addDebreifing(values?.packageTrackingId, validationId).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          setIsValidated(true);
          playSuccessSound();
          sessionStorage.setItem("isValidated", true);
          toast.success(`Packagetracking Id scanned`);
          countCourierTasks(sessionStorage.getItem("SelectCourier"));
          getData(validationId);
          setShowTransfer(false);
          resetForm();
          setTrackId("");
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShowTransfer(false);
          setTrackId("");
          resetForm();
          toast.error(errorMessages);
          playErrorSound();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg?.split("!")[0];
          toast.error(errorMessage);
          setShowTransfer(false);
          setTrackId("");
          resetForm();
          playErrorSound();
        } else {
          const errorMessage = response?.data?.msg?.split(",")[0];
          toast.error(errorMessage);
          setShowTransfer(false);
          setTrackId("");
          resetForm();
          playErrorSound();
        }
      });
    },
  });
  const submitDl = useFormik({
    initialValues: {
      debriefingCode: validationId,
    },
    onSubmit: async (values, { resetForm }) => {
      values.debriefingCode = sessionStorage.getItem("ValidationId");
      await submitDebreifing(validationId).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Debriefing Submitted`);
          getData(validationId);
          setErrMsg(response?.data?.msg);
          clearCourier("");
          setShowSubmit(false);
          resetForm();
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setErrMsg(response?.data?.msg);
          setShowSubmit(false);
          resetForm();
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setErrMsg(
            response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ")
          );
          toast.error(errorMessages);
          setShowSubmit(false);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          setErrMsg(response?.response?.data?.msg);
          setShowSubmit(false);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg;
          setErrMsg(response?.data?.msg);
          setShowSubmit(false);
          if (
            response?.data?.msg ==
            "Scanned number of shipments is not equal to the assigned number"
          ) {
            setShowAssign(true);
          }
          resetForm();
        }
      });
    },
  });
  const startDebreifing = useFormik({
    initialValues: {
      courierId: trackId,
    },
    validationSchema: Yup.object({
      courierId: Yup.string().required("Courier is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await startCourierDebreif(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          const newValidationId = response?.data?.data;
          setValidationId(newValidationId);
          sessionStorage.setItem("DebriefingCode", newValidationId);
          sessionStorage.setItem("SelectCourier", values?.courierId);
          setShow(false);
          resetForm();
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
        } else if (response?.data?.errors?.length > 0 && response?.data?.status === "ERROR") {
          const errorMessages = response?.data?.errors.map((item) => `${item.key}: ${item.msg}`).join(", ");
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
          toast.error(errorMessages);
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
        }
      });
    },
  });
  const countCourierTasks = async (courierId) => {
    const res = await countCourierDebreif(courierId);
    setAssigned(res?.data?.data?.assigned);
    sessionStorage.setItem("assigned", res?.data?.data?.assigned);
    setDelivered(res?.data?.data?.delivered);
    sessionStorage.setItem("delivered", res?.data?.data?.delivered);
    setRemained(res?.data?.data?.remaining);
    sessionStorage.setItem("remaining", res?.data?.data?.remaining);
    setAssignedPackage(res?.data?.data?.scanned);
    sessionStorage.setItem("assignedPacakges", res?.data?.data?.assignedPackage);
    setscannedPackage(res?.data?.data?.deliveredPackage);
    sessionStorage.setItem("deliveredPacakges", res?.data?.data?.deliveredPackage);
    setRemainedPackage(res?.data?.data?.remainingPackage);
    sessionStorage.setItem("remainingPacakges", res?.data?.data?.remainingPackage);
  };
  const [dlData, setDlData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [isDelete, setIsDelete] = useState("");
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData(validationId);
  };
  useEffect(() => {
    if (validationId) {
      getData(validationId);
    }
  }, [validationId]);
  
  const getData = async (validationId) => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&debriefingCode=${validationId}`;
    const data = await getDebreif(params);
    setTotalCount(data?.data?.data?.totalElements);
    setDlData(data?.data?.data?.content);
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [userHub, setUserHub] = useState("");
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  const loadMoreOptionsCourierVal = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierdlPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierDlPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierdlPage, prevSearch, setPrevSearch]
  );
  const clearCourier = () => {
    deleteDebreifing(validationId);
    setDlData([]);
    sessionStorage.removeItem("SelectCourier");
    sessionStorage.removeItem("DebriefingCode");
    sessionStorage.removeItem("assigned");
    sessionStorage.removeItem("delivered");
    sessionStorage.removeItem("remaining");
    sessionStorage.removeItem("assignedPacakges");
    sessionStorage.removeItem("deliveredPacakges");
    sessionStorage.removeItem("remainingPacakges");
    sessionStorage.removeItem("isValidated");
    setSelectedOptionCourier(null);
    setCourierId("");
    setValidationId(false);
    setValidationId(null);
  };
  const deleteDebrief = async() => {
   let res = await deleteDebreifing(validationId);
   setDlData([]);
   sessionStorage.removeItem("SelectCourier");
   sessionStorage.removeItem("DebriefingCode");
   sessionStorage.removeItem("assigned");
   sessionStorage.removeItem("delivered");
   sessionStorage.removeItem("remaining");
   sessionStorage.removeItem("assignedPacakges");
   sessionStorage.removeItem("deliveredPacakges");
   sessionStorage.removeItem("remainingPacakges");
   sessionStorage.removeItem("isValidated");
   setSelectedOptionCourier(null);
   setCourierId("");
   setValidationId(false);
   setValidationId(null);
  }
  return (
    <div>
      <div>
        <div>
        {validationId !== null &&
              sessionStorage.getItem("DebriefingCode") !== null && (
                <>
                  <input
                    type="text"
                    value={trackId}
                    className={"mainInput mt_10 mr_8"}
                    style={
                      trackId?.length > 0
                        ? { border: "2px solid #ec171c", width: "auto" }
                        : { width: "auto" }
                    }
                    placeholder="Package Tracking Id"
                    onChange={(e) => {
                      setTrackId(e.target.value);
                      validateDl.setFieldValue(
                        "packageTrackingId",
                        e.target.value
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        validateDl.handleSubmit();
                      }
                    }}
                  />
                  <button
                    className="activeFilterBtn ml_0 mt_15 mr_15"
                    onClick={validateDl.handleSubmit}
                  >
                    Scan
                  </button>
                </>
              )}
            {validationId !== null && (
                            <button
                            className="activeFilterBtn mt_15 mr_15"
                            onClick={() => setShowSubmit(true)}
                          >
                              Submit
                            </button>
            )}
        </div>
        <div className="displayFlex alignItemCenter justifyContent_end">
          <div className="totals mr_auto mbtm_10 ml_inherit">
            {validationId !== null &&
              sessionStorage.getItem("DebriefingCode") !== null && (
                <>
                  <span className="total-assign mt_15">
                    Assigned : {sessionStorage?.getItem("assigned")}
                  </span>
                  <span className="total-assign mt_15">
                    Assigned Packages : {sessionStorage?.getItem("assignedPacakges")}
                  </span>
                  <span className="total-success mt_15">
                    Delivered : {sessionStorage?.getItem("delivered")}
                  </span>
                  <span className="total-success mt_15">
                    Delivered Packages : {sessionStorage?.getItem("deliveredPacakges")}
                  </span>
                  <span className="total-remain mt_15">
                    Remaining : {sessionStorage?.getItem("remaining")}
                  </span>
                  <span className="total-remain mt_15">
                    Remaining Packages : {sessionStorage?.getItem("remainingPacakges")}
                  </span>
                </>
              )}
            {(roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
              roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING.write")) && (
              <>
                {validationId === null &&
                sessionStorage.getItem("DebriefingCode") == null ? (
                  <button
                    className="activeFilterBtn mt_6pix"
                    onClick={() => setShow(true)}
                  >
                    Start Debrief
                  </button>
                ) : (
                  <div className="totalCourier mr_auto ml_0 mt_5">
                    <span className="total-courier mt_6pix mt_5">
                      Courier: {sessionStorage.getItem("SelectCourier")}
                      {sessionStorage.getItem("SelectCourier") && (
                        <button
                          className="tableBtn"
                          title="Remove Courier"
                          onClick={clearCourier}
                        >
                          <span>
                            <img src={removeIcon} className="h18" />
                          </span>
                        </button>
                      )}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="displayFlex alignItemCenter justifyContent_end mb_16 mt_10">
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
          </div>
        </div>
        <PaginationTable
          tableData={dlData}
          tbl={"Debrief"}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setSize={setSize}
          totalCount={totalCount}
          setSortField={setSortField}
          sortField={sortField}
          statusHandler={statusHandler}
          setStatusHandler={setStatusHandler}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Start Debriefing
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Courier
                  </label>
                  <AsyncSearchInput
                    id="courierId"
                    label="courierId"
                    name="courierId"
                    value={selectedOptionCourierDl}
                    loadMoreOptions={loadMoreOptionsCourierVal}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(courier) => courier.label}
                    getOptionValue={(courier) => courier.value}
                    onChange={async (selectedOptionCourierDl) => {
                      setSelectedOptionCourierDl(selectedOptionCourierDl);
                      startDebreifing?.setFieldValue(
                        "courierId",
                        selectedOptionCourierDl
                          ? selectedOptionCourierDl.value
                          : ""
                      );
                      setCourierId(
                        selectedOptionCourierDl
                          ? selectedOptionCourierDl.value
                          : ""
                      );
                      countCourierTasks(selectedOptionCourierDl.value);
                    }}
                    placeholder="Courier"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                  {startDebreifing.touched.courierId &&
                  startDebreifing.errors.courierId ? (
                    <p className="requiredText">
                      {startDebreifing.errors.courierId}
                    </p>
                  ) : null}
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_20">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={startDebreifing.handleSubmit}
                >
                  Start
                </button>
              </div>
              <div>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
        {showSubmit && (
          <Modal
            className="scanConfirmpopup"
            show={showSubmit}
            onHide={handleSubmitClose}
            centered
          >
            <Modal.Body>
              <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
                Do you want to submit?
              </h2>
            </Modal.Body>
            <Modal.Footer style={{ flexWrap: "unset" }}>
              <div>
                <button
                  className="deletebtn cancelPrimary"
                  onClick={() => handleSubmitClose()}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="deletebtn confirmPrimary"
                  onClick={submitDl.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Debriefing;
