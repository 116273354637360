import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useParams } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import { useCollectionStore } from "../../../store/CollectionsMgt/CollectionStore";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import PaginationTable from "../../../components/Tables/paginationTable";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import Dropdown from "react-bootstrap/Dropdown";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { UserContext } from "../../../helper/ProtectedRoute";
const Collections = ({ setTab }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const prevent = useRef(false);
  const paramHeader = useParams();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [trackingId, setTrackingId] = useState("");
  const [collectionSum, setCollectionSum] = useState("");
  const [cashList, setCashList] = useState("");
  const [settlementStatus, setSettlementStatus] = useState("");
  const [size, setSize] = useState(20);
  const [dateRange, setDateRange] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [ledgerData, setLedgerData] = useState("");
  const [selectedOptionEmployee, setSelectedOptionEmployee] = useState(null);
  const [modalHandler, setModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const [courierPage, setCourierPage] = useState(-1);
  const getCollections = useCollectionStore((state) => state?.getCollections);
  const sumCollections = useCollectionStore((state) => state?.sumCollections);
  const exportCollections = useCollectionStore(
    (state) => state?.exportCollections
  );
  const getCollectionMode = useCollectionStore(
    (state) => state?.getCollectionMode
  );
  const getSettlementStatus = useCollectionStore(
    (state) => state?.getSettlementStatus
  );
  const collectionModeList = useCollectionStore(
    (state) => state?.collectionModeList
  );
  const settlementStatusList = useCollectionStore(
    (state) => state?.settlementStatusList
  );
  const getCouriers = usecourierStore((state) => state?.getCouriers);
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const gridId = "finance.collections";
  const [column, SetCoulmn] = useState([
    "Tracking Id",
    "COD Amount",
    "DDU Amount",
    "Collected Amount",
    "Currency",
    "Mode",
    "Location",
    "Status",
    "Settled by",
    "Settlement Date",
    "Created at",
    "Created by",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "Created at",
    "Created by",
  ]);
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [state] = useContext(UserContext);
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const [dateRangefil, setDateRangefil] = useState({});
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    if (collectionModeList?.data?.data === undefined) {
      getCollectionMode();
    }
    if (settlementStatusList?.data?.data === undefined) {
      getSettlementStatus();
    }
  }, []);
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}`;
    if (cashList) {
      params += `&mode=${cashList}`;
    }
    if (settlementStatus) {
      params += `&status=${settlementStatus}`;
    }
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (
      dateRangefil.start !== null &&
      dateRangefil.end !== null &&
      !isNaN(dateRangefil.start) &&
      !isNaN(dateRangefil.end) &&
      dateRangefil.start !== dateRangefil.end
    ) {
      params += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
    }
    try {
      const res = await exportCollections(params);
      if (res.status == 200) {
        fileDownload(res.data, `Collections.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}`;
    if (cashList) {
      params += `&mode=${cashList}`;
    }
    if (settlementStatus) {
      params += `&status=${settlementStatus}`;
    }
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (
      dateRangefil.start !== null &&
      dateRangefil.end !== null &&
      !isNaN(dateRangefil.start) &&
      !isNaN(dateRangefil.end) &&
      dateRangefil.start !== dateRangefil.end
    ) {
      params += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
    }
    const data = await getCollections(params);
    setTotalCount(data?.data?.data?.totalElements);
    setLedgerData(data?.data?.data?.content);
  };
  const sumCollecnData = async () => {
    let paramSum = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}`;
    if (cashList) {
      paramSum += `&mode=${cashList}`;
    }
    if (settlementStatus) {
      paramSum += `&status=${settlementStatus}`;
    }
    if (trackingId) {
      paramSum += `&trackingId=${trackingId}`;
    }
    if (
      dateRangefil.start !== null &&
      dateRangefil.end !== null &&
      !isNaN(dateRangefil.start) &&
      !isNaN(dateRangefil.end) &&
      dateRangefil.start !== dateRangefil.end
    ) {
      paramSum += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
    }
    const data = await sumCollections(paramSum);
    setCollectionSum(data?.data?.data);
  };
  useEffect(() => {
    getData();
    sumCollecnData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    trackingId,
    cashList,
    settlementStatus,
    dateRangefil.start,
    dateRangefil.end,
    ispostAdded,
  ]);
  return (
    <div>
      <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          <div className="displayFlex justifyContent_end mleft_5">
            <Row>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Tracking ID:
                </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    value={trackingId}
                    className={
                      trackingId?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      trackingId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    placeholder="Tracking Id"
                    onChange={(e) => setTrackingId(e.target.value)}
                  />
                </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Collection Type:
                </label>
                <SearchInput
                  id="cashList"
                  label="cashList"
                  name="cashList"
                  options={collectionModeList?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  handleChange={(selectedOption) => {
                    setCashList(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Collection Type"
                  className={
                    cashList?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Settlement Status:
                </label>
                <SearchInput
                  id="settlementStatus"
                  label="settlementStatus"
                  name="settlementStatus"
                  options={settlementStatusList?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  handleChange={(selectedOption) => {
                    setSettlementStatus(
                      selectedOption ? selectedOption.key : ""
                    );
                  }}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  menuIsOpen={isReactRates}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Settlement Status"
                  className={
                    settlementStatus?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Collection Date:
                </label>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  placeholder="Collection Date"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  placement="auto"
                  minDate={
                    new Date(new Date().setMonth(new Date().getMonth() - 3))
                  }
                  onOpen={handleMenuOpen}
                  onClose={handleMenuClose}
                  showMeridian
                  className="w200"
                />
              </Col>
            </Row>
          </div>
          <div className="margin_top40">
            <div className="displayFlex gap_12">
            <div className="totals totalsTransaction">
                <span className="total-success">
                  Total Amount: {collectionSum}
                </span>
              </div>
              <button
                className="filterBtn"
                title={"Export Collections"}
                onClick={() => exportToExcel()}
              >
                <span>
                  <img src={downloadicon} className="h18" />
                </span>
              </button>
              <button
                className="filterBtn plus_icon"
                title="Sync"
                onClick={() => syncronizeRoles()}
              >
                <span>
                  <img src={syncicon} className="h18" />
                </span>
              </button>
              <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>
  
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column?.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
            </div>
          </div>
        </div>
        <PaginationTable
          tableData={ledgerData}
          tbl={"Collections"}
          modalHandler={modalHandler}
          restrict={checkedCol}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          isDropdownselectOpen={isDropdownselectOpen}
          newPage={newPage}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          setNewPage={setNewPage}
          size={size}
          setCollectionsTab={setTab}
          collectionTrackingId={trackingId}
          setSize={setSize}
          totalCount={totalCount}
          sortField={sortField}
          setSortField={setSortField}
        />
      </div>
    </div>
  );
};

export default Collections;
