import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../../store/AoneStore";
import { useroutesStore } from "../../../store/RoutesMgt/RoutesStore";
import fileDownload from "js-file-download";
import * as XLSX from "xlsx";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import { roleHandler } from "../../../helper/roleHandler";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import PaginationTable from "../../../components/Tables/paginationTable";
import CusModal from "../../../components/modal/CusModal";
import { useweightStore } from "../../../store/WeighingMgt/WeighingStore";
import DeletingModal from "../../../components/modal/deletingModal";
import { DateRangePicker } from "rsuite";
import { UserContext } from "../../../helper/ProtectedRoute";
import WeighingModal from "./WeighingModal";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Weight = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [trackingId, setTrackingId] = useState("");
  const [packagetrackingId, setPackageTrackingId] = useState("");
  const [customer, setCustomer] = useState("");
  const [tool, setTool] = useState("");
  const [resetKey, setResetKey] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [filterDate, setFilterDate] = useState("");
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [weighingData, setWeighingData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const getWeights = useweightStore((state) => state?.getWeights);
  const weightTemplate = useweightStore((state) => state?.weightTemplate);
  const importWeight = useweightStore((state) => state?.importWeight);
  const getWeightTool = useweightStore((state) => state?.getWeightTool);
  const getWeightUnit = useweightStore((state) => state?.getWeightUnit);
  const getDimensionUnit = useweightStore((state) => state?.getDimensionUnit);
  const weightUnitList = useweightStore((state) => state?.weightUnitList);
  const weightToolList = useweightStore((state) => state?.weightToolList);
  const dimensionUnitList = useweightStore((state) => state?.dimensionUnitList);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const [dateRangefil, setDateRangefil] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cusPage, setCusPage] = useState(-1);
  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
  const handleMenuCurrency = () => {
    setReactCurrency(true);
  };
  const setReactCurrency = useAoneStore((state) => state?.setReactCurrency);
  const handleMenuCloseCurrency = () => {
    setReactCurrency(false);
  };
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const setisReactRatestate = useAoneStore(
    (state) => state?.setisReactRatestate
  );
  const handleMenuState = () => {
    setisReactRatestate(true);
  };
  const handleMenuCloseState = () => {
    setisReactRatestate(false);
  };
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };
  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const gridId = "ops.weighing";
  const [column, SetCoulmn] = useState([
    "Tracking Id",
    "Package Tracking Id",
    "Product",
    "Customer",
    "Weight Unit",
    "Original Weight",
    "New Weight",
    "Dimension Unit",
    "Original Dimensions",
    "New Dimensions",
    "Original volumetric weight",
    "New volumetric weight",
    "Tool",
    "Created At",
    "Created By",
    "Updated At",
    "Updated By",
  ]);
  const [checkedCol, setCheckedCol] = useState([]);
  useEffect(() => {
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const dateFilterOptions = [
    { label: "Created Date", key: "createdAt" },
    { label: "Last Updated Date", key: "updatedAt" },
  ];
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      setFilterDate("");
      setResetKey((prevKey) => prevKey + 1);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const downloadTemplate = async () => {
    try {
      const res = await weightTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Weighing Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (packagetrackingId) {
      params += `&packageTrackingId=${packagetrackingId}`;
    }
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (tool) {
      params += `&tool=${tool}`;
    }
    if (filterDate) {
      params += `&filterDate=${filterDate}`;
      if (dateRangefil?.start) {
        params += `&startTime=${dateRangefil?.start}`;
      }
      if (dateRangefil?.end) {
        params += `&endTime=${dateRangefil?.end}`;
      }
    }
    const data = await getWeights(params);
    setTotalCount(data?.data?.data?.totalElements);
    setWeighingData(data?.data?.data?.content);
  };
  useEffect(() => {
    if (weightToolList?.data?.data === undefined) {
      getWeightTool();
    }
    if (weightUnitList?.data?.data === undefined) {
      getWeightUnit();
    }
    if (dimensionUnitList?.data?.data === undefined) {
      getDimensionUnit();
    }
  }, []);
  //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            importWeight(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  setispostAdded(!ispostAdded);
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (
                  response?.data?.errorCode !== "" &&
                  response?.data?.errorCode !== undefined
                ) {
                  const errorMessage = response?.data?.msg;
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  fileInput.value = "";
                } else if (response?.data?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessage = response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (
                  response?.data?.data?.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    location,
    dateRangefil,
    saveHandler,
    trackingId,
    packagetrackingId,
    customer,
    tool,
    ispostAdded,
  ]);
  const [title, setTitle] = useState("Add Weight");
  const [weightbyCode, setWeightbyCode] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/weighing")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/ops/weighing");
                }}
              >
                Weighing
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Tracking ID:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={trackingId}
                      className={
                        trackingId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        trackingId?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setTrackingId(e.target.value)}
                    />
                    {trackingId && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setTrackingId("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Package Tracking ID:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={packagetrackingId}
                      className={
                        packagetrackingId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        packagetrackingId?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setPackageTrackingId(e.target.value)}
                    />
                    {packagetrackingId && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setPackageTrackingId("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Customer:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <AsyncSearchInput
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOptionCustomer}
                      loadMoreOptions={loadMoreOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      customStyles={{ control: { width: "180px" } }}
                      onChange={(selectedOptionCustomer) => {
                        setSelectedOptionCustomer(selectedOptionCustomer);
                        if (selectedOptionCustomer) {
                          setCustomer(selectedOptionCustomer.value);
                        } else {
                          setCustomer(null);
                        }
                      }}
                      placeholder="Customer"
                    />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Tool:
                  </label>
                  <SearchInput
                    id="tool"
                    label="tool"
                    name="tool"
                    options={weightToolList?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    handleChange={(selectedOption) => {
                      setTool(selectedOption ? selectedOption.key : "");
                    }}
                    onMenuOpen={handleMenudescountry}
                    onMenuClose={handleMenuClosedescountry}
                    menuIsOpen={isReactdesCountry}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Tool"
                    customStyles={{ control: { width: "180px" } }}
                    className="mainInput"
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                  Date Filter:
                  </label>
                  <SearchInput
                key={`dateFilter-${resetKey}`}
                id="dateFilter"
                label="dateFilter"
                name="dateFilter"
                options={dateFilterOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.key}
                onMenuOpen={handleMenuCurrency}
                onMenuClose={handleMenuCloseCurrency}
                menuIsOpen={isReactCurrency}
                handleChange={(selectedOption) => {
                  setFilterDate(selectedOption ? selectedOption.key : "");
                }}
                isClearable={true}
                isSearchable={true}
                placeholder="Select Date Filter"
                className={"mainInput-dropdown"}
              />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                  Date:
                  </label>
                  <DateRangePicker
                format="yyyy-MM-dd"
                placeholder="Select Date"
                value={dateRange}
                onChange={handleDateRangeChange}
                placement="auto"
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 3))
                }
                onOpen={handleMenuState}
                onClose={handleMenuCloseState}
                showMeridian
                className="w200"
              />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                  <>
                    {(roleHandler(role, "AONE_OPS.write") ||
                      roleHandler(role, "AONE_OPS.admin") ||
                      roleHandler(role, "AONE_OPS_WEIGHTING.write") ||
                      roleHandler(role, "AONE_OPS_WEIGHTING.admin")) && (
                      <>
                        <button
                          style={{ marginLeft: "10px" }}
                          className="filterBtn plus_icon"
                          title="Weighing Template"
                          onClick={downloadTemplate}
                        >
                          <span>
                            <img src={templateIcon} className="h18" />
                          </span>
                        </button>
                        <div title="Import Weight">
                          <label
                            className="filterBtn plus_icon"
                            htmlFor="uploadFile"
                          >
                            <span>
                              <img src={importIcon} className="h18" />
                            </span>
                            <input
                              type="file"
                              id="uploadFile"
                              onInput={(e) => handleFileChange(e)}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              hidden
                            />
                          </label>
                        </div>
                      </>
                    )}
                  </>
                </>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_WEIGHTING.write") ||
                  roleHandler(role, "AONE_OPS_WEIGHTING.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    tabIndex="-1"
                    onClick={() => {
                      setShowModal(true);
                      setTitle("Add Weight");
                    }}
                  >
                    +
                  </button>
                )}
                  <div ref={ref} className="customFilter">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={DotboldIcon} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ul className="listColumn cursorPointer">
                      {column.map((d, i) => (
                        <li key={d}>
                          <div className="smallCheckbox mb_4">
                            <input
                              id={`product${i}`}
                              type="checkbox"
                              checked={!checkedCol.includes(d)}
                            />
                            <label
                              htmlFor={`product${i}`}
                              onClick={() => handleSelect(d)}
                            >
                              {d}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
      <PaginationTable
        tbl="Weight"
        tableData={weighingData}
        restrict={checkedCol}
        size={size}
        setSize={setSize}
        totalCount={totalCount}
        setSortField={setSortField}
        sortField={sortField}
        setNewPage={setNewPage}
        newPage={newPage}
      />
      <CusModal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="addnnewWeighing"
      >
        <WeighingModal
          onSuccess={handleSuccess}
          onError={handleError}
          setShowModal={setShowModal}
          title={title}
          weightbyCode={weightbyCode}
        />
      </CusModal>
    </div>
  );
};

export default Weight;
