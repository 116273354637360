import React, { useState, useContext, useEffect } from "react";
import Mainlogo from "../../assets/img/logo.svg";
import DashboardIcon from "../../assets/img/dashboard_icon.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { usepickupStore } from "../../store/PickupRequest/pickuprequestStore";
import Arrowleft from "../../assets/img/arrowleft.svg";
import ArrowRight from "../../assets/img/arrowright.svg";
import ReportIcon from "../../assets/img/report_icon.svg";
import Arrowdown from "../../assets/img/arrowdown.svg";
import Arrowup from "../../assets/img/arrowup.svg";
import DemandIcon from "../../assets/img/demand_icon.svg";
import iam_icon from "../../assets/img/icons/iam_icon.svg";
import role_icon from "../../assets/img/icons/userRole_icon.svg";
import user_icon from "../../assets/img/icons/users_icon.svg";
import group_icon from "../../assets/img/icons/groups_icon.svg";
import milestone_icon from "../../assets/img/icons/milestone_icon.svg";
import product_icon from "../../assets/img/icons/product_icon.svg";
import PrinterIcon from "../../assets/img/PrinterIcon.svg";
import AdditionalInfo from "../../assets/img/InfoIcon.svg";
import HajIcon from "../../assets/img/HajIcon.svg";
import report_icon from "../../assets/img/icons/report_icon.svg";
import integration_icon from "../../assets/img/icons/intergration_icon.svg";
import merchant_icon from "../../assets/img/icons/merchant_icon.svg";
import config_icon from "../../assets/img/icons/config_icon.svg";
import address_icon from "../../assets/img/icons/address_icon.svg";
import businessline_icon from "../../assets/img/icons/businessLine_icon.svg";
import express_icon from "../../assets/img/icons/express_invoice.svg";
import landfreight_icon from "../../assets/img/icons/landfreight_icon.svg";
import warehouse_icon from "../../assets/img/icons/warehouse_invoice.svg";
import management_icon from "../../assets/img/icons/customerManagement_icon.svg";
import admin_icon from "../../assets/img/icons/admin_icon.svg";
import SortIcon from "../../assets/img/SortIcon.svg";
import bag_icon from "../../assets/img/bags.svg";
import freight_icon from "../../assets/img/icons/invoice_freightForwarding.svg";
import sla_icon from "../../assets/img/icons/sla_services.svg";
import addressMapIcon from "../../assets/img/addressMapIcon.svg";
import ShortAddressIcon from "../../assets/img/ShortAddressIcon.svg";
import zoneIcon from "../../assets/img/zoneIcon.svg";
import thirdparty_icon from "../../assets/img/thirdparty.svg";
import conf_icon from "../../assets/img/config.svg";
import dollar_icon from "../../assets/img/dollar_icon.svg";
import financeIcon from "../../assets/img/financeIcon.svg";
import invoiceIcon from "../../assets/img/invoiceIcon.svg";
import { UseAuthRoute, UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import OrderIcon from "../../assets/img/order_list.svg";
import crmIcon from "../../assets/img/crm_icon.svg";
import customerIcon from "../../assets/img/customer_icon.svg";
import employeeIcon from "../../assets/img/employee_icon.svg";
import currencyIcon from "../../assets/img/currencyIcon.svg";
import hubIcon from "../../assets/img/hubIcon.svg";
import assetsIcon from "../../assets/img/assetIcon.svg";
import productsIcon from "../../assets/img/productsIcon.svg";
import OpsIcon from "../../assets/img/OpsIcon.svg";
import PickupIcon from "../../assets/img/PickupIcon.svg";
import PickupReasonIcon from "../../assets/img/PickupReasonIcon.svg";
import mastersIcon from "../../assets/img/mastersIcon.svg";
import ConsignmentIcon from "../../assets/img/ShipIcon.svg";
import OrdersIcon from "../../assets/img/OrderIcon.svg";
import TiersIcon from "../../assets/img/TiersIcon.svg";
import VehicleIcon from "../../assets/img/VehicleIcon.svg";
import RoutesIcon from "../../assets/img/RoutesIcon.svg";
import RatesIcon from "../../assets/img/Sample Rate.svg";
import CouriersIcon from "../../assets/img/CourierIcon.svg";
import WeightIcon from "../../assets/img/WeighingIcon.svg";
import OrderConsIcon from "../../assets/img/ConsignmentOrders.svg";
import LinehaulIcon from "../../assets/img/LinehaulIcon.svg";
import AclIcon from "../../assets/img/AclIcon.svg";
import LastMileIcon from "../../assets/img/LastMileIcon.svg";
import SequenceIcon from "../../assets/img/SequenceIcon.svg";
import NotificationsIcon from "../../assets/img/NotificationIcon.svg";
import RtoIcon from "../../assets/img/RtoIcon.svg";
import SmsTemplateIcon from "../../assets/img/SmsIcon.svg";
import CashIcon from "../../assets/img/CashDebriefIcon.svg";
import DeliveryflowIcon from "../../assets/img/DeliveryFlowIcon.svg";
import { useDriverStore } from "../../store/DriverMgt/driverStore";
const Adminsidebar = ({ setWrapper }) => {
  const [state] = useContext(UserContext);
  const [toggleIcon, setToggleIcon] = useState(false);
  const navigate = useNavigate();
  const queryParams = useLocation();
  const pathname = queryParams.pathname;
  const [log, setLog] = useState(false);
  const [product, setProduct] = useState(false);
  const [print, setPrint] = useState(false);
  const [sendsms, setSendsms] = useState(false);
  const [integrations, setIntergrations] = useState(false);
  const [zid, setZid] = useState(false);
  const [projects, setProjects] = useState(false);
  const [addons, setAddons] = useState(false);
  const [business, setBusiness] = useState(false);
  const [consignments, setConsignments] = useState(false);
  const [crm, setCrm] = useState(false);
  const [masters, setMasters] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [settings, setSettings] = useState(false);
  const [ops, setOps] = useState(false);
  const [assets, setAsset] = useState(false);
  const [notification, setNotifications] = useState(false);
  const [IAM, setIAM] = useState(false);
  const [configs, setConfigs] = useState(false);
  const [thirdparty, setThirdparty] = useState(false);
  const [middleMile, setMiddleMile] = useState(false);
  const [management, setManagement] = useState(false);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  const setLineHaulTab = useDriverStore((state) => state?.setLineHaulTab);
  const setisPickupRequest = usepickupStore(
    (state) => state?.setisPickupRequest
  );
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  // useEffect(() => {
  //   handleContextMenu()
  //   // Attach the context menu event listener when the component mounts
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);
  // const handleContextMenu = (e) => {
  //   // Prevent the default context menu from appearing
  //   e.preventDefault();
  //   // Check if the right mouse button was clicked
  //   if (e.button === 2) { // Right mouse button is button 2
  //     // Open the page in a new tab
  //     window.open("iam/roles", "_blank"); // Open the URL dynamically
  //   }
  // };
  const token =
    localStorage.getItem("user_access_token") ||
    sessionStorage.getItem("user_access_token");
  const urlWithToken = `/ops/consignments?token=${token}`;
  const getLinkWithToken = (path) => `${path}?token=${token}`;
  const getLinkWithoutToken = (path) => path;
  const handleLinkClick = (e, path) => {
    const isNewTab = e.ctrlKey || e.metaKey || e.button === 1;
    if (isNewTab) {
      e.preventDefault();
      window.open(getLinkWithToken(path), "_blank");
    } else {
      // Handle in-app navigation with token
      e.preventDefault();
      navigate(getLinkWithoutToken(path));
    }
  };
  const handleLinklastClick = (e, path) => {
    // Check if the link is to be opened in a new tab
    const isNewTab = e.ctrlKey || e.metaKey || e.button === 1;
    if (isNewTab) {
      e.preventDefault();
      // Open in a new tab with token
      window.open(getLinkWithToken(path), "_blank");
    } else {
      // Prevent default behavior and clear session storage
      e.preventDefault();
      sessionStorage.removeItem("SelectedCourier");
      sessionStorage.removeItem("ValidationId");
      sessionStorage.removeItem("assignedCount");
      sessionStorage.removeItem("scannedCount");
      sessionStorage.removeItem("isValidated");
      sessionStorage.removeItem("assigned");
      sessionStorage.removeItem("delivered");
      sessionStorage.removeItem("remaining");
      sessionStorage.removeItem("assignedPacakges");
      sessionStorage.removeItem("deliveredPacakges");
      sessionStorage.removeItem("remainingPacakges");
      sessionStorage.removeItem("DebriefingCode");
      sessionStorage.removeItem("SelectCourier");
      // Use navigate instead of window.location.href for in-app navigation
      navigate(getLinkWithoutToken(path));
    }
  };
  return (
    <div className="admin_sidebar">
      <div className="desktoptogglemenu">
        <button
          className="empty_btn displayFlex alignItemCenter justifyContent_center"
          onClick={() => {
            setToggleIcon((prev) => !prev);
            setWrapper((prev) => !prev);
          }}
        >
          {toggleIcon ? (
            <img loading="lazy" src={ArrowRight} />
          ) : (
            <img loading="lazy" src={Arrowleft} />
          )}
        </button>
      </div>

      {(role?.includes("AONE_ADMIN") ||
        role?.includes("AONE_IAM.read") ||
        role?.includes("AONE_IAM.write") ||
        role?.includes("AONE_IAM.admin") ||
        role?.includes("AONE_IAM_USERS.read") ||
        role?.includes("AONE_IAM_USERS.write") ||
        role?.includes("AONE_IAM_USERS.admin") ||
        role?.includes("AONE_IAM_GROUPS.read") ||
        role?.includes("AONE_IAM_GROUPS.write") ||
        role?.includes("AONE_IAM_GROUPS.admin") ||
        role?.includes("AONE_IAM_ROLES.read") ||
        role?.includes("AONE_IAM_ROLES.write") ||
        role?.includes("AONE_IAM_ROLES.admin")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setIAM(!IAM)} title="IAM">
              <button
                className={
                  pathname.includes("/roles") ||
                  pathname.includes("/groups") ||
                  pathname.includes("/users")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={iam_icon} />
                <span>IAM</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {IAM && (
              <ul className="adminsubmenu">
                {(role?.includes("AONE_ADMIN") ||
                  role?.includes("AONE_IAM.read") ||
                  role?.includes("AONE_IAM.write") ||
                  role?.includes("AONE_IAM.admin") ||
                  role?.includes("AONE_IAM_ROLES.read") ||
                  role?.includes("AONE_IAM_ROLES.write") ||
                  role?.includes("AONE_IAM_ROLES.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Roles"
                  >
                    <a
                      href={getLinkWithToken("/iam/roles")}
                      className={pathname.includes("/roles") ? "active" : ""}
                      onClick={(e) => handleLinkClick(e, "/iam/roles")}
                    >
                      <img loading="lazy" src={role_icon} alt="Role Icon" />
                      <span>Roles</span>
                    </a>
                  </li>
                )}
                {(role?.includes("AONE_ADMIN") ||
                  role?.includes("AONE_IAM.read") ||
                  role?.includes("AONE_IAM.write") ||
                  role?.includes("AONE_IAM.admin") ||
                  role?.includes("AONE_IAM_GROUPS.read") ||
                  role?.includes("AONE_IAM_GROUPS.write") ||
                  role?.includes("AONE_IAM_GROUPS.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Groups"
                  >
                    <a
                      href={getLinkWithToken("/iam/groups")}
                      className={pathname.includes("/groups") ? "active" : ""}
                      onClick={(e) => handleLinkClick(e, "/iam/groups")}
                    >
                      <img loading="lazy" src={group_icon} alt="Group Icon" />
                      <span>Groups</span>
                    </a>
                  </li>
                )}
                {(role?.includes("AONE_ADMIN") ||
                  role?.includes("AONE_IAM.read") ||
                  role?.includes("AONE_IAM.write") ||
                  role?.includes("AONE_IAM.admin") ||
                  role?.includes("AONE_IAM_USERS.read") ||
                  role?.includes("AONE_IAM_USERS.write") ||
                  role?.includes("AONE_IAM_USERS.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Users"
                  >
                    <a
                      href={getLinkWithToken("/iam/users")}
                      className={pathname.includes("/users") ? "active" : ""}
                      onClick={(e) => handleLinkClick(e, "/iam/users")}
                    >
                      <img loading="lazy" src={user_icon} alt="User Icon" />
                      <span>Users</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {roleHandler(role, "MM") && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setMiddleMile(!middleMile)} title="Middle Mile">
              <button
                className={pathname.includes("/middlemile") ? "active" : " "}
              >
                <img loading="lazy" src={milestone_icon} />
                <span>Middle Mile</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {middleMile && (
              <ul className="adminsubmenu">
                {roleHandler(role, "SORTING-MACHINE") && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Sorting Machine"
                  >
                    <a
                      href={getLinkWithToken(
                        "/middlemile/sortingmachine/config"
                      )}
                      className={
                        pathname.includes("/middlemile/sortingmachine")
                          ? "active"
                          : ""
                      }
                      onClick={(e) =>
                        handleLinkClick(e, "/middlemile/sortingmachine/config")
                      }
                    >
                      <img
                        loading="lazy"
                        src={SortIcon}
                        alt="Sorting Machine Icon"
                      />
                      <span>Sorting Machine</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_MM.admin") ||
                  roleHandler(role, "AONE_MM_BAGS")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Sorting Machine"
                  >
                    <a
                      href={getLinkWithoutToken("/middlemile/bags")}
                      className={
                        pathname.includes("/middlemile/bags") ? "active" : ""
                      }
                      onClick={(e) => handleLinkClick(e, "/middlemile/bags")}
                    >
                      <img loading="lazy" src={bag_icon} alt="Bag Icon" />
                      <span>Bags</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}

      {/* {role?.includes("AONE_ADMIN") && (
        <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
          <li
            onClick={() => {
              setProduct(!product);
            }}
            title="Products"
          >
            <Link
              to="/products/sla"
              className={pathname.includes("/products/sla") ? "active" : ""}
            >
              <img loading="lazy" src={product_icon} />
              <span>Products</span>
              {product ? (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowup}
                />
              ) : (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              )}
            </Link>
          </li>
          {product && (
            <ul className="adminsubmenu">
              <li
                onClick={() => {
                  setLog(false);
                  setPrint(false);
                  setSendsms(false);
                  setIntergrations(false);
                  setBusiness(false);
                  setConsignments(false);
                  setMasters(false);
                  setIAM(false);
                  setConfigs(false);
                  setMiddleMile(false);
                  setThirdparty(false);
                  setOps(false);
                  setProjects(false);
                  setManagement(false);
                }}
                title="SLA"
              >
                <Link
                  to="/products/sla"
                  className={pathname.includes("/products/sla") ? "active" : ""}
                >
                  <img loading="lazy" src={sla_icon} alt="SLA Icon" />
                  <span>SLA</span>
                </Link>
              </li>
            </ul>
          )}
        </ul>
      )} */}

      {/* {role?.includes("AONE_ADMIN") && (
        <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
          <li
            onClick={() => {
              setLog(!log);
            }}
            title="Reports"
          >
            <button className={pathname.includes("/ondemand") ? "active" : " "}>
              <img loading="lazy" src={report_icon} />
              <span>Reports</span>
              {log ? (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowup}
                />
              ) : (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              )}
            </button>
          </li>
          {log && (
            <ul className="adminsubmenu">
              <li
                onClick={() => {
                  setProduct(false);
                  setPrint(false);
                  setSendsms(false);
                  setIntergrations(false);
                  setBusiness(false);
                  setConsignments(false);
                  setMasters(false);
                  setIAM(false);
                  setConfigs(false);
                  setThirdparty(false);
                  setOps(false);
                  setMiddleMile(false);
                  setProjects(false);
                  setManagement(false);
                }}
                title="On Demand"
              >
                <Link
                  className={pathname.includes("/ondemand") ? "active" : " "}
                  to="/ondemand"
                >
                  <img loading="lazy" src={DemandIcon} />
                  <span>On Demand</span>
                </Link>
              </li>
            </ul>
          )}
        </ul>
      )} */}
      {(roleHandler(role, "AONE_HAJ_CONSIGNMENTS.read") ||
        roleHandler(role, "AONE_HAJ_CONSIGNMENTS.write") ||
        roleHandler(role, "AONE_HAJ_CONSIGNMENTS.print")) && (
        <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
          <li
            onClick={() => {
              setPrint(!print);
            }}
            title="Haj"
          >
            <button
              className={
                pathname.includes("/consignments/print") ||
                pathname.includes("/consignments/additional-info")
                  ? "active"
                  : " "
              }
            >
              <img loading="lazy" src={HajIcon} />
              <span>Haj</span>
              {print ? (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowup}
                />
              ) : (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              )}
            </button>
          </li>
          {print && (
            <ul className="adminsubmenu">
              {(roleHandler(role, "AONE_HAJ_CONSIGNMENTS.read") ||
                roleHandler(role, "AONE_HAJ_CONSIGNMENTS.write") ||
                roleHandler(role, "AONE_HAJ_CONSIGNMENTS.print")) && (
                <li
                  onClick={() => {
                    setProduct(false);
                    setLog(false);
                    setSendsms(false);
                    setIntergrations(false);
                    setBusiness(false);
                    setConsignments(false);
                    setMasters(false);
                    setIAM(false);
                    setConfigs(false);
                    setThirdparty(false);
                    setOps(false);
                    setMiddleMile(false);
                    setProjects(false);
                    setManagement(false);
                    setNotifications(false);
                  }}
                  title="Print"
                >
                  <a
                    className={
                      pathname.includes("/consignments/print") ? "active" : ""
                    }
                    href={getLinkWithToken("/consignments/print")}
                    onClick={(e) => handleLinkClick(e, "/consignments/print")}
                  >
                    <img loading="lazy" src={PrinterIcon} alt="Print Icon" />
                    <span>Print</span>
                  </a>
                </li>
              )}
              {(roleHandler(role, "AONE_HAJ_CONSIGNMENTS.write") ||
                roleHandler(role, "AONE_HAJ_CONSIGNMENTS.print")) && (
                <li
                  onClick={() => {
                    setProduct(false);
                    setLog(false);
                    setSendsms(false);
                    setIntergrations(false);
                    setBusiness(false);
                    setConsignments(false);
                    setMasters(false);
                    setIAM(false);
                    setConfigs(false);
                    setThirdparty(false);
                    setOps(false);
                    setMiddleMile(false);
                    setProjects(false);
                    setManagement(false);
                    setNotifications(false);
                  }}
                  title="Additional Info"
                >
                  <a
                    className={
                      pathname.includes("/consignments/additional-info")
                        ? "active"
                        : ""
                    }
                    href={getLinkWithToken("/consignments/additional-info")}
                    onClick={(e) =>
                      handleLinkClick(e, "/consignments/additional-info")
                    }
                  >
                    <img
                      loading="lazy"
                      src={AdditionalInfo}
                      alt="Additional Info Icon"
                    />
                    <span>Additional Info</span>
                  </a>
                </li>
              )}
            </ul>
          )}
        </ul>
      )}

      {role?.includes("SMS_SEND") && !role?.includes("AONE_ADMIN") && (
        <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
          <li
            onClick={() => {
              setSendsms(!sendsms);
            }}
            title="Tools"
          >
            <button className={pathname.includes("/tools") ? "active" : " "}>
              <img loading="lazy" src={ReportIcon} />
              <span>Tools</span>
              {sendsms ? (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowup}
                />
              ) : (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              )}
            </button>
          </li>
          {(sendsms || pathname.includes("/tools")) && (
            <ul className="adminsubmenu">
              <li
                onClick={() => {
                  setProduct(false);
                  setLog(false);
                  setPrint(false);
                  setIntergrations(false);
                  setBusiness(false);
                  setConsignments(false);
                  setMasters(false);
                  setIAM(false);
                  setConfigs(false);
                  setThirdparty(false);
                  setOps(false);
                  setMiddleMile(false);
                  setProjects(false);
                  setManagement(false);
                  setNotifications(false);
                }}
                title="Send SMS"
              >
                <a
                  className={
                    pathname.includes("/tools/send-sms") ? "active" : ""
                  }
                  href={getLinkWithToken("/tools/send-sms")}
                  onClick={(e) => handleLinkClick(e, "/tools/send-sms")}
                >
                  <img loading="lazy" src={DemandIcon} alt="Send SMS Icon" />
                  <span>Send SMS</span>
                </a>
              </li>
            </ul>
          )}
        </ul>
      )}

      {/* {(role?.includes("AONE_ADMIN") || roleHandler(role, "INT")) && (
        <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
          <li
            onClick={() => setIntergrations(!integrations)}
            title="Integrations"
          >
            <button
              className={pathname.includes("/integrations") ? "active" : " "}
            >
              <img loading="lazy" src={integration_icon} />
              <span>Intergrations</span>
              {integrations ? (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowup}
                />
              ) : (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              )}
            </button>
          </li>
          {integrations && (
            <ul className="adminsubmenu">
              {(role?.includes("AONE_ADMIN") || roleHandler(role, "ZID")) && (
                <li
                  // onClick={() => {
                  //   setProduct(false);
                  //   setLog(false);
                  //   setPrint(false);
                  // }}`
                  onClick={() => setZid(!zid)}
                  title="ZID"
                >
                  <Link
                    className={
                      pathname.includes("/integrations/zid/") ? "active" : " "
                    }
                    to="/tools/send-sms"
                  >
                    <img loading="lazy" src={ReportIcon} />
                    <span>ZID</span>
                    {zid ? (
                      <img
                        className="ml_auto dropdownIcon"
                        loading="lazy"
                        src={Arrowup}
                      />
                    ) : (
                      <img
                        className="ml_auto dropdownIcon"
                        loading="lazy"
                        src={Arrowdown}
                      />
                    )}
                  </Link>
                </li>
              )}
              {zid && (
                <>
                  {roleHandler(role, "PRODUCTS") && (
                    <li
                      onClick={() => {
                        setProduct(false);
                        setLog(false);
                        setPrint(false);
                        setSendsms(false);
                        setBusiness(false);
                        setConsignments(false);
                        setMasters(false);
                        setIAM(false);
                        setConfigs(false);
                        setThirdparty(false);
                        setOps(false);
                        setMiddleMile(false);
                        setProjects(false);
                        setManagement(false);
                        setNotifications(false);
                      }}
                      title="Products"
                      className={!toggleIcon && "pl16"}
                    >
                      <Link
                        className={
                          pathname.includes("/integrations/zid/products")
                            ? "active"
                            : " "
                        }
                        to="/integrations/zid/products"
                      >
                        <img loading="lazy" src={product_icon} />
                        <span>Products</span>
                      </Link>
                    </li>
                  )}
                  {roleHandler(role, "MERCHANTS") && (
                    <li
                      onClick={() => {
                        setProduct(false);
                        setLog(false);
                        setPrint(false);
                        setSendsms(false);
                        setBusiness(false);
                        setConsignments(false);
                        setMasters(false);
                        setIAM(false);
                        setConfigs(false);
                        setThirdparty(false);
                        setOps(false);
                        setMiddleMile(false);
                        setProjects(false);
                        setManagement(false);
                         setNotifications(false);
                      }}
                      title="Merchants"
                      className={!toggleIcon && "pl16"}
                    >
                      <Link
                        className={
                          pathname.includes("/integrations/zid/merchants")
                            ? "active"
                            : " "
                        }
                        to="/integrations/zid/merchants"
                      >
                        <img loading="lazy" src={merchant_icon} />
                        <span>Merchants</span>
                      </Link>
                    </li>
                  )}
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Orders"
                    className={!toggleIcon && "pl16"}
                  >
                    <Link
                      className={
                        pathname.includes("/integrations/zid/orders")
                          ? "active"
                          : " "
                      }
                      to="/integrations/zid/orders"
                    >
                      <img loading="lazy" src={OrderIcon} />
                      <span>Orders</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          )}
        </ul>
      )} */}

      {(role?.includes("AONE_CONFIGS.read") ||
        role?.includes("AONE_CONFIGS.write") ||
        role?.includes("AONE_CONFIGS.admin") ||
        role?.includes("AONE_CONFIGS_ADDRESSES.read") ||
        role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
        role?.includes("AONE_CONFIGS_ADDRESSES.admin") ||
        role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.read") ||
        role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
        role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
        role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.read") ||
        role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write") ||
        role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin") ||
        role?.includes("AONE_CONFIGS_SHORT-ADDRESS_HISTORY.read") ||
        role?.includes("AONE_CONFIGS_SHORT-ADDRESS_HISTORY.write") ||
        role?.includes("AONE_CONFIGS_ZONES.read") ||
        role?.includes("AONE_CONFIGS_ZONES.write") ||
        role?.includes("AONE_CONFIGS_ZONES.admin")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setConfigs(!configs)} title="Configs">
              <button
                className={pathname.includes("/addresses") ? "active" : " "}
              >
                <img loading="lazy" src={config_icon} />
                <span>Configs</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {configs && (
              <ul className="adminsubmenu">
                {(role?.includes("AONE_CONFIGS.read") ||
                  role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.read") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Addresses"
                  >
                    <a
                      className={
                        pathname.includes("/addresses") ? "active" : ""
                      }
                      href={getLinkWithToken("/configs/addresses")}
                      onClick={(e) => handleLinkClick(e, "/configs/addresses")}
                      style={{ marginLeft: "-3px" }}
                    >
                      <img
                        loading="lazy"
                        src={address_icon}
                        alt="Address Icon"
                      />
                      <span>Addresses</span>
                    </a>
                  </li>
                )}
                {(role?.includes("AONE_CONFIGS.read") ||
                  role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ZONES.read") ||
                  role?.includes("AONE_CONFIGS_ZONES.write") ||
                  role?.includes("AONE_CONFIGS_ZONES.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Addresses"
                  >
                    <a
                      className={pathname.includes("/zones") ? "active" : ""}
                      href={getLinkWithToken("/configs/zones")}
                      onClick={(e) => handleLinkClick(e, "/configs/zones")}
                    >
                      <img loading="lazy" src={zoneIcon} alt="Zone Icon" />
                      <span>Zones</span>
                    </a>
                  </li>
                )}
                {(role?.includes("AONE_CONFIGS.read") ||
                  role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.read") ||
                  role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
                  role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Addresses"
                  >
                    <a
                      className={
                        pathname.includes("/addressmapping") ? "active" : ""
                      }
                      href={getLinkWithToken("/configs/addressmapping")}
                      onClick={(e) =>
                        handleLinkClick(e, "/configs/addressmapping")
                      }
                    >
                      <img
                        loading="lazy"
                        src={addressMapIcon}
                        alt="Address Mapping Icon"
                      />
                      <span>Address Mapping</span>
                    </a>
                  </li>
                )}
                {(role?.includes("AONE_CONFIGS.read") ||
                  role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.read") ||
                  role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write") ||
                  role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin") ||
                  role?.includes("AONE_CONFIGS_SHORT-ADDRESS_HISTORY.read") ||
                  role?.includes(
                    "AONE_CONFIGS_SHORT-ADDRESS_HISTORY.write"
                  )) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Short Addresses"
                  >
                    <a
                      className={
                        pathname.includes("/shortaddress") ? "active" : ""
                      }
                      href={getLinkWithToken("/configs/shortaddress")}
                      onClick={(e) =>
                        handleLinkClick(e, "/configs/shortaddress")
                      }
                    >
                      <img
                        loading="lazy"
                        src={ShortAddressIcon}
                        alt="Short Address Icon"
                      />
                      <span>Short Addresses</span>
                    </a>
                  </li>
                )}
                {(role?.includes("AONE_CONFIGS.read") ||
                  role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_CURRENCY.read") ||
                  role?.includes("AONE_CONFIGS_CURRENCY.write") ||
                  role?.includes("AONE_CONFIGS_CURRENCY.admin") ||
                  role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.read") ||
                  role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.write") ||
                  role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Currency"
                  >
                    <a
                      className={pathname.includes("/currency") ? "active" : ""}
                      href={getLinkWithToken("/configs/currency")}
                      onClick={(e) => handleLinkClick(e, "/configs/currency")}
                    >
                      <img
                        loading="lazy"
                        src={currencyIcon}
                        alt="Currency Icon"
                      />
                      <span>Currency</span>
                    </a>
                  </li>
                )}
                {(role?.includes("AONE_CONFIGS.read") ||
                  role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.read") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Tiers"
                  >
                    <a
                      className={pathname.includes("/tiers") ? "active" : ""}
                      href={getLinkWithToken("/configs/tiers")}
                      onClick={(e) => handleLinkClick(e, "/configs/tiers")}
                      style={{ marginLeft: "-3px" }}
                    >
                      <img loading="lazy" src={TiersIcon} alt="Tiers Icon" />
                      <span>Tiers</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {/* {role?.includes("AONE_ADMIN") && (
        <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
          <li onClick={() => setProjects(!projects)} title="Projects">
            <button className={pathname.includes("/projects") ? "active" : " "}>
              <img loading="lazy" src={integration_icon} />
              <span>Projects</span>
              {projects ? (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowup}
                />
              ) : (
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              )}
            </button>
          </li>
          {projects && (
            <ul className="adminsubmenu">
              {role?.includes("AONE_ADMIN") && (
                <li
                  // onClick={() => {
                  //   setProduct(false);
                  //   setLog(false);
                  //   setPrint(false);
                  // }}
                  onClick={() => setAddons(!addons)}
                  title="Add-On Shipment"
                >
                  <Link
                    className={
                      pathname.includes("/projects/addon/") ? "active" : " "
                    }
                    // onClick={() => navigate("/tools/send-sms")}
                  >
                    <img loading="lazy" src={ReportIcon} />
                    <span>Add-On Shipment</span>
                    {addons ? (
                      <img
                        className="ml_auto dropdownIcon"
                        loading="lazy"
                        src={Arrowup}
                      />
                    ) : (
                      <img
                        className="ml_auto dropdownIcon"
                        loading="lazy"
                        src={Arrowdown}
                      />
                    )}
                  </Link>
                </li>
              )}
              {addons && (
                <>
                  {role?.includes("AONE_ADMIN") && (
                    <>
                      <li
                        onClick={() => {
                          setProduct(false);
                          setLog(false);
                          setPrint(false);
                          setSendsms(false);
                          setBusiness(false);
                          setConsignments(false);
                          setMasters(false);
                          setIAM(false);
                          setConfigs(false);
                          setThirdparty(false);
                          setOps(false);
                          setMiddleMile(false);
                          setProjects(false);
                          setManagement(false);
                          setNotifications(false);
                        }}
                        title="Job Orders"
                        className={!toggleIcon && "pl16"}
                      >
                        <Link
                          className={
                            pathname.includes("/projects/addon/orders")
                              ? "active"
                              : " "
                          }
                          to="/projects/addon/orders"
                        >
                          <img loading="lazy" src={product_icon} />
                          <span>Job Orders</span>
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setProduct(false);
                          setLog(false);
                          setPrint(false);
                          setSendsms(false);
                          setBusiness(false);
                          setConsignments(false);
                          setMasters(false);
                          setIAM(false);
                          setConfigs(false);
                          setThirdparty(false);
                          setOps(false);
                          setMiddleMile(false);
                          setProjects(false);
                          setManagement(false);
                          setNotifications(false);
                        }}
                        title="Operations"
                        className={!toggleIcon && "pl16"}
                      >
                        <Link
                          className={
                            pathname.includes("/projects/addon/operations")
                              ? "active"
                              : " "
                          }
                          to="/projects/addon/operations"
                        >
                          <img loading="lazy" src={product_icon} />
                          <span>Operations</span>
                        </Link>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          )}
        </ul>
      )} */}
      {(roleHandler(role, "AONE_CRM.write") ||
        roleHandler(role, "AONE_CRM.admin") ||
        roleHandler(role, "AONE_CRM.read") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS.read") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.read") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.write") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS.salesperson") ||
        roleHandler(role, "AONE_CONFIGS.write") ||
        roleHandler(role, "AONE_CONFIGS.admin") ||
        roleHandler(role, "AONE_CONFIGS.read") ||
        roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.read") ||
        roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
        roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES.read") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES.view_sensitive_data") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.read") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.read") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.write") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.admin") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.read") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.write") ||
        roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.admin")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setCrm(!crm)} title="Crm Lines">
              <button
                className={
                  pathname.includes("/customers") ||
                  pathname.includes("crm/employees")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={crmIcon} />
                <span>CRM</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {crm && (
              <ul className="adminsubmenu">
                {(roleHandler(role, "AONE_CRM.write") ||
                  roleHandler(role, "AONE_CRM.admin") ||
                  roleHandler(role, "AONE_CRM.read") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS.read") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.read") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.write") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS.salesperson") ||
                  roleHandler(role, "AONE_CONFIGS.write") ||
                  roleHandler(role, "AONE_CONFIGS.admin") ||
                  roleHandler(role, "AONE_CONFIGS.read") ||
                  roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.read") ||
                  roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
                  roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Customers"
                  >
                    <a
                      className={
                        pathname.includes("/customers") ? "active" : ""
                      }
                      href={getLinkWithToken("/crm/customers")}
                      onClick={(e) => handleLinkClick(e, "/crm/customers")}
                    >
                      <img
                        loading="lazy"
                        src={customerIcon}
                        alt="Customer Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Customers</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_CRM.write") ||
                  roleHandler(role, "AONE_CRM.admin") ||
                  roleHandler(role, "AONE_CRM.read") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.read") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.view_sensitive_data") ||
                  roleHandler(
                    role,
                    "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.read"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin"
                  ) ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.read") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.admin") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.read") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Employment"
                  >
                    <a
                      className={
                        pathname.includes("/employees") ? "active" : ""
                      }
                      href={getLinkWithToken("/crm/employees")}
                      onClick={(e) => handleLinkClick(e, "/crm/employees")}
                    >
                      <img
                        loading="lazy"
                        src={employeeIcon}
                        alt="Employee Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Employees</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {(roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.read") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.cancel") ||
        roleHandler(
          role,
          "AONE_CONSIGNMENTS_CONSIGNMENT.edit_destination_address"
        ) ||
        roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.edit_cod") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.edit_incoterms") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.read") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.write") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.admin")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li
              onClick={() => setConsignments(!consignments)}
              title="Consignments"
            >
              <button
                className={
                  pathname.includes("/consignments/orders") ||
                  pathname.includes("/consignments/sequence")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={ConsignmentIcon} />
                <span>Consignments</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {consignments && (
              <ul className="adminsubmenu">
                {(roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.read") ||
                  roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") ||
                  roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.cancel") ||
                  roleHandler(
                    role,
                    "AONE_CONSIGNMENTS_CONSIGNMENT.edit_destination_address"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount"
                  ) ||
                  roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.edit_cod") ||
                  roleHandler(
                    role,
                    "AONE_CONSIGNMENTS_CONSIGNMENT.edit_incoterms"
                  )) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setConsignments(true);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Orders"
                  >
                    <a
                      className={
                        pathname.includes("/consignments/orders")
                          ? "active"
                          : ""
                      }
                      href={getLinkWithToken("/consignments/orders")}
                      onClick={(e) =>
                        handleLinkClick(e, "/consignments/orders")
                      }
                    >
                      <img loading="lazy" src={OrdersIcon} alt="Orders Icon" />
                      <span style={{ paddingLeft: "4px" }}>Orders</span>
                    </a>
                  </li>
                )}
                {(roleHandler(
                  role,
                  "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.read"
                ) ||
                  roleHandler(
                    role,
                    "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.write"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.admin"
                  )) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setConsignments(true);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Customer Sequencer"
                  >
                    <a
                      className={
                        pathname.includes("/consignments/sequence")
                          ? "active"
                          : ""
                      }
                      href={getLinkWithToken("/consignments/sequence")}
                      onClick={(e) =>
                        handleLinkClick(e, "/consignments/sequence")
                      }
                    >
                      <img
                        loading="lazy"
                        src={SequenceIcon}
                        alt="Sequence Ranges Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>
                        Sequence Ranges
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {(roleHandler(role, "AONE_OPS.read") ||
        roleHandler(role, "AONE_OPS.write") ||
        roleHandler(role, "AONE_OPS.admin") ||
        roleHandler(role, "AONE_OPS_REASONS_NPR.read") ||
        roleHandler(role, "AONE_OPS_REASONS_NPR.write") ||
        roleHandler(role, "AONE_OPS_REASONS_NPR.admin") ||
        roleHandler(role, "AONE_OPS_REASONS_NDR.read") ||
        roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
        roleHandler(role, "AONE_OPS_REASONS_NDR.admin") ||
        roleHandler(role, "AONE_OPS_REASONS_HOLD.read") ||
        roleHandler(role, "AONE_OPS_REASONS_HOLD.write") ||
        roleHandler(role, "AONE_OPS_REASONS_HOLD.admin") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.read") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.print") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.cancel") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.receive") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.lost") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.hold") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_hold") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.damaged") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.disposed") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_pickup") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_cancel") ||
        roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_delivery") ||
        roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount") ||
        roleHandler(role, "AONE_OPS_PICKUP-REQUEST.read") ||
        roleHandler(role, "AONE_OPS_PICKUP-REQUEST.write") ||
        roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin") ||
        roleHandler(role, "AONE_OPS_PICKUP-REQUEST.cancel") ||
        roleHandler(role, "AONE_OPS_COURIERS.read") ||
        roleHandler(role, "AONE_OPS_DRIVERS.read") ||
        roleHandler(role, "AONE_OPS_DRIVERS.write") ||
        roleHandler(role, "AONE_OPS_DRIVERS.admin") ||
        roleHandler(role, "AONE_OPS_TRIPS.read") ||
        roleHandler(role, "AONE_OPS_TRIPS.write") ||
        roleHandler(role, "AONE_OPS_TRIPS.admin") ||
        roleHandler(role, "AONE_OPS_RTO.read") ||
        roleHandler(role, "AONE_OPS_RTO.write") ||
        roleHandler(role, "AONE_OPS_WEIGHTING.read") ||
        roleHandler(role, "AONE_OPS_WEIGHTING.write") ||
        roleHandler(role, "AONE_OPS_WEIGHTING.admin") ||
        roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.read") ||
        roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
        roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin") ||
        roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.read") ||
        roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.write") ||
        roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.admin") ||
        roleHandler(role, "AONE_OPS_SORTING-MACHINE_SCANS.read") ||
        roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
        roleHandler(role, "AONE_OPS_LAST-MILE.read") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.read") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.read") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING.read") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING.write") ||
        roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING-HISTORY.read") ||
        roleHandler(role, "AONE_OPS_ACL.read") ||
        roleHandler(role, "AONE_OPS_ACL.print") ||
        roleHandler(role, "AONE_OPS_ACL.scan") ||
        roleHandler(role, "AONE_OPS_ACL.write") ||
        roleHandler(role, "AONE_OPS_ACL.assign") ||
        roleHandler(role, "AONE_OPS_ACL.admin")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setOps(!ops)} title="OPS">
              <button
                className={
                  pathname.includes("/ops/pickups") ||
                  pathname.includes("/ops/reasons") ||
                  pathname.includes("/ops/consignments") ||
                  pathname.includes("/ops/routes") ||
                  pathname.includes("/ops/couriers") ||
                  pathname.includes("/ops/deliveryflow") ||
                  pathname.includes("/ops/sortingmachine") ||
                  pathname.includes("/ops/weighing")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={OpsIcon} />
                <span>OPS</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {ops && (
              <ul className="adminsubmenu">
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.read") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.print") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.cancel") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.receive") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.lost") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.hold") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_hold") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.damaged") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.disposed") ||
                  roleHandler(
                    role,
                    "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write"
                  ) ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_pickup") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_delivery") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_cancel") ||
                  roleHandler(
                    role,
                    "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount"
                  ) ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Consignments"
                  >
                    <a
                      className={
                        pathname.includes("/ops/consignments") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/consignments")}
                      onClick={(e) => handleLinkClick(e, "/ops/consignments")}
                    >
                      <img
                        loading="lazy"
                        src={OrderConsIcon}
                        alt="Consignments Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Consignments</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_PICKUP-REQUEST.read") ||
                  roleHandler(role, "AONE_OPS_PICKUP-REQUEST.write") ||
                  roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin") ||
                  roleHandler(role, "AONE_OPS_PICKUP-REQUEST.cancel")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Pickups"
                  >
                    <a
                      className={
                        pathname.includes("/ops/pickups") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/pickups")}
                      onClick={(e) => handleLinkClick(e, "/ops/pickups")}
                    >
                      <img
                        loading="lazy"
                        src={PickupIcon}
                        alt="Pickup Requests Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>
                        Pickup Requests
                      </span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.read") ||
                  roleHandler(
                    role,
                    "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write"
                  ) ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.read") ||
                  roleHandler(
                    role,
                    "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.read"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write"
                  ) ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING.read") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING.write") ||
                  roleHandler(
                    role,
                    "AONE_OPS_LAST-MILE_DEBRIEFING-HISTORY.read"
                  )) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Last Mile"
                  >
                    <a
                      className={
                        pathname.includes("/ops/lastmile") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/lastmile")}
                      onClick={(e) => handleLinklastClick(e, "/ops/lastmile")}
                    >
                      <img
                        loading="lazy"
                        src={LastMileIcon}
                        alt="Last Mile Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Last Mile</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_WEIGHTING.read") ||
                  roleHandler(role, "AONE_OPS_WEIGHTING.write") ||
                  roleHandler(role, "AONE_OPS_WEIGHTING.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Weighing"
                  >
                    <a
                      className={
                        pathname.includes("/ops/weighing") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/weighing")}
                      onClick={(e) => handleLinkClick(e, "/ops/weighing")}
                    >
                      <img
                        loading="lazy"
                        src={WeightIcon}
                        alt="Weighing Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Weighing</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.read") ||
                  roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
                  roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin") ||
                  roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.read") ||
                  roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.write") ||
                  roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.admin") ||
                  roleHandler(role, "AONE_OPS_SORTING-MACHINE_SCANS.read")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Sorting Machine"
                  >
                    <a
                      className={
                        pathname.includes("/ops/sortingmachine") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/sortingmachine/config")}
                      onClick={(e) =>
                        handleLinkClick(e, "/ops/sortingmachine/config")
                      }
                    >
                      <img
                        loading="lazy"
                        src={SortIcon}
                        alt="Sorting Machine Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>
                        Sorting Machine
                      </span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_ACL.read") ||
                  roleHandler(role, "AONE_OPS_ACL.print") ||
                  roleHandler(role, "AONE_OPS_ACL.scan") ||
                  roleHandler(role, "AONE_OPS_ACL.write") ||
                  roleHandler(role, "AONE_OPS_ACL.assign") ||
                  roleHandler(role, "AONE_OPS_ACL.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Consolidations"
                  >
                    <a
                      className={
                        pathname.includes("/ops/consolidations") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/consolidations")}
                      onClick={(e) => handleLinkClick(e, "/ops/consolidations")}
                    >
                      <img
                        loading="lazy"
                        src={AclIcon}
                        alt="Consolidations Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Consolidations</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.read") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.admin") ||
                  roleHandler(role, "AONE_OPS_TRIPS.read") ||
                  roleHandler(role, "AONE_OPS_TRIPS.write") ||
                  roleHandler(role, "AONE_OPS_TRIPS.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Linehauls"
                  >
                    <a
                      className={
                        pathname.includes("/ops/linehauls") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/linehauls")}
                      onClick={(e) => handleLinkClick(e, "/ops/linehauls")}
                    >
                      <img
                        loading="lazy"
                        src={LinehaulIcon}
                        alt="Linehaul Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Linehaul</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_REASONS_NPR.read") ||
                  roleHandler(role, "AONE_OPS_REASONS_NPR.write") ||
                  roleHandler(role, "AONE_OPS_REASONS_NPR.admin") ||
                  roleHandler(role, "AONE_OPS_REASONS_NDR.read") ||
                  roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
                  roleHandler(role, "AONE_OPS_REASONS_NDR.admin") ||
                  roleHandler(role, "AONE_OPS_REASONS_HOLD.read") ||
                  roleHandler(role, "AONE_OPS_REASONS_HOLD.write") ||
                  roleHandler(role, "AONE_OPS_REASONS_HOLD.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Reasons"
                  >
                    <a
                      className={
                        pathname.includes("/ops/reasons") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/reasons")}
                      onClick={(e) => handleLinkClick(e, "/ops/reasons")}
                    >
                      <img
                        loading="lazy"
                        src={PickupReasonIcon}
                        alt="Reasons Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Reasons</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_COURIERS.read")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Couriers"
                  >
                    <a
                      className={
                        pathname.includes("/ops/couriers") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/couriers")}
                      onClick={(e) => handleLinkClick(e, "/ops/couriers")}
                    >
                      <img
                        loading="lazy"
                        src={CouriersIcon}
                        alt="Couriers Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Couriers</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_ASSETS_ROUTES.read") ||
                  roleHandler(role, "AONE_ASSETS_ROUTES.write") ||
                  roleHandler(role, "AONE_ASSETS_ROUTES.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Routes"
                  >
                    <a
                      className={
                        pathname.includes("/ops/routes") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/routes")}
                      onClick={(e) => handleLinkClick(e, "/ops/routes")}
                    >
                      <img loading="lazy" src={RoutesIcon} alt="Routes Icon" />
                      <span style={{ paddingLeft: "4px" }}>Routes</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_RTO.read") ||
                  roleHandler(role, "AONE_OPS_RTO.write")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Rto"
                  >
                    <a
                      className={pathname.includes("/ops/rto") ? "active" : ""}
                      href={getLinkWithToken("/ops/rto")}
                      onClick={(e) => handleLinkClick(e, "/ops/rto")}
                    >
                      <img loading="lazy" src={RtoIcon} alt="RTO Icon" />
                      <span style={{ paddingLeft: "4px" }}>RTO</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Deliveryflow"
                  >
                    <a
                      className={
                        pathname.includes("/ops/deliveryflow") ? "active" : ""
                      }
                      href={getLinkWithToken("/ops/deliveryflow")}
                      onClick={(e) => handleLinkClick(e, "/ops/deliveryflow")}
                    >
                      <img
                        loading="lazy"
                        src={DeliveryflowIcon}
                        alt="Delivery Flow Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Delivery Flow</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {(roleHandler(role, "AONE_MASTERS.read") ||
        roleHandler(role, "AONE_MASTERS.write") ||
        roleHandler(role, "AONE_MASTERS.admin") ||
        roleHandler(role, "AONE_MASTERS_PRODUCT.read") ||
        roleHandler(role, "AONE_MASTERS_PRODUCT.write") ||
        roleHandler(role, "AONE_MASTERS_PRODUCT.admin") ||
        roleHandler(role, "AONE_MASTERS_RATES.admin") ||
        roleHandler(role, "AONE_MASTERS_RATES.write") ||
        roleHandler(role, "AONE_MASTERS_RATES.read")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setMasters(!masters)} title="Business Lines">
              <button className={pathname.includes("masters") ? "active" : " "}>
                <img loading="lazy" src={mastersIcon} />
                <span>Masters</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {masters && (
              <ul className="adminsubmenu">
                {(roleHandler(role, "AONE_MASTERS.read") ||
                  roleHandler(role, "AONE_MASTERS.write") ||
                  roleHandler(role, "AONE_MASTERS.admin") ||
                  roleHandler(role, "AONE_MASTERS_PRODUCT.read") ||
                  roleHandler(role, "AONE_MASTERS_PRODUCT.write") ||
                  roleHandler(role, "AONE_MASTERS_PRODUCT.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Products"
                  >
                    <a
                      className={
                        pathname.includes("/masters/products") ? "active" : ""
                      }
                      href={getLinkWithToken("/masters/products")}
                      onClick={(e) => handleLinkClick(e, "/masters/products")}
                    >
                      <img
                        loading="lazy"
                        src={productsIcon}
                        alt="Products Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Products</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_MASTERS.read") ||
                  roleHandler(role, "AONE_MASTERS.write") ||
                  roleHandler(role, "AONE_MASTERS.admin") ||
                  roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                  roleHandler(role, "AONE_MASTERS_RATES.write") ||
                  roleHandler(role, "AONE_MASTERS_RATES.read")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Rates"
                  >
                    <a
                      className={
                        pathname.includes("/masters/rates") ? "active" : ""
                      }
                      href={getLinkWithToken("/masters/rates")}
                      onClick={(e) => handleLinkClick(e, "/masters/rates")}
                    >
                      <img loading="lazy" src={RatesIcon} alt="Rates Icon" />
                      <span style={{ paddingLeft: "4px" }}>Rates</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {(roleHandler(role, "AONE_ASSETS.read") ||
        roleHandler(role, "AONE_ASSETS.write") ||
        roleHandler(role, "AONE_ASSETS.admin") ||
        roleHandler(role, "AONE_ASSETS_HUBS.read") ||
        roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
        roleHandler(role, "AONE_ASSETS_HUBS.write") ||
        roleHandler(role, "AONE_ASSETS_ROUTES.admin") ||
        roleHandler(role, "AONE_ASSETS_ROUTES.read") ||
        roleHandler(role, "AONE_ASSETS_ROUTES.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.read") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.admin") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.read") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.admin") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.read") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.admin") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.read") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.read") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.admin") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.read") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.write") ||
        roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.admin")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setAsset(!assets)} title="Finance">
              <button
                className={
                  pathname.includes("/assets") || pathname.includes("/hubs")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={assetsIcon} />
                <span>Assets</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {assets && (
              <ul className="adminsubmenu">
                {(roleHandler(role, "AONE_ASSETS.read") ||
                  roleHandler(role, "AONE_ASSETS.write") ||
                  roleHandler(role, "AONE_ASSETS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.read") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Hubs"
                  >
                    <a
                      className={pathname.includes("/hubs") ? "active" : ""}
                      href={getLinkWithToken("/assets/hubs")}
                      onClick={(e) => handleLinkClick(e, "/assets/hubs")}
                    >
                      <img loading="lazy" src={hubIcon} alt="Hubs Icon" />
                      <span style={{ paddingLeft: "4px" }}>Hubs</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_ASSETS.read") ||
                  roleHandler(role, "AONE_ASSETS.write") ||
                  roleHandler(role, "AONE_ASSETS.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.read") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.read") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.read") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.read") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.read") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.read") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Vehicles"
                  >
                    <a
                      className={pathname.includes("/vehicles") ? "active" : ""}
                      href={getLinkWithToken("/assets/vehicles")}
                      onClick={(e) => handleLinkClick(e, "/assets/vehicles")}
                    >
                      <img
                        loading="lazy"
                        src={VehicleIcon}
                        alt="Vehicles Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Vehicles</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.read") ||
        roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.write") ||
        roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.admin") ||
        roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.read") ||
        roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.write") ||
        roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.admin") ||
        roleHandler(role, "AONE_NOTIFICATIONS_SMS_HISTORY.read")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setNotifications(!notification)} title="Finance">
              <button
                className={
                  pathname.includes("/notifications") ||
                  pathname.includes("/sms")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={NotificationsIcon} />
                <span>Notifications</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {notification && (
              <ul className="adminsubmenu">
                <li
                  onClick={() => {
                    setProduct(false);
                    setLog(false);
                    setPrint(false);
                    setSendsms(false);
                    setIntergrations(false);
                    setIAM(false);
                    setConfigs(false);
                    setThirdparty(false);
                    setOps(false);
                    setMiddleMile(false);
                    setProjects(false);
                    setManagement(false);
                    setNotifications(true);
                  }}
                  title="SMS"
                >
                  <a
                    className={pathname.includes("/sms") ? "active" : ""}
                    href={getLinkWithToken("/notifications/sms")}
                    onClick={(e) => handleLinkClick(e, "/notifications/sms")}
                  >
                    <img loading="lazy" src={SmsTemplateIcon} alt="SMS Icon" />
                    <span style={{ paddingLeft: "4px" }}>SMS</span>
                  </a>
                </li>
              </ul>
            )}
          </ul>
        </>
      )}
      {(roleHandler(role, "AONE_CONFIGS_ADDRESSES.read") ||
        roleHandler(role, "AONE_FINANCE.read") ||
        roleHandler(role, "AONE_FINANCE.write") ||
        roleHandler(role, "AONE_FINANCE.admin") ||
        roleHandler(role, "AONE_FINANCE_INVOICE.read") ||
        roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
        roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
        roleHandler(role, "AONE_FINANCE_INVOICE_INVOICE-REQUEST.read") ||
        roleHandler(role, "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write") ||
        roleHandler(role, "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin") ||
        roleHandler(role, "AONE_FINANCE_INVOICE_CONSIGNMENTS.oracle") ||
        roleHandler(
          role,
          "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.read"
        ) ||
        roleHandler(
          role,
          "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.write"
        ) ||
        roleHandler(
          role,
          "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.admin"
        ) ||
        roleHandler(role, "AONE_FINANCE_CHARGES.read") ||
        roleHandler(role, "AONE_FINANCE_CHARGES.write") ||
        roleHandler(role, "AONE_FINANCE_CHARGES.admin") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS.read") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS.settle") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.read") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.settle") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.mode") ||
        roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.read")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setInvoice(!invoice)} title="Finance">
              <button
                className={
                  pathname.includes("/invoices") ||
                  pathname.includes("/charges")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={financeIcon} />
                <span>Finance</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {invoice && (
              <ul className="adminsubmenu">
                {(roleHandler(role, "AONE_CONFIGS_ADDRESSES.read") ||
                  roleHandler(role, "AONE_FINANCE.read") ||
                  roleHandler(role, "AONE_FINANCE.write") ||
                  roleHandler(role, "AONE_FINANCE.admin") ||
                  roleHandler(role, "AONE_FINANCE_INVOICE.read") ||
                  roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
                  roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST.read"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_CONSIGNMENTS.oracle"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.read"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.write"
                  )) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Invoices"
                  >
                    <a
                      className={pathname.includes("/invoices") ? "active" : ""}
                      href={getLinkWithToken("/finance/invoices")}
                      onClick={(e) => handleLinkClick(e, "/finance/invoices")}
                    >
                      <img
                        loading="lazy"
                        src={invoiceIcon}
                        alt="Invoices Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Invoices</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_CONFIGS_ADDRESSES.read") ||
                  roleHandler(role, "AONE_FINANCE.read") ||
                  roleHandler(role, "AONE_FINANCE.write") ||
                  roleHandler(role, "AONE_FINANCE.admin") ||
                  roleHandler(role, "AONE_FINANCE_INVOICE.read") ||
                  roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
                  roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST.read"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_CONSIGNMENTS.oracle"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.read"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.write"
                  ) ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.admin"
                  ) ||
                  roleHandler(role, "AONE_FINANCE_CHARGES.read") ||
                  roleHandler(role, "AONE_FINANCE_CHARGES.write") ||
                  roleHandler(role, "AONE_FINANCE_CHARGES.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Charges"
                  >
                    <a
                      className={pathname.includes("/charges") ? "active" : ""}
                      href={getLinkWithToken("/finance/charges")}
                      onClick={(e) => handleLinkClick(e, "/finance/charges")}
                    >
                      <img
                        loading="lazy"
                        src={warehouse_icon}
                        alt="Charges Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Charges (Temp)</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_FINANCE.read") ||
                  roleHandler(role, "AONE_FINANCE.write") ||
                  roleHandler(role, "AONE_FINANCE.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.read") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.settle") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.read") ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_LEDGERS_COLLECTIONS.settle"
                  ) ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.mode") ||
                  roleHandler(
                    role,
                    "AONE_FINANCE_LEDGERS_TRANSACTIONS.read"
                  )) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Cash Debriefing"
                  >
                    <a
                      className={
                        pathname.includes("/cashdebriefing") ? "active" : ""
                      }
                      href={getLinkWithToken("/finance/cashdebriefing")}
                      onClick={(e) =>
                        handleLinkClick(e, "/finance/cashdebriefing")
                      }
                    >
                      <img
                        loading="lazy"
                        src={CashIcon}
                        alt="Cash Debriefing Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>
                        Cash Debriefing
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {(role?.includes("AONE_ADMIN") ||
        role?.includes("AONE_THIRD-PARTY.read") ||
        role?.includes("AONE_THIRD-PARTY.write") ||
        role?.includes("AONE_THIRD-PARTY.admin") ||
        role?.includes("AONE_THIRD-PARTY_SCANS.read") ||
        role?.includes("AONE_THIRD-PARTY_SCANS.admin") ||
        role?.includes("AONE_THIRD-PARTY_SCANS_PICKUP.read") ||
        role?.includes("AONE_THIRD-PARTY_SCANS_PICKUP.write") ||
        role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.read") ||
        role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write") ||
        role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin") ||
        role?.includes("AONE_THIRD-PARTY_ORDERS.read") ||
        role?.includes("AONE_THIRD-PARTY_ORDERS.write") ||
        role?.includes("AONE_THIRD-PARTY_ORDERS.cancel") ||
        role?.includes("AONE_THIRD-PARTY_ORDERS.admin") ||
        role?.includes("AONE_THIRD-PARTY_ORDERS.assign")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setThirdparty(!thirdparty)} title="Third Party">
              <button
                className={
                  pathname.includes("/configurations") ? "active" : " "
                }
              >
                <img loading="lazy" src={thirdparty_icon} />
                <span>Third Party</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {thirdparty && (
              <ul className="adminsubmenu">
                {(role?.includes("AONE_ADMIN") ||
                  role?.includes("AONE_THIRD-PARTY.read") ||
                  role?.includes("AONE_THIRD-PARTY.write") ||
                  role?.includes("AONE_THIRD-PARTY.admin") ||
                  role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.read") ||
                  role?.includes(
                    "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write"
                  ) ||
                  role?.includes(
                    "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin"
                  )) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Configrations"
                  >
                    <a
                      className={
                        pathname.includes("/configurations") ? "active" : ""
                      }
                      href={getLinkWithToken("/thirdparty/configurations")}
                      onClick={(e) =>
                        handleLinkClick(e, "/thirdparty/configurations")
                      }
                    >
                      <img
                        loading="lazy"
                        src={conf_icon}
                        alt="Configurations Icon"
                      />
                      <span>Configurations</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_ADMIN") ||
                  roleHandler(role, "AONE_THIRD-PARTY.read") ||
                  roleHandler(role, "AONE_THIRD-PARTY.write") ||
                  roleHandler(role, "AONE_THIRD-PARTY.admin") ||
                  roleHandler(role, "AONE_THIRD-PARTY_ORDERS.read") ||
                  roleHandler(role, "AONE_THIRD-PARTY_ORDERS.assign") ||
                  roleHandler(role, "AONE_THIRD-PARTY_ORDERS.cancel") ||
                  roleHandler(role, "AONE_THIRD-PARTY_ORDERS.write") ||
                  roleHandler(role, "AONE_THIRD-PARTY_ORDERS.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setBusiness(false);
                      setConsignments(false);
                      setMasters(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Orders"
                  >
                    <a
                      className={pathname.includes("/orders") ? "active" : ""}
                      href={getLinkWithToken("/thirdparty/orders")}
                      onClick={(e) => handleLinkClick(e, "/thirdparty/orders")}
                    >
                      <img
                        loading="lazy"
                        src={landfreight_icon}
                        alt="Orders Icon"
                      />
                      <span>Orders</span>
                    </a>
                  </li>
                )}
                {(roleHandler(role, "AONE_ADMIN") ||
                  roleHandler(role, "AONE_THIRD-PARTY.read") ||
                  roleHandler(role, "AONE_THIRD-PARTY.write") ||
                  roleHandler(role, "AONE_THIRD-PARTY.admin") ||
                  roleHandler(role, "AONE_THIRD-PARTY_SCANS.read") ||
                  roleHandler(role, "AONE_THIRD-PARTY_SCANS.admin") ||
                  roleHandler(role, "ONE_THIRD-PARTY_SCANS_PICKUP.read") ||
                  roleHandler(role, "AONE_THIRD-PARTY_SCANS_PICKUP.write")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setMasters(false);
                      setBusiness(false);
                      setConsignments(false);
                      setIAM(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Configrations"
                  >
                    <a
                      className={pathname.includes("/events") ? "active" : ""}
                      href={getLinkWithToken("/thirdparty/events")}
                      onClick={(e) => handleLinkClick(e, "/thirdparty/events")}
                    >
                      <img
                        loading="lazy"
                        src={freight_icon}
                        alt="Events Icon"
                      />
                      <span>Events</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
        {(roleHandler(role, "AONE_SETTINGS_LOCALIZATION.read") ||
        roleHandler(role, "AONE_SETTINGS_LOCALIZATION.write") ||
        roleHandler(role, "AONE_SETTINGS_LOCALIZATION.admin") ||
        roleHandler(role, "AONE_SETTINGS_SYSTEM.read") ||
        roleHandler(role, "AONE_SETTINGS_SYSTEM.write") ||
        roleHandler(role, "AONE_ADMIN") ||
        roleHandler(role, "AONE_SETTINGS_SYSTEM.admin") ||
        roleHandler(role, "AONE_SETTINGS_RESOURCES.read") ||
        roleHandler(role, "AONE_SETTINGS_RESOURCES.upload") ||
        roleHandler(role, "AONE_SETTINGS_RESOURCES.admin")) && (
        <>
          <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setSettings(!settings)} title="Finance">
              <button
                className={
                  pathname.includes("/settings") 
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={financeIcon} />
                <span>Settings</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {settings && (
              <ul className="adminsubmenu">
                {(roleHandler(role, "AONE_SETTINGS_LOCALIZATION.read") ||
        roleHandler(role, "AONE_SETTINGS_LOCALIZATION.write") ||
        roleHandler(role, "AONE_ADMIN") ||
        roleHandler(role, "AONE_SETTINGS_LOCALIZATION.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="Localization"
                  >
                    <a
                      className={pathname.includes("/settings/localization") ? "active" : ""}
                      href={getLinkWithToken("/settings/localization")}
                      onClick={(e) => handleLinkClick(e, "/settings/localization")}
                    >
                      <img
                        loading="lazy"
                        src={invoiceIcon}
                        alt="Localization Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>Localization</span>
                    </a>
                  </li>
                )}
                 {(roleHandler(role, "AONE_SETTINGS_SYSTEM.read") ||
        roleHandler(role, "AONE_SETTINGS_SYSTEM.write") ||
        roleHandler(role, "AONE_ADMIN") ||
        roleHandler(role, "AONE_SETTINGS_SYSTEM.admin")) && (
                  <li
                    onClick={() => {
                      setProduct(false);
                      setLog(false);
                      setPrint(false);
                      setSendsms(false);
                      setIntergrations(false);
                      setIAM(false);
                      setConfigs(false);
                      setThirdparty(false);
                      setOps(false);
                      setMiddleMile(false);
                      setProjects(false);
                      setManagement(false);
                      setNotifications(false);
                    }}
                    title="System"
                  >
                    <a
                      className={pathname.includes("/settings/system") ? "active" : ""}
                      href={getLinkWithToken("/settings/system")}
                      onClick={(e) => handleLinkClick(e, "/settings/system")}
                    >
                      <img
                        loading="lazy"
                        src={invoiceIcon}
                        alt="System Icon"
                      />
                      <span style={{ paddingLeft: "4px" }}>System</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ul>
        </>
      )}
      {role?.includes("AONE_ADMIN") && (
        <>
          {/* <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li onClick={() => setBusiness(!business)} title="Business Lines">
              <button
                className={
                  pathname.includes("/express") ||
                  pathname.includes("/warehouse") ||
                  pathname.includes("/freightforwarding") ||
                  pathname.includes("/landfreight")
                    ? "active"
                    : " "
                }
              >
                <img loading="lazy" src={businessline_icon} />
                <span>Business Lines</span>
                <img
                  className="ml_auto dropdownIcon"
                  loading="lazy"
                  src={Arrowdown}
                />
              </button>
            </li>
            {business && (
              <ul className="adminsubmenu">
                <li
                  onClick={() => {
                    setProduct(false);
                    setLog(false);
                    setPrint(false);
                    setSendsms(false);
                    setIntergrations(false);
                    setIAM(false);
                    setConfigs(false);
                    setThirdparty(false);
                    setOps(false);
                    setMiddleMile(false);
                    setProjects(false);
                    setManagement(false);
                    setNotifications(false);
                  }}
                  title="Express Invoice"
                >
                  <Link
                    className={
                      pathname.includes("/express") ||
                      pathname.includes("EXPRESS")
                        ? "active"
                        : " "
                    }
                    to="/express"
                  >
                    <img loading="lazy" src={express_icon} />
                    <span style={{ paddingLeft: "4px" }}>Express Invoice</span>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setProduct(false);
                    setLog(false);
                    setPrint(false);
                    setSendsms(false);
                    setIntergrations(false);
                    setIAM(false);
                    setConfigs(false);
                    setThirdparty(false);
                    setOps(false);
                    setMiddleMile(false);
                    setProjects(false);
                    setManagement(false);
                    setNotifications(false);
                  }}
                  title="Land Freight Invoice"
                >
                  <Link
                    className={
                      pathname.includes("/landfreight") ||
                      pathname.includes("LAND_FREIGHT")
                        ? "active"
                        : " "
                    }
                    to="/landfreight"
                  >
                    <img loading="lazy" src={landfreight_icon} />
                    <span>Land Freight Invoice</span>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setProduct(false);
                    setLog(false);
                    setPrint(false);
                    setSendsms(false);
                    setIntergrations(false);
                    setIAM(false);
                    setConfigs(false);
                    setThirdparty(false);
                    setOps(false);
                    setMiddleMile(false);
                    setProjects(false);
                    setManagement(false);
                    setNotifications(false);
                  }}
                  title="Ware House Invoice"
                >
                  <Link
                    className={
                      pathname.includes("/warehouse") ||
                      pathname.includes("WARE_HOUSE")
                        ? "active"
                        : " "
                    }
                    to="/warehouse"
                  >
                    <img loading="lazy" src={warehouse_icon} />
                    <span>Ware House Invoice</span>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setProduct(false);
                    setLog(false);
                    setPrint(false);
                    setSendsms(false);
                    setIntergrations(false);
                    setIAM(false);
                    setConfigs(false);
                    setThirdparty(false);
                    setOps(false);
                    setMiddleMile(false);
                    setProjects(false);
                    setManagement(false);
                    setNotifications(false);
                  }}
                  title="Freight Forwarding Invoice"
                >
                  <Link
                    className={
                      pathname.includes("/freightforwarding") ||
                      pathname.includes("FREIGHT_FORWARDING")
                        ? "active"
                        : " "
                    }
                    to="/freightforwarding"
                  >
                    <img loading="lazy" src={freight_icon} />
                    <span>Freight Forwarding Invoice</span>
                  </Link>
                </li>
              </ul>
            )}
          </ul> */}

          {/* <ul style={{ paddingTop: "10px", marginTop: "10px" }}>
            <li
              onClick={() => {
                setProduct(false);
                setLog(false);
                setPrint(false);
                setSendsms(false);
                setIntergrations(false);
                setBusiness(false);
                setConsignments(false);
                setMasters(false);
                setIAM(false);
                setConfigs(false);
                setThirdparty(false);
                setOps(false);
                setMiddleMile(false);
                setProjects(false);
                setManagement(false);
                setNotifications(false);
              }}
              title="Customer Management"
            >
              <Link
                className={
                  pathname.includes("/customermanagement") ? "active" : " "
                }
                to="/customermanagement"
              >
                <img loading="lazy" src={management_icon} />
                <span>Customer Management</span>
              </Link>
            </li>
          </ul> */}

          {/* <ul style={{ paddingTop: "10px" , marginTop: "10px"}}>
          <li
            onClick={() => {
              setProduct(false);
              setLog(false);
              setPrint(false);
              setSendsms(false);
              setIntergrations(false);
              setBusiness(false);
              setConsignments(false)
              setIAM(false);
              setConfigs(false);
              setThirdparty(false)
              setMiddleMile(false);
            }}    
            title="Administration"         
          >
            <button
              className={
                pathname.includes("/administration") ? "active" : " "
              }
              onClick={()=>navigate("/administration")}
            >
              <img loading="lazy" src={admin_icon} />
              <span>Administration</span>
            </button>
          </li>
        </ul> */}
        </>
      )}
    </div>
  );
};

export default Adminsidebar;
