import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getLinehaulReasons,getLinehaulReasonsbyId,postLinehaulReasons,
    editLinehaulReasons,exportLinehaulReasons,deleteLineHaulsbyId} from "../../services/Reasons/Linehaulreason_services"
const initialValues = {
 
};
let lineHaulStore = (set, get) => ({
  ...initialValues,
      getLinehaulReasons: async (params) => {
        const response = await getLinehaulReasons(params);
        return response;
      },
      getLinehaulReasonsbyId: async (params) => {
        const response = await getLinehaulReasonsbyId(params);
        return response;
      },
      postLinehaulReasons: async (params) => {
        const response = await postLinehaulReasons(params);
        return response;
      },
      editLinehaulReasons: async (params,data) => {
        const response = await editLinehaulReasons(params,data);
        return response;
      },
      exportLinehaulReasons: async (params) => {
        const response = await exportLinehaulReasons(params);
        return response;
      },
      deleteLineHaulsbyId: async (params) => {
        const response = await deleteLineHaulsbyId(params);
        return response;
      },
});

lineHaulStore = devtools(lineHaulStore);

export const useLinehaulStore = create(lineHaulStore);