import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get pickup request
export const getPickupRequest = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/pickup/request${params}`);
    } catch (error) {
      return error;
    }
  };
//get pickup request by id
export const getPickupRequestbyId = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/pickup/request/${params}`);
  } catch (error) {
    return error;
  }
};
//post pickup request
export const postPickupRequest = async (data) => {
    try {
      return await AoneService.post("ops/api/v1/pickup/request", data);
    } catch (error) {
      return error;
    }
  };
//edit pickup request
export const editPickupRequest = async (params,data) => {
    try {
      return await AoneService.put(`ops/api/v1/pickup/request/${params}`, data);
    } catch (error) {
      return error;
    }
  };
//upload pickup request
export const importPickupRequests = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/pickup/request/upload`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };
  //upload proof
  export const importPickupRequestsProof = async (param,data) => {
    try {
      return await AoneService.post(`ops/api/v1/pickup/request/${param}/images/201`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };
//pickup request template
  export const getPickupTemplate = async () => {
    try {
      return await AoneService.get(
        "ops/api/v1/pickup/request/template",
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };
//export pickup request
export const exportPickupRequests = async (params) => {
    try {
      return await AoneService.post("ops/api/v1/pickup/request/export" , params, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//cancel pickup request
export const cancelPickupRequest = async (params,data) => {
    try {
      return await AoneService.post(`ops/api/v1/pickup/request/${params}/cancel`, data);
    } catch (error) {
      return error;
    }
  };
//apply pickup request
export const applyPickupRequest = async (params,data) => {
    try {
      return await AoneService.post(`ops/api/v1/pickup/request/${params}/apply`, data);
    } catch (error) {
      return error;
    }
  };
  //apply bulk pickup request
  export const applyBulkPickupRequest = async (data) => {
    try {
      return await AoneService.patch("ops/api/v1/pickup/request/assign", data);
    } catch (error) {
      return error;
    }
  };
//get packagelevel event
export const getPackageTrackEvent = async (param) => {
  try {
    return await AoneService.post(`ops/api/v1/events/package/${param}`);
  } catch (error) {
    return error;
  }
};
  
//post consignment by pickup request
export const postPickupConsignment = async (params,data) => {
    try {
      return await AoneService.post(`ops/api/v1/pickup/request/${params}/consignments`, data);
    } catch (error) {
      return error;
    }
  };
//delete consignment
export const deletePickupConsignment = async (params,params2) => {
    try {
      return await AoneService.delete(`ops/api/v1/pickup/request/${params}/consignments/${params2}`);
    } catch (error) {
      return error;
    }
  };

  //consignment controller
  export const getConsignments = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/consignments${params}`);
    } catch (error) {
      return error;
    }
  };

  //consignment destination info
  export const getConsignmentsDestination = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/consignments/${params}/destination`);
    } catch (error) {
      return error;
    }
  };
//apply pickup consignments
export const applyConsignments = async (data)=>{
   try{
    return await AoneService.post(`ops/api/v1/scans`,data);
   } catch (error) {
    return error;
   }
}
//bulk apply
export const bulkapplyConsignments = async (data)=>{
  try{
   return await AoneService.post(`ops/api/v1/scans/bulk`,data);
  } catch (error) {
   return error;
  }
}
//revoke pickup consignment
export const revokePickupConsignment = async (data)=>{
  try{
   return await AoneService.post(`ops/api/v1/consignments/revoke-pickup`,data);
  } catch (error) {
   return error;
  }
}
//revoke cancel consignment
export const revokeCancelConsignment = async (data)=>{
  try{
   return await AoneService.post(`ops/api/v1/consignments/revoke-cancel`,data);
  } catch (error) {
   return error;
  }
}
//revoke delivery consignment
export const revokeDeliveryConsignment = async (data)=>{
  try{
   return await AoneService.post(`ops/api/v1/consignments/revoke-delivery`,data);
  } catch (error) {
   return error;
  }
}
//revoke hold
export const revokeHoldConsignment = async (data)=>{
  try{
   return await AoneService.post(`ops/api/v1/consignments/revoke-hold`,data);
  } catch (error) {
   return error;
  }
}
//ddu update template
export const dduUpdateTemplate = async () => {
  try {
    return await AoneService.get(
      `orders/api/v1/consignments/ddu/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//upload ddu
export const importDdu = async (data) => {
  try {
    return await AoneService.post(`orders/api/v1/consignments/ddu/upload`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
  //get pickup request consignment
  export const getPickupConsignments = async (params,data) => {
    try {
      return await AoneService.get(`ops/api/v1/pickup/request/${params}/consignments${data}`);
    } catch (error) {
      return error;
    }
  };

  //consignment export
  export const exportConsignments = async (params) => {
    try {
      return await AoneService.get("ops/api/v1/consignments/export" + params, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };

  //consignment lookups
  export const getConsignmentStatus = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/PickupStatus`);
    } catch (error) {
      return error;
    }
  };
  //pickuprequest lookups
  export const getPickuprequestStatus = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/EventCode`);
    } catch (error) {
      return error;
    }
  };