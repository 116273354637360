import React, { useState, useEffect, useContext } from "react";
import { useAoneStore } from "../../store/AoneStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import { useParams, useNavigate } from "react-router-dom";
import { getDistricts } from "../../services/aone_services";
const Rto = ({ setTab }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [phoneNumberRegex, setphoneNumberRegex] = useState("");
  const [alternatephoneNumberRegex, setAlternatephoneNumberRegex] =
    useState("");
  const getCountries = useAoneStore((state) => state?.getCountries);
  const getCountryArray = useAoneStore((state) => state?.getCountryArray);
  const getCities = useAoneStore((state) => state.getCities);
  const getRegionbyCode = useAoneStore((state) => state?.getRegionbyCode);
  const getCitybyCode = useAoneStore((state) => state.getCitybyCode);
  const getCountryByCity = useAoneStore((state) => state.getCountryByCity);
  const citybyCountyData = useAoneStore((state) => state.citybyCountyData);
  const getBillingbyId = useAoneStore((state) => state.getBillingbyId);
  const getRtoaddressbyId = useAoneStore((state) => state.getRtoaddressbyId);
  const postRtoAddress = useAoneStore((state) => state.postRtoAddress);
  const editRtoaddress = useAoneStore((state) => state.editRtoaddress);
  const getDistrictbyCode = useAoneStore((state) => state.getDistrictbyCode);
  const setbillingArrayData = useAoneStore(
    (state) => state.setbillingArrayData
  );
  const [alternatePhoneerror, setAlternatePhoneError] = useState(null);
  const [isPhonecodePrefix, setIsPhonecodeprefix] = useState(false);
  const billingArrayData = useAoneStore((state) => state.billingArrayData);
  const countryArrayData = useAoneStore((state) => state?.countryArrayData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const rtoAddressData = useAoneStore((state) => state?.rtoAddressData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const citybyCode = useAoneStore((state) => state?.citybyCode);
  const districtbyCode = useAoneStore((state) => state?.districtbyCode);
  const globalcustomerAccount = useAoneStore(
    (state) => state?.globalcustomerAccount
  );
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length === undefined ||
      countriesData?.data?.data?.content?.length < 1
    ) {
      getCountries(paramslink);
    }
    if (
      citiesData?.data?.data?.content?.length === undefined ||
      citiesData?.data?.data?.content?.length < 1
    ) {
      getCities(paramslink);
    }
  }, []);
  const addRtoAddress = useFormik({
    initialValues: {
      receiverName: "",
      nationalId: "",
      countryCode3: "",
      cityCode: "",
      districtCode: "",
      postalCode: "",
      addressLine1: "",
      addressLine2: "",
      phoneNumber: "",
      alternativePhone: "",
      longitude: "",
      latitude: "",
    },
    validationSchema: Yup.object({
      countryCode3: Yup.string().required("Country is required"),
      cityCode: Yup.string().required("City is required"),
      districtCode: Yup.string().required("District is required"),
      phoneNumber: Yup.string()
        .required("Phone Number is required")
        .matches(
          /^[0-9]+$/,
          "Invalid phone number. Please enter only numbers."
        ),
      postalCode: Yup.string().matches(
        /^\d{4,5}$/,
        "Postal code must be 4 or 5 digits"
      ),
      nationalId: Yup.number().typeError("National Id must be a number"),
      latitude: Yup.number().typeError("Latitude must be a number"),
      longitude: Yup.number().typeError("Longitude must be a number"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (params?.id) {
        const updatedValues = {
          ...values,
          id: data?.id,
        };
        if (updatedValues?.id !== undefined) {
          const { phonecodeprefix, ...newValues } = updatedValues;
          newValues.phoneNumber = addRtoAddress.values.phonecodeprefix + addRtoAddress.values.phoneNumber;
          newValues.alternativePhone = addRtoAddress.values.phonecodeprefix + addRtoAddress.values.alternativePhone;
          await editRtoaddress(globalcustomerAccount, newValues).then(
            (response) => {
              if (response?.data?.status == "ERROR") {
                const errorMessage = response.data.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.data?.status === "ERROR") {
                toast.error(response?.response?.data?.msg);
              } else if (response?.response?.status === 500) {
                toast.error(response?.response?.statusText);
              } else if (response?.response?.data?.errors?.length > 0) {
                const errorMessages = response?.response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else {
                toast.success("RTO Address Updated");
                  navigate("/crm/customers");
                  resetForm();
              }
            }
          );
        } else {
          const { phonecodeprefix, ...newValues } = values;
          newValues.phoneNumber = addRtoAddress.values.phonecodeprefix + addRtoAddress.values.phoneNumber;
          newValues.alternativePhone = addRtoAddress.values.phonecodeprefix + addRtoAddress.values.alternativePhone;
          const updatedValues = {
            ...newValues,
            customerAccount: params?.id,
          };
          await postRtoAddress(globalcustomerAccount, updatedValues).then(
            (response) => {
              if (response?.data?.status == "ERROR") {
                const errorMessage = response.data.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.data?.status === "ERROR") {
                toast.error(response?.response?.data?.msg);
              } else if (response?.response?.status === 500) {
                toast.error(response?.response?.statusText);
              } else if (response?.response?.data?.errors?.length > 0) {
                const errorMessages = response?.response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else {
                toast.success("RTO Address Added");
                navigate("/crm/customers");
                resetForm();
              }
            }
          );
        }
      } else {
        if (globalcustomerAccount) {
          const { phonecodeprefix, ...newValues } = values;
          newValues.phoneNumber = addRtoAddress.values.phonecodeprefix + addRtoAddress.values.phoneNumber;
          newValues.alternativePhone = addRtoAddress.values.phonecodeprefix + addRtoAddress.values.alternativePhone;
          await postRtoAddress(globalcustomerAccount, newValues).then(
            (response) => {
              if (response?.data?.status == "ERROR") {
                const errorMessage = response.data.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.data?.status === "ERROR") {
                toast.error(response?.response?.data?.msg);
              } else if (response?.response?.status === 500) {
                toast.error(response?.response?.statusText);
              } else if (response?.response?.data?.errors?.length > 0) {
                const errorMessages = response?.response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else {
                toast.success("RTO Address Added");
                navigate("/crm/customers");
                resetForm();
              }
            }
          );
        } else {
          toast.error(
            "Customer Account need to be created before saving the RTO Address"
          );
        }
      }
    },
  });
  const [nameErr, setNameErr] = useState("");
  const [nationalIdErr, setNationalIdErr] = useState("");
  const [addressLineErr, setAddressLineErr] = useState("");
  const [addressLinetwoErr, setAddressLinetwoErr] = useState("");
  const [latitudeErr, setLatitudeErr] = useState("");
  const [longitudeErr, setLongitudeErr] = useState("");
  const saveRto = async () => {
    if (/^\s|\s$/.test(addRtoAddress.values.receiverName)) {
      setNameErr("Receiver Name cannot contain leading or trailing spaces");
    } else if (/\s/g.test(addRtoAddress.values.nationalId)) {
      setNationalIdErr(
        "National Id cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/^\s|\s$/.test(addRtoAddress.values.addressLine1)) {
      setAddressLineErr(
        "Addressline 1 cannot contain leading or trailing spaces"
      );
    } else if (/^\s|\s$/.test(addRtoAddress.values.addressLine2)) {
      setAddressLinetwoErr(
        "Addressline 2 cannot contain leading or trailing spaces"
      );
    } else if (/\s/g.test(addRtoAddress.values.latitude)) {
      setLatitudeErr(
        "Latitude cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addRtoAddress.values.longitude)) {
      setLongitudeErr(
        "Longitude cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setNameErr("");
      setNationalIdErr("");
      setAddressLineErr("");
      setAddressLinetwoErr("");
      setLatitudeErr("");
      setLongitudeErr("");
      addRtoAddress?.handleSubmit();
    }
  };
  useEffect(() => {
    if (phoneNumberRegex === null) {
      setError("");
      return;
    }
    const regex = new RegExp(phoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addRtoAddress.values.phoneNumber &&
      !regex.test(
        addRtoAddress.values.phonecodeprefix + addRtoAddress.values.phoneNumber
      )
    ) {
      // If it doesn't match, set the error message
      setError("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setError("");
    }
  }, [addRtoAddress.values.phoneNumber, phoneNumberRegex]);
  useEffect(() => {
    if (alternatephoneNumberRegex === null) {
      setAlternatePhoneError("");
      return;
    }
    const regex = new RegExp(alternatephoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addRtoAddress.values.alternativePhone &&
      !regex.test(
        addRtoAddress.values.phonecodeprefix +
          addRtoAddress.values.alternativePhone
      )
    ) {
      // If it doesn't match, set the error message
      setAlternatePhoneError("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setAlternatePhoneError("");
    }
  }, [addRtoAddress.values.alternativePhone, alternatephoneNumberRegex]);
  useEffect(() => {
    params?.id && getData();
  }, [params?.id]);
  useEffect(() => {
    const mappedFields = Object.keys(addRtoAddress.values).reduce(
      (acc, field) => {
        acc[field] = data?.[field] || "";
        return acc;
      },
      {}
    );
    // Set form field values based on data
    addRtoAddress.setValues((prevValues) => ({
      ...prevValues,
      ...mappedFields,
    }));
  }, [data]);
  useEffect(() => {
    const { phonecodeprefix, phoneNumber } = addRtoAddress.values;
    if (phonecodeprefix && phoneNumber.startsWith(phonecodeprefix)) {
      const updatedPhone = phoneNumber.replace(phonecodeprefix, '');
      addRtoAddress.setFieldValue("phoneNumber", updatedPhone);
      addRtoAddress.setFieldValue("alternativePhone", updatedPhone);
    }
  }, [addRtoAddress.values.phonecodeprefix, addRtoAddress.values.phoneNumber]);
  const getData = async () => {
      const data = await getRtoaddressbyId(params.id);
      setData(data?.data?.data);
      let resp = await getCountryArray(data?.data?.data.countryCode3);
      addRtoAddress.setFieldValue(
        "phonecodeprefix",
        resp?.data?.data?.phoneCodePrefix
          ? resp?.data?.data?.phoneCodePrefix
          : ""
      );
      setphoneNumberRegex(resp?.data?.data?.phoneNumberRegex);
      setbillingArrayData(data?.data?.data);
      const cityData = await getCountryByCity(data?.data?.data?.countryCode3);
      console.log("citydata",cityData)
      let paramslink = `?page=${0}&size=${30}&name=${data?.data?.data?.countryCode3}`;
      // let testCity = await getCities(paramslink);
      const districtData = await getDistrictbyCode(
        data?.data?.data?.countryCode3,
        cityData?.data?.data[0].regionCode,
        data?.data?.data?.cityCode
      );
  };
  return (
    <div className="row">
      <div className="borderFrame">
        <div className="borderFrameTitle">RTO Address</div>
        <div className="row mt_-30px">
          <div className="col-md-4">
            <div className="col-md-11" style={{ padding: "12px" }}>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Receiver Name:
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="Receiver Name"
                    className="mainInput"
                    name="name"
                    value={addRtoAddress.values.receiverName}
                    onChange={(e) => {
                      addRtoAddress.setFieldValue(
                        "receiverName",
                        e.target.value
                      );
                      setNameErr("");
                    }}
                    autoComplete="off"
                  />
                </div>
                {nameErr ? <p className="requiredText">{nameErr}</p> : null}
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Country:
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                </label>
                <div className="col-md-12">
                  <SearchInput
                    id="country"
                    label="country"
                    name="country"
                    isClearable={true}
                    options={countriesData?.data?.data?.content || []}
                    getOptionLabel={(country) => country.name}
                    getOptionValue={(country) => country.isoCode3}
                    isSearchable={true}
                    value={countriesData?.data?.data?.content?.find(
                      (country) =>
                        country.isoCode3 === addRtoAddress.values.countryCode3
                    )}
                    handleChange={async (selectedOption) => {
                      try {
                        addRtoAddress.setFieldValue("cityCode", "");
                        addRtoAddress.setFieldValue("districtCode", "");
                        addRtoAddress.setFieldValue(
                          "countryCode3",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                        //call city based on country selected
                        const response = await getCountryByCity(
                          selectedOption.isoCode3
                        );
                        let countryParam = `?page=${0}&size=20&isoCode3=${
                          selectedOption.isoCode3
                        }`;
                        let resp = await getCountryArray(countryParam);
                        addRtoAddress.setFieldValue(
                          "phonecodeprefix",
                          resp?.data?.data?.content[0].phoneCodePrefix
                            ? resp?.data?.data?.content[0]?.phoneCodePrefix
                            : ""
                        );
                        setIsPhonecodeprefix(!!selectedOption);
                        setphoneNumberRegex(
                          resp?.data?.data?.content[0].phoneNumberRegex
                        );
                        setAlternatephoneNumberRegex(
                          resp?.data?.data?.content[0].phoneNumberRegex
                        );
                      } catch (error) {
                        console.error("Error fetching region data:", error);
                      }
                    }}
                    placeholder="Select Country"
                  />
                  {addRtoAddress.touched.countryCode3 &&
                  addRtoAddress.errors.countryCode3 ? (
                    <p className="requiredText">
                      {addRtoAddress.errors.countryCode3}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  City:
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                </label>
                <div className="col-md-12">
                  <SearchInput
                    id="cityCode"
                    label="cityCode"
                    name="cityCode"
                    isClearable={true}
                    options={citybyCountyData?.data?.data || []}
                    getOptionLabel={(city) => city.name}
                    getOptionValue={(city) => city.code}
                    value={
                      addRtoAddress.values.cityCode
                        ? citybyCountyData?.data?.data?.find(
                            (city) =>
                              city.code === addRtoAddress.values.cityCode
                          )
                        : null
                    }
                    handleChange={async (selectedOption) => {
                      addRtoAddress.setFieldValue("districtCode", "");
                      addRtoAddress.setFieldValue(
                        "cityCode",
                        selectedOption ? selectedOption.code : ""
                      );
                      const districtData = await getDistrictbyCode(
                        selectedOption.countryIsoCode3,
                        selectedOption.regionCode,
                        selectedOption.code
                      );
                    }}
                    isSearchable={true}
                    placeholder="Select City"
                  />
                  {addRtoAddress.touched.cityCode &&
                  addRtoAddress.errors.cityCode ? (
                    <p className="requiredText">
                      {addRtoAddress.errors.cityCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  District:
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                </label>
                <div className="col-md-12">
                  <SearchInput
                    id="districtCode"
                    label="districtCode"
                    name="districtCode"
                    isClearable={true}
                    options={districtbyCode || []}
                    getOptionLabel={(district) => district.name}
                    getOptionValue={(district) => district.code}
                    value={
                      districtbyCode.find(
                        (district) =>
                          district.code === addRtoAddress.values.districtCode
                      ) || null
                    }
                    handleChange={(selectedOption) =>
                      addRtoAddress.setFieldValue(
                        "districtCode",
                        selectedOption ? selectedOption.code : ""
                      )
                    }
                    isSearchable={true}
                    placeholder="Select District"
                  />
                  {addRtoAddress.touched.districtCode &&
                  addRtoAddress.errors.districtCode ? (
                    <p className="requiredText">
                      {addRtoAddress.errors.districtCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Addressline 1:
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="AddressLine 1"
                    className="mainInput"
                    name="addressLine1"
                    value={addRtoAddress.values.addressLine1}
                    onChange={(e) => {
                      addRtoAddress.setFieldValue(
                        "addressLine1",
                        e.target.value
                      );
                      setAddressLineErr("");
                    }}
                  />
                  {addRtoAddress.touched.addressLine1 &&
                  addRtoAddress.errors.addressLine1 &&
                  addressLineErr == "" ? (
                    <p className="requiredText">
                      {addRtoAddress.errors.addressLine1}
                    </p>
                  ) : null}
                  {addressLineErr !== "" ? (
                    <p className="requiredText">{addressLineErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Addressline 2:
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="AddressLine 2"
                    className="mainInput"
                    name="addressLine2"
                    value={addRtoAddress.values.addressLine2}
                    onChange={(e) => {
                      addRtoAddress.setFieldValue(
                        "addressLine2",
                        e.target.value
                      );
                      setAddressLinetwoErr("");
                    }}
                  />
                </div>
                {addressLinetwoErr !== "" ? (
                  <p className="requiredText">{addressLinetwoErr}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-11" style={{ padding: "12px" }}>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  National Id:
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="National Id"
                    className="mainInput"
                    name="name"
                    value={addRtoAddress.values.nationalId}
                    onChange={(e) => {
                      addRtoAddress.setFieldValue("nationalId", e.target.value);
                      setNationalIdErr("");
                    }}
                    autoComplete="off"
                  />
                </div>
                {(addRtoAddress.touched.nationalId &&
                  addRtoAddress.errors.nationalId && nationalIdErr == "")? (
                    <p className="requiredText">
                      {addRtoAddress.errors.nationalId}
                    </p>
                  ) : null}
                {nationalIdErr !== "" ? (
                  <p className="requiredText">{nationalIdErr}</p>
                ) : null}
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                  Phone Number:
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                </label>
                <div className="col-md-3">
                  <div className="GrpinputOrders">
                    <input
                      className="mainInput"
                      name="phonecodeprefix"
                      value={addRtoAddress.values.phonecodeprefix}
                      autoComplete="off"
                      disabled={isPhonecodePrefix}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="GrpinputOrders">
                    <input
                      type="text"
                      placeholder="Phone Number"
                      className="mainInput"
                      name="phone"
                      autoComplete="off"
                      value={addRtoAddress.values.phoneNumber}
                      onChange={(e) => {
                        addRtoAddress.setFieldValue(
                          "phoneNumber",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  {addRtoAddress?.touched?.phoneNumber &&
                  addRtoAddress?.errors?.phoneNumber &&
                  error === "" ? (
                    <p className="requiredText">
                      {addRtoAddress?.errors?.phoneNumber}
                    </p>
                  ) : null}
                  {error !== "" && <p className="requiredText">{error}</p>}
                </div>
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_14 menacingCloudsText fontWeight500">
                  Alternate Phone Number:
                </label>
                <div className="col-md-3">
                  <div className="GrpinputOrders">
                    <input
                      className="mainInput"
                      name="phonecodeprefix"
                      value={addRtoAddress.values.phonecodeprefix}
                      autoComplete="off"
                      disabled={isPhonecodePrefix}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="GrpinputOrders">
                    <input
                      type="text"
                      placeholder="Alternate Phone Number"
                      className="mainInput"
                      name="phone"
                      autoComplete="off"
                      value={addRtoAddress.values.alternativePhone}
                      onChange={(e) => {
                        addRtoAddress.setFieldValue(
                          "alternativePhone",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  {addRtoAddress?.touched?.pickupAddress?.alternatePhone &&
                  addRtoAddress?.errors?.pickupAddress?.alternatePhone &&
                  alternatePhoneerror === "" ? (
                    <p className="requiredText">
                      {addRtoAddress?.errors?.pickupAddress?.alternatePhone}
                    </p>
                  ) : null}
                  {alternatePhoneerror !== "" && (
                    <p className="requiredText">{alternatePhoneerror}</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                      Location:
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Latitude"
                            className="mainInput"
                            name="latitude"
                            autoComplete="off"
                            value={addRtoAddress.values.latitude}
                            onChange={(e) => {
                              addRtoAddress.setFieldValue(
                                "latitude",
                                e.target.value
                              );
                              setLatitudeErr("");
                            }}
                          />
                        </div>
                        {addRtoAddress.touched.latitude &&
                        addRtoAddress.errors.latitude &&
                        latitudeErr == "" ? (
                          <p className="requiredText">
                            {addRtoAddress.errors.latitude}
                          </p>
                        ) : null}
                        {latitudeErr !== "" ? (
                          <p className="requiredText">{latitudeErr}</p>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <div className="GrpinputOrders">
                          <input
                            type="text"
                            placeholder="Longitude"
                            className="mainInput"
                            name="longitude"
                            autoComplete="off"
                            value={addRtoAddress.values.longitude}
                            onChange={(e) => {
                              addRtoAddress.setFieldValue(
                                "longitude",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        {addRtoAddress.touched.longitude &&
                        addRtoAddress.errors.longitude &&
                        longitudeErr == "" ? (
                          <p className="requiredText">
                            {addRtoAddress.errors.longitude}
                          </p>
                        ) : null}
                        {longitudeErr !== "" ? (
                          <p className="requiredText">{longitudeErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <label className="fontSize14 mb_12 marginR10 mt_14 menacingCloudsText fontWeight500">
                  Postal Code:
                </label>
                <div className="col-md-6">
                  <div className="GrpinputOrders">
                    <input
                      type="text"
                      placeholder="Postal Code"
                      className="mainInput"
                      name="name"
                      value={addRtoAddress.values.postalCode}
                      onChange={(e) => {
                        addRtoAddress.setFieldValue(
                          "postalCode",
                          e.target.value
                        );
                      }}
                      autoComplete="off"
                    />
                  </div>
                  {addRtoAddress.touched.postalCode &&
                  addRtoAddress.errors.postalCode ? (
                    <p className="requiredText">
                      {addRtoAddress.errors.postalCode}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(roleHandler(role, "AONE_CRM.write") ||
        roleHandler(role, "AONE_CRM.admin") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
        roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
        roleHandler(role, "AONE_CONFIGS.write") ||
        roleHandler(role, "AONE_CONFIGS.admin") ||
        roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
        roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) && (
        <div className="row">
          <div className="displayFlex ml_42 alignItemCenter gap_16 mt_12">
            <button type="button" className="blue_btn" onClick={saveRto}>
              Save
            </button>
            <button className="cancel_btn">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Rto;
