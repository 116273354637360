import React, {
    useState,
    useEffect,
  } from "react";
  import PaginationTable from "../../../components/Tables/paginationTable";
  import { useTripStore } from "../../../store/TripMgt/TripStore";
import { useParams } from "react-router";
import syncicon from "../../../..//src/assets/img/sync.svg";
  const Tracking = () => {
    const params = useParams();
    const [newPage, setNewPage] = useState(0);
    const [size, setSize] = useState(20);
  const getTripEvents = useTripStore((state) => state?.getTripEvents);
    const [trackingData, setTrackingData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [sortField, setSortField] = useState("createdAt");
    const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
    const [deleteHandler, setDeleteHandler] = useState(false);
    const [statusHandler, setStatusHandler] = useState(false);
    const [sortBy, setSortBy] = useState("-updatedAt");
    const [isDelete, setIsDelete] = useState("");
    const getData = async () => {
        let paramslink = `?page=${newPage}&size=${size}`;
        const data = await getTripEvents(params?.id,paramslink);
        setTotalCount(data.data.data.totalElements);
        setTrackingData(data.data.data.content);
      };
      const syncronizeRoles = async () => {
        setNewPage(0);
        getData();
      };
      useEffect(() => {
        getData();
      }, [
        newPage,
        sortBy,
        size,
        sortField
      ]);
    return (
      <div>
        <div>
        <div className="displayFlex alignItemCenter justifyContent_end">
          <div className="displayFlex alignItemCenter justifyContent_end mb_16">
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
          </div>
        </div>
          <PaginationTable
            tableData={trackingData}
            tbl={"TripTracking"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setNewPage={setNewPage}
            size={size}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSize={setSize}
            totalCount={totalCount}
            setSortField={setSortField}
            sortField={sortField}
            statusHandler={statusHandler}
            setStatusHandler={setStatusHandler}
            isDelete={isDelete}
            setIsDelete={setIsDelete}
          />
        </div>
      </div>
    );
  };
  
  export default Tracking;
  