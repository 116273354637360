import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../assets/img/download_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../store/AoneStore";
import { useNotificationStore } from "../../store/NotificationsMgt/NotificationStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import { roleHandler } from "../../helper/roleHandler";
import { UserContext } from "../../helper/ProtectedRoute";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import CusModal from "../../components/modal/CusModal";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../../src/assets/img/dotsbold.svg";
import RulesModal from "./RulesModal";
const SmsRules = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const prevent = useRef(false);
  const [title, setTitle] = useState("");
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code, setCode] = useState("");
  const [event, setEvent] = useState("");
  const [country,setCountry] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [state] = useContext(UserContext);
  const [templatebyCode, setTemplatebyCode] = useState([]);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("createdAt");
  const getRules = useNotificationStore((state) => state?.getRules);
  const exportRules = useNotificationStore((state) => state?.exportRules);
  const templateList = useNotificationStore((state) => state?.templateList);
  const getDataType = useNotificationStore((state) => state?.getDataType);
  const getField = useNotificationStore((state) => state?.getField);
  const getOperator = useNotificationStore((state) => state?.getOperator);
  const getRuleStatus = useNotificationStore((state) => state?.getRuleStatus);
  const getRulesbyId = useNotificationStore((state) => state?.getRulesbyId);
  const getConditionLogic = useNotificationStore(
    (state) => state?.getConditionLogic
  );
  const dataTypeList = useNotificationStore((state) => state?.dataTypeList);
  const fieldList = useNotificationStore((state) => state?.fieldList);
  const operatorList = useNotificationStore((state) => state?.operatorList);
  const ruleStatusList = useNotificationStore((state) => state?.ruleStatusList);
  const conditionLogicList = useNotificationStore(
    (state) => state?.conditionLogicList
  );
  const eventStatusList = useNotificationStore(
    (state) => state?.eventStatusList
  );
  const getEventStatus = useNotificationStore((state) => state?.getEventStatus);
  const setglobalHubCode = useAoneStore((state) => state?.setglobalHubCode);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  const [column, SetCoulmn] = useState([
    "Template",
    "Event",
    "Status",
    "Country",
    "Created by",
    "Created at",
    "Updated by",
    "Updated at",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
  ]);
  const handleEdit = (code) => {
    setTitle("Edit SMS Rule");
    setShowModal(true);
    getRuleonEdit(code);
  };
  const [rulebyCode, setRulebyCode] = useState([]);
  const getRuleonEdit = async (code) => {
    const res = await getRulesbyId(code);
    setRulebyCode(res?.data?.data);
  };
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (country) {
      params += `&countryCode=${country}`;
    }
    if (event) {
      params += `&eventCode=${event}`;
    }
    const data = await getRules(params);
    setTotalCount(data?.data?.data?.totalElements);
    setTemplateData(data?.data?.data?.content);
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (country) {
      params += `&countryCode=${country}`;
    }
    if (event) {
      params += `&eventCode=${event}`;
    }
    try {
      const res = await exportRules(params);
      if (res.status == 200) {
        fileDownload(res.data, `SMS Rules.xlsx`);
      }
    } catch (err) {}
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    tab,
    editPage,
    sortField,
    location,
    country,
    saveHandler,
    code,
    event,
    ispostAdded,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.data?.data?.content?.length == undefined
    ) {
      filterCountries(paramslink);
    }
    if (
      dataTypeList?.data?.data?.length < 1 ||
      dataTypeList?.data?.data?.length == undefined
    ) {
      getDataType();
    }
    if (
      fieldList?.data?.data?.length < 1 ||
      fieldList?.data?.data?.length == undefined
    ) {
      getField();
    }
    if (
      operatorList?.data?.data?.length < 1 ||
      operatorList?.data?.data?.length == undefined
    ) {
      getOperator();
    }
    if (
      ruleStatusList?.data?.data?.length < 1 ||
      ruleStatusList?.data?.data?.length == undefined
    ) {
      getRuleStatus();
    }
    if (
      conditionLogicList?.data?.data?.length < 1 ||
      conditionLogicList?.data?.data?.length == undefined
    ) {
      getConditionLogic();
    }
    if (
      eventStatusList?.data?.data?.length < 1 ||
      eventStatusList?.data?.data?.length == undefined
    ) {
      getEventStatus();
    }
  }, []);
  return (
    <div>
      <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          <div className="displayFlex justifyContent_end mleft_5">
            <Row>
            <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        setCountry(
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isReactselectOpen}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      customStyles={{ control: { width: "200px" } }}
                      className={
                        country?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Event:
                </label>
                <SearchInput
                  id="countryCode"
                  label="countryCode"
                  name="countryName"
                  options={eventStatusList?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  handleChange={(selectedOption) => {
                    setEvent(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Event"
                  customStyles={{ control: { width: "200px" } }}
                  className={
                    event?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="margin_top40">
            <div className="displayFlex gap_12">
              <button
                className="filterBtn"
                title={"Export Rules"}
                onClick={() => exportToExcel()}
              >
                <span>
                  <img src={downloadicon} className="h18" />
                </span>
              </button>
              <button
                className="filterBtn plus_icon"
                title="Sync"
                onClick={() => syncronizeRoles()}
              >
                <span>
                  <img src={syncicon} className="h18" />
                </span>
              </button>
              {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.write") ||
                  roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.admin")) && (
              <button
                className="filterBtn plus_icon"
                title="Add"
                tabIndex="-1"
                onClick={() => {
                  setShowModal(true);
                  setTitle("Create new SMS rule");
                }}
              >
                +
              </button>)}
              <div ref={ref} className="customFilter">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={DotboldIcon} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <ul className="listColumn cursorPointer">
                        {column?.map((d, i) => (
                          <li key={d}>
                            <div className="smallCheckbox mb_4">
                              <input
                                id={`product${i}`}
                                type="checkbox"
                                checked={!checkedCol.includes(d)}
                              />
                              <label
                                htmlFor={`product${i}`}
                                onClick={() => handleSelect(d)}
                              >
                                {d}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <PaginationTable
          tableData={templateData}
          tbl={"MessageRules"}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          restrict={checkedCol}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          handleRuleEdit={handleEdit}
          setNewPage={setNewPage}
          size={size}
          isMenuOpen={isMenuOpen}
          setSize={setSize}
          totalCount={totalCount}
          sortField={sortField}
          setSortField={setSortField}
        />
        <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={"addnewvehcilepop"}
        >
          <RulesModal
            onSuccess={handleSuccess}
            onError={handleError}
            showModal={showModal}
            setShowModal={setShowModal}
            title={title}
            rulebyCode={rulebyCode}
          />
        </CusModal>
      </div>
    </div>
  );
};

export default SmsRules;
