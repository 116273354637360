import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
import { useAoneStore } from "../../store/AoneStore";
import { useOrderStore } from "../../store/OrderMgt/OrderStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const SequenceModal = ({
  onSuccess,
  setShowModal,
  title,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const postCustomerSequencer = useOrderStore((state) => state?.postCustomerSequencer);
  const [selectedHubOption, setSelectedHubOption] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const addCustomersequence = useFormik({
    initialValues: {
      customerAccount: "",
      sequence: "",
      returnCreatedOrder: false,
    },
    validationSchema: Yup.object({
      customerAccount: Yup.string().trim().required("Customer is required"),
      sequence: Yup.string().required("Sequence is required")
      .matches(/^\d{3}$/, "Sequence must be exactly 3 digits"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postCustomerSequencer(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Customer sequence Added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key}: ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} : ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    },
  });
  const [page, setPage] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const addCustomerSequence = (initialValues) => {
      addCustomersequence?.handleSubmit();
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleVendors">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Customer
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                          <AsyncSearchInput
                            id="customerAccount"
                            label="customerAccount"
                            name="customerAccount"
                            value={selectedHubOption}
                            loadMoreOptions={loadMoreOptionsGroups}
                            shouldLoadMore={shouldLoadMore}
                            getOptionLabel={(zone) => zone.label}
                            getOptionValue={(zone) => zone.value}
                            onChange={(selectedHubOption) => {
                              setSelectedHubOption(selectedHubOption);
                              addCustomersequence.setFieldValue(
                                "customerAccount",
                                selectedHubOption ? selectedHubOption.value : ""
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                            placeholder="Customer"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuStyle={{ zIndex: 9999 }}
                          />
                  </div>
                  {addCustomersequence.touched.customerAccount &&
                  addCustomersequence.errors.customerAccount ? (
                    <p className="requiredText">
                      {addCustomersequence.errors.customerAccount}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Sequence
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                          <input
                            type="text"
                            value={addCustomersequence?.values?.sequence}
                            name="sequence"
                            className="mainInput"
                            onChange={(event)=>addCustomersequence.setFieldValue("sequence",event.target.value)}
                          />
                  </div>
                  {addCustomersequence.touched.sequence &&
                  addCustomersequence.errors.sequence ? (
                    <p className="requiredText">
                      {addCustomersequence.errors.sequence}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
            <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <div className="smallCheckbox">
                    <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                    <input
                      type="checkbox"
                      id="Return created order"
                      style={{ marginLeft: "5px" }}
                      checked={addCustomersequence.values.returnCreatedOrder}
                      onChange={() => {
                        addCustomersequence.setFieldValue(
                          "returnCreatedOrder",
                          !addCustomersequence.values.returnCreatedOrder
                        );
                        if (!addCustomersequence.values.returnCreatedOrder) {
                          addCustomersequence.setFieldValue(
                            "returnCreatedOrder",
                            true
                          );
                        }
                      }}
                    />
                    <label
                      className="margintop7"
                      htmlFor="Return created order"
                    >
                      Return created order
                    </label>
                  </div>
                  </div>
                </div>
              </div>
              </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={addCustomerSequence}>
          Create
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default SequenceModal;
