import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../assets/img/template_icon.svg";
import importIcon from "../../assets/img/importIcon.svg";
import downloadicon from "../../assets/img/download_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../store/AoneStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import * as XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
const Areas = () => {
  const location = useLocation();
  const [importError,setImportError] = useState([])
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [size, setSize] = useState(20);
  const [areaData, setAreadata] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const getAreas = useAoneStore((state) => state?.getAreas);
  const getEmployeebyId = useAoneStore((state) => state?.getEmployeebyId);
  const exportAreas = useAoneStore((state) => state?.exportAreas);
  const setglobalEmployeeCode = useAoneStore(
    (state) => state?.setglobalEmployeeCode
  );
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setShow(false);
  };
  const getAreasTemplate = useAoneStore((state) => state?.getAreasTemplate);
  const importAreas = useAoneStore((state) => state?.importAreas);
  const getHubs = useAoneStore((state) => state?.getHubs);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const getRegions = useAoneStore((state) => state.getRegions);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const [sortBy, setSortBy] = useState("createdAt");
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const [isDelete, setIsDelete] = useState("");
  const [modalHandler, setModalHandler] = useState(false);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const downloadTemplate = async () => {
    try {
      const res = await getAreasTemplate();
      if (res?.status == 200) {
        fileDownload(res.data, `Area Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleMenuOpen = () => {
    setReactSelect(true);
  };

  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            importAreas(globalEmployeeCode,formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "IMPORT_ERROR") {
                  setImportError(response?.response?.data?.errors)
                  handleShow()
                  fileInput.value = "";
                } else if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (country) {
      params += `&countryIsoCode3=${country}`;
    }
    if (region) {
      params += `&regionCode=${region}`;
    }
    try {
      const res = await exportAreas(globalEmployeeCode,params);
      if (res.status == 200) {
        fileDownload(res.data, `Areas.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (country) {
      params += `&countryIsoCode3=${country}`;
    }
    if (region) {
      params += `&regionCode=${region}`;
    }
    const data = await getAreas(globalEmployeeCode,params);
    setTotalCount(data.data.data.totalElements);
    setAreadata(data.data.data.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    location,
    ispostAdded,
    saveHandler,
    isDelete,
    region,
    country,
    globalEmployeeCode,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (countriesData?.data?.data?.content?.length === undefined) {
      getCountries(paramslink);
    }
    if (regionsData?.data?.data?.content?.length === undefined) {
      getRegions(paramslink);
    }
    if (hubsData?.data?.data?.content?.length === undefined) {
      getHubs(paramslink);
    }
  }, []);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Country:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <SearchInput
                      name="country"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        setCountry(
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isReactselectOpen}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      customStyles={{ control: { width: "160px" } }}
                      className={
                        country?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Region:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <SearchInput
                      name="region"
                      options={regionsData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        setRegion(selectedOption ? selectedOption.code : "");
                      }}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Region"
                      customStyles={{ control: { width: "160px" } }}
                      className={
                        region?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                {(roleHandler(role, "AONE_CRM.admin") ||
                  roleHandler(role, "AONE_CRM.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.admin") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.admin")) && (
                  <>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon ml_10"
                      title={"Download"}
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div title={"Import"}>
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                )}
                <button
                  className="filterBtn"
                  title={"Export"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_CRM.admin") ||
                  roleHandler(role, "AONE_CRM.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.admin") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => setAddModalHandler(!addModalHandler)}
                    tabIndex="-1"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={areaData}
            tbl={"Area"}
            title={"Edit Area"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Add Area"}
          editHandler={editHandler}
          employeeCode={globalEmployeeCode}
          setEditHandler={setEditHandler}
        />)}
      </div>
      {show && (
      <Modal
        show={show}
        onHide={() => handleCancel()}
        className="addnewpopup"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">
            Import Exceptions
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="error-message">
          <p><span>!</span>Failed to import below records</p>
          </div>
          <form>
            <div className="row">
              <div className="table_wrapper overflowScroll mb_12 showTenRows">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Field</th>
                      <th>Resource Id</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importError?.map(
                      (err, id) => (
                        <tr
                          key={id}
                        >
                          <td>{id + 1}</td>
                          <td>{err.field}</td>
                          <td>{err.key}</td>
                          <td>{err.msg}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center">
            <div>
              <button className="cancel_btn" onClick={() => handleCancel()}>
                Dismiss
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>)}
    </div>
  );
};

export default Areas;
