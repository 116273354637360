import React,{ useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import searchicon from "../../assets/img/searchicon.svg"
import PaginationTable from '../Tables/paginationTable';
import { useAoneStore } from '../../store/AoneStore';

const AssignerModal = ({ modalHandler, setModalHander, title }) => {

    const handleClose = () => {
        setModalHander(!modalHandler)
    }

    const getRoles = useAoneStore((state) => state?.getRoles)
    const getUsers = useAoneStore((state) => state?.getUsers)
    const getGroups = useAoneStore((state) => state?.getGroups) 

    const [ roles, setRoles ] = useState([])
    const [ users, setUsers ] = useState([])
    const [ groups, setGroups ] = useState([])

    const [ name, setName ] = useState("")
    const [ newPage, setNewPage ] = useState(0)
    const [ size, setSize ] = useState(5)
    const [ totalCount, setTotalCount ] = useState(0)

    const getData = async () => {
        if( title == "Assign Role" ){
            const params = `?page=${newPage}&size=${size}&name=${name}`
            const data = await getRoles(params)
            setTotalCount(data?.data?.data?.totalElements)
            setRoles(data?.data?.data?.content)
        }else if( title == "Assign User" ){
            const params = `?page=${newPage}&size=${size}&name=${name}`
            const data = await getUsers(params)
            setTotalCount(data?.data?.data?.totalElements)
            setUsers(data?.data?.data?.content)
        }else{
            const params = `?page=${newPage}&size=${size}&name=${name}`
            const data = await getGroups(params)
            setTotalCount(data?.data?.data?.totalElements)
            setGroups(data?.data?.data?.content)
        }
        // console.log("COUNTS", totalCount);
    }

    useEffect(() => {
        const delayFn = setTimeout(() => {
        getData();
        }, 1500)  
        return () => clearTimeout(delayFn)
      }, [name])
  
    useEffect(()=>{
        getData();
    },[newPage,size])

  return (
    <Modal
    show={modalHandler}
    onHide={handleClose}
    className="addnewpopup"
    backdrop="static"
    centered
>
    <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
    </Modal.Header>
    <div className="modelScroll">
        <Modal.Body>
            <div>
                <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                <div className='displayFlex justifyContent_end'>
                    <div className='displayFlex alignItemCenter InputGroup '>
                        <span className='inputsearch'>
                        <img src={searchicon} className='turnBlack h18'/>
                        </span>
                        <input type='text' placeholder='Search' className='Maininput w280'
                         value={name} onChange={(e)=>setName(e.target.value)}/>
                    </div>
                </div>
                </div>
                <PaginationTable tableData={ title == "Assign Role" ? roles : title == "Assign User" ? users : groups} 
                    tbl={title == "Assign Role" ? "Assign_Roles" : title == "Assign User" ? "Assign_Users" : "Assign_Groups"} 
                    newPage={newPage} deleteHandler={modalHandler} setDeleteHandler={setModalHander}
                    setNewPage={setNewPage} size={size} setSize={setSize} totalCount={totalCount}/> 
            </div>    
        </Modal.Body>
    </div>
    <Modal.Footer>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
            <button className="cancel_btn" onClick={()=>handleClose()}>
                Cancel
            </button>
        </div>    
    </Modal.Footer>
    </Modal>
  )
}

export default AssignerModal