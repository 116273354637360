import React, { useEffect,useState,useRef } from "react";


const AddnewSla = ({
  initialData,
  handleInputChange,
  title,
  enableEdit
}) => {

    const serviceTypeRef = useRef("")
    const slaHoursRef = useRef("")
    const countryRef = useRef("")
    const orgincityRef = useRef("")
    const destcityRef = useRef("")
    const[err,setErr] = useState({
      required: false,
      length:false
    })
    const[slaerr,setSlaErr] = useState({
      required: false,
      length:false
    })
    const[countryerr,setCountryErr] = useState({
      required: false,
      length:false
    })
    const[orginerr,setOriginErr] = useState({
      required: false,
      length:false
    })
    const[destierr,setDestiErr] = useState({
      required: false,
      length:false
    })




  return (
    <>
        <div>
        <div className="mb_24">
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Service Type:</label>
          <input
            type="text"
            className="washMe_btn"
            placeholder="Service Type"
            value={initialData?.serviceType}
            ref={serviceTypeRef}
            onChange={(e) =>{
              handleInputChange("serviceType", e, initialData.id)
              if (serviceTypeRef.current.value.length > 1) {
                setErr(prevState => ({
                  ...prevState,
                  required : false
                }))
              }}
            }
            onBlur={()=>{
              if (serviceTypeRef.current.value.length == 0) {
                setErr(prevState => ({
                  ...prevState,
                  required : true
                }))
              }
            }}
            disabled={(title == "View SLA" && enableEdit == false) ? true:false}
          />
            {
              err.required == true && (
                <p className="requiredText">Service Type Required</p>
              )
            }
          </div>
          <div className="mb_24">
            <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Customer Code:</label>
            <input
              type="text"
              className="washMe_btn"
              placeholder="Customer Code"
              value={initialData?.customerAccount}
              onChange={(e) =>{
                handleInputChange("customerAccount", e, initialData.id)
              }
              }
              disabled={(title == "View SLA" && enableEdit == false) ? true:false}
            />
          </div>
          <div className="mb_24">
            <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">SLA Hours:</label>
            <input
              type="number"
              className="washMe_btn"
              placeholder="SLA Hours"
              ref={slaHoursRef}
              value={initialData?.slaMinutes}
              onChange={(e) => {
                handleInputChange("slaMinutes", e, initialData.id)
                if (slaHoursRef.current.value > 1) {
                  setSlaErr(prevState => ({
                    ...prevState,
                    required : false
                  }))
                }
                if (slaHoursRef.current.value < 24) {
                  setSlaErr(prevState => ({
                    ...prevState,
                    length : false
                  }))
                }
              }}
              onBlur={()=>{
                if (slaHoursRef.current.value < 1) {
                  setSlaErr(prevState => ({
                    ...prevState,
                    required : true
                  }))
                }
                if (slaHoursRef.current.value > 24) {
                  setSlaErr(prevState => ({
                    ...prevState,
                    length : true
                  }))
                }
              }}
              disabled={(title == "View SLA" && enableEdit == false) ? true:false}
              max={24}
            />
             {
              slaerr.required == true && (
                <p className="requiredText">SLA Hours Required</p>
              )
            }
             { slaerr.length == true && (
                <p className="requiredText">SLA Hours Should Not be above 24</p>
              )}
          </div>
          <div className="mb_24">
            <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Country:</label>
            <input
              type="text"
              className="washMe_btn"
              placeholder="country"
              value={initialData?.country}
              ref={countryRef}
              onChange={(e) => {
                handleInputChange("country", e, initialData.id)
                if (countryRef.current.value.length > 1) {
                  setCountryErr(prevState => ({
                    ...prevState,
                    required : false
                  }))
                }
              }}
              onBlur={()=>{
                if (countryRef.current.value.length == 0) {
                  setCountryErr(prevState => ({
                    ...prevState,
                    required : true
                  }))
                }
              }}
              disabled={(title == "View SLA" && enableEdit == false) ? true:false}
            />
            {
              countryerr.required == true && (
                <p className="requiredText">Country is Required</p>
              )
            }
          </div>
          <div className="mb_24">
            <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Origin City:</label>
            <input
              type="text"
              className="washMe_btn"
              placeholder="Origin City"
              value={initialData?.originCity}
              ref={orgincityRef}
              onChange={(e) =>{
                handleInputChange("originCity", e, initialData.id)
                if (orgincityRef.current.value.length > 1) {
                  setOriginErr(prevState => ({
                    ...prevState,
                    required : false
                  }))
                }
              }}
              onBlur={()=>{
                if (orgincityRef.current.value.length == 0) {
                  setOriginErr(prevState => ({
                    ...prevState,
                    required : true
                  }))
                }
              }}
              disabled={(title == "View SLA" && enableEdit == false) ? true:false}
            />
            {
              orginerr.required == true && (
                <p className="requiredText">Origin City is Required</p>
              )
            }
          </div>
          <div className="mb_24">
            <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">Desitination City:</label>
            <input
              type="text"
              className="washMe_btn"
              placeholder="Destination"
              value={initialData?.destinationCity}
              ref={destcityRef}
              onChange={(e) =>{
                handleInputChange("destinationCity", e, initialData.id)
                if (destcityRef.current.value.length > 1) {
                  setDestiErr(prevState => ({
                    ...prevState,
                    required : false
                  }))
                }
              }}
              onBlur={()=>{
                if (destcityRef.current.value.length == 0) {
                  setDestiErr(prevState => ({
                    ...prevState,
                    required : true
                  }))
                }
              }}
              disabled={(title == "View SLA" && enableEdit == false) ? true:false}
            />
            {
              destierr.required == true && (
                <p className="requiredText">Desitination City is Required</p>
              )
            }
          </div>
        </div>
            </>
  );
};

export default AddnewSla;
