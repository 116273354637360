import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import Pagination from "react-js-pagination";
import CusModal from "../modal/modal";
import OpenModal from "../modal/openModal";
import editicon from "../../../src/assets/img/editicon.svg";
import EyeIcon from "../../assets/img/eye_show.svg";
import PrintIcon from "../../assets/img/printorder.svg";
import DeleteIcon from "../../assets/img/delete_icon.svg";
import assignIcon from "../../assets/img/assignorder_icon.svg";
import cancelIcon from "../../assets/img/cancelorder_icon.svg";
import receiveicon from "../../assets/img/receive.svg";
import ActivateIcon from "../../assets/img/ActiveIcon.svg";
import InactiveIcon from "../../assets/img/InActiveIcon.svg";
import SealIcon from "../../assets/img/SealIcon.svg";
import moveicon from "../../assets/img/move_icon.svg";
import UnsealIcon from "../../assets/img/UnSealIcon.svg";
import MapPinIcon from "../../assets/img/MapPinIcon.svg";
import openEye from "../../assets/img/eye_show.svg";
import exportIcon from "../../assets/img/ExportIcon.svg";
import invoiceIcon from "../../assets/img/invoiceIcon.svg";
import outscanicon from "../../assets/img/outscan.svg";
import PushIcon from "../../assets/img/push_arrow.svg";
import PickupApplyIcon from "../../assets/img/PickuprequestApply_icon.svg"
import externalLink from "../../assets/img/externalLink.svg";
import FetchIcon from "../../assets/img/fetchIcon.svg";
import EditVehicle from "../../assets/img/EditVehicle.svg";
import PickupCancelIcon from "../../assets/img/Pickupcancel.svg";
import downArrowIcon from "../../assets/img/black_arrowDropdown.svg";
import downupIcon from "../../assets/img/black_arrowup.svg";
import jwtDecode from "jwt-decode";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import { useAoneStore } from "../../store/AoneStore";
import { usepickupStore } from "../../store/PickupRequest/pickuprequestStore";
import { useOrderStore } from "../../store/OrderMgt/OrderStore";
import { usevehicleStore } from "../../store/VehicleMgt/VehicleStore";
import { usecourierStore } from "../../store/CourierMgt/courierStore";
import { useDriverStore } from "../../store/DriverMgt/driverStore";
import { useCollectionStore } from "../../store/CollectionsMgt/CollectionStore";
import arrowRight from "../../assets/img/arrowright.svg";
import unassignIcon from "../../assets/img/unassign_icon.svg";
import settleIcon from "../../assets/img/SettleIcon.svg";
import TransactionIcon from "../../assets/img/TransactionIcon.svg";
import SubmitIcon from "../../assets/img/SubmitIcon.svg";
import DeletingModal from "../modal/deletingModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import fileDownload from "js-file-download";
import Resizable from "react-resizable-box";
import SearchInput from "../dropdown/autoCompleteInputComponent";
import SortingIconComponent from "../sorting/SortingIcon";
import "react-resizable/css/styles.css";
import { useFormik } from "formik";
import * as Yup from "yup";
const PaginationTable = ({
  tableData,
  tbl,
  restrict,
  restrictPickupreq,
  updateName,
  tblDataDup,
  submit,
  cancleChange,
  handleDelete,
  setModalHandler,
  modalHandler,
  setSelectReq,
  title,
  newPage,
  setNewPage,
  statusChange,
  SetisActivated,
  isActivated,
  setStatusChange,
  size,
  setSize,
  countriesArray,
  totalCount,
  setSortField,
  sortField,
  deleteHandler,
  setDeleteHandler,
  setShowModalRto,
  setHeader,
  assignCourierModal,
  handleShowTransfer,
  setShowUpload,
  handleRtobyId,
  statusHandler,
  handleVehicleEdit,
  handleAclEdit,
  setStatusHandler,
  seleczone,
  setCollectionsTab,
  setselectZone,
  handleRouteDelete,
  addPickupRequest,
  handleRouteEdit,
  handleRateEdit,
  handleRuleEdit,
  assignAclModal,
  setShowAssignCourier,
  setShowUnAssignCourier,
  isChange,
  SetDeliveryId,
  ischangeRtoTable,
  setDelivPackage,
  setDelivCourier,
  setDelivTitle,
  addDeliveryTask,
  ischangeTable,
  settleCheckbox,
  setSettleCheckboxes,
  setSelectedEmployeeCode,
  selectPickup,
  setCollectionSum,
  setSelectPickup,
  handleShortEdit,
  setSelectOriginHub,
  handleLinehaulsEdit,
  handleTripEdit,
  handleVehEdit,
  latitude,
  handleTemplateEdit,
  longitude,
  currentTimeMillis,
  eventTimeZone,
  selectOrders,
  setShowAssign,
  setSelectOrders,
  setShowPickup,
  setSelectWaybill,
  isChanged,
  setSelectRates,
  selectRates,
  selectAll,
  setShowModal,
  setCourierCode,
  isPush,
  selectAllRto,
  setSelectAllRto,
  setSelectRto,
  setIsPush,
  isDropdownOpen,
  setAclAssignee,
  setIsassign,
  isassign,
  setSettleRto,
  settleRto,
  selectAcl,
  setSelectAcl,
  selectAllAcl,
  setSelectAllAcl,
  settleAcl,
  setSettleAcl,
  ischangeAclTable,
  selectAclNum,
  setSelectAclNum,
  setBagNum,
  countryNameparam,
  setSelectAllCollections,
  selectAllCollections,
  setSelectCollections,
  selectCollections,
  setSelectPickuprequest,
  setPickupTitle,
  setTrackId,
  tier,
  setSelectAll,
  setShowCancel,
  setShowActions,
  SetshowPrCustomer,
  setCusPage,
  setBagName,
  setIndividualCheckboxesBag,
  individualCheckboxesbag,
  setCancelRequestId,
  setCancelCourierId,
  setissaved,
  issaved,
  isDropdownOpens,
  isMenuOpen,
  isDropdownselectOpen,
  isDropdownselectOpenconv,
  isDropdownselectOpencurrency,
  setAclNum,
  setSealNum,
  setShoword,
  handlescanShow,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const shipseyUrl = process.env.REACT_APP_SHIPSEY_URL;
  const aoneUrl = process.env.REACT_APP_AONE_URL;
  const [individualCheckboxes, setIndividualCheckboxes] = useState([]);
  const initialCheckboxes = new Array(tableData?.length).fill(false);
  const deleteWaybillByBagId = useAoneStore(
    (state) => state?.deleteWaybillByBagId
  );
  const assignOrders = useAoneStore((state) => state?.assignOrders);
  const cancelOrders = useAoneStore((state) => state?.cancelOrders);
  const printOrders = useAoneStore((state) => state?.printOrders);
  const pushInvoicebyId = useAoneStore((state) => state?.pushInvoicebyId);
  const pushOracleCustomer = useAoneStore((state) => state?.pushOracleCustomer);
  const downloadInvoiceRequest = useAoneStore(
    (state) => state?.downloadInvoiceRequest
  );
  const printConsignments = useAoneStore((state) => state?.printConsignments);
  const CancelInvoiceRequest = useAoneStore(
    (state) => state?.CancelInvoiceRequest
  );
  const fetchConsignments = useAoneStore((state) => state?.fetchConsignments);
  const getDistricts = useAoneStore((state) => state?.getDistricts);
  const getCities = useAoneStore((state) => state?.getCities);
  const setisEditPickup = useAoneStore((state) => state?.setisEditPickup);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactRatestate = useAoneStore((state) => state?.isReactRatestate);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const isReactdesBulk = useAoneStore((state) => state?.isReactdesBulk);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
  const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
  const showSensitiveFields = useDriverStore(
    (state) => state?.showSensitiveFields
  );
  const cancelConsignments = useAoneStore((state) => state?.cancelConsignments);
  const thirdpartyCouriers = useAoneStore((state) => state?.thirdpartyCouriers);
  const setselectedCourierCode = usecourierStore(
    (state) => state?.setselectedCourierCode
  );
  const selectedCourierCode = usecourierStore(
    (state) => state?.selectedCourierCode
  );
  const applyConsignments = usepickupStore((state) => state?.applyConsignments);
  const activateVehicleDocs = usevehicleStore(
    (state) => state?.activateVehicleDocs
  );
  const deactivateVehicleDocs = usevehicleStore(
    (state) => state?.deactivateVehicleDocs
  );
  const activateDrivers = useDriverStore((state) => state?.activateDrivers);
  const deactivateDrivers = useDriverStore((state) => state?.deactivateDrivers);
  const activateCustomerSequencer = useOrderStore(
    (state) => state?.activateCustomerSequencer
  );
  const downloadDriverLicense = useDriverStore(
    (state) => state?.downloadDriverLicense
  );
  const downloadTransactionProof = useCollectionStore(
    (state) => state?.downloadTransactionProof
  );
  const downloadSettlementProof = useCollectionStore(
    (state) => state?.downloadSettlementProof
  );
  const getConsignmentStatus = useAoneStore(
    (state) => state?.getConsignmentStatus
  );
  const exportPickupInvoice = useAoneStore(
    (state) => state?.exportPickupInvoice
  );
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const configureCourierHubs = usecourierStore(
    (state) => state?.configureCourierHubs
  );
  const downloadTemplate = async (id) => {
    try {
      const res = await downloadInvoiceRequest(id);
      if (res?.status == 200) {
        fileDownload(res?.data, `Invoice Template.xlsx`);
      }
    } catch (err) {}
  };
  const downloadDriverLicenses = async (id, type) => {
    try {
      const res = await downloadDriverLicense(id);
      if (res?.status == 200) {
        fileDownload(res?.data, `Driving License.${type}`);
      }
    } catch (err) {}
  };
  const downloadTransacProof = async (id, type) => {
    try {
      const res = await downloadTransactionProof(id);
      if (res?.status == 200) {
        fileDownload(res?.data, `Transaction Proof.${type}`);
      }
    } catch (err) {}
  };
  const downloadSettleProof = async (id, type) => {
    try {
      const res = await downloadSettlementProof(id);
      if (res?.status == 200) {
        fileDownload(res?.data, `Settlement History Proof.${type}`);
      }
    } catch (err) {}
  };
  const handleClickSort = (e) => {
    setHeader(e?.name);
    setSortField("chuteNumber,zoneCode");
    setSelectAll(false);
    setShowActions(false);

    if (location.pathname.includes("middlemile")) {
      navigate(`/middlemile/sortingmachine/config/${e.id}`);
    } else {
      navigate(`/ops/sortingmachine/config/${e.id}`);
    }
  };
  const printConsignmentOrders = async (id) => {
    try {
      const res = await printConsignments(id);
      // Create a Blob from the response data
      const blob = new Blob([res.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      // Open the PDF in a new window
      window.open(blobUrl, "_blank");
    } catch (error) {}
  };
  const [username, setUsername] = useState("");
  // Decode the token using jwtDecode
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    // Decode the token using jwtDecode
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const { name } = decodedToken;
        setUsername(name);
      } catch (error) {
        console.error("Error decoding token:", error.message);
      }
    } else {
      console.error("Token not found in session storage");
    }
  }, []);
  const handleShowTasks = (employeecode) => {
    setCourierCode(employeecode);
    setShowModal(true);
  };
  const handleBulkCancelOrders = async (selectOrders) => {
    try {
      let payload = {
        trackingIds: [selectOrders],
        canceledBy: username,
        canceledAt: Date.now(),
      };
      const res = await cancelConsignments(payload);
      if (res?.data?.status == "ERROR") {
        const errorMessage = res?.data?.msg;
        toast.error(errorMessage);
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
      } else {
        toast.success("Consignment cancelled");
        setispostAdded(!ispostAdded);
      }
    } catch (err) {}
  };
  const handleShowMapPins = (employeeCode) => {
    setSelectedEmployeeCode(employeeCode);
  };
  const handleActivate = async (vehicleCode) => {
    try {
      let payload = [vehicleCode];
      const res = await activateVehicleDocs(payload);
      if (res?.data?.status == "SUCCESS") {
        SetisActivated(!isActivated);
        setispostAdded(!ispostAdded);
        toast.success("Vehicle Activated");
      } else if (res?.status == 200 && res?.data?.status !== "ERROR") {
        SetisActivated(!isActivated);
        setispostAdded(!ispostAdded);
        toast.success("Vehicle Activated");
      } else if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const handleInActivate = async (vehicleCode) => {
    try {
      let payload = [vehicleCode];
      const res = await deactivateVehicleDocs(payload);
      if (res?.data?.status == "SUCCESS") {
        SetisActivated(!isActivated);
        setispostAdded(!ispostAdded);
        toast.success("Vehicle Deactivated");
      } else if (res?.status == 200 && res?.data?.status !== "ERROR") {
        SetisActivated(!isActivated);
        setispostAdded(!ispostAdded);
        toast.success("Vehicle Deactivated");
      } else if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const handleDriverActivate = async (vehicleCode) => {
    try {
      const res = await activateDrivers(vehicleCode);
      if (res?.data?.status == "SUCCESS") {
        SetisActivated(!isActivated);
        toast.success("Driver Activated");
      } else if (res?.status == 200 && res?.data?.status !== "ERROR") {
        SetisActivated(!isActivated);
        toast.success("Driver Activated");
      } else if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const handleDriverInActivate = async (vehicleCode) => {
    try {
      const res = await deactivateDrivers(vehicleCode);
      if (res?.data?.status == "SUCCESS") {
        SetisActivated(!isActivated);
        setispostAdded(!ispostAdded);
        toast.success("Driver Deactivated");
      } else if (res?.status == 200 && res?.data?.status !== "ERROR") {
        SetisActivated(!isActivated);
        setispostAdded(!ispostAdded);
        toast.success("Driver Deactivated");
      } else if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const handleSeqActivate = async (id) => {
    try {
      const payload = {
        status: "ACTIVE",
      };
      const res = await activateCustomerSequencer(id, payload);
      if (res?.data?.status == "SUCCESS") {
        setispostAdded(!ispostAdded);
        toast.success("Sequence Activated");
      } else if (res?.status == 200 && res?.data?.status !== "ERROR") {
        setispostAdded(!ispostAdded);
        toast.success("Sequence Activated");
      } else if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const handleSeqInActivate = async (id) => {
    try {
      const payload = {
        status: "NOT_ACTIVE",
      };
      const res = await activateCustomerSequencer(id, payload);
      if (res?.data?.status == "SUCCESS") {
        setispostAdded(!ispostAdded);
        toast.success("Sequence Deactivated");
      } else if (res?.status == 200 && res?.data?.status !== "ERROR") {
        setispostAdded(!ispostAdded);
        toast.success("Sequence Deactivated");
      } else if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const cancelRequest = async (id) => {
    try {
      const res = await CancelInvoiceRequest(id);
      if (res?.status == 200) {
        setissaved(!issaved);
        toast.success("Invoice cancelled");
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleCancel = () => {
    handleClose();
  };
  const [fetchData, setFetchData] = useState([]);
  const fetchInvoice = async (waybill) => {
    if (waybill !== null) {
      try {
        const res = await fetchConsignments(waybill);
        if (res?.data?.status == "SUCCESS") {
          setFetchData(res?.data?.data);
          setShow(true);
          toast.success("Consignment Fetched");
        } else {
          toast.error(res?.response?.statusText);
        }
      } catch (err) {}
    } else {
      toast.error("Waybill is null, cannot fetch consignment");
    }
  };
  const editPickupAddress = (e) => {
    let paramslink = `?page=${0}&size=${20}`;
    getCities(paramslink);
    getDistricts(paramslink);
    setisEditPickup(true);
    setSaveHandlerAction(true);
    setModalHandler(!modalHandler);
    setModalData(e);
  };
  const downloadConsignments = useAoneStore(
    (state) => state?.downloadConsignments
  );
  const downloadConsignmentTemplate = async (id) => {
    try {
      const res = await downloadConsignments(id);
      if (res?.status == 200) {
        fileDownload(res?.data, `Consignments Template.xlsx`);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const exportPickupRevenue = async (id) => {
    try {
      const res = await exportPickupInvoice(id);
      if (res?.status == 200) {
        fileDownload(res?.data, `Pickup Invoice Revenue.xlsx`);
      } else {
        toast.error(res?.response?.statusText);
      }
    } catch (err) {}
  };
  const handlepushOracle = async (id) => {
    try {
      const res = await pushOracleCustomer(id);
      if (res?.data?.status === "SUCCESS") {
        setIsPush(!isPush);
        toast.success("Customers pushed to oracle!");
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
      } else {
        const errorMessage = res?.data?.msg.split(",")[0];
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("Error pushing customers. Please try again.");
    }
  };
  const applyPickupOrder = async (trackingId, originHub, pickupRequestId) => {
    try {
      let payload = {
        trackingId: trackingId,
        eventCode: 201,
        latitude: latitude,
        longitude: longitude,
        hubCode: originHub,
        eventDate: currentTimeMillis,
        eventTimeZone: eventTimeZone,
        pickupRequestId: pickupRequestId,
      };
      const res = await applyConsignments(payload);
      if (res?.data?.status === "SUCCESS") {
        toast.success("Consignment scanned successfully");
        setispostAdded(!ispostAdded);
      } else if (res?.response?.data?.errors?.length > 0) {
        const errorMessages = res?.response?.data?.errors
          ?.map((item) => `${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
      } else {
        const errorMessage = res?.data?.msg.split(",")[0];
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("Error in applying the consignment.");
    }
  };
  const handleInvoiceButtonClick = async (id) => {
    try {
      // Call the pushInvoicebyId function
      const res = await pushInvoicebyId(id);
      if (res?.status == "200") {
        setissaved(!issaved);
        toast.success("Consignments Invoiced and sent to oracle!");
      } else {
        const errorMessage = res.response?.data.msg.split(",")[0];
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("Error pushing invoice. Please try again.");
    }
  };
  // const assignOrder = async (waybill, thirdpartyid) => {
  //   const payload = {
  //     waybillNumber: waybill,
  //     thirdPartyId: thirdpartyid,
  //   };
  //   await assignOrders(payload).then((response) => {
  //     if (response?.response?.data?.status === "ERROR") {
  //       const errorMessage = response?.response?.data?.msg.split(",")[0];
  //       toast.error(errorMessage);

  //     } else {
  //       toast.success("Order Assigned");
  //       setIsassign(!isassign);
  //       setShoworder(false)
  //     }
  //   });
  // };
  const [orderWaybill, setorderWaybill] = useState([]);
  const assignModal = (waybill) => {
    setorderWaybill([waybill]);
    setShoworder(true);
    assignThirdpartyOrder.setFieldValue("waybillNumbers", waybill);
  };
  //assign thirdparty orders
  const [showorder, setShoworder] = useState(false);
  const handleShoworder = () => setShoworder(true);
  const handleCloseorder = () => {
    setShoworder(false);
  };
  const assignThirdpartyOrder = useFormik({
    initialValues: {
      waybillNumbers: [],
      thirdPartyId: "",
    },
    validationSchema: Yup.object({
      thirdPartyId: Yup.string().required("Select a courier to assign order"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const updatedValues = {
        ...values,
        waybillNumbers: [values.waybillNumbers],
      };
      await assignOrders(updatedValues).then((response) => {
        if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setShoworder(false);
          resetForm();
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setShoworder(false);
          resetForm();
        } else {
          toast.success("Order Assigned");
          setIsassign(!isassign);
          setShoworder(false);
          resetForm();
        }
      });
    },
  });
  //print order
  const printThirdpartyOrder = async (id) => {
    try {
      const res = await printOrders(id);
      if (res?.data?.status === "SUCCESS") {
        let url = res?.data?.data?.url;
        window.open(url, "_blank");
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg?.split(",")[0];
        toast.error(errorMessage);
      } else {
        const errorMessage = res?.data?.msg?.split(",")[0];
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("Error printing orders. Please try again.");
    }
  };
  const cancelOrder = async (referenceNumber, waybill) => {
    const payload = {
      referenceNumber: referenceNumber,
      waybillNumber: waybill,
    };
    await cancelOrders(payload).then((response) => {
      if (response?.response?.data?.status === "ERROR") {
        const errorMessage = response?.response?.data?.msg.split(",")[0];
        toast.error(errorMessage);
      } else {
        toast.success("Order Cancelled");
        setIsassign(!isassign);
      }
    });
  };
  //download docs in vehicles
  const downloadFile = async (url, fileName, token) => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });
      const blob = await response.blob();
      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName; // Set the file name
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Usage example
  const handleDownload = (url, fileName, token) => {
    downloadFile(url, fileName, token);
  };
  useEffect(() => {
    if (!deleteHandler && tbl !== "Bags" && tbl !== "DetailedBags") {
      setIndividualCheckboxes(new Array(tableData?.length).fill(false));
    } else if (deleteHandler && tbl === "Assign_Users") {
      setIndividualCheckboxes(new Array(tableData?.length).fill(false));
    } else if (deleteHandler && tbl === "Assign_Roles") {
      setIndividualCheckboxes(new Array(tableData?.length).fill(false));
    } else {
      setIndividualCheckboxes(new Array(tableData?.length).fill(false));
      if (tbl !== "Assign_Groups") {
        setIndividualCheckboxesBag(new Array(tableData?.length).fill(false));
      }
    }
  }, [tableData]);
  useEffect(() => {
    if (tbl == "Settle") {
      setSettleCheckboxes(new Array(tableData?.length).fill(false));
    }
  }, [tableData, ischangeTable]);
  useEffect(() => {
    if (tbl == "RtoRequest") {
      setSettleRto(new Array(tableData?.length).fill(false));
    }
  }, [tableData, ischangeRtoTable]);
  useEffect(() => {
    if (tbl == "ACL") {
      setSettleAcl(new Array(tableData?.length).fill(false));
    }
  }, [tableData, ischangeAclTable]);
  const handleSelectAllConsignmentsClick = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setIndividualCheckboxes(
      newSelectAll
        ? individualCheckboxes?.map(() => true)
        : individualCheckboxes?.map(() => false)
    );

    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      if (tbl === "Pickup Request") {
        setSelectPickup(tableData?.map((row) => row?.requestId));
      } else {
        setSelectPickup(tableData?.map((row) => row?.trackingId));
        setSelectOriginHub(tableData?.map((row) => row?.originHub));
        setSelectPickuprequest(tableData?.map((row) => row?.pickupRequestId));
      }
    } else {
      setSelectPickup([]);
      setSelectOriginHub([]);
      setSelectPickuprequest([]);
    }
  };
  const handleSelectAllRatesClick = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setIndividualCheckboxes(
      newSelectAll
        ? individualCheckboxes?.map(() => true)
        : individualCheckboxes?.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setSelectRates(tableData?.map((row) => row?.id));
    } else {
      setSelectRates([]);
    }
  };
  const handleSelectAllOrdersClick = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setIndividualCheckboxes(
      newSelectAll
        ? individualCheckboxes?.map(() => true)
        : individualCheckboxes?.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setSelectOrders(tableData?.map((row) => row?.trackingId));
    } else {
      setSelectOrders([]);
    }
  };
  const handleSelectAllCollectionsClick = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAllCollections;
    setSelectAllCollections(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setSettleCheckboxes(
      newSelectAll
        ? settleCheckbox?.map(() => true)
        : settleCheckbox?.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setSelectCollections(tableData?.map((row) => row?.id));
      setCollectionSum(tableData?.map((row) => row?.collectedAmount));
    } else {
      setSelectCollections([]);
      setCollectionSum([]);
    }
  };
  const handleSelectAllAclClick = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAllAcl;
    setSelectAllAcl(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setSettleAcl(
      newSelectAll ? settleAcl?.map(() => true) : settleAcl?.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setSelectAcl(
        tableData
          ?.filter((row) => row?.sealNumber) // Only include rows with non-empty sealNumber
          .map((row) => row?.number)
      );
      const allAclNumbers = tableData.map((row) => row?.number);
      setSelectAclNum(allAclNumbers);
    } else {
      setSelectAcl([]);
      setSelectAclNum([]);
    }
  };
  const handleSelectAllRtoClick = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAllRto;
    setSelectAllRto(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setSettleRto(
      newSelectAll ? settleRto?.map(() => true) : settleRto?.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setSelectRto(tableData?.map((row) => row?.rtoTrackingId));
    } else {
      setSelectRto([]);
    }
  };
  const handlePickupCheckboxClick = (index) => {
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAll(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (tbl === "Pickup Request") {
      if (newCheckboxes[index]) {
        setSelectPickup((prevSelectedZoneCodes) => {
          const updatedZoneCodes = [
            ...prevSelectedZoneCodes,
            tableData[index].requestId,
          ];
          return updatedZoneCodes;
        });
      } else {
        setSelectPickup((prevSelectedZoneCodes) => {
          const updatedZoneCodes = prevSelectedZoneCodes?.filter(
            (code) => code !== tableData[index].requestId
          );
          return updatedZoneCodes;
        });
      }
    } else {
      if (newCheckboxes[index]) {
        setSelectPickup((prevSelectedZoneCodes) => {
          const updatedZoneCodes = [
            ...prevSelectedZoneCodes,
            tableData[index].trackingId,
          ];
          return updatedZoneCodes;
        });
        setSelectOriginHub((prevSelectedHub) => {
          const updatedZoneHub = [
            ...prevSelectedHub,
            tableData[index].originHub,
          ];
          return updatedZoneHub;
        });
        setSelectPickuprequest((prevSelectedHub) => {
          const updatedZoneHub = [
            ...prevSelectedHub,
            tableData[index].pickupRequestId,
          ];
          return updatedZoneHub;
        });
      } else {
        setSelectPickup((prevSelectedZoneCodes) => {
          const updatedZoneCodes = prevSelectedZoneCodes?.filter(
            (code) => code !== tableData[index].trackingId
          );
          return updatedZoneCodes;
        });
        setSelectOriginHub((prevSelectedHub) => {
          const updatedZoneHub = prevSelectedHub?.filter(
            (code) => code !== tableData[index].originHub
          );
          return updatedZoneHub;
        });
        setSelectPickuprequest((prevSelectedHub) => {
          const updatedZoneHub = prevSelectedHub?.filter(
            (code) => code !== tableData[index].pickupRequestId
          );
          return updatedZoneHub;
        });
      }
    }
  };
  const handleRatesCheckboxClick = (index) => {
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAll(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setSelectRates((prevSelectedZoneCodes) => {
        const updatedZoneCodes = [
          ...prevSelectedZoneCodes,
          tableData[index].id,
        ];
        return updatedZoneCodes;
      });
    } else {
      setSelectRates((prevSelectedZoneCodes) => {
        const updatedZoneCodes = prevSelectedZoneCodes?.filter(
          (code) => code !== tableData[index].id
        );
        return updatedZoneCodes;
      });
    }
  };
  const handleOrdersCheckboxClick = (index) => {
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAll(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setSelectOrders((prevSelectedZoneCodes) => {
        const updatedOrders = [
          ...prevSelectedZoneCodes,
          tableData[index].trackingId,
        ];
        return updatedOrders;
      });
    } else {
      setSelectOrders((prevSelectedZoneCodes) => {
        const updatedOrders = prevSelectedZoneCodes?.filter(
          (code) => code !== tableData[index].trackingId
        );
        return updatedOrders;
      });
    }
  };
  const handleOrdersCheckboxCollectionsClick = (index) => {
    const newCheckboxes = [...settleCheckbox];
    newCheckboxes[index] = !newCheckboxes[index];
    setSettleCheckboxes(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAllCollections(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setSelectCollections((prevSelectedZoneCodes) => {
        const updatedOrders = [...prevSelectedZoneCodes, tableData[index].id];
        return updatedOrders;
      });
      setCollectionSum((prevSelectedZoneCodes) => {
        const updatedOrders = [
          ...prevSelectedZoneCodes,
          tableData[index].collectedAmount,
        ];
        return updatedOrders;
      });
    } else {
      setSelectCollections((prevSelectedZoneCodes) => {
        const updatedOrders = prevSelectedZoneCodes?.filter(
          (code) => code !== tableData[index].id
        );
        return updatedOrders;
      });
      setCollectionSum((prevSelectedZoneCodes) => {
        const updatedOrders = prevSelectedZoneCodes?.filter(
          (code) => code !== tableData[index].collectedAmount
        );
        return updatedOrders;
      });
    }
  };
  const handleCheckboxRtoClick = (index) => {
    const newCheckboxes = [...settleRto];
    newCheckboxes[index] = !newCheckboxes[index];
    setSettleRto(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAllRto(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setSelectRto((prevSelectedZoneCodes) => {
        const updatedOrders = [
          ...prevSelectedZoneCodes,
          tableData[index].rtoTrackingId,
        ];
        return updatedOrders;
      });
    } else {
      setSelectRto((prevSelectedZoneCodes) => {
        const updatedOrders = prevSelectedZoneCodes?.filter(
          (code) => code !== tableData[index].rtoTrackingId
        );
        return updatedOrders;
      });
    }
  };
  const handleCheckboxAclClick = (index) => {
    const newCheckboxes = [...settleAcl];
    newCheckboxes[index] = !newCheckboxes[index];
    setSettleAcl(newCheckboxes);
    // Check if all checkboxes are selected
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAllAcl(allSelected);
    const { number, sealNumber } = tableData[index];
    if (newCheckboxes[index]) {
      // Only add the number if sealNumber is not empty or null
      if (sealNumber) {
        setSelectAcl((prevSelectedZoneCodes) => {
          const updatedOrders = [...prevSelectedZoneCodes, number];
          return updatedOrders;
        });
      }
      setSelectAclNum((prevSelectedZoneCodes) => {
        const updatedOrders = [...prevSelectedZoneCodes, number];
        return updatedOrders;
      });
    } else {
      setSelectAcl((prevSelectedZoneCodes) => {
        const updatedOrders = prevSelectedZoneCodes?.filter(
          (code) => code !== number
        );
        return updatedOrders;
      });
      setSelectAclNum((prevSelectedZoneCodes) => {
        const updatedOrders = prevSelectedZoneCodes?.filter(
          (code) => code !== number
        );
        return updatedOrders;
      });
    }
  };
  
  const handleSelectAllClick = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setIndividualCheckboxes(
      newSelectAll
        ? individualCheckboxes.map(() => true)
        : individualCheckboxes.map(() => false)
    );

    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setselectZone(tableData.map((row) => row.zoneCode));
    } else {
      setselectZone([]);
    }
  };

  const handleIndividualCheckboxClick = (index) => {
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAll(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = [
          ...prevSelectedZoneCodes,
          tableData[index].zoneCode,
        ];
        return updatedZoneCodes;
      });
    } else {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = prevSelectedZoneCodes.filter(
          (code) => code !== tableData[index].zoneCode
        );
        return updatedZoneCodes;
      });
    }
  };
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (index) => {
    setExpandedRows((prevRows) => {
      const isRowExpanded = prevRows?.includes(index);
      if (isRowExpanded) {
        return prevRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevRows, index];
      }
    });
  };
  useEffect(() => {
    // When isChanged changes, reset all values in individualCheckboxes to false
    const resetCheckboxes = new Array(individualCheckboxes.length).fill(false);
    setIndividualCheckboxes(resetCheckboxes);
  }, [isChanged, isChange]);
  //bulk click-machine-scan
  const handleSelectAllClickScan = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setIndividualCheckboxes(
      newSelectAll
        ? individualCheckboxes.map(() => true)
        : individualCheckboxes.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setselectZone(tableData.map((row) => row.id));
    } else {
      setselectZone([]);
    }
  };
  //bulk click-bag
  const handleSelectAllClickBag = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setIndividualCheckboxesBag(
      newSelectAll
        ? individualCheckboxesbag.map(() => true)
        : individualCheckboxesbag.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setselectZone(tableData.map((row) => row.id));
      setSelectWaybill(tableData.map((row) => row.bagNumber));
    } else {
      setselectZone([]);
      setSelectWaybill([]);
    }
  };
  const handleIndividualCheckboxBag = (index) => {
    const newCheckboxes = [...individualCheckboxesbag];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxesBag(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAll(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = [
          ...prevSelectedZoneCodes,
          tableData[index].id,
        ];
        return updatedZoneCodes;
      });
      setSelectWaybill((prevSelectedZoneCodes) => {
        const updatedZoneCodes = [
          ...prevSelectedZoneCodes,
          tableData[index].bagNumber,
        ];
        return updatedZoneCodes;
      });
    } else {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = prevSelectedZoneCodes.filter(
          (code) => code !== tableData[index].id
        );
        return updatedZoneCodes;
      });
      setSelectWaybill((prevSelectedZoneCodes) => {
        const updatedZoneCodes = prevSelectedZoneCodes.filter(
          (code) => code !== tableData[index].bagNumber
        );
        return updatedZoneCodes;
      });
    }
  };
  //select all consignments
  const handleSelectAllClickConsignment = () => {
    // Toggle the selectAll state
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    // Update individualCheckboxes: set all checkboxes to true if newSelectAll is true,
    // or set them all to false if newSelectAll is false
    setIndividualCheckboxesBag(
      newSelectAll
        ? individualCheckboxesbag.map(() => true)
        : individualCheckboxesbag.map(() => false)
    );
    // If the top-level checkbox is selected, add all zoneCodes to the selectZone array;
    // Otherwise, clear the array
    if (newSelectAll) {
      setselectZone(tableData.map((row) => row.waybillNumber));
      const firstRowBagNumber =
        tableData?.length > 0 ? tableData[0]?.bagNumber : null;
      setBagNum(firstRowBagNumber);
    } else {
      setselectZone([]);
      setSelectWaybill([]);
    }
  };
  //select consignments
  const handleIndividualConsignment = (index, bag) => {
    setBagNum(bag);
    const newCheckboxes = [...individualCheckboxesbag];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxesBag(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAll(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = [
          ...prevSelectedZoneCodes,
          tableData[index].waybillNumber,
        ];
        return updatedZoneCodes;
      });
    } else {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = prevSelectedZoneCodes.filter(
          (code) => code !== tableData[index].waybillNumber
        );
        return updatedZoneCodes;
      });
    }
  };
  const handleIndividualCheckboxClickScan = (index) => {
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);
    const allSelected = newCheckboxes.every((value) => value);
    setSelectAll(allSelected);
    // If the checkbox is selected, add the zoneCode to the selectedZoneCode array;
    // Otherwise, remove it
    if (newCheckboxes[index]) {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = [
          ...prevSelectedZoneCodes,
          tableData[index].id,
        ];
        return updatedZoneCodes;
      });
    } else {
      setselectZone((prevSelectedZoneCodes) => {
        const updatedZoneCodes = prevSelectedZoneCodes.filter(
          (code) => code !== tableData[index].id
        );
        return updatedZoneCodes;
      });
    }
  };
  const handleEditVariantClick = (variant, parentData, index) => {
    setSaveHandlerAction(true);
    setModalHandler(!modalHandler);
    setModalData({
      ...parentData,
      variantIndex: index,
      variantData: variant,
    });
  };
  const handleViewVariantClick = (variant, parentData, index) => {
    setModalHandler(!modalHandler);
    setModalData({
      ...parentData,
      variantIndex: index,
      variantData: variant,
    });
  };
  const deleteRoleById = useAoneStore((state) => state?.deleteRoleById);
  const deleteUserById = useAoneStore((state) => state?.deleteUserById);
  const deleteCountryById = useAoneStore((state) => state?.deleteCountryById);
  const deleteRegionById = useAoneStore((state) => state?.deleteRegionById);

  const deleteChuteConfigs = useAoneStore((state) => state?.deleteChuteConfigs);
  const deleteChuteConfigsById = useAoneStore(
    (state) => state?.deleteChuteConfigsById
  );
  const deleteAgentConfigs = useAoneStore((state) => state?.deleteAgentConfigs);
  const deactivateAgentConfigs = useAoneStore(
    (state) => state?.deactivateAgentConfigs
  );
  const [phoneHandler, setPhoneHandler] = useState(false);
  const assignRolesToUserId = useAoneStore(
    (state) => state?.assignRolesToUserId
  );
  const unassignRolesOfUserId = useAoneStore(
    (state) => state?.unassignRolesOfUserId
  );
  const assignGroupsToUserId = useAoneStore(
    (state) => state?.assignGroupsToUserId
  );
  const unassignGroupsOfUserId = useAoneStore(
    (state) => state?.unassignGroupsOfUserId
  );
  const assignRolesToGroupId = useAoneStore(
    (state) => state?.assignRolesToGroupId
  );

  const deleteJobOrderById = useAoneStore((state) => state?.deleteJobOrderById);
  const receivemachineScan = useAoneStore((state) => state?.receiveMachineScan);
  const outScanMachineScanById = useAoneStore(
    (state) => state?.outScanMachineScan
  );
  const setSaveHandlerAction = useAoneStore((state) => state?.setSaveHandler);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [mul, setMul] = useState([20, 50, 100]);
  const [customdropdownOne, setCustomdropdownone] = useState(null);

  const [modalData, setModalData] = useState([]);
  const [editHandler, setEditHandler] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const [deleteParamsId, setDeleteParamsId] = useState({
    idOne: "",
    idTwo: "",
    name: "",
    title: "",
  });
  const deleteParamsIdSetter = (id1, id2, name, title) => {
    setDeleteParamsId({
      idOne: id1,
      idTwo: id2,
      name: name,
      title: title,
    });
    setDeleteParams({
      id: id1,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };
  const [visiblePhones, setVisiblePhones] = useState({});
  const togglePhoneHandler = async (code, defaultPhoneNumber) => {
    try {
      const response = await showSensitiveFields(code, "phoneNumber");
      if (response?.data?.status === "SUCCESS") {
        setVisiblePhones((prevState) => ({
          ...prevState,
          [code]: response?.data?.data,
        }));
      } else if (response?.data?.status === "ERROR") {
        setVisiblePhones((prevState) => ({
          ...prevState,
          [code]: defaultPhoneNumber,
        }));
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching phone number:", error);
      setVisiblePhones((prevState) => ({
        ...prevState,
        [code]: defaultPhoneNumber,
      }));
    }
  };

  const deleteParamsSetter = (id, name, title) => {
    setDeleteParams({
      id: id,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };
  const paginationHandler = (e) => {
    setNewPage(e - 1);
  };
  const statusSwitchHandler = async (id, value) => {
    if (
      roleHandler(role, "CONFIGS.write") ||
      roleHandler(role, "CONFIGS.admin")
    ) {
      await deactivateAgentConfigs(id, { status: !value });
      setStatusHandler(!statusHandler);
    }
  };
  // useEffect(() => {
  //   if (deleteParamsId.idOne !== null && deleteParamsId.idTwo !== null) {
  //     // setIsDelete(!isDelete)
  //     setSaveHandlerAction(false);
  //     deleteWaybillByBagId(
  //       deleteParamsId?.idTwo,
  //       deleteParamsId?.idOne
  //     ).then((response) => {
  //       setDeleteHandler(false);
  //       if (response.status == "200") {
  //         toast.success(`${deleteParamsId.name} has been Deleted`);
  //       } else {
  //         const errorMessage = response.response.data.msg;
  //         const firstCommaIndex = errorMessage.indexOf(",");
  //         const toastMessage =
  //           firstCommaIndex !== -1
  //             ? errorMessage.substring(0, firstCommaIndex)
  //             : errorMessage;
  //         toast.error(toastMessage);
  //       }
  //   })
  // }
  // }, [deleteParamsId]);
  const deleteWaybill = async () => {
    setSaveHandlerAction(false);
    await deleteWaybillByBagId(
      deleteParamsId?.idTwo,
      deleteParamsId?.idOne
    ).then((response) => {
      setDeleteHandler(false);
      if (response.status == "200") {
        toast.success(`${deleteParamsId.name} has been Deleted`);
      } else {
        const errorMessage = response.response.data.msg;
        const firstCommaIndex = errorMessage.indexOf(",");
        const toastMessage =
          firstCommaIndex !== -1
            ? errorMessage.substring(0, firstCommaIndex)
            : errorMessage;
        toast.error(toastMessage);
      }
    });
  };
  const handleStatusButtonClick = async (e) => {
    const idPayload = {
      ids: [e.id],
    };
    if (e?.currentStatus === "pickup_completed" || e?.currentStatus === null) {
      const response = await receivemachineScan(idPayload);
      // if (response?.data?.status === "Success") {
      setStatusChange(!statusChange);
      toast.success("Status changed to Inscan at hub");
      // }
      // else{
      //   toast.success(response?.data?.status)
      // }
    } else if (e?.currentStatus === "inscan_at_hub") {
      const response = await outScanMachineScanById(idPayload);
      // if (response?.data?.status === "Success") {
      setStatusChange(!statusChange);
      toast.success("Status changed to InTransit to hub");
      // } else{
      //   toast.success(response?.data?.status)
      // }
    }
  };
  const deleteById = async (id) => {
    //ALSO USED TO ASSIGN IAMS
    if (tbl == "Roles") {
      await deleteRoleById(id);
    } else if (tbl == "Countries") {
      await deleteCountryById(id);
    } else if (tbl == "Regions") {
      await deleteRegionById(id);
    } else if (tbl == "Configs") {
      await deleteChuteConfigs(id);
    } else if (tbl == "DetailedConfig") {
      await deleteChuteConfigsById(params?.id, id);
    } else if (tbl == "Agents") {
      await deleteAgentConfigs(id);
    } else if (tbl == "Users") {
      await deleteUserById(id);
    } else if (
      tbl == "Assign_Roles" &&
      location.pathname.includes("/groups/")
    ) {
      await assignRolesToGroupId(params?.id, id);
    } else if (
      tbl == "Assign_Roles" &&
      !location.pathname.includes("/groups/")
    ) {
      await assignRolesToUserId(params?.id, id);
    } else if (
      tbl == "Assign_Users" &&
      location.pathname.includes("/groups/")
    ) {
      await assignGroupsToUserId(id, params?.id);
    } else if (
      tbl == "Assign_Users" &&
      !location.pathname.includes("/groups/")
    ) {
      await assignRolesToUserId(id, params?.id);
    } else if (tbl == "Roles_Users") {
      await unassignRolesOfUserId(id, params?.id);
    } else if (tbl == "Assign_Groups") {
      const res = await assignGroupsToUserId(params?.id, id);
      if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      }
    } else if (tbl == "Orders") {
      await deleteJobOrderById(id);
    }
    setDeleteHandler(!deleteHandler);
  };
  const sorting = (name) => {
    const value = sortField?.startsWith("-") ? "" : "-";
    setSortField(value + name);
  };
  const handleClick = (latitude, longitude) => {
    if (latitude && longitude) {
      // Construct the Google Maps URL using the latitude and longitude
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      // Open a new tab with the constructed URL
      window.open(googleMapsUrl, "_blank");
    } else {
      console.warn("Latitude and/or longitude are missing.");
    }
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]); // For Role Based Access

  // const loop = () => {
  //   let ddata = [];
  //   var dshow = Math.ceil(tableData?.length / 5)
  //   for (let a = 1; a <= dshow; a++) {
  //     ddata.push(a);
  //   }
  //   setMul(ddata);
  // };
  // Define your columnWidths object
  const [columnWidths, setColumnWidths] = useState({
    agentId: 71,
    waybillNumber: 100,
    customerCode: 100,
    zoneCode: 120,
    zoneGroup: 120,
    chuteNum: 100,
    currentHubCode: 100,
    desHubCode: 100,
    currentStatus: 100,
    sortingConfig: 100,
    weight: 60,
    volume: 80,
    scanTime: 150,
    result: 80,
    reason: 80,
    actions: 100,
  });
  const [thirdpartyColumnWidths, setThirdpartyTableColumnWidths] = useState({
    carriercode: 72,
    carriername: 90,
    status: 60,
    createdAt: 150,
    updatedAt: 150,
    actions: 70,
  });
  const [mappingColumnWidths, setMappingTableColumnWidths] = useState({
    carrier: 72,
    carrierAlias: 90,
    ajexValue: 80,
    type: 80,
    createdAt: 150,
    updatedAt: 150,
    actions: 70,
  });
  const [ordersColumnWidths, setOrderTableColumnWidths] = useState({
    carrier: 72,
    referenceNo: 110,
    waybillNo: 80,
    status: 80,
    thirdpartyorderNum: 135,
    thirdpartyStatus: 120,
    exception: 80,
    eventTime: 150,
    pushTime: 150,
    actions: 70,
  });
  const [eventsColumnWidths, setEventTableColumnWidths] = useState({
    carrier: 72,
    referenceNo: 110,
    waybillNo: 80,
    status: 80,
    thirdpartyStatus: 120,
    exception: 80,
    eventTime: 150,
    pushTime: 150,
    actions: 70,
  });
  const [bagColumnWidths, setBagTableColumnWidths] = useState({
    bagNumber: 100,
    zoneGroup: 100,
    sourceHub: 110,
    destinationHub: 90,
    zoneLevel: 80,
    destinationZone: 100,
    zoneGroupLevel: 80,
    destinationZoneGroupCode: 130,
    chute: 80,
    totalWeight: 80,
    totalCount: 110,
    valid: 65,
    sortingConfig: 90,
    tripId: 75,
    creationAt: 80,
    actions: 150,
  });
  const [waybillColumnWidths, setWaybillTableColumnWidths] = useState({
    waybillNumber: 100,
    weight: 65,
    length: 65,
    width: 65,
    height: 65,
    receiveTime: 125,
    dropTime: 125,
    valid: 65,
    errorMessage: 90,
    actions: 140,
  });
  // Function to handle column resizing
  const handleColumnResize = (columnId, newWidth) => {
    // Update the columnWidths state when a column is resized
    if (tbl === "Machinescan") {
      setColumnWidths({
        ...columnWidths,
        [columnId]: newWidth,
      });
    } else if (tbl === "Thirdparties") {
      setThirdpartyTableColumnWidths({
        ...thirdpartyColumnWidths,
        [columnId]: newWidth,
      });
    } else if (tbl === "Mapping") {
      setMappingTableColumnWidths({
        ...mappingColumnWidths,
        [columnId]: newWidth,
      });
    } else if (tbl === "Orders") {
      setOrderTableColumnWidths({
        ...ordersColumnWidths,
        [columnId]: newWidth,
      });
    } else if (tbl === "Events") {
      setEventTableColumnWidths({
        ...eventsColumnWidths,
        [columnId]: newWidth,
      });
    } else if (tbl === "Bags") {
      setBagTableColumnWidths({
        ...bagColumnWidths,
        [columnId]: newWidth,
      });
    } else if (tbl === "DetailedBags") {
      setWaybillTableColumnWidths({
        ...waybillColumnWidths,
        [columnId]: newWidth,
      });
    }
  };
  return (
    <>
      <div className="table_wrapper overflowScroll mb_24 showTenRows">
        <table>
          <thead
            className="cursorPointer"
            style={{
              zIndex:
                isDropdownOpen ||
                isDropdownOpens ||
                isDropdownselectOpen ||
                isDropdownselectOpencurrency ||
                isDropdownselectOpenconv ||
                isReactselectOpen ||
                isReactdesCountry ||
                isReactdesBulk ||
                isReactselectCity ||
                isReactselectRegion ||
                isReactoriginselectCity ||
                isReactdesCity ||
                isReactdesRegion ||
                isReactRates ||
                isReactCharges ||
                isReactCurrency ||
                isReactRatestate ||
                isMenuOpen ||
                tbl === "Cod" ||
                tbl === "Freight" ||
                tbl === "Fuel_in_perc" ||
                tbl === "Fuel_in_kg" ||
                tbl === "Lval" ||
                tbl === "Hval" ||
                tbl === "Insurance" ||
                tbl === "ODA" ||
                tbl === "Rto" ||
                tbl === "VAT"
                  ? "auto"
                  : 999,
            }}
          >
            <tr>
              {tbl === "onDemand" ? (
                <>
                  <th className="fixedColH col_no zIndex999">#</th>
                  {!restrict?.includes("waybillnumber") && (
                    <th className="fixedColH col_Firstname zIndex999">
                      Waybill Number
                    </th>
                  )}
                  {!restrict?.includes("bookingHub") && <th>Booking Hub</th>}
                  {!restrict?.includes("deliveryHub") && <th>Delivery Hub</th>}
                  {!restrict?.includes("creationTime") && (
                    <th>Creation Time</th>
                  )}
                  {!restrict?.includes("pickUpTime") && <th>Pick up Time</th>}
                  {!restrict?.includes("pickby") && <th>Picked By</th>}
                  {!restrict?.includes("OfdTime") && <th>OFD Time</th>}
                  {!restrict?.includes("deliveredBy") && <th>Delivered By</th>}
                  {!restrict?.includes("deliveryTime") && (
                    <th>Delivery Time</th>
                  )}
                  {!restrict?.includes("serviceType") && <th>Service Type</th>}
                  {!restrict?.includes("status") && <th>Status</th>}
                  {!restrict?.includes("sla") && <th>SLA</th>}
                  {!restrict?.includes("customerName") && (
                    <th>Customer Name</th>
                  )}
                  {!restrict?.includes("customerCode") && (
                    <th>Customer code</th>
                  )}
                  {!restrict?.includes("timeSinceCreation") && (
                    <th>Time Since Order Creation</th>
                  )}
                  {!restrict?.includes("timeSincePickup") && (
                    <th>Time Since Pick up</th>
                  )}
                  {!restrict?.includes("pickupDuration") && (
                    <th>Pickup Duration</th>
                  )}
                  {!restrict?.includes("deliveryDuration") && (
                    <th>Delivery Duration</th>
                  )}
                </>
              ) : tbl === "sla" ? (
                <>
                  <th>Service Type</th>
                  <th>Customer Code</th>
                  <th>SLA Hours</th>
                  <th>Country</th>
                  <th>Origin City</th>
                  <th>Destination City</th>
                  {/* <th>Action</th> */}
                </>
              ) : tbl === "ZIA" ? (
                <>
                  <th className="fixedColH col_no zIndex999">#</th>
                  {!restrict?.includes("storeId") && (
                    <th className="fixedColH col_Firstname zIndex999">
                      Store Id
                    </th>
                  )}
                  {!restrict?.includes("productName") && <th>Product Name</th>}
                  {!restrict?.includes("sku") && <th>SKU</th>}
                  {!restrict?.includes("quantity") && <th>Quantity</th>}
                  {!restrict?.includes("parentProduct") && (
                    <th>Parent Product</th>
                  )}
                  <th>Action</th>
                  <th></th>
                  {/* <th>Product Image Url</th>
                    <th>Parent Product</th> */}
                </>
              ) : tbl === "ZIM" ? (
                <>
                  <th className="fixedColH col_no zIndex999">#</th>
                  {!restrict?.includes("storeId") && (
                    <th className="fixedColH col_Firstname zIndex999">
                      Store Id
                    </th>
                  )}
                  {!restrict?.includes("storeName") && <th>Store Name</th>}
                  {!restrict?.includes("customerAccount") && (
                    <th>Customer Account</th>
                  )}
                  {!restrict?.includes("expressType") && <th>Express Type</th>}
                  {!restrict?.includes("pickupMethod") && (
                    <th>Pick Up Method</th>
                  )}
                  <th>Action</th>
                  {/* <th>Product Image Url</th>
                    <th>Parent Product</th> */}
                </>
              ) : tbl === "Roles" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("name")}>Role<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("app")}>App<SortingIconComponent sortField={sortField} fieldName="app" /></th>
                  <th onClick={() => sorting("description")}>Description<SortingIconComponent sortField={sortField} fieldName="description" /></th>
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_IAM.write") ||
                    role?.includes("AONE_IAM.admin") ||
                    role?.includes("AONE_IAM_ROLES.write") ||
                    role?.includes("AONE_IAM_ROLES.admin")) && <th>Action</th>}
                </>
              ) : tbl === "Roles_Users" ? (
                <>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Full Name</th>
                  <th>Action</th>
                </>
              ) : tbl === "Assign_Roles" ? (
                <>
                  <th>#</th>
                  <th>Role</th>
                  <th>Action</th>
                </>
              ) : tbl === "Users" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("username")}>Username<SortingIconComponent sortField={sortField} fieldName="username" /></th>
                  <th onClick={() => sorting("email")}>Email<SortingIconComponent sortField={sortField} fieldName="email" /></th>
                  <th onClick={() => sorting("firstName")}>First Name<SortingIconComponent sortField={sortField} fieldName="firstName" /></th>
                  <th onClick={() => sorting("lastName")}>Last Name<SortingIconComponent sortField={sortField} fieldName="lastName" /></th>
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_IAM.write") ||
                    role?.includes("AONE_IAM.admin") ||
                    role?.includes("AONE_IAM_USERS.write") ||
                    role?.includes("AONE_IAM_USERS.admin")) && <th>Action</th>}
                </>
              ) : tbl === "Assign_Users" ? (
                <>
                  <th>#</th>
                  <th>Username</th>
                  <th>Action</th>
                </>
              ) : tbl === "Assign_Groups" ? (
                <>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </>
              ) : tbl === "Countries" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("name")}>Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("isoCode2")}>ISO Code 2<SortingIconComponent sortField={sortField} fieldName="isoCode2" /></th>
                  <th onClick={() => sorting("isoCode3")}>ISO Code 3<SortingIconComponent sortField={sortField} fieldName="isoCode3" /></th>
                  <th onClick={() => sorting("isoNumeric")}>ISO Numeric<SortingIconComponent sortField={sortField} fieldName="isoNumeric" /></th>
                  <th onClick={() => sorting("currency")}>Currency<SortingIconComponent sortField={sortField} fieldName="currency" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "Tiers" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  <th onClick={() => sorting("updateAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updateAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {(role?.includes("AONE_CONFIGS.write") ||
                    role?.includes("AONE_CONFIGS.admin") ||
                    role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                    role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "OdTiers" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th>Country</th>
                  <th>Tier</th>
                  <th onClick={() => sorting("destinationRegionCode")}>
                    Destination Region<SortingIconComponent sortField={sortField} fieldName="destinationRegionCode" />
                  </th>
                  <th onClick={() => sorting("destinationCityCode")}>
                    Destination City<SortingIconComponent sortField={sortField} fieldName="destinationCityCode" />
                  </th>
                  <th onClick={() => sorting("originRegionCode")}>
                    Origin Region<SortingIconComponent sortField={sortField} fieldName="originRegionCode" />
                  </th>
                  <th onClick={() => sorting("originCityCode")}>Origin City<SortingIconComponent sortField={sortField} fieldName="originCityCode" /></th>
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {(role?.includes("AONE_CONFIGS.write") ||
                    role?.includes("AONE_CONFIGS.admin") ||
                    role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                    role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "Cod" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("codAmountMin")}>
                    Min COD Amount<SortingIconComponent sortField={sortField} fieldName="codAmountMin" />
                  </th>
                  <th onClick={() => sorting("codAmountMax")}>
                    Max COD Amount<SortingIconComponent sortField={sortField} fieldName="codAmountMax" />
                  </th>
                  <th onClick={() => sorting("charge")}>Charge<SortingIconComponent sortField={sortField} fieldName="charge" /></th>
                  <th onClick={() => sorting("codChargeCalculationType")}>
                    Calculation Type<SortingIconComponent sortField={sortField} fieldName="codChargeCalculationType" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "Freight" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#</th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("destinationRegion")}>
                    Destination Region<SortingIconComponent sortField={sortField} fieldName="destinationRegion" />
                  </th>
                  <th onClick={() => sorting("courierType")}>Courier Type<SortingIconComponent sortField={sortField} fieldName="courierType" /></th>
                  <th onClick={() => sorting("chargableWeightMin")}>
                    Min chargeable weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMin" />
                  </th>
                  <th onClick={() => sorting("chargableWeightMax")}>
                    Max chargeable weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMax" />
                  </th>
                  <th onClick={() => sorting("baseCharge")}>Base charge<SortingIconComponent sortField={sortField} fieldName="baseCharge" /></th>
                  <th onClick={() => sorting("steps")}>Step size<SortingIconComponent sortField={sortField} fieldName="steps" /></th>
                  <th onClick={() => sorting("stepsPrice")}>Price per step<SortingIconComponent sortField={sortField} fieldName="stepsPrice" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "Fuel_in_perc" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("chargeCalculationType")}>
                    Percentage<SortingIconComponent sortField={sortField} fieldName="chargeCalculationType" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "Fuel_in_kg" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("chargeCalculationType")}>
                    Percentage<SortingIconComponent sortField={sortField} fieldName="chargeCalculationType" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "Lval" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("chargableWeightMin")}>
                    Min Weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMin" />
                  </th>
                  <th onClick={() => sorting("chargableWeightMax")}>
                    Max Weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMax" />
                  </th>
                  <th onClick={() => sorting("baseCharge")}>Base Charge<SortingIconComponent sortField={sortField} fieldName="baseCharge" /></th>
                  <th onClick={() => sorting("steps")}>Step<SortingIconComponent sortField={sortField} fieldName="steps" /></th>
                  <th onClick={() => sorting("stepsPrice")}>Price<SortingIconComponent sortField={sortField} fieldName="stepsPrice" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "Hval" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("declaredValueMin")}>
                    Min Declared Value<SortingIconComponent sortField={sortField} fieldName="declaredValueMin" />
                  </th>
                  <th onClick={() => sorting("declaredValueMax")}>
                    Max Declared Value<SortingIconComponent sortField={sortField} fieldName="declaredValueMax" />
                  </th>
                  <th onClick={() => sorting("charges")}>Charge<SortingIconComponent sortField={sortField} fieldName="charges" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "Insurance" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("isRiskSurcharge")}>
                    Is risk applicable<SortingIconComponent sortField={sortField} fieldName="isRiskSurcharge" />
                  </th>
                  <th onClick={() => sorting("declaredValueMin")}>
                    Min Declared Value<SortingIconComponent sortField={sortField} fieldName="declaredValueMin" />
                  </th>
                  <th onClick={() => sorting("declaredValueMax")}>
                    Max Declared Value<SortingIconComponent sortField={sortField} fieldName="declaredValueMax" />
                  </th>
                  <th onClick={() => sorting("declaredValueChargePercentage")}>
                    Percentage<SortingIconComponent sortField={sortField} fieldName="declaredValueChargePercentage" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "Rto" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("destinationRegion")}>
                    Destination Region<SortingIconComponent sortField={sortField} fieldName="destinationRegion" />
                  </th>
                  <th onClick={() => sorting("courierType")}>Courier Type<SortingIconComponent sortField={sortField} fieldName="courierType" /></th>
                  <th onClick={() => sorting("chargableWeightMin")}>
                    Min Weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMin" />
                  </th>
                  <th onClick={() => sorting("chargableWeightMax")}>
                    Max Weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMax" />
                  </th>
                  <th onClick={() => sorting("baseCharge")}>Base Charge<SortingIconComponent sortField={sortField} fieldName="baseCharge" /></th>
                  <th onClick={() => sorting("steps")}>Step<SortingIconComponent sortField={sortField} fieldName="steps" /></th>
                  <th onClick={() => sorting("stepsPrice")}>Price per step<SortingIconComponent sortField={sortField} fieldName="stepsPrice" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "VAT" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("vatChargePercentage")}>
                    Percentage<SortingIconComponent sortField={sortField} fieldName="vatChargePercentage" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "ODA" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerCode")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>Service Type<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" /></th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("chargeType")}>
                    Destination Type<SortingIconComponent sortField={sortField} fieldName="chargeType" />
                  </th>
                  <th onClick={() => sorting("chargableWeightMin")}>
                    Min Weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMin" />
                  </th>
                  <th onClick={() => sorting("chargableWeightMax")}>
                    Max Weight<SortingIconComponent sortField={sortField} fieldName="chargableWeightMax" />
                  </th>
                  <th onClick={() => sorting("baseCharge")}>Base Charge<SortingIconComponent sortField={sortField} fieldName="baseCharge" /></th>
                  <th onClick={() => sorting("steps")}>Step<SortingIconComponent sortField={sortField} fieldName="steps" /></th>
                  <th onClick={() => sorting("stepsPrice")}>Price per step<SortingIconComponent sortField={sortField} fieldName="stepsPrice" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                </>
              ) : tbl === "rates_Freight" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    # <SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Name <SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Code <SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name <SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account <SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("state")}>State <SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("structure")}>Structure <SortingIconComponent sortField={sortField} fieldName="structure" /></th>
                  <th onClick={() => sorting("paymentType")}>Payment Type <SortingIconComponent sortField={sortField} fieldName="paymentType" /></th>
                  <th onClick={() => sorting("deliveryStatus")}>
                    Delivery Status <SortingIconComponent sortField={sortField} fieldName="deliveryStatus" />
                  </th>
                  <th onClick={() => sorting("contentType")}>Content Type <SortingIconComponent sortField={sortField} fieldName="contentType" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country <SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("originCityCode")}>Origin City <SortingIconComponent sortField={sortField} fieldName="originCityCode" /></th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Des. Country <SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCityCode")}>
                    Dest. City <SortingIconComponent sortField={sortField} fieldName="destinationCityCode" />
                  </th>
                  <th onClick={() => sorting("tierCode")}>Tier <SortingIconComponent sortField={sortField} fieldName="tierCode" /></th>
                  <th onClick={() => sorting("weightUnits")}>Weight Unit <SortingIconComponent sortField={sortField} fieldName="weightUnits" /></th>
                  <th onClick={() => sorting("fromWeight")}>From Weight <SortingIconComponent sortField={sortField} fieldName="fromWeight" /></th>
                  <th onClick={() => sorting("toWeight")}>To Weight <SortingIconComponent sortField={sortField} fieldName="toWeight" /></th>
                  <th onClick={() => sorting("flatPrice")}>Flat Price <SortingIconComponent sortField={sortField} fieldName="flatPrice" /></th>
                  <th onClick={() => sorting("unit")}>Unit <SortingIconComponent sortField={sortField} fieldName="unit" /></th>
                  <th onClick={() => sorting("unitPrice")}>Unit Price <SortingIconComponent sortField={sortField} fieldName="unitPrice" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date <SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_RTO" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    # <SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("structure")}>Structure<SortingIconComponent sortField={sortField} fieldName="structure" /></th>
                  <th onClick={() => sorting("contentType")}>Content Type<SortingIconComponent sortField={sortField} fieldName="contentType" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("originCityCode")}>Origin City<SortingIconComponent sortField={sortField} fieldName="originCityCode" /></th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Des. Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCityCode")}>
                    Dest. City<SortingIconComponent sortField={sortField} fieldName="destinationCityCode" />
                  </th>
                  <th onClick={() => sorting("tierCode")}>Tier<SortingIconComponent sortField={sortField} fieldName="tierCode" /></th>
                  <th onClick={() => sorting("weightUnits")}>Weight Unit<SortingIconComponent sortField={sortField} fieldName="weightUnits" /></th>
                  <th onClick={() => sorting("fromWeight")}>From Weight<SortingIconComponent sortField={sortField} fieldName="fromWeight" /></th>
                  <th onClick={() => sorting("toWeight")}>To Weight<SortingIconComponent sortField={sortField} fieldName="toWeight" /></th>
                  <th onClick={() => sorting("flatPrice")}>Flat Price<SortingIconComponent sortField={sortField} fieldName="flatPrice" /></th>
                  <th onClick={() => sorting("unit")}>Unit<SortingIconComponent sortField={sortField} fieldName="unit" /></th>
                  <th onClick={() => sorting("unitPrice")}>Unit Price<SortingIconComponent sortField={sortField} fieldName="unitPrice" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_COD" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Des. Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("collectionMethod")}>
                    Collection Method<SortingIconComponent sortField={sortField} fieldName="collectionMethod" />
                  </th>
                  <th onClick={() => sorting("percent")}>Percent<SortingIconComponent sortField={sortField} fieldName="percent" /></th>
                  <th onClick={() => sorting("minimumCharge")}>
                    Minimum charge<SortingIconComponent sortField={sortField} fieldName="minimumCharge" />
                  </th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_ODA" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Des. Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("weightUnits")}>Weight Unit<SortingIconComponent sortField={sortField} fieldName="weightUnits" /></th>
                  <th onClick={() => sorting("fromWeight")}>From Weight<SortingIconComponent sortField={sortField} fieldName="fromWeight" /></th>
                  <th onClick={() => sorting("toWeight")}>To Weight<SortingIconComponent sortField={sortField} fieldName="toWeight" /></th>
                  <th onClick={() => sorting("flatPrice")}>Flat Price<SortingIconComponent sortField={sortField} fieldName="flatPrice" /></th>
                  <th onClick={() => sorting("unit")}>Unit<SortingIconComponent sortField={sortField} fieldName="unit" /></th>
                  <th onClick={() => sorting("unitPrice")}>Unit Price<SortingIconComponent sortField={sortField} fieldName="unitPrice" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_Fuel" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Des. Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("weightUnits")}>Weight Unit<SortingIconComponent sortField={sortField} fieldName="weightUnits" /></th>
                  <th onClick={() => sorting("unit")}>Unit<SortingIconComponent sortField={sortField} fieldName="unit" /></th>
                  <th onClick={() => sorting("unitPrice")}>Unit Price<SortingIconComponent sortField={sortField} fieldName="unitPrice" /></th>
                  <th onClick={() => sorting("percent")}>Percent<SortingIconComponent sortField={sortField} fieldName="percent" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_Insurance" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Des. Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("declaredValueMin")}>
                    Min Declared value<SortingIconComponent sortField={sortField} fieldName="declaredValueMin" />
                  </th>
                  <th onClick={() => sorting("declaredValueMax")}>
                    Max Declared value<SortingIconComponent sortField={sortField} fieldName="declaredValueMax" />
                  </th>
                  <th onClick={() => sorting("percent")}>Percent<SortingIconComponent sortField={sortField} fieldName="percent" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_Attempts" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Des. Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("charge")}>Charges<SortingIconComponent sortField={sortField} fieldName="charge" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_Vat" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("percent")}>Percent<SortingIconComponent sortField={sortField} fieldName="percent" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updateAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updateAt" /></th>
                  <th onClick={() => sorting("updateBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updateBy" /></th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "rates_Gpa" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllRatesClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product Code<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("productCode")}>Product Name<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("state")}>State<SortingIconComponent sortField={sortField} fieldName="state" /></th>
                  <th onClick={() => sorting("originCountryCode")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                  </th>
                  <th onClick={() => sorting("destinationCountryCode")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                  </th>
                  <th onClick={() => sorting("percent")}>Percent<SortingIconComponent sortField={sortField} fieldName="percent" /></th>
                  <th onClick={() => sorting("minValue")}>Minimum Value<SortingIconComponent sortField={sortField} fieldName="minValue" /></th>
                  <th onClick={() => sorting("minWeight")}>Min Weight<SortingIconComponent sortField={sortField} fieldName="minWeight" /></th>
                  <th onClick={() => sorting("maxWeight")}>Max Weight<SortingIconComponent sortField={sortField} fieldName="maxWeight" /></th>
                  <th onClick={() => sorting("effectiveDate")}>
                    Effective Date<SortingIconComponent sortField={sortField} fieldName="effectiveDate" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updateAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updateAt" /></th>
                  <th onClick={() => sorting("updateBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updateBy" /></th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                    roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "CITY" ? (
                <>
                  <th>#</th>
                  <th onClick={() => sorting("name")}>Destination City</th>
                  <th onClick={() => sorting("name")}>Destination Type Code</th>
                </>
              ) : tbl === "AWB" ? (
                <>
                  <th>#</th>
                  <th onClick={() => sorting("name")}>HAWB</th>
                  <th onClick={() => sorting("name")}>Client ID</th>
                  <th onClick={() => sorting("name")}>Client Name</th>
                  <th onClick={() => sorting("name")}>Declared Value</th>
                  <th onClick={() => sorting("name")}>Currency</th>
                  <th onClick={() => sorting("name")}>CD</th>
                  <th onClick={() => sorting("name")}>VAT</th>
                  <th onClick={() => sorting("name")}>Admin Charge</th>
                  <th onClick={() => sorting("name")}>Banyan Number</th>
                </>
              ) : tbl === "Shipsy" ? (
                <>
                  <th>#</th>
                  <th onClick={() => sorting("name")}>Node Code</th>
                  <th onClick={() => sorting("name")}>Node Name</th>
                  <th onClick={() => sorting("name")}>Parent Code</th>
                  <th onClick={() => sorting("name")}>Parent Name</th>
                </>
              ) : tbl === "Thirdparties" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th className="th-resizable">
                    <Resizable
                      width={thirdpartyColumnWidths.carriercode}
                      onResize={(e, data) =>
                        handleColumnResize("carriercode", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("code")}
                        style={{ width: thirdpartyColumnWidths.carriercode }}
                      >
                        Carrier Code<SortingIconComponent sortField={sortField} fieldName="code" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={thirdpartyColumnWidths.carriername}
                      onResize={(e, data) =>
                        handleColumnResize("carriername", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("name")}
                        style={{ width: thirdpartyColumnWidths.carriername }}
                      >
                        Carrier Name<SortingIconComponent sortField={sortField} fieldName="name" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={thirdpartyColumnWidths.status}
                      onResize={(e, data) =>
                        handleColumnResize("status", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("status")}
                        style={{ width: thirdpartyColumnWidths.status }}
                      >
                        Status<SortingIconComponent sortField={sortField} fieldName="status" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={thirdpartyColumnWidths.createdAt}
                      onResize={(e, data) =>
                        handleColumnResize("status", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("createdAt")}
                        style={{ width: thirdpartyColumnWidths.createdAt }}
                      >
                        Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={thirdpartyColumnWidths.updatedAt}
                      onResize={(e, data) =>
                        handleColumnResize("updatedAt", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("updatedAt")}
                        style={{ width: thirdpartyColumnWidths.updatedAt }}
                      >
                        Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" />
                      </div>
                    </Resizable>
                  </th>
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes(
                      "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write"
                    ) ||
                    role?.includes(
                      "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin"
                    ) ||
                    role?.includes("AONE_THIRD-PARTY.write") ||
                    role?.includes("AONE_THIRD-PARTY.admin")) && (
                    <th className="th-resizable">
                      <Resizable
                        width={thirdpartyColumnWidths.actions}
                        onResize={(e, data) =>
                          handleColumnResize("actions", data.width)
                        }
                      >
                        <div
                          onClick={() => sorting("actions")}
                          style={{ width: thirdpartyColumnWidths.actions }}
                        >
                          Actions
                        </div>
                      </Resizable>
                    </th>
                  )}
                </>
              ) : tbl === "Mapping" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th className="th-resizable">
                    <Resizable
                      width={mappingColumnWidths.carrier}
                      onResize={(e, data) =>
                        handleColumnResize("carrier", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("carrierId")}
                        style={{ width: mappingColumnWidths.carrier }}
                      >
                        Carrier<SortingIconComponent sortField={sortField} fieldName="carrierId" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={mappingColumnWidths.carrierAlias}
                      onResize={(e, data) =>
                        handleColumnResize("carrierAlias", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("alias")}
                        style={{ width: mappingColumnWidths.carrierAlias }}
                      >
                        Carrier Alias<SortingIconComponent sortField={sortField} fieldName="alias" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={mappingColumnWidths.ajexValue}
                      onResize={(e, data) =>
                        handleColumnResize("ajexValue", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("value")}
                        style={{ width: mappingColumnWidths.ajexValue }}
                      >
                        AJEX Value<SortingIconComponent sortField={sortField} fieldName="value" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={mappingColumnWidths.type}
                      onResize={(e, data) =>
                        handleColumnResize("type", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("type")}
                        style={{ width: mappingColumnWidths.type }}
                      >
                        Type<SortingIconComponent sortField={sortField} fieldName="type" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={mappingColumnWidths.createdAt}
                      onResize={(e, data) =>
                        handleColumnResize("createdAt", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("createdAt")}
                        style={{ width: mappingColumnWidths.createdAt }}
                      >
                        Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={mappingColumnWidths.updatedAt}
                      onResize={(e, data) =>
                        handleColumnResize("updatedAt", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("updatedAt")}
                        style={{ width: mappingColumnWidths.updatedAt }}
                      >
                        Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" />
                      </div>
                    </Resizable>
                  </th>
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes(
                      "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write"
                    ) ||
                    role?.includes(
                      "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin"
                    ) ||
                    role?.includes("AONE_THIRD-PARTY.write") ||
                    role?.includes("AONE_THIRD-PARTY.admin")) && (
                    <th className="th-resizable">
                      <Resizable
                        width={mappingColumnWidths.actions}
                        onResize={(e, data) =>
                          handleColumnResize("actions", data.width)
                        }
                      >
                        <div
                          style={{ width: mappingColumnWidths.actions }}
                        >
                          Actions
                        </div>
                      </Resizable>
                    </th>
                  )}
                </>
              ) : tbl === "Events" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th className="th-resizable">
                    <Resizable
                      width={eventsColumnWidths.carrier}
                      onResize={(e, data) =>
                        handleColumnResize("carrier", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("carrier")}
                        style={{ width: eventsColumnWidths.carrier }}
                      >
                        Carrier<SortingIconComponent sortField={sortField} fieldName="carrier" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={eventsColumnWidths.referenceNo}
                      onResize={(e, data) =>
                        handleColumnResize("referenceNo", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("referenceNumber")}
                        style={{ width: eventsColumnWidths.referenceNo }}
                      >
                        Reference Number<SortingIconComponent sortField={sortField} fieldName="referenceNumber" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={eventsColumnWidths.waybillNo}
                      onResize={(e, data) =>
                        handleColumnResize("waybillNo", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("trackingId")}
                        style={{ width: eventsColumnWidths.waybillNo }}
                      >
                        Waybill Number<SortingIconComponent sortField={sortField} fieldName="trackingId" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={eventsColumnWidths.status}
                      onResize={(e, data) =>
                        handleColumnResize("status", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("status")}
                        style={{ width: eventsColumnWidths.status }}
                      >
                        Status<SortingIconComponent sortField={sortField} fieldName="status" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={eventsColumnWidths.thirdpartyStatus}
                      onResize={(e, data) =>
                        handleColumnResize("thirdpartyStatus", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("thirdPartyStatus")}
                        style={{ width: eventsColumnWidths.thirdpartyStatus }}
                      >
                        Third Party's Status<SortingIconComponent sortField={sortField} fieldName="thirdPartyStatus" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={eventsColumnWidths.exception}
                      onResize={(e, data) =>
                        handleColumnResize("exception", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("exception")}
                        style={{ width: eventsColumnWidths.exception }}
                      >
                        Exception<SortingIconComponent sortField={sortField} fieldName="exception" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={eventsColumnWidths.eventTime}
                      onResize={(e, data) =>
                        handleColumnResize("eventTime", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("eventDate")}
                        style={{ width: eventsColumnWidths.eventTime }}
                      >
                        Event Time<SortingIconComponent sortField={sortField} fieldName="eventDate" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable fixedColH col_actions zIndex999">
                    <Resizable
                      width={eventsColumnWidths.actions}
                      onResize={(e, data) =>
                        handleColumnResize("actions", data.width)
                      }
                    >
                      <div style={{ width: eventsColumnWidths.actions }}>
                        Actions
                      </div>
                    </Resizable>
                  </th>
                </>
              ) : tbl === "Orders" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.carrier}
                      onResize={(e, data) =>
                        handleColumnResize("carrier", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("carrier")}
                        style={{ width: ordersColumnWidths.carrier }}
                      >
                        Carrier<SortingIconComponent sortField={sortField} fieldName="carrier" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.referenceNo}
                      onResize={(e, data) =>
                        handleColumnResize("referenceNo", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("referenceNumber")}
                        style={{ width: ordersColumnWidths.referenceNo }}
                      >
                        Reference Number<SortingIconComponent sortField={sortField} fieldName="referenceNumber" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.waybillNo}
                      onResize={(e, data) =>
                        handleColumnResize("waybillNo", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("waybillNumber")}
                        style={{ width: ordersColumnWidths.waybillNo }}
                      >
                        Waybill Number<SortingIconComponent sortField={sortField} fieldName="waybillNumber" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.status}
                      onResize={(e, data) =>
                        handleColumnResize("status", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("status")}
                        style={{ width: ordersColumnWidths.status }}
                      >
                        Status<SortingIconComponent sortField={sortField} fieldName="status" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.thirdpartyorderNum}
                      onResize={(e, data) =>
                        handleColumnResize("thirdpartyorderNum", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("thirdPartyOrderNo")}
                        style={{ width: ordersColumnWidths.thirdpartyorderNum }}
                      >
                        Third Party Order Number<SortingIconComponent sortField={sortField} fieldName="thirdPartyOrderNo" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.thirdpartyStatus}
                      onResize={(e, data) =>
                        handleColumnResize("thirdpartyStatus", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("thirdPartyStatus")}
                        style={{ width: ordersColumnWidths.thirdpartyStatus }}
                      >
                        Third Party's Status<SortingIconComponent sortField={sortField} fieldName="thirdPartyStatus" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.exception}
                      onResize={(e, data) =>
                        handleColumnResize("exception", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("exception")}
                        style={{ width: ordersColumnWidths.exception }}
                      >
                        Exception<SortingIconComponent sortField={sortField} fieldName="exception" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.eventTime}
                      onResize={(e, data) =>
                        handleColumnResize("eventTime", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("eventTime")}
                        style={{ width: ordersColumnWidths.eventTime }}
                      >
                        Event Time<SortingIconComponent sortField={sortField} fieldName="eventTime" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable">
                    <Resizable
                      width={ordersColumnWidths.pushTime}
                      onResize={(e, data) =>
                        handleColumnResize("pushTime", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("pushTime")}
                        style={{ width: ordersColumnWidths.pushTime }}
                      >
                        Push Time<SortingIconComponent sortField={sortField} fieldName="pushTime" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="th-resizable fixedColH col_actions zIndex999">
                    <Resizable
                      width={ordersColumnWidths.actions}
                      onResize={(e, data) =>
                        handleColumnResize("actions", data.width)
                      }
                    >
                      <div style={{ width: ordersColumnWidths.actions }}>
                        Actions
                      </div>
                    </Resizable>
                  </th>
                </>
              ) : tbl === "Regions" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("name")}>Region Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("code")}>Region Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("countryCode")}>Country Name<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("postalCode")}>Postal Code<SortingIconComponent sortField={sortField} fieldName="postalCode" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "Cities" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>City Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>City Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("tierCode")}>Tier<SortingIconComponent sortField={sortField} fieldName="tierCode" /></th>
                  <th onClick={() => sorting("postalCode")}>Postal Code<SortingIconComponent sortField={sortField} fieldName="postalCode" /></th>
                  <th onClick={() => sorting("remote")}>Remote<SortingIconComponent sortField={sortField} fieldName="remote" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updateAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updateAt" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "Districts" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>District Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>District Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("cityCode")}>City Name<SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("postalCode")}>Postal Code<SortingIconComponent sortField={sortField} fieldName="postalCode" /></th>
                  <th onClick={() => sorting("zoneCode")}>Zone<SortingIconComponent sortField={sortField} fieldName="zoneCode" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th className="fixedColH col_actions zIndex999">Actions</th>
                </>
              ) : tbl === "Zone" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Zone Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Zone Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("alias")}>Alias<SortingIconComponent sortField={sortField} fieldName="alias" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("groupCode")}>Zone Group<SortingIconComponent sortField={sortField} fieldName="groupCode" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "Zonegroups" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Group Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Group Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "CountriesMap" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("customerAlias")}>Alias<SortingIconComponent sortField={sortField} fieldName="customerAlias" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "RegionsMap" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerCode")}>Country<SortingIconComponent sortField={sortField} fieldName="customerCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("customerAlias")}>
                    Customer Alias<SortingIconComponent sortField={sortField} fieldName="customerAlias" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "CitiesMap" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerCode")}>Country<SortingIconComponent sortField={sortField} fieldName="customerCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("cityCode")}>City<SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>
                  <th onClick={() => sorting("customerAlias")}>
                    Customer Alias<SortingIconComponent sortField={sortField} fieldName="customerAlias" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "DistrictsMap" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("cityCode")}>City<SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>
                  <th onClick={() => sorting("districtCode")}>District<SortingIconComponent sortField={sortField} fieldName="districtCode" /></th>
                  <th onClick={() => sorting("customerAlias")}>
                    Customer Alias<SortingIconComponent sortField={sortField} fieldName="customerAlias" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "Currency" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("isoNumber")}>ISO Number<SortingIconComponent sortField={sortField} fieldName="isoNumber" /></th>
                  {(role?.includes("AONE_CONFIGS.write") ||
                    role?.includes("AONE_CONFIGS.admin") ||
                    role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.write") ||
                    role?.includes(
                      "AONE_CONFIGS_CURRENCY-CONVERSION.admin"
                    )) && (
                    <th className="fixedColH col_no zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Currencyconversion" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("fromCurrency")}>From Currency<SortingIconComponent sortField={sortField} fieldName="fromCurrency" /></th>
                  <th onClick={() => sorting("toCurrency")}>To Currency<SortingIconComponent sortField={sortField} fieldName="toCurrency" /></th>
                  <th onClick={() => sorting("rate")}>Rate<SortingIconComponent sortField={sortField} fieldName="rate" /></th>
                  {(role?.includes("AONE_CONFIGS.write") ||
                    role?.includes("AONE_CONFIGS.admin") ||
                    role?.includes("AONE_CONFIGS_CURRENCY.write") ||
                    role?.includes("AONE_CONFIGS_CURRENCY.admin")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "Crmcustomers" ? (
                <>
                  <th
                    className="fixedColH col_no zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("accountNumber")}
                  >
                    Account Number<SortingIconComponent sortField={sortField} fieldName="accountNumber" />
                  </th>
                  <th onClick={() => sorting("name")}>Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("email")}>Email<SortingIconComponent sortField={sortField} fieldName="email" /></th>
                  <th onClick={() => sorting("phone")}>Phone<SortingIconComponent sortField={sortField} fieldName="phone" /></th>
                  <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  <th onClick={() => sorting("vatNo")}>Vat Number<SortingIconComponent sortField={sortField} fieldName="vatNo" /></th>
                  <th onClick={() => sorting("crNo")}>CR Number<SortingIconComponent sortField={sortField} fieldName="crNo" /></th>
                  <th onClick={() => sorting("parentAccount")}>Parent<SortingIconComponent sortField={sortField} fieldName="parentAccount" /></th>
                  <th onClick={() => sorting("salesperson")}>Sales Person<SortingIconComponent sortField={sortField} fieldName="salesperson" /></th>
                  <th onClick={() => sorting("oraclePushed")}>
                    Is Oracle pushed<SortingIconComponent sortField={sortField} fieldName="oraclePushed" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  {(roleHandler(role, "AONE_CRM.write") ||
                              roleHandler(role, "AONE_CRM.admin") ||
                              roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
                              roleHandler(role, "AONE_CRM_CUSTOMERS.salesperson") ||
                              roleHandler(
                                role,
                                "AONE_CRM_CUSTOMERS.write"
                              )) && (
                  <th className="fixedColH col_actions zIndex999">Actions</th>)}
                </>
              ) : tbl === "Employee" ? (
                <>
                  <th
                    className="fixedColH col_no zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("code")}
                  >
                    Code<SortingIconComponent sortField={sortField} fieldName="code" />
                  </th>
                  <th onClick={() => sorting("firstName")}>First Name<SortingIconComponent sortField={sortField} fieldName="firstName" /></th>
                  <th onClick={() => sorting("lastName")}>Last Name<SortingIconComponent sortField={sortField} fieldName="lastName" /></th>
                  <th onClick={() => sorting("email")}>Email<SortingIconComponent sortField={sortField} fieldName="email" /></th>
                  <th onClick={() => sorting("department")}>Department<SortingIconComponent sortField={sortField} fieldName="department" /></th>
                  <th onClick={() => sorting("hubCode")}>Hub<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  {/* <th>Status</th> */}
                  <th onClick={() => sorting("createdAt")}>Created Date<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated Date<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  {(roleHandler(role, "AONE_CRM.admin") ||
                    roleHandler(role, "AONE_CRM.write") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.write") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES_ACCOUNTS.admin") ||
                    roleHandler(
                      role,
                      "AONE_CRM_EMPLOYEES.view_sensitive_data"
                    ) ||
                    roleHandler(
                      role,
                      "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write"
                    ) ||
                    roleHandler(
                      role,
                      "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin"
                    ) ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.write") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES_AREAS.admin")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Area" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  <th className="fixedColH col_actions zIndex999">Actions</th>
                </>
              ) : tbl === "Pickupaddress" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("name")}>Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("shipperName")}>Shipper Name<SortingIconComponent sortField={sortField} fieldName="shipperName" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("cityCode")}>City<SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>
                  <th onClick={() => sorting("districtCode")}>District<SortingIconComponent sortField={sortField} fieldName="districtCode" /></th>
                  <th onClick={() => sorting("postalCode")}>Postal Code<SortingIconComponent sortField={sortField} fieldName="postalCode" /></th>
                  <th onClick={() => sorting("shortAddress")}>
                    Short Address<SortingIconComponent sortField={sortField} fieldName="shortAddress" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "Invoice Request" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("billingCountry")}>
                    Billing Country<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("invoiceRequestStatus")}>
                    Status<SortingIconComponent sortField={sortField} fieldName="invoiceRequestStatus" />
                  </th>
                  <th
                    className="fixedColH col_no zIndex999"
                    onClick={() => sorting("id")}
                  >
                    Invoice Request Id<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("message")}>Message<SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  <th onClick={() => sorting("fromDate")}>From Date<SortingIconComponent sortField={sortField} fieldName="fromDate" /></th>
                  <th onClick={() => sorting("toDate")}>To Date<SortingIconComponent sortField={sortField} fieldName="toDate" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  {(roleHandler(role, "AONE_FINANCE.write") ||
                    roleHandler(role, "AONE_FINANCE.admin") ||
                    roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
                    roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                    roleHandler(
                      role,
                      "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write"
                    ) ||
                    roleHandler(
                      role,
                      "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Ledger" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Employee Code") && (
                    <th onClick={() => sorting("employeeCode")}>
                      Employee Code<SortingIconComponent sortField={sortField} fieldName="employeeCode" />
                    </th>
                  )}
                  {!restrict?.includes("Employee Name") && (
                    <th onClick={() => sorting("employeeCode")}>
                      Employee Name<SortingIconComponent sortField={sortField} fieldName="employeeCode" />
                    </th>
                  )}
                  {!restrict?.includes("Country") && (
                    <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  )}
                  {!restrict?.includes("Hub") && (
                    <th onClick={() => sorting("hubCode")}>Hub<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  )}
                  {!restrict?.includes("Balance") && (
                    <th onClick={() => sorting("balance")}>Balance<SortingIconComponent sortField={sortField} fieldName="balance" /></th>
                  )}
                  {!restrict?.includes("Currency") && (
                    <th onClick={() => sorting("currency")}>Currency<SortingIconComponent sortField={sortField} fieldName="currency" /></th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
                  {!restrict?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                 {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_FINANCE.read") ||
  roleHandler(role, "AONE_FINANCE.write") ||
  roleHandler(role, "AONE_FINANCE.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.read") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.settle")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Collections" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Tracking Id") && (
                    <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>
                  )}
                  {!restrict?.includes("COD Amount") && (
                    <th onClick={() => sorting("codAmount")}>COD Amount<SortingIconComponent sortField={sortField} fieldName="codAmount" /></th>
                  )}
                  {!restrict?.includes("DDU Amount") && (
                    <th onClick={() => sorting("dduAmount")}>DDU Amount<SortingIconComponent sortField={sortField} fieldName="dduAmount" /></th>
                  )}
                  {!restrict?.includes("Collected Amount") && (
                    <th onClick={() => sorting("collectedAmount")}>
                      Collected Amount<SortingIconComponent sortField={sortField} fieldName="collectedAmount" />
                    </th>
                  )}
                  {!restrict?.includes("Currency") && (
                    <th onClick={() => sorting("currency")}>Currency<SortingIconComponent sortField={sortField} fieldName="currency" /></th>
                  )}
                  {!restrict?.includes("Mode") && (
                    <th onClick={() => sorting("mode")}>Mode<SortingIconComponent sortField={sortField} fieldName="mode" /></th>
                  )}
                  {!restrict?.includes("Location") && (
                    <th onClick={() => sorting("latitude")}>Location<SortingIconComponent sortField={sortField} fieldName="latitude" /></th>
                  )}
                  {!restrict?.includes("Status") && (
                    <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  )}
                  {!restrict?.includes("Settled by") && (
                    <th onClick={() => sorting("settledBy")}>Settled by<SortingIconComponent sortField={sortField} fieldName="settledBy" /></th>
                  )}
                  {!restrict?.includes("Settlement Date") && (
                    <th onClick={() => sorting("settledAt")}>
                      Settlement Date<SortingIconComponent sortField={sortField} fieldName="settledAt" />
                    </th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Actions") && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Settle" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAllCollections}
                        onChange={handleSelectAllCollectionsClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>
                  <th onClick={() => sorting("collectedAmount")}>Amount<SortingIconComponent sortField={sortField} fieldName="collectedAmount" /></th>
                  <th onClick={() => sorting("currency")}>Currency<SortingIconComponent sortField={sortField} fieldName="currency" /></th>
                  <th onClick={() => sorting("mode")}>Mode<SortingIconComponent sortField={sortField} fieldName="mode" /></th>
                  <th onClick={() => sorting("location")}>Location<SortingIconComponent sortField={sortField} fieldName="location" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                </>
              ) : tbl === "Settlement" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Employee Code") && (
                    <th onClick={() => sorting("employeeCode")}>
                      Employee Code<SortingIconComponent sortField={sortField} fieldName="employeeCode" />
                    </th>
                  )}
                  {!restrict?.includes("Employee Name") && (
                    <th onClick={() => sorting("employeeCode")}>
                      Employee Name<SortingIconComponent sortField={sortField} fieldName="employeeCode" />
                    </th>
                  )}
                  {!restrict?.includes("Amount") && (
                    <th onClick={() => sorting("amount")}>Amount<SortingIconComponent sortField={sortField} fieldName="amount" /></th>
                  )}
                  {!restrict?.includes("Currency") && (
                    <th onClick={() => sorting("currency")}>Currency<SortingIconComponent sortField={sortField} fieldName="currency" /></th>
                  )}
                  {!restrict?.includes("Location") && (
                    <th onClick={() => sorting("location")}>Location<SortingIconComponent sortField={sortField} fieldName="location" /></th>
                  )}
                  {!restrict?.includes("Proof") && (
                    <th onClick={() => sorting("proofUrl")}>Proof<SortingIconComponent sortField={sortField} fieldName="proofUrl" /></th>
                  )}
                  {!restrict?.includes("Settled by") && (
                    <th onClick={() => sorting("settledBy")}>Settled By<SortingIconComponent sortField={sortField} fieldName="settledBy" /></th>
                  )}
                  {!restrict?.includes("Settled at") && (
                    <th onClick={() => sorting("settledAt")}>Settled At<SortingIconComponent sortField={sortField} fieldName="settledAt" /></th>
                  )}
                </>
              ) : tbl === "Consignments" ? (
                <>
                  <th
                    className="fixedColH col_no zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("customerAccountNo")}
                  >
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccountNo" />
                  </th>
                  <th onClick={() => sorting("waybillNo")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="waybillNo" /></th>
                  <th onClick={() => sorting("customerAccountName")}>
                    Customer Account Name<SortingIconComponent sortField={sortField} fieldName="customerAccountName" />
                  </th>
                  <th
                    className="fixedColH col_invoiceId zIndex999"
                    onClick={() => sorting("invoiceRequestId")}
                  >
                    Invoice Request Id<SortingIconComponent sortField={sortField} fieldName="invoiceRequestId" />
                  </th>
                  <th onClick={() => sorting("originCountry")}>
                    Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                  </th>
                  <th onClick={() => sorting("destinationCountry")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                  </th>
                  <th onClick={() => sorting("serviceTypeId")}>
                    Service Type Id<SortingIconComponent sortField={sortField} fieldName="serviceTypeId" />
                  </th>
                  <th onClick={() => sorting("consignmentStatus")}>
                    Consignment Status<SortingIconComponent sortField={sortField} fieldName="consignmentStatus" />
                  </th>
                  <th onClick={() => sorting("message")}>Message<SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  <th onClick={() => sorting("invoiceNumber")}>
                    Invoice Number<SortingIconComponent sortField={sortField} fieldName="invoiceNumber" />
                  </th>
                  <th onClick={() => sorting("transactionId")}>
                    Transaction Id<SortingIconComponent sortField={sortField} fieldName="transactionId" />
                  </th>
                  <th onClick={() => sorting("transactionDate")}>
                    Transaction Date<SortingIconComponent sortField={sortField} fieldName="transactionDate" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  {(roleHandler(role, "AONE_FINANCE.write") ||
                    roleHandler(role, "AONE_FINANCE.admin") ||
                    roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
                    roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                    roleHandler(role, "AONE_FINANCE_INVOICE-REQUEST.write") ||
                    roleHandler(
                      role,
                      "AONE_FINANCE_INVOICE-REQUEST.admin"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Invoice Request Templates" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("name")}>Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("billingCountry")}>
                    Billing Country<SortingIconComponent sortField={sortField} fieldName="billingCountry" />
                  </th>
                  <th onClick={() => sorting("operationCode")}>Status<SortingIconComponent sortField={sortField} fieldName="operationCode" /></th>
                  <th onClick={() => sorting("message")}>Message<SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  {/* <th onClick={() => sorting("code")}>From Date</th> */}
                  {/* <th onClick={() => sorting("code")}>To Date</th> */}
                  {/* <th>Actions</th> */}
                </>
              ) : tbl === "Hubs" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>
                    # <SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th onClick={() => sorting("code")}>
                    Code <SortingIconComponent sortField={sortField} fieldName="code" />
                  </th>
                  <th onClick={() => sorting("name")}>
                    Name <SortingIconComponent sortField={sortField} fieldName="name" />
                  </th>
                  <th onClick={() => sorting("alias")}>
                    Alias <SortingIconComponent sortField={sortField} fieldName="alias" />
                  </th>
                  <th onClick={() => sorting("type")}>
                    Type <SortingIconComponent sortField={sortField} fieldName="type" />
                  </th>
                  <th onClick={() => sorting("countryIsoCode3")}>
                    Country <SortingIconComponent sortField={sortField} fieldName="countryIsoCode3" />
                  </th>
                  <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("cityCode")}>City<SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>
                  <th onClick={() => sorting("latitude")}>
                    Lat <SortingIconComponent sortField={sortField} fieldName="latitude" />
                  </th>
                  <th onClick={() => sorting("longitude")}>
                    Lang <SortingIconComponent sortField={sortField} fieldName="longitude" />
                  </th>
                  <th onClick={() => sorting("description")}>
                    Description <SortingIconComponent sortField={sortField} fieldName="description" />
                  </th>
                  {(roleHandler(role, "AONE_ASSETS.write") ||
                    roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                    roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "Service Areas" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("hubCode")}>Hub code<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  <th onClick={() => sorting("zoneCode")}>Zone code<SortingIconComponent sortField={sortField} fieldName="zoneCode" /></th>
                  <th onClick={() => sorting("allowedEvents")}>
                    Allowed Events<SortingIconComponent sortField={sortField} fieldName="allowedEvents" />
                  </th>
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  {(roleHandler(role, "AONE_ASSETS.write") ||
                    roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                    roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "MessageTemplate" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Code") && (
                    <th onClick={() => sorting("code")}>Code <SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  )}
                  {!restrict?.includes("Message") && (
                    <th onClick={() => sorting("message")}>Message <SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  )}
                  {!restrict?.includes("Country") && (
                    <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  )}
                  {!restrict?.includes("Language") && (
                    <th onClick={() => sorting("language")}>Language <SortingIconComponent sortField={sortField} fieldName="language" /></th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by <SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated by <SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                  {!restrict?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated at <SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
               {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.write") ||
    roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.admin")) && (
    <th className="fixedColH col_actions zIndex999">Actions</th>  
  )}
                </>
              ) : tbl === "MessageRules" ? (
                <>
                  <th onClick={() => sorting("createdBy")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Template") && (
                    <th onClick={() => sorting("templateId")}>Template <SortingIconComponent sortField={sortField} fieldName="templateId" /></th>
                  )}
                  {!restrict?.includes("Event") && (
                    <th onClick={() => sorting("eventCode")}>Event <SortingIconComponent sortField={sortField} fieldName="eventCode" /></th>
                  )}
                  {!restrict?.includes("Status") && (
                    <th onClick={() => sorting("status")}>Status <SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  )}
                  {!restrict?.includes("Country") && (
                    <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by <SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated by <SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                  {!restrict?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated at <SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
                  {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.write") ||
    roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.admin")) && (
    <th className="fixedColH col_actions zIndex999">Actions</th>  
  )}
                </>
              ) : tbl === "MessageHistory" ? (
                <>
                  <th onClick={() => sorting("createdBy")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Tracking Id") && (
                    <th onClick={() => sorting("trackingId")}>Tracking Id <SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>
                  )}
                  {!restrict?.includes("Event") && (
                    <th onClick={() => sorting("eventName")}>Event <SortingIconComponent sortField={sortField} fieldName="eventName" /></th>
                  )}
                  {!restrict?.includes("Country") && (
                    <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  )}
                  {!restrict?.includes("Message") && (
                    <th onClick={() => sorting("message")}>Message <SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  )}
                  {!restrict?.includes("Receiver phone number") && (
                    <th onClick={() => sorting("receiverPhoneNumber")}>
                      Receiver phone number <SortingIconComponent sortField={sortField} fieldName="receiverPhoneNumber" />
                    </th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by <SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                </>
              ) : tbl === "ConsignmentOrders" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllOrdersClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  {!restrict?.includes("Tracking Id") && (
                    <th
                      className="fixedColH col_trackingId zIndex999"
                      onClick={() => sorting("trackingId")}
                    >
                      Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" />
                    </th>
                  )}
                  {!restrict?.includes("Original Tracking Id") && (
                    <th
                      className="fixedColH col_trackingId zIndex999"
                      onClick={() => sorting("trackingId")}
                    >
                      Original Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" />
                    </th>
                  )}
                  {!restrict?.includes("RTO Tracking Id") && (
                    <th
                      className="fixedColH col_trackingId zIndex999"
                      onClick={() => sorting("trackingId")}
                    >
                      RTO Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" />
                    </th>
                  )}
                  {!restrict?.includes("Reference Number") && (
                    <th onClick={() => sorting("referenceNumber")}>
                      Reference Number<SortingIconComponent sortField={sortField} fieldName="referenceNumber" />
                    </th>
                  )}
                  {!restrict?.includes("Product") && (
                    <th onClick={() => sorting("productCode")}>Product<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  )}
                  {!restrict?.includes("Status") && (
                    <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  )}
                     {!restrict?.includes("RTO") && (
                    <th>RTO</th>
                  )}
                   {!restrict?.includes("RPU") && (
                    <th >RPU</th>
                  )}
                  {!restrict?.includes("Origin Country") && (
                    <th onClick={() => sorting("originCountryCode")}>
                      Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountryCode" />
                    </th>
                  )}
                  {!restrict?.includes("Origin Region") && (
                    <th onClick={() => sorting("originRegionCode")}>
                      Origin Region<SortingIconComponent sortField={sortField} fieldName="originRegionCode" />
                    </th>
                  )}
                  {!restrict?.includes("Origin City") && (
                    <th onClick={() => sorting("originCityCode")}>
                      Origin City<SortingIconComponent sortField={sortField} fieldName="originCityCode" />
                    </th>
                  )}
                    {!restrict?.includes("Origin District") && (
                    <th>
                      Origin District
                    </th>
                  )}
                  {!restrict?.includes("Destination Country") && (
                    <th onClick={() => sorting("destinationCountryCode")}>
                      Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryCode" />
                    </th>
                  )}
                  {!restrict?.includes("Destination Region") && (
                    <th onClick={() => sorting("destinationRegionCode")}>
                      Destination Region<SortingIconComponent sortField={sortField} fieldName="destinationRegionCode" />
                    </th>
                  )}
                  {!restrict?.includes("Destination City") && (
                    <th onClick={() => sorting("destinationCityCode")}>
                      Destination City<SortingIconComponent sortField={sortField} fieldName="destinationCityCode" />
                    </th>
                  )}
                   {!restrict?.includes("Destination District") && (
                    <th>
                      Destination District
                    </th>
                  )}
                   {!restrict?.includes("Receiver Phone") && (
                    <th>
                     Receiver Phone
                    </th>
                  )}
                   {!restrict?.includes("COD Amount") && (
                    <th>
                     COD Amount
                    </th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
                  {!restrict?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                  {(roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") ||
                    roleHandler(
                      role,
                      "AONE_CONSIGNMENTS_CONSIGNMENT.edit_destination_address"
                    ) ||
                    roleHandler(
                      role,
                      "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount"
                    ) ||
                    roleHandler(
                      role,
                      "AONE_CONSIGNMENTS_CONSIGNMENT.edit_incoterms"
                    )) &&
                    !restrict?.includes("Actions") && (
                      <th className="fixedColH col_actions zIndex999">
                        Actions
                      </th>
                    )}
                </>
              ) : tbl === "OrderTracking" ? (
                <>
                  <th>#</th>
                  <th>Event Date</th>
                  <th>Event Code</th>
                  <th>Status</th>
                  <th>Description</th>
                  <th>Employee Code</th>
                  <th>Employee Name</th>
                  <th>Hub Code</th>
                  <th>Images</th>
                  <th>Signature</th>
                  <th>Failure Reason</th>
                  <th>Location</th>
                  <th>Pickup Request</th>
                  <th>Category</th>
                  <th>Third Party</th>
                  <th>Client Id</th>
                </>
                  ) : tbl === "PackageTracking" ? (
                    <>
                      <th>#</th>
                      <th>Event Date</th>
                      <th>Event Code</th>
                      <th>Status</th>
                      <th>Employee Code</th>
                      <th>Employee Name</th>
                      <th>
                        Hub Code
                      </th>
                      <th>
                        Images
                      </th>
                      <th>Signature</th>
                      <th>Failure Reason</th>
                      <th>Location</th>
                      <th>Pickup Request</th>
                      <th>Category</th>
                      <th>Third Party</th>
                      <th>Client Id</th>
                    </>
              ) : tbl === "NPR" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("message")}>Message<SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  <th onClick={() => sorting("events")}>Event<SortingIconComponent sortField={sortField} fieldName="events" /></th>
                  <th onClick={() => sorting("category")}>Category<SortingIconComponent sortField={sortField} fieldName="category" /></th>
                  <th onClick={() => sorting("requireReschedule")}>
                    Require Reschedule<SortingIconComponent sortField={sortField} fieldName="requireReschedule" />
                  </th>
                  <th onClick={() => sorting("requireAddressChange")}>
                    Require Address Change<SortingIconComponent sortField={sortField} fieldName="requireAddressChange" />
                  </th>
                  <th onClick={() => sorting("requireOtp")}>Require OTP<SortingIconComponent sortField={sortField} fieldName="requireOtp" /></th>
                  {(roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_REASONS_NPR.write") ||
                    roleHandler(role, "AONE_OPS_REASONS_NPR.admin")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "NDR" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("message")}>Message<SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  <th onClick={() => sorting("events")}>Event<SortingIconComponent sortField={sortField} fieldName="events" /></th>
                  <th onClick={() => sorting("requireReschedule")}>
                    Require Reschedule<SortingIconComponent sortField={sortField} fieldName="requireReschedule" />
                  </th>
                  <th onClick={() => sorting("requireAddressChange")}>
                    Require Address Change<SortingIconComponent sortField={sortField} fieldName="requireAddressChange" />
                  </th>
                  <th onClick={() => sorting("requireOtp")}>Require OTP<SortingIconComponent sortField={sortField} fieldName="requireOtp" /></th>
                  <th onClick={() => sorting("requireGeolocationValidation")}>
                    Require Geolocation validation<SortingIconComponent sortField={sortField} fieldName="requireGeolocationValidation" />
                  </th>
                  <th onClick={() => sorting("distance")}>
                    Distance in meters<SortingIconComponent sortField={sortField} fieldName="distance" />
                  </th>
                  {(roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
                    roleHandler(role, "AONE_OPS_REASONS_NDR.admin")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Linehauls" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("message")}>Message<SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  <th onClick={() => sorting("requireEstimateDelay")}>
                    Require estimate delay<SortingIconComponent sortField={sortField} fieldName="requireEstimateDelay" />
                  </th>
                  {(roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
                    roleHandler(role, "AONE_OPS_REASONS_NDR.admin")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "HOLD" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("message")}>Message<SortingIconComponent sortField={sortField} fieldName="message" /></th>
                  <th onClick={() => sorting("externalMessage")}>
                    External Message<SortingIconComponent sortField={sortField} fieldName="externalMessage" />
                  </th>
                  {(roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_REASONS_HOLD.write") ||
                    roleHandler(role, "AONE_OPS_REASONS_HOLD.admin")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "ConsignmentPickup" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllConsignmentsClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  {!restrict?.includes("Tracking Id") && (
                    <th
                      className="fixedColH col_trackingId zIndex999"
                      onClick={() => sorting("trackingId")}
                    >
                      Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" />
                    </th>
                  )}
                  {!restrict?.includes("Request Id") && (
                    <th
                      className="fixedColH col_cusAcc zIndex999"
                      onClick={() => sorting("pickupRequestId")}
                    >
                      Request Id<SortingIconComponent sortField={sortField} fieldName="pickupRequestId" />
                    </th>
                  )}
                    {!restrict?.includes("Shipper Name") && (
                    <th
                      onClick={() => sorting("shipperName")}
                    >
                      Shipper Name<SortingIconComponent sortField={sortField} fieldName="shipperName" />
                    </th>
                  )}
                    {!restrict?.includes("Receiver Name") && (
                    <th
                      onClick={() => sorting("receiverName")}
                    >
                      Receiver Name<SortingIconComponent sortField={sortField} fieldName="receiverName" />
                    </th>
                  )}
                  {!restrict?.includes("Customer Account") && (
                    <th onClick={() => sorting("customerAccount")}>
                      Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                    </th>
                  )}
                  {!restrict?.includes("Customer Name") && (
                    <th onClick={() => sorting("customerAccount")}>
                      Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                    </th>
                  )}
                  {!restrict?.includes("Product") && (
                    <th onClick={() => sorting("productCode")}>Product<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  )}
                  {!restrict?.includes("Status") && (
                    <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  )}
                  {!restrict?.includes("Reason") && (
                    <th onClick={() => sorting("reason")}>Reason<SortingIconComponent sortField={sortField} fieldName="reason" /></th>
                  )}
                  {!restrict?.includes("Packages Count") && (
                    <th onClick={() => sorting("packagesCount")}>
                      Package Count<SortingIconComponent sortField={sortField} fieldName="packagesCount" />
                    </th>
                  )}
                    {!restrict?.includes("Weight") && (
                    <th onClick={() => sorting("weight")}>
                      Weight<SortingIconComponent sortField={sortField} fieldName="weight" />
                    </th>
                  )}
                     {!restrict?.includes("RTO") && (
                    <th>
                      RTO
                    </th>
                  )}
                     {!restrict?.includes("RPU") && (
                    <th>
                      RPU
                    </th>
                  )}
                  {!restrict?.includes("Origin Country") && (
                    <th onClick={() => sorting("originCountry")}>
                      Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                    </th>
                  )}
                  {!restrict?.includes("Origin City") && (
                    <th onClick={() => sorting("originCity")}>Origin City<SortingIconComponent sortField={sortField} fieldName="originCity" /></th>
                  )}
                   {!restrict?.includes("Origin District") && (
                    <th onClick={() => sorting("originDistrict")}>Origin District<SortingIconComponent sortField={sortField} fieldName="originDistrict" /></th>
                  )}
                  {!restrict?.includes("Origin Hub") && (
                    <th onClick={() => sorting("originHub")}>Origin Hub<SortingIconComponent sortField={sortField} fieldName="originHub" /></th>
                  )}
                   {!restrict?.includes("Origin Zone") && (
                    <th onClick={() => sorting("originZone")}>Origin Zone<SortingIconComponent sortField={sortField} fieldName="originZone" /></th>
                  )}
                     {!restrict?.includes("Destination Country") && (
                    <th onClick={() => sorting("destinationCountry")}>
                      Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountry" />
                    </th>
                  )}
                  {!restrict?.includes("Destination City") && (
                    <th onClick={() => sorting("destinationCity")}>Destination City<SortingIconComponent sortField={sortField} fieldName="destinationCity" /></th>
                  )}
                   {!restrict?.includes("Destination District") && (
                    <th onClick={() => sorting("destinationDistrict")}>Destination District<SortingIconComponent sortField={sortField} fieldName="destinationDistrict" /></th>
                  )}
                  {!restrict?.includes("Destination Hub") && (
                    <th onClick={() => sorting("destinationHub")}>Destination Hub<SortingIconComponent sortField={sortField} fieldName="destinationHub" /></th>
                  )}
                   {!restrict?.includes("Destination Zone") && (
                    <th onClick={() => sorting("destinationZone")}>Destination Zone<SortingIconComponent sortField={sortField} fieldName="destinationZone" /></th>
                  )}
                     {!restrict?.includes("Shipper Address") && (
                    <th>Shipper Address</th>
                  )}
                      {!restrict?.includes("Shipper Phone") && (
                    <th>Shipper Phone</th>
                  )}
                       {!restrict?.includes("Receiver Phone") && (
                    <th>Receiver Phone</th>
                  )}
                  {!restrict?.includes("Courier Id") && (
                    <th onClick={() => sorting("assignedPickupCourierId")}>
                      Courier Id<SortingIconComponent sortField={sortField} fieldName="assignedPickupCourierId" />
                    </th>
                  )}
                    {!restrict?.includes("COD Amount") && (
                    <th>
                      COD Amount
                    </th>
                  )}
                    {!restrict?.includes("Pickup Date") && (
                    <th onClick={() => sorting("pickupAt")}>Pickup Date<SortingIconComponent sortField={sortField} fieldName="pickupAt" /></th>
                  )}
                    {!restrict?.includes("Delivery Date") && (
                    <th onClick={() => sorting("deliveryAt")}>Delivery Date<SortingIconComponent sortField={sortField} fieldName="deliveryAt" /></th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
                  {!restrict?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                  {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.read") ||
                  roleHandler(role, "AONE_OPS_CONSIGNMENTS.print") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup")) &&
                    !restrict?.includes("Actions") && (
                      <th className="fixedColH col_actions zIndex999">
                        Actions
                      </th>
                    )}
                </>
              ) : tbl === "Courier" ? (
                <>
                  <th
                    className="fixedColH col_no zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("employeeCode")}
                  >
                    Employee Code<SortingIconComponent sortField={sortField} fieldName="employeeCode" />
                  </th>
                  <th onClick={() => sorting("employeeName")}>Employee Name<SortingIconComponent sortField={sortField} fieldName="employeeName" /></th>
                  <th onClick={() => sorting("hubCode")}>Hub<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  <th>Assigned Tasks</th>
                  <th>Pending Tasks</th>
                  <th>Completed Tasks</th>
                  <th>Failed Tasks</th>
                  <th className="fixedColH col_actions zIndex999">Actions</th>
                </>
              ) : tbl === "Weight" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Tracking Id") && (
                  <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>)}
                   {!restrict?.includes("Package Tracking Id") && (
                  <th onClick={() => sorting("packageTrackingId")}>
                    Package Tracking Id<SortingIconComponent sortField={sortField} fieldName="packageTrackingId" />
                  </th>)}
                  {!restrict?.includes("Product") && (
                  <th onClick={() => sorting("productCode")}>Product<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>)}
                   {!restrict?.includes("Customer") && (
                  <th onClick={() => sorting("customerAccount")}>Customer<SortingIconComponent sortField={sortField} fieldName="customerAccount" /></th>)}
                   {!restrict?.includes("Weight Unit") && (
                  <th onClick={() => sorting("weightUnit")}>Weight Unit<SortingIconComponent sortField={sortField} fieldName="weightUnit" /></th>)}
                   {!restrict?.includes("Original Weight") && (
                  <th onClick={() => sorting("originalWeight")}>
                    Original Weight<SortingIconComponent sortField={sortField} fieldName="originalWeight" />
                  </th>)}
                  {!restrict?.includes("New Weight") && (
                  <th onClick={() => sorting("newWeight")}>New Weight<SortingIconComponent sortField={sortField} fieldName="newWeight" /></th>)}
                   {!restrict?.includes("Dimension Unit") && (
                  <th onClick={() => sorting("dimensionUnit")}>
                    Dimension Unit<SortingIconComponent sortField={sortField} fieldName="dimensionUnit" />
                  </th>)}
                  {!restrict?.includes("Original Dimensions") && (
                  <th>
                    Original Dimensions
                  </th>)}
                  {!restrict?.includes("New Dimensions") && (
                  <th>
                    New Dimensions
                  </th>)}
                  {!restrict?.includes("Original volumetric weight") && (
                  <th>
                    Original volumetric weight
                  </th>)}
                  {!restrict?.includes("New volumetric weight") && (
                  <th>
                    New volumetric weight
                  </th>)}
                  {!restrict?.includes("Tool") && (
                  <th onClick={() => sorting("tool")}>Tool<SortingIconComponent sortField={sortField} fieldName="tool" /></th>)}
                   {!restrict?.includes("Created At") && (
                  <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>)}
                  {!restrict?.includes("Created By") && (
                  <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>)}
                  {!restrict?.includes("Updated At") && (
                  <th onClick={() => sorting("updatedAt")}>Updated At<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>)}
                  {!restrict?.includes("Updated By") && (
                  <th onClick={() => sorting("updatedBy")}>Updated By<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>)}
                </>
              ) : tbl === "DeliveryFlow" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customers")}>Customers<SortingIconComponent sortField={sortField} fieldName="customers" /></th>
                  <th onClick={() => sorting("productCode")}>Product<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("countryCode")}>Country<SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("requireOTP")}>Require OTP<SortingIconComponent sortField={sortField} fieldName="requireOTP" /></th>
                  <th onClick={() => sorting("requireReceiver")}>
                    Require Receiver Id/Name<SortingIconComponent sortField={sortField} fieldName="requireReceiver" />
                  </th>
                  <th onClick={() => sorting("requireProof")}>
                    Require Proof of delivery<SortingIconComponent sortField={sortField} fieldName="requireProof" />
                  </th>
                  <th onClick={() => sorting("requireSignature")}>
                    Require Signature<SortingIconComponent sortField={sortField} fieldName="requireSignature" />
                  </th>
                  <th>Actions</th>
                </>
              ) : tbl === "Drivers" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code<SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("tripId")}>Trip Id<SortingIconComponent sortField={sortField} fieldName="tripId" /></th>
                  <th onClick={() => sorting("activeVehicle")}>Vehicle<SortingIconComponent sortField={sortField} fieldName="activeVehicle" /></th>
                  <th onClick={() => sorting("phoneNumber")}>Phone Number<SortingIconComponent sortField={sortField} fieldName="phoneNumber" /></th>
                  <th onClick={() => sorting("payrollType")}>Payroll Type<SortingIconComponent sortField={sortField} fieldName="payrollType" /></th>
                  <th onClick={() => sorting("payrollCompany")}>
                    Payroll Company<SortingIconComponent sortField={sortField} fieldName="payrollCompany" />
                  </th>
                  <th onClick={() => sorting("drivingLicenseUrl")}>
                    Driving License<SortingIconComponent sortField={sortField} fieldName="drivingLicenseUrl" />
                  </th>
                  <th onClick={() => sorting("drivingLicenseExpireDate")}>
                    Driving License Expire date<SortingIconComponent sortField={sortField} fieldName="drivingLicenseExpireDate" />
                  </th>
                  <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  {(roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                    roleHandler(role, "AONE_OPS_DRIVERS.admin")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "Trips" ? (
                <>
                  <th
                    className="fixedColH col_no zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  {!restrict?.includes("Trip Id") && (
                    <th
                      className="fixedColH col_index zIndex999"
                      onClick={() => sorting("tripId")}
                    >
                      Trip Id<SortingIconComponent sortField={sortField} fieldName="tripId" />
                    </th>
                  )}
                  {!restrict?.includes("Origin Hub") && (
                    <th onClick={() => sorting("originHubCode")}>Origin Hub<SortingIconComponent sortField={sortField} fieldName="originHubCode" /></th>
                  )}
                  {!restrict?.includes("Destination Hub") && (
                    <th onClick={() => sorting("destinationHubCode")}>
                      Destination Hub<SortingIconComponent sortField={sortField} fieldName="destinationHubCode" />
                    </th>
                  )}
                  {!restrict?.includes("Vehicle") && (
                    <th onClick={() => sorting("vehicleCode")}>Vehicle<SortingIconComponent sortField={sortField} fieldName="vehicleCode" /></th>
                  )}
                  {!restrict?.includes("Route") && (
                    <th onClick={() => sorting("routeCode")}>Route<SortingIconComponent sortField={sortField} fieldName="routeCode" /></th>
                  )}
                  {!restrict?.includes("Status") && (
                    <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  )}
                  {!restrict?.includes("Estimated Arrival Date") && (
                    <th onClick={() => sorting("estimatedArrivalDate")}>
                      Estimated Arrival Date<SortingIconComponent sortField={sortField} fieldName="estimatedArrivalDate" />
                    </th>
                  )}
                  {!restrict?.includes("Total Shipments count") && (
                    <th onClick={() => sorting("totalShipments")}>
                      Total Shipments count<SortingIconComponent sortField={sortField} fieldName="totalShipments" />
                    </th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
                  {!restrict?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                  {(roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_TRIPS.write") ||
                    roleHandler(role, "AONE_OPS_TRIPS.admin")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Sequencer" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("startRange")}>Start Range<SortingIconComponent sortField={sortField} fieldName="startRange" /></th>
                  <th onClick={() => sorting("sequence")}>Current sequence<SortingIconComponent sortField={sortField} fieldName="sequence" /></th>
                  <th onClick={() => sorting("endRange")}>End Range<SortingIconComponent sortField={sortField} fieldName="endRange" /></th>
                  <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  <th onClick={() => sorting("returnCreatedOrder")}>
                    Return created order<SortingIconComponent sortField={sortField} fieldName="returnCreatedOrder" />
                  </th>
                  {(roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.write") ||
                    roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.admin")) && (
                  <th>Actions</th>)}
                </>
              ) : tbl === "TripTracking" ? (
                <>
                  <th>#</th>
                  <th>Event Status</th>
                  <th>Event Code</th>
                  <th>Event Date</th>
                  <th>Hub Code</th>
                  <th>Seal Number</th>
                  <th>Images</th>
                  <th>Reason Message</th>
                  <th>Location</th>
                </>
              ) : tbl === "Routes" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>
    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
  </th>
  <th onClick={() => sorting("code")}>
    Code<SortingIconComponent sortField={sortField} fieldName="code" />
  </th>
  <th onClick={() => sorting("name")}>
    Name<SortingIconComponent sortField={sortField} fieldName="name" />
  </th>
  <th onClick={() => sorting("status")}>
    Status<SortingIconComponent sortField={sortField} fieldName="status" />
  </th>
  <th onClick={() => sorting("productCode")}>
    Product<SortingIconComponent sortField={sortField} fieldName="productCode" />
  </th>
  <th onClick={() => sorting("originHubCode")}>
    Origin Hub<SortingIconComponent sortField={sortField} fieldName="originHubCode" />
  </th>
  <th onClick={() => sorting("destinationHubCode")}>
    Destination Hub<SortingIconComponent sortField={sortField} fieldName="destinationHubCode" />
  </th>
  <th className="fixedColH col_actions zIndex999">Actions</th>
                </>
              ) : tbl === "RtoRequest" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAllRto}
                        onChange={handleSelectAllRtoClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Tracking Id") && (
                    <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>
                  )}
                  {!restrict?.includes("RTO Tracking Id") && (
                    <th onClick={() => sorting("rtoTrackingId")}>
                      RTO Tracking Id<SortingIconComponent sortField={sortField} fieldName="rtoTrackingId" />
                    </th>
                  )}
                  {!restrict?.includes("Package Count") && (
                    <th onClick={() => sorting("packagesCount")}>
                      Package Count<SortingIconComponent sortField={sortField} fieldName="packagesCount" />
                    </th>
                  )}
                  {!restrict?.includes("Customer") && (
                    <th onClick={() => sorting("customerAccount")}>Customer<SortingIconComponent sortField={sortField} fieldName="customerAccount" /></th>
                  )}
                  {!restrict?.includes("Status") && (
                    <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  )}
                  {!restrict?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrict?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
                  {!restrict?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                {!restrict?.includes("Actions") && 
  (roleHandler(role, "AONE_OPS.write") ||
   roleHandler(role, "AONE_OPS.admin") ||
   roleHandler(role, "AONE_OPS_RTO.write")) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "CourierTasks" ? (
                <>
                  <th className="fixedColH col_no zIndex999" onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th className="fixedColH col_Firstname zIndex999" onClick={() => sorting("trackingId")}>
                    Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" />
                  </th>
                  <th onClick={() => sorting("pickupRequestId")}>Pickup request<SortingIconComponent sortField={sortField} fieldName="pickupRequestId" /></th>
                  <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  <th onClick={() => sorting("originZone")}>Origin Zone<SortingIconComponent sortField={sortField} fieldName="originZone" /></th>
                  <th onClick={() => sorting("originHub")}>Origin Hub<SortingIconComponent sortField={sortField} fieldName="originHub" /></th>
                  <th>ACL Number</th>
                  <th>Destination Zone</th>
                  <th>Destination hub</th>
                  <th className="fixedColH col_actions zIndex999">Task type</th>
                </>
              ) : tbl === "Vehicles" ? (
                <>
                  <th
                    className="fixedColH col_no zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    # <SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("code")}
                  >
                    Code <SortingIconComponent sortField={sortField} fieldName="code" />
                  </th>
                  <th onClick={() => sorting("plateNumber")}>
                    Plate Number <SortingIconComponent sortField={sortField} fieldName="plateNumber" />
                  </th>
                  <th onClick={() => sorting("countryCode")}>
                    Country <SortingIconComponent sortField={sortField} fieldName="countryCode" />
                  </th>
                  <th onClick={() => sorting("type")}>
                    Type <SortingIconComponent sortField={sortField} fieldName="type" />
                  </th>
                  <th onClick={() => sorting("categoryCode")}>
                    Vehicle Category <SortingIconComponent sortField={sortField} fieldName="categoryCode" />
                  </th>
                  <th onClick={() => sorting("hubCode")}>
                    Hub <SortingIconComponent sortField={sortField} fieldName="hubCode" />
                  </th>
                  <th onClick={() => sorting("")}>
                    Odometer <SortingIconComponent sortField={sortField} fieldName="odometer" />
                  </th>
                  <th onClick={() => sorting("weightCapacity")}>
                    Weight Capacity <SortingIconComponent sortField={sortField} fieldName="weightCapacity" />
                  </th>
                  <th onClick={() => sorting("weightUnit")}>
                    Weight Unit <SortingIconComponent sortField={sortField} fieldName="weightUnit" />
                  </th>
                  <th onClick={() => sorting("volumeCapacity")}>
                    Volume Capacity <SortingIconComponent sortField={sortField} fieldName="volumeCapacity" />
                  </th>
                  <th onClick={() => sorting("dimensionUnit")}>
                    Dimension Unit <SortingIconComponent sortField={sortField} fieldName="dimensionUnit" />
                  </th>
                  <th onClick={() => sorting("vendorCode")}>
                    Vendor <SortingIconComponent sortField={sortField} fieldName="vendorCode" />
                  </th>
                  <th onClick={() => sorting("model")}>
                    Model <SortingIconComponent sortField={sortField} fieldName="model" />
                  </th>
                  <th onClick={() => sorting("manufactureYear")}>
                    Manufacture Year <SortingIconComponent sortField={sortField} fieldName="manufactureYear" />
                  </th>
                  <th onClick={() => sorting("hasGPS")}>
                    Has GPS <SortingIconComponent sortField={sortField} fieldName="hasGPS" />
                  </th>
                  <th onClick={() => sorting("gpsVendor")}>
                    GPS Vendor <SortingIconComponent sortField={sortField} fieldName="gpsVendor" />
                  </th>
                  <th onClick={() => sorting("vehicleStatus")}>
                    Status <SortingIconComponent sortField={sortField} fieldName="vehicleStatus" />
                  </th>
                  <th onClick={() => sorting("replacement")}>
                    Replacement <SortingIconComponent sortField={sortField} fieldName="replacement" />
                  </th>
                  <th onClick={() => sorting("employeeCode")}>
                    Employee <SortingIconComponent sortField={sortField} fieldName="employeeCode" />
                  </th>
                  <th onClick={() => sorting("status")}>
                    Active <SortingIconComponent sortField={sortField} fieldName="status" />
                  </th>
                  {(roleHandler(role, "AONE_ASSETS.write") ||
                    roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.write") ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_VEHICLES.admin"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Vehicle Category" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code <SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Name <SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("description")}>Description <SortingIconComponent sortField={sortField} fieldName="description" /></th>
                  {(roleHandler(role, "AONE_ASSETS.write") ||
                    roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_CATEGORIES.write"
                    ) ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_CATEGORIES.admin"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Vendors" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code <SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Name <SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  {(roleHandler(role, "AONE_ASSETS.write") ||
                    roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.write") ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_VENDORS.admin"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "GpsVendors" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code <SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Name <SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  {(roleHandler(role, "AONE_ASSETS.write") ||
                    roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin"
                    ) ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_GPS-VENDORS.write"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Odometer" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("vehicleCode")}>Vehicle <SortingIconComponent sortField={sortField} fieldName="vehicleCode" /></th>
                  <th onClick={() => sorting("odometer")}>Odometer <SortingIconComponent sortField={sortField} fieldName="odometer" /></th>
                  <th onClick={() => sorting("createdAt")}>Created at <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created by <SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  {(roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_ODOMETER.admin"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Vehicle Documents" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("vehicleCode")}>Vehicle <SortingIconComponent sortField={sortField} fieldName="vehicleCode" /></th>
                  <th onClick={() => sorting("rcDocumentUrl")}>RC Document <SortingIconComponent sortField={sortField} fieldName="rcDocumentUrl" /></th>
                  <th onClick={() => sorting("rcValidityDate")}>
                    RC Validity Date <SortingIconComponent sortField={sortField} fieldName="rcValidityDate" />
                  </th>
                  <th onClick={() => sorting("insuranceDocumentUrl")}>
                    Insurance Document <SortingIconComponent sortField={sortField} fieldName="insuranceDocumentUrl" />
                  </th>
                  <th onClick={() => sorting("insuranceValidityDate")}>
                    Insurance Validity Date <SortingIconComponent sortField={sortField} fieldName="insuranceValidityDate" />
                  </th>
                  <th onClick={() => sorting("pucDocumentUrl")}>
                    PUC Document <SortingIconComponent sortField={sortField} fieldName="pucDocumentUrl" />
                  </th>
                  <th onClick={() => sorting("pucValidityDate")}>
                    PUC Validity Date <SortingIconComponent sortField={sortField} fieldName="pucValidityDate" />
                  </th>
                  <th onClick={() => sorting("opsCard")}>OPS Card <SortingIconComponent sortField={sortField} fieldName="opsCard" /></th>
                  {(roleHandler(role, "AONE_ASSETS.write") ||
                    roleHandler(role, "AONE_ASSETS.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                    roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.write") ||
                    roleHandler(
                      role,
                      "AONE_ASSETS_VEHICLES_DOCUMENTS.admin"
                    )) && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "Pickup Request" ? (
                <>
                  <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllConsignmentsClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th
                    className="fixedColH col_Firstname zIndex999"
                    onClick={() => sorting("createdAt")}
                  >
                    #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                  </th>
                  {!restrictPickupreq?.includes("Request Id") && (
                    <th
                      className="fixedColH col_trackingId zIndex999"
                      onClick={() => sorting("requestId")}
                    >
                      Request Id<SortingIconComponent sortField={sortField} fieldName="requestId" />
                    </th>
                  )}
                  {!restrictPickupreq?.includes("Customer Account") && (
                    <th
                      className="fixedColH col_cusNum zIndex999"
                      onClick={() => sorting("customerAccount")}
                    >
                      Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                    </th>
                  )}
                  {!restrictPickupreq?.includes("Customer Name") && (
                    <th onClick={() => sorting("customerAccount")}>
                      Customer Name<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                    </th>
                  )}
                   {!restrictPickupreq?.includes("Shipper Name") && (
                    <th onClick={() => sorting("shipperName")}>
                      Shipper Name<SortingIconComponent sortField={sortField} fieldName="shipperName" />
                    </th>
                  )}
                  {!restrictPickupreq?.includes("Status") && <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>}
                  {!restrictPickupreq?.includes("Total Packages") && (
                    <th onClick={() => sorting("totalPackagesCount")}>Total Packages<SortingIconComponent sortField={sortField} fieldName="totalPackagesCount" /></th>
                  )}
                  {!restrictPickupreq?.includes("Total Count") && (
                    <th onClick={() => sorting("totalCount")}>Total Count<SortingIconComponent sortField={sortField} fieldName="totalCount" /></th>
                  )}
                  {!restrictPickupreq?.includes("Success Count") && (
                    <th onClick={() => sorting("successCount")}>
                      Success Count<SortingIconComponent sortField={sortField} fieldName="successCount" />
                    </th>
                  )}
                  {!restrictPickupreq?.includes("Failed Count") && (
                    <th onClick={() => sorting("failedCount")}>Failed Count<SortingIconComponent sortField={sortField} fieldName="failedCount" /></th>
                  )}
                   {!restrictPickupreq?.includes("RPU") && (
                    <th>RPU</th>
                  )}
                  {!restrictPickupreq?.includes("Reason") && (
                    <th onClick={() => sorting("reason")}>Reason<SortingIconComponent sortField={sortField} fieldName="reason" /></th>
                  )}
                  {!restrictPickupreq?.includes("Courier Id") && (
                    <th onClick={() => sorting("employeeCode")}>Courier Id<SortingIconComponent sortField={sortField} fieldName="employeeCode" /></th>
                  )}
                  {!restrictPickupreq?.includes("Origin Country") && (
                    <th onClick={() => sorting("originCountry")}>
                      Origin Country<SortingIconComponent sortField={sortField} fieldName="originCountry" />
                    </th>
                  )}
                  {!restrictPickupreq?.includes("Origin City") && (
                    <th onClick={() => sorting("originCity")}>Origin City<SortingIconComponent sortField={sortField} fieldName="originCity" /></th>
                  )}
                   {!restrictPickupreq?.includes("Shipper Address") && (
                    <th>Shipper Address</th>
                  )}
                   {!restrictPickupreq?.includes("Shipper Phone") && (
                    <th>Shipper Phone</th>
                  )}
                  {!restrictPickupreq?.includes("Created at") && (
                    <th onClick={() => sorting("createdAt")}>Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrictPickupreq?.includes("Created by") && (
                    <th onClick={() => sorting("createdBy")}>Created by<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {!restrictPickupreq?.includes("Updated at") && (
                    <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>
                  )}
                  {!restrictPickupreq?.includes("Updated by") && (
                    <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>
                  )}
                  {!restrictPickupreq?.includes("Actions") && (
                    <th className="fixedColH col_actions zIndex999">Actions</th>
                  )}
                </>
              ) : tbl === "PickupConsignment" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Tracking Id") && <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>}
                  {!restrict?.includes("Customer Account") && (
                    <th onClick={() => sorting("customerAccount")}>Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" /></th>
                  )}
                  {!restrict?.includes("Product") && <th onClick={() => sorting("productCode")}>Product<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>}
                  {!restrict?.includes("Packages Count") && (
                    <th onClick={() => sorting("packagesCount")}>Package Count<SortingIconComponent sortField={sortField} fieldName="packagesCount" /></th>
                  )}
                    {!restrict?.includes("RPU") && (
                    <th>RPU</th>
                  )}
                  {!restrict?.includes("Status") && <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>}
                  {!restrict?.includes("Content") && <th onClick={() => sorting("content")}>Content<SortingIconComponent sortField={sortField} fieldName="content" /></th>}
                  {!restrict?.includes("Reason") && <th onClick={() => sorting("reason")}>Reason<SortingIconComponent sortField={sortField} fieldName="reason" /></th>}
                  {!restrict?.includes("Weight Unit") && <th onClick={() => sorting("weightUnit")}>Weight Unit<SortingIconComponent sortField={sortField} fieldName="weightUnit" /></th>}
                  {!restrict?.includes("Weight") && <th onClick={() => sorting("weight")}>Weight<SortingIconComponent sortField={sortField} fieldName="weight" /></th>}
                  {!restrict?.includes("Updated at") && <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>}
                  {!restrict?.includes("Updated by") && <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>}
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin")) &&
                    !restrict?.includes("Actions") && (
                      <th className="fixedColH col_actions zIndex999">
                        Actions
                      </th>
                    )}
                </>
                  ) : tbl === "ShortAddressHistory" ? (
                    <>
                      <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                      {!restrict?.includes("Short address") && <th onClick={() => sorting("shortAddress")}>Short address<SortingIconComponent sortField={sortField} fieldName="shortAddress" /></th>}
                      {!restrict?.includes("Region") && (
                        <th onClick={() => sorting("region")}>Region<SortingIconComponent sortField={sortField} fieldName="region" /></th>
                      )}
                      {!restrict?.includes("City") && <th onClick={() => sorting("city")}>City<SortingIconComponent sortField={sortField} fieldName="city" /></th>}
                      {!restrict?.includes("District") && (
                        <th onClick={() => sorting("district")}>District<SortingIconComponent sortField={sortField} fieldName="district" /></th>
                      )}
                      {!restrict?.includes("SPL Region") && <th onClick={() => sorting("splRegion")}>SPL Region<SortingIconComponent sortField={sortField} fieldName="splRegion" /></th>}
                      {!restrict?.includes("SPL Governorate") && <th onClick={() => sorting("splGovernorate")}>SPL Governorate<SortingIconComponent sortField={sortField} fieldName="splGovernorate" /></th>}
                      {!restrict?.includes("SPL City") && <th onClick={() => sorting("splCity")}>SPL City<SortingIconComponent sortField={sortField} fieldName="splCity" /></th>}
                      {!restrict?.includes("SPL District") && <th onClick={() => sorting("splDistrict")}>SPL District<SortingIconComponent sortField={sortField} fieldName="splDistrict" /></th>}
                      {!restrict?.includes("Additional number") && <th onClick={() => sorting("additionalNumber")}>Additional number<SortingIconComponent sortField={sortField} fieldName="additionalNumber" /></th>}
                      {!restrict?.includes("Created at") && <th onClick={() => sorting("createdAt")}>Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>}
                      {!restrict?.includes("Created by") && <th onClick={() => sorting("createdBy")}>Created by<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>}
                      {!restrict?.includes("Updated at") && <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>}
                      {!restrict?.includes("Updated by") && <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>}
                    </>
                                      ) : tbl === "ShortAddressMapping" ? (
                                        <>
                                          <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                                          {!restrict?.includes("SPL Region") && <th onClick={() => sorting("splRegionName")}>SPL Region<SortingIconComponent sortField={sortField} fieldName="splRegionName" /></th>}
                                          {!restrict?.includes("SPL Governorate") && (
                                            <th onClick={() => sorting("splGovernorate")}>SPL Governorate<SortingIconComponent sortField={sortField} fieldName="splGovernorate" /></th>
                                          )}
                                          {!restrict?.includes("SPL City") && <th onClick={() => sorting("splCityName")}>SPL City<SortingIconComponent sortField={sortField} fieldName="splCityName" /></th>}
                                          {!restrict?.includes("SPL District") && (
                                            <th onClick={() => sorting("splDistrictName")}>SPL District<SortingIconComponent sortField={sortField} fieldName="splDistrictName" /></th>
                                          )}
                                          {!restrict?.includes("Region") && <th onClick={() => sorting("regionCode")}>Region<SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>}
                                          {!restrict?.includes("City") && <th onClick={() => sorting("cityCode")}>City<SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>}
                                          {!restrict?.includes("District") && <th onClick={() => sorting("districtCode")}>District<SortingIconComponent sortField={sortField} fieldName="districtCode" /></th>}
                                          {!restrict?.includes("Created at") && <th onClick={() => sorting("createdAt")}>Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>}
                                          {!restrict?.includes("Created by") && <th onClick={() => sorting("createdBy")}>Created by<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>}
                                          {!restrict?.includes("Updated at") && <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>}
                                          {!restrict?.includes("Updated by") && <th onClick={() => sorting("updatedAt")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>}
                                          {( role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write") ||
                  role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin")) &&
                                            !restrict?.includes("Actions") && (
                                              <th className="fixedColH col_actions zIndex999">
                                                Actions
                                              </th>
                                            )}
                                        </>
                      ) : tbl === "PickupConsignment" ? (
                        <>
                          <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                          {!restrict?.includes("Tracking Id") && <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>}
                          {!restrict?.includes("Customer Account") && (
                            <th onClick={() => sorting("customerAccount")}>Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" /></th>
                          )}
                          {!restrict?.includes("Product") && <th onClick={() => sorting("productCode")}>Product<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>}
                          {!restrict?.includes("Packages Count") && (
                            <th onClick={() => sorting("packagesCount")}>Package Count<SortingIconComponent sortField={sortField} fieldName="packagesCount" /></th>
                          )}
                           {!restrict?.includes("RPU") && (
                    <th>RPU</th>
                  )}
                          {!restrict?.includes("Status") && <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>}
                          {!restrict?.includes("Reason") && <th onClick={() => sorting("reason")}>Reason<SortingIconComponent sortField={sortField} fieldName="reason" /></th>}
                          {!restrict?.includes("Weight Unit") && <th onClick={() => sorting("weightUnit")}>Weight Unit<SortingIconComponent sortField={sortField} fieldName="weightUnit" /></th>}
                          {!restrict?.includes("Weight") && <th onClick={() => sorting("weight")}>Weight<SortingIconComponent sortField={sortField} fieldName="weight" /></th>}
                          {!restrict?.includes("Updated at") && <th onClick={() => sorting("updatedAt")}>Updated at<SortingIconComponent sortField={sortField} fieldName="updatedAt" /></th>}
                          {!restrict?.includes("Updated by") && <th onClick={() => sorting("updatedBy")}>Updated by<SortingIconComponent sortField={sortField} fieldName="updatedBy" /></th>}
                          {!restrict?.includes("Actions") && (
                              <th className="fixedColH col_actions zIndex999">
                                Actions
                              </th>
                            )}
                        </>
              ) : tbl === "ACL" ? (
                <>
                   <th className="fixedColH col_no zIndex999">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAllAcl}
                        onChange={handleSelectAllAclClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  {!restrict?.includes("Acl Number") && (
                    <th onClick={() => sorting("number")}>Acl Number<SortingIconComponent sortField={sortField} fieldName="number" /></th>
                  )}
                  {/* {!restrict?.includes("Seal Number") && (
                    <th onClick={() => sorting("sealNumber")}>Seal Number<SortingIconComponent sortField={sortField} fieldName="sealNumber" /></th>
                  )} */}
                  {!restrict?.includes("Trip Id") && (
                    <th onClick={() => sorting("tripId")}>Trip Id<SortingIconComponent sortField={sortField} fieldName="tripId" /></th>
                  )}
                  {!restrict?.includes("Total Shipments") && (
                    <th onClick={() => sorting("totalShipment")}>
                      Total Shipments<SortingIconComponent sortField={sortField} fieldName="totalShipment" />
                    </th>
                  )}
                  {!restrict?.includes("Type") && (
                    <th onClick={() => sorting("type")}>Type<SortingIconComponent sortField={sortField} fieldName="type" /></th>
                  )}
                  {!restrict?.includes("Origin Hub") && (
                    <th onClick={() => sorting("originHubCode")}>Origin Hub<SortingIconComponent sortField={sortField} fieldName="originHubCode" /></th>
                  )}
                  {!restrict?.includes("Destination Hub") && (
                    <th onClick={() => sorting("destinationHubCode")}>
                      Destination Hub<SortingIconComponent sortField={sortField} fieldName="destinationHubCode" />
                    </th>
                  )}
                  {!restrict?.includes("Destination Zone") && (
                    <th onClick={() => sorting("destinationZoneCode")}>
                      Destination Zone<SortingIconComponent sortField={sortField} fieldName="destinationZoneCode" />
                    </th>
                  )}
                  {/* {!restrict?.includes("Route") && <th>Route</th>} */}
                  {!restrict?.includes("Assignee") && (
                    <th onClick={() => sorting("assignee")}>Assignee<SortingIconComponent sortField={sortField} fieldName="assignee" /></th>
                  )}
                  {!restrict?.includes("Assigned By") && (
                    <th onClick={() => sorting("assignedBy")}>Assigned By<SortingIconComponent sortField={sortField} fieldName="assignedBy" /></th>
                  )}
                  {!restrict?.includes("Total Weight") && (
                    <th onClick={() => sorting("totalWeight")}>Total Weight<SortingIconComponent sortField={sortField} fieldName="totalWeight" /></th>
                  )}
                  {!restrict?.includes("Weight Unit") && (
                    <th onClick={() => sorting("totalWeightUnit")}>
                      Weight Unit<SortingIconComponent sortField={sortField} fieldName="totalWeightUnit" />
                    </th>
                  )}
                  {!restrict?.includes("Created At") && (
                    <th onClick={() => sorting("createdAt")}>Created At<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  )}
                  {!restrict?.includes("Created By") && (
                    <th onClick={() => sorting("createdBy")}>Created By<SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  )}
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS_ACL.write") ||
                    roleHandler(role, "AONE_OPS_ACL.admin")) &&
                    !restrict?.includes("Actions") && (
                      <th className="fixedColH col_actions zIndex999">
                        Actions
                      </th>
                    )}
                </>
              ) : tbl === "AclTracking" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("eventTime")}>Event Date<SortingIconComponent sortField={sortField} fieldName="eventTime" /></th>
                  <th onClick={() => sorting("eventCode")}>Event Code<SortingIconComponent sortField={sortField} fieldName="eventCode" /></th>
                  <th onClick={() => sorting("eventStatus")}>Status<SortingIconComponent sortField={sortField} fieldName="eventStatus" /></th>
                  {/* <th>Count</th> */}
                  <th onClick={() => sorting("employeeCode")}>Employee Code<SortingIconComponent sortField={sortField} fieldName="employeeCode" /></th>
                  <th onClick={() => sorting("hubCode")}>Hub Code<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  <th onClick={() => sorting("sealNumber")}>Seal Number<SortingIconComponent sortField={sortField} fieldName="sealNumber" /></th>
                  <th onClick={() => sorting("vehicleId")}>Vehicle Id<SortingIconComponent sortField={sortField} fieldName="vehicleId" /></th>
                </>
              ) : tbl === "AclConsignments" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("aclNumber")}>ACL Number<SortingIconComponent sortField={sortField} fieldName="aclNumber" /></th>
                  <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>
                  <th onClick={() => sorting("packageTrackingId")}>
                    Package Tracking Id<SortingIconComponent sortField={sortField} fieldName="packageTrackingId" />
                  </th>
                  <th onClick={() => sorting("originHubCode")}>Origin Hub<SortingIconComponent sortField={sortField} fieldName="originHubCode" /></th>
                  <th onClick={() => sorting("destinationHubCode")}>
                    Destination Hub<SortingIconComponent sortField={sortField} fieldName="destinationHubCode" />
                  </th>
                  <th onClick={() => sorting("destinationZoneCode")}>
                    Destination Zone<SortingIconComponent sortField={sortField} fieldName="destinationZoneCode" />
                  </th>
                  {/* <th onClick={() => sorting("createdAt")}>Destination Zone Group</th> */}
                  <th onClick={() => sorting("errorMsg")}>Error Message<SortingIconComponent sortField={sortField} fieldName="errorMsg" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "DlValidations" ? (
                <>
                  <th>#</th>
                  <th>Tracking Id</th>
                  <th>Package Tracking Id</th>
                  <th>Is Multipieces</th>
                  <th>Courier Name</th>
                  <th>Attempts</th>
                  <th>Hub</th>
                  {(roleHandler(role, "AONE_OPS.admin") ||
  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write")) && (
                  <th>Actions</th>)}
                </>
              ) : tbl === "DeliveryTasks" ? (
                <>
                  <th className="fixedColH col_no zIndex999" onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                
                  <th className="fixedColH col_Firstname zIndex999" onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>
                  <th className="fixedColH col_cusNum zIndex999" onClick={() => sorting("destinationZoneName")}>
                    Destination Zone<SortingIconComponent sortField={sortField} fieldName="destinationZoneName" />
                  </th>
                  <th onClick={() => sorting("customerAccount")}>
                    Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount" />
                  </th>
                  <th onClick={() => sorting("productCode")}>Product<SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  <th onClick={() => sorting("hubCode")}>Hub<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  <th onClick={() => sorting("employeeCode")}>Courier<SortingIconComponent sortField={sortField} fieldName="employeeCode" /></th>
                  <th onClick={() => sorting("packageCount")}>Package Count<SortingIconComponent sortField={sortField} fieldName="packageCount" /></th>
                  <th onClick={() => sorting("assignedBy")}>Assigned by<SortingIconComponent sortField={sortField} fieldName="assignedBy" /></th>
                  <th onClick={() => sorting("assignedAt")}>Assigned at<SortingIconComponent sortField={sortField} fieldName="assignedAt" /></th>
                  <th onClick={() => sorting("unassignedBy")}>Unassigned by<SortingIconComponent sortField={sortField} fieldName="unassignedBy" /></th>
                  <th onClick={() => sorting("unassignedAt")}>Unassigned at<SortingIconComponent sortField={sortField} fieldName="unassignedAt" /></th>
                  <th onClick={() => sorting("destinationCountryName")}>
                    Destination Country<SortingIconComponent sortField={sortField} fieldName="destinationCountryName" />
                  </th>
                  <th onClick={() => sorting("destinationCityName")}>
                    Destination City<SortingIconComponent sortField={sortField} fieldName="destinationCityName" />
                  </th>
                  <th onClick={() => sorting("destinationDistrictName")}>
                    Destination District<SortingIconComponent sortField={sortField} fieldName="destinationDistrictName" />
                  </th>
                  {(roleHandler(role, "AONE_OPS.write") ||
                 roleHandler(role, "AONE_OPS.admin") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write")) && (
                  <th className="th-resizable fixedColH col_actions zIndex999">
                    Actions
                  </th>)}
                </>
                   ) : tbl === "Debrief" ? (
                    <>
                      <th>#</th>
                      <th>Tracking Id</th>
                      <th>Package Tracking Id</th>
                      <th>Status</th>
                      <th>Reasons</th>
                      <th>Courier Id</th>
                      <th>Attempts</th>
                      <th>Hub</th>
                      <th>Location</th>
                      <th>Proof</th>
                    </>
                        ) : tbl === "DebreifingHistory" ? (
                          <>
                            <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                            <th onClick={() => sorting("courierId")}>Courier Id<SortingIconComponent sortField={sortField} fieldName="courierId" /></th>
                            <th onClick={() => sorting("courierName")}>Courier Name<SortingIconComponent sortField={sortField} fieldName="courierName" /></th>
                            <th onClick={() => sorting("hubCode")}>Hub<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                            <th onClick={() => sorting("deliveredCount")}>Delivered Count<SortingIconComponent sortField={sortField} fieldName="deliveredCount" /></th>
                            <th onClick={() => sorting("receivedCount")}>Received Count<SortingIconComponent sortField={sortField} fieldName="receivedCount" /></th>
                            <th onClick={() => sorting("debriefingAt")}>Debriefing Date<SortingIconComponent sortField={sortField} fieldName="debriefingAt" /></th>
                            <th onClick={() => sorting("debriefingBy")}>Debriefer Id<SortingIconComponent sortField={sortField} fieldName="debriefingBy" /></th>
                            <th onClick={() => sorting("debriefingName")}>Debriefer Name<SortingIconComponent sortField={sortField} fieldName="debriefingName" /></th>
                          </>
                          ) : tbl === "Configs" ? (
                            <>
                              <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                              <th onClick={() => sorting("name")}>Configuration Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                              <th onClick={() => sorting("printBag")}>Print Bag<SortingIconComponent sortField={sortField} fieldName="printBag" /></th>
                              <th onClick={() => sorting("description")}>Description<SortingIconComponent sortField={sortField} fieldName="description" /></th>
                              <th
                                className={
                                  !(
                                    roleHandler(role, "CONFIGS.write") ||
                                    roleHandler(role, "CONFIGS.admin")
                                  ) && "displayNone"
                                }
                              >
                                Actions
                              </th>
                              <th>Chute Mapping</th>
                            </>
              ) : tbl === "Configs_Ops" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("name")}>Configuration Name<SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("aclType")}>ACL Type<SortingIconComponent sortField={sortField} fieldName="aclType" /></th>
                  <th onClick={() => sorting("printACL")}>Print ACL Label<SortingIconComponent sortField={sortField} fieldName="printACL" /></th>
                  <th onClick={() => sorting("description")}>Description<SortingIconComponent sortField={sortField} fieldName="description" /></th>
                  {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
                            roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin")) && (
                  <th>
                    Actions
                  </th>)}
                  <th>Chute Mapping</th>
                </>
                ) : tbl === "DetailedConfig" ? (
                  <>
                    <th style={{ width: "5px" }}>
                      <div className="col-md-12 smallCheckbox">
                        <input
                          type="checkbox"
                          id="tableCheckall"
                          checked={selectAll}
                          onChange={handleSelectAllClick}
                        />
                        <label htmlFor="tableCheckall" />
                      </div>
                    </th>
                    <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                    <th onClick={() => sorting("zoneCode")}>Zone Code<SortingIconComponent sortField={sortField} fieldName="zoneCode" /></th>
                    <th onClick={() => sorting("printBag")}>Print Bag<SortingIconComponent sortField={sortField} fieldName="printBag" /></th>
                    <th onClick={() => sorting("zoneGroup")}>Zone Group<SortingIconComponent sortField={sortField} fieldName="zoneGroup" /></th>
                    <th onClick={() => sorting("chuteNumber")}>Chute Number<SortingIconComponent sortField={sortField} fieldName="chuteNumber" /></th>
                    <th onClick={() => sorting("hubCode")}>Hub Code<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                    <th onClick={() => sorting("hubCode")}>Hub Name<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                    <th
                      className={
                        !(
                          roleHandler(role, "CONFIGS.write") ||
                          roleHandler(role, "CONFIGS.admin")
                        ) && "displayNone"
                      }
                    >
                      Actions
                    </th>
                  </>
              ) : tbl === "DetailedConfig_Ops" ? (
                <>
                  <th style={{ width: "5px" }}>
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllClick}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("zoneCode")}>Zone Code<SortingIconComponent sortField={sortField} fieldName="zoneCode" /></th>
                  <th>Zone Group</th>
                  <th onClick={() => sorting("chuteNumber")}>Chute Number<SortingIconComponent sortField={sortField} fieldName="chuteNumber" /></th>
                  <th onClick={() => sorting("hubCode")}>Hub Code<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  <th onClick={() => sorting("printBag")}>Print ACL<SortingIconComponent sortField={sortField} fieldName="printBag" /></th>
                  {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
                            roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin")) && (
                  <th >
                    Actions
                  </th>)}
                </>
                 ) : tbl === "Agents" ? (
                  <>
                    <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                    <th onClick={() => sorting("agentId")}>Agent Id<SortingIconComponent sortField={sortField} fieldName="agentId" /></th>
                    <th onClick={() => sorting("assignedConfigId")}>
                      Assigned Configuration<SortingIconComponent sortField={sortField} fieldName="assignedConfigId" />
                    </th>
                    <th onClick={() => sorting("healthy")}>Health<SortingIconComponent sortField={sortField} fieldName="healthy" /></th>
                    <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                    <th onClick={() => sorting("")}>Hub Code<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                    <th onClick={() => sorting("hubCode")}>Hub Name<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                    <th
                      className={
                        !(
                          roleHandler(role, "CONFIGS.write") ||
                          roleHandler(role, "CONFIGS.admin")
                        ) && "displayNone"
                      }
                    >
                      Actions
                    </th>
                  </>
              ) : tbl === "Agents_Ops" ? (
                <>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("agentId")}>Agent Id<SortingIconComponent sortField={sortField} fieldName="agentId" /></th>
                  <th onClick={() => sorting("assignedConfigId")}>
                    Assigned Configuration<SortingIconComponent sortField={sortField} fieldName="assignedConfigId" />
                  </th>
                  <th onClick={() => sorting("status")}>Status<SortingIconComponent sortField={sortField} fieldName="status" /></th>
                  <th onClick={() => sorting("hubCode")}>Hub<SortingIconComponent sortField={sortField} fieldName="hubCode" /></th>
                  {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.write") ||
                            roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.admin")) && (
                  <th>
                    Actions
                  </th>)}
                </>
                   ) : tbl === "Machinescan" ? (
                    <>
                      <th>
                        <div className="col-md-12 smallCheckbox">
                          <input
                            type="checkbox"
                            id="tableCheckall"
                            checked={selectAll}
                            onChange={handleSelectAllClickScan}
                          />
                          <label htmlFor="tableCheckall" />
                        </div>
                      </th>
                      <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.agentId}
                          onResize={(e, data) =>
                            handleColumnResize("agentId", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("agentId")}
                            style={{ width: columnWidths.agentId }}
                          >
                            Agent Id<SortingIconComponent sortField={sortField} fieldName="agentId" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.waybillNumber}
                          onResize={(e, data) =>
                            handleColumnResize("waybillNumber", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("waybillNumber")}
                            style={{ width: columnWidths.waybillNumber }}
                          >
                            WaybillNumber<SortingIconComponent sortField={sortField} fieldName="waybillNumber" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.customerCode}
                          onResize={(e, data) =>
                            handleColumnResize("customerCode", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("customerCode")}
                            style={{ width: columnWidths.customerCode }}
                          >
                            Customer Code<SortingIconComponent sortField={sortField} fieldName="customerCode" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.zoneCode}
                          onResize={(e, data) =>
                            handleColumnResize("zoneCode", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("zoneCode")}
                            style={{ width: columnWidths.zoneCode }}
                          >
                            Zone Code<SortingIconComponent sortField={sortField} fieldName="zoneCode" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.zoneGroup}
                          onResize={(e, data) =>
                            handleColumnResize("zoneGroup", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("zoneGroup")}
                            style={{ width: columnWidths.zoneGroup }}
                          >
                            Zone Group<SortingIconComponent sortField={sortField} fieldName="zoneGroup" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.chuteNum}
                          onResize={(e, data) =>
                            handleColumnResize("chuteNum", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("chute")}
                            style={{ width: columnWidths.chuteNum }}
                          >
                            Chute Number<SortingIconComponent sortField={sortField} fieldName="chute" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.currentHubCode}
                          onResize={(e, data) =>
                            handleColumnResize("currentHubCode", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("currentHubCode")}
                            style={{ width: columnWidths.currentHubCode }}
                          >
                            Current Hub<SortingIconComponent sortField={sortField} fieldName="currentHubCode" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.desHubCode}
                          onResize={(e, data) =>
                            handleColumnResize("desHubCode", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("desHubCode")}
                            style={{ width: columnWidths.desHubCode }}
                          >
                            Destination Hub<SortingIconComponent sortField={sortField} fieldName="desHubCode" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.currentStatus}
                          onResize={(e, data) =>
                            handleColumnResize("currentStatus", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("currentStatus")}
                            style={{ width: columnWidths.currentStatus }}
                          >
                            Current Status<SortingIconComponent sortField={sortField} fieldName="currentStatus" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.sortingConfig}
                          onResize={(e, data) =>
                            handleColumnResize("sortingConfig", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("configSummary")}
                            style={{ width: columnWidths.sortingConfig }}
                          >
                            Sorting Config<SortingIconComponent sortField={sortField} fieldName="configSummary" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.weight}
                          onResize={(e, data) =>
                            handleColumnResize("weight", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("weight")}
                            style={{ width: columnWidths.weight }}
                          >
                            Weight<SortingIconComponent sortField={sortField} fieldName="weight" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.volume}
                          onResize={(e, data) =>
                            handleColumnResize("volume", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("length")}
                            style={{ width: columnWidths.volume }}
                          >
                            Volumes<SortingIconComponent sortField={sortField} fieldName="length" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.scanTime}
                          onResize={(e, data) =>
                            handleColumnResize("scanTime", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("scanTime")}
                            style={{ width: columnWidths.scanTime }}
                          >
                            Scan Time<SortingIconComponent sortField={sortField} fieldName="scanTime" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.result}
                          onResize={(e, data) =>
                            handleColumnResize("result", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("result")}
                            style={{ width: columnWidths.result }}
                          >
                            Result<SortingIconComponent sortField={sortField} fieldName="result" />
                          </div>
                        </Resizable>
                      </th>
                      <th className="th-resizable">
                        <Resizable
                          width={columnWidths.reason}
                          onResize={(e, data) =>
                            handleColumnResize("reason", data.width)
                          }
                        >
                          <div
                            onClick={() => sorting("reason")}
                            style={{ width: columnWidths.reason }}
                          >
                            Reason<SortingIconComponent sortField={sortField} fieldName="reason" />
                          </div>
                        </Resizable>
                      </th>
                      {(roleHandler(role, "CONFIGS.write") ||
                        roleHandler(role, "CONFIGS.admin")) && (
                        <th className="fixedColH col_bagactions zIndex999">
                          <Resizable
                            width={columnWidths.actions}
                            onResize={(e, data) =>
                              handleColumnResize("actions", data.width)
                            }
                          >
                            <div
                              onClick={() => sorting("actions")}
                              style={{ width: columnWidths.actions }}
                            >
                              Actions
                            </div>
                          </Resizable>
                        </th>
                      )}
                    </>
              ) : tbl === "Machinescan_Ops" ? (
                <>
                  <th>
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllClickScan}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("agentId")}> Agent Id<SortingIconComponent sortField={sortField} fieldName="agentId" /></th>
                  <th onClick={() => sorting("trackingId")}>Tracking Id<SortingIconComponent sortField={sortField} fieldName="trackingId" /></th>
                  <th onClick={() => sorting("packageTrackingId")}>Package Tracking Id<SortingIconComponent sortField={sortField} fieldName="packageTrackingId" /></th>
                  <th onClick={() => sorting("customerAccount")} >Customer Account<SortingIconComponent sortField={sortField} fieldName="customerAccount"/></th>
                  <th onClick={() => sorting("zoneCode")}
                      >
                        Zone Code<SortingIconComponent sortField={sortField} fieldName="zoneCode" />
                  </th>
                  <th onClick={() => sorting("zoneGroup")}
                      >
                        Zone Group<SortingIconComponent sortField={sortField} fieldName="zoneGroup" />
                  </th>
                  <th onClick={() => sorting("hubCode")}
                      >
                        Current Hub<SortingIconComponent sortField={sortField} fieldName="hubCode" />
                  </th>
                  <th  onClick={() => sorting("desHubCode")}
                      >
                        Destination Hub<SortingIconComponent sortField={sortField} fieldName="desHubCode" />
                  </th>
                  <th onClick={() => sorting("weight")}
                      >
                        Weight<SortingIconComponent sortField={sortField} fieldName="weight" />
                  </th>
                  <th  onClick={() => sorting("length")}
                      >
                        Volumes<SortingIconComponent sortField={sortField} fieldName="length" />
                  </th>
                  <th onClick={() => sorting("scanTime")}
                      >
                        Scan Time<SortingIconComponent sortField={sortField} fieldName="scanTime" />
                  </th>
                    <th className="fixedColH col_bagactions zIndex999">
                        <div
                          onClick={() => sorting("actions")}
                        >
                          Actions
                        </div>
                    </th>
                </>
              ) : tbl === "jobOrders" ? (
                <>
                  <th>#</th>
                  <th>Code</th>
                  <th>Customer Account</th>
                  <th>Estimate Receive Date</th>
                  <th>Schedule Date</th>
                  <th>Fulfillment Status</th>
                  <th>Box Count</th>
                  <th>Status</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Handover" ? (
                <>
                  <th>#</th>
                  <th>Fullfillment Employee</th>
                  <th>Hub Operator</th>
                  <th>Job Order Code</th>
                  <th>Box Quantity</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Delivered" ? (
                <>
                  <th>#</th>
                  <th>Courier</th>
                  <th>Job Order</th>
                  <th>Waybill Number</th>
                  <th>Delivery Date</th>
                  <th>Delivery Location</th>
                  <th>Customer Account</th>
                  <th>Customer Name</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Origins" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region <SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("cityCode")}>City <SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "Destinations" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("countryCode")}>Country <SortingIconComponent sortField={sortField} fieldName="countryCode" /></th>
                  <th onClick={() => sorting("regionCode")}>Region <SortingIconComponent sortField={sortField} fieldName="regionCode" /></th>
                  <th onClick={() => sorting("cityCode")}>City <SortingIconComponent sortField={sortField} fieldName="cityCode" /></th>
                  <th>Actions</th>
                </>
              ) : tbl === "transittime" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("productCode")}>Product <SortingIconComponent sortField={sortField} fieldName="productCode" /></th>
                  {/* <th>Product Name</th> */}
                  <th onClick={() => sorting("originCountryName")}>
                    Origin Country <SortingIconComponent sortField={sortField} fieldName="originCountryName" />
                  </th>
                  <th onClick={() => sorting("originRegionName")}>
                    Origin Region <SortingIconComponent sortField={sortField} fieldName="originRegionName" />
                  </th>
                  <th onClick={() => sorting("originCityName")}>Origin City<SortingIconComponent sortField={sortField} fieldName="originCityName" /></th>
                  <th onClick={() => sorting("destinationCountryName")}>
                    Destination Country <SortingIconComponent sortField={sortField} fieldName="destinationCountryName" />
                  </th>
                  <th onClick={() => sorting("destinationRegionName")}>
                    Destination Region <SortingIconComponent sortField={sortField} fieldName="destinationRegionName" />
                  </th>
                  <th onClick={() => sorting("destinationCityName")}>
                    Destination City <SortingIconComponent sortField={sortField} fieldName="destinationCityName" />
                  </th>
                  <th onClick={() => sorting("days")}>Days <SortingIconComponent sortField={sortField} fieldName="days" /></th>
                  <th onClick={() => sorting("createdAt")}>Created At <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By <SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("updateAt")}>Updated At <SortingIconComponent sortField={sortField} fieldName="updateAt" /></th>
                  <th onClick={() => sorting("updateBy")}>Updated By <SortingIconComponent sortField={sortField} fieldName="updateBy" /></th>
                  <th className="fixedColH col_actions zIndex999">Actions</th>
                </>
              ) : tbl === "Products" ? (
                <>
                  <th onClick={() => sorting("createdAt")}># <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("code")}>Code <SortingIconComponent sortField={sortField} fieldName="code" /></th>
                  <th onClick={() => sorting("name")}>Name <SortingIconComponent sortField={sortField} fieldName="name" /></th>
                  <th onClick={() => sorting("contentType")}>Content Type <SortingIconComponent sortField={sortField} fieldName="contentType" /></th>
                  <th onClick={() => sorting("createdBy")}>Created By <SortingIconComponent sortField={sortField} fieldName="createdBy" /></th>
                  <th onClick={() => sorting("createdAt")}>Created Date <SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th onClick={() => sorting("updateBy")}>Updated By <SortingIconComponent sortField={sortField} fieldName="updateBy" /></th>
                  <th onClick={() => sorting("updateAt")}>Updated Date <SortingIconComponent sortField={sortField} fieldName="updateAt" /></th>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_PRODUCT.admin") ||
                    roleHandler(role, "AONE_MASTERS_PRODUCT.write")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "Customers" ? (
                <>
                  <th>#</th>
                  <th>Account Number</th>
                  <th>Registration Number</th>
                  <th>Company Name</th>
                  <th>VAT Number</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Invoices" ? (
                <>
                  <th>#</th>
                  <th>Waybill Number</th>
                  <th>Account Number</th>
                  <th>Oracle Invoice Number</th>
                  <th>Business Line</th>
                  <th>Status</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Bags" ? (
                <>
                  <th>
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllClickBag}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.bagNumber}
                      onResize={(e, data) =>
                        handleColumnResize("bagNumber", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("bagNumber")}
                        style={{ width: bagColumnWidths.bagNumber }}
                      >
                        Bag Number<SortingIconComponent sortField={sortField} fieldName="bagNumber" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.zoneGroup}
                      onResize={(e, data) =>
                        handleColumnResize("zoneGroup", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("zoneGroup")}
                        style={{ width: bagColumnWidths.zoneGroup }}
                      >
                        Zone Group<SortingIconComponent sortField={sortField} fieldName="zoneGroup" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.sourceHub}
                      onResize={(e, data) =>
                        handleColumnResize("sourceHub", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("sourceHubCode")}
                        style={{ width: bagColumnWidths.sourceHub }}
                      >
                        Source Hub<SortingIconComponent sortField={sortField} fieldName="sourceHubCode" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.destinationHubCode}
                      onResize={(e, data) =>
                        handleColumnResize("destinationHub", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("destinationHub")}
                        style={{ width: bagColumnWidths.destinationHub }}
                      >
                        Destination Hub<SortingIconComponent sortField={sortField} fieldName="destinationHub" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.zoneLevel}
                      onResize={(e, data) =>
                        handleColumnResize("zoneLevel", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("zoneLevel")}
                        style={{ width: bagColumnWidths.zoneLevel }}
                      >
                        Zone Level<SortingIconComponent sortField={sortField} fieldName="destinationHub" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.destinationZone}
                      onResize={(e, data) =>
                        handleColumnResize("destinationZone", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("destinationZoneCode")}
                        style={{ width: bagColumnWidths.destinationZone }}
                      >
                        Destination Zone<SortingIconComponent sortField={sortField} fieldName="destinationZoneCode" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.zoneGroupLevel}
                      onResize={(e, data) =>
                        handleColumnResize("zoneGroupLevel", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("zoneGroupLevel")}
                        style={{
                          width: bagColumnWidths.zonzoneGroupLeveleLevel,
                        }}
                      >
                        Zone Group Level<SortingIconComponent sortField={sortField} fieldName="zoneGroupLevel" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.destinationZoneGroupCode}
                      onResize={(e, data) =>
                        handleColumnResize(
                          "destinationZoneGroupCode",
                          data.width
                        )
                      }
                    >
                      <div
                        onClick={() => sorting("destinationZoneGroupCode")}
                        style={{
                          width: bagColumnWidths.destinationZoneGroupCode,
                        }}
                      >
                        Destination Zone Group<SortingIconComponent sortField={sortField} fieldName="destinationZoneGroupCode" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.chute}
                      onResize={(e, data) =>
                        handleColumnResize("chute", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("chute")}
                        style={{ width: bagColumnWidths.chute }}
                      >
                        Chute Number<SortingIconComponent sortField={sortField} fieldName="chute" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.totalWeight}
                      onResize={(e, data) =>
                        handleColumnResize("totalWeight", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("totalWeight")}
                        style={{ width: bagColumnWidths.totalWeight }}
                      >
                        Total Weight<SortingIconComponent sortField={sortField} fieldName="totalWeight" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.totalCount}
                      onResize={(e, data) =>
                        handleColumnResize("totalCount", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("totalCount")}
                        style={{ width: bagColumnWidths.totalCount }}
                      >
                        Number of Waybills<SortingIconComponent sortField={sortField} fieldName="totalCount" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.valid}
                      onResize={(e, data) =>
                        handleColumnResize("valid", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("valid")}
                        style={{ width: bagColumnWidths.valid }}
                      >
                        Valid<SortingIconComponent sortField={sortField} fieldName="valid" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.sortingConfig}
                      onResize={(e, data) =>
                        handleColumnResize("sortingConfig", data.width)
                      }
                    >
                      <div
                        style={{ width: bagColumnWidths.sortingConfigs }}
                      >
                        Sorting Config
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={bagColumnWidths.tripId}
                      onResize={(e, data) =>
                        handleColumnResize("tripId", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("tripId")}
                        style={{ width: bagColumnWidths.tripId }}
                      >
                        Trip ID<SortingIconComponent sortField={sortField} fieldName="tripId" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    {/* <Resizable
                      width={bagColumnWidths.creationAt}
                      onResize={(e, data) =>
                        handleColumnResize("creationAt", data.width)
                      }
                    > */}
                    <div
                      onClick={() => sorting("createdAt")}
                      style={{ width: bagColumnWidths.creationAt }}
                    >
                      Created at<SortingIconComponent sortField={sortField} fieldName="createdAt" />
                    </div>
                    {/* </Resizable> */}
                  </th>
                  <th className="fixedColH col_bagactions zIndex999">
                    <Resizable
                      width={bagColumnWidths.actions}
                      onResize={(e, data) =>
                        handleColumnResize("actions", data.width)
                      }
                    >
                      <div
                        style={{ width: bagColumnWidths.actions }}
                      >
                        Actions
                      </div>
                    </Resizable>
                  </th>
                </>
              ) : tbl === "DetailedBags" ? (
                <>
                  <th>
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="tableCheckall"
                        checked={selectAll}
                        onChange={handleSelectAllClickConsignment}
                      />
                      <label htmlFor="tableCheckall" />
                    </div>
                  </th>
                  <th onClick={() => sorting("createdAt")}>#<SortingIconComponent sortField={sortField} fieldName="createdAt" /></th>
                  <th className="fixedColH col_Firstname zIndex999">
                    <Resizable
                      width={waybillColumnWidths.waybillNumber}
                      onResize={(e, data) =>
                        handleColumnResize("waybillNumber", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("waybillNumber")}
                        style={{ width: waybillColumnWidths.waybillNumber }}
                      >
                        Waybill Number<SortingIconComponent sortField={sortField} fieldName="waybillNumber" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.weight}
                      onResize={(e, data) =>
                        handleColumnResize("weight", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("weight")}
                        style={{ width: waybillColumnWidths.weight }}
                      >
                        Weight<SortingIconComponent sortField={sortField} fieldName="weight" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.length}
                      onResize={(e, data) =>
                        handleColumnResize("length", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("length")}
                        style={{ width: waybillColumnWidths.length }}
                      >
                        Length<SortingIconComponent sortField={sortField} fieldName="length" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.width}
                      onResize={(e, data) =>
                        handleColumnResize("width", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("width")}
                        style={{ width: waybillColumnWidths.width }}
                      >
                        Width<SortingIconComponent sortField={sortField} fieldName="width" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.height}
                      onResize={(e, data) =>
                        handleColumnResize("height", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("height")}
                        style={{ width: waybillColumnWidths.height }}
                      >
                        Height<SortingIconComponent sortField={sortField} fieldName="height" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.receiveTime}
                      onResize={(e, data) =>
                        handleColumnResize("receiveTime", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("receiveTime")}
                        style={{ width: waybillColumnWidths.receiveTime }}
                      >
                        Receive Time<SortingIconComponent sortField={sortField} fieldName="receiveTime" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.dropTime}
                      onResize={(e, data) =>
                        handleColumnResize("dropTime", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("dropTime")}
                        style={{ width: waybillColumnWidths.dropTime }}
                      >
                        Drop Time<SortingIconComponent sortField={sortField} fieldName="dropTime" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.valid}
                      onResize={(e, data) =>
                        handleColumnResize("valid", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("valid")}
                        style={{ width: waybillColumnWidths.valid }}
                      >
                        Valid<SortingIconComponent sortField={sortField} fieldName="valid" />
                      </div>
                    </Resizable>
                  </th>
                  <th>
                    <Resizable
                      width={waybillColumnWidths.errorMessage}
                      onResize={(e, data) =>
                        handleColumnResize("errorMessage", data.width)
                      }
                    >
                      <div
                        onClick={() => sorting("errorMsg")}
                        style={{ width: waybillColumnWidths.errorMessage }}
                      >
                        Error Message<SortingIconComponent sortField={sortField} fieldName="errorMsg" />
                      </div>
                    </Resizable>
                  </th>
                  <th className="fixedColH col_actions zIndex999">
                    <Resizable
                      width={waybillColumnWidths.actions}
                      onResize={(e, data) =>
                        handleColumnResize("actions", data.width)
                      }
                    >
                      <div
                        style={{ width: waybillColumnWidths.actions }}
                      >
                        Actions
                      </div>
                    </Resizable>
                  </th>
                </>
              ) : (
                <td className="textAlignCenter" colSpan={19}>
                  No Data
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData?.map((e, i) => {
                return (
                  <>
                    {tbl === "onDemand" ? (
                      <>
                        <tr key={e.id}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("waybillnumber") && (
                            <td className="fixedCol col_Firstname" scope="row">
                              {e.waybillnumber}
                            </td>
                          )}
                          {!restrict?.includes("bookingHub") && (
                            <td>{e.bookingHub}</td>
                          )}
                          {!restrict?.includes("deliveryHub") && (
                            <td>{e.deliveryHub}</td>
                          )}
                          {!restrict?.includes("creationTime") && (
                            <td>
                              {new Date(e?.creationTime)?.toLocaleDateString()}
                            </td>
                          )}
                          {!restrict?.includes("pickUpTime") && (
                            <td>{e.pickUpTime}</td>
                          )}
                          {!restrict?.includes("pickby") && <td>{e.pickby}</td>}
                          {!restrict?.includes("OfdTime") && (
                            <td>{e.OfdTime}</td>
                          )}
                          {!restrict?.includes("deliveredBy") && (
                            <td>{e.deliveredBy}</td>
                          )}
                          {!restrict?.includes("deliveryTime") && (
                            <td>{e.deliveryTime}</td>
                          )}
                          {!restrict?.includes("serviceType") && (
                            <td>{e.serviceType}</td>
                          )}
                          {!restrict?.includes("status") && <td>{e.status}</td>}
                          {!restrict?.includes("sla") && <td>{e.sla}</td>}
                          {!restrict?.includes("customerName") && (
                            <td>{e.customerName}</td>
                          )}
                          {!restrict?.includes("customerCode") && (
                            <td>{e.customerCode}</td>
                          )}
                          {!restrict?.includes("timeSinceCreation") && (
                            <td>{e.timeSinceCreation}</td>
                          )}
                          {!restrict?.includes("timeSincePickup") && (
                            <td>{e.timeSincePickup}</td>
                          )}
                          {!restrict?.includes("pickupDuration") && (
                            <td>{e.pickupDuration}</td>
                          )}
                          {!restrict?.includes("deliveryDuration") && (
                            <td>{e.deliveryDuration}</td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "sla" ? (
                      <>
                        <CusModal
                          key={e.id}
                          btnName=""
                          title="View SLA"
                          initialData={tblDataDup[i]}
                          setStateData={updateName}
                          submit={submit}
                          cancleChange={cancleChange}
                          handleDelete={handleDelete}
                        >
                          <td>{e.serviceType}</td>
                          <td>{e.customerAccount}</td>
                          <td>{e.slaMinutes}</td>
                          <td>{e.country}</td>
                          <td>{e.originCity}</td>
                          <td>{e.destinationCity}</td>
                          {/* <td></td> */}
                        </CusModal>
                        {/* <img src={delete_icon} onClick={()=>handleDelete(e.id)}/> */}
                      </>
                    ) : tbl === "ZIA" ? (
                      <>
                        <tr className="cursorPointer">
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("storeId") && (
                            <td className="fixedCol col_Firstname" scope="row">
                              {e?.storeId}
                            </td>
                          )}
                          {!restrict?.includes("productName") && (
                            <td>{e?.name?.en}</td>
                          )}
                          {!restrict?.includes("sku") && <td>{e?.sku}</td>}
                          {!restrict?.includes("quantity") && (
                            <td>{e?.quantity}</td>
                          )}
                          {!restrict?.includes("parentProduct") && (
                            <td>{e?.parentProduct?.en}</td>
                          )}
                          {e?.parentProduct !== null ||
                          e?.variants?.length < 1 ? (
                            <td>
                              <button
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                                title="View"
                                className="tableBtn"
                              >
                                <span>
                                  <img src={EyeIcon} />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "PRODUCTS.write") ||
                                roleHandler(role, "PRODUCTS.admin")) && (
                                <button
                                  onClick={() => (
                                    setSaveHandlerAction(true),
                                    // setEditHandler(!editHandler),
                                    setModalHandler(!modalHandler),
                                    setModalData(e)
                                  )}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            {e?.parentProduct === null &&
                            e?.variants?.length > 1 ? (
                              <button
                                title="View"
                                className="tableBtn"
                                onClick={() => toggleRow(e.id)}
                              >
                                <span>
                                  <img
                                    src={
                                      expandedRows.includes(e.id)
                                        ? downupIcon
                                        : downArrowIcon
                                    }
                                  />
                                </span>
                              </button>
                            ) : null}
                          </td>
                        </tr>
                        {expandedRows.includes(e.id) &&
                          e.variants &&
                          e.variants.length > 0 &&
                          // Render variant rows here
                          e.variants.map((variant, index) => (
                            <tr key={variant.id}>
                              {/* Render variant details in similar structure to normal rows */}
                              <td className="fixedCol col_no" scope="row"></td>
                              <td>{variant?.store_id}</td>
                              <td>{variant.name?.en}</td>
                              <td>{variant.sku}</td>
                              <td>{variant.quantity}</td>
                              <td></td>
                              <td>
                                <button
                                  onClick={() =>
                                    handleViewVariantClick(variant, e, index)
                                  }
                                  title="View"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={EyeIcon} />
                                  </span>
                                </button>{" "}
                                &nbsp;
                                {(roleHandler(role, "PRODUCTS.write") ||
                                  roleHandler(role, "PRODUCTS.admin")) && (
                                  <button
                                    onClick={() =>
                                      handleEditVariantClick(variant, e, index)
                                    }
                                    title="Edit"
                                    className="tableBtn"
                                  >
                                    <span>
                                      <img
                                        src={editicon}
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                              </td>
                              <td></td>
                            </tr>
                          ))}
                      </>
                    ) : tbl === "ZIM" ? (
                      <>
                        <tr className="cursorPointer">
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("storeId") && (
                            <td className="fixedCol col_Firstname" scope="row">
                              {e?.storeId}
                            </td>
                          )}
                          {!restrict?.includes("storeName") && (
                            <td>{e?.storeName}</td>
                          )}
                          {!restrict?.includes("customerAccount") && (
                            <td>{e?.customerAccount}</td>
                          )}
                          {!restrict?.includes("expressType") && (
                            <td>{e?.expressType}</td>
                          )}
                          {!restrict?.includes("pickupMethod") && (
                            <td>{e?.pickupMethod}</td>
                          )}
                          <td>
                            <button
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                              className="tableBtn"
                            >
                              <span>
                                <img src={EyeIcon} />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              onClick={() => (
                                // setEditHandler(!editHandler),
                                setSaveHandlerAction(true),
                                setModalHandler(!modalHandler),
                                setModalData(e)
                              )}
                              className="tableBtn"
                            >
                              <span>
                                <img src={editicon} />
                              </span>
                            </button>
                            {/* <div className="customFilter">
                              <Dropdown autoClose="outside" className="userProfile lastEditManage">
                                <Dropdown.Toggle id="dropdown-basic">
                                  <img src={editicon} style={{ width: "auto", height: "auto"}}/>
                                </Dropdown.Toggle>
  
                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <ul className="listColumn">
                                      <li onClick={()=>(setEditHandler(!editHandler),setModalHandler(!modalHandler), setModalData(e))}>Edit</li>
                                    </ul>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              </div>     */}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Roles" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.name}</td>
                          <td>{e?.app}</td>
                          <td>{e?.description}</td>
                          {(role?.includes("AONE_ADMIN") ||
                            role?.includes("AONE_IAM.write") ||
                            role?.includes("AONE_IAM.admin") ||
                            role?.includes("AONE_IAM_ROLES.write") ||
                            role?.includes("AONE_IAM_ROLES.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => navigate(`/iam/roles/${e.id}`)}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_ADMIN") ||
                                role?.includes("AONE_IAM.admin") ||
                                role?.includes("AONE_IAM_ROLES.admin")) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(e?.id, e?.name, "Role")
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Roles_Users" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.username}</td>
                          <td>{`${e?.firstName} ${e?.lastName}`}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Unassign"
                              onClick={() => deleteById(e.id)}
                            >
                              <span>
                                <img src={unassignIcon} className="turnBlack" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Assign_Roles" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.name}</td>
                          <td>
                            <button
                              className="tableBtn plus_icon displayFlex alignItemCenter"
                              title="Assign"
                              onClick={() => deleteById(e.id)}
                            >
                              {" "}
                              {/* Acts As Assigner */}+
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Users" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.username}</td>
                          <td>{e?.email}</td>
                          <td>{e?.firstName}</td>
                          <td>{e?.lastName}</td>
                          {(role?.includes("AONE_ADMIN") ||
                            role?.includes("AONE_IAM.write") ||
                            role?.includes("AONE_IAM.admin") ||
                            role?.includes("AONE_IAM_USERS.write") ||
                            role?.includes("AONE_IAM_USERS.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => navigate(`/iam/users/${e.id}`)}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_ADMIN") ||
                                role?.includes("AONE_IAM.admin") ||
                                role?.includes("AONE_IAM_USERS.admin")) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.username,
                                      "User"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Assign_Users" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.username}</td>
                          <td>
                            <button
                              className="tableBtn plus_icon displayFlex alignItemCenter"
                              title="Assign"
                              onClick={() => deleteById(e.id)}
                            >
                              {" "}
                              {/* Acts As Assigner */}+
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Assign_Groups" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.name}</td>
                          <td>
                            <button
                              className="tableBtn plus_icon displayFlex alignItemCenter"
                              title="Assign"
                              onClick={() => deleteById(e.id)}
                            >
                              {" "}
                              {/* Acts As Assigner */}+
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Cod" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td className="textAlignCenter">{e?.codAmountMin}</td>
                          <td className="textAlignCenter">{e?.codAmountMax}</td>
                          <td className="textAlignCenter">{e?.charge}</td>
                          <td className="textAlignCenter">
                            {e?.codChargeCalculationType}
                          </td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "Freight" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td>{e?.destinationRegion}</td>
                          <td>{e?.courierType}</td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMin}
                          </td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMax}
                          </td>
                          <td className="textAlignCenter">{e?.baseCharge}</td>
                          <td className="textAlignCenter">{e?.steps}</td>
                          <td className="textAlignCenter">{e?.stepsPrice}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "Fuel_in_perc" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td>{e?.chargeCalculationType}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "Fuel_in_kg" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td>{e?.chargeCalculationType}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "Lval" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.destinationCountry}</td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMin}
                          </td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMax}
                          </td>
                          <td className="textAlignCenter">{e?.baseCharge}</td>
                          <td className="textAlignCenter">{e?.steps}</td>
                          <td className="textAlignCenter">{e?.stepsPrice}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "Hval" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.destinationCountry}</td>
                          <td className="textAlignCenter">
                            {e?.declaredValueMin}
                          </td>
                          <td className="textAlignCenter">
                            {e?.declaredValueMax}
                          </td>
                          <td className="textAlignCenter">{e?.charges}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "Insurance" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td>{e?.isRiskSurcharge}</td>
                          <td className="textAlignCenter">
                            {e?.declaredValueMin}
                          </td>
                          <td className="textAlignCenter">
                            {e?.declaredValueMax}
                          </td>
                          <td className="textAlignCenter">
                            {e?.declaredValueChargePercentage}
                          </td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "ODA" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td>{e?.chargeType}</td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMin}
                          </td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMax}
                          </td>
                          <td className="textAlignCenter">{e?.baseCharge}</td>
                          <td className="textAlignCenter">{e?.steps}</td>
                          <td className="textAlignCenter">{e?.stepsPrice}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "rates_Freight" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.state}</td>
                          <td>{e?.structure}</td>
                          <td>{e?.paymentType}</td>
                          <td>{e?.deliveryStatus}</td>
                          <td>{e?.contentType}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.originCityCode}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.destinationCityCode}</td>
                          <td>{e?.tierCode}</td>
                          <td>{e?.weightUnits}</td>
                          <td>{e?.fromWeight}</td>
                          <td>{e?.toWeight}</td>
                          <td>{e?.flatPrice}</td>
                          <td>{e?.unit}</td>
                          <td>{e?.unitPrice}</td>
                          <td>{e?.effectiveDate}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "Freight Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_COD" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.state}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.collectionMethod}</td>
                          <td>{e?.percent}</td>
                          <td>{e?.minimumCharge}</td>
                          <td>{e?.effectiveDate}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "COD Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_RTO" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.state}</td>
                          <td>{e?.structure}</td>
                          <td>{e?.contentType}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.originCityCode}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.destinationCityCode}</td>
                          <td>{e?.tierCode}</td>
                          <td>{e?.weightUnits}</td>
                          <td>{e?.fromWeight}</td>
                          <td>{e?.toWeight}</td>
                          <td>{e?.flatPrice}</td>
                          <td>{e?.unit}</td>
                          <td>{e?.unitPrice}</td>
                          <td>{e?.effectiveDate}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "RTO Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_ODA" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.state}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.weightUnits}</td>
                          <td>{e?.fromWeight}</td>
                          <td>{e?.toWeight}</td>
                          <td>{e?.flatPrice}</td>
                          <td>{e?.unit}</td>
                          <td>{e?.unitPrice}</td>
                          <td>{e?.effectiveDate}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "ODA Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_Fuel" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.state}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.weightUnits}</td>
                          <td>{e?.unit}</td>
                          <td>{e?.unitPrice}</td>
                          <td>{e?.percent}</td>
                          <td>{e?.effectiveDate}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "Fuel Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_Insurance" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.state}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.declaredValueMin}</td>
                          <td>{e?.declaredValueMax}</td>
                          <td>{e?.percent}</td>
                          <td>{e?.effectiveDate}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "Insurance Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_Attempts" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.state}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.charge}</td>
                          <td>{e?.effectiveDate}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "Attempts Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_Vat" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.state}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.percent}</td>
                          <td>{e?.effectiveDate}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {" "}
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.updateBy}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => handleRateEdit(e?.id)}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "Vat Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "rates_Gpa" ? (
                      <>
                        <tr
                          className={
                            selectRates?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleRatesCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.productName}</td>
                          <td>{e?.state}</td>
                          <td>{e?.originCountryName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.percent}</td>
                          <td>{e?.minValue}</td>
                          <td>{e?.minWeight}</td>
                          <td>{e?.maxWeight}</td>
                          <td>{e?.effectiveDate}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {" "}
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.updateBy}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
                            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => handleRateEdit(e?.id)}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_RATES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.productCode,
                                      "Gpa Rate"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Rto" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td>{e?.destinationRegion}</td>
                          <td>{e?.courierType}</td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMin}
                          </td>
                          <td className="textAlignCenter">
                            {e?.chargableWeightMax}
                          </td>
                          <td className="textAlignCenter">{e?.baseCharge}</td>
                          <td className="textAlignCenter">{e?.steps}</td>
                          <td className="textAlignCenter">{e?.stepsPrice}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "VAT" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerCode}</td>
                          <td>{e?.serviceTypeId}</td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td>{e?.vatChargePercentage}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                        </tr>
                      </>
                    ) : tbl === "Countries" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.name}</td>
                          <td>{e?.isoCode2}</td>
                          <td>{e?.isoCode3}</td>
                          <td>{e?.isoNumeric}</td>
                          <td>{e?.currency?.code}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View & Edit"
                              onClick={() => (
                                setModalHandler(true),
                                navigate(`/configs/addresses/${e.isoCode3}`)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESSES.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.isoCode3,
                                    e?.name,
                                    "Country"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Tiers" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.updatedBy}</td>
                          <td>
                            {" "}
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          {(role?.includes("AONE_CONFIGS.write") ||
                            role?.includes("AONE_CONFIGS.admin") ||
                            role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                            role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="View"
                                onClick={() => {
                                  navigate(
                                    `/configs/tiers/${
                                      e.code
                                    }?tiername=${encodeURIComponent(
                                      e?.name
                                    )}&countryName=${encodeURIComponent(
                                      e?.countryName
                                    )}&countryId=${encodeURIComponent(
                                      e?.countryCode
                                    )}`
                                  );
                                }}
                              >
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_CONFIGS.admin") ||
                                role?.includes(
                                  "AONE_CONFIGS_ADDRESSES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(e?.id, e?.code, "Tiers")
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "OdTiers" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{countryNameparam}</td>
                          <td>{tier}</td>
                          <td>{e?.destinationRegionName}</td>
                          <td>{e?.destinationCityName}</td>
                          <td>{e?.originRegionName}</td>
                          <td>{e?.originCityName}</td>
                          <td>{e?.updatedBy}</td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          {(role?.includes("AONE_CONFIGS.write") ||
                            role?.includes("AONE_CONFIGS.admin") ||
                            role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                            role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_CONFIGS.admin") ||
                                role?.includes(
                                  "AONE_CONFIGS_ADDRESSES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.originRegionName,
                                      "OD-Tiers"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Regions" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.name}</td>
                          <td>{e?.code}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.postalCode}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View & Edit"
                              onClick={() => (
                                setModalHandler(true),
                                navigate(`/configs/addresses/${e.code}`)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESSES.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(e?.code, e?.name, "Region")
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Cities" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.regionName}</td>
                          <td>{e?.tierCode}</td>
                          <td>{e?.postalCode}</td>
                          <td>{e?.remote ? "true" : null}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View & Edit"
                              onClick={() => (
                                setModalHandler(true),
                                navigate(`/configs/addresses/${e.code}`)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESSES.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(e?.code, e?.name, "City")
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Districts" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.cityName}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.regionName}</td>
                          <td>{e?.postalCode}</td>
                          <td>{e?.zoneCode}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="fixedCol col_actions" scope="row">
                            <button
                              className="tableBtn"
                              title="View & Edit"
                              onClick={() => (
                                setModalHandler(true),
                                navigate(`/configs/addresses/${e.code}`)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESSES.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.code,
                                    e?.name,
                                    "District"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "CountriesMap" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.customerAlias}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESS-MAPPINGS.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.customerAccount,
                                    "CountriesMap"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "RegionsMap" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.regionName}</td>
                          <td>{e?.customerAlias}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESS-MAPPINGS.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.customerAccount,
                                    "RegionsMap"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "CitiesMap" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.regionName}</td>
                          <td>{e?.cityName}</td>
                          <td>{e?.customerAlias}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESS-MAPPINGS.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.customerAccount,
                                    "CitiesMap"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "DistrictsMap" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.regionName}</td>
                          <td>{e?.cityName}</td>
                          <td>{e?.districtName}</td>
                          <td>{e?.customerAlias}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes(
                                "AONE_CONFIGS_ADDRESS-MAPPINGS.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.customerAccount,
                                    "DistrictsMap"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Currency" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.isoNumber}</td>
                          {(role?.includes("AONE_CONFIGS.write") ||
                            role?.includes("AONE_CONFIGS.admin") ||
                            role?.includes(
                              "AONE_CONFIGS_CURRENCY-CONVERSION.write"
                            ) ||
                            role?.includes(
                              "AONE_CONFIGS_CURRENCY-CONVERSION.admin"
                            )) && (
                            <td className="fixedCol col_no" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_CONFIGS.write") ||
                                role?.includes("AONE_CONFIGS.admin") ||
                                role?.includes("AONE_CONFIGS_CURRENCY.write") ||
                                role?.includes(
                                  "AONE_CONFIGS_CURRENCY.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Currency"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Currencyconversion" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.fromCurrency}</td>
                          <td>{e?.toCurrency}</td>
                          <td>{e?.rate}</td>
                          {(role?.includes("AONE_CONFIGS.write") ||
                            role?.includes("AONE_CONFIGS.admin") ||
                            role?.includes(
                              "AONE_CONFIGS_CURRENCY-CONVERSION.write"
                            ) ||
                            role?.includes(
                              "AONE_CONFIGS_CURRENCY-CONVERSION.admin"
                            )) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_CONFIGS.write") ||
                                role?.includes("AONE_CONFIGS.admin") ||
                                role?.includes(
                                  "AONE_CONFIGS_CURRENCY-CONVERSION.write"
                                ) ||
                                role?.includes(
                                  "AONE_CONFIGS_CURRENCY-CONVERSION.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      "Currency conversion",
                                      "Currencyconversions"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Crmcustomers" ? (
                      <>
                        <tr className="cursorPointer">
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_index" scope="row">
                            {e?.accountNumber}
                          </td>
                          <td>{e?.name}</td>
                          <td>{e?.email}</td>
                          <td>{e?.phone}</td>
                          <td>{e?.status}</td>
                          <td>{e?.vatNo}</td>
                          <td>{e?.crNo}</td>
                          <td>{e?.parentAccount !== 0 && e?.parentAccount}</td>
                          <td>{e?.salesperson}</td>
                          <td>{e?.oraclePushed ? "true" : null}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="textAlignCenter">{e?.createdBy}</td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.updatedBy}</td>
                          {(roleHandler(role, "AONE_CRM.write") ||
                              roleHandler(role, "AONE_CRM.admin") ||
                              roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
                              roleHandler(role, "AONE_CRM_CUSTOMERS.salesperson") ||
                              roleHandler(
                                role,
                                "AONE_CRM_CUSTOMERS.write"
                              )) && (
                          <td className="fixedCol col_actions" scope="row">
                              <>
                                {e?.oraclePushed !== true &&
                                  (roleHandler(role, "AONE_CRM.admin") ||
                                    roleHandler(
                                      role,
                                      "AONE_CRM_CUSTOMERS.admin"
                                    )) && (
                                    <button
                                      className="tableBtn"
                                      title="Push to Oracle"
                                      onClick={() =>
                                        handlepushOracle(e.accountNumber)
                                      }
                                    >
                                      <span>
                                        <img
                                          src={PushIcon}
                                          className="turnBlack"
                                        />
                                      </span>
                                    </button>
                                  )}
                                <button
                                  className="tableBtn"
                                  title="Edit"
                                  onClick={() =>{
                                    navigate(
                                      `/crm/customers/${e?.accountNumber}`,
                                      { state: { title: "Edit Account" } }
                                    );
                                    sessionStorage.setItem("SalesPerson",e?.salesperson)
                                  }}
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                              </>
                            &nbsp;
                            {(roleHandler(role, "AONE_CRM.admin") ||
                              roleHandler(role, "AONE_CRM_CUSTOMERS.admin")) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.accountNumber,
                                    e?.accountNumber,
                                    "Customers"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>)}
                        </tr>
                      </>
                    ) : tbl === "Employee" ? (
                      <>
                        <tr className="cursorPointer">
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_Firstname" scope="row">
                            {e?.code}
                          </td>
                          <td>{e?.firstName}</td>
                          <td>{e?.lastName}</td>
                          <td>{e?.email}</td>
                          <td>{e?.department}</td>
                          <td>{e?.hubName}</td>
                          {/* <td>{e?.status === true ? "true" : null}</td> */}
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          {(roleHandler(role, "AONE_CRM.admin") ||
                            roleHandler(role, "AONE_CRM.write") ||
                            roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                            roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                            roleHandler(
                              role,
                              "AONE_CRM_EMPLOYEES_ACCOUNTS.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CRM_EMPLOYEES_ACCOUNTS.admin"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CRM_EMPLOYEES.view_sensitive_data"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CRM_EMPLOYEES_AREAS.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CRM_EMPLOYEES_AREAS.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() =>
                                  navigate(`/crm/employees/${e?.code}`)
                                }
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>
                              &nbsp;
                              {(roleHandler(role, "AONE_CRM.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_CRM_EMPLOYEES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Employees"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Area" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.countryCode}</td>
                          <td>{e?.regionCode}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.updatedBy}</td>
                          <td className="fixedCol col_actions" scope="row">
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>
                            &nbsp;
                            {(roleHandler(role, "AONE_CRM.admin") ||
                              roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                              roleHandler(
                                role,
                                "AONE_CRM_EMPLOYEES_AREAS.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.countryCode,
                                    "Areas"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Pickupaddress" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                         <td>{e?.name}</td>
                         <td>{e?.shipperName}</td>
                          <td>{e?.country}</td>
                          <td>{e?.city}</td>
                          <td>{e?.district}</td>
                          <td>{e?.postalCode}</td>
                          <td>{e?.shortAddress}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => editPickupAddress(e)}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(roleHandler(role, "AONE_CRM.admin") ||
                              roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
                              roleHandler(role, "AONE_CONFIGS.admin") ||
                              roleHandler(
                                role,
                                "AONE_CONFIGS_ADDRESS-MAPPINGS.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.shortAddress,
                                    "PickupAddress"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Invoice Request" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>
                            {countriesArray?.find(
                              (country) =>
                                country?.isoCode3 === e?.billingCountry
                            )?.name || e?.billingCountry}
                          </td>
                          <td>{e?.invoiceRequestStatus}</td>
                          <td
                            className="fixedCol col_no textAlignCenter"
                            scope="row"
                          >
                            {e?.id}
                          </td>
                          <td>{e?.message}</td>
                          <td>
                            {e?.fromDate
                              ? new Date(e?.fromDate).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {e?.toDate
                              ? new Date(e?.toDate).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="textAlignCenter">
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.updatedBy}</td>
                          {(roleHandler(role, "AONE_FINANCE.write") ||
                            roleHandler(role, "AONE_FINANCE.admin") ||
                            roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
                            roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                            roleHandler(
                              role,
                              "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              {(e?.invoiceRequestStatus === "INVOICED" ||
                                e?.invoiceRequestStatus === "PROCESSED") && (
                                <button
                                  className="tableBtn"
                                  title="Download"
                                  onClick={() => downloadTemplate(e.id)}
                                >
                                  <span>
                                    <img
                                      src={PrintIcon}
                                      className="turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                              {e?.invoiceRequestStatus === "PROCESSED" &&
                                !e?.operationCode?.includes("PICKUP") && (
                                  <button
                                    className="tableBtn"
                                    title="Invoice"
                                    onClick={() =>
                                      handleInvoiceButtonClick(e.id)
                                    }
                                  >
                                    <span>
                                      <img
                                        src={invoiceIcon}
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                              &nbsp;
                              {e?.operationCode?.includes("PICKUP") && (
                                <button
                                  className="tableBtn"
                                  title="Export"
                                  onClick={() => exportPickupRevenue(e.id)}
                                >
                                  <span>
                                    <img
                                      src={exportIcon}
                                      className="turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                              &nbsp;
                              {e?.invoiceRequestStatus !== "CANCELLED" &&
                                e?.invoiceRequestStatus !== "INVOICED" && (
                                  <button
                                    className="tableBtn"
                                    title="Cancel"
                                    onClick={() => cancelRequest(e?.id)}
                                  >
                                    <span>
                                      <img
                                        src={cancelIcon}
                                        className="h18 turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Ledger" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Employee Code") && (
                            <td>{e?.employeeCode}</td>
                          )}
                          {!restrict?.includes("Employee Name") && (
                            <td>{e?.employeeName}</td>
                          )}
                          {!restrict?.includes("Country") && (
                            <td>{e?.countryName}</td>
                          )}
                          {!restrict?.includes("Hub") && <td>{e?.hubCode}</td>}
                          {!restrict?.includes("Balance") && (
                            <td>{e?.balance}</td>
                          )}
                          {!restrict?.includes("Currency") && (
                            <td>{e?.currency}</td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td>{e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td>{e?.updatedBy}</td>
                          )}
                           {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_FINANCE.read") ||
  roleHandler(role, "AONE_FINANCE.write") ||
  roleHandler(role, "AONE_FINANCE.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.read") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.settle")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="View"
                                onClick={() => {
                                  navigate(
                                    `/finance/cashdebriefing/${e.employeeCode}`
                                  );
                                }}
                              >
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button>
                              {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_FINANCE.write") ||
  roleHandler(role, "AONE_FINANCE.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS.settle")) && (
                              <button
                                className="tableBtn"
                                title="Settle"
                                onClick={() => {
                                  navigate(
                                    `/finance/cashdebriefing/settle/${e.employeeCode}`
                                  );
                                  sessionStorage.setItem(
                                    "EmployeeCurrency",
                                    e?.currency
                                  );
                                  sessionStorage.removeItem(
                                    "collectionTracking"
                                  );
                                }}
                              >
                                <span>
                                  <img src={settleIcon} className="turnBlack" />
                                </span>
                              </button>)}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Collections" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Tracking Id") && (
                            <td>{e?.trackingId}</td>
                          )}
                          {!restrict?.includes("COD Amount") && (
                            <td>{e?.codAmount}</td>
                          )}
                          {!restrict?.includes("DDU Amount") && (
                            <td>{e?.dduAmount}</td>
                          )}
                          {!restrict?.includes("Collected Amount") && (
                            <td>{e?.collectedAmount}</td>
                          )}
                          {!restrict?.includes("Currency") && (
                            <td>{e?.currency}</td>
                          )}
                          {!restrict?.includes("Mode") && <td>{e?.mode}</td>}
                          {!restrict?.includes("Location") && (
                            <td>
                              <div className="col-md-3">
                                <button className="cancel_btn">
                                  <span
                                    onClick={() =>
                                      handleClick(
                                        e?.location?.latitude,
                                        e?.location?.longitude
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={externalLink} alt="Link" />
                                  </span>
                                </button>
                              </div>
                            </td>
                          )}
                          {!restrict?.includes("Status") && (
                            <td>{e?.status}</td>
                          )}
                          {!restrict?.includes("Settled by") && (
                            <td>{e?.settledBy}</td>
                          )}
                          {!restrict?.includes("Settlement Date") && (
                            <td>
                              {e?.settledAt
                                ? new Date(e?.settledAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>{e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td>
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                         {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_FINANCE.read") ||
  roleHandler(role, "AONE_FINANCE.write") ||
  roleHandler(role, "AONE_FINANCE.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.read") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.settle")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Transactions"
                                onClick={() => {
                                  setCollectionsTab(2);
                                  sessionStorage.setItem(
                                    "collectionTracking",
                                    e?.trackingId
                                  );
                                }}
                              >
                                <span>
                                  <img
                                    src={TransactionIcon}
                                    className="turnBlack"
                                  />
                                </span>
                              </button>
                              {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_FINANCE.write") ||
  roleHandler(role, "AONE_FINANCE.admin") ||
  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.settle")) && (
                              <button
                                className="tableBtn"
                                title="Settle"
                                onClick={() => {
                                  navigate(
                                    `/finance/cashdebriefing/settle/${params?.id}`
                                  );
                                  sessionStorage.setItem(
                                    "EmployeeCurrency",
                                    e?.currency
                                  );
                                  sessionStorage.setItem(
                                    "collectionTracking",
                                    e?.trackingId
                                  );
                                }}
                              >
                                <span>
                                  <img src={settleIcon} className="turnBlack" />
                                </span>
                              </button>
                            
                            )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Settle" ? (
                      <>
                        <tr
                          className={
                            selectCollections?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={settleCheckbox[i]}
                                onChange={() =>
                                  handleOrdersCheckboxCollectionsClick(i)
                                }
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.trackingId}</td>
                          <td>{e?.collectedAmount}</td>
                          <td>{e?.currency}</td>
                          <td>{e?.mode}</td>
                          <td>
                            <div className="col-md-3">
                              <button className="cancel_btn">
                                <span
                                  onClick={() =>
                                    handleClick(
                                      e?.location?.latitude,
                                      e?.location?.longitude
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={externalLink} alt="Link" />
                                </span>
                              </button>
                            </div>
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Settlement" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Employee Code") && (
                            <td>{e?.employeeCode}</td>
                          )}
                          {!restrict?.includes("Employee Name") && (
                            <td>{e?.employeeName}</td>
                          )}
                          {!restrict?.includes("Amount") && (
                            <td>{e?.amount}</td>
                          )}
                          {!restrict?.includes("Currency") && (
                            <td>{e?.currency}</td>
                          )}
                          {!restrict?.includes("Location") && (
                            <td>
                              <div className="col-md-3">
                                {e?.location?.latitude && (
                                  <button className="cancel_btn">
                                    <span
                                      onClick={() =>
                                        handleClick(
                                          e?.location?.latitude,
                                          e?.location?.longitude
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={externalLink} alt="Link" />
                                    </span>
                                  </button>
                                )}
                              </div>
                            </td>
                          )}
                          {!restrict?.includes("Proof") && (
                            <td>
                              {e?.proofUrl && (
                                <span
                                  onClick={() =>
                                    downloadSettleProof(e?.id, e?.proofType)
                                  }
                                >
                                  <img src={PrintIcon} className="turnBlack" />
                                </span>
                              )}
                            </td>
                          )}
                          {!restrict?.includes("Settled by") && (
                            <td>{e?.settledBy}</td>
                          )}
                          {!restrict?.includes("Settled at") && (
                            <td>
                              {e?.settledAt
                                ? new Date(e?.settledAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Hubs" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.alias}</td>
                          <td>{e?.type}</td>
                          <td>{e?.countryIsoCode3}</td>
                          <td>{e?.regionSummary?.name}</td>
                          <td>{e?.citySummary?.name}</td>
                          <td>{e?.latitude}</td>
                          <td>{e?.longitude}</td>
                          <td>{e?.description}</td>
                          {(roleHandler(role, "AONE_ASSETS.write") ||
                            roleHandler(role, "AONE_ASSETS.admin") ||
                            roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                            roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() =>
                                    navigate(`/assets/hubs/${e?.code}`, {
                                      state: { title: "Edit Hub" },
                                    })
                                  }
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(e?.code, e?.code, "Hubs")
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Service Areas" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.hubCode}</td>
                          <td>{e?.zoneCode}</td>
                          <td>{e?.allowedEvents?.join(", ")}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td> {e?.createdBy}</td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td> {e?.updatedBy}</td>
                          {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.write") ||
                            roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.admin")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() => (
                                    setModalHandler(!modalHandler),
                                    setModalData(e)
                                  )}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.admin")) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.hubCode,
                                      "Service Areas"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>)}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "MessageTemplate" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Code") && <td>{e?.code}</td>}
                          {!restrict?.includes("Message") && (
                            <td>{e?.message}</td>
                          )}
                          {!restrict?.includes("Country") && (
                            <td>{e?.countryName}</td>
                          )}
                          {!restrict?.includes("Language") && (
                            <td>{e?.language}</td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Actions") && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() => handleTemplateEdit(e)}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.code,
                                      "SMS Templates"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "MessageRules" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Template") && (
                            <td>{e?.template}</td>
                          )}
                          {!restrict?.includes("Event") && (
                            <td>{e?.eventStatus}</td>
                          )}
                          {!restrict?.includes("Status") && (
                            <td>{e?.status}</td>
                          )}
                          {!restrict?.includes("Country") && (
                            <td>{e?.countryName}</td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                           {!restrict?.includes("Actions") &&
  (roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.write") ||
    roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.admin")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() => handleRuleEdit(e?.id)}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.admin")) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.id,
                                      "SMS Rules"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>)}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "MessageHistory" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Tracking Id") && (
                            <td>{e?.trackingId}</td>
                          )}
                          {!restrict?.includes("Event") && (
                            <td>{e?.eventName}</td>
                          )}
                          {!restrict?.includes("Country") && (
                            <td>{e?.countryName}</td>
                          )}
                          {!restrict?.includes("Message") && (
                            <td>{e?.message}</td>
                          )}
                          {!restrict?.includes("Receiver phone number") && (
                            <td>{e?.receiverPhoneNumber}</td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                        </tr>
                      </>
                         ) : tbl === "PackageTracking" ? (
                          <>
                            <tr className="cursorPointer">
                             <td>{newPage * size + (i + 1)}</td>
                             <td>
                              {e?.eventDate
                                ? new Date(e?.eventDate).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                              <td>{e?.eventCode}</td>
                              <td>{e?.eventStatus}</td>
                              <td>{e?.employeeCode}</td>
                              <td>{e?.employeeName}</td>
                              <td>{e?.hubCode}</td>
                              <td><div className="col-md-3">
                                {e?.images && (
                                  <button className="cancel_btn">
                                    <span
                                      onClick={() =>
                                        handleClick(
                                          e?.images
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={externalLink} alt="Link" />
                                    </span>
                                  </button>
                                )}
                              </div></td>
                              <td><div className="col-md-3">
                                {e?.signature && (
                                  <button className="cancel_btn">
                                    <span
                                      onClick={() =>
                                        handleClick(
                                          e?.signature
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={externalLink} alt="Link" />
                                    </span>
                                  </button>
                                )}
                              </div></td>
                              <td>{e?.failureReason}</td>
                              <td><div className="col-md-3">
                                {e?.latitude && (
                                  <button className="cancel_btn">
                                    <span
                                      onClick={() =>
                                        handleClick(
                                          e?.latitude,
                                          e?.longitude
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={externalLink} alt="Link" />
                                    </span>
                                  </button>
                                )}
                              </div></td>
                              <td>{e?.pickupRequestId}</td>
                              <td>{e?.category}</td>
                              <td>{e?.thirdPartyCode}</td>
                              <td>{e?.clientId}</td>
                            </tr>
                          </>
                    ) : tbl === "NPR" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.message}</td>
                          <td>{e?.events}</td>
                          <td>{e?.category}</td>
                          <td> {e?.requireReschedule ? "true" : null}</td>
                          <td> {e?.requireAddressChange ? "true" : null}</td>
                          <td> {e?.requireOtp ? "true" : null}</td>
                          {(roleHandler(role, "AONE_OPS.write") ||
                            roleHandler(role, "AONE_OPS.admin") ||
                            roleHandler(role, "AONE_OPS_REASONS_NPR.write") ||
                            roleHandler(
                              role,
                              "AONE_OPS_REASONS_NPR.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() => (
                                    setModalHandler(!modalHandler),
                                    setModalData(e)
                                  )}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_REASONS_NPR.admin"
                                  )) && (
                                  <button
                                    className="tableBtn"
                                    title="Delete"
                                    onClick={() =>
                                      deleteParamsSetter(
                                        e?.code,
                                        e?.code,
                                        "NPR"
                                      )
                                    }
                                  >
                                    <span>
                                      <img
                                        src={DeleteIcon}
                                        className="h18 turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "NDR" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.message}</td>
                          <td>{e?.events}</td>
                          <td> {e?.requireReschedule ? "true" : null}</td>
                          <td> {e?.requireAddressChange ? "true" : null}</td>
                          <td> {e?.requireOtp ? "true" : null}</td>
                          <td>
                            {" "}
                            {e?.requireGeolocationValidation ? "true" : null}
                          </td>
                          <td> {e?.distance}</td>
                          {(roleHandler(role, "AONE_OPS.write") ||
                            roleHandler(role, "AONE_OPS.admin") ||
                            roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
                            roleHandler(
                              role,
                              "AONE_OPS_REASONS_NDR.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() => (
                                    setModalHandler(!modalHandler),
                                    setModalData(e)
                                  )}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_REASONS_NDR.admin"
                                  )) && (
                                  <button
                                    className="tableBtn"
                                    title="Delete"
                                    onClick={() =>
                                      deleteParamsSetter(
                                        e?.code,
                                        e?.code,
                                        "NDR"
                                      )
                                    }
                                  >
                                    <span>
                                      <img
                                        src={DeleteIcon}
                                        className="h18 turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Linehauls" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.message}</td>
                          <td> {e?.requireEstimateDelay ? "true" : null}</td>
                          {(roleHandler(role, "AONE_OPS.write") ||
                            roleHandler(role, "AONE_OPS.admin") ||
                            roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
                            roleHandler(
                              role,
                              "AONE_OPS_REASONS_NDR.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() => handleLinehaulsEdit(e.code)}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_REASONS_NDR.admin"
                                  )) && (
                                  <button
                                    className="tableBtn"
                                    title="Delete"
                                    onClick={() =>
                                      deleteParamsSetter(
                                        e?.code,
                                        e?.code,
                                        "Linehauls"
                                      )
                                    }
                                  >
                                    <span>
                                      <img
                                        src={DeleteIcon}
                                        className="h18 turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "HOLD" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.message}</td>
                          <td>{e?.externalMessage}</td>
                          {(roleHandler(role, "AONE_OPS.write") ||
                            roleHandler(role, "AONE_OPS.admin") ||
                            roleHandler(role, "AONE_OPS_REASONS_HOLD.write") ||
                            roleHandler(
                              role,
                              "AONE_OPS_REASONS_HOLD.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                <button
                                  onClick={() => (
                                    setModalHandler(!modalHandler),
                                    setModalData(e)
                                  )}
                                  title="Edit"
                                  className="tableBtn"
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                                {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_REASONS_HOLD.admin"
                                  )) && (
                                  <button
                                    className="tableBtn"
                                    title="Delete"
                                    onClick={() =>
                                      deleteParamsSetter(
                                        e?.code,
                                        e?.code,
                                        "Hold Reasons"
                                      )
                                    }
                                  >
                                    <span>
                                      <img
                                        src={DeleteIcon}
                                        className="h18 turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "ConsignmentPickup" ? (
                      <>
                        <tr
                          className={
                            selectPickup?.includes(e?.trackingId)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handlePickupCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                          <td className="fixedCol col_index" scope="row">
                          {newPage * size + (i + 1)}
                          </td>
                          {!restrict?.includes("Tracking Id") && (
                            <td className="fixedCol col_trackingId" scope="row">
                              {e?.trackingId}
                            </td>
                          )}
                           {!restrict?.includes("Request Id") && (
                            <td className="fixedCol col_cusAcc" scope="row">
                              {e?.pickupRequestId}
                            </td>
                          )}
                           {!restrict?.includes("Shipper Name") && (
                            <td>
                              {e?.shipperName}
                            </td>
                          )}
                           {!restrict?.includes("Receiver Name") && (
                            <td>
                              {e?.receiverName}
                            </td>
                          )}
                          {!restrict?.includes("Customer Account") && (
                            <td>{e?.customerAccount}</td>
                          )}
                          {!restrict?.includes("Customer Name") && (
                            <td>{e?.customerName}</td>
                          )}
                          {!restrict?.includes("Product") && (
                            <td>{e?.productCode}</td>
                          )}
                          {!restrict?.includes("Status") && (
                            <td> {e?.status}</td>
                          )}
                          {!restrict?.includes("Reason") && (
                            <td> {e?.reason}</td>
                          )}
                          {!restrict?.includes("Packages Count") && (
                            <td> {e?.packagesCount}</td>
                          )}
                              {!restrict?.includes("Weight") && (
                            <td> {e?.weight} {e?.weightUnit}</td>
                          )}
                            {!restrict?.includes("RTO") && (
                          <td>{e?.rto ? "true" : ""}</td>
                          )}
                            {!restrict?.includes("RPU") && (
                          <td>{e?.rpu ? "true" : ""}</td>
                          )}
                          {!restrict?.includes("Origin Country") && (
                              <td> {e?.originDetail?.country}</td>
                          )}
                          {!restrict?.includes("Origin City") && (
                                <td> {e?.originDetail?.city}</td>
                          )}
                           {!restrict?.includes("Origin District") && (
                            <td> {e?.originDetail?.district}</td>
                          )}
                          {!restrict?.includes("Origin Hub") && (
                            <td> {e?.originHub}</td>
                          )}
                           {!restrict?.includes("Origin Zone") && (
                            <td> {e?.originZone}</td>
                          )}
                            {!restrict?.includes("Destination Country") && (
                             <td> {e?.destinationDetail?.country}</td>
                          )}
                          {!restrict?.includes("Destination City") && (
                               <td>{e?.destinationDetail?.city}</td>
                          )}
                           {!restrict?.includes("Destination District") && (
                              <td> {e?.destinationDetail?.district}</td>
                          )}
                          {!restrict?.includes("Destination Hub") && (
                            <td> {e?.destinationHub}</td>
                          )}
                           {!restrict?.includes("Destination Zone") && (
                            <td> {e?.destinationZone}</td>
                          )}
                             {!restrict?.includes("Shipper Address") && (
                            <td> {e?.originDetail?.address}</td>
                          )}
                           {!restrict?.includes("Shipper Phone") && (
                            <td> {e?.originDetail?.phoneNumber}</td>
                          )}
                              {!restrict?.includes("Receiver Phone") && (
                            <td> {e?.destinationDetail?.phoneNumber}</td>
                          )}
                          {!restrict?.includes("Courier Id") && (
                            <td>{e?.assignedPickupCourierId}</td>
                          )}
                             {!restrict?.includes("COD Amount") && (
                            <td>{e?.codAmount}</td>
                          )}
                           {!restrict?.includes("Pickup Date") && (
                            <td>
                              {" "}
                              {e?.pickupAt
                                ? new Date(e?.pickupAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                           {!restrict?.includes("Delivery Date") && (
                            <td>
                              {" "}
                              {e?.deliveryAt
                                ? new Date(e?.deliveryAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {" "}
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {" "}
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {!restrict?.includes("Actions") && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                {(roleHandler(role, "AONE_OPS.read") ||
                                  roleHandler(role, "AONE_OPS.write") ||
                                  roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_CONSIGNMENTS.read"
                                  ) ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_CONSIGNMENTS.pickup"
                                  )) && (
                                  <button
                                    title="View"
                                    className="tableBtn"
                                    onClick={() => {
                                      const token = state.token;
                                      const url = `/consignments/orders/${e?.trackingId}?token=${token}`;
                                      window.open(url, '_blank');
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={EyeIcon}
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                           {(roleHandler(role, "AONE_OPS.admin") ||
                             roleHandler(role, "AONE_OPS.write") ||
                             roleHandler(role, "AONE_OPS_CONSIGNMENTS.print")) &&
                             e?.status !== "Delivered" && (
                                  <button
                                    className="tableBtn"
                                    title="Print"
                                    onClick={() =>
                                      printConsignmentOrders(e.trackingId)
                                    }
                                  >
                                    <span>
                                      <img
                                        src={PrintIcon}
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                                {/* {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_CONSIGNMENTS.pickup"
                                  )) &&
                                  e?.status === "Pickup Scheduled" && (
                                    <button className="tableBtn" title="Apply">
                                      <span>
                                        <img
                                          src={applyIcon}
                                          className="h18 turnBlack"
                                          onClick={() =>
                                            applyPickupOrder(
                                              e?.trackingId,
                                              e?.originHub,
                                              e?.pickupRequestId
                                            )
                                          }
                                        />
                                      </span>
                                    </button>
                                  )} */}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Courier" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_Firstname" scope="row">
                            {e?.employeeCode}
                          </td>
                          <td>{e?.employeeName}</td>
                          <td>{e?.hubCode}</td>
                          <td>{e?.assigned}</td>
                          <td> {e?.pending}</td>
                          <td> {e?.completed}</td>
                          <td>{e?.failed}</td>
                          <td className="fixedCol col_actions" scope="row">
                            <>
                              <button
                                className="tableBtn"
                                title="Configure Hubs"
                                tabIndex="-1"
                                onClick={(event) => {
                                  assignCourierModal(e?.hubCode);
                                  setselectedCourierCode(e?.employeeCode);
                                }}
                              >
                                <span>
                                  <img src={assignIcon} className="turnBlack" />
                                </span>
                              </button>
                              {(roleHandler(role, "AONE_OPS_COURIERS.map")) && (
                              <button
                                className="tableBtn"
                                title="View Courier Location"
                                onClick={() =>
                                  handleShowMapPins(e?.employeeCode)
                                }
                              >
                                <span>
                                  <img src={MapPinIcon} className="turnBlack" />
                                </span>
                              </button>)}
                              <button
                                className="tableBtn"
                                title="View Tasks"
                                tabIndex="-1"
                                onClick={() => handleShowTasks(e?.employeeCode)}
                              >
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button>
                            </>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Weight" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                         {!restrict?.includes("Tracking Id") && (
                          <td>{e?.trackingId}</td>)}
                            {!restrict?.includes("Package Tracking Id") && (
                          <td>{e?.packageTrackingId}</td>)}
                           {!restrict?.includes("Product") && (
                          <td>{e?.productCode}</td>)}
                            {!restrict?.includes("Customer") && (
                          <td>{e?.customerAccount}</td>)}
                            {!restrict?.includes("Weight Unit") && (
                          <td>{e?.weightUnit}</td>)}
                            {!restrict?.includes("Original Weight") && (
                          <td> {e?.originalWeight}</td>)}
                           {!restrict?.includes("New Weight") && (
                          <td> {e?.newWeight}</td>)}
                           {!restrict?.includes("Dimension Unit") && (
                          <td>{e?.dimensionUnit}</td>)}
                           {!restrict?.includes("Original Dimensions") && (
                          <td>{e?.originalDimension}</td>)}
                           {!restrict?.includes("New Dimensions") && (
                          <td>{e?.newDimension}</td>)}
                           {!restrict?.includes("Original volumetric weight") && (
                          <td>{e?.originalVolumetricWeight}</td>)}
                           {!restrict?.includes("New volumetric weight") && (
                          <td>{e?.newVolumetricWeight}</td>)}
                            {!restrict?.includes("Tool") && (
                          <td>{e?.tool}</td>)}
                             {!restrict?.includes("Created At") && (
                            <td>
                              {" "}
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created By") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Updated At") && (
                            <td>
                              {" "}
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated By") && (
                            <td> {e?.updatedBy}</td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "DeliveryFlow" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.customers == "[]" ? "" : e?.customers}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.requireOTP ? "true" : ""}</td>
                          <td>{e?.requireReceiver ? "true" : ""}</td>
                          <td> {e?.requireProof ? "true" : ""}</td>
                          <td>{e?.requireSignature ? "true" : ""}</td>
                          <td className="fixedCol col_actions" scope="row">
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() =>
                                navigate(`/ops/deliveryflow/${e?.id}`)
                              }
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              className="tableBtn"
                              title="Delete"
                              onClick={() =>
                                deleteParamsSetter(
                                  e?.id,
                                  e?.customers,
                                  "Delivery Flow"
                                )
                              }
                            >
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Drivers" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.tripId}</td>
                          <td>{e?.activeVehicle}</td>
                          <td>
                            {visiblePhones[e.code] ? (
                              visiblePhones[e.code]
                            ) : (
                              <div className="inputgroup_append">
                                {roleHandler(role, "AONE_OPS.write") ||
                                roleHandler(role, "AONE_OPS.admin") ||
                                roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                                roleHandler(role, "AONE_OPS_DRIVERS.admin") ? (
                                  <div className="displayFlex">
                                    <span className="Groupinput_text mt_3 mr_10p">
                                      ***********
                                    </span>
                                    <span
                                      className="Groupinput_text"
                                      onClick={() => {
                                        togglePhoneHandler(
                                          e?.code,
                                          e?.phoneNumber
                                        );
                                      }}
                                    >
                                      <img
                                        src={openEye}
                                        alt="Toggle visibility"
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <span className="Groupinput_text">
                                    ***********
                                  </span>
                                )}
                              </div>
                            )}
                          </td>
                          <td>{e?.payrollType}</td>
                          <td> {e?.payrollCompany}</td>
                          <td>
                            {e?.drivingLicenseUrl && (
                              <span
                                onClick={() =>
                                  downloadDriverLicenses(
                                    e?.code,
                                    e?.drivingLicenseType
                                  )
                                }
                              >
                                <img src={PrintIcon} className="turnBlack" />
                              </span>
                            )}
                          </td>
                          <td>
                            {" "}
                            {e?.drivingLicenseExpireDate
                              ? new Date(
                                  e?.drivingLicenseExpireDate
                                ).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.status}</td>
                          {(roleHandler(role, "AONE_OPS.write") ||
                            roleHandler(role, "AONE_OPS.admin") ||
                            roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                            roleHandler(role, "AONE_OPS_DRIVERS.admin")) && (
                            <td className="fixedCol col_actions" scope="row">
                              {(roleHandler(role, "AONE_OPS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_OPS_DRIVERS.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title={
                                    e?.status === "Not Active"
                                      ? "Active"
                                      : "Inactive"
                                  }
                                  onClick={() => {
                                    e?.status === "Not Active"
                                      ? handleDriverActivate(e.code)
                                      : handleDriverInActivate(e.code);
                                  }}
                                >
                                  <span>
                                    <img
                                      src={
                                        e?.status === "Not Active"
                                          ? InactiveIcon
                                          : ActivateIcon
                                      }
                                      className="turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() =>
                                  navigate(`/ops/linehauls/driver/${e?.code}`)
                                }
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_OPS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_OPS_DRIVERS.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Drivers"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Trips" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Trip Id") && (
                            <td className="fixedCol col_index" scope="row">
                              {e?.tripId}
                            </td>
                          )}
                          {!restrict?.includes("Origin Hub") && (
                            <td>{e?.originHubName}</td>
                          )}
                          {!restrict?.includes("Destination Hub") && (
                            <td>{e?.destinationHubName}</td>
                          )}
                          {!restrict?.includes("Vehicle") && (
                            <td>{e?.vehicleCode}</td>
                          )}
                          {!restrict?.includes("Route") && (
                            <td>{e?.routeName}</td>
                          )}
                          {!restrict?.includes("Status") && (
                            <td>{e?.status}</td>
                          )}
                          {!restrict?.includes("Estimated Arrival Date") && (
                            <td> {e?.estimatedArrivalDate}</td>
                          )}
                          {!restrict?.includes("Total Shipments count") && (
                            <td> {e?.totalShipments}</td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {" "}
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {" "}
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {(roleHandler(role, "AONE_OPS.write") ||
                            roleHandler(role, "AONE_OPS.admin") ||
                            roleHandler(role, "AONE_OPS_TRIPS.write") ||
                            roleHandler(role, "AONE_OPS_TRIPS.admin")) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="View"
                                onClick={() =>
                                  navigate(`/ops/linehauls/trip/${e?.tripId}`)
                                }
                              >
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {e?.status == "Trip Created" && (
                                <button
                                  className="tableBtn"
                                  title="Edit"
                                  onClick={() => handleTripEdit(e.tripId)}
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>
                              )}{" "}
                              &nbsp;
                              {e?.status == "Trip Created" && (
                                <button
                                  className="tableBtn"
                                  title="Edit Vehicle"
                                  onClick={() =>
                                    handleVehEdit(e.tripId, e?.vehicleCode)
                                  }
                                >
                                  <span>
                                    <img
                                      src={EditVehicle}
                                      className="turnBlack"
                                    />
                                  </span>
                                </button>
                              )}{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_OPS.admin") ||
                                roleHandler(role, "AONE_OPS_TRIPS.admin")) &&
                                e?.status == "Trip Created" && (
                                  <button
                                    className="tableBtn"
                                    title="Delete"
                                    onClick={() =>
                                      deleteParamsSetter(
                                        e?.tripId,
                                        e?.tripId,
                                        "Trip"
                                      )
                                    }
                                  >
                                    <span>
                                      <img
                                        src={DeleteIcon}
                                        className="h18 turnBlack"
                                      />
                                    </span>
                                  </button>
                                )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Sequencer" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.customerName}</td>
                          <td>{e?.startRange}</td>
                          <td>{e?.sequence}</td>
                          <td>{e?.endRange}</td>
                          <td>{e?.status}</td>
                          <td> {e?.returnCreatedOrder ? "true" : ""}</td>
                          {(roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.write") ||
                    roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.admin")) && (
                          <td className="fixedCol col_actions" scope="row">
                            <button
                              className="tableBtn"
                              title={
                                e?.status === "ACTIVE"
                                  ? "Deactivate"
                                  : "Activate"
                              }
                              onClick={() =>
                                e?.status === "ACTIVE"
                                  ? handleSeqInActivate(e.id)
                                  : handleSeqActivate(e.id)
                              }
                            >
                              <span>
                                <img
                                  src={
                                    e?.status === "ACTIVE"
                                      ? ActivateIcon
                                      : InactiveIcon
                                  }
                                  className="turnBlack"
                                />
                              </span>
                            </button>
                          </td>)}
                        </tr>
                      </>
                    ) : tbl === "TripTracking" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td>{e?.eventStatus}</td>
                          <td>{e?.eventCode}</td>
                          <td>{e?.eventDate}</td>
                          <td>{e?.hubCode}</td>
                          <td>{e?.sealNumber}</td>
                          <td>{e?.image}</td>
                          <td>{e?.reasonMessage}</td>
                          {e?.location ? (
                            <td>
                              <div className="col-md-3">
                                <button className="cancel_btn">
                                  <span
                                    onClick={() =>
                                      handleClick(
                                        e?.location?.latitude,
                                        e?.location?.longitude
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={externalLink} alt="Link" />
                                  </span>
                                </button>
                              </div>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Routes" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.status}</td>
                          <td>{e?.productCode}</td>
                          <td> {e?.originHub?.name}</td>
                          <td> {e?.destinationHub?.name}</td>
                          <td className="fixedCol col_actions" scope="row">
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => handleRouteEdit(e.code)}
                            >
                              <img
                                src={editicon}
                                className="turnBlack"
                                alt="Edit"
                              />
                            </button>{" "}
                            <button
                              className="tableBtn"
                              title="Delete"
                              onClick={() =>
                                handleRouteDelete(e.code, e.code, "Routes")
                              }
                            >
                              <img
                                src={DeleteIcon}
                                className="h18 turnBlack"
                                alt="Delete"
                              />
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "RtoRequest" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={settleRto[i]}
                                onChange={() => handleCheckboxRtoClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Tracking Id") && (
                            <td>{e?.trackingId}</td>
                          )}
                          {!restrict?.includes("RTO Tracking Id") && (
                            <td>{e?.rtoTrackingId}</td>
                          )}
                          {!restrict?.includes("Package Count") && (
                            <td>{e?.packagesCount}</td>
                          )}
                          {!restrict?.includes("Customer") && (
                            <td>{e?.customerName}</td>
                          )}
                          {!restrict?.includes("Status") && (
                            <td>{e?.status}</td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {!restrict?.includes("Actions") && 
  (roleHandler(role, "AONE_OPS.write") ||
   roleHandler(role, "AONE_OPS.admin") ||
   roleHandler(role, "AONE_OPS_RTO.write")) && (
                            <td className="fixedCol col_actions" scope="row">
                              {e?.status !== "CANCELED" && (
                                <button
                                  className="tableBtn"
                                  title="View/Submit"
                                  onClick={() => setShowModalRto(true)}
                                >
                                  <img
                                    src={SubmitIcon}
                                    className="turnBlack"
                                    alt="View"
                                    onClick={() => handleRtobyId(e.id)}
                                  />
                                </button>
                              )}
                              {e?.status == "NEW" && (
                                <button
                                  className="tableBtn"
                                  title="Cancel RTO"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.trackingId,
                                      "RTO"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={cancelIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "CourierTasks" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_Firstname" scope="row">
                            {e?.trackingId}
                          </td>
                          <td>{e?.pickupRequestId}</td>
                          <td>{e?.status}</td>
                          <td>{e?.originZone}</td>
                          <td> {e?.originHub}</td>
                          <td> {e?.bagNumber}</td>
                          <td>{e?.destinationZone}</td>
                          <td>{e?.destinationHub}</td>
                          <td className="fixedCol col_actions" scope="row">
                            {e?.taskType}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Vehicles" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_Firstname" scope="row">
                            {e?.code}
                          </td>
                          <td>{e?.plateNumber}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.type}</td>
                          <td>{e?.categoryCode}</td>
                          <td>{e?.hubName}</td>
                          <td>{e?.odometer}</td>
                          <td>{e?.weightCapacity}</td>
                          <td>{e?.weightUnit}</td>
                          <td>{e?.volumeCapacity}</td>
                          <td>{e?.dimensionUnit}</td>
                          <td>{e?.vendorName}</td>
                          <td>{e?.model}</td>
                          <td>{e?.manufactureYear}</td>
                          <td>{e?.hasGPS ? "true" : null}</td>
                          <td>{e?.gpsVendor?.name}</td>
                          <td>{e?.vehicleStatus}</td>
                          <td>{e?.replacement}</td>
                          <td>{e?.employeeCode}</td>
                          <td>{e?.status}</td>
                          {(roleHandler(role, "AONE_ASSETS.write") ||
                            roleHandler(role, "AONE_ASSETS.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_VEHICLES.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_VEHICLES.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              {(roleHandler(role, "AONE_ASSETS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES.admin"
                                ) ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES_VEHICLES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title={
                                    e?.status === "ACTIVE"
                                      ? "Inactive"
                                      : "Active"
                                  }
                                  onClick={() =>
                                    e?.status === "ACTIVE"
                                      ? handleInActivate(e.code)
                                      : handleActivate(e.code)
                                  }
                                >
                                  <span>
                                    <img
                                      src={
                                        e?.status === "ACTIVE"
                                          ? ActivateIcon
                                          : InactiveIcon
                                      }
                                      className="turnBlack"
                                    />
                                  </span>
                                </button>
                              )}{" "}
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => handleVehicleEdit(e.code)}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_ASSETS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES.admin"
                                ) ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES_VEHICLES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Vehicles"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Vehicle Category" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.description}</td>
                          {(roleHandler(role, "AONE_ASSETS.write") ||
                            roleHandler(role, "AONE_ASSETS.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_CATEGORIES.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_CATEGORIES.admin"
                            )) && (
                            <td>
                              <button className="tableBtn" title="Edit">
                                <span>
                                  <img
                                    src={editicon}
                                    className="turnBlack"
                                    onClick={() => handleVehicleEdit(e.code)}
                                  />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_ASSETS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES.admin"
                                ) ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES_CATEGORIES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Vehicle Category"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Vendors" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.countryName}</td>
                          {(roleHandler(role, "AONE_ASSETS.write") ||
                            roleHandler(role, "AONE_ASSETS.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_VENDORS.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_VENDORS.admin"
                            )) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => handleVehicleEdit(e.code)}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_ASSETS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES.admin"
                                ) ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES_VENDORS.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Vendors"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "GpsVendors" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.countryName}</td>
                          {(roleHandler(role, "AONE_ASSETS.write") ||
                            roleHandler(role, "AONE_ASSETS.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_GPS-VENDORS.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin"
                            )) && (
                            <td>
                              <button className="tableBtn" title="Edit">
                                <span>
                                  <img
                                    src={editicon}
                                    className="turnBlack"
                                    onClick={() => handleVehicleEdit(e)}
                                  />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_ASSETS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin"
                                ) ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Gps vendors"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Odometer" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.vehicleCode}</td>
                          <td>{e?.odometer}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          {(roleHandler(role, "AONE_ASSETS.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_ODOMETER.admin"
                            )) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.vehicleCode,
                                    "Odometer"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Vehicle Documents" ? (
                      <>
                        <tr className={"cursorPointer"}>
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_Firstname" scope="row">
                            {e?.vehicleCode}
                          </td>
                          <td>
                            {e?.rcDocumentUrl && (
                              <span
                                onClick={() =>
                                  handleDownload(
                                    e?.rcDocumentUrl,
                                    `RC_Document.${e?.rcDocumentType}`,
                                    sessionStorage.getItem("user_access_token")
                                  )
                                }
                              >
                                <img src={PrintIcon} className="turnBlack" />
                              </span>
                            )}
                          </td>
                          <td>{e?.rcValidityDate}</td>
                          <td>
                            {e?.insuranceDocumentUrl && (
                              <span
                                onClick={() =>
                                  handleDownload(
                                    e?.insuranceDocumentUrl,
                                    `INSURANCE_DOCUMENT.${e?.insuranceDocumentType}`,
                                    sessionStorage.getItem("user_access_token")
                                  )
                                }
                              >
                                <img src={PrintIcon} className="turnBlack" />
                              </span>
                            )}
                          </td>
                          <td>{e?.insuranceValidityDate}</td>
                          <td>
                            {e?.pucDocumentUrl && (
                              <span
                                onClick={() =>
                                  handleDownload(
                                    e?.pucDocumentUrl,
                                    `Puc_Document.${e?.pucDocumentType}`,
                                    sessionStorage.getItem("user_access_token")
                                  )
                                }
                              >
                                <img src={PrintIcon} className="turnBlack" />
                              </span>
                            )}
                          </td>
                          <td> {e?.pucValidityDate}</td>
                          <td>
                            {e?.opsCard && (
                              <span
                                onClick={() =>
                                  handleDownload(
                                    e?.opsCard,
                                    `Opscard.${e?.opsCardType}`,
                                    sessionStorage.getItem("user_access_token")
                                  )
                                }
                              >
                                <img src={PrintIcon} className="turnBlack" />
                              </span>
                            )}
                          </td>
                          {(roleHandler(role, "AONE_ASSETS.write") ||
                            roleHandler(role, "AONE_ASSETS.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                            roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_DOCUMENTS.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_ASSETS_VEHICLES_DOCUMENTS.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button className="tableBtn" title="Edit">
                                <span>
                                  <img
                                    src={editicon}
                                    className="turnBlack"
                                    onClick={() =>
                                      handleVehicleEdit(e.vehicleCode)
                                    }
                                  />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_ASSETS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES.admin"
                                ) ||
                                roleHandler(
                                  role,
                                  "AONE_ASSETS_VEHICLES_DOCUMENTS.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.vehicleCode,
                                      e?.vehicleCode,
                                      "Vehicle Documents"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Pickup Request" ? (
                      <>
                        <tr
                          className={
                            selectPickup?.includes(e?.requestId)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handlePickupCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                          <td className="fixedCol col_index" scope="row">
                          {newPage * size + (i + 1)}
                          </td>
                          {!restrictPickupreq?.includes("Request Id") && (
                            <td className="fixedCol col_trackingId" scope="row">
                              {e?.requestId}
                            </td>
                          )}
                          {!restrictPickupreq?.includes("Customer Account") && (
                            <td className="fixedCol col_cusNum" scope="row">
                              {e?.customerAccount}
                            </td>
                          )}
                          {!restrictPickupreq?.includes("Customer Name") && (
                            <td>{e?.customerName}</td>
                          )}
                            {!restrictPickupreq?.includes("Shipper Name") && (
                            <td>{e?.shipperName}</td>
                          )}
                          {!restrictPickupreq?.includes("Status") && (
                            <td>{e?.status}</td>
                          )}
                            {!restrictPickupreq?.includes("Total Packages") && (
                            <td>{e?.totalPackagesCount}</td>
                          )}
                          {!restrictPickupreq?.includes("Total Count") && (
                            <td>{e?.totalCount}</td>
                          )}
                          {!restrictPickupreq?.includes("Success Count") && (
                            <td> {e?.successCount}</td>
                          )}
                          {!restrictPickupreq?.includes("Failed Count") && (
                            <td> {e?.failedCount}</td>
                          )}
                           {!restrictPickupreq?.includes("RPU") && (
                              <td>{e?.rpu ? "true" : ""}</td>
                          )}
                          {!restrictPickupreq?.includes("Reason") && (
                            <td>{e?.reason}</td>
                          )}
                          {!restrictPickupreq?.includes("Courier Id") && (
                            <td>{e?.employeeCode}</td>
                          )}
                          {!restrictPickupreq?.includes("Origin Country") && (
                            <td> {e?.originDetail?.country}</td>
                          )}
                          {!restrictPickupreq?.includes("Origin City") && (
                            <td>{e?.originDetail?.city}</td>
                          )}
                             {!restrictPickupreq?.includes("Shipper Address") && (
                               <td> {e?.originDetail?.address}</td>
                          )}
                          {!restrictPickupreq?.includes("Shipper Phone") && (
                             <td> {e?.originDetail?.phoneNumber}</td>
                          )}
                          {!restrictPickupreq?.includes("Created at") && (
                            <td>
                              {" "}
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrictPickupreq?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrictPickupreq?.includes("Updated at") && (
                            <td>
                              {" "}
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrictPickupreq?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {!restrictPickupreq?.includes("Actions") && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_PICKUP-REQUEST.admin"
                                  ) ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_PICKUP-REQUEST.cancel"
                                  )) &&
                                  e?.status !== "Cancel" &&
                                  e?.status !== "Partial success" &&
                                  e?.status !== "Success" && (
                                    <button
                                      className="tableBtn"
                                      title="Cancel"
                                      tabIndex="-1"
                                      onClick={() => {
                                        setShowCancel(true);
                                        setCancelRequestId(e?.requestId);
                                        setCancelCourierId(e?.courierId);
                                      }}
                                    >
                                      <span>
                                        <img
                                          src={PickupCancelIcon}
                                          className="h18 turnBlack"
                                        />
                                      </span>
                                    </button>
                                  )}
                                <button
                                  className="tableBtn"
                                  title="Apply Pickup"
                                  onClick={() => {setShowUpload(true); setSelectReq(e?.requestId)}}
                                >
                                  <span>
                                    <img src={PickupApplyIcon} className="turnBlack" />
                                  </span>
                                </button>
                                <button
                                  className="tableBtn"
                                  title="Apply"
                                  onClick={() => {
                                    navigate(`/ops/pickups/${e.requestId}`);
                                  }}
                                >
                                  <span>
                                    <img src={EyeIcon} className="turnBlack" />
                                  </span>
                                </button>
                                {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(role, "AONE_OPS.write") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_PICKUP-REQUEST.admin"
                                  ) ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_PICKUP-REQUEST.write"
                                  ) ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_PICKUP-REQUEST.cancel"
                                  )) &&
                                  e?.status !== "Cancel" &&
                                  e?.status !== "Success" && (
                                    <button
                                      onClick={() => {
                                        setShowPickup(true);
                                        setTrackId(e?.requestId);
                                        SetshowPrCustomer(false);
                                        setCusPage(-1);
                                        setPickupTitle("Edit pickup request");
                                        addPickupRequest.resetForm();
                                      }}
                                      title="Edit"
                                      className="tableBtn"
                                    >
                                      <span>
                                        <img
                                          src={editicon}
                                          className="turnBlack"
                                        />
                                      </span>
                                    </button>
                                  )}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                         ) : tbl === "ShortAddressMapping" ? (
                          <>
                            <tr className="cursorPointer">
                             <td>{newPage * size + (i + 1)}</td>
                              {!restrict?.includes("SPL Region") && (
                                <td>{e?.splRegionName}</td>
                              )}
                              {!restrict?.includes("SPL Governorate") && (
                                <td>{e?.splGovernorate}</td>
                              )}
                              {!restrict?.includes("SPL City") && (
                                <td>{e?.splCityName}</td>
                              )}
                              {!restrict?.includes("SPL District") && (
                                <td>{e?.splDistrictName}</td>
                              )}
                              {!restrict?.includes("Region") && (
                                <td>{e?.regionName}</td>
                              )}
                              {!restrict?.includes("City") && (
                                <td>{e?.cityName}</td>
                              )}
                              {!restrict?.includes("District") && (
                                <td>{e?.districtName}</td>
                              )}
                                  {!restrict?.includes("Created at") && (
                                <td>
                                  {" "}
                                  {e?.createdAt
                                    ? new Date(e?.createdAt).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                        }
                                      )
                                    : null}
                                </td>
                              )}
                              {!restrict?.includes("Created by") && (
                                <td> {e?.createdBy}</td>
                              )}
                              {!restrict?.includes("Updated at") && (
                                <td>
                                  {" "}
                                  {e?.updatedAt
                                    ? new Date(e?.updatedAt).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                        }
                                      )
                                    : null}
                                </td>
                              )}
                              {!restrict?.includes("Updated by") && (
                                <td> {e?.updatedBy}</td>
                              )}
                              {!restrict?.includes("Actions") &&
                (role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write") ||
                 role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin")) && (
                                <td className="fixedCol col_actions" scope="row">
                                  <>
                                  <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => handleShortEdit(e.id)}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                 {(role?.includes("AONE_CONFIGS.admin") ||
                 role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin")) && (
                                        <button
                                          className="tableBtn"
                                          title="Delete"
                                          onClick={() =>
                                            deleteParamsSetter(
                                              e?.id,
                                              e?.splRegionName,
                                              "ShortAddress"
                                            )
                                          }
                                        >
                                          <span>
                                            <img
                                              src={DeleteIcon}
                                              className="h18 turnBlack"
                                            />
                                          </span>
                                        </button>)}
                                  </>
                                </td>
                              )}
                            </tr>
                          </>
                              ) : tbl === "ShortAddressHistory" ? (
                                <>
                                  <tr className="cursorPointer">
                                   <td>{newPage * size + (i + 1)}</td>
                                   {!restrict?.includes("Short address") && (
                                      <td>{e?.shortAddress}</td>
                                    )}
                                     {!restrict?.includes("Region") && (
                                      <td>{e?.region}</td>
                                    )}
                                    {!restrict?.includes("City") && (
                                      <td>{e?.city}</td>
                                    )}
                                    {!restrict?.includes("District") && (
                                      <td>{e?.district}</td>
                                    )}
                                      {!restrict?.includes("SPL Region") && (
                                      <td>{e?.splRegion}</td>
                                    )}
                                     {!restrict?.includes("SPL Governorate") && (
                                      <td>{e?.splGovernorate}</td>
                                    )}
                                    {!restrict?.includes("SPL City") && (
                                      <td>{e?.splCity}</td>
                                    )}
                                    {!restrict?.includes("SPL District") && (
                                      <td>{e?.splDistrict}</td>
                                    )}
                                        {!restrict?.includes("Additional number") && (
                                      <td> {e?.additionalNumber}</td>
                                    )}
                                       {!restrict?.includes("Created at") && (
                                      <td>
                                        {" "}
                                        {e?.createdAt
                                          ? new Date(e?.createdAt).toLocaleString(
                                              "en-US",
                                              {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                              }
                                            )
                                          : null}
                                      </td>
                                    )}
                                     {!restrict?.includes("Created by") && (
                                      <td> {e?.createdBy}</td>
                                    )}
                                    {!restrict?.includes("Updated at") && (
                                      <td>
                                        {" "}
                                        {e?.updatedAt
                                          ? new Date(e?.updatedAt).toLocaleString(
                                              "en-US",
                                              {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                              }
                                            )
                                          : null}
                                      </td>
                                    )}
                                    {!restrict?.includes("Updated by") && (
                                      <td> {e?.updatedBy}</td>
                                    )}
                                  </tr>
                                </>
                    ) : tbl === "PickupConsignment" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Tracking Id") && (
                            <td>{e?.trackingId}</td>
                          )}
                          {!restrict?.includes("Customer Account") && (
                            <td>{e?.customerAccount}</td>
                          )}
                          {!restrict?.includes("Product") && (
                            <td>{e?.productCode}</td>
                          )}
                          {!restrict?.includes("Packages Count") && (
                            <td>{e?.packagesCount}</td>
                          )}
                            {!restrict?.includes("RPU") && (
                            <td>{e?.rpu ? "true" : ""}</td>
                          )}
                          {!restrict?.includes("Status") && (
                            <td>{e?.status}</td>
                          )}
                            {!restrict?.includes("Content") && (
                            <td>{e?.content}</td>
                          )}
                          {!restrict?.includes("Reason") && (
                            <td>{e?.reason}</td>
                          )}
                          {!restrict?.includes("Weight Unit") && (
                            <td>{e?.weightUnit}</td>
                          )}
                          {!restrict?.includes("Weight") && (
                            <td>{e?.weight}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {" "}
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {!restrict?.includes("Actions") && (
                            <td className="fixedCol col_actions" scope="row">
                              <>
                                  <button
                                    title="View"
                                    className="tableBtn"                                    onClick={() => {
                                      const token = state.token;
                                      const url = `/consignments/orders/${e?.trackingId}?token=${token}`;
                                      window.open(url, '_blank');
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={EyeIcon}
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>
                                {(roleHandler(role, "AONE_OPS.admin") ||
                                  roleHandler(
                                    role,
                                    "AONE_OPS_PICKUP-REQUEST.admin"
                                  )) &&
                                  e?.status !== "Pickup Completed" &&
                                  e?.status !== "Received at origin hub" && (
                                    <button
                                      className="tableBtn"
                                      title="Delete"
                                      onClick={() =>
                                        deleteParamsSetter(
                                          e?.trackingId,
                                          e?.trackingId,
                                          "Consignments"
                                        )
                                      }
                                    >
                                      <span>
                                        <img
                                          src={DeleteIcon}
                                          className="h18 turnBlack"
                                        />
                                      </span>
                                    </button>
                                  )}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "ACL" ? (
                      <>
                        <tr className={
                            selectAclNum?.includes(e?.number)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }>
                        <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={settleAcl[i]}
                                onChange={() => handleCheckboxAclClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                         <td>{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Acl Number") && (
                            <td>{e?.number}</td>
                          )}
                          {/* {!restrict?.includes("Seal Number") && (
                            <td>{e?.sealNumber}</td>
                          )} */}
                          {!restrict?.includes("Trip Id") && (
                            <td>{e?.tripId}</td>
                          )}
                          {!restrict?.includes("Total Shipments") && (
                            <td>{e?.totalShipment}</td>
                          )}
                          {!restrict?.includes("Type") && <td>{e?.type}</td>}
                          {!restrict?.includes("Origin Hub") && (
                            <td>{e?.originHubName}</td>
                          )}
                          {!restrict?.includes("Destination Hub") && (
                            <td>{e?.destinationHubName}</td>
                          )}
                          {!restrict?.includes("Destination Zone") && (
                            <td>{e?.destinationZoneName}</td>
                          )}
                          {/* {!restrict?.includes("Route") && (
                              <td>{e?.routeName}</td>
                            )} */}
                          {!restrict?.includes("Assignee") && (
                            <td> {e?.assignee}</td>
                          )}
                          {!restrict?.includes("Assigned By") && (
                            <td> {e?.assignedBy}</td>
                          )}
                          {!restrict?.includes("Total Weight") && (
                            <td> {e?.totalWeight}</td>
                          )}
                          {!restrict?.includes("Weight Unit") && (
                            <td> {e?.totalWeightUnit}</td>
                          )}
                          {!restrict?.includes("Created At") && (
                            <td>
                              {" "}
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created By") && (
                            <td>{e?.createdBy}</td>
                          )}
                          {(roleHandler(role, "AONE_OPS.admin") ||
                            roleHandler(role, "AONE_OPS.write") ||
                            roleHandler(role, "AONE_OPS_ACL.write") ||
                            roleHandler(role, "AONE_OPS_ACL.admin")) &&
                            !restrict?.includes("Actions") && (
                              <td className="fixedCol col_actions" scope="row">
                                <>
                                  <button
                                    title="View"
                                    className="tableBtn"
                                    onClick={() => {
                                      sessionStorage.setItem("originHub", e?.originHubName);
                                      sessionStorage.setItem("destinationHub", e?.destinationHubName);
                                      sessionStorage.setItem("destinationZone", e?.destinationZoneName);
                                      navigate(`/ops/consolidations/${e?.number}`);
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={EyeIcon}
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>
                                  {e?.type == "DESTINATION_ZONE" && (
                                    <button
                                      className="tableBtn"
                                      title="Assign Courier"
                                      onClick={(event) => {
                                        assignAclModal(e?.number, e?.assignee);
                                      }}
                                    >
                                      <span>
                                        <img
                                          src={assignIcon}
                                          className="turnBlack"
                                        />
                                      </span>
                                    </button>
                                  )}
                                  {(roleHandler(role, "AONE_OPS.admin") ||
                                    roleHandler(role, "AONE_OPS_ACL.admin")) &&
                                    e?.assignee !== null && (
                                      <button
                                        className="tableBtn"
                                        title="Unassign Courier"
                                        onClick={() => {
                                          setAclNum(e?.number);
                                          setAclAssignee(e?.assignee);
                                          setShowAssign(true);
                                        }}
                                      >
                                        <span>
                                          <img
                                            src={unassignIcon}
                                            className="turnBlack"
                                          />
                                        </span>
                                      </button>
                                    )}
                                  {/* <button
                                    className="tableBtn"
                                    title="Edit"
                                    onClick={() => handleAclEdit(e)}
                                  >
                                    <span>
                                      <img
                                        src={editicon}
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>{" "}
                                  &nbsp; */}
                                    {(roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_ACL.write") ||
              roleHandler(role, "AONE_OPS_ACL.admin")) && 
                                    !e?.sealNumber &&
                                     (
                                      <button
                                        className="tableBtn"
                                        title="Seal"
                                        onClick={() => {
                                          setAclNum(e?.number);
                                          setSealNum(e?.sealNumber);
                                          setShoword(true);
                                        }}
                                      >
                                        <span>
                                          <img
                                            src={SealIcon}
                                            className="turnBlack"
                                          />
                                        </span>
                                      </button>
                                    )}
                                   {(roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_ACL.write") ||
              roleHandler(role, "AONE_OPS_ACL.admin")) && 
                                    e?.sealNumber &&
                                     (
                                      <button
                                        className="tableBtn"
                                        title="Unseal"
                                        onClick={() => {
                                          {
                                            setAclNum(e?.number);
                                            handlescanShow();
                                          }
                                        }}
                                      >
                                        <span>
                                          <img
                                            src={UnsealIcon}
                                            className="turnBlack"
                                          />
                                        </span>
                                      </button>
                                    )}
                                </>
                              </td>
                            )}
                        </tr>
                      </>
                    ) : tbl === "ConsignmentOrders" ? (
                      <>
                        <tr
                          className={
                            selectOrders?.includes(e?.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td className="fixedCol col_no" scope="row">
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() => handleOrdersCheckboxClick(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                           <td className="fixedCol col_Firstname" scope="row">{newPage * size + (i + 1)}</td>
                          {!restrict?.includes("Tracking Id") && (
                            <td className="fixedCol col_trackingId" scope="row">
                              {e?.trackingId}
                            </td>
                          )}
                          {!restrict?.includes("Original Tracking Id") && (
                            <td>{e?.originalTrackingId}</td>
                          )}
                          {!restrict?.includes("RTO Tracking Id") && (
                            <td>{e?.rtoTrackingId}</td>
                          )}
                          {!restrict?.includes("Reference Number") && (
                            <td>{e?.referenceNumber}</td>
                          )}
                          {!restrict?.includes("Product") && (
                            <td>{e?.productCode}</td>
                          )}
                          {!restrict?.includes("Status") && (
                            <td>{e?.status}</td>
                          )}
                            {!restrict?.includes("RTO") && (
                            <td>{e?.rto ? "true" : ""}</td>
                          )}
                           {!restrict?.includes("RPU") && (
                            <td>{e?.rpu ? "true" : ""}</td>
                          )}
                          {!restrict?.includes("Origin Country") && (
                            <td> {e?.originDetails?.country}</td>
                          )}
                          {!restrict?.includes("Origin Region") && (
                            <td> {e?.originDetails?.region}</td>
                          )}
                          {!restrict?.includes("Origin City") && (
                            <td> {e?.originDetails?.city}</td>
                          )}
                            {!restrict?.includes("Origin District") && (
                            <td> {e?.originDetails?.district}</td>
                          )}
                          {!restrict?.includes("Destination Country") && (
                            <td> {e?.destinationDetails?.country}</td>
                          )}
                          {!restrict?.includes("Destination Region") && (
                            <td> {e?.destinationDetails?.region}</td>
                          )}
                          {!restrict?.includes("Destination City") && (
                            <td>{e?.destinationDetails?.city}</td>
                          )}
                            {!restrict?.includes("Destination District") && (
                            <td> {e?.destinationDetails?.district}</td>
                          )}
                            {!restrict?.includes("Receiver Phone") && (
                            <td> {e?.destinationDetails?.phone}</td>
                          )}
                            {!restrict?.includes("COD Amount") && (
                            <td> {e?.codAmount}</td>
                          )}
                          {!restrict?.includes("Created at") && (
                            <td>
                              {" "}
                              {e?.createdAt
                                ? new Date(e?.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Created by") && (
                            <td> {e?.createdBy}</td>
                          )}
                          {!restrict?.includes("Updated at") && (
                            <td>
                              {" "}
                              {e?.updatedAt
                                ? new Date(e?.updatedAt).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : null}
                            </td>
                          )}
                          {!restrict?.includes("Updated by") && (
                            <td> {e?.updatedBy}</td>
                          )}
                          {(roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") ||
                            roleHandler(
                              role,
                              "AONE_CONSIGNMENTS_CONSIGNMENT.edit_destination_address"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_CONSIGNMENTS_CONSIGNMENT.edit_incoterms"
                            )) &&
                            !restrict?.includes("Actions") && (
                              <td className="fixedCol col_actions" scope="row">
                                <>
                                  {(roleHandler(
                                    role,
                                    "AONE_CONSIGNMENTS_CONSIGNMENT.create"
                                  ) ||
                                    roleHandler(
                                      role,
                                      "AONE_CONSIGNMENTS_CONSIGNMENT.edit_destination_address"
                                    ) ||
                                    roleHandler(
                                      role,
                                      "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount"
                                    ) ||
                                    roleHandler(
                                      role,
                                      "AONE_CONSIGNMENTS_CONSIGNMENT.edit_incoterms"
                                    )) && (
                                    <button
                                      title="View"
                                      className="tableBtn"
                                      onClick={() => {
                                        const token = state.token;
                                        const url = `/consignments/orders/${e?.trackingId}?token=${token}`;
                                        window.open(url, '_blank');
                                      }}
                                    >
                                      <span>
                                        <img
                                          src={EyeIcon}
                                          className="turnBlack"
                                        />
                                      </span>
                                    </button>
                                  )}
                               {roleHandler(role, "AONE_CONSIGNMENTS_CONSIGNMENT.create") &&
                                e?.status !== "Consignment Canceled" &&
                                e?.status !== "Delivered" && (
                                      <button
                                        className="tableBtn"
                                        title="Print"
                                        onClick={() =>
                                          printConsignmentOrders(e.trackingId)
                                        }
                                      >
                                        <span>
                                          <img
                                            src={PrintIcon}
                                            className="turnBlack"
                                          />
                                        </span>
                                      </button>
                                    )}
                                  {roleHandler(
                                    role,
                                    "AONE_CONSIGNMENTS_CONSIGNMENT.cancel"
                                  ) &&
                                    (e?.status ===
                                      "Assigned to a pickup request" ||
                                      e?.status === "Pickup Scheduled" ||
                                      e?.status === "Consignment Created" ||
                                      e?.status === "Pickup Failed") && (
                                      <button
                                        className="tableBtn"
                                        title="Cancel"
                                        onClick={() =>
                                          handleBulkCancelOrders(e.trackingId)
                                        }
                                      >
                                        <span>
                                          <img
                                            src={cancelIcon}
                                            className="turnBlack"
                                          />
                                        </span>
                                      </button>
                                    )}
                                </>
                              </td>
                            )}
                        </tr>
                      </>
                    ) : tbl === "OrderTracking" ? (
                      <>
                        <tr className={"cursorPointer"}>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>
                            {e?.eventDate
                              ? new Date(e?.eventDate).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.eventCode}</td>
                          <td>{e?.eventStatus}</td>
                          <td>{e?.description}</td>
                          <td>{e?.employeeCode}</td>
                          <td>{e?.employeeName}</td>
                          <td> {e?.hubCode}</td>
                          <td> </td>
                          <td> {e?.signature}</td>
                          <td> {e?.failureReason}</td>
                          {e?.latitude !== null && e?.latitude ? (
                            <td>
                              {e?.latitude},{e?.longitude}{" "}
                              <span
                                onClick={() =>
                                  handleClick(e?.latitude, e?.longitude)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img src={externalLink} alt="Link" />
                              </span>
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td> {e?.pickupRequestId}</td>
                          <td>{e?.category}</td>
                          <td>{e?.thirdPartyCode}</td>
                          <td>{e?.clientId}</td>
                        </tr>
                      </>
                    ) : tbl === "Invoice Request Templates" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.name}</td>
                          <td>
                            {countriesArray?.find(
                              (country) =>
                                country?.isoCode3 === e?.billingCountry
                            )?.name || e?.billingCountry}
                          </td>
                          <td>{e?.operationCode}</td>
                          <td>{e?.message}</td>
                          {/* <td>01/12/2023</td>  */}
                          {/* <td>31/12/2023</td> */}
                          {/* <td>
                            <button className="tableBtn" title="Edit">
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(roleHandler(role, "AONE_FINANCE.admin") ||
                              roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                              roleHandler(
                                role,
                                "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.accountNumber,
                                    e?.accountNumber,
                                    "Customers"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td> */}
                        </tr>
                      </>
                    ) : tbl === "Consignments" ? (
                      <>
                        <tr className="cursorPointer">
                          <td className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_Firstname" scope="row">
                            {e?.customerAccountNo}
                          </td>
                          <td>{e?.waybillNo}</td>
                          <td className="textAlignCenter">
                            {e?.customerAccountName}
                          </td>
                          <td className="fixedCol col_invoiceId textAlignCenter">
                            {e?.invoiceRequestId}
                          </td>
                          <td>{e?.originCountry}</td>
                          <td>{e?.destinationCountry}</td>
                          <td className="textAlignCenter">
                            {e?.serviceTypeId}
                          </td>
                          <td className="textAlignCenter">
                            {e?.consignmentStatus}
                          </td>
                          <td>{e?.message}</td>
                          <td>{e?.invoiceNumber}</td>
                          <td>{e?.transactionId}</td>
                          <td>
                            {e?.transactionDate
                              ? new Date(e?.transactionDate).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )
                              : null}
                          </td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="textAlignCenter">{e?.createdBy}</td>
                          <td>
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="textAlignCenter">{e?.updatedBy}</td>
                          {(roleHandler(role, "AONE_FINANCE.write") ||
                            roleHandler(role, "AONE_FINANCE.admin") ||
                            roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
                            roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
                            roleHandler(
                              role,
                              "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write"
                            ) ||
                            roleHandler(
                              role,
                              "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Fetch"
                                onClick={() => fetchInvoice(e?.waybillNo)}
                              >
                                <span>
                                  <img src={FetchIcon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              {/* <button
                                className="tableBtn"
                                title="Download"
                                onClick={() => downloadConsignmentTemplate(e.waybillNo)}
                              >
                                <span>
                                  <img src={PrintIcon} className="turnBlack" />
                                </span>
                              </button> */}
                              {/* &nbsp;
                              <button
                                className="tableBtn"
                                title="View"
                              >
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button> */}
                              {/* <button
                              className="tableBtn"
                              title="Delete"
                              onClick={() =>
                                deleteParamsSetter(e?.accountNumber, e?.accountNumber, "Customers")
                              }
                            >
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button> */}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Zone" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.alias}</td>
                          <td>{e?.countryName}</td>
                          <td>{e?.groupCode}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes("AONE_CONFIGS_ZONES.admin")) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(e?.code, e?.code, "Zones")
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Zonegroups" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.countryName}</td>
                          <td>
                            {" "}
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {" "}
                            {e?.updatedAt
                              ? new Date(e?.updatedAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_CONFIGS.admin") ||
                              role?.includes("AONE_CONFIGS_ZONES.admin")) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.code,
                                    e?.code,
                                    "Zonesgroup"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "AclTracking" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>
                            {e?.eventTime
                              ? new Date(e?.eventTime).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.eventCode}</td>
                          <td>{e?.eventStatus}</td>
                          {/* <td>{e?.count}</td> */}
                          <td>{e?.employeeCode}</td>
                          <td>{e?.hubCode}</td>
                          <td>{e?.sealNumber}</td>
                          <td>{e?.vehicleId}</td>
                        </tr>
                      </>
                    ) : tbl === "AclConsignments" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.aclNumber}</td>
                          <td>{e?.trackingId}</td>
                          <td>{e?.packageTrackingId}</td>
                          <td>{e?.originHubName}</td>
                          <td>{e?.destinationHubName}</td>
                          <td>{e?.destinationZoneName}</td>
                          {/* <td>{e?.routeCode}</td> */}
                          <td>{e?.errorMsg}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Transfer Consignment"
                              onClick={() => {
                                handleShowTransfer(e?.packageTrackingId);
                              }}
                            >
                              <span>
                                <img src={moveicon} className="h18 turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              className="tableBtn"
                              title="Delete"
                              onClick={() =>
                                deleteParamsSetter(
                                  e?.aclNumber,
                                  e?.packageTrackingId,
                                  "ACL Consignments"
                                )
                              }
                            >
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "DlValidations" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.trackingId}</td>
                          <td>{e?.packageTrackingId}</td>
                          <td>{e?.isMultiple ? "true" : ""}</td>
                          <td>{e?.courierName}</td>
                          <td>{e?.attempts}</td>
                          <td>{e?.hubCode}</td>
                          {(roleHandler(role, "AONE_OPS.admin") ||
  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write")) && (
                          <td>
                            <button
                              className="tableBtn"
                              title="Delete"
                              onClick={() =>
                                deleteParamsSetter(
                                  e?.id,
                                  e?.packageTrackingId,
                                  "Delivery Validation"
                                )
                              }
                            >
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>)}
                        </tr>
                      </>
                                          ) : tbl === "Debrief" ? (
                                            <>
                                              <tr className="cursorPointer">
                                               <td>{newPage * size + (i + 1)}</td>
                                                <td>{e?.trackingId}</td>
                                                <td>{e?.packageTrackingId}</td>
                                                <td>{e?.status}</td>
                                                <td>{e?.reason}</td>
                                                <td>{e?.courierId}</td>
                                                <td>{e?.attempts}</td>
                                                <td>{e?.hubCode}</td>
                                                <td>
  <div className="col-md-3">
    {e?.latitude && e?.longitude ? (
      <button className="cancel_btn">
        <span
          onClick={() =>
            handleClick(e.latitude, e.longitude)
          }
          style={{ cursor: "pointer" }}
        >
          <img src={externalLink} alt="Link" />
        </span>
      </button>
    ) : null}
  </div>
</td>
                                                <td>{e?.proofImageUrls}</td>
                                              </tr>
                                            </>
                                               ) : tbl === "DebreifingHistory" ? (
                                                <>
                                                  <tr className="cursorPointer">
                                                   <td>{newPage * size + (i + 1)}</td>
                                                    <td>{e?.courierId}</td>
                                                    <td>{e?.courierName}</td>
                                                    <td>{e?.hubCode}</td>
                                                    <td>{e?.deliveredCount}</td>
                                                    <td>{e?.receivedCount}</td>
                                                    <td>  {e?.debriefingAt
                              ? new Date(e?.debriefingAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}</td>
                                                    <td>{e?.debriefingBy}</td>
                                                    <td>{e?.debriefingName}</td>
                                                  </tr>
                                                </>
                    ) : tbl === "DeliveryTasks" ? (
                      <>
                        <tr className="cursorPointer">
                         <td  className="fixedCol col_no" scope="row">{newPage * size + (i + 1)}</td>
                          <td  className="fixedCol col_Firstname" scope="row">{e?.trackingId}</td>
                          <td  className="fixedCol col_cusNum" scope="row">{e?.destinationZoneName}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.productCode}</td>
                          <td>{e?.hubCode}</td>
                          <td>{e?.status}</td>
                          <td>{e?.employeeCode}</td>
                          <td>{e?.packageCount}</td>
                          <td>{e?.assignedBy}</td>
                          <td>
                            {e?.assignedAt
                              ? new Date(e?.assignedAt).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )
                              : null}
                          </td>
                          <td>{e?.unassignedBy}</td>
                          <td>
                            {e?.unassignedAt
                              ? new Date(e?.unassignedAt).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )
                              : null}
                          </td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.destinationCityName}</td>
                          <td>{e?.destinationDistrictName}</td>
                          {(roleHandler(role, "AONE_OPS.write") ||
                 roleHandler(role, "AONE_OPS.admin") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write")) && (
                          <td className="fixedCol col_actions" scope="row">
                            {!e?.employeeCode ? (
                              <button
                                className="tableBtn"
                                title="Assign Courier"
                                onClick={(event) => {
                                  setShowAssignCourier(true);
                                  setDelivTitle(`Assign courier`);
                                  addDeliveryTask.setFieldValue(
                                    "trackingId",
                                    e?.trackingId
                                  );
                                }}
                              >
                                <span>
                                  <img src={assignIcon} className="turnBlack" />
                                </span>
                              </button>
                            ) : (
                              <button
                                className="tableBtn"
                                title="Unassign Courier"
                              >
                                <span>
                                  <img
                                    src={unassignIcon}
                                    className="h18 turnBlack"
                                    onClick={() => {
                                      setShowUnAssignCourier(true);
                                      SetDeliveryId(e?.id);
                                      setDelivPackage(e?.trackingId);
                                      setDelivCourier(e?.employeeCode);
                                    }}
                                  />
                                </span>
                              </button>
                            )}
                          </td>)}
                        </tr>
                      </>
                       ) : tbl === "Configs" ? (
                        <>
                          <tr className="cursorPointer">
                           <td>{newPage * size + (i + 1)}</td>
                            <td>{e?.name}</td>
                            <td>{e?.printBag ? "true" : null}</td>
                            <td>{e?.description}</td>
                            {(roleHandler(role, "CONFIGS.write") ||
                              roleHandler(role, "CONFIGS.admin")) && (
                              <td>
                                <button
                                  className="tableBtn"
                                  title="View"
                                  onClick={() => (
                                    setSaveHandlerAction(true),
                                    setModalHandler(!modalHandler),
                                    setModalData(e)
                                  )}
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>{" "}
                                &nbsp;
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(e?.id, e?.name, "Config")
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              </td>
                            )}
                            <td>
                              <button
                                className="tableBtn"
                                title="View Mapping"
                                onClick={() => (
                                  setHeader(e?.name),
                                  setSortField("chuteNumber,zoneCode"),
                                  navigate(
                                    `/middlemile/sortingmachine/config/${e.id}`
                                  ),
                                  setSelectAll(false),
                                  setShowActions(false)
                                )}
                              >
                                <span>
                                  <img
                                    src={arrowRight}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            </td>
                          </tr>
                        </>
                    ) : tbl === "Configs_Ops" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.name}</td>
                          <td>{e?.aclType}</td>
                          <td>{e?.printACL ? "true" : null}</td>
                          <td>{e?.description}</td>
                          {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
                            roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="View"
                                onClick={() => (
                                  setSaveHandlerAction(true),
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin") && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(e?.id, e?.name, "Config")
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>)}
                            </td>
                          )}
                          <td>
                            <button
                              className="tableBtn"
                              title="View Mapping"
                              onClick={() => handleClickSort(e)}
                            >
                              <span>
                                <img
                                  src={arrowRight}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Thirdparties" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.status}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          {(role?.includes("AONE_ADMIN") ||
                            role?.includes(
                              "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write"
                            ) ||
                            role?.includes(
                              "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin"
                            ) ||
                            role?.includes("AONE_THIRD-PARTY.write") ||
                            role?.includes("AONE_THIRD-PARTY.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_ADMIN") ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin"
                                ) ||
                                role?.includes("AONE_THIRD-PARTY.admin")) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e.id,
                                      e.code,
                                      "Thirdparty"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Mapping" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.carrierId}</td>
                          <td>{e?.alias}</td>
                          <td>{e?.value}</td>
                          <td>{e?.type}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          {(role?.includes("AONE_ADMIN") ||
                            role?.includes(
                              "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write"
                            ) ||
                            role?.includes(
                              "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin"
                            ) ||
                            role?.includes("AONE_THIRD-PARTY.write") ||
                            role?.includes("AONE_THIRD-PARTY.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setSaveHandlerAction(true),
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(role?.includes("AONE_ADMIN") ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin"
                                ) ||
                                role?.includes("AONE_THIRD-PARTY.admin")) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e.id,
                                      e.carrier,
                                      "Mapping"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Events" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.carrier}</td>
                          <td>{e?.referenceNumber}</td>
                          <td>{e?.trackingId}</td>
                          <td>{e?.status}</td>
                          <td>{e?.thirdPartyStatus}</td>
                          <td>{e?.exception}</td>
                          <td>
                            {e?.eventDate
                              ? new Date(e?.eventDate).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="fixedCol col_actions" scope="row">
                            <Link
                              to={`${shipseyUrl}ops/details/${e?.waybillNumber}`}
                              target="_blank"
                            >
                              <button className="tableBtn" title="View">
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button>
                            </Link>
                            &nbsp;
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Orders" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.carrier}</td>
                          <td>{e?.referenceNumber}</td>
                          <td>{e?.waybillNumber}</td>
                          <td>{e?.status}</td>
                          <td>{e?.thirdPartyOrderNo}</td>
                          <td>{e?.thirdPartyStatus}</td>
                          <td>{e?.exception}</td>
                          <td>
                            {e?.eventTime
                              ? new Date(e?.eventTime).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {e?.pushTime
                              ? new Date(e?.pushTime).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="fixedCol col_actions" scope="row">
                            <Link
                              to={`${shipseyUrl}ops/details/${e?.waybillNumber}`}
                              target="_blank"
                            >
                              <button className="tableBtn" title="View">
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button>
                            </Link>
                            &nbsp;
                            {(e?.carrier || e?.thirdPartyOrderNo) &&
                              (role?.includes("AONE_ADMIN") ||
                                role?.includes("AONE_THIRD-PARTY.admin") ||
                                role?.includes("AONE_THIRD-PARTY.write") ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_ORDERS.admin"
                                ) ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_ORDERS.write"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Print"
                                  onClick={() =>
                                    printThirdpartyOrder(e.waybillNumber)
                                  }
                                >
                                  <span>
                                    <img
                                      src={PrintIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            &nbsp;
                            {!e?.carrier &&
                              (role?.includes("AONE_ADMIN") ||
                                role?.includes("AONE_THIRD-PARTY.admin") ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_ORDERS.admin"
                                ) ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_ORDERS.assign"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Assign"
                                  onClick={() => assignModal(e.waybillNumber)}
                                >
                                  <span>
                                    <img
                                      src={assignIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            &nbsp;
                            {e?.status !== "NOT_ASSIGN" &&
                              e.carrier &&
                              (role?.includes("AONE_ADMIN") ||
                                role?.includes("AONE_THIRD-PARTY.admin") ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_ORDERS.cancel"
                                ) ||
                                role?.includes(
                                  "AONE_THIRD-PARTY_ORDERS.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Cancel"
                                  onClick={() =>
                                    cancelOrder(
                                      e.referenceNumber,
                                      e.waybillNumber
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={cancelIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                          </td>
                        </tr>
                      </>
                        ) : tbl === "DetailedConfig" ? (
                          <>
                            <tr
                              className={
                                seleczone.includes(e.zoneCode)
                                  ? "selectRow cursorPointer"
                                  : "cursorPointer"
                              }
                            >
                              <td>
                                <div className="col-md-12 smallCheckbox">
                                  <input
                                    type="checkbox"
                                    id={`tableCheck${i}`}
                                    checked={individualCheckboxes[i]}
                                    onChange={() =>
                                      handleIndividualCheckboxClick(i)
                                    }
                                  />
                                  <label htmlFor={`tableCheck${i}`} />
                                </div>
                              </td>
                             <td>{newPage * size + (i + 1)}</td>
                              <td>{e?.zoneCode}</td>
                              <td>{e?.printBag ? "true" : null}</td>
                              <td>{e?.zoneGroup?.name}</td>
                              <td>{e?.chuteNumber}</td>
                              <td>{e?.hubCode}</td>
                              <td>{e?.hubName}</td>
                              {(roleHandler(role, "CONFIGS.write") ||
                                roleHandler(role, "CONFIGS.admin")) && (
                                <td>
                                  <button
                                    className="tableBtn"
                                    title="Edit"
                                    onClick={() => (
                                      setSaveHandlerAction(true),
                                      setModalHandler(!modalHandler),
                                      setModalData(e)
                                    )}
                                  >
                                    <span>
                                      <img src={editicon} className="turnBlack" />
                                    </span>
                                  </button>{" "}
                                  &nbsp;
                                  <button
                                    className="tableBtn"
                                    title="Delete"
                                    onClick={() =>
                                      deleteParamsSetter(
                                        e?.zoneCode,
                                        e?.zoneCode,
                                        "Chute"
                                      )
                                    }
                                  >
                                    <span>
                                      <img
                                        src={DeleteIcon}
                                        className="h18 turnBlack"
                                      />
                                    </span>
                                  </button>
                                </td>
                              )}
                            </tr>
                          </>
                    ) : tbl === "DetailedConfig_Ops" ? (
                      <>
                        <tr
                          className={
                            seleczone.includes(e.zoneCode)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td>
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() =>
                                  handleIndividualCheckboxClick(i)
                                }
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.zoneCode}</td>
                          <td>{e?.zoneGroup?.name}</td>
                          <td>{e?.chuteNumber}</td>
                          <td>{e?.hubCode}</td>
                          <td>{e?.printACL ? "true" : null}</td>
                          {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
                            roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setSaveHandlerAction(true),
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {roleHandler(role, "AONE_OPS_SORTING-MACHINE_CONFIGS.admin") && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.zoneCode,
                                    e?.zoneCode,
                                    "Chute"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>)}
                            </td>
                          )}
                        </tr>
                      </>
                       ) : tbl === "Agents" ? (
                        <>
                          <tr className="cursorPointer">
                           <td>{newPage * size + (i + 1)}</td>
                            <td>{e?.agentId}</td>
                            <td>{e?.assignedConfigName}</td>
                            <td>{e?.healthy}</td>
                            <td
                              onClick={() => statusSwitchHandler(e.id, e.status)}
                            >
                              {e?.status ? (
                                <p className="status_active">Active</p>
                              ) : (
                                <p className="status_inactive">Inactive</p>
                              )}
                            </td>
                            <td>{e?.hubCode}</td>
                            <td>{e?.hubName}</td>
                            {(roleHandler(role, "CONFIGS.write") ||
                              roleHandler(role, "CONFIGS.admin")) && (
                              <td>
                                <button
                                  className="tableBtn"
                                  title="Edit"
                                  onClick={() => (
                                    setSaveHandlerAction(true),
                                    setModalHandler(!modalHandler),
                                    setModalData(e)
                                  )}
                                >
                                  <span>
                                    <img src={editicon} className="turnBlack" />
                                  </span>
                                </button>{" "}
                                &nbsp;
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(e?.id, e?.agentId, "Agent")
                                  }
                                >
                                  {" "}
                                  {/* ()=>deleteById(e.id) */}
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              </td>
                            )}
                          </tr>
                        </>
                    ) : tbl === "Agents_Ops" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.agentId}</td>
                          <td>{e?.assignedConfigName}</td>
                          <td
                            onClick={() => statusSwitchHandler(e.id, e.status)}
                          >
                            {e?.status ? (
                              <p className="status_active">Active</p>
                            ) : (
                              <p className="status_inactive">Inactive</p>
                            )}
                          </td>
                          <td>{e?.hubCode}</td>
                          {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.write") ||
                            roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setSaveHandlerAction(true),
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>{" "}
                              &nbsp;
                              {(roleHandler(role, "AONE_OPS_SORTING-MACHINE_AGENTS.admin")) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(e?.id, e?.agentId, "Agent")
                                }
                              >
                                {" "}
                                {/* ()=>deleteById(e.id) */}
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>)}
                            </td>
                          )}
                        </tr>
                      </>
                         ) : tbl === "Machinescan" ? (
                          <>
                            <tr
                              className={
                                seleczone.includes(e.id)
                                  ? "selectRow cursorPointer"
                                  : "cursorPointer"
                              }
                            >
                              <td>
                                <div className="col-md-12 smallCheckbox">
                                  <input
                                    type="checkbox"
                                    id={`tableCheck${i}`}
                                    checked={individualCheckboxes[i]}
                                    onChange={() =>
                                      handleIndividualCheckboxClickScan(i)
                                    }
                                  />
                                  <label htmlFor={`tableCheck${i}`} />
                                </div>
                              </td>
                             <td>{newPage * size + (i + 1)}</td>
                              <td>{e?.agentId}</td>
                              <td>{e?.waybillNumber}</td>
                              <td>{e?.customerCode}</td>
                              <td>{e?.zoneCode}</td>
                              <td>{e?.zoneGroup?.name}</td>
                              <td>{e?.chute}</td>
                              <td>{e?.currentHubCode}</td>
                              <td>{e?.desHubCode}</td>
                              <td>{e?.currentStatus}</td>
                              <td>{e?.configSummary?.name}</td>
                              <td>{e?.weight}</td>
                              <td>
                                {e?.length},{e.width},{e.height}
                              </td>
                              <td>
                                {e?.scanTime
                                  ? new Date(e?.scanTime).toLocaleString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    })
                                  : null}
                              </td>
                              <td>
                                {e?.status == "1" ? (
                                  <p className="success_active">Success</p>
                                ) : e?.status == "0" ? (
                                  <p className="success_inactive">Fail</p>
                                ) : null}
                              </td>
                              <td>{e?.reason}</td>
                              {(roleHandler(role, "CONFIGS.write") ||
                                roleHandler(role, "CONFIGS.admin")) && (
                                <td className="fixedCol col_bagactions" scope="row">
                                  <button
                                    className="tableBtn"
                                    title={
                                      e?.currentStatus === "pickup_completed" ||
                                      e?.currentStatus === null
                                        ? "Receive"
                                        : e?.currentStatus === "inscan_at_hub"
                                        ? "Outscan"
                                        : null
                                    }
                                    onClick={() => {
                                      handleStatusButtonClick(e);
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={
                                          e?.currentStatus === "pickup_completed" ||
                                          e?.currentStatus === null
                                            ? receiveicon
                                            : e?.currentStatus === "inscan_at_hub"
                                            ? outscanicon
                                            : null
                                        }
                                        className="turnBlack"
                                      />
                                    </span>
                                  </button>
                                  <Link
                                    to={`${shipseyUrl}ops/details/${e?.waybillNumber}`}
                                    target="_blank"
                                  >
                                    <button className="tableBtn" title="View">
                                      <span>
                                        <img src={EyeIcon} className="turnBlack" />
                                      </span>
                                    </button>{" "}
                                  </Link>
                                </td>
                              )}
                            </tr>
                          </>  
                    ) : tbl === "Machinescan_Ops" ? (
                      <>
                        <tr
                          className={
                            seleczone.includes(e.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td>
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxes[i]}
                                onChange={() =>
                                  handleIndividualCheckboxClickScan(i)
                                }
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.agentId}</td>
                          <td>{e?.trackingId}</td>
                          <td>{e?.packageTrackingId}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.zoneCode}</td>
                          <td>{e?.zoneGroup?.name}</td>
                          <td>{e?.hubCode}</td>
                          <td>{e?.desHubCode}</td>
                          <td>{e?.weight}</td>
                          <td>
                            {e?.length},{e.width},{e.height}
                          </td>
                          <td>
                            {e?.scanTime
                              ? new Date(e?.scanTime).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          {roleHandler(role, "AONE_OPS_SORTING-MACHINE_SCANS.read") && (
                            <td className="fixedCol col_bagactions" scope="row">
                              <button
                                className="tableBtn"
                                title={
                                  e?.currentStatus === "pickup_completed" ||
                                  e?.currentStatus === null
                                    ? "Receive"
                                    : e?.currentStatus === "inscan_at_hub"
                                    ? "Outscan"
                                    : null
                                }
                                onClick={() => {
                                  handleStatusButtonClick(e);
                                }}
                              >
                                <span>
                                  <img
                                    src={
                                      e?.currentStatus === "pickup_completed" ||
                                      e?.currentStatus === null
                                        ? receiveicon
                                        : e?.currentStatus === "inscan_at_hub"
                                        ? outscanicon
                                        : null
                                    }
                                    className="turnBlack"
                                  />
                                </span>
                              </button>
                              <button
                                      title="View"
                                      className="tableBtn"
                                      onClick={() => {
                                        const token = state.token;
                                        const url = `/consignments/orders/${e?.trackingId}?token=${token}`;
                                        window.open(url, '_blank');
                                      }}
                                    >
                                      <span>
                                        <img
                                          src={EyeIcon}
                                          className="turnBlack"
                                        />
                                      </span>
                                    </button>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "jobOrders" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.customerAccount}</td>
                          <td>{e?.estimatedReceiveDate?.substring(0, 10)}</td>
                          <td>{e?.scheduledDeliverDate?.substring(0, 10)}</td>
                          <td>{e?.fulfillmentStatus}</td>
                          <td>{e?.boxCount}</td>
                          <td>{e?.status}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() =>
                                navigate(`/projects/addon/orders/${e?.id}`)
                              }
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              className="tableBtn"
                              title="Delete"
                              onClick={() => deleteById(e.id)}
                            >
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Handover" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.fullfillment_employee}</td>
                          <td>{e?.hub_operator}</td>
                          <td>{e?.joborder_code}</td>
                          <td>{e?.box_quantity}</td>
                          <td>{e?.created_by}</td>
                          <td>{e?.updated_by}</td>
                          <td>{e?.created_at}</td>
                          <td>{e?.updated_at}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                // setEditHandler(!editHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Delete">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Delivered" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.courier}</td>
                          <td>{e?.joborder_code}</td>
                          <td>{e?.waybill_no}</td>
                          <td>{e?.delivery_date}</td>
                          <td>{e?.delivery_location}</td>
                          <td>{e?.customer_account}</td>
                          <td>{e?.customer_name}</td>
                          <td>{e?.created_by}</td>
                          <td>{e?.updated_by}</td>
                          <td>{e?.created_at}</td>
                          <td>{e?.updated_at}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Delete">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Products" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.code}</td>
                          <td>{e?.name}</td>
                          <td>{e?.contentType}</td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.updateBy}</td>
                          <td>
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_PRODUCT.admin") ||
                            roleHandler(
                              role,
                              "AONE_MASTERS_PRODUCT.write"
                            )) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() =>
                                  navigate(`/masters/products/${e?.code}`)
                                }
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_PRODUCT.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.code,
                                      e?.code,
                                      "Products"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Origins" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.countryName}</td>
                          <td>
                            {e?.regionName ? e?.regionName : e?.regionCode}
                          </td>
                          <td>{e?.cityName ? e?.cityName : e?.cityCode}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>
                            &nbsp;
                            {(roleHandler(role, "AONE_MASTERS.admin") ||
                              roleHandler(
                                role,
                                "AONE_MASTERS_PRODUCT.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.countryName,
                                    "Origins"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Destinations" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.countryName}</td>
                          <td>
                            {e?.regionName ? e?.regionName : e?.regionCode}
                          </td>
                          <td>{e?.cityName ? e?.cityName : e?.cityCode}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>
                            &nbsp;
                            {(roleHandler(role, "AONE_MASTERS.admin") ||
                              roleHandler(
                                role,
                                "AONE_MASTERS_PRODUCT.admin"
                              )) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(
                                    e?.id,
                                    e?.countryName,
                                    "Destinations"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : tbl === "transittime" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.productCode}</td>
                          {/* <td>{e?.productCode}</td> */}
                          <td>{e?.originCountryName}</td>
                          <td>{e?.originRegionName}</td>
                          <td>{e?.originCityName}</td>
                          <td>{e?.destinationCountryName}</td>
                          <td>{e?.destinationRegionName}</td>
                          <td>{e?.destinationCityName}</td>
                          <td>{e?.days}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.createdBy}</td>
                          <td>
                            {e?.updateAt
                              ? new Date(e?.updateAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>{e?.updateBy}</td>
                          {(roleHandler(role, "AONE_MASTERS.write") ||
                            roleHandler(role, "AONE_MASTERS.admin") ||
                            roleHandler(role, "AONE_MASTERS_PRODUCT.admin") ||
                            roleHandler(
                              role,
                              "AONE_MASTERS_PRODUCT.write"
                            )) && (
                            <td className="fixedCol col_actions" scope="row">
                              <button
                                className="tableBtn"
                                title="Edit"
                                onClick={() => (
                                  setModalHandler(!modalHandler),
                                  setModalData(e)
                                )}
                              >
                                <span>
                                  <img src={editicon} className="turnBlack" />
                                </span>
                              </button>
                              &nbsp;
                              {(roleHandler(role, "AONE_MASTERS.admin") ||
                                roleHandler(
                                  role,
                                  "AONE_MASTERS_PRODUCT.admin"
                                )) && (
                                <button
                                  className="tableBtn"
                                  title="Delete"
                                  onClick={() =>
                                    deleteParamsSetter(
                                      e?.id,
                                      e?.originCountryName,
                                      "Transit Time"
                                    )
                                  }
                                >
                                  <span>
                                    <img
                                      src={DeleteIcon}
                                      className="h18 turnBlack"
                                    />
                                  </span>
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Customers" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.mainAccount}</td>
                          <td>{e?.companyRegistrationNumber}</td>
                          <td>{e?.companyName}</td>
                          <td>{e?.companyVatNumber}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() =>
                                navigate(
                                  `/customermanagement/${e?.mainAccount}`
                                )
                              }
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Invoices" ? (
                      <>
                        <tr className="cursorPointer">
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.waybillNo}</td>
                          <td>{e?.customerAccountNo}</td>
                          <td>{e?.oracleInvoiceNo}</td>
                          <td>{e?.bussinessLine}</td>
                          <td>{e?.status}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() =>
                                navigate(
                                  `/invoice/${e?.bussinessLine}/${e?.waybillNo}`
                                )
                              }
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Bags" ? (
                      <>
                        <tr
                          className={
                            seleczone?.includes(e.id)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td>
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxesbag[i]}
                                onChange={() => handleIndividualCheckboxBag(i)}
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                         <td>{newPage * size + (i + 1)}</td>
                          <td>{e?.bagNumber}</td>
                          <td>{e?.zoneGroup?.name}</td>
                          <td>{e?.sourceHubName}</td>
                          <td>{e?.destinationHubName}</td>
                          <td>{e?.zoneLevel ? "true" : null}</td>
                          <td>{e?.destinationZoneCode}</td>
                          <td>{e?.zoneGroupLevel ? "true" : null}</td>
                          <td>{e?.destinationZoneGroupCode}</td>
                          <td>{e?.chute}</td>
                          <td>{e?.totalWeight}</td>
                          <td>{e?.totalCount}</td>
                          <td>
                            {e?.valid === true ? (
                              <p className="status_active">Valid</p>
                            ) : e?.valid === false ? (
                              <p className="status_inactive">Invalid</p>
                            ) : null}
                          </td>
                          <td>{e?.configSummary?.name}</td>
                          <td>{e?.tripId}</td>
                          <td>
                            {e?.createdAt
                              ? new Date(e?.createdAt).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td className="fixedCol col_bagactions" scope="row">
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => {
                                setBagName(e?.bagNumber);
                                setSelectAll(false);
                                setselectZone([]);
                                setSelectWaybill([]);
                                navigate(`/middlemile/bags/${e.id}`);
                              }}
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_ADMIN") ||
                              role?.includes("AONE_MM_BAGS_BAG.admin") ||
                              role?.includes("AONE_MM_BAGS.admin")) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(e.id, e.bagNumber, "Bags")
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr
                          className={
                            seleczone?.includes(e?.waybillNumber)
                              ? "selectRow cursorPointer"
                              : "cursorPointer"
                          }
                        >
                          <td>
                            <div className="col-md-12 smallCheckbox">
                              <input
                                type="checkbox"
                                id={`tableCheck${i}`}
                                checked={individualCheckboxesbag[i]}
                                onChange={() =>
                                  handleIndividualConsignment(i, e?.bagNumber)
                                }
                              />
                              <label htmlFor={`tableCheck${i}`} />
                            </div>
                          </td>
                         <td>{newPage * size + (i + 1)}</td>
                          <td className="fixedCol col_Firstname" scope="row">
                            {e?.waybillNumber}
                          </td>
                          <td>{e?.weight}</td>
                          <td>{e?.length}</td>
                          <td>{e?.width}</td>
                          <td>{e?.height}</td>
                          <td>
                            {e?.receiveTime
                              ? new Date(e?.receiveTime).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )
                              : null}
                          </td>
                          <td>
                            {e?.dropTime
                              ? new Date(e?.dropTime).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })
                              : null}
                          </td>
                          <td>
                            {e?.valid ? (
                              <p className="status_active">Valid</p>
                            ) : (
                              <p className="status_inactive">Invalid</p>
                            )}
                          </td>
                          <td>{e?.errorMsg}</td>
                          <td className="fixedCol col_actions" scope="row">
                            <Link
                              to={`${shipseyUrl}ops/details/${e?.waybillNumber}`}
                              target="_blank"
                            >
                              <button className="tableBtn" title="View">
                                <span>
                                  <img src={EyeIcon} className="turnBlack" />
                                </span>
                              </button>
                            </Link>
                            &nbsp;
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setModalHandler(!modalHandler),
                                setSaveHandlerAction(true),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            {(role?.includes("AONE_ADMIN") ||
                              role?.includes("AONE_MM_BAGS_BAG.admin") ||
                              role?.includes("AONE_MM_BAGS.admin")) && (
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsIdSetter(
                                    e.id,
                                    e.bagId,
                                    e.waybillNumber,
                                    "Waybill"
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                );
              })
            ) : tbl === "DetailedBags" ? (
              <></>
            ) : (
              <>
                <tr>
                  <td>No Data</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      {/* <TableFooter range={range} slice={slice} setPage={setPage} page={page} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage}/> */}
      {/* Edit Box Handler */}
      {modalHandler && title && (
        <OpenModal
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          title={title}
          data={modalData}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />
      )}
      {/* Delete Box Handler */}
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
          deleteParamsId={deleteParamsId}
          setDeleteParamsId={setDeleteParamsId}
        />
      )}
      <div className="displayFlex alignItemCenter justifyContent_spacebetween">
        {/* <div></div> */}
        <div className="displayFlex gap_16 alignItemCenter">
          <label className="fontSize14 blackText">Show </label>
          <div>
            <div className="positionRelative">
              <input
                type="text"
                readOnly
                placeholder={size}
                className="seletDropdownIcOnPage"
                onClick={() => setCustomdropdownone(!customdropdownOne)}
              />
              {customdropdownOne && (
                <div className="dropdownResult appearTop">
                  {mul?.map((d, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          className="showPerItem"
                          onClick={() => {
                            setCustomdropdownone(!customdropdownOne);
                            setSize(d);
                            setNewPage(0);
                          }}
                        >
                          {d}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <>
  <div className="customPagination">
    <Pagination
      activePage={newPage + 1}
      itemsCountPerPage={size}
      totalItemsCount={totalCount}
      pageRangeDisplayed={5}  // Adjust this number to show more or fewer pages
      onChange={paginationHandler}
    />
  </div>
  <div>
    <p>
      {totalCount === tableData?.length
        ? totalCount
        : newPage * size + 1}
      {(newPage + 1) * size < totalCount &&
        (newPage + 1) * size !== newPage * size + 1 && (
          <span>-{(newPage + 1) * size}</span>
        )}
      /{totalCount}
    </p>
  </div>
</>

        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Consignment Fetched
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="row mb_24">
                  <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Waybill Number:</label>
                  </div>
                  <div className="col-md-8 mt_8">{fetchData?.waybillNo}</div>
                </div>
                <div className="row mb_24">
                  <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Oracle Invoice Id:</label>
                  </div>
                  <div className="col-md-8 mt_8">
                    {fetchData?.oracleInvoiceId}
                  </div>
                </div>
                <div className="row mb_24">
                  <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Oracle Invoice Date:</label>
                  </div>
                  <div className="col-md-8 mt_8">
                    {fetchData?.oracleInvoiceDate
                      ? new Date(fetchData?.oracleInvoiceDate)?.toLocaleString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }
                        )
                      : null}
                  </div>
                </div>
                <div className="row mb_24">
                  <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Transaction Id: </label>
                  </div>
                  <div className="col-md-8 mt_8">
                    {fetchData?.transactionId}
                  </div>
                </div>
                <div className="row mb_24">
                  <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Transaction Date: </label>
                  </div>
                  <div className="col-md-8 mt_8">
                    {fetchData?.transactionDate
                      ? new Date(fetchData?.transactionDate)?.toLocaleString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }
                        )
                      : null}
                  </div>
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
              <div>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
              {/* <div>
              <button
                type="button"
                className="blue_btn"
              >
                Save
              </button>
            </div> */}
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
        <Modal
          show={showorder}
          onHide={handleCloseorder}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Assign to courier
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Waybill Number
                  </label>
                  <input
                    type="text"
                    className="mainInput"
                    value={orderWaybill}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Carrier
                  </label>
                  <SearchInput
                    id="thirdPartyId"
                    label="thirdPartyId"
                    name="thirdPartyId"
                    isClearable={true}
                    options={(thirdpartyCouriers || [])?.filter(
                      (carrier) => carrier?.status === "ACTIVE"
                    )}
                    getOptionLabel={(carrier) => carrier.code}
                    getOptionValue={(carrier) => carrier.id}
                    handleChange={async (selectedOption) => {
                      assignThirdpartyOrder.setFieldValue(
                        "thirdPartyId",
                        selectedOption ? selectedOption.id : ""
                      );
                    }}
                    isSearchable={true}
                    placeholder="Carrier"
                  />
                  {assignThirdpartyOrder.touched.thirdPartyId &&
                  assignThirdpartyOrder.errors.thirdPartyId ? (
                    <p className="requiredText">
                      {assignThirdpartyOrder.errors.thirdPartyId}
                    </p>
                  ) : null}
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={assignThirdpartyOrder.handleSubmit}
                >
                  Assign
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseorder()}
                >
                  Cancel
                </button>
              </div>
              <div></div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
      </div>
    </>
  );
};

export default PaginationTable;
