import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import PaginationTable from "../../../components/Tables/paginationTable";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fileDownload from "js-file-download";
import syncicon from "../../../../src/assets/img/sync.svg";
import downloadicon from "../../../assets/img/download_icon.svg";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
const CourierModal = ({
  onSuccess,
  onError,
  setShowModal,
  courierCode,
}) => {
  const [trackingId, setTrackingId] = useState("");
  const [status, setStatus] = useState("");
  const [taskType, setTaskType] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const getCourierTasks = usecourierStore((state) => state?.getCourierTasks);
  const exportTasks = usecourierStore((state) => state?.exportTasks);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const courierStatus = usecourierStore((state) => state?.courierStatus);
  const couriertaskType = usecourierStore((state) => state?.couriertaskType);
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const [rows, setRows] = useState([]);
  const [courierTask, setCourierTask] = useState([]);
  const [sortField, setSortField] = useState("-updatedAt");
  const [newRow, setNewRow] = useState({
    fromHub: { name: "", code: "" },
    toHub: { name: "", code: "" },
    distance: "",
    transitTime: "",
  });
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };
  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(-1);
  const columns = [
    { id: "trackingId", numeric: false, label: "Tracking Id" },
    { id: "pickupRequestId", numeric: false, label: "Pickup request" },
    { id: "status", numeric: false, label: "Status" },
    { id: "originZone", numeric: false, label: "Origin Zone" },
    { id: "originHub", numeric: false, label: "Origin Hub" },
    { id: "bagNumber", numeric: false, label: "Bag number" },
    { id: "destinationZone", numeric: false, label: "Destination Zone" },
    { id: "destinationHub", numeric: false, label: "Destination hub" },
    { id: "taskType", numeric: false, label: "Task type" },
  ];
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&employeeCode=${courierCode}`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (taskType) {
      params += `&taskType=${taskType}`;
    }
    const data = await getCourierTasks(params);
    setTotalCount(data?.data?.data?.totalElements);
    setCourierTask(data?.data?.data?.content);
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&employeeCode=${courierCode}`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (taskType) {
      params += `&taskType=${taskType}`;
    }
    try {
      const res = await exportTasks(params);
      if (res.status == 200) {
        fileDownload(res.data, `Tasks.xlsx`);
      }else if(res?.response?.data?.errorCode == 500){
        toast?.error(res?.response?.data?.msg)
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    size,
    sortField,
    courierCode,
    trackingId,
    status,
    taskType
  ]);
  const getConsignmentStatus = usecourierStore((state) => state?.getConsignmentStatus);
  const getConsignmentTaskType = usecourierStore((state) => state?.getConsignmentTaskType);
  useEffect(() => {
    if (courierStatus?.length < 1) {
      getConsignmentStatus();
    }
    if (couriertaskType?.length < 1) {
      getConsignmentTaskType();
    }
  }, []);
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">Tasks</h4>
      </Modal.Header>
      <div className="modelScrollRoute">
        <Modal.Body>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Tracking Id:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10">
                      <input
                        type="text"
                        value={trackingId}
                        className={
                          trackingId?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          trackingId?.length > 0 ? { border: "2px solid #ec171c" } : {}
                        }
                        onChange={(e) => setTrackingId(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Status:
                    </label>
                    <SearchInput
                      id="status"
                      label="status"
                      name="status"
                      options={courierStatus || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        setStatus(selectedOption ? selectedOption.key : "");
                      }}
                      onMenuOpen={handleMenudescountry}
                      onMenuClose={handleMenuClosedescountry}
                      menuIsOpen={isReactdesCountry}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Status"
                      customStyles={{ control: { width: "150px" } }}
                      className="mainInput"
                    />
                  </Col>
                  <Col>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Task Type:
                    </label>
                    <SearchInput
                      id="taskType"
                      label="taskType"
                      name="taskType"
                      options={couriertaskType || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        setTaskType(selectedOption ? selectedOption.key : "");
                      }}
                      onMenuOpen={handleMenuOpenorgcity}
                      onMenuClose={handleMenuCloseorgcity}
                      menuIsOpen={isReactoriginselectCity}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Task Type"
                      customStyles={{ control: { width: "150px" } }}
                      className="mainInput"
                    />
                  </Col>
                </Row>
              </div>
              <div className="margin_top40">
                <div className="displayFlex gap_12">
                  <>
                  </>
                  <button
                    className="filterBtn"
                    title={"Export Tasks"}
                    onClick={() => exportToExcel()}
                  >
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
                  <button
                    className="filterBtn plus_icon"
                    title="Sync"
                    onClick={() => syncronizeRoles()}
                  >
                    <span>
                      <img src={syncicon} className="h18" />
                    </span>
                  </button>
                </div>
              </div>
              </div>
         <PaginationTable
          tbl="CourierTasks"
          tableData={courierTask}
          size={size}
          setSize={setSize}
          totalCount={totalCount}
          setNewPage={setNewPage}
          newPage={newPage}
          setSortField={setSortField}
          sortField={sortField}
        />
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Dismiss
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default CourierModal;
