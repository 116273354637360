import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get weight
export const getWeights = async (params) => {
    try  {
        return await AoneService.get(`ops/api/v1/weighing${params}`)
    } catch (error) {
        return error;
    }
}
//post weight
export const postWeight = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/weighing`,data);
    } catch (error) {
      return error;
    }
  };

//get weight by id
export const getWeightbyId = async (params) => {
    try {
      return await AoneService.post(`ops/api/v1/weighing/${params}`);
    } catch (error) {
      return error;
    }
  };

//weight template
export const weightTemplate = async () => {
    try {
      return await AoneService.get(
        `ops/api/v1/weighing/template/download`,
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };

//weight import
export const importWeight = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/weighing/upload`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };

//lookup-tool
export const getWeightTool = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/WeighingTool`);
    } catch (error) {
      return error;
    }
  };
// weightunit-lookup
export const getWeightUnit = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/WeightUnit`);
    } catch (error) {
      return error;
    }
  };
  //Dimension Unit
  export const getDimensionUnit = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/DimensionUnit`);
    } catch (error) {
      return error;
    }
  };