import React, { useRef, useEffect, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";

const MarkerComponent = ({ routesData, selectedEmployeeCode, setSelectedEmployeeCode }) => {
  const map = useMap();
  const markerRefs = useRef(new Map());

  useEffect(() => {
    const marker = markerRefs.current.get(selectedEmployeeCode);
    if (marker) {
      map.setView(marker.getLatLng(), map.getZoom());
      const popup = marker.getPopup();
      if (popup && !popup.isOpen()) {
        popup.openOn(map);
      }
    } else {
      map.closePopup();
    }
  }, [selectedEmployeeCode, map]);

  const handleMarkerClick = (employeeCode) => {
    setSelectedEmployeeCode(employeeCode);
  };
  return (
    <>
      {routesData?.map((route, index) => {
        if (!route.latitude) return null;
        return (
          <Marker
            key={index}
            position={[route.latitude, route.longitude]}
            ref={(el) => {
              if (el) {
                markerRefs.current.set(route.employeeCode, el);
              }
            }}
            onClick={() => console.log("Marker clicked")} 
          >
            {/* Open popup only if this marker corresponds to the selected employee */}
            {(selectedEmployeeCode === route.employeeCode || (typeof route.latitude === 'number' && typeof route.longitude === 'number')) && (
              <Popup>
                <div>
                  <p>
                    <strong>Employee Name:</strong> {route.employeeName}
                  </p>
                  <p>
                    <strong>Employee Code:</strong> {route.employeeCode}
                  </p>
                  <p>
                    <strong>Vehicle Number:</strong> {route.vehicleNumber}
                  </p>
                </div>
              </Popup>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default MarkerComponent;
