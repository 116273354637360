import React, { useEffect,useState } from "react";
import MainTable from "../components/Tables/mtable";
import CusModal from "../components/modal/modal";
import { useAoneStore } from "../store/AoneStore";
import { toast } from 'react-toastify';

const SlaPage = ({}) => {
  const postSla = useAoneStore((state) => state?.postSla);
  const getSla = useAoneStore((state) => state?.getSla)
  const editSla = useAoneStore((state) => state?.editSla);
  const deleteSla = useAoneStore((state) => state?.deleteSla);
  const [slaData,setSlaData] = useState({
    productCode: "",
    customerAccount: "",
    originCity: "",
    destinationCity: "",
    slaMinutes: 0,
    serviceType:"",
    country:""
  })
    const [tblData,setTblData] = useState([]);
    const  [tblDataDup,setTblDataDup] = useState([])

    const captureData = (key,value)=>{
      console.log(key,value)
      setSlaData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }

    const saveData = async(e)=>{
      e.preventDefault();
      try{
      // setTblData([...tblData,slaData]);
      const data = await postSla(slaData)
      if(data?.data?.code == 200) {
        toast.success('posted sucessfully')
        getData()
      }
      }catch(err){
        console.log("save sla errr---->",err)
      }
    }

    // key,value,id
  const updateName = (key,value,id) => {
    setTblDataDup(prevState => {
        return prevState.map(item => {
          if (item.id === id) {
            return { ...item, [key]: value };
          }
          return item;
        });
      });
    };
  
    const saveChange = async (e,id)=>{
      e.preventDefault();
      try{
      const data = tblDataDup.filter((data)=>{
        return data.id == id
      })
      const resdata = await editSla(data[0])
      if(resdata?.data?.code == 200){
        toast.success('Updated sucessfully')
        getData()
      }
    }catch(err){
      console.log("edit sla errr---->",err)

    }
    }
    const cancleChange = ()=>{
      setTblDataDup(tblData)
    }

    const getData = async () => {
      try{
      const data = await getSla()
      if(data?.data?.code == 200){
        setTblData(data?.data?.data)
        setTblDataDup(data?.data?.data)
      }
      }catch(err){
        console.log("get sla errr---->",err)
      }
    }

    const handleDelete = async (id)=>{
      const res = await deleteSla(id)
      if(res.data.code === 200){
        console.log("res---->",res)
        toast.success('Deleted sucessfully')
        getData()

      }
    }

    useEffect(()=>{
      getData()
    },[])
  return (
    <>
      <div className="">
      <div className="displayFlex alignItemCenter justifyContent_end gap_16 mb_24">
        <CusModal title="Add New SLA" btnName="Add" setStateData={captureData} initialData={slaData} submit={saveData}/>
      </div>
      <MainTable tableData={tblData} tblDataDup={tblDataDup} tbl="sla" updateName={updateName} submit={saveChange} cancleChange={cancleChange} handleDelete={handleDelete}/>
      </div>
     
    </>
  );
};
 
export default SlaPage;
