import Select from 'react-select';
import React from 'react';
import { useAoneStore } from '../../store/AoneStore';
const SearchInput = ({
  key,
  id,
  options,
  name,
  label,
  value,
  defaultValue,
  isDisabled,
  isLoading,
  isMulti,
  isClearable,
  isRtl,
  isSearchable,
  getOptionLabel,
  getOptionValue,
  customStyles,
  handleChange,
  placeholder,
  onMenuOpen,
  onMenuClose,
  menuIsOpen,
  tabIndex
}) => {
   const combinedStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '35px', 
      fontSize: '12px',
      ...(customStyles && customStyles.control)
    }),
  };
  const isdropModalOpen = useAoneStore((state) => state?.isdropModalOpen);
  return (
    <div>
      <Select
        styles={{
          ...combinedStyles,
        }}
        placeholder={placeholder}
        label={label}
        key={key}
        id={id}
        className='basic-single'
        classNamePrefix='select'
        drop
        defaultValue={defaultValue && { [label]: defaultValue }}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        value={value}
        options={options}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={menuIsOpen}
        menuShouldScrollIntoView={false}
        menuPlacement='bottom'
        menuPosition='fixed'
        tabIndex={tabIndex} 
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#fff2f2', // Option hover color
            primary: '#ec171c',   // Control primary color
            primary50: '#FCAEAE', // Selected option color
            neutral20: '#7a869a',
          }
        })}
      />
    </div>
  );
};

export default SearchInput;