import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get Line hauls
export const getLinehaulReasons = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/reasons/linehaul${params}`);
    } catch (error) {
      return error;
    }
  };
//get linehauls by id
export const getLinehaulReasonsbyId = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/reasons/linehaul/${params}`);
  } catch (error) {
    return error;
  }
};
//post linehauls
export const postLinehaulReasons = async (data) => {
    try {
      return await AoneService.post("ops/api/v1/reasons/linehaul", data);
    } catch (error) {
      return error;
    }
  };
//edit linehauls
export const editLinehaulReasons = async (params,data) => {
    try {
      return await AoneService.put(`ops/api/v1/reasons/linehaul/${params}`, data);
    } catch (error) {
      return error;
    }
  };
//export pickup request
export const exportLinehaulReasons = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/reasons/linehaul/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//delete linehauls
export const deleteLineHaulsbyId = async (params) => {
    try {
      return await AoneService.delete(`ops/api/v1/reasons/linehaul/${params}`);
    } catch (error) {
      return error;
    }
  };
  