import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import PaginationTable from "../../components/Tables/paginationTable";
import templateIcon from "../../assets/img/template_icon.svg";
import downloadicon from "../../assets/img/download_icon.svg";
import importIcon from "../../assets/img/importIcon.svg";
import { toast } from "react-toastify";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import * as XLSX from "xlsx";
import syncicon from "../../../src/assets/img/sync.svg";
import { useNavigate } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import { useRatesStore } from "../../store/Rates/RateStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import fileDownload from "js-file-download";
import Modal from "react-bootstrap/Modal";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
import OpenModal from "../../components/modal/openModal";
import CusModal from "../../components/modal/CusModal";
import VatModal from "../RateTabs/VatModal";
import GpaModal from "../RateTabs/GpaModal";
const Rates = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("Freight");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [editPage, setEditPage] = useState(false);
  const prevent = useRef(false);
  const [state] = useContext(UserContext);
  const [title, setTitle] = useState("");
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState("-updatedAt");
  const [freightData, setFreightData] = useState([]);
  const [odaData, setOdaData] = useState([]);
  const [rtoData, setRtoData] = useState([]);
  const [codData, setCodData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [attemptsData, setAtteptsData] = useState([]);
  const [vatData, setVatData] = useState([]);
  const [gpaData, setGpaData] = useState([]);
  const [key, setKey] = useState(0);
  const [page, setPage] = useState(-1);
  const [cusPage, setCusPage] = useState(-1);
  const [originCityPage, setOriginCitypage] = useState(-1);
  const [desCityPage, setDescityPage] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  const setProductsPage = useAoneStore((state) => state.setProductsPage);
  const customersPage = useAoneStore((state) => state.customersPage);
  const setCustomersPage = useAoneStore((state) => state.setCustomersPage);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [product, setProduct] = useState(null);
  const [structure, setStructure] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [rateState, setRateState] = useState("");
  const [tradeLane, setTradeLane] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [contentType, setContentType] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectRates, setSelectRates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [originCountry, setOriginCountry] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [destRegion, setDestRegion] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [calculationType, setCalculationTypes] = useState("");
  const filterRegions = useAoneStore((state) => state.filterRegions);
  const getTiers = useAoneStore((state) => state?.getTiers);
  const getCities = useAoneStore((state) => state?.getCities);
  const serviceTypes = useAoneStore((state) => state?.serviceTypes);
  const getCalType = useAoneStore((state) => state?.getCalType);
  const getProductsEvent = useAoneStore((state) => state?.getProductsEvent);
  const getCollectionMethod = useAoneStore(
    (state) => state?.getCollectionMethod
  );
  const filterProducts = useAoneStore((state) => state?.filterProducts);
  const productsData = useAoneStore((state) => state?.productsData);
  const getContentType = useAoneStore((state) => state?.getContentType);
  const getStateRates = useAoneStore((state) => state?.getStateRates);
  const getDeliveryStatus = useAoneStore((state) => state?.getDeliveryStatus);
  const getPaymentType = useAoneStore((state) => state?.getPaymentType);
  const getState = useAoneStore((state) => state?.getState);
  const getStructure = useAoneStore((state) => state?.getStructure);
  const bulkFreightDelete = useAoneStore((state) => state.bulkFreightDelete);
  const bulkRtoDelete = useAoneStore((state) => state.bulkRtoDelete);
  const isReactdesBulk = useAoneStore((state) => state?.isReactdesBulk);
  const bulkCodDelete = useAoneStore((state) => state.bulkCodDelete);
  const bulkOdaDelete = useAoneStore((state) => state.bulkOdaDelete);
  const bulkFuelDelete = useAoneStore((state) => state.bulkFuelDelete);
  const bulkInsuranceDelete = useAoneStore(
    (state) => state.bulkInsuranceDelete
  );
  const bulkAttemptsDelete = useAoneStore((state) => state.bulkAttemptsDelete);
  const getGpaTemplate = useRatesStore((state) => state.getGpaTemplate);
  const importGpa = useRatesStore((state) => state.importGpa);
  const bulkVatDelete = useRatesStore((state) => state.bulkVatDelete);
  const bulkGpaDelete = useRatesStore((state) => state.bulkGpaDelete);
  const getVatbyId = useRatesStore((state) => state.getVatbyId);
  const getGpabyId = useRatesStore((state) => state.getGpabyId);
  const getGpa = useRatesStore((state) => state.getGpa);
  const exportrateGpa = useRatesStore((state) => state.exportrateGpa);
  const calculationData = useAoneStore((state) => state?.calculationData);
  const deliveryStatusData = useAoneStore((state) => state?.deliveryStatusData);
  const structureData = useAoneStore((state) => state?.structureData);
  const paymentData = useAoneStore((state) => state?.paymentData);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const contentData = useAoneStore((state) => state?.contentData);
  const stateRateData = useAoneStore((state) => state?.stateRateData);
  const productEventData = useAoneStore((state) => state?.productEventData);
  const collectionData = useAoneStore((state) => state?.collectionData);
  const stateData = useAoneStore((state) => state?.stateData);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const setReactDesBulk = useAoneStore((state) => state?.setReactDesBulk);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const productWeightData = useAoneStore((state) => state?.productWeightData);
  const getProductsWeight = useAoneStore((state) => state?.getProductsWeight);
  const editFreightState = useAoneStore((state) => state?.editFreightState);
  const editRtoState = useAoneStore((state) => state?.editRtoState);
  const editCodState = useAoneStore((state) => state?.editCodState);
  const editOdaState = useAoneStore((state) => state?.editOdaState);
  const editFuelState = useAoneStore((state) => state?.editFuelState);
  const editInsuranceState = useAoneStore((state) => state?.editInsuranceState);
  const editAttemptsState = useAoneStore((state) => state?.editAttemptsState);
  const editVatState = useRatesStore((state) => state?.editVatState);
  const editGpaState = useRatesStore((state) => state?.editGpaState);  
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const [editHandler, setEditHandler] = useState(false);
  const getFreight = useAoneStore((state) => state?.getFreight);
  const getRto = useAoneStore((state) => state?.getRto);
  const getCod = useAoneStore((state) => state?.getCod);
  const getOda = useAoneStore((state) => state?.getOda);
  const getFuel = useAoneStore((state) => state?.getFuel);
  const getInsurance = useAoneStore((state) => state?.getInsurance);
  const getAttempts = useAoneStore((state) => state?.getAttempts);
  const getVat = useRatesStore((state) => state?.getVat);
  const exportrateFreight = useAoneStore((state) => state?.exportrateFreight);
  const exportrateVat = useRatesStore((state) => state?.exportrateVat);
  const exportrateRto = useAoneStore((state) => state?.exportrateRto);
  const exportrateCod = useAoneStore((state) => state?.exportrateCod);
  const exportrateOda = useAoneStore((state) => state?.exportrateOda);
  const exportrateFuel = useAoneStore((state) => state?.exportrateFuel);
  const exportrateInsurance = useAoneStore(
    (state) => state?.exportrateInsurance
  );
  const exportrateAttempts = useAoneStore((state) => state?.exportrateAttempts);
  const importFreight = useAoneStore((state) => state?.importFreight);
  const importRto = useAoneStore((state) => state?.importRto);
  const importCod = useAoneStore((state) => state?.importCod);
  const importOda = useAoneStore((state) => state?.importOda);
  const importFuel = useAoneStore((state) => state?.importFuel);
  const importVat = useRatesStore((state) => state?.importVat);
  const importInsurance = useAoneStore((state) => state?.importInsurance);
  const importAttempts = useAoneStore((state) => state?.importAttempts);
  const getFreightsTemplate = useAoneStore(
    (state) => state?.getFreightsTemplate
  );
  const getCodrateTemplate = useAoneStore((state) => state?.getCodrateTemplate);
  const getVatTemplate = useRatesStore((state) => state?.getVatTemplate);
  const getRtosTemplate = useAoneStore((state) => state?.getRtosTemplate);
  const getOdarateTemplate = useAoneStore((state) => state?.getOdarateTemplate);
  const getFuelrateTemplate = useAoneStore(
    (state) => state?.getFuelrateTemplate
  );
  const getInsurancerateTemplate = useAoneStore(
    (state) => state?.getInsurancerateTemplate
  );
  const getAttemptsrateTemplate = useAoneStore(
    (state) => state?.getAttemptsrateTemplate
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const setReactCharges = useAoneStore((state) => state?.setReactCharges);
  const setReactCurrency = useAoneStore((state) => state?.setReactCurrency);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const setisReactRatestate = useAoneStore(
    (state) => state?.setisReactRatestate
  );
  const isReactRatestate = useAoneStore((state) => state?.isReactRatestate);
  const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const customerData = useAoneStore((state) => state?.customerData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const handleCloseModal = () => {
    setError(null);
  };
  const dropdownRef = useRef(null); // Reference for the dropdown
  // Toggle dropdown
  const toggleDropdown = () => setIsDropdownOpen(prev => !prev);

  // Handle clicks outside the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setReactDesBulk(false);
    }
  };
  useEffect(() => {
    // Add event listener for clicks outside of dropdown
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const clearStates = () => {
    setSortField("-updatedAt");
    setKey((prevKey) => prevKey + 1);
    setReactDesBulk(false);
    setNewPage(0);
    setPage(-1);
    setRateState("");
    setIsDropdownOpen(false);
    setSelectRates([]);
    setSelectAll(false);
    setProductsPage(-1);
    setCustomersPage(-1);
    setOriginCitypage(-1);
    setDescityPage(-1);
    setServiceType("");
    setCustomer(null);
    setDeliveryStatus("");
    setProduct(null);
    setOriginCountry("");
    setOriginCity("");
    setDestinationCity("");
    setStructure("");
    setDestinationCountry("");
    setCalculationTypes("");
    setDestRegion("");
    setSelectedOption(null);
    setSelectedOptionCustomer(null);
    setSelectedOptionCity(null);
    setSelectedOptionDesCity(null);
  };
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };

  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };

  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };
  const handleMenuClosedescity = () => {
    setReactDescity(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const handleMenuCharges = () => {
    setReactCharges(true);
  };

  const handleMenuCloseCharges = () => {
    setReactCharges(false);
  };
  const handleMenuCurrency = () => {
    setReactCurrency(true);
  };
  const handleMenuCloseCurrency = () => {
    setReactCurrency(false);
  };
  const handleMenuState = () => {
    setisReactRatestate(true);
  };
  const handleMenuCloseState = () => {
    setisReactRatestate(false);
  };
  const [showScan, setShowscan] = useState(false);
  const handlescanShow = () => {
    if (selectRates?.length > 0) setShowscan(true);
  };
  const handlescanClose = () => {
    setShowscan(false);
  };
  const handlescanCancel = () => {
    setShowscan(false);
  };
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const handleAddModalClick = () => {
    if (status === "Vat") {
      setTitle("Create new VAT Rate");
      setShowModal(true);
      setRatesbyCode([]);
    } else if (status === "Gpa") {
      setTitle("Create new GPA Rate");
      setShowModal(true);
      setRatesbyCode([]);
    } else {
      setAddModalHandler(!addModalHandler);
    }
  };
  // Toggle state dropdown display
  const toggleStateDropdown = () => {
    setIsStateDropdownOpen(!isStateDropdownOpen);
  };
  const handleStateChange = async (state) => {
    let importFunction;
    switch (status) {
      case "Freight":
        importFunction = editFreightState;
        break;
      case "RTO":
        importFunction = editRtoState;
        break;
      case "COD":
        importFunction = editCodState;
        break;
      case "ODA":
        importFunction = editOdaState;
        break;
      case "Fuel":
        importFunction = editFuelState;
        break;
      case "Insurance":
        importFunction = editInsuranceState;
        break;
      case "Attempts":
        importFunction = editAttemptsState;
        break;
        case "Vat":
          importFunction = editVatState;
          break;
          case "Gpa":
            importFunction = editGpaState;
            break;
      default:
        importFunction = editFreightState;
    }
    await importFunction(state, selectRates).then((response) => {
      if (response?.data?.status == "SUCCESS") {
        toast.success(`State updated for selected rates`);
        getData();
        setShowscan(false);
        setIsDropdownOpen(false);
        setSelectRates([]);
        setSelectAll(false);
        setIsStateDropdownOpen(false);
        setReactDesBulk(false);
      } else if (response?.data?.status == "ERROR") {
        const errorMessage = response?.data?.msg.split(",")[0];
        toast.error(errorMessage);
        setShowscan(false);
        setSelectRates([]);
        setIsDropdownOpen(false);
        setSelectAll(false);
        setIsStateDropdownOpen(false);
        setReactDesBulk(false);
      } else if (response?.response?.data?.status == 404) {
        toast.error("Error in changing the state");
        setShowscan(false);
        setSelectRates([]);
        setIsDropdownOpen(false);
        setSelectAll(false);
        setIsStateDropdownOpen(false);
        setReactDesBulk(false);
      } else {
        const errorMessage = response?.response?.data?.msg;
        toast.error(errorMessage);
        setShowscan(false);
        setIsDropdownOpen(false);
        setSelectRates([]);
        setSelectAll(false);
        setIsStateDropdownOpen(false);
        setReactDesBulk(false);
      }
    });
  };
  const handledeleteRates = async () => {
    let importFunction;
    switch (status) {
      case "Freight":
        importFunction = bulkFreightDelete;
        break;
      case "RTO":
        importFunction = bulkRtoDelete;
        break;
      case "COD":
        importFunction = bulkCodDelete;
        break;
      case "ODA":
        importFunction = bulkOdaDelete;
        break;
      case "Fuel":
        importFunction = bulkFuelDelete;
        break;
      case "Insurance":
        importFunction = bulkInsuranceDelete;
        break;
      case "Attempts":
        importFunction = bulkAttemptsDelete;
        break;
      case "Vat":
        importFunction = bulkVatDelete;
        break;
      case "Gpa":
        importFunction = bulkGpaDelete;
        break;
      default:
        importFunction = bulkAttemptsDelete;
    }
    await importFunction(selectRates).then((response) => {
      if (response?.data?.status == "SUCCESS") {
        toast.success(`Selected rates has been Deleted`);
        getData();
        setShowscan(false);
        setIsDropdownOpen(false);
        setSelectRates([]);
        setSelectAll(false);
      } else if (response?.data?.status == "ERROR") {
        const errorMessage = response?.data?.msg.split(",")[0];
        toast.error(errorMessage);
        setShowscan(false);
        setSelectRates([]);
        setIsDropdownOpen(false);
        setSelectAll(false);
      } else {
        const errorMessage = response?.response?.data?.msg;
        toast.error(errorMessage);
        setShowscan(false);
        setIsDropdownOpen(false);
        setSelectRates([]);
        setSelectAll(false);
      }
    });
  };
  const downloadTemplate = async () => {
    try {
      if (status === "Freight") {
        const res = await getFreightsTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Freight Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "RTO") {
        const res = await getRtosTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `RTO Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "COD") {
        const res = await getCodrateTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `COD Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "ODA") {
        const res = await getOdarateTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `ODA Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Fuel") {
        const res = await getFuelrateTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Fuel Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Insurance") {
        const res = await getInsurancerateTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Insurance Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Attempts") {
        const res = await getAttemptsrateTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Attempts Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Vat") {
        const res = await getVatTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `VAT Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else {
        const res = await getGpaTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `GPA Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);
          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            let importFunction;
            switch (status) {
              case "Freight":
                importFunction = importFreight;
                break;
              case "RTO":
                importFunction = importRto;
                break;
              case "COD":
                importFunction = importCod;
                break;
              case "ODA":
                importFunction = importOda;
                break;
              case "Fuel":
                importFunction = importFuel;
                break;
              case "Insurance":
                importFunction = importInsurance;
                break;
              case "Attempts":
                importFunction = importAttempts;
                break;
              case "Vat":
                importFunction = importVat;
                break;
              case "Gpa":
                importFunction = importGpa;
                break;
              default:
                importFunction = importGpa;
            }
            importFunction(formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR" && (response?.data?.data?.length == 0 || response?.data?.data?.length == undefined)) {
                  toast.error(response.data.msg);
                  fileInput.value = "";
                } else if (response?.response?.status === "ERROR") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response?.response?.status == "500") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch(() => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const handleEdit = (code) => {
    if (status == "Vat") {
      setTitle("Edit VAT Rate");
    } else if (status == "Gpa") {
      setTitle("Edit GPA Rate");
    }
    setShowModal(true);
    getRatesonEdit(code);
  };
  const [ratesbyCode, setRatesbyCode] = useState([]);
  const getRatesonEdit = async (code) => {
    if (status == "Vat") {
      const res = await getVatbyId(code);
      setRatesbyCode(res?.data?.data);
    } else if (status == "Gpa") {
      const res = await getGpabyId(code);
      setRatesbyCode(res?.data?.data);
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (product) {
      params += `&productCode=${product}`;
    }
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (originCountry) {
      params += `&originCountryCode=${originCountry}`;
    }
    if (destinationCountry) {
      params += `&destinationCountryCode=${destinationCountry}`;
    }
    if (rateState) {
      params += `&state=${rateState}`;
    }
    if (status === "Freight") {
      if (structure) {
        params += `&structure=${structure}`;
      }
      if (paymentType) {
        params += `&paymentType=${paymentType}`;
      }
      if (deliveryStatus) {
        params += `&deliveryStatus=${deliveryStatus}`;
      }
      if (contentType) {
        params += `&contentType=${contentType}`;
      }
      if (originCity) {
        params += `&originCityCode=${originCity}`;
      }
      if (destinationCity) {
        params += `&destinationCityCode=${destinationCity}`;
      }
      const data = await getFreight(params);
      setTotalCount(data.data.data.totalElements);
      setFreightData(data.data.data.content);
    } else if (status === "RTO") {
      if (structure) {
        params += `&structure=${structure}`;
      }
      if (deliveryStatus) {
        params += `&deliveryStatus=${deliveryStatus}`;
      }
      if (contentType) {
        params += `&contentType=${contentType}`;
      }
      if (originCity) {
        params += `&originCityCode=${originCity}`;
      }
      if (destinationCity) {
        params += `&destinationCityCode=${destinationCity}`;
      }
      const data = await getRto(params);
      setTotalCount(data.data.data.totalElements);
      setRtoData(data.data.data.content);
    } else if (status === "COD") {
      const data = await getCod(params);
      setTotalCount(data.data.data.totalElements);
      setCodData(data.data.data.content);
    } else if (status === "ODA") {
      if (contentType) {
        params += `&contentType=${contentType}`;
      }
      const data = await getOda(params);
      setTotalCount(data.data.data.totalElements);
      setOdaData(data.data.data.content);
    } else if (status === "Fuel") {
      const data = await getFuel(params);
      setTotalCount(data.data.data.totalElements);
      setFuelData(data.data.data.content);
    } else if (status === "Insurance") {
      const data = await getInsurance(params);
      setTotalCount(data.data.data.totalElements);
      setInsuranceData(data.data.data.content);
    } else if (status === "Attempts") {
      const data = await getAttempts(params);
      setTotalCount(data.data.data.totalElements);
      setAtteptsData(data.data.data.content);
    } else if (status === "Vat") {
      const data = await getVat(params);
      setTotalCount(data.data.data.totalElements);
      setVatData(data.data.data.content);
    } else {
      const data = await getGpa(params);
      setTotalCount(data.data.data.totalElements);
      setGpaData(data.data.data.content);
    }
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (status === "Freight") {
      try {
        const res = await exportrateFreight(params);
        if (res.status == 200) {
          fileDownload(res.data, `Freight Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "RTO") {
      try {
        const res = await exportrateRto(params);
        if (res.status == 200) {
          fileDownload(res.data, `RTO Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "COD") {
      try {
        const res = await exportrateCod(params);
        if (res.status == 200) {
          fileDownload(res.data, `COD Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "ODA") {
      try {
        const res = await exportrateOda(params);
        if (res.status == 200) {
          fileDownload(res.data, `ODA Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Fuel") {
      try {
        const res = await exportrateFuel(params);
        if (res.status == 200) {
          fileDownload(res.data, `Fuel Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Insurance") {
      try {
        const res = await exportrateInsurance(params);
        if (res.status == 200) {
          fileDownload(res.data, `Insurance Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Attempts") {
      try {
        const res = await exportrateAttempts(params);
        if (res.status == 200) {
          fileDownload(res.data, `Attempts Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Vat") {
      try {
        const res = await exportrateVat(params);
        if (res.status == 200) {
          fileDownload(res.data, `VAT Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status == "Gpa") {
      try {
        const res = await exportrateGpa(params);
        if (res.status == 200) {
          fileDownload(res.data, `GPA Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else {
      try {
        const res = await exportrateFreight(params);
        if (res.status == 200) {
          fileDownload(res.data, `GPA Rates.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    }
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      productsData?.data?.data?.content?.length < 1 ||
      productsData?.length < 1
    ) {
      filterProducts(paramslink);
    }
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.length < 1
    ) {
      filterCountries(paramslink);
    }
    if (
      regionsData?.data?.data?.content?.length < 1 ||
      regionsData?.length < 1
    ) {
      filterRegions(paramslink);
    }
  }, []);
  useEffect(() => {
    !editPage && getData();
  }, [
    newPage,
    size,
    sortField,
    status,
    editPage,
    customer,
    product,
    structure,
    rateState,
    paymentType,
    deliveryStatus,
    ispostAdded,
    contentType,
    originCity,
    destinationCity,
    originCountry,
    destinationCountry,
    serviceType,
    calculationType,
    destRegion,
  ]);
  useEffect(() => {
    if (calculationData?.data?.data === undefined) {
      getCalType();
    }
    if (productEventData?.data?.data === undefined) {
      getProductsEvent();
    }
    if (collectionData?.data?.data === undefined) {
      getCollectionMethod();
    }
    if (contentData?.data?.data === undefined) {
      getContentType();
    }
    if (stateRateData?.data?.data === undefined) {
      getStateRates();
    }
    if (deliveryStatusData?.data?.data === undefined) {
      getDeliveryStatus();
    }
    if (paymentData?.data?.data === undefined) {
      getPaymentType();
    }
    if (stateData?.data?.data === undefined) {
      getState();
    }
    if (structureData?.data?.data === undefined) {
      getStructure();
    }
    if (productWeightData?.data?.data === undefined) {
      getProductsWeight();
    }
  }, []);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [selectedOptionDesCity, setSelectedOptionDesCity] = useState(null);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  const loadMoreCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : originCityPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setOriginCitypage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${search}`;
        }
        const response = await getCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, originCityPage]
  );
  const loadMoreDesCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : desCityPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDescityPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${search}`;
        }
        const response = await getCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desCityPage]
  );
  const combinedStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      fontSize: "12px",
    }),
  };
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/masters/rates")}
              >
                Masters
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/masters/rates")}
              >
                Rates
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${status == "Freight" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Freight");
            clearStates();
            setPaymentType("");
            setContentType("");
          }}
        >
          Freight
        </button>
        <button
          className={`filterBtn ${status == "RTO" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("RTO");
            clearStates();
            setContentType("");
          }}
        >
          RTO
        </button>
        <button
          className={`filterBtn ${status == "COD" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("COD");
            clearStates();
          }}
        >
          COD
        </button>
        <button
          className={`filterBtn ${status == "ODA" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("ODA");
            clearStates();
          }}
        >
          ODA Surcharge
        </button>
        <button
          className={`filterBtn ${status == "Fuel" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Fuel");
            clearStates();
          }}
        >
          Fuel
        </button>
        <button
          className={`filterBtn ${status == "Insurance" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Insurance");
            clearStates();
          }}
        >
          Insurance
        </button>
        <button
          className={`filterBtn ${status == "Attempts" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Attempts");
            clearStates();
          }}
        >
          Attempts
        </button>
        <button
          className={`filterBtn ${status == "Vat" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Vat");
            clearStates();
          }}
        >
          VAT
        </button>
        <button
          className={`filterBtn ${status == "Gpa" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Gpa");
            clearStates();
          }}
        >
          GPA
        </button>
      </div>
      <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
        <div className="displayFlex justifyContent_end mt_5">
          {status === "Freight" || status === "RTO" ? (
            <Row>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">Product:</label>
                <SearchInput
                  key={key}
                  id="product"
                  label="product"
                  name="product"
                  options={productsData?.data?.data?.content || []}
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  getOptionValue={(option) => option.code}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setProduct(selectedOption ? selectedOption.code : "");
                  }}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isReactselectOpen}
                  isSearchable={true}
                  placeholder="Product"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12 marginR10">Customer:</label>
                <AsyncSearchInput
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOptionCustomer}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  customStyles={{ control: { width: "155px" } }}
                  onChange={(selectedOptionCustomer) => {
                    setSelectedOptionCustomer(selectedOptionCustomer);
                    if (selectedOptionCustomer) {
                      setCustomer(selectedOptionCustomer.value);
                    } else {
                      setCustomer(null);
                    }
                  }}
                  placeholder="Customer"
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">Structure:</label>
                <SearchInput
                  key={key}
                  id="structure"
                  label="structure"
                  name="structure"
                  options={structureData?.data?.data || []}
                  getOptionLabel={(option) => `${option.key} - ${option.label}`}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setStructure(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuOpencity}
                  onMenuClose={handleMenuClosecity}
                  menuIsOpen={isReactselectCity}
                  isSearchable={true}
                  placeholder="Structure"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              {status === "Freight" && (
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">Payment Type:</label>
                  <SearchInput
                    key={key}
                    id="paymentType"
                    label="paymentType"
                    name="paymentType"
                    options={paymentData?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    isClearable={true}
                    handleChange={(selectedOption) => {
                      setPaymentType(selectedOption ? selectedOption.key : "");
                    }}
                    onMenuOpen={handleMenudescountry}
                    onMenuClose={handleMenuClosedescountry}
                    menuIsOpen={isReactdesCountry}
                    isSearchable={true}
                    placeholder="Payment Type"
                    customStyles={{ control: { width: "155px" } }}
                    className="mainInput"
                    style={{ whiteSpace: "nowrap" }}
                  />
                </Col>
              )}
              {status === "Freight" && (
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">Delivery Status:</label>
                  <SearchInput
                    id="deliveryStatus"
                    label="deliveryStatus"
                    name="deliveryStatus"
                    options={deliveryStatusData?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    isClearable={true}
                    handleChange={(selectedOption) => {
                      setDeliveryStatus(
                        selectedOption ? selectedOption.key : ""
                      );
                    }}
                    onMenuOpen={handleMenuOpenorgcity}
                    onMenuClose={handleMenuCloseorgcity}
                    menuIsOpen={isReactoriginselectCity}
                    isSearchable={true}
                    placeholder="Delivery Status"
                    customStyles={{ control: { width: "155px" } }}
                    className="mainInput"
                    style={{ whiteSpace: "nowrap" }}
                  />
                </Col>
              )}
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">Content Type:</label>
                <SearchInput
                  key={key}
                  id="contentType"
                  label="contentType"
                  name="contentType"
                  options={contentData?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setContentType(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenudescity}
                  onMenuClose={handleMenuClosedescity}
                  menuIsOpen={isReactdesCity}
                  isSearchable={true}
                  placeholder="Content Type"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">Origin Country:</label>
                <SearchInput
                  key={key}
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setOriginCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isSearchable={true}
                  placeholder="Origin Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">Origin City:</label>
                <AsyncSearchInput
                  id="originCity"
                  label="originCity"
                  name="originCity"
                  value={selectedOptionCity}
                  loadMoreOptions={loadMoreCityOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  menuIsOpen={isReactRates}
                  customStyles={{ control: { width: "155px" } }}
                  onChange={(selectedOptionCity) => {
                    setSelectedOptionCity(selectedOptionCity);
                    if (selectedOptionCity) {
                      setOriginCity(selectedOptionCity.value);
                    } else {
                      setOriginCity(null);
                    }
                  }}
                  placeholder="Origin City"
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">Destination Country:</label>
                <SearchInput
                  key={key}
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenuCharges}
                  onMenuClose={handleMenuCloseCharges}
                  menuIsOpen={isReactCharges}
                  isSearchable={true}
                  placeholder="Destination Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">Destination City:</label>
                <AsyncSearchInput
                  id="destinationCity"
                  label="destinationCity"
                  name="destinationCity"
                  value={selectedOptionDesCity}
                  loadMoreOptions={loadMoreDesCityOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuCurrency}
                  onMenuClose={handleMenuCloseCurrency}
                  menuIsOpen={isReactCurrency}
                  customStyles={{ control: { width: "155px" } }}
                  onChange={(selectedOptionDesCity) => {
                    setSelectedOptionDesCity(selectedOptionDesCity);
                    if (selectedOptionDesCity) {
                      setDestinationCity(selectedOptionDesCity.value);
                    } else {
                      setDestinationCity(null);
                    }
                  }}
                  placeholder="Destination City"
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize14 mb_12">State:</label>
                <SearchInput
                  key={key}
                  id="rateState"
                  label="rateState"
                  name="rateState"
                  options={stateRateData?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setRateState(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuState}
                  onMenuClose={handleMenuCloseState}
                  menuIsOpen={isReactRatestate}
                  isSearchable={true}
                  placeholder="State"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
            </Row>
          ) : status === "ODA" ? (
            <Row>
              <Col className="col-20-percent">
                <label className="fontSize14 mb_12">Product:</label>
                <SearchInput
                  key={key}
                  id="product"
                  label="product"
                  name="product"
                  options={productsData?.data?.data?.content || []}
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  getOptionValue={(option) => option.code}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setProduct(selectedOption ? selectedOption.code : "");
                  }}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isReactselectOpen}
                  isSearchable={true}
                  placeholder="Product"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="col-20-percent">
                <label className="fontSize14 mb_12 marginR10">Customer:</label>
                <AsyncSearchInput
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOptionCustomer}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  customStyles={{ control: { width: "155px" } }}
                  onChange={(selectedOptionCustomer) => {
                    setSelectedOptionCustomer(selectedOptionCustomer);
                    if (selectedOptionCustomer) {
                      setCustomer(selectedOptionCustomer.value);
                    } else {
                      setCustomer(null);
                    }
                  }}
                  placeholder="Customer"
                />
              </Col>
              <Col className="col-20-percent">
                <label className="fontSize14 mb_12">Origin Country:</label>
                <SearchInput
                  key={key}
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setOriginCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isSearchable={true}
                  placeholder="Origin Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="col-20-percent">
                <label className="fontSize14 mb_12">Destination Country:</label>
                <SearchInput
                  key={key}
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenuCharges}
                  onMenuClose={handleMenuCloseCharges}
                  menuIsOpen={isReactCharges}
                  isSearchable={true}
                  placeholder="Destination Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="col-20-percent">
                <label className="fontSize14 mb_12">State:</label>
                <SearchInput
                  key={key}
                  id="rateState"
                  label="rateState"
                  name="rateState"
                  options={stateRateData?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setRateState(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuState}
                  onMenuClose={handleMenuCloseState}
                  menuIsOpen={isReactRatestate}
                  isSearchable={true}
                  placeholder="State"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
            </Row>
          ) : status === "COD" ||
            status === "Fuel" ||
            status === "Insurance" ||
            status === "Attempts" ? (
            <Row>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Product:</label>
                <SearchInput
                  key={key}
                  id="product"
                  label="product"
                  name="product"
                  options={productsData?.data?.data?.content || []}
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  getOptionValue={(option) => option.code}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setProduct(selectedOption ? selectedOption.code : "");
                  }}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isReactselectOpen}
                  isSearchable={true}
                  placeholder="Product"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10">Customer:</label>
                <AsyncSearchInput
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOptionCustomer}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  customStyles={{ control: { width: "155px" } }}
                  onChange={(selectedOptionCustomer) => {
                    setSelectedOptionCustomer(selectedOptionCustomer);
                    if (selectedOptionCustomer) {
                      setCustomer(selectedOptionCustomer.value);
                    } else {
                      setCustomer(null);
                    }
                  }}
                  placeholder="Customer"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Origin Country:</label>
                <SearchInput
                  key={key}
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setOriginCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isSearchable={true}
                  placeholder="Origin Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Destination Country:</label>
                <SearchInput
                  key={key}
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenuCharges}
                  onMenuClose={handleMenuCloseCharges}
                  menuIsOpen={isReactCharges}
                  isSearchable={true}
                  placeholder="Destination Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">State:</label>
                <SearchInput
                  key={key}
                  id="rateState"
                  label="rateState"
                  name="rateState"
                  options={stateRateData?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setRateState(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuState}
                  onMenuClose={handleMenuCloseState}
                  menuIsOpen={isReactRatestate}
                  isSearchable={true}
                  placeholder="State"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
            </Row>
          ) : status === "Vat" || status === "Gpa" ? (
            <Row>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Product:</label>
                <SearchInput
                  key={key}
                  id="product"
                  label="product"
                  name="product"
                  options={productsData?.data?.data?.content || []}
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  getOptionValue={(option) => option.code}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setProduct(selectedOption ? selectedOption.code : "");
                  }}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isReactselectOpen}
                  isSearchable={true}
                  placeholder="Product"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Origin Country:</label>
                <SearchInput
                  key={key}
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setOriginCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isSearchable={true}
                  placeholder="Origin Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Destination Country:</label>
                <SearchInput
                  key={key}
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenuCharges}
                  onMenuClose={handleMenuCloseCharges}
                  menuIsOpen={isReactCharges}
                  isSearchable={true}
                  placeholder="Destination Country"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
              <label className="fontSize14 mb_12">State:</label>
                <SearchInput
                  key={key}
                  id="rateState"
                  label="rateState"
                  name="rateState"
                  options={stateRateData?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setRateState(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuState}
                  onMenuClose={handleMenuCloseState}
                  menuIsOpen={isReactRatestate}
                  isSearchable={true}
                  placeholder="State"
                  customStyles={{ control: { width: "155px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
      <div className="displayFlex justifyContent_end">
        <div className="smallGroupInput mleft_10">
          {(roleHandler(role, "AONE_MASTERS.write") ||
            roleHandler(role, "AONE_MASTERS.admin") ||
            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
            <div class="jira-dropdown mr_5 w115 ml_15" ref={dropdownRef}>
              {selectRates?.length > 0 && (
                <>
                  <button
                    className="jira-dropdown-button"
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                      setReactDesBulk(!isReactdesBulk);
                    }}
                  >
                    Bulk Actions
                  </button>
                  <ul
                    className="jira-dropdown-content"
                    style={{
                      display: isDropdownOpen ? "block" : "none",
                      borderRadius: "10px",
                    }}
                  >
                    <li onClick={handlescanShow}>
                      <a>Delete</a>
                    </li>
                    <li>
                      <a onClick={toggleStateDropdown}>Update State</a>
                      <ul
                        className="state-dropdown-content jira-dropdown-content"
                        style={{
                          display: isStateDropdownOpen ? "block" : "none",
                        }}
                      >
                        {(roleHandler(role, "AONE_MASTERS.admin") ||
                          roleHandler(role, "AONE_MASTERS_RATES.admin")) && (
                          <>
                            <li onClick={() => handleStateChange("ACTIVE")}>
                              <a>Active</a>
                            </li>
                            <li onClick={() => handleStateChange("NOT_ACTIVE")}>
                              <a>Not Active</a>
                            </li>
                          </>
                        )}
                        <li
                          onClick={() => handleStateChange("WAITING_APPROVAL")}
                        >
                          <a>Waiting</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </>
              )}
            </div>
          )}
          <button
            className="filterBtn"
            title="Export"
            onClick={() => exportToExcel()}
          >
            <span>
              <img src={downloadicon} className="h18" />
            </span>
          </button>
          {(roleHandler(role, "AONE_MASTERS.write") ||
            roleHandler(role, "AONE_MASTERS.admin") ||
            roleHandler(role, "AONE_MASTERS_RATES.admin") ||
            roleHandler(role, "AONE_MASTERS_RATES.write")) && (
            <>
              <button
                style={{ marginLeft: "10px" }}
                className="filterBtn plus_icon"
                title="Download Template"
                onClick={downloadTemplate}
              >
                <span>
                  <img src={templateIcon} className="h18" />
                </span>
              </button>
              <div title="Import File" className="mleft_10">
                <label className="filterBtn plus_icon" htmlFor="uploadFile">
                  <span>
                    <img src={importIcon} className="h18" />
                  </span>
                  <input
                    type="file"
                    id="uploadFile"
                    onInput={(e) => handleFileChange(e)}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                  />
                </label>
              </div>
            </>
          )}
          <div className="displayFlex gap_12 mleft_10">
            <button
              className="filterBtn plus_icon"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
            {(roleHandler(role, "AONE_MASTERS.write") ||
              roleHandler(role, "AONE_MASTERS.admin") ||
              roleHandler(role, "AONE_MASTERS_RATES.admin") ||
              roleHandler(role, "AONE_MASTERS_RATES.write")) && (
              <button
                className="filterBtn plus_icon"
                onClick={handleAddModalClick}
                title="Add"
                tabIndex="-1"
              >
                +
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="mt_20">
        <PaginationTable
          tbl={`rates_${status}`}
          tableData={
            status === "Freight"
              ? freightData
              : status === "RTO"
              ? rtoData
              : status === "COD"
              ? codData
              : status === "ODA"
              ? odaData
              : status === "Fuel"
              ? fuelData
              : status === "Insurance"
              ? insuranceData
              : status === "Attempts"
              ? attemptsData
              : status === "Vat"
              ? vatData
              : gpaData
          }
          title={
            status === "Freight"
              ? "Edit freight rate"
              : status === "RTO"
              ? "Edit RTO rate"
              : status === "COD"
              ? "Edit COD rate"
              : status === "ODA"
              ? "Edit ODA Surcharge rate"
              : status === "Fuel"
              ? "Edit Fuel rate"
              : status === "Insurance"
              ? "Edit Insurance rate"
              : status === "Attempts"
              ? "Edit Attempts rate"
              : status === "Vat"
              ? "Edit Vat rate"
              : "Edit Gpa rate"
          }
          modalHandler={editPage}
          setModalHandler={setEditPage}
          setSelectRates={setSelectRates}
          selectRates={selectRates}
          setSelectAll={setSelectAll}
          selectAll={selectAll}
          setChargeTitle={setTitle}
          setShowCharge={setShowModal}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          handleRateEdit={handleEdit}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          setSize={setSize}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          totalCount={totalCount}
          sortField={sortField}
          setSortField={setSortField}
        />
        {addModalHandler && (status !== "Vat" || status !== "Gpa") && (
          <OpenModal
            modalHandler={addModalHandler}
            title={
              status === "Freight"
                ? "Create new freight rate"
                : status === "RTO"
                ? "Create new RTO rate"
                : status === "COD"
                ? "Create new COD rate"
                : status === "ODA"
                ? "Create new ODA Surcharge rate"
                : status === "Fuel"
                ? "Create new Fuel rate"
                : status === "Insurance"
                ? "Create new Insurance rate"
                : "Create new Attempts rate"
            }
            setModalHandler={setAddModalHandler}
            editHandler={editHandler}
            setEditHandler={setEditHandler}
          />
        )}
        <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={status === "Vat" ? "addVat" : "addnewpopup"}
        >
          {status == "Vat" ? (
            <VatModal
              showModal={showModal}
              onSuccess={handleSuccess}
              onError={handleError}
              setShowModal={setShowModal}
              title={title}
              ratesbyCode={ratesbyCode}
            />
          ) : (
            <GpaModal
              showModal={showModal}
              onSuccess={handleSuccess}
              onError={handleError}
              setShowModal={setShowModal}
              title={title}
              ratesbyCode={ratesbyCode}
            />
          )}
        </CusModal>
        {showScan && (
          <Modal
            className="scanConfirmpopup"
            show={showScan}
            onHide={handlescanClose}
            centered
          >
            <Modal.Body>
              <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
                Do you really want to delete the selected rates?
              </h2>
            </Modal.Body>
            <Modal.Footer style={{ flexWrap: "unset" }}>
              <div>
                <button
                  className="deletebtn cancelPrimary"
                  onClick={handlescanCancel}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="deletebtn confirmPrimary"
                  onClick={handledeleteRates}
                >
                  Delete
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Rates;
