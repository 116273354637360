import React, {
} from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg";
import { useNavigate, useParams } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import Orders from "./Orders";
const OrderAdd = () => {
  const navigate = useNavigate();
  const params = useParams();
  const setcustomerAccount = useAoneStore((state) => state?.setcustomerAccount);
  const getCitybyCode = useAoneStore((state) => state?.getCitybyCode);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  setcustomerAccount("");
                  navigate("/consignments/orders");
                }}
              >
                Consignments
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  setcustomerAccount("");
                  navigate("/consignments/orders");
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                }}
              >
                Orders
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
                {!params?.id ? "New" : params?.id}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Orders/>
    </div>
  );
};

export default OrderAdd;
