import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../../store/AoneStore";
import fileDownload from "js-file-download";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import PaginationTable from "../../../components/Tables/paginationTable";
import CusModal from "../../../components/modal/CusModal";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { useRtoStore } from "../../../store/RtoMgt/RtoStore";
import DeletingModal from "../../../components/modal/deletingModal";
import { UserContext } from "../../../helper/ProtectedRoute";
import { roleHandler } from "../../../helper/roleHandler";
import downloadicon from "../../../assets/img/download_icon.svg";
import RtoViewmodal from "./RtoViewmodal";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Rto = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [trackingId, setTrackingId] = useState("");
  const [packagetrackingId, setPackageTrackingId] = useState("");
  const [rtotrackingId, setRtoTrackingId] = useState("");
  const [customer, setCustomer] = useState("");
  const [status, setStatus] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const[newRtoTrackingId,setNewRtoTrackingId] = useState("");
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [weighingData, setWeighingData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const [isDropdownOpenRto, setIsDropdownOpenRto] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [ischangeTable, setIsChangeTable] = useState(false);
  const [selectRto, setSelectRto] = useState([]);
  const setReactDesBulk = useAoneStore((state) => state?.setReactDesBulk);
  const isReactdesBulk = useAoneStore((state) => state?.isReactdesBulk);
  const getRtoRequest = useRtoStore((state) => state?.getRtoRequest);
  const getRtobyPackageId = useRtoStore((state) => state?.getRtobyPackageId);
  const getRtobyId = useRtoStore((state) => state?.getRtobyId);
  const listRtoStatus = useRtoStore((state) => state?.listRtoStatus);
  const submitSingleRtoRequest = useRtoStore(
    (state) => state?.submitSingleRtoRequest
  );
  const rtoStatusList = useRtoStore((state) => state?.rtoStatusList);
  const exportRtoRequest = useRtoStore((state) => state?.exportRtoRequest);
  const printConsignments = useAoneStore((state) => state?.printConsignments);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const [settleRto, setSettleRto] = useState([]);
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const printBulkConsignments = useAoneStore(
    (state) => state?.printBulkConsignments
  );
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cusPage, setCusPage] = useState(-1);
  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };
  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const gridId = "ops.rto";
  const [column, SetCoulmn] = useState([
    "Tracking Id",
    "RTO Tracking Id",
    "Package Count",
    "Customer",
    "Status",
    "Created at",
    "Created by",
    "Updated by",
    "Updated at",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState(["Updated by", "Updated at"]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const handleBulkPrintOrders = async () => {
    if (selectRto?.length < 1) {
      toast.error("Please select atleast one consignment to print");
    } else {
      try {
        const res = await printBulkConsignments(selectRto);
        // Create a Blob from the response data
        const blob = new Blob([res.data], { type: "application/pdf" });
        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
        // Open the PDF in a new window
        window.open(blobUrl, "_blank");
        setSelectAll(false);
        setSelectRto([]);
        setIsChangeTable(!ischangeTable);
      } catch (error) {}
    }
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const handleEdit = (id) => {
    setShowModal(true);
    getRtoonView(id);
  };
  const getRtoonView = async (id) => {
    getRtobyId(id);
  };
  const [packErr, setPackErr] = useState("");
  const singleSubmit = async () => {
    if (packagetrackingId == "") {
      setPackErr("Package tracking id is required");
    } else if (packagetrackingId.length !== 18) {
      setPackErr("Package tracking id should be valid");
    } else {
      setPackErr("");
      let res = await submitSingleRtoRequest(packagetrackingId);
      if (
        res?.data?.status == "ERROR" &&
        res?.data?.msg !== "There are unprocessed packages"
      ) {
        toast.error(res?.data?.msg);
        setPackageTrackingId("");
      } else if (
        res?.data?.status == "ERROR" &&
        res?.data?.msg == "There are unprocessed packages"
      ) {
        getRtobyPackageId(packagetrackingId);
        setShowModal(true);
        setPackageTrackingId("");
      } else if (res?.data?.status == "SUCCESS") {
        setNewRtoTrackingId(res?.data?.data?.rtoTrackingId);
        toast.success(`RTO Submitted for the package ${packagetrackingId}`);
        setPackageTrackingId("");
        getData();
      }
    }
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  const printShipmentOrders = async () => {
    try {
      const res = await printConsignments(newRtoTrackingId);
      // Create a Blob from the response data
      const blob = new Blob([res.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      // Open the PDF in a new window
      window.open(blobUrl, "_blank");
    } catch (error) {}
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (rtotrackingId) {
      params += `&rtoTrackingId=${rtotrackingId}`;
    }
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    try {
      const res = await exportRtoRequest(params);
      if (res.status == 200) {
        fileDownload(res.data, `Rto.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (rtotrackingId) {
      params += `&rtoTrackingId=${rtotrackingId}`;
    }
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    const data = await getRtoRequest(params);
    setTotalCount(data?.data?.data?.totalElements);
    setWeighingData(data?.data?.data?.content);
  };
  useEffect(() => {
    if (rtoStatusList?.data?.data === undefined) {
      listRtoStatus();
    }
  }, []);
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    location,
    saveHandler,
    trackingId,
    rtotrackingId,
    customer,
    status,
    ispostAdded,
  ]);
  const printRtoLabel = () => {};
  const [title, setTitle] = useState("Rto Request");
  const [weightbyCode, setWeightbyCode] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/rto")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/ops/rto");
                }}
              >
                RTO
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween">
        {( roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_RTO.write")) && (
          <div className="displayFlex justifyContent_end mleft_5">
            <Row>
              <Col className="ml_5">
                <div className="displayFlex alignItemCenter marginR10">
                  <input
                    type="text"
                    value={packagetrackingId}
                    className={
                      packagetrackingId?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      packagetrackingId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    placeholder="Package Tracking Id"
                    onChange={(e) => {
                      setPackageTrackingId(e.target.value);
                      setPackErr("");
                      setNewRtoTrackingId("");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        singleSubmit();
                      }
                    }}
                  />

                  <button
                    className="activeFilterBtn marginL12"
                    onClick={singleSubmit}
                  >
                    Submit
                  </button>
                  {newRtoTrackingId !== "" && (
                  <button
                    className="activeFilterBtn marginL12"
                    onClick={printShipmentOrders}
                  >
                    Print
                  </button>)}
                  {/* <button className="activeFilterBtn marginL12">Print</button> */}
                </div>
                {packErr !== "" ? (
                  <p className="requiredText">{packErr}</p>
                ) : null}
              </Col>
            </Row>
          </div>)}
        </div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Tracking ID:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={trackingId}
                      className={
                        trackingId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        trackingId?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setTrackingId(e.target.value)}
                    />
                      {trackingId && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setTrackingId("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    RTO Tracking ID:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={rtotrackingId}
                      className={
                        rtotrackingId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        rtotrackingId?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setRtoTrackingId(e.target.value)}
                    />
                      {rtotrackingId && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setRtoTrackingId("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Customer:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <AsyncSearchInput
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOptionCustomer}
                      loadMoreOptions={loadMoreOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      customStyles={{ control: { width: "180px" } }}
                      onChange={(selectedOptionCustomer) => {
                        setSelectedOptionCustomer(selectedOptionCustomer);
                        if (selectedOptionCustomer) {
                          setCustomer(selectedOptionCustomer.value);
                        } else {
                          setCustomer(null);
                        }
                      }}
                      placeholder="Customer"
                    />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Status:
                  </label>
                  <SearchInput
                    id="status"
                    label="status"
                    name="status"
                    options={rtoStatusList?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    handleChange={(selectedOption) => {
                      setStatus(selectedOption ? selectedOption.key : "");
                    }}
                    onMenuOpen={handleMenudescountry}
                    onMenuClose={handleMenuClosedescountry}
                    menuIsOpen={isReactdesCountry}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Status"
                    customStyles={{ control: { width: "180px" } }}
                    className="mainInput"
                  />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                  <div class="jira-dropdown" style={{ marginLeft: "20px" }}>
                    <button
                      className="jira-dropdown-button"
                      onClick={() => {
                        setIsDropdownOpenRto(!isDropdownOpenRto);
                        setReactDesBulk(!isReactdesBulk);
                      }}
                    >
                      Actions
                    </button>
                    <ul
                      className="jira-dropdown-content"
                      style={{
                        display: isDropdownOpenRto ? "block" : "none",
                        borderRadius: "10px",
                      }}
                    >
                      <li onClick={handleBulkPrintOrders}>
                        <a>Print RTO Label</a>
                      </li>
                    </ul>
                  </div>
                </>
                <button
                  className="filterBtn"
                  title={"Export RTO"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column?.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
      <PaginationTable
        tbl="RtoRequest"
        tableData={weighingData}
        size={size}
        restrict={checkedCol}
        setSize={setSize}
        totalCount={totalCount}
        selectAllRto={selectAll}
        setSelectAllRto={setSelectAll}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        selectRto={selectRto}
        setSelectRto={setSelectRto}
        handleRtobyId={handleEdit}
        settleRto={settleRto}
        setSettleRto={setSettleRto}
        ischangeRtoTable={ischangeTable}
        setNewPage={setNewPage}
        setShowModalRto={setShowModal}
        sortField={sortField}
        setSortField={setSortField}
        newPage={newPage}
      />
      <CusModal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="addnewvehcilepop"
      >
        <RtoViewmodal
          onSuccess={handleSuccess}
          onError={handleError}
          setShowModal={setShowModal}
          title={title}
          weightbyCode={weightbyCode}
        />
      </CusModal>
    </div>
  );
};

export default Rto;
