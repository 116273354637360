import { useRoutes, useLocation, Navigate } from "react-router-dom";
import Ondemand from "../../pages/Ondemand";
import Login from "../../pages/login";
import Admin from "../sidebar/Admin";
import SlaPage from "../../pages/SlaPage";
import {UseAuthRoute} from "../../helper/ProtectedRoute";
import Callback from "../../helper/Callback";
import Consigments from "../../pages/Print";
import AdditionalInfo from "../../pages/Additional";
import Sendsms from "../../pages/Sendsms";
import ZidProducts from "../../pages/Zid/ZidProducts";
import ZidMerchants from "../../pages/Zid/ZidMerchants";
import ZidOrders from "../../pages/Zid/ZidOrders";
import Express from "../../pages/express";
import Management from "../../pages/management";
import DetailedExpress from "../../pages/BusinessLines/detailedExpress";
import DetailedRole from "../../pages/BusinessLines/DetailedRole";
import Addresses from "../../pages/Configrations/Addresses/Addresses";
import SortingMachine from "../../pages/Middlemile/SortingMachine";
import Groups from "../../pages/IAM/Groups";
import Users from "../../pages/IAM/Users";
import Roles from "../../pages/IAM/Roles";
import DetailedManagement from "../../pages/BusinessLines/DetailedManagement";
import DetailedUser from "../../pages/BusinessLines/DetailedUser";
import DetailedOrder from "../../pages/BusinessLines/DetailedOrder";
import JobOrders from "../../pages/JobOrders";
import Operations from "../../pages/Operations";
import NoContent from "../../pages/NoContent";
import ThirdpartyConfig from "../../pages/Thirdparty/Configrations"
import Events from "../../pages/Thirdparty/events";
import Orders from "../../pages/Thirdparty/orders";
import Bags from "../../pages/Middlemile/Bags";
import Ratelimiter from "../../pages/ratelimiter";
import AddressMapping from "../../pages/AddressMapping";
import Zones from "../../pages/Configrations/Zones/zones";
import Customers from "../../pages/Customers/Customers";
import Employees from "../../pages/Employees/Employees";
import CustomerAdd from "../../pages/Customers/CustomerAdd";
import Invoices from "../../pages/Invoices/Invoices";
import Currency from "../../pages/Configrations/Currency/Currency";
import Hub from "../../pages/Assetmanagement/Hubs/Hub";
import EmployeeAdd from "../../pages/Employees/EmployeeAdd";
import Products from "../../pages/Masters/Products";
import Rates from "../../pages/Masters/Rates";
import ProdcutAdd from "../../pages/Product/ProductAdd";
import HubsAdd from "../../pages/Assetmanagement/Hubs/HubsAdd";
import Pickups from "../../pages/Operations/Pickup/Pickups";
import Reasons from "../../pages/Operations/Reasons/Reasons";
import OpsSortingMachine from "../../pages/Operations/SortingMachine/SortingMachine";
import ConsignmentOrders from "../../pages/Consignments/ConsignmentOrders";
import OrderAdd from "../../pages/Consignments/OrderAdd";
import Tiers from "../../pages/Tiers/Tiers";
import Tierodpair from "../../pages/Tiers/Tierodpair"
import ConsignmentsPickup from "../../pages/Operations/Pickup/Consignment";
import Vehicle from "../../pages/Assetmanagement/Vehicle/Vehicle";
import VehicleRoute from "../../pages/Operations/Routes/Routes";
import Couriers from "../../pages/Operations/Couriers/Courier";
import Weight from "../../pages/Operations/Weight/Weight";
import Consignments from "../../pages/Operations/ConsignmentOps/Consignments";
import OrderDetailsAdd from "../../pages/Consignments/OrderDetailsAdd";
import Linehauls from "../../pages/Linehaul/Linehauls";
import DriverAdd from "../../pages/Linehaul/Driver/DriverEditAdd";
import Acl from "../../pages/Operations/Acl/Acl";
import AclView from "../../pages/Operations/Acl/AclView";
import TripView from "../../pages/Linehaul/Trip/TripView";
import LastmileView from "../../pages/Operations/LastMile/LastMileView";
import Sequence from "../../pages/Consignments/Sequence"
import SmsView from "../../pages/Notifications/SmsView";
import Ledger from "../../pages/Invoices/CashDebriefing/Ledger"
import CashTabs from "../../pages/Invoices/CashDebriefing/CashTabs";
import Settlement from "../../pages/Invoices/CashDebriefing/Settlement";
import Rto from "../../pages/Operations/Rto/Rto";
import Deliveryflow from "../../pages/Operations/DeliveryFlow/DeliveryFlow";
import DeliveryFlowAdd from "../../pages/Operations/DeliveryFlow/DeliveryFlowAdd";
import ShortAddress from "../../pages/Configrations/ShortAddress/ShortAddress";
import Localizations from "../../pages/Settings/Localization";
import Systems from "../../pages/Settings/System";
export const Routes = () => {
  const routes = useRoutes([
    {
      path: "/auth_callback",
      element: <Callback />,
    },
    // {
    //   path: "/",
    //   element: <Navigate to="/login" />,
    // },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/no-content",
      element: <NoContent/>
    },
    {
      element: <Admin />,
      children: [
        {
          path: "/ondemand",
          element: (
            <UseAuthRoute>
              <Ondemand />
            </UseAuthRoute>
          ),
        },
        {
          path: "/products/sla",
          element: (
            <UseAuthRoute>
              {" "}
              <SlaPage />{" "}
            </UseAuthRoute>
          ),
        },
        {
          path: "/consignments/print",
          element: (
            <UseAuthRoute>
              {" "}
              <Consigments />{" "}
            </UseAuthRoute>
          ),
        },
        {
          path: "/consignments/additional-info",
          element: (
            <UseAuthRoute>
              {" "}
              <AdditionalInfo />{" "}
            </UseAuthRoute>
          ),
        },
        {
          path: "/tools/send-sms",
          element: (
            <UseAuthRoute>
              {" "}
              <Sendsms />{" "}
            </UseAuthRoute>
          ),
        },
        {
          path: "/integrations/zid/products",
          element: (
            <UseAuthRoute>
              {" "}
              <ZidProducts />{" "}
            </UseAuthRoute>
          )
        },
        {
          path: "/integrations/zid/merchants",
          element: (
            <UseAuthRoute>
              {" "}
              <ZidMerchants />{" "}
            </UseAuthRoute>
          )
        },
        {
          path: "/integrations/zid/orders",
          element: (
            <UseAuthRoute>
              {" "}
              <ZidOrders />{" "}
            </UseAuthRoute>
          )
        },
        {
          path: "/iam/roles",
          element: (
            <UseAuthRoute>
              <Roles/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/iam/roles/:id",
          element: (
            <UseAuthRoute>
              <DetailedRole/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/iam/groups",
          element: (
            <UseAuthRoute>
              <Groups/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/iam/groups/:id",
          element: (
            <UseAuthRoute>
              <Groups/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/iam/users",
          element: (
            <UseAuthRoute>
              <Users/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/iam/users/:id",
          element: (
            <UseAuthRoute>
              <DetailedUser/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/middlemile/sortingmachine/config",
          element: (
            <UseAuthRoute>
              <SortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/middlemile/sortingmachine/config/:id",
          element: (
            <UseAuthRoute>
              <SortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/middlemile/sortingmachine/agent",
          element: (
            <UseAuthRoute>
              <SortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/middlemile/sortingmachine/machinescan",
          element: (
            <UseAuthRoute>
              <SortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/middlemile/bags",
          element: (
            <UseAuthRoute>
              <Bags/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/middlemile/bags/:id",
          element: (
            <UseAuthRoute>
              <Bags/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/crm/customers",
          element: (
            <UseAuthRoute>
              <Customers/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/crm/customers/add",
          element: (
            <UseAuthRoute>
              <CustomerAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/crm/customers/:id",
          element: (
            <UseAuthRoute>
              <CustomerAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/crm/employees/add",
          element: (
            <UseAuthRoute>
              <EmployeeAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/crm/employees/:id",
          element: (
            <UseAuthRoute>
              <EmployeeAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/masters/products",
          element: (
            <UseAuthRoute>
              <Products/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/masters/products/add",
          element: (
            <UseAuthRoute>
              <ProdcutAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/masters/products/:id",
          element: (
            <UseAuthRoute>
              <ProdcutAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/assets/hubs/add",
          element: (
            <UseAuthRoute>
              <HubsAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/assets/hubs/:id",
          element: (
            <UseAuthRoute>
              <HubsAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/consignments",
          element: (
            <UseAuthRoute>
              <Consignments/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/linehauls",
          element: (
            <UseAuthRoute>
              <Linehauls/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/linehauls/driver/:id",
          element: (
            <UseAuthRoute>
              <DriverAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/linehauls/trip/:id",
          element: (
            <UseAuthRoute>
              <TripView/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/lastmile",
          element: (
            <UseAuthRoute>
              <LastmileView/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/pickups",
          element: (
            <UseAuthRoute>
              <Pickups/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/pickups/:id",
          element: (
            <UseAuthRoute>
              <ConsignmentsPickup/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/pickups/:id",
          element: (
            <UseAuthRoute>
              <Pickups/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/reasons",
          element: (
            <UseAuthRoute>
              <Reasons/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/sortingmachine/config",
          element: (
            <UseAuthRoute>
              <OpsSortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/sortingmachine/config/:id",
          element: (
            <UseAuthRoute>
              <OpsSortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/sortingmachine/agent",
          element: (
            <UseAuthRoute>
              <OpsSortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/sortingmachine/machinescan",
          element: (
            <UseAuthRoute>
              <OpsSortingMachine/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/deliveryflow",
          element: (
            <UseAuthRoute>
              <Deliveryflow/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/deliveryflow/add",
          element: (
            <UseAuthRoute>
              <DeliveryFlowAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/deliveryflow/:id",
          element: (
            <UseAuthRoute>
              <DeliveryFlowAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/consignments/orders",
          element: (
            <UseAuthRoute>
              <ConsignmentOrders/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/consignments/orders/add",
          element: (
            <UseAuthRoute>
              <OrderAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/consignments/orders/:id",
          element: (
            <UseAuthRoute>
              <OrderDetailsAdd/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/consignments/sequence",
          element: (
            <UseAuthRoute>
              <Sequence/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/masters/rates",
          element: (
            <UseAuthRoute>
              <Rates/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/tiers",
          element: (
            <UseAuthRoute>
              <Tiers/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/tiers/:id",
          element: (
            <UseAuthRoute>
              <Tierodpair/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/finance/invoices",
          element: (
            <UseAuthRoute>
              <Invoices/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/finance/cashdebriefing",
          element: (
            <UseAuthRoute>
              <Ledger/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/finance/cashdebriefing/:id",
          element: (
            <UseAuthRoute>
              <CashTabs/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/finance/cashdebriefing/settle/:id",
          element: (
            <UseAuthRoute>
              <Settlement/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/rto",
          element: (
            <UseAuthRoute>
              <Rto/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/assets/hubs",
          element: (
            <UseAuthRoute>
              <Hub/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/assets/vehicles",
          element: (
            <UseAuthRoute>
              <Vehicle/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/routes",
          element: (
            <UseAuthRoute>
              <VehicleRoute/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/couriers",
          element: (
            <UseAuthRoute>
              <Couriers/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/weighing",
          element: (
            <UseAuthRoute>
              <Weight/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/consolidations",
          element: (
            <UseAuthRoute>
              <Acl/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/ops/consolidations/:id",
          element: (
            <UseAuthRoute>
              <AclView/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/crm/employees",
          element: (
            <UseAuthRoute>
              <Employees/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/addresses",
          element: (
            <UseAuthRoute>
              <Addresses/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/addresses/add",
          element: (
            <UseAuthRoute>
              <Addresses/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/addresses/:id",
          element: (
            <UseAuthRoute>
              <Addresses/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/zones",
          element: (
            <UseAuthRoute>
              <Zones/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/addressmapping",
          element: (
            <UseAuthRoute>
              <AddressMapping/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/shortaddress",
          element: (
            <UseAuthRoute>
              <ShortAddress/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/settings/localization",
          element: (
            <UseAuthRoute>
              <Localizations/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/settings/system",
          element: (
            <UseAuthRoute>
              <Systems/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/configs/currency",
          element: (
            <UseAuthRoute>
              <Currency/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/configurations",
          element: (
            <UseAuthRoute>
              <ThirdpartyConfig/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/configurations/add",
          element: (
            <UseAuthRoute>
              <ThirdpartyConfig/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/configurations/:id",
          element: (
            <UseAuthRoute>
              <ThirdpartyConfig/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/orders",
          element: (
            <UseAuthRoute>
              <Orders/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/orders/add",
          element: (
            <UseAuthRoute>
              <Orders/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/orders/:id",
          element: (
            <UseAuthRoute>
              <Orders/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/events",
          element: (
            <UseAuthRoute>
              <Events/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/events/add",
          element: (
            <UseAuthRoute>
              <Events/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/thirdparty/events/:id",
          element: (
            <UseAuthRoute>
              <Events/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/notifications/sms",
          element: (
            <UseAuthRoute>
              <SmsView/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/projects/addon/orders",
          element: (
            <UseAuthRoute>
              <JobOrders/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/projects/addon/orders/add",
          element: (
            <UseAuthRoute>
              <DetailedOrder/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/projects/addon/orders/:id",
          element: (
            <UseAuthRoute>
              <DetailedOrder/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/projects/addon/operations",
          element: (
            <UseAuthRoute>
              <Operations/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/finance/charges",
          element: (
            <UseAuthRoute>
              <Ratelimiter title="CHARGES"/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/express",
          element: (
            <UseAuthRoute>
              <Express title="EXPRESS"/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/invoice/:name/:id",
          element: (
            <UseAuthRoute>
              <DetailedExpress/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/warehouse",
          element: (
            <UseAuthRoute>
              <Express title="WARE_HOUSE"/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/freightforwarding",
          element: (
            <UseAuthRoute>
              <Express title="FREIGHT_FORWARDING"/>
            </UseAuthRoute>
          ),
        },
        {
          path: "/landfreight",
          element: (
            <UseAuthRoute>
              <Express title="LAND_FREIGHT" />
            </UseAuthRoute>
          ),
        },
        {
          path: "/customermanagement",
          element: (
            <UseAuthRoute>
              <Management />
            </UseAuthRoute>
          ),
        },
        {
          path: "/customermanagement/:id",
          element: (
            <UseAuthRoute>
              <DetailedManagement />
            </UseAuthRoute>
          )
        }
      ],
    },
  ]);
  return <>{routes}</>;
};
