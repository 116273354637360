import React from 'react';
import toast from 'react-hot-toast';

// Create a custom toast component
const showToast = (message, type = 'default', options = {}) => {
  const defaultOptions = {
    icon: '👏',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
    },
    ...options,
  };

  switch (type) {
    case 'success':
      toast.success(message, defaultOptions);
      break;
    case 'error':
      toast.error(message, defaultOptions);
      break;
    case 'loading':
      toast.loading(message, defaultOptions);
      break;
    default:
      toast(message, defaultOptions);
      break;
  }
};

export default showToast;