import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
const VehicleModal = ({
  onSuccess,
  onError,
  showModal,
  setShowModal,
  title,
  vehiclebyCode,
  isedited,
  SetIsEdited,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postVehicles = usevehicleStore((state) => state?.postVehicles);
  const editVehicles = usevehicleStore((state) => state?.editVehicles);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const VehicleTypes = usevehicleStore((state) => state?.VehicleTypes);
  const getVehicleVendor = usevehicleStore((state) => state?.getVehicleVendor);
  const getVehicleCategory = usevehicleStore(
    (state) => state?.getVehicleCategory
  );
  const getGpsvendor = usevehicleStore((state) => state?.getGpsvendor);
  const vehicleReplacementList = usevehicleStore(
    (state) => state?.vehicleReplacementList
  );
  const vehicleVendorList = usevehicleStore(
    (state) => state?.vehicleVendorList
  );
  const vehicleCategoryList = usevehicleStore(
    (state) => state?.vehicleCategoryList
  );
  const VehicleCouriersList = usevehicleStore(
    (state) => state?.VehicleCouriersList
  );
  const VehicleDimesionUnits = usevehicleStore(
    (state) => state?.VehicleDimesionUnits
  );
  const vehicleGpsvendorList = usevehicleStore(
    (state) => state?.vehicleGpsvendorList
  );
  const VehicleStatusDoclist = usevehicleStore(
    (state) => state?.VehicleStatusDoclist
  );
  const countriesData = useAoneStore((state) => state?.countriesData);
  const vehicleList = usevehicleStore((state) => state?.vehicleList);
  const VehicleWeightunit = usevehicleStore(
    (state) => state?.VehicleWeightunit
  );
  const [resetKey, setResetKey] = useState(0);
  const currentYear = new Date().getFullYear(); // Get the current year
  const [years, setYears] = useState(
    Array.from({ length: 100 }, (_, i) => ({
      value: currentYear - i,
      label: currentYear - i,
    }))
  );
  const Vehicleoptions = VehicleCouriersList?.data?.data?.map((item) => ({
    label: Object.values(item)[0], // Assuming the name is always the first property value
    value: Object.keys(item)[0], // Assuming the employee code is always the first property key
  }));
  const [odoValue, setOdoValue] = useState("");
  const addVehicle = useFormik({
    initialValues: {
      code: "",
      plateNumber: "",
      type: "",
      hubCode: "",
      weightCapacity: "",
      weightUnit: "",
      volumeCapacity: "",
      dimensionUnit: "",
      vendorCode: "",
      categoryCode: "",
      model: "",
      manufactureYear: "",
      employeeCode: "",
      hasGPS: false,
      gpsVendorCode: "",
      vehicleStatus: "",
      countryCode: "",
      replacement: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .trim()
        .required("Code is required")
        .matches(/^[a-zA-Z0-9\s_-]*$/, "Code must not contain special characters")
        .max(50, "Code must be at most 50 characters long"),
      plateNumber: Yup.string()
        .trim()
        .required("Plate Number is required")
        .matches(
          /^[a-zA-Z0-9\s_-]*$/,
          "Plate Number must not contain special characters"
        )
        .max(15, "Plate Number must be at most 15 characters long"),
      type: Yup.string().required("Type is required"),
      weightCapacity: Yup.number()
        .typeError("Weight Capacity must be a number")
        .max(99999, "Weight Capacity must be less than 100000"),
      volumeCapacity: Yup.number().typeError(
        "Volume Capacity must be a number"
      ),
      manufactureYear: Yup.string().required("Manufacture year is required"),
      countryCode: Yup.string().required("Country is required"),
      vehicleStatus: Yup.string().required("Vehicle Status is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { ...postData } = values;
      const valuestoSend = { ...values };
      if (valuestoSend.hubCode === "") {
        valuestoSend.hubCode = null;
      }
      if (valuestoSend.weightCapacity === "") {
        valuestoSend.weightCapacity = null;
      }
      if (valuestoSend.weightUnit === "") {
        valuestoSend.weightUnit = null;
      }
      if (valuestoSend.volumeCapacity === "") {
        valuestoSend.volumeCapacity = null;
      }
      if (valuestoSend.dimensionUnit === "") {
        valuestoSend.dimensionUnit = null;
      }
      if (valuestoSend.vendorCode === "") {
        valuestoSend.vendorCode = null;
      }
      if (valuestoSend.categoryCode === "") {
        valuestoSend.categoryCode = null;
      }
      if (valuestoSend.model === "") {
        valuestoSend.model = null;
      }
      if (valuestoSend.employeeCode === "") {
        valuestoSend.employeeCode = null;
      }
      if (valuestoSend.replacement === "") {
        valuestoSend.replacement = null;
      }
      if (title === "Edit Vehicle") {
        postData.id = vehiclebyCode.id;
        await editVehicles(vehiclebyCode.code, valuestoSend).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Vehicle updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response.status == 200) {
            toast.success("Vehicle Updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      } else {
        await postVehicles(valuestoSend).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Vehicle has been added");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      }
    },
  });
  useEffect(() => {
    const selectedYear = addVehicle?.values?.manufactureYear;
    if (selectedYear) {
      setYears((prevYears) => [
        ...prevYears,
        { value: selectedYear, label: selectedYear },
      ]);
    }
  }, [addVehicle.values.manufactureYear]);
  useEffect(() => {
    addVehicle.setFieldValue("countryCode", vehiclebyCode?.countryCode);
    addVehicle.setFieldValue("code", vehiclebyCode?.code);
    addVehicle.setFieldValue("plateNumber", vehiclebyCode?.plateNumber);
    addVehicle.setFieldValue("type", vehiclebyCode?.type);
    addVehicle.setFieldValue("hubCode", vehiclebyCode?.hubCode);
    addVehicle.setFieldValue("weightCapacity", vehiclebyCode?.weightCapacity);
    addVehicle.setFieldValue("weightUnit", vehiclebyCode?.weightUnit);
    addVehicle.setFieldValue("volumeCapacity", vehiclebyCode?.volumeCapacity);
    addVehicle.setFieldValue("dimensionUnit", vehiclebyCode?.dimensionUnit);
    addVehicle.setFieldValue("vendorCode", vehiclebyCode?.vendorCode);
    addVehicle.setFieldValue("categoryCode", vehiclebyCode?.categoryCode);
    addVehicle.setFieldValue("model", vehiclebyCode?.model);
    addVehicle.setFieldValue("manufactureYear", vehiclebyCode?.manufactureYear);
    addVehicle.setFieldValue("employeeCode", vehiclebyCode?.employeeCode);
    addVehicle.setFieldValue("hasGPS", vehiclebyCode?.hasGPS);
    addVehicle.setFieldValue(
      "gpsVendorCode",
      vehiclebyCode?.["gpsVendor"]?.["code"]
    );
    addVehicle.setFieldValue("vehicleStatus", vehiclebyCode?.vehicleStatus);
    addVehicle.setFieldValue("replacement", vehiclebyCode?.replacement);
    setOdoValue(vehiclebyCode?.odometer);
    if(vehiclebyCode?.countryCode !== undefined) {
    let paramslink = `?page=${0}&size=${30}&countryCode=${
      vehiclebyCode?.countryCode
    }`;
    getVehicleVendor(paramslink);
    getVehicleCategory(paramslink);
    getGpsvendor(paramslink);
  }
  }, [title, vehiclebyCode]);
  useEffect(() => {
    if (title == "Add Vehicle") {
      addVehicle.setFieldValue("code", "");
      addVehicle.setFieldValue("plateNumber", "");
      addVehicle.setFieldValue("type", "");
      addVehicle.setFieldValue("hubCode", "");
      addVehicle.setFieldValue("weightCapacity", "");
      addVehicle.setFieldValue("weightUnit", "");
      addVehicle.setFieldValue("volumeCapacity", "");
      addVehicle.setFieldValue("dimensionUnit", "");
      addVehicle.setFieldValue("vendorCode", "");
      addVehicle.setFieldValue("categoryCode", "");
      addVehicle.setFieldValue("model", "");
      addVehicle.setFieldValue("manufactureYear", "");
      addVehicle.setFieldValue("employeeCode", "");
      addVehicle.setFieldValue("hasGPS", "");
      addVehicle.setFieldValue("gpsVendorCode", "");
      addVehicle.setFieldValue("vehicleStatus", "");
      addVehicle.setFieldValue("countryCode", "");
      addVehicle.setFieldValue("replacement", "");
    }
  }, [title, vehiclebyCode]);
  const [isOpen, setIsopen] = useState(false);
  useEffect(() => {
    if (title !== "Edit Vehicle") {
      addVehicle.setFieldValue("categoryCode", "");
      addVehicle.setFieldValue("vendorCode", "");
      addVehicle.setFieldValue("gpsVendorCode", "");
    }
  }, [addVehicle.values.countryCode]);
  const [vehicleCodeErr, setVehicleCodeErr] = useState("");
  const [vehiclePlateErr, setVehiclePlateErr] = useState("");
  const [vehicleGpsErr, setVehicleGpsErr] = useState("");
  const saveVehicle = (initialValues) => {
    if (/\s/g.test(addVehicle.values.code)) {
      setVehicleCodeErr(
        "Code cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/^\s|\s$/.test(addVehicle.values.plateNumber)) {
      setVehiclePlateErr(
        "Plate Number cannot cannot contain leading or trailing spaces"
      );
    } else if (
      addVehicle?.values?.hasGPS &&
      addVehicle?.values?.gpsVendorCode === ""
    ) {
      setVehicleGpsErr("Please select GPS Vendor");
    } else {
      setVehicleCodeErr("");
      setVehiclePlateErr("");
      setVehicleGpsErr("");
      addVehicle?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehiclespopup">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Code
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Code"
                      value={addVehicle.values.code}
                      onChange={(e) => {
                        addVehicle.setFieldValue("code", e.target.value);
                        setVehicleCodeErr("");
                      }}
                      name="code"
                      disabled={title == "Edit Vehicle"}
                      className="mainInput"
                    />
                  </div>
                  {addVehicle.touched.code &&
                  addVehicle.errors.code &&
                  vehicleCodeErr == "" ? (
                    <p className="requiredText">{addVehicle.errors.code}</p>
                  ) : null}
                  {vehicleCodeErr !== "" ? (
                    <p className="requiredText">{vehicleCodeErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Plate Number
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Plate Number"
                      value={addVehicle.values.plateNumber}
                      onChange={(e) => {
                        addVehicle.setFieldValue("plateNumber", e.target.value);
                        setVehiclePlateErr("");
                      }}
                      name="plateNumber"
                      className="mainInput"
                    />
                  </div>
                  {addVehicle.touched.plateNumber &&
                  addVehicle.errors.plateNumber &&
                  vehiclePlateErr === "" ? (
                    <p className="requiredText">
                      {addVehicle.errors.plateNumber}
                    </p>
                  ) : null}
                  {vehiclePlateErr !== "" ? (
                    <p className="requiredText">{vehiclePlateErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Type
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="type"
                      label="type"
                      name="type"
                      options={VehicleTypes?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "type",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.type
                          ? VehicleTypes?.data?.data?.find(
                              (status) =>
                                status.key === addVehicle?.values?.type
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Type"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addVehicle.touched.type && addVehicle.errors.type ? (
                    <p className="requiredText">{addVehicle.errors.type}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={async (selectedOption) => {
                        addVehicle.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                        let paramslink = `?page=${0}&size=${30}&countryCode=${
                          selectedOption?.isoCode3
                        }`;
                        getVehicleVendor(paramslink);
                        getVehicleCategory(paramslink);
                        getGpsvendor(paramslink);
                      }}
                      value={
                        addVehicle?.values?.countryCode
                          ? countriesData?.data?.data?.content.find(
                              (option) =>
                                option.isoCode3 ===
                                addVehicle?.values?.countryCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addVehicle.touched.countryCode &&
                    addVehicle.errors.countryCode ? (
                      <p className="requiredText">
                        {addVehicle.errors.countryCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Hub
                    </label>
                    <SearchInput
                      id="hubCode"
                      label="hubCode"
                      name="hubCode"
                      options={hubsData?.data?.data?.content || []}
                      getOptionLabel={(option) =>
                        `${option.code} - ${option.name}`
                      }
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "hubCode",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.hubCode
                          ? hubsData?.data?.data?.content?.find(
                              (hub) => hub.code === addVehicle?.values?.hubCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Hub"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addVehicle.touched.hubCode && addVehicle.errors.hubCode ? (
                    <p className="requiredText">{addVehicle.errors.hubCode}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Weight Capacity
                    </label>
                    <input
                      type="text"
                      placeholder="Weight Capacity"
                      value={addVehicle.values.weightCapacity}
                      onChange={(e) => {
                        addVehicle.setFieldValue(
                          "weightCapacity",
                          e.target.value
                        );
                      }}
                      name="weightCapacity"
                      className="mainInput"
                    />
                  </div>
                  {addVehicle.touched.weightCapacity &&
                  addVehicle.errors.weightCapacity ? (
                    <p className="requiredText">
                      {addVehicle.errors.weightCapacity}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Weight Unit
                    </label>
                    <SearchInput
                      id="weightUnit"
                      label="weightUnit"
                      name="weightUnit"
                      options={VehicleWeightunit?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "weightUnit",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.weightUnit
                          ? VehicleWeightunit?.data?.data?.find(
                              (status) =>
                                status.key === addVehicle?.values?.weightUnit
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Weight Unit"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addVehicle.touched.weightUnit &&
                  addVehicle.errors.weightUnit ? (
                    <p className="requiredText">
                      {addVehicle.errors.weightUnit}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Volume Capacity
                    </label>
                    <input
                      type="text"
                      placeholder="Volume Capacity"
                      value={addVehicle.values.volumeCapacity}
                      onChange={(e) => {
                        addVehicle.setFieldValue(
                          "volumeCapacity",
                          e.target.value
                        );
                      }}
                      name="volumeCapacity"
                      className="mainInput"
                    />
                  </div>
                  {addVehicle.touched.volumeCapacity &&
                  addVehicle.errors.volumeCapacity ? (
                    <p className="requiredText">
                      {addVehicle.errors.volumeCapacity}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Dimension Unit
                    </label>
                    <SearchInput
                      id="dimensionUnit"
                      label="dimensionUnit"
                      name="dimensionUnit"
                      options={VehicleDimesionUnits?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "dimensionUnit",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.dimensionUnit
                          ? VehicleDimesionUnits?.data?.data?.find(
                              (status) =>
                                status.key === addVehicle?.values?.dimensionUnit
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Dimension Unit"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Vendor
                    </label>
                    <SearchInput
                      id="vendorCode"
                      label="vendorCode"
                      name="vendorCode"
                      options={vehicleVendorList?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "vendorCode",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.vendorCode
                          ? vehicleVendorList?.data?.data?.content?.find(
                              (vendor) =>
                                vendor.code === addVehicle?.values?.vendorCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Vendor"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Category
                    </label>
                    <SearchInput
                      id="categoryCode"
                      label="categoryCode"
                      name="categoryCode"
                      options={vehicleCategoryList?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "categoryCode",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.categoryCode
                          ? vehicleCategoryList?.data?.data?.content?.find(
                              (status) =>
                                status.code === addVehicle?.values?.categoryCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Category"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Model
                    </label>
                    <input
                      type="text"
                      placeholder="Model"
                      value={addVehicle.values.model}
                      onChange={(e) => {
                        addVehicle.setFieldValue("model", e.target.value);
                      }}
                      name="model"
                      className="mainInput"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Manufacture Year
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="manufactureYear"
                      label="manufactureYear"
                      name="manufactureYear"
                      options={years}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "manufactureYear",
                          selectedOption ? selectedOption?.value : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.manufactureYear
                          ? years?.find(
                              (years) =>
                                years.value ===
                                addVehicle?.values?.manufactureYear
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Manufacture Year"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addVehicle.touched.manufactureYear &&
                  addVehicle.errors.manufactureYear ? (
                    <p className="requiredText">
                      {addVehicle.errors.manufactureYear}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Employee
                    </label>
                    <SearchInput
                      id="employeeCode"
                      label="employeeCode"
                      name="employeeCode"
                      options={Vehicleoptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "employeeCode",
                          selectedOption ? selectedOption?.value : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.employeeCode
                          ? Vehicleoptions?.find(
                              (option) =>
                                option.value ===
                                addVehicle?.values?.employeeCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Employee"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Vehicle Status
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="vehicleStatus"
                      label="vehicleStatus"
                      name="vehicleStatus"
                      options={VehicleStatusDoclist?.data?.data}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "vehicleStatus",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.vehicleStatus
                          ? VehicleStatusDoclist?.data?.data?.find(
                              (option) =>
                                option.key === addVehicle?.values?.vehicleStatus
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Vehicle Status"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addVehicle.touched.vehicleStatus &&
                    addVehicle.errors.vehicleStatus ? (
                      <p className="requiredText">
                        {addVehicle.errors.vehicleStatus}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Replacement Vehicle
                    </label>
                    <SearchInput
                      id="replacement"
                      label="replacement"
                      name="replacement"
                      options={vehicleReplacementList?.data?.data}
                      getOptionLabel={(option) => option.plateNumber}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "replacement",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addVehicle?.values?.replacement
                          ? vehicleReplacementList?.data?.data?.find(
                              (option) =>
                                option.code === addVehicle?.values?.replacement
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Replacement Vehicle"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              {title === "Edit Vehicle" && (
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Odometer
                      </label>
                      <input
                        type="text"
                        placeholder="Odometer"
                        value={odoValue}
                        disabled
                        name="odoValue"
                        className="mainInput"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-3">
                <div className="row alignItemCenter mt_40">
                  <div className="col-md-12">
                    <div className="smallCheckbox col-md-12">
                      <input
                        type="checkbox"
                        id="hasGPS"
                        style={{ marginLeft: "5px" }}
                        checked={addVehicle.values.hasGPS}
                        onChange={() => {
                          addVehicle.setFieldValue(
                            "hasGPS",
                            !addVehicle.values.hasGPS
                          );
                          setVehicleGpsErr("");
                          addVehicle?.setFieldValue("gpsVendorCode", "");
                          setResetKey((prevKey) => prevKey + 1);
                        }}
                      />
                      <label htmlFor="hasGPS">Has GPS?</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      GPS Vendor
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="gpsVendorCode"
                      label="gpsVendorCode"
                      name="gpsVendorCode"
                      options={vehicleGpsvendorList?.data?.data?.content}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addVehicle.setFieldValue(
                          "gpsVendorCode",
                          selectedOption ? selectedOption?.code : ""
                        );
                        setVehicleGpsErr("");
                      }}
                      value={
                        addVehicle?.values?.gpsVendorCode
                          ? vehicleGpsvendorList?.data?.data?.content.find(
                              (option) =>
                                option.code ===
                                addVehicle?.values?.gpsVendorCode
                            )
                          : null
                      }
                      isDisabled={!addVehicle?.values?.hasGPS}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="GPS Vendor"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {vehicleGpsErr !== "" ? (
                      <p className="requiredText">{vehicleGpsErr}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveVehicle}>
            {title === "Add Vehicle" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default VehicleModal;
