import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useNavigate } from "react-router";
import { useAoneStore } from "../../../store/AoneStore";
import { useAclStore } from "../../../store/AclMgt/Aclstore";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import Dropdown from "react-bootstrap/Dropdown";
import downloadIcon from "../../../assets/img/download_icon.svg";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { roleHandler } from "../../../helper/roleHandler";
import CusModal from "../../../components/modal/CusModal";
import AclModal from "./AclModal"
import jwt_decode from "jwt-decode";
import Modal from "react-bootstrap/Modal";
import { DateRangePicker } from "rsuite";
const Acl = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(-1);
  const [zonepage, setZonePage] = useState(-1);
  const [desPage, setDesPage] = useState(-1);
  const [courierPage, setCourierPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [AclData, setConsignmentData] = useState([]);
  const [selectAcl, setSelectAcl] = useState([]);
  const [selectAclNum, setSelectAclNum] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [settleAcl, setSettleAcl] = useState([]);
  const [individualCheckboxesAcl, setIndividualCheckboxesAcl] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [ischangeTable, setIsChangeTable] = useState(false);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const [isChange, setIsChange] = useState(false);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [currentTimeMillis, setCurrentTimeMillis] = useState(null);
  const [eventTimeZone, setEventTimeZone] = useState("");
  useEffect(() => {
    const requestUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
          },
          (error) => {
            switch(error.code) {
              case error.PERMISSION_DENIED:
                alert("Please enable location services in your browser settings.");
                break;
              case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable. Please try again later.");
                break;
              case error.TIMEOUT:
                alert("The request to get user location timed out. Please try again.");
                break;
              case error.UNKNOWN_ERROR:
                alert("An unknown error occurred while requesting location. Please try again.");
                break;
              default:
                alert("An unknown error occurred while requesting location. Please try again.");
            }
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    };

    const currentDate = new Date();
    setCurrentTimeMillis(currentDate.getTime());

    const timeZoneOffset = currentDate.getTimezoneOffset();
    const offsetHours = -timeZoneOffset / 60;
    const formattedTimeZone = `GMT${offsetHours >= 0 ? "+" : ""}${offsetHours}`;

    const timeZoneMap = {
      "+3": "KSA +3",
      // Add more mappings for different offsets if needed
    };

    setEventTimeZone(timeZoneMap[formattedTimeZone] || formattedTimeZone);

    requestUserLocation();
  }, []);
  const [totalCount, setTotalCount] = useState(0);
  const productsData = useAoneStore((state) => state?.productsData);
  const [sortField, setSortField] = useState("-updatedAt");
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  //filters
  const [resetKey, setResetKey] = useState(0);
  const [aclNumber, setAclNumber] = useState("");
  const [sealNumber, setSealNumber] = useState("");
  const [type, setType] = useState("");
  const [originHub, setOriginHub] = useState("");
  const [destinationHub, setDestinationHub] = useState("");
  const [destinationZone, setDestinationZone] = useState("");
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [isDelete, setIsDelete] = useState("");
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCourier, setSelectedOptionCourier] = useState(null);
  const [selectedOptionHub, setSelectedOptionHub] = useState(null);
  const [selectedDesOption, setSelectedDesOption] = useState(null);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const getAcls = useAclStore((state) => state?.getAcls);
  const getAclTypes = useAclStore((state) => state?.getAclTypes);
  const getAclStatus = useAclStore((state) => state?.getAclStatus);
  const getWeightUnit = useAclStore((state) => state?.getWeightUnit);
  const assignAclDeliver = useAclStore((state) => state?.assignAclDeliver);
  const AclTypeList = useAclStore((state) => state?.AclTypeList);
  const receiveAclEvent = useAclStore((state) => state?.receiveAclEvent);
  const AclStatusList = useAclStore((state) => state?.AclStatusList);
  const AclWeightList = useAclStore((state) => state?.AclWeightList);
  const sealAcl = useAclStore((state) => state?.sealAcl);
  const unsealAcl = useAclStore((state) => state?.unsealAcl);
  const assignAcl = useAclStore((state) => state?.assignAcl);
  const exportAcl = useAclStore((state) => state?.exportAcl);
  const unAssignAcl = useAclStore((state) => state?.unAssignAcl);
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const filterZone = useAoneStore((state) => state.filterZone);
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const dateFilterOptions = [
    { label: "Created Date", key: "createdAt" },
    { label: "Last Updated Date", key: "updatedAt" },
  ];
  const [dateRangefil, setDateRangefil] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      setResetKey((prevKey) => prevKey + 1);
      setFilterDate("");
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  const [showorder, setShoworder] = useState(false);
  const handleShoworder = () => setShoworder(true);
  const handleCloseorder = () => {
    setShoworder(false);
    SetSealErr("");
  };
  const [showcourier, setShowcourier] = useState(false);
  const handleShowcourier = () => showcourier(true);
  const handleClosecourier = () => {
    setShowcourier(false);
    setCourierPage(-1);
    setShowSelectedCourier(false);
    setCourierErr("");
  };
  const [aclNumb,setAclNumb] = useState("");
  const [aclAssignee,setAclAssignee] = useState("");
  const [aclCourier,setAclCourier] = useState("");
  const [courierErr,setCourierErr] = useState("");
  const [showScan, setShowscan] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const handleassignShow = () => setShowAssign(true);
  const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
  const setReactCurrency = useAoneStore((state) => state?.setReactCurrency);
  const setisReactRatestate = useAoneStore(
    (state) => state?.setisReactRatestate
  );
  const handleassignClose = () => {
    setShowAssign(false);
  };
  const handleassignCancel = () => {
    setShowAssign(false);
  };
  const handlescanShow = () => setShowscan(true);
  const handlescanClose = () => {
    setShowscan(false);
  };
  const handlescanCancel = () => {
    setShowscan(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };
  const handleMenuClosedescity = () => {
    setReactDescity(false);
  };
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuState = () => {
    setisReactRatestate(true);
  };
  const handleMenuCloseState = () => {
    setisReactRatestate(false);
  };
  const handleMenuCurrency = () => {
    setReactCurrency(true);
  };
  const handleMenuCloseCurrency = () => {
    setReactCurrency(false);
  };
  const gridId = "ops.consolidations";
  const [column, SetCoulmn] = useState([
    "Acl Number",
    "Seal Number",
    "Trip Id",
    "Total Shipments",
    "Type",
    "Origin Hub",
    "Destination Hub",
    "Destination Zone",
    // "Route",
    "Assignee",
    "Assigned By",
    "Created At",
    "Created By",
    "Total Weight",
    "Weight Unit",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState(["Assigned By","Created At","Created By"]);
  useEffect(() => {
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  useEffect(() => {
    if (AclTypeList?.data?.data === undefined) {
      getAclTypes();
    }
    if (AclStatusList?.data?.data === undefined) {
      getAclStatus();
    }
    if (AclWeightList?.data?.data === undefined) {
      getWeightUnit();
    }
  }, []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown
  // Toggle dropdown
  const toggleDropdown = () => setIsDropdownOpen(prev => !prev);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    // Add event listener for clicks outside of dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const exporttoExcel = async () => {
    try {
      let payload = {
        aclNumbers: selectAclNum,
      };
      const res = await exportAcl(payload);
      if (res.status == 200) {
        fileDownload(res.data, `ACL.csv`);
        setSelectAcl([]);
        setSelectAclNum([]);
        setIsChangeTable(!ischangeTable);
      } else if (res?.response?.status === 500) {
        toast.error("Internal Server Error");
        setSelectAcl([]);
        setSelectAclNum([]);
        setIsChangeTable(!ischangeTable);
      } else {
        toast.error(res.response.statusText);
        setSelectAcl([]);
        setSelectAclNum([]);
        setIsChangeTable(!ischangeTable);
      }
    } catch (err) {}
};
  const requestUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
            resolve({ latitude, longitude });
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                alert("Please enable location services in your browser settings.");
                break;
              case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable. Please try again later.");
                break;
              case error.TIMEOUT:
                alert("The request to get user location timed out. Please try again.");
                break;
              default:
                alert("An unknown error occurred while requesting location. Please try again.");
            }
            reject(error);
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
        reject(new Error("Geolocation not supported"));
      }
    });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  //receive acl
  const handleReceiveAcl = () => {
    if (selectAcl.length < 1) {
      toast.error("Select ACL's with seal number to receive");
    } else {
      setShow(true);
    }
  };
  const textareaRef = useRef(null);
  const aclsString = selectAcl?.join("\n");
  useEffect(() => {
    const adjustHeight = () => {
      if (textareaRef.current) {
        // Reset the height first to recalculate the proper scroll height
        textareaRef.current.style.height = "auto";
        // Calculate height with 40% increase
        const newHeight = textareaRef.current.scrollHeight * 2.5;
        textareaRef.current.style.height = `${newHeight}px`;
      }
    };
    // Use setTimeout to ensure the height calculation happens after rendering
    setTimeout(adjustHeight, 0);
  }, [aclsString]);
  const applyReceiveAcl = async () => {
    const unsealedAcls = AclData
    ?.filter((_, index) => settleAcl[index])
    ?.filter((row) => !row.sealNumber);
  if (unsealedAcls.length > 0) {
    toast.error("All the selected ACL should be sealed before receiving");
    return;
  }
    if (selectAcl?.length < 1) {
      toast.error("Select ACL to perform any operation");
      return;
    }
    // Check if any selected ACL does not have a seal number
    if (latitude === null || longitude === null) {
      try {
        const position = await requestUserLocation();
        if (!position) {
          return; // Exit if location is not retrieved
        }
      } catch (error) {
        return; // Exit if there is an error in getting location
      }
    }
    if (!userCode) {
      toast.error("User code is missing for logged in user");
      return;
    }
    if (!userHub) {
      toast.error("User hub is missing for logged in user");
      return;
    }
    try {
      const events = {
        eventCode: 407,
        longitude: longitude,
        latitude: latitude,
        employeeCode: userCode,
        hubCode: userHub,
        aclNumbers: selectAcl
      };
      const res = await receiveAclEvent(events);
      if (res?.data?.status === "SUCCESS") {
        toast.success("ACL received");
        setIsDropdownOpen(false);
        setispostAdded(!ispostAdded);
        setSelectAcl([]);
        setShow(false);
        setIsChangeTable(!ischangeTable);
      } else if (res?.data?.status == "ERROR") {
        const errorMessage = res?.data?.msg;
        toast.error(`${errorMessage}`);
        setIsDropdownOpen(false);
        setShow(false);
        setSelectAcl([]);
        setIsChangeTable(!ischangeTable);
      } else if (res?.response?.data?.errors?.length > 0) {
        const errorMessages = res?.response?.data?.errors
          ?.map((item) => `${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
        setIsDropdownOpen(false);
        setSelectAcl([]);
        setShow(false);
        setIsChangeTable(!ischangeTable);
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
        setIsDropdownOpen(false);
        setSelectAcl([]);
        setShow(false);
        setIsChangeTable(!ischangeTable);
      } else {
        const errorMessage = res?.data?.msg.split(",")[0];
        toast.error(errorMessage);
        setIsDropdownOpen(false);
        setSelectAcl([]);
        setShow(false);
        setIsChangeTable(!ischangeTable);
      }
    } catch (error) {
      toast.error("Error in applying the consignment.");
    }
  };
  const [prevSearch, setPrevSearch] = useState("");
  const [aclNum,setAclNum] = useState("");
  const [sealNumb,setSealNum] = useState("");
  const [sealErr,SetSealErr] = useState("")
  const sealAclNum = async () => {
  if(!sealNumb){
    SetSealErr("Seal number is required")
  }else{
    const res = await sealAcl(aclNum,sealNumb);
    if(res?.data?.status == "ERROR"){
      toast.error(res?.data?.msg)
      setShoworder(false);
    }else if(res?.data?.status == "SUCCESS"){
      toast.success("ACL Sealed")
      setShoworder(false);
      getData();
    }
  }
 }
 const unScanAclNumber = async () => {
  const res = await unsealAcl(aclNum);
  if(res?.data?.status == "ERROR"){
    toast.error(res?.data?.msg)
    setShowscan(false);
  }else if(res?.data?.status == "SUCCESS"){
    toast.success("ACL Unsealed")
    setShowscan(false);
    getData();
  }
}
const unAssignCourier = async () => {
  const res = await unAssignAcl(aclNum);
  if(res?.data?.status == "ERROR"){
    toast.error(res?.data?.msg)
    setShowAssign(false);
  }else if(res?.data?.status == "SUCCESS"){
    toast.success(`Courier unassigned from ${aclNum}`)
    setShowAssign(false);
    getData();
  }
}
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? zonepage + 1 : zonepage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setZonePage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await filterZone(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
            name: zone.name,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, zonepage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsDesGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : desPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDesPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desPage, prevSearch, setPrevSearch]
  );
  const [userHub,setUserHub] = useState("")
  const [userCode,setUserCode] = useState("")
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      setUserHub(decoded?.attributes?.hubCode || "");
      setUserCode(decoded?.attributes?.employeeCode || "");
    }
  }, []);
  const loadMoreOptionsCouriers = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&code=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub,loadingMore, courierPage, prevSearch, setPrevSearch]
  );
  const [isCourierSelect,setIsCourierSelect] = useState(false)
  //ASSIGN COURIER TO ACL
  const assignModal = (acl,emp) => {
    setShowcourier(true);
    setAclNumb(acl);
    setAclCourier(emp)
    setIsCourierSelect(true);
  };
  const [showSelectedCourier, setShowSelectedCourier] = useState(false);
  useEffect(()=>{
    if((aclCourier !== null ) && isCourierSelect){
      setShowSelectedCourier(true)
    }
  },[aclCourier,isCourierSelect])
  const handleCourierClick = () => {
    setShowSelectedCourier(false);
  };
  const assignACLCourier = async ()=>{
    if(aclCourier == "" || aclCourier == null){
      setCourierErr("Courier is required")
      setCourierPage(-1);
    }else{
      let payload={
        aclNumber: aclNumb,
        employeeCode: aclCourier
      }
    const res = await assignAclDeliver(payload)
    if(res?.data?.status == "ERROR" && res?.data?.errors?.length == undefined){
      toast.error(res?.data?.msg)
      setShowcourier(false);
      setCourierPage(-1);
      setAclNumb("");
      setAclCourier("");
    } else if (res?.data?.errors?.length > 0) {
      const errorMessages = res?.data?.errors
        .map((item) => `${item.key}: ${item.msg}`)
        .join(", ");
      toast.error(`${errorMessages}`);
      setShowcourier(false);
      setCourierPage(-1);
      setAclNumb("");
      setAclCourier("");
    }else if(res?.data?.status == "SUCCESS"){
      toast.success("Courier assigned to ACL")
      setShowcourier(false);
      setCourierPage(-1);
      getData();
      setAclNumb("");
      setAclCourier("");
    }
    }
  }
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (aclNumber) {
      params += `&number=${aclNumber}`;
    }
    if (sealNumber) {
      params += `&sealNumber=${sealNumber}`;
    }
    if (type) {
      params += `&type=${type}`;
    }
    if (originHub) {
      params += `&originHubCode=${originHub}`;
    }
    if (destinationHub) {
      params += `&destinationHubCode=${destinationHub}`;
    }
    if (destinationZone) {
      params += `&destinationZoneCode=${destinationZone}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (filterDate) {
      params += `&filterDate=${filterDate}`;
      if (dateRangefil?.start) {
        params += `&startTime=${dateRangefil?.start}`;
      }
      if (dateRangefil?.end) {
        params += `&endTime=${dateRangefil?.end}`;
      }
    }
    const data = await getAcls(params);
    setTotalCount(data.data.data.totalElements);
    setConsignmentData(data.data.data.content);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    saveHandler,
    ispostAdded,
    aclNumber,
    sealNumber,
    type,
    dateRangefil,
    status,
    originHub,
    destinationHub,
    destinationZone,
  ]);
  const handleEdit = (code) => {
    setAclbyCode(code);
    setTitle("Edit ACL");
    setShowModal(true);
  };
  const [aclByCode, setAclbyCode] = useState([]);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };
  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/consolidations")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/consolidations")}
              >
                Consolidations
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
              >
                Acls
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
            <Row>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                  ACL Number:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={aclNumber}
                    className={
                      aclNumber?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      aclNumber?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setAclNumber(e.target.value)}
                  />
                     {aclNumber && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setAclNumber("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                </div>
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                  Seal Number:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={sealNumber}
                    className={
                      sealNumber?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      sealNumber?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setSealNumber(e.target.value)}
                  />
                {sealNumber && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setSealNumber("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                </div>
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Type:
                </label>
                <SearchInput
                  key={resetKey}
                  id="type"
                  label="type"
                  name="type"
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isReactselectOpen}
                  options={AclTypeList?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    if (selectedOption) {
                      setType(selectedOption?.key);
                    } else {
                      setType(null);
                    }
                  }}
                  isSearchable={true}
                  placeholder="Type"
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              {/* <Col className="col-13-5-percent">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Status:
                </label>
                <SearchInput
                  key={resetKey}
                  id="status"
                  label="status"
                  name="status"
                  onMenuOpen={handleMenudescountry}
                  onMenuClose={handleMenuClosedescountry}
                  menuIsOpen={isReactdesCountry}
                  options={AclStatusList?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  customStyles={{ control: { width: "170px" } }}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    if (selectedOption) {
                      setStatus(selectedOption?.key);
                    } else {
                      setStatus(null);
                    }
                  }}
                  isSearchable={true}
                  placeholder="Status"
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col> */}
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Origin Hub:
                </label>
                <AsyncSearchInput
                  id="originHub"
                  label="originHub"
                  name="originHub"
                  value={selectedOption}
                  loadMoreOptions={loadMoreOptionsGroups}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    setOriginHub(
                      selectedOption ? selectedOption.value : ""
                    );
                  }}
                  placeholder="Origin Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Destination Hub:
                </label>
                <AsyncSearchInput
                  id="destinationHub"
                  label="destinationHub"
                  name="destinationHub"
                  value={selectedOptionHub}
                  loadMoreOptions={loadMoreOptionsDesGroups}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenorgcity}
                  onMenuClose={handleMenuCloseorgcity}
                  menuIsOpen={isReactoriginselectCity}
                  onChange={(selectedOptionHub) => {
                    setSelectedOptionHub(selectedOptionHub);
                    setDestinationHub(
                      selectedOptionHub ? selectedOptionHub.value : ""
                    );
                  }}
                  placeholder="Destination Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              </Col>
              <Col className="col-20-percent" md={3}>
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Destination Zone:
                </label>
                <AsyncSearchInput
                  id="destinationZone"
                  label="destinationZone"
                  name="destinationZone"
                  value={selectedDesOption}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenudescity}
                  onMenuClose={handleMenuClosedescity}
                  menuIsOpen={isReactdesCity}
                  onChange={(selectedDesOption) => {
                    setSelectedDesOption(selectedDesOption);
                    setDestinationZone(
                      selectedDesOption ? selectedDesOption.value : ""
                    );
                  }}
                  placeholder="Destination Zone"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              </Col>
              <Col className="col-20-percent" md={3}>
            <label className="fontSize12 mb_12 marginR10 mt_10">
              Date Filter:
            </label>
            <SearchInput
              key={`dateFilter-${resetKey}`}
              id="dateFilter"
              label="dateFilter"
              name="dateFilter"
              options={dateFilterOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.key}
              onMenuOpen={handleMenuCurrency}
              onMenuClose={handleMenuCloseCurrency}
              menuIsOpen={isReactCurrency}
              handleChange={(selectedOption) => {
                setFilterDate(selectedOption ? selectedOption.key : "");
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Select Date Filter"
              className={"mainInput-dropdown"}
            />
          </Col>
          <Col className="col-20-percent" md={3}>
            <label className="fontSize12 mb_12 marginR10 mt_10">Date:</label>
            <DateRangePicker
              format="yyyy-MM-dd"
              placeholder="Select Date"
              value={dateRange}
              onChange={handleDateRangeChange}
              placement="auto"
              minDate={new Date(new Date().setMonth(new Date().getMonth() - 3))}
              onOpen={handleMenuState}
              onClose={handleMenuCloseState}
              showMeridian
              className="w200"
            />
          </Col>
            </Row>
        <div className="displayFlex alignItemCenter justifyContent_end">
          <div className="displayFlex alignItemCenter justifyContent_end mb_8">
           {(roleHandler(role, "AONE_OPS.admin")) && (
              <div
                class="jira-dropdown"
                style={{ marginLeft: "20px" }}
                ref={dropdownRef} 
              >
                {!isReactRates && (
                  <button
                    className="jira-dropdown-button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    Bulk Action
                  </button>
                )}
                <ul
                  className="jira-dropdown-content"
                  style={{
                    display: isDropdownOpen ? "block" : "none",
                    borderRadius: "10px",
                  }}
                >
                    <li onClick={handleReceiveAcl}>
                      <a>Receive</a>
                    </li>
                </ul>
              </div>
            )}
            {selectAclNum?.length > 0 &&
             <button
              className="filterBtn plus_icon mleft_10"
              title="Export"
              onClick={() => exporttoExcel()}
            >
              <span>
                <img src={downloadIcon} className="h18" />
              </span>
            </button>}
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
            {(roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_ACL.write") ||
              roleHandler(role, "AONE_OPS_ACL.admin")) && (
              <button
                className="filterBtn plus_icon mleft_10"
                title="Add"
                tabIndex="-1"
                onClick={() => {
                  setShowModal(true);
                  setTitle("Create new ACL");
                }}
              >
                +
              </button>
            )}
            <div ref={ref} className="customFilter mleft_5p">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={DotboldIcon} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ul className="listColumn cursorPointer">
                      {column.map((d, i) => (
                        <li key={d}>
                          <div className="smallCheckbox mb_4">
                            <input
                              id={`product${i}`}
                              type="checkbox"
                              checked={!checkedCol.includes(d)}
                            />
                            <label
                              htmlFor={`product${i}`}
                              onClick={() => handleSelect(d)}
                            >
                              {d}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <PaginationTable
          tableData={AclData}
          tbl={"ACL"}
          restrict={checkedCol}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          handleAclEdit={handleEdit}
          assignAclModal={assignModal}
          size={size}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setAclNum={setAclNum}
          setSealNum={setSealNum}
          setShoword={setShoworder}
          isDropdownOpens={isDropdownOpen}
          setShowAssign={setShowAssign}
          setAclAssignee={setAclAssignee}
          handlescanShow={handlescanShow}
          setIndividualCheckboxesAcl={setIndividualCheckboxesAcl}
          individualCheckboxesAcl={individualCheckboxesAcl}
          selectAcl={selectAcl}
          setSelectAcl={setSelectAcl}
          selectAllAcl={selectAll}
          setSelectAllAcl={setSelectAll}
          settleAcl={settleAcl}
          setSettleAcl={setSettleAcl}
          selectAclNum={selectAclNum}
          setSelectAclNum={setSelectAclNum}
          ischangeAclTable={ischangeTable}
          setSize={setSize}
          totalCount={totalCount}
          setSortField={setSortField}
          sortField={sortField}
          statusHandler={statusHandler}
          setStatusHandler={setStatusHandler}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
        <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={"addVehicleCateg"}
        >
          <AclModal
             onSuccess={handleSuccess}
             onError={handleError}
             showModal={showModal}
             setShowModal={setShowModal}
             title={title}
             aclByCode={aclByCode}
          />
        </CusModal>
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Do you want to receive the selected ACL's ?
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-12">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    ACL Numbers
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <textarea
                      ref={textareaRef}
                      className="washMe_btn"
                      style={{ paddingTop: "6px", height: "90px" }}
                      disabled
                      value={aclsString}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={() => applyReceiveAcl()}
                >
                  Receive
                </button>
              </div>
              <div>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
        {showorder && (
        <Modal
          show={showorder}
          className="addnnewWeighing"
          onHide={handleCloseorder}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Seal
            </h4>
          </Modal.Header>
          <div className="modelScrollVehicleVendors">
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Seal Number:
                    </label>
                    <input
                      type="text"
                      className="mainInput"
                      name="sealNumb"
                      value={sealNumb}
                      onChange={(e)=>setSealNum(e?.target?.value)}
                    />
                    {sealErr !== "" ? (
                      <p className="requiredText">{sealErr}</p>
                    ) : null}
                  </div>
                </div>
              </form>
              <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
                <div>
                  <button
                     type="button"
                     className="blue_btn"
                     onClick={sealAclNum}
                  >
                    Save
                  </button>
                </div>
                <div>
                  <button
                    className="cancel_btn"
                    onClick={() => handleCloseorder()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
      {showScan &&
      <Modal
          className="scanConfirmpopup"
          show={showScan}
          onHide={handlescanClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
              Unseal the ACL?
            </h2>
            <>
              <div className="displayFlex alignItemCenter justifyContent_center">
                <ul className="emptyUnorderList mb_12">
                    <li
                      className="fontSize14 fontWeight400 blackText"
                    >
                      - {aclNum}
                    </li>
                </ul>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <div>
              <button
                className="deletebtn cancelPrimary"
                onClick={() => handlescanCancel()}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="deletebtn confirmPrimary"
                onClick={unScanAclNumber}
              >
                Unseal
              </button>
            </div>
          </Modal.Footer>
        </Modal>}
        {showAssign &&
      <Modal
          className="scanConfirmpopup"
          show={showAssign}
          onHide={handleassignClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
              Unassign the courier from ACL?
            </h2>
            <>
              <div className="displayFlex alignItemCenter justifyContent_center">
                <ul className="emptyUnorderList mb_12">
                    <li
                      className="fontSize14 fontWeight400 blackText"
                    >
                      -do you want to unassign {aclAssignee} from {aclNum}?
                    </li>
                </ul>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <div>
              <button
                className="deletebtn cancelPrimary"
                onClick={() => handleassignCancel()}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="deletebtn confirmPrimary"
                onClick={unAssignCourier}
              >
                Unassign
              </button>
            </div>
          </Modal.Footer>
        </Modal>}
        {showcourier && (
        <Modal
          show={showcourier}
          className="addnnewWeighing"
          onHide={handleClosecourier}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Assign ACL to courier
            </h4>
          </Modal.Header>
          <div className="modelScrollVehicleVendors">
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      ACL Number:
                    </label>
                    <input
                      type="text"
                      className="mainInput"
                      name="courierName"
                      value={aclNumb}
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Courier:
                    </label>
                    {!showSelectedCourier ? (
                    <AsyncSearchInput
                  id="aclCourier"
                  label="aclCourier"
                  name="aclCourier"
                  value={selectedOptionCourier}
                  loadMoreOptions={loadMoreOptionsCouriers}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(employee) => employee.label}
                  getOptionValue={(employee) => employee.value}
                  onChange={(selectedOptionCourier) => {
                    setCourierErr("");
                    setIsCourierSelect(false);
                    setSelectedOptionCourier(selectedOptionCourier);
                    setAclCourier(
                      selectedOptionCourier ? selectedOptionCourier.value : ""
                    );
                  }}
                  placeholder="Courier"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              ) : (
                <input
                  type="text"
                  value={aclCourier}
                  name="aclCourier"
                  className="mainInput"
                  onClick={handleCourierClick}
                />
              )}
                      {courierErr !== "" ? (
                      <p className="requiredText">{courierErr}</p>
                    ) : null}
                  </div>
                </div>
              </form>
              <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
                <div>
                  <button
                    className="cancel_btn"
                    onClick={() => handleClosecourier()}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="blue_btn"
                    onClick={assignACLCourier}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
      </div>
    </div>
  );
};

export default Acl;
