import React, { useState, useEffect, useRef, useContext } from "react";
import PaginationTable from "../../components/Tables/paginationTable";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import OpenModal from "../../components/modal/openModal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moveicon from "../../assets/img/move_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import outscanicon from "../../assets/img/outscan.svg";
import inscanicon from "../../assets/img/receive.svg";
import downloadIcon from "../../assets/img/download_icon.svg";
import labelIcon from "../../assets/img/label_icon.svg";
import templateIcon from "../../assets/img/template_icon.svg";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import importIcon from "../../assets/img/import_file.svg";
import * as XLSX from "xlsx";
import { DateRangePicker } from "rsuite";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { UserContext } from "../../helper/ProtectedRoute";
const Bags = () => {
  const navigate = useNavigate();
  const paramsId = useParams();
  const location = useLocation();
  const [headerParams, setHeaderParams] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [editHandler, setEditHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [isInscan,setIsinscan] = useState(false)
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [seleczone, setselectZone] = useState([]);
  const [selecwaybill, setSelectWaybill] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [bagName, setBagName] = useState("");
  const [bagData, setBagData] = useState([]);
  const [isAdded, setIsadded] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const today = new Date();
  const startOfYesterday = new Date(today);
  startOfYesterday.setDate(today.getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);
  const defaultStartDate = startOfYesterday;
  const defaultEndDate = endOfToday;
  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [dateRangefil, setDateRangefil] = useState({
    start: startOfYesterday.getTime(), // Convert to timestamp
    end: endOfToday.getTime(), // Convert to timestamp
  });
  const [bagNumber, setBagNumber] = useState("");
  const [bagNumberArray, setBagNumberArray] = useState([]);
  const [sourceHub, SetSourceHub] = useState("");
  const [chute, setChute] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isNone, setIsNone] = useState(true);
  const [destinationHub, setDestinationHub] = useState("");
  const [bagHandler, setBagHandler] = useState(true);
  const [waybillNumber, setwaybillNumber] = useState("");
  const [waybillArray, setWaybillArray] = useState([]);
  const [awbNum, setAwbNum] = useState("");
  const [bagNum,setBagNum] = useState("")
  const prevent = useRef(false);
  const getBags = useAoneStore((state) => state?.getBags);
  const getHubs = useAoneStore((state)=> state?.getHubs)
  const hubsData = useAoneStore((state)=> state?.hubsData)
  const printBag = useAoneStore((state) => state?.printBag);
  const outScanBag = useAoneStore((state) => state?.outScanBag);
  const inScanBag = useAoneStore((state) => state?.inScanBag);
  const moveConsignment = useAoneStore((state) => state?.moveConsignment);
  const exportBulkBag = useAoneStore((state) => state?.exportBulkBag);
  const outScanBulkBag = useAoneStore((state) => state?.outScanBulkBag);
  const consignmentBag = useAoneStore((state) => state?.consignmentBag);
  const templateConsigment = useAoneStore((state) => state?.templateConsigment);
  const postWaybillByTemplate = useAoneStore(
    (state) => state?.postWaybillByTemplate
  );
  const getBagsById = useAoneStore((state) => state?.getBagsById);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [selectedOption, setSelectedOption] = useState("None");
  const [show, setShow] = useState(false);
  const [showScan, setShowscan] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleCancel = () => {
    handleClose();
  };
  const handlescanShow = () => setShowscan(true);
  const handlescanClose = () => {
    setShowscan(false);
  };
  const handlescanCancel = () => {
    setShowscan(false);
  };
  const handleInputChange = (e) => {
    setBagNumber(e.target.value);
    const inputText = e.target.value;
    const hasSpacesOrCommas = /\s|,/.test(inputText);

    if (hasSpacesOrCommas) {
      const bagNumbersArray = inputText.split(/[,\s]+/).filter(Boolean);
      setBagNumberArray(bagNumbersArray);
    } else {
      setBagNumberArray([inputText]);
    }
  };
  const handleWaybillChange = (e) => {
    setAwbNum(e.target.value);
    const inputText = e.target.value;
    const hasSpacesOrCommas = /\s|,/.test(inputText);

    if (hasSpacesOrCommas) {
      const waybillArray = inputText.split(/[,\s]+/).filter(Boolean);
      setWaybillArray(waybillArray);
    } else {
      setWaybillArray([inputText]);
    }
  };
  const downloadPrint = async () => {
    try {
      const res = await printBag(paramsId?.id);
      // Create a Blob from the response data
      const blob = new Blob([res.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      // Open the PDF in a new window
      window.open(blobUrl, "_blank");
    } catch (error) {}
  };
  const downloadConsignment = async () => {
    try {
      const res = await consignmentBag(paramsId?.id);
      if (res.status == 200) {
        fileDownload(res.data, `${paramsId?.id}.xlsx`);
      }
    } catch (err) {}
  };
  const moveWaybill = () => {
    if (seleczone?.length === 0) {
      toast.error("Select a consignment to move");
    } else if (seleczone?.length > 1) {
      toast.error("Only one consignment can be moved at a time");
    } else {
      setShow(true);
    }
  };
  const bulkOutscan = (e) => {
    if (seleczone.length < 1) {
      toast.error("Select Bags to Outscan");
    } else {
      setShowscan(true);
    }
  };
  const bulkwaybillscan = (e) => {
    setIsinscan(false)
      setShowscan(true);
  };
  const bulkwaybillinscan = (e) => {
    setIsinscan(true)
    setShowscan(true);
  }
  const outScanBulkDownload = async () => {
    try {
      const bagIds = seleczone;
      const payload = {
        bagIds: bagIds,
      };
      const response = await outScanBulkBag(payload);
      if (response.status === 200) {
        toast.success(`Outscan updated for ${bagIds}`);
        setShowscan(false);
        setselectZone([]);
        setSelectWaybill([]);
        setSelectAll(false);
        setIndividualCheckboxesBag(
          Array(individualCheckboxesbag?.length).fill(false)
        );
      } else {
        const errorMessage = response?.response?.data?.msg?.split(/[,!]/)[0];
        toast.error(errorMessage);
        setselectZone([]);
        setSelectWaybill([]);
        setSelectAll(false);
        setShowscan(false);
        setIndividualCheckboxesBag(
          Array(individualCheckboxesbag?.length).fill(false)
        );
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
    }
  };
  const outScanDownload = async () => {
    const res = await outScanBag(paramsId?.id).then((response) => {
      if (response.status == 200) {
        setShowscan(false);
        toast.success(`Outscan updated for ${paramsId.id}`);
      } else {
        const errorMessage = response.response.data.msg.split(",")[0];
        toast.error(errorMessage);
        setShowscan(false);
      }
    });
  };
  const inScanDownload = async () => {
    const res = await inScanBag(paramsId?.id).then((response) => {
      if (response.status == 200) {
        setShowscan(false);
        toast.success(`Inscan updated for ${paramsId.id}`);
      } else {
        const errorMessage = response.response.data.msg.split(/[,!]/)[0];
        toast.error(errorMessage);
        setShowscan(false);
      }
    });
  };
  const addBag = useFormik({
    initialValues: {
      bagNumber: "",
    },
    validationSchema: Yup.object({
      bagNumber: Yup.string().required("Bag Number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        waybillNumber: seleczone[0], // Set waybillNumber to the first item in seleczone
        targetBagNumber: values.bagNumber.trim(),
      };
      await moveConsignment(paramsId.id, payload).then((response) => {
        handleClose();
        if (response?.data?.status == "SUCCESS") {
          setBagHandler(true);
          navigate("/middlemile/bags");
          toast.success(`Consignment moved to ${values.bagNumber}`);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg?.split("!")[0];
          toast.error(errorMessage);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg?.split(",")[0];
          toast.error(errorMessage);
          resetForm();
        }
      });
    },
  });
  const downloadTemplate = async () => {
    try {
      const res = await templateConsigment(paramsId?.id);
      if (res.status == 200) {
        fileDownload(res.data, `Consignment Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    // Update isValid state based on the selected option
    if (selectedValue === "Valid") {
      setIsNone(false);
      setIsValid(true);
    } else if (selectedValue === "Invalid") {
      setIsNone(false);
      setIsValid(false);
    } else {
      // For 'None'
      setIsValid(true);
      setIsNone(true);
    }

    // Update selected option state
    setSelectedOption(selectedValue);
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            postWaybillByTemplate(paramsId?.id, formData)
              .then((response) => {
                if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.key}: ${item.msg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const getData = async () => {
    if (location.pathname.endsWith("bags")) {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (bagNumber && bagNumberArray.length <= 1) {
        params += `&bagNumber=${bagNumber}`;
      }
      if (sourceHub) {
        params += `&sourceHubName=${sourceHub}`;
      }
      if (destinationHub) {
        params += `&destinationHubName=${destinationHub}`;
      }
      if (chute) {
        params += `&chute=${chute}`;
      }
      if (awbNum && waybillArray?.length <= 1) {
        params += `&waybillNumber=${awbNum}`;
      }
      if (!isNone) {
        params += `&valid=${isValid}`;
      }
      if (bagNumberArray.length > 1) {
        params += `&bagNumbers=${[bagNumberArray]}`;
      }
      if (waybillArray?.length > 1) {
        params += `&waybillNumbers=${[waybillArray]}`;
      }
      if (
        dateRangefil.start !== null &&
        dateRangefil.end !== null &&
        !isNaN(dateRangefil.start) &&
        !isNaN(dateRangefil.end) &&
        dateRangefil.start !== dateRangefil.end
      ) {
        params += `&createdStartTime=${dateRangefil.start}&createdEndTime=${dateRangefil.end}`;
      } else if (
        dateRangefil.start !== null &&
        dateRangefil.end !== null &&
        !isNaN(dateRangefil.start) &&
        !isNaN(dateRangefil.end)
      ) {
        const startOfDay = new Date(dateRangefil.start);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(dateRangefil.end);
        endOfDay.setHours(23, 59, 59, 999);

        // Check if dates are the same (today)
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (
          startOfDay.getTime() === endOfDay.getTime() &&
          startOfDay.getTime() === today.getTime()
        ) {
          // Set times to the beginning and end of the day
          startOfDay.setHours(0, 0, 0, 0);
          endOfDay.setHours(23, 59, 59, 999);
        } else {
          // Set times to the provided dates
          startOfDay.setHours(0, 0, 0, 0);
          endOfDay.setHours(23, 59, 59, 999);
        }

        params += `&scanStartTime=${startOfDay.getTime()}&scanEndTime=${endOfDay.getTime()}`;
      }
      const data = await getBags(params);
      if (data?.data?.status === "SUCCESS") {
        setBagData(data?.data?.data?.content);
        setTotalCount(data?.data?.data?.totalElements);
        setIsinscan(false)
        setBagHandler(true);
        setBagNum("")
      } else {
        const errorMessage = data?.response?.data?.msg.split("!")[0];
        toast.error(errorMessage);
      }
    } else {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (waybillNumber) {
        params += `&waybillNumber=${waybillNumber}`;
      }
      const data = await getBagsById(paramsId.id, params);
      setBagData(data.data.data.content);
      setBagNum(data?.data?.data?.content[0]?.bagNumber)
      setTotalCount(data.data.data.totalElements);
      setBagHandler(false);
    }
  };
    const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const exportBulkDownload = async () => {
    if (seleczone.length < 1) {
      toast.error("Select Bags to Export");
    } else {
      try {
        // Assuming selectZone is an array of selected IDs
        const bagIds = seleczone;
        const payload = {
          bagIds: bagIds,
        };
        const response = await exportBulkBag(payload);
        if (response.status === 200) {
          fileDownload(response.data, `Consignments.xlsx`);
          setselectZone([]);
          setSelectAll(false);
          setIndividualCheckboxesBag(
            Array(individualCheckboxesbag?.length).fill(false)
          );
        } else {
          toast.error("Error while bag export");
          setselectZone([]);
          setSelectAll(false);
          setIndividualCheckboxesBag(
            Array(individualCheckboxesbag?.length).fill(false)
          );
        }
      } catch (error) {
        throw error; // Rethrow the error to propagate it further if needed
      }
    }
  };

  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    location,
    statusHandler,
    saveHandler,
    isDelete,
    isAdded,
    isEdited,
    bagNumber,
    bagNumberArray,
    waybillArray,
    waybillNumber,
    awbNum,
    sourceHub,
    destinationHub,
    isValid,
    isNone,
    sortBy,
    chute,
    dateRangefil.start,
    dateRangefil.end,
  ]);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  useEffect(()=>{
    let paramslink = `?page=${0}&size=${30}`;
    getHubs(paramslink)
  },[])
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }}>
                Middle Mile
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  navigate("/middlemile/bags");
                  setSelectAll(false);
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: bagHandler ? "bolder" : "500",
                }}
              >
                Bags
              </span>
            </li>
            {!bagHandler && bagName && (
              <>
                <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                  <span>
                    <img
                      src={breadArrow}
                      className="turnBlack"
                      alt="breadcrumbarrow"
                    />
                  </span>
                </li>
                <li>
                  <span
                    className="mainheadlink"
                    onClick={() => navigate("/middlemile/bags")}
                    style={{
                      fontWeight: "500",
                      marginLeft: "-3px",
                      fontWeight: "bolder",
                    }}
                  >
                    {bagName}
                  </span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          {bagHandler ? (
            <div className="displayFlex justifyContent_end">
              <Row>
                <Col
                  md={3}
                  className={
                    bagNumber?.length < 22
                      ? "col-20-percent"
                      : bagNumber?.length < 44
                      ? "col-28-percent"
                      : bagNumber?.length < 67
                      ? "col-40-percent"
                      : bagNumber?.length < 87
                      ? "col-50-percent"
                      : "col-100-percent"
                  }
                >
                  <label className="fontSize14 mb_12">Bag Number:</label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={bagNumber}
                      onChange={handleInputChange}
                      className={
                        bagNumber?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        bagNumber?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                    />
                  </div>
                </Col>
                <Col
                  md={3}
                  className={
                    awbNum?.length < 22
                      ? "col-20-percent"
                      : awbNum?.length < 44
                      ? "col-28-percent"
                      : awbNum?.length < 67
                      ? "col-40-percent"
                      : awbNum?.length < 87
                      ? "col-50-percent"
                      : "col-100-percent"
                  }
                >
                  <label className="fontSize14 mb_12">Waybill Number:</label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={awbNum}
                      className={
                        awbNum?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onChange={handleWaybillChange}
                      style={
                        awbNum?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                    />
                  </div>
                </Col>
                <Col md={3} className="col-20-percent">
                  <label className="fontSize14 mb_12">Source Hub:</label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={sourceHub}
                      className={
                        sourceHub?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onChange={(e) => SetSourceHub(e.target.value)}
                      style={
                        sourceHub?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                    />
                  </div>
                </Col>
                <Col md={3} className="col-20-percent">
                  <label className="fontSize14 mb_12">Destination Hub:</label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={destinationHub}
                      className={
                        destinationHub?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onChange={(e) => setDestinationHub(e.target.value)}
                      style={
                        destinationHub?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                    />
                  </div>
                </Col>
                <Col md={3} className="mb-fil col-8-percent">
                  <label className="fontSize14 mb_12">Chute:</label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={chute}
                      className={
                        chute?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onChange={(e) => setChute(e.target.value)}
                      style={
                        chute?.length > 0 ? { border: "1px solid #ec171c" } : {}
                      }
                    />
                  </div>
                </Col>
                <Col md={3} className="col-20-percent">
                  <label className="fontSize14 mb_12">Valid:</label>
                  <div className="displayFlex alignItemCenter">
                    <select
                      value={selectedOption}
                      onChange={handleSelectChange}
                      className="mainInput"
                      style={
                        selectedOption?.length > 0 && selectedOption !== "None"
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                    >
                      <option value="None">None</option>
                      <option value="Valid">Valid</option>
                      <option value="Invalid">Invalid</option>
                    </select>
                  </div>
                </Col>
                <Col md={3} className="col-20-percent">
                  <label className="fontSize14 mb_12">Date Range:</label>
                  <div className="displayFlex alignItemCenter">
                    <div className="customDateRange">
                      <DateRangePicker
                        format="yyyy-MM-dd hh:mm aa"
                        placeholder="Select Date Range"
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        placement="auto"
                        minDate={
                          new Date(
                            new Date().setMonth(new Date().getMonth() - 3)
                          )
                        }
                        onOpen={handleMenuOpen}
                        onClose={handleMenuClose}
                        showMeridian
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="displayFlex justifyContent_end">
              <label className="fontSize14 mb_12 marginR10 mt_10">
                Waybill Number:
              </label>
              <div className="displayFlex alignItemCenter">
                <input
                  type="text"
                  value={waybillNumber}
                  onChange={(e) => setwaybillNumber(e.target.value)}
                  className={
                    waybillNumber?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                  // className="mainInput"
                  style={
                    waybillNumber?.length > 0
                      ? { border: "1px solid #ec171c" }
                      : {}
                  }
                />
              </div>
            </div>
          )}
          <div className="margin_top40">
            <div className="smallGroupInput mleft_10">
              {!bagHandler && (
                <>
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_MM_BAGS_BAG.print") ||
                    role?.includes("AONE_MM_BAGS_BAG.admin") ||
                    role?.includes("AONE_MM_BAGS.admin")) && (
                    <button
                      className="filterBtn plus_icon"
                      title="Print Label"
                      onClick={downloadPrint}
                    >
                      <span>
                        <img src={labelIcon} className="h18" />
                      </span>
                    </button>
                  )}
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_MM_BAGS_BAG.inscan") ||
                    role?.includes("AONE_MM_BAGS_BAG.admin") ||
                    role?.includes("AONE_MM_BAGS.admin")) && (
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon"
                      title="Inscan"
                    onClick={bulkwaybillinscan}
                    >
                      <span>
                        <img src={inscanicon} className="h18" />
                      </span>
                    </button>
                  )}
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_MM_BAGS_BAG.outscan") ||
                    role?.includes("AONE_MM_BAGS_BAG.admin") ||
                    role?.includes("AONE_MM_BAGS.admin")) && (
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon"
                      title="Outscan"
                      onClick={bulkwaybillscan}
                    >
                      <span>
                        <img src={outscanicon} className="h18" />
                      </span>
                    </button>
                  )}
                  <button
                    style={{ marginLeft: "10px" }}
                    className="filterBtn plus_icon"
                    title="Download Consignments"
                    onClick={downloadConsignment}
                  >
                    <span>
                      <img src={downloadIcon} className="h18" />
                    </span>
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    className="filterBtn plus_icon"
                    title="Move Consignments"
                    onClick={moveWaybill}
                  >
                    <span>
                      <img src={moveicon} className="h18" />
                    </span>
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    className="filterBtn plus_icon"
                    title="Consignment Template"
                    onClick={downloadTemplate}
                  >
                    <span>
                      <img src={templateIcon} className="h18" />
                    </span>
                  </button>
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_MM_BAGS_BAG.admin") ||
                    role?.includes("AONE_MM_BAGS_BAG.write") ||
                    role?.includes("AONE_MM_BAGS.admin")) && (
                    <div title="Import File" className="ml_10">
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  )}
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_MM_BAGS_BAG.admin") ||
                    role?.includes("AONE_MM_BAGS_BAG.write") ||
                    role?.includes("AONE_MM_BAGS.write") ||
                    role?.includes("AONE_MM_BAGS.admin")) && (
                    <button
                      className="filterBtn plus_icon ml_10"
                      title="Add"
                      onClick={() => setAddModalHandler(!addModalHandler)}
                      tabIndex="-1"
                    >
                      +
                    </button>
                  )}
                  <button
                    className="filterBtn plus_icon ml_10"
                    title="Sync"
                    onClick={() => syncronizeRoles()}
                  >
                    <span>
                      <img src={syncicon} className="h18" />
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {bagHandler && (
        <div className="displayFlex alignItemCenter justifyContent_end mb_16">
          {(role?.includes("AONE_ADMIN") ||
            role?.includes("AONE_MM_BAGS_BAG.outscan") ||
            role?.includes("AONE_MM_BAGS_BAG.admin") ||
            role?.includes("AONE_MM_BAGS.admin")) && (
            <button
              className="filterBtn plus_icon mleft_10"
              title="Outscan"
              onClick={(e) => bulkOutscan(e)}
            >
              <span>
                <img src={outscanicon} className="h18" />
              </span>
            </button>
          )}
          <button
            className="filterBtn plus_icon mleft_10"
            title="Export"
            onClick={() => exportBulkDownload()}
          >
            <span>
              <img src={downloadIcon} className="h18" />
            </span>
          </button>
          {(role?.includes("AONE_ADMIN") ||
            role?.includes("AONE_MM_BAGS_BAG.admin") ||
            role?.includes("AONE_MM_BAGS_BAG.write") ||
            role?.includes("AONE_MM_BAGS.write") ||
            role?.includes("AONE_MM_BAGS.admin")) && (
            <button
              className="filterBtn plus_icon mleft_10"
              title="Add"
              onClick={() => setAddModalHandler(!addModalHandler)}
              tabIndex="-1"
            >
              +
            </button>
          )}
          <button
            className="filterBtn plus_icon mleft_10"
            title="Sync"
            onClick={() => syncronizeRoles()}
          >
            <span>
              <img src={syncicon} className="h18" />
            </span>
          </button>
        </div>
      )}
      <PaginationTable
        tableData={bagData}
        setHeader={setHeaderParams}
        tbl={bagHandler ? "Bags" : "DetailedBags"}
        modalHandler={modalHandler}
        title={
          bagHandler &&
          (role?.includes("AONE_ADMIN") ||
            role?.includes("AONE_MM_BAGS.write") ||
            role?.includes("AONE_MM_BAGS.admin"))
            ? "Edit Bag"
            : bagHandler
            ? "View Bag"
            : "Edit Waybill"
        }
        setModalHandler={setModalHandler}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        newPage={newPage}
        setNewPage={setNewPage}
        bagName={bagName}
        setBagName={setBagName}
        size={size}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setSize={setSize}
        seleczone={seleczone}
        setBagNum={setBagNum}
        setselectZone={setselectZone}
        selecwaybill={selecwaybill}
        setSelectWaybill={setSelectWaybill}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        isDropdownselectOpen={isDropdownselectOpen}
        setIndividualCheckboxesBag={setIndividualCheckboxesBag}
        individualCheckboxesbag={individualCheckboxesbag}
        totalCount={totalCount}
        setSortField={setSortField}
        sortField={sortField}
        statusHandler={statusHandler}
        setStatusHandler={setStatusHandler}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
      />
      {addModalHandler && (
      <OpenModal
        modalHandler={addModalHandler}
        setModalHandler={setAddModalHandler}
        title={bagHandler ? "Add Bag" : "Add Waybill"}
        editHandler={editHandler}
        setEditHandler={setEditHandler}
        setIsadded={setIsadded}
        isAdded={isAdded}
        setIsEdited={setIsEdited}
        isEdited={isEdited !== undefined ? isEdited : false}
      />)}
      <Modal
        show={show}
        onHide={handleClose}
        // className="addnewpopup"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">
            Move Consignment to Other Bag
          </h4>
        </Modal.Header>
        {/* <div className="modelScrollTwo"> */}
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  Consignment
                </label>
                <input
                  type="text"
                  className="mainInput"
                  value={seleczone[0]}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  Bag Number
                </label>
                <input
                  type="text"
                  name="bagNumber"
                  placeholder="Bag Number"
                  className="mainInput"
                  onBlur={addBag.handleBlur}
                  onChange={(e) => {
                    const bagNumber = e.target.value;
                    addBag.setFieldValue("bagNumber", bagNumber);
                  }}
                />
                {addBag.touched.bagNumber && addBag.errors.bagNumber ? (
                  <p className="requiredText">{addBag.errors.bagNumber}</p>
                ) : null}
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
            <div>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="blue_btn"
                onClick={addBag.handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>
      <>
        <Modal
          className="scanConfirmpopup"
          show={showScan}
          onHide={handleClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight600 textAlignCenter blackText mb_8">
              Do you really want to outscan the selected{" "}
              {selecwaybill?.length <= 1 ? "bag" : "bags"}?
            </h2>
            <>
              <div className="displayFlex alignItemCenter justifyContent_center">
                <ul className="emptyUnorderList mb_12">
                  {selecwaybill.map((item, index) => (
                    <li
                      key={index}
                      className="fontSize14 fontWeight400 blackText"
                    >
                      - {item}
                      {index !== selecwaybill.length - 1}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <div>
              <button
                className="deletebtn cancelPrimary"
                onClick={() => handlescanCancel()}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="deletebtn confirmPrimary"
                onClick={outScanBulkDownload}
              >
                Outscan
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          className="scanConfirmpopup"
          show={showScan}
          onHide={handleClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight600 textAlignCenter blackText mb_8">
              Do you really want to {(bagNum?.length > 1 && isInscan) ? "inscan" : "outscan"} the {bagNum?.length < 1 ? "selected" : "current"}{" "}
              {selecwaybill?.length <= 1 ? "bag" : "bags"}?
            </h2>
            <>
              <div className="displayFlex alignItemCenter justifyContent_center">
                {bagNum?.length < 1 ? 
                <ul className="emptyUnorderList mb_12">
                  {selecwaybill?.map((item, index) => (
                    <li
                      key={index}
                      className="fontSize14 fontWeight400 blackText"
                    >
                      - {item}
                      {index !== selecwaybill?.length - 1}
                    </li>
                  ))}
                </ul>
                : <ul className="emptyUnorderList mb_12">
                  <li
                    className="fontSize14 fontWeight400 blackText"
                  >
                    - {bagNum}
                  </li>
              </ul>}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <div>
              <button
                className="deletebtn cancelPrimary"
                onClick={() => handlescanCancel()}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="deletebtn confirmPrimary"
                onClick={(bagNum?.length > 1 && isInscan) ? inScanDownload : (bagNum?.length > 1 && !isInscan) ? outScanDownload :  outScanBulkDownload}
              >
               {(bagNum?.length > 1 && isInscan) ? "Inscan" : "Outscan"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default Bags;
