import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getCouriers,filterCouriers,exportCouriers,getCourierTasks,
exportTasks,getConsignmentStatus,getConsignmentTaskType,configureCourierHubs} from "../../services/CourierManagement/courier_services"

const initialValues = {
    courierStatus: [],
    couriertaskType: [],
    selectedCourierCode: "",
};

let courierStore = (set, get) => ({
    ...initialValues,
    getCouriers: async (params) => {
        const response = await getCouriers(params);
        return response;
      },
      filterCouriers: async (params) => {
        const response = await filterCouriers(params);
        return response;
      },
      exportCouriers: async (params) => {
        const response = await exportCouriers(params);
        return response;
      },
      getCourierTasks: async (params) => {
        const response = await getCourierTasks(params);
        return response;
      },
      configureCourierHubs: async (params,data) => {
        const response = await configureCourierHubs(params,data);
        return response;
      },
      exportTasks: async (params) => {
        const response = await exportTasks(params);
        return response;
      },
      getConsignmentStatus: async () => {
        const response = await getConsignmentStatus();
        set({ courierStatus: response?.data?.data });
        return response;
      },
      getConsignmentTaskType: async () => {
        const response = await getConsignmentTaskType();
        set({ couriertaskType: response?.data?.data });
        return response;
      },
      setselectedCourierCode: (data) => set({ selectedCourierCode: data }),
});

courierStore = devtools(courierStore);

export const usecourierStore = create(courierStore);