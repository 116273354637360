import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

// Get Delivery task
export const getDeliveryTask = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/delivery/task${params}`)
    } catch (error) {
        return error;
    }
}

//create delivery task
export const postDeliveryTask = async (data) => {
    try {
        return await AoneService.post(`ops/api/v1/delivery/task`,data)
    } catch (error) {
        return error;
    }
}
//assign bulk couriers
export const bulkAssignDeliveryTask = async (data) => {
    try {
        return await AoneService.post(`ops/api/v1/delivery/task/bulk`,data)
    } catch (error) {
        return error;
    }
}
//delivery task export
export const exportDeliveryTasks = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/delivery/task/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//delivery task template
export const DeliveryTaskTemplate = async () => {
    try {
      return await AoneService.get(
        `ops/api/v1/delivery/task/template/download`,
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };
//bulk import delivery tasks
export const importDeliveryTasks = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/delivery/task/import`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };

//validate delivery task
export const validateDeliveryTask = async (params) => {
    try {
        return await AoneService.post(`ops/api/v1/delivery/task/${params}/validate`)
    } catch (error) {
        return error;
    }
}
//count employee for delivery task
export const countEmployee = async (params) => {
    try {
        return await AoneService.post(`ops/api/v1/delivery/task/${params}/count`)
    } catch (error) {
        return error;
    }
}
//unassign delivery task
export const removeDeliveryTask = async (params) => {
    try {
        return await AoneService.delete(`ops/api/v1/delivery/task/${params}`)
    } catch (error) {
        return error;
    }
}
//delivery status lookup
export const getdeliveryStatus = async () => {
    try {
        return await AoneService.get(`ops/api/v1/lookups/DeliveryTaskStatus`)
    } catch (error) {
        return error;
    }
}

//delivery validation
//get delivery validation
export const getdeliveryValidation = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/delivery/validations/${params}/scans`)
    } catch (error) {
        return error;
    }
}
//post delivery validation
export const postdeliveryValidation = async (data) => {
    try {
        return await AoneService.post(`ops/api/v1/delivery/validations`,data)
    } catch (error) {
        return error;
    }
}
//validate package tracking id
export const validatePackageTrackingId = async (params,data) => {
    try {
        return await AoneService.post(`ops/api/v1/delivery/validations/${params}/validate`,data)
    } catch (error) {
        return error;
    }
}
//submit delivery validation
export const submitDeliveryValidation = async (data) => {
    try {
        return await AoneService.post(`ops/api/v1/delivery/validations/submit`,data)
    } catch (error) {
        return error;
    }
}
//make OFD
export const makeOfd = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/delivery/validation/ofd/${params}`)
    } catch (error) {
        return error;
    }
}
// count tasks for courier
export const countTasks = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/delivery/validations/${params}/count`)
    } catch (error) {
        return error;
    }
}
//export delivery validation
export const exportDeliveryValidation = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/delivery/validations/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
  //delete delivery validation
  export const deleteDeliveryValidation = async (params) => {
    try {
        return await AoneService.delete(`ops/api/v1/delivery/validations/scans/${params}`)
    } catch (error) {
        return error;
    }
}
//remove delivery validated scans
export const removeDeliveryValidation = async (params) => {
    try {
        return await AoneService.delete(`ops/api/v1/delivery/validations/${params}`)
    } catch (error) {
        return error;
    }
}

//courier debreifing
export const startCourierDebreif = async (data) => {
    try {
        return await AoneService.post(`ops/api/v1/debriefing`,data)
    } catch (error) {
        return error;
    }
}
//count courier datas
export const countCourierDebreif = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/debriefing/${params}/count`)
    } catch (error) {
        return error;
    }
}
//add debreifing
export const addDebreifing = async (params,params2) => {
    try {
        return await AoneService.post(`ops/api/v1/debriefing/add?packageTrackingId=${params}&debriefingCode=${params2}`)
    } catch (error) {
        return error;
    }
}
//get debriefing
export const getDebreif = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/debriefing/scans${params}`)
    } catch (error) {
        return error;
    }
}
//submit debreifing
export const submitDebreifing = async (params) => {
    try {
        return await AoneService.post(`ops/api/v1/debriefing/submit?debriefingCode=${params}`)
    } catch (error) {
        return error;
    }
}
//delete debreifing
export const deleteDebreifing = async (params) => {
    try {
        return await AoneService.delete(`ops/api/v1/debriefing/${params}`)
    } catch (error) {
        return error;
    }
}
//get history
export const getDebreifHistory = async (params) => {
    try {
        return await AoneService.get(`ops/api/v1/debriefing/history${params}`)
    } catch (error) {
        return error;
    }
}
//export history
export const exportDebreifingHistory = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/debriefing/history/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };