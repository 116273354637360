import React, { useState } from 'react'
import searchicon from "../../src/assets/img/searchicon.svg";
import downloadicon from "../../src/assets/img/download_icon.svg"
import PaginationTable from '../components/Tables/paginationTable';
import { DatePicker } from 'rsuite';
import breadArrow from "../../src/assets/img/arrow-dropright_icon.svg"

const Operations = () => {

    const [tab, setTab] = useState(1)
    const [handoverTab, setHandoverTab] = useState(1)

    const [ name, setName ] = useState("")
    const [ newPage, setNewPage ] = useState(0)
    const [ size, setSize ] = useState(20)
    const [ totalCount, setTotalCount ] = useState(0)
    const [ sortField, setSortField ] = useState("createdAt")

    const [ modalHandler, setModalHandler ] = useState(false)
    const [ deleteHandler, setDeleteHandler ] = useState(false)

    const data = [
      {
        id: 1,
        joborder_code: "JO-001",
        hub_operator: "AJE-001",
        created_at: "01/09/23",
        updated_at: "10/10/23",
        fullfillment_employee: "AJE-001",
        box_quantity: 20,
        created_by: "User-01",
        updated_by: "User-01"
      }
    ]

    const data2 = [
      {
        id: 1,
        joborder_code: "JO-001",
        courier: "AJE-001",
        created_at: "01/09/23",
        updated_at: "01/09/23",
        delivery_date: "10/10/23",
        waybill_no: "ABC000901212",
        delivery_location: "Google Maps",
        customer_account: "ACC-001",
        customer_name: "Customer Name",
        created_by: "User-01",
        updated_by: "User-01"
      }
    ]

  return (
    <div>
        <div className='row alignItemCenter'>
            <div className='col-md-7'>
                <ul className="page_breadcrumb">
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500"}}>
                  Projects
                  </span>
                  </li>
                  <li style={{marginLeft:"-10px",marginBottom:"5px"}}>
                  <span><img src={breadArrow} className="turnBlack" alt="breadcrumbarrow"/></span>
                  </li>
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px"}}>
                  Add-On List
                  </span>
                  </li>
                  <li style={{marginLeft:"-10px",marginBottom:"5px"}}>
                  <span><img src={breadArrow} className="turnBlack" alt="breadcrumbarrow"/></span>
                  </li>
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px",fontWeight:"bolder"}}>
                  Assignation
                  </span>
                  </li>
                  </ul>
            </div>
        </div>

        <div className='displayFlex tabletab_section mt_20 mb_24'>
          <button className={`filterBtn ${tab == 1 && `activeFilterBtn`}`} onClick={()=>setTab(1)}>Handover</button>
          <button className={`filterBtn ${tab == 2 && `activeFilterBtn`}`} onClick={()=>setTab(2)}>Delivered</button>
          <button className={`filterBtn ${tab == 3 && `activeFilterBtn`}`} onClick={()=>setTab(3)}>Exceptions</button>
        </div>

        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          <div className='displayFlex justifyContent_spacebetween gap_12'>
            <div className='displayFlex alignItemCenter'>
                <input type='text' className={name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"}
                      style={
                        name?.length > 0
                          ? { border: '1px solid #ec171c' }
                          : {}
                      } onChange={(e)=>(setName(e.target.value))}/>
            </div>
            { tab == 2 &&
              <div className='displayFlex alignItemCenter gap_12'>
                <label className='fontSize14 menacingCloudsText fontWeight500'>Deliver Date:</label>                
                <div className="deliveredDateRange" style={{ color: "black"}}>
                    <DatePicker
                        placeholder="Select Date"
                        placement="leftStart"
                    />
                </div>
              </div>
            }
          </div>

          <div className='displayFlex gap_12 alignItemCenter'> 
            <div className='displayFlex tabletab_section'>
              { tab == 1 &&
              <>
              <button className={`filterBtn ${handoverTab == 1 && `activeFilterBtn`}`} onClick={()=>setHandoverTab(1)}>Handover To Operator</button>
              <button className={`filterBtn ${handoverTab == 2 && `activeFilterBtn`}`} onClick={()=>setHandoverTab(2)}>Handover To Courier</button>
              <button className={`filterBtn ${handoverTab == 3 && `activeFilterBtn`}`} onClick={()=>setHandoverTab(3)}>Returned Back To Operator</button>
              </> 
              }
            </div>
            <div>
              <button className="filterBtn" title='Download'>
                <span><img src={downloadicon} className='h18'/></span>
              </button>
            </div>               
          </div> 
        </div>

        <PaginationTable tableData={tab == 1 ? data : tab == 2 && data2} tbl={tab == 1 ? "Handover" : tab == 2 && "Delivered"} newPage={newPage} 
          setNewPage={setNewPage} size={size} setSize={setSize} totalCount={20} setSortField={setSortField} sortField={sortField}
          modalHandler={modalHandler} setModalHandler={setModalHandler} deleteHandler={deleteHandler} setDeleteHandler={setDeleteHandler} title={"Edit Handover"}/>

    </div>
  )
}

export default Operations