import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { useweightStore } from "../../../store/WeighingMgt/WeighingStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const WeighingModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  weightbyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postWeight = useweightStore((state) => state?.postWeight);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const weightUnitList = useweightStore((state) => state?.weightUnitList);
  const dimensionUnitList = useweightStore((state) => state?.dimensionUnitList);
  const [resetKey, setResetKey] = useState(0);
  const addWeight = useFormik({
    initialValues: {
      trackingId: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      weightUnit: "",
      dimensionUnit: "",
    },
    validationSchema: Yup.object({
      trackingId: Yup.string().trim().required("Tracking Id is required"),
      length: Yup.number()
        .typeError("Length must be a number")
        .integer("Length must be an integer")
        .required("Length is required"),
      width: Yup.number()
        .typeError("Width must be a number")
        .integer("Width must be an integer")
        .required("Width is required"),
      height: Yup.number()
        .typeError("Height must be a number")
        .integer("Height must be an integer")
        .required("Height is required"),
      weight: Yup.number()
        .typeError("Weight must be a number")
        .positive("Weight must be greater than zero")
        .required("Weight is required"),
      weightUnit: Yup.string().required("Weight Unit is required"),
      dimensionUnit : Yup.string().trim().required("Dimension Unit is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postWeight(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Weight has been added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    },
  });
  const [trackError, setTrackError] = useState("");
  const [weightErr, setWeightErr] = useState("");
  const [heightErr, setHeightErr] = useState("");
  const [lengthErr, setLengthErr] = useState("");
  const [widthErr, setWidthErr] = useState("");
  const saveRoutes = (initialValues) => {
    if (
      addWeight.values.weightUnit === "GRAM" &&
      addWeight.values.weight > 70000
    ) {
      setWeightErr("Weight should not be greater than 70000");
    } else if (
      addWeight.values.weightUnit === "KG" &&
      addWeight.values.weight > 70
    ) {
      setWeightErr("Weight should not be greater than 70");
    } else if (
      addWeight.values.weightUnit === "POUNDS" &&
      addWeight.values.weight > 154
    ) {
      setWeightErr("Weight should not be greater than 154");
    } else if (/\s/g.test(addWeight.values.weight)) {
      setWeightErr(
        "Weight cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addWeight.values.trackingId)) {
      setTrackError(
        "Tracking Id cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addWeight.values.length)) {
      setLengthErr(
        "Length cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addWeight.values.width)) {
      setWidthErr(
        "Width cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addWeight.values.height)) {
      setHeightErr(
        "Height price cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setTrackError("");
      setWeightErr("");
      setHeightErr("");
      setLengthErr("");
      setWidthErr("");
      addWeight?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollWt">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Package Tracking Id{" "}
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Package Tracking Id"
                      value={addWeight.values.trackingId}
                      onChange={(e) => {
                        addWeight.setFieldValue("trackingId", e.target.value);
                        setTrackError("");
                      }}
                      name="trackingId"
                      className="mainInput"
                    />
                  </div>
                  {addWeight.touched.trackingId &&
                  addWeight.errors.trackingId &&
                  trackError === "" ? (
                    <p className="requiredText">
                      {addWeight.errors.trackingId}
                    </p>
                  ) : null}
                  {trackError !== "" ? (
                    <p className="requiredText">{trackError}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Dimension Unit
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="dimensionUnit"
                      label="dimensionUnit"
                      name="dimensionUnit"
                      options={dimensionUnitList?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addWeight.setFieldValue(
                          "dimensionUnit",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Dimension Unit"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addWeight.touched.dimensionUnit &&
                  addWeight.errors.dimensionUnit ? (
                    <p className="requiredText">
                      {addWeight.errors.dimensionUnit}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Weight
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Weight"
                      value={addWeight.values.weight}
                      onChange={(e) => {
                        addWeight.setFieldValue("weight", e.target.value);
                        setWeightErr("");
                      }}
                      name="weight"
                      className="mainInput"
                    />
                  </div>
                  {(addWeight.touched.weight &&
                  addWeight.errors.weight &&
                  weightErr === "") ? (
                    <p className="requiredText">{addWeight.errors.weight}</p>
                  ) : null}
                  {weightErr !== "" ? (
                    <p className="requiredText">{weightErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Weight Unit
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="weightUnit"
                      label="weightUnit"
                      name="weightUnit"
                      options={weightUnitList?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addWeight.setFieldValue(
                          "weightUnit",
                          selectedOption ? selectedOption?.key : ""
                        );
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Weight Unit"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addWeight.touched.weightUnit &&
                  addWeight.errors.weightUnit ? (
                    <p className="requiredText">
                      {addWeight.errors.weightUnit}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Length
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Length"
                      value={addWeight.values.length}
                      onChange={(e) => {
                        addWeight.setFieldValue("length", e.target.value);
                        setLengthErr("");
                      }}
                      name="length"
                      className="mainInput"
                    />
                  </div>
                  {addWeight.touched.length &&
                  addWeight.errors.length &&
                  lengthErr === "" ? (
                    <p className="requiredText">{addWeight.errors.length}</p>
                  ) : null}
                  {lengthErr !== "" ? (
                    <p className="requiredText">{lengthErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Width
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Width"
                      value={addWeight.values.width}
                      onChange={(e) => {
                        addWeight.setFieldValue("width", e.target.value);
                        setWeightErr("");
                      }}
                      name="width"
                      className="mainInput"
                    />
                  </div>
                  {addWeight.touched.width &&
                  addWeight.errors.width &&
                  widthErr === "" ? (
                    <p className="requiredText">{addWeight.errors.width}</p>
                  ) : null}
                  {widthErr !== "" ? (
                    <p className="requiredText">{widthErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Height
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Height"
                      value={addWeight.values.height}
                      onChange={(e) => {
                        addWeight.setFieldValue("height", e.target.value);
                        setHeightErr("");
                      }}
                      name="height"
                      className="mainInput"
                    />
                  </div>
                  {addWeight.touched.height &&
                  addWeight.errors.height &&
                  heightErr === "" ? (
                    <p className="requiredText">{addWeight.errors.height}</p>
                  ) : null}
                  {heightErr !== "" ? (
                    <p className="requiredText">{heightErr}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveRoutes}>
            Create
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default WeighingModal;
