import React, { useState, useRef, useEffect, useContext , useCallback} from "react";
import { useNavigate } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import { useCollectionStore } from "../../../store/CollectionsMgt/CollectionStore";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import jwt_decode from "jwt-decode";
import PaginationTable from "../../../components/Tables/paginationTable";
import Dropdown from "react-bootstrap/Dropdown";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { UserContext } from "../../../helper/ProtectedRoute";
const Ledger = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [employee, setEmployee] = useState("");
  const [country, setCountry] = useState("");
  const [category, setCategory] = useState("");
  const [size, setSize] = useState(20);
  const [dateRange, setDateRange] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [ledgerData,setLedgerData] = useState("")
  const [selectedOptionEmployee, setSelectedOptionEmployee] = useState(null);
  const [modalHandler, setModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const [courierPage, setCourierPage] = useState(-1);
  const getLedger = useCollectionStore((state) => state?.getLedger);
  const exportLedger = useCollectionStore((state) => state?.exportLedger);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [state] = useContext(UserContext);
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const gridId = "finance.ledger";
  const [column, SetCoulmn] = useState([
    "Employee Code",
    "Employee Name",
    "Country",
    "Hub",
    "Balance",
    "Currency",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
  ]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const [dateRangefil, setDateRangefil] = useState({});
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [loadingMore, setLoadingMore] = useState(false);
  const [prevSearch, setPrevSearch] = useState("");
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
    const [userHub, setUserHub] = useState("");
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      console.log("decoded", decoded);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  const loadMoreOptionsCourier = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: employee.employeeName,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, courierPage,userHub, prevSearch, setPrevSearch]
  );
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&fields=employeeName`;
    if (country) {
        params += `&country=${category}`;
      }
      if (employee) {
        params += `&employeeCode=${employee}`;
      }
      try {
        const res = await exportLedger(params);
        if (res.status == 200) {
          fileDownload(res.data, `Ledger.xlsx`);
        }
      } catch (err) {}
}
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&fields=employeeName`;
      if (country) {
        params += `&countryCode=${country}`;
      }
      if (employee) {
        params += `&employeeCode=${employee}`;
      }
      if (
        dateRangefil.start !== null &&
        dateRangefil.end !== null &&
        !isNaN(dateRangefil.start) &&
        !isNaN(dateRangefil.end) &&
        dateRangefil.start !== dateRangefil.end
      ) {
        params += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
      }
      const data = await getLedger(params);
      setTotalCount(data?.data?.data?.totalElements);
      setLedgerData(data?.data?.data?.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    country,
    employee,
    dateRangefil.start,
    dateRangefil.end,
    ispostAdded,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${30}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.length < 1
    ) {
      filterCountries(paramslink);
    }
  }, []);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/finance/cashdebriefing")}
              >
                Finance
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/finance/cashdebriefing")}
              >
                Cash Debriefing
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
      <div className="borderFrame mb_16">
      <div className="borderFrameTitle">Ledgers</div>
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16 mt_-30px">
              <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 mt_10">
                      Employee:
                    </label>
                    <AsyncSearchInput
                    id="employee"
                    label="employee"
                    name="employee"
                    value={selectedOptionEmployee}
                    loadMoreOptions={loadMoreOptionsCourier}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(employee) => employee.label}
                    getOptionValue={(employee) => employee.value}
                    onMenuOpen={handleMenuOpenorgcity}
                    onMenuClose={handleMenuCloseorgcity}
                    menuIsOpen={isReactoriginselectCity}
                    onChange={(selectedOptionEmployee) => {
                      setSelectedOptionEmployee(selectedOptionEmployee);
                      setEmployee(
                        selectedOptionEmployee ? selectedOptionEmployee.value : ""
                      );
                    }}
                    placeholder="Employee"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        setCountry(
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      onMenuOpen={handleMenudesregion}
                      onMenuClose={handleMenuClosedesregion}
                      menuIsOpen={isReactdesRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className={
                        country?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Updated Date:
                    </label>
                    <DateRangePicker
                      format="yyyy-MM-dd"
                      placeholder="Select Updated Date"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      placement="auto"
                      minDate={
                        new Date(new Date().setMonth(new Date().getMonth() - 3))
                      }
                      onOpen={handleMenuOpen}
                      onClose={handleMenuClose}
                      showMeridian
                      className="w200"
                    />
                  </Col>
                </Row>
              </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn"
                  title={"Export Ledger"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>
  
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column?.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <PaginationTable
            tableData={ledgerData}
            tbl={"Ledger"}
            restrict={checkedCol}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            isDropdownselectOpen={isDropdownselectOpen}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
          </div>
      </div>
    </div>
  );
};

export default Ledger;
