export const roleHandler = (role,text) => {
    var roles = role
    if(typeof role == "string"){
        roles = role.split(",")
    }
    if(roles?.includes("ROLE_ADMIN")){
        return true
    }
    const access = roles?.filter((e)=>e.includes(text))
    if(access?.length > 0){
        return true
    }else{
        return false
    }
}