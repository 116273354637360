import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import deleteIcon from "../../../assets/img/delete_icon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const VehicleVendorModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  vehiclebyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postVehicleVendor = usevehicleStore((state) => state?.postVehicleVendor);
  const editVehicleVendor = usevehicleStore((state) => state?.editVehicleVendor);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [resetKey, setResetKey] = useState(0);
  const addVehicleVendor = useFormik({
    initialValues: {
      code: "",
      name: "",
      countryCode: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().required("Code is required")
      .matches(/^[a-zA-Z0-9\s_-]*$/, "Code must not contain special characters")
      .max(30, "Code must be at most 30 characters long"),
      name: Yup.string().required("Name is required")
      .matches(/^[a-zA-Z0-9\s_-]*$/, "Name must not contain special characters"),
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit vehicle vendor") {
        await editVehicleVendor(vehiclebyCode.code,values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Vehicle vendor updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          }else if(response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response.status == 200) {
            toast.success("Vehicle vendor updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      } else {
      await postVehicleVendor(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Vehicle vendor added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    }
    },
  });
  useEffect(() => {
    addVehicleVendor.setFieldValue("code", vehiclebyCode?.code);
    addVehicleVendor.setFieldValue("name", vehiclebyCode?.name);
    addVehicleVendor.setFieldValue("countryCode", vehiclebyCode?.countryCode);
}, [title, vehiclebyCode]);
useEffect(() => {
  if(title == "Add Vehicle Vendor"){
    addVehicleVendor.setFieldValue("code", "");
    addVehicleVendor.setFieldValue("name", "");
    addVehicleVendor.setFieldValue("countryCode", "");
  }
}, [title, vehiclebyCode]);
  const [vehicleCodeErr,setVehicleCodeErr] = useState("")
  const [vehicleNameErr, setVehicleNameErr] = useState("");
  const saveVehicleVendor = (initialValues) => {
    if (
      /\s/g.test(addVehicleVendor.values.code)
    ) {
      setVehicleCodeErr("Code cannot cannot contain leading, trailing, or consecutive spaces");
    }else if (/^\s|\s$/.test(addVehicleVendor.values.name)) {
      setVehicleNameErr(
          "Name cannot cannot contain leading or trailing spaces"
        );
    } else {
      setVehicleCodeErr("");
      setVehicleNameErr("");
      addVehicleVendor?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleVendors">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Code
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Code"
                      value={addVehicleVendor.values.code}
                      onChange={(e) => {
                        addVehicleVendor.setFieldValue("code", e.target.value);
                        setVehicleCodeErr("");
                      }}
                      disabled={title == "Edit vehicle vendor"}
                      name="code"
                      className="mainInput"
                    />
                  </div>
                  {(addVehicleVendor.touched.code &&
                  addVehicleVendor.errors.code &&  vehicleCodeErr == "")  ? (
                    <p className="requiredText">
                      {addVehicleVendor.errors.code}
                    </p>
                  ) : null}
                   {(vehicleCodeErr !== "")  ? (
                    <p className="requiredText">
                      {vehicleCodeErr}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Name
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={addVehicleVendor.values.name}
                      onChange={(e) => {
                        addVehicleVendor.setFieldValue("name", e.target.value);
                        setVehicleNameErr("");
                      }}
                      name="name"
                      className="mainInput"
                    />
                  </div>
                  {(addVehicleVendor.touched.name &&
                  addVehicleVendor.errors.name  && vehicleNameErr == "") ? (
                    <p className="requiredText">
                      {addVehicleVendor.errors.name}
                    </p>
                  ) : null}
                  {vehicleNameErr !== "" ? (
                    <p className="requiredText">{vehicleNameErr}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
            <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        addVehicleVendor.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                      }}
                      value={
                        addVehicleVendor?.values?.countryCode
                          ? countriesData?.data?.data?.content.find(
                              (option) => option.isoCode3 === addVehicleVendor?.values?.countryCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addVehicleVendor.touched.countryCode &&
                  addVehicleVendor.errors.countryCode ? (
                    <p className="requiredText">
                      {addVehicleVendor.errors.countryCode}
                    </p>
                  ) : null}
                </div>
              </div>
              </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveVehicleVendor}>
          {title === "Add Vehicle Vendor" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default VehicleVendorModal;
