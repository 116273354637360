import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../store/AoneStore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import PasswordIcon from "../../../src/assets/img/PasswordGearIcon.svg"
import ResetPasswordModal from "./ResetPasswordModal";
import CusModal from "../../components/modal/CusModal";
const Usermanagement = ({setTab}) => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
  const globalUsername = useAoneStore((state) => state?.globalUsername);
  const globalJobtitle = useAoneStore((state) => state?.globalJobtitle);
  const globalEmail = useAoneStore((state) => state?.globalEmail);
  const globalFirstname = useAoneStore((state) => state?.globalFirstname);
  const globalLastname = useAoneStore((state) => state?.globalLastname);
  const globalDepartment = useAoneStore((state) => state?.globalDepartment);
  const globalEmployeeStatus = useAoneStore(
    (state) => state?.globalEmployeeStatus
  );
  const globalUserStatus = useAoneStore((state) => state?.globalUserStatus);
  const setglobalEmployeeCode = useAoneStore(
    (state) => state?.setglobalEmployeeCode
  );
  const postLoginInfoemployee = useAoneStore(
    (state) => state?.postLoginInfoemployee
  );
  const toggleUserstatus = useAoneStore((state) => state?.toggleUserstatus);
  const [isEnabled, setIsEnabled] = useState(globalEmployeeStatus);
  const toggleStatus = async () => {
    const updatedIsEnabled = !isEnabled; // Toggle the isEnabled state
    setIsEnabled(updatedIsEnabled); // Update the state
    // Call the API with the updated isEnabled value
    let res =await toggleUserstatus(globalEmployeeCode, updatedIsEnabled);
    const errormMessage = res?.response?.data?.msg;
    toast.error(errormMessage);
  };
  const generatePassword = useAoneStore((state) => state?.generatePassword);
  useEffect(() => {
    addLoginInformation.setFieldValue("username", globalEmployeeCode);
    addLoginInformation.setFieldValue("email", globalEmail);
  }, []);
  const addLoginInformation = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      temporary: false,
      emailVerified: false,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(25, "Password must not exceed 25 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.!@#$%^&*()+?><]).{6,25}$/,
          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character from -.!@#$%^&*()+?><"
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      SetIsReset(false);
      values.firstName = globalFirstname;
      values.lastName = globalLastname;
      values.department = globalDepartment;
      values.jobTitle = globalJobtitle;
      if (globalEmployeeCode) {
        await postLoginInfoemployee(globalEmployeeCode, values).then(
          (response) => {
            if (response.data.status == "ERROR") {
              const errorMessages = response?.data?.msg;
              toast.error(errorMessages);
            } else if (
              response?.response?.data?.status === "ERROR" &&
              response?.response?.data?.msg
            ) {
              toast.error(`${response?.response?.data?.msg}`);
            } else if (response?.response?.data?.status === "ERROR") {
              const errorMessages = response?.response?.data?.errors
                .map((item) => `${item.key}: ${item.msg}`)
                .join(", ");
              toast.error(`${errorMessages}`);
            } else {
              toast.success("Login Information Added");
              if(globalUserStatus){
              setTab(2);
              }else{
                navigate("/crm/employees");
                setglobalEmployeeCode("");
              }
              resetForm();
            }
          }
        );
      } else {
        toast.error(
          "Employee Account need to be created for adding login information"
        );
      }
    },
  });
  const generatepassword = async () => {
    const data = await generatePassword();
    addLoginInformation.setFieldValue("password", data?.data?.data);
  };
  const [isReset, SetIsReset] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <div className="row mb_22">
            <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
              Username:
            </label>
            <div className="col-md-8">
              <input
                type="text"
                placeholder="Username"
                className="mainInput"
                value={addLoginInformation.values.username}
                onChange={(e) =>
                  addLoginInformation.setFieldValue("username", e.target.value)
                }
                name="username"
                disabled={true}
              />
            </div>
          </div>
        </div>
        {(roleHandler(role, "AONE_CRM.admin") ||
          roleHandler(role, "AONE_CRM.write") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES.admin")) &&
          globalUserStatus &&
          params?.id && (
            <div className="row mt_reset">
              <div className="row justify-content-end">
                <div className="col-md-4">
                  <div className="toggle-switch-container">
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={isEnabled}
                        onChange={toggleStatus}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="toggle-label">
                      {isEnabled ? "Enabled" : "Disabled"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-md-4">
                  <button
                    type="button"
                    className="blue_btn"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="row mb_22">
            <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
              Email:
            </label>
            <div className="col-md-8">
              <input
                type="text"
                placeholder="Email"
                className="mainInput"
                name="email"
                value={addLoginInformation.values.email}
                onChange={(e) =>
                  addLoginInformation.setFieldValue("email", e.target.value)
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      {!globalUserStatus &&
      <><div className="row">
          <div className="col-md-4 mb_20">
            <div className="row mb_22">
              <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                Password:
                <span className="bloodDonortext fontWeight600 fontSize16">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  placeholder="Password"
                  className="mainInput"
                  name="password"
                  value={addLoginInformation.values.password}
                  onChange={(e) => {
                    addLoginInformation.setFieldValue("password", e.target.value);
                  } } />
                {addLoginInformation.touched.password &&
                  addLoginInformation.errors.password
                  ? (
                    <p className="requiredText">
                      {addLoginInformation?.errors?.password?.includes(
                        "required"
                      ) &&
                        !isReset && <p>Password is required</p>}
                      <ul className="requiredText">
                        {addLoginInformation?.errors?.password?.includes(
                          "characters"
                        ) && <li>Password must be at least 6 characters</li>}
                        {addLoginInformation?.errors?.password?.includes(
                          "not exceed"
                        ) && <li>Password must not exceed 25 characters</li>}
                        {addLoginInformation?.errors?.password?.includes(
                          "lowercase letter"
                        ) && (
                            <li>
                              Password must contain at least one lowercase letter
                            </li>
                          )}
                        {addLoginInformation?.errors?.password?.includes(
                          "uppercase letter"
                        ) && (
                            <li>
                              Password must contain at least one uppercase letter
                            </li>
                          )}
                        {addLoginInformation?.errors?.password?.includes(
                          "digit"
                        ) && <li>Password must contain at least one digit</li>}
                        {addLoginInformation?.errors?.password?.includes(
                          "special character"
                        ) && (
                            <li>
                              Password must contain at least one special character
                              from -.!@#$%^&*()+?
                            </li>
                          )}
                      </ul>
                    </p>
                  ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb_150">
            <div className="row mb_22 generatePassword mt_55">
              <div className="col-md-4">
                <span>
                  <img src={PasswordIcon} className="h18 cursorPointer" onClick={generatepassword} />
                </span>
              </div>
            </div>
          </div>
        </div><div className="row">
            <div className="col-md-4 mb_30">
              <div className="smallCheckbox col-md-12">
                <input
                  type="checkbox"
                  id="temporary"
                  style={{ marginLeft: "5px" }}
                  checked={addLoginInformation.values.temporary}
                  onChange={() => {
                    addLoginInformation.setFieldValue(
                      "temporary",
                      !addLoginInformation.values.temporary
                    );
                  } } />
                <label htmlFor="temporary">Temperary Password</label>
              </div>
            </div>
          </div><div className="row">
            <div className="col-md-4 mb_60">
              <div className="smallCheckbox col-md-12">
                <input
                  type="checkbox"
                  id="emailVerified"
                  style={{ marginLeft: "5px" }}
                  checked={addLoginInformation.values.emailVerified}
                  onChange={() => {
                    addLoginInformation.setFieldValue(
                      "emailVerified",
                      !addLoginInformation.values.emailVerified
                    );
                  } } />
                <label htmlFor="emailVerified">Email Verified</label>
              </div>
            </div>
          </div></>}
      <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
        {params?.id ? (
          (roleHandler(role, "AONE_CRM.admin") ||
            roleHandler(role, "AONE_CRM.write") ||
            roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
            roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write") ||
            roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin") ||
            roleHandler(role, "AONE_CRM_EMPLOYEES.admin")) &&
          !globalUserStatus ? (
            <>
              <button
                type="button"
                className="blue_btn"
                onClick={addLoginInformation?.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn">Cancel</button>
            </>
          ) : null
        ) : roleHandler(role, "AONE_CRM.admin") ||
          roleHandler(role, "AONE_CRM.write") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ? (
          <>
            <button
              type="button"
              className="blue_btn"
              onClick={addLoginInformation?.handleSubmit}
            >
              Save
            </button>
            <button className="cancel_btn">Cancel</button>
          </>
        ) : null}
      </div>
      <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={"addVehicleCateg"}
        >
            <ResetPasswordModal
              onSuccess={handleSuccess}
              onError={handleError}
              showModal={showModal}
              setShowModal={setShowModal}
            />
        </CusModal>
    </div>
  );
};

export default Usermanagement;
