import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getPickupRequest,getPickupRequestbyId, postPickupRequest,editPickupRequest,
  importPickupRequests,getPickupTemplate,exportPickupRequests,cancelPickupRequest,
  applyPickupRequest,applyBulkPickupRequest,getPackageTrackEvent,postPickupConsignment,deletePickupConsignment,
  getConsignments,getConsignmentsDestination,getPickupConsignments,
  exportConsignments,getConsignmentStatus,getPickuprequestStatus,importPickupRequestsProof,
  applyConsignments,bulkapplyConsignments,revokePickupConsignment,revokeCancelConsignment,revokeDeliveryConsignment,revokeHoldConsignment,importDdu,dduUpdateTemplate} from "../../services/PickupRequest/pickuprequest_services"
import { resetPasswordEmployee } from "../../services/aone_services";
const initialValues = {
  isPickupRequest: false,
  consignmentStatus: [],
  pickupStatus: []
};
let pickupStore = (set, get) => ({
  ...initialValues,
    getPickupRequest: async (params) => {
        const response = await getPickupRequest(params);
        return response;
      },
      getPickupRequestbyId: async (params) => {
        const response = await getPickupRequestbyId(params);
        return response;
      },
      postPickupRequest: async (params) => {
        const response = await postPickupRequest(params);
        return response;
      },
      editPickupRequest: async (params,data) => {
        const response = await editPickupRequest(params,data);
        return response;
      },
      importPickupRequests: async (params) => {
        const response = await importPickupRequests(params);
        return response;
      },
      importPickupRequestsProof: async (params,data) => {
        const response = await importPickupRequestsProof(params,data);
        return response;
      },
      getPickupTemplate: async () => {
        const response = await getPickupTemplate();
        return response;
      },
      exportPickupRequests: async (params) => {
        const response = await exportPickupRequests(params);
        return response;
      },
      cancelPickupRequest: async (params,data) => {
        const response = await cancelPickupRequest(params,data);
        return response;
      },
      applyPickupRequest: async (params,data) => {
        const response = await applyPickupRequest(params,data);
        return response;
      },
      applyBulkPickupRequest: async (params) => {
        const response = await applyBulkPickupRequest(params);
        return response;
      },
      getPackageTrackEvent: async (params) => {
        const response = await getPackageTrackEvent(params);
        return response;
      },
      postPickupConsignment: async (params,data) => {
        const response = await postPickupConsignment(params,data);
        return response;
      },
      applyConsignments: async (data) =>{
        const response = await applyConsignments(data);
        return response;
      },
      bulkapplyConsignments: async (params,data) =>{
        const response = await bulkapplyConsignments(params,data);
        return response;
      },
      revokePickupConsignment: async (params) =>{
        const response = await revokePickupConsignment(params);
        return response;
      },
      revokeCancelConsignment: async (params) =>{
        const response = await revokeCancelConsignment(params);
        return response;
      },
      revokeDeliveryConsignment: async (params) =>{
        const response = await revokeDeliveryConsignment(params);
        return response;
      },
      revokeHoldConsignment: async (params) =>{
        const response = await revokeHoldConsignment(params);
        return response;
      },
      importDdu: async (params) =>{
        const response = await importDdu(params);
        return response;
      },
      dduUpdateTemplate: async () =>{
        const response = await dduUpdateTemplate();
        return response;
      },
      deletePickupConsignment: async (params,params2) => {
        const response = await deletePickupConsignment(params,params2);
        return response;
      },
      getConsignments: async (params) => {
        const response = await getConsignments(params);
        return response;
      },
      getConsignmentsDestination: async (params) => {
        const response = await getConsignmentsDestination(params);
        return response;
      },
      getPickupConsignments: async (params,data) => {
        const response = await getPickupConsignments(params,data);
        return response;
      },
      exportConsignments: async (params) => {
        const response = await exportConsignments(params);
        return response;
      },
      getConsignmentStatus: async () => {
        const response = await getConsignmentStatus();
        set({ consignmentStatus: response });
        return response;
      },
      getPickuprequestStatus: async () => {
        const response = await getPickuprequestStatus();
        set({ pickupStatus: response });
        return response;
      },
      setisPickupRequest: (data) => set({ isPickupRequest: data }),
});

pickupStore = devtools(pickupStore);

export const usepickupStore = create(pickupStore);