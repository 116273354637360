import React, { useEffect, useState, useRef, useContext } from "react";
import downloadicon from "../../../src/assets/img/download_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import removeIcon from "../../../src/assets/img/cancelIcon.svg";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import { useAoneStore } from "../../store/AoneStore";
import { UserContext } from "../../helper/ProtectedRoute";
import exportFromJSON from "export-from-json";
const Roles = () => {
  const getRoles = useAoneStore((state) => state?.getRoles);
  const syncRoles = useAoneStore((state) => state?.syncRoles);
  const ModuleList = useAoneStore((state) => state.ModuleList);
  const [roles, setRoles] = useState([]);
  const getAllModules = useAoneStore((state) => state?.getAllModules);
  const [name, setName] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [editHandler, setEditHandler] = useState(false); // To Update While Delete - Complexity
  const [deleteHandler, setDeleteHandler] = useState(false); // Reload Page While Role Is Deleted
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const prevent = useRef(false);

  const getData = async () => {
    const params = `?page=${newPage}&size=${size}&sortBy=${sortField}&name=${name}`;
    const data = await getRoles(params);
    setTotalCount(data.data.data.totalElements);
    setRoles(data.data.data.content);
  };

  const syncronizeRoles = async () => {
    await syncRoles();
    setNewPage(0);
  };
  useEffect(() => {
    if (
      ModuleList?.data?.data?.length < 0 ||
      ModuleList?.data?.data?.length === undefined
    ) {
      getAllModules();
    }
  }, []);
  const fileName = "Roles";
  const exportType = "csv";

  const exportToExcel = () => {
    let data = roles.map((e) => ({
      id: e.id,
      role: e.name,
      app: e.app,
      description: e.description,
    }));
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    if (prevent.current) {
      const delayFn = setTimeout(() => {
        getData();
      }, 1500);
      return () => clearTimeout(delayFn);
    } else {
      prevent.current = true;
    }
  }, [name]);

  useEffect(() => {
    getData();
  }, [newPage, size, addModalHandler, editHandler, sortField, deleteHandler]);

  return (
    <div>
      {/* <div className='row alignItemCenter mb_16'>
          <h3 className='mainheadtext'>
            Roles
          </h3>
        </div> */}

      <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
        <div className="displayFlex justifyContent_end">
          <label className="fontSize14 mb_12 marginR10 mt_10">Role:</label>
          <div className="displayFlex alignItemCenter position-relative">
            <input
              type="text"
              className={
                name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
              }
              value={name}
              style={name?.length > 0 ? { border: "1px solid #ec171c" } : {}}
              onChange={(e) => (setName(e.target.value), setNewPage(0))}
            />
             {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => {setName("");}}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
          </div>
        </div>

        <div className="displayFlex gap_12">
          <div>
            <button
              className="filterBtn"
              title="Download As CSV"
              onClick={() => exportToExcel()}
            >
              <span>
                <img src={downloadicon} className="h18" />
              </span>
            </button>
          </div>
          {(role?.includes("AONE_ADMIN") ||
            role?.includes("AONE_IAM.write") ||
            role?.includes("AONE_IAM.admin") ||
            role?.includes("AONE_IAM_ROLES.write") ||
            role?.includes("AONE_IAM_ROLES.admin")) && (
            <div>
              <button
                className="filterBtn plus_icon"
                onClick={() => setAddModalHandler(!addModalHandler)}
                tabIndex="-1"
                title="Add"
              >
                +
              </button>
            </div>
          )}
          {(role?.includes("AONE_ADMIN") ||
            role?.includes("AONE_IAM.admin") ||
            role?.includes("AONE_IAM_ROLES.admin")) && (
            <div>
              <button className="filterBtn" title="Sync">
                <span>
                  <img
                    src={syncicon}
                    className="h18"
                    onClick={() => syncronizeRoles()}
                  />
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Add Roles"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />
      )}

      <PaginationTable
        tableData={roles}
        tbl="Roles"
        newPage={newPage}
        modalHandler={editHandler}
        setModalHandler={setEditHandler}
        setNewPage={setNewPage}
        size={size}
        setSize={setSize}
        totalCount={totalCount}
        setSortField={setSortField}
        sortField={sortField}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        individualCheckboxesbag={individualCheckboxesbag}
        setIndividualCheckboxesBag={setIndividualCheckboxesBag}
      />
    </div>
  );
};

export default Roles;
