import React from 'react'
import { Modal } from 'react-bootstrap';
const UploadSuccess = ({show, handleClose}) => {
  return (
    <Modal show={show} onHide={handleClose} className='multipleShipmentpopup'>
    <Modal.Body>
      <div className='mb_24 textAlignCenter'>
        <img src='/images/successfullyTick.svg' />
      </div>
      <h2 className='fontSize18 fontWeight600 satinDeepblackText textAlignCenter mb_12'>
        File has been uploaded successfully
      </h2>
      <p className='sharkFinText fontSize14 fontWeight500 textAlignCenter mb_24'>
        Click here to continue
      </p>
      <div className='displayFlex justifycontent_Center alignItem_center'>
        <div>
          <button className='Main_btn satinDeepblack_color'>
            Continue
          </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default UploadSuccess
