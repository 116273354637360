import { useEffect, useState, useRef, useContext } from "react";
import TableFooter from "./tablefooter";
import useTable from "./hook/tableRange";
import Pagination from "react-js-pagination";
import CusModal from "../modal/modal";
import OpenModal from "../modal/openModal";
import editicon from "../../../src/assets/img/editicon.svg";
import EyeIcon from "../../assets/img/eye_show.svg";
import DeleteIcon from "../../assets/img/delete_icon.svg";
import arrowRight from "../../assets/img/arrowright.svg";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import unassignIcon from "../../assets/img/unassign_icon.svg";
import DeletingModal from "../modal/deletingModal";
import PrintIcon from "../../assets/img/printorder.svg";
import CreateIcon from "../../assets/img/create_order.svg";
import PushIcon from "../../assets/img/push_arrow.svg";
import { UserContext } from "../../helper/ProtectedRoute";
const MainTable = ({
  tableData,
  tbl,
  restrict,
  updateName,
  tblDataDup,
  submit,
  cancleChange,
  handleDelete,
  setModalHandler,
  modalHandler,
  title,
  setEditPage,
  deleteHandler,
  setDeleteHandler,
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [show, setShow] = useState(Math.ceil(tableData?.length / 5));
  const [mul, setMul] = useState([20, 50, 100]);
  const { slice, range } = useTable(tableData, page, rowsPerPage);
  const [customdropdownOne, setCustomdropdownone] = useState(null);
  const strtIndex = useRef((page - 1) * rowsPerPage + 1);
  const endIndx = useRef(Math.min(page * rowsPerPage, tableData?.length));
  const dsplay = useRef(
    `${strtIndex.current}-${Math.min(page * rowsPerPage, tableData?.length)}/${
      tableData?.length
    }`
  );
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [modalData, setModalData] = useState([]);
  const [editHandler, setEditHandler] = useState(false);

  const handlePageChange = (e) => {
    setPage(e);
    strtIndex.current = (e - 1) * rowsPerPage + 1;
    endIndx.current = Math.min(e * rowsPerPage, tableData?.length);
    dsplay.current = `${strtIndex.current}-${endIndx.current}/${tableData?.length}`;
  };

  const deleteGroupById = useAoneStore((state) => state?.deleteGroupById);
  const unassignRolesOfUserId = useAoneStore(
    (state) => state?.unassignRolesOfUserId
  );
  const unassignGroupsOfUserId = useAoneStore(
    (state) => state?.unassignGroupsOfUserId
  );
  const unassignRolesOfGroupId = useAoneStore(
    (state) => state?.unassignRolesOfGroupId
  );

  const params = useParams();
  const location = useLocation();

  const handleAssigneeDelete = async (id) => {
    if (tbl == "Users_Roles" && location.pathname.includes("/groups/")) {
      await unassignRolesOfGroupId(params?.id, id);
    } else if (
      tbl == "Users_Roles" &&
      !location.pathname.includes("/groups/")
    ) {
      await unassignRolesOfUserId(params.id, id);
    } else if (tbl == "Groups_Users") {
      await unassignGroupsOfUserId(id, params?.id);
    } else if (tbl == "Users_Groups") {
      await unassignGroupsOfUserId(params?.id, id);
    }
    setDeleteHandler(!deleteHandler);
  };

  const handleGroupDelete = async (id) => {
    await deleteGroupById(id);
    setDeleteHandler(!deleteHandler);
  };

  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });

  const deleteParamsSetter = (id, name, title) => {
    setDeleteParams({
      id: id,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };

  // const loop = () => {
  //   let ddata = [];
  //   var dshow = Math.ceil(tableData?.length / 5)
  //   for (let a = 1; a <= dshow; a++) {
  //     ddata.push(a);
  //   }
  //   setMul(ddata);
  // };

  useEffect(() => {
    strtIndex.current = (page - 1) * rowsPerPage + 1;
    endIndx.current = Math.min(page * rowsPerPage, tableData?.length);
    dsplay.current = `${strtIndex.current}-${endIndx.current}/${tableData?.length}`;
    setShow(Math.ceil(tableData?.length / 5));
    // loop();
  }, [tableData?.length]);

  const navigate = useNavigate();

  return (
    <>
      <div className="table_wrapper overflowScroll mb_24 showTenRows">
        <table>
          <thead>
            <tr>
              {tbl === "onDemand" ? (
                <>
                  <th className="fixedColH col_no zIndex999">#</th>
                  {!restrict?.includes("waybillnumber") && (
                    <th className="fixedColH col_Firstname zIndex999">
                      Waybill Number
                    </th>
                  )}
                  {!restrict?.includes("bookingHub") && <th>Booking Hub</th>}
                  {!restrict?.includes("deliveryHub") && <th>Delivery Hub</th>}
                  {!restrict?.includes("creationTime") && (
                    <th>Creation Time</th>
                  )}
                  {!restrict?.includes("pickUpTime") && <th>Pick up Time</th>}
                  {!restrict?.includes("pickby") && <th>Picked By</th>}
                  {!restrict?.includes("OfdTime") && <th>OFD Time</th>}
                  {!restrict?.includes("deliveredBy") && <th>Delivered By</th>}
                  {!restrict?.includes("deliveryTime") && (
                    <th>Delivery Time</th>
                  )}
                  {!restrict?.includes("serviceType") && <th>Service Type</th>}
                  {!restrict?.includes("status") && <th>Status</th>}
                  {!restrict?.includes("sla") && <th>SLA</th>}
                  {!restrict?.includes("customerName") && (
                    <th>Customer Name</th>
                  )}
                  {!restrict?.includes("customerCode") && (
                    <th>Customer code</th>
                  )}
                  {!restrict?.includes("timeSinceCreation") && (
                    <th>Time Since Order Creation</th>
                  )}
                  {!restrict?.includes("timeSincePickup") && (
                    <th>Time Since Pick up</th>
                  )}
                  {!restrict?.includes("pickupDuration") && (
                    <th>Pickup Duration</th>
                  )}
                  {!restrict?.includes("deliveryDuration") && (
                    <th>Delivery Duration</th>
                  )}
                </>
              ) : tbl === "sla" ? (
                <>
                  <th>Service Type</th>
                  <th>Customer Code</th>
                  <th>SLA Hours</th>
                  <th>Country</th>
                  <th>Origin City</th>
                  <th>Destination City</th>
                  {/* <th>Action</th> */}
                </>
              ) : tbl === "ZIA" ? (
                <>
                  <th className="fixedColH col_no zIndex999">#</th>
                  {!restrict?.includes("storeId") && (
                    <th className="fixedColH col_Firstname zIndex999">
                      Store Id
                    </th>
                  )}
                  {!restrict?.includes("productName") && <th>Product Name</th>}
                  {!restrict?.includes("sku") && <th>SKU</th>}
                  {!restrict?.includes("quantity") && <th>Quantity</th>}
                  <th>Action</th>
                  {/* <th>Product Image Url</th>
                  <th>Parent Product</th> */}
                </>
              ) : tbl === "ZIM" ? (
                <>
                  <th className="fixedColH col_no zIndex999">#</th>
                  {!restrict?.includes("storeId") && (
                    <th className="fixedColH col_Firstname zIndex999">
                      Store Id
                    </th>
                  )}
                  {!restrict?.includes("storeName") && <th>Store Name</th>}
                  {!restrict?.includes("customerAccount") && (
                    <th>Customer Account</th>
                  )}
                  {!restrict?.includes("expressType") && <th>Express Type</th>}
                  {!restrict?.includes("pickupMethod") && (
                    <th>Pick Up Method</th>
                  )}
                  {!restrict?.includes("appType") && <th>App Type</th>}
                  <th>Action</th>
                  {/* <th>Product Image Url</th>
                  <th>Parent Product</th> */}
                </>
              ) : tbl === "ZIO" ? (
                <>
                  <th>#</th>
                  <th>Order Id</th>
                  <th>Store Id</th>
                  <th>Waybill Number</th>
                  <th>Store Name</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Roles" ? (
                <>
                  <th>Role</th>
                  <th>App</th>
                  <th>Description</th>
                  <th>Action</th>
                </>
              ) : tbl === "Roles_Users" ? (
                <>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Full Name</th>
                  <th>Action</th>
                </>
              ) : tbl === "Users_Roles" ? (
                <>
                  <th>#</th>
                  <th>Role</th>
                  <th>Description</th>
                  <th>Action</th>
                </>
              ) : tbl === "Users_Groups" ? (
                <>
                  <th>#</th>
                  <th>Group</th>
                  <th>Path</th>
                  <th>Action</th>
                </>
              ) : tbl === "Groups_Users" ? (
                <>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </>
              ) : tbl === "Countries" ? (
                <>
                  <th>#</th>
                  <th>Name</th>
                  <th>ISO Code 2</th>
                  <th>ISO Code 3</th>
                  <th>ISO Numeric</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Regions" ? (
                <>
                  <th>#</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Configs" ? (
                <>
                  <th>#</th>
                  <th>Configuration Name</th>
                  <th>Description</th>
                  <th>Actions</th>
                  <th>Chute Mapping</th>
                </>
              ) : tbl === "DetailedConfig" ? (
                <>
                  <th>#</th>
                  <th>Unit Zone</th>
                  <th>Chute Number</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Agents" ? (
                <>
                  <th>#</th>
                  <th>Agent Id</th>
                  <th>Assigned Configuration</th>
                  <th>Status</th>
                  <th>Actions</th>
                </>
              ) : tbl === "Child_Groups" ? (
                <>
                  <th>#</th>
                  <th>Group Name</th>
                  {(role?.includes("AONE_ADMIN") ||
                    role?.includes("AONE_IAM.admin") ||
                    role?.includes("AONE_IAM_GROUPS.admin")) && (
                    <th>Actions</th>
                  )}
                </>
              ) : tbl === "Sub_Accounts" ? (
                <>
                  <th>#</th>
                  <th>Sub Account Number</th>
                  <th>Name</th>
                  <th>Billing Address</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Actions</th>
                </>
              ) : (
                <td className="textAlignCenter" colSpan={19}>
                  Yet To Be Implemented
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {slice?.length > 0 ? (
              slice.map((e, i) => {
                return (
                  <>
                    {tbl === "onDemand" ? (
                      <>
                        <tr key={e.id}>
                          <td className="fixedCol col_no" scope="row">
                            {i + 1}
                          </td>
                          {!restrict?.includes("waybillnumber") && (
                            <td className="fixedCol col_Firstname" scope="row">
                              {e.waybillnumber}
                            </td>
                          )}
                          {!restrict?.includes("bookingHub") && (
                            <td>{e.bookingHub}</td>
                          )}
                          {!restrict?.includes("deliveryHub") && (
                            <td>{e.deliveryHub}</td>
                          )}
                          {!restrict?.includes("creationTime") && (
                            <td>
                              {new Date(e?.creationTime)?.toLocaleDateString()}
                            </td>
                          )}
                          {!restrict?.includes("pickUpTime") && (
                            <td>{e.pickUpTime}</td>
                          )}
                          {!restrict?.includes("pickby") && <td>{e.pickby}</td>}
                          {!restrict?.includes("OfdTime") && (
                            <td>{e.OfdTime}</td>
                          )}
                          {!restrict?.includes("deliveredBy") && (
                            <td>{e.deliveredBy}</td>
                          )}
                          {!restrict?.includes("deliveryTime") && (
                            <td>{e.deliveryTime}</td>
                          )}
                          {!restrict?.includes("serviceType") && (
                            <td>{e.serviceType}</td>
                          )}
                          {!restrict?.includes("status") && <td>{e.status}</td>}
                          {!restrict?.includes("sla") && <td>{e.sla}</td>}
                          {!restrict?.includes("customerName") && (
                            <td>{e.customerName}</td>
                          )}
                          {!restrict?.includes("customerCode") && (
                            <td>{e.customerCode}</td>
                          )}
                          {!restrict?.includes("timeSinceCreation") && (
                            <td>{e.timeSinceCreation}</td>
                          )}
                          {!restrict?.includes("timeSincePickup") && (
                            <td>{e.timeSincePickup}</td>
                          )}
                          {!restrict?.includes("pickupDuration") && (
                            <td>{e.pickupDuration}</td>
                          )}
                          {!restrict?.includes("deliveryDuration") && (
                            <td>{e.deliveryDuration}</td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "sla" ? (
                      <>
                        <CusModal
                          key={e.id}
                          btnName=""
                          title="View SLA"
                          initialData={tblDataDup[i]}
                          setStateData={updateName}
                          submit={submit}
                          cancleChange={cancleChange}
                          handleDelete={handleDelete}
                        >
                          <td>{e.serviceType}</td>
                          <td>{e.customerAccount}</td>
                          <td>{e.slaMinutes}</td>
                          <td>{e.country}</td>
                          <td>{e.originCity}</td>
                          <td>{e.destinationCity}</td>
                          {/* <td></td> */}
                        </CusModal>
                        {/* <img src={delete_icon} onClick={()=>handleDelete(e.id)}/> */}
                      </>
                    ) : tbl === "ZIA" ? (
                      <>
                        <tr className="cursorPointer">
                          <td className="fixedCol col_no" scope="row">
                            {i + 1}
                          </td>
                          {!restrict?.includes("storeId") && (
                            <td className="fixedCol col_Firstname" scope="row">
                              {e?.storeId}
                            </td>
                          )}
                          {!restrict?.includes("productName") && (
                            <td>{e?.productName?.en}</td>
                          )}
                          {!restrict?.includes("sku") && <td>{e?.sku}</td>}
                          {!restrict?.includes("quantity") && (
                            <td>{e?.quantity}</td>
                          )}
                          <td>
                            <button
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                              className="tableBtn"
                            >
                              <span>
                                <img src={EyeIcon} />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              onClick={() => (
                                setEditHandler(!editHandler),
                                setModalHandler(!modalHandler),
                                setModalData(e)
                              )}
                              className="tableBtn"
                            >
                              <span>
                                <img src={editicon} />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "ZIM" ? (
                      <>
                        <tr className="cursorPointer">
                          <td className="fixedCol col_no" scope="row">
                            {i + 1}
                          </td>
                          {!restrict?.includes("storeId") && (
                            <td className="fixedCol col_Firstname" scope="row">
                              {e?.storeId}
                            </td>
                          )}
                          {!restrict?.includes("storeName") && (
                            <td>{e?.storeName}</td>
                          )}
                          {!restrict?.includes("customerAccount") && (
                            <td>{e?.customerAccount}</td>
                          )}
                          {!restrict?.includes("expressType") && (
                            <td>{e?.expressType}</td>
                          )}
                          {!restrict?.includes("pickupMethod") && (
                            <td>{e?.pickupMethod}</td>
                          )}
                          {!restrict?.includes("appType") && (
                            <td>{e?.appType}</td>
                          )}
                          <td>
                            <button
                              onClick={() => (
                                setModalHandler(!modalHandler), setModalData(e)
                              )}
                              title="View"
                              className="tableBtn"
                            >
                              <span>
                                <img src={EyeIcon} />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button
                              onClick={() => (
                                setEditHandler(!editHandler),
                                setModalHandler(!modalHandler),
                                setModalData(e)
                              )}
                              title="Edit"
                              className="tableBtn"
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "ZIO" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.orderId}</td>
                          <td>{e?.storeId}</td>
                          <td>{e?.waybillno}</td>
                          <td>{e?.storeName}</td>
                          <td>{e?.status}</td>
                          <td>{e?.createdAt}</td>
                          <td>{e?.updatedAt}</td>
                          <td>
                            <button className="tableBtn" title="Print">
                              <span>
                                <img src={PrintIcon} />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Push">
                              <span>
                                <img src={PushIcon} />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Create">
                              <span>
                                <img src={CreateIcon} />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Roles" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{e?.role}</td>
                          <td>{e?.app}</td>
                          <td>{e?.description}</td>
                          <td>
                            <button
                              className="tableBtn"
                              onClick={() => navigate("/iam/roles/123")}
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn">
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Roles_Users" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.username}</td>
                          <td>{e?.fullname}</td>
                          <td>
                            <button className="tableBtn" title="Unassign">
                              <span>
                                <img src={unassignIcon} className="turnBlack" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Users_Roles" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.name}</td>
                          <td>{e?.description}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Unassign"
                              onClick={() => handleAssigneeDelete(e?.id)}
                            >
                              <span>
                                <img src={unassignIcon} className="turnBlack" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Users_Groups" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.name}</td>
                          <td>{e?.path}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="unassign"
                              onClick={() => handleAssigneeDelete(e?.id)}
                            >
                              <span>
                                <img src={unassignIcon} className="turnBlack" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Groups_Users" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.username}</td>
                          <td>{e?.email}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="unassign"
                              onClick={() => handleAssigneeDelete(e?.id)}
                            >
                              <span>
                                <img src={unassignIcon} className="turnBlack" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Countries" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.name}</td>
                          <td>{e?.isoCode2}</td>
                          <td>{e?.isoCode3}</td>
                          <td>{e?.isoNumeric}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => (
                                setEditPage(true),
                                navigate(`/configs/addresses/${e.id}`)
                              )}
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Edit">
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Delete">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Regions" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.name}</td>
                          <td>{e?.code}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => (
                                setEditPage(true),
                                navigate(`/configs/addresses/${e.id}`)
                              )}
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Edit">
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Delete">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Configs" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.name}</td>
                          <td>{e?.description}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View"
                              onClick={() => (
                                setEditHandler(!editHandler),
                                setModalHandler(!modalHandler),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={EyeIcon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Delete">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="View Mapping"
                              onClick={() =>
                                navigate(
                                  `/middlemile/sortingmachine/chute/${e.id}`
                                )
                              }
                            >
                              <span>
                                <img
                                  src={arrowRight}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "DetailedConfig" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.zoneCode}</td>
                          <td>{e?.chuteNumber}</td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setEditHandler(!editHandler),
                                setModalHandler(!modalHandler),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Delete">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Agents" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.agentId}</td>
                          <td>{e?.sortingName}</td>
                          <td>
                            {e?.active ? (
                              <p className="status_active">Active</p>
                            ) : (
                              <p className="status_inactive">Inactive</p>
                            )}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              title="Edit"
                              onClick={() => (
                                setEditHandler(!editHandler),
                                setModalHandler(!modalHandler),
                                setModalData(e)
                              )}
                            >
                              <span>
                                <img src={editicon} className="turnBlack" />
                              </span>
                            </button>{" "}
                            &nbsp;
                            <button className="tableBtn" title="Delete">
                              <span>
                                <img
                                  src={DeleteIcon}
                                  className="h18 turnBlack"
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : tbl === "Child_Groups" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.name}</td>
                          {(role?.includes("AONE_ADMIN") ||
                            role?.includes("AONE_IAM.admin") ||
                            role?.includes("AONE_IAM_GROUPS.admin")) && (
                            <td>
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={() =>
                                  deleteParamsSetter(e?.id, e?.name, "Group")
                                }
                              >
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            </td>
                          )}
                        </tr>
                      </>
                    ) : tbl === "Sub_Accounts" ? (
                      <>
                        <tr className="cursorPointer">
                          <td>{i + 1}</td>
                          <td>{e?.subAccountNo}</td>
                          <td>{e?.subAccountName}</td>
                          <td>
                            {e?.customerBillingAddress?.billingAddrsStreet}
                          </td>
                          <td>{e?.customerBillingAddress?.billingEmail}</td>
                          <td>
                            {e?.customerBillingAddress?.billingContactPhone}
                          </td>
                          <td>
                            <button
                              className="tableBtn"
                              onClick={() => (
                                setEditHandler(!editHandler),
                                setModalHandler(!modalHandler),
                                setModalData(e)
                              )}
                              title="View"
                            >
                              <span>
                                <img src={EyeIcon} className="h18 turnBlack" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })
            ) : (
              <>
                <tr>
                  <td>No Data</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      {/* <TableFooter range={range} slice={slice} setPage={setPage} page={page} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage}/> */}
      {modalHandler && (
        <OpenModal
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          title={title}
          data={modalData}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />
      )}
      {/* Delete Box Handler */}
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
      <div className="displayFlex alignItemCenter justifyContent_spacebetween paginationFooter">
        {/* <div></div> */}
        <div className="displayFlex gap_16 alignItemCenter">
          <label className="fontSize14 blackText mb_12">Show </label>
          <div>
            <div className="positionRelative">
              <input
                type="text"
                readOnly
                placeholder={rowsPerPage}
                className="seletDropdownIcOnPage"
                onClick={() => setCustomdropdownone(!customdropdownOne)}
              />
              {customdropdownOne && (
                <div className="dropdownResult appearTop">
                  {mul.map((d, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          className="showPerItem"
                          onClick={() => {
                            setShow(Math.ceil(tableData.length / d));
                            setRowsPerPage(d);
                            setCustomdropdownone(!customdropdownOne);
                            strtIndex.current = (page - 1) * d + 1;
                            endIndx.current = Math.min(
                              page * d,
                              tableData.length
                            );
                            dsplay.current = `${strtIndex.current}-${endIndx.current}/${tableData.length}`;
                          }}
                        >
                          {d}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="customPagination">
          <Pagination
            activePage={page}
            itemsCountPerPage={Number(rowsPerPage)}
            totalItemsCount={tableData?.length}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </div>
        <div>
          <p>{dsplay.current}</p>
        </div>
      </div>
    </>
  );
};

export default MainTable;
