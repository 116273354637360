import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import PaginationTable from "../../../components/Tables/paginationTable";
import OpenModal from "../../../components/modal/openModal";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import downloadicon from "../../../assets/img/download_icon.svg";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Servicearea = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [hub, setHub] = useState("");
  const [zone, setZone] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [state] = useContext(UserContext);
  const [page, setPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const allowedeventData = useAoneStore((state) => state?.allowedeventData);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const [editHandler, setEditHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("createdAt");
  const [selectedOption, setSelectedOption] = useState(null);
  const getHubTypes = useAoneStore((state) => state?.getHubTypes);
  const globalHubCode = useAoneStore((state) => state.globalHubCode);
  const getZone = useAoneStore((state) => state?.getZone);
  const getServiceAreas = useAoneStore((state) => state?.getServiceAreas);
  const getServiceAreasTemplate = useAoneStore((state) => state?.getServiceAreasTemplate);
  const exportServiceAreas = useAoneStore((state) => state?.exportServiceAreas);
  const importServiceAreas = useAoneStore((state) => state?.importServiceAreas);
  const allowedeventLookup = useAoneStore((state) => state?.allowedeventLookup);
  const setglobalHubCode = useAoneStore((state) => state?.setglobalHubCode);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const getRegions = useAoneStore((state) => state?.getRegions);
  const getCities = useAoneStore((state) => state?.getCities);
  const hubTypes = useAoneStore((state) => state?.hubTypes);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const getData = async () => {
    if (params?.id) {
      setglobalHubCode(params?.id);
    }
    let paramslink = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (zone) {
      paramslink += `&zoneCode=${zone}`;
    }
    if (params?.id) {
      paramslink += `&hubCode=${params?.id}`;
    } else {
      paramslink += `&hubCode=${globalHubCode}`;
    }
    const data = await getServiceAreas(paramslink);
    setTotalCount(data.data.data.totalElements);
    setServiceData(data.data.data.content);
  };
  useEffect(() => {
    if (selectedOption) {
      setZone(selectedOption.value);
    }
  }, [selectedOption]);
  const downloadTemplate = async () => {
    try {
      const res = await getServiceAreasTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Service Areas Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            importServiceAreas(params?.id,formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessage = response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpens = () => {
    setIsMenuOpen(true);
  };

  const handleMenuCloses = () => {
    setIsMenuOpen(false);
  };
  const exportToExcel = async () => {
    let paramslink = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (zone) {
      paramslink += `&zoneCode=${zone}`;
    }
    if (params?.id) {
      paramslink += `&hubCode=${params?.id}`;
    } else {
      paramslink += `&hubCode=${globalHubCode}`;
    }
    try {
      const res = await exportServiceAreas(paramslink);
      if (res.status == 200) {
        fileDownload(res.data, `Service areas.xlsx`);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    location,
    saveHandler,
    zone,
    hub,
    ispostAdded,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.data?.data?.content?.length == undefined
    ) {
      getCountries(paramslink);
    }
    if (
      regionsData?.data?.data?.content?.length < 1 ||
      regionsData?.data?.data?.content?.length == undefined
    ) {
      getRegions(paramslink);
    }
    if (
      citiesData?.data?.data?.content?.length < 1 ||
      citiesData?.data?.data?.content?.length == undefined
    ) {
      getCities(paramslink);
    }
    if (
      hubTypes?.data?.data?.length < 1 ||
      hubTypes?.data?.data?.length == undefined
    ) {
      getHubTypes();
    }
    if (
      allowedeventData?.data?.data?.length < 1 ||
      allowedeventData?.data?.data?.length == undefined
    ) {
      allowedeventLookup();
    }
  }, []);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;

    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Trigger a state update to increment the page number
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await getZone(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  return (
    <div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5" style={{ width: "290px !important" }}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Zone:
                  </label>
                  <div style={{ width: "165px" }}>
                    <AsyncSearchInput
                      id="zoneCode"
                      label="Zone"
                      name="zoneCode"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onChange={(selectedOption) => {
                        if (selectedOption === null) {
                          setSelectedOption(null);
                          setZone("");
                        } else {
                          setSelectedOption(selectedOption);
                        }
                      }}
                      placeholder="Zone"
                      onMenuOpen={handleMenuOpens}
                      onMenuClose={handleMenuCloses}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
              {(roleHandler(role, "AONE_ASSETS.write") ||
                  roleHandler(role, "AONE_ASSETS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
              <>
                        <button
                          style={{ marginLeft: "10px" }}
                          className="filterBtn plus_icon"
                          title="Service area Template"
                          onClick={downloadTemplate}
                        >
                          <span>
                            <img src={templateIcon} className="h18" />
                          </span>
                        </button>
                        <div title="Import service area">
                          <label
                            className="filterBtn plus_icon"
                            htmlFor="uploadFile"
                          >
                            <span>
                              <img src={importIcon} className="h18" />
                            </span>
                            <input
                              type="file"
                              id="uploadFile"
                              onInput={(e) => handleFileChange(e)}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              hidden
                            />
                          </label>
                        </div>
                      </>)}
                      <button
                    className="filterBtn"
                    title={"Export service area"}
                    onClick={() => exportToExcel()}
                  >
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_ASSETS.write") ||
                  roleHandler(role, "AONE_ASSETS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => setAddModalHandler(!addModalHandler)}
                    tabIndex="-1"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={serviceData}
            tbl={"Service Areas"}
            title={"Edit Service Areas"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            isMenuOpen={isMenuOpen}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Create service area"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />)}
      </div>
    </div>
  );
};

export default Servicearea;
