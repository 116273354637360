import React from 'react'
import { Modal } from 'react-bootstrap';
import removeIcon from '../../assets/img/remove_error.svg'

const UploadErrorModal = ({show, handleClose,errorMessage}) => {
  return (
    <Modal show={show} onHide={handleClose} className='multipleShipmentpopup'>
      <Modal.Body>
        <div className='mb_24 textAlignCenter'>
  <img src={removeIcon} alt='Remove Error'/>
</div>
        <h2 className='fontSize18 fontWeight600 satinDeepblackText textAlignCenter mb_12'>
          We are Sorry!
        </h2>
        <p className='sharkFinText fontSize14 fontWeight500 textAlignCenter mb_24'>
              <div className='bloodDonortext fontSize14 fontWeight600 textAlignCenter mb_24'>
                <p>{errorMessage}</p>
              </div>
        </p>
        <div className='displayFlex justifycontent_Center alignItem_center gap12'>
          <div>
            <button className='Main_btn Secondary_color' onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UploadErrorModal
