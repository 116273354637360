import AoneService from "../axiosbase";
const url = process.env.REACT_APP_BASE_API_URL;
//Localizations
//get localizations
export const getLocalization = async (params) => {
    try  {
        return await AoneService.get(`settings/api/v1/localizations${params}`)
    } catch (error) {
        return error;
    }
}
//post localaizations
export const postLocalization = async (params,data) => {
    try {
      return await AoneService.post(`settings/api/v1/localizations?component=${params}`,data);
    } catch (error) {
      return error;
    }
  };

  //edit localization
  export const editLocalization = async (params,data) => {
   try {
     return await AoneService.put(`settings/api/v1/localizations?component=${params}`,data);
   } catch (error) {
     return error;
   }
 };

//get localizations by id
export const getLocalizationbyId = async (params) => {
    try {
      return await AoneService.get(`settings/api/v1/localizations/${params}`);
    } catch (error) {
      return error;
    }
  };
//get localizations by language and component
export const getLocalizationbyComponent = async (params,params2) => {
   try {
     return await AoneService.post(`settings/api/v1/localizations/${params}?component=${params2}`);
   } catch (error) {
     return error;
   }
 };
//localization template
export const localizationTemplate = async () => {
    try {
      return await AoneService.get(
        `settings/api/v1/localizations/template/download`,
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };

//localaization import
export const importLocalization = async (params,data) => {
    try {
      return await AoneService.post(`settings/api/v1/localizations/upload?component=${params}`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };

//localization export
export const exportLocalization = async (params) => {
   try {
     return await AoneService.get(`settings/api/v1/localizations/download?component=${params}`, {
       responseType: "blob",
     });
   } catch (error) {
     return error;
   }
 };

 //delete localization
 export const deleteLocalization = async (params) => {
   try {
     return await AoneService.delete(`settings/api/v1/localizations/${params}`);
   } catch (error) {
     return error;
   }
 }

 //language
 export const getLanguage = async (params,params2) => {
  try  {
      return await AoneService.get(`settings/api/v1/languages`)
  } catch (error) {
      return error;
  }
}

//settings api's
//get systems
export const getSettings = async (params) => {
  try  {
      return await AoneService.get(`settings/api/v1/systems${params}`)
  } catch (error) {
      return error;
  }
}
//post systems
export const postSettings = async (params,data) => {
  try {
    return await AoneService.post(`settings/api/v1/systems?component=${params}`,data);
  } catch (error) {
    return error;
  }
};

//edit systems
export const editSettings = async (params,data) => {
 try {
   return await AoneService.put(`settings/api/v1/systems?component=${params}`,data);
 } catch (error) {
   return error;
 }
};

//get systems by id
export const getSettingsbyId = async (params) => {
  try {
    return await AoneService.get(`settings/api/v1/systems/${params}`);
  } catch (error) {
    return error;
  }
};
//get systems by language and component
export const getSettingsbyComponent = async (params,params2) => {
 try {
   return await AoneService.post(`settings/api/v1/systems/${params}?component=${params2}`);
 } catch (error) {
   return error;
 }
};
//systems template
export const settingsTemplate = async () => {
  try {
    return await AoneService.get(
      `settings/api/v1/systems/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

//systems import
export const importSettings = async (params,data) => {
  try {
    return await AoneService.post(`settings/api/v1/systems/upload?component=${params}`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

//systems export
export const exportSettings = async (params) => {
 try {
   return await AoneService.get(`settings/api/v1/systems/download?component=${params}`, {
     responseType: "blob",
   });
 } catch (error) {
   return error;
 }
};

//delete systems
export const deleteSettings = async (params) => {
 try {
   return await AoneService.delete(`settings/api/v1/systems/${params}`);
 } catch (error) {
   return error;
 }
}