import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../../store/AoneStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../../components/Tables/paginationTable";
import OpenModal from "../../../components/modal/openModal";
import * as XLSX from "xlsx";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
const Currency = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code,setCode] = useState("")
  const [name,setName] = useState("")
  const [number,setNumber] = useState("")
  const [rate,setRate] = useState("")
  const [fromCurrency,setFromcurrency] = useState("")
  const [toCurrency,setTocurrency] = useState("")
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [modalHandler, setModalHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [currencyArray,setCurrencyData] = useState([])
  const [isDelete, setIsDelete] = useState("");
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [currencyConversionData,setCurrencyConversionData] = useState([])
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const [isDropdownselectOpencurrency, setIsDropdownOpencurrency] = useState(false);
  const [isDropdownselectOpenconv, setIsDropdownOpenconv] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpencurrency(true);
  };

  const handleMenuClose = () => {
    setIsDropdownOpencurrency(false);
  };
  const handleMenuOpenconv = () => {
    setIsDropdownOpenconv(true);
  };

  const handleMenuCloseconv = () => {
    setIsDropdownOpenconv(false);
  };
  const getCurrency = useAoneStore((state) => state?.getCurrency);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const getCurrencyTemplate = useAoneStore((state) => state?.getCurrencyTemplate);
  const exportCurrency = useAoneStore((state) => state?.exportCurrency);
  const importCurrency = useAoneStore((state) => state?.importCurrency);
  const getCurrencyConversion = useAoneStore((state) => state?.getCurrencyConversion);
  const getCurrencyConversionTemplate = useAoneStore((state) => state?.getCurrencyConversionTemplate);
  const exportCurrencyConversion = useAoneStore((state) => state?.exportCurrencyConversion);
  const importCurrencyConversion = useAoneStore((state) => state?.importCurrencyConversion);
  const currencyData = useAoneStore((state) => state?.currencyData);
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const downloadTemplate = async () => {
    try {
      if (tab === 1) {
        const res = await getCurrencyTemplate();
        if (res?.status == 200) {
          fileDownload(res.data, `Currency Template.xlsx`);
        }
      } else {
        const res = await getCurrencyConversionTemplate();
        if (res?.status == 200) {
          fileDownload(res.data, `Currency Conversion Template.xlsx`);
        }
      }
    } catch (err) {}
  }
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            let importFunction;
            switch (tab) {
              case 1:
                importFunction = importCurrency;
                break;
              case 2:
                importFunction = importCurrencyConversion;
                break;
              default:
                importFunction = importCurrency;
            }
            importFunction(formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };

  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (code) {
        params += `&code=${code}`;
      }
      if (number) {
        params += `&isoNumber=${number}`;
      }
      if (name) {
        params += `&name=${name}`;
      }
      try {
        const res = await exportCurrency(params);
        if (res.status == 200) {
          fileDownload(res.data, `Currency.xlsx`);
        }
      } catch (err) {}
    } else {
      if (fromCurrency) {
        params += `&fromCurrency=${fromCurrency}`;
      }
      if (toCurrency) {
        params += `&toCurrency=${toCurrency}`;
      }
      if (rate) {
        params += `&rate=${rate}`;
      }
      try {
        const res = await exportCurrencyConversion(params);
        if (res.status == 200) {
          fileDownload(res.data, `Currency Conversion.xlsx`);
        }
      } catch (err) {}
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (code) {
        params += `&code=${code}`;
      }
      if (number) {
        params += `&isoNumber=${number}`;
      }
      if (name) {
        params += `&name=${name}`;
      }
      const data = await getCurrency(params);
      setTotalCount(data.data.data.totalElements);
      setCurrencyData(data.data.data.content);
    }else{
      if (fromCurrency) {
        params += `&fromCurrency=${fromCurrency}`;
      }
      if (toCurrency) {
        params += `&toCurrency=${toCurrency}`;
      }
      if (rate) {
        params += `&rate=${rate}`;
      }
      const data = await getCurrencyConversion(params);
      setTotalCount(data.data.data.totalElements);
      setCurrencyConversionData(data.data.data.content);
    }
  }
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    tab,
    editPage,
    sortField,
    location,
    ispostAdded,
    statusHandler,
    saveHandler,
    isDelete,
    code,
    name,
    number,
    rate,
    fromCurrency,
    toCurrency,
    rate,
  ]);
  return (
    <div>
       <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/configs/currency")}
              >
                Configs
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/configs/currency")}
              >
                Currency
              </span>
            </li>
            {tab === 2 &&
            <><li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                <span>
                  <img
                    src={breadArrow}
                    className="turnBlack"
                    alt="breadcrumbarrow" />
                </span>
              </li><li>
                  <span
                    className="mainheadlink"
                    style={{ fontWeight: "500", marginLeft: "-3px" }}
                    onClick={() => navigate("/configs/currency")}
                  >
                    Conversions
                  </span>
                </li></>}
          </ul>
        </div>
      </div>

      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => {tabHandler(1); setSortField("-updatedAt");}}
        >
          Currency
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {tabHandler(2); setSortField("-updatedAt");}}
        >
          Currency Conversions
        </button>
      </div>
      <div>
      {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
      {tab === 1 ? (
              <div className="displayFlex j1ustifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Code:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={code}
                        className={
                          code?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          code?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setCode(e.target.value)}
                      />
                       {code && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Name:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={name}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setName(e.target.value)}
                      />
                       {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Number:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={number}
                        className={
                          number?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          number?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setNumber(e.target.value)}
                      />
                       {number && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setNumber("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                </Row>
              </div>
            )  : (
              <Row>
                 <Col>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      From Currency:
                    </label>
                    <SearchInput
                      id="fromCurrency"
                      label="fromCurrency"
                      name="fromCurrency"
                      options={currencyData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.code}
                      getOptionValue={(country) => country.code}
                      handleChange={(selectedOption) => {
                        setFromcurrency(selectedOption ? selectedOption.code : "");
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="From Currency"
                      className={
                        fromCurrency?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isDropdownselectOpencurrency}
                    />
                  </Col>
                  <Col>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      To Currency:
                    </label>
                    <SearchInput
                      id="toCurrency"
                      label="toCurrency"
                      name="toCurrency"
                      options={currencyData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.code}
                      getOptionValue={(country) => country.code}
                      handleChange={(selectedOption) => {
                        setTocurrency(selectedOption ? selectedOption.code : "");
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="To Currency"
                      className={
                        toCurrency?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onMenuOpen={handleMenuOpenconv}
                  onMenuClose={handleMenuCloseconv}
                  menuIsOpen={isDropdownselectOpenconv}
                    />
                  </Col>
                 <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Rate:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={rate}
                        className={
                          rate?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          rate?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setRate(e.target.value)}
                      />
                       {rate && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setRate("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
              </Row>
            )}
            <div className="margin_top40">
              <div className="displayFlex gap_12">
              {tab == 1 ?
                    <>
                     {(role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_CURRENCY.write") ||
                role?.includes("AONE_CONFIGS_CURRENCY.admin")) && (
                    <><button
                        style={{ marginLeft: "10px" }}
                        className="filterBtn plus_icon ml_10"
                        title={"Currency Template"}
                        onClick={downloadTemplate}
                      >
                        <span>
                          <img src={templateIcon} className="h18" />
                        </span>
                      </button><div
                        title={"Import Currency"}
                      >
                          <label
                            className="filterBtn plus_icon"
                            htmlFor="uploadFile"
                          >
                            <span>
                              <img src={importIcon} className="h18" />
                            </span>
                            <input
                              type="file"
                              id="uploadFile"
                              onInput={(e) => handleFileChange(e)}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              hidden />
                          </label>
                        </div></>)}
                    <button
                      className="filterBtn"
                      title={"Export Currency"}
                      onClick={() => exportToExcel()}
                    >
                      <span>
                        <img src={downloadicon} className="h18" />
                      </span>
                    </button><button
                      className="filterBtn plus_icon"
                      title="Sync"
                      onClick={() => syncronizeRoles()}
                    >
                      <span>
                        <img src={syncicon} className="h18" />
                      </span>
                    </button>
                    {(role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_CURRENCY.write") ||
                role?.includes("AONE_CONFIGS_CURRENCY.admin")) && (
                    <button
                      className="filterBtn plus_icon"
                      title="Add"
                      onClick={() => setAddModalHandler(!addModalHandler)}
                      tabIndex="-1"
                    >
                      +
                    </button>)}</>
                  :
                  <>
                   {(role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.write") ||
                role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.admin")) && (
                  <><button
                        style={{ marginLeft: "10px" }}
                        className="filterBtn plus_icon ml_10"
                        title={"Currency conversion Template"}
                        onClick={downloadTemplate}
                      >
                        <span>
                          <img src={templateIcon} className="h18" />
                        </span>
                      </button><div
                        title={"Import Currency Conversion"}
                      >
                          <label
                            className="filterBtn plus_icon"
                            htmlFor="uploadFile"
                          >
                            <span>
                              <img src={importIcon} className="h18" />
                            </span>
                            <input
                              type="file"
                              id="uploadFile"
                              onInput={(e) => handleFileChange(e)}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              hidden />
                          </label>
                        </div></>)}
                    <button
                      className="filterBtn"
                      title={"Export Currency Conversion"}
                      onClick={() => exportToExcel()}
                    >
                      <span>
                        <img src={downloadicon} className="h18" />
                      </span>
                    </button><button
                      className="filterBtn plus_icon"
                      title="Sync"
                      onClick={() => syncronizeRoles()}
                    >
                      <span>
                        <img src={syncicon} className="h18" />
                      </span>
                    </button>
                    {(role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.write") ||
                role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.admin")) && (
                    <button
                      className="filterBtn plus_icon"
                      title="Add"
                      onClick={() => setAddModalHandler(!addModalHandler)}
                      tabIndex="-1"
                    >
                      +
                    </button>)}</>
              }
              </div>
            </div>
            </div>
      )}
       {!editPage && (
          <PaginationTable
            tableData={
              tab == 1
                ? currencyArray
                : currencyConversionData
            }
            tbl={
              tab == 1
                ? "Currency"
                : "Currencyconversion"
            }
            title={
              tab === 1
                ? "Edit Currency"
                : "Edit Currency Conversion"
            }
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            isDropdownselectOpencurrency={isDropdownselectOpencurrency}
            isDropdownselectOpenconv={isDropdownselectOpenconv}
            newPage={newPage}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
          {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={
            tab === 1
              ? "Create new currency"
              : "Create new conversion"
          }
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />)}
          </div>
    </div>
  )
}

export default Currency