import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../store/AoneStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import { UserContext } from "../../helper/ProtectedRoute";
import { useOrderStore } from "../../store/OrderMgt/OrderStore";
import downloadicon from "../../assets/img/download_icon.svg";
const OrderTrack = ({totalCount}) => {
  const params = useParams()
  const location = useLocation();
  const [importError,setImportError] = useState([])
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [trackingData, setTrackingData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const getConsignmentStatus = useAoneStore((state) => state?.getConsignmentStatus);
  const consignemntStatus = useAoneStore((state) => state?.consignemntStatus);
  const exportConsignmentEvents = useOrderStore((state) => state?.exportConsignmentEvents);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [sortBy, setSortBy] = useState("createdAt");
  const [isDelete, setIsDelete] = useState("");
  const [modalHandler, setModalHandler] = useState(false);
  const [sortField, setSortField] = useState("createdAt");
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const exportToExcel = async () => {
    try {
      const res = await exportConsignmentEvents(params?.id);
      if (res.status == 200) {
        fileDownload(res.data, `${params?.id}.xlsx`);
      }
    } catch (err) {}
  };
  return (
    <div> 
       <div className="displayFlex alignItemCenter justifyContent_end">
       <div className="mt_10">
                    <button
                    className="filterBtn"
                    title={"Export Consignment Tracking"}
                    onClick={() => exportToExcel()}>
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
       </div>
       </div>
      <div className="mt_10">
          <PaginationTable
            tableData={consignemntStatus}
            tbl={"OrderTracking"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
      </div>
    </div>
  );
};

export default OrderTrack;
