import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../store/AoneStore";
import { roleHandler } from "../../helper/roleHandler";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import PaginationTable from "../../components/Tables/paginationTable";
import CusModal from "../../components/modal/CusModal";
import { useOrderStore } from "../../store/OrderMgt/OrderStore";
import SequenceModal from "./SequenceModal";
import DeletingModal from "../../components/modal/deletingModal";
import { UserContext } from "../../helper/ProtectedRoute";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
const Sequence = () => {
  const navigate = useNavigate();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isActivated, SetisActivated] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [sequenceData, setSequenceData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [customer, setCustomer] = useState("");
  const [sortField, setSortField] = useState("-updatedAt");
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const getCustomerSequencer = useOrderStore(
    (state) => state?.getCustomerSequencer
  );
  const getSequencerStatus = useOrderStore(
    (state) => state?.getSequencerStatus
  );
  const customerSeqStatus = useOrderStore((state) => state?.customerSeqStatus);
  const [loadingMore, setLoadingMore] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [status, setStatus] = useState("");
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  useEffect(() => {
    if (customerSeqStatus?.data?.data === undefined) {
      getSequencerStatus();
    }
  }, []);
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    const data = await getCustomerSequencer(params);
    setTotalCount(data?.data?.data?.totalElements);
    setSequenceData(data?.data?.data?.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    status,
    customer,
    ispostAdded,
    isActivated,
  ]);
  const [prevSearch, setPrevSearch] = useState("");
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const [title, setTitle] = useState("Create new sequencer");
  const [showModal, setShowModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/consignments/sequence")}
              >
                Consignments
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/consignments/sequence")}
              >
                Sequence Ranges
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Customer:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <AsyncSearchInput
                      id="originHub"
                      label="originHub"
                      name="originHub"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptionsGroups}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        setCustomer(selectedOption ? selectedOption.value : "");
                      }}
                      customStyles={{ control: { width: "160px" } }}
                      placeholder="Customer"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Status:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <SearchInput
                      id="status"
                      label="status"
                      name="status"
                      options={customerSeqStatus?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        setStatus(selectedOption ? selectedOption.key : "");
                      }}
                      onMenuOpen={handleMenuRates}
                      onMenuClose={handleMenuCloseRates}
                      customStyles={{ control: { width: "160px" } }}
                      menuIsOpen={isReactRates}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Status"
                      className={
                        status?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.write") ||
                  roleHandler(role, "AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    tabIndex="-1"
                    onClick={() => {
                      setShowModal(true);
                      setTitle("Create new sequencer");
                    }}
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
      <PaginationTable
        tbl="Sequencer"
        tableData={sequenceData}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        size={size}
        setSize={setSize}
        setSortField={setSortField}
        sortField={sortField}
        totalCount={totalCount}
        setNewPage={setNewPage}
        newPage={newPage}
      />
      <CusModal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="addVehicleCateg"
      >
        <SequenceModal
          onSuccess={handleSuccess}
          onError={handleError}
          setShowModal={setShowModal}
          title={title}
        />
      </CusModal>
    </div>
  );
};

export default Sequence;
