import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../assets/img/template_icon.svg";
import importIcon from "../../assets/img/importIcon.svg";
import downloadicon from "../../assets/img/download_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import removeIcon from "../../assets/img/cancelIcon.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../store/AoneStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import * as XLSX from "xlsx";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
const Products = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [contentType, setContentType] = useState("");
  const [size, setSize] = useState(20);
  const [sortBy, setSortBy] = useState("createdAt");
  const [totalCount, setTotalCount] = useState(0);
  const [productsData, setProductsData] = useState([]);
  const [isDelete, setIsDelete] = useState("");
  const [modalHandler, setModalHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [editHandler, setEditHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const getProductsContent = useAoneStore((state) => state?.getProductsContent);
  const setglobalproductCode = useAoneStore(
    (state) => state?.setglobalproductCode
  );
  const productContentData = useAoneStore((state) => state?.productContentData);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const getProducts = useAoneStore((state) => state?.getProducts);
  const exportProducts = useAoneStore((state) => state?.exportProducts);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const handleMenuOpen = () => {
    setReactSelect(true);
  };

  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const getProductsTemplate = useAoneStore(
    (state) => state?.getProductsTemplate
  );
  const importProducts = useAoneStore((state) => state?.importProducts);
  const setglobalEmployeeCode = useAoneStore(
    (state) => state?.setglobalEmployeeCode
  );
  useEffect(() => {
    if (productContentData?.data?.data === undefined) {
      getProductsContent();
    }
  }, []);
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const downloadTemplate = async () => {
    try {
      const res = await getProductsTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Products Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            importProducts(formData)
              .then((response) => {
                if (response?.response?.data?.status == "404") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.error
                  toast.error(errorMessage);
                  fileInput.value = "";
                }else if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };

  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (name) {
      params += `&name=${name}`;
    }
    if (code) {
      params += `&code=${code}`;
    }
    if (contentType) {
      params += `&contentType=${contentType}`;
    }
    try {
      const res = await exportProducts(params);
      if (res.status == 200) {
        fileDownload(res.data, `Products.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (name) {
      params += `&name=$${name}$`;
    }
    if (code) {
      params += `&code=${code}`;
    }
    if (contentType) {
      params += `&contentType=${contentType}`;
    }
    const data = await getProducts(params);
    setTotalCount(data?.data?.data?.totalElements);
    setProductsData(data?.data?.data?.content);
  };
  useEffect(() => {
    getData();
    sessionStorage.removeItem("products");
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    location,
    modalHandler,
    addModalHandler,
    statusHandler,
    saveHandler,
    isDelete,
    name,
    code,
    contentType,
  ]);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  setglobalproductCode("");
                  navigate("/masters/products");
                }}
              >
                Masters
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  setglobalproductCode("");
                  navigate("/masters/products");
                }}
              >
                Products
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Code:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={code}
                      className={
                        code?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setCode(e.target.value)}
                    />
                 {code && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setCode("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
          </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Name:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={name}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        name?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setName(e.target.value)}
                    />
                 {name && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setName("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
          </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Content Type:
                  </label>
                  <SearchInput
                    id="contentType"
                    label="contentType"
                    name="contentType"
                    options={productContentData?.data?.data || []}
                    isClearable={true}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    handleChange={(selectedOption) => {
                      setContentType(selectedOption ? selectedOption.key : "");
                    }}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    menuIsOpen={isReactselectOpen}
                    isSearchable={true}
                    placeholder="Content Type"
                    customStyles={{ control: { width: "160px" } }}
                    className={
                      name?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                  {(roleHandler(role, "AONE_MASTERS.write") ||
                    roleHandler(role, "AONE_MASTERS.admin") ||
                    roleHandler(role, "AONE_MASTERS_PRODUCT.admin") ||
                    roleHandler(role, "AONE_MASTERS_PRODUCT.write")) && (
                    <>
                      <button
                        style={{ marginLeft: "10px" }}
                        className="filterBtn plus_icon ml_10"
                        title={"Download"}
                        onClick={downloadTemplate}
                      >
                        <span>
                          <img src={templateIcon} className="h18" />
                        </span>
                      </button>
                      <div title={"Import"}>
                        <label
                          className="filterBtn plus_icon"
                          htmlFor="uploadFile"
                        >
                          <span>
                            <img src={importIcon} className="h18" />
                          </span>
                          <input
                            type="file"
                            id="uploadFile"
                            onInput={(e) => handleFileChange(e)}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            hidden
                          />
                        </label>
                      </div>
                    </>
                  )}
                </>
                <button
                  className="filterBtn"
                  title={"Export"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_MASTERS.write") ||
                  roleHandler(role, "AONE_MASTERS.admin") ||
                  roleHandler(role, "AONE_MASTERS_PRODUCT.admin") ||
                  roleHandler(role, "AONE_MASTERS_PRODUCT.write")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => navigate("/masters/products/add")}
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={productsData}
            tbl={"Products"}
            title={"Products"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
        {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={tab === "Products"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />)}
      </div>
    </div>
  );
};

export default Products;
