import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getDrivers,postDrivers,deleteDrivers,editDrivers, activateDrivers,deactivateDrivers,getDriversbyId,driverTemplate,
    importDriver,getPayrollCompany,getPayrollType,importDriverDocument,downloadDriverLicense,
    postLoginInfoDriver,exportDrivers,resetPasswordDriver,toggleDriverstatus,showSensitiveFields,generatePassword } from "../../services/DriverMgt/Driver_services"
const initialValues = {
    payrollTypeList: [],
    payrollCompanyList: [],
    globalDriverCode: "",
    globalDriverFirstName: "",
    globalDriverLastName: "",
    globalEmployeeCode: "",
    globaldriverStatus: "",
    globaldriverActive: "",
    linehaulTab: 1,
    isDriverClicked: false
};
let driverStore = (set, get) => ({
  ...initialValues,
     getDrivers: async (params) => {
        const response = await getDrivers(params);
        return response;
      },
      postDrivers: async (data) => {
        const response = await postDrivers(data);
        return response;
      },
      deleteDrivers: async (data) => {
        const response = await deleteDrivers(data);
        return response;
      },
      exportDrivers: async (data) => {
        const response = await exportDrivers(data);
        return response;
      },
      downloadDriverLicense: async (data) => {
        const response = await downloadDriverLicense(data);
        return response;
      },
      editDrivers: async (params,data) => {
        const response = await editDrivers(params,data);
        return response;
      },
      getDriversbyId: async (data) => { 
        const response = await getDriversbyId(data);
        return response;
      },
      driverTemplate: async () => {
        const response = await driverTemplate();
        return response;
      },
      importDriver: async (params) => {
        const response = await importDriver(params);
        return response;
      },
      activateDrivers: async (params) => {
        const response = await activateDrivers(params);
        return response;
      },
      deactivateDrivers: async (params) => {
        const response = await deactivateDrivers(params);
        return response;
      },
      postLoginInfoDriver: async (params,data) => {
        const response = await postLoginInfoDriver(params,data);
        return response;
      },
      generatePassword: async () => {
        const response = await generatePassword();
        return response;
      },
      resetPasswordDriver: async (params,data) => {
        const response = await resetPasswordDriver(params,data);
        return response;
      },
      toggleDriverstatus: async (params,data) => {
        const response = await toggleDriverstatus(params,data);
        return response;
      },
      showSensitiveFields: async (params,params2) => {
        const response = await showSensitiveFields(params,params2);
        return response;
      },
      importDriverDocument: async (params) => {
        const response = await importDriverDocument(params);
        return response;
      },
      getPayrollCompany: async () => {
        const response = await getPayrollCompany();
        set({ payrollCompanyList: response });
        return response;
      },
      getPayrollType: async () => {
        const response = await getPayrollType();
        set({ payrollTypeList: response });
        return response;
      },
      setLineHaulTab: (data) => set({ linehaulTab: data }),
      setDriverClick: (data) => set({ isDriverClicked: data }),
      setglobalDriverCode: (data) => set({ globalDriverCode: data }),
      setglobalDriverFirstName: (data) => set({ globalDriverFirstName: data }),
      setglobalDriverLastName: (data) => set({ globalDriverLastName: data }),
      setglobalEmployeeCode: (data) => set({ globalEmployeeCode: data }),
      setglobalUserStatus: (data) => set({ globaldriverStatus: data }),
      setglobalDriverActive: (data) => set({ globaldriverActive: data }),
});

driverStore = devtools(driverStore);

export const useDriverStore = create(driverStore);