import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useDebugValue,
} from "react";
import Modal from "react-bootstrap/Modal";
import { useAoneStore } from "../../store/AoneStore";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import openEye from "../../assets/img/eye_show.svg";
import closeEye from "../../assets/img/eye_hide.svg";
// import { DatePicker } from "rsuite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserContext } from "../../helper/ProtectedRoute";
import SearchInput from "../dropdown/autoCompleteInputComponent";
import rightIcon from "../../assets/img/rightIcon.svg";
import leftIcon from "../../assets/img/leftIcon.svg";
import { useNavigate } from "react-router-dom";
import { roleHandler } from "../../helper/roleHandler";
import removeIcon from "../../../src/assets/img/cancelIcon.svg";
import {
  editDestination,
  editOrigin,
  editTransittime,
  getCurrency,
  getTiers,
  postDestination,
  postOrigin,
  postTransittime,
  resetPasswordEmployee,
} from "../../services/aone_services";
import AsyncSearchInput from "../dropdown/autoCompleteAsyncComponent";
import { useAclStore } from "../../store/AclMgt/Aclstore";
const OpenModal = ({
  modalHandler,
  setModalHandler,
  title,
  data,
  editHandler,
  setEditHandler,
  setIsadded,
  isAdded,
  setIsEdited,
  countryName,
  tier,
  setTab,
  employeeCode,
  categoryData,
  originCty,
  destinationCty,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const isFinanceInvoicePath =
    window?.location?.pathname?.includes("/finance/invoice");
  const getChuteConfigs = useAoneStore((state) => state?.getChuteConfigs);
  const getEmployeeDepartments = useAoneStore(
    (state) => state?.getEmployeeDepartments
  );
  //hide modal when clicked esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the "Escape" key (key code 27)
      if (event.keyCode === 27) {
        // Close the modal
        setModalHandler(false);
      }
    };
    // Attach event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalHandler]);
  const getZone = useAoneStore((state) => state?.getZone);
  const putZidProduct = useAoneStore((state) => state?.putZidProduct);
  const postZidMerchants = useAoneStore((state) => state?.postZidMerchants);
  const putZidMerchants = useAoneStore((state) => state.putZidMerchants);
  const postRoles = useAoneStore((state) => state.postRoles);
  const postUsers = useAoneStore((state) => state.postUsers);
  const getCountryArray = useAoneStore((state) => state?.getCountryArray);
  const postGroups = useAoneStore((state) => state?.postGroups);
  const postChildGroups = useAoneStore((state) => state?.postChildGroups);
  const getOrigin = useAoneStore((state) => state?.getOrigin);
  const getDestination = useAoneStore((state) => state?.getDestination);
  const updateSubAccounts = useAoneStore((state) => state.updateSubAccounts);
  const postEmployees = useAoneStore((state) => state?.postEmployees);
  const putEmployeeById = useAoneStore((state) => state?.putEmployeeById);
  const postThirdParty = useAoneStore((state) => state?.postThirdParty);
  const EditThirdparty = useAoneStore((state) => state?.EditThirdparty);
  const postMapping = useAoneStore((state) => state?.postMapping);
  const EditMapping = useAoneStore((state) => state?.EditMapping);
  const editFreight = useAoneStore((state) => state?.editFreight);
  const postFreight = useAoneStore((state) => state?.postFreight);
  const postRto = useAoneStore((state) => state?.postRto);
  const editRto = useAoneStore((state) => state?.editRto);
  const postOda = useAoneStore((state) => state?.postOda);
  const editOda = useAoneStore((state) => state?.editOda);
  const postCod = useAoneStore((state) => state?.postCod);
  const editCod = useAoneStore((state) => state?.editCod);
  const postFuel = useAoneStore((state) => state?.postFuel);
  const editFuel = useAoneStore((state) => state?.editFuel);
  const postInsurance = useAoneStore((state) => state?.postInsurance);
  const editInsurance = useAoneStore((state) => state?.editInsurance);
  const postAttempts = useAoneStore((state) => state?.postAttempts);
  const editAttempts = useAoneStore((state) => state?.editAttempts);
  const setOriginData = useAoneStore((state) => state?.setOriginData);
  const originData = useAoneStore((state) => state?.originData);
  const [originCountries, setOriginCounties] = useState([]);
  const OpsDeliveryEventData = useAoneStore(
    (state) => state?.OpsDeliveryEventData
  );
  const [destinationCountries, setDestinationCountries] = useState([]);
  const setDestinationData = useAoneStore((state) => state?.setDestinationData);
  const destinationData = useAoneStore((state) => state?.destinationData);
  const getPickupReasonsById = useAoneStore(
    (state) => state?.getPickupReasonsById
  );
  const getDeliveryReasonsById = useAoneStore(
    (state) => state?.getDeliveryReasonsById
  );
  const getHoldReasonsById = useAoneStore((state) => state?.getHoldReasonsById);
  const postPickupReasons = useAoneStore((state) => state?.postPickupReasons);
  const updatePickupReasonsById = useAoneStore(
    (state) => state?.updatePickupReasonsById
  );
  const getCountries = useAoneStore((state) => state?.getCountries);
  const postHoldReasons = useAoneStore((state) => state?.postHoldReasons);
  const updateHoldReasonsById = useAoneStore(
    (state) => state?.updateHoldReasonsById
  );
  const postDeliveryReasons = useAoneStore(
    (state) => state?.postDeliveryReasons
  );
  const updateDeliveryReasonsById = useAoneStore(
    (state) => state?.updateDeliveryReasonsById
  );
  const postChuteConfigs = useAoneStore((state) => state.postChuteConfigs);
  const postBags = useAoneStore((state) => state.postBags);
  const postCurrency = useAoneStore((state) => state.postCurrency);
  const updateCurrencyById = useAoneStore((state) => state.updateCurrencyById);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionProduct, setSelectedOptionProduct] = useState(null);
  const postCurrencyConversion = useAoneStore(
    (state) => state.postCurrencyConversion
  );
  const updateCurrencyConversionById = useAoneStore(
    (state) => state.updateCurrencyConversionById
  );
  const postWaybill = useAoneStore((state) => state.postWaybill);
  const editBags = useAoneStore((state) => state.editBags);
  const postCountryMapping = useAoneStore((state) => state.postCountryMapping);
  const postCityMapping = useAoneStore((state) => state.postCityMapping);
  const postHubs = useAoneStore((state) => state.postHubs);
  const getHubs = useAoneStore((state) => state?.getHubs);
  const editHubs = useAoneStore((state) => state.editHubs);
  const getProducts = useAoneStore((state) => state.getProducts);
  const getPickupCategory = useAoneStore((state) => state.getPickupCategory);
  const postServiceAreas = useAoneStore((state) => state.postServiceAreas);
  const getProductsbyId = useAoneStore((state) => state?.getProductsbyId);
  const editServiceAreas = useAoneStore((state) => state.editServiceAreas);
  const getSubModules = useAoneStore((state) => state.getSubModules);
  const getModels = useAoneStore((state) => state.getModels);
  const getZoneGroups = useAoneStore((state) => state.getZoneGroups);
  const setProductsPage = useAoneStore((state) => state.setProductsPage);
  const productsPage = useAoneStore((state) => state.productsPage);
  const customersPage = useAoneStore((state) => state.customersPage);
  const setCustomersPage = useAoneStore((state) => state.setCustomersPage);
  const getPermissions = useAoneStore((state) => state.getPermissions);
  const productsData = useAoneStore((state) => state?.productsData);
  const PermissionList = useAoneStore((state) => state.PermissionList);
  const stateRateData = useAoneStore((state) => state.stateRateData);
  const globalHubCode = useAoneStore((state) => state.globalHubCode);
  const zoneServiceData = useAoneStore((state) => state.zoneServiceData);
  const allowedeventData = useAoneStore((state) => state?.allowedeventData);
  const globalproductCode = useAoneStore((state) => state.globalproductCode);
  const invoiceTempData = useAoneStore((state) => state.invoiceTempData);
  const deliveryStatusData = useAoneStore((state) => state?.deliveryStatusData);
  const getCountryByCity = useAoneStore((state) => state?.getCountryByCity);
  const productWeightData = useAoneStore((state) => state?.productWeightData);
  const citybyCountyData = useAoneStore((state) => state?.citybyCountyData);
  const calculationData = useAoneStore((state) => state?.calculationData);
  const tiersData = useAoneStore((state) => state?.tiersData);
  const [tiersArray, setTiersArray] = useState([]);
  const getDesCountryByCity = useAoneStore(
    (state) => state?.getDesCountryByCity
  );
  const citybyDesCountyData = useAoneStore(
    (state) => state?.citybyDesCountyData
  );
  const structureData = useAoneStore((state) => state?.structureData);
  const paymentData = useAoneStore((state) => state?.paymentData);
  const collectionData = useAoneStore((state) => state?.collectionData);
  const contentData = useAoneStore((state) => state?.contentData);
  const handleModuleChange = async (selectedOption) => {
    // Check if a module is selected
    if (selectedOption) {
      addRole.setFieldValue("module", selectedOption?.value);
    } else {
      addRole.setFieldValue("module", "");
    }
    const moduleId = selectedOption.id; // Extract the selected module's ID
    if (selectedOption) {
      const params = `size=100&page=0&moduleId=${moduleId}`; // Construct the params string
      try {
        // Call the getSubModules API with the constructed params
        const subModules = await getSubModules(params);
        const permissions = await getPermissions(params);
      } catch (error) {
        // Handle errors from the API call
        console.error("Error fetching submodules:", error);
      }
    } else {
      // Handle if no module is selected
      console.warn("No module selected");
    }
  };
  const handleSubModuleChange = async (selectedOption) => {
    // Check if a module is selected
    if (selectedOption) {
      addRole.setFieldValue("subModule", selectedOption?.value);

      const moduleId = selectedOption.moduleId; // Extract the selected module's ID
      const submoduleId = selectedOption.id;
      const params = `size=100&page=0&moduleId=${moduleId}&subModuleId=${submoduleId}`; // Construct the params string
      try {
        // Call the getSubModules API with the constructed params
        const subModules = await getModels(params);
      } catch (error) {
        // Handle errors from the API call
        console.error("Error fetching submodules:", error);
      }
    } else {
      addRole.setFieldValue("subModule", "");
      // Handle if no module is selected
      console.warn("No module selected");
    }
  };
  const [prodmaxWeight, setProdMaxWeight] = useState("");
  const ModuleList = useAoneStore((state) => state.ModuleList);
  const SubmoduleList = useAoneStore((state) => state.SubmoduleList);
  const OpsEventData = useAoneStore((state) => state.OpsEventData);
  const OpsCategoryData = useAoneStore((state) => state?.OpsCategoryData);
  const [loadingMore, setLoadingMore] = useState(false);
  const ModelList = useAoneStore((state) => state.ModelList);
  const editHubsbypatch = useAoneStore((state) => state.editHubsbypatch);
  const postDistrictMapping = useAoneStore(
    (state) => state.postDistrictMapping
  );
  const postRegionMapping = useAoneStore((state) => state.postRegionMapping);
  const updateCountryMappingById = useAoneStore(
    (state) => state.updateCountryMappingById
  );
  const updateDistrictMappingById = useAoneStore(
    (state) => state.updateDistrictMappingById
  );
  const updateCityMappingById = useAoneStore(
    (state) => state.updateCityMappingById
  );
  const updateRegionMappingById = useAoneStore(
    (state) => state.updateRegionMappingById
  );
  const putChuteConfigs = useAoneStore((state) => state.putChuteConfigs);
  const postZones = useAoneStore((state) => state.postZones);
  const updateZoneById = useAoneStore((state) => state.updateZoneById);
  const postZonegroup = useAoneStore((state) => state.postZonegroup);
  const postInvoiceRequest = useAoneStore((state) => state.postInvoiceRequest);
  const postInvoiceRequestTemplate = useAoneStore(
    (state) => state.postInvoiceRequestTemplate
  );
  const AclTypeList = useAclStore((state) => state?.AclTypeList);
  const getInvoiceRequestTemplatebyId = useAoneStore(
    (state) => state.getInvoiceRequestTemplatebyId
  );
  const updateZonegroupById = useAoneStore(
    (state) => state.updateZonegroupById
  );
  const editpickupaddress = useAoneStore((state) => state.editpickupaddress);
  const postpikcupAddress = useAoneStore((state) => state.postpikcupAddress);
  const postChuteConfigsById = useAoneStore(
    (state) => state.postChuteConfigsById
  );
  const [addedCustomers, setAddedCustomers] = useState([]);
  const getCustomer = useAoneStore((state) => state?.getCustomer);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const getOperationCodes = useAoneStore((state) => state?.getOperationCodes);
  const operationCodes = useAoneStore((state) => state?.operationCodes);
  const customerData = useAoneStore((state) => state?.customerData);
  const currencyData = useAoneStore((state) => state?.currencyData);
  const getAllAreasbyHub = useAoneStore((state) => state?.getAllAreasbyHub);
  const getAllZonegroupbyHub = useAoneStore(
    (state) => state?.getAllZonegroupbyHub
  );
  const zoneGroupbyHubData = useAoneStore((state) => state?.zoneGroupbyHubData);
  const setisEditPickup = useAoneStore((state) => state?.setisEditPickup);
  const isEditPickup = useAoneStore((state) => state?.isEditPickup);
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(100);
  const [invoicesize, setInvoiceSize] = useState(10000);
  const [name, setName] = useState("");
  //filter state for added name
  const [sortField, setSortField] = useState("createdAt");
  const [addedName, setAddedName] = useState("");
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}`;
    if (name) {
      params += `&name=${name}`;
    }
    if (params.includes("finance") || params.includes("invoices")) {
      // const data = await getCustomer(params);
    }
  };
  useEffect(() => {
    const delayFn = setTimeout(() => {
      getData();
    }, 1500);
    return () => clearTimeout(delayFn);
  }, [name]);
  useEffect(() => {
    if (isFinanceInvoicePath && operationCodes?.length < 1) {
      getOperationCodes();
    }
  }, []);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectRemovingCustomers, setSelectRemovingCustomers] = useState([]);
  const [isAddedClick, setIsaddedClick] = useState(false);
  const handleCusSelection = (customer) => {
    setIsaddedClick(false);
    // Check if the customer is already selected
    const isCustomerSelected = selectedCustomers.some(
      (selectedCustomer) =>
        selectedCustomer.accountNumber === customer.accountNumber
    );

    // If not selected, add to the selectedCustomers array; otherwise, remove it
    setSelectedCustomers((prevSelectedCustomers) =>
      isCustomerSelected
        ? prevSelectedCustomers.filter(
            (selectedCustomer) =>
              selectedCustomer.accountNumber !== customer.accountNumber
          )
        : [...prevSelectedCustomers, customer]
    );
  };
  const [isaddAll, setIsaddall] = useState(false);
  const handleAddAll = () => {
    setIsaddall(true);
    const allCustomerNames =
      customerData?.data?.data?.content?.map((customer) => customer.name) || [];
    const allCustomerAccounts =
      customerData?.data?.data?.content?.map(
        (customer) => customer.accountNumber
      ) || [];
    // Update setAddedCustomers with all customer names
    if (title === "Create Invoice Request") {
      setAddedCustomers(allCustomerNames);
      // Optionally, you can update the formik form values if needed
      addInvoice.setValues({
        ...addInvoice.values,
        customerAccounts: allCustomerAccounts,
      });
    } else {
      setAddedCustomers(allCustomerNames);
      // Optionally, you can update the formik form values if needed
      addInvoiceTemplate.setValues({
        ...addInvoiceTemplate.values,
        customerAccounts: allCustomerAccounts,
      });
    }
    // Clear the selected customers array
    setSelectedCustomers([]);
  };
  const handleAdd = () => {
    setIsaddall(false);
    // Combine previously selected customers with newly selected ones
    const updatedCustomers = [...addedCustomers, ...selectedCustomers];
    // Check if each newly selected customer is not already in the addedCustomers
    const uniqueSelectedCustomers = selectedCustomers.filter(
      (customer) =>
        !addedCustomers.some(
          (addedCustomer) =>
            addedCustomer.accountNumber === customer.accountNumber
        )
    );
    const allUpdatedCustomers = [...addedCustomers, ...uniqueSelectedCustomers];
    const addedCustomerNames = allUpdatedCustomers.map(
      (customer) => customer.name
    );
    const addedCustomerAccount = allUpdatedCustomers.map(
      (customer) => customer.accountNumber
    );
    if (title === "Create Invoice Request") {
      addInvoice.setValues({
        ...addInvoice.values,
        customerAccounts: addedCustomerAccount,
      });
      setAddedCustomers(allUpdatedCustomers);
    } else {
      addInvoiceTemplate.setValues({
        ...addInvoiceTemplate.values,
        customerAccounts: addedCustomerAccount,
      });
      setAddedCustomers(allUpdatedCustomers);
    }
    // Clear the selected customers
    setSelectedCustomers([]);
  };
  const handleAddTemplate = () => {
    setIsaddall(false);
    // Combine previously selected customers with newly selected ones
    const updatedCustomers = [...addedCustomers, ...selectedCustomers];
    // Check if each newly selected customer is not already in the addedCustomers
    const uniqueSelectedCustomers = selectedCustomers.filter(
      (customer) =>
        !addedCustomers.some(
          (addedCustomer) =>
            addedCustomer.accountNumber === customer.accountNumber
        )
    );
    const allUpdatedCustomers = [...addedCustomers, ...uniqueSelectedCustomers];
    const addedCustomerNames = allUpdatedCustomers.map(
      (customer) => customer.name
    );
    const addedCustomerAccount = allUpdatedCustomers.map(
      (customer) => customer.accountNumber
    );
    addInvoiceTemplate.setValues({
      ...addInvoiceTemplate.values,
      customerAccounts: addedCustomerAccount,
    });
    setAddedCustomers(allUpdatedCustomers);
    // Clear the selected customers
    setSelectedCustomers([]);
  };
  const handleCustomerClick = (customer) => {
    setIsaddedClick(true);
    setSelectedCustomers((prevSelected) => {
      const isCustomerSelected = prevSelected.some(
        (selectedCustomer) =>
          selectedCustomer.accountNumber === customer.accountNumber
      );

      if (isCustomerSelected) {
        // If already selected, remove it
        return prevSelected.filter(
          (selectedCustomer) =>
            selectedCustomer.accountNumber !== customer.accountNumber
        );
      } else {
        // If not selected, add it
        return [...prevSelected, customer];
      }
    });
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleCancel = () => {
    handleCloseTemplate();
  };
  const handleCloseTemplate = () => {
    setShow(false);
  };
  const handleRemove = () => {
    // Remove selected customers from addedCustomers
    const updatedCustomers = addedCustomers.filter(
      (customer) =>
        !selectedCustomers.some(
          (selectedCustomer) =>
            selectedCustomer.accountNumber === customer.accountNumber
        )
    );
    // Update the state
    setAddedCustomers(updatedCustomers);
    setSelectedCustomers([]); // Clear selected customers
  };
  const handleRemoveAll = () => {
    setAddedCustomers([]);
    addInvoice.setFieldValue("customerAccounts", []);
  };
  const zoneGroupData = useAoneStore((state) => state.zoneGroupData);
  const editWaybillsbyBagId = useAoneStore(
    (state) => state.editWaybillsbyBagId
  );
  const putChuteConfigsById = useAoneStore(
    (state) => state.putChuteConfigsById
  );
  const postAgentConfigs = useAoneStore((state) => state.postAgentConfigs);
  const putAgentConfigs = useAoneStore((state) => state.putAgentConfigs);
  const postAreas = useAoneStore((state) => state.postAreas);
  const editAreas = useAoneStore((state) => state.editAreas);
  const setSaveHandlerAction = useAoneStore((state) => state?.setSaveHandler);
  const mappingData = useAoneStore((state) => state?.mappingData);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  // const [ saveHandler, setSaveHandler ] = useState(false)
  const [passwordHandler, setPasswordHandler] = useState(true);
  const [ajexKeyHandler, setAjexkeyHandler] = useState(true);
  const [thirdpartyHandler, setThirdpartyHandler] = useState(true);
  const [dropdownTwo, setDropdownTwo] = useState(false);
  const app = ["AONE", "AIMS", "WMS"];
  const permission = ["Read", "Write", "Admin", "Assign"];
  const bussinessLine = [
    "EXPRESS",
    "LAND_FREIGHT",
    "WARE_HOUSE",
    "FREIGHT_FORWARDING",
  ];
  const [department, setDepartment] = useState([]);
  const [configuration, setConfiguration] = useState([]);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const zoneData = useAoneStore((state) => state?.zoneData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const districtDatas = useAoneStore((state) => state?.districtDatas);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const citybyCode = useAoneStore((state) => state?.citybyCode);
  const regionbyCodeDes = useAoneStore((state) => state?.regionbyCodeDes);
  const citybyCodeDes = useAoneStore((state) => state?.citybyCodeDes);
  const districtbyCode = useAoneStore((state) => state?.districtbyCode);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const hubTypes = useAoneStore((state) => state?.hubTypes);
  const getRegionbyCode = useAoneStore((state) => state?.getRegionbyCode);
  const getCitybyCode = useAoneStore((state) => state.getCitybyCode);
  const getRegionbyCodeDes = useAoneStore((state) => state.getRegionbyCodeDes);
  const getCitybyCodeDes = useAoneStore((state) => state.getCitybyCodeDes);
  const getDistrictbyCode = useAoneStore((state) => state.getDistrictbyCode);
  const postTiers = useAoneStore((state) => state.postTiers);
  const updateTiersById = useAoneStore((state) => state.updateTiersById);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const postOdTier = useAoneStore((state) => state?.postOdTier);
  const updateOdTierById = useAoneStore((state) => state?.updateOdTierById);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const handleClose = () => {
    setModalHandler(!modalHandler);
    setEditHandler(false);
    setSaveHandlerAction(false);
  };
  const handleCloseRates = () => {
    setProductsPage(-1);
    setCustomersPage(-1);
    setModalHandler(!modalHandler);
    setEditHandler(false);
    setSaveHandlerAction(false);
  };
  const handleClosebag = () => {
    setModalHandler(!modalHandler);
    setEditHandler(false);
    setSaveHandlerAction(!saveHandler);
  };
  const [dummyProductUrl, setDummyProductUrl] = useState(
    data?.imagesUrls ?? []
  );
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const editZidProduct = useFormik({
    initialValues: {
      productId: data?.parentProduct === null ? data?.id : data?.id,
      storeId:
        data?.parentProduct === null
          ? data?.variantData?.store_id
          : data?.storeId,
      productNameAr:
        data?.parentProduct === null
          ? data?.variantData?.name?.ar
          : data?.name?.ar,
      productName:
        data?.parentProduct === null
          ? data?.variantData?.name?.en
          : data?.name?.en,
      sku: data?.parentProduct === null ? data?.variantData?.sku : data?.sku,
      quantity:
        data?.parentProduct === null
          ? data?.variantData?.quantity
          : data?.quantity,
      parentProductAr: data?.parentProduct?.ar,
      parentProductEn: data?.parentProduct?.en,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      productName: Yup.string().required("Product Name is Required"),
      storeId: Yup.string().required("Store Id is Required"),
      sku: Yup.string().required("SKU is Required"),
      quantity: Yup.string().required("Quantity is Required"),
    }),
    onSubmit: async (values) => {
      let data = {
        // productId: values.productId,
        // storeId: values.storeId,
        name: {
          ar: values.productNameAr,
          en: values.productName,
        },
        sku: values.sku,
        quantity: Number(values.quantity),
        images: dummyProductUrl,
      };
      await putZidProduct(values.productId, data);
      handleClose();
    },
  });
  let merchantToken =
    JSON.parse(sessionStorage.getItem("postMerchantToken")) ?? {};

  const addMerchant = useFormik({
    initialValues: {
      storeName: title == "Edit ZID Merchant" ? data.storeName : "",
      storeId: title == "Edit ZID Merchant" ? data.storeId : "",
      accessToken: title == "Edit ZID Merchant" ? data?.accessToken : "", // merchantToken?.access_token
      refreshToken: title == "Edit ZID Merchant" ? data?.refreshToken : "",
      authorization: title == "Edit ZID Merchant" ? data?.authorization : "",
      expiresIn: title == "Edit ZID Merchant" ? data?.expiresIn : "",
      emailAddress: title == "Edit ZID Merchant" ? data?.emailAddress : "",
      phone: title == "Edit ZID Merchant" ? data?.phone : "",
      country: title == "Edit ZID Merchant" ? data?.country : "",
      countryCode: title == "Edit ZID Merchant" ? data?.countryCode : "",
      province: title == "Edit ZID Merchant" ? data?.province : "",
      city: title == "Edit ZID Merchant" ? data?.city : "",
      cityCode: title == "Edit ZID Merchant" ? data?.cityCode : "",
      district: title == "Edit ZID Merchant" ? data?.district : "",
      detailAddress: title == "Edit ZID Merchant" ? data?.detailAddress : "",
      postalCode: title == "Edit ZID Merchant" ? data?.postalCode : "",
      customerAccount:
        title == "Edit ZID Merchant" ? data?.customerAccount : "",
      productCode: title == "Edit ZID Merchant" ? data?.productCode : "",
      tierOneCost: title == "Edit ZID Merchant" ? data?.tierOneCost : 0,
      tierTwoCost: title == "Edit ZID Merchant" ? data?.tierTwoCost : 0,
      tierThreeCost: title == "Edit ZID Merchant" ? data?.tierThreeCost : 0,
      tierOneCODFee: title == "Edit ZID Merchant" ? data?.tierOneCODFee : 0,
      tierTwoCODFee: title == "Edit ZID Merchant" ? data?.tierTwoCODFee : 0,
      tierThreeCODFee: title == "Edit ZID Merchant" ? data?.tierThreeCODFee : 0,
      expressType: title == "Edit ZID Merchant" ? data?.expressType : "",
      pickupMethod:
        title == "Edit ZID Merchant" ? data?.pickupMethod : "PICKUP",
      defaultWeight: title == "Edit ZID Merchant" ? data?.defaultWeight : 0,
      appType: title == "Edit ZID Merchant" ? data?.appType : "SHIPPING",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      title == "Edit ZID Merchant"
        ? await putZidMerchants(data?.id, values)
        : await postZidMerchants(values);
      handleClose();
    },
  });
  const [dropdownOne, setDropdownOne] = useState(false);

  // Define a handleAppChange function similar to handleModuleChange for managing app selection
  const handleAppChange = (selectedOption) => {
    if (selectedOption) {
      addRole.setFieldValue("app", selectedOption.value); // Use selectedOption.value
    } else {
      addRole.setFieldValue("app", "");
    }
  };
  const permissionOptions = ["Read", "Write", "Admin", "Assign"].map(
    (permission) => ({
      label: permission,
      value: permission,
    })
  );

  const handlePermissionChange = (selectedOption) => {
    if (selectedOption) {
      addRole.setFieldValue("permission", selectedOption.value);
    } else {
      addRole.setFieldValue("permission", "");
    }
  };
  const addRole = useFormik({
    initialValues: {
      description: "",
      app: "",
      module: "",
      subModule: "",
      model: "",
      permission: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      description: Yup.string().required("Description is Required"),
      app: Yup.string().required("App is Required"),
      // module: Yup.string().required("Module is Required"),
      // subModule: Yup.string().required("Sub Module is Required"),
      // model: Yup.string().required("Model is Required"),
      permission: Yup.string().required("Permission is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values["name"] = AutoFillRoleName();
      await postRoles(values).then((response) => {
        handleClose();
        if (response.status == "201") {
          toast.success("Role Created");
          handleClose();
          resetForm();
        } else if(response?.data?.status == "ERROR"){
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
        } else {
          const errorMessage = response?.response?.data?.msg?.split(",")[0];
          toast.error(errorMessage);
          handleClose();
          resetForm();
        }
      });
    },
  });
  useEffect(() => {
    addRole.setFieldValue("subModule", "");
    addRole.setFieldValue("model", "");
    addRole.setFieldValue("permission", "");
  }, [addRole?.values?.module]);
  useEffect(() => {
    addRole.setFieldValue("model", "");
  }, [addRole?.values?.subModule]);
  const addConfig = useFormik({
    initialValues: {
      name: title == "Edit Chutes" ? data?.name : "",
      description: title == "Edit Chutes" ? data?.description : "",
      printACL: title === "Edit Chutes" ? data.printACL : true,
      aclType: title === "Edit Chutes" ? data?.aclType : ""
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Description is Required"),
      name: Yup.string().required("Name is Required"),
      aclType: Yup.string().required("ACL Type is Required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (title == "Edit Chutes") {
        await putChuteConfigs(data.id, values).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Config Updated");
          } else if (response.status == "409") {
            toast.error(`${values.name} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      } else {
        await postChuteConfigs(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Config Added");
            setIsadded(!isAdded);
          } else if (response.response.data.errorCode == "409") {
            toast.error(`${values.name} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      }
      // title == "Edit Chute" ? await putChuteConfigs(data.id,values) : await postChuteConfigs(values)
      // handleClose();
    },
  });
  const addConfigs = useFormik({
    initialValues: {
      name: title == "Edit Chute" ? data?.name : "",
      description: title == "Edit Chute" ? data?.description : "",
      printBag: title === "Edit Chute" ? data.printBag : true,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Description is Required"),
      name: Yup.string().required("Name is Required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (title == "Edit Chute") {
        await putChuteConfigs(data.id, values).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Config Updated");
          } else if (response.status == "409") {
            toast.error(`${values.name} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      } else {
        await postChuteConfigs(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Config Added");
            setIsadded(!isAdded);
          } else if (response.response.data.errorCode == "409") {
            toast.error(`${values.name} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      }
      // title == "Edit Chute" ? await putChuteConfigs(data.id,values) : await postChuteConfigs(values)
      // handleClose();
    },
  });
  const detailedConfig = useFormik({
    initialValues: {
      zoneCode: title == "Edit Units" ? data.zoneCode : "",
      chuteNumber: title == "Edit Units" ? data.chuteNumber : "",
      hubCode: title == "Edit Units" ? data.hubCode : "",
      printACL: title === "Edit Units" ? data.printACL : true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      zoneCode: Yup.string().required("Zone Code is Required"),
      chuteNumber: Yup.string()
        .required("Chute Number is Required"),
        hubCode: Yup.string().required("Hub Code is Required"),
    }),
    onSubmit: async (values) => {
      if (title == "Edit Units") {
        await putChuteConfigsById(params?.id, values).then((response) => {
          console.log("respo",response)
          handleClose();
          if (response?.data?.status == "SUCCESS") {
            toast.success("Chute Updated");
            setispostAdded(!ispostAdded);
          } else if (response.data?.status == "ERROR") {
            toast.error(response?.data?.msg);
          } else if (response.status == "409") {
            toast.error(`${values.zoneCode} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      } else {
        await postChuteConfigsById(params?.id, values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Chute Added");
            setispostAdded(!ispostAdded);
          } else if (response.data?.status == "ERROR") {
            toast.error(response?.data?.msg);
          } else if (response.status == "409") {
            toast.error(`${values.zoneCode} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      }
    },
  });
  const detailedConfigs = useFormik({
    initialValues: {
      zoneCode: title == "Edit Unit" ? data.zoneCode : "",
      chuteNumber: title == "Edit Unit" ? data.chuteNumber : "",
      hubCode: title == "Edit Unit" ? data.hubCode : "",
      hubName: title == "Edit Unit" ? data.hubName : "",
      printBag: title === "Edit Unit" ? data.printBag : true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      zoneCode: Yup.string().required("Zone Code is Required"),
      chuteNumber: Yup.string()
        .required("Chute Number is Required")
        .test("is-number", "Chute Number must be a number", (value) =>
          /^[0-9]*$/.test(value)
        ),
      hubCode: Yup.string().required("Hub Code is Required"),
      hubName: Yup.string().required("Hub Name is Required"),
    }),
    onSubmit: async (values) => {
      if (title == "Edit Unit") {
        await putChuteConfigsById(params?.id, values).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Chute Updated");
          } else if (response.status == "409") {
            toast.error(`${values.zoneCode} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      } else {
        await postChuteConfigsById(params?.id, values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Chute Added");
          } else if (response.status == "409") {
            toast.error(`${values.zoneCode} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      }
    },
  });
  console.log("detailedConfi",detailedConfigs)
  const [selectedOptionHub, SetselectedOptionHub] = useState(null);
  const [showSelectedHub, setShowSelectedHub] = useState(false);
  const [showSelectedZone, setShowSelectedZone] = useState(false);
  useEffect(()=>{
    if(title === "Edit Units" || title == "Edit Unit"){
      setShowSelectedHub(true)
    }
  },[data])
  const handleHubClick = () => {
    setShowSelectedHub(false);
  };
  useEffect(()=>{
    if(title === "Edit Units" || title == "Edit Unit"){
      setShowSelectedZone(true)
    }
  },[data])
  const handleZoneClick = () => {
    setShowSelectedZone(false);
  };
  const [page, setPage] = useState(-1);
  const [pagecustomer, setPageCustomer] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsHubs = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await getHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const maxChuteOptions = Array.from({ length: 47 }, (v, i) => {
    const value = i + 1;
    return { label: value.toString(), value: value.toString() };
  });
  const addAgent = useFormik({
    initialValues: {
      agentId: title === "Edit Agents" ? data?.agentId : "",
      assignedConfigId: title === "Edit Agents" ? data?.assignedConfigId : "",
      status: title === "Edit Agents" ? data?.status : false,
      hubCode: title === "Edit Agents" ? data?.hubCode : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      assignedConfigId: Yup.string().required("Configuration is Required"),
      agentId: Yup.string().required("Agent Id is Required"),
      hubCode: Yup.string().required("Hub Code is Required"),
    }),
    onSubmit: async (values) => {
      delete values["name"];
      if (title == "Edit Agents") {
        await putAgentConfigs(data.id, values).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Agent Updated");
            setispostAdded(!ispostAdded);
          } else if (response.data?.status == "ERROR") {
            toast.error(response?.data?.msg);
          } else if (response.status == "409") {
            toast.error(`${values.agentId} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      } else {
        await postAgentConfigs(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Agent Added");
            setispostAdded(!ispostAdded);
          } else if (response.data?.status == "ERROR") {
            toast.error(response?.data?.msg);
          } else if (response.status == "409") {
            toast.error(`${values.agentId} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      }
    },
  });
  const addAgents = useFormik({
    initialValues: {
      agentId: title === "Edit Agent" ? data?.agentId : "",
      assignedConfigId: title === "Edit Agent" ? data?.assignedConfigId : "",
      status: title === "Edit Agent" ? data?.status : false,
      name: title === "Edit Agent" ? data?.assignedConfigName : "",
      hubCode: title === "Edit Agent" ? data?.hubCode : "",
      hubName: title === "Edit Agent" ? data?.hubName : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      assignedConfigId: Yup.string().required("Sorting is Required"),
      agentId: Yup.string().required("Agent Id is Required"),
      hubCode: Yup.string().required("Hub Code is Required"),
      hubName: Yup.string().required("Hub Name is Required"),
    }),
    onSubmit: async (values) => {
      delete values["name"];
      if (title == "Edit Agent") {
        await putAgentConfigs(data.id, values).then((response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Agent Updated");
          } else if (response.status == "409") {
            toast.error(`${values.agentId} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      } else {
        await postAgentConfigs(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Agent Added");
          } else if (response.status == "409") {
            toast.error(`${values.agentId} Already Exists`);
          } else {
            toast.error(response.response.data.msg);
          }
        });
      }
    },
  });
  const [showSelectHub, setShowSelectHub] = useState(false);
  useEffect(()=>{
    if(title === "Edit Agents"){
      setShowSelectHub(true)
    }
  },[data])
  const handleHbClick = () => {
    setShowSelectHub(false);
  };
  const addGroup = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Group Name is Required"),
      description: Yup.string().required("Description Name is Required"),
    }),
    onSubmit: async (values) => {
      title == "Add Group"
        ? await postGroups(values)
        : await postChildGroups(data, values);
      handleClose();
    },
  });

  const addUsers = useFormik({
    initialValues: {
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      active: true,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("User Name is Required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(25, "Password must not exceed 25 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.!@#$%^&*()+?><]).{6,25}$/,
          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character from -.!@#$%^&*()+?><"
        ),
      email: Yup.string().required("Email Name is Required"),
      firstName: Yup.string().required("First Name is Required"),
      lastName: Yup.string().required("Last Name is Required"),
    }),
    onSubmit: async (values) => {
      await postUsers(values).then((response) => {
        handleClose();
        if (response.status == "201") {
          toast.success("User Added");
        } else {
          const errorMessage = response.response.data.msg.split(",")[0];
          toast.error(errorMessage);
        }
      });
    },
  });
  //create third party
  const addThirdParty = useFormik({
    initialValues: {
      code: title === "Edit third party configuration" ? data?.code : "",
      name: title === "Edit third party configuration" ? data?.name : "",
      clientId:
        title === "Edit third party configuration" ? data?.clientId : "",
      autoAssign:
        title === "Edit third party configuration" ? data?.autoAssign : false,
      status:
        title === "Edit third party configuration" ? data?.status : "INACTIVE",
      consumeEvents: "Edit third party configuration"
        ? data?.consumeEvents
        : "",
      produceEvents: "Edit third party configuration"
        ? data?.produceEvents
        : "",
      ajexSecretKey: "Edit third party configuration"
        ? data?.ajexSecretKey
        : "",
      thirdPartySecretKey: "Edit third party configuration"
        ? data?.thirdPartySecretKey
        : "",
      products: "Edit third party configuration" ? data?.products : "",
      sourceCountries: "Edit third party configuration"
        ? data?.sourceCountries
        : "",
      destinationCountries: "Edit third party configuration"
        ? data?.destinationCountries
        : "",
      customerAccounts: "Edit third party configuration"
        ? data?.customerAccounts
        : "",
      excludeCustomerAccounts: "Edit third party configuration"
        ? data?.excludeCustomerAccounts
        : "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Code is required"),
      name: Yup.string().required("Name is required"),
      clientId: Yup.string().required("Client Id is required"),
    }),
    onSubmit: async (values) => {
      if (title === "Edit third party configuration") {
        await EditThirdparty(data.id, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("Third Party Updated");
            setispostAdded(!ispostAdded);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postThirdParty(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Third Party Added");
            setispostAdded(!ispostAdded);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  function formatDate(dateString) {
    const date = new Date(dateString + " UTC");
    const year = date.getUTCFullYear();
    const month = padZeroes(date.getUTCMonth() + 1);
    const day = padZeroes(date.getUTCDate());
    return `${year}-${month}-${day}`;
  }

  function padZeroes(value) {
    return value.toString().padStart(2, "0");
  }
  //Freight Rate
  const addFreight = useFormik({
    initialValues: {
      productCode: title === "Edit freight rate" ? data?.productCode : "",
      structure: title === "Edit freight rate" ? data?.structure : "",
      customerAccount:
        title === "Edit freight rate" ? data?.customerAccount : "",
      paymentType: title === "Edit freight rate" ? data?.paymentType : "",
      deliveryStatus: title === "Edit freight rate" ? data?.deliveryStatus : "",
      contentType: title === "Edit freight rate" ? data?.contentType : "",
      originCountryCode:
        title === "Edit freight rate" ? data?.originCountryCode : "",
      originCityCode: title === "Edit freight rate" ? data?.originCityCode : "",
      destinationCountryCode:
        title === "Edit freight rate" ? data?.destinationCountryCode : "",
      destinationCityCode:
        title === "Edit freight rate" ? data?.destinationCityCode : "",
      tierCode: title === "Edit freight rate" ? data?.tierCode : "",
      weightUnits: title === "Edit freight rate" ? data?.weightUnits : "",
      fromWeight: title === "Edit freight rate" ? data?.fromWeight : "",
      toWeight: title === "Edit freight rate" ? data?.toWeight : "",
      flatPrice:
        title === "Edit freight rate"
          ? data?.flatPrice === null
            ? ""
            : data?.flatPrice
          : "",
      unit:
        title === "Edit freight rate"
          ? data?.unit === null
            ? ""
            : data?.unit
          : "",
      unitPrice:
        title === "Edit freight rate"
          ? data?.unitPrice === null
            ? ""
            : data?.unitPrice
          : "",
      effectiveDate: title === "Edit freight rate" ? data?.effectiveDate : "",
      lowClearance: title === "Edit freight rate" ? data?.lowClearance : false,
      state: title === "Edit freight rate" ? data?.state : "",
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      structure: Yup.string().required("Structure is required"),
      customerAccount: Yup.string().required("Customer is required"),
      paymentType: Yup.string().required("Payment type is required"),
      deliveryStatus: Yup.string().required("Delivery Status is required"),
      contentType: Yup.string().required("Content Type is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      weightUnits: Yup.string().required("Weight Unit is required"),
      fromWeight: Yup.number()
        .required("From Weight is required")
        .typeError("From Weight must be a number")
        .min(0, "From Weight must be greater than or equal to 0"),
      toWeight: Yup.number()
        .typeError("To Weight must be a number")
        .min(1, "To Weight must be greater than or equal to 1")
        .when(["fromWeight"], (fromWeight, schema) =>
          schema.test({
            test: function (toWeight) {
              if (fromWeight && toWeight) {
                return toWeight > fromWeight;
              }
              return true;
            },
            message: "To Weight must be greater than From Weight",
          })
        ),
      flatPrice: Yup.number()
        .typeError("Flat price must be a number")
        .min(0.01, "Flat price must be greater than or equal to 0.01"),
      unit: Yup.number()
        .typeError("Unit must be a number")
        .min(0.01, "Unit must be greater than or equal to 0.01"),
      unitPrice: Yup.number()
        .typeError("Unit price must be a number")
        .min(0.01, "Unit price must be greater than or equal to 0.01"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      if (valuestoSend.originCityCode === "") {
        valuestoSend.originCityCode = null;
      }
      if (valuestoSend.destinationCityCode === "") {
        valuestoSend.destinationCityCode = null;
      }
      if (valuestoSend.flatPrice === "") {
        valuestoSend.flatPrice = null;
      }
      if (valuestoSend.unit === "") {
        valuestoSend.unit = null;
      }
      if (valuestoSend.unitPrice === "") {
        valuestoSend.unitPrice = null;
      }
      if (valuestoSend.tierCode === "") {
        valuestoSend.tierCode = null;
      }
      if (valuestoSend.tierCode !== "") {
        valuestoSend.originCityCode = null;
        valuestoSend.destinationCityCode = null;
      }
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      formattedValues.sameCountries =
        formattedValues.originCountryCode ===
        formattedValues.destinationCountryCode;
      formattedValues.lowClearance =
        formattedValues.structure === "LOW_CLEARANCE";
      if (title === "Edit freight rate") {
        await editFreight(data.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
          handleCloseRates();
            toast.success("Freight Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }else if(response?.data?.errorCode !== ""){
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage)
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }
        });
      } else {
        await postFreight(formattedValues).then((response) => {
          if (response.status == "201") {
          handleCloseRates();
            toast.success("Freight Rate Added");
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
            resetForm();
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }
        });
      }
    },
  });
  const [showFreightCustomer, SetshowfreightCustomer] = useState(
    title !== "Edit freight rate"
  );
  const handleInputCustomerClick = () => {
    if (title === "Edit freight rate") {
      SetshowfreightCustomer(true);
    }
  };
  useEffect(() => {
    if (title === "Edit freight rate") {
      getProductsonRateEdit();
      getCountryByCity(data?.originCountryCode);
      getDesCountryByCity(data?.destinationCountryCode);
      getOriginCountryonEdit();
      if (data?.tierCode !== null) {
        setTiersbyCountry();
      }
    }
  }, []);
  const setTiersbyCountry = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&countryCode=${data?.originCountryCode}`;
    const tiersRes = await getTiers(params);
    setTiersArray(tiersRes?.data?.data?.content);
  };
  const getProductsonRateEdit = async () => {
    const resp = await getProductsbyId(data?.productCode);
    setProdMaxWeight(resp?.data?.data?.maxWeight);
    setProdWeightUnit(resp?.data?.data?.weightUnit);
  };
  const getOriginCountry = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(productCode, paramslink);
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  const getOriginCountryonEdit = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addFreight.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addFreight.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };

  const [cityError, setCityError] = useState("");
  const [citydesError, setCityDeserror] = useState("");
  const [fromfreightError, setFromfreightError] = useState("");
  const [tofreightError, setfreighterror] = useState("");
  const [flatfreightError, setFlatfreightError] = useState("");
  const [unitPricefreightError, setUnitPricefreightError] = useState("");
  const [unitfreight, setUnitfreightError] = useState("");
  const [prodWeightUnit, setProdWeightUnit] = useState("");
  const [convertedWeight, SetConvertedWeight] = useState("");
  useEffect(() => {
    // Define conversion functions
    const convertGramToKg = (weight) => weight / 1000;
    const convertKgToGram = (weight) => weight * 1000;
    const convertPoundToKg = (weight) => weight / 2.2;
    const convertKgToPound = (weight) => weight * 2.2;

    // Define the converted weight variable
    let convertedWeight = prodmaxWeight;

    // Handle conversions
    if (prodWeightUnit === addFreight?.values?.weightUnits) {
      // No conversion needed
      SetConvertedWeight(prodmaxWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addFreight?.values?.weightUnits === "KG"
    ) {
      convertedWeight = convertGramToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addFreight?.values?.weightUnits === "POUNDS"
    ) {
      // Convert from GRAM to KG, then from KG to POUNDS
      convertedWeight = convertKgToPound(convertGramToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addFreight?.values?.weightUnits === "GRAM"
    ) {
      convertedWeight = convertKgToGram(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addFreight?.values?.weightUnits === "POUNDS"
    ) {
      convertedWeight = convertKgToPound(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addFreight?.values?.weightUnits === "KG"
    ) {
      convertedWeight = convertPoundToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addFreight?.values?.weightUnits === "GRAM"
    ) {
      // Convert from POUNDS to KG, then from KG to GRAM
      convertedWeight = convertKgToGram(convertPoundToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    }
  }, [
    prodmaxWeight,
    prodWeightUnit,
    addFreight?.values?.weightUnits,
    SetConvertedWeight,
  ]);
  const handleSaveRates = (initialValues) => {
    if (addFreight.values.toWeight > convertedWeight) {
      setfreighterror(
        `To Weight should be less than or equal to ${convertedWeight}`
      );
    } else if (
      addFreight.values.weightUnits === "GRAM" &&
      addFreight.values.fromWeight > 69999
    ) {
      setFromfreightError("From Weight should not be greater than 69999");
    } else if (
      addFreight.values.weightUnits === "KG" &&
      addFreight.values.fromWeight > 69
    ) {
      setFromfreightError("From Weight should not be greater than 69");
    } else if (
      addFreight.values.weightUnits === "POUNDS" &&
      addFreight.values.fromWeight > 153
    ) {
      setFromfreightError("From Weight should not be greater than 153");
    } else if (
      addFreight.values.weightUnits === "GRAM" &&
      addFreight.values.toWeight > 70000
    ) {
      setfreighterror("To Weight should not be greater than 70000");
    } else if (
      addFreight.values.weightUnits === "KG" &&
      addFreight.values.toWeight > 70
    ) {
      setfreighterror("To Weight should not be greater than 70");
    } else if (
      addFreight.values.weightUnits === "POUNDS" &&
      addFreight.values.toWeight > 154
    ) {
      setfreighterror("To Weight should not be greater than 154");
    } else if (/\s/g.test(addFreight.values.fromWeight)) {
      setFromfreightError(
        "From Weight cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addFreight.values.toWeight)) {
      setfreighterror(
        "To Weight cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addFreight.values.flatPrice)) {
      setFlatfreightError(
        "Flatprice cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addFreight.values.unit)) {
      setUnitfreightError(
        "Unit cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addFreight.values.unitPrice)) {
      setUnitPricefreightError(
        "Unit price cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setFromfreightError("");
      setfreighterror("");
      setFlatfreightError("");
      setUnitfreightError("");
      setUnitPricefreightError("");
      // Proceed with form submission or other actions
      addFreight?.handleSubmit();
    }
  };

  const addRto = useFormik({
    initialValues: {
      productCode: title === "Edit RTO rate" ? data?.productCode : "",
      structure: title === "Edit RTO rate" ? data?.structure : "",
      customerAccount: title === "Edit RTO rate" ? data?.customerAccount : "",
      contentType: title === "Edit RTO rate" ? data?.contentType : "",
      originCountryCode:
        title === "Edit RTO rate" ? data?.originCountryCode : "",
      originCityCode: title === "Edit RTO rate" ? data?.originCityCode : "",
      destinationCountryCode:
        title === "Edit RTO rate" ? data?.destinationCountryCode : "",
      destinationCityCode:
        title === "Edit RTO rate" ? data?.destinationCityCode : "",
      tierCode: title === "Edit RTO rate" ? data?.tierCode : "",
      weightUnits: title === "Edit RTO rate" ? data?.weightUnits : "",
      fromWeight: title === "Edit RTO rate" ? data?.fromWeight : "",
      toWeight: title === "Edit RTO rate" ? data?.toWeight : "",
      flatPrice:
        title === "Edit RTO rate"
          ? data?.flatPrice === null
            ? ""
            : data?.flatPrice
          : "",
      unit:
        title === "Edit RTO rate"
          ? data?.unit === null
            ? ""
            : data?.unit
          : "",
      unitPrice:
        title === "Edit RTO rate"
          ? data?.unitPrice === null
            ? ""
            : data?.unitPrice
          : "",
      effectiveDate: title === "Edit RTO rate" ? data?.effectiveDate : "",
      state: title === "Edit RTO rate" ? data?.state : "",
      lowClearance: title === "Edit RTO rate" ? data?.lowClearance : false,
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      structure: Yup.string().required("Structure is required"),
      customerAccount: Yup.string().required("Customer is required"),
      contentType: Yup.string().required("Content Type is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      weightUnits: Yup.string().required("Weight Unit is required"),
      fromWeight: Yup.number()
        .required("From Weight is required")
        .min(0, "From Weight must be greater than or equal to 0")
        .typeError("From Weight must be a number"),
      toWeight: Yup.number()
        .typeError("To Weight must be a number")
        .min(1, "To Weight must be greater than or equal to 1")
        .when(["fromWeight"], (fromWeight, schema) =>
          schema.test({
            test: function (toWeight) {
              if (fromWeight && toWeight) {
                return toWeight > fromWeight;
              }
              return true;
            },
            message: "To Weight must be greater than From Weight",
          })
        ),
      flatPrice: Yup.number()
        .typeError("Flat price must be a number")
        .min(0, "Flat price must be greater than or equal to 0"),
      unit: Yup.number()
        .typeError("Unit must be a number")
        .min(0, "Unit must be greater than or equal to 0"),
      unitPrice: Yup.number()
        .typeError("Unit price must be a number")
        .min(0, "Unit price must be greater than or equal to 0"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      if (valuestoSend.originCityCode === "") {
        valuestoSend.originCityCode = null;
      }
      if (valuestoSend.destinationCityCode === "") {
        valuestoSend.destinationCityCode = null;
      }
      if (valuestoSend.flatPrice === "") {
        valuestoSend.flatPrice = null;
      }
      if (valuestoSend.unit === "") {
        valuestoSend.unit = null;
      }
      if (valuestoSend.unitPrice === "") {
        valuestoSend.unitPrice = null;
      }
      if (valuestoSend.tierCode === "") {
        valuestoSend.tierCode = null;
      }
      if (valuestoSend.tierCode !== "") {
        valuestoSend.originCityCode = null;
        valuestoSend.destinationCityCode = null;
      }
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      formattedValues.sameCountries =
        formattedValues.originCountryCode ===
        formattedValues.destinationCountryCode;
      formattedValues.lowClearance =
        formattedValues.structure === "LOW_CLEARANCE";
      if (title === "Edit RTO rate") {
        await editRto(data.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
            handleCloseRates();
            toast.success("RTO Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }else if(response?.data?.errorCode !== ""){
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage)
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }
        });
      } else {
        await postRto(formattedValues).then((response) => {
          if (response.status == "201") {
            handleCloseRates();
            toast.success("RTO Rate Added");
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }
        });
      }
    },
  });
  const getOriginCountryRtoonEdit = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addRto.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addRto.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };

  useEffect(() => {
    if (title === "Edit RTO rate") {
      getProductsonRateEdit();
      getCountryByCity(data?.originCountryCode);
      getDesCountryByCity(data?.destinationCountryCode);
      getOriginCountryRtoonEdit();
      if (data?.tierCode !== null) {
        setTiersbyCountry();
      }
    }
  }, []);
  const [showRtoCustomer, SetshowrtoCustomer] = useState(
    title !== "Edit RTO rate"
  );
  const handleInputrtoCustomerClick = () => {
    if (title === "Edit RTO rate") {
      SetshowrtoCustomer(true);
    }
  };
  useEffect(() => {
    // Define conversion functions
    const convertGramToKg = (weight) => weight / 1000;
    const convertKgToGram = (weight) => weight * 1000;
    const convertPoundToKg = (weight) => weight / 2.2;
    const convertKgToPound = (weight) => weight * 2.2;

    // Define the converted weight variable
    let convertedWeight = prodmaxWeight;

    // Handle conversions
    if (prodWeightUnit === addRto?.values?.weightUnits) {
      // No conversion needed
      SetConvertedWeight(prodmaxWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addRto?.values?.weightUnits === "KG"
    ) {
      convertedWeight = convertGramToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addRto?.values?.weightUnits === "POUNDS"
    ) {
      // Convert from GRAM to KG, then from KG to POUNDS
      convertedWeight = convertKgToPound(convertGramToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addRto?.values?.weightUnits === "GRAM"
    ) {
      convertedWeight = convertKgToGram(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addRto?.values?.weightUnits === "POUNDS"
    ) {
      convertedWeight = convertKgToPound(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addRto?.values?.weightUnits === "KG"
    ) {
      convertedWeight = convertPoundToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addRto?.values?.weightUnits === "GRAM"
    ) {
      // Convert from POUNDS to KG, then from KG to GRAM
      convertedWeight = convertKgToGram(convertPoundToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    }
  }, [
    prodmaxWeight,
    prodWeightUnit,
    addRto?.values?.weightUnits,
    SetConvertedWeight,
  ]);
  const [fromRateError, setFromrateError] = useState("");
  const [toRateError, setTorateError] = useState("");
  const [flatRtoError, setFlatrtoError] = useState("");
  const [unitPriceRtoError, setUnitPriceRtoError] = useState("");
  const [unit, setUnitRtoError] = useState("");
  const handleSaveRtoRates = (initialValues) => {
    if (addRto.values.toWeight > convertedWeight) {
      setTorateError(
        `To Weight should be less than or equal to ${convertedWeight}`
      );
    } else if (
      addRto.values.weightUnits === "GRAM" &&
      addRto.values.fromWeight > 69999
    ) {
      setFromrateError("From Weight should not be greater than 69999");
    } else if (
      addRto.values.weightUnits === "KG" &&
      addRto.values.fromWeight > 69
    ) {
      setFromrateError("From Weight should not be greater than 69");
    } else if (
      addRto.values.weightUnits === "POUNDS" &&
      addRto.values.fromWeight > 153
    ) {
      setFromrateError("From Weight should not be greater than 153");
    } else if (
      addRto.values.weightUnits === "GRAM" &&
      addRto.values.toWeight > 70000
    ) {
      setTorateError("To Weight should not be greater than 70000");
    } else if (
      addRto.values.weightUnits === "KG" &&
      addRto.values.toWeight > 70
    ) {
      setTorateError("To Weight should not be greater than 70");
    } else if (
      addRto.values.weightUnits === "POUNDS" &&
      addRto.values.toWeight > 154
    ) {
      setTorateError("To Weight should not be greater than 154");
    } else if (/\s/g.test(addRto.values.fromWeight)) {
      setFromrateError(
        "From Weight cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addRto.values.toWeight)) {
      setTorateError(
        "To Weight cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addRto.values.flatPrice)) {
      setFlatrtoError(
        "Flatprice cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addRto.values.unit)) {
      setUnitRtoError(
        "Unit cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addRto.values.unitPrice)) {
      setUnitPriceRtoError(
        "Unit price cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setFromrateError("");
      setTorateError("");
      setFlatrtoError("");
      setUnitRtoError("");
      setUnitPriceRtoError("");
      addRto?.handleSubmit();
    }
  };
  const addCod = useFormik({
    initialValues: {
      productCode: title === "Edit COD rate" ? data?.productCode : "",
      customerAccount: title === "Edit COD rate" ? data?.customerAccount : "",
      originCountryCode:
        title === "Edit COD rate" ? data?.originCountryCode : "",
      collectionMethod: title === "Edit COD rate" ? data?.collectionMethod : "",
      destinationCountryCode:
        title === "Edit COD rate" ? data?.destinationCountryCode : "",
      minimumCharge:
        title === "Edit COD rate"
          ? data?.minimumCharge === null
            ? ""
            : data?.minimumCharge
          : "",
      percent:
        title === "Edit COD rate"
          ? data?.percent === null
            ? ""
            : data?.percent
          : "",
      effectiveDate: title === "Edit COD rate" ? data?.effectiveDate : "",
      state: title === "Edit COD rate" ? data?.state : "",
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      customerAccount: Yup.string().required("Customer is required"),
      collectionMethod: Yup.string().required("Collection Type is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      minimumCharge: Yup.number()
        .required("Minimum Charge is required")
        .typeError("Minimum Charge must be a number")
        .min(0, "Minimum Charge must be greater than or equal to 0"),
      percent: Yup.number().typeError("Percent must be a number")
      .min(0, "Percent must be greater than or equal to 0"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      if (valuestoSend.originCityCode === "") {
        valuestoSend.originCityCode = null;
      }
      if (valuestoSend.destinationCityCode === "") {
        valuestoSend.destinationCityCode = null;
      }
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      if (title === "Edit COD rate") {
        await editCod(data.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
          handleCloseRates();
            toast.success("COD Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }else if(response?.data?.errorCode !== ""){
               const errorMessage = response?.data?.msg;
               toast.error(errorMessage)
              } else if (response?.data?.errors?.length > 0) {
                const errorMessages = response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(errorMessages);
                setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }
        });
      } else {
        await postCod(formattedValues).then((response) => {
          if (response.status == "201") {
          handleCloseRates();
            toast.success("COD Rate Added");
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
            resetForm();
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOptionProduct(null);
          }
        });
      }
    },
  });
  const [percentError, setPercentError] = useState("");
  const [minChargeError, setMinChargeError] = useState("");
  const handleSaveCodRates = (initialValues) => {
    if (/\s/g.test(addCod.values.percent)) {
      setPercentError(
        "Percent cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addCod.values.minimumCharge)) {
      setMinChargeError(
        "Minimum Charge cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setPercentError("");
      setMinChargeError("");
      addCod?.handleSubmit();
    }
  };
  const getOriginCountryCodonEdit = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addCod.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addCod.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  useEffect(() => {
    if (title === "Edit COD rate") {
      getOriginCountryCodonEdit();
    }
  }, []);
  const [showCodCustomer, SetshowcodCustomer] = useState(
    title !== "Edit COD rate"
  );
  const handleCustomerCodClick = () => {
    if (title === "Edit COD rate") {
      SetshowcodCustomer(true);
    }
  };
  const addOda = useFormik({
    initialValues: {
      productCode: title === "Edit ODA Surcharge rate" ? data?.productCode : "",
      customerAccount:
        title === "Edit ODA Surcharge rate" ? data?.customerAccount : "",
      originCountryCode:
        title === "Edit ODA Surcharge rate" ? data?.originCountryCode : "",
      destinationCountryCode:
        title === "Edit ODA Surcharge rate" ? data?.destinationCountryCode : "",
      weightUnits: title === "Edit ODA Surcharge rate" ? data?.weightUnits : "",
      flatPrice:
        title === "Edit ODA Surcharge rate"
          ? data?.flatPrice === null
            ? ""
            : data?.flatPrice
          : "",
      unit:
        title === "Edit ODA Surcharge rate"
          ? data?.unit === null
            ? ""
            : data?.unit
          : "",
      unitPrice:
        title === "Edit ODA Surcharge rate"
          ? data?.unitPrice === null
            ? ""
            : data?.unitPrice
          : "",
      fromWeight: title === "Edit ODA Surcharge rate" ? data?.fromWeight : "",
      toWeight: title === "Edit ODA Surcharge rate" ? data?.toWeight : "",
      effectiveDate:
        title === "Edit ODA Surcharge rate" ? data?.effectiveDate : "",
      state: title === "Edit ODA Surcharge rate" ? data?.state : "",
      lowClearance:
        title === "Edit ODA Surcharge rate" ? data?.lowClearance : false,
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      customerAccount: Yup.string().required("Customer is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      weightUnits: Yup.string().required("Weight Unit is required"),
      flatPrice: Yup.number()
        .typeError("Flat price must be a number")
        .min(0, "Flat price must be greater than or equal to 0"),
        fromWeight: Yup.number()
        .required("From Weight is required")
        .min(0, "From Weight must be greater than or equal to 0")
        .typeError("From Weight must be a number"),
      toWeight: Yup.number()
        .typeError("To Weight must be a number")
        .min(1, "To Weight must be greater than or equal to 1")
        .when(["fromWeight"], (fromWeight, schema) =>
          schema.test({
            test: function (toWeight) {
              if (fromWeight && toWeight) {
                return toWeight > fromWeight;
              }
              return true;
            },
            message: "To Weight must be greater than From Weight",
          })
        ),
      unit: Yup.number()
        .typeError("Unit must be a number")
        .min(0, "Unit must be greater than or equal to 0"),
      unitPrice: Yup.number()
        .typeError("Unit price must be a number")
        .min(0, "Unit price must be greater than or equal to 0"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      if (valuestoSend.originCityCode === "") {
        valuestoSend.originCityCode = null;
      }
      if (valuestoSend.destinationCityCode === "") {
        valuestoSend.destinationCityCode = null;
      }
      if (valuestoSend.flatPrice === "") {
        valuestoSend.flatPrice = null;
      }
      if (valuestoSend.unit === "") {
        valuestoSend.unit = null;
      }
      if (valuestoSend.unitPrice === "") {
        valuestoSend.unitPrice = null;
      }
      if (valuestoSend.tierCode === "") {
        valuestoSend.tierCode = null;
      }
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      if (title === "Edit ODA Surcharge rate") {
        await editOda(data.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
          handleCloseRates();
            toast.success("ODA Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }else if(response?.data?.errorCode !== ""){
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage)
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      } else {
        await postOda(formattedValues).then((response) => {
          if (response.status == "201") {
            handleCloseRates();
            toast.success("ODA Rate Added");
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
            resetForm();
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      }
    },
  });
  const getOriginCountryOdaonEdit = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addOda.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addOda.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  useEffect(() => {
    if (title === "Edit ODA Surcharge rate") {
      getProductsonRateEdit();
      getOriginCountryOdaonEdit();
    }
  }, []);
  const [showodaCustomer, SetshowodaCustomer] = useState(
    title !== "Edit ODA Surcharge rate"
  );
  const handleInputodaCustomerClick = () => {
    if (title === "Edit ODA Surcharge rate") {
      SetshowodaCustomer(true);
    }
  };
  const addInsurance = useFormik({
    initialValues: {
      productCode: title === "Edit Insurance rate" ? data?.productCode : "",
      customerAccount:
        title === "Edit Insurance rate" ? data?.customerAccount : "",
      originCountryCode:
        title === "Edit Insurance rate" ? data?.originCountryCode : "",
      destinationCountryCode:
        title === "Edit Insurance rate" ? data?.destinationCountryCode : "",
      percent: title === "Edit Insurance rate" ? data?.percent : "",
      state: title === "Edit Insurance rate" ? data?.state : "",
      effectiveDate: title === "Edit Insurance rate" ? data?.effectiveDate : "",
      declaredValueMin: title === "Edit Insurance rate" ? data?.declaredValueMin : "",
      declaredValueMax: title === "Edit Insurance rate" ? data?.declaredValueMax : "",
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      customerAccount: Yup.string().required("Customer is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      percent: Yup.number()
        .required("Percent is required")
        .typeError("Percent must be a number")
        .min(0, "Percent must be greater than or equal to 0"),
        declaredValueMin: Yup.number()
        .min(0, "Min Declared value must be greater than or equal to 0")
        .typeError("Min Declared value must be a number"),
        declaredValueMax: Yup.number()
        .min(0, "Max Declared value must be greater than or equal to 0")
        .typeError("Max Declared value must be a number")
        .when(["declaredValueMin"], (declaredValueMin, schema) =>
          schema.test({
            test: function (declaredValueMax) {
              if (declaredValueMin && declaredValueMax) {
                return declaredValueMax > declaredValueMin;
              }
              return true;
            },
            message: "Max declared value must be greater than Min declared value",
          })
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      if (title === "Edit Insurance rate") {
        await editInsurance(data.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
            handleCloseRates();
            toast.success("Insurance Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }else if(response?.data?.errorCode !== ""){
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage)
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length >= 1) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      } else {
        await postInsurance(formattedValues).then((response) => {
          if (response.status == "201") {
            handleCloseRates();
            toast.success("Insurance Rate Added");
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
            resetForm();
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      }
    },
  });
  const [flatInsurancepercentError, setFlatInsurancePercentError] =
    useState("");
    const [flatInsuranceDecMaxError, setFlatInsuranceDecMaxError] =
    useState("");
    const [flatInsuranceDecMinError, setFlatInsuranceDecMinError] =
    useState("");
  const handleSaveInsuranceRates = (initialValues) => {
    if (/\s/g.test(addInsurance.values.percent)) {
      setFlatInsurancePercentError(
        "Percent cannot contain leading, trailing, or consecutive spaces"
      );
    }else if (/\s/g.test(addInsurance.values.declaredValueMin)) {
      setFlatInsuranceDecMinError(
        "Minimum Declared value cannot contain leading, trailing, or consecutive spaces"
      );  
    }else if (/\s/g.test(addInsurance.values.declaredValueMax)) {
      setFlatInsuranceDecMaxError(
        "Maximum Declared value cannot contain leading, trailing, or consecutive spaces"
      );  
    } else {
      setFlatInsurancePercentError("");
      setFlatInsuranceDecMaxError("");
      setFlatInsuranceDecMinError("");
      addInsurance?.handleSubmit();
    }
  };
  const getOriginCountryInsuranceonEdit = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addInsurance.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addInsurance.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  useEffect(() => {
    if (title === "Edit Insurance rate") {
      getOriginCountryInsuranceonEdit();
    }
  }, []);
  const [showInsuranceCustomer, SetshowInsuranceCustomer] = useState(
    title !== "Edit Insurance rate"
  );
  const handleCustomerInsuranceClick = () => {
    if (title === "Edit Insurance rate") {
      SetshowInsuranceCustomer(true);
    }
  };
  const addAttempts = useFormik({
    initialValues: {
      productCode: title === "Edit Attempts rate" ? data?.productCode : "",
      customerAccount:
        title === "Edit Attempts rate" ? data?.customerAccount : "",
      originCountryCode:
        title === "Edit Attempts rate" ? data?.originCountryCode : "",
      destinationCountryCode:
        title === "Edit Attempts rate" ? data?.destinationCountryCode : "",
      charge: title === "Edit Attempts rate" ? data?.charge : "",
      state: title === "Edit Attempts rate" ? data?.state : "",
      effectiveDate: title === "Edit Attempts rate" ? data?.effectiveDate : "",
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      customerAccount: Yup.string().required("Customer is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      charge: Yup.number()
        .required("Charges is required")
        .typeError("Charges must be a number")
        .min(0, "Charges must be greater than or equal to 0"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      if (title === "Edit Attempts rate") {
        await editAttempts(data.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
            handleCloseRates();
            toast.success("Attempts Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }else if(response?.data?.errorCode !== ""){
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage)
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length >= 1) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      } else {
        await postAttempts(formattedValues).then((response) => {
          if (response.status == "201") {
            handleCloseRates();
            toast.success("Attempts Rate Added");
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
            resetForm();
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      }
    },
  });
  const [flatAttemptspercentError, setFlatAttemptsPercentError] = useState("");
  const handleSaveAttemptsRates = (initialValues) => {
    if (/\s/g.test(addAttempts.values.charge)) {
      setFlatAttemptsPercentError(
        "Charges cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setFlatAttemptsPercentError("");
      addAttempts?.handleSubmit();
    }
  };
  const getOriginCountryAttemptsonEdit = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addAttempts.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addAttempts.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  useEffect(() => {
    if (title === "Edit Attempts rate") {
      getOriginCountryAttemptsonEdit();
    }
  }, []);
  const [showAttemptsCustomer, SetshowAttemptsCustomer] = useState(
    title !== "Edit Attempts rate"
  );
  const handleCustomerAttemptsClick = () => {
    if (title === "Edit Attempts rate") {
      SetshowAttemptsCustomer(true);
    }
  };
  useEffect(() => {
    // Define conversion functions
    const convertGramToKg = (weight) => weight / 1000;
    const convertKgToGram = (weight) => weight * 1000;
    const convertPoundToKg = (weight) => weight / 2.2;
    const convertKgToPound = (weight) => weight * 2.2;

    // Define the converted weight variable
    let convertedWeight = prodmaxWeight;

    // Handle conversions
    if (prodWeightUnit === addOda?.values?.weightUnits) {
      // No conversion needed
      SetConvertedWeight(prodmaxWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addOda?.values?.weightUnits === "KG"
    ) {
      convertedWeight = convertGramToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addOda?.values?.weightUnits === "POUNDS"
    ) {
      // Convert from GRAM to KG, then from KG to POUNDS
      convertedWeight = convertKgToPound(convertGramToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addOda?.values?.weightUnits === "GRAM"
    ) {
      convertedWeight = convertKgToGram(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addOda?.values?.weightUnits === "POUNDS"
    ) {
      convertedWeight = convertKgToPound(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addOda?.values?.weightUnits === "KG"
    ) {
      convertedWeight = convertPoundToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addOda?.values?.weightUnits === "GRAM"
    ) {
      // Convert from POUNDS to KG, then from KG to GRAM
      convertedWeight = convertKgToGram(convertPoundToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    }
  }, [
    prodmaxWeight,
    prodWeightUnit,
    addOda?.values?.weightUnits,
    SetConvertedWeight,
  ]);
  const [flatOdaError, setFlatOdaError] = useState("");
  const [unitPriceOdaError, setUnitPriceOdaError] = useState("");
  const [unitOda, setUnitOdaError] = useState("");
  const [fromOdaError, setFromodaError] = useState("");
  const [toOdaError, setOdaerror] = useState("");
  const handleSaveOdaRates = (initialValues) => {
    if (addOda.values.toWeight > convertedWeight) {
      setOdaerror(
        `To Weight should be less than or equal to ${convertedWeight}`
      );
    } else if (
      addOda.values.weightUnits === "GRAM" &&
      addOda.values.fromWeight > 69999
    ) {
      setFromodaError("From Weight should not be greater than 69999");
    } else if (
      addOda.values.weightUnits === "KG" &&
      addOda.values.fromWeight > 69
    ) {
      setFromodaError("From Weight should not be greater than 69");
    } else if (
      addOda.values.weightUnits === "POUNDS" &&
      addOda.values.fromWeight > 153
    ) {
      setFromodaError("From Weight should not be greater than 153");
    } else if (
      addOda.values.weightUnits === "GRAM" &&
      addOda.values.toWeight > 70000
    ) {
      setOdaerror("To Weight should not be greater than 70000");
    } else if (
      addOda.values.weightUnits === "KG" &&
      addOda.values.toWeight > 70
    ) {
      setOdaerror("To Weight should not be greater than 70");
    } else if (
      addOda.values.weightUnits === "POUNDS" &&
      addOda.values.toWeight > 154
    ) {
      setOdaerror("To Weight should not be greater than 154");
    } else if (/\s/g.test(addOda.values.fromWeight)) {
      setFromodaError(
        "From Weight cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addOda.values.toWeight)) {
      setOdaerror(
        "To Weight cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addOda.values.flatPrice)) {
      setFlatOdaError(
        "Flatprice cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addOda.values.unit)) {
      setUnitOdaError(
        "Unit cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addOda.values.unitPrice)) {
      setUnitPriceOdaError(
        "Unit price cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setFlatOdaError("");
      setUnitPriceOdaError("");
      setUnitOdaError("");
      addOda?.handleSubmit();
    }
  };
  const addFuel = useFormik({
    initialValues: {
      productCode: title === "Edit Fuel rate" ? data?.productCode : "",
      customerAccount: title === "Edit Fuel rate" ? data?.customerAccount : "",
      originCountryCode:
        title === "Edit Fuel rate" ? data?.originCountryCode : "",
      destinationCountryCode:
        title === "Edit Fuel rate" ? data?.destinationCountryCode : "",
      unit:
        title === "Edit Fuel rate"
          ? data?.unit === null
            ? ""
            : data?.unit
          : "",
      weightUnits: title === "Edit Fuel rate" ? data?.weightUnits : "",
      unitPrice:
        title === "Edit Fuel rate"
          ? data?.unitPrice === null
            ? ""
            : data?.unitPrice
          : "",
      percent:
        title === "Edit Fuel rate"
          ? data?.percent === null
            ? ""
            : data?.percent
          : "",
      state: title === "Edit Fuel rate" ? data?.state : "",
      effectiveDate: title === "Edit Fuel rate" ? data?.effectiveDate : "",
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      customerAccount: Yup.string().required("Customer is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      percent: Yup.number().typeError("Percent must be a number")
      .min(0, "Percent must be greater than or equal to 0"),
      unit: Yup.number()
        .typeError("Unit must be a number")
        .min(0, "Unit must be greater than or equal to 0"),
      unitPrice: Yup.number()
        .typeError("Unit price must be a number")
        .min(0, "Unit price must be greater than or equal to 0"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      if (valuestoSend.weightUnits === "") {
        valuestoSend.weightUnits = null;
      }
      if (valuestoSend.percent === "") {
        valuestoSend.percent = null;
      }
      if (valuestoSend.unit === "") {
        valuestoSend.unit = null;
      }
      if (valuestoSend.unitPrice === "") {
        valuestoSend.unitPrice = null;
      }
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      if (title === "Edit Fuel rate") {
        await editFuel(data.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
            handleCloseRates();
            toast.success("Fuel Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }else if(response?.data?.errorCode !== ""){
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      } else {
        await postFuel(formattedValues).then((response) => {
          if (response.status == "201") {
            handleCloseRates();
            toast.success("Fuel Rate Added");
            setispostAdded(!ispostAdded);
            setSelectedOptionProduct(null);
            setSelectedOption(null);
            resetForm();
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setSelectedOptionProduct(null);
          }else if(response?.data?.status == "ERROR"){
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            // resetForm();
            setSelectedOptionProduct(null);
            // setSelectedOption(null);
          }
        });
      }
    },
  });
  const [flatFuelpercentError, setFlatFuelPercentError] = useState("");
  const [unitPriceFuelError, setUnitPriceFuelError] = useState("");
  const [unitFuel, setUnitFuelError] = useState("");
  const handleSaveFuelRates = (initialValues) => {
    if (/\s/g.test(addFuel.values.percent)) {
      setFlatFuelPercentError(
        "Percent cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addFuel.values.unit)) {
      setUnitFuelError(
        "Unit cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (/\s/g.test(addFuel.values.unitPrice)) {
      setUnitPriceFuelError(
        "Unit price cannot cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (
      addFuel?.values?.weightUnits !== "" &&
      addFuel?.values?.unit === ""
    ) {
      setUnitFuelError("Unit is required");
    } else if (
      addFuel?.values?.weightUnits !== "" &&
      addFuel?.values?.unitPrice === ""
    ) {
      setUnitPriceFuelError("Unit price is required");
    } else if (
      (addFuel?.values?.weightUnits == "" && addFuel?.values?.percent == "")
    ) {
      setFlatFuelPercentError("Percent is required");
    } else {
      setFlatFuelPercentError("");
      setUnitFuelError("");
      setUnitPriceFuelError("");
      addFuel?.handleSubmit();
    }
  };
  const getOriginCountryFuelonEdit = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addFuel.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addFuel.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  useEffect(() => {
    if (title === "Edit Fuel rate") {
      getOriginCountryFuelonEdit();
    }
  }, []);
  const [showFuelProduct, SetshowFuelProduct] = useState(
    title !== "Edit Fuel rate"
  );
  const [showFuelCustomer, SetshowFuelCustomer] = useState(
    title !== "Edit Fuel rate"
  );
  const handleProductFuelClick = () => {
    if (title === "Edit Fuel rate") {
      SetshowFuelProduct(true);
    }
  };
  const handleCustomerFuelClick = () => {
    if (title === "Edit Fuel rate") {
      SetshowFuelCustomer(true);
    }
  };
  //create invoice request
  const addInvoice = useFormik({
    initialValues: {
      customerAccounts: [],
      toDate: "",
      fromDate: "",
      billingCountry: "",
      operationCode: [],
    },
    validationSchema: Yup.object({
      toDate: Yup.string().required("To Date is required"),
      fromDate: Yup.string().required("From Date is required"),
      billingCountry: Yup.string().required("Billing Country is required"),
      operationCode: Yup.array()
        .min(1, "Status is required")
        .required("Status is required"),
      customerAccounts: Yup.array()
        .required("Customer Account is required")
        .min(1, "At least one customer account is need to be added")
        .max(50, "Cannot add more than 50 customers"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formattedValues = {
        ...values,
        fromDate: values.fromDate
          ? new Date(values.fromDate + " UTC").setUTCHours(12, 0, 0, 0)
          : null,
        toDate: values.toDate
          ? new Date(values.toDate + " UTC").setUTCHours(12, 0, 0, 0)
          : null,
      };
      await postInvoiceRequest(formattedValues).then((response) => {
        handleClose();
        if (response?.status == "201") {
          toast.success("Invoice Request Created");
          resetForm();
          setSelectedCustomers([]);
          setAddedCustomers([]);
        }else if(response?.data?.status == "ERROR"){
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);  
        }else if(response?.response?.data?.status == "ERROR"){
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);  
        } else {
          // const errorMessage = response.response.data.msg.split(",")[0];
          toast.error(response.response.statusText);
          resetForm();
          setSelectedCustomers([]);
          setAddedCustomers([]);
        }
      });
    },
  });
  const handleCloseInvoice = () => {
    setModalHandler(!modalHandler);
    setEditHandler(false);
    setSaveHandlerAction(false);
    addInvoice.resetForm();
  };
  //create invoice request template
  const addInvoiceTemplate = useFormik({
    initialValues: {
      name: "",
      customerAccounts: [],
      billingCountry: "",
      operationCode: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      billingCountry: Yup.string().required("Billing Country is required"),
      operationCode: Yup.array()
        .min(1, "Operation Code is required") // Set minimum length requirement to 1
        .required("Operation Code is required"),
      customerAccounts: Yup.array()
        .required("Customer Account is required")
        .min(1, "At least one customer account is need to be added")
        .max(50, "Cannot add more than 50 customers"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postInvoiceRequestTemplate(values).then((response) => {
        handleClose();
        if (response?.status == "201") {
          toast.success("Invoice Request Template Created");
          resetForm();
          setSelectedCustomers([]);
          setAddedCustomers([]);
        }else if(response?.response?.data?.status == "ERROR"){
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);  
        } else if (response?.response?.data?.status === 400) {
          toast.error(response?.response?.data?.error);
          resetForm();
          setSelectedCustomers([]);
          setAddedCustomers([]);
        } else {
          // const errorMessage = response.response.data.msg.split(",")[0];
          toast.error(response.response.statusText);
          resetForm();
          setSelectedCustomers([]);
          setAddedCustomers([]);
        }
      });
    },
  });
  //create mapping
  const addMapping = useFormik({
    initialValues: {
      type: title === "Edit Mapping" ? data?.type : "",
      value: title === "Edit Mapping" ? data?.value : "",
      carrier: title === "Edit Mapping" ? data?.carrierId : "",
      alias: title === "Edit Mapping" ? data?.alias : "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Type is required"),
      value: Yup.string().required("Carrier's Value is required"),
      carrier: Yup.string().required("Carrier is required"),
      alias: Yup.string().required("Ajex Alias is required"),
    }),
    onSubmit: async (values) => {
      if (title === "Edit Mapping") {
        await EditMapping(data.id, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("Mapping Updated");
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postMapping(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Mapping Added");
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  //create Bag
  const addBag = useFormik({
    initialValues: {
      bagNumber: title === "Edit Bag" ? data?.bagNumber : "",
      createBag: title === false,
      sourceHubCode: title === "Edit Bag" ? data?.sourceHubCode : "",
      sourceHubName: title === "Edit Bag" ? data?.sourceHubName : "",
      destinationHubCode: title === "Edit Bag" ? data?.destinationHubCode : "",
      destinationHubName: title === "Edit Bag" ? data?.destinationHubName : "",
      zoneGroupLevel: title === "Edit Bag" ? data?.zoneGroupLevel : "",
      destinationZoneGroupCode:
        title === "Edit Bag" ? data?.destinationZoneGroupCode : "",
      zoneLevel: title === "Edit Bag" ? data?.zoneLevel : "",
      destinationZoneCode:
        title === "Edit Bag" ? data?.destinationZoneCode : "",
      tripId: title === "Edit Bag" ? data?.tripId : "",
    },
    validationSchema: Yup.object({
      sourceHubCode: Yup.string().required("Source Hub Code is required"),
      destinationHubCode: Yup.string().required(
        "Destination Hub Code is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Bag") {
        await editBags(data.id, values).then((response) => {
          handleClosebag();
          if (response.status == 200) {
            toast.success("Bag Updated");
            setIsadded(!isAdded);
            setIsEdited(true);
            setSelectedOption(null);
            resetForm();
          } else if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            resetForm();
          }
        });
      } else {
        await postBags(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Bag Created");
            setSelectedOption(null);
            setIsadded(!isAdded);
            resetForm();
          } else if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            resetForm();
          }
        });
      }
    },
  });
  const [isdesChanged, setIsdeschanged] = useState(false);
  const handleCheckboxChange = () => {
    if (addBag.values.destinationHubCode) {
      setIsBagError("");
      setIsdestinationHubError("");
      addBag.setFieldValue("zoneLevel", !addBag.values.zoneLevel);
    }
  };
  const handleCheckboxGroupChange = () => {
    if (addBag.values.destinationHubCode) {
      setIsBagGroupError("");
      setIsdestinationHubError("");
      addBag.setFieldValue("zoneGroupLevel", !addBag.values.zoneGroupLevel);
    }
  };
  const [isBagError, setIsBagError] = useState("");
  const [isBagGroupError, setIsBagGroupError] = useState("");
  const [isdestinationHubError, setIsdestinationHubError] = useState("");
  const handleSave = () => {
    if (addBag.values.zoneLevel && !addBag.values.destinationZoneCode) {
      setIsBagError("Destination Zone is required");
    } else if (
      addBag.values.zoneGroupLevel &&
      !addBag.values.destinationZoneGroupCode
    ) {
      setIsBagGroupError("Destination Zone Group is required");
    } else if (
      !addBag.values.zoneLevel &&
      !addBag.values.zoneGroupLevel &&
      addBag.values.sourceHubCode === addBag.values.destinationHubCode &&
      addBag.values.destinationHubCode !== ""
    ) {
      setIsdestinationHubError("Source Hub and Destination Hub cannot be same");
    } else {
      setIsBagError("");
      addBag.handleSubmit();
    }
  };
  useEffect(() => {
    if (title !== "Edit Bag") {
      addBag.setFieldValue("destinationZoneCode", "");
    } else {
      if (isOpen) {
        addBag.setFieldValue("destinationZoneCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addBag.values.destinationHubCode]);
  useEffect(() => {
    if (title === "Add Bag" || title === "Edit Bag") {
      if (
        addBag.values.destinationHubCode !== "" &&
        addBag.values.sourceHubCode === addBag.values.destinationHubCode
      ) {
        getAllAreasbyHub(addBag.values.destinationHubCode);
        getAllZonegroupbyHub(addBag.values.destinationHubCode);
        if (title !== "Edit Bag") {
          addBag.setFieldValue("zoneGroupLevel", false);
          addBag.setFieldValue("destinationZoneGroupCode", false);
        }
      } else {
        setIsdestinationHubError("");
        getAllAreasbyHub(addBag.values.destinationHubCode);
        addBag.setFieldValue("zoneGroupLevel", false);
        addBag.setFieldValue("destinationZoneGroupCode", false);
      }
    }
  }, [addBag.values.destinationHubCode]);
  useEffect(() => {
    if (title !== "Edit Bag") {
      if (addBag.values.zoneGroupLevel) {
        addBag.setFieldValue("zoneGroupLevel", false);
        addBag.setFieldValue("destinationZoneGroupCode", "");
      } else if (addBag.values.zoneLevel) {
        addBag.setFieldValue("destinationZoneCode", "");
      }
    }
  }, [addBag.values.sourceHubCode]);
  const [showAsyncInput, setShowAsyncInput] = useState(title !== "Edit Bag");
  const handleRegularInputClick = () => {
    if (title === "Edit Bag") {
      setShowAsyncInput(true);
    }
  };
  const addCountryMap = useFormik({
    initialValues: {
      customerAccount:
        title === "Edit Country Mapping" ? data?.customerAccount : "",
      customerName: title === "Edit Country Mapping" ? data?.customerName : "",
      countryIsoCode3: title === "Edit Country Mapping" ? data?.countryIsoCode3 : "",
      customerAlias:
        title === "Edit Country Mapping" ? data?.customerAlias : "",
    },
    validationSchema: Yup.object({
      customerAccount: Yup.string().required("Customer Account is required"),
      countryIsoCode3: Yup.string().required("Country is required"),
      customerAlias: Yup.string().required("Customer Alias is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Country Mapping") {
        await updateCountryMappingById(data.id, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("Country Mapping Updated");
            setSelectedOption(null);
            setPageCustomer(-1);
            resetForm();
          }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length == undefined){
            const errorMessages = response?.data?.msg;
          toast.error(`${errorMessages}`);
          setSelectedOption(null);
          setPageCustomer(-1);
        }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length >= 1){
          const errorMessages = response?.data?.errors
          .map((item) => `Row ${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
        setSelectedOption(null);
        setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      } else {
        await postCountryMapping(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Country Mapping Created");
            setSelectedOption(null);
            setPageCustomer(-1);
            resetForm();
          }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length == undefined){
            const errorMessages = response?.data?.msg;
          toast.error(`${errorMessages}`);
          setSelectedOption(null);
          setPageCustomer(-1);
        }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length >= 1){
          const errorMessages = response?.data?.errors
          .map((item) => `Row ${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
        setSelectedOption(null);
        setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      }
    },
  });
  const [showCustomer, SetshowCustomer] = useState(
    title !== "Edit Country Mapping"
  );
  const handleCusClick = () => {
    if (title === "Edit Country Mapping") {
      SetshowCustomer(true);
    }
  };
  const [showRegCustomer, SetshowRegCustomer] = useState(
    title !== "Edit Region Mapping"
  );
  const handleRegCusClick = () => {
    if (title === "Edit Region Mapping") {
      SetshowRegCustomer(true);
    }
  };
  const [showCityCustomer, SetshowCityCustomer] = useState(
    title !== "Edit City Mapping"
  );
  const handleCityCusClick = () => {
    if (title === "Edit City Mapping") {
      SetshowCityCustomer(true);
    }
  };
  const [showDisCustomer, SetshowDisCustomer] = useState(
    title !== "Edit District Mapping"
  );
  const handleDisCusClick = () => {
    if (title === "Edit District Mapping") {
      SetshowDisCustomer(true);
    }
  };
  const addRegionMap = useFormik({
    initialValues: {
      customerAccount:
        title === "Edit Region Mapping" ? data?.customerAccount : "",
      customerName: title === "Edit Region Mapping" ? data?.customerName : "",
      countryIsoCode3: title === "Edit Region Mapping" ? data?.countryIsoCode3 : "",
      regionCode: title === "Edit Region Mapping" ? data?.regionCode : "",
      customerAlias: title === "Edit Region Mapping" ? data?.customerAlias : "",
    },
    validationSchema: Yup.object({
      customerAccount: Yup.string().required("Customer Account is required"),
      customerName: Yup.string().required("Customer Name is required"),
      countryIsoCode3: Yup.string().required("Country is required"),
      regionCode: Yup.string().required("Region is required"),
      customerAlias: Yup.string().required("Customer Alias is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Region Mapping") {
        await updateRegionMappingById(data.id, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("Region Mapping Updated");
            resetForm();
            setSelectedOption(null);
            setPageCustomer(-1);
          }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length < 1){
            const errorMessages = response?.data?.msg;
          toast.error(`${errorMessages}`);
          setSelectedOption(null);
          setPageCustomer(-1);
        }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length == undefined){
          const errorMessages = response?.data?.errors
          .map((item) => `Row ${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
        setSelectedOption(null);
        setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      } else {
        await postRegionMapping(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Region Mapping Created");
            resetForm();
            setSelectedOption(null);
            setPageCustomer(-1);
          }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length == undefined){
            const errorMessages = response?.data?.msg;
          toast.error(`${errorMessages}`);
          setSelectedOption(null);
          setPageCustomer(-1);
        }else if(response?.data?.status == "ERROR" && response?.data?.errors?.length >= 1){
          const errorMessages = response?.data?.errors
          .map((item) => `Row ${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
        setSelectedOption(null);
        setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      }
    },
  });
  useEffect(() => {
    if (!params.id && title !== "Edit Region Mapping") {
      addRegionMap.setFieldValue("regionCode", "");
    }
  }, [addRegionMap.values.countryIsoCode3]);
  const addCityMap = useFormik({
    initialValues: {
      customerAccount:
        title === "Edit City Mapping" ? data?.customerAccount : "",
      customerName: title === "Edit City Mapping" ? data?.customerName : "",
      cityCode: title === "Edit City Mapping" ? data?.cityCode : "",
      customerAlias: title === "Edit City Mapping" ? data?.customerAlias : "",
      countryIsoCode3: title === "Edit City Mapping" ? data?.countryIsoCode3 : "",
      regionCode: title === "Edit City Mapping" ? data?.regionCode : "",
    },
    validationSchema: Yup.object({
      customerAccount: Yup.string().required("Customer Account is required"),
      customerName: Yup.string().required("Customer Name is required"),
      countryIsoCode3: Yup.string().required("Country is required"),
      regionCode: Yup.string().required("Region is required"),
      customerAlias: Yup.string().required("Customer Alias is required"),
      cityCode: Yup.string().required("City is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit City Mapping") {
        await updateCityMappingById(data.id, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("City Mapping Updated");
            resetForm();
            setSelectedOption(null);
            setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      } else {
        await postCityMapping(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("City Mapping Created");
            resetForm();
            setSelectedOption(null);
            setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      }
    },
  });
  useEffect(() => {
    if (!params.id && title !== "Edit City Mapping") {
      addCityMap.setFieldValue("regionCode", "");
      addCityMap.setFieldValue("cityCode", "");
    }
  }, [addCityMap.values.countryIsoCode3]);
  useEffect(() => {
    if (!params.id && title !== "Edit City Mapping") {
      addCityMap.setFieldValue("cityCode", "");
    }
  }, [addCityMap.values.regionCode]);
  useEffect(()=>{
    if (title == "Edit City Mapping" || title == "Edit District Mapping") {
      setCityData();
    }else if(title == "Edit District Mapping"){
      setDistrictData();
    }
  },[])
  useEffect(()=>{
    if(title == "Edit District Mapping"){
      setDistrictData();
    }
  },[])
  const setCityData= async()=>{
    try {
      const cityData = await getCitybyCode(
        data?.countryIsoCode3,
        data?.regionCode
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  }
  const setDistrictData= async()=>{
    try {
      const districtData = await getDistrictbyCode(
        data.countryIsoCode3,
        data.regionCode,
        data.cityCode
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  }
  const addDistrictMap = useFormik({
    initialValues: {
      customerAccount:
        title === "Edit District Mapping" ? data?.customerAccount : "",
        cityCode: title === "Edit District Mapping" ? data?.cityCode : "",
      countryIsoCode3: title === "Edit District Mapping" ? data?.countryIsoCode3 : "",
      regionCode: title === "Edit District Mapping" ? data?.regionCode : "",
      customerAlias:
        title === "Edit District Mapping" ? data?.customerAlias : "",
        districtCode: title === "Edit District Mapping" ? data?.districtCode : "",
    },
    validationSchema: Yup.object({
      customerAccount: Yup.string().required("Customer Account is required"),
      countryIsoCode3: Yup.string().required("Country is required"),
      regionCode: Yup.string().required("Region is required"),
      customerAlias: Yup.string().required("Customer Alias is required"),
      cityCode: Yup.string().required("City is required"),
      districtCode: Yup.string().required("District is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit District Mapping") {
        await updateDistrictMappingById(data.id, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("District Mapping Updated");
            resetForm();
            setSelectedOption(null);
            setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      } else {
        await postDistrictMapping(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("District Mapping Created");
            resetForm();
            setSelectedOption(null);
            setPageCustomer(-1);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            setSelectedOption(null);
            setPageCustomer(-1);
          }
        });
      }
    },
  });
  const [error, setError] = useState(null);
  const addHubs = useFormik({
    initialValues: {
      code: title === "Edit Hub" ? data?.code : "",
      name: title === "Edit Hub" ? data?.name : "",
      countryIsoCode3: title === "Edit Hub" ? data?.countryIsoCode3 : "",
      regionCode:
        title === "Edit Hub"
          ? data?.regionSummary?.name
            ? regionsData.data.data.content.find(
                (region) => region.name === data?.regionSummary?.name
              )?.code
            : ""
          : "",
      cityCode:
        title === "Edit Hub"
          ? data?.citySummary?.name
            ? citiesData.data.data.content.find(
                (city) => city.name === data?.citySummary?.name
              )?.code
            : ""
          : "",
      description: title === "Edit Hub" ? data?.description : "",
      type:
        title === "Edit Hub"
          ? hubTypes?.data?.data?.find((item) => item.label === data?.type)
              ?.key || ""
          : "",
      parentHub: title === "Edit Hub" ? data?.parentHub : "",
      latitude: title === "Edit Hub" ? data?.latitude : "",
      longitude: title === "Edit Hub" ? data?.longitude : "",
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().required("Code is required"),
      name: Yup.string().trim().required("Name is required"),
      countryIsoCode3: Yup.string().required("Country is required"),
      regionCode: Yup.string().required("Region is required"),
      cityCode: Yup.string().required("City is required"),
      description: Yup.string().trim().required("Description is required"),
      type: Yup.string().required("Type is required"),
      latitude: Yup.number()
        .typeError("Latitude must be a number")
        .required("Latitude is required"),
      longitude: Yup.number()
        .typeError("Longitude must be a number")
        .required("Longitude is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Hub") {
        values.id = data.id;
        await editHubs(values).then((response) => {
          // await editHubsbypatch(data.code, values).then((response) => {
          handleClose();
          if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Row ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response.status == 200) {
            toast.success("Hubs Updated");
            handleClosebag();
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postHubs(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Hubs Created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  useEffect(() => {
    if (title !== "Edit Hub") {
      addHubs.setFieldValue("regionCode", "");
      addHubs.setFieldValue("cityCode", "");
    }
  }, [addHubs.values.countryIsoCode3]);
  useEffect(() => {
    if (title !== "Edit Hub") {
      addHubs.setFieldValue("cityCode", "");
    }
  }, [addHubs.values.regionCode]);
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? page + 1 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await getZone(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
            name: zone.name,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsBags = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        let options = [];
        // Construct the endpoint URL only if destinationHubCode is set
        if (addBag.values.destinationHubCode) {
          // Construct the endpoint URL
          let endpoint = `?page=${nextPage}&size=20&hubCode=${addBag.values.destinationHubCode}`;
          if (search) {
            endpoint += `&zoneCode=${search}`;
          }
          options = [];
          const response = await getAllAreasbyHub(endpoint);
          options = response?.data?.data?.content || [];
        }
        setLoadingMore(false);
        return {
          options: options.map((zone) => ({
            label: zone.zoneCode,
            value: zone.zoneCode,
            name: zone.zoneCode,
          })),
          hasMore: options.length > 0,
        };
      }
      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, isdesChanged, addBag.values.destinationHubCode]
  );
  const [asyncOptions, setAsyncOptions] = useState([]);

  // Effect to clear options when destinationHubCode changes
  // useEffect(() => {
  //   loadMoreOptionsBags('');
  //   setPage(-1)
  // }, [isdesChanged]);
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await getZoneGroups(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.name,
            value: zone.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  const loadMoreOptionsCustomer = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : pagecustomer + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPageCustomer(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: option.name,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }
      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, pagecustomer]
  );
  const loadMoreOptionsRatesCustomer = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : customersPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCustomersPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          // endpoint += `&name=${hashsearch}`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }
      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, customersPage]
  );
  const loadMoreProductsOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : productsPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setProductsPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await getProducts(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, productsPage]
  );
  useEffect(() => {
    addServiceAreas.setFieldValue("hubCode", globalHubCode);
  }, [globalHubCode]);
  const addServiceAreas = useFormik({
    initialValues: {
      hubCode: globalHubCode,
      zoneCode: title === "Edit Service Areas" ? data?.zoneCode : "",
      allowedEvents: title === "Edit Service Areas" ? data?.allowedEvents : [],
    },
    validationSchema: Yup.object({
      hubCode: Yup.string().trim().required("Hub code is required"),
      zoneCode: Yup.string().trim().required("Zone code is required"),
      allowedEvents: Yup.array().min(1, "Allowed Events is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Service Areas") {
        values.id = data.id;
        await editServiceAreas(values).then((response) => {
          handleClose();
          if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Row ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            addServiceAreas.setFieldValue("allowedEvents", "");
          } else if (response?.data?.errorCode !== "") {
            const errorMessage = response.data.msg
            toast.error(errorMessage);
            addServiceAreas.setFieldValue("allowedEvents", "");
            setSelectedOption(null);
          } else if (response.status == 200) {
            toast.success("Service area updated");
            handleClosebag();
            setSelectedOption(null);
            addServiceAreas.setFieldValue("allowedEvents", "");
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            addServiceAreas.setFieldValue("allowedEvents", "");
            toast.error(errorMessage);
            setSelectedOption(null);
          }
        });
      } else {
        await postServiceAreas(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Service area created");
            addServiceAreas.setFieldValue("allowedEvents", "");
            setSelectedOption(null);
            setispostAdded(!ispostAdded);
          } else if (response?.data?.errorCode !== "") {
            const errorMessage = response.data.msg
            toast.error(errorMessage);
            addServiceAreas.setFieldValue("allowedEvents", "");
            setSelectedOption(null);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            addServiceAreas.setFieldValue("allowedEvents", "");
            toast.error(errorMessage);
            setSelectedOption(null);
          }
        });
      }
    },
  });
  const addTiers = useFormik({
    initialValues: {
      countryCode: title === "Edit tier" ? data?.countryCode : "",
      code: title === "Edit tier" ? data?.code : "",
      name: title === "Edit tier" ? data?.name : "",
    },
    validationSchema: Yup.object({
      countryCode: Yup.string().required("Country is required"),
      code: Yup.string()
        .matches(/^[^\s]+$/, "Code cannot contain spaces")
        .required("Code is required")
        .min(1, "Code is required"),
      name: Yup.string()
        .matches(/^[^\s]+$/, "Name cannot contain spaces")
        .required("Name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit tier") {
        await updateTiersById(data?.id, values).then((response) => {
          handleClose();
          if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Row ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response.status == 201) {
            toast.success("Tier updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postTiers(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Tier created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  const queryString = window.location.search;
  const param = new URLSearchParams(queryString);
  const tierCountryName = param.get("countryName");
  const addODPairtiers = useFormik({
    initialValues: {
      tierCode: title === "Edit OD Pair" ? params?.id : tier,
      originRegionCode: title === "Edit OD Pair" ? data?.originRegionCode : "",
      originCityCode: title === "Edit OD Pair" ? data?.originCityCode : "",
      destinationRegionCode:
        title === "Edit OD Pair" ? data?.destinationRegionCode : "",
      destinationCityCode:
        title === "Edit OD Pair" ? data?.destinationCityCode : "",
    },
    validationSchema: Yup.object({
      originRegionCode: Yup.string().required("Origin Region is required"),
      originCityCode: Yup.string().required("Origin City is required"),
      destinationRegionCode: Yup.string().required(
        "Destination Region is required"
      ),
      destinationCityCode: Yup.string().required(
        "Destination City is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit OD Pair") {
        await updateOdTierById(data?.id, values).then((response) => {
          handleClose();
          if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.status == 500) {
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Row ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response.status == 201) {
            toast.success("OD Pair updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postOdTier(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("OD Pair for Tier created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  useEffect(() => {
    if (title !== "Edit OD Pair") {
      addODPairtiers.setFieldValue("originCityCode", "");
    } else {
      if (isOpen) {
        addODPairtiers.setFieldValue("originCityCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addODPairtiers.values.originRegionCode]);
  useEffect(() => {
    if (title !== "Edit OD Pair") {
      addODPairtiers.setFieldValue("destinationCityCode", "");
    } else {
      if (isOpen) {
        addODPairtiers.setFieldValue("destinationCityCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addODPairtiers.values.destinationRegionCode]);
  const globalcustomerAccount = useAoneStore(
    (state) => state?.globalcustomerAccount
  );
  const [showAsyncInputareas, setShowAsyncInputareas] = useState(
    title !== "Edit Service Areas"
  );
  const [showAsyncInputtiers, seShowAsyncInputtiers] = useState(
    title !== "Edit OD Pair"
  );
  const [showAsyncInputDestiers, seShowAsyncInputDestiers] = useState(
    title !== "Edit OD Pair"
  );
  const handleRegularInputareasClick = () => {
    if (title === "Edit Service Areas") {
      setShowAsyncInputareas(true);
    }
  };
  const handleRegularInputTiersClick = () => {
    if (title === "Edit OD Pair") {
      seShowAsyncInputtiers(true);
    }
  };
  const handleRegularInputDesTiersClick = () => {
    if (title === "Edit OD Pair") {
      seShowAsyncInputDestiers(true);
    }
  };
  const languages = [
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "en", label: "English" },
    { key: "tr", label: "Turkish" },
  ];
  const [resetKey, setResetKey] = useState(0);
  //locales for npr
  const [locales, setLocales] = useState({});
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const handleObjectAdd = () => {
    if (firstValue?.trim() === "" || secondValue?.trim() === "") {
      toast.error("Cannot add empty value for message locals");
    } else {
      let newObject = { ...locales };
        setResetKey((prevKey) => prevKey + 1);
        newObject[firstValue] = secondValue;
        setLocales(newObject);
        setFirstValue("");
        setSecondValue("");
      }
  };
  const handleObjectDelete = (key) => {
    let newObject = { ...locales };
    delete newObject[key];
    setLocales(newObject);
  };
  const [extlocales, setextLocales] = useState({});
  const [firstextValue, setFirstextValue] = useState("");
  const [secondextValue, setSecondextValue] = useState("");
  const handleObjectextAdd = () => {
    if (firstextValue?.trim() === "" || secondextValue?.trim() === "") {
      toast.error("Cannot add empty value for external message locals");
    } else {
      let newObject = { ...extlocales };
      if (firstextValue?.trim() === "" || secondextValue?.trim() === "") {
        toast.error("Cannot add empty value for message locals");
      } else {
        setResetKey((prevKey) => prevKey + 1);
        let newObject = { ...extlocales };
          newObject[firstextValue] = secondextValue;
          setextLocales(newObject);
          setFirstextValue("");
          setSecondextValue("");
        }
    }
  };
  const handleObjectextDelete = (key) => {
    let newObject = { ...extlocales };
    delete newObject[key];
    setextLocales(newObject);
  };

  const [dataReasons, setDataReasons] = useState({});
  const getReasonsbyId = async () => {
    const response = await getPickupReasonsById(data?.code);
    setDataReasons(response?.data?.data);
    const messageLocalsList = response.data.data.messageLocalesList ?? {};
    const locales = messageLocalsList.reduce((acc, item) => {
      acc[item.language] = item.message;
      return acc;
    }, {});
    setLocales(locales);
    const externalMessageLocalsList =
      response.data.data.externalMessageLocalesList ?? {};
    const extlocales = externalMessageLocalsList.reduce((acc, item) => {
      acc[item.language] = item.message;
      return acc;
    }, {});
    setextLocales(extlocales);
  };
  const getdeliveryReasonsbyId = async () => {
    const response = await getDeliveryReasonsById(data?.code);
    setDataReasons(response?.data?.data);
    const messageLocalsList = response.data.data.messageLocalesList ?? {};
    const locales = messageLocalsList.reduce((acc, item) => {
      acc[item.language] = item.message;
      return acc;
    }, {});
    setLocales(locales);
    const externalMessageLocalsList =
      response.data.data.externalMessageLocalesList ?? {};
    const extlocales = externalMessageLocalsList.reduce((acc, item) => {
      acc[item.language] = item.message;
      return acc;
    }, {});
    setextLocales(extlocales);
  };
  const getHoldReasonsbyId = async () => {
    const response = await getHoldReasonsById(data?.code);
    setDataReasons(response?.data?.data);
    const messageLocalsList = response.data.data.messageLocalesList ?? {};
    const locales = messageLocalsList.reduce((acc, item) => {
      acc[item.language] = item.message;
      return acc;
    }, {});
    setLocales(locales);
  };
  useEffect(() => {
    if (title == "Edit NPR") {
      getReasonsbyId();
    }
  }, []);
  useEffect(() => {
    if (title == "Edit NDR") {
      getdeliveryReasonsbyId();
    }
  }, []);
  useEffect(() => {
    if (title == "Edit Hold reason") {
      getHoldReasonsbyId();
    }
  }, []);
  const addPickupReasons = useFormik({
    initialValues: {
      code: title === "Edit NPR" ? data?.code : "",
      message: title === "Edit NPR" ? data?.message : "",
      externalMessage: title === "Edit NPR" ? data?.externalMessage : "",
      events: title === "Edit NPR" ? data?.events : "",
      category: title === "Edit NPR" ? data?.category : "",
      requireReschedule: title === "Edit NPR" ? data?.requireReschedule : "",
      internal: title === "Edit NPR" ? data?.internal : "",
      requireAddressChange:
        title === "Edit NPR" ? data?.requireAddressChange : "",
      requireOtp: title === "Edit NPR" ? data?.requireOtp : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      code: Yup.string()
        .trim()
        .required("Code is Required")
        .matches(/^[a-zA-Z0-9_\s]*$/, "Code must not contain special characters")
        .max(30, "Code must not be more than 30 characters"),
      message: Yup.string()
        .trim()
        .max(200, "Message must not be more than 200 characters")
        .required("Message is Required"),
      externalMessage: Yup.string()
        .trim()
        .max(200, "External Message must not be more than 200 characters")
        .required("External Message is Required"),
      events: Yup.string().trim().required("Event is Required"),
      category: Yup.string().trim().required("Category is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      // Initialize localsList array
      const localsList = [];
      // Iterate over locales object
      Object.entries(locales).forEach(([language, message]) => {
        // Construct an object for each language
        const localEntry = {
          reasonCode: values.code,
          language: language,
          message: message,
          external: false,
        };
        // Add the local entry to localsList array
        localsList.push(localEntry);
      });
      // Iterate over extlocales object
      Object.entries(extlocales).forEach(([language, message]) => {
        // Construct an object for each language with external set to true
        const extLocalEntry = {
          reasonCode: values.code,
          language: language,
          message: message,
          external: true,
        };

        // Add the external local entry to localsList array
        localsList.push(extLocalEntry);
      });
      // Add localsList to the values object
      values["localesList"] = localsList;
      if (title === "Edit NPR") {
        values["id"] = data.id;
        await updatePickupReasonsById(data?.code, values).then((response) => {
          handleClose();
          if (response?.data?.status == "SUCCESS") {
            toast.success("Pickup reasons Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response.response.data.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postPickupReasons(values).then((response) => {
          handleClose();
          if (response?.data?.status == "SUCCESS") {
            toast.success("Pickup reasons created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response.response.data.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
      setAllDefault();
    },
  });
  const addDeliveryupReasons = useFormik({
    initialValues: {
      code: title === "Edit NDR" ? data?.code : "",
      message: title === "Edit NDR" ? data?.message : "",
      externalMessage: title === "Edit NDR" ? data?.externalMessage : "",
      events: title === "Edit NDR" ? data?.events : "",
      requireReschedule: title === "Edit NDR" ? data?.requireReschedule : "",
      internal: title === "Edit NDR" ? data?.internal : "",
      requireAddressChange:
        title === "Edit NDR" ? data?.requireAddressChange : "",
      requireOtp: title === "Edit NDR" ? data?.requireOtp : "",
      requireGeolocationValidation:
        title === "Edit NDR" ? data?.requireGeolocationValidation : "",
      distance: title === "Edit NDR" ? data?.distance : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      code: Yup.string()
        .trim()
        .required("Code is Required")
        .matches(/^[a-zA-Z0-9_\s]*$/, "Code must not contain special characters")
        .max(30, "Code must not be more than 30 characters"),
      message: Yup.string()
        .trim()
        .max(200, "Message must not be more than 200 characters")
        .required("Message is Required"),
      externalMessage: Yup.string()
        .trim()
        .max(200, "External Message must not be more than 200 characters")
        .required("External Message is Required"),
      events: Yup.string().trim().required("Event is Required")
    }),
    onSubmit: async (values, { resetForm }) => {
      // Initialize localsList array
      const localsList = [];
      // Iterate over locales object
      Object.entries(locales).forEach(([language, message]) => {
        // Construct an object for each language
        const localEntry = {
          reasonCode: values.code,
          language: language,
          message: message,
          external: false,
        };
        // Add the local entry to localsList array
        localsList.push(localEntry);
      });
      // Iterate over extlocales object
      Object.entries(extlocales).forEach(([language, message]) => {
        // Construct an object for each language with external set to true
        const extLocalEntry = {
          reasonCode: values.code,
          language: language,
          message: message,
          external: true,
        };

        // Add the external local entry to localsList array
        localsList.push(extLocalEntry);
      });
      // Add localsList to the values object
      values["localesList"] = localsList;
      if (title === "Edit NDR") {
        values["id"] = data.id;
        await updateDeliveryReasonsById(data?.code, values).then((response) => {
          handleClose();
          if (response?.data?.status == "SUCCESS") {
            toast.success("Delivery reasons Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (
            response?.response?.data?.status === "ERROR" &&
            response?.response?.data.errors?.length < 1
          ) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postDeliveryReasons(values).then((response) => {
          handleClose();
          if (response?.data?.status == "SUCCESS") {
            toast.success("Delivery reasons created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response.response.data.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
      setAllDefault();
    },
  });
  const addHoldReasons = useFormik({
    initialValues: {
      code: title === "Edit Hold reason" ? data?.code : "",
      message: title === "Edit Hold reason" ? data?.message : "",
      externalMessage:
        title === "Edit Hold reason" ? data?.externalMessage : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      code: Yup.string()
      .trim()
      .required("Code is Required")
      .matches(/^[a-zA-Z0-9_\s]*$/, "Code must not contain special characters")
      .max(30, "Code must not be more than 30 characters"),
      message: Yup.string().trim().required("Message is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      // Initialize localsList array
      const localsList = [];
      // Iterate over locales object
      Object.entries(locales).forEach(([language, message]) => {
        // Construct an object for each language
        const localEntry = {
          reasonCode: values.code,
          language: language,
          message: message,
          external: false,
        };
        // Add the local entry to localsList array
        localsList.push(localEntry);
      });
      // Add localsList to the values object
      values["localesList"] = localsList;
      if (title === "Edit Hold reason") {
        values["id"] = data.id;
        await updateHoldReasonsById(data?.code, values).then((response) => {
          handleClose();
          if (response?.data?.status == "SUCCESS") {
            toast.success("Hold reasons Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (
            response?.response?.data?.status === "ERROR" &&
            response?.response?.data.errors?.length < 1
          ) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postHoldReasons(values).then((response) => {
          handleClose();
          if (response?.data?.status == "SUCCESS") {
            toast.success("Hold reasons added");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response.response.data.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
      setAllDefault();
    },
  });
  const [holdcode, SetholdCode] = useState("");
  const [messageholdcode, SetMessageholdCode] = useState("");
  const [extMessageHoldcode, SetExternalMsgHoldCode] = useState("");
  const handleSaveHoldReasons = () => {
    if (/^\s+|\s+$|\s{2,}/.test(addHoldReasons.values.code)) {
      SetholdCode("Code cannot contain leading or trailing spaces");
    } else if (/^\s+|\s+$/.test(addHoldReasons.values.message)) {
      SetMessageholdCode(
        "Message cannot cannot contain leading or trailing spaces"
      );
    } else if (/^\s+|\s+$/.test(addHoldReasons.values.externalMessage)) {
      SetExternalMsgHoldCode(
        "External Message cannot cannot contain leading or trailing spaces"
      );
    } else {
      SetholdCode("");
      SetMessageholdCode("");
      SetExternalMsgHoldCode("");
      addHoldReasons?.handleSubmit();
    }
  };
  const [ndrcode, SetndrCode] = useState("");
  const [messagendrcode, SetMessageNdrCode] = useState("");
  const [extMessagendrcode, SetExternalMsgNdrCode] = useState("");
  const [ndrDistance, setNdrDistance] = useState("");
  const handleSaveDeliveryupReasons = () => {
    if (/^\s+|\s+$|\s{2,}/.test(addDeliveryupReasons.values.code)) {
      SetndrCode("Code cannot contain leading or trailing spaces");
    } else if (/^\s+|\s+$/.test(addDeliveryupReasons.values.message)) {
      SetMessageNdrCode(
        "Message cannot cannot contain leading or trailing spaces"
      );
    } else if (/^\s+|\s+$/.test(addDeliveryupReasons.values.externalMessage)) {
      SetExternalMsgNdrCode(
        "External Message cannot cannot contain leading or trailing spaces"
      );
     }else if(addDeliveryupReasons?.values?.requireGeolocationValidation && addDeliveryupReasons?.values?.distance == ""){
      setNdrDistance(
          "Distance is required"
        );
    } else if (/^\s+|\s+$/.test(addDeliveryupReasons.values.distance)) {
      setNdrDistance(
        "Distance cannot cannot contain leading or trailing spaces"
      );
    } else {
      SetndrCode("");
      SetMessageNdrCode("");
      SetExternalMsgNdrCode("");
      addDeliveryupReasons?.handleSubmit();
    }
  };
  const setAllDefault = () => {
    setLocales({});
    setextLocales({});
  };
  const [nprcode, SetnprCode] = useState("");
  const [messagecode, SetMessageCode] = useState("");
  const [extMessagecode, SetExternalMsgCode] = useState("");
  const handleSavepickupReasons = () => {
    if (/^\s+|\s+$|\s{2,}/.test(addPickupReasons.values.code)) {
      SetnprCode("Code cannot contain leading or trailing spaces");
    } else if (/^\s+|\s+$/.test(addPickupReasons.values.message)) {
      SetMessageCode(
        "Message cannot cannot contain leading or trailing spaces"
      );
    } else if (/^\s+|\s+$/.test(addPickupReasons.values.externalMessage)) {
      SetExternalMsgCode(
        "External Message cannot cannot contain leading or trailing spaces"
      );
    } else {
      SetnprCode("");
      SetMessageCode("");
      SetExternalMsgCode("");
      addPickupReasons?.handleSubmit();
    }
  };
  //currency add
  const addCurrency = useFormik({
    initialValues: {
      code: title === "Edit Currency" ? data?.code : "",
      name: title === "Edit Currency" ? data?.name : "",
      isoNumber: title === "Edit Currency" ? data?.isoNumber : "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .trim()
        .required("Code is required")
        .test("not-a-number", "Code should not be a number", (value) => {
          return isNaN(value);
        }),

      name: Yup.string()
        .trim()
        .required("Name is required")
        .test("not-a-number", "Name should not be a number", (value) => {
          return isNaN(value);
        }),
        isoNumber: Yup.string()
        .trim()
        .required("Number is required")
        .matches(/^[0-9]+$/, "Number must be a valid integer"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Currency") {
        await updateCurrencyById(data.code, values).then((response) => {
          handleClose();
          if (response?.status == 200 && response?.data?.status !== "ERROR") {
            toast.success("Currency Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postCurrency(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Currency Created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  //currency conversion
  const addCurrencyconversion = useFormik({
    initialValues: {
      fromCurrency:
        title === "Edit Currency Conversion" ? data?.fromCurrency : "",
      toCurrency: title === "Edit Currency Conversion" ? data?.toCurrency : "",
      rate: title === "Edit Currency Conversion" ? data?.rate : "",
    },
    validationSchema: Yup.object({
      fromCurrency: Yup.string().required("From currency is required"),
      toCurrency: Yup.string()
        .notOneOf(
          [Yup.ref("fromCurrency")],
          "To currency must be different from From currency"
        )
        .required("To currency is required"),
      rate: Yup.number()
        .typeError("Rate must be a number")
        .required("Rate is required")
        .positive("Rate must be a positive number"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Currency Conversion") {
        await updateCurrencyConversionById(data.id, values).then((response) => {
          handleClose();
          if (response?.status == 200 && response?.data?.status !== "ERROR") {
            toast.success("Currency conversion Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postCurrencyConversion(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Currency conversion created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else if (response?.data?.status) {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  useEffect(() => {
    if (title === "Edit Currency Conversion") {
      let paramslink = `?page=${0}&size=${20}`;
      getCurrency(paramslink);
    }
  }, []);
  const [empl, setEmpl] = useState("");
  useEffect(() => {
    if (title !== "Edit Area") {
      setEmpl(employeeCode);
    }
  }, [title, employeeCode]);
  const addArea = useFormik({
    initialValues: {
      countryCode: title === "Edit Area" ? data?.countryCode : "",
      regionCode: title === "Edit Area" ? data?.regionCode : "all"
    },
    validationSchema: Yup.object({
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.employeeCode = empl;
      if (!values.regionCode) {
        values.regionCode = "all";
      }
      if (title === "Edit Area") {
        const changedValues = Object.keys(values).reduce((result, key) => {
          if (key !== "employeeCode" && values[key] !== data[key]) {
            result[key] = values[key];
          }
          return result;
        }, {});
        if (title === "Edit Area") {
          changedValues.countryCode = values.countryCode;
          if (Object?.keys(changedValues).length === 0) {
            toast.error("Make changes to update");
          } else {
            await editAreas(params?.id, data?.id, changedValues).then(
              (response) => {
                handleClose();
                if (response?.data?.status == "ERROR") {
                  const errorMessage =
                    response?.data?.msg.split(",")[0];
                  toast.error(errorMessage);
                  resetForm();
                }else if (response?.response?.data?.status == "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg.split(",")[0];
                  toast.error(errorMessage);
                  resetForm();
                } else if (response?.data?.status == "ERROR") {
                  const errorMessage = response?.data?.msg.split(",");
                  toast.error(errorMessage);
                  resetForm();
                } else if (response?.response?.data?.status == "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg.split(",")[0];
                  toast.error(errorMessage);
                  resetForm();
                } else {
                  toast.success("Area Updated");
                  setispostAdded(!ispostAdded);
                  resetForm();
                }
              }
            );
          }
        }
      } else {
        if (employeeCode) {
          await postAreas(employeeCode, values).then((response) => {
            handleClose();
            if (response?.data?.status == "ERROR") {
              const errorMessage =
                response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage =
                response?.response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            } else if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",");
              toast.error(errorMessage);
              resetForm();
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage =
                response?.response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            } else {
              toast.success("Area Added");
              setispostAdded(!ispostAdded);
              resetForm();
            }
          });
        } else {
          toast.error("Employee Account need to be created for adding areas");
        }
      }
    },
  });
  const [isAreaOpen, setIsAreaopen] = useState(false);
  useEffect(() => {
    if (title !== "Edit Area") {
      addArea.setFieldValue("regionCode", "");
    } else {
      if (isAreaOpen) {
        addArea.setFieldValue("regionCode", "");
      } else {
        setIsAreaopen(true);
      }
    }
  }, [addArea.values.countryCode]);
  const addOrigin = useFormik({
    initialValues: {
      countryCode: title === "Edit origin" ? data?.countryCode : "",
      regionCode: title === "Edit origin" ? data?.regionCode : "all",
      cityCode: title === "Edit origin" ? data?.cityCode : "all",
    },
    validationSchema: Yup.object({
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.productCode = globalproductCode;
      if (title === "Edit origin") {
        await editOrigin(params?.id, data?.id, values).then((response) => {
          handleClose();
          if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.status == "500") {
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else {
            toast.success("Origin Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          }
        });
      } else {
        if (globalproductCode) {
          await postOrigin(globalproductCode, values).then((response) => {
            handleClose();
            if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
            } else if (response?.response?.data?.status == "500") {
              const errorMessage = response?.response?.data?.error;
              toast.error(errorMessage);
              resetForm();
            } else if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
            } else {
              toast.success("Origin Added");
              setispostAdded(!ispostAdded);
              resetForm();
            }
          });
        } else {
          toast.error("Product need to be created for adding origin");
        }
      }
    },
  });
  const [isOpen, setIsopen] = useState(false);
  const [showOrgCity, setShowOrgCity] = useState(title !== "Edit origin");
  const handleRegularInputoriginClick = () => {
    if (title === "Edit origin") {
      setShowOrgCity(true);
    }
  };
  useEffect(() => {
    if (title !== "Edit origin") {
      addOrigin.setFieldValue("regionCode", "");
      addOrigin.setFieldValue("cityCode", "");
    } else {
      if (isOpen) {
        addOrigin.setFieldValue("regionCode", "");
        addOrigin.setFieldValue("cityCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addOrigin.values.countryCode]);
  useEffect(() => {
    if (title !== "Edit origin") {
      addOrigin.setFieldValue("cityCode", "");
    } else {
      if (isOpen) {
        addOrigin.setFieldValue("cityCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addOrigin.values.regionCode]);
  const addDestination = useFormik({
    initialValues: {
      countryCode: title === "Edit destination" ? data?.countryCode : "",
      regionCode: title === "Edit destination" ? data?.regionCode : "all",
      cityCode: title === "Edit destination" ? data?.cityCode : "all",
    },
    validationSchema: Yup.object({
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.productCode = globalproductCode;
      if (title === "Edit destination") {
        await editDestination(params?.id, data?.id, values).then((response) => {
          handleClose();
          if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.status == "500") {
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else {
            toast.success("Destination Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          }
        });
      } else {
        if (globalproductCode) {
          await postDestination(globalproductCode, values).then((response) => {
            handleClose();
            if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
            } else if (response?.response?.data?.status == "500") {
              const errorMessage = response?.response?.data?.error;
              toast.error(errorMessage);
              resetForm();
            } else if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
            } else {
              toast.success("Destination Added");
              setispostAdded(!ispostAdded);
              resetForm();
            }
          });
        } else {
          toast.error("Product need to be created for adding destination");
        }
      }
    },
  });
  const [showDesCity, setShowDesCity] = useState(title !== "Edit destination");
  const handleRegularInputdesClick = () => {
    if (title === "Edit destination") {
      setShowDesCity(true);
    }
  };
  useEffect(() => {
    if (title !== "Edit destination") {
      addDestination.setFieldValue("regionCode", "");
      addDestination.setFieldValue("cityCode", "");
    } else {
      if (isOpen) {
        addDestination.setFieldValue("regionCode", "");
        addDestination.setFieldValue("cityCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addDestination.values.countryCode]);
  useEffect(() => {
    if (title !== "Edit destination") {
      addDestination.setFieldValue("cityCode", "");
    } else {
      if (isOpen) {
        addDestination.setFieldValue("cityCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addDestination.values.regionCode]);
  const addTransit = useFormik({
    initialValues: {
      originCountry:
        title === "Edit Transit Time" ? data?.originCountryCode : "",
      originCity: title === "Edit Transit Time" ? data?.originCityCode : "",
      originRegion: title === "Edit Transit Time" ? data?.originRegionCode : "",
      destinationCountry:
        title === "Edit Transit Time" ? data?.destinationCountryCode : "",
      destinationCity:
        title === "Edit Transit Time" ? data?.destinationCityCode : "",
      destinationRegion:
        title === "Edit Transit Time" ? data?.destinationRegionCode : "",
      days: title === "Edit Transit Time" ? data?.days : "",
    },
    validationSchema: Yup.object({
      days: Yup.number()
        .required("Days is required")
        .typeError("Days must be a number"),
      originCountry: Yup.string().required("Origin country is required"),
      originCity: Yup.string().required("Origin city is required"),
      originRegion: Yup.string().required("Origin Region is required"),
      destinationCountry: Yup.string().required(
        "Destination Country is required"
      ),
      destinationCity: Yup.string().required("Destination City is required"),
      destinationRegion: Yup.string().required(
        "Destination Region is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Transit Time") {
        await editTransittime(params?.id, data?.id, values).then((response) => {
          handleClose();
          if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
          } else {
            toast.success("Transit Time Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          }
        });
      } else {
        if (globalproductCode) {
          await postTransittime(globalproductCode, values).then((response) => {
            handleClose();
            if (response.status == "201") {
              toast.success("Transit Time Added");
              setispostAdded(!ispostAdded);
              resetForm();
            } else {
              const errorMessage = response.response.data.msg;
              toast.error(errorMessage);
              resetForm();
            }
          });
        } else {
          toast.error("Product need to be created for adding transittime");
        }
      }
    },
  });
  const [showorgTransitCity, setShowOrgTransitCity] = useState(
    title !== "Edit Transit Time"
  );
  const handleInputorgTransitClick = () => {
    if (title === "Edit Transit Time") {
      setShowOrgTransitCity(true);
    }
  };
  const [showdesTransitCity, setShowDesTransitCity] = useState(
    title !== "Edit Transit Time"
  );
  const handleInputdesTransitClick = () => {
    if (title === "Edit Transit Time") {
      setShowDesTransitCity(true);
    }
  };
  useEffect(() => {
    if (title === "Edit Transit Time") {
      getOriginCountryTransit();
    }
  }, []);
  const getOriginCountryTransit = async () => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(
      params?.id ? params?.id : globalproductCode,
      paramslink
    );
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    const response = await getDestination(
      params?.id ? params?.id : globalproductCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  useEffect(() => {
    if (title !== "Edit Transit Time") {
      addTransit.setFieldValue("originCity", "");
      addTransit.setFieldValue("originRegion", "");
    } else {
      if (isOpen) {
        addTransit.setFieldValue("originCity", "");
        addTransit.setFieldValue("originRegion", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addTransit.values.originCountry]);
  useEffect(() => {
    if (title !== "Edit Transit Time") {
      addTransit.setFieldValue("originCity", "");
    } else {
      if (isOpen) {
        addTransit.setFieldValue("originCity", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addTransit.values.originRegion]);
  useEffect(() => {
    if (title !== "Edit Transit Time") {
      addTransit.setFieldValue("destinationCity", "");
      addTransit.setFieldValue("destinationRegion", "");
    } else {
      if (isOpen) {
        addTransit.setFieldValue("destinationCity", "");
        addTransit.setFieldValue("destinationRegion", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addTransit.values.destinationCountry]);
  useEffect(() => {
    if (title !== "Edit Transit Time") {
      addTransit.setFieldValue("destinationCity", "");
    } else {
      if (isOpen) {
        addTransit.setFieldValue("destinationCity", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addTransit.values.destinationRegion]);
  const [phoneErr, setphoneErr] = useState(null);
  const [AltphoneErr, setAltphoneErr] = useState(null);
  const addPickupAddres = useFormik({
    initialValues: {
      name: title === "Edit Pickup Address" ? data?.name : "",
      shipperName: title === "Edit Pickup Address" ? data?.shipperName : "",
      countryCode3: title === "Edit Pickup Address" ? data?.countryCode3 : "",
      districtCode: title === "Edit Pickup Address" ? data?.districtCode : "",
      cityCode: title === "Edit Pickup Address" ? data?.cityCode : "",
      postalCode: title === "Edit Pickup Address" ? data?.postalCode : "",
      regionCode: title === "Edit Pickup Address" ? data?.regionCode : "",
      shortAddress:
        title === "Edit Pickup Address" ? data?.shortAddress : "",
        addressLine1:
        title === "Edit Pickup Address" ? data?.addressLine1 : "",
        addressLine2:
        title === "Edit Pickup Address" ? data?.addressLine2 : "",
      phoneNumber: title === "Edit Pickup Address" ? data?.phoneNumber : "",
      alternativePhone:
        title === "Edit Pickup Address" ? data?.alternativePhone : "",
      longitude: title === "Edit Pickup Address" ? data?.longitude : "",
      latitude: title === "Edit Pickup Address" ? data?.latitude : "",
      emailAddress: title === "Edit Pickup Address" ? data?.emailAddress : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      shipperName: Yup.string().required("Shipper Name is required"),
      countryCode3: Yup.string().required("Country is required"),
      cityCode: Yup.string().required("City is required"),
      districtCode: Yup.string().required("District is required"),
      regionCode: Yup.string().required("Region is required"),
      postalCode: Yup.string()
        .trim()
        .matches(/^\d{4,5}$/, "Postal code must be 4 or 5 digits"),
      phoneNumber: Yup.string().required("Phone Number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!globalcustomerAccount) {
        toast.error("Account Number required for saving pickup address");
        return;
      }
      if (title === "Edit Pickup Address") {
        const valuestoSend = { ...values };
          valuestoSend.phoneNumber =
          addPickupAddres.values.phonecodeprefix +
          addPickupAddres.values.phoneNumber;
          valuestoSend.alternativePhone =
          addPickupAddres.values.phonecodeprefix +
          addPickupAddres.values.alternativePhone;
        await editpickupaddress(globalcustomerAccount, data.id, valuestoSend).then(
          (response) => {
            handleClose();
            if (response.status == "201") {
              toast.success("Pickup Address Updated");
              resetForm();
            } else if (response?.response?.data?.status === "ERROR") {
              const errorMessage = response?.response?.data?.msg?.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            } else if (response.data.status === "ERROR") {
              const errorMessage = response.data.msg.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            } else {
              const errorMessage = response.response.data.msg.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            }
          }
        );
      } else {
        const valuestoSend = { ...values };
          valuestoSend.phoneNumber =
          addPickupAddres.values.phonecodeprefix +
          addPickupAddres.values.phoneNumber;
          valuestoSend.alternativePhone =
          addPickupAddres.values.phonecodeprefix +
          addPickupAddres.values.alternativePhone;
        await postpikcupAddress(globalcustomerAccount, valuestoSend).then(
          (response) => {
            handleClose();
            if (response.status == "201") {
              toast.success("Pickup Address Created");
              if (!params?.id) {
                setTab(5);
              }
              resetForm();
            }else if(response?.data?.errors?.length > 0){
              const errorMessages = response?.data?.errors
              .map((item) => `${item.key} ${item.msg}`)
              .join(", ");
              toast.error(errorMessages);
            }else if(response?.response?.data?.errors?.length > 0){
              const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key} ${item.msg}`)
              .join(", ");
              toast.error(errorMessages);
            } else if (response?.response?.data?.status === "ERROR") {
              const errorMessage = response?.response?.data?.msg?.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            } else if (response.data.status === "ERROR") {
              const errorMessage = response.data.msg.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            } else {
              const errorMessage = response.response.data.msg.split(",")[0];
              toast.error(errorMessage);
              resetForm();
            }
          }
        );
      }
    },
  });
  const [phoneNumberRegex, setphoneNumberRegex] = useState("");
  useEffect(() => {
    if (phoneNumberRegex === null) {
      setphoneErr("");
      return;
    }
    const regex = new RegExp(phoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addPickupAddres.values.phoneNumber &&
      !regex.test(
        addPickupAddres.values.phonecodeprefix +
        addPickupAddres.values.phoneNumber
      )
    ) {
      // If it doesn't match, set the error message
      setphoneErr("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setphoneErr("");
    }
  }, [addPickupAddres.values.phoneNumber, phoneNumberRegex]);
  useEffect(() => {
    if (phoneNumberRegex === null) {
      setAltphoneErr("");
      return;
    }
    const regex = new RegExp(phoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addPickupAddres.values.alternativePhone &&
      !regex.test(
        addPickupAddres.values.phonecodeprefix +
        addPickupAddres.values.alternativePhone
      )
    ) {
      // If it doesn't match, set the error message
      setAltphoneErr("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setAltphoneErr("");
    }
  }, [addPickupAddres.values.alternativePhone, phoneNumberRegex]);
  const [isdeliveryPhonecodePrefix, setIsDeliveryPhonecodeprefix] =
    useState(false);
  const handleCountryChange = async (selectedOption) => {
    addPickupAddres.setFieldValue(
      "countryCode3",
      selectedOption ? selectedOption.isoCode3 : ""
    );
    addPickupAddres.setFieldValue(
      "phonecodeprefix",
      selectedOption.phoneCodePrefix ? selectedOption?.phoneCodePrefix : ""
    );
    let resp = await getCountryArray(selectedOption.isoCode3);
    setphoneNumberRegex(resp?.data?.data?.phoneNumberRegex);
    setIsDeliveryPhonecodeprefix(!!selectedOption);
    // Call your API with isoCode3
    try {
      const regionData = await getRegionbyCode(selectedOption.isoCode3);
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  useEffect(() => {
    if (!isEditPickup) {
      addPickupAddres.setFieldValue("regionCode", "");
      addPickupAddres.setFieldValue("cityCode", "");
      addPickupAddres.setFieldValue("districtCode", "");
    }
  }, [addPickupAddres.values.countryCode3]);
  useEffect(() => {
    if (!isEditPickup) {
      addPickupAddres.setFieldValue("cityCode", "");
      addPickupAddres.setFieldValue("districtCode", "");
    }
  }, [addPickupAddres.values.regionCode]);
  useEffect(() => {
    if (!isEditPickup) {
      addPickupAddres.setFieldValue("districtCode", "");
    }
  }, [addPickupAddres.values.cityCode]);
  useEffect(() => {
    if (!params.id && title !== "Edit District Mapping") {
      addDistrictMap.setFieldValue("regionCode", "");
      addDistrictMap.setFieldValue("cityCode", "");
      addDistrictMap.setFieldValue("districtName", "");
    }
  }, [addDistrictMap.values.countryCode3]);
  useEffect(() => {
    if (!params.id && title !== "Edit District Mapping") {
      addDistrictMap.setFieldValue("cityCode", "");
      addDistrictMap.setFieldValue("districtName", "");
    }
  }, [addDistrictMap.values.regionCode]);
  useEffect(() => {
    if (!params.id && title !== "Edit District Mapping") {
      addDistrictMap.setFieldValue("districtCode", "");
    }
  }, [addDistrictMap.values.cityCode]);
  const handleRegionChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const regionCode = selectedOption.code; // Use the correct property for the region code
    form.setFieldValue("regionCode", regionCode);
    // Call your API with isoCode3
    try {
      const cityData = await getCitybyCode(
        selectedOption.countryIsoCode3,
        selectedOption.code
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  const handleCityChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const cityName = selectedOption.code; // Use the correct property for the region code
    form.setFieldValue("cityCode", cityName);
  };
  const handleCtyChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const cityName = selectedOption.code; // Use the correct property for the region code
    form.setFieldValue("cityCode", cityName);
    try {
      const districtData = await getDistrictbyCode(
        selectedOption.countryIsoCode3,
        selectedOption.regionCode,
        selectedOption.code
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  const handleCtyChangePickup = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const cityName = selectedOption.code; // Use the correct property for the region code
    form.setFieldValue("cityCode", cityName);
    try {
      const districtData = await getDistrictbyCode(
        selectedOption.countryIsoCode3,
        selectedOption.regionCode,
        selectedOption.code
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  const handleDistrictChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const districtName = selectedOption.code; // Use the correct property for the region code
    form.setFieldValue("districtCode", districtName);
  };
  //addzone
  const addZone = useFormik({
    initialValues: {
      code: title === "Edit Zone" ? data?.code : "",
      name: title === "Edit Zone" ? data?.name : "",
      groupCode: title === "Edit Zone" ? data?.groupCode : "",
      alias: title === "Edit Zone" ? data?.alias : "",
      countryCode: title === "Edit Zone" ? data?.countryCode : ""
     },
    validationSchema: Yup.object({
      code: Yup.string().required("Zone Code is required"),
      name: Yup.string().required("Zone Name is required"),
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Zone") {
        await updateZoneById(data.code, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("Zone Updated");
            setispostAdded(!ispostAdded);
            setSelectedOption(null);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postZones(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Zone Created");
            setispostAdded(!ispostAdded);
            setSelectedOption(null);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  const [showZonegroup, SetshowZonegroup] = useState(title !== "Edit Zone");
  const handleInputZonegrpClick = () => {
    if (title === "Edit Zone") {
      SetshowZonegroup(true);
    }
  };
  const getDataPickup = async () => {
    const countryMatch = countriesData?.data?.data?.content?.find(
      (country) => country?.name === data?.country
    );
    if (countryMatch) {
      const regionData = await getRegionbyCode(countryMatch?.isoCode3);
      const cityData = await getCitybyCode(
        regionData?.data?.data?.[0]?.countryIsoCode3,
        regionData?.data?.data?.[0]?.code
      );
      const districtData = await getDistrictbyCode(
        regionData?.data?.data?.[0]?.countryIsoCode3,
        regionData?.data?.data?.[0]?.code,
        cityData?.data?.data[0]?.code
      );
    }
  };
  useEffect(() => {
    if (data) {
      getDataPickup();
    }
  }, [addPickupAddres.values.countryCode3]);
  const addZonegroup = useFormik({
    initialValues: {
      code: title === "Edit Zone Group" ? data?.code : "",
      name: title === "Edit Zone Group" ? data?.name : "",
      countryCode: title === "Edit Zone Group" ? data?.countryCode : ""
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Group Code is required"),
      name: Yup.string().required("Group Name is required"),
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit Zone Group") {
        await updateZonegroupById(data.code, values).then((response) => {
          handleClose();
          if (response.status == 200) {
            toast.success("Zone Group Updated");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postZonegroup(values).then((response) => {
          handleClose();
          if (response.status == "201") {
            toast.success("Zone Group Created");
            setispostAdded(!ispostAdded);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  let { id } = useParams();
  //add waybill
  const addWaybill = useFormik({
    initialValues: {
      waybillNumber: "",
      weight: "",
      height: "",
      length: "",
      width: "",
      valid: true,
      // errorMsg: "",
      // receiveTime: 0,
      // dropTime: 0,
    },
    validationSchema: Yup.object({
      waybillNumber: Yup.string()
        .required("Waybill is required")
        .matches(
          /^(?=[a-zA-Z\d]*[a-zA-Z])(?=[a-zA-Z\d]*\d)[a-zA-Z\d]{15}$/,
          "Waybill must be 15 characters"
        ),
      weight: Yup.number()
        .required("Weight is required")
        .typeError("Weight must be a valid number")
        .positive("Weight must be a positive number"),
      height: Yup.number()
        .required("Height is required")
        .typeError("Height must be a valid number")
        .positive("Height must be a positive number"),
      length: Yup.number()
        .required("Length is required")
        .typeError("Length must be a valid number")
        .positive("Length must be a positive number"),
      width: Yup.number()
        .required("Width is required")
        .typeError("Width must be a valid number")
        .positive("Width must be a positive number"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.weight = parseFloat(values.weight);
      values.height = parseFloat(values.height);
      values.length = parseFloat(values.length);
      values.width = parseFloat(values.width);
      await postWaybill(id, values).then((response) => {
        handleClose();
        if (response?.data?.status == "SUCCESS") {
          toast.success("Consignment Added");
          setIsadded(!isAdded);
          resetForm();
        } else if (response?.response?.data?.status == "ERROR") {
          const errorMessage = response?.response?.data?.msg?.split(",")[0];
          toast.error(errorMessage);
          resetForm();
        } else {
          const errorMessage = response.data.msg.split(",")[0];
          toast.error(errorMessage);
          resetForm();
        }
      });
    },
  });
  //edit waybill
  const editWaybill = useFormik({
    initialValues: {
      weight: title === "Edit Waybill" ? data?.weight : "",
      height: title === "Edit Waybill" ? data?.height : "",
      length: title === "Edit Waybill" ? data?.length : "",
      width: title === "Edit Waybill" ? data?.width : "",
    },
    validationSchema: Yup.object({
      weight: Yup.number()
        .required("Weight is required")
        .typeError("Weight must be a valid number")
        .positive("Weight must be a positive number"),
      height: Yup.number()
        .required("Height is required")
        .typeError("Height must be a valid number")
        .positive("Height must be a positive number"),
      length: Yup.number()
        .required("Length is required")
        .typeError("Length must be a valid number")
        .positive("Length must be a positive number"),
      width: Yup.number()
        .required("Width is required")
        .typeError("Width must be a valid number")
        .positive("Width must be a positive number"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.weight = parseFloat(values.weight);
      values.height = parseFloat(values.height);
      values.length = parseFloat(values.length);
      values.width = parseFloat(values.width);
      await editWaybillsbyBagId(data?.bagId, data?.id, values).then(
        (response) => {
          handleClose();
          if (response.status == "200") {
            toast.success("Waybill Edited");
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        }
      );
    },
  });
  const editHandover = useFormik({
    initialValues: {
      from_employee: "",
      to_employee: "",
      job_order: "",
      joborder_qr: "",
      quantity: "",
    },
    validationSchema: Yup.object({
      from_employee: Yup.string().required("Employee Name is Required"),
      to_employee: Yup.string().required("Employee Name is Required"),
      job_order: Yup.string().required("Job Order is Required"),
      joborder_qr: Yup.string().required("Job Order QR is Required"),
      quantity: Yup.string().required("Quantity is Required"),
    }),
    onSubmit: async (values) => {
      handleClose();
    },
  });

  const editEmployee = useFormik({
    initialValues: {
      userId: "Edit Employee" ? data?.userId : "",
      name: "Edit Employee" ? data?.name : "",
      email: "Edit Employee" ? data?.email : "",
      department: "Edit Employee" ? data?.department : "",
      status: "Edit Employee" ? data?.status : false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      userId: Yup.string().required("User Id is Required"),
      name: Yup.string().required("Name is Required"),
      email: Yup.string().required("Email is Required"),
      department: Yup.string().required("Department is Required"),
    }),
    onSubmit: async (values) => {
      title == "Edit Employee"
        ? putEmployeeById(data?.id, values).then(() => handleClose())
        : postEmployees(values).then(() => handleClose());
    },
  });
  const billingAddress = useFormik({
    initialValues: {
      billingContactName:
        data?.customerBillingAddress?.billingContactName ?? "",
      billingContactPhone:
        data?.customerBillingAddress?.billingContactPhone ?? "",
      billingEInvoiceEmail:
        data?.customerBillingAddress?.billingEInvoiceEmail ?? "",
      billingCountry: data?.customerBillingAddress?.billingCountry ?? "",
      billingCity: data?.customerBillingAddress?.billingCity ?? "",
      billingPostal: data?.customerBillingAddress?.billingPostal ?? "",
      billingAddrsStreet:
        data?.customerBillingAddress?.billingAddrsStreet ?? "",
      billingPhone: data?.customerBillingAddress?.billingContactPhone ?? "",
      billingEmail: data?.customerBillingAddress?.billingEmail ?? "",
      billingFax: data?.customerBillingAddress?.billingFax ?? "",
    },
    enableReinitialize: true,
  });

  const customerAddress = useFormik({
    initialValues: {
      country: data?.customerAddress?.country ?? "",
      city: data?.customerAddress?.city ?? "",
      postalCode: data?.customerAddress?.postalCode ?? "",
      addressStreet: data?.customerAddress?.addressStreet ?? "",
      mobile: data?.customerAddress?.mobile ?? "",
      phone: data?.customerAddress?.phone ?? "",
      email: data?.customerAddress?.email ?? "",
      fax: data?.customerAddress?.fax ?? "",
    },
    enableReinitialize: true,
  });

  const updateSubAccount = useFormik({
    initialValues: {
      subAccountNo: data?.subAccountNo,
      subAccountName: data?.subAccountName,
      bussinessLine: data?.bussinessLine,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      subAccountNo: Yup.string().required("Account Number is Required"),
      subAccountName: Yup.string().required("Name is Required"),
      bussinessLine: Yup.string().required("Business Line is Required"),
    }),
    onSubmit: async (values) => {
      values["customerAddress"] = customerAddress.values;
      values["customerBillingAddress"] = billingAddress.values;
      await updateSubAccounts(params.id, values.subAccountNo, values);
      handleClose();
    },
  });
  const AutoFillRoleName = () => {
    let values = addRole.values;
    let roleName =
      "ROLE" +
      (values.app ? `_${values.app}` : "") +
      (values.module ? `_${values.module}` : "") +
      (values.subModule ? `_${values.subModule}` : "") +
      (values.model ? `_${values.model}` : "") +
      (values.permission ? `.${values.permission}` : "");
    return roleName;
  };

  const handleMultipleChange = (e, name, i) => {
    let data = [...dummyProductUrl];
    if (name == "alt_text" || name == "display_order") {
      data[i][name] = e;
    } else {
      data[i]["image"][name] = e;
    }
    setDummyProductUrl(data);
  };

  const getArrays = async () => {
    if (title == "Add Agent" || title == "Edit Agent" || title == "Add Agents" || title == "Edit Agents") {
      const data = await getChuteConfigs("?");
      setConfiguration(data.data.data.content);
    } else if (title == "Add Employee" || title == "Edit Employee") {
      const data = await getEmployeeDepartments();
      setDepartment(data.data.data);
    }
  };
  useEffect(() => {
    getArrays();
  }, []);
  const [selectedZone, setSelectedZone] = useState("");
  const handleZoneChange = (e) => {
    setSelectedZone(e.target.value);
  };
  const handleRowClick = (billingCountry, operationCode, customerAccounts) => {
    setShow(false);
    addInvoice.setFieldValue("billingCountry", billingCountry);
    addInvoice.setFieldValue("operationCode", [operationCode]);
    addInvoice.setFieldValue("customerAccounts", customerAccounts);
    const matchedCustomers = customerData?.data?.data?.content.filter(
      (customer) => customerAccounts.includes(customer.accountNumber)
    );
    setAddedCustomers(matchedCustomers);
    addInvoice.setValues({
      ...addInvoice.values,
      billingCountry,
      operationCode: [operationCode],
      customerAccounts,
    });
  };
  return (
    <div>
      <Modal
        show={modalHandler}
        onHide={
          title === "Create new freight rate" ||
          title === "Edit freight rate" ||
          title === "Create new COD rate" ||
          title === "Edit COD rate" ||
          title === "Create new ODA Surcharge rate" ||
          title === "Edit ODA Surcharge rate" ||
          title === "Create new Insurance rate" ||
          title === "Edit Insurance rate" ||
          title === "Create new Attempts rate" ||
          title === "Edit Attempts rate" ||
          title === "Create new Fuel rate" ||
          title === "Edit Fuel rate" ||
          title === "Create new RTO rate" ||
          title === "Edit RTO rate"
            ? handleCloseRates
            : handleClose
        }
        className={
          title === "Create service area" ||
          title === "Edit Service Areas" ||
          title === "Add Area" ||
          title === "Edit Area" ||
          title === "Add origin" ||
          title === "Edit origin" ||
          title === "Add destination" ||
          title === "Edit destination"
            ? "addnewservicearea"
            : title === "Create Invoice Request Templates" ||
              title === "Create Invoice Request"
            ? "addnewpopupNpr"
            : title === "Create new tier" || title === "Edit tier" || title == "New Country Mapping" || title == "Edit Country Mapping" || title == "New Region Mapping" || title == "Edit Region Mapping" || title == "New City Mapping" || title == "Edit City Mapping" || title == "New District Mapping" || title == "Edit District Mapping" || title == "Create new currency" || title == "Edit Currency" || title == "Create new conversion" || title == "Edit Currency Conversion"
            ? "addtiers"
            : title === "Create New NPR" ||
              title === "Edit NPR" ||
              title === "Create New NDR" ||
              title === "Edit NDR"
            ? "addnewpopupinvoice"
            : title === "Create New Zone" ||
            title === "Edit Zone" ||
            title === "Create New Zone Group" ||
            title === "Edit Zone Group"
          ? "addVehicleCateg"
            : title === "Add new Hub" ||
              title === "Edit Hub" ||
              title === "Create third party configuration" ||
              "Edit third party configuration" ||
              title === "Create new freight rate" ||
              title === "Edit freight rate" ||
              title === "Create new ODA Surcharge rate" ||
              title === "Create new Insurance rate" ||
              title === "Edit Insurance rate" ||
              title === "Create new Attempts rate" ||
              title === "Edit Attempts rate" ||
              "Edit ODA Surcharge rate" ||
              title === "Create new Fuel rate" ||
              title === "Edit Fuel rate" ||
              title === "Create new RTO rate" ||
              title === "Edit RTO rate"
            ? "addnewpopuphub"
            : title === "Add Pickup Address" || title === "Edit Pickup Address"
            ? "addnewpopupCus"
            : "addnewpopup"
        }
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
        </Modal.Header>
        <div
          className={
            title === "Create Invoice Request" ||
            title === "Create Invoice Request Templates"
              ? "modelScrollinvoice"
              : title === "Add new Hub" || title === "Edit Hub"
              ? "modelScrollhub"
              : title === "Create third party configuration" ||
                title === "Edit third party configuration" ||
                title === "Create new freight rate" ||
                title === "Edit freight rate" ||
                title === "Create new RTO rate" ||
                title === "Edit RTO rate"
              ? "modelScrollthirdparty"
              : title === "Create new COD rate" ||
                title === "Edit COD rate" ||
                title === "Create new Fuel rate" ||
                title === "Edit Fuel rate"
              ? "modelScrollFuel"
              : title === "Edit ODA Surcharge rate" ||
                title === "Create new ODA Surcharge rate"
              ? "modelScrollOda"
              : title === "Create new Insurance rate" ||
                title === "Edit Insurance rate" ||
                title === "Create new Attempts rate" ||
                title === "Edit Attempts rate"
              ? "modelScrollOda"
              : title === "Add Pickup Address" ||
                title === "Edit Pickup Address"
              ? "modelScrollCus"
              : title === "Create New NPR" ||
                title === "Edit NPR" 
              ? "modelScrollNpr" :
              title === "Create New NDR" ||
              title === "Edit NDR"
            ? "modelScrollNdr"
              : "modelScroll"
          }
        >
          <Modal.Body>
            {title == "Edit ZID Product" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Store Id:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Store Id"
                        name="storeId"
                        onChange={(e) =>
                          editZidProduct.setFieldValue(
                            "storeId",
                            e.target.value.replace(/[^0-9]/g, "")
                          )
                        }
                        onBlur={editZidProduct.handleBlur}
                        value={editZidProduct.values.storeId}
                        readOnly
                        disabled={!saveHandler}
                      />
                      {editZidProduct.touched.storeId &&
                      editZidProduct.errors.storeId ? (
                        <p className="requiredText">
                          {editZidProduct.errors.storeId}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Product Name [EN]:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Product Name"
                        name="productName"
                        onChange={(e) =>
                          editZidProduct.setFieldValue(
                            "productName",
                            e.target.value
                          )
                        }
                        onBlur={editZidProduct.handleBlur}
                        value={editZidProduct.values.productName}
                        readOnly
                        disabled={!saveHandler}
                      />
                      {editZidProduct.touched.productName &&
                      editZidProduct.errors.productName ? (
                        <p className="requiredText">
                          {editZidProduct.errors.productName}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Product Name [AR]:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Product Name"
                        name="productNameAr"
                        onChange={(e) =>
                          editZidProduct.setFieldValue(
                            "productNameAr",
                            e.target.value
                          )
                        }
                        onBlur={editZidProduct.handleBlur}
                        value={editZidProduct.values.productNameAr}
                        readOnly
                        disabled={!saveHandler}
                      />
                      {editZidProduct.touched.productNameAr &&
                      editZidProduct.errors.productNameAr ? (
                        <p className="requiredText">
                          {editZidProduct.errors.productNameAr}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        SKU:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="SKU Number"
                        name="sku"
                        onChange={(e) =>
                          editZidProduct.setFieldValue("sku", e.target.value)
                        }
                        onBlur={editZidProduct.handleBlur}
                        value={editZidProduct.values.sku}
                        disabled={!saveHandler}
                      />
                      {editZidProduct.touched.sku &&
                      editZidProduct.errors.sku ? (
                        <p className="requiredText">
                          {editZidProduct.errors.sku}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Quantity:
                      </label>
                      <input
                        type="number"
                        className="washMe_btn"
                        placeholder="Number Of Quantity"
                        name="quantity"
                        onChange={(e) =>
                          editZidProduct.setFieldValue(
                            "quantity",
                            e.target.value
                          )
                        }
                        onBlur={editZidProduct.handleBlur}
                        value={editZidProduct.values.quantity}
                        disabled={!saveHandler}
                      />
                      {editZidProduct.touched.quantity &&
                      editZidProduct.errors.quantity ? (
                        <p className="requiredText">
                          {editZidProduct.errors.quantity}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {editZidProduct?.values?.parentProductAr &&
                    editZidProduct?.values?.parentProductAr && (
                      <div className="row mb_24">
                        <div className="col-md-6">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Parent Product [EN]:
                          </label>
                          <input
                            type="text"
                            className="washMe_btn"
                            placeholder="Parent Product"
                            name="Parent Product"
                            onChange={(e) =>
                              editZidProduct.setFieldValue(
                                "parentProductEn",
                                e.target.value
                              )
                            }
                            onBlur={editZidProduct.handleBlur}
                            value={editZidProduct.values?.parentProductEn}
                            readOnly
                            disabled={!saveHandler}
                          />
                          {editZidProduct.touched?.parentProductEn &&
                          editZidProduct.errors?.parentProductEn ? (
                            <p className="requiredText">
                              {editZidProduct.errors?.parentProductEn}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Parent Product [AR]:
                          </label>
                          <input
                            type="text"
                            className="washMe_btn"
                            placeholder="Parent Product"
                            name="Parent Product"
                            onChange={(e) =>
                              editZidProduct.setFieldValue(
                                "parentProductAr",
                                e.target.value
                              )
                            }
                            onBlur={editZidProduct.handleBlur}
                            value={editZidProduct.values.parentProductAr}
                            readOnly
                            disabled={!saveHandler}
                          />
                          {editZidProduct.touched.parentProductAr &&
                          editZidProduct.errors?.parentProductAr ? (
                            <p className="requiredText">
                              {editZidProduct.errors?.parentProductAr}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    )}
                </div>
              </form>
            ) : title == "Add Zid Merchant" || title == "Edit ZID Merchant" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Store Name :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Store Name"
                        name="storeName"
                        onChange={(e) =>
                          addMerchant.setFieldValue("storeName", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.storeName}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.storeName &&
                      addMerchant.errors.storeName ? (
                        <p className="requiredText">
                          {addMerchant.errors.storeName}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Store Id:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Store ID"
                        name="storeId"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "storeId",
                            e.target.value.replace(/[^0-9]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.storeId}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.storeId &&
                      addMerchant.errors.storeId ? (
                        <p className="requiredText">
                          {addMerchant.errors.storeId}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Email Address :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Email"
                        name="emailAddress"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "emailAddress",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.emailAddress}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.emailAddress &&
                      addMerchant.errors.emailAddress ? (
                        <p className="requiredText">
                          {addMerchant.errors.emailAddress}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Phone Number :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Phone Number"
                        name="phone"
                        onChange={(e) =>
                          addMerchant.setFieldValue("phone", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.phone}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.phone && addMerchant.errors.phone ? (
                        <p className="requiredText">
                          {addMerchant.errors.phone}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Country :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Country"
                        name="country"
                        onChange={(e) =>
                          addMerchant.setFieldValue("country", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.country}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.country &&
                      addMerchant.errors.country ? (
                        <p className="requiredText">
                          {addMerchant.errors.country}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Country Code :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Country Code"
                        name="countryCode"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "countryCode",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.countryCode}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.countryCode &&
                      addMerchant.errors.countryCode ? (
                        <p className="requiredText">
                          {addMerchant.errors.countryCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Province :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Province"
                        name="provice"
                        onChange={(e) =>
                          addMerchant.setFieldValue("province", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.province}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.province &&
                      addMerchant.errors.province ? (
                        <p className="requiredText">
                          {addMerchant.errors.province}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        City :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="City"
                        name="city"
                        onChange={(e) =>
                          addMerchant.setFieldValue("city", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.city}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.city && addMerchant.errors.city ? (
                        <p className="requiredText">
                          {addMerchant.errors.city}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        City Code :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="City Code"
                        name="cityCode"
                        onChange={(e) =>
                          addMerchant.setFieldValue("cityCode", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.cityCode}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.cityCode &&
                      addMerchant.errors.cityCode ? (
                        <p className="requiredText">
                          {addMerchant.errors.cityCode}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        District :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="District"
                        name="district"
                        onChange={(e) =>
                          addMerchant.setFieldValue("district", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.district}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.district &&
                      addMerchant.errors.district ? (
                        <p className="requiredText">
                          {addMerchant.errors.district}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Detailed Address :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Address"
                        name="detailAddress"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "detailAddress",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.detailAddress}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.detailAddress &&
                      addMerchant.errors.detailAddress ? (
                        <p className="requiredText">
                          {addMerchant.errors.detailAddress}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Postal Code :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Postal Code"
                        name="postalCode"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "postalCode",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.postalCode}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.postalCode &&
                      addMerchant.errors.postalCode ? (
                        <p className="requiredText">
                          {addMerchant.errors.postalCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Customer Account :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Customer Account Number"
                        name="customerAccount"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "customerAccount",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.customerAccount}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.customerAccount &&
                      addMerchant.errors.customerAccount ? (
                        <p className="requiredText">
                          {addMerchant.errors.customerAccount}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Product Code :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Product Code"
                        name="productCode"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "productCode",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.productCode}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.productCode &&
                      addMerchant.errors.productCode ? (
                        <p className="requiredText">
                          {addMerchant.errors.productCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Tier One Cost :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Tier One Cost"
                        name="tierOneCost"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "tierOneCost",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.tierOneCost}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.tierOneCost &&
                      addMerchant.errors.tierOneCost ? (
                        <p className="requiredText">
                          {addMerchant.errors.tierOneCost}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Tier Two Cost :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Tier Two Cost"
                        name="tierTwoCost"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "tierTwoCost",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.tierTwoCost}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.tierTwoCost &&
                      addMerchant.errors.tierTwoCost ? (
                        <p className="requiredText">
                          {addMerchant.errors.tierTwoCost}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Tier Three Cost :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Tier Three Cost"
                        name="tierThreeCost"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "tierThreeCost",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.tierThreeCost}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.tierThreeCost &&
                      addMerchant.errors.tierThreeCost ? (
                        <p className="requiredText">
                          {addMerchant.errors.tierThreeCost}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Tier One COD Fee :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Tier One COD Fee"
                        name="tierOneCODFee"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "tierOneCODFee",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.tierOneCODFee}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.tierOneCODFee &&
                      addMerchant.errors.tierOneCODFee ? (
                        <p className="requiredText">
                          {addMerchant.errors.tierOneCODFee}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Tier Two COD Fee :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Tier Two COD Fee"
                        name="tierTwoCODFee"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "tierTwoCODFee",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.tierTwoCODFee}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.tierTwoCODFee &&
                      addMerchant.errors.tierTwoCODFee ? (
                        <p className="requiredText">
                          {addMerchant.errors.tierTwoCODFee}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Tier Three COD Fee :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Tier Three COD Fee"
                        name="tierThreeCODFee"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "tierThreeCODFee",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.tierThreeCODFee}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.tierThreeCODFee &&
                      addMerchant.errors.tierThreeCODFee ? (
                        <p className="requiredText">
                          {addMerchant.errors.tierThreeCODFee}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Express Type :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Express Type"
                        name="expressType"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "expressType",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.expressType}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.expressType &&
                      addMerchant.errors.expressType ? (
                        <p className="requiredText">
                          {addMerchant.errors.expressType}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Pick Up Method :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Pickup Method"
                        name="pickupMethod"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "pickupMethod",
                            e.target.value
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.pickupMethod}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.pickupMethod &&
                      addMerchant.errors.pickupMethod ? (
                        <p className="requiredText">
                          {addMerchant.errors.pickupMethod}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Default Weight :
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Default Weight"
                        name="defaultWeight"
                        onChange={(e) =>
                          addMerchant.setFieldValue(
                            "defaultWeight",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.defaultWeight}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      />
                      {addMerchant.touched.defaultWeight &&
                      addMerchant.errors.defaultWeight ? (
                        <p className="requiredText">
                          {addMerchant.errors.defaultWeight}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        App Type:
                      </label>
                      <select
                        // type="text"
                        className="washMe_btn"
                        placeholder="App Type"
                        name="appType"
                        onChange={(e) =>
                          addMerchant.setFieldValue("appType", e.target.value)
                        }
                        onBlur={addMerchant.handleBlur}
                        value={addMerchant.values.appType}
                        disabled={
                          !(saveHandler || title == "Add Zid Merchant") ?? true
                        }
                      >
                        <option value="SHIPPING">SHIPPING</option>
                        <option value="SHIPPING_FULFILLMENT">
                          SHIPPING AND FULFILLMENT
                        </option>
                      </select>
                      {addMerchant.touched.appType &&
                      addMerchant.errors.appType ? (
                        <p className="requiredText">
                          {addMerchant.errors.appType}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            ) : title == "Add Roles" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Role Name:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Role Name"
                        name="name"
                        value={AutoFillRoleName()}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        App:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        placeholder="App Name"
                        name="app"
                        options={app.map((e) => ({ value: e, label: e }))} // Convert app array to options format
                        handleChange={handleAppChange}
                        isSearchable={true}
                        onBlur={addRole.handleBlur}
                      />
                      {addRole.touched.app && addRole.errors.app ? (
                        <p className="requiredText">{addRole.errors.app}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Module:
                      </label>
                      <SearchInput
                        id="module"
                        label="module"
                        name="module"
                        isClearable={true}
                        options={ModuleList?.data?.data || []}
                        getOptionLabel={(module) => module.displayText}
                        getOptionValue={(module) => module.value}
                        value={
                          addRole?.values?.module
                            ? ModuleList?.data?.data?.find(
                                (module) =>
                                  module?.value === addRole?.values?.module
                              )
                            : null
                        }
                        handleChange={handleModuleChange}
                        isSearchable={true}
                        placeholder="Select Module"
                        readOnly={params?.id}
                      />
                      {addRole.touched.module && addRole.errors.module ? (
                        <p className="requiredText">{addRole.errors.module}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Sub Module:
                      </label>
                      <SearchInput
                        id="subModule"
                        label="subModule"
                        name="subModule"
                        isClearable={true}
                        options={SubmoduleList?.data?.data?.content || []}
                        getOptionLabel={(subModule) => subModule.displayText}
                        getOptionValue={(subModule) => subModule.value}
                        value={
                          addRole?.values?.subModule
                            ? SubmoduleList?.data?.data?.content?.find(
                                (subModule) =>
                                  subModule?.value ===
                                  addRole?.values?.subModule
                              )
                            : null
                        }
                        handleChange={handleSubModuleChange}
                        isSearchable={true}
                        placeholder="Select Submodule"
                        readOnly={params?.id}
                      />
                      {addRole.touched.subModule && addRole.errors.subModule ? (
                        <p className="requiredText">
                          {addRole.errors.subModule}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Model:
                      </label>
                      <SearchInput
                        id="model"
                        label="model"
                        name="model"
                        isClearable={true}
                        options={ModelList?.data?.data?.content || []}
                        getOptionLabel={(model) => model.displayText}
                        getOptionValue={(model) => model.value}
                        value={
                          addRole?.values?.model
                            ? ModelList?.data?.data?.content?.find(
                                (model) =>
                                  model?.value === addRole?.values?.model
                              )
                            : null
                        }
                        handleChange={(selectedOption) =>
                          addRole.setFieldValue(
                            "model",
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        isSearchable={true}
                        placeholder="Select Model"
                        readOnly={params?.id}
                      />
                      {addRole.touched.model && addRole.errors.model ? (
                        <p className="requiredText">{addRole.errors.model}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Permission:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        placeholder="Permission"
                        isClearable={true}
                        options={PermissionList?.data?.data?.content || []}
                        getOptionLabel={(model) => model.displayText}
                        getOptionValue={(model) => model.value}
                        value={
                          addRole?.values?.permission
                            ? PermissionList?.data?.data?.content?.find(
                                (model) =>
                                  model?.value === addRole?.values?.permission
                              )
                            : null
                        }
                        name="permission"
                        handleChange={handlePermissionChange}
                        isSearchable={true}
                        onBlur={addRole.handleBlur}
                      />

                      {addRole.touched.permission && addRole.errors.app ? (
                        <p className="requiredText">
                          {addRole.errors.permission}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Description:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <textarea
                        className="washMe_btn"
                        placeholder="Role Description"
                        name="description"
                        onChange={(e) =>
                          addRole.setFieldValue("description", e.target.value)
                        }
                        onBlur={addRole.handleBlur}
                        value={addRole.values.description}
                        style={{ height: "90px", paddingTop: "6px" }}
                      ></textarea>
                      {addRole.touched.description &&
                      addRole.errors.description ? (
                        <p className="requiredText">
                          {addRole.errors.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
               ) : title == "Add Configuration" || title == "Edit Chute" ? (
                <form>
                  <div>
                    <div className="row mb_24">
                      <div className="col-md-12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Name:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Name"
                          name="name"
                          onChange={(e) =>
                            addConfigs.setFieldValue("name", e.target.value)
                          }
                          onBlur={addConfigs.handleBlur}
                          value={addConfigs.values.name}
                          disabled={
                            !(saveHandler || title == "Add Configuration") ?? true
                          }
                        />
                        {addConfigs.touched.name && addConfigs.errors.name ? (
                          <p className="requiredText">{addConfigs.errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb_24">
                      <div className="col-md-12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Description:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <textarea
                          type="text"
                          className="washMe_btn"
                          placeholder="Description"
                          name="description"
                          onChange={(e) =>
                            addConfigs.setFieldValue(
                              "description",
                              e.target.value
                            )
                          }
                          onBlur={addConfigs.handleBlur}
                          value={addConfigs.values.description}
                          style={{ height: "100px", paddingTop: "6px" }}
                          disabled={
                            !(saveHandler || title == "Add Configuration") ?? true
                          }
                        ></textarea>
                        {addConfigs.touched.description &&
                        addConfigs.errors.description ? (
                          <p className="requiredText">
                            {addConfigs.errors.description}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="smallCheckbox col-md-12">
                      <input
                        type="checkbox"
                        id="printBag"
                        style={{ marginLeft: "5px" }}
                        checked={addConfigs.values.printBag}
                        onChange={() => {
                          addConfigs.setFieldValue(
                            "printBag",
                            !addConfigs.values.printBag
                          );
                          if (!addConfigs.values.printBag) {
                            addConfigs.setFieldValue("printBag", true); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label htmlFor="printBag">Print Bag</label>
                    </div>
                  </div>
                </form>
            ) : title == "Add Configration" || title == "Edit Chutes" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Name:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Name"
                        name="name"
                        onChange={(e) =>
                          addConfig.setFieldValue("name", e.target.value)
                        }
                        onBlur={addConfig.handleBlur}
                        value={addConfig.values.name}
                        disabled={
                          !(saveHandler || title == "Add Configration") ?? true
                        }
                      />
                      {addConfig.touched.name && addConfig.errors.name ? (
                        <p className="requiredText">{addConfig.errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Description:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <textarea
                        type="text"
                        className="washMe_btn"
                        placeholder="Description"
                        name="description"
                        onChange={(e) =>
                          addConfig.setFieldValue(
                            "description",
                            e.target.value
                          )
                        }
                        onBlur={addConfig.handleBlur}
                        value={addConfig.values.description}
                        style={{ height: "100px", paddingTop: "6px" }}
                        disabled={
                          !(saveHandler || title == "Add Configration") ?? true
                        }
                      ></textarea>
                      {addConfig.touched.description &&
                      addConfig.errors.description ? (
                        <p className="requiredText">
                          {addConfig.errors.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        ACL Type:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                            id="aclType"
                            label="aclType"
                            name="aclType"
                            options={AclTypeList?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            value={
                              addConfig?.values?.aclType
                                ? AclTypeList?.data?.data?.find(
                                    (acl) =>
                                      acl.key ===
                                    addConfig?.values?.aclType
                                  )
                                : null
                            }
                            handleChange={(selectedOption) => {
                              addConfig.setFieldValue(
                                "aclType",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            isSearchable={true}
                            placeholder="ACL Type"
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                      {addConfig.touched.aclType && addConfig.errors.aclType ? (
                        <p className="requiredText">{addConfig.errors.aclType}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                    <div className="smallCheckbox col-md-12 marginL12 mt_40">
                    <input
                      type="checkbox"
                      id="printACL"
                      checked={addConfig.values.printACL}
                      onChange={() => {
                        addConfig.setFieldValue(
                          "printACL",
                          !addConfig.values.printACL
                        );
                        if (!addConfig.values.printACL) {
                          addConfig.setFieldValue("printACL", true); // Set bagNumber to an empty string
                        }
                      }}
                    />
                    <label htmlFor="printACL">Print ACL</label>
                  </div>
                    </div>
                  </div>
              </form>
               ) : title == "Add Unit" || title == "Edit Unit" ? (
                <form>
                  <div>
                    <div className="row mb_24">
                      <div className="col-md-12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Unit Zone:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        {!showSelectedZone ? (
                        <AsyncSearchInput
                          id="zoneCode"
                          label="Zone"
                          name="zoneCode"
                          value={selectedOption}
                          loadMoreOptions={loadMoreOptions}
                          shouldLoadMore={shouldLoadMore}
                          getOptionLabel={(zone) => zone.label}
                          getOptionValue={(zone) => zone.value}
                          onChange={(selectedOption) => {
                            setSelectedOption(selectedOption);
                            detailedConfigs.setFieldValue(
                              "zoneCode",
                              selectedOption ? selectedOption.value : ""
                            );
                          }}
                          placeholder="Unit"
                        />
                      ) : (
                        <input
                          type="text"
                          value={detailedConfigs?.values?.zoneCode}
                          name="aclCourier"
                          className="mainInput"
                          onClick={handleZoneClick}
                        />
                      )}
                        {detailedConfigs.touched.zoneCode &&
                        detailedConfigs.errors.zoneCode ? (
                          <p className="requiredText">
                            {detailedConfigs.errors.zoneCode}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb_24">
                      <div className="col-md-12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Chute Number:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Chute Number"
                          name="chuteNumber"
                          onChange={(e) =>
                            detailedConfigs.setFieldValue(
                              "chuteNumber",
                              e.target.value
                            )
                          }
                          onBlur={detailedConfigs.handleBlur}
                          value={detailedConfigs.values.chuteNumber}
                          disabled={!(saveHandler || title == "Add Unit") ?? true}
                        />
                        {detailedConfigs.touched.chuteNumber &&
                        detailedConfigs.errors.chuteNumber ? (
                          <p className="requiredText">
                            {detailedConfigs.errors.chuteNumber}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb_24">
                      <div className="col-md-12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Hub Code:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Hub Code"
                          name="hubCode"
                          onChange={(e) =>
                            detailedConfigs.setFieldValue(
                              "hubCode",
                              e.target.value
                            )
                          }
                          onBlur={detailedConfigs.handleBlur}
                          value={detailedConfigs.values.hubCode}
                          disabled={!(saveHandler || title == "Add Unit") ?? true}
                        />
                        {detailedConfigs.touched.hubCode &&
                        detailedConfigs.errors.hubCode ? (
                          <p className="requiredText">
                            {detailedConfigs.errors.hubCode}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb_24">
                      <div className="col-md-12">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Hub Name:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Hub Name"
                          name="hubName"
                          onChange={(e) =>
                            detailedConfigs.setFieldValue(
                              "hubName",
                              e.target.value
                            )
                          }
                          onBlur={detailedConfigs.handleBlur}
                          value={detailedConfigs.values.hubName}
                          disabled={!(saveHandler || title == "Add Unit") ?? true}
                        />
                        {detailedConfigs.touched.hubName &&
                        detailedConfigs.errors.hubName ? (
                          <p className="requiredText">
                            {detailedConfigs.errors.hubName}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb_24">
                      <div className="smallCheckbox col-md-12">
                        <input
                          type="checkbox"
                          id="printBag"
                          style={{ marginLeft: "5px" }}
                          checked={detailedConfigs.values.printBag}
                          onChange={() => {
                            detailedConfigs.setFieldValue(
                              "printBag",
                              !detailedConfigs.values.printBag
                            );
                            if (!detailedConfigs.values.printBag) {
                              detailedConfigs.setFieldValue("printBag", true); // Set bagNumber to an empty string
                            }
                          }}
                        />
                        <label htmlFor="printBag">Print Bag</label>
                      </div>
                    </div>
                  </div>
                </form>
            ) : title == "Add Units" || title == "Edit Units" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Unit Zone:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      {!showSelectedZone ? (
                      <AsyncSearchInput
                        id="zoneCode"
                        label="Zone"
                        name="zoneCode"
                        value={selectedOption}
                        loadMoreOptions={loadMoreOptions}
                        shouldLoadMore={shouldLoadMore}
                        getOptionLabel={(zone) => zone.label}
                        getOptionValue={(zone) => zone.value}
                        onChange={(selectedOption) => {
                          setSelectedOption(selectedOption);
                          detailedConfig.setFieldValue(
                            "zoneCode",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        placeholder="Unit Zone"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        menuStyle={{ zIndex: 9999 }}
                      />
                       ) : (
                <input
                  type="text"
                  value={detailedConfig?.values?.zoneCode}
                  name="aclCourier"
                  className="mainInput"
                  onClick={handleZoneClick}
                />
              )}
                      {detailedConfig.touched.zoneCode &&
                      detailedConfig.errors.zoneCode ? (
                        <p className="requiredText">
                          {detailedConfig.errors.zoneCode}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Hub:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      {!showSelectedHub ? (
                    <AsyncSearchInput
                  id="hubCode"
                  label="hubCode"
                  name="hubCode"
                  value={selectedOptionHub}
                  loadMoreOptions={loadMoreOptionsHubs}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(hub) => hub.label}
                  getOptionValue={(hub) => hub.value}
                  onChange={(selectedOptionHub) => {
                    SetselectedOptionHub(selectedOptionHub);
                    detailedConfig.setFieldValue("hubCode",selectedOptionHub ? selectedOptionHub.value : "");
                  }}
                  placeholder="Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              ) : (
                <input
                  type="text"
                  value={detailedConfig?.values?.hubCode}
                  name="aclCourier"
                  className="mainInput"
                  onClick={handleHubClick}
                />
              )}
                      {detailedConfig.touched.hubCode &&
                      detailedConfig.errors.hubCode ? (
                        <p className="requiredText">
                          {detailedConfig.errors.hubCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Chute Number:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
              id="chuteNumber"
              name="chuteNumber"
              options={maxChuteOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={
                detailedConfig.values.chuteNumber
                  ? maxChuteOptions.find(
                      (option) =>
                        option.value == detailedConfig.values.chuteNumber
                    )
                  : null
              }
              handleChange={(selectedOption) => {
                detailedConfig.setFieldValue(
                  "chuteNumber",
                  selectedOption ? selectedOption.value : ""
                );
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Chute Number"
              // isDisabled={!(saveHandler || title == "Add Unit") ?? true}
            />
                    
                      {detailedConfig.touched.chuteNumber &&
                      detailedConfig.errors.chuteNumber ? (
                        <p className="requiredText">
                          {detailedConfig.errors.chuteNumber}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="smallCheckbox col-md-12 mt_10">
                      <input
                        type="checkbox"
                        id="printACL"
                        style={{ marginLeft: "5px" }}
                        checked={detailedConfig.values.printACL}
                        onChange={() => {
                          detailedConfig.setFieldValue(
                            "printACL",
                            !detailedConfig.values.printACL
                          );
                          if (!detailedConfig.values.printACL) {
                            detailedConfig.setFieldValue("printACL", true); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label htmlFor="printACL">Print ACL Label</label>
                    </div>
                  </div>
                </div>
              </form>
                ) : title == "Add Agent" || title == "Edit Agent" ? (
                  <form>
                    <div>
                      <div className="row mb_24">
                        <div className="col-md-12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Agent Id:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            className="washMe_btn"
                            placeholder="Agent Id"
                            name="agentId"
                            onChange={(e) =>
                              addAgents.setFieldValue("agentId", e.target.value)
                            }
                            onBlur={addAgents.handleBlur}
                            value={addAgents.values.agentId}
                            disabled={
                              !(saveHandler || title == "Add Agent") ?? true
                            }
                          />
                          {addAgents.touched.agentId && addAgents.errors.agentId ? (
                            <p className="requiredText">
                              {addAgents.errors.agentId}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb_24">
                        <div className="col-md-12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Hub Code:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            className="washMe_btn"
                            placeholder="Hub Code"
                            name="hubCode"
                            onChange={(e) =>
                              addAgents.setFieldValue("hubCode", e.target.value)
                            }
                            onBlur={addAgents.handleBlur}
                            value={addAgents.values.hubCode}
                            disabled={
                              !(saveHandler || title == "Add Agent") ?? true
                            }
                          />
                          {addAgents.touched.hubCode && addAgents.errors.hubCode ? (
                            <p className="requiredText">
                              {addAgents.errors.hubCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb_24">
                        <div className="col-md-12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Hub Name:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            className="washMe_btn"
                            placeholder="Hub Name"
                            name="hubName"
                            onChange={(e) =>
                              addAgents.setFieldValue("hubName", e.target.value)
                            }
                            onBlur={addAgents.handleBlur}
                            value={addAgents.values.hubName}
                            disabled={
                              !(saveHandler || title == "Add Agent") ?? true
                            }
                          />
                          {addAgents.touched.hubName && addAgents.errors.hubName ? (
                            <p className="requiredText">
                              {addAgents.errors.hubName}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb_24">
                        <div className="col-md-12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Assigned Configuration:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            className="washMe_btn"
                            placeholder="Configuration"
                            name="name"
                            onClick={() => setDropdownOne(!dropdownOne)}
                            onBlur={addAgents.handleBlur}
                            value={addAgents.values.name}
                            disabled={
                              !(saveHandler || title == "Add Agent") ?? true
                            }
                          />
                          {dropdownOne && (
                            <div className="dropdownResult">
                              {configuration.map((e, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="dropdownResultItem"
                                    onClick={() => {
                                      (saveHandler || title == "Add Agent") &&
                                        setDropdownOne(!dropdownOne);
                                      addAgents.setFieldValue(
                                        "assignedConfigId",
                                        e.id
                                      );
                                      addAgents.setFieldValue("name", e.name);
                                    }}
                                  >
                                    {e.name}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {addAgents.touched.assignedConfigId &&
                          addAgents.errors.assignedConfigId ? (
                            <p className="requiredText">
                              {addAgents.errors.assignedConfigId}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb_24">
                        <div className="col-md-12 smallCheckbox">
                          <input
                            type="checkbox"
                            id="newstatus"
                            checked={addAgents.values.status}
                          />
                          <label
                            htmlFor="newstatus"
                            onClick={() =>
                              (saveHandler || title == "Add Agent") &&
                              addAgents.setFieldValue(
                                "status",
                                !addAgents.values.status
                              )
                            }
                          >
                            {addAgents.values.status ? "Active" : "Inactive"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
            ) : title == "Add Agents" || title == "Edit Agents" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Agent Id:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Agent Id"
                        name="agentId"
                        onChange={(e) =>
                          addAgent.setFieldValue("agentId", e.target.value)
                        }
                        onBlur={addAgent.handleBlur}
                        value={addAgent.values.agentId}
                        disabled={
                          !(saveHandler || title == "Add Agents") ?? true
                        }
                      />
                      {addAgent.touched.agentId && addAgent.errors.agentId ? (
                        <p className="requiredText">
                          {addAgent.errors.agentId}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Assigned Configuration:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
              id="assignedConfigId"
              name="assignedConfigId"
              options={configuration}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={
                addAgent.values.assignedConfigId
                  ? configuration.find(
                      (option) =>
                        option.id == addAgent.values.assignedConfigId
                    )
                  : null
              }
              handleChange={(selectedOption) => {
                addAgent.setFieldValue(
                  "assignedConfigId",
                  selectedOption ? selectedOption.id : ""
                );
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Assigned Configuration"
            />
                      {addAgent.touched.assignedConfigId &&
                      addAgent.errors.assignedConfigId ? (
                        <p className="requiredText">
                          {addAgent.errors.assignedConfigId}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_30">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Hub:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      {!showSelectHub ? (
                    <AsyncSearchInput
                  id="hubCode"
                  label="hubCode"
                  name="hubCode"
                  value={selectedOptionHub}
                  loadMoreOptions={loadMoreOptionsHubs}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(hub) => hub.label}
                  getOptionValue={(hub) => hub.value}
                  onChange={(selectedOptionHub) => {
                    SetselectedOptionHub(selectedOptionHub);
                    addAgent.setFieldValue("hubCode",selectedOptionHub ? selectedOptionHub.value : "");
                  }}
                  placeholder="Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                  isDisabled={
                    !(saveHandler || title == "Add Agents") ?? true
                  }
                />
              ) : (
                <input
                  type="text"
                  value={addAgent?.values?.hubCode}
                  name="hubCode"
                  className="mainInput"
                  onClick={handleHbClick}
                />
              )}
                      {addAgent.touched.hubCode && addAgent.errors.hubCode ? (
                        <p className="requiredText">
                          {addAgent.errors.hubCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="newstatus"
                        checked={addAgent.values.status}
                      />
                      <label
                        htmlFor="newstatus"
                        onClick={() =>
                          (saveHandler || title == "Add Agents") &&
                          addAgent.setFieldValue(
                            "status",
                            !addAgent.values.status
                          )
                        }
                      >
                        {addAgent.values.status ? "Active" : "Inactive"}
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            ) : title == "Add Group" || title == "Add Child Group" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Group Name:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="Grpinput">
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Name"
                          name="name"
                          onChange={(e) =>
                            addGroup.setFieldValue("name", e.target.value)
                          }
                          onBlur={addGroup.handleBlur}
                          value={addGroup.values.name}
                        />
                      </div>
                      {addGroup.touched.name && addGroup.errors.name ? (
                        <p className="requiredText">{addGroup.errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Description:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="Grpinput">
                        <textarea
                          className="washMe_btn"
                          placeholder="Description"
                          name="description"
                          onChange={(e) =>
                            addGroup.setFieldValue(
                              "description",
                              e.target.value
                            )
                          }
                          onBlur={addGroup.handleBlur}
                          value={addGroup.values.description}
                          style={{ height: "90px", paddingTop: "6px" }}
                        ></textarea>
                      </div>
                      {addGroup.touched.description &&
                      addGroup.errors.description ? (
                        <p className="requiredText">
                          {addGroup.errors.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            ) : title == "Add Users" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        User Name:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="Grpinput">
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Username"
                          name="username"
                          onChange={(e) =>
                            addUsers.setFieldValue("username", e.target.value)
                          }
                          onBlur={addUsers.handleBlur}
                          value={addUsers.values.username}
                        />
                      </div>
                      {addUsers.touched.username && addUsers.errors.username ? (
                        <p className="requiredText">
                          {addUsers.errors.username}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Password:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="Groupinput">
                        <input
                          type={passwordHandler ? "password" : "input"}
                          placeholder="Password"
                          name="password"
                          onChange={(e) =>
                            addUsers.setFieldValue("password", e.target.value)
                          }
                          onBlur={addUsers.handleBlur}
                          value={addUsers.values.password}
                        />
                        <div className="inputgroup_append">
                          <span
                            className="Groupinput_text"
                            onClick={() => setPasswordHandler(!passwordHandler)}
                          >
                            <img src={passwordHandler ? openEye : closeEye} />
                          </span>
                        </div>
                      </div>
                      {addUsers.touched.password && addUsers.errors.password ? (
                        <p className="requiredText">
                          {addUsers.errors.password.includes("required") && (
                            <p>Password is required</p>
                          )}
                          <ul className="requiredText">
                            {addUsers.errors.password.includes(
                              "characters"
                            ) && (
                              <li>Password must be at least 6 characters</li>
                            )}
                            {addUsers.errors.password.includes(
                              "not exceed"
                            ) && (
                              <li>Password must not exceed 25 characters</li>
                            )}
                            {addUsers.errors.password.includes(
                              "lowercase letter"
                            ) && (
                              <li>
                                Password must contain at least one lowercase
                                letter
                              </li>
                            )}
                            {addUsers.errors.password.includes(
                              "uppercase letter"
                            ) && (
                              <li>
                                Password must contain at least one uppercase
                                letter
                              </li>
                            )}
                            {addUsers.errors.password.includes("digit") && (
                              <li>Password must contain at least one digit</li>
                            )}
                            {addUsers.errors.password.includes(
                              "special character"
                            ) && (
                              <li>
                                Password must contain at least one special
                                character from -.!@#$%^&*()+?
                              </li>
                            )}
                          </ul>
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Email:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="Grpinput">
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Email"
                          name="email"
                          onChange={(e) =>
                            addUsers.setFieldValue("email", e.target.value)
                          }
                          onBlur={addUsers.handleBlur}
                          value={addUsers.values.email}
                        />
                      </div>
                      {addUsers.touched.email && addUsers.errors.email ? (
                        <p className="requiredText">{addUsers.errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        First Name:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="Grpinput">
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="First Name"
                          name="firstName"
                          onChange={(e) =>
                            addUsers.setFieldValue("firstName", e.target.value)
                          }
                          onBlur={addUsers.handleBlur}
                          value={addUsers.values.firstName}
                        />
                      </div>
                      {addUsers.touched.firstName &&
                      addUsers.errors.firstName ? (
                        <p className="requiredText">
                          {addUsers.errors.firstName}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Last Name:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div className="Grpinput">
                        <input
                          type="text"
                          className="washMe_btn"
                          placeholder="Last Name"
                          name="lastName"
                          onChange={(e) =>
                            addUsers.setFieldValue("lastName", e.target.value)
                          }
                          onBlur={addUsers.handleBlur}
                          value={addUsers.values.lastName}
                        />
                      </div>
                      {addUsers.touched.lastName && addUsers.errors.lastName ? (
                        <p className="requiredText">
                          {addUsers.errors.lastName}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            ) : title == "Edit Handover" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        From Employee:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="From Employee"
                        name="from_employee"
                        onChange={(e) =>
                          editHandover.setFieldValue(
                            "from_employee",
                            e.target.value
                          )
                        }
                        onBlur={editHandover.handleBlur}
                        value={editHandover.values.from_employee}
                        disabled={!saveHandler}
                      />
                      {editHandover.touched.from_employee &&
                      editHandover.errors.from_employee ? (
                        <p className="requiredText">
                          {editHandover.errors.from_employee}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        To Employee:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="To Employee"
                        name="to_employee"
                        onChange={(e) =>
                          editHandover.setFieldValue(
                            "to_employee",
                            e.target.value
                          )
                        }
                        onBlur={editHandover.handleBlur}
                        value={editHandover.values.to_employee}
                        disabled={!saveHandler}
                      />
                      {editHandover.touched.to_employee &&
                      editHandover.errors.to_employee ? (
                        <p className="requiredText">
                          {editHandover.errors.to_employee}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Job Order:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Job Order"
                        name="job_order"
                        onChange={(e) =>
                          editHandover.setFieldValue(
                            "job_order",
                            e.target.value
                          )
                        }
                        onBlur={addUsers.handleBlur}
                        value={editHandover.values.job_order}
                        disabled={!saveHandler}
                      />
                      {editHandover.touched.job_order &&
                      editHandover.errors.job_order ? (
                        <p className="requiredText">
                          {editHandover.errors.job_order}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Job Order QR:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Job Order"
                        name="joborder_qr"
                        onChange={(e) =>
                          editHandover.setFieldValue(
                            "joborder_qr",
                            e.target.value
                          )
                        }
                        onBlur={addUsers.handleBlur}
                        value={editHandover.values.joborder_qr}
                        disabled={!saveHandler}
                      />
                      {editHandover.touched.joborder_qr &&
                      editHandover.errors.joborder_qr ? (
                        <p className="requiredText">
                          {editHandover.errors.joborder_qr}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Quantity:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Quantity"
                        name="quantity"
                        onChange={(e) =>
                          editHandover.setFieldValue("quantity", e.target.value)
                        }
                        onBlur={addUsers.handleBlur}
                        value={editHandover.values.quantity}
                        disabled={!saveHandler}
                      />
                      {editHandover.touched.quantity &&
                      editHandover.errors.quantity ? (
                        <p className="requiredText">
                          {editHandover.errors.quantity}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create third party configuration" ||
              title === "Edit third party configuration" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Information</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Code"
                            value={addThirdParty.values.code}
                            name="code"
                            style={{ width: "120%", marginLeft: "5px" }}
                            onChange={(e) =>
                              addThirdParty.setFieldValue(
                                "code",
                                e.target.value
                              )
                            }
                            className="mainInput"
                            onBlur={addThirdParty.handleBlur}
                            disabled={
                              !(
                                title == "Edit third party configuration" ||
                                title == "Create third party configuration"
                              ) ?? true
                            }
                          />
                          {addThirdParty.touched.code &&
                          addThirdParty.errors.code ? (
                            <p className="requiredText">
                              {addThirdParty.errors.code}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <input
                            type="text"
                            name="name"
                            value={addThirdParty.values.name}
                            onChange={(e) =>
                              addThirdParty.setFieldValue(
                                "name",
                                e.target.value
                              )
                            }
                            placeholder="Name"
                            style={{ width: "120%", marginLeft: "25px" }}
                            className="mainInput"
                            onBlur={addThirdParty.handleBlur}
                            disabled={
                              !(
                                title == "Edit third party configuration" ||
                                title === "Create third party configuration"
                              ) ?? true
                            }
                          />
                          {addThirdParty.touched.name &&
                          addThirdParty.errors.name ? (
                            <p className="requiredText">
                              {addThirdParty.errors.name}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5" style={{ marginRight: "35px" }}>
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Client ID"
                            value={addThirdParty.values.clientId}
                            name="clientId"
                            style={{
                              width: "120%",
                              marginLeft: "5px",
                              marginRight: "10px",
                            }}
                            onChange={(e) =>
                              addThirdParty.setFieldValue(
                                "clientId",
                                e.target.value
                              )
                            }
                            className="mainInput"
                            onBlur={addThirdParty.handleBlur}
                            disabled={
                              !(
                                title == "Edit third party configuration" ||
                                title == "Create third party configuration"
                              ) ?? true
                            }
                          />
                          {addThirdParty.touched.clientId &&
                          addThirdParty.errors.clientId ? (
                            <p className="requiredText">
                              {addThirdParty.errors.clientId}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "50px", marginTop: "7px" }}
                        >
                          <div className="displayFlex alignItemCenter">
                            <div className="smallCheckbox col-md-12">
                              <input
                                type="checkbox"
                                id="autoassign"
                                style={{ marginLeft: "5px" }}
                                checked={addThirdParty.values.autoAssign}
                              />
                              <label
                                htmlFor="autoassign"
                                onClick={() =>
                                  (title == "Edit third party configuration" ||
                                    title ==
                                      "Create third party configuration") &&
                                  addThirdParty.setFieldValue(
                                    "autoAssign",
                                    !addThirdParty.values.autoAssign
                                  )
                                }
                              >
                                Auto Assign
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <div className="displayFlex alignItemCenter">
                            <div className="smallCheckbox col-md-12">
                              <input
                                type="checkbox"
                                id="status"
                                style={{
                                  marginLeft: "35px",
                                  marginTop: "10px",
                                }}
                                defaultChecked={
                                  title === "Edit third party configuration" &&
                                  data?.status === "ACTIVE"
                                }
                                disabled={
                                  !(
                                    title == "Edit third party configuration" ||
                                    title === "Create third party configuration"
                                  ) ?? true
                                }
                                onChange={(e) => {
                                  const newStatus = e.target.checked
                                    ? "ACTIVE"
                                    : "INACTIVE";
                                  addThirdParty.setFieldValue(
                                    "status",
                                    newStatus
                                  );
                                }}
                              />
                              <label
                                htmlFor="status"
                                style={{
                                  marginLeft: "25px",
                                  marginTop: "10px",
                                }}
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="row" style={{ marginLeft: "1px" }}>
                  <div
                    className="borderFrame mb_16 col-md-5"
                    style={{ width: "48%" }}
                  >
                    <div className="borderFrameTitle">Consumed Events</div>
                    <div className="row col-md-12">
                      <div className="col-md-4">
                        <div className="row alignItemCenter mb_12">
                          <div className="displayFlex alignItemCenter">
                            <div className="smallCheckbox col-md-12">
                              <input
                                type="checkbox"
                                id="pickup"
                                style={{ marginLeft: "5px" }}
                                defaultChecked={
                                  title === "Edit third party configuration" &&
                                  data?.consumeEvents?.includes("pickup")
                                }
                                disabled={
                                  !(
                                    title == "Edit third party configuration" ||
                                    title === "Create third party configuration"
                                  ) ?? true
                                }
                                onChange={() => {
                                  const consumeArray =
                                    addThirdParty?.values?.consumeEvents?.split(
                                      ", "
                                    ) || [];
                                  if (consumeArray.includes("pickup")) {
                                    addThirdParty.setFieldValue(
                                      "consumeEvents",
                                      consumeArray
                                        .filter((event) => event !== "pickup")
                                        .join(", ")
                                    );
                                  } else {
                                    addThirdParty.setFieldValue(
                                      "consumeEvents",
                                      [...consumeArray, "pickup"].join(", ")
                                    );
                                  }
                                }}
                              />
                              <label
                                htmlFor="pickup"
                                style={{ marginLeft: "15px" }}
                              >
                                Pickup
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="handover"
                              style={{ marginLeft: "5px" }}
                              defaultChecked={
                                title === "Edit third party configuration" &&
                                data?.consumeEvents?.includes("handover")
                              }
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title === "Create third party configuration"
                                ) ?? true
                              }
                              onChange={() => {
                                const consumeArray =
                                  addThirdParty?.values?.consumeEvents?.split(
                                    ", "
                                  ) || [];
                                if (consumeArray.includes("handover")) {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    consumeArray
                                      .filter((event) => event !== "handover")
                                      .join(", ")
                                  );
                                } else {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    [...consumeArray, "handover"].join(", ")
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="handover"
                              style={{ marginLeft: "5px" }}
                            >
                              Handover
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="rto"
                              style={{ marginLeft: "5px" }}
                              defaultChecked={
                                title === "Edit third party configuration" &&
                                data?.consumeEvents?.includes("rto")
                              }
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title === "Create third party configuration"
                                ) ?? true
                              }
                              onChange={() => {
                                const consumeArray =
                                  addThirdParty?.values?.consumeEvents?.split(
                                    ", "
                                  ) || [];
                                if (consumeArray.includes("rto")) {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    consumeArray
                                      .filter((event) => event !== "rto")
                                      .join(", ")
                                  );
                                } else {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    [...consumeArray, "rto"].join(", ")
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="rto"
                              style={{ marginLeft: "5px" }}
                            >
                              Rto
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row col-md-12">
                      <div className="col-md-4">
                        <div className="row alignItemCenter mb_12">
                          <div className="displayFlex alignItemCenter">
                            <div className="smallCheckbox col-md-12">
                              <input
                                type="checkbox"
                                id="facility"
                                style={{ marginLeft: "5px" }}
                                defaultChecked={
                                  title === "Edit third party configuration" &&
                                  data?.consumeEvents?.includes("facility")
                                }
                                disabled={
                                  !(
                                    title == "Edit third party configuration" ||
                                    title === "Create third party configuration"
                                  ) ?? true
                                }
                                onChange={() => {
                                  const consumeArray =
                                    addThirdParty?.values?.consumeEvents?.split(
                                      ", "
                                    ) || [];
                                  if (consumeArray.includes("facility")) {
                                    addThirdParty.setFieldValue(
                                      "consumeEvents",
                                      consumeArray
                                        .filter((event) => event !== "facility")
                                        .join(", ")
                                    );
                                  } else {
                                    addThirdParty.setFieldValue(
                                      "consumeEvents",
                                      [...consumeArray, "facility"].join(", ")
                                    );
                                  }
                                }}
                              />
                              <label
                                htmlFor="facility"
                                style={{ marginLeft: "15px" }}
                              >
                                Facility
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="exception"
                              style={{ marginLeft: "5px" }}
                              defaultChecked={
                                title === "Edit third party configuration" &&
                                data?.consumeEvents?.includes("exception")
                              }
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title === "Create third party configuration"
                                ) ?? true
                              }
                              onChange={() => {
                                const consumeArray =
                                  addThirdParty?.values?.consumeEvents?.split(
                                    ", "
                                  ) || [];
                                if (consumeArray.includes("exception")) {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    consumeArray
                                      .filter((event) => event !== "exception")
                                      .join(", ")
                                  );
                                } else {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    [...consumeArray, "exception"].join(", ")
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="exception"
                              style={{ marginLeft: "5px" }}
                            >
                              Exception
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="delivery"
                              style={{ marginLeft: "5px" }}
                              defaultChecked={
                                title === "Edit third party configuration" &&
                                data?.consumeEvents?.includes("delivery")
                              }
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title === "Create third party configuration"
                                ) ?? true
                              }
                              onChange={() => {
                                const consumeArray =
                                  addThirdParty?.values?.consumeEvents?.split(
                                    ", "
                                  ) || [];
                                if (consumeArray.includes("delivery")) {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    consumeArray
                                      .filter((event) => event !== "delivery")
                                      .join(", ")
                                  );
                                } else {
                                  addThirdParty.setFieldValue(
                                    "consumeEvents",
                                    [...consumeArray, "delivery"].join(", ")
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="delivery"
                              style={{ marginLeft: "5px" }}
                            >
                              Delivery
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="borderFrame mb_16 col-md-5"
                    style={{ width: "48%", marginLeft: "20px" }}
                  >
                    <div className="borderFrameTitle">Produced Events</div>
                    <div className="row col-md-12 custom-row">
                      <div className="col-md-4">
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="producedpickup"
                              style={{ marginLeft: "5px" }}
                              defaultChecked={
                                title === "Edit third party configuration" &&
                                data?.produceEvents?.includes("pickup")
                              }
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title === "Create third party configuration"
                                ) ?? true
                              }
                              onChange={() => {
                                const produceArray =
                                  addThirdParty?.values?.produceEvents?.split(
                                    ", "
                                  ) || [];
                                if (produceArray.includes("pickup")) {
                                  addThirdParty.setFieldValue(
                                    "produceEvents",
                                    produceArray
                                      .filter((event) => event !== "pickup")
                                      .join(", ")
                                  );
                                } else {
                                  addThirdParty.setFieldValue(
                                    "produceEvents",
                                    [...produceArray, "pickup"].join(", ")
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="producedpickup"
                              style={{ marginLeft: "5px" }}
                            >
                              Pickup
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="producedhandover"
                              defaultChecked={
                                title === "Edit third party configuration" &&
                                data?.produceEvents?.includes("handover")
                              }
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title == "Create third party configuration"
                                ) ?? true
                              }
                              onChange={() => {
                                const produceArray =
                                  addThirdParty?.values?.produceEvents?.split(
                                    ", "
                                  ) || [];
                                if (produceArray.includes("handover")) {
                                  addThirdParty.setFieldValue(
                                    "produceEvents",
                                    produceArray
                                      .filter((event) => event !== "handover")
                                      .join(", ")
                                  );
                                } else {
                                  addThirdParty.setFieldValue(
                                    "produceEvents",
                                    [...produceArray, "handover"].join(", ")
                                  );
                                }
                              }}
                            />
                            <label htmlFor="producedhandover">Handover</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="deliver"
                              style={{ marginLeft: "5px" }}
                              defaultChecked={
                                title === "Edit third party configuration" &&
                                data?.produceEvents?.includes("deliver")
                              }
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title === "Create third party configuration"
                                ) ?? true
                              }
                              onChange={() => {
                                const produceArray =
                                  addThirdParty?.values?.produceEvents?.split(
                                    ", "
                                  ) || [];
                                if (produceArray.includes("deliver")) {
                                  addThirdParty.setFieldValue(
                                    "produceEvents",
                                    produceArray
                                      .filter((event) => event !== "deliver")
                                      .join(", ")
                                  );
                                } else {
                                  addThirdParty.setFieldValue(
                                    "produceEvents",
                                    [...produceArray, "deliver"].join(", ")
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="deliver"
                              style={{ marginLeft: "5px" }}
                            >
                              Deliver
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">
                    Integration Information
                  </div>
                  <div className="row col-md-12">
                    <div className="col-md-12">
                      <div className="row alignItemCenter mb_12">
                        <div className="Groupinput">
                          <input
                            type={ajexKeyHandler ? "password" : "input"}
                            placeholder="AJEX Secret Key"
                            style={{ width: "100%", marginLeft: "5px" }}
                            value={addThirdParty.values.ajexSecretKey}
                            onBlur={addThirdParty.handleBlur}
                            name="integration.ajexSecretKey"
                            onChange={(e) =>
                              addThirdParty.setFieldValue(
                                "ajexSecretKey",
                                e.target.value
                              )
                            }
                            className="mainInput"
                            disabled={
                              !(
                                title == "Edit third party configuration" ||
                                title === "Create third party configuration"
                              ) ?? true
                            }
                          />
                          {/* {addThirdParty.touched?.ajexSecretKey &&
                          addThirdParty.errors?.ajexSecretKey ? (
                            <p className="requiredText">
                              {addThirdParty.errors?.ajexSecretKey}
                            </p>
                          ) : null} */}
                          <div className="inputgroup_append">
                            <span
                              className="Groupinput_text"
                              onClick={() => setAjexkeyHandler(!ajexKeyHandler)}
                            >
                              <img src={ajexKeyHandler ? openEye : closeEye} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row alignItemCenter mb_12">
                        <div className="Groupinput">
                          <input
                            type={thirdpartyHandler ? "password" : "input"}
                            placeholder="Third Party Secret Key"
                            style={{ width: "100%", marginLeft: "5px" }}
                            className="mainInput"
                            onBlur={addThirdParty.handleBlur}
                            value={addThirdParty.values.thirdPartySecretKey}
                            name="thirdPartySecretKey"
                            onChange={(e) =>
                              addThirdParty.setFieldValue(
                                "thirdPartySecretKey",
                                e.target.value
                              )
                            }
                            disabled={
                              !(
                                title == "Edit third party configuration" ||
                                title == "Create third party configuration"
                              ) ?? true
                            }
                          />
                          {/* {addThirdParty.touched?.thirdPartySecretKey &&
                          addThirdParty.errors?.thirdPartySecretKey ? (
                            <p className="requiredText">
                              {addThirdParty.errors?.thirdPartySecretKey}
                            </p>
                          ) : null} */}
                          <div className="inputgroup_append">
                            <span
                              className="Groupinput_text"
                              onClick={() =>
                                setThirdpartyHandler(!thirdpartyHandler)
                              }
                            >
                              <img
                                src={thirdpartyHandler ? openEye : closeEye}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Allocation Rules</div>
                  <div className="row col-md-12  margin-top-5">
                    <div className="col-md-4" style={{ marginRight: "14px" }}>
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 marginL8">
                            Product:
                          </label>
                          <div className="col-md-12">
                            <input
                              type="text"
                              style={{ width: "220px", marginLeft: "5px" }}
                              className="mainInput"
                              value={addThirdParty.values.products}
                              name="products"
                              onChange={(e) => {
                                const productValue = e.target.value;
                                addThirdParty.setFieldValue(
                                  "products",
                                  productValue
                                );
                              }}
                              disabled={
                                !(
                                  title == "Edit third party configuration" ||
                                  title === "Create third party configuration"
                                ) ?? true
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 marginL-12">
                            Source Country:
                          </label>
                          <input
                            type="text"
                            style={{ width: "98%", marginLeft: "-15px" }}
                            className="mainInput"
                            onBlur={addThirdParty.handleBlur}
                            value={addThirdParty.values.sourceCountries}
                            name="sourceCountries"
                            onChange={(e) => {
                              const sourceCountryValue = e.target.value;
                              addThirdParty.setFieldValue(
                                "sourceCountries",
                                sourceCountryValue
                              );
                            }}
                            disabled={
                              !(
                                title == "Edit third party configuration" ||
                                title === "Create third party configuration"
                              ) ?? true
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4" style={{ marginLeft: "-20px" }}>
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 marginL12">
                            Destination Country:
                          </label>
                          <input
                            type="text"
                            style={{ width: "98%", marginLeft: "10px" }}
                            className="mainInput"
                            value={addThirdParty.values.destinationCountries}
                            name="destinationCountries"
                            onChange={(e) => {
                              const destCountryValue = e.target.value;
                              addThirdParty.setFieldValue(
                                "destinationCountries",
                                destCountryValue
                              );
                            }}
                            disabled={
                              !(
                                title == "Edit third party configuration" ||
                                title === "Create third party configuration"
                              ) ?? true
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row alignItemCenter mb_12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 marginL5">
                        Include customer accounts:
                      </label>
                      <input
                        type="text"
                        style={{ width: "93.5%", marginLeft: "15px" }}
                        className="mainInput"
                        value={addThirdParty.values.customerAccounts}
                        name="customerAccounts"
                        onChange={(e) => {
                          const customerAccArray = e.target.value;
                          addThirdParty.setFieldValue(
                            "customerAccounts",
                            customerAccArray
                          );
                        }}
                        disabled={
                          !(
                            title == "Edit third party configuration" ||
                            title === "Create third party configuration"
                          ) ?? true
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row alignItemCenter mb_12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 marginL5">
                        Exclude customer accounts:
                      </label>
                      <input
                        type="text"
                        style={{ width: "93.5%", marginLeft: "15px" }}
                        className="mainInput"
                        value={addThirdParty.values.excludeCustomerAccounts}
                        name="excludeCustomerAccounts"
                        onChange={(e) => {
                          const customerAccArray = e.target.value;
                          addThirdParty.setFieldValue(
                            "excludeCustomerAccounts",
                            customerAccArray
                          );
                        }}
                        disabled={
                          !(
                            title == "Edit third party configuration" ||
                            title === "Create third party configuration"
                          ) ?? true
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Add New Mapping" || title === "Edit Mapping" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Carrier
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addMapping.values.carrier}
                      name="carrier"
                      placeholder="SF International"
                      className="mainInput"
                      onChange={(e) =>
                        addMapping.setFieldValue("carrier", e.target.value)
                      }
                      disabled={
                        !(saveHandler || title === "Add New Mapping") ?? true
                      }
                    />
                    {addMapping.touched.carrier && addMapping.errors.carrier ? (
                      <p className="requiredText">
                        {addMapping.errors.carrier}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Type
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <select
                      name="type"
                      value={addMapping.values.type}
                      onChange={(e) =>
                        addMapping.setFieldValue("type", e.target.value)
                      }
                      className="mainInput"
                      disabled={!(saveHandler || title === "Add New Mapping")}
                    >
                      <option value="">Select Mapping Type</option>
                      {mappingData?.map((item, index) => (
                        <option key={index} value={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </select>
                    {addMapping.touched.type && addMapping.errors.type ? (
                      <p className="requiredText">{addMapping.errors.type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Carrier Alias
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <textarea
                      className="washMe_btn"
                      placeholder="Carrier Alias"
                      value={addMapping.values.value}
                      onChange={(e) =>
                        addMapping.setFieldValue("value", e.target.value)
                      }
                      name="value"
                      style={{ height: "90px", paddingTop: "6px" }}
                      disabled={
                        !(saveHandler || title === "Add New Mapping") ?? true
                      }
                    ></textarea>
                    {addMapping.touched.value && addMapping.errors.value ? (
                      <p className="requiredText">{addMapping.errors.value}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      AJEX Value
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <textarea
                      className="washMe_btn"
                      value={addMapping.values.alias}
                      name="alias"
                      placeholder="AJEX Value"
                      style={{ height: "90px", paddingTop: "6px" }}
                      disabled={
                        !(saveHandler || title === "Add New Mapping") ?? true
                      }
                      onChange={(e) =>
                        addMapping.setFieldValue("alias", e.target.value)
                      }
                    ></textarea>
                    {addMapping.touched.alias && addMapping.errors.alias ? (
                      <p className="requiredText">{addMapping.errors.alias}</p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "Create new freight rate" ||
              title === "Edit freight rate" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Product and Customers</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Product <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="productCode"
                            label="productCode"
                            name="productCode"
                            options={productsData?.data?.data?.content || []}
                            getOptionLabel={(option) =>
                              `${option.code} - ${option.name}`
                            }
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            value={
                              addFreight?.values?.productCode
                                ? productsData?.data?.data?.content?.find(
                                    (prod) =>
                                      prod.code ===
                                      addFreight?.values?.productCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              setfreighterror("");
                              setSelectedOptionProduct(selectedOption);
                              const resp = await getProductsbyId(
                                selectedOption?.code
                              );
                              setProdMaxWeight(resp?.data?.data?.maxWeight);
                              setProdWeightUnit(resp?.data?.data?.weightUnit);
                              addFreight.setFieldValue(
                                "productCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              addFreight.setFieldValue("contentType",resp?.data?.data?.contentType)
                              getOriginCountry(selectedOption?.code);
                            }}
                            isSearchable={true}
                            placeholder="Product"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFreight.touched.productCode &&
                          addFreight.errors.productCode ? (
                            <p className="requiredText">
                              {addFreight.errors.productCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Customer
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          {showFreightCustomer ? (
                            <AsyncSearchInput
                              id="customerAccount"
                              label="customerAccount"
                              name="customerAccount"
                              value={selectedOption}
                              loadMoreOptions={loadMoreOptionsRatesCustomer}
                              shouldLoadMore={shouldLoadMore}
                              getOptionLabel={(customer) => customer.label}
                              getOptionValue={(customer) => customer.value}
                              customStyles={{ control: { width: "200px" } }}
                              onChange={(selectedOption) => {
                                setSelectedOption(selectedOption);
                                addFreight.setFieldValue(
                                  "customerAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Customer"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              menuStyle={{ zIndex: 9999 }}
                            />
                          ) : (
                            <input
                              type="text"
                              value={data?.customerName}
                              name="customerAccount"
                              className="mainInput"
                              style={{ width: "200px" }}
                              onClick={handleInputCustomerClick}
                            />
                          )}
                          {addFreight.touched.customerAccount &&
                          addFreight.errors.customerAccount ? (
                            <p className="requiredText">
                              {addFreight.errors.customerAccount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "145px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Structure: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="structure"
                            label="structure"
                            name="structure"
                            options={structureData?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "structure",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              addFreight?.values?.structure
                                ? structureData?.data?.data?.find(
                                    (structure) =>
                                      structure.key ===
                                      addFreight?.values?.structure
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Structure"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFreight.touched.structure &&
                          addFreight.errors.structure ? (
                            <p className="requiredText">
                              {addFreight.errors.structure}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Origin-Destination</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="originCountryCode"
                            label="originCountryCode"
                            name="originCountryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "originCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                              if (selectedOption) {
                                try {
                                  const response = await getCountryByCity(
                                    selectedOption.countryCode
                                  );
                                  let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&countryCode=${selectedOption.countryCode}`;
                                  const tiersRes = await getTiers(params);
                                  setTiersArray(tiersRes?.data?.data?.content);
                                } catch (error) {}
                              }
                            }}
                            value={
                              addFreight?.values?.originCountryCode
                                ? originCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addFreight?.values?.originCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFreight.touched.originCountryCode &&
                          addFreight.errors.originCountryCode ? (
                            <p className="requiredText">
                              {addFreight.errors.originCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin City
                          </label>
                          <SearchInput
                            id="originCityCode"
                            label="originCityCode"
                            name="originCityCode"
                            options={citybyCountyData?.data?.data || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "originCityCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              setCityError("");
                            }}
                            value={
                              addFreight?.values?.originCityCode
                                ? citybyCountyData?.data?.data?.find(
                                    (city) =>
                                      city.code ===
                                      addFreight?.values?.originCityCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin City"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {cityError ? (
                            <p className="requiredText">{cityError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {addFreight.values.originCountryCode ===
                      addFreight.values.destinationCountryCode && (
                      <div className="col-md-3">
                        <div className="row alignItemCenter mb_12">
                          <div
                            className="col-md-12"
                            style={{ marginLeft: "145px" }}
                          >
                            <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Tier</label>
                            <SearchInput
                              id="tierCode"
                              label="tierCode"
                              name="tierCode"
                              options={tiersArray || []}
                              value={
                                addFreight?.values?.tierCode
                                  ? tiersArray?.find(
                                      (city) =>
                                        city.code ===
                                        addFreight?.values?.tierCode
                                    )
                                  : null
                              }
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.code}
                              isClearable={true}
                              handleChange={async (selectedOption) => {
                                addFreight.setFieldValue(
                                  "tierCode",
                                  selectedOption ? selectedOption.code : ""
                                );
                                setCityError("");
                                setCityDeserror("");
                              }}
                              isSearchable={true}
                              placeholder="Tier"
                              customStyles={{ control: { width: "200px" } }}
                              className="mainInput"
                              style={{ whiteSpace: "nowrap" }}
                            />
                            {addFreight.touched.tierCode &&
                            addFreight.errors.tierCode ? (
                              <p className="requiredText">
                                {addFreight.errors.tierCode}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row mt_10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="destinationCountryCode"
                            label="destinationCountryCode"
                            name="destinationCountryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "destinationCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                              // Check if a country is selected before making the API call
                              if (selectedOption) {
                                try {
                                  const response = await getDesCountryByCity(
                                    selectedOption.countryCode
                                  );
                                } catch (error) {}
                              }
                            }}
                            value={
                              addFreight?.values?.destinationCountryCode
                                ? destinationCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addFreight?.values?.destinationCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFreight.touched.destinationCountryCode &&
                          addFreight.errors.destinationCountryCode ? (
                            <p className="requiredText">
                              {addFreight.errors.destinationCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination City
                          </label>
                          <SearchInput
                            id="destinationCityCode"
                            label="destinationCityCode"
                            name="destinationCityCode"
                            options={citybyDesCountyData?.data?.data || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "destinationCityCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              setCityDeserror("");
                            }}
                            value={
                              addFreight?.values?.destinationCityCode
                                ? citybyDesCountyData?.data?.data?.find(
                                    (city) =>
                                      city.code ===
                                      addFreight?.values?.destinationCityCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination City"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {citydesError ? (
                            <p className="requiredText">{citydesError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Consignment Info</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Payment Type:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="paymentType"
                            label="paymentType"
                            name="paymentType"
                            options={paymentData?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "paymentType",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              addFreight?.values?.paymentType
                                ? paymentData?.data?.data?.find(
                                    (payment) =>
                                      payment.key ===
                                      addFreight?.values?.paymentType
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Payment Type"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFreight.touched.paymentType &&
                          addFreight.errors.paymentType ? (
                            <p className="requiredText">
                              {addFreight.errors.paymentType}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Content Type:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="contentType"
                            label="contentType"
                            name="contentType"
                            options={contentData?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "contentType",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              addFreight?.values?.contentType
                                ? contentData?.data?.data?.find(
                                    (content) =>
                                      content.key ===
                                      addFreight?.values?.contentType
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Content Type"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFreight.touched.contentType &&
                          addFreight.errors.contentType ? (
                            <p className="requiredText">
                              {addFreight.errors.contentType}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "145px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Delivery Status:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="deliveryStatus"
                            label="deliveryStatus"
                            name="deliveryStatus"
                            options={deliveryStatusData?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFreight.setFieldValue(
                                "deliveryStatus",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              addFreight?.values?.deliveryStatus
                                ? deliveryStatusData?.data?.data?.find(
                                    (delivery) =>
                                      delivery.key ===
                                      addFreight?.values?.deliveryStatus
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Delivery Status"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFreight.touched.deliveryStatus &&
                          addFreight.errors.deliveryStatus ? (
                            <p className="requiredText">
                              {addFreight.errors.deliveryStatus}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Prices</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Weight Unit
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="weightUnits"
                            name="weightUnits"
                            isClearable={true}
                            options={productWeightData?.data?.data || []}
                            getOptionLabel={(region) => region.label}
                            getOptionValue={(region) => region.key}
                            handleChange={(selectedOption) => {
                              addFreight.setFieldValue(
                                "weightUnits",
                                selectedOption ? selectedOption.key : ""
                              );
                              setfreighterror("");
                            }}
                            value={
                              addFreight?.values?.weightUnits
                                ? productWeightData?.data?.data?.find(
                                    (weight) =>
                                      weight.key ===
                                      addFreight?.values?.weightUnits
                                  )
                                : null
                            }
                            className="mainInput"
                            customStyles={{ control: { width: "145px" } }}
                            isSearchable={true}
                            placeholder="Weight Unit"
                          />
                          {addFreight.touched.weightUnits &&
                          addFreight.errors.weightUnits ? (
                            <p className="requiredText">
                              {addFreight.errors.weightUnits}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            From Weight
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            name="fromWeight"
                            value={addFreight.values.fromWeight}
                            onChange={(e) => {
                              addFreight.setFieldValue(
                                "fromWeight",
                                e.target.value
                              );
                              setFromfreightError("");
                            }}
                            placeholder="From Weight"
                            className="mainInput"
                            onBlur={addFreight.handleBlur}
                          />
                          {addFreight.touched.fromWeight &&
                          addFreight.errors.fromWeight &&
                          fromfreightError === "" ? (
                            <p className="requiredText">
                              {addFreight.errors.fromWeight}
                            </p>
                          ) : null}
                          {fromfreightError !== "" ? (
                            <p className="requiredText">{fromfreightError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">To Weight</label>
                          <input
                            type="text"
                            name="toWeight"
                            value={addFreight.values.toWeight}
                            placeholder="To Weight"
                            className="mainInput"
                            onChange={(e) => {
                              addFreight.setFieldValue(
                                "toWeight",
                                e.target.value
                              );
                              setfreighterror("");
                            }}
                            onBlur={addFreight.handleBlur}
                          />
                          {addFreight.touched.toWeight &&
                          addFreight.errors.toWeight &&
                          tofreightError === "" ? (
                            <p className="requiredText">
                              {addFreight.errors.toWeight}
                            </p>
                          ) : null}
                          {tofreightError !== "" ? (
                            <p className="requiredText">{tofreightError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Effective Date
                          </label>
                          <div
                            className="customDateRange"
                            style={{ color: "black", position: "relative" }}
                          >
                            <DatePicker
                              placeholderText="Effective Date"
                              placement="rightStart"
                              selected={
                                addFreight.values.effectiveDate
                                  ? new Date(addFreight.values.effectiveDate)
                                  : null
                              }
                              onChange={(date) => {
                                addFreight.setFieldValue("effectiveDate", date);
                              }}
                              onClean={() =>
                                addFreight.setFieldValue("effectiveDate", null)
                              }
                              className="mainInput"
                              showYearDropdown
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt_10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Flat Price</label>
                          <input
                            type="text"
                            name="flatPrice"
                            value={addFreight.values.flatPrice}
                            onChange={(e) => {
                              addFreight.setFieldValue(
                                "flatPrice",
                                e.target.value
                              );
                              setFlatfreightError("");
                            }}
                            maxLength={7}
                            placeholder="Flat Price"
                            className="mainInput"
                            onBlur={addFreight.handleBlur}
                            disabled={
                              addFreight?.values?.unit ||
                              addFreight?.values?.unitPrice
                            }
                          />
                          {addFreight.touched.flatPrice &&
                          addFreight.errors.flatPrice &&
                          flatfreightError === "" ? (
                            <p className="requiredText">
                              {addFreight.errors.flatPrice}
                            </p>
                          ) : null}
                          {flatfreightError !== "" ? (
                            <p className="requiredText">{flatfreightError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit</label>
                          <input
                            type="text"
                            name="unit"
                            value={addFreight.values.unit}
                            onChange={(e) => {
                              addFreight.setFieldValue("unit", e.target.value);
                              setUnitfreightError("");
                            }}
                            placeholder="Unit"
                            className="mainInput"
                            onBlur={addFreight.handleBlur}
                            disabled={addFreight?.values?.flatPrice}
                          />
                          {addFreight.touched.unit &&
                          addFreight.errors.unit &&
                          unitfreight === "" ? (
                            <p className="requiredText">
                              {addFreight.errors.unit}
                            </p>
                          ) : null}
                          {unitfreight !== "" ? (
                            <p className="requiredText">{unitfreight}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit Price</label>
                          <input
                            type="text"
                            name="unitPrice"
                            value={addFreight.values.unitPrice}
                            onChange={(e) => {
                              addFreight.setFieldValue(
                                "unitPrice",
                                e.target.value
                              );
                              setUnitPricefreightError("");
                            }}
                            placeholder="Unit Price"
                            className="mainInput"
                            onBlur={addFreight.handleBlur}
                            disabled={addFreight?.values?.flatPrice}
                          />
                          {addFreight.touched.unitPrice &&
                          addFreight.errors.unitPrice &&
                          unitPricefreightError === "" ? (
                            <p className="requiredText">
                              {addFreight.errors.unitPrice}
                            </p>
                          ) : null}
                          {unitPricefreightError !== "" ? (
                            <p className="requiredText">
                              {unitPricefreightError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create new RTO rate" || title === "Edit RTO rate" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Product and Customers</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Product <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="productCode"
                            label="productCode"
                            name="productCode"
                            options={productsData?.data?.data?.content || []}
                            getOptionLabel={(option) =>
                              `${option.code} - ${option.name}`
                            }
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            value={
                              addRto?.values?.productCode
                                ? productsData?.data?.data?.content?.find(
                                    (prod) =>
                                      prod.code === addRto?.values?.productCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              setTorateError("");
                              setSelectedOptionProduct(selectedOption);
                              const resp = await getProductsbyId(
                                selectedOption?.code
                              );
                              setProdMaxWeight(resp?.data?.data?.maxWeight);
                              setProdWeightUnit(resp?.data?.data?.weightUnit);
                              addRto.setFieldValue(
                                "productCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              addRto.setFieldValue("contentType",resp?.data?.data?.contentType)
                              getOriginCountry(selectedOption?.code);
                            }}
                            isSearchable={true}
                            placeholder="Product"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addRto.touched.productCode &&
                          addRto.errors.productCode ? (
                            <p className="requiredText">
                              {addRto.errors.productCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Customer
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          {showRtoCustomer ? (
                            <AsyncSearchInput
                              id="customerAccount"
                              label="customerAccount"
                              name="customerAccount"
                              value={selectedOption}
                              loadMoreOptions={loadMoreOptionsRatesCustomer}
                              shouldLoadMore={shouldLoadMore}
                              getOptionLabel={(customer) => customer.label}
                              getOptionValue={(customer) => customer.value}
                              customStyles={{ control: { width: "200px" } }}
                              onChange={(selectedOption) => {
                                setSelectedOption(selectedOption);
                                addRto.setFieldValue(
                                  "customerAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Customer"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              menuStyle={{ zIndex: 9999 }}
                            />
                          ) : (
                            <input
                              type="text"
                              value={data?.customerName}
                              name="customerAccount"
                              className="mainInput"
                              style={{ width: "200px" }}
                              onClick={handleInputrtoCustomerClick}
                            />
                          )}
                          {addRto.touched.customerAccount &&
                          addRto.errors.customerAccount ? (
                            <p className="requiredText">
                              {addRto.errors.customerAccount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "145px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Structure: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="structure"
                            label="structure"
                            name="structure"
                            options={structureData?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addRto.setFieldValue(
                                "structure",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              addRto?.values?.structure
                                ? structureData?.data?.data?.find(
                                    (structure) =>
                                      structure.key ===
                                      addRto?.values?.structure
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Structure"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addRto.touched.structure &&
                          addRto.errors.structure ? (
                            <p className="requiredText">
                              {addRto.errors.structure}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Origin-Destination</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="originCountryCode"
                            label="originCountryCode"
                            name="originCountryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addRto.setFieldValue(
                                "originCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                              // Check if a country is selected before making the API call
                              if (selectedOption) {
                                try {
                                  const response = await getCountryByCity(
                                    selectedOption.countryCode
                                  );
                                  let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&countryCode=${selectedOption.countryCode}`;
                                  const tiersRes = await getTiers(params);
                                  setTiersArray(tiersRes?.data?.data?.content);
                                } catch (error) {}
                              }
                            }}
                            value={
                              addRto?.values?.originCountryCode
                                ? originCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addRto?.values?.originCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addRto.touched.originCountryCode &&
                          addRto.errors.originCountryCode ? (
                            <p className="requiredText">
                              {addRto.errors.originCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin City
                          </label>
                          <SearchInput
                            id="originCityCode"
                            label="originCityCode"
                            name="originCityCode"
                            options={citybyCountyData?.data?.data || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addRto.setFieldValue(
                                "originCityCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              setCityError("");
                            }}
                            value={
                              addRto?.values?.originCityCode
                                ? citybyCountyData?.data?.data?.find(
                                    (city) =>
                                      city.code ===
                                      addRto?.values?.originCityCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin City"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {cityError ? (
                            <p className="requiredText">{cityError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {addRto.values.originCountryCode ===
                      addRto.values.destinationCountryCode && (
                      <div className="col-md-3">
                        <div className="row alignItemCenter mb_12">
                          <div
                            className="col-md-12"
                            style={{ marginLeft: "145px" }}
                          >
                            <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Tier</label>
                            <SearchInput
                              id="tierCode"
                              label="tierCode"
                              name="tierCode"
                              options={tiersArray || []}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.code}
                              isClearable={true}
                              handleChange={async (selectedOption) => {
                                addRto.setFieldValue(
                                  "tierCode",
                                  selectedOption ? selectedOption.code : ""
                                );
                                setCityError("");
                                setCityDeserror("");
                              }}
                              value={
                                addRto?.values?.tierCode
                                  ? tiersArray?.find(
                                      (city) =>
                                        city.code === addRto?.values?.tierCode
                                    )
                                  : null
                              }
                              isSearchable={true}
                              placeholder="Tier"
                              customStyles={{ control: { width: "200px" } }}
                              className="mainInput"
                              style={{ whiteSpace: "nowrap" }}
                            />
                            {addRto.touched.tierCode &&
                            addRto.errors.tierCode ? (
                              <p className="requiredText">
                                {addRto.errors.tierCode}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row mt_10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="destinationCountryCode"
                            label="destinationCountryCode"
                            name="destinationCountryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addRto.setFieldValue(
                                "destinationCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                              // Check if a country is selected before making the API call
                              if (selectedOption) {
                                try {
                                  const response = await getDesCountryByCity(
                                    selectedOption.countryCode
                                  );
                                } catch (error) {}
                              }
                            }}
                            value={
                              addRto?.values?.destinationCountryCode
                                ? destinationCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addRto?.values?.destinationCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addRto.touched.destinationCountryCode &&
                          addRto.errors.destinationCountryCode ? (
                            <p className="requiredText">
                              {addRto.errors.destinationCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination City
                          </label>
                          <SearchInput
                            id="destinationCityCode"
                            label="destinationCityCode"
                            name="destinationCityCode"
                            options={citybyDesCountyData?.data?.data || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addRto.setFieldValue(
                                "destinationCityCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              setCityDeserror("");
                            }}
                            value={
                              addRto?.values?.destinationCityCode
                                ? citybyDesCountyData?.data?.data?.find(
                                    (city) =>
                                      city.code ===
                                      addRto?.values?.destinationCityCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination City"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {citydesError ? (
                            <p className="requiredText">{citydesError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Consignment Info</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Content Type:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="contentType"
                            label="contentType"
                            name="contentType"
                            options={contentData?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addRto.setFieldValue(
                                "contentType",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              addRto?.values?.contentType
                                ? contentData?.data?.data?.find(
                                    (content) =>
                                      content.key ===
                                      addRto?.values?.contentType
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Content Type"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addRto.touched.contentType &&
                          addRto.errors.contentType ? (
                            <p className="requiredText">
                              {addRto.errors.contentType}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Prices</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Weight Unit
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="weightUnits"
                            name="weightUnits"
                            isClearable={true}
                            options={productWeightData?.data?.data || []}
                            getOptionLabel={(region) => region.label}
                            getOptionValue={(region) => region.key}
                            handleChange={(selectedOption) => {
                              addRto.setFieldValue(
                                "weightUnits",
                                selectedOption ? selectedOption.key : ""
                              );
                              setTorateError("");
                            }}
                            className="mainInput"
                            customStyles={{ control: { width: "145px" } }}
                            value={
                              productWeightData?.data?.data?.find(
                                (account) =>
                                  account.key === addRto.values.weightUnits
                              ) || null
                            }
                            isSearchable={true}
                            placeholder="Weight Unit"
                          />
                          {addRto.touched.weightUnits &&
                          addRto.errors.weightUnits ? (
                            <p className="requiredText">
                              {addRto.errors.weightUnits}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            From Weight
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            name="fromWeight"
                            value={addRto.values.fromWeight}
                            onChange={(e) => {
                              addRto.setFieldValue(
                                "fromWeight",
                                e.target.value
                              );
                              setFromrateError("");
                            }}
                            placeholder="From Weight"
                            className="mainInput"
                            onBlur={addRto.handleBlur}
                          />
                          {addRto.touched.fromWeight &&
                          addRto.errors.fromWeight &&
                          fromRateError === "" ? (
                            <p className="requiredText">
                              {addRto.errors.fromWeight}
                            </p>
                          ) : null}
                          {fromRateError !== "" ? (
                            <p className="requiredText">{fromRateError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">To Weight</label>
                          <input
                            type="text"
                            name="toWeight"
                            value={addRto.values.toWeight}
                            placeholder="To Weight"
                            className="mainInput"
                            onChange={(e) => {
                              addRto.setFieldValue("toWeight", e.target.value);
                              setTorateError("");
                            }}
                            onBlur={addRto.handleBlur}
                          />
                          {addRto.touched.toWeight &&
                          addRto.errors.toWeight &&
                          toRateError === "" ? (
                            <p className="requiredText">
                              {addRto.errors.toWeight}
                            </p>
                          ) : null}
                          {toRateError !== "" ? (
                            <p className="requiredText">{toRateError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Effective Date
                          </label>
                          <div
                            className="customDateRange"
                            style={{ color: "black", position: "relative" }}
                          >
                            <DatePicker
                              placeholderText="Effective Date"
                              placement="rightStart"
                              selected={
                                addRto.values.effectiveDate
                                  ? new Date(addRto.values.effectiveDate)
                                  : null
                              }
                              onChange={(date) => {
                                addRto.setFieldValue("effectiveDate", date);
                              }}
                              onClean={() =>
                                addRto.setFieldValue("effectiveDate", null)
                              }
                              className="mainInput"
                              showYearDropdown
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt_10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Flat Price</label>
                          <input
                            type="text"
                            name="flatPrice"
                            value={addRto.values.flatPrice}
                            onChange={(e) => {
                              addRto.setFieldValue("flatPrice", e.target.value);
                              setFlatrtoError("");
                            }}
                            maxLength={7}
                            placeholder="Flat Price"
                            className="mainInput"
                            onBlur={addRto.handleBlur}
                            disabled={
                              addRto?.values?.unit || addRto?.values?.unitPrice
                            }
                          />
                          {addRto.touched.flatPrice &&
                          addRto.errors.flatPrice &&
                          flatRtoError === "" ? (
                            <p className="requiredText">
                              {addRto.errors.flatPrice}
                            </p>
                          ) : null}
                          {flatRtoError !== "" ? (
                            <p className="requiredText">{flatRtoError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit</label>
                          <input
                            type="text"
                            name="unit"
                            value={addRto.values.unit}
                            onChange={(e) => {
                              addRto.setFieldValue("unit", e.target.value);
                              setUnitRtoError("");
                            }}
                            placeholder="Unit"
                            className="mainInput"
                            onBlur={addRto.handleBlur}
                            disabled={addRto?.values?.flatPrice}
                          />
                          {addRto.touched.unit &&
                          addRto.errors.unit &&
                          unit === "" ? (
                            <p className="requiredText">{addRto.errors.unit}</p>
                          ) : null}
                          {unit !== "" ? (
                            <p className="requiredText">{unit}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit Price</label>
                          <input
                            type="text"
                            name="unitPrice"
                            value={addRto.values.unitPrice}
                            onChange={(e) => {
                              addRto.setFieldValue("unitPrice", e.target.value);
                              setUnitPriceRtoError("");
                            }}
                            placeholder="Unit Price"
                            className="mainInput"
                            onBlur={addRto.handleBlur}
                            disabled={addRto?.values?.flatPrice}
                          />
                          {addRto.touched.unitPrice &&
                          addRto.errors.unitPrice &&
                          unitPriceRtoError === "" ? (
                            <p className="requiredText">
                              {addRto.errors.unitPrice}
                            </p>
                          ) : null}
                          {unitPriceRtoError !== "" ? (
                            <p className="requiredText">{unitPriceRtoError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create new ODA Surcharge rate" ||
              title === "Edit ODA Surcharge rate" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Product and Customers</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Product <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="productCode"
                            label="productCode"
                            name="productCode"
                            options={productsData?.data?.data?.content || []}
                            getOptionLabel={(option) =>
                              `${option.code} - ${option.name}`
                            }
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            value={
                              addOda?.values?.productCode
                                ? productsData?.data?.data?.content?.find(
                                    (prod) =>
                                      prod.code === addOda?.values?.productCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              setSelectedOptionProduct(selectedOption);
                              const resp = await getProductsbyId(
                                selectedOption?.code
                              );
                              setProdMaxWeight(resp?.data?.data?.maxWeight);
                              setProdWeightUnit(resp?.data?.data?.weightUnit);
                              addOda.setFieldValue(
                                "productCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              getOriginCountry(selectedOption?.code);
                            }}
                            isSearchable={true}
                            placeholder="Product"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addOda.touched.productCode &&
                          addOda.errors.productCode ? (
                            <p className="requiredText">
                              {addOda.errors.productCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Customer
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          {showodaCustomer ? (
                            <AsyncSearchInput
                              id="customerAccount"
                              label="customerAccount"
                              name="customerAccount"
                              value={selectedOption}
                              loadMoreOptions={loadMoreOptionsRatesCustomer}
                              shouldLoadMore={shouldLoadMore}
                              getOptionLabel={(customer) => customer.label}
                              getOptionValue={(customer) => customer.value}
                              customStyles={{ control: { width: "200px" } }}
                              onChange={(selectedOption) => {
                                setSelectedOption(selectedOption);
                                addOda.setFieldValue(
                                  "customerAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Customer"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              menuStyle={{ zIndex: 9999 }}
                            />
                          ) : (
                            <input
                              type="text"
                              value={data?.customerName}
                              name="productCode"
                              style={{ width: "200px" }}
                              className="mainInput"
                              onClick={handleInputodaCustomerClick}
                            />
                          )}
                          {addOda.touched.customerAccount &&
                          addOda.errors.customerAccount ? (
                            <p className="requiredText">
                              {addOda.errors.customerAccount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Origin-Destination</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="originCountryCode"
                            label="originCountryCode"
                            name="originCountryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            value={
                              addOda?.values?.originCountryCode
                                ? originCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addOda?.values?.originCountryCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              addOda.setFieldValue(
                                "originCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            isSearchable={true}
                            placeholder="Origin Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addOda.touched.originCountryCode &&
                          addOda.errors.originCountryCode ? (
                            <p className="requiredText">
                              {addOda.errors.originCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="destinationCountryCode"
                            label="destinationCountryCode"
                            name="destinationCountryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            value={
                              addOda?.values?.destinationCountryCode
                                ? destinationCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addOda?.values?.destinationCountryCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              addOda.setFieldValue(
                                "destinationCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            isSearchable={true}
                            placeholder="Destination Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addOda.touched.destinationCountryCode &&
                          addOda.errors.destinationCountryCode ? (
                            <p className="requiredText">
                              {addOda.errors.destinationCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Prices</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Weight Unit
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="weightUnits"
                            name="weightUnits"
                            isClearable={true}
                            options={productWeightData?.data?.data || []}
                            getOptionLabel={(region) => region.label}
                            getOptionValue={(region) => region.key}
                            handleChange={(selectedOption) => {
                              addOda.setFieldValue(
                                "weightUnits",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            className="mainInput"
                            customStyles={{ control: { width: "145px" } }}
                            value={
                              addOda?.values?.weightUnits
                                ? productWeightData?.data?.data?.find(
                                    (weight) =>
                                      weight.key === addOda?.values?.weightUnits
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Weight Unit"
                          />
                          {addOda.touched.weightUnits &&
                          addOda.errors.weightUnits ? (
                            <p className="requiredText">
                              {addOda.errors.weightUnits}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            From Weight
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            name="fromWeight"
                            value={addOda.values.fromWeight}
                            onChange={(e) => {
                              addOda.setFieldValue(
                                "fromWeight",
                                e.target.value
                              );
                              setFromodaError("");
                            }}
                            placeholder="From Weight"
                            className="mainInput"
                            onBlur={addOda.handleBlur}
                          />
                          {addOda.touched.fromWeight &&
                          addOda.errors.fromWeight &&
                          fromOdaError === "" ? (
                            <p className="requiredText">
                              {addOda.errors.fromWeight}
                            </p>
                          ) : null}
                          {fromOdaError !== "" ? (
                            <p className="requiredText">{fromOdaError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">To Weight</label>
                          <input
                            type="text"
                            name="toWeight"
                            value={addOda.values.toWeight}
                            placeholder="To Weight"
                            className="mainInput"
                            onChange={(e) => {
                              addOda.setFieldValue(
                                "toWeight",
                                e.target.value
                              );
                              setOdaerror("");
                            }}
                            onBlur={addOda.handleBlur}
                          />
                          {addOda.touched.toWeight &&
                          addOda.errors.toWeight &&
                          toOdaError === "" ? (
                            <p className="requiredText">
                              {addOda.errors.toWeight}
                            </p>
                          ) : null}
                          {toOdaError !== "" ? (
                            <p className="requiredText">{toOdaError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Effective Date
                          </label>
                          <div
                            className="customDateRange"
                            style={{ color: "black", position: "relative" }}
                          >
                            <DatePicker
                              placeholderText="Effective Date"
                              placement="rightStart"
                              selected={
                                addOda.values.effectiveDate
                                  ? new Date(addOda.values.effectiveDate)
                                  : null
                              }
                              onChange={(date) => {
                                addOda.setFieldValue("effectiveDate", date);
                              }}
                              onClean={() =>
                                addOda.setFieldValue("effectiveDate", null)
                              }
                              className="mainInput"
                              showYearDropdown
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt_10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Flat Price</label>
                          <input
                            type="text"
                            name="flatPrice"
                            value={addOda.values.flatPrice}
                            onChange={(e) => {
                              addOda.setFieldValue("flatPrice", e.target.value);
                              setFlatOdaError("");
                            }}
                            maxLength={7}
                            placeholder="Flat Price"
                            className="mainInput"
                            onBlur={addOda.handleBlur}
                            disabled={
                              addOda?.values?.unit || addOda?.values?.unitPrice
                            }
                          />
                          {addOda.touched.flatPrice &&
                          addOda.errors.flatPrice &&
                          flatOdaError === "" ? (
                            <p className="requiredText">
                              {addOda.errors.flatPrice}
                            </p>
                          ) : null}
                          {flatOdaError !== "" ? (
                            <p className="requiredText">{flatOdaError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit</label>
                          <input
                            type="text"
                            name="unit"
                            value={addOda.values.unit}
                            onChange={(e) => {
                              addOda.setFieldValue("unit", e.target.value);
                              setUnitOdaError("");
                            }}
                            placeholder="Unit"
                            className="mainInput"
                            onBlur={addOda.handleBlur}
                            disabled={addOda?.values?.flatPrice}
                          />
                          {addOda.touched.unit &&
                          addOda.errors.unit &&
                          unitOda === "" ? (
                            <p className="requiredText">{addOda.errors.unit}</p>
                          ) : null}
                          {unitOda !== "" ? (
                            <p className="requiredText">{unitOda}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit Price</label>
                          <input
                            type="text"
                            name="unitPrice"
                            value={addOda.values.unitPrice}
                            onChange={(e) => {
                              addOda.setFieldValue("unitPrice", e.target.value);
                              setUnitPriceOdaError("");
                            }}
                            placeholder="Unit Price"
                            className="mainInput"
                            onBlur={addOda.handleBlur}
                            disabled={addOda?.values?.flatPrice}
                          />
                          {addOda.touched.unitPrice &&
                          addOda.errors.unitPrice &&
                          unitPriceOdaError === "" ? (
                            <p className="requiredText">
                              {addOda.errors.unitPrice}
                            </p>
                          ) : null}
                          {unitPriceOdaError !== "" ? (
                            <p className="requiredText">{unitPriceOdaError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create new COD rate" || title === "Edit COD rate" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Product and Customers</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Product <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="productCode"
                            label="productCode"
                            name="productCode"
                            options={productsData?.data?.data?.content || []}
                            getOptionLabel={(option) =>
                              `${option.code} - ${option.name}`
                            }
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            value={
                              addCod?.values?.productCode
                                ? productsData?.data?.data?.content?.find(
                                    (prod) =>
                                      prod.code === addCod?.values?.productCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              setSelectedOptionProduct(selectedOption);
                              addCod.setFieldValue(
                                "productCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              getOriginCountry(selectedOption?.code);
                            }}
                            isSearchable={true}
                            placeholder="Product"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addCod.touched.productCode &&
                          addCod.errors.productCode ? (
                            <p className="requiredText">
                              {addCod.errors.productCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Customer
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          {showCodCustomer ? (
                            <AsyncSearchInput
                              id="customerAccount"
                              label="customerAccount"
                              name="customerAccount"
                              value={selectedOption}
                              loadMoreOptions={loadMoreOptionsRatesCustomer}
                              shouldLoadMore={shouldLoadMore}
                              getOptionLabel={(customer) => customer.label}
                              getOptionValue={(customer) => customer.value}
                              customStyles={{ control: { width: "200px" } }}
                              onChange={(selectedOption) => {
                                setSelectedOption(selectedOption);
                                addCod.setFieldValue(
                                  "customerAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Customer"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              menuStyle={{ zIndex: 9999 }}
                            />
                          ) : (
                            <input
                              type="text"
                              value={data?.customerName}
                              name="productCode"
                              style={{ width: "200px" }}
                              className="mainInput"
                              onClick={handleCustomerCodClick}
                            />
                          )}
                          {addCod.touched.customerAccount &&
                          addCod.errors.customerAccount ? (
                            <p className="requiredText">
                              {addCod.errors.customerAccount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Origin-Destination</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="originCountryCode"
                            label="originCountryCode"
                            name="originCountryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addCod.setFieldValue(
                                "originCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addCod?.values?.originCountryCode
                                ? originCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addCod?.values?.originCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addCod.touched.originCountryCode &&
                          addCod.errors.originCountryCode ? (
                            <p className="requiredText">
                              {addCod.errors.originCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="destinationCountryCode"
                            label="destinationCountryCode"
                            name="destinationCountryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addCod.setFieldValue(
                                "destinationCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addCod?.values?.destinationCountryCode
                                ? destinationCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addCod?.values?.destinationCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addCod.touched.destinationCountryCode &&
                          addCod.errors.destinationCountryCode ? (
                            <p className="requiredText">
                              {addCod.errors.destinationCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Consignment Info</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Collection Method:
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="collectionMethod"
                            label="collectionMethod"
                            name="collectionMethod"
                            options={collectionData?.data?.data || []}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addCod.setFieldValue(
                                "collectionMethod",
                                selectedOption ? selectedOption.key : ""
                              );
                            }}
                            value={
                              addCod?.values?.collectionMethod
                                ? collectionData?.data?.data?.find(
                                    (payment) =>
                                      payment.key ===
                                      addCod?.values?.collectionMethod
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Collection Method"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addCod.touched.collectionMethod &&
                          addCod.errors.collectionMethod ? (
                            <p className="requiredText">
                              {addCod.errors.collectionMethod}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Prices</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Percent</label>
                          <input
                            type="text"
                            name="percent"
                            value={addCod.values.percent}
                            onChange={(e) => {
                              addCod.setFieldValue("percent", e.target.value);
                              setPercentError("");
                            }}
                            placeholder="Percent"
                            className="mainInput"
                            onBlur={addCod.handleBlur}
                          />
                          {addCod.touched.percent &&
                          addCod.errors.percent &&
                          percentError === "" ? (
                            <p className="requiredText">
                              {addCod.errors.percent}
                            </p>
                          ) : null}
                          {percentError !== "" ? (
                            <p className="requiredText">{percentError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Minimum Charge<span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <input
                            type="text"
                            name="minimumCharge"
                            value={addCod.values.minimumCharge}
                            placeholder="Minimum Charge"
                            className="mainInput"
                            onChange={(e) => {
                              addCod.setFieldValue(
                                "minimumCharge",
                                e.target.value
                              );
                              setMinChargeError("");
                            }}
                            onBlur={addCod.handleBlur}
                          />
                          {addCod.touched.minimumCharge &&
                          addCod.errors.minimumCharge &&
                          minChargeError === "" ? (
                            <p className="requiredText">
                              {addCod.errors.minimumCharge}
                            </p>
                          ) : null}
                          {minChargeError !== "" ? (
                            <p className="requiredText">{minChargeError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Effective Date
                          </label>
                          <div
                            className="customDateRange"
                            style={{ color: "black", position: "relative" }}
                          >
                            <DatePicker
                              placeholderText="Effective Date"
                              placement="rightStart"
                              selected={
                                addCod.values.effectiveDate
                                  ? new Date(addCod.values.effectiveDate)
                                  : null
                              }
                              onChange={(date) => {
                                addCod.setFieldValue("effectiveDate", date);
                              }}
                              onClean={() =>
                                addCod.setFieldValue("effectiveDate", null)
                              }
                              className="mainInput"
                              showYearDropdown
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create new Fuel rate" ||
              title === "Edit Fuel rate" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Product and Customers</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Product <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="productCode"
                            label="productCode"
                            name="productCode"
                            options={productsData?.data?.data?.content || []}
                            getOptionLabel={(option) =>
                              `${option.code} - ${option.name}`
                            }
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            value={
                              addFuel?.values?.productCode
                                ? productsData?.data?.data?.content?.find(
                                    (prod) =>
                                      prod.code === addFuel?.values?.productCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              setSelectedOptionProduct(selectedOption);
                              addFuel.setFieldValue(
                                "productCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              getOriginCountry(selectedOption?.code);
                            }}
                            isSearchable={true}
                            placeholder="Product"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFuel.touched.productCode &&
                          addFuel.errors.productCode ? (
                            <p className="requiredText">
                              {addFuel.errors.productCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Customer
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          {showFuelCustomer ? (
                            <AsyncSearchInput
                              id="customerAccount"
                              label="customerAccount"
                              name="customerAccount"
                              value={selectedOption}
                              loadMoreOptions={loadMoreOptionsRatesCustomer}
                              shouldLoadMore={shouldLoadMore}
                              getOptionLabel={(customer) => customer.label}
                              getOptionValue={(customer) => customer.value}
                              customStyles={{ control: { width: "200px" } }}
                              onChange={(selectedOption) => {
                                setSelectedOption(selectedOption);
                                addFuel.setFieldValue(
                                  "customerAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Customer"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              menuStyle={{ zIndex: 9999 }}
                            />
                          ) : (
                            <input
                              type="text"
                              value={data?.customerName}
                              name="customerAccount"
                              style={{ width: "200px" }}
                              className="mainInput"
                              onClick={handleCustomerFuelClick}
                            />
                          )}
                          {addFuel.touched.customerAccount &&
                          addFuel.errors.customerAccount ? (
                            <p className="requiredText">
                              {addFuel.errors.customerAccount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Origin-Destination</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="originCountryCode"
                            label="originCountryCode"
                            name="originCountryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFuel.setFieldValue(
                                "originCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addFuel?.values?.originCountryCode
                                ? originCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addFuel?.values?.originCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFuel.touched.originCountryCode &&
                          addFuel.errors.originCountryCode ? (
                            <p className="requiredText">
                              {addFuel.errors.originCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="destinationCountryCode"
                            label="destinationCountryCode"
                            name="destinationCountryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addFuel.setFieldValue(
                                "destinationCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addFuel?.values?.destinationCountryCode
                                ? destinationCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addFuel?.values?.destinationCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addFuel.touched.destinationCountryCode &&
                          addFuel.errors.destinationCountryCode ? (
                            <p className="requiredText">
                              {addFuel.errors.destinationCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Prices</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Weight Unit
                          </label>
                          <SearchInput
                            id="weightUnits"
                            name="weightUnits"
                            isClearable={true}
                            options={productWeightData?.data?.data || []}
                            getOptionLabel={(region) => region.label}
                            getOptionValue={(region) => region.key}
                            handleChange={(selectedOption) => {
                              addFuel.setFieldValue(
                                "weightUnits",
                                selectedOption ? selectedOption.key : ""
                              );
                              setUnitFuelError("");
                              setFlatFuelPercentError("");
                              setUnitPriceFuelError("");
                            }}
                            value={
                              addFuel?.values?.weightUnits
                                ? productWeightData?.data?.data?.find(
                                    (weight) =>
                                      weight.key ===
                                      addFuel?.values?.weightUnits
                                  )
                                : null
                            }
                            isDisabled={addFuel?.values?.percent}
                            className="mainInput"
                            customStyles={{ control: { width: "145px" } }}
                            isSearchable={true}
                            placeholder="Weight Unit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Effective Date
                          </label>
                          <div
                            className="customDateRange"
                            style={{ color: "black", position: "relative" }}
                          >
                            <DatePicker
                              placeholderText="Effective Date"
                              placement="rightStart"
                              selected={
                                addFuel.values.effectiveDate
                                  ? new Date(addFuel.values.effectiveDate)
                                  : null
                              }
                              onChange={(date) => {
                                addFuel.setFieldValue("effectiveDate", date);
                              }}
                              onClean={() =>
                                addFuel.setFieldValue("effectiveDate", null)
                              }
                              className="mainInput"
                              showYearDropdown
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt_10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Percent</label>
                          <input
                            type="text"
                            name="percent"
                            value={addFuel.values.percent}
                            onChange={(e) => {
                              addFuel.setFieldValue("percent", e.target.value);
                              setFlatFuelPercentError("");
                            }}
                            placeholder="Percent"
                            disabled={
                              addFuel?.values?.weightUnits ||
                              addFuel?.values?.unit ||
                              addFuel?.values?.unitPrice
                            }
                            className="mainInput"
                            onBlur={addFuel.handleBlur}
                          />
                          {addFuel.touched.percent &&
                          addFuel.errors.percent &&
                          flatFuelpercentError === "" ? (
                            <p className="requiredText">
                              {addFuel.errors.percent}
                            </p>
                          ) : null}
                          {flatFuelpercentError !== "" ? (
                            <p className="requiredText">
                              {flatFuelpercentError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit</label>
                          <input
                            type="text"
                            name="unit"
                            value={addFuel.values.unit}
                            onChange={(e) => {
                              addFuel.setFieldValue("unit", e.target.value);
                              setUnitFuelError("");
                            }}
                            placeholder="Unit"
                            disabled={addFuel?.values?.percent}
                            className="mainInput"
                            onBlur={addFuel.handleBlur}
                          />
                          {addFuel.touched.unit &&
                          addFuel.errors.unit &&
                          unitFuel === "" ? (
                            <p className="requiredText">
                              {addFuel.errors.unit}
                            </p>
                          ) : null}
                          {unitFuel !== "" ? (
                            <p className="requiredText">{unitFuel}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-10">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Unit Price</label>
                          <input
                            type="text"
                            name="unitPrice"
                            value={addFuel.values.unitPrice}
                            onChange={(e) => {
                              addFuel.setFieldValue(
                                "unitPrice",
                                e.target.value
                              );
                              setUnitPriceFuelError("");
                            }}
                            placeholder="Unit Price"
                            disabled={addFuel?.values?.percent}
                            className="mainInput"
                            onBlur={addFuel.handleBlur}
                          />
                          {addFuel.touched.unitPrice &&
                          addFuel.errors.unitPrice &&
                          unitPriceFuelError === "" ? (
                            <p className="requiredText">
                              {addFuel.errors.unitPrice}
                            </p>
                          ) : null}
                          {unitPriceFuelError !== "" ? (
                            <p className="requiredText">{unitPriceFuelError}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create new Insurance rate" ||
              title === "Edit Insurance rate" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Product and Customers</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Product <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="productCode"
                            label="productCode"
                            name="productCode"
                            options={productsData?.data?.data?.content || []}
                            getOptionLabel={(option) =>
                              `${option.code} - ${option.name}`
                            }
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            value={
                              addInsurance?.values?.productCode
                                ? productsData?.data?.data?.content?.find(
                                    (prod) =>
                                      prod.code ===
                                      addInsurance?.values?.productCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              setSelectedOptionProduct(selectedOption);
                              addInsurance.setFieldValue(
                                "productCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              getOriginCountry(selectedOption?.code);
                            }}
                            isSearchable={true}
                            placeholder="Product"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addInsurance.touched.productCode &&
                          addInsurance.errors.productCode ? (
                            <p className="requiredText">
                              {addInsurance.errors.productCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Customer
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          {showInsuranceCustomer ? (
                            <AsyncSearchInput
                              id="customerAccount"
                              label="customerAccount"
                              name="customerAccount"
                              value={selectedOption}
                              loadMoreOptions={loadMoreOptionsRatesCustomer}
                              shouldLoadMore={shouldLoadMore}
                              getOptionLabel={(customer) => customer.label}
                              getOptionValue={(customer) => customer.value}
                              customStyles={{ control: { width: "200px" } }}
                              onChange={(selectedOption) => {
                                setSelectedOption(selectedOption);
                                addInsurance.setFieldValue(
                                  "customerAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Customer"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              menuStyle={{ zIndex: 9999 }}
                            />
                          ) : (
                            <input
                              type="text"
                              value={data?.customerName}
                              name="customerAccount"
                              className="mainInput"
                              style={{ width: "200px" }}
                              onClick={handleCustomerInsuranceClick}
                            />
                          )}
                          {addInsurance.touched.customerAccount &&
                          addInsurance.errors.customerAccount ? (
                            <p className="requiredText">
                              {addInsurance.errors.customerAccount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Origin-Destination</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="originCountryCode"
                            label="originCountryCode"
                            name="originCountryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addInsurance.setFieldValue(
                                "originCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addInsurance?.values?.originCountryCode
                                ? originCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addInsurance?.values?.originCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addInsurance.touched.originCountryCode &&
                          addInsurance.errors.originCountryCode ? (
                            <p className="requiredText">
                              {addInsurance.errors.originCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="destinationCountryCode"
                            label="destinationCountryCode"
                            name="destinationCountryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addInsurance.setFieldValue(
                                "destinationCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addInsurance?.values?.destinationCountryCode
                                ? destinationCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addInsurance?.values
                                        ?.destinationCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addInsurance.touched.destinationCountryCode &&
                          addInsurance.errors.destinationCountryCode ? (
                            <p className="requiredText">
                              {addInsurance.errors.destinationCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Prices</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Percent <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <input
                            type="text"
                            name="percent"
                            value={addInsurance.values.percent}
                            onChange={(e) => {
                              addInsurance.setFieldValue(
                                "percent",
                                e.target.value
                              );
                              setFlatInsurancePercentError("");
                            }}
                            placeholder="Percent"
                            className="mainInput"
                            onBlur={addInsurance.handleBlur}
                          />
                          {addInsurance.touched.percent &&
                          addInsurance.errors.percent &&
                          flatInsurancepercentError === "" ? (
                            <p className="requiredText">
                              {addInsurance.errors.percent}
                            </p>
                          ) : null}
                          {flatInsurancepercentError !== "" ? (
                            <p className="requiredText">
                              {flatInsurancepercentError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Min Declared value</label>
                          <input
                            type="text"
                            name="percent"
                            value={addInsurance.values.declaredValueMin}
                            onChange={(e) => {
                              addInsurance.setFieldValue(
                                "declaredValueMin",
                                e.target.value
                              );
                              setFlatInsuranceDecMinError("");
                            }}
                            placeholder="Min Declared value"
                            className="mainInput"
                            onBlur={addInsurance.handleBlur}
                          />
                          {addInsurance.touched.declaredValueMin &&
                          addInsurance.errors.declaredValueMin &&
                          flatInsuranceDecMinError === "" ? (
                            <p className="requiredText">
                              {addInsurance.errors.declaredValueMin}
                            </p>
                          ) : null}
                          {flatInsuranceDecMinError !== "" ? (
                            <p className="requiredText">
                              {flatInsuranceDecMinError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Max Declared value</label>
                          <input
                            type="text"
                            name="declaredValueMax"
                            value={addInsurance.values.declaredValueMax}
                            onChange={(e) => {
                              addInsurance.setFieldValue(
                                "declaredValueMax",
                                e.target.value
                              );
                              setFlatInsuranceDecMaxError("");
                            }}
                            placeholder="Max Declared value"
                            className="mainInput"
                            onBlur={addInsurance.handleBlur}
                          />
                          {addInsurance.touched.declaredValueMax &&
                          addInsurance.errors.declaredValueMax &&
                          flatInsuranceDecMaxError === "" ? (
                            <p className="requiredText">
                              {addInsurance.errors.declaredValueMax}
                            </p>
                          ) : null}
                          {flatInsuranceDecMaxError !== "" ? (
                            <p className="requiredText">
                              {flatInsuranceDecMaxError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Effective Date
                          </label>
                          <div
                            className="customDateRange"
                            style={{ color: "black", position: "relative" }}
                          >
                            <DatePicker
                              placeholderText="Effective Date"
                              placement="rightStart"
                              selected={
                                addInsurance.values.effectiveDate
                                  ? new Date(addInsurance.values.effectiveDate)
                                  : null
                              }
                              onChange={(date) => {
                                addInsurance.setFieldValue(
                                  "effectiveDate",
                                  date
                                );
                              }}
                              onClean={() =>
                                addInsurance.setFieldValue(
                                  "effectiveDate",
                                  null
                                )
                              }
                              className="mainInput"
                              showYearDropdown
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create new Attempts rate" ||
              title === "Edit Attempts rate" ? (
              <form>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Product and Customers</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Product <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <SearchInput
                            id="productCode"
                            label="productCode"
                            name="productCode"
                            options={productsData?.data?.data?.content || []}
                            getOptionLabel={(option) =>
                              `${option.code} - ${option.name}`
                            }
                            getOptionValue={(option) => option.code}
                            isClearable={true}
                            value={
                              addAttempts?.values?.productCode
                                ? productsData?.data?.data?.content?.find(
                                    (prod) =>
                                      prod.code ===
                                      addAttempts?.values?.productCode
                                  )
                                : null
                            }
                            handleChange={async (selectedOption) => {
                              setSelectedOptionProduct(selectedOption);
                              addAttempts.setFieldValue(
                                "productCode",
                                selectedOption ? selectedOption.code : ""
                              );
                              getOriginCountry(selectedOption?.code);
                            }}
                            isSearchable={true}
                            placeholder="Product"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addAttempts.touched.productCode &&
                          addAttempts.errors.productCode ? (
                            <p className="requiredText">
                              {addAttempts.errors.productCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                            Customer
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          {showAttemptsCustomer ? (
                            <AsyncSearchInput
                              id="customerAccount"
                              label="customerAccount"
                              name="customerAccount"
                              value={selectedOption}
                              loadMoreOptions={loadMoreOptionsRatesCustomer}
                              shouldLoadMore={shouldLoadMore}
                              getOptionLabel={(customer) => customer.label}
                              getOptionValue={(customer) => customer.value}
                              customStyles={{ control: { width: "200px" } }}
                              onChange={(selectedOption) => {
                                setSelectedOption(selectedOption);
                                addAttempts.setFieldValue(
                                  "customerAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Customer"
                              menuPlacement="auto"
                              menuPosition="fixed"
                              menuStyle={{ zIndex: 9999 }}
                            />
                          ) : (
                            <input
                              type="text"
                              value={data?.customerName}
                              name="customerAccount"
                              className="mainInput"
                              style={{ width: "200px" }}
                              onClick={handleCustomerAttemptsClick}
                            />
                          )}
                          {addAttempts.touched.customerAccount &&
                          addAttempts.errors.customerAccount ? (
                            <p className="requiredText">
                              {addAttempts.errors.customerAccount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Origin-Destination</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Origin Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="originCountryCode"
                            label="originCountryCode"
                            name="originCountryCode"
                            options={originCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addAttempts.setFieldValue(
                                "originCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addAttempts?.values?.originCountryCode
                                ? originCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addAttempts?.values?.originCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Origin Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addAttempts.touched.originCountryCode &&
                          addAttempts.errors.originCountryCode ? (
                            <p className="requiredText">
                              {addAttempts.errors.originCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Destination Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="destinationCountryCode"
                            label="destinationCountryCode"
                            name="destinationCountryCode"
                            options={destinationCountries || []}
                            getOptionLabel={(option) => option.countryName}
                            getOptionValue={(option) => option.countryCode}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addAttempts.setFieldValue(
                                "destinationCountryCode",
                                selectedOption ? selectedOption.countryCode : ""
                              );
                            }}
                            value={
                              addAttempts?.values?.destinationCountryCode
                                ? destinationCountries?.find(
                                    (country) =>
                                      country.countryCode ===
                                      addAttempts?.values
                                        ?.destinationCountryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Destination Country"
                            customStyles={{ control: { width: "200px" } }}
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                          />
                          {addAttempts.touched.destinationCountryCode &&
                          addAttempts.errors.destinationCountryCode ? (
                            <p className="requiredText">
                              {addAttempts.errors.destinationCountryCode}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Prices</div>
                  <div className="row margin-top-10">
                    <div className="col-md-3">
                      <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">Charges <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
                          <input
                            type="text"
                            name="charge"
                            value={addAttempts.values.charge}
                            onChange={(e) => {
                              addAttempts.setFieldValue(
                                "charge",
                                e.target.value
                              );
                              setFlatAttemptsPercentError("");
                            }}
                            placeholder="Charges"
                            className="mainInput"
                            onBlur={addAttempts.handleBlur}
                          />
                          {addAttempts.touched.charge &&
                          addAttempts.errors.charge &&
                          flatAttemptspercentError === "" ? (
                            <p className="requiredText">
                              {addAttempts.errors.charge}
                            </p>
                          ) : null}
                          {flatAttemptspercentError !== "" ? (
                            <p className="requiredText">
                              {flatAttemptspercentError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row alignItemCenter mb_12">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "75px" }}
                        >
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Effective Date
                          </label>
                          <div
                            className="customDateRange"
                            style={{ color: "black", position: "relative" }}
                          >
                            <DatePicker
                              placeholderText="Effective Date"
                              placement="rightStart"
                              selected={
                                addAttempts.values.effectiveDate
                                  ? new Date(addAttempts.values.effectiveDate)
                                  : null
                              }
                              onChange={(date) => {
                                addAttempts.setFieldValue(
                                  "effectiveDate",
                                  date
                                );
                              }}
                              onClean={() =>
                                addAttempts.setFieldValue("effectiveDate", null)
                              }
                              className="mainInput"
                              showYearDropdown
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Add Bag" ||
              title === "Edit Bag" ||
              title === "View Bag" ? (
              <form>
                <div className="row">
                  <div
                    className={title === "Edit Bag" ? "col-md-12" : "col-md-6"}
                  >
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Bag Number
                    </label>
                    <input
                      type="text"
                      value={
                        addBag.values.createBag ? "" : addBag.values.bagNumber
                      }
                      name="carrier"
                      placeholder="Bag Number"
                      className={`mainInput ${
                        addBag.values.createBag ? "disabled" : ""
                      }`}
                      disabled={addBag.values.createBag || title === "Edit Bag"}
                      onChange={(e) =>
                        addBag.setFieldValue("bagNumber", e.target.value)
                      }
                    />
                  </div>
                  {title !== "Edit Bag" && (
                    <div className="col-md-6" style={{ marginTop: "38px" }}>
                      <div className="displayFlex alignItemCenter">
                        <div className="smallCheckbox col-md-12">
                          <input
                            type="checkbox"
                            id="createBag"
                            style={{ marginLeft: "5px" }}
                            checked={addBag.values.createBag}
                            onChange={() => {
                              addBag.setFieldValue(
                                "createBag",
                                !addBag.values.createBag
                              );
                              if (!addBag.values.createBag) {
                                addBag.setFieldValue("bagNumber", ""); // Set bagNumber to an empty string
                              }
                            }}
                          />
                          <label htmlFor="createBag">
                            Create Bag Number Automatically
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Source Hub
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="hubCode"
                      label="hubCode"
                      name="hubCode"
                      isClearable={true}
                      options={hubsData?.data?.data?.content || []}
                      getOptionLabel={(hub) => hub.name}
                      getOptionValue={(hub) => hub.code}
                      handleChange={async (selectedOption) => {
                        addBag.setFieldValue(
                          "sourceHubCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        addBag.setFieldValue(
                          "sourceHubName",
                          selectedOption ? selectedOption.name : ""
                        );
                      }}
                      value={
                        addBag.values.sourceHubCode
                          ? hubsData?.data?.data?.content?.find(
                              (hub) => hub.code === addBag.values.sourceHubCode
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Source Hub"
                    />
                    {addBag.touched.sourceHubCode &&
                    addBag.errors.sourceHubCode ? (
                      <p className="requiredText">
                        {addBag.errors.sourceHubCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Destination Hub
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="hubCode"
                      label="hubCode"
                      name="hubCode"
                      isClearable={true}
                      options={hubsData?.data?.data?.content || []}
                      getOptionLabel={(hub) => hub.name}
                      getOptionValue={(hub) => hub.code}
                      handleChange={async (selectedOption) => {
                        addBag.setFieldValue(
                          "destinationHubCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        addBag.setFieldValue(
                          "destinationHubName",
                          selectedOption ? selectedOption.name : ""
                        );
                      }}
                      value={
                        addBag.values.destinationHubCode
                          ? hubsData?.data?.data?.content?.find(
                              (hub) =>
                                hub.code === addBag.values.destinationHubCode
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Destination Hub"
                    />
                    {addBag.touched.destinationHubCode &&
                    addBag.errors.destinationHubCode ? (
                      <p className="requiredText">
                        {addBag.errors.destinationHubCode}
                      </p>
                    ) : null}
                    {isdestinationHubError ? (
                      <p className="requiredText">{isdestinationHubError}</p>
                    ) : null}
                  </div>
                </div>
                {addBag.values.sourceHubCode ===
                  addBag.values.destinationHubCode &&
                  !addBag.values.zoneLevel && (
                    <div className="row">
                      <div className="col-md-6" style={{ marginTop: "65px" }}>
                        <div className="displayFlex alignItemCenter">
                          <div className="smallCheckbox col-md-12">
                            <input
                              type="checkbox"
                              id="zoneGroupLevel"
                              style={{ marginLeft: "5px" }}
                              checked={addBag.values.zoneGroupLevel}
                              onChange={handleCheckboxGroupChange}
                            />
                            <label htmlFor="zoneGroupLevel">
                              Zone group level
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6" style={{ marginTop: "38px" }}>
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Destination Zone group
                        </label>
                        <SearchInput
                          id="destinationZoneGroupCode"
                          label="destinationZoneGroupCode"
                          name="destinationZoneGroupCode"
                          isClearable={true}
                          options={(
                            zoneGroupbyHubData?.data?.data || []
                          )?.filter((option) => option !== null)}
                          getOptionLabel={(hub) => hub.name}
                          getOptionValue={(hub) => hub.code}
                          handleChange={(selectedOption) => {
                            addBag.setFieldValue(
                              "destinationZoneGroupCode",
                              selectedOption ? selectedOption.code : ""
                            );
                            setIsBagGroupError("");
                          }}
                          value={
                            addBag.values.destinationZoneGroupCode
                              ? zoneGroupbyHubData?.data?.data
                                  ?.filter((option) => option !== null)
                                  ?.find(
                                    (hub) =>
                                      hub.code ===
                                      addBag.values.destinationZoneGroupCode
                                  )
                              : []
                          }
                          isSearchable={true}
                          isDisabled={!addBag.values.zoneGroupLevel}
                          placeholder="Destination zone group"
                        />
                        {isBagGroupError ? (
                          <p className="requiredText">{isBagGroupError}</p>
                        ) : null}
                      </div>
                    </div>
                  )}
                {!addBag.values.zoneGroupLevel && (
                  <div className="row">
                    <div className="col-md-6" style={{ marginTop: "65px" }}>
                      <div className="displayFlex alignItemCenter">
                        <div className="smallCheckbox col-md-12">
                          <input
                            type="checkbox"
                            id="zoneLevel"
                            style={{ marginLeft: "5px" }}
                            checked={addBag.values.zoneLevel}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="zoneLevel">Zone Level</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6" style={{ marginTop: "38px" }}>
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Destination Zone
                      </label>
                      <SearchInput
                        id="destinationZoneCode"
                        label="destinationZoneCode"
                        name="destinationZoneCode"
                        isClearable={true}
                        options={zoneServiceData?.data?.data || []}
                        getOptionLabel={(hub) => hub.zoneCode}
                        getOptionValue={(hub) => hub.zoneCode}
                        handleChange={(selectedOption) => {
                          addBag.setFieldValue(
                            "destinationZoneCode",
                            selectedOption ? selectedOption.zoneCode : ""
                          );
                          setIsBagError("");
                        }}
                        value={
                          addBag.values.destinationZoneCode
                            ? zoneServiceData?.data?.data?.find(
                                (hub) =>
                                  hub.zoneCode ===
                                  addBag.values.destinationZoneCode
                              )
                            : []
                        }
                        isSearchable={true}
                        isDisabled={!addBag.values.zoneLevel}
                        placeholder="Destination Zone"
                      />
                      {isBagError ? (
                        <p className="requiredText">{isBagError}</p>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Trip Id
                    </label>
                    <input
                      type="text"
                      value={addBag.values.tripId}
                      name="tripId"
                      placeholder="Trip Id"
                      className="mainInput"
                      onChange={(e) =>
                        addBag.setFieldValue("tripId", e.target.value)
                      }
                    />
                  </div>
                </div>
              </form>
            ) : title === "Edit Waybill" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Weight
                    </label>
                    <input
                      type="text"
                      value={editWaybill.values.weight}
                      name="weight"
                      placeholder="Weight"
                      className="mainInput"
                      onChange={(e) =>
                        editWaybill.setFieldValue("weight", e.target.value)
                      }
                    />
                    {editWaybill.touched.weight && editWaybill.errors.weight ? (
                      <p className="requiredText">
                        {editWaybill.errors.weight}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Length
                    </label>
                    <input
                      type="text"
                      value={editWaybill.values.length}
                      name="length"
                      placeholder="Length"
                      className="mainInput"
                      onChange={(e) =>
                        editWaybill.setFieldValue("length", e.target.value)
                      }
                    />
                    {editWaybill.touched.length && editWaybill.errors.length ? (
                      <p className="requiredText">
                        {editWaybill.errors.length}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Width
                    </label>
                    <input
                      type="text"
                      value={editWaybill.values.width}
                      name="width"
                      placeholder="Width"
                      className="mainInput"
                      onChange={(e) =>
                        editWaybill.setFieldValue("width", e.target.value)
                      }
                    />
                    {editWaybill.touched.width && editWaybill.errors.width ? (
                      <p className="requiredText">{editWaybill.errors.width}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Height
                    </label>
                    <input
                      type="text"
                      value={editWaybill.values.height}
                      name="height"
                      placeholder="Height"
                      className="mainInput"
                      onChange={(e) =>
                        editWaybill.setFieldValue("height", e.target.value)
                      }
                    />
                    {editWaybill.touched.height && editWaybill.errors.height ? (
                      <p className="requiredText">
                        {editWaybill.errors.height}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "New Country Mapping" ||
              title === "Edit Country Mapping" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    {showCustomer ? (
                    <AsyncSearchInput
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptionsCustomer}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(customer) => customer.label}
                      getOptionValue={(customer) => customer.value}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        addCountryMap.setFieldValue(
                          "customerAccount",
                          selectedOption ? selectedOption.value : ""
                        );
                        addCountryMap.setFieldValue(
                          "customerName",
                          selectedOption ? selectedOption.label : ""
                        );
                      }}
                      placeholder="Customer"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                  ) : (
                    <input
                      type="text"
                      value={data?.customerName}
                      name="customerAccount"
                      className="mainInput"
                      onClick={handleCusClick}
                    />
                  )}
                    {addCountryMap.touched.customerAccount &&
                    addCountryMap.errors.customerAccount ? (
                      <p className="requiredText">
                        {addCountryMap.errors.customerAccount}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryIsoCode3"
                      label="Country"
                      name="countryIsoCode3"
                      isClearable={true}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.isoCode3}
                      value={
                        addCountryMap?.values?.countryIsoCode3
                          ? countriesData?.data?.data?.content?.find(
                              (cnt) =>
                                cnt?.isoCode3 === addCountryMap?.values?.countryIsoCode3
                            )
                          : null
                      }
                      handleChange={(selectedOption) =>
                        addCountryMap.setFieldValue(
                          "countryIsoCode3",
                          selectedOption ? selectedOption.isoCode3 : ""
                        )
                      }
                      isSearchable={true}
                      placeholder="Select Country"
                      readOnly={params?.id}
                    />
                    {addCountryMap.touched.countryIsoCode3 &&
                    addCountryMap.errors.countryIsoCode3 ? (
                      <p className="requiredText">
                        {addCountryMap.errors.countryIsoCode3}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer's Alias:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCountryMap.values.customerAlias}
                      name="customerAlias"
                      placeholder="Customer's Alias"
                      className="mainInput"
                      onChange={(e) =>
                        addCountryMap.setFieldValue(
                          "customerAlias",
                          e.target.value
                        )
                      }
                    />
                    {addCountryMap.touched.customerAlias &&
                    addCountryMap.errors.customerAlias ? (
                      <p className="requiredText">
                        {addCountryMap.errors.customerAlias}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "New Region Mapping" ||
              title === "Edit Region Mapping" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    {showRegCustomer ? (
                    <AsyncSearchInput
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptionsCustomer}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(customer) => customer.label}
                      getOptionValue={(customer) => customer.value}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        addRegionMap.setFieldValue(
                          "customerAccount",
                          selectedOption ? selectedOption.value : ""
                        );
                        addRegionMap.setFieldValue(
                          "customerName",
                          selectedOption ? selectedOption.label : ""
                        );
                      }}
                      placeholder="Customer"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                  ) : (
                    <input
                      type="text"
                      value={data?.customerName}
                      name="customerAccount"
                      className="mainInput"
                      onClick={handleRegCusClick}
                    />
                  )}
                    {addRegionMap.touched.customerAccount &&
                    addRegionMap.errors.customerAccount ? (
                      <p className="requiredText">
                        {addRegionMap.errors.customerAccount}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryIsoCode3"
                      label="Country"
                      name="countryIsoCode3"
                      isClearable={true}
                      value={countriesData?.data?.data?.content?.find(
                        (country) =>
                          country.isoCode3 === addRegionMap.values.countryIsoCode3
                      )}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.isoCode3}
                      handleChange={async (selectedOption) => {
                        // Update form field when an option is selected
                        addRegionMap.setFieldValue(
                          "countryIsoCode3",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                        // Call your API with isoCode3
                        try {
                          const regionData = await getRegionbyCode(
                            selectedOption.isoCode3
                          );
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      isSearchable={true}
                      placeholder="Select Country"
                    />
                    {addRegionMap.touched.countryIsoCode3 &&
                    addRegionMap.errors.countryIsoCode3 ? (
                      <p className="requiredText">
                        {addRegionMap.errors.countryIsoCode3}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="regionCode"
                      className="MainInput"
                      label="Region"
                      name="regionCode"
                      options={regionbyCode || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.code}
                      handleChange={(selectedOption) => {
                        addRegionMap.setFieldValue(
                          "regionCode",
                          selectedOption ? selectedOption.code : ""
                        );
                      }}
                      value={
                        addRegionMap.values.regionCode
                          ? regionsData?.data?.data?.content?.find(
                              (zone) =>
                                zone.code === addRegionMap.values.regionCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Region"
                    />
                    {addRegionMap.touched.regionCode &&
                    addRegionMap.errors.regionCode ? (
                      <p className="requiredText">
                        {addRegionMap.errors.regionCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer's Alias:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addRegionMap.values.customerAlias}
                      name="customerAlias"
                      placeholder="Customer's Alias"
                      className="mainInput"
                      onChange={(e) =>
                        addRegionMap.setFieldValue(
                          "customerAlias",
                          e.target.value
                        )
                      }
                    />
                    {addRegionMap.touched.customerAlias &&
                    addRegionMap.errors.customerAlias ? (
                      <p className="requiredText">
                        {addRegionMap.errors.customerAlias}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "New City Mapping" ||
              title === "Edit City Mapping" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer Account:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    {showCityCustomer ? (
                    <AsyncSearchInput
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptionsCustomer}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(customer) => customer.label}
                      getOptionValue={(customer) => customer.value}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        addCityMap.setFieldValue(
                          "customerAccount",
                          selectedOption ? selectedOption.value : ""
                        );
                        addCityMap.setFieldValue(
                          "customerName",
                          selectedOption ? selectedOption.label : ""
                        );
                      }}
                      placeholder="Customer"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                  ) : (
                    <input
                      type="text"
                      value={data?.customerName}
                      name="customerAccount"
                      className="mainInput"
                      onClick={handleCityCusClick}
                    />
                  )}
                    {addCityMap.touched.customerAccount &&
                    addCityMap.errors.customerAccount ? (
                      <p className="requiredText">
                        {addCityMap.errors.customerAccount}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryIsoCode3"
                      label="Country"
                      name="countryIsoCode3"
                      isClearable={true}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.isoCode3}
                      value={countriesData?.data?.data?.content?.find(
                        (country) =>
                          country.isoCode3 === addCityMap.values.countryIsoCode3
                      )}
                      handleChange={async (selectedOption) => {
                        // Update form field when an option is selected
                        addCityMap.setFieldValue(
                          "countryIsoCode3",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                        // Call your API with isoCode3
                        try {
                          const regionData = await getRegionbyCode(
                            selectedOption.isoCode3
                          );
                          // if(regionData?.data?.data?.length === 1){
                          const cityData = await getCitybyCode(
                            regionData?.data?.data?.[0]?.countryIsoCode3,
                            regionData?.data?.data?.[0]?.code
                          );
                          // }
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      isSearchable={true}
                      placeholder="Select Country"
                    />
                    {addCityMap.touched.countryIsoCode3 &&
                    addCityMap.errors.countryIsoCode3 ? (
                      <p className="requiredText">
                        {addCityMap.errors.countryIsoCode3}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="regionCode"
                      label="Region"
                      name="regionCode"
                      isClearable={true}
                      options={regionbyCode || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.code}
                      value={
                        addCityMap.values.regionCode
                          ? regionsData?.data?.data?.content?.find(
                              (zone) =>
                                zone.code === addCityMap.values.regionCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) =>
                        handleRegionChange(selectedOption, addCityMap)
                      }
                      isSearchable={true}
                      placeholder="Select Region"
                    />
                    {addCityMap.touched.regionCode &&
                    addCityMap.errors.regionCode ? (
                      <p className="requiredText">
                        {addCityMap.errors.regionCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      City:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="cityCode"
                      label="City"
                      name="cityCode"
                      options={citybyCode || []}
                      getOptionLabel={(city) => city.name}
                      getOptionValue={(city) => city.code}
                      value={
                        addCityMap.values.cityCode
                          ? citybyCode?.find(
                              (zone) => zone.code === addCityMap.values.cityCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) =>
                        handleCityChange(selectedOption, addCityMap)
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select City"
                    />
                    {addCityMap.touched.cityCode &&
                    addCityMap.errors.cityCode ? (
                      <p className="requiredText">
                        {addCityMap.errors.cityCode}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer's Alias:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCityMap.values.customerAlias}
                      name="customerAlias"
                      placeholder="Customer's Alias"
                      className="mainInput"
                      onChange={(e) =>
                        addCityMap.setFieldValue(
                          "customerAlias",
                          e.target.value
                        )
                      }
                    />
                    {addCityMap.touched.customerAlias &&
                    addCityMap.errors.customerAlias ? (
                      <p className="requiredText">
                        {addCityMap.errors.customerAlias}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "New District Mapping" ||
              title === "Edit District Mapping" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer Account:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    {showDisCustomer ? (
                    <AsyncSearchInput
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptionsCustomer}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(customer) => customer.label}
                      getOptionValue={(customer) => customer.value}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        addDistrictMap.setFieldValue(
                          "customerAccount",
                          selectedOption ? selectedOption.value : ""
                        );
                        addDistrictMap.setFieldValue(
                          "customerName",
                          selectedOption ? selectedOption.label : ""
                        );
                      }}
                      placeholder="Customer"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                  ) : (
                    <input
                      type="text"
                      value={data?.customerName}
                      name="customerAccount"
                      className="mainInput"
                      onClick={handleDisCusClick}
                    />
                  )}
                    {addDistrictMap.touched.customerAccount &&
                    addDistrictMap.errors.customerAccount ? (
                      <p className="requiredText">
                        {addDistrictMap.errors.customerAccount}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryIsoCode3"
                      label="Country"
                      name="countryIsoCode3"
                      isClearable={true}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.isoCode3}
                      value={
                        addDistrictMap.values.countryIsoCode3
                          ? countriesData?.data?.data?.content?.find(
                              (country) =>
                                country.isoCode3 ===
                                addDistrictMap.values.countryIsoCode3
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        // Update form field when an option is selected
                        addDistrictMap.setFieldValue(
                          "countryIsoCode3",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                        // Call your API with isoCode3
                        try {
                          const regionData = await getRegionbyCode(
                            selectedOption.isoCode3
                          );
                          // if(regionData?.data?.data?.length === 1){
                          const cityData = await getCitybyCode(
                            regionData?.data?.data?.[0]?.countryIsoCode3,
                            regionData?.data?.data?.[0]?.code
                          );
                          // }
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      isSearchable={true}
                      placeholder="Select Country"
                    />
                    {addDistrictMap.touched.countryIsoCode3 &&
                    addDistrictMap.errors.countryIsoCode3 ? (
                      <p className="requiredText">
                        {addDistrictMap.errors.countryIsoCode3}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="regionCode"
                      label="Region"
                      name="regionCode"
                      isClearable={true}
                      options={regionbyCode || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.name}
                      value={
                        addDistrictMap.values.regionCode
                          ? regionsData?.data?.data?.content?.find(
                              (zone) =>
                                zone.code === addDistrictMap.values.regionCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) =>
                        handleRegionChange(selectedOption, addDistrictMap)
                      }
                      isSearchable={true}
                      placeholder="Select Region"
                    />
                    {addDistrictMap.touched.regionCode &&
                    addDistrictMap.errors.regionCode ? (
                      <p className="requiredText">
                        {addDistrictMap.errors.regionCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      City:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="cityName"
                      label="City"
                      name="cityName"
                      options={citybyCode || []}
                      getOptionLabel={(city) => city.name}
                      getOptionValue={(city) => city.code}
                      value={
                        addDistrictMap.values.cityCode
                          ? citybyCode?.find(
                              (zone) =>
                                zone.code === addDistrictMap.values.cityCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) =>
                        handleCtyChange(selectedOption, addDistrictMap)
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select City"
                    />
                    {addDistrictMap.touched.cityCode &&
                    addDistrictMap.errors.cityCode ? (
                      <p className="requiredText">
                        {addDistrictMap.errors.cityCode}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      District:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="districtName"
                      label="City"
                      name="districtName"
                      options={districtbyCode || []}
                      getOptionLabel={(district) => district.name}
                      getOptionValue={(district) => district.code}
                      value={
                        addDistrictMap.values.districtCode
                          ? districtbyCode?.find(
                              (district) =>
                                district.code ===
                                addDistrictMap.values.districtCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) =>
                        handleDistrictChange(selectedOption, addDistrictMap)
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select District"
                    />
                    {addDistrictMap.touched.districtCode &&
                    addDistrictMap.errors.districtCode ? (
                      <p className="requiredText">
                        {addDistrictMap.errors.districtCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Customer's Alias:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addDistrictMap.values.customerAlias}
                      name="customerAlias"
                      placeholder="Customer's Alias"
                      className="mainInput"
                      onChange={(e) =>
                        addDistrictMap.setFieldValue(
                          "customerAlias",
                          e.target.value
                        )
                      }
                    />
                    {addDistrictMap.touched.customerAlias &&
                    addDistrictMap.errors.customerAlias ? (
                      <p className="requiredText">
                        {addDistrictMap.errors.customerAlias}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "Add new Hub" || title === "Edit Hub" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Hub Code:
                    </label>
                    <div className="Grpinput">
                      <input
                        type="text"
                        value={addHubs.values.code}
                        name="code"
                        placeholder="Hub Code"
                        className="mainInput"
                        onChange={(e) =>
                          addHubs.setFieldValue("code", e.target.value)
                        }
                        disabled={title == "Edit Hub" ?? true}
                      />
                    </div>
                    {addHubs.touched.code && addHubs.errors.code ? (
                      <p className="requiredText">{addHubs.errors.code}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Hub Name:
                    </label>
                    <div className="Grpinput">
                      <input
                        type="text"
                        value={addHubs.values.name}
                        name="name"
                        placeholder="Hub Name"
                        className="mainInput"
                        onChange={(e) =>
                          addHubs.setFieldValue("name", e.target.value)
                        }
                      />
                    </div>
                    {addHubs.touched.name && addHubs.errors.name ? (
                      <p className="requiredText">{addHubs.errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Type:
                    </label>
                    <SearchInput
                      id="type"
                      label="type"
                      name="type"
                      isClearable={true}
                      options={hubTypes?.data?.data || []}
                      getOptionLabel={(country) => country.label}
                      getOptionValue={(country) => country.key}
                      handleChange={async (selectedOption) => {
                        addHubs?.setFieldValue(
                          "type",
                          selectedOption ? selectedOption.key : ""
                        );
                        if (
                          selectedOption.key === "SUBSTATION" ||
                          selectedOption.key === "VIRTUAL"
                        ) {
                          // Set an error message indicating that SUBSTATION is not allowed
                          if (addHubs?.values?.parentHub === "") {
                            setError("Parent Hub is required");
                          }
                        } else {
                          // Clear any existing error message
                          setError(null);
                        }
                      }}
                      value={
                        addHubs.values.type
                          ? hubTypes?.data?.data?.find(
                              (hub) => hub.key === addHubs.values.type
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Type"
                    />
                    {addHubs.touched.type && addHubs.errors.type ? (
                      <p className="requiredText">{addHubs.errors.type}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Parent Hub:
                    </label>
                    <SearchInput
                      id="parentHub"
                      label="parentHub"
                      name="parentHub"
                      isClearable={true}
                      options={hubsData?.data?.data?.content || []}
                      getOptionLabel={(hub) => hub.name}
                      getOptionValue={(hub) => hub.code}
                      value={
                        addHubs.values.parentHub
                          ? hubsData?.data?.data?.content?.find(
                              (hub) => hub.code === addHubs.values.parentHub
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        addHubs.setFieldValue(
                          "parentHub",
                          selectedOption ? selectedOption.code : ""
                        );
                        // Check if the selectedOption is null (i.e., the user cleared the selection)
                        if (!selectedOption) {
                          // If type is SUBSTATION, set the error message
                          if (addHubs.values.type === "SUBSTATION") {
                            setError("Parent Hub is required");
                          } else {
                            // Clear any existing error message if the type is not SUBSTATION
                            setError(null);
                          }
                        } else {
                          // Clear any existing error message if a selection is made
                          setError(null);
                        }
                      }}
                      isSearchable={true}
                      placeholder="Parent Hub"
                    />
                    {error ? <p className="requiredText">{error}</p> : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                    </label>
                    <SearchInput
                      id="countryIsoCode3"
                      label="countryIsoCode3"
                      name="countryIsoCode3"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(city) => city.name}
                      getOptionValue={(city) => city.isoCode3}
                      value={
                        addHubs.values.countryIsoCode3
                          ? countriesData?.data?.data?.content?.find(
                              (country) =>
                                country.isoCode3 ===
                                addHubs.values.countryIsoCode3
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        addHubs.setFieldValue(
                          "countryIsoCode3",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                        try {
                          const regionData = await getRegionbyCode(
                            selectedOption.isoCode3
                          );
                          const cityData = await getCitybyCode(
                            regionData?.data?.data?.[0]?.countryIsoCode3,
                            regionData?.data?.data?.[0]?.code
                          );
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                    />
                    {addHubs.touched.countryIsoCode3 &&
                    addHubs.errors.countryIsoCode3 ? (
                      <p className="requiredText">
                        {addHubs.errors.countryIsoCode3}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                    </label>
                    <SearchInput
                      id="regionCode"
                      label="regionCode"
                      name="regionCode"
                      options={regionbyCode || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.code}
                      value={
                        addHubs.values.regionCode
                          ? regionsData?.data?.data?.content?.find(
                              (region) =>
                                region.code === addHubs.values.regionCode
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        addHubs.setFieldValue(
                          "regionCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        const cityData = await getCitybyCode(
                          selectedOption?.countryIsoCode3,
                          selectedOption?.code
                        );
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Region"
                    />
                    {addHubs.touched.regionCode && addHubs.errors.regionCode ? (
                      <p className="requiredText">
                        {addHubs.errors.regionCode}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-4">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      City:
                    </label>
                    <SearchInput
                      id="cityCode"
                      label="cityCode"
                      name="cityCode"
                      options={citybyCode || []}
                      getOptionLabel={(city) => city.name}
                      getOptionValue={(city) => city.code}
                      value={
                        addHubs.values.cityCode
                          ? citiesData?.data?.data?.content?.find(
                              (city) => city.code === addHubs.values.cityCode
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        addHubs.setFieldValue(
                          "cityCode",
                          selectedOption ? selectedOption.code : ""
                        );
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="City"
                    />
                    {addHubs.touched.cityCode && addHubs.errors.cityCode ? (
                      <p className="requiredText">{addHubs.errors.cityCode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Latitude:
                    </label>
                    <div className="Grpinput">
                      <input
                        type="text"
                        value={addHubs.values.latitude}
                        name="latitude"
                        placeholder="Latitude"
                        className="mainInput"
                        onChange={(e) =>
                          addHubs.setFieldValue("latitude", e.target.value)
                        }
                      />
                    </div>
                    {addHubs.touched.latitude && addHubs.errors.latitude ? (
                      <p className="requiredText">{addHubs.errors.latitude}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Longitude:
                    </label>
                    <div className="Grpinput">
                      <input
                        type="text"
                        value={addHubs.values.longitude}
                        name="longitude"
                        placeholder="Longitude"
                        className="mainInput"
                        onChange={(e) =>
                          addHubs.setFieldValue("longitude", e.target.value)
                        }
                      />
                    </div>
                    {addHubs.touched.longitude && addHubs.errors.longitude ? (
                      <p className="requiredText">{addHubs.errors.longitude}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-12">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Description:
                    </label>
                    <div className="Grpinput">
                      <textarea
                        className="washMe_button"
                        value={addHubs.values.description}
                        name="description"
                        placeholder="Description"
                        style={{ height: "90px", paddingTop: "6px" }}
                        onChange={(e) =>
                          addHubs.setFieldValue("description", e.target.value)
                        }
                      ></textarea>
                    </div>
                    {addHubs.touched.description &&
                    addHubs.errors.description ? (
                      <p className="requiredText">
                        {addHubs.errors.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "Create new currency" || title === "Edit Currency" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Code:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCurrency.values.code}
                      name="customerAccount"
                      placeholder="Code"
                      className="mainInput"
                      onChange={(e) =>
                        addCurrency.setFieldValue("code", e.target.value)
                      }
                    />
                    {addCurrency.touched.code && addCurrency.errors.code ? (
                      <p className="requiredText">{addCurrency.errors.code}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      ISO Number:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCurrency.values.isoNumber}
                      name="isoNumber"
                      placeholder="ISO Number"
                      className="mainInput"
                      onChange={(e) =>
                        addCurrency.setFieldValue("isoNumber", e.target.value)
                      }
                    />
                    {addCurrency.touched.isoNumber &&
                    addCurrency.errors.isoNumber ? (
                      <p className="requiredText">
                        {addCurrency.errors.isoNumber}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Name:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCurrency.values.name}
                      name="name"
                      placeholder="Name"
                      className="mainInput"
                      onChange={(e) =>
                        addCurrency.setFieldValue("name", e.target.value)
                      }
                    />
                    {addCurrency.touched.name && addCurrency.errors.name ? (
                      <p className="requiredText">{addCurrency.errors.name}</p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "Add origin" || title === "Edit origin" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      isClearable={true}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.isoCode3}
                      handleChange={async (selectedOption) => {
                        addOrigin.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                        try {
                          const regionData = await getRegionbyCode(
                            selectedOption.isoCode3
                          );
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      value={
                        countriesData?.data?.data?.content?.find(
                          (country) =>
                            country.isoCode3 === addOrigin.values.countryCode
                        ) || null
                      }
                      isSearchable={true}
                      placeholder="Country"
                    />
                    {addOrigin.touched.countryCode &&
                    addOrigin.errors.countryCode ? (
                      <p className="requiredText">
                        {addOrigin.errors.countryCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                    </label>
                    <SearchInput
                      id="regionCode"
                      label="regionCode"
                      name="regionCode"
                      isClearable={true}
                      options={regionbyCode || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.code}
                      handleChange={async (selectedOption) => {
                        addOrigin.setFieldValue(
                          "regionCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        try {
                          const cityData = await getCitybyCode(
                            selectedOption.countryIsoCode3,
                            selectedOption.code
                          );
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      value={
                        regionsData?.data?.data?.content?.find(
                          (region) =>
                            region.code === addOrigin.values.regionCode
                        ) || null
                      }
                      isSearchable={true}
                      placeholder="Region"
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      City:
                    </label>
                    {showOrgCity ? (
                      <SearchInput
                        id="cityCode"
                        label="cityCode"
                        name="cityCode"
                        isClearable={true}
                        options={citybyCode || []}
                        getOptionLabel={(region) => region.name}
                        getOptionValue={(region) => region.code}
                        handleChange={(selectedOption) =>
                          addOrigin.setFieldValue(
                            "cityCode",
                            selectedOption ? selectedOption.code : ""
                          )
                        }
                        value={
                          citybyCode?.find(
                            (city) => city.code === addOrigin.values.cityCode
                          ) || null
                        }
                        isSearchable={true}
                        placeholder="City"
                      />
                    ) : (
                      <input
                        type="text"
                        value={addOrigin.values.cityCode}
                        name="zoneCode"
                        placeholder="Zone"
                        className="mainInput"
                        onClick={handleRegularInputoriginClick}
                      />
                    )}
                  </div>
                </div>
              </form>
            ) : title === "Add destination" || title === "Edit destination" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      isClearable={true}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.isoCode3}
                      handleChange={async (selectedOption) => {
                        addDestination.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                        try {
                          const regionData = await getRegionbyCode(
                            selectedOption?.isoCode3
                          );
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      value={
                        countriesData?.data?.data?.content?.find(
                          (country) =>
                            country.isoCode3 ===
                            addDestination.values.countryCode
                        ) || null
                      }
                      isSearchable={true}
                      placeholder="Country"
                    />
                    {addDestination.touched.countryCode &&
                    addDestination.errors.countryCode ? (
                      <p className="requiredText">
                        {addDestination.errors.countryCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                    </label>
                    <SearchInput
                      id="regionCode"
                      label="regionCode"
                      name="regionCode"
                      isClearable={true}
                      options={regionbyCode || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.code}
                      handleChange={async (selectedOption) => {
                        addDestination.setFieldValue(
                          "regionCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        try {
                          const cityData = await getCitybyCode(
                            selectedOption.countryIsoCode3,
                            selectedOption.code
                          );
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      value={
                        regionsData?.data?.data?.content?.find(
                          (region) =>
                            region.code === addDestination.values.regionCode
                        ) || null
                      }
                      isSearchable={true}
                      placeholder="Region"
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      City:
                    </label>
                    {showDesCity ? (
                      <SearchInput
                        id="cityCode"
                        label="cityCode"
                        name="cityCode"
                        isClearable={true}
                        options={citybyCode || []}
                        getOptionLabel={(region) => region.name}
                        getOptionValue={(region) => region.code}
                        handleChange={(selectedOption) =>
                          addDestination.setFieldValue(
                            "cityCode",
                            selectedOption ? selectedOption.code : ""
                          )
                        }
                        value={
                          citybyCode?.find(
                            (city) =>
                              city.code === addDestination.values.cityCode
                          ) || null
                        }
                        isSearchable={true}
                        placeholder="City"
                      />
                    ) : (
                      <input
                        type="text"
                        value={addDestination.values.cityCode}
                        name="zoneCode"
                        placeholder="Zone"
                        className="mainInput"
                        onClick={handleRegularInputdesClick}
                      />
                    )}
                  </div>
                </div>
              </form>
            ) : title === "New Transit Time" ||
              title === "Edit Transit Time" ? (
              <form>
                <div className="row">
                  <div className="col-md-3">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Days:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <div className="Grpinput">
                      <input
                        type="text"
                        value={addTransit.values.days}
                        name="days"
                        placeholder="Days"
                        className="mainInput"
                        onChange={(e) =>
                          addTransit.setFieldValue("days", e.target.value)
                        }
                      />
                    </div>
                    {addTransit.touched.days && addTransit.errors.days ? (
                      <p className="requiredText">{addTransit.errors.days}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt_20">
                    <div className="borderFrame mb_16">
                      <div className="borderFrameTitle">Origin</div>
                      <div className="mt_-15">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Country:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <SearchInput
                          id="originCountry"
                          label="originCountry"
                          name="originCountry"
                          isClearable={true}
                          options={originCty || []}
                          getOptionLabel={(region) => region.countryName}
                          getOptionValue={(region) => region.countryCode}
                          handleChange={async (selectedOption) => {
                            addTransit.setFieldValue(
                              "originCountry",
                              selectedOption ? selectedOption.countryCode : ""
                            );
                            try {
                              const regionData = await getRegionbyCode(
                                selectedOption.countryCode
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          value={
                            addTransit?.values?.originCountry
                              ? originCountries?.find(
                                  (country) =>
                                    country.countryCode ===
                                    addTransit?.values?.originCountry
                                )
                              : null
                          }
                          isSearchable={true}
                          placeholder="Country"
                        />
                        {addTransit.touched.originCountry &&
                        addTransit.errors.originCountry ? (
                          <p className="requiredText">
                            {addTransit.errors.originCountry}
                          </p>
                        ) : null}
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Region:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <SearchInput
                          id="originRegion"
                          label="originRegion"
                          name="originRegion"
                          isClearable={true}
                          options={regionbyCode || []}
                          getOptionLabel={(region) => region.name}
                          getOptionValue={(region) => region.code}
                          handleChange={async (selectedOption) => {
                            addTransit.setFieldValue(
                              "originRegion",
                              selectedOption ? selectedOption.code : ""
                            );
                            try {
                              const cityData = await getCitybyCode(
                                selectedOption.countryIsoCode3,
                                selectedOption.code
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          value={
                            regionsData?.data?.data?.content?.find(
                              (region) =>
                                region.code === addTransit.values.originRegion
                            ) || null
                          }
                          isSearchable={true}
                          placeholder="Region"
                        />
                        {addTransit.touched.originRegion &&
                        addTransit.errors.originRegion ? (
                          <p className="requiredText">
                            {addTransit.errors.originRegion}
                          </p>
                        ) : null}
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          City:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        {showorgTransitCity ? (
                          <SearchInput
                            id="originCity"
                            label="originCity"
                            name="originCity"
                            isClearable={true}
                            options={citybyCode || []}
                            getOptionLabel={(region) => region.name}
                            getOptionValue={(region) => region.code}
                            handleChange={(selectedOption) =>
                              addTransit.setFieldValue(
                                "originCity",
                                selectedOption ? selectedOption.code : ""
                              )
                            }
                            value={
                              citybyCode?.find(
                                (city) =>
                                  city.code === addTransit.values.originCity
                              ) || null
                            }
                            isSearchable={true}
                            placeholder="City"
                          />
                        ) : (
                          <input
                            type="text"
                            value={addTransit.values.originCity}
                            name="originCity"
                            className="mainInput"
                            onClick={handleInputorgTransitClick}
                          />
                        )}
                        {addTransit.touched.originCity &&
                        addTransit.errors.originCity ? (
                          <p className="requiredText">
                            {addTransit.errors.originCity}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt_20">
                    <div className="borderFrame mb_16">
                      <div className="borderFrameTitle">Destination</div>
                      <div className="mt_-15">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Country:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <SearchInput
                          id="destinationCountry"
                          label="destinationCountry"
                          name="destinationCountry"
                          isClearable={true}
                          options={destinationCty || []}
                          getOptionLabel={(region) => region.countryName}
                          getOptionValue={(region) => region.countryCode}
                          handleChange={async (selectedOption) => {
                            addTransit.setFieldValue(
                              "destinationCountry",
                              selectedOption ? selectedOption.countryCode : ""
                            );
                            try {
                              const regionData = await getRegionbyCodeDes(
                                selectedOption.countryCode
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          value={
                            addTransit?.values?.destinationCountry
                              ? destinationCountries?.find(
                                  (country) =>
                                    country.countryCode ===
                                    addTransit?.values?.destinationCountry
                                )
                              : null
                          }
                          isSearchable={true}
                          placeholder="Country"
                        />
                        {addTransit.touched.destinationCountry &&
                        addTransit.errors.destinationCountry ? (
                          <p className="requiredText">
                            {addTransit.errors.destinationCountry}
                          </p>
                        ) : null}
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Region:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <SearchInput
                          id="destinationRegion"
                          label="destinationRegion"
                          name="destinationRegion"
                          isClearable={true}
                          options={regionbyCodeDes || []}
                          getOptionLabel={(region) => region.name}
                          getOptionValue={(region) => region.code}
                          handleChange={async (selectedOption) => {
                            addTransit.setFieldValue(
                              "destinationRegion",
                              selectedOption ? selectedOption.code : ""
                            );
                            try {
                              const cityData = await getCitybyCodeDes(
                                selectedOption.countryIsoCode3,
                                selectedOption.code
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          value={
                            regionsData?.data?.data?.content?.find(
                              (region) =>
                                region.code ===
                                addTransit.values.destinationRegion
                            ) || null
                          }
                          isSearchable={true}
                          placeholder="Region"
                        />
                        {addTransit.touched.destinationRegion &&
                        addTransit.errors.destinationRegion ? (
                          <p className="requiredText">
                            {addTransit.errors.destinationRegion}
                          </p>
                        ) : null}
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          City:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        {showdesTransitCity ? (
                          <SearchInput
                            id="destinationCity"
                            label="destinationCity"
                            name="destinationCity"
                            isClearable={true}
                            options={citybyCodeDes || []}
                            getOptionLabel={(region) => region.name}
                            getOptionValue={(region) => region.code}
                            handleChange={(selectedOption) =>
                              addTransit.setFieldValue(
                                "destinationCity",
                                selectedOption ? selectedOption.code : ""
                              )
                            }
                            value={
                              citybyCodeDes?.find(
                                (city) =>
                                  city.code ===
                                  addTransit.values.destinationCity
                              ) || null
                            }
                            isSearchable={true}
                            placeholder="City"
                          />
                        ) : (
                          <input
                            type="text"
                            value={addTransit.values.destinationCity}
                            name="destinationCity"
                            className="mainInput"
                            onClick={handleInputdesTransitClick}
                          />
                        )}
                        {addTransit.touched.destinationCity &&
                        addTransit.errors.destinationCity ? (
                          <p className="requiredText">
                            {addTransit.errors.destinationCity}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Add Area" || title === "Edit Area" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      isClearable={true}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.isoCode3}
                      handleChange={async (selectedOption) => {
                        addArea.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                        try {
                          const regionData = await getRegionbyCode(
                            selectedOption.isoCode3
                          );
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      value={
                        countriesData?.data?.data?.content?.find(
                          (country) =>
                            country.isoCode3 === addArea.values.countryCode
                        ) || null
                      }
                      isSearchable={true}
                      placeholder="Country"
                    />
                    {addArea.touched.countryCode &&
                    addArea.errors.countryCode ? (
                      <p className="requiredText">
                        {addArea.errors.countryCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                    </label>
                    <SearchInput
                      id="regionCode"
                      label="regionCode"
                      name="regionCode"
                      isClearable={true}
                      options={
                        title === "Add Area"
                          ? regionbyCode
                          : regionsData?.data?.data?.content || []
                      }
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.code}
                      handleChange={async (selectedOption) => {
                        addArea.setFieldValue(
                          "regionCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        try {
                          let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
                          params += `&countryIsoCode3=${selectedOption.countryIsoCode3}`;
                          params += `&regionCode=${selectedOption.code}`;
                          const hubData = await getHubs(params);
                        } catch (error) {
                          console.error("Error fetching region data:", error);
                        }
                      }}
                      value={
                        regionsData?.data?.data?.content?.find(
                          (region) => region.code === addArea.values.regionCode
                        ) || null
                      }
                      isSearchable={true}
                      placeholder="Region"
                    />
                  </div>
                </div>
              </form>
            ) : title === "Create service area" ||
              title === "Edit Service Areas" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Hub
                    </label>
                    <input
                      type="text"
                      value={addServiceAreas.values.hubCode}
                      name="hubCode"
                      placeholder="Hub"
                      className="mainInput"
                      disabled
                    />
                    {addServiceAreas.touched.hubCode &&
                    addServiceAreas.errors.hubCode ? (
                      <p className="requiredText">
                        {addServiceAreas.errors.hubCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Zone
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <div className="w165 mt_-5">
                      {showAsyncInputareas ? (
                        <AsyncSearchInput
                          id="zoneCode"
                          label="Zone"
                          name="zoneCode"
                          value={selectedOption}
                          loadMoreOptions={loadMoreOptions}
                          shouldLoadMore={shouldLoadMore}
                          getOptionLabel={(zone) => zone.label}
                          getOptionValue={(zone) => zone.value}
                          onChange={(selectedOption) => {
                            setSelectedOption(selectedOption);
                            addServiceAreas.setFieldValue(
                              "zoneCode",
                              selectedOption ? selectedOption.value : ""
                            );
                          }}
                          customStyles={{ control: { width: "197px" } }}
                          placeholder="Zone"
                          menuPlacement="auto"
                          menuPosition="fixed"
                          menuStyle={{ zIndex: 9999 }}
                        />
                      ) : (
                        <input
                          type="text"
                          value={addServiceAreas.values.zoneCode}
                          name="zoneCode"
                          placeholder="Zone"
                          className="mainInput w197"
                          onClick={handleRegularInputareasClick}
                        />
                      )}
                      {addServiceAreas.touched.zoneCode &&
                      addServiceAreas.errors.zoneCode ? (
                        <p className="requiredText">
                          {addServiceAreas.errors.zoneCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 mt_20">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Allowed Event
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="allowedEvents"
                      label="allowedEvents"
                      name="allowedEvents"
                      options={allowedeventData?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      value={allowedeventData?.data?.data?.filter((option) =>
                        addServiceAreas.values.allowedEvents?.includes(
                          option.key
                        )
                      )}
                      handleChange={(selectedOptions) =>
                        addServiceAreas.setFieldValue(
                          "allowedEvents",
                          selectedOptions
                            ? selectedOptions.map((option) => option.key)
                            : []
                        )
                      }
                      isClearable={true}
                      isMulti={true}
                      isSearchable={true}
                      placeholder="Allowed Event"
                      customStyles={{ control: { width: "197px" } }}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />

                    {addServiceAreas.touched.allowedEvents &&
                    addServiceAreas.errors.allowedEvents ? (
                      <p className="requiredText">
                        {addServiceAreas.errors.allowedEvents}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "Create new tier" || title === "Edit tier" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.isoCode3}
                      value={
                        addTiers.values.countryCode
                          ? countriesData?.data?.data?.content?.find(
                              (country) =>
                                country.isoCode3 === addTiers.values.countryCode
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        addTiers.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      customStyles={{ control: { width: "180px" } }}
                    />
                    {addTiers.touched.countryCode &&
                    addTiers.errors.countryCode ? (
                      <p className="requiredText">
                        {addTiers.errors.countryCode}
                      </p>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt_20">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Code
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div style={{ width: "180px" }}>
                        <input
                          type="text"
                          value={addTiers.values.code}
                          name="code"
                          placeholder="Code"
                          className="mainInput"
                          onChange={(e) =>
                            addTiers.setFieldValue("code", e.target.value)
                          }
                          onBlur={addTiers.handleBlur}
                        />
                        {addTiers.touched.code && addTiers.errors.code ? (
                          <p className="requiredText">{addTiers.errors.code}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 mt_20">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Name
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div style={{ width: "180px" }}>
                        <input
                          type="text"
                          value={addTiers.values.name}
                          name="name"
                          placeholder="Name"
                          className="mainInput"
                          onChange={(e) =>
                            addTiers.setFieldValue("name", e.target.value)
                          }
                          onBlur={addTiers.handleBlur}
                        />
                      </div>
                      {addTiers.touched.name && addTiers.errors.name ? (
                        <p className="requiredText">{addTiers.errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create OD Pair" || title === "Edit OD Pair" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                    </label>
                    <div className="Grpinput">
                      <input
                        type="text"
                        value={
                          title !== "Edit OD Pair"
                            ? countryName
                            : tierCountryName
                        }
                        name="tier"
                        className="mainInput"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Tier:
                    </label>
                    <div className="Grpinput">
                      <input
                        type="text"
                        value={title !== "Edit OD Pair" ? tier : params?.id}
                        name="tier"
                        className="mainInput"
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt_20">
                    <div className="borderFrame mb_16">
                      <div className="borderFrameTitle">Origin</div>
                      <div className="mt_-15">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Region:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <SearchInput
                          id="originRegionCode"
                          label="originRegionCode"
                          name="originRegionCode"
                          isClearable={true}
                          options={regionsData?.data?.data?.content || []}
                          getOptionLabel={(region) => region.name}
                          getOptionValue={(region) => region.code}
                          handleChange={async (selectedOption) => {
                            addODPairtiers.setFieldValue(
                              "originRegionCode",
                              selectedOption ? selectedOption.code : ""
                            );
                            try {
                              const cityData = await getCitybyCode(
                                selectedOption.countryIsoCode3,
                                selectedOption.code
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          value={
                            regionsData?.data?.data?.content?.find(
                              (region) =>
                                region.code ===
                                addODPairtiers.values.originRegionCode
                            ) || null
                          }
                          isSearchable={true}
                          placeholder="Region"
                        />
                        {addODPairtiers.touched.originRegionCode &&
                        addODPairtiers.errors.originRegionCode ? (
                          <p className="requiredText">
                            {addODPairtiers.errors.originRegionCode}
                          </p>
                        ) : null}
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 mt_10">
                          City:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        {showAsyncInputtiers ? (
                          <SearchInput
                            id="originCityCode"
                            label="originCityCode"
                            name="originCityCode"
                            isClearable={true}
                            options={citybyCode || []}
                            getOptionLabel={(region) => region.name}
                            getOptionValue={(region) => region.code}
                            handleChange={(selectedOption) =>
                              addODPairtiers.setFieldValue(
                                "originCityCode",
                                selectedOption ? selectedOption.code : ""
                              )
                            }
                            value={
                              citybyCode?.find(
                                (city) =>
                                  city.code ===
                                  addODPairtiers.values.originCityCode
                              ) || null
                            }
                            isSearchable={true}
                            placeholder="City"
                          />
                        ) : (
                          <input
                            type="text"
                            value={addODPairtiers.values.originCityCode}
                            name="originCityCode"
                            className="mainInput"
                            onClick={handleRegularInputTiersClick}
                          />
                        )}
                        {addODPairtiers.touched.originCityCode &&
                        addODPairtiers.errors.originCityCode ? (
                          <p className="requiredText">
                            {addODPairtiers.errors.originCityCode}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt_20">
                    <div className="borderFrame mb_16">
                      <div className="borderFrameTitle">Destination</div>
                      <div className="mt_-15">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Region:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        <SearchInput
                          id="destinationRegionCode"
                          label="destinationRegionCode"
                          name="destinationRegionCode"
                          isClearable={true}
                          options={regionsData?.data?.data?.content || []}
                          getOptionLabel={(region) => region.name}
                          getOptionValue={(region) => region.code}
                          handleChange={async (selectedOption) => {
                            addODPairtiers.setFieldValue(
                              "destinationRegionCode",
                              selectedOption ? selectedOption.code : ""
                            );
                            try {
                              const cityData = await getCitybyCodeDes(
                                selectedOption.countryIsoCode3,
                                selectedOption.code
                              );
                            } catch (error) {
                              console.error(
                                "Error fetching region data:",
                                error
                              );
                            }
                          }}
                          value={
                            regionsData?.data?.data?.content?.find(
                              (region) =>
                                region.code ===
                                addODPairtiers.values.destinationRegionCode
                            ) || null
                          }
                          isSearchable={true}
                          placeholder="Region"
                        />
                        {addODPairtiers.touched.destinationRegionCode &&
                        addODPairtiers.errors.destinationRegionCode ? (
                          <p className="requiredText">
                            {addODPairtiers.errors.destinationRegionCode}
                          </p>
                        ) : null}
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 mt_10">
                          City:
                          <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                        </label>
                        {showAsyncInputDestiers ? (
                          <SearchInput
                            id="destinationCityCode"
                            label="destinationCityCode"
                            name="destinationCityCode"
                            isClearable={true}
                            options={citybyCodeDes || []}
                            getOptionLabel={(region) => region.name}
                            getOptionValue={(region) => region.code}
                            handleChange={(selectedOption) =>
                              addODPairtiers.setFieldValue(
                                "destinationCityCode",
                                selectedOption ? selectedOption.code : ""
                              )
                            }
                            value={
                              citybyCodeDes?.find(
                                (city) =>
                                  city.code ===
                                  addODPairtiers.values.destinationCityCode
                              ) || null
                            }
                            isSearchable={true}
                            placeholder="City"
                          />
                        ) : (
                          <input
                            type="text"
                            value={addODPairtiers.values.destinationCityCode}
                            name="destinationCityCode"
                            className="mainInput"
                            onClick={handleRegularInputDesTiersClick}
                          />
                        )}
                        {addODPairtiers.touched.destinationCityCode &&
                        addODPairtiers.errors.destinationCityCode ? (
                          <p className="requiredText">
                            {addODPairtiers.errors.destinationCityCode}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create new currency" || title === "Edit Currency" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Code:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCurrency.values.code}
                      name="customerAccount"
                      placeholder="Code"
                      className="mainInput"
                      onChange={(e) =>
                        addCurrency.setFieldValue("code", e.target.value)
                      }
                    />
                    {addCurrency.touched.code && addCurrency.errors.code ? (
                      <p className="requiredText">{addCurrency.errors.code}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      ISO Number:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCurrency.values.isoNumber}
                      name="isoNumber"
                      placeholder="ISO Number"
                      className="mainInput"
                      onChange={(e) =>
                        addCurrency.setFieldValue("isoNumber", e.target.value)
                      }
                    />
                    {addCurrency.touched.isoNumber &&
                    addCurrency.errors.isoNumber ? (
                      <p className="requiredText">
                        {addCurrency.errors.isoNumber}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Name:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCurrency.values.name}
                      name="name"
                      placeholder="Name"
                      className="mainInput"
                      onChange={(e) =>
                        addCurrency.setFieldValue("name", e.target.value)
                      }
                    />
                    {addCurrency.touched.name && addCurrency.errors.name ? (
                      <p className="requiredText">{addCurrency.errors.name}</p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "Create new conversion" ||
              title === "Edit Currency Conversion" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      From Currency:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="fromCurrency"
                      label="fromCurrency"
                      name="fromCurrency"
                      isClearable={true}
                      options={currencyData?.data?.data?.content || []}
                      value={currencyData?.data?.data?.content?.find(
                        (country) =>
                          country.code ===
                          addCurrencyconversion?.values?.fromCurrency
                      )}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.code}
                      handleChange={(selectedOption) =>
                        addCurrencyconversion.setFieldValue(
                          "fromCurrency",
                          selectedOption ? selectedOption.code : ""
                        )
                      }
                      isSearchable={true}
                      placeholder="From Currency"
                    />
                    {addCurrencyconversion.touched.fromCurrency &&
                    addCurrencyconversion.errors.fromCurrency ? (
                      <p className="requiredText">
                        {addCurrencyconversion.errors.fromCurrency}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      To Currency:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="toCurrency"
                      label="toCurrency"
                      name="toCurrency"
                      isClearable={true}
                      options={currencyData?.data?.data?.content || []}
                      value={currencyData?.data?.data?.content?.find(
                        (country) =>
                          country.code ===
                          addCurrencyconversion?.values?.toCurrency
                      )}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.code}
                      handleChange={(selectedOption) =>
                        addCurrencyconversion.setFieldValue(
                          "toCurrency",
                          selectedOption ? selectedOption.code : ""
                        )
                      }
                      isSearchable={true}
                      placeholder="To Currency"
                    />
                    {addCurrencyconversion.touched.toCurrency &&
                    addCurrencyconversion.errors.toCurrency ? (
                      <p className="requiredText">
                        {addCurrencyconversion.errors.toCurrency}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Rate:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addCurrencyconversion.values.rate}
                      name="rate"
                      placeholder="Rate"
                      className="mainInput"
                      onChange={(e) =>
                        addCurrencyconversion.setFieldValue(
                          "rate",
                          e.target.value
                        )
                      }
                    />
                    {addCurrencyconversion.touched.rate &&
                    addCurrencyconversion.errors.rate ? (
                      <p className="requiredText">
                        {addCurrencyconversion.errors.rate}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : title === "Add Pickup Address" ||
              title === "Edit Pickup Address" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Name:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addPickupAddres.values.name}
                      name="name"
                      placeholder="Name"
                      className="mainInput"
                      onChange={(e) =>
                        addPickupAddres.setFieldValue("name", e.target.value)
                      }
                    />
                    {addPickupAddres.touched.name &&
                    addPickupAddres.errors.name ? (
                      <p className="requiredText">
                        {addPickupAddres.errors.name}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Shipper Name:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addPickupAddres.values.shipperName}
                      name="shipperName"
                      placeholder="Shipper Name"
                      className="mainInput"
                      onChange={(e) =>
                        addPickupAddres.setFieldValue("shipperName", e.target.value)
                      }
                    />
                    {addPickupAddres.touched.shipperName &&
                    addPickupAddres.errors.shipperName ? (
                      <p className="requiredText">
                        {addPickupAddres.errors.shipperName}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row mt_20">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="countryCode3"
                      label="countryCode3"
                      name="countryCode3"
                      isClearable={true}
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(country) => country.name}
                      getOptionValue={(country) => country.isoCode3}
                      value={countriesData?.data?.data?.content?.find(
                              (country) =>
                                country.isoCode3 ===
                                addPickupAddres.values.countryCode3)}
                      handleChange={handleCountryChange}
                      isSearchable={true}
                      placeholder="Select Country"
                    />
                    {addPickupAddres.touched.countryCode3 &&
                    addPickupAddres.errors.countryCode3 ? (
                      <p className="requiredText">
                        {addPickupAddres.errors.countryCode3}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Short Address:
                    </label>
                    <input
                      type="text"
                      value={addPickupAddres.values.shortAddress}
                      name="shortAddress"
                      placeholder="Short Address"
                      className="mainInput"
                      onChange={(e) =>
                        addPickupAddres.setFieldValue(
                          "shortAddress",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="row mt_20">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Region:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="regionCode"
                      className="MainInput"
                      label="regionCode"
                      name="regionCode"
                      options={regionbyCode || []}
                      getOptionLabel={(region) => region.name}
                      getOptionValue={(region) => region.code}
                      handleChange={async(selectedOption) => {
                        addPickupAddres.setFieldValue(
                          "regionCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        const cityData = await getCitybyCode(
                          selectedOption?.countryIsoCode3,
                          selectedOption?.code
                        );
                      }}
                      value={
                        addPickupAddres.values.regionCode
                          ? regionbyCode?.find(
                              (region) =>
                                region.code ===
                                addPickupAddres.values.regionCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Region"
                    />
                    {addPickupAddres.touched.regionCode &&
                    addPickupAddres.errors.regionCode ? (
                      <p className="requiredText">
                        {addPickupAddres.errors.regionCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt_-10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12 marginR10 mt_10">
                      Phone Number:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="Grpinput">
                          <input
                            className="mainInput"
                            name="phonecodeprefix"
                            value={addPickupAddres.values.phonecodeprefix}
                            autoComplete="off"
                            onChange={(e) =>
                              addPickupAddres.setFieldValue(
                                "phonecodeprefix",
                                e.target.value
                              )
                            }
                            disabled={isdeliveryPhonecodePrefix}
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="Grpinput">
                          <input
                            type="text"
                            placeholder="Phone Number"
                            className="mainInput"
                            name="phoneNumber"
                            autoComplete="off"
                            value={addPickupAddres.values.phoneNumber}
                            onChange={(e) => {
                              addPickupAddres.setFieldValue(
                                "phoneNumber",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        {addPickupAddres.touched.phoneNumber &&
                        addPickupAddres.errors.phoneNumber  && phoneErr === "" ? (
                          <p className="requiredText">
                            {addPickupAddres.errors.phoneNumber}
                          </p>
                        ) : null}
                           {phoneErr !== "" && <p className="requiredText">{phoneErr}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt_20">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      City:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="cityCode"
                      label="cityCode"
                      name="cityCode"
                      options={citybyCode || []}
                      getOptionLabel={(city) => city.name}
                      getOptionValue={(city) => city.code}
                      value={
                        addPickupAddres.values.cityCode
                          ? citiesData?.data?.data?.content?.find(
                              (zone) =>
                                zone.code === addPickupAddres.values.cityCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) =>
                        handleCtyChangePickup(selectedOption, addPickupAddres)
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select City"
                    />
                    {addPickupAddres.touched.cityCode &&
                    addPickupAddres.errors.cityCode ? (
                      <p className="requiredText">
                        {addPickupAddres.errors.cityCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt_-10">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 menacingCloudsText fontWeight500 marginR10 mt_10">
                      Phone Number:
                    </label>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="Grpinput">
                          <input
                            className="mainInput"
                            name="phonecodeprefix"
                            value={addPickupAddres.values.phonecodeprefix}
                            autoComplete="off"
                            onChange={(e) =>
                              addPickupAddres.setFieldValue(
                                "phonecodeprefix",
                                e.target.value
                              )
                            }
                            disabled={isdeliveryPhonecodePrefix}
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="Grpinput">
                          <input
                            type="text"
                            placeholder="Alternate Phone Number"
                            className="mainInput"
                            name="alternativePhone"
                            autoComplete="off"
                            value={addPickupAddres.values.alternativePhone}
                            onChange={(e) => {
                              addPickupAddres.setFieldValue(
                                "alternativePhone",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        {AltphoneErr !== "" && <p className="requiredText">{AltphoneErr}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt_20">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      District:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="districtCode"
                      label="districtCode"
                      name="districtCode"
                      options={districtbyCode || []}
                      getOptionLabel={(district) => district.name}
                      getOptionValue={(district) => district.code}
                      value={
                        addPickupAddres.values.districtCode
                          ? districtDatas?.data?.data?.content?.find(
                              (district) =>
                                district.code ===
                                addPickupAddres.values.districtCode
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        addPickupAddres.setFieldValue(
                          "districtCode",
                          selectedOption ? selectedOption.code : ""
                        );
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select District"
                    />
                     {addPickupAddres.touched.districtCode &&
                    addPickupAddres.errors.districtCode ? (
                      <p className="requiredText">
                        {addPickupAddres.errors.districtCode}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Postal Code:
                    </label>
                    <input
                      type="text"
                      value={addPickupAddres.values.postalCode}
                      name="postalCode"
                      placeholder="Postal Code"
                      className="mainInput"
                      onChange={(e) =>
                        addPickupAddres.setFieldValue(
                          "postalCode",
                          e.target.value
                        )
                      }
                    />
                     {addPickupAddres.touched.postalCode &&
                    addPickupAddres.errors.postalCode ? (
                      <p className="requiredText">
                        {addPickupAddres.errors.postalCode}
                      </p>
                    ) : null}
                  </div>
                  </div>
                  <div className="row mt_20">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Address Line 1:
                      </label>
                      <input
                        type="text"
                        placeholder="Address Line 1"
                        className="mainInput"
                        name="addressLine1"
                        maxLength={100}
                        value={addPickupAddres.values.addressLine1}
                        onChange={(e) => {
                          addPickupAddres.setFieldValue(
                            "addressLine1",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="row mt_20">
                    <div className="col-md-12">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Address Line 2:
                      </label>
                      <input
                        type="text"
                        placeholder="Address Line 2"
                        className="mainInput"
                        name="addressLine2"
                        maxLength={100}
                        value={addPickupAddres.values.addressLine2}
                        onChange={(e) => {
                          addPickupAddres.setFieldValue(
                            "addressLine2",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="row mt_20">
                    <div className="col-md-3">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Latitude:
                    </label>
                      <input
                        type="text"
                        placeholder="Latitude"
                        className="mainInput"
                        name="latitude"
                        maxLength={100}
                        value={addPickupAddres.values.latitude}
                        onChange={(e) => {
                          addPickupAddres.setFieldValue(
                            "latitude",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-md-3">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Longitude:
                    </label>
                      <input
                        type="text"
                        placeholder="Longitude"
                        className="mainInput"
                        name="longitude"
                        maxLength={100}
                        value={addPickupAddres.values.longitude}
                        onChange={(e) => {
                          addPickupAddres.setFieldValue(
                            "longitude",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Email Address:
                    </label>
                      <input
                        type="text"
                        placeholder="Email address"
                        className="mainInput"
                        name="emailAddress"
                        maxLength={100}
                        value={addPickupAddres.values.emailAddress}
                        onChange={(e) => {
                          addPickupAddres.setFieldValue(
                            "emailAddress",
                            e.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                </div>
              </form>
            ) : title === "Create New NPR" || title === "Edit NPR" ? (
              <div className="row">
                <div className="col-md-5">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Code:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addPickupReasons.values.code}
                      name="code"
                      placeholder="Code"
                      className="mainInput"
                      onChange={(e) => {
                        addPickupReasons.setFieldValue("code", e.target.value);
                        SetnprCode("");
                      }}
                      disabled={title === "Edit NPR" ? true : false}
                    />
                    {addPickupReasons.touched.code &&
                    addPickupReasons.errors.code &&
                    nprcode === "" ? (
                      <p className="requiredText">
                        {addPickupReasons.errors.code}
                      </p>
                    ) : null}
                    {nprcode !== "" ? (
                      <p className="requiredText">{nprcode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt_10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Message:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addPickupReasons.values.message}
                      name="message"
                      placeholder="Message"
                      className="mainInput"
                      onChange={(e) => {
                        addPickupReasons.setFieldValue(
                          "message",
                          e.target.value
                        );
                        SetMessageCode("");
                      }}
                    />
                    {addPickupReasons.touched.message &&
                    addPickupReasons.errors.message &&
                    messagecode === "" ? (
                      <p className="requiredText">
                        {addPickupReasons.errors.message}
                      </p>
                    ) : null}
                    {messagecode !== "" ? (
                      <p className="requiredText">{messagecode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt_10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      External Message:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addPickupReasons.values.externalMessage}
                      name="externalMessage"
                      placeholder="External Message"
                      className="mainInput"
                      onChange={(e) => {
                        addPickupReasons.setFieldValue(
                          "externalMessage",
                          e.target.value
                        );
                        SetExternalMsgCode("");
                      }}
                    />
                    {addPickupReasons.touched.externalMessage &&
                    addPickupReasons.errors.externalMessage &&
                    extMessagecode === "" ? (
                      <p className="requiredText">
                        {addPickupReasons.errors.externalMessage}
                      </p>
                    ) : null}
                    {extMessagecode !== "" ? (
                      <p className="requiredText">{extMessagecode}</p>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt_10">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Event:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        id="events"
                        label="events"
                        name="events"
                        options={OpsEventData?.data?.data || []}
                        getOptionLabel={(dept) => dept.label}
                        getOptionValue={(dept) => dept.key}
                        handleChange={(selectedOption) =>
                          addPickupReasons.setFieldValue(
                            "events",
                            selectedOption ? selectedOption.key : ""
                          )
                        }
                        value={
                          addPickupReasons?.values?.events
                            ? OpsEventData?.data?.data?.find(
                                (model) =>
                                  model?.key ===
                                  addPickupReasons?.values?.events
                              )
                            : null
                        }
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Event"
                      />
                      {addPickupReasons.touched.events &&
                      addPickupReasons.errors.events ? (
                        <p className="requiredText">
                          {addPickupReasons.errors.events}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6 mt_10">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Category:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        id="category"
                        label="category"
                        name="category"
                        options={OpsCategoryData?.data?.data || []}
                        getOptionLabel={(dept) => dept.label}
                        getOptionValue={(dept) => dept.key}
                        handleChange={(selectedOption) =>
                          addPickupReasons.setFieldValue(
                            "category",
                            selectedOption ? selectedOption.key : ""
                          )
                        }
                        value={
                          addPickupReasons?.values?.category
                            ? OpsCategoryData?.data?.data?.find(
                                (model) =>
                                  model?.key ===
                                  addPickupReasons?.values?.category
                              )
                            : null
                        }
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Category"
                      />
                      {addPickupReasons.touched.category &&
                      addPickupReasons.errors.category ? (
                        <p className="requiredText">
                          {addPickupReasons.errors.category}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Require Reschedule"
                        style={{ marginLeft: "5px" }}
                        checked={addPickupReasons.values.requireReschedule}
                        onChange={() => {
                          addPickupReasons.setFieldValue(
                            "requireReschedule",
                            !addPickupReasons.values.requireReschedule
                          );
                          if (!addPickupReasons.values.requireReschedule) {
                            addPickupReasons.setFieldValue(
                              "requireReschedule",
                              true
                            ); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label
                        className="margintop7"
                        htmlFor="Require Reschedule"
                      >
                        Require Reschedule
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 mt_10">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Internal Only"
                        style={{ marginLeft: "5px" }}
                        checked={addPickupReasons.values.internal}
                        onChange={() => {
                          addPickupReasons.setFieldValue(
                            "internal",
                            !addPickupReasons.values.internal
                          );
                          if (!addPickupReasons.values.internal) {
                            addPickupReasons.setFieldValue("internal", true); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label className="margintop7" htmlFor="Internal Only">
                        Internal Only
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 mt_10">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Require address change"
                        style={{ marginLeft: "5px" }}
                        checked={addPickupReasons.values.requireAddressChange}
                        onChange={() => {
                          addPickupReasons.setFieldValue(
                            "requireAddressChange",
                            !addPickupReasons.values.requireAddressChange
                          );
                          if (!addPickupReasons.values.requireAddressChange) {
                            addPickupReasons.setFieldValue(
                              "requireAddressChange",
                              true
                            ); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label
                        className="margintop7"
                        htmlFor="Require address change"
                      >
                        Require address change
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 mt_10">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Require OTP"
                        style={{ marginLeft: "5px" }}
                        checked={addPickupReasons.values.requireOtp}
                        onChange={() => {
                          addPickupReasons.setFieldValue(
                            "requireOtp",
                            !addPickupReasons.values.requireOtp
                          );
                          if (!addPickupReasons.values.requireOtp) {
                            addPickupReasons.setFieldValue("requireOtp", true); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label className="margintop7" htmlFor="Require OTP">
                        Require OTP
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="borderFrame mb_16">
                    <div className="borderFrameTitle">Message Locals</div>
                    <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                      <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                      <SearchInput
                  key={resetKey}
                  id="firstValue"
                  label="firstValue"
                  name="firstValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                        <input
                          type="text"
                          placeholder="Message"
                          style={{ marginLeft: "15px" }}
                          value={secondValue}
                          onChange={(e) => setSecondValue(e.target.value)}
                          className="mainInput"
                        />
                      </div>
                      <button
                        className="filterBtn"
                        onClick={() => handleObjectAdd()}
                      >
                        +
                      </button>
                    </div>
                    <div className="table_wrapper mb_16 showFiveRows">
                      <table>
                        <thead>
                          <tr>
                            <th>Language</th>
                            <th>Message</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(locales).map((value, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{value}</td>
                                <td>{locales[value]}</td>
                                <td>
                                  <button
                                    className="cancel_btn"
                                    onClick={() => handleObjectDelete(value)}
                                  >
                                    <span>
                                      <img src={removeIcon} alt="Remove" />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="displayFlex alignItemCenter justifyContent_end mb_16"></div>
                  </div>
                  <div className="borderFrame mb_16">
                    <div className="borderFrameTitle">
                      External Message Locals
                    </div>
                    <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                      <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                         <SearchInput
                  key={resetKey}
                  id="firstextValue"
                  label="firstextValue"
                  name="firstextValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstextValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                        <input
                          type="text"
                          placeholder="Message"
                          style={{ marginLeft: "15px" }}
                          value={secondextValue}
                          onChange={(e) => setSecondextValue(e.target.value)}
                          className="mainInput"
                        />
                      </div>
                      <button
                        className="filterBtn"
                        onClick={() => handleObjectextAdd()}
                      >
                        +
                      </button>
                    </div>
                    <div className="table_wrapper mb_16 showFiveRows">
                      <table>
                        <thead>
                          <tr>
                            <th>Language</th>
                            <th>Message</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(extlocales).map((value, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{value}</td>
                                <td>{extlocales[value]}</td>
                                <td>
                                  <button
                                    className="cancel_btn"
                                    onClick={() => handleObjectextDelete(value)}
                                  >
                                    <span>
                                      <img src={removeIcon} alt="Remove" />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="displayFlex alignItemCenter justifyContent_end mb_16"></div>
                  </div>
                </div>
              </div>
            ) : title === "Create New Hold reason" ||
              title === "Edit Hold reason" ? (
              <div className="row">
                <div className="col-md-5">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Code:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addHoldReasons.values.code}
                      name="code"
                      placeholder="Code"
                      className="mainInput"
                      onChange={(e) => {
                        addHoldReasons.setFieldValue("code", e.target.value);
                        SetholdCode("");
                      }}
                      disabled={title === "Edit Hold reason" ? true : false}
                    />
                    {addHoldReasons.touched.code &&
                    addHoldReasons.errors.code &&
                    holdcode === "" ? (
                      <p className="requiredText">
                        {addHoldReasons.errors.code}
                      </p>
                    ) : null}
                    {holdcode !== "" ? (
                      <p className="requiredText">{holdcode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt_10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Message:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addHoldReasons.values.message}
                      name="message"
                      placeholder="Message"
                      className="mainInput"
                      onChange={(e) => {
                        addHoldReasons.setFieldValue("message", e.target.value);
                        SetMessageholdCode("");
                      }}
                    />
                    {addHoldReasons.touched.message &&
                    addHoldReasons.errors.message &&
                    messageholdcode === "" ? (
                      <p className="requiredText">
                        {addHoldReasons.errors.message}
                      </p>
                    ) : null}
                    {messageholdcode !== "" ? (
                      <p className="requiredText">{messageholdcode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt_10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      External Message:
                    </label>
                    <input
                      type="text"
                      value={addHoldReasons.values.externalMessage}
                      name="externalMessage"
                      placeholder="External Message"
                      className="mainInput"
                      onChange={(e) => {
                        addHoldReasons.setFieldValue(
                          "externalMessage",
                          e.target.value
                        );
                        SetExternalMsgHoldCode("");
                      }}
                    />
                    {addHoldReasons.touched.externalMessage &&
                    addHoldReasons.errors.externalMessage &&
                    extMessageHoldcode === "" ? (
                      <p className="requiredText">
                        {addHoldReasons.errors.externalMessage}
                      </p>
                    ) : null}
                    {extMessageHoldcode !== "" ? (
                      <p className="requiredText">{extMessageHoldcode}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="borderFrame mb_16">
                    <div className="borderFrameTitle">Message Locals</div>
                    <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                      <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                      <SearchInput
                  key={resetKey}
                  id="firstValue"
                  label="firstValue"
                  name="firstValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                        <input
                          type="text"
                          placeholder="Message"
                          style={{ marginLeft: "15px" }}
                          value={secondValue}
                          onChange={(e) => setSecondValue(e.target.value)}
                          className="mainInput"
                        />
                      </div>
                      <button
                        className="filterBtn"
                        onClick={() => handleObjectAdd()}
                      >
                        +
                      </button>
                    </div>
                    <div className="table_wrapper mb_16 showFiveRows">
                      <table>
                        <thead>
                          <tr>
                            <th>Language</th>
                            <th>Message</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(locales).map((value, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{value}</td>
                                <td>{locales[value]}</td>
                                <td>
                                  <button
                                    className="cancel_btn"
                                    onClick={() => handleObjectDelete(value)}
                                  >
                                    <span>
                                      <img src={removeIcon} alt="Remove" />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : title === "Create New NDR" || title === "Edit NDR" ? (
              <div className="row">
                <div className="col-md-5">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Code:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addDeliveryupReasons.values.code}
                      name="code"
                      placeholder="Code"
                      className="mainInput"
                      onChange={(e) => {
                        addDeliveryupReasons.setFieldValue(
                          "code",
                          e.target.value
                        );
                        SetndrCode("");
                      }}
                      disabled={title === "Edit NDR" ? true : false}
                    />
                    {addDeliveryupReasons.touched.code &&
                    addDeliveryupReasons.errors.code &&
                    ndrcode === "" ? (
                      <p className="requiredText">
                        {addDeliveryupReasons.errors.code}
                      </p>
                    ) : null}
                    {ndrcode !== "" ? (
                      <p className="requiredText">{ndrcode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt_10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Message:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addDeliveryupReasons.values.message}
                      name="message"
                      placeholder="Message"
                      className="mainInput"
                      onChange={(e) => {
                        addDeliveryupReasons.setFieldValue(
                          "message",
                          e.target.value
                        );
                        SetMessageNdrCode("");
                      }}
                    />
                    {addDeliveryupReasons.touched.message &&
                    addDeliveryupReasons.errors.message &&
                    messagendrcode === "" ? (
                      <p className="requiredText">
                        {addDeliveryupReasons.errors.message}
                      </p>
                    ) : null}
                    {messagendrcode !== "" ? (
                      <p className="requiredText">{messagendrcode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt_10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      External Message:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addDeliveryupReasons.values.externalMessage}
                      name="externalMessage"
                      placeholder="External Message"
                      className="mainInput"
                      onChange={(e) => {
                        addDeliveryupReasons.setFieldValue(
                          "externalMessage",
                          e.target.value
                        );
                        SetExternalMsgNdrCode("");
                      }}
                    />
                    {addDeliveryupReasons.touched.externalMessage &&
                    addDeliveryupReasons.errors.externalMessage &&
                    extMessagendrcode === "" ? (
                      <p className="requiredText">
                        {addDeliveryupReasons.errors.externalMessage}
                      </p>
                    ) : null}
                    {extMessagendrcode !== "" ? (
                      <p className="requiredText">{extMessagendrcode}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt_10">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Event:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <SearchInput
                      id="events"
                      label="events"
                      name="events"
                      options={OpsDeliveryEventData?.data?.data || []}
                      getOptionLabel={(dept) => dept.label}
                      getOptionValue={(dept) => dept.key}
                      handleChange={(selectedOption) =>
                        addDeliveryupReasons.setFieldValue(
                          "events",
                          selectedOption ? selectedOption.key : ""
                        )
                      }
                      value={
                        addDeliveryupReasons?.values?.events
                          ? OpsDeliveryEventData?.data?.data?.find(
                              (model) =>
                                model?.key ===
                                addDeliveryupReasons?.values?.events
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Event"
                    />
                    {addDeliveryupReasons.touched.events &&
                    addDeliveryupReasons.errors.events ? (
                      <p className="requiredText">
                        {addDeliveryupReasons.errors.events}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Require Reschedule"
                        style={{ marginLeft: "5px" }}
                        checked={addDeliveryupReasons.values.requireReschedule}
                        onChange={() => {
                          addDeliveryupReasons.setFieldValue(
                            "requireReschedule",
                            !addDeliveryupReasons.values.requireReschedule
                          );
                          if (!addDeliveryupReasons.values.requireReschedule) {
                            addDeliveryupReasons.setFieldValue(
                              "requireReschedule",
                              true
                            ); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label
                        className="margintop7"
                        htmlFor="Require Reschedule"
                      >
                        Require Reschedule
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 mt_10">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Internal Only"
                        style={{ marginLeft: "5px" }}
                        checked={addDeliveryupReasons.values.internal}
                        onChange={() => {
                          addDeliveryupReasons.setFieldValue(
                            "internal",
                            !addDeliveryupReasons.values.internal
                          );
                          if (!addDeliveryupReasons.values.internal) {
                            addDeliveryupReasons.setFieldValue(
                              "internal",
                              true
                            ); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label className="margintop7" htmlFor="Internal Only">
                        Internal Only
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 mt_10">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Require address change"
                        style={{ marginLeft: "5px" }}
                        checked={
                          addDeliveryupReasons.values.requireAddressChange
                        }
                        onChange={() => {
                          addDeliveryupReasons.setFieldValue(
                            "requireAddressChange",
                            !addDeliveryupReasons.values.requireAddressChange
                          );
                          if (
                            !addDeliveryupReasons.values.requireAddressChange
                          ) {
                            addDeliveryupReasons.setFieldValue(
                              "requireAddressChange",
                              true
                            ); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label
                        className="margintop7"
                        htmlFor="Require address change"
                      >
                        Require address change
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 mt_10">
                    <div className="smallCheckbox">
                      <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                      <input
                        type="checkbox"
                        id="Require OTP"
                        style={{ marginLeft: "5px" }}
                        checked={addDeliveryupReasons.values.requireOtp}
                        onChange={() => {
                          addDeliveryupReasons.setFieldValue(
                            "requireOtp",
                            !addDeliveryupReasons.values.requireOtp
                          );
                          if (!addDeliveryupReasons.values.requireOtp) {
                            addDeliveryupReasons.setFieldValue(
                              "requireOtp",
                              true
                            ); // Set bagNumber to an empty string
                          }
                        }}
                      />
                      <label className="margintop7" htmlFor="Require OTP">
                        Require OTP
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="smallCheckbox">
                        <div className="fontSize14 menacingCloudsText fontWeight500 mt_8">
                          <input
                            type="checkbox"
                            id="requireGeolocationValidation"
                            style={{ marginLeft: "5px" }}
                            checked={
                              addDeliveryupReasons.values
                                .requireGeolocationValidation
                            }
                            onChange={() => {
                              setNdrDistance("");
                              addDeliveryupReasons.setFieldValue(
                                "requireGeolocationValidation",
                                !addDeliveryupReasons.values
                                  .requireGeolocationValidation
                              );
                              if (
                                !addDeliveryupReasons.values
                                  .requireGeolocationValidation
                              ) {
                                addDeliveryupReasons.setFieldValue(
                                  "requireGeolocationValidation",
                                  true
                                );
                              }
                            }}
                          />
                          <label
                            className="margintop7"
                            htmlFor="requireGeolocationValidation"
                          >
                            Require geolocation validation
                          </label>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                      <div className="fontSize14 menacingCloudsText fontWeight500 mt_8">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Distance:
                    </label>
                        <input
                          type="text"
                          value={addDeliveryupReasons.values.distance}
                          name="distance"
                          placeholder="Distance"
                          className="mainInput"
                          onChange={(e) => {
                            addDeliveryupReasons.setFieldValue(
                              "distance",
                              e.target.value
                            );
                            setNdrDistance("");
                          }}
                        />
                      </div>
                      {ndrDistance !== "" ? (
                        <p className="requiredText">{ndrDistance}</p>
                      ) : null}
                    </div>
                    </div>
                </div>
                <div className="col-md-7">
                  <div className="borderFrame mb_16">
                    <div className="borderFrameTitle">Message Locals</div>
                    <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                      <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                      <SearchInput
                  key={resetKey}
                  id="firstValue"
                  label="firstValue"
                  name="firstValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                        <input
                          type="text"
                          placeholder="Message"
                          style={{ marginLeft: "15px" }}
                          value={secondValue}
                          onChange={(e) => setSecondValue(e.target.value)}
                          className="mainInput"
                        />
                      </div>
                      <button
                        className="filterBtn"
                        onClick={() => handleObjectAdd()}
                      >
                        +
                      </button>
                    </div>
                    <div className="table_wrapper mb_16 showFiveRows">
                      <table>
                        <thead>
                          <tr>
                            <th>Language</th>
                            <th>Message</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(locales).map((value, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{value}</td>
                                <td>{locales[value]}</td>
                                <td>
                                  <button
                                    className="cancel_btn"
                                    onClick={() => handleObjectDelete(value)}
                                  >
                                    <span>
                                      <img src={removeIcon} alt="Remove" />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="displayFlex alignItemCenter justifyContent_end mb_16"></div>
                  </div>
                  <div className="borderFrame mb_16">
                    <div className="borderFrameTitle">
                      External Message Locals
                    </div>
                    <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                      <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                          <SearchInput
                  key={resetKey}
                  id="firstextValue"
                  label="firstextValue"
                  name="firstextValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstextValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                        <input
                          type="text"
                          placeholder="Message"
                          style={{ marginLeft: "15px" }}
                          value={secondextValue}
                          onChange={(e) => setSecondextValue(e.target.value)}
                          className="mainInput"
                        />
                      </div>
                      <button
                        className="filterBtn"
                        onClick={() => handleObjectextAdd()}
                      >
                        +
                      </button>
                    </div>
                    <div className="table_wrapper mb_16 showFiveRows">
                      <table>
                        <thead>
                          <tr>
                            <th>Language</th>
                            <th>Message</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(extlocales).map((value, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{value}</td>
                                <td>{extlocales[value]}</td>
                                <td>
                                  <button
                                    className="cancel_btn"
                                    onClick={() => handleObjectextDelete(value)}
                                  >
                                    <span>
                                      <img src={removeIcon} alt="Remove" />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="displayFlex alignItemCenter justifyContent_end mb_16"></div>
                  </div>
                </div>
              </div>
            ) : title === "Create Invoice Request" ? (
              <form onSubmit={addInvoice.handleSubmit}>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-3">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Country:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        id="billingCountry"
                        label="Country"
                        name="billingCountry"
                        isClearable={true}
                        value={
                          addInvoice?.values?.billingCountry
                            ? countriesData?.data?.data?.content?.find(
                                (country) =>
                                  country.isoCode3 ===
                                  addInvoice?.values?.billingCountry
                              )
                            : null
                        }
                        options={countriesData?.data?.data?.content || []}
                        getOptionLabel={(country) => country.name}
                        getOptionValue={(country) => country.isoCode3}
                        handleChange={(selectedOption) => {
                          addInvoice.setFieldValue(
                            "billingCountry",
                            selectedOption ? selectedOption.isoCode3 : ""
                          );
                          // Make API call here
                          const params = `?page=${newPage}&size=${invoicesize}&allowInvoice=true&sortBy=${sortField}&country=${
                            selectedOption ? selectedOption.isoCode3 : ""}`;
                          getCustomer(params);
                          setAddedCustomers([]);
                        }}
                        isSearchable={true}
                        placeholder="Billing Country"
                      />
                      {addInvoice.touched.billingCountry &&
                      addInvoice.errors.billingCountry ? (
                        <p className="requiredText">
                          {addInvoice.errors.billingCountry}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-3">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        From Date:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div
                        className="customDateRange"
                        style={{ color: "black", position: "relative" }}
                      >
                        <DatePicker
                          placeholderText="From Date"
                          placement="rightStart"
                          selected={addInvoice.values.fromDate}
                          onChange={(date) => {
                            addInvoice.setFieldValue("fromDate", date);
                          }}
                          onClean={() =>
                            addInvoice.setFieldValue("fromDate", null)
                          }
                          className="custom-date-picker"
                          showYearDropdown
                        />
                        {addInvoice.touched.fromDate &&
                        addInvoice.errors.fromDate ? (
                          <p
                            className="requiredText"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              marginTop: "37px",
                            }}
                          >
                            {addInvoice.errors.fromDate}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        To Date:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <div
                        className="customDateRange"
                        style={{ color: "black", position: "relative" }}
                      >
                        <DatePicker
                          placeholderText="To Date"
                          placement="rightStart"
                          selected={addInvoice.values.toDate}
                          onChange={(date) => {
                            addInvoice.setFieldValue("toDate", date);
                          }}
                          onClean={() =>
                            addInvoice.setFieldValue("toDate", null)
                          }
                          className="custom-date-picker"
                          showYearDropdown
                        />
                        {addInvoice.touched.toDate &&
                        addInvoice.errors.toDate ? (
                          <p
                            className="requiredText"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              marginTop: "37px",
                            }}
                          >
                            {addInvoice.errors.toDate}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="col-md-3 mt-2">
                      <button
                        className="blue_btn float-md-end"
                        title="Load from template"
                        type="button"
                        onClick={() => setShow(true)}
                      >
                        <span>Load from template</span>
                      </button>
                    </div> */}
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-3">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Statuses:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        id="operationCode"
                        label="Status"
                        name="operationCode"
                        isClearable={true}
                        options={operationCodes?.data?.data || []}
                        getOptionLabel={(code) => code.key}
                        getOptionValue={(code) => code.key}
                        value={
                          addInvoice?.values?.operationCode
                            ? operationCodes?.data?.data?.filter((cnt) =>
                                addInvoice?.values?.operationCode.includes(
                                  cnt.key
                                )
                              )
                            : null
                        }
                        isSearchable={true}
                        isMulti={true}
                        placeholder="Select Status"
                        handleChange={(selectedOptions) => {
                          const selectedKeys = selectedOptions
                            ? selectedOptions.map((option) => option.key)
                            : [];
                          addInvoice.setFieldValue(
                            "operationCode",
                            selectedKeys
                          );
                        }}
                      />
                      {addInvoice.touched.operationCode &&
                      addInvoice.errors.operationCode ? (
                        <p className="requiredText">
                          {addInvoice.errors.operationCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-5">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Search Customer:
                      </label>
                      <input
                        type="text"
                        placeholder="Search"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                      />
                      <div className="customerContainer">
                        {customerData?.data?.data?.content
                          ?.filter((customer) =>
                            name
                              ? customer.name
                                  .toLowerCase()
                                  .includes(name.toLowerCase())
                              : true
                          )
                          .map((customer) => (
                            <div
                              key={customer.id}
                              // className={`customerContainerItem ${selectedCustomers.some((selectedCustomer) => selectedCustomer?.accountNumber === customer?.accountNumber) ? 'selected' : ''}`}
                              className={`customerContainerItem ${
                                isAddedClick
                                  ? ""
                                  : selectedCustomers.some(
                                      (selectedCustomer) =>
                                        selectedCustomer?.accountNumber ===
                                        customer?.accountNumber
                                    )
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleCusSelection(customer)}
                            >
                              {customer.name}
                            </div>
                          ))}
                        {/* Empty div to ensure customerContainer is always rendered */}
                        {!customerData?.data?.data?.content?.length &&
                          addInvoice.values.billingCountry === "" && (
                            <div className="customerContainerItem">
                              Select Billing country to view customers.
                            </div>
                          )}
                        {!customerData?.data?.data?.content?.length &&
                          addInvoice.values.billingCountry !== "" && (
                            <div className="customerContainerItem">
                              No customers found for selected billing country.
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-2 text-center mt_70">
                      <div className="mb-2">
                        <button
                          type="button"
                          className="cancelinv_btn"
                          onClick={handleAddAll}
                        >
                          Add All
                        </button>
                        <img loading="rightarrow" src={rightIcon} />
                      </div>
                      <div className="mb-2">
                        <button
                          type="button"
                          className="cancelinv_btn"
                          onClick={handleAdd}
                        >
                          Add
                        </button>
                        <img loading="rightarrow" src={rightIcon} />
                      </div>
                      <div className="mb-2">
                        <img loading="rightarrow" src={leftIcon} />
                        <button
                          className="cancelinv_btn"
                          type="button"
                          onClick={handleRemove}
                        >
                          Remove
                        </button>
                      </div>
                      <div className="mb-2">
                        <img loading="rightarrow" src={leftIcon} />
                        <button
                          className="cancelinv_btn"
                          type="button"
                          onClick={handleRemoveAll}
                        >
                          Remove All
                        </button>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Search Customer:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Search"
                        value={addedName}
                        onChange={(e) => setAddedName(e.target.value)}
                        className={
                          addedName?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          addedName?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                      />
                      <div className="customerContainer">
                        {/* {addedCustomers?.map((customer) => ( */}
                        {addedCustomers
                          ?.filter((customer) =>
                            addedName
                              ? customer.name
                                  .toLowerCase()
                                  .includes(addedName?.toLowerCase())
                              : true
                          )
                          .map((customer) => (
                            <div
                              key={customer.accountNumber}
                              className={`customerContainerItem ${
                                selectedCustomers.some(
                                  (selectedCustomer) =>
                                    selectedCustomer.accountNumber ===
                                    customer.accountNumber
                                )
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleCustomerClick(customer)}
                            >
                              {isaddAll ? customer : customer.name}
                            </div>
                          ))}
                        {/* Empty div to ensure customerContainer is always rendered */}
                        {!addedCustomers.length && (
                          <div className="customerContainerItem">
                            No customers Added.
                          </div>
                        )}
                        {addInvoice.touched.customerAccounts &&
                        addInvoice.errors.customerAccounts ? (
                          <p className="requiredText">
                            {addInvoice.errors.customerAccounts}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create Invoice Request Templates" ? (
              <form onSubmit={addInvoiceTemplate.handleSubmit}>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Name:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Name"
                        value={addInvoiceTemplate.values.name}
                        name="name"
                        onChange={(e) =>
                          addInvoiceTemplate.setFieldValue(
                            "name",
                            e.target.value
                          )
                        }
                      />
                      {addInvoiceTemplate.touched.name &&
                      addInvoiceTemplate.errors.name ? (
                        <p className="requiredText">
                          {addInvoiceTemplate.errors.name}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Country:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        id="billingCountry"
                        label="Country"
                        name="billingCountry"
                        isClearable={true}
                        options={countriesData?.data?.data?.content || []}
                        getOptionLabel={(country) => country.name}
                        getOptionValue={(country) => country.isoCode3}
                        handleChange={(selectedOption) => {
                          addInvoiceTemplate.setFieldValue(
                            "billingCountry",
                            selectedOption ? selectedOption.isoCode3 : ""
                          );
                          // Make API call here
                          const params = `?page=${newPage}&size=${invoicesize}&sortBy=${sortField}&country=${
                            selectedOption ? selectedOption.isoCode3 : ""
                          }`;
                          getCustomer(params);
                          setAddedCustomers([]);
                        }}
                        isSearchable={true}
                        placeholder="Billing Country"
                      />
                      {addInvoiceTemplate.touched.billingCountry &&
                      addInvoiceTemplate.errors.billingCountry ? (
                        <p className="requiredText">
                          {addInvoiceTemplate.errors.billingCountry}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Statuses:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <SearchInput
                        id="operationCode"
                        label="Status"
                        name="operationCode"
                        isClearable={true}
                        options={operationCodes?.data?.data || []}
                        getOptionLabel={(code) => code.key}
                        getOptionValue={(code) => code.key}
                        isSearchable={true}
                        isMulti={true}
                        placeholder="Select Status"
                        handleChange={(selectedOptions) => {
                          const selectedKeys = selectedOptions
                            ? selectedOptions.map((option) => option.key)
                            : [];
                          addInvoiceTemplate.setFieldValue(
                            "operationCode",
                            selectedKeys
                          );
                        }}
                      />
                      {addInvoiceTemplate.touched.operationCode &&
                      addInvoiceTemplate.errors.operationCode ? (
                        <p className="requiredText">
                          {addInvoiceTemplate.errors.operationCode}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-5">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Search Customer:
                      </label>
                      <input
                        type="text"
                        placeholder="Search"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                      />
                      <div className="customerContainer">
                        {customerData?.data?.data?.content
                          ?.filter((customer) =>
                            name
                              ? customer.name
                                  .toLowerCase()
                                  .includes(name.toLowerCase())
                              : true
                          )
                          .map((customer) => (
                            <div
                              key={customer.id}
                              className={`customerContainerItem ${
                                isAddedClick
                                  ? ""
                                  : selectedCustomers.some(
                                      (selectedCustomer) =>
                                        selectedCustomer?.accountNumber ===
                                        customer?.accountNumber
                                    )
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleCusSelection(customer)}
                            >
                              {customer.name}
                            </div>
                          ))}
                        {/* Empty div to ensure customerContainer is always rendered */}
                        {!customerData?.data?.data?.content?.length &&
                          addInvoiceTemplate.values.billingCountry === "" && (
                            <div className="customerContainerItem">
                              Select Billing country to view customers.
                            </div>
                          )}
                        {!customerData?.data?.data?.content?.length &&
                          addInvoiceTemplate.values.billingCountry !== "" && (
                            <div className="customerContainerItem">
                              No customers found for selected billing country.
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-2 text-center mt_70">
                      <div className="mb-2">
                        <button
                          className="cancelinv_btn"
                          type="button"
                          onClick={handleAddAll}
                        >
                          Add All
                        </button>
                        <img loading="rightarrow" src={rightIcon} />
                      </div>
                      <div className="mb-2">
                        <button
                          className="cancelinv_btn"
                          type="button"
                          onClick={handleAddTemplate}
                        >
                          Add
                        </button>
                        <img loading="rightarrow" src={rightIcon} />
                      </div>
                      <div className="mb-2">
                        <img loading="rightarrow" src={leftIcon} />
                        <button
                          className="cancelinv_btn"
                          onClick={handleRemove}
                          type="button"
                        >
                          Remove
                        </button>
                      </div>
                      <div className="mb-2">
                        <img loading="rightarrow" src={leftIcon} />
                        <button
                          className="cancelinv_btn"
                          onClick={handleRemoveAll}
                          type="button"
                        >
                          Remove All
                        </button>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Search Customer:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Search"
                        value={addedName}
                        onChange={(e) => setAddedName(e.target.value)}
                        className={
                          addedName?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          addedName?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                      />
                      <div className="customerContainer">
                        {addedCustomers
                          ?.filter((customer) =>
                            addedName
                              ? customer.name
                                  .toLowerCase()
                                  .includes(addedName?.toLowerCase())
                              : true
                          )
                          .map((customer) => (
                            <div
                              key={customer.accountNumber}
                              className={`customerContainerItem ${
                                selectedCustomers.some(
                                  (selectedCustomer) =>
                                    selectedCustomer.accountNumber ===
                                    customer.accountNumber
                                )
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleCustomerClick(customer)}
                            >
                              {isaddAll ? customer : customer.name}
                            </div>
                          ))}
                        {/* Empty div to ensure customerContainer is always rendered */}
                        {!addedCustomers.length && (
                          <div className="customerContainerItem">
                            No customers Added.
                          </div>
                        )}
                        {addInvoiceTemplate.touched.customerAccounts &&
                        addInvoiceTemplate.errors.customerAccounts ? (
                          <p className="requiredText">
                            {addInvoiceTemplate.errors.customerAccounts}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Add Waybill" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Waybill Number
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addWaybill.values.waybillNumber}
                      name="waybillNumber"
                      placeholder="Waybill Number"
                      className="mainInput"
                      onChange={(e) =>
                        addWaybill.setFieldValue(
                          "waybillNumber",
                          e.target.value
                        )
                      }
                    />
                    {addWaybill.touched.waybillNumber &&
                    addWaybill.errors.waybillNumber ? (
                      <p className="requiredText">
                        {addWaybill.errors.waybillNumber}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Weight
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addWaybill.values.weight}
                      name="weight"
                      placeholder="Weight"
                      className="mainInput"
                      onChange={(e) =>
                        addWaybill.setFieldValue("weight", e.target.value)
                      }
                    />
                    {addWaybill.touched.weight && addWaybill.errors.weight ? (
                      <p className="requiredText">{addWaybill.errors.weight}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Length
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addWaybill.values.length}
                      name="length"
                      placeholder="Length"
                      className="mainInput"
                      onChange={(e) =>
                        addWaybill.setFieldValue("length", e.target.value)
                      }
                    />
                    {addWaybill.touched.length && addWaybill.errors.length ? (
                      <p className="requiredText">{addWaybill.errors.length}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Width
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addWaybill.values.width}
                      name="width"
                      placeholder="Width"
                      className="mainInput"
                      onChange={(e) =>
                        addWaybill.setFieldValue("width", e.target.value)
                      }
                    />
                    {addWaybill.touched.width && addWaybill.errors.width ? (
                      <p className="requiredText">{addWaybill.errors.width}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Height
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addWaybill.values.height}
                      name="height"
                      placeholder="Height"
                      className="mainInput"
                      onChange={(e) =>
                        addWaybill.setFieldValue("height", e.target.value)
                      }
                    />
                    {addWaybill.touched.height && addWaybill.errors.height ? (
                      <p className="requiredText">{addWaybill.errors.height}</p>
                    ) : null}
                  </div>
                  {/* <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Receive Time
                  </label>
                  <div className="customDateRange" style={{ color: "black"}}>
                                <DatePicker
                                    placeholder="Receive Time"
                                    placement="rightStart"
                                    onChange={(date)=>addWaybill.setFieldValue("scantime",date)}
                                    onClean={()=>addWaybill.setFieldValue("scantime",null)}
                                    value={addWaybill.values.scantime}
                                />
                            </div>
                            {addWaybill.touched.scantime &&
                                addWaybill.errors.scantime ? (
                                    <p className="requiredText">
                                {addWaybill.errors.scantime}
                            </p>
                            ) : null}
                </div> */}
                </div>
                {/* <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Drop Time
                  </label>
                  <div className="customDateRange" style={{ color: "black", maxHeight: "300px", overflow: "auto"}}>
                                <DatePicker
                                    placeholder="Drop Time"
                                    placement="rightStart"
                                    onChange={(date)=>addWaybill.setFieldValue("droptime",date)}
                                    onClean={()=>addWaybill.setFieldValue("droptime",null)}
                                    value={addWaybill.values.droptime}
                                />
                            </div>
                            {addWaybill.touched.droptime &&
                                addWaybill.errors.droptime ? (
                                    <p className="requiredText">
                                {addWaybill.errors.droptime}
                            </p>
                            ) : null}
                </div>
              </div> */}
              </form>
            ) : title === "Add Employee" || title === "Edit Employee" ? (
              <form>
                <div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        User Id:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="User Id"
                        name="userId"
                        onChange={(e) =>
                          editEmployee.setFieldValue("userId", e.target.value)
                        }
                        onBlur={editEmployee.handleBlur}
                        value={editEmployee.values.userId}
                        disabled={
                          !(saveHandler || title == "Add Employee") ?? true
                        }
                      />
                      {editEmployee.touched.userId &&
                      editEmployee.errors.userId ? (
                        <p className="requiredText">
                          {editEmployee.errors.userId}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Name:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Name"
                        name="name"
                        onChange={(e) =>
                          editEmployee.setFieldValue("name", e.target.value)
                        }
                        onBlur={editEmployee.handleBlur}
                        value={editEmployee.values.name}
                        disabled={
                          !(saveHandler || title == "Add Employee") ?? true
                        }
                      />
                      {editEmployee.touched.name && editEmployee.errors.name ? (
                        <p className="requiredText">
                          {editEmployee.errors.name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Email:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Email"
                        name="email"
                        onChange={(e) =>
                          editEmployee.setFieldValue("email", e.target.value)
                        }
                        onBlur={editEmployee.handleBlur}
                        value={editEmployee.values.email}
                        disabled={
                          !(saveHandler || title == "Add Employee") ?? true
                        }
                      />
                      {editEmployee.touched.email &&
                      editEmployee.errors.email ? (
                        <p className="requiredText">
                          {editEmployee.errors.email}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Department:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Department"
                        name="department"
                        onClick={() => setDropdownOne(!dropdownOne)}
                        onBlur={editEmployee.handleBlur}
                        value={editEmployee.values.department}
                        readOnly
                        disabled={
                          !(saveHandler || title == "Add Employee") ?? true
                        }
                      />
                      {dropdownOne && (
                        <div className="dropdownResult">
                          {department.map((e, i) => {
                            return (
                              <div
                                key={i}
                                className="dropdownResultItem"
                                onClick={() => {
                                  (saveHandler || title == "Add Employee") &&
                                    setDropdownOne(!dropdownOne);
                                  editEmployee.setFieldValue("department", e);
                                }}
                              >
                                {e}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {editEmployee.touched.department &&
                      editEmployee.errors.department ? (
                        <p className="requiredText">
                          {editEmployee.errors.department}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-12 smallCheckbox">
                      <input
                        type="checkbox"
                        id="newstatus"
                        checked={editEmployee.values.status}
                      />
                      <label
                        htmlFor="newstatus"
                        onClick={() =>
                          (saveHandler || title == "Add Employee") &&
                          editEmployee.setFieldValue(
                            "status",
                            !editEmployee.values.status
                          )
                        }
                      >
                        {editEmployee.values.status ? "Active" : "Inactive"}
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            ) : title == "Sub Accounts" ? (
              <form>
                <div>
                  <p className="fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline">
                    Account Details
                  </p>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Account Number:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Account Number"
                        name="subAccountNo"
                        onChange={(e) =>
                          updateSubAccount.setFieldValue(
                            "subAccountNo",
                            e.target.value
                          )
                        }
                        onBlur={updateSubAccount.handleBlur}
                        value={updateSubAccount.values.subAccountNo}
                        readOnly
                      />
                      {updateSubAccount.touched.subAccountNo &&
                      updateSubAccount.errors.subAccountNo ? (
                        <p className="requiredText">
                          {updateSubAccount.errors.subAccountNo}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Account Name:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Account Name"
                        name="subAccountName"
                        onChange={(e) =>
                          updateSubAccount.setFieldValue(
                            "subAccountName",
                            e.target.value
                          )
                        }
                        onBlur={updateSubAccount.handleBlur}
                        value={updateSubAccount.values.subAccountName}
                        disabled={!saveHandler}
                      />
                      {updateSubAccount.touched.subAccountName &&
                      updateSubAccount.errors.subAccountName ? (
                        <p className="requiredText">
                          {updateSubAccount.errors.subAccountName}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Business Line:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn seletDropdownIcOn"
                        placeholder="Business Lien"
                        name="bussinessLine"
                        // onChange={(e)=> editZidProduct.setFieldValue("storeId",e.target.value.replace(/[^0-9]/g, ""))}
                        onClick={() =>
                          saveHandler && setDropdownOne(!dropdownOne)
                        }
                        onBlur={updateSubAccount.handleBlur}
                        value={updateSubAccount.values.bussinessLine}
                      />
                      {dropdownOne && (
                        <div className="dropdownResult">
                          {bussinessLine.map((e, i) => {
                            return (
                              <div
                                key={i}
                                className="dropdownResultItem"
                                onClick={() => {
                                  setDropdownOne(!dropdownOne);
                                  updateSubAccount.setFieldValue(
                                    "bussinessLine",
                                    e
                                  );
                                }}
                              >
                                {e}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {addRole.touched.app && addRole.errors.app ? (
                        <p className="requiredText">{addRole.errors.app}</p>
                      ) : null}
                    </div>
                  </div>
                  <p className="fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline">
                    Customer Details
                  </p>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Country:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Country"
                        name="country"
                        onChange={(e) =>
                          customerAddress.setFieldValue(
                            "country",
                            e.target.value
                          )
                        }
                        value={customerAddress.values.country}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        City:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="City"
                        name="city"
                        onChange={(e) =>
                          customerAddress.setFieldValue("city", e.target.value)
                        }
                        value={customerAddress.values.city}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Postal Code:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Postal Code"
                        name="postalCode"
                        onChange={(e) =>
                          customerAddress.setFieldValue(
                            "postalCode",
                            e.target.value
                          )
                        }
                        value={customerAddress.values.postalCode}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Street:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Street"
                        name="addressStreet"
                        onChange={(e) =>
                          customerAddress.setFieldValue(
                            "addressStreet",
                            e.target.value
                          )
                        }
                        value={customerAddress.values.addressStreet}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Mobile:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Mobile"
                        name="mobile"
                        onChange={(e) =>
                          customerAddress.setFieldValue(
                            "mobile",
                            e.target.value
                          )
                        }
                        value={customerAddress.values.mobile}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Phone:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Phone"
                        name="phone"
                        onChange={(e) =>
                          customerAddress.setFieldValue("phone", e.target.value)
                        }
                        value={customerAddress.values.phone}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Email:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Email"
                        name="email"
                        onChange={(e) =>
                          customerAddress.setFieldValue("email", e.target.value)
                        }
                        value={customerAddress.values.email}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Fax:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Fax"
                        name="fax"
                        onChange={(e) =>
                          customerAddress.setFieldValue("fax", e.target.value)
                        }
                        value={customerAddress.values.fax}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  {/* BILLING ADDRESS */}
                  <p className="fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline">
                    Billing Details
                  </p>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Contact Name:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Contact Name"
                        name="billingContactName"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingContactName",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingContactName}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Contact Phone:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Contact Phone"
                        name="billingContactPhone"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingContactPhone",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingContactPhone}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Country:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Country"
                        name="billingCountry"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingCountry",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingCountry}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing City:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="City"
                        name="billingCity"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingCity",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingCity}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Postal Code:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Postal Code"
                        name="billingPostal"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingPostal",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingPostal}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Street:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Street"
                        name="billingAddrsStreet"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingAddrsStreet",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingAddrsStreet}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing E-Invoice Email:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Invoice Email"
                        name="billingEInvoiceEmail"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingEInvoiceEmail",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingEInvoiceEmail}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Phone:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Phone"
                        name="billingPhone"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingPhone",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingPhone}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Email:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Email"
                        name="billingEmail"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingEmail",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingEmail}
                        disabled={!saveHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Billing Fax:
                      </label>
                      <input
                        type="text"
                        className="washMe_btn"
                        placeholder="Fax"
                        name="billingFax"
                        onChange={(e) =>
                          billingAddress.setFieldValue(
                            "billingFax",
                            e.target.value
                          )
                        }
                        value={billingAddress.values.billingFax}
                        disabled={!saveHandler}
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : title === "Create New Zone" || title === "Edit Zone" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Zone Group:
                    </label>
                    {showZonegroup ? (
                      <AsyncSearchInput
                        id="groupCode"
                        label="groupCode"
                        name="groupCode"
                        value={selectedOption}
                        loadMoreOptions={loadMoreOptionsGroups}
                        shouldLoadMore={shouldLoadMore}
                        getOptionLabel={(zone) => zone.label}
                        getOptionValue={(zone) => zone.value}
                        onChange={(selectedOption) => {
                          setSelectedOption(selectedOption);
                          addZone.setFieldValue(
                            "groupCode",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        menuStyle={{ zIndex: 9999 }}
                        placeholder="Select Zone Group"
                      />
                    ) : (
                      <input
                        type="text"
                        value={data?.groupCode}
                        name="productCode"
                        className="mainInput"
                        onClick={handleInputZonegrpClick}
                      />
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Zone Code:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addZone.values.code}
                      name="code"
                      placeholder="Zone Code"
                      className="mainInput"
                      onChange={(e) =>
                        addZone.setFieldValue("code", e.target.value)
                      }
                      disabled={title === "Edit Zone" ? true : false}
                    />
                    {addZone.touched.code && addZone.errors.code ? (
                      <p className="requiredText">{addZone.errors.code}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Zone Name:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addZone.values.name}
                      name="name"
                      placeholder="Zone Name"
                      className="mainInput"
                      onChange={(e) =>
                        addZone.setFieldValue("name", e.target.value)
                      }
                    />
                    {addZone.touched.name && addZone.errors.name ? (
                      <p className="requiredText">{addZone.errors.name}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Alias:
                    </label>
                    <input
                      type="text"
                      value={addZone.values.alias}
                      name="alias"
                      placeholder="Alias"
                      className="mainInput"
                      onChange={(e) =>
                        addZone.setFieldValue("alias", e.target.value)
                      }
                    />
                    {addZone.touched.alias && addZone.errors.alias ? (
                      <p className="requiredText">{addZone.errors.alias}</p>
                    ) : null}
                  </div>
                  </div>
                  <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                            Country
                            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                          </label>
                          <SearchInput
                            id="countryCode"
                            label="countryCode"
                            name="countryCode"
                            options={countriesData?.data?.data?.content || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.isoCode3}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addZone.setFieldValue(
                                "countryCode",
                                selectedOption ? selectedOption.isoCode3 : ""
                              );
                            }}
                            value={
                              addZone?.values?.countryCode
                                ? countriesData?.data?.data?.content?.find(
                                    (country) =>
                                      country.isoCode3 ===
                                    addZone?.values?.countryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Country"
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                            isDisabled={title === "Edit Zone" ? true : false}
                          />
                          {addZone.touched.countryCode &&
                          addZone.errors.countryCode ? (
                            <p className="requiredText">
                              {addZone.errors.countryCode}
                            </p>
                          ) : null}
                        </div>
                </div>
              </form>
            ) : title === "Create New Zone Group" ||
              title === "Edit Zone Group" ? (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Group Code:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addZonegroup.values.code}
                      name="code"
                      placeholder="Group Code"
                      className="mainInput"
                      onChange={(e) =>
                        addZonegroup.setFieldValue("code", e.target.value)
                      }
                    />
                    {addZonegroup.touched.code && addZonegroup.errors.code ? (
                      <p className="requiredText">{addZonegroup.errors.code}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Group Name:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                    <input
                      type="text"
                      value={addZonegroup.values.name}
                      name="name"
                      placeholder="Group Name"
                      className="mainInput"
                      onChange={(e) =>
                        addZonegroup.setFieldValue("name", e.target.value)
                      }
                    />
                    {addZonegroup.touched.name && addZonegroup.errors.name ? (
                      <p className="requiredText">{addZonegroup.errors.name}</p>
                    ) : null}
                  </div>
                  </div>
                  <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Country:
                      <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                    </label>
                  <SearchInput
                            id="countryCode"
                            label="countryCode"
                            name="countryCode"
                            options={countriesData?.data?.data?.content || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.isoCode3}
                            isClearable={true}
                            handleChange={async (selectedOption) => {
                              addZonegroup.setFieldValue(
                                "countryCode",
                                selectedOption ? selectedOption.isoCode3 : ""
                              );
                            }}
                            value={
                              addZonegroup?.values?.countryCode
                                ? countriesData?.data?.data?.content?.find(
                                    (country) =>
                                      country.isoCode3 ===
                                    addZonegroup?.values?.countryCode
                                  )
                                : null
                            }
                            isSearchable={true}
                            placeholder="Country"
                            className="mainInput"
                            style={{ whiteSpace: "nowrap" }}
                            isDisabled={title === "Edit Zone Group" ? true : false}
                          />
                          {addZonegroup.touched.countryCode &&
                          addZonegroup.errors.countryCode ? (
                            <p className="requiredText">
                              {addZonegroup.errors.countryCode}
                            </p>
                          ) : null}
                          </div>
                </div>
              </form>
            ) : (
              <></>
            )}
          </Modal.Body>
        </div>
        <Modal.Footer
          style={{
            justifyContent:'center',
          }}
        >
          {title == "Edit ZID Product" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <div>
                {/* <img src={deleteButton} alt="Delete" onClick={handleDelete}/> */}
              </div>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              {saveHandler && (
                <button
                  className="blue_btn"
                  onClick={editZidProduct.handleSubmit}
                >
                  Save
                </button>
              )}
            </div>
          ) : (title == "Create new currency" || title == "Edit Currency") &&
            (role?.includes("AONE_CONFIGS.write") ||
              role?.includes("AONE_CONFIGS.admin") ||
              role?.includes("AONE_CONFIGS_CURRENCY.write") ||
              role?.includes("AONE_CONFIGS_CURRENCY.admin")) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addCurrency.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : (title == "Create new conversion" ||
              title == "Edit Currency Conversion") &&
            (role?.includes("AONE_CONFIGS.write") ||
              role?.includes("AONE_CONFIGS.admin") ||
              role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.write") ||
              role?.includes("AONE_CONFIGS_CURRENCY-CONVERSION.admin")) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                className="blue_btn"
                onClick={addCurrencyconversion.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Add Zid Merchant" || title == "Edit ZID Merchant" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              {(saveHandler || title == "Add Zid Merchant") && (
                <button className="blue_btn" onClick={addMerchant.handleSubmit}>
                  Save
                </button>
              )}
            </div>
          ) : title == "Add Roles" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addRole.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Add Group" || title == "Add Child Group" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addGroup.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Add origin" || title === "Edit origin" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addOrigin.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Add destination" || title === "Edit destination" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                className="blue_btn"
                onClick={addDestination.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "New Transit Time" || title === "Edit Transit Time" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addTransit.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Add Area" || title === "Edit Area" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addArea.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Add Users" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addUsers.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "New Country Mapping" ||
            (title === "Edit Country Mapping" &&
              (role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin"))) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addCountryMap.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "New Region Mapping" ||
            (title === "Edit Region Mapping" &&
              (role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin"))) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addRegionMap.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Create New NPR" || title === "Edit NPR" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={handleSavepickupReasons}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Create New NDR" || title === "Edit NDR" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                className="blue_btn"
                onClick={handleSaveDeliveryupReasons}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Create New Hold reason" ||
            title === "Edit Hold reason" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={handleSaveHoldReasons}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "New City Mapping" ||
            (title === "Edit City Mapping" &&
              (role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin"))) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={addCityMap.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "New District Mapping" ||
            (title === "Edit District Mapping" &&
              (role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
                role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin"))) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                className="blue_btn"
                type="submit"
                onClick={addDistrictMap.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn"  type="button" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : (title == "Add new Hub" || title === "Edit Hub") &&
            (role?.includes("AONE_ASSETS.write") ||
              role?.includes("AONE_ASSETS.admin") ||
              role?.includes("AONE_ASSETS_HUBS.admin") ||
              role?.includes("AONE_ASSETS_HUBS.write")) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={() => {
                  if (!error) {
                    addHubs.handleSubmit();
                  }
                }}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Create service area" ||
            (title === "Edit Service Areas" &&
              (role?.includes("AONE_ASSETS.write") ||
                role?.includes("AONE_ASSETS.admin") ||
                role?.includes("AONE_ASSETS_HUBS.admin") ||
                role?.includes("AONE_ASSETS_HUBS.write"))) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={() => {
                  if (!error) {
                    addServiceAreas.handleSubmit();
                  } else {
                    console.log("Error exists:", error);
                  }
                }}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Create new tier" || title === "Edit tier" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={addTiers.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Create OD Pair" || title === "Edit OD Pair" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={addODPairtiers.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : (title == "Add Pickup Address" ||
              title == "Edit Pickup Address") &&
            (roleHandler(role, "AONE_CRM.write") ||
              roleHandler(role, "AONE_CRM.admin") ||
              roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
              roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
              roleHandler(role, "AONE_CONFIGS.write") ||
              roleHandler(role, "AONE_CONFIGS.admin") ||
              roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
              roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={addPickupAddres.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Create Invoice Request" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={addInvoice.handleSubmit}
              >
                Start
              </button>
              {/* <button
                type="button"
                className="blue_btn"
                onClick={addInvoiceTemplate.handleSubmit}
              >
                Save as template
              </button> */}
              <button
                className="cancel_btn"
                onClick={() => handleCloseInvoice()}
              >
                Cancel
              </button>
            </div>
          ) : title == "Create Invoice Request Templates" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={addInvoiceTemplate.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          ) : title == "Sub Accounts" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              {saveHandler && (
                <button
                  className="blue_btn"
                  onClick={updateSubAccount.handleSubmit}
                >
                  Save
                </button>
              )}
            </div>
          ) : title == "Edit Handover" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              {saveHandler && (
                <button
                  className="blue_btn"
                  onClick={updateSubAccount.handleSubmit}
                >
                  Save
                </button>
              )}
            </div>
          ) : title == "Add Employee" || title == "Edit Employee" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              {(saveHandler || title == "Add Employee") && (
                <button
                  className="blue_btn"
                  onClick={editEmployee.handleSubmit}
                >
                  Save
                </button>
              )}
            </div>
          ) : title == "Create third party configuration" ||
            title == "Edit third party configuration" ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              {(title == "Edit third party configuration" ||
                title == "Create third party configuration") && (
                <button
                  className="blue_btn"
                  onClick={addThirdParty.handleSubmit}
                >
                  Save
                </button>
              )}
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
            </div>
          ) : title === "Add New Mapping" || title === "Edit Mapping" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                {(saveHandler || title === "Add New Mapping") && (
                  <button
                    className="blue_btn"
                    onClick={addMapping.handleSubmit}
                  >
                    Save
                  </button>
                )}
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
                {editHandler && (
                  <button
                    className="blue_btn"
                    onClick={() => (
                      setSaveHandlerAction(true), setEditHandler(false)
                    )}
                  >
                    Edit
                  </button>
                )}
              </div>
            </>
          ) : title === "Create new freight rate" ||
            title === "Edit freight rate" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  className="blue_btn"
                  type="button"
                  onClick={handleSaveRates}
                >
                  Save
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseRates()}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : title === "Create new RTO rate" || title === "Edit RTO rate" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  className="blue_btn"
                  type="button"
                  onClick={handleSaveRtoRates}
                >
                  Save
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseRates()}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : title === "Create new ODA Surcharge rate" ||
            title === "Edit ODA Surcharge rate" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  className="blue_btn"
                  type="button"
                  onClick={handleSaveOdaRates}
                >
                  Save
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseRates()}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : title === "Create new COD rate" || title === "Edit COD rate" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  className="blue_btn"
                  type="button"
                  onClick={handleSaveCodRates}
                >
                  Save
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseRates()}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : title === "Create new Fuel rate" || title === "Edit Fuel rate" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  className="blue_btn"
                  type="button"
                  onClick={handleSaveFuelRates}
                >
                  Save
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseRates()}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : title === "Create new Insurance rate" ||
            title === "Edit Insurance rate" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  className="blue_btn"
                  type="button"
                  onClick={handleSaveInsuranceRates}
                >
                  Save
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseRates()}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : title === "Create new Attempts rate" ||
            title === "Edit Attempts rate" ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  className="blue_btn"
                  type="button"
                  onClick={handleSaveAttemptsRates}
                >
                  Save
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseRates()}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (title === "Add Bag" || title === "Edit Bag") &&
            (role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_MM.admin") ||
              role?.includes("AONE_MM_BAGS_BAG.admin") ||
              role?.includes("AONE_MM_BAGS_BAG.write") ||
              role?.includes("AONE_MM_BAGS.write") ||
              role?.includes("AONE_MM_BAGS.admin")) ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button type="button" className="blue_btn" onClick={handleSave}>
                  Save
                </button>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
                {editHandler && (
                  <button
                    className="blue_btn"
                    onClick={() => (
                      setSaveHandlerAction(true), setEditHandler(false)
                    )}
                  >
                    Edit
                  </button>
                )}
              </div>
            </>
          ) : (title == "Add Configration" || title == "Edit Chutes") &&
            (role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_MM_SORTING-MACHINE.admin") ||
              role?.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write") ||
              role?.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
              role?.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.admin") ||
              role?.includes("AONE_SORTING-MACHINE_CONFIGS.admin")) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
               {(saveHandler || title == "Add Configration") && (
                <button className="blue_btn" onClick={addConfig.handleSubmit}>
                  Save
                </button>
              )}
               {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
             ) : (title == "Add Configuration" || title == "Edit Chute") &&
             (role?.includes("AONE_ADMIN") ||
               role?.includes("AONE_MM_SORTING-MACHINE.admin") ||
               role?.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write") ||
               role?.includes("AONE_SORTING-MACHINE_CONFIGS.admin")) ? (
             <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                {(saveHandler || title == "Add Configuration") && (
                 <button className="blue_btn" onClick={addConfigs.handleSubmit}>
                   Save
                 </button>
               )}
                {editHandler && (
                 <button
                   className="blue_btn"
                   onClick={() => (
                     setSaveHandlerAction(true), setEditHandler(false)
                   )}
                 >
                   Edit
                 </button>
               )}
               <button className="cancel_btn" onClick={() => handleClose()}>
                 Cancel
               </button>
             </div>
          ) : (title == "Add Units" || title == "Edit Units") &&
            (role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_MM_SORTING-MACHINE.admin") ||
              role?.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write") ||
              role?.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
              role?.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.admin") ||
              role?.includes("AONE_SORTING-MACHINE_CONFIGS.admin")) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              {(saveHandler || title == "Add Units") && (
                <button
                  className="blue_btn"
                  onClick={detailedConfig.handleSubmit}
                >
                  Save
                </button>
              )}
               <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
              ) : (title == "Add Unit" || title == "Edit Unit") &&
              (role?.includes("AONE_ADMIN") ||
                role?.includes("AONE_MM_SORTING-MACHINE.admin") ||
                role?.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write") ||
                role?.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
                role?.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.admin") ||
                role?.includes("AONE_SORTING-MACHINE_CONFIGS.admin")) ? (
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                {editHandler && (
                  <button
                    className="blue_btn"
                    onClick={() => (
                      setSaveHandlerAction(true), setEditHandler(false)
                    )}
                  >
                    Edit
                  </button>
                )}
                {(saveHandler || title == "Add Unit") && (
                  <button
                    className="blue_btn"
                    onClick={detailedConfigs.handleSubmit}
                  >
                    Save
                  </button>
                )}
                 <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
          ) : (title == "Add Agents" || title == "Edit Agents") &&
            (role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_MM_SORTING-MACHINE.admin") ||
              role?.includes("AONE_MM_SORTING-MACHINE_AGENT.write") ||
              role?.includes("AONE_OPS_SORTING-MACHINE_AGENTS.write") ||
              role?.includes("AONE_OPS_SORTING-MACHINE_AGENTS.admin") ||
              role?.includes("AONE_SORTING-MACHINE_AGENT.admin")) ? (
            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              {editHandler && (
                <button
                  className="blue_btn"
                  onClick={() => (
                    setSaveHandlerAction(true), setEditHandler(false)
                  )}
                >
                  Edit
                </button>
              )}
              {(saveHandler || title == "Add Agents") && (
                <button className="blue_btn" onClick={addAgent.handleSubmit}>
                  Save
                </button>
              )}
               <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
               ) : (title == "Add Agent" || title == "Edit Agent") &&
               (role?.includes("AONE_ADMIN") ||
                 role?.includes("AONE_MM_SORTING-MACHINE.admin") ||
                 role?.includes("AONE_MM_SORTING-MACHINE_AGENT.write") ||
                 role?.includes("AONE_SORTING-MACHINE_AGENT.admin")) ? (
               <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                 {editHandler && (
                   <button
                     className="blue_btn"
                     onClick={() => (
                       setSaveHandlerAction(true), setEditHandler(false)
                     )}
                   >
                     Edit
                   </button>
                 )}
                 {(saveHandler || title == "Add Agent") && (
                   <button className="blue_btn" onClick={addAgents.handleSubmit}>
                     Save
                   </button>
                 )}
                  <button className="cancel_btn" onClick={() => handleClose()}>
                   Cancel
                 </button>
               </div>
          ) : title === "Edit Waybill" &&
            (role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_MM_BAGS_BAG.admin") ||
              role?.includes("AONE_MM_BAGS_BAG.write") ||
              role?.includes("AONE_MM_BAGS.write") ||
              role?.includes("AONE_MM_BAGS.admin")) ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                {(saveHandler || title === "Edit Waybill") && (
                  <button
                    type="button"
                    className="blue_btn"
                    onClick={editWaybill.handleSubmit}
                  >
                    Save
                  </button>
                )}
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
                {editHandler && (
                  <button
                    className="blue_btn"
                    onClick={() => (
                      setSaveHandlerAction(true), setEditHandler(false)
                    )}
                  >
                    Edit
                  </button>
                )}
              </div>
            </>
          ) : title === "Create New Zone" ||
            (title === "Edit Zone" &&
              (role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_ZONES.write") ||
                role?.includes("AONE_CONFIGS_ZONES.admin"))) ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                {(saveHandler || title === "Create New Zone") && (
                  <button
                    type="button"
                    className="blue_btn"
                    onClick={addZone.handleSubmit}
                  >
                    Save
                  </button>
                )}
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
                {editHandler && (
                  <button
                    className="blue_btn"
                    onClick={() => (
                      setSaveHandlerAction(true), setEditHandler(false)
                    )}
                  >
                    Edit
                  </button>
                )}
              </div>
            </>
          ) : title === "Create New Zone Group" ||
            (title === "Edit Zone Group" &&
              (role?.includes("AONE_CONFIGS.write") ||
                role?.includes("AONE_CONFIGS.admin") ||
                role?.includes("AONE_CONFIGS_ZONES.write") ||
                role?.includes("AONE_CONFIGS_ZONES.admin"))) ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                {(saveHandler || title === "Create New Zone Group") && (
                  <button
                    type="button"
                    className="blue_btn"
                    onClick={addZonegroup.handleSubmit}
                  >
                    Save
                  </button>
                )}
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
                {editHandler && (
                  <button
                    className="blue_btn"
                    onClick={() => (
                      setSaveHandlerAction(true), setEditHandler(false)
                    )}
                  >
                    Edit
                  </button>
                )}
              </div>
            </>
          ) : title === "Add Waybill" &&
            (role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_MM_BAGS_BAG.admin") ||
              role?.includes("AONE_MM_BAGS_BAG.write") ||
              role?.includes("AONE_MM_BAGS.write") ||
              role?.includes("AONE_MM_BAGS.admin")) ? (
            <>
              <div className="displayFlex alignItemCenter justifyContent_end gap_16">
                <button
                  type="button"
                  className="blue_btn"
                  onClick={addWaybill.handleSubmit}
                >
                  Save
                </button>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
                {editHandler && (
                  <button
                    className="blue_btn"
                    onClick={() => (
                      setSaveHandlerAction(true), setEditHandler(false)
                    )}
                  >
                    Edit
                  </button>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={() => handleCancel()}
        // className="addnewpopup"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">
            Select Invoice from below Templates
          </h4>
        </Modal.Header>
        {/* <div className="modelScrollTwo"> */}
        <Modal.Body>
          <form>
            <div className="row">
              <div className="table_wrapper overflowScroll mb_12 showTenRows">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceTempData?.data?.data?.content?.map(
                      (item, index) => (
                        <tr
                          key={id}
                          onDoubleClick={() =>
                            handleRowClick(
                              item?.billingCountry,
                              item?.operationCode,
                              item?.customerAccounts
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <td>{item.id}</td>
                          <td>{item.customerAccounts}</td>
                          <td>{item.billingCountry}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          {/* <>
            <div className="customPagination">
              <Pagination
                activePage={newPage + 1}
                itemsCountPerPage={size}
                totalItemsCount={1}
                pageRangeDisplayed={1}
              />
            </div>
            <div>
              <p>
                {1}
                {(newPage + 1) * size < 1 &&
                  (newPage + 1) * size !== newPage * size + 1 && (
                    <span>-{(newPage + 1) * size}</span>
                  )}
                /{1}
              </p>
            </div>
          </> */}
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center">
            <div>
              <button className="cancel_btn" onClick={() => handleCancel()}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default OpenModal;
