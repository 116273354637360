import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { useAoneStore } from "../../store/AoneStore";
import { usevehicleStore } from "../../store/VehicleMgt/VehicleStore";
import { usesettingStore } from "../../store/Settings/SettingStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const LocalizationModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  localizationbyCode,
  component
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postLocalization = usesettingStore((state) => state?.postLocalization);
  const editLocalization = usesettingStore((state) => state?.editLocalization);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const LanguageData = usesettingStore((state) => state.LanguageData);
  const [resetKey, setResetKey] = useState(0);
  const addLocalization = useFormik({
    initialValues: {
      languageCode: "",
      localizationKey: "",
      value: "",
    },
    validationSchema: Yup.object({
      languageCode: Yup.string().trim().required("Language is required"),
      localizationKey: Yup.string().required("Key is required")
      .matches(/^[a-zA-Z0-9\s_-]*$/, "Key must not contain special characters"),
      value: Yup.string().required("Value is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title == "Edit localization") {
        const updatedValues = { ...values, id: localizationbyCode?.id };
        await editLocalization(component,updatedValues).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Localization updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          }else if(response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response.status == 200) {
            toast.success("Localization updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      } else {
      await postLocalization(component,values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Localization added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    }
    },
  });
  useEffect(() => {
    addLocalization.setFieldValue("languageCode", localizationbyCode?.languageCode);
    addLocalization.setFieldValue("localizationKey", localizationbyCode?.localizationKey);
    addLocalization.setFieldValue("value", localizationbyCode?.value);
}, [title, localizationbyCode]);
useEffect(() => {
  if(title == "Create new localization"){
    addLocalization.setFieldValue("languageCode", "");
    addLocalization.setFieldValue("localizationKey", "");
    addLocalization.setFieldValue("value", "");
  }
}, [title, localizationbyCode]);
  const [valueErr,setValueErr] = useState("");
  const [keyErr,setKeyErr] = useState("")
  const saveLocalization = (initialValues) => {
    if (
      /\s/g.test(addLocalization.values.localizationKey)
    ) {
      setKeyErr("Key cannot cannot contain leading, trailing, or consecutive spaces");
    }else if (/^\s|\s$/.test(addLocalization.values.value)) {
      localizationbyCode(
          "Value cannot cannot contain leading or trailing spaces"
        );
    } else {
      setValueErr("");
      setKeyErr("");
      addLocalization?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleVendors">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
            <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Language
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="languageCode"
                      label="languageCode"
                      name="languageCode"
                      options={LanguageData?.data?.data}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addLocalization.setFieldValue(
                          "languageCode",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addLocalization?.values?.languageCode
                          ? LanguageData?.data?.data?.find(
                              (option) => option.code === addLocalization?.values?.languageCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Language"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addLocalization.touched.languageCode &&
                  addLocalization.errors.languageCode ? (
                    <p className="requiredText">
                      {addLocalization.errors.languageCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Key
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Key"
                      value={addLocalization.values.localizationKey}
                      onChange={(e) => {
                        addLocalization.setFieldValue("localizationKey", e.target.value);
                        setKeyErr("");
                      }}
                      name="localizationKey"
                      className="mainInput"
                    />
                  </div>
                  {(addLocalization.touched.localizationKey &&
                  addLocalization.errors.localizationKey  && keyErr == "") ? (
                    <p className="requiredText">
                      {addLocalization.errors.localizationKey}
                    </p>
                  ) : null}
                  {keyErr !== "" ? (
                    <p className="requiredText">{keyErr}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-12">
                <div className="row alignItemCenter">
                  <div className="col-md-12">
                    <label className="fontSize14 menacingCloudsText fontWeight500">
                      Value
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <textarea
                          className="washMe_btn"
                          placeholder="Value"
                          name="value"
                          maxLength={250}
                          onChange={(e) =>{
                            addLocalization.setFieldValue(
                              "value",
                              e.target.value
                            );
                            setValueErr("");
                          }
                          }
                          onBlur={addLocalization.handleBlur}
                          value={addLocalization.values.value}
                          style={{ height: "90px", paddingTop: "6px" }}
                        ></textarea>
                  </div>
                  {(addLocalization.touched.value &&
                  addLocalization.errors.value && keyErr == "") ? (
                    <p className="requiredText">
                      {addLocalization.errors.value}
                    </p>
                  ) : null}
                   {keyErr !== "" ? (
                    <p className="requiredText">
                      {keyErr}
                    </p>
                  ) : null}
                </div>
              </div>
              </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveLocalization}>
          {title === "Create new localization" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default LocalizationModal;
