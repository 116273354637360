import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userManager, logout, getUser } from './auth_helper';
import jwtDecode from 'jwt-decode';
import showToast from '../components/Toast/ToastComponent'
const Callback = () => {
  const navigate = useNavigate();
  const redirection = (token) => {
    const role = jwtDecode(token)?.realm_access?.roles;
    switch (true) {
      case role.some(
        (key) =>
          key.includes("AONE_OPS.read") ||
        key.includes("AONE_OPS.write") ||
        key.includes("AONE_OPS.admin") ||
        key.includes("AONE_OPS_REASONS_NPR.read") ||
        key.includes("AONE_OPS_REASONS_NPR.write") ||
        key.includes("AONE_OPS_REASONS_NPR.admin") ||
        key.includes("AONE_OPS_REASONS_NDR.read") ||
        key.includes("AONE_OPS_REASONS_NDR.write") ||
        key.includes("AONE_OPS_REASONS_NDR.admin") ||
        key.includes("AONE_OPS_REASONS_HOLD.read") ||
        key.includes("AONE_OPS_REASONS_HOLD.write") ||
        key.includes("AONE_OPS_REASONS_HOLD.admin") ||
        key.includes("AONE_OPS_CONSIGNMENTS.read") ||
        key.includes("AONE_OPS_CONSIGNMENTS.print") ||
        key.includes("AONE_OPS_CONSIGNMENTS.pickup") ||
        key.includes("AONE_OPS_CONSIGNMENTS.cancel") ||
        key.includes("AONE_OPS_CONSIGNMENTS.receive") ||
        key.includes("AONE_OPS_CONSIGNMENTS.hold") ||
        key.includes("AONE_OPS_CONSIGNMENTS.revoke_hold") ||
        key.includes("AONE_OPS_CONSIGNMENTS.lost") ||
        key.includes("AONE_OPS_CONSIGNMENTS.damaged") ||
        key.includes("AONE_OPS_CONSIGNMENTS.disposed") ||
        key.includes("AONE_OPS_CONSIGNMENTS.revoke_pickup") ||
        key.includes("AONE_OPS_CONSIGNMENTS.revoke_cancel") ||
        key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount") ||
        key.includes("AONE_OPS_CONSIGNMENTS.revoke_delivery") ||
        key.includes("AONE_OPS_LAST-MILE_DELIVERY-TASKS.write") ||
        key.includes("AONE_OPS_PICKUP-REQUEST.read") ||
        key.includes("AONE_OPS_PICKUP-REQUEST.write") ||
        key.includes("AONE_OPS_PICKUP-REQUEST.admin") ||
        key.includes("AONE_OPS_PICKUP-REQUEST.cancel")
      ):
        return "/ops/consignments";
      case role.some((key) => key.includes("AONE_ADMIN")):
        return "/iam/roles";
      case role.some(
        (key) =>
          key.includes("AONE_IAM.read") ||
          key.includes("AONE_IAM.write") ||
          key.includes("AONE_IAM.admin") ||
          key.includes("AONE_IAM_USERS.read") ||
          key.includes("AONE_IAM_USERS.write") ||
          key.includes("AONE_IAM_USERS.admin") ||
          key.includes("AONE_IAM_GROUPS.read") ||
          key.includes("AONE_IAM_GROUPS.write") ||
          key.includes("AONE_IAM_GROUPS.admin") ||
          key.includes("AONE_IAM_ROLES.read") ||
          key.includes("AONE_IAM_ROLES.write") ||
          key.includes("AONE_IAM_ROLES.admin")
      ):
        return "/iam/roles";
      case role.some(
        (key) =>
          key.includes("AONE_ADMIN") ||
          key.includes("AONE_THIRD-PARTY.read") ||
          key.includes("AONE_THIRD-PARTY.write") ||
          key.includes("AONE_THIRD-PARTY.admin") ||
          key.includes("AONE_THIRD_PARTY_SCANS.read") ||
          key.includes("AONE_THIRD_PARTY_SCANS.admin") ||
          key.includes("AONE_THIRD-PARTY_SCANS_PICKUP.read") ||
          key.includes("AONE_THIRD-PARTY_SCANS_PICKUP.write") ||
          key.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write") ||
          key.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin") ||
          key.includes("AONE_THIRD-PARTY_ORDERS.read") ||
          key.includes("AONE_THIRD-PARTY_ORDERS.write") ||
          key.includes("AONE_THIRD-PARTY_ORDERS.cancel") ||
          key.includes("AONE_THIRD-PARTY_ORDERS.admin") ||
          key.includes("AONE_THIRD-PARTY_ORDERS.assign")
      ):
        return "/thirdparty/configurations";
      case role.some((key) => key.includes("AONE_MM_SORTING-MACHINE.admin") ||
      key.includes("AONE_MM_SORTING-MACHINE_CONFIGS.read") ||
      key.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write") ||
      key.includes("AONE_MM_SORTING-MACHINE_CONFIGS.admin") ||
      key.includes("AONE_MM_SORTING-MACHINE_AGENT.read") ||
      key.includes("AONE_MM_SORTING-MACHINE_AGENT.write") ||
      key.includes("AONE_MM_SORTING-MACHINE_AGENT.admin") ||
      key.includes("AONE_MM_SORTING-MACHINE_SCANS.read") ||
      key.includes("AONE_MM_SORTING-MACHINE_SCANS.write") ||
      key.includes("AONE_MM_SORTING-MACHINE_SCANS.admin")
    ):
        return "/middlemile/sortingmachine/config";
      case role.some((key) => key.includes("AONE_MM")):
        return "/middlemile/bags";
      case role.some(
        (key) =>
          key.includes("AONE_CONFIGS.read") ||
          key.includes("AONE_CONFIGS.write") ||
          key.includes("AONE_CONFIGS.admin") ||
          key.includes("AONE_CONFIGS_ADDRESSES") ||
          key.includes("AONE_CONFIGS_ADDRESSES.admin") ||
          key.includes("AONE_CONFIGS_ADDRESSES.write") ||
          key.includes("AONE_CONFIGS_ADDRESSES.read")
      ):
        return "/configs/addresses";
      case role.some(
        (key) =>
          key.includes("AONE_CONFIGS_ZONES.read") ||
          key.includes("AONE_CONFIGS_ZONES.write") ||
          key.includes("AONE_CONFIGS_ZONES.admin")
      ):
        return "/configs/zones";
        case role.some(
          (key) =>
            key.includes("AONE_HAJ_CONSIGNMENTS.read") ||
            key.includes("AONE_HAJ_CONSIGNMENTS.write") ||
            key.includes("AONE_HAJ_CONSIGNMENTS.print")
        ):
          return "/consignments/print";
      case role.some((key) => key.includes("AONE_CONFIGS_ADDRESS-MAPPINGS")):
        return "/configs/addressmapping";
      case role.some(
        (key) =>
          key.includes("AONE_CONFIGS_CURRENCY.read") ||
          key.includes("AONE_CONFIGS_CURRENCY.write") ||
          key.includes("AONE_CONFIGS_CURRENCY.admin") ||
          key.includes("AONE_CONFIGS_CURRENCY-CONVERSION.read") ||
          key.includes("AONE_CONFIGS_CURRENCY-CONVERSION.write") ||
          key.includes("AONE_CONFIGS_CURRENCY-CONVERSION.admin")
      ):
        return "/configs/currency";
        case role.some(
          (key) =>
            key.includes("AONE_CONFIGS.read") ||
            key.includes("AONE_CONFIGS.write") ||
            key.includes("AONE_CONFIGS.admin") ||
            key.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.read") ||
            key.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write") ||
            key.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin") ||
            key.includes("AONE_CONFIGS_SHORT-ADDRESS_HISTORY.read") ||
            key.includes("AONE_CONFIGS_SHORT-ADDRESS_HISTORY.write")
        ):
          return "/configs/shortaddress";
          case role.some(
            (key) =>
              key.includes("AONE_NOTIFICATIONS_SMS_TEMPLATES.read") ||
              key.includes("AONE_NOTIFICATIONS_SMS_TEMPLATES.write") ||
              key.includes("AONE_NOTIFICATIONS_SMS_TEMPLATES.admin") ||
              key.includes("AONE_NOTIFICATIONS_SMS_RULES.read") ||
              key.includes("AONE_NOTIFICATIONS_SMS_RULES.write") ||
              key.includes("AONE_NOTIFICATIONS_SMS_RULES.admin") ||
              key.includes("AONE_NOTIFICATIONS_SMS_HISTORY.read")
          ):
            return "/notifications/sms";
      case role.some(
        (key) =>
          key.includes("AONE_ASSETS.read") ||
          key.includes("AONE_ASSETS.write") ||
          key.includes("AONE_ASSETS.admin") ||
          key.includes("AONE_ASSETS_HUBS.read") ||
          key.includes("AONE_ASSETS_HUBS.admin") ||
          key.includes("AONE_ASSETS_HUBS.write")
      ):
        return "/assets/hubs";
      case role.some(
        (key) =>
          key.includes("AONE_ASSETS.read") ||
          key.includes("AONE_ASSETS.write") ||
          key.includes("AONE_ASSETS.admin") ||
          key.includes("AONE_ASSETS_ROUTES.read") ||
          key.includes("AONE_ASSETS_ROUTES.admin") ||
          key.includes("AONE_ASSETS_ROUTES.write")
      ):
        return "/assets/routes";
      case role.some(
        (key) =>
          key.includes("AONE_ASSETS.read") ||
          key.includes("AONE_ASSETS.write") ||
          key.includes("AONE_ASSETS.admin") ||
          key.includes("AONE_ASSETS_VEHICLES.read") ||
          key.includes("AONE_ASSETS_VEHICLES.admin") ||
          key.includes("AONE_ASSETS_VEHICLES.write") ||
          key.includes("AONE_ASSETS_VEHICLES_VEHICLES.read") ||
          key.includes("AONE_ASSETS_VEHICLES_VEHICLES.write") ||
          key.includes("AONE_ASSETS_VEHICLES_VEHICLES.admin") ||
          key.includes("AONE_ASSETS_VEHICLES_CATEGORIES.read") ||
          key.includes("AONE_ASSETS_VEHICLES_CATEGORIES.write") ||
          key.includes("AONE_ASSETS_VEHICLES_CATEGORIES.admin") ||
          key.includes("AONE_ASSETS_VEHICLES_VENDORS.read") ||
          key.includes("AONE_ASSETS_VEHICLES_VENDORS.write") ||
          key.includes("AONE_ASSETS_VEHICLES_VENDORS.admin") ||
          key.includes("AONE_ASSETS_VEHICLES_GPS-VENDORS.read") ||
          key.includes("AONE_ASSETS_VEHICLES_GPS-VENDORS.write") ||
          key.includes("AONE_ASSETS_VEHICLES_GPS-VENDORS.admin") ||
          key.includes("AONE_ASSETS_VEHICLES_DOCUMENTS.read") ||
          key.includes("AONE_ASSETS_VEHICLES_DOCUMENTS.write") ||
          key.includes("AONE_ASSETS_VEHICLES_DOCUMENTS.admin") ||
          key.includes("AONE_ASSETS_VEHICLES_ODOMETER.read") ||
          key.includes("AONE_ASSETS_VEHICLES_ODOMETER.admin") ||
          key.includes("AONE_ASSETS_VEHICLES_ODOMETER.write")
      ):
        return "/assets/vehicles";
      case role.some((key) => key.includes("PRODUCTS")):
        return "/integrations/zid/products";
      case role.some((key) => key.includes("MERCHANTS")):
        return "/integrations/zid/merchants";
        case role.some(
          (key) =>
            key.includes("AONE_FINANCE.read") ||
            key.includes("AONE_FINANCE.write") ||
            key.includes("AONE_FINANCE.admin") ||
            key.includes("AONE_CONFIGS_ADDRESSES.read") ||
            key.includes("AONE_FINANCE_INVOICE.read") ||
            key.includes("AONE_FINANCE_INVOICE.write") ||
            key.includes("AONE_FINANCE_INVOICE.admin") ||
            key.includes("AONE_FINANCE_INVOICE_INVOICE-REQUEST.read") ||
            key.includes("AONE_FINANCE_INVOICE_INVOICE-REQUEST.write") ||
            key.includes("AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin") ||
            key.includes("AONE_FINANCE_INVOICE_CONSIGNMENTS.oracle") ||
            key.includes("AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.read") ||
            key.includes("AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.write") ||
            key.includes("AONE_FINANCE_INVOICE_INVOICE-REQUEST-TEMPLATE.admin")
        ):
        return "/finance/invoices";
        case role.some(
          (key) =>
            key.includes("AONE_FINANCE_CHARGES.read") ||
            key.includes("AONE_FINANCE_CHARGES.write") ||
            key.includes("AONE_FINANCE_CHARGES.admin")
        ):
          return "/finance/charges";
        case role.some(
          (key) =>
            key.includes("AONE_FINANCE.read") ||
            key.includes("AONE_FINANCE.write") ||
            key.includes("AONE_FINANCE.admin") ||
            key.includes("AONE_FINANCE_LEDGERS.read") ||
            key.includes("AONE_FINANCE_LEDGERS.write") ||
            key.includes("AONE_FINANCE_LEDGERS.admin") ||
            key.includes("AONE_FINANCE_LEDGERS.settle") ||
            key.includes("AONE_FINANCE_LEDGERS_COLLECTIONS.read") ||
            key.includes("AONE_FINANCE_LEDGERS_COLLECTIONS.settle") ||
            key.includes("AONE_FINANCE_LEDGERS_TRANSACTIONS.mode") ||
            key.includes("AONE_FINANCE_LEDGERS_TRANSACTIONS.read")
        ):
          return "/finance/cashdebriefing";
      case role.some(
        (key) =>
          key.includes("AONE_CRM.write") ||
          key.includes("AONE_CRM.admin") ||
          key.includes("AONE_CRM.read") ||
          key.includes("AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.read") ||
          key.includes("AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.write") ||
          key.includes("AONE_CRM_CUSTOMERS.salesperson") ||
          key.includes("AONE_CRM_CUSTOMERS.admin") ||
          key.includes("AONE_CRM_CUSTOMERS.write") ||
          key.includes("AONE_CRM_CUSTOMERS.read")
      ):
        return "/crm/customers";
      case role.some(
        (key) =>
          key.includes("AONE_CRM_EMPLOYEES.read") ||
          key.includes("AONE_CRM_EMPLOYEES.write") ||
          key.includes("AONE_CRM_EMPLOYEES.admin") ||
          key.includes("AONE_CRM_EMPLOYEES.view_sensitive_data") ||
          key.includes("AONE_CRM_EMPLOYEES_USER_MANAGEMENT.read") ||
          key.includes("AONE_CRM_EMPLOYEES_USER_MANAGEMENT.write") ||
          key.includes("AONE_CRM_EMPLOYEES_USER_MANAGEMENT.admin") ||
          key.includes("AONE_CRM_EMPLOYEES_AREAS.read") ||
          key.includes("AONE_CRM_EMPLOYEES_AREAS.write") ||
          key.includes("AONE_CRM_EMPLOYEES_AREAS.admin") ||
          key.includes("AONE_CRM_EMPLOYEES_ACCOUNTS.read") ||
          key.includes("AONE_CRM_EMPLOYEES_ACCOUNTS.write") ||
          key.includes("AONE_CRM_EMPLOYEES_ACCOUNTS.admin") ||
          key.includes("AONE_CRM.read") ||
          key.includes("AONE_CRM.write") ||
          key.includes("AONE_CRM.admin")
      ):
        return "/crm/employees";
      case role.some(
        (key) =>
          key.includes("AONE_MASTERS.read") ||
          key.includes("AONE_MASTERS.write") ||
          key.includes("AONE_MASTERS.admin") ||
          key.includes("AONE_MASTERS_PRODUCT.read") ||
          key.includes("AONE_MASTERS_PRODUCT.admin") ||
          key.includes("AONE_MASTERS_PRODUCT.write")
      ):
        return "/masters/products";
      case role.some(
        (key) =>
          key.includes("AONE_MASTERS_RATES.read") ||
          key.includes("AONE_MASTERS_RATES.write") ||
          key.includes("AONE_MASTERS_RATES.admin")
      ):
        return "/masters/rates";
        case role.some(
          (key) =>
            key.includes("AONE_OPS.read") ||
            key.includes("AONE_OPS.write") ||
            key.includes("AONE_OPS.admin") ||
            key.includes("AONE_OPS_PICKUP-REQUEST.read") ||
            key.includes("AONE_OPS_PICKUP-REQUEST.write") ||
            key.includes("AONE_OPS_PICKUP-REQUEST.admin") ||
            key.includes("AONE_OPS_PICKUP-REQUEST.cancel")
        ):
          return "/ops/pickups";
          case role.some(
            (key) =>
              key.includes("AONE_OPS.read") ||
              key.includes("AONE_OPS.write") ||
              key.includes("AONE_OPS.admin") ||
              key.includes("AONE_OPS_LAST-MILE.write") ||
              key.includes("AONE_OPS_LAST-MILE.read") ||
              key.includes("AONE_OPS_LAST-MILE_DELIVERY-TASKS.write") ||
              key.includes("AONE_OPS_LAST-MILE_DELIVERY-TASKS.read") ||
              key.includes("AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.read") ||
              key.includes("AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write") ||
              key.includes("AONE_OPS_LAST-MILE_DEBRIEFING.read") ||
              key.includes("AONE_OPS_LAST-MILE_DEBRIEFING.write") ||
              key.includes("AONE_OPS_LAST-MILE_DEBRIEFING-HISTORY.read")
          ):
            return "/ops/lastmile";
        case role.some(
          (key) =>
            key.includes("AONE_OPS.read") ||
            key.includes("AONE_OPS.write") ||
            key.includes("AONE_OPS.admin") ||
            key.includes("AONE_OPS_REASONS_NPR.read") ||
            key.includes("AONE_OPS_REASONS_NPR.write") ||
            key.includes("AONE_OPS_REASONS_NPR.admin") ||
            key.includes("AONE_OPS_REASONS_NDR.read") ||
            key.includes("AONE_OPS_REASONS_NDR.write") ||
            key.includes("AONE_OPS_REASONS_NDR.admin") ||
            key.includes("AONE_OPS_REASONS_HOLD.read") ||
            key.includes("AONE_OPS_REASONS_HOLD.write") ||
            key.includes("AONE_OPS_REASONS_HOLD.admin")
        ):
          return "/ops/reasons";
          case role.some(
            (key) =>
              key.includes("AONE_OPS.read") ||
              key.includes("AONE_OPS.write") ||
              key.includes("AONE_OPS.admin") ||
              key.includes("AONE_OPS_COURIERS.read")
          ):
            return "/ops/couriers";
            case role.some(
              (key) =>
                key.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.read") ||
                key.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.write") ||
                key.includes("AONE_OPS_SORTING-MACHINE_CONFIGS.admin") ||
                key.includes("AONE_OPS_SORTING-MACHINE_AGENTS.read") ||
                key.includes("AONE_OPS_SORTING-MACHINE_AGENTS.write") ||
                key.includes("AONE_OPS_SORTING-MACHINE_AGENTS.admin") ||
                key.includes("AONE_OPS_SORTING-MACHINE_SCANS.read")
            ):
              return "/ops/sortingmachine/config";
            case role.some(
              (key) =>
                key.includes("AONE_OPS.read") ||
                key.includes("AONE_OPS.write") ||
                key.includes("AONE_OPS.admin") ||
                key.includes("AONE_OPS_TRIPS.read") ||
                key.includes("AONE_OPS_TRIPS.write") ||
                key.includes("AONE_OPS_TRIPS.admin")
            ):
              return "/ops/linehauls";
            case role.some(
              (key) =>
                key.includes("AONE_OPS.read") ||
                key.includes("AONE_OPS.write") ||
                key.includes("AONE_OPS.admin") ||
                key.includes("AONE_OPS_DRIVERS.write") ||
                key.includes("AONE_OPS_DRIVERS.read") ||
                key.includes("AONE_OPS_DRIVERS.admin")
            ):
              return "/ops/linehauls";
            case role.some(
              (key) =>
                key.includes("AONE_OPS.read") ||
                key.includes("AONE_OPS.write") ||
                key.includes("AONE_OPS.admin") ||
                key.includes("AONE_OPS_WEIGHTING.read") ||
                key.includes("AONE_OPS_WEIGHTING.write") ||
                key.includes("AONE_OPS_WEIGHTING.admin")
            ):
              return "/ops/weighing";
              case role.some(
                (key) =>
                  key.includes("AONE_OPS.read") ||
                  key.includes("AONE_OPS.write") ||
                  key.includes("AONE_OPS.admin") ||
                  key.includes("AONE_OPS_ACL.read") ||
                  key.includes("AONE_OPS_ACL.print") ||
                  key.includes("AONE_OPS_ACL.scan") ||
                  key.includes("AONE_OPS_ACL.write") ||
                  key.includes("AONE_OPS_ACL.assign") ||
                  key.includes("AONE_OPS_ACL.admin")
              ):
                return "/ops/consolidations";
                case role.some(
                  (key) =>
                    key.includes("AONE_OPS.read") ||
                    key.includes("AONE_OPS.write") ||
                    key.includes("AONE_OPS.admin") ||
                    key.includes("AONE_OPS_RTO.read") ||
                    key.includes("AONE_OPS_RTO.write")
                ):
                  return "/ops/rto";
      case role.some(
        (key) =>
          key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.read") ||
          key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.create") ||
          key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.cancel") ||
          key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.edit_destination_address") ||
          key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.edit_cod") ||
          key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount") ||
          key.includes("AONE_CONSIGNMENTS_CONSIGNMENT.edit_incoterms")
      ):
        return "/consignments/orders";
        case role.some(
          (key) =>
            key.includes("AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.read") ||
            key.includes("AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.write") ||
            key.includes("AONE_CONSIGNMENTS_CUSTOMER_SEQUENCER.admin")
        ):
          return "/consignments/sequence";
          case role.some(
            (key) =>
              key.includes("AONE_SETTINGS_LOCALIZATION.read") ||
              key.includes("AONE_SETTINGS_LOCALIZATION.write") ||
              key.includes("AONE_SETTINGS_LOCALIZATION.admin")
          ):
            return "/settings/localization";
            case role.some(
              (key) =>
                key.includes("AONE_SETTINGS_SYSTEM.read") ||
                key.includes("AONE_SETTINGS_SYSTEM.write") ||
                key.includes("AONE_SETTINGS_SYSTEM.admin")
            ):
              return "/settings/system";
      default:
        return "/no-content";
    }
  };
  const [userName, setUserName] = useState("");
  const [user, setUser] = useState(null);
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwtDecode(token);
      setUserName(decoded?.name || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  useEffect(() => {
    userManager.signinRedirectCallback().then((user) => {
      const decoded = jwtDecode(user?.access_token);
      setUserName(decoded?.name || "");
      setUser(user);
      sessionStorage.setItem("user_access_token", user?.access_token)
    }).catch((err) => {
    });
  }, [navigate]);
 
  useEffect(() => {
    if (userName) {
      showToast(`Hello, ${userName}!`, {
        icon: '👏',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        }
      });
      setTimeout(() => {
        navigate(redirection(user?.access_token));
      }, 1); // Adjust the delay time as needed
    }
  }, [userName,user, navigate]);
  return <div>Loading...</div>;
};

export default Callback;