import React, { useState, useEffect, useCallback , useContext} from "react";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useAoneStore } from "../../../store/AoneStore";
import { useParams } from "react-router";
import { useDeliveryStore } from "../../../store/DeliveryMgt/DeliveryStore";
import syncicon from "../../../../src/assets/img/sync.svg";
import downloadIcon from "../../../assets/img/download_icon.svg";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import removeIcon from "../../../assets/img/CancelDlIcon.svg";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
const DeliveryValidation = () => {
  const params = useParams();
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [trackId, setTrackId] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [validationId, setValidationId] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [courierPage, setCourierPage] = useState(-1);
  const [courierdlPage, setCourierDlPage] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const [packageTrackingId, setPackageTrackingId] = useState("");
  const [courierId, setCourierId] = useState(sessionStorage.getItem("SelectedCourier"));
  const [assignedCount, setAssignCount] = useState("");
  const [scannedCount, setScannedCount] = useState("");
  const [isMultiple, setIsMultiple] = useState(false);
  const [isMultipleFilter,setIsMultipleFilter] = useState(false);
  const [selectedOptionCourier, setSelectedOptionCourier] = useState(null);
  const [selectedOptionCourierDl, setSelectedOptionCourierDl] = useState(null);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const getdeliveryValidation = useDeliveryStore(
    (state) => state?.getdeliveryValidation
  );
  const postdeliveryValidation = useDeliveryStore(
    (state) => state?.postdeliveryValidation
  );
  const validatePackageTrackingId = useDeliveryStore(
    (state) => state?.validatePackageTrackingId
  );
  const submitDeliveryValidation = useDeliveryStore(
    (state) => state?.submitDeliveryValidation
  );
  const makeOfd = useDeliveryStore((state) => state?.makeOfd);
  const countTasks = useDeliveryStore((state) => state?.countTasks);
  const exportDeliveryValidation = useDeliveryStore(
    (state) => state?.exportDeliveryValidation
  );
  const removeDeliveryValidation = useDeliveryStore(
    (state) => state?.removeDeliveryValidation
  );
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setCourierDlPage(-1);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const handleShow = () => setShow(true);
  const [showTransfer, setShowTransfer] = useState(false);
  const handleTransferClose = () => {
    setShowTransfer(false);
  };
  const handleShowTransfer = () => {
    setShowTransfer(true);
  };
  const [showSubmit, setShowSubmit] = useState(false);
  const handleSubmitClose = () => {
    setShowSubmit(false);
  };
  const handleShowSubmit = () => {
    setShowSubmit(true);
  };
  const [showAssign, setShowAssign] = useState(false);
  const handleassignShow = () => setShowAssign(true);
  const handleassignClose = () => {
    setShowAssign(false);
  };
  const handleassignCancel = () => {
    setShowAssign(false);
  };
  const [errorMsg, setErrMsg] = useState("");
  const [showOfd, setShowOfd] = useState(false);
  const handleOfdClose = () => {
    setShowOfd(false);
  };
  const handleShowOfd = () => {
    setShowOfd(true);
  };
  useEffect(() => {
    setValidationId(sessionStorage.getItem("ValidationId"));
    setAssignCount(sessionStorage.getItem("assignedCount"));
    setScannedCount(sessionStorage.getItem("scannedCount"));
    setIsValidated(sessionStorage.getItem("isValidated"));
    setCourierId(sessionStorage.getItem("SelectedCourier"))
  }, []);
  const [isValidated,setIsValidated] = useState(false);
  useEffect(()=>{
    if(validationId !== null &&
      sessionStorage.getItem("ValidationId") !== null){
        setIsValidated(true);
      }
  },[validationId])
    // Create an audio element
    const playErrorSound = () => {
      const audio = new Audio('/error-sound.mp3');
      audio.play().catch(error => console.error('Error playing audio:', error));
    };
    const playSuccessSound = () => {
      const audio = new Audio('/success-sound.mp3');
      audio.play().catch(error => console.error('Success playing audio:', error));
    };
  const validateDl = useFormik({
    initialValues: {
      packageTrackingId: "",
      validationCode: validationId,
    },
    validationSchema: Yup.object({
      packageTrackingId: Yup.string().required(
        "Package tracking Id is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.validationCode = sessionStorage.getItem("ValidationId");
      await validatePackageTrackingId(courierId,values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          setIsValidated(true);
          playSuccessSound();
          sessionStorage.setItem("isValidated",true)
          toast.success(`Packagetracking Id validated`);
          countCourierTasks(sessionStorage.getItem("SelectedCourier"));
          getData();
          setShowTransfer(false);
          resetForm();
          setTrackId("");
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShowTransfer(false);
          setTrackId("");
          resetForm();
          toast.error(errorMessages);
          playErrorSound();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg?.split("!")[0];
          toast.error(errorMessage);
          setShowTransfer(false);
          setTrackId("");
          resetForm();
          playErrorSound();
        } else {
          const errorMessage = response?.data?.msg?.split(",")[0];
          toast.error(errorMessage);
          setShowTransfer(false);
          setTrackId("");
          resetForm();
          playErrorSound();
        }
      });
    },
  });
  const submitDl = useFormik({
    initialValues: {
      validationCode: validationId,
    },
    onSubmit: async (values, { resetForm }) => {
      values.validationCode = sessionStorage.getItem("ValidationId");
      await submitDeliveryValidation(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Validation Submitted`);
          getData();
          setErrMsg(response?.data?.msg);
          clearCourier("");
          setShowSubmit(false);
          resetForm();
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage)
          setErrMsg(response?.data?.msg);
          setShowSubmit(false);
          resetForm();
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setErrMsg(
            response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ")
          );
          toast.error(errorMessages)
          setShowSubmit(false);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage)
          setErrMsg(response?.response?.data?.msg);
          setShowSubmit(false);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg;
          setErrMsg(response?.data?.msg);
          setShowSubmit(false);
          if(response?.data?.msg == "Scanned number of shipments is not equal to the assigned number"){
          setShowAssign(true);
          }
          resetForm();
        }
      });
    },
  });
  const addDeliveryValidation = useFormik({
    initialValues: {
      employeeCode: trackId,
    },
    validationSchema: Yup.object({
      employeeCode: Yup.string().required("Courier is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postdeliveryValidation(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          setValidationId(response?.data?.data?.validationCode);
          sessionStorage.setItem("ValidationId", response?.data?.data?.validationCode);
          sessionStorage.setItem("SelectedCourier", values?.employeeCode);
          getData();
          setShow(false);
          resetForm();
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
          toast.error(errorMessages);
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setSelectedOptionCourierDl(null);
          setCourierDlPage(-1);
          resetForm();
        }
      });
    },
  });
  const countCourierTasks = async (courierId) =>{
    const res = await countTasks(courierId);
    setAssignCount(res?.data?.data?.assigned);
    sessionStorage.setItem(
      "assignedCount",
      res?.data?.data?.assigned
    );
    setScannedCount(res?.data?.data?.scanned);
    sessionStorage.setItem(
      "scannedCount",
      res?.data?.data?.scanned
    );
  }
  const [dlData, setDlData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [isDelete, setIsDelete] = useState("");
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (packageTrackingId) {
      params += `&packageTrackingId=${packageTrackingId}`;
    }
    if (courierId) {
      params += `&employeeCode=${courierId}`;
    }
    if (isMultiple) {
      params += `&isMultiple=${isMultiple}`;
    }
    let courierParams = "";
    if (courierId) {
    courierParams += courierId;
    const data = await getdeliveryValidation(courierParams);
    setTotalCount(data?.data?.data?.totalElements);
    setDlData(data?.data?.data);
  }
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    packageTrackingId,
    courierId,
    isMultiple,
    ispostAdded,
  ]);
  const exporttoExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (packageTrackingId) {
      params += `&packageTrackingId=${packageTrackingId}`;
    }
    if (courierId) {
      params += `&employeeCode=${courierId}`;
    }
    if (isMultiple) {
      params += `&isMultiple=${isMultiple}`;
    } 
    try {
      const res = await exportDeliveryValidation(params);
      if (res.status == 200) {
        fileDownload(res.data, `Delivery Validations.xlsx`);
      } else if (res?.response?.status === 500) {
        toast.error("Internal Server Error");
      } else {
        toast.error(res.response.statusText);
      }
    } catch (err) {}
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [userHub,setUserHub] = useState("")
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  const loadMoreOptionsCourierVal = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierdlPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierDlPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub,loadingMore, courierdlPage, prevSearch, setPrevSearch]
  );
  const clearCourier = () => {
    removeDeliveryValidation(validationId);
    setDlData([]);
    sessionStorage.removeItem("SelectedCourier");
    sessionStorage.removeItem("ValidationId");
    sessionStorage.removeItem("assignedCount");
    sessionStorage.removeItem("scannedCount");
    sessionStorage.removeItem("isValidated");
    setSelectedOptionCourier(null);
    setCourierId("");
    setValidationId(false);
    setValidationId(null);
  };
  const makeDlOfd = async () => {
    try {
      const res = await makeOfd(validationId);
      if (res?.data?.status == "ERROR") {
        const errorMessage = res?.data?.msg;
        toast.error(errorMessage);
        handleOfdClose();
        setShowAssign(false);
      } else if (res?.response?.data?.status == "ERROR") {
        const errorMessage = res?.response?.data?.msg;
        toast.error(errorMessage);
        handleOfdClose();
        setShowAssign(false);
      } else {
        toast.success("Added for OFD");
        clearCourier();
        setispostAdded(!ispostAdded);
        handleOfdClose();
        setShowAssign(false);
      }
    } catch (err) {}
  };
  return (
    <div>
      <div>
        <div className="displayFlex alignItemCenter justifyContent_end">
        <div className="totals mr_auto mbtm_10 ml_inherit">
          {validationId !== null &&
            sessionStorage.getItem("ValidationId") !== null && (
                <><span className="total-assign mt_15">
                Assigned : {sessionStorage?.getItem("assignedCount")}
              </span><span className="total-success mt_15">Scanned : {sessionStorage?.getItem("scannedCount")}</span>
              </>
            )}
              {(roleHandler(role, "AONE_OPS.write") ||
  roleHandler(role, "AONE_OPS.admin") ||
  roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write")) && (
  <>
    {validationId === null && sessionStorage.getItem("ValidationId") == null ? (
      <button
        className="activeFilterBtn mt_6pix"
        onClick={() => setShow(true)}
      >
        Start Validation
      </button>
    ) : (
      <div className="totalCourier mr_auto ml_0 mt_5">
        <span className="total-courier mt_6pix mt_5">
          Courier: {sessionStorage.getItem("SelectedCourier")}
          {sessionStorage.getItem("SelectedCourier") && (
            <button
              className="tableBtn"
              title="Remove Courier"
              onClick={clearCourier}
            >
              <span>
                <img src={removeIcon} className="h18" />
              </span>
            </button>
          )}
        </span>
      </div>
    )}
  </>
)}
                {validationId !== null &&
              sessionStorage.getItem("ValidationId") !== null && (
                <>
                  <input
                    type="text"
                    value={trackId}
                    className={"mainInput mt_10"}
                    style={
                      trackId?.length > 0
                        ? { border: "2px solid #ec171c", width: "auto" }
                        : { width: "auto" }
                    }
                    placeholder="Package Tracking Id"
                    onChange={(e) => {
                      setTrackId(e.target.value);
                      validateDl.setFieldValue(
                        "packageTrackingId",
                        e.target.value
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        validateDl.handleSubmit();
                      }
                    }}
                  />
                  <button
                    className="activeFilterBtn ml_0 mt_15"
                    onClick={validateDl.handleSubmit}
                  >
                    Validate
                  </button>
                </>
              )}
              {(dlData?.length > 0 && validationId !== null) &&
                <button
                  className="activeFilterBtn mt_15"
                  onClick={() => setShowSubmit(true)}
                >
                  Submit
                </button>
              }
              </div>
          <div className="displayFlex alignItemCenter justifyContent_end mb_16">
            <button
              className="filterBtn plus_icon mleft_10"
              title="Export"
              onClick={() => exporttoExcel()}
            >
              <span>
                <img src={downloadIcon} className="h18" />
              </span>
            </button>
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
          </div>
        </div>
        <PaginationTable
          tableData={dlData}
          tbl={"DlValidations"}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setSize={setSize}
          totalCount={totalCount}
          setSortField={setSortField}
          sortField={sortField}
          statusHandler={statusHandler}
          setStatusHandler={setStatusHandler}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Start Validation
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Courier
                  </label>
                  <AsyncSearchInput
                    id="employeeCode"
                    label="employeeCode"
                    name="employeeCode"
                    value={selectedOptionCourierDl}
                    loadMoreOptions={loadMoreOptionsCourierVal}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(courier) => courier.label}
                    getOptionValue={(courier) => courier.value}
                    onChange={async (selectedOptionCourierDl) => {
                      setSelectedOptionCourierDl(selectedOptionCourierDl);
                      addDeliveryValidation?.setFieldValue(
                        "employeeCode",
                        selectedOptionCourierDl
                          ? selectedOptionCourierDl.value
                          : ""
                      );
                      setCourierId(
                        selectedOptionCourierDl ? selectedOptionCourierDl.value : ""
                      );
                      countCourierTasks(selectedOptionCourierDl.value);
                    }}
                    placeholder="Courier"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                  {addDeliveryValidation.touched.employeeCode &&
                  addDeliveryValidation.errors.employeeCode ? (
                    <p className="requiredText">
                      {addDeliveryValidation.errors.employeeCode}
                    </p>
                  ) : null}
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_20">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={addDeliveryValidation.handleSubmit}
                >
                  Start
                </button>
              </div>
              <div>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
        {showSubmit && (
          <Modal
            className="scanConfirmpopup"
            show={showSubmit}
            onHide={handleSubmitClose}
            centered
          >
            <Modal.Body>
              <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
                Do you want to submit?
              </h2>
            </Modal.Body>
            <Modal.Footer style={{ flexWrap: "unset" }}>
              <div>
                <button
                  className="deletebtn cancelPrimary"
                  onClick={() => handleSubmitClose()}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="deletebtn confirmPrimary"
                  onClick={submitDl.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {showAssign && (
          <Modal
            className="scanConfirmpopup"
            show={showAssign}
            onHide={handleassignClose}
            centered
          >
            <Modal.Body>
              <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
                {errorMsg}
              </h2>
              <>
              </>
            </Modal.Body>
            <Modal.Footer style={{ flexWrap: "unset" }}>
              <div>
                <button
                  className="deletebtn cancelPrimary"
                  onClick={() => handleassignCancel()}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="deletebtn confirmPrimary"
                  onClick={makeDlOfd}
                >
                  Proceed
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default DeliveryValidation;
