import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../src/assets/img/arrow-dropright_icon.svg";
import templateIcon from "../assets/img/template_icon.svg";
import importIcon from "../assets/img/importIcon.svg";
import downloadicon from "../assets/img/download_icon.svg";
import removeIcon from "../assets/img/cancelIcon.svg";
import syncicon from "../../src/assets/img/sync.svg";
import PaginationTable from "../components/Tables/paginationTable";
import OpenModal from "../components/modal/openModal";
import { useParams } from "react-router";
import { useAoneStore } from "../store/AoneStore";
import fileDownload from "js-file-download";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { UserContext } from "../helper/ProtectedRoute";
import SearchInput from "../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../components/dropdown/autoCompleteAsyncComponent";
const AddressMapping = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const prevent = useRef(false);
  const params = useParams();
  const location = useLocation();
  const [editPage, setEditPage] = useState(false);
  const [customerAccount, setCustomerAcount] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [country, setCountry] = useState("");
  const [customerAliance, setCustomerAlliance] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [addressData, setAddressData] = useState([]);
  const [regionresponse, setRegionData] = useState([]);
  const [cityResponse, setCityData] = useState([]);
  const [districtResponse, setDistrictData] = useState([]);
  const [cusPage, setCusPage] = useState(-1);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [districtPage, setDistrictpage] = useState(-1);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const [selectedOptionDistrict, setSelectedOptionDistrict] = useState(null);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
  const [isDelete, setIsDelete] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [resetKey, setResetKey] = useState(0);
  const [regionResetKey, setRegionResetKey] = useState(0);
  const [cityResetKey, setCityResetKey] = useState(0);
  const [districtResetKey, setDistrictResetKey] = useState(0);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const getRegions = useAoneStore((state) => state.getRegions);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const filterCities = useAoneStore((state) => state.filterCities);
  const getDistricts = useAoneStore((state) => state.getDistricts);
  const getZone = useAoneStore((state) => state.getZone);
  const getCountryMapping = useAoneStore((state) => state?.getCountryMapping);
  const getRegionMapping = useAoneStore((state) => state?.getRegionMapping);
  const getCityMapping = useAoneStore((state) => state?.getCityMapping);
  const getDistrictMapping = useAoneStore((state) => state?.getDistrictMapping);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const filterDistricts = useAoneStore((state) => state?.filterDistricts);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const getCountryMapTemplate = useAoneStore(
    (state) => state?.getCountryMapTemplate
  );
  const getRegionMapTemplate = useAoneStore(
    (state) => state?.getRegionMapTemplate
  );
  const getCityMapTemplate = useAoneStore((state) => state?.getCityMapTemplate);
  const getDistrictMapTemplate = useAoneStore(
    (state) => state?.getDistrictMapTemplate
  );
  const exportDistrictMap = useAoneStore((state) => state?.exportDistrictMap);
  const exportCountryMap = useAoneStore((state) => state?.exportCountryMap);
  const exportRegionMap = useAoneStore((state) => state?.exportRegionMap);
  const exportCityMap = useAoneStore((state) => state?.exportCityMap);
  const importCountryMap = useAoneStore((state) => state?.importCountryMap);
  const importRegionMap = useAoneStore((state) => state?.importRegionMap);
  const importCityMap = useAoneStore((state) => state?.importCityMap);
  const importDistrictMap = useAoneStore((state) => state?.importDistrictMap);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const cityArray = useAoneStore((state) => state?.cityArray);
  const districtDatas = useAoneStore((state) => state?.districtDatas);
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const setReactCharges = useAoneStore((state) => state?.setReactCharges);
  const [dropModalOpen, setDropModalOpen] = useState(false);
  const handleMenuOpen = () => {
    setDropModalOpen(true);
  };
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const handleMenuClose = () => {
    setDropModalOpen(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };
  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const handleMenuCharges = () => {
    setReactCharges(true);
  };
  const handleMenuCloseCharges = () => {
    setReactCharges(false);
  };
  const downloadTemplate = async () => {
    try {
      if (tab === 1) {
        const res = await getCountryMapTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Country Mapping Template.xlsx`);
        }
      } else if (tab === 2) {
        const res = await getRegionMapTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Region Mapping Template.xlsx`);
        }
      } else if (tab === 3) {
        const res = await getCityMapTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `City Mapping Template.xlsx`);
        }
      } else {
        const res = await getDistrictMapTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `District Mapping Template.xlsx`);
        }
      }
    } catch (err) {}
  };
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
    setCustomerAcount("");
    setCustomerName("");
    setCountry("");
    setCustomerAlliance("");
    setRegion("");
    setCity("");
    setDistrict("");
    navigate("/configs/addressmapping");
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=customerName`;
    if (tab === 1) {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      const data = await getCountryMapping(params);
      setTotalCount(data.data.data.totalElements);
      setAddressData(data.data.data.content);
    } else if (tab === 2) {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      const data = await getRegionMapping(params);
      setTotalCount(data.data.data.totalElements);
      setRegionData(data.data.data.content);
    } else if (tab === 3) {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      if (city) {
        params += `&cityCode=${city}`;
      }
      const data = await getCityMapping(params);
      setTotalCount(data.data.data.totalElements);
      setCityData(data.data.data.content);
    } else {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      if (city) {
        params += `&cityCode=${city}`;
      }
      if (district) {
        params += `&districtCode=${district}`;
      }
      const data = await getDistrictMapping(params);
      setTotalCount(data.data.data.totalElements);
      setDistrictData(data.data.data.content);
    }
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=customerName`;
    if (tab === 1) {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      try {
        const res = await exportCountryMap(params);
        if (res.status == 200) {
          fileDownload(res.data, `Country Mapping.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 2) {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      try {
        const res = await exportRegionMap(params);
        if (res.status == 200) {
          fileDownload(res.data, `Region Mapping.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 3) {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      if (city) {
        params += `&cityCode=${city}`;
      }
      try {
        const res = await exportCityMap(params);
        if (res.status == 200) {
          fileDownload(res.data, `City Mapping.xlsx`);
        }
      } catch (err) {}
    } else {
      if (customerAccount) {
        params += `&customerAccount=${customerAccount}`;
      }
      if (customerAliance) {
        params += `&customerAlias=${customerAliance}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      if (city) {
        params += `&cityCode=${city}`;
      }
      if (district) {
        params += `&districtCode=${district}`;
      }
      try {
        const res = await exportDistrictMap(params);
        if (res.status == 200) {
          fileDownload(res.data, `District Mapping.xlsx`);
        }
      } catch (err) {}
    }
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    getCountries(paramslink);
    filterCities(paramslink);
    getDistricts(paramslink);
    let paramslk = `?page=${0}&size=${10}`;
    getZone(paramslk);
    let paramlink = `?page=${0}&size=${60}`;
    getRegions(paramlink);
  }, []);
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    tab,
    editPage,
    sortField,
    deleteHandler,
    location,
    modalHandler,
    addModalHandler,
    statusHandler,
    saveHandler,
    isDelete,
    customerAccount,
    customerName,
    country,
    customerAliance,
    region,
    city,
    district,
  ]);
  //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            let importFunction;
            switch (tab) {
              case 1:
                importFunction = importCountryMap;
                break;
              case 2:
                importFunction = importRegionMap;
                break;
              case 3:
                importFunction = importCityMap;
                break;
              default:
                importFunction = importDistrictMap;
            }
            importFunction(formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [loadingMore, setLoadingMore] = useState(false);
  const [originCityPage, setOriginCitypage] = useState(-1);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const loadMoreCusOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  const loadMoreCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : originCityPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setOriginCitypage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if(country){
          endpoint +=  `&countryIsoCode3=${country}`;
        }
        if(region){
          endpoint +=  `&regionCode=${region}`;
        }
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${search}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, originCityPage,region,country]
  );
  const loadMoreDistrictOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : districtPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDistrictpage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20&fields=countryName`;
        if(country){
          endpoint +=  `&countryIsoCode3=${country}`;
        }
        if(region){
          endpoint +=  `&regionCode=${region}`;
        }
        if(city){
          endpoint +=  `&cityCode=${city}`;
        }
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${search}`;
        }
        const response = await filterDistricts(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, districtPage,country,region,city]
  );
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/configs/addresses")}
              >
                Configs
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/configs/addressmapping")}
              >
                Address Mappings
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight:
                    editPage && params?.id === undefined ? "bolder" : "525",
                }}
              >
                {tab == 1
                  ? "Countries"
                  : tab == 2
                  ? "Regions"
                  : tab == 3
                  ? "Cities"
                  : "Districts"}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(1);
            setSelectedOptionCity(null);
            setSelectedOptionCustomer(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setDistrictResetKey((prevKey) => prevKey + 1);
            setOriginCitypage(-1);
            setCustomerAcount("");
            setCusPage(-1);
            setSortField("-updatedAt");
          }}
        >
          Countries
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(2);
            setSelectedOptionCity(null);
            setSelectedOptionCustomer(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setDistrictResetKey((prevKey) => prevKey + 1);
            setOriginCitypage(-1);
            setCustomerAcount("");
            setCusPage(-1);
            setSortField("-updatedAt");
          }}
        >
          Regions
        </button>
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(3);
            setSelectedOptionCity(null);
            setSelectedOptionCustomer(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setDistrictResetKey((prevKey) => prevKey + 1);
            setOriginCitypage(-1);
            setCustomerAcount("");
            setCusPage(-1);
            setSortField("-updatedAt");
          }}
        >
          Cities
        </button>
        <button
          className={`filterBtn ${tab == 4 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(4);
            setSelectedOptionCity(null);
            setSelectedOptionCustomer(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setDistrictResetKey((prevKey) => prevKey + 1);
            setOriginCitypage(-1);
            setCustomerAcount("");
            setCusPage(-1);
            setSortField("-updatedAt");
          }}
        >
          Districts
        </button>
      </div>

      <div>
        {!editPage && (
          <> <div style={{ position: "relative", width: "100%" }}>
            {tab === 1 ? (
                <Row>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Customer:
                    </label>
                    <AsyncSearchInput
                     key={resetKey}
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOptionCustomer}
                      loadMoreOptions={loadMoreCusOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(customer) => customer.label}
                      getOptionValue={(customer) => customer.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      onChange={(selectedOptionCustomer) => {
                        setSelectedOptionCustomer(selectedOptionCustomer);
                        if (selectedOptionCustomer) {
                          setCustomerAcount(selectedOptionCustomer.value);
                        } else {
                          setCustomerAcount(null);
                        }
                      } }
                      placeholder="Customer" />
                  </Col>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Customer's Alias:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                      <input
                        type="text"
                        value={customerAliance}
                        className={customerAliance?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"}
                        style={customerAliance?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}}
                        onChange={(e) => setCustomerAlliance(e.target.value)} />
                      {customerAliance && (
                        <button
                          className="cancel_btn clear-btn"
                          type="button"
                          onClick={() => setCustomerAlliance("")}
                        >
                          <span>
                            <img src={removeIcon} alt="Clear" />
                          </span>
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      id="country"
                      label="Country Name"
                      name="country"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        setCountry(selectedOption ? selectedOption.isoCode3 : "");
                      } }
                      onMenuOpen={handleMenuOpencity}
                      onMenuClose={handleMenuClosecity}
                      menuIsOpen={isReactselectCity}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className={country?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"} />
                  </Col>
                </Row>
            ) : tab === 2 ? (
                <Row>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Customer:
                    </label>
                    <AsyncSearchInput
                      key={resetKey}
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOptionCustomer}
                      loadMoreOptions={loadMoreCusOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(customer) => customer.label}
                      getOptionValue={(customer) => customer.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      onChange={(selectedOptionCustomer) => {
                        setSelectedOptionCustomer(selectedOptionCustomer);
                        if (selectedOptionCustomer) {
                          setCustomerAcount(selectedOptionCustomer.value);
                        } else {
                          setCustomerAcount(null);
                        }
                      } }
                      placeholder="Customer" />
                  </Col>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Customer's Alias:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                      <input
                        type="text"
                        value={customerAliance}
                        className={customerAliance?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"}
                        style={customerAliance?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}}
                        onChange={(e) => setCustomerAlliance(e.target.value)} />
                      {customerAliance && (
                        <button
                          className="cancel_btn clear-btn"
                          type="button"
                          onClick={() => setCustomerAlliance("")}
                        >
                          <span>
                            <img src={removeIcon} alt="Clear" />
                          </span>
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="country"
                      label="Country Name"
                      name="country"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={async (selectedOption) => {
                        setCountry(selectedOption ? selectedOption.isoCode3 : "");
                        let params = `?page=${newPage}&size=100&sortBy=${sortField}&fields=countryName&fields=regionName`;
                        if (selectedOption) {
                          params += `&countryIsoCode3=${selectedOption.isoCode3}`;
                        }
                        const regionData = await getRegions(params);
                        setRegion("");
                        setRegionResetKey((prevKey) => prevKey + 1);
                      } }
                      onMenuOpen={handleMenuOpencity}
                      onMenuClose={handleMenuClosecity}
                      menuIsOpen={isReactselectCity}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className={country?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"} />
                  </Col>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Region:
                    </label>
                    <SearchInput
                     key={regionResetKey}
                      id="region"
                      label="Region"
                      name="region"
                      options={regionsData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        setRegion(selectedOption ? selectedOption.code : "");
                      } }
                      onMenuOpen={handleMenudesregion}
                      onMenuClose={handleMenuClosedesregion}
                      menuIsOpen={isReactdesRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Region"
                      className={region?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"} />
                  </Col>
                </Row>
            ) : tab == 3 ? (
              <Row>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 mt_10">
                    Customer:
                  </label>
                  <AsyncSearchInput
                    key={resetKey}
                    id="customer"
                    label="customer"
                    name="customer"
                    value={selectedOptionCustomer}
                    loadMoreOptions={loadMoreCusOptions}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(customer) => customer.label}
                    getOptionValue={(customer) => customer.value}
                    onMenuOpen={handleMenuOpenregion}
                    onMenuClose={handleMenuCloseregion}
                    menuIsOpen={isReactselectRegion}
                    onChange={(selectedOptionCustomer) => {
                      setSelectedOptionCustomer(selectedOptionCustomer);
                      if (selectedOptionCustomer) {
                        setCustomerAcount(selectedOptionCustomer.value);
                      } else {
                        setCustomerAcount(null);
                      }
                    } }
                    placeholder="Customer" />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Customer's Alias:
                  </label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={customerAliance}
                      className={customerAliance?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"}
                      style={customerAliance?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}}
                      onChange={(e) => setCustomerAlliance(e.target.value)} />
                    {customerAliance && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setCustomerAlliance("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Country:
                  </label>
                  <SearchInput
                    key={resetKey}
                    id="country"
                    label="Country Name"
                    name="country"
                    options={countriesData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.isoCode3}
                    handleChange={async (selectedOption) => {
                      setCountry(selectedOption ? selectedOption.isoCode3 : "");
                      console.log("here")
                      let params = `?page=${newPage}&size=100&sortBy=${sortField}&fields=countryName&fields=regionName`;
                      if (selectedOption) {
                        params += `&countryIsoCode3=${selectedOption.isoCode3}`;
                      }
                      const regionData = await getRegions(params);
                      setDistrictpage(-1);
                      setOriginCitypage(-1);
                      setRegion("");
                      setRegionResetKey((prevKey) => prevKey + 1);
                      setCity("");
                      setSelectedOptionCity(null);
                      setOriginCitypage(-1);
                      setCityResetKey((prevKey) => prevKey + 1);
                    } }
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    menuIsOpen={isReactselectCity}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Country"
                    className={country?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"} />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Region:
                  </label>
                  <SearchInput
                    key={regionResetKey}
                    id="region"
                    label="Region"
                    name="region"
                    options={regionsData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.code}
                    handleChange={(selectedOption) => {
                      setRegion(selectedOption ? selectedOption.code : "");
                      setDistrictpage(-1);
                      setOriginCitypage(-1);
                      setCity("");
                      setSelectedOptionCity(null);
                      setOriginCitypage(-1);
                      setCityResetKey((prevKey) => prevKey + 1);
                    } }
                    onMenuOpen={handleMenudesregion}
                    onMenuClose={handleMenuClosedesregion}
                    menuIsOpen={isReactdesRegion}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Region"
                    className={region?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"} />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    City:
                  </label>
                  <AsyncSearchInput
                     key={cityResetKey}
                    id="city"
                    label="City"
                    name="city"
                    value={selectedOptionCity}
                    loadMoreOptions={loadMoreCityOptions}
                    shouldLoadMore={shouldLoadMore}
                    className={city?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuRates}
                    onMenuClose={handleMenuCloseRates}
                    menuIsOpen={isReactRates}
                    onChange={(selectedOptionCity) => {
                      setSelectedOptionCity(selectedOptionCity);
                      setDistrictpage(-1);
                      if (selectedOptionCity) {
                        setCity(selectedOptionCity.value);
                      } else {
                        setCity(null);
                      }
                    } }
                    placeholder="City" />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 mt_10">
                    Customer:
                  </label>
                  <AsyncSearchInput
                    key={resetKey}
                    id="customer"
                    label="customer"
                    name="customer"
                    value={selectedOptionCustomer}
                    loadMoreOptions={loadMoreCusOptions}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(customer) => customer.label}
                    getOptionValue={(customer) => customer.value}
                    onMenuOpen={handleMenuOpenregion}
                    onMenuClose={handleMenuCloseregion}
                    menuIsOpen={isReactselectRegion}
                    onChange={(selectedOptionCustomer) => {
                      setSelectedOptionCustomer(selectedOptionCustomer);
                      if (selectedOptionCustomer) {
                        setCustomerAcount(selectedOptionCustomer.value);
                      } else {
                        setCustomerAcount(null);
                      }
                    } }
                    placeholder="Customer" />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 mt_10">
                    Customer's Alias:
                  </label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={customerAliance}
                      className={customerAliance?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"}
                      style={customerAliance?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}}
                      onChange={(e) => setCustomerAlliance(e.target.value)} />
                    {customerAliance && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setCustomerAlliance("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Country:
                  </label>
                  <SearchInput
                   key={resetKey}
                    id="country"
                    label="Country Name"
                    name="country"
                    options={countriesData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={async (selectedOption) => {
                      setCountry(selectedOption ? selectedOption.isoCode3 : "");
                      let params = `?page=${newPage}&size=100&sortBy=${sortField}&fields=countryName&fields=regionName`;
                      if (selectedOption) {
                        params += `&countryIsoCode3=${selectedOption.isoCode3}`;
                      }
                      setDistrictpage(-1);
                      setOriginCitypage(-1);
                      const regionData = await getRegions(params);
                      setRegion("");
                      setRegionResetKey((prevKey) => prevKey + 1);
                      setCity("");
                      setSelectedOptionCity(null);
                      setOriginCitypage(-1);
                      setCityResetKey((prevKey) => prevKey + 1);
                      setDistrict("");
                      setSelectedOptionDistrict(null);
                      setDistrictpage(-1);
                      setDistrictResetKey((prevKey) => prevKey + 1);
                    } }
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    menuIsOpen={isReactselectCity}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Country"
                    className={country?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"} />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Region:
                  </label>
                  <SearchInput
                     key={regionResetKey}
                    id="region"
                    label="Region"
                    name="region"
                    options={regionsData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={(selectedOption) => {
                      setRegion(selectedOption ? selectedOption.code : "");
                      setDistrictpage(-1);
                      setOriginCitypage(-1);
                      setCity("");
                      setSelectedOptionCity(null);
                      setOriginCitypage(-1);
                      setCityResetKey((prevKey) => prevKey + 1);
                      setDistrict("");
                      setSelectedOptionDistrict(null);
                      setDistrictpage(-1);
                      setDistrictResetKey((prevKey) => prevKey + 1);
                    } }
                    onMenuOpen={handleMenudesregion}
                    onMenuClose={handleMenuClosedesregion}
                    menuIsOpen={isReactdesRegion}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Region"
                    className={region?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"} />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    City:
                  </label>
                  <AsyncSearchInput
                   key={cityResetKey}
                    id="city"
                    label="City"
                    name="city"
                    value={selectedOptionCity}
                    loadMoreOptions={loadMoreCityOptions}
                    shouldLoadMore={shouldLoadMore}
                    className={city?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuRates}
                    onMenuClose={handleMenuCloseRates}
                    menuIsOpen={isReactRates}
                    onChange={(selectedOptionCity) => {
                      setSelectedOptionCity(selectedOptionCity);
                      if (selectedOptionCity) {
                        setCity(selectedOptionCity.value);
                      } else {
                        setCity(null);
                      }
                      setDistrict("");
                      setSelectedOptionDistrict(null);
                      setDistrictpage(-1);
                      setDistrictResetKey((prevKey) => prevKey + 1);
                    } }
                    placeholder="City" />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    District:
                  </label>
                  <AsyncSearchInput
                   key={districtResetKey}
                    id="district"
                    label="district"
                    name="district"
                    value={selectedOptionDistrict}
                    loadMoreOptions={loadMoreDistrictOptions}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuCharges}
                    onMenuClose={handleMenuCloseCharges}
                    menuIsOpen={isReactCharges}
                    onChange={(selectedOptionDistrict) => {
                      setSelectedOptionDistrict(selectedOptionDistrict);
                      if (selectedOptionDistrict) {
                        setDistrict(selectedOptionDistrict.value);
                      } else {
                        setDistrict(null);
                      }
                    } }
                    placeholder="District" />
                </Col>
              </Row>
            )}
          </div>
              <div className="displayFlex alignItemCenter justifyContent_end mb_8 mt_-24">
                <div className="margin_top40">
                  <div className="displayFlex gap_12">
                    {(role?.includes("AONE_CONFIGS.write") ||
                      role?.includes("AONE_CONFIGS.admin") ||
                      role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
                      role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin")) && (
                        <>
                          <button
                            style={{ marginLeft: "10px" }}
                            className="filterBtn plus_icon ml_10"
                            title={tab === 1
                              ? "Country Mapping Template"
                              : tab === 2
                                ? "Region Mapping Template"
                                : tab === 3
                                  ? "City Mapping Template"
                                  : "District Mapping Template"}
                            onClick={downloadTemplate}
                          >
                            <span>
                              <img src={templateIcon} className="h18" />
                            </span>
                          </button>
                          <div
                            title={tab === 1
                              ? "Import Country Mapping"
                              : tab === 2
                                ? "Import Region Mapping"
                                : tab === 3
                                  ? "Import City Mapping"
                                  : "Import District Mapping"}
                          >
                            <label
                              className="filterBtn plus_icon"
                              htmlFor="uploadFile"
                            >
                              <span>
                                <img src={importIcon} className="h18" />
                              </span>
                              <input
                                type="file"
                                id="uploadFile"
                                onInput={(e) => handleFileChange(e)}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                hidden />
                            </label>
                          </div>
                        </>
                      )}
                    <button
                      className="filterBtn"
                      title={tab === 1
                        ? "Export Country Mapping"
                        : tab === 2
                          ? "Export Region Mapping"
                          : tab === 3
                            ? "Export City Mapping"
                            : "Export District Mapping"}
                      onClick={() => exportToExcel()}
                    >
                      <span>
                        <img src={downloadicon} className="h18" />
                      </span>
                    </button>
                    <button
                      className="filterBtn plus_icon"
                      title="Sync"
                      onClick={() => syncronizeRoles()}
                    >
                      <span>
                        <img src={syncicon} className="h18" />
                      </span>
                    </button>
                    {(role?.includes("AONE_CONFIGS.write") ||
                      role?.includes("AONE_CONFIGS.admin") ||
                      role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.write") ||
                      role?.includes("AONE_CONFIGS_ADDRESS-MAPPINGS.admin")) && (
                        <button
                          className="filterBtn plus_icon"
                          title="Add"
                          onClick={() => setAddModalHandler(!addModalHandler)}
                          tabIndex="-1"
                        >
                          +
                        </button>
                      )}
                  </div>
                </div>
            </div></>
        )}
        {!editPage && (
          <PaginationTable
            tableData={
              tab == 1
                ? addressData
                : tab === 2
                ? regionresponse
                : tab === 3
                ? cityResponse
                : districtResponse
            }
            tbl={
              tab == 1
                ? "CountriesMap"
                : tab === 2
                ? "RegionsMap"
                : tab === 3
                ? "CitiesMap"
                : "DistrictsMap"
            }
            title={
              tab === 1
                ? "Edit Country Mapping"
                : tab === 2
                ? "Edit Region Mapping"
                : tab === 3
                ? "Edit City Mapping"
                : "Edit District Mapping"
            }
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
        {addModalHandler && (
          <OpenModal
            modalHandler={addModalHandler}
            setModalHandler={setAddModalHandler}
            title={
              tab === 1
                ? "New Country Mapping"
                : tab === 2
                ? "New Region Mapping"
                : tab === 3
                ? "New City Mapping"
                : "New District Mapping"
            }
            editHandler={editHandler}
            setEditHandler={setEditHandler}
          />
        )}
      </div>
    </div>
  );
};

export default AddressMapping;
