import React, { useState, useEffect, useContext } from 'react'
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAoneStore } from "../../store/AoneStore"
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { roleHandler } from "../../helper/roleHandler"
import { UserContext } from "../../helper/ProtectedRoute";
const BankDetails = ({ setTab }) => {
  const navigate = useNavigate()
  const params = useParams();
  const [data, setData] = useState([])
  const globalcustomerAccount = useAoneStore((state) => state?.globalcustomerAccount);
  const postBankAddress = useAoneStore((state) => state?.postBankAddress);
  const editBankAddress = useAoneStore((state) => state?.editBankAddress);
  const getBankdetailbyId = useAoneStore((state) => state?.getBankdetailbyId);
  const setFormState = useAoneStore((state) => state?.setFormState);
  const setbankArrayData = useAoneStore((state) => state?.setbankArrayData);
  const bankArrayData = useAoneStore((state) => state?.bankArrayData);
  const bankState = useAoneStore((state) => state?.bankState);
  const bankAddressData = useAoneStore((state) => state?.bankAddressData);
  const isTabChanging = useAoneStore((state) => state?.isTabChanging);  const formState = useAoneStore((state) => state?.formState);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const addBankAddress = useFormik({
    initialValues: {
      iban: "",
    },
    validationSchema: Yup.object({
      iban: Yup.string()
      .trim()
      .required("IBAN Number is required")
      .min(10, "IBAN Number must be at least 10 characters long"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (params?.id) {
        const updatedValues = {
          ...values,
          id: data?.id 
        };
        if(updatedValues?.id !== undefined) {
        await editBankAddress(globalcustomerAccount, updatedValues).then((response) => {
          if (response?.data?.status == "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.status === 500) {
            toast.error(response?.response?.statusText);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else {
            toast.success("Bank Address Updated");
            setTab(4)
            resetForm();
          }
        });
      }else{
        await postBankAddress(globalcustomerAccount, values).then((response) => {
          if (response.status == "200") {
            toast.success("Bank Address Added for Account");
            setTab(4)
            resetForm();
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
      } else {
        if (globalcustomerAccount) {
          await postBankAddress(globalcustomerAccount, values).then((response) => {
            if (response.status == "200") {
              toast.success("Bank Address Added for Account");
              setTab(4)
              resetForm();
            } else {
              const errorMessage = response.response.data.msg.split(",")[0];
              toast.error(errorMessage);
            }
          });
        } else {
          toast.error("Customer Account need to be created before saving the bank and billing address")
        }
      }
    },
  });
  useEffect(() => {
    if (isTabChanging) {
      setFormState(addBankAddress.values);
    }
  }, [isTabChanging, addBankAddress.values, setFormState]);
  useEffect(() => {
    const hasNonEmptyValues = Object.values(bankState).some(value => value !== '');
    if (hasNonEmptyValues) {
      addBankAddress.setValues(bankState);
    }
  }, [bankState]);
  useEffect(() => {
    params?.id && getData()
  }, [params?.id])
  useEffect(() => {
    const mappedFields = Object.keys(addBankAddress.values).reduce((acc, field) => {
      acc[field] = data?.[field] || "";
      return acc;
    }, {});
    // Set form field values based on data
    addBankAddress.setValues((prevValues) => ({
      ...prevValues,
      ...mappedFields,
    }));
  }, [data])

  const getData = async () => {
    if(bankArrayData?.iban === undefined){
    const data = await getBankdetailbyId(params.id)
    setbankArrayData(data?.data?.data)
    setData(data?.data?.data)
    }else{
   setData(bankArrayData)
    }
  }
  return (
    <div className='row'>
      <div className='col-md-6' style={{ padding: "12px" }}>
        <div className='row mb_22'>
          <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8'>
            <label>IBAN: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className='col-md-8'>
            <input
              type='text' placeholder='Iban' className='mainInput' name="iban"
              value={addBankAddress.values.iban}
              onChange={(e) =>
                addBankAddress.setFieldValue("iban", e.target.value)
              }
            />
            {addBankAddress.touched.iban && addBankAddress.errors.iban ? (
              <p className="requiredText">{addBankAddress.errors.iban}</p>
            ) : null}
          </div>
        </div>
        {(roleHandler(role, "AONE_CRM.write") || roleHandler(role, "AONE_CRM.admin") || roleHandler(role, "AONE_CRM_CUSTOMERS.admin") || roleHandler(role, "AONE_CRM_CUSTOMERS.write") || roleHandler(role, "AONE_CONFIGS.write") || roleHandler(role, "AONE_CONFIGS.admin") || roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") || roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) && (
          <div className='row mb_22' style={{ marginTop: "40px" }}>
            <div className='col-md-12 displayFlex alignItemCenter justifyContent_end gap_16'>
              <button type="button" onClick={addBankAddress.handleSubmit} className='blue_btn'>
                Save
              </button>
              <button className='cancel_btn'>
                Cancel
              </button>
            </div>
          </div>)}
      </div>
    </div>
  )
}

export default BankDetails