import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../../store/AoneStore";
import { useTripStore } from "../../../store/TripMgt/TripStore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import { UserContext } from "../../../helper/ProtectedRoute";
import StepperComponent from "../../../components/stepper/stepper";
const Details = ({}) => {
  const params = useParams();
  const getTripsbyId = useTripStore((state) => state?.getTripsbyId);
  const tripsDetails = useTripStore((state) => state?.tripsDetails);
  useEffect(() => {
    getTripsbyId(params?.id);
  }, []);
  const [expanded, setExpanded] = useState(false);
  const handleExpandAll = () => {
    const newExpandedState = !expanded;
    if (newExpandedState) {
      setExpanded(["panel2", "panel3"]);
    } else {
      setExpanded(false);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const steps = ["Created", "Loaded", "Started", "Arrived at Hub", "Ended"];
  const statusCodeToStep = (statusCode) => {
    switch (statusCode) {
      case 500:
        return 1;
      case 501:
        return 2;
      case 502:
        return 3;
      case 504:
      case 505:
        return 4;
      case 507:
        return 5;
      default:
        return 0;
    }
  };
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (tripsDetails?.data?.data?.statusCode) {
      const step = statusCodeToStep(tripsDetails.data.data.statusCode);
      setActiveStep(step);
    }
  }, [tripsDetails]);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div style={{ position: "relative" }}>
      <Button
        onClick={handleExpandAll}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "8px 16px",
          backgroundColor: "#ec171c",
          color: "#ffffff",
          borderRadius: "4px",
          cursor: "pointer",
          border: "none",
          outline: "none",
        }}
      >
        {expanded ? "Collapse" : "Expand"}
      </Button>
      <div className="displayFlex tabletab_section"></div>
      {tripsDetails?.status !== "CANCELLED" && (
        <div className="mt_20">
          <StepperComponent
            activeStep={activeStep}
            steps={steps}
            className="mt_20"
          />
        </div>
      )}
      <Accordion defaultExpanded className="mt_20">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Trip Summary
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography>Driver:</Typography>
                    <Typography className="greycol">
                      {tripsDetails?.data?.data?.driverName
                        ? tripsDetails?.data?.data?.driverName
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Origin Hub:</Typography>
                    <Typography className="greycol">
                      {tripsDetails?.data?.data?.originHubName}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Destination Hub:</Typography>
                    <Typography className="greycol">
                      {tripsDetails?.data?.data?.destinationHubName}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">
                      Estimated arrival date:
                    </Typography>
                    <Typography className="greycol">
                      {tripsDetails?.data?.data?.estimatedArrivalDate
                        ? new Date(
                            tripsDetails?.data?.data?.estimatedArrivalDate
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Created at:</Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.createdAt
                        ? new Date(
                            tripsDetails.data?.data?.createdAt
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Start at:</Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.startedAt
                        ? new Date(
                            tripsDetails.data?.data?.startedAt
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">End at:</Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.endedAt
                        ? new Date(
                            tripsDetails.data?.data?.endedAt
                          ).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography>Route Distance:</Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.routDistance
                        ? tripsDetails.data?.data?.routDistance
                        : "-"}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="mt_10">Actual Distance:</Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.actualDistance
                        ? tripsDetails.data?.data?.actualDistance
                        : "-"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={
          Array?.isArray(expanded)
            ? expanded?.includes("panel2")
            : expanded === "panel2"
        }
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="customAccordionSummary"
        >
          <Typography variant="h6" fontWeight="bold">
            Content Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <Typography className="mt_10">
                      Content Weight Unit:
                    </Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.weightUnit
                        ? tripsDetails.data?.data?.weightUnit
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography className="mt_10">Content Weight:</Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.totalWeight
                        ? tripsDetails.data?.data?.totalWeight
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Typography className="mt_10">
                      Total Shipments Count:
                    </Typography>
                    <Typography className="greycol">
                      {tripsDetails.data?.data?.totalShipments
                        ? tripsDetails.data?.data?.totalShipments
                        : "-"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Details;
