import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useweightStore } from "../../../store/WeighingMgt/WeighingStore";
import { useDlFlowStore } from "../../../store/DeliveryFlowMgt/DeliveryFlowStore";
import DeletingModal from "../../../components/modal/deletingModal";
import { UserContext } from "../../../helper/ProtectedRoute";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Deliveryflow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [customer, setCustomer] = useState("");
  const [product, setProduct] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [dlFlowData, setDlFlowData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const filterProducts = useAoneStore((state) => state?.filterProducts);
  const productsData = useAoneStore((state) => state?.productsData);
  const getDeliveryFlow = useDlFlowStore((state) => state?.getDeliveryFlow);
  const weightToolList = useweightStore((state) => state?.weightToolList);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cusPage, setCusPage] = useState(-1);
  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };
  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      productsData?.data?.data?.content?.length < 1 ||
      productsData?.length < 1
    ) {
      filterProducts(paramslink);
    }
  }, []);
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (product) {
      params += `&productCode=${product}`;
    }
    const data = await getDeliveryFlow(params);
    setTotalCount(data?.data?.data?.totalElements);
    setDlFlowData(data?.data?.data?.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    location,
    saveHandler,
    customer,
    product,
    ispostAdded,
  ]);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/deliveryflow")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/ops/deliveryflow");
                }}
              >
                Delivery Flow
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Customer:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <AsyncSearchInput
                      id="customer"
                      label="customer"
                      name="customer"
                      value={selectedOptionCustomer}
                      loadMoreOptions={loadMoreOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      customStyles={{ control: { width: "180px" } }}
                      onChange={(selectedOptionCustomer) => {
                        setSelectedOptionCustomer(selectedOptionCustomer);
                        if (selectedOptionCustomer) {
                          setCustomer(selectedOptionCustomer.value);
                        } else {
                          setCustomer(null);
                        }
                      }}
                      placeholder="Customer"
                    />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Product:
                  </label>
                  <SearchInput
                    id="product"
                    label="product"
                    name="product"
                    options={productsData?.data?.data?.content || []}
                    getOptionLabel={(option) =>
                      `${option.code} - ${option.name}`
                    }
                    getOptionValue={(option) => option.code}
                    handleChange={(selectedOption) => {
                      setProduct(selectedOption ? selectedOption.code : "");
                    }}
                    onMenuOpen={handleMenudescountry}
                    onMenuClose={handleMenuClosedescountry}
                    menuIsOpen={isReactdesCountry}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Product"
                    customStyles={{ control: { width: "180px" } }}
                    className="mainInput"
                  />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Add"
                  tabIndex="-1"
                  onClick={() => navigate("/ops/deliveryflow/add")}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
      <PaginationTable
        tbl="DeliveryFlow"
        tableData={dlFlowData}
        size={size}
        setSize={setSize}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        totalCount={totalCount}
        setNewPage={setNewPage}
        newPage={newPage}
        sortField={sortField}
        setSortField={setSortField}
      />
    </div>
  );
};

export default Deliveryflow;
