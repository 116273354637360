import React,{ useState , useEffect} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams, useNavigate } from 'react-router';
import { useAoneStore } from '../../store/AoneStore';

const DetailedExpress = () => {

  const params = useParams();
  const navigate = useNavigate();

  const getInvoices = useAoneStore((state) => state?.getInvoices)
  
  const [ invoice, setInvoice ] = useState({})

  const getData = async () => {
    const queryParams = {
      waybillNo: `${params.id}`,
      invoiceId: "",
      interfaceLineGUID: "",
      status: null,
      bussinessLine: `${params.name}`,
      page: 0,
      size: 1
    }
    const data = await getInvoices(queryParams)
    setInvoice(data.data[0])
  }

  useEffect(()=>{
    getData();
  },[])

  return (
    <div>
      <div className=''>
        <div className='row alignItemCenter'>
          <div className='col-md-7'>
            <h3 className='mainheadtext' >
              <span className='mainheadlink' onClick={()=>navigate(-1)} >{params?.name.replace("_"," ")} INVOICE </span>
              &#62; <span className='mainheadlink'>DETAILS </span>
              {/* &#62; <span className='mainheadlink'>Approval </span> */}
            </h3>
          </div>
        </div>

        <div className='row pt30 justifyContent_spacebetween'>
          <div className='table_wrapper  col-md-8'>
            <table>
              <thead>
                <th>Waybill No</th>
                <th>Customer AccountNo</th>
                <th>Business Line</th>
                <th>Status</th>
              </thead>
              <tbody>
                <tr>
                  <td>{invoice?.waybillNo}</td>
                  <td>{invoice?.customerAccountNo}</td>
                  <td>{invoice?.bussinessLine}</td>
                  <td className=''>
                    <p className='status_process' style={{padding: "5px"}}>{invoice?.status}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='table_wrapper col-md-3'>
            <table>
              <thead>
                  <th>Action</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select class="actionInputHandler bg_grey" placeholder='Select Action'>
                      <option disabled selected>Select Action</option>
                      <option value="1">Approve</option>
                      <option value="1">Reject</option>
                      <option value="1">Add Notes</option>
                    </select>
                  </td>
                </tr> 
              </tbody>
            </table>            
          </div >      
        </div>

        <Tabs
          defaultActiveKey="Shipment_detail"
          id="uncontrolled-tab-example"
          className=" text_red"
        >
          <Tab eventKey="Shipment_detail" title="Shipment Detail">
            Scroll Through Other Tabs
          </Tab>
          <Tab eventKey="Customer_Info" title=" Customer Info">
          <div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Main Account Number</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.mainAccount ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Sub-Account Name</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.subAccountName ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Sub-Account Number</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.subAccountNo ?? "-"}</p>
                </div>
              </div>              
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Company Name</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.companyName ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Company Registration Number</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.companyRegistrationNumber ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Company VAT Number</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.companyVatNumber ?? "-"}</p>
                </div>
              </div>
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Contact Name</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.contactName ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Contact Phone</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.contactPhone ?? "-"}</p>
                </div>
              </div>
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Address</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.address ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>City</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.city ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Country</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.country ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Postal Code</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.postalCode ?? "-"}</p>
                </div>
              </div>    
            </div> 
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Email</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.email ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Phone</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.phone ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>E-invoice Email</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.einvoiceEmail ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Fax</label>
                  <p className='fontSize14'>{invoice?.customerInfo?.fax ?? "-"}</p>
                </div>
              </div>    
            </div>                        
          </div>
          </Tab>
          <Tab eventKey="Invoice_Data" title=" Invoice Data" >
          <div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Reference Number</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.referenceNo ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Waybill Number</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.waybillNo ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Main Account Number</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.mainAccount ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Sub Account Number</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.subAccountNo ?? "-"}</p>
                </div>
              </div>    
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Origin</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.origin ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Destination</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.destination ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Pickup Date</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.pickupDate ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Delivery Date</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.deliveryDate ?? "-"}</p>
                </div>
              </div>    
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Service Type</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.serviceTyoe ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Sub-Service Type</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.subServiceType ?? "-"}</p>
                </div>
              </div>  
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Pieces</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.pieces ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Weight</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.weight ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Temperature</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.temperature ?? "-"}</p>
                </div>
              </div>   
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>VAT Amount</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.vatAmount ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>VAT Percentage</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.vatPercentage ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Volume Of Cargo</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.volumeOfCargo ?? "-"}</p>
                </div>
              </div>                 
            </div>            
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Service Charge</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.serviceCharge ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Pod Charge</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.podCharge ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Oda Charge</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.odaCharge ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Cod Service Charge</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.codServiceCharge ?? "-"}</p>
                </div>
              </div>    
            </div> 
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Demurrage Charge</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.demurrageCharge ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Labour Charge</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.labourCharge ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Other Charge</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.otherCharge ?? "-"}</p>
                </div>
              </div>   
            </div>
            <div className='row mb_24'>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Net Amount</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.netAmount ?? "-"}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='subContent'>
                  <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>Total Amount</label>
                  <p className='fontSize14'>{invoice?.aimsInvoiceData?.totalAmount ?? "-"}</p>
                </div>
              </div>   
            </div>                                                                        
          </div>
          </Tab>
          <Tab eventKey="Notes" title="Notes" >
          <div>
            {
              invoice?.remarksList?.length > 0 && 
              invoice.remarksList.map((e,i) => {
                return <div key={i} className='row justifyContent_spacebetween notesContents'>
                        <div className='col-md-8'>
                          <div className='subContent'>
                            <label className='fontSize14 menacingCloudsText fontWeight500 mb_12 textDecoration_underline'>{e?.userName?.toUpperCase()}</label>
                            <p className='fontSize14'>{e?.message}</p>
                          </div>
                        </div>
                        <div className='col-md-3' style={{ textAlign: "end"}}>
                          <p className='fontSize14'>{e?.timestamp}</p>
                        </div>  
                </div>
              })
            }
          </div>
          </Tab>
        </Tabs>

      </div>        
    </div>
  )
}

export default DetailedExpress