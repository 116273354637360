import AoneService from "../axiosbase";
const url = process.env.REACT_APP_BASE_API_URL;

//get vehicle
export const getVehicles = async (params) => {
    try  {
        return await AoneService.get(`assets/api/v1/vehicles${params}`)
    } catch (error) {
        return error;
    }
}
//get replacement vehicles
export const getReplacementVehicles = async () => {
  try  {
      return await AoneService.get(`assets/api/v1/vehicles/replacement`)
  } catch (error) {
      return error;
  }
}
//get vehicle by code
  export const getVehiclesbyCode = async (params) => {
    try {
      return await AoneService.get(`assets/api/v1/vehicles/${params}`);
    } catch (error) {
      return error;
    }
  };
//post vehicle
export const postVehicles = async (data) => {
    try {
      return await AoneService.post("assets/api/v1/vehicles", data);
    } catch (error) {
      return error;
    }
  };
//edit vehicles
export const editVehicles = async (params,data) => {
    try {
      return await AoneService.put(`assets/api/v1/vehicles/${params}`, data);
    } catch (error) {
      return error;
    }
  };
//vehicle template
export const VehiclesTemplate = async () => {
  try {
    return await AoneService.get(
      `assets/api/v1/vehicles/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//upload vehicle
export const importVehicles = async (data) => {
    try {
      return await AoneService.post(`assets/api/v1/vehicles/import`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };
//export vehicle
export const exportVehicles = async (params) => {
    try {
      return await AoneService.get(`assets/api/v1/vehicles/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//delete vehicle
export const deleteVehicles = async (params) => {
    try {
      return await AoneService.delete(`assets/api/v1/vehicles/${params}`);
    } catch (error) {
      return error;
    }
  };
  //vehicle lookups
  export const getDimensionUnit = async () => {
    try {
      return await AoneService.get(`assets/api/v1/lookups/DimensionUnit`);
    } catch (error) {
      return error;
    }
  };
  export const getStatus = async () => {
    try {
      return await AoneService.get(`assets/api/v1/lookups/Status`);
    } catch (error) {
      return error;
    }
  };
  export const getVehicleDocumentType = async () => {
    try {
      return await AoneService.get(`assets/api/v1/lookups/VehicleDocumentType`);
    } catch (error) {
      return error;
    }
  };
  export const getVehicleType = async () => {
    try {
      return await AoneService.get(`assets/api/v1/lookups/VehicleType`);
    } catch (error) {
      return error;
    }
  };
  export const getWeightUnit = async () => {
    try {
      return await AoneService.get(`assets/api/v1/lookups/WeightUnit`);
    } catch (error) {
      return error;
    }
  };
  export const getDriverCourierList = async (param1,param2) => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/drivers?couriers=true&${param2}`);
    } catch (error) {
      return error;
    }
  };
  export const getVehicleStatus = async () => {
    try {
      return await AoneService.get(`assets/api/v1/lookups/VehicleStatus`);
    } catch (error) {
      return error;
    }
  };
//--------------------------------------------------//
//get vehicle documents
export const getVehicleDocument = async (params) => {
    try  {
        return await AoneService.get(`assets/api/v1/vehicles/document${params}`)
    } catch (error) {
        return error;
    }
}
//get vehicles document by vehiclecode
export const getVehicleDocumentbycode = async (params) => {
    try  {
        return await AoneService.get(`assets/api/v1/vehicles/${params}/documents`)
    } catch (error) {
        return error;
    }
}

//delete vehicle document
export const deleteVehicleDocuments = async (params) => {
    try {
      return await AoneService.delete(`assets/api/v1/vehicles/${params}/document/`);
    } catch (error) {
      return error;
    }
  };

//activate vehicle code
export const activateVehicleDocs = async (params) => {
    try  {
        return await AoneService.patch(`assets/api/v1/vehicles/activate`,params)
    } catch (error) {
        return error;
    }
}

//deactivate vehicle code
export const deactivateVehicleDocs = async (params) => {
    try  {
        return await AoneService.patch(`assets/api/v1/vehicles/de-activate`,params)
    } catch (error) {
        return error;
    }
}

//add vehicle documents
export const postVehicleDocument = async (params,data) => {
    try {
      return await AoneService.post(`assets/api/v1/vehicles/${params}/documents/`, data);
    } catch (error) {
      return error;
    }
  };
//edit vehicle document
export const EditVehicleDocument = async (params,data) => {
  try {
    return await AoneService.put(`assets/api/v1/vehicles/${params}/documents/`, data);
  } catch (error) {
    return error;
  }
};
//getvehicle document by code
export const getVehiclesDocsbyCode = async (params) => {
  try {
    return await AoneService.get(`assets/api/v1/vehicles/${params}/documents`);
  } catch (error) {
    return error;
  }
};
//add vehicle documenttype
export const importVehiclesDocuments = async (param1,param2,param3,data) => {
  try {
    return await AoneService.post(`assets/api/v1/vehicles/${param1}/documents/${param2}/${param3}`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };
  //-----------------------------------------------------------//
  //odometer
  //get odo
  export const getOdoMeter = async (params) => {
    try  {
        return await AoneService.get(`assets/api/v1/vehicles/odometer${params}`)
    } catch (error) {
        return error;
    }
}
  //post odo
  export const postOdoMeter = async (data) => {
    try {
      return await AoneService.post("assets/api/v1/vehicles/odometer", data);
    } catch (error) {
      return error;
    }
  };
  //delete odo
  export const deleteOdoMeter = async (params) => {
    try {
      return await AoneService.delete(`assets/api/v1/vehicles/odometer/${params}`);
    } catch (error) {
      return error;
    }
  };

  //get gps vendors
  export const getGpsvendor = async (params) => {
    try  {
        return await AoneService.get(`assets/api/v1/gps/vendors${params}`)
    } catch (error) {
        return error;
    }
}
  //post gps
  export const postGpsVendor = async (data) => {
    try {
      return await AoneService.post("assets/api/v1/gps/vendors", data);
    } catch (error) {
      return error;
    }
  };
  //delete gps
  export const deleteGpsvendor = async (params) => {
    try {
      return await AoneService.delete(`assets/api/v1/gps/vendors/${params}`);
    } catch (error) {
      return error;
    }
  };
//edit gps
export const editGpsvendor = async (params,data) => {
  try {
    return await AoneService.put(`assets/api/v1/gps/vendors/${params}`, data);
  } catch (error) {
    return error;
  }
};
//template
export const GpsvendorTemplate = async () => {
  try {
    return await AoneService.get(
      `assets/api/v1/gps/vendors/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//import
export const importGpsvendor = async (data) => {
  try {
    return await AoneService.post(`assets/api/v1/gps/vendors/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//export
export const exportGpsvendor = async (params) => {
  try {
    return await AoneService.get(`assets/api/v1/gps/vendors/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//vehicle category
//get vehiclecategory
export const getVehicleCategory = async (params) => {
  try  {
      return await AoneService.get(`assets/api/v1/vehicle-categories${params}`)
  } catch (error) {
      return error;
  }
}
//get vehiclecategory by code
export const getVehicleCategorybyCode = async (params) => {
  try {
    return await AoneService.get(`assets/api/v1/vehicle-categories/categories/${params}`);
  } catch (error) {
    return error;
  }
};
//post vehiclecategory
export const postVehicleCategory = async (data) => {
  try {
    return await AoneService.post("assets/api/v1/vehicle-categories", data);
  } catch (error) {
    return error;
  }
};
//edit vehiclescategory
export const editVehicleCategory = async (params,data) => {
  try {
    return await AoneService.put(`assets/api/v1/vehicle-categories/${params}`, data);
  } catch (error) {
    return error;
  }
};
//vehiclecategory template
export const VehicleCategoryTemplate = async () => {
try {
  return await AoneService.get(
    `assets/api/v1/vehicle-categories/template/download`,
    {
      responseType: "blob",
    }
  );
} catch (error) {
  return error;
}
};
//upload vehiclecategory
export const importVehicleCategory = async (data) => {
  try {
    return await AoneService.post(`assets/api/v1/vehicle-categories/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//export vehiclecategory
export const exportVehicleCategory = async (params) => {
  try {
    return await AoneService.get(`assets/api/v1/vehicle-categories/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//delete vehiclecategory
export const deleteVehicleCategory = async (params) => {
  try {
    return await AoneService.delete(`assets/api/v1/vehicle-categories/${params}`);
  } catch (error) {
    return error;
  }
};


//-------------vendors---------------------------------------------//
export const getVehicleVendor = async (params) => {
  try  {
      return await AoneService.get(`assets/api/v1/vendors${params}`)
  } catch (error) {
      return error;
  }
}
//get vehicle vendors by code
export const getVehicleVendorbyCode = async (params) => {
  try {
    return await AoneService.get(`assets/api/v1/vendors/${params}`);
  } catch (error) {
    return error;
  }
};
//post vehiclevendors
export const postVehicleVendor = async (data) => {
  try {
    return await AoneService.post("assets/api/v1/vendors", data);
  } catch (error) {
    return error;
  }
};
//edit vehicle vendors
export const editVehicleVendor = async (params,data) => {
  try {
    return await AoneService.put(`assets/api/v1/vendors/${params}`, data);
  } catch (error) {
    return error;
  }
};
//vehicle vendors template
export const VehicleVendorTemplate = async () => {
try {
  return await AoneService.get(
    `assets/api/v1/vendors/template/download`,
    {
      responseType: "blob",
    }
  );
} catch (error) {
  return error;
}
};
//upload vehicle vendors
export const importVehicleVendor = async (data) => {
  try {
    return await AoneService.post(`assets/api/v1/vendors/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//export vehicle vendors
export const exportVehicleVendor = async (params) => {
  try {
    return await AoneService.get(`assets/api/v1/vendors/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//delete vehicle vendors
export const deleteVehicleVendor = async (params) => {
  try {
    return await AoneService.delete(`assets/api/v1/vendors/${params}`);
  } catch (error) {
    return error;
  }
};