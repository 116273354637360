import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import PaginationTable from "../components/Tables/paginationTable";
import templateIcon from "../assets/img/template_icon.svg";
import downloadicon from "../assets/img/download_icon.svg";
import importIcon from "../assets/img/importIcon.svg";
import { toast } from "react-toastify";
import breadArrow from "../../src/assets/img/arrow-dropright_icon.svg";
import * as XLSX from "xlsx";
import syncicon from "../../src/assets/img/sync.svg";
import { useNavigate } from "react-router";
import { useAoneStore } from "../store/AoneStore";
import UploadSuccess from "../components/modal/uploadSuccess";
import UploadProgressModal from "../components/modal/uploadProgressModal";
import UploadErrorModal from "../components/modal/uploadErrorModal";
import SearchInput from "../components/dropdown/autoCompleteInputComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserContext } from "../helper/ProtectedRoute";
import { roleHandler } from "../helper/roleHandler";
import fileDownload from "js-file-download";
import AsyncSearchInput from "../components/dropdown/autoCompleteAsyncComponent";
const Ratelimiter = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("Cod");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [editPage, setEditPage] = useState(false);
  const prevent = useRef(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState("-updatedAt");
  const [chargesData, setChargesData] = useState([]);
  const [freightData, setFreightData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [fuelkgData, setFuelkgData] = useState([]);
  const [lowvalueData, setLowvalueData] = useState([]);
  const [highvalueData, setHighvalueData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [odaData, setOdaData] = useState([]);
  const [rtoData, setRtoData] = useState([]);
  const [vatData, setVatData] = useState([]);
  const [page, setPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [originCountry, setOriginCountry] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [destRegion, setDestRegion] = useState("");
  const [calculationType, setCalculationTypes] = useState("");
  // progress bar modal
  const [showProgress, setShowProgress] = useState(false);
  // success modal
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // upload error modal
  const [showuploadErrModal, setShowuploadErrModal] = useState(false);
  const postCodTemplate = useAoneStore((state) => state?.postCodTemplate);
  const filterRegions = useAoneStore((state) => state.filterRegions);
  const getLookups = useAoneStore((state) => state?.getLookups);
  const serviceTypes = useAoneStore((state) => state?.serviceTypes);
  const getCalculationType = useAoneStore((state) => state?.getCalculationType);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const calculationTypeArray = useAoneStore(
    (state) => state?.calculationTypeArray
  );
  const postFreightTemplate = useAoneStore(
    (state) => state?.postFreightTemplate
  );
  const postFuelTemplate = useAoneStore((state) => state?.postFuelTemplate);
  const postFuelperkgTemplate = useAoneStore(
    (state) => state?.postFuelperkgTemplate
  );
  const postLowvalueTemplate = useAoneStore(
    (state) => state?.postLowvalueTemplate
  );
  const postHighvalueTemplate = useAoneStore(
    (state) => state?.postHighvalueTemplate
  );
  const postInsuranceTemplate = useAoneStore(
    (state) => state?.postInsuranceTemplate
  );
  const postOdaTemplate = useAoneStore((state) => state?.postOdaTemplate);
  const postRtoTemplate = useAoneStore((state) => state?.postRtoTemplate);
  const postVatTemplate = useAoneStore((state) => state?.postVatTemplate);
  const getCodCharge = useAoneStore((state) => state?.getCodCharge);
  const getFreightCharge = useAoneStore((state) => state?.getFreightCharge);
  const getFuelCharge = useAoneStore((state) => state?.getFuelCharge);
  const getFuelkgcharge = useAoneStore((state) => state?.getFuelkgcharge);
  const getLowvaluecharge = useAoneStore((state) => state?.getLowvaluecharge);
  const getHighvaluecharge = useAoneStore((state) => state?.getHighvaluecharge);
  const getInusranceCharge = useAoneStore((state) => state?.getInusranceCharge);
  const getOdacharge = useAoneStore((state) => state?.getOdacharge);
  const getRtocharge = useAoneStore((state) => state?.getRtocharge);
  const exportCod = useAoneStore((state) => state?.exportCod);
  const exportFreight = useAoneStore((state) => state?.exportFreight);
  const exportFuel = useAoneStore((state) => state?.exportFuel);
  const exportFuelperkg = useAoneStore((state) => state?.exportFuelperkg);
  const exportLowvalue = useAoneStore((state) => state?.exportLowvalue);
  const exportHighvalue = useAoneStore((state) => state?.exportHighvalue);
  const exportInsurance = useAoneStore((state) => state?.exportInsurance);
  const exportOda = useAoneStore((state) => state?.exportOda);
  const exportRto = useAoneStore((state) => state?.exportRto);
  const exportVat = useAoneStore((state) => state?.exportVat);
  const getCodTemplate = useAoneStore((state) => state?.getCodTemplate);
  const getFreightTemplate = useAoneStore((state) => state?.getFreightTemplate);
  const getFuelTemplate = useAoneStore((state) => state?.getFuelTemplate);
  const getFuelperkgTemplate = useAoneStore(
    (state) => state?.getFuelperkgTemplate
  );
  const getLowvalueTemplate = useAoneStore(
    (state) => state?.getLowvalueTemplate
  );
  const getHighvalueTemplate = useAoneStore(
    (state) => state?.getHighvalueTemplate
  );
  const getInsuranceTemplate = useAoneStore(
    (state) => state?.getInsuranceTemplate
  );
  const getOdaTempalte = useAoneStore((state) => state?.getOdaTempalte);
  const getRtoTemplate = useAoneStore((state) => state?.getRtoTemplate);
  const getVatTemplate = useAoneStore((state) => state?.getVatTemplate);
  const getVatCharge = useAoneStore((state) => state?.getVatCharge);
  const getCustomer = useAoneStore((state) => state?.getCustomer);
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const customerData = useAoneStore((state) => state?.customerData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const handleCloseModal = () => {
    setError(null);
  };
  const clearStates = () => {
    setSortField("-updatedAt");
    setServiceType("");
    setCustomer(null);
    setOriginCountry("");
    setDestinationCountry("");
    setCalculationTypes("");
    setDestRegion("");
  };
  const downloadTemplate = async () => {
    try {
      if (status === "Cod") {
        const res = await getCodTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Cod Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Freight") {
        const res = await getFreightTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Freight Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Fuel_in_perc") {
        const res = await getFuelTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Fuel Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Fuel_in_kg") {
        const res = await getFuelperkgTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Fuelperkg Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Lval") {
        const res = await getLowvalueTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Low Value Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Hval") {
        const res = await getHighvalueTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `High Value Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Insurance") {
        const res = await getInsuranceTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Insurance Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "ODA") {
        const res = await getOdaTempalte();
        if (res.status == 200) {
          fileDownload(res.data, `Oda Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else if (status === "Rto") {
        const res = await getRtoTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Rto Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      } else {
        const res = await getVatTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Vat Template.xlsx`);
        } else {
          toast.error("Error while downloading the template");
        }
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);
          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            let importFunction;
            switch (status) {
              case "Cod":
                importFunction = postCodTemplate;
                break;
              case "Freight":
                importFunction = postFreightTemplate;
                break;
              case "Fuel_in_perc":
                importFunction = postFuelTemplate;
                break;
              case "Fuel_in_kg":
                importFunction = postFuelperkgTemplate;
                break;
              case "Lval":
                importFunction = postLowvalueTemplate;
                break;
              case "Hval":
                importFunction = postHighvalueTemplate;
                break;
              case "Insurance":
                importFunction = postInsuranceTemplate;
                break;
              case "ODA":
                importFunction = postOdaTemplate;
                break;
              case "Rto":
                importFunction = postRtoTemplate;
                break;
              case "VAT":
                importFunction = postVatTemplate;
                break;
              default:
                importFunction = postCodTemplate;
            }
            importFunction(formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.request?.status == "413") {
                  toast.error(response?.code);
                  fileInput.value = "";
                } else if (response?.response?.status === "ERROR") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response?.response?.status == "500") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch(() => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (originCountry) {
      params += `&originCountry=${originCountry}`;
    }
    if (destinationCountry) {
      params += `&destCountry=${destinationCountry}`;
    }
    if (serviceType) {
      params += `&serviceType=${serviceType}`;
    }
    if (status === "Cod") {
      if (calculationType) {
        params += `&calculationType=${calculationType}`;
      }
      const data = await getCodCharge(params);
      setTotalCount(data.data.data.totalElements);
      setChargesData(data.data.data.content);
    } else if (status === "Freight") {
      if (destRegion) {
        params += `&destRegion=${destRegion}`;
      }
      const data = await getFreightCharge(params);
      setTotalCount(data.data.data.totalElements);
      setFreightData(data.data.data.content);
    } else if (status === "Fuel_in_perc") {
      const data = await getFuelCharge(params);
      setTotalCount(data.data.data.totalElements);
      setFuelData(data.data.data.content);
    } else if (status === "Fuel_in_kg") {
      const data = await getFuelkgcharge(params);
      setTotalCount(data.data.data.totalElements);
      setFuelkgData(data.data.data.content);
    } else if (status === "Lval") {
      const data = await getLowvaluecharge(params);
      setTotalCount(data.data.data.totalElements);
      setLowvalueData(data.data.data.content);
    } else if (status === "Hval") {
      const data = await getHighvaluecharge(params);
      setTotalCount(data.data.data.totalElements);
      setHighvalueData(data.data.data.content);
    } else if (status === "Insurance") {
      const data = await getInusranceCharge(params);
      setTotalCount(data.data.data.totalElements);
      setInsuranceData(data.data.data.content);
    } else if (status === "ODA") {
      const data = await getOdacharge(params);
      setTotalCount(data.data.data.totalElements);
      setOdaData(data.data.data.content);
    } else if (status === "Rto") {
      const data = await getRtocharge(params);
      setTotalCount(data.data.data.totalElements);
      setRtoData(data.data.data.content);
    } else {
      const data = await getVatCharge(params);
      setTotalCount(data.data.data.totalElements);
      setVatData(data.data.data.content);
    }
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (originCountry) {
      params += `&originCountry=${originCountry}`;
    }
    if (destinationCountry) {
      params += `&destCountry=${destinationCountry}`;
    }
    if (status === "Cod") {
      try {
        const res = await exportCod(params);
        if (res.status == 200) {
          fileDownload(res.data, `Cod Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Freight") {
      try {
        const res = await exportFreight(params);
        if (res.status == 200) {
          fileDownload(res.data, `Freight Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Fuel_in_perc") {
      try {
        const res = await exportFuel(params);
        if (res.status == 200) {
          fileDownload(res.data, `Fuel in Percentage.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Fuel_in_kg") {
      try {
        const res = await exportFuelperkg(params);
        if (res.status == 200) {
          fileDownload(res.data, `Fuel in Kg.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Lval") {
      try {
        const res = await exportLowvalue(params);
        if (res.status == 200) {
          fileDownload(res.data, `Lowvalue Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Hval") {
      try {
        const res = await exportHighvalue(params);
        if (res.status == 200) {
          fileDownload(res.data, `Highvalue Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Insurance") {
      try {
        const res = await exportInsurance(params);
        if (res.status == 200) {
          fileDownload(res.data, `Insurance Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "ODA") {
      try {
        const res = await exportOda(params);
        if (res.status == 200) {
          fileDownload(res.data, `ODA Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else if (status === "Rto") {
      try {
        const res = await exportRto(params);
        if (res.status == 200) {
          fileDownload(res.data, `Rto Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    } else {
      try {
        const res = await exportVat(params);
        if (res.status == 200) {
          fileDownload(res.data, `Vat Charges.xlsx`);
        } else if (res?.response?.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error(res.response.statusText);
        }
      } catch (err) {}
    }
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    // if(customerData?.data?.data?.content?.length < 1  || customerData?.length < 1){
    // fetchCustomerData();
    // }
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.length < 1
    ) {
      filterCountries(paramslink);
    }
    if (
      regionsData?.data?.data?.content?.length < 1 ||
      regionsData?.length < 1
    ) {
      filterRegions(paramslink);
    }
  }, []);
  const fetchCustomerData = async () => {
    try {
      const paramslink = `?page=${0}&size=${20}`;
      const response = await getCustomer(paramslink);
      const data = response?.data?.data?.content || [];
      setCustomerOptions(data);
    } catch (error) {
      console.error("Error fetching customer data", error);
    }
  };
  const getLookupservices = async () => {
    const data = await getLookups("ServiceType");
  };
  const getCalcul = async () => {
    const data = await getCalculationType();
  };
  useEffect(() => {
    !editPage && getData();
  }, [
    newPage,
    size,
    sortField,
    status,
    editPage,
    deleteHandler,
    customer,
    originCountry,
    destinationCountry,
    serviceType,
    calculationType,
    destRegion,
  ]);
  useEffect(() => {
    if (serviceTypes?.length < 1 || serviceType === undefined) {
      getLookupservices();
    }
    if (
      calculationTypeArray?.length < 1 ||
      calculationTypeArray === undefined
    ) {
      getCalcul();
    }
  }, []);
  const [selectedOption, setSelectedOption] = useState(null);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  const combinedStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      fontSize: "12px",
    }),
  };
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/finance/invoice")}
              >
                Finance
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/finance/charges")}
              >
                Charges(Temp)
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${status == "Cod" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Cod");
            clearStates();
            setSelectedOption(null);
          }}
        >
          COD
        </button>
        <button
          className={`filterBtn ${status == "Freight" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Freight");
            clearStates();
            setSelectedOption(null);
          }}
        >
          Freight
        </button>
        <button
          className={`filterBtn ${
            status == "Fuel_in_perc" && "activeFilterBtn"
          }`}
          onClick={() => {
            setStatus("Fuel_in_perc");
            clearStates();
            setSelectedOption(null);
          }}
        >
          Fuel
        </button>
        <button
          className={`filterBtn ${status == "Fuel_in_kg" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Fuel_in_kg");
            clearStates();
            setSelectedOption(null);
          }}
        >
          Fuel Per Kg
        </button>
        <button
          className={`filterBtn ${status == "Lval" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Lval");
            clearStates();
            setSelectedOption(null);
          }}
        >
          Low Value
        </button>
        <button
          className={`filterBtn ${status == "Hval" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Hval");
            clearStates();
            setSelectedOption(null);
          }}
        >
          High Value
        </button>
        <button
          className={`filterBtn ${status == "Insurance" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Insurance");
            clearStates();
            setSelectedOption(null);
          }}
        >
          Insurance
        </button>
        <button
          className={`filterBtn ${status == "ODA" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("ODA");
            clearStates();
            setSelectedOption(null);
          }}
        >
          ODA
        </button>
        <button
          className={`filterBtn ${status == "Rto" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("Rto");
            clearStates();
            setSelectedOption(null);
          }}
        >
          RTO
        </button>
        <button
          className={`filterBtn ${status == "VAT" && "activeFilterBtn"}`}
          onClick={() => {
            setStatus("VAT");
            clearStates();
            setSelectedOption(null);
          }}
        >
          VAT
        </button>
        {/* <button className={`filterBtn ${ status == "CITY" && "activeFilterBtn"}`} onClick={()=>setStatus("CITY")}>CITY</button>
          <button className={`filterBtn ${ status == "AWB" && "activeFilterBtn"}`} onClick={()=>setStatus("AWB")}>AWB</button>
          <button className={`filterBtn ${ status == "Shipsy" && "activeFilterBtn"}`} onClick={()=>setStatus("Shipsy")}>Shipsy</button> */}
      </div>
      <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
        <div
          className="displayFlex justifyContent_end mleft_5"
          style={{ marginTop: "14px" }}
        >
          {status === "Cod" ? (
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10">Customer:</label>
                <AsyncSearchInput
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOption}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    if (selectedOption) {
                      setCustomer(selectedOption.value);
                    } else {
                      setCustomer(null);
                    }
                  }}
                  placeholder="Customer"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Service Type:</label>
                <SearchInput
                  id="serviceType"
                  label="serviceType"
                  name="serviceType"
                  options={serviceTypes || []}
                  value={
                    serviceTypes.find((option) => option.key === serviceType) ||
                    null
                  }
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setServiceType(selectedOption ? selectedOption.key : "");
                  }}
                  isSearchable={true}
                  placeholder="Service Type"
                  customStyles={{ control: { width: "145px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Origin Country:</label>
                <SearchInput
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  value={
                    countriesData?.data?.data?.content.find(
                      (option) => option?.name === originCountry
                    ) || null
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  handleChange={(selectedOption) => {
                    setOriginCountry(selectedOption ? selectedOption.name : "");
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "140px" } }}
                  isSearchable={true}
                  placeholder="Origin Country"
                  className="mainInput"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Destination Country:</label>
                <SearchInput
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  options={countriesData?.data?.data?.content || []}
                  value={
                    countriesData?.data?.data?.content.find(
                      (option) => option?.name === destinationCountry
                    ) || null
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.name : ""
                    );
                  }}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Destination Country"
                  className="mainInput"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Calculation Type:</label>
                <SearchInput
                  id="countryCode"
                  label="countryCode"
                  name="countryName"
                  options={calculationTypeArray || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  value={
                    calculationTypeArray.find(
                      (option) => option.key === calculationType
                    ) || null
                  }
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setCalculationTypes(
                      selectedOption ? selectedOption.key : ""
                    );
                  }}
                  isSearchable={true}
                  placeholder="Calculation Type"
                  className="mainInput"
                />
              </Col>
            </Row>
          ) : status === "Freight" ? (
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10">Customer:</label>
                <AsyncSearchInput
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOption}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    if (selectedOption) {
                      setCustomer(selectedOption.value);
                    } else {
                      setCustomer(null);
                    }
                  }}
                  placeholder="Customer"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Service Type:</label>
                <SearchInput
                  id="serviceType"
                  label="serviceType"
                  name="serviceType"
                  options={serviceTypes || []}
                  value={
                    serviceTypes.find((option) => option.key === serviceType) ||
                    null
                  }
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setServiceType(selectedOption ? selectedOption.key : "");
                  }}
                  isSearchable={true}
                  placeholder="Service Type"
                  customStyles={{ control: { width: "145px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>

              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Origin Country:</label>
                <SearchInput
                  id="originCountry"
                  label="originCountry"
                  name="originCountry"
                  options={countriesData?.data?.data?.content || []}
                  value={
                    countriesData?.data?.data?.content.find(
                      (option) => option?.name === originCountry
                    ) || null
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  handleChange={(selectedOption) => {
                    setOriginCountry(selectedOption ? selectedOption.name : "");
                  }}
                  isClearable={true}
                  isSearchable={true}
                  customStyles={{ control: { width: "140px" } }}
                  placeholder="Origin Country"
                  className="mainInput"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Destination Country:</label>
                <SearchInput
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  options={countriesData?.data?.data?.content || []}
                  value={
                    countriesData?.data?.data?.content.find(
                      (option) => option?.name === destinationCountry
                    ) || null
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.name : ""
                    );
                  }}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Destination Country"
                  className="mainInput"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Destination Region:</label>
                <div className="displayFlex alignItemCenter marginR10">
                  <input
                    type="text"
                    value={destRegion}
                    className={
                      destRegion?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      destRegion?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setDestRegion(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          ) : status === "Fuel_in_perc" ||
            status === "Fuel_in_kg" ||
            status === "Lval" ||
            status === "Hval" ||
            status === "Insurance" ||
            status === "ODA" ||
            status === "Rto" ||
            status === "VAT" ? (
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10">Customer:</label>
                <AsyncSearchInput
                  id="customer"
                  label="customer"
                  name="customer"
                  value={selectedOption}
                  loadMoreOptions={loadMoreOptions}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    if (selectedOption) {
                      setCustomer(selectedOption.value);
                    } else {
                      setCustomer(null);
                    }
                  }}
                  placeholder="Customer"
                />
              </Col>
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Service Type:</label>
                <SearchInput
                  id="serviceType"
                  label="serviceType"
                  name="serviceType"
                  options={serviceTypes || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  isClearable={true}
                  value={
                    serviceTypes.find((option) => option.key === serviceType) ||
                    null
                  }
                  handleChange={(selectedOption) => {
                    setServiceType(selectedOption ? selectedOption.key : "");
                  }}
                  isSearchable={true}
                  placeholder="Service Type"
                  customStyles={{ control: { width: "145px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
              {status !== "Lval" && status !== "Hval" && (
                <Col className="ml_5 ws_nowrap">
                  <label className="fontSize14 mb_12">Origin Country:</label>
                  <SearchInput
                    id="originCountry"
                    label="originCountry"
                    name="originCountry"
                    options={countriesData?.data?.data?.content || []}
                    value={
                      countriesData?.data?.data?.content.find(
                        (option) => option?.name === originCountry
                      ) || null
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={(selectedOption) => {
                      setOriginCountry(
                        selectedOption ? selectedOption.name : ""
                      );
                    }}
                    isClearable={true}
                    customStyles={{ control: { width: "140px" } }}
                    isSearchable={true}
                    placeholder="Origin Country"
                    className="mainInput"
                  />
                </Col>
              )}
              <Col className="ml_5 ws_nowrap">
                <label className="fontSize14 mb_12">Destination Country:</label>
                <SearchInput
                  id="destinationCountry"
                  label="destinationCountry"
                  name="destinationCountry"
                  options={countriesData?.data?.data?.content || []}
                  value={
                    countriesData?.data?.data?.content.find(
                      (option) => option?.name === destinationCountry
                    ) || null
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  handleChange={(selectedOption) => {
                    setDestinationCountry(
                      selectedOption ? selectedOption.name : ""
                    );
                  }}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Destination Country"
                  className="mainInput"
                />
              </Col>
            </Row>
          ) : null}
        </div>
        <div className="displayFlex justifyContent_end mt_10">
          <div className="smallGroupInput mleft_10">
            <button
              className="filterBtn"
              title="Export"
              onClick={() => exportToExcel()}
            >
              <span>
                <img src={downloadicon} className="h18" />
              </span>
            </button>
            {(roleHandler(role, "AONE_FINANCE.write") ||
              roleHandler(role, "AONE_FINANCE.admin") ||
              roleHandler(role, "AONE_FINANCE_INVOICE.write") ||
              roleHandler(role, "AONE_FINANCE_INVOICE.admin") ||
              roleHandler(role, "AONE_FINANCE_CHARGES.write") ||
              roleHandler(role, "AONE_FINANCE_CHARGES.admin") ||
              roleHandler(role, "AONE_FINANCE_INVOICE_INVOICE-REQUEST.write") ||
              roleHandler(role, "AONE_FINANCE_INVOICE_INVOICE-REQUEST.admin")) && (
              <>
                <button
                  style={{ marginLeft: "10px" }}
                  className="filterBtn plus_icon"
                  title="Download Template"
                  onClick={downloadTemplate}
                >
                  <span>
                    <img src={templateIcon} className="h18" />
                  </span>
                </button>
                <div title="Import File" className="mleft_10">
                  <label className="filterBtn plus_icon" htmlFor="uploadFile">
                    <span>
                      <img src={importIcon} className="h18" />
                    </span>
                    <input
                      type="file"
                      id="uploadFile"
                      onInput={(e) => handleFileChange(e)}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      hidden
                    />
                  </label>
                </div>
              </>
            )}
            <div className="displayFlex gap_12 mleft_10">
              <button
                className="filterBtn plus_icon"
                title="Sync"
                onClick={() => syncronizeRoles()}
              >
                <span>
                  <img src={syncicon} className="h18" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt_20">
        <PaginationTable
          tbl={status}
          tableData={
            status === "Cod"
              ? chargesData
              : status === "Freight"
              ? freightData
              : status === "Fuel_in_perc"
              ? fuelData
              : status === "Fuel_in_kg"
              ? fuelkgData
              : status === "Lval"
              ? lowvalueData
              : status === "Hval"
              ? highvalueData
              : status === "Insurance"
              ? insuranceData
              : status === "ODA"
              ? odaData
              : status === "Rto"
              ? rtoData
              : vatData
          }
          modalHandler={editPage}
          setModalHandler={setEditPage}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          setSize={setSize}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          totalCount={totalCount}
          sortField={sortField}
          setSortField={setSortField}
        />
      </div>
      <UploadErrorModal
        show={error !== null}
        handleClose={handleCloseModal}
        errorMessage={error}
      />
      <UploadProgressModal show={showProgress} />
      <UploadSuccess
        show={showSuccessModal}
        handleClose={handleCloseModal}
        closeModal={setShowSuccessModal}
      />
    </div>
  );
};

export default Ratelimiter;
