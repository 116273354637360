import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getRoutes,postRoutes, editRoutes,routesTemplate,
    importRoutes,exportRoutes,deleteRoutes,getRoutesbyCode,
    getDistance,getStatus} from "../../services/RoutesManagement/Routes_Services"
const initialValues = {
  distanceList: [],
  statusList: []
};
let routesStore = (set, get) => ({
  ...initialValues,
  getRoutes: async (params) => {
        const response = await getRoutes(params);
        return response;
      },
      getRoutesbyCode: async (params) => {
        const response = await getRoutesbyCode(params);
        return response;
      },
      postRoutes: async (data) => {
        const response = await postRoutes(data);
        return response;
      },
      editRoutes: async (params) => {
        const response = await editRoutes(params);
        return response;
      },
      routesTemplate: async () => {
        const response = await routesTemplate();
        return response;
      },
      importRoutes: async (params) => {
        const response = await importRoutes(params);
        return response;
      },
      exportRoutes: async (params) => {
        const response = await exportRoutes(params);
        return response;
      },
      deleteRoutes: async (params) => {
        const response = await deleteRoutes(params);
        return response;
      },
      getDistance: async (params) => {
        const response = await getDistance(params);
        set({distanceList : response})
        return response;
      },
      getStatus: async () => {
        const response = await getStatus();
        set({ statusList: response });
        return response;
      },
});

routesStore = devtools(routesStore);

export const useroutesStore = create(routesStore);