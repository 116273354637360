import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAoneStore } from "../../store/AoneStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import { useParams } from "react-router-dom";
import { selectClasses } from "@mui/material";
const BillingAddress = ({ setTab }) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const getCities = useAoneStore((state) => state.getCities);
  const getRegionbyCode = useAoneStore((state) => state?.getRegionbyCode);
  const getCitybyCode = useAoneStore((state) => state.getCitybyCode);
  const getCountryByCity = useAoneStore((state) => state.getCountryByCity);
  const citybyCountyData = useAoneStore((state) => state.citybyCountyData);
  const getBillingbyId = useAoneStore((state) => state.getBillingbyId);
  const getDistrictbyCode = useAoneStore((state) => state.getDistrictbyCode);
  const postBillingAddress = useAoneStore((state) => state.postBillingAddress);
  const editBillingAddress = useAoneStore((state) => state.editBillingAddress);
  const setbillingArrayData = useAoneStore(
    (state) => state.setbillingArrayData
  );
  const [resetKey, setResetKey] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [cusPage, setCusPage] = useState(-1);
  const [phoneNumberRegex, setphoneNumberRegex] = useState("");
  const getCurrency = useAoneStore((state) => state?.getCurrency);
  const [selOptionCustomer, setOptionCustomer] = useState(null);
  const getCountryArray = useAoneStore((state) => state?.getCountryArray);
  const billingArrayData = useAoneStore((state) => state.billingArrayData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const billingAddressData = useAoneStore((state) => state?.billingAddressData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const citybyCode = useAoneStore((state) => state?.citybyCode);
  const districtbyCode = useAoneStore((state) => state?.districtbyCode);
  const globalcustomerAccount = useAoneStore(
    (state) => state?.globalcustomerAccount
  );
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length === undefined ||
      countriesData?.data?.data?.content?.length < 1
    ) {
      getCountries(paramslink);
    }
    if (
      citiesData?.data?.data?.content?.length === undefined ||
      citiesData?.data?.data?.content?.length < 1
    ) {
      getCities(paramslink);
    }
  }, []);
  const currencies = ["SAR", "CN", "USD", "AED", "BHD"];

  const currencyOptions = currencies.map((currency) => ({
    value: currency,
    label: currency,
  }));
  const addBillingAddress = useFormik({
    initialValues: {
      country: "",
      city: "",
      district: "",
      currency: "",
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      fax: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Country is required"),
      city: Yup.string().required("City is required"),
      district: Yup.string().required("District is required"),
      addressLine1: Yup.string()
        .trim()
        .required("Addressline 1 is required")
        .min(1, "Addressline 1 is required"),
      postalCode: Yup.string()
        .trim()
        .required("Postalcode is required")
        .matches(/^\d{4,5}$/, "Postal code must be 4 or 5 digits")
        .min(1, "Postalcode is required"),
      fax: Yup.string()
        .trim()
        .required("Billing fax is required")
        .min(9, "Billing fax must be more than 8 digits"),
      email: Yup.string()
        .required("Billing email is required")
        .email("Invalid billing email address"),
      phone: Yup.string()
        .required("Billing Phone is required")
        .matches(
          /^[0-9]+$/,
          "Invalid Billing phone number. Please enter only numbers."
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (params?.id) {
        const valuestoSend = { ...values };
        valuestoSend.phone =
          addBillingAddress.values.phonecodeprefix +
          addBillingAddress.values.phone;
        const updatedValues = {
          ...valuestoSend,
          id: data?.id,
        };
        if (updatedValues?.id !== undefined) {
          await editBillingAddress(globalcustomerAccount, updatedValues).then(
            (response) => {
              if (response?.data?.status == "ERROR" && (response?.data?.errors?.length < 1 || response?.data?.errors?.length == undefined)) {
                const errorMessage = response.data.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.data?.status === "ERROR") {
                toast.error(response?.response?.data?.msg);
              } else if (response?.response?.status === 500) {
                toast.error(response?.response?.statusText);
              } else if (response?.data?.errors?.length > 0) {
                const errorMessages = response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else if (response?.response?.data?.errors?.length > 0) {
                const errorMessages = response?.response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else {
                toast.success("Billing Address Updated");
                setTab(3);
                resetForm();
              }
            }
          );
        } else {
          const valuestoSend = { ...values };
          valuestoSend.phone =
            addBillingAddress.values.phonecodeprefix +
            addBillingAddress.values.phone;
          await postBillingAddress(globalcustomerAccount, valuestoSend).then(
            (response) => {
              if (response?.data?.errors?.length > 0) {
                const errorMessages = response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else if (response?.data?.status == "ERROR" && (response?.data?.errors?.length < 1 || response?.data?.errors?.length == undefined)) {
                const errorMessage = response.data.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.data?.status === "ERROR") {
                toast.error(response?.response?.data?.msg);
              } else if (response?.response?.status === 500) {
                toast.error(response?.response?.statusText);
              } else if (response?.response?.data?.errors?.length > 0) {
                const errorMessages = response?.response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else {
                toast.success("Billing Address Added for Account");
                setTab(3);
                resetForm();
              }
            }
          );
        }
      } else {
        if (globalcustomerAccount) {
          const valuestoSend = { ...values };
          valuestoSend.phone =
            addBillingAddress.values.phonecodeprefix +
            addBillingAddress.values.phone;
          await postBillingAddress(globalcustomerAccount, valuestoSend).then(
            (response) => {
              if (response?.data?.errors?.length > 0) {
                const errorMessages = response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else if (response?.data?.status == "ERROR") {
                const errorMessage = response.data.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.data?.status === "ERROR") {
                toast.error(response?.response?.data?.msg);
              } else if (response?.response?.status === 500) {
                toast.error(response?.response?.statusText);
              } else {
                toast.success("Billing Address Added for Account");
                setTab(3);
                resetForm();
              }
            }
          );
        } else {
          toast.error(
            "Customer Account need to be created before saving the billing address"
          );
        }
      }
    },
  });
  const [showCustomer, SetshowCustomer] = useState(!params?.id);
  const handleInputCustomerClick = () => {
    if (params?.id) {
      SetshowCustomer(true);
    }
  };
  useEffect(() => {
    if (phoneNumberRegex === null) {
      setError("");
      return;
    }
    const regex = new RegExp(phoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addBillingAddress.values.phone &&
      !regex.test(
        addBillingAddress.values.phonecodeprefix +
          addBillingAddress.values.phone
      )
    ) {
      // If it doesn't match, set the error message
      setError("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setError("");
    }
  }, [addBillingAddress.values.phone, phoneNumberRegex]);
  useEffect(() => {
    params?.id && getData();
  }, [params?.id]);
  useEffect(() => {
    const mappedFields = Object.keys(addBillingAddress.values).reduce(
      (acc, field) => {
        acc[field] = data?.[field] || "";
        return acc;
      },
      {}
    );
    // Set form field values based on data
    addBillingAddress.setValues((prevValues) => ({
      ...prevValues,
      ...mappedFields,
    }));
  }, [data]);
  useEffect(() => {
    const { phonecodeprefix, phone } = addBillingAddress.values;

    if (phonecodeprefix && phone.startsWith(phonecodeprefix)) {
      const updatedPhone = phone.replace(phonecodeprefix, "");
      addBillingAddress.setFieldValue("phone", updatedPhone);
    }
  }, [
    addBillingAddress.values.phonecodeprefix,
    addBillingAddress.values.phone,
  ]);
  const getData = async () => {
    // if (billingArrayData.id === undefined) {
      const data = await getBillingbyId(params.id);
      setData(data?.data?.data);
      setbillingArrayData(data?.data?.data);
      let resp = await getCountryArray(data?.data?.data.country);
      addBillingAddress.setFieldValue(
        "phonecodeprefix",
        resp?.data?.data?.phoneCodePrefix
          ? resp?.data?.data?.phoneCodePrefix
          : ""
      );
      setphoneNumberRegex(resp?.data?.data?.phoneNumberRegex);
      const cityData = await getCountryByCity(data?.data?.data.country);
      let paramslink = `?page=${0}&size=${30}&name=${data?.data?.data?.city}`;
      // let testCity = await getCities(paramslink);
      const districtData = await getDistrictbyCode(
        data?.data?.data?.country,
        cityData?.data?.data[0].regionCode,
        data?.data?.data?.city
      );
    // } else {
    //   setData(billingArrayData);
    //   let resp = await getCountryArray(billingAddressData?.data?.data.country);
    //   addBillingAddress.setFieldValue(
    //     "phonecodeprefix",
    //     resp?.data?.data?.phoneCodePrefix
    //       ? resp?.data?.data?.phoneCodePrefix
    //       : ""
    //   );
    //   setphoneNumberRegex(resp?.data?.data?.phoneNumberRegex);
    //   const cityData = await getCountryByCity(
    //     billingAddressData?.data?.data.country
    //   );
    //   console.log("two")
    //   let paramslink = `?page=${0}&size=${30}&name=${data?.city}`;
    //   // let testCity = await getCities(paramslink);
    //   console.log("citydata",cityData?.data?.data[0].code)
    //   const districtData = await getDistrictbyCode(
    //     cityData?.data?.data[0].countryIsoCode3,
    //     cityData?.data?.data[0]?.regionCode,
    //     cityData?.data?.data[0].code
    //   );
    // }
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `${search}`;
          if (search) {
            endpoint += `&code=${hashsearch}`;
          }
        }
        const response = await getCurrency(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.code} - ${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  return (
    <div className="row">
      <div className="col-md-6" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Country:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="country"
              label="country"
              name="country"
              isClearable={true}
              options={countriesData?.data?.data?.content || []}
              getOptionLabel={(country) => country.name}
              getOptionValue={(country) => country.isoCode3}
              isSearchable={true}
              value={countriesData?.data?.data?.content?.find(
                (country) =>
                  country.isoCode3 === addBillingAddress.values.country
              )}
              handleChange={async (selectedOption) => {
                try {
                  addBillingAddress.setFieldValue("city", "");
                  addBillingAddress.setFieldValue("district", "");
                  addBillingAddress.setFieldValue(
                    "country",
                    selectedOption ? selectedOption.isoCode3 : ""
                  );
                  addBillingAddress.setFieldValue(
                    "currency",
                    selectedOption.currency
                      ? selectedOption?.currency?.name
                      : ""
                  );
                  let resp = await getCountryArray(selectedOption.isoCode3);
                  addBillingAddress.setFieldValue(
                    "phonecodeprefix",
                    resp?.data?.data?.phoneCodePrefix
                      ? resp?.data?.data?.phoneCodePrefix
                      : ""
                  );
                  setphoneNumberRegex(resp?.data?.data?.phoneNumberRegex);
                  //call city based on country selected
                  const response = await getCountryByCity(
                    selectedOption.isoCode3
                  );
                } catch (error) {
                  console.error("Error fetching region data:", error);
                }
              }}
              placeholder="Select Country"
            />
            {addBillingAddress.touched.country &&
            addBillingAddress.errors.country ? (
              <p className="requiredText">{addBillingAddress.errors.country}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              City:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="city"
              label="city"
              name="city"
              isClearable={true}
              options={citybyCountyData?.data?.data || []}
              getOptionLabel={(city) => city.name}
              getOptionValue={(city) => city.code}
              isSearchable={true}
              value={
                addBillingAddress.values.city
                  ? citybyCountyData?.data?.data?.find(
                      (city) => city.code === addBillingAddress.values.city
                    )
                  : null
              }
              handleChange={async (selectedOption) => {
                try {
                  addBillingAddress.setFieldValue("district", "");
                  addBillingAddress.setFieldValue(
                    "city",
                    selectedOption ? selectedOption.code : ""
                  );
                  const districtData = await getDistrictbyCode(
                    selectedOption.countryIsoCode3,
                    selectedOption.regionCode,
                    selectedOption.code
                  );
                } catch (error) {
                  console.error("Error fetching city data:", error);
                }
              }}
              placeholder="Select City"
            />
            {addBillingAddress.touched.city && addBillingAddress.errors.city ? (
              <p className="requiredText">{addBillingAddress.errors.city}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              District:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="district"
              label="district"
              name="district"
              isClearable={true}
              options={districtbyCode || []}
              getOptionLabel={(district) => district.name}
              getOptionValue={(district) => district.code}
              value={
                districtbyCode.find(
                  (district) =>
                    district.code === addBillingAddress.values.district
                ) || null
              }
              handleChange={(selectedOption) =>
                addBillingAddress.setFieldValue(
                  "district",
                  selectedOption ? selectedOption.code : ""
                )
              }
              isSearchable={true}
              placeholder="Select District"
            />
            {addBillingAddress.touched.district &&
            addBillingAddress.errors.district ? (
              <p className="requiredText">
                {addBillingAddress.errors.district}
              </p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Currency:</label>
          </div>
          <div className="col-md-8">
            {showCustomer ? (
              <AsyncSearchInput
                key={resetKey}
                id="currency"
                label="currency"
                name="currency"
                value={selOptionCustomer}
                loadMoreOptions={loadMoreOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onChange={async (selOptionCustomer) => {
                  setOptionCustomer(selOptionCustomer);
                  addBillingAddress.setFieldValue(
                    "currency",
                    selOptionCustomer ? selOptionCustomer.value : ""
                  );
                }}
                placeholder="Currency"
              />
            ) : (
              <input
                type="text"
                className="mainInput"
                name="currency"
                value={addBillingAddress.values.currency}
                onClick={handleInputCustomerClick}
              />
            )}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Addressline 1:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="AddressLine 1"
              className="mainInput"
              name="addressLine1"
              value={addBillingAddress.values.addressLine1}
              onChange={(e) =>
                addBillingAddress.setFieldValue("addressLine1", e.target.value)
              }
            />
            {addBillingAddress.touched.addressLine1 &&
            addBillingAddress.errors.addressLine1 ? (
              <p className="requiredText">
                {addBillingAddress.errors.addressLine1}
              </p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Addressline 2:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="AddressLine 2"
              className="mainInput"
              name="addressLine2"
              value={addBillingAddress.values.addressLine2}
              onChange={(e) =>
                addBillingAddress.setFieldValue("addressLine2", e.target.value)
              }
            />
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Postal Code:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Postal Code"
              className="mainInput"
              name="postalCode"
              value={addBillingAddress.values.postalCode}
              onChange={(e) =>
                addBillingAddress.setFieldValue("postalCode", e.target.value)
              }
            />
            {addBillingAddress.touched.postalCode &&
            addBillingAddress.errors.postalCode ? (
              <p className="requiredText">
                {addBillingAddress.errors.postalCode}
              </p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Billing Fax:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Billing Fax"
              className="mainInput"
              name="fax"
              value={addBillingAddress.values.fax}
              onChange={(e) =>
                addBillingAddress.setFieldValue("fax", e.target.value)
              }
            />
            {addBillingAddress.touched.fax && addBillingAddress.errors.fax ? (
              <p className="requiredText">{addBillingAddress.errors.fax}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Billing Email:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Billing Email"
              className="mainInput"
              name="email"
              value={addBillingAddress.values.email}
              onChange={(e) =>
                addBillingAddress.setFieldValue("email", e.target.value)
              }
            />
            {addBillingAddress.touched.email &&
            addBillingAddress.errors.email ? (
              <p className="requiredText">{addBillingAddress.errors.email}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Billing Phone:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Billing Phone"
              className="mainInput"
              name="phone"
              value={addBillingAddress.values.phone}
              onChange={(e) =>
                addBillingAddress.setFieldValue("phone", e.target.value)
              }
            />
            {addBillingAddress.touched.phone &&
            addBillingAddress.errors.phone &&
            error === "" ? (
              <p className="requiredText">{addBillingAddress.errors.phone}</p>
            ) : null}
            {error !== "" && <p className="requiredText">{error}</p>}
          </div>
        </div>
        {(roleHandler(role, "AONE_CRM.write") ||
          roleHandler(role, "AONE_CRM.admin") ||
          roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
          roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
          roleHandler(role, "AONE_CONFIGS.write") ||
          roleHandler(role, "AONE_CONFIGS.admin") ||
          roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
          roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) && (
          <div className="row mb_22">
            <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={error == "" ? addBillingAddress.handleSubmit : null}
              >
                Save
              </button>
              <button className="cancel_btn">Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingAddress;
