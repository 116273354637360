import React,{ useEffect, useState, useRef } from 'react'
import searchicon from "../../src/assets/img/searchicon.svg";
import downloadicon from "../../src/assets/img/download_icon.svg"
import PaginationTable from '../components/Tables/paginationTable';
import { useNavigate } from 'react-router-dom';
import OpenModal from '../components/modal/openModal';
import { useAoneStore } from '../store/AoneStore';
import fileDownload from 'js-file-download';
import breadArrow from "../../src/assets/img/arrow-dropright_icon.svg"

const JobOrders = () => {

    const navigate = useNavigate();
    const [ orders, setOrders ] = useState([])

    const getJobOrders = useAoneStore((state) => state?.getJobOrders)
    const downloadJobOrder = useAoneStore((state) => state?.downloadJobOrder)

    const [ name, setName ] = useState("")
    const [ newPage, setNewPage ] = useState(0)
    const [ size, setSize ] = useState(20)
    const [ totalCount, setTotalCount ] = useState(0)
    const [ sortField, setSortField ] = useState("createdAt")

    const [ addModalHandler, setAddModalHandler ] = useState(false) // For Add Pop Up
    const [ modalHandler, setModalHandler] = useState(false)          // To Update While Delete - Complexity 
    const [ deleteHandler, setDeleteHandler ] = useState(false)     // Reload Page While Role Is Deleted

    const prevent = useRef(false)

    const getData = async () => {
      const params = `?page=${newPage}&size=${size}&sortBy=${sortField}&customerAccount=${name}`
      const data = await getJobOrders(params)
      setTotalCount(data?.data?.data?.totalElements)
      setOrders(data?.data?.data?.content)
    }

    const exportToExcel = () => {
      const params =`?customerAccount=${name}`
      downloadJobOrder(params).then((res)=>{
        fileDownload(res.data, `Job Orders.xlsx`);
      })
    }

    useEffect(() => {
      if(prevent.current){
        const delayFn = setTimeout(() => {
          getData();
        }, 1500)  
        return () => clearTimeout(delayFn)
      }else{
        prevent.current = true
      }
    }, [name])

    useEffect(()=>{
      getData();
    },[newPage,size,addModalHandler,modalHandler,sortField,deleteHandler])

  return (
    <div>
        <div className='row alignItemCenter mb_16'>
          <ul className="page_breadcrumb">
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500"}}>
                  Projects
                  </span>
                  </li>
                  <li style={{marginLeft:"-10px",marginBottom:"5px"}}>
                  <span><img src={breadArrow} className="turnBlack" alt="breadcrumbarrow"/></span>
                  </li>
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px"}}>
                  Add On
                  </span>
                  </li>
                  <li style={{marginLeft:"-10px",marginBottom:"5px"}}>
                  <span><img src={breadArrow} className="turnBlack" alt="breadcrumbarrow"/></span>
                  </li>
                  <li>
                  <span className="mainheadlink" style={{fontWeight:"500",marginLeft:"-3px",fontWeight:"bolder"}}>
                  Job Orders
                  </span>
                  </li>
                  </ul>
        </div>

        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          <div className='displayFlex justifyContent_end'>
            <div className="displayFlex alignItemCenter">
            <input type='text'  className={name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"}
                      style={
                        name?.length > 0
                          ? { border: '1px solid #ec171c' }
                          : {}
                      } onChange={(e)=>(setName(e.target.value),setNewPage(0))}/>
                </div>
          </div>

          <div className='displayFlex gap_12'> 
            <div>
              <button className="filterBtn" title='Download' onClick={()=>exportToExcel()}>
                <span><img src={downloadicon} className='h18'/></span>
              </button>
            </div>
            <div>
              <button className='filterBtn plus_icon' onClick={()=>navigate("/projects/addon/orders/add")} title='Add'>
                +
              </button>
            </div>               
          </div> 
        </div>
        {/* {
        addModalHandler && <OpenModal modalHandler={addModalHandler} setModalHandler={setAddModalHandler} title={"Add Users"} editHandler={editHandler} setEditHandler={setEditHandler}/>
        }  */}

        <PaginationTable tableData={orders} tbl="jobOrders" newPage={newPage} modalHandler={modalHandler} setModalHandler={setModalHandler}
          setNewPage={setNewPage} size={size} setSize={setSize} totalCount={totalCount} setSortField={setSortField} sortField={sortField}
          deleteHandler={deleteHandler} setDeleteHandler={setDeleteHandler}/>
    </div>
  )
}

export default JobOrders