import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import Account from "./Account";
import Areas from "./Areas";
import UserMgtTab from "./UserMgtTab";
import { useAoneStore } from "../../store/AoneStore";
const EmployeeAdd = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
    const setglobalEmployeeCode = useAoneStore((state) => state?.setglobalEmployeeCode);
    const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
        // navigate("/crm/customers");
      };
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }} onClick={() => {
                  setglobalEmployeeCode("");
                  navigate("/crm/employees");
                }}>
                CRM
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  setglobalEmployeeCode("");
                  navigate("/crm/employees");
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                }}
              >
                Employees
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
                {params?.id ? params?.id : globalEmployeeCode ? globalEmployeeCode : "New"}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section  mb_12">
        <button
          className={`filterBtn ${tab == 1 &&  `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalEmployeeCode !== '') {
              tabHandler(1);
            }
          }}
        >
          Account
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalEmployeeCode !== '') {
              tabHandler(2);
            }
          }}
        >
          Areas
        </button>
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalEmployeeCode !== '') {
              tabHandler(3);
            }
          }}
        >
          User Management
        </button>
      </div>
      <div>
         { (tab == 1 ? (
            <Account edit={setEditPage} setTab={setTab}/>
          ) : tab == 2 ? (
            <Areas edit={setEditPage} setTab={setTab}/>
          ) :
            <UserMgtTab edit={setEditPage} />)}
      </div>
    </div>
  )
}

export default EmployeeAdd