import React, { useState, useRef, useEffect, useContext , useCallback} from "react";
import { useNavigate , useParams} from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useCollectionStore } from "../../../store/CollectionsMgt/CollectionStore";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import Dropdown from "react-bootstrap/Dropdown";
import PaginationTable from "../../../components/Tables/paginationTable";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { UserContext } from "../../../helper/ProtectedRoute";
const SettlementHistory = () => {
  const navigate = useNavigate();
  const prevent = useRef(false);
  const ref = useRef();
  const paramHeader = useParams();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [employee, setEmployee] = useState("");
  const [country, setCountry] = useState("");
  const [category, setCategory] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [settlementStatus, setSettlementStatus] = useState("");
  const [refNum, setRefNum] = useState("");
  const [size, setSize] = useState(20);
  const [dateRange, setDateRange] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [ledgerData,setLedgerData] = useState("")
  const [selectedOptionEmployee, setSelectedOptionEmployee] = useState(null);
  const [modalHandler, setModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const [courierPage, setCourierPage] = useState(-1);
  const getSettlement = useCollectionStore((state) => state?.getSettlement);
  const exportSettlements = useCollectionStore((state) => state?.exportSettlements);
  const getEmployee = useAoneStore((state) => state?.getEmployee);
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const settlementStatusList = useCollectionStore((state) => state?.settlementStatusList);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [state] = useContext(UserContext);
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const [dateRangefil, setDateRangefil] = useState({});
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const gridId = "finance.settlement";
  const [column, SetCoulmn] = useState([
    "Employee Code",
    "Employee Name",
    "Amount",
    "Currency",
    "Location",
    "Proof",
    "Settled by",
    "Settled at",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "Settlement at"
  ]);
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const [loadingMore, setLoadingMore] = useState(false);
  const [prevSearch, setPrevSearch] = useState("");
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsCourier = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await getEmployee(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: employee.firstName,
            value: employee.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, courierPage, prevSearch, setPrevSearch]
  );
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&fields=employeeName&employeeCode=${paramHeader?.id}`;
    if (country) {
        params += `&country=${category}`;
      }
      if (settlementStatus) {
        params += `&settleBy=${settlementStatus}`;
      }
      try {
        const res = await exportSettlements(params);
        if (res.status == 200) {
          fileDownload(res.data, `Settlements.xlsx`);
        }
      } catch (err) {}
}
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&fields=employeeName&employeeCode=${paramHeader?.id}`;
      if (country) {
        params += `&countryCode=${country}`;
      }
      if (employee) {
        params += `&employeeCode=${employee}`;
      }
      if (settlementStatus) {
        params += `&settleBy=${settlementStatus}`;
      }
      if (
        dateRangefil.start !== null &&
        dateRangefil.end !== null &&
        !isNaN(dateRangefil.start) &&
        !isNaN(dateRangefil.end) &&
        dateRangefil.start !== dateRangefil.end
      ) {
        params += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
      }
      const data = await getSettlement(params);
      setTotalCount(data?.data?.data?.totalElements);
      setLedgerData(data?.data?.data?.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    country,
    employee,
    settlementStatus,
    trackingId,
    refNum,
    dateRangefil.start,
    dateRangefil.end,
    ispostAdded,
  ]);
  return (
    <div>
      <div>
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                    Settled By:
                    </label>
                    <AsyncSearchInput
                    id="settlementStatus"
                    label="settlementStatus"
                    name="settlementStatus"
                    value={selectedOptionEmployee}
                    loadMoreOptions={loadMoreOptionsCourier}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(employee) => employee.label}
                    getOptionValue={(employee) => employee.value}
                    onMenuOpen={handleMenuOpenorgcity}
                    onMenuClose={handleMenuCloseorgcity}
                    menuIsOpen={isReactoriginselectCity}
                    onChange={(selectedOptionEmployee) => {
                      setSelectedOptionEmployee(selectedOptionEmployee);
                      setSettlementStatus(
                        selectedOptionEmployee ? selectedOptionEmployee.value : ""
                      );
                    }}
                    placeholder="Settled By"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Settlement Date:
                    </label>
                    <DateRangePicker
                      format="yyyy-MM-dd"
                      placeholder="Settlement Date"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      placement="auto"
                      minDate={
                        new Date(new Date().setMonth(new Date().getMonth() - 3))
                      }
                      onOpen={handleMenuOpen}
                      onClose={handleMenuClose}
                      showMeridian
                       className="w200"
                    />
                  </Col>
                </Row>
              </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn"
                  title={"Export Settlement History"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>
  
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column?.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <PaginationTable
            tableData={ledgerData}
            tbl={"Settlement"}
            restrict={checkedCol}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            isDropdownselectOpen={isDropdownselectOpen}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
      </div>
    </div>
  );
};

export default SettlementHistory;
