import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useDeliveryStore } from "../../../store/DeliveryMgt/DeliveryStore";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import { UserContext } from "../../../helper/ProtectedRoute";
import Modal from "react-bootstrap/Modal";
import downloadicon from "../../../assets/img/download_icon.svg";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import jwt_decode from "jwt-decode";
import { useFormik } from "formik";
import fileDownload from "js-file-download";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const DebriefHistory = () => {
  const navigate = useNavigate();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [VehicleActive, SetVehicleActive] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(-1);
  const [courierPage, setCourierPage] = useState(-1);
  const [courierFilterPage, setCourierFilterPage] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCourier, setSelectedOptionCourier] = useState(null);
  const [selectedOptionEmployee, setSelectedOptionEmployee] = useState(null);
  const [isActivated, SetisActivated] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [deliveryData, setDeliveryData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const [courier, setCourier] = useState("");
  const [employee, setEmployee] = useState("");
  const [hub, setHub] = useState("");
  const [dateRangefil, setDateRangefil] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const getDeliveryTask = useDeliveryStore((state) => state?.getDeliveryTask);
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const postDeliveryTask = useDeliveryStore((state) => state?.postDeliveryTask);
  const exportDeliveryTasks = useDeliveryStore(
    (state) => state?.exportDeliveryTasks
  );
  const importDeliveryTasks = useDeliveryStore(
    (state) => state?.importDeliveryTasks
  );
  const getdeliveryStatus = useDeliveryStore(
    (state) => state?.getdeliveryStatus
  );
  const deliveryStatusList = useDeliveryStore(
    (state) => state?.deliveryStatusList
  );
  const DeliveryTaskTemplate = useDeliveryStore(
    (state) => state?.DeliveryTaskTemplate
  );
  const removeDeliveryTask = useDeliveryStore(
    (state) => state?.removeDeliveryTask
  );
  const getDebreifHistory = useDeliveryStore(
    (state) => state?.getDebreifHistory
  );
  const exportDebreifingHistory = useDeliveryStore(
    (state) => state?.exportDebreifingHistory
  );
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const [loadingMore, setLoadingMore] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const getEmployee = useAoneStore((state) => state?.getEmployee);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  useEffect(() => {
    if (deliveryStatusList?.data?.data === undefined) {
      getdeliveryStatus();
    }
  }, []);
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const addDeliveryTask = useFormik({
    initialValues: {
      trackingId: "",
      employeeCode: "",
    },
    validationSchema: Yup.object({
      trackingId: Yup.string().required("Tracking id is required"),
      employeeCode: Yup.string().required("Courier is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postDeliveryTask(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Delivery task added`);
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          getData();
          setShow(false);
          resetForm();
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShow(false);
          resetForm();
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          toast.error(errorMessages);
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilterPage(-1);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          resetForm();
        }
      });
    },
  });
  const [deliveryId, SetDeliveryId] = useState("");
  const unAssignCourier = async () => {
    const res = await removeDeliveryTask(deliveryId);
    if (res?.data?.status == "ERROR") {
      toast.error(res?.data?.msg);
      setShowAssign(false);
    } else if (res?.data?.status == "SUCCESS") {
      toast.success(`Courier unassigned from the delivery task`);
      setShowAssign(false);
      getData();
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=employeeName`;
    if (courier) {
      params += `&courierId=${courier}`;
    }
    if (employee) {
      params += `&debriefingBy=${employee}`;
    }
    if (hub) {
      params += `&hubCode=${hub}`;
    }
    if (dateRangefil?.start) {
      params += `&startTime=${dateRangefil?.start}`;
    }
    if (dateRangefil?.end) {
      params += `&endTime=${dateRangefil?.end}`;
    }
    const data = await getDebreifHistory(params);
    setTotalCount(data?.data?.data?.totalElements);
    setDeliveryData(data?.data?.data?.content);
  };
  const exporttoExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=employeeName`;
    if (courier) {
      params += `&courierId=${courier}`;
    }
    if (employee) {
      params += `&debriefingBy=${employee}`;
    }
    if (hub) {
      params += `&hubCode=${hub}`;
    }
    if (dateRangefil?.start) {
      params += `&startTime=${dateRangefil?.start}`;
    }
    if (dateRangefil?.end) {
      params += `&endTime=${dateRangefil?.end}`;
    }
    try {
      const res = await exportDebreifingHistory(params);
      if (res.status == 200) {
        fileDownload(res.data, `Debriefing History.xlsx`);
      } else if (res?.response?.status === 500) {
        toast.error("Internal Server Error");
      } else {
        toast.error(res.response.statusText);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    employee,
    courier,
    hub,
    dateRangefil,
    VehicleActive,
    ispostAdded,
    isActivated,
  ]);
  const downloadTemplate = async () => {
    try {
      const res = await DeliveryTaskTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Delivery tasks Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            importDeliveryTasks(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (
                  response?.data?.status === "ERROR" &&
                  (response?.data?.data?.length == 0 ||
                    response?.data?.data?.length === undefined)
                ) {
                  // Handle error response from the server
                  const errorMessage = response?.data?.msg;
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.data?.length > 0) {
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (
                  response.data.data.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  const [prevSearch, setPrevSearch] = useState("");
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [showAssign, setShowAssign] = useState(false);
  const handleassignShow = () => setShowAssign(true);
  const handleassignClose = () => {
    setShowAssign(false);
  };
  const handleassignCancel = () => {
    setShowAssign(false);
  };
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const [userHub, setUserHub] = useState("");
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  const loadMoreOptionsCourier = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierPage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsEmployee = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierFilterPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierFilterPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await getEmployee(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: employee.firstName + ' ' + employee.lastName,
            value: employee.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierFilterPage, prevSearch, setPrevSearch]
  );
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedOptionCourier(null);
    setCourierPage(-1);
    setCourierFilterPage(-1);
  };
  const handleCancel = () => {
    handleClose();
  };
  return (
    <div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Courier:
                  </label>
                  <AsyncSearchInput
                    id="employee"
                    label="employee"
                    name="employee"
                    value={selectedOptionCourier}
                    loadMoreOptions={loadMoreOptionsCourier}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    menuIsOpen={isReactselectCity}
                    onChange={(selectedOptionCourier) => {
                      setSelectedOptionCourier(selectedOptionCourier);
                      setCourier(
                        selectedOptionCourier
                          ? selectedOptionCourier.value
                          : ""
                      );
                    }}
                    customStyles={{ control: { width: "175px" } }}
                    placeholder="Courier"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Debriefer:
                  </label>
                  <AsyncSearchInput
                    id="employee"
                    label="employee"
                    name="employee"
                    value={selectedOptionEmployee}
                    loadMoreOptions={loadMoreOptionsEmployee}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuOpenorgcity}
                    onMenuClose={handleMenuCloseorgcity}
                    menuIsOpen={isReactoriginselectCity}
                    onChange={(selectedOptionEmployee) => {
                      setSelectedOptionEmployee(selectedOptionEmployee);
                      setEmployee(
                        selectedOptionEmployee
                          ? selectedOptionEmployee.value
                          : ""
                      );
                    }}
                    customStyles={{ control: { width: "175px" } }}
                    placeholder="Debriefer"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Hub:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <AsyncSearchInput
                      id="hubCode"
                      label="hubCode"
                      name="hubCode"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptionsGroups}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        setHub(selectedOption ? selectedOption.value : "");
                      }}
                      customStyles={{ control: { width: "175px" } }}
                      placeholder="Hub"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Debreifing Date:
                  </label>
                  <DateRangePicker
                    format="yyyy-MM-dd"
                    placeholder="Select Date"
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    placement="auto"
                    minDate={
                      new Date(new Date().setMonth(new Date().getMonth() - 3))
                    }
                    onOpen={handleMenuOpen}
                    onClose={handleMenuClose}
                    showMeridian
                    className="w200"
                  />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                  <button
                    className="filterBtn plus_icon mleft_10"
                    title="Export Delivery Tasks"
                    onClick={() => exporttoExcel()}
                  >
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
                  <button
                    className="filterBtn plus_icon"
                    title="Sync"
                    onClick={() => syncronizeRoles()}
                  >
                    <span>
                      <img src={syncicon} className="h18" />
                    </span>
                  </button>
                </>
              </div>
            </div>
          </div>
        )}
      </div>
      <PaginationTable
        tbl="DebreifingHistory"
        tableData={deliveryData}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        size={size}
        addDeliveryTask={addDeliveryTask}
        setSize={setSize}
        setSortField={setSortField}
        sortField={sortField}
        setShowUnAssignCourier={setShowAssign}
        setShowAssignCourier={setShow}
        SetDeliveryId={SetDeliveryId}
        totalCount={totalCount}
        isDropdownselectOpen={isDropdownselectOpen}
        setNewPage={setNewPage}
        newPage={newPage}
      />
    </div>
  );
};

export default DebriefHistory;
