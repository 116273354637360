import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getRtoRequest,getRtobyId,getRtobyPackageId,submitRtoRequest,submitSingleRtoRequest,
    exportRtoRequest,scanRtoPackage,markPackageException,cancelRto,discardRtoScan,deleteRtoPackage,listWarningType,listRtoStatus} from "../../services/RtoMgt/Rto_Services";
const initialValues = {
  rtoPackagesList: [],
  rtoWarnList: [],
  rtoStatusList: [],
};
let rtoStore = (set, get) => ({
  ...initialValues,
  getRtoRequest: async (params) => {
        const response = await getRtoRequest(params);
        return response;
      },
      getRtobyId: async (params) => {
        const response = await getRtobyId(params);
        set({ rtoPackagesList: response });
        return response;
      },
      getRtobyPackageId: async (params) => {
        const response = await getRtobyPackageId(params);
        set({ rtoPackagesList: response });
        return response;
      },
      submitRtoRequest: async (params) => {
        const response = await submitRtoRequest(params);
        return response;
      },
      submitSingleRtoRequest: async (params) => {
        const response = await submitSingleRtoRequest(params);
        return response;
      },
      deleteRtoPackage: async (params,params2) => {
        const response = await deleteRtoPackage(params,params2);
        return response;
      },
      cancelRto: async (params) => {
        const response = await cancelRto(params);
        return response;
      },
      discardRtoScan: async (params) => {
        const response = await discardRtoScan(params);
        return response;
      },
      exportRtoRequest: async (params) => {
        const response = await exportRtoRequest(params);
        return response;
      },
      scanRtoPackage: async (params,params2) => {
        const response = await scanRtoPackage(params,params2);
        return response;
      },
      markPackageException: async (params,params2,data) => {
        const response = await markPackageException(params,params2,data);
        return response;
      },
      listWarningType: async () => {
        const response = await listWarningType();
        set({ rtoWarnList: response });
        return response;
      },
      listRtoStatus: async () => {
        const response = await listRtoStatus();
        set({ rtoStatusList: response });
        return response;
      },
});

rtoStore = devtools(rtoStore);

export const useRtoStore = create(rtoStore);