import React, { useState, useRef, useEffect ,useContext} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import downloadicon from "../../../assets/img/download_icon.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import PaginationTable from "../../../components/Tables/paginationTable";
import OpenModal from "../../../components/modal/openModal";
import { useParams } from "react-router";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
const Zones = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [state] = useContext(UserContext);
    const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
    useEffect(() => {
      setRole(state.roles);
    }, [state.roles]);
    const [editPage, setEditPage] = useState(false);
    const [zonecode, setZoneCode] = useState("");
    const [alias, setAlias] = useState("");
    const [country, setCountry] = useState("");
    const [groupCode, setGroupCode] = useState("");
    const [newPage, setNewPage] = useState(0);
    const [addressData, setAddressData] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const [modalHandler, setModalHandler] = useState(false);
    const [deleteHandler, setDeleteHandler] = useState(false);
    const [statusHandler, setStatusHandler] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
    const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
    const [size, setSize] = useState(20);
    const [totalCount, setTotalCount] = useState(0);
    const [sortField, setSortField] = useState("-updatedAt");
    const [addModalHandler, setAddModalHandler] = useState(false);
    const [editHandler, setEditHandler] = useState(false);
    const [sortBy, setSortBy] = useState("-updatedAt");
    const [isDelete, setIsDelete] = useState("");
    const [key, setKey] = useState(0);
    const getZone = useAoneStore((state) => state.getZone);
    const getHubs = useAoneStore((state) => state?.getHubs);
    const hubsData = useAoneStore((state) => state?.hubsData);
    const getZoneTemplate = useAoneStore((state) => state.getZoneTemplate);
    const exportZones = useAoneStore((state) => state.exportZones);
    const importZones = useAoneStore((state) => state.importZones);
    const getZoneGroups = useAoneStore((state) => state.getZoneGroups);
    const getZonegroupTemplate = useAoneStore((state) => state.getZonegroupTemplate);
    const exportZonegroup = useAoneStore((state) => state.exportZonegroup);
    const importZonegroup = useAoneStore((state) => state.importZonegroup);
    const getCountries = useAoneStore((state) => state?.getCountries);
    const countriesData = useAoneStore((state) => state?.countriesData);
    const handleMenudesregion = () => {
      setReactDesregion(true);
    };
    const handleMenuClosedesregion = () => {
      setReactDesregion(false);
    };
    const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
    const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setZoneCode("");
        setTab(i);
        navigate("/configs/zones");
      };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (tab === 1) {
      if (zonecode) {
        params += `&code=${zonecode}`;
      }
      if (alias) {
        params += `&alias=${alias}`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      const data = await getZone(params);
      setTotalCount(data.data.data.totalElements);
      setAddressData(data.data.data.content);
    } else {
        if (groupCode) {
          params += `&code=${groupCode}`;
        }
        if (country) {
          params += `&countryCode=${country}`;
        }
    const data = await getZoneGroups(params);
    setTotalCount(data.data.data.totalElements);
    setZoneData(data.data.data.content);
  }
  }
      const syncronizeRoles = async () => {
        setNewPage(0);
        getData();
      };
    useEffect(() => {
      getData();
    }, [
      newPage,
      sortBy,
      size,
      sortField,
      tab, 
      editPage,
      location,
      country,
      statusHandler,
      isDelete,
      ispostAdded,
      alias,
      zonecode,
      groupCode
    ]);
    useEffect(() => {
      let paramslink = `?page=${0}&size=${30}`;
      if(hubsData?.data?.data?.content?.length < 1 || hubsData?.data?.data?.content?.length == undefined){
      getHubs(paramslink)
      }
      if (
        countriesData?.data?.data?.content?.length < 1 ||
        countriesData?.length < 1
      ) {
        getCountries(paramslink);
      }
  }, [])
    const downloadTemplate = async () => {
      try {
        if (tab === 1) {
          const res = await getZoneTemplate();
          if (res.status == 200) {
            fileDownload(res.data, `Zones Template.xlsx`);
          }
        } else {
          const res = await getZonegroupTemplate();
          if (res.status == 200) {
            fileDownload(res.data, `Zone Groups Template.xlsx`);
          }
        }
      } catch (err) { }
    };
            //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);
          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            let importFunction;
            switch (tab) {
              case 1:
                importFunction = importZones;
                break;
              default:
                importFunction = importZonegroup;
            }
            importFunction(formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  if(response?.data?.data === undefined){
                    const errorMessage =
                    response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                  }else{
                  const errorMessages = response?.data?.data
                  .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                  .join(", ");
                toast.error(`Error uploading the file: ${errorMessages}`);
                const errorMessage =
                  response?.response?.data?.msg?.split(",")[0];
                toast.error(errorMessage);
                fileInput.value = "";
                  }
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (zonecode) {
        params += `&code=${zonecode}`;
      }
      try {
        const res = await exportZones(params);
        if (res.status == 200) {
          fileDownload(res.data, `Zones.xlsx`);
        }
      } catch (err) { }
    } else {
      if (groupCode) {
        params += `&code=${groupCode}`;
      }
      try {
        const res = await exportZonegroup(params);
        if (res.status == 200) {
          fileDownload(res.data, `Zone Groups.xlsx`);
        }
      } catch (err) { }
    }
}
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }}>
                Configs
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
              >
                Zones
              </span>
            </li>
            {tab === 2 &&
            <><li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                <span>
                  <img
                    src={breadArrow}
                    className="turnBlack"
                    alt="breadcrumbarrow" />
                </span>
              </li><li>
                  <span
                    className="mainheadlink"
                    style={{ fontWeight: "bolder", marginLeft: "-3px" }}
                  >
                    Groups
                  </span>
                </li></>}
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => { tabHandler(1); setCountry(""); setKey((prevKey) => prevKey + 1); setSortField("-updatedAt");}}
        >
          Zones
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => { tabHandler(2); setCountry(""); setKey((prevKey) => prevKey + 1); setSortField("-updatedAt");}}
        >
          Zone Groups
        </button>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            {tab === 1 ? (
              <div className="displayFlex justifyContent_end mleft_5">
                  <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">Zone Code:</label>
                <div className="displayFlex alignItemCenter marginR10 position-relative">
                  <input
                    type="text"
                    value={zonecode}
                    className={
                        zonecode?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    onChange={(e) => setZoneCode(e.target.value)}
                    style={
                        zonecode?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                  />
                     {zonecode && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setZoneCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                </div>
                </Col>
                <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">Alias:</label>
                <div className="displayFlex alignItemCenter marginR10 position-relative">
                  <input
                    type="text"
                    value={alias}
                    className={
                      alias?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    onChange={(e) => setAlias(e.target.value)}
                    style={
                      alias?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                  />
                    {alias && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setAlias("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                </div>
                </Col>
                <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">Country:</label>
                <SearchInput
                  key={key}
                  id="country"
                  label="country"
                  name="country"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isSearchable={true}
                  placeholder="Country"
                  customStyles={{ control: { width: "180px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
                </Row>
              </div>
            ) : (
              <div className="displayFlex justifyContent_end mleft_5">
                  <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Group Code:
                </label>
                <div className="displayFlex alignItemCenter marginR10 position-relative">
                  <input
                    type="text"
                    value={groupCode}
                    className={
                        groupCode?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                        groupCode?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                    onChange={(e) => setGroupCode(e.target.value)}
                  />
                   {groupCode && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setGroupCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                </div>
                </Col>
                <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">Country:</label>
                <SearchInput
                  id="country"
                  label="country"
                  name="country"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  isClearable={true}
                  handleChange={(selectedOption) => {
                    setCountry(
                      selectedOption ? selectedOption.isoCode3 : ""
                    );
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isSearchable={true}
                  placeholder="Country"
                  customStyles={{ control: { width: "180px" } }}
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </Col>
                </Row>
              </div>
            )}
            <div>
              <div className="displayFlex gap_12 mt_40">
              {((role?.includes("AONE_CONFIGS.write")) || (role?.includes("AONE_CONFIGS.admin"))||(role?.includes("AONE_CONFIGS_ZONES.write")) || (role?.includes("AONE_CONFIGS_ZONES.admin"))) && ( 
                <><button
                    className="filterBtn plus_icon mr_10 ml_10"
                    title={tab === 1      
                      ? "Zones Template"
                      : "Zonegroup Template"}
                    onClick={downloadTemplate}
                  >
                    <span>
                      <img src={templateIcon} className="h18" />
                    </span>
                  </button><div title={tab === 1
                    ? "Import Zones"
                    : "Import Zonegroup"} className="ml_10">
                      <label className="filterBtn plus_icon" htmlFor="uploadFile">
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden />
                      </label>
                    </div></>
              )}
                <button
                  className="filterBtn"
                  title={
                    tab === 1
                      ? "Export Zones"
                          : "Export Zonegroup"
                  }
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {((role?.includes("AONE_CONFIGS.write")) || (role?.includes("AONE_CONFIGS.admin"))||(role?.includes("AONE_CONFIGS_ZONES.write")) || (role?.includes("AONE_CONFIGS_ZONES.admin"))) && ( 
                <button
                  className="filterBtn plus_icon"
                  title="Add"
                  onClick={() => setAddModalHandler(!addModalHandler)}
                  tabIndex="-1"
                >
                  +
                </button>)}
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={tab === 1 ? addressData : zoneData}
            tbl={
              tab == 1
                ? "Zone"
                    : "Zonegroups"
            }
            title={tab === 1 ? "Edit Zone" :  "Edit Zone Group"}
                    modalHandler={modalHandler}
                    setModalHandler={setModalHandler}
                    deleteHandler={deleteHandler}
                    setDeleteHandler={setDeleteHandler}
                    newPage={newPage}
                    setIndividualCheckboxesBag={setIndividualCheckboxesBag}
                    individualCheckboxesbag={individualCheckboxesbag}
                    setNewPage={setNewPage}
                    size={size}
                    setSize={setSize}
                    totalCount={totalCount}
                    sortField={sortField} 
                    setSortField={setSortField}
          />
        )}
        {addModalHandler && (
        <OpenModal
                modalHandler={addModalHandler}
                setModalHandler={setAddModalHandler}
                title={tab === 1 ? "Create New Zone" : "Create New Zone Group"}
                editHandler={editHandler}
                setEditHandler={setEditHandler}
              />)}
      </div>
    </div>
  )
}

export default Zones