import React, { useEffect, useState, useContext } from "react";
import dotIcon from "../../../assets/img/dotsbold.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UserContext } from "../../../helper/ProtectedRoute";
import removeIcon from "../../../../src/assets/img/cancelIcon.svg";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
const Regions = ({ edit }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const postRegions = useAoneStore((state) => state.postRegions);
  const getRegionById = useAoneStore((state) => state.getRegionById);
  const updateRegionById = useAoneStore((state) => state.updateRegionById);
  const getRegions = useAoneStore((state) => state.getRegions);
  const filterCities = useAoneStore((state) => state.filterCities);
  const filterCountriesies = useAoneStore((state) => state?.filterCountriesies);
  const getZone = useAoneStore((state) => state.getZone);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [minibar, setMinibar] = useState(0);
  const [data, setData] = useState({});
  const [alaises, setAlaises] = useState([]);
  const [locales, setLocales] = useState({});
  const setAllDefault = () => {
    setAlaises([]);
    setLocales({});
    edit(false);
    navigate("/configs/addresses");
  };
  const country = useFormik({
    initialValues: {
      name: params?.id ? data?.name : "",
      countryName: params?.id ? data?.countryName : "",
      countryIsoCode3: params?.id ? data?.countryIsoCode3 : "",
      code: params?.id ? data?.code : "",
      postalCode: params?.id ? data?.postalCode : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim() // Remove leading and trailing spaces
        .matches(/\S/, "Name should not contain only spaces")
        .required("Name is Required"),
      countryIsoCode3: Yup.string().required("Country Iso Code 3 is Required"),
      countryName: Yup.string().required("Country is Required"),
      code: Yup.string()
        .trim()
        .matches(/\S/, "Code should not contain only spaces")
        .required("Code is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values["aliases"] = alaises;
      values["locales"] = locales;
      if (params?.id) {
        values["id"] = data.id;
        await updateRegionById(params?.id, values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Region Updated");
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postRegions(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Region Created");
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
      setAllDefault();
    },
  });
  // const handleCountryChange = (e, form) => {
  //     const selectedOption = e.target.options[e.target.selectedIndex];
  //     const isoCode3 = selectedOption.getAttribute("data-iso-code3");
  //     form.setFieldValue("countryName", e.target.value);
  //     form.setFieldValue("countryIsoCode3", isoCode3);
  //   };
  const handleCountryChange = (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const isoCode3 = selectedOption.isoCode3;
    form.setFieldValue("countryName", selectedOption.name);
    form.setFieldValue("countryIsoCode3", isoCode3);
  };
  const languages = [
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "en", label: "English" },
    { key: "tr", label: "Turkish" },
  ];
  const [resetKey, setResetKey] = useState(0);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");

  const minibarhandler = (i) => {
    setFirstValue("");
    setSecondValue("");
    if (i == minibar) {
      setMinibar(0);
    } else {
      setMinibar(i);
    }
  };

  const handleObjectDelete = (key) => {
    let newObject = { ...locales };
    delete newObject[key];
    setLocales(newObject);
  };

  const handleObjectAdd = () => {
    if (firstValue.trim() === "" || secondValue.trim() === "") {
      toast.error("Cannot add empty value for locales");
    } else {
      setResetKey((prevKey) => prevKey + 1);
      let newObject = { ...locales };
      newObject[firstValue] = secondValue;
      setLocales(newObject);
      setFirstValue("");
      setSecondValue("");
    }
  };

  const handleArrayDelete = (i) => {
    let newArray = [...alaises];
    newArray.splice(i, 1);
    setAlaises(newArray);
  };

  const handleArrayAdd = () => {
    if (firstValue.trim() === "") {
      toast.error("Cannot add empty value in Alaises");
    } else {
      let newArray = [...alaises];
      newArray.push(firstValue);
      setAlaises(newArray);
      setFirstValue("");
    }
  };

  const getData = async () => {
    const data = await getRegionById(params.id);
    setData(data.data.data);
    setAlaises(data.data.data.aliases ?? []);
    setLocales(data.data.data.locales ?? {});
  };

  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    filterCities(paramslink);
    params?.id && getData();
  }, []);
  return (
    <div className="row">
      <div className="col-md-6" style={{ padding: "12px" }}>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Country: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="countryName"
              label="Country"
              name="countryName"
              isClearable={true}
              value={
                country?.values?.countryName
                  ? countriesData?.data?.data?.content?.find(
                      (cnt) => cnt?.name === country?.values?.countryName
                    )
                  : null
              }
              options={countriesData?.data?.data?.content || []}
              getOptionLabel={(country) => country.name}
              getOptionValue={(country) => country}
              handleChange={(selectedOption) =>
                handleCountryChange(selectedOption, country)
              }
              isSearchable={true}
              placeholder="Select Country"
            />
            {country.touched.countryName && country.errors.countryName ? (
              <p className="requiredText">{country.errors.countryName}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Name: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Name"
              className="mainInput"
              name="name"
              onChange={(e) => country.setFieldValue("name", e.target.value)}
              onBlur={country.handleBlur}
              value={country.values.name}
            />
            {country.touched.name && country.errors.name ? (
              <p className="requiredText">{country.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Locales:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(1)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Code: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Code"
              className="mainInput"
              name="code"
              onChange={(e) => country.setFieldValue("code", e.target.value)}
              onBlur={country.handleBlur}
              value={country.values.code}
              disabled={params?.id ? true : false}
            />
            {country.touched.code && country.errors.code ? (
              <p className="requiredText">{country.errors.code}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Country ISO Code 3: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Country Iso Code 3"
              className="mainInput"
              name="countryIsoCode3"
              onChange={(e) =>
                country.setFieldValue("countryIsoCode3", e.target.value)
              }
              onBlur={country.handleBlur}
              value={country.values.countryIsoCode3}
              disabled
            />
            {country.touched.countryIsoCode3 &&
            country.errors.countryIsoCode3 ? (
              <p className="requiredText">{country.errors.countryIsoCode3}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Postal Code:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Postal Code"
              className="mainInput"
              name="postalCode"
              onChange={(e) =>
                country.setFieldValue("postalCode", e.target.value)
              }
              onBlur={country.handleBlur}
              value={country.values.postalCode}
            />
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Alaises:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(2)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        {(role?.includes("AONE_CONFIGS.write") ||
          role?.includes("AONE_CONFIGS.admin") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
          <div className="row mb_24">
            <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={country.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => setAllDefault()}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6" style={{ padding: "12px" }}>
        {minibar == 1 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Locales</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                <SearchInput
                  key={resetKey}
                  id="firstValue"
                  label="firstValue"
                  name="firstValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                <input
                  type="text"
                  placeholder="Value"
                  style={{ marginLeft: "15px" }}
                  value={secondValue || ""}
                  onChange={(e) => setSecondValue(e.target.value)}
                  className="mainInput"
                />
              </div>
              <button className="filterBtn" onClick={() => handleObjectAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Languages</th>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                            <td>FR</td>
                            <td>AUBA AUBA</td>
                            <td><button className='cancel_btn'>Remove</button></td>
                        </tr> */}
                  {Object.keys(locales).map((value, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{value}</td>
                        <td>{locales[value]}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleObjectDelete(value)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : minibar == 2 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Alaises</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <input
                type="text"
                placeholder="Values"
                value={firstValue}
                style={{ width: "50%" }}
                onChange={(e) => setFirstValue(e.target.value)}
                className="mainInput"
              />
              <button className="filterBtn" onClick={() => handleArrayAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <td>ABC</td>
                            <td><button className='cancel_btn'>Remove</button></td> */}
                  {alaises.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleArrayDelete(e)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Regions;
