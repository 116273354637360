import React, { useState, useEffect, useContext } from 'react'
import { toast } from "react-toastify";
import { useAoneStore } from "../../store/AoneStore"
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { roleHandler } from "../../helper/roleHandler"
import { UserContext } from "../../helper/ProtectedRoute";
const Booking = ({ setTab }) => {
  const navigate = useNavigate()
  const params = useParams();
  const [data, setData] = useState([])
  const globalcustomerAccount = useAoneStore((state) => state?.globalcustomerAccount);
  const getBookingbyId = useAoneStore((state) => state?.getBookingbyId);
  const editBookingSystem = useAoneStore((state) => state?.editBookingSystem);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleStatus = async () => {
    const updatedIsEnabled = !isEnabled;
    setIsEnabled(updatedIsEnabled);
  };
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const handleSubmit = async () => {
    try {
      const response = await editBookingSystem(params?.id, isEnabled);
      if (response?.data?.status === "ERROR") {
        const errorMessage = response.data.msg.split(",")[0];
        toast.error(errorMessage);
      } else if (response?.response?.status === 500) {
        toast.error(response?.response?.statusText);
      } else if (response?.response?.data?.status === "ERROR") {
        const errorMessages = response.response.data.errors
          .map((item) => `${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
      } else {
        toast.success("Booking setting Updated");
        navigate("/crm/customers");
      }
    } catch (error) {
      // Handle unexpected errors
      if (error?.response?.status === 500) {
        toast.error(error?.response?.statusText);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };
  const getData = async () => {
    const res = await getBookingbyId(params.id)
    console.log("data",data)
    setData(res?.data?.data)
    setIsEnabled(res?.data?.data?.addressMappingEnabled)
  }
  useEffect(()=>{
    getData();
  },[])
  return (
    <div className='row'>
      <div className='col-md-6' style={{ padding: "12px" }}>
        <div className='row mb_22'>
          <div className='col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8'>
            <label>Enable address mapping:</label>
          </div>
          <div className='col-md-8 mt_8'>
          <div className="toggle-switch-container">
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={isEnabled}
                        onChange={toggleStatus}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
          </div>
        </div>
        {(roleHandler(role, "AONE_CRM.write") || roleHandler(role, "AONE_CRM.admin") || roleHandler(role, "AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.write")) && (
          <div className='row mb_22' style={{ marginTop: "40px" }}>
            <div className='col-md-12 displayFlex alignItemCenter justifyContent_end gap_16'>
              <button type="button" onClick={handleSubmit} className='blue_btn'>
                Save
              </button>
              <button className='cancel_btn'>
                Cancel
              </button>
            </div>
          </div>)}
      </div>
    </div>
  )
}

export default Booking;