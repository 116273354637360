import React, { useEffect, useState, useContext, useCallback } from "react";
import dotIcon from "../../../assets/img/dotsbold.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import removeIcon from "../../../../src/assets/img/cancelIcon.svg";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import MapViewWithDraw from "../../../components/Map/MapDraw";
const Districts = ({ edit }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [resetParam, setResetParams] = useState(false);
  const filterRegions = useAoneStore((state) => state.filterRegions);
  const filterZone = useAoneStore((state) => state.filterZone);
  const filterCities = useAoneStore((state) => state.filterCities);
  const getDistrictById = useAoneStore((state) => state.getDistrictById);
  const postDistrict = useAoneStore((state) => state.postDistrict);
  const updateDistrictById = useAoneStore((state) => state.updateDistrictById);
  const getRegionbyCode = useAoneStore((state) => state.getRegionbyCode);
  const getCitybyCode = useAoneStore((state) => state.getCitybyCode);
  const getDistrictGeometry = useAoneStore((state) => state.getDistrictGeometry);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const zoneData = useAoneStore((state) => state?.zoneData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const citybyCode = useAoneStore((state) => state?.citybyCode);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const [minibar, setMinibar] = useState(0);
  const [data, setData] = useState({});
  const [alaises, setAlaises] = useState([]);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [locales, setLocales] = useState({});
  const setAllDefault = () => {
    setAlaises([]);
    setMandatoryFields([]);
    setLocales({});
    edit(false);
    navigate("/configs/addresses");
  };
  const district = useFormik({
    initialValues: {
      name: params?.id ? data?.name : "",
      cityCode: params?.id ? data?.cityCode : "",
      countryIsoCode3: params?.id ? data?.countryIsoCode3 : "",
      regionCode: params?.id ? data?.regionCode : "",
      code: params?.id ? data?.code : "",
      postalCode: params?.id ? data?.postalCode : "",
      zoneCode: params?.id ? data?.zoneCode : "",
      remote: params?.id ? (data?.remote ? true : false) : false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .matches(/\S/, "District Name should not contain only spaces")
        .required("District Name is Required"),
      countryIsoCode3: Yup.string().required("Country is Required"),
      regionCode: Yup.string().required("Region is Required"),
      cityCode: Yup.string().required("City is Required"),
      code: Yup.string()
        .trim()
        .matches(/\S/, "Code should not contain only spaces")
        .required("Code is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values["mandatoryFields"] = mandatoryFields;
      values["aliases"] = alaises;
      values["locales"] = locales;
      if (params?.id) {
        values["id"] = data.id;
        await updateDistrictById(params?.id, values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("District Updated");
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.status === "ERROR") {
            response?.response?.data?.errors?.forEach((error) => {
              toast.error(error.msg);
            });
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postDistrict(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("District Created");
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.status === "ERROR") {
            response?.response?.data?.errors?.forEach((error) => {
              toast.error(error.msg);
            });
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
      setAllDefault();
    },
  });
  const [showZone, setShowZone] = useState(params?.id == undefined);
  const handleInputZoneClick = () => {
    if (params?.id != undefined) {
      setShowZone(true);
    }
  };
  useEffect(() => {
    if (params?.id) {
      getCityonEdit();
    }
  }, [data]);
  const getCityonEdit = async () => {
    const cityData = await getCitybyCode(
      data?.countryIsoCode3,
      data?.regionCode
    );
  };
  useEffect(() => {
    if (!params.id) {
      district.setFieldValue("regionCode", "");
      district.setFieldValue("cityCode", "");
    }
  }, [district.values.countryIsoCode3]);
  useEffect(() => {
    if (!params.id) {
      setResetParams(true);
      district.setFieldValue("cityCode", "");
    }
  }, [district.values.regionCode]);
  const languages = [
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "en", label: "English" },
    { key: "tr", label: "Turkish" },
  ];
  const [resetKey, setResetKey] = useState(0);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const minibarhandler = (i) => {
    setFirstValue("");
    setSecondValue("");
    if (i === minibar) {
      setMinibar(0);
    } else {
      setMinibar(i);
      if (i === 4) {
        getDistrictGeometry(params?.id);
      }
    }
  };
  

  const handleObjectDelete = (key) => {
    let newObject = { ...locales };
    delete newObject[key];
    setLocales(newObject);
  };
  const handleObjectAdd = () => {
    if (firstValue.trim() === "" || secondValue.trim() === "") {
      toast.error("Cannot add empty value");
    } else {
      setResetKey((prevKey) => prevKey + 1);
      let newObject = { ...locales };
      newObject[firstValue] = secondValue;
      setLocales(newObject);
      setFirstValue("");
      setSecondValue("");
    }
  };

  const handleArrayDelete = (i) => {
    if (minibar == 3) {
      let newArray = [...alaises];
      newArray.splice(i, 1);
      setAlaises(newArray);
    } else {
      let newArray = [...mandatoryFields];
      newArray.splice(i, 1);
      setMandatoryFields(newArray);
    }
  };

  const handleArrayAdd = () => {
    if (firstValue.trim() === "") {
      toast.error("Cannot add empty value");
    } else {
      let newArray = [...alaises];
      newArray.push(firstValue);
      setAlaises(newArray);
      setFirstValue("");
    }
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const getData = async () => {
    const data = await getDistrictById(params.id);
    setData(data.data.data);
    setAlaises(data?.data?.data?.aliases ?? []);
    setMandatoryFields(data.data.data.mandatoryFields ?? []);
    setLocales(data.data.data.locales ?? {});
  };
  const [page, setPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    filterRegions(paramslink);
    filterCities(paramslink);
    params?.id && getData();
  }, []);
  const [maxPages, setMaxPages] = useState(0);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;

    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await filterZone(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );

  const handleRegionChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const regionCode = selectedOption.code; // Use the correct property for the region code
    form.setFieldValue("regionCode", regionCode);
    // Call your API with isoCode3
    try {
      const cityData = await getCitybyCode(
        form.values.countryIsoCode3,
        regionCode
      );
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };
  const handleCityChange = async (selectedOption, form) => {
    if (!selectedOption) {
      // Handle the case where selectedOption is undefined
      return;
    }
    const cityCode = selectedOption.code; // Use the correct property for the region code
    form.setFieldValue("cityCode", cityCode);
  };
  const handleCreated = (e) => {
    const { layerType, layer } = e;
    const drawnItems = layer.toGeoJSON();
    // Save the drawn items' coordinates or other relevant information here
  };

  const handleEdited = (e) => {
    const layers = e.layers;
    layers.eachLayer((layer) => {
      const updatedLayer = layer.toGeoJSON();
      // Update your state or database with the new coordinates or information
    });
  };

  const handleDeleted = (e) => {
    const layers = e.layers;
    layers.eachLayer((layer) => {
      const deletedLayer = layer.toGeoJSON();
      // Remove the deleted layer's data from your state or database
    });
  };
  return (
    <div className="row">
      <div className="col-md-6" style={{ padding: "12px" }}>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Country:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="countryIsoCode3"
              label="Country"
              name="countryIsoCode3"
              isClearable={true}
              options={countriesData?.data?.data?.content || []}
              getOptionLabel={(country) => country.name}
              getOptionValue={(country) => country.isoCode3}
              value={
                district.values.countryIsoCode3
                  ? countriesData?.data?.data?.content?.find(
                      (country) =>
                        country.isoCode3 === district.values.countryIsoCode3
                    )
                  : null
              }
              handleChange={async (selectedOption) => {
                // Update form field when an option is selected
                district.setFieldValue(
                  "countryIsoCode3",
                  selectedOption ? selectedOption.isoCode3 : ""
                );

                // Call your API with isoCode3
                try {
                  const regionData = await getRegionbyCode(
                    selectedOption.isoCode3
                  );
                  // if(regionData?.data?.data?.length === 1){
                  const cityData = await getCitybyCode(
                    regionData?.data?.data?.[0]?.countryIsoCode3,
                    regionData?.data?.data?.[0]?.code
                  );
                  // }
                } catch (error) {
                  console.error("Error fetching region data:", error);
                }
              }}
              isSearchable={true}
              placeholder="Select Country"
              isDisabled={params?.id ? true : false}
            />
            {district.touched.countryIsoCode3 &&
            district.errors.countryIsoCode3 ? (
              <p className="requiredText">{district.errors.countryIsoCode3}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Region:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="regionCode"
              label="Region"
              name="regionCode"
              isClearable={true}
              options={regionbyCode || []}
              getOptionLabel={(region) => region.name}
              getOptionValue={(region) => region}
              value={
                district.values.regionCode
                  ? regionsData?.data?.data?.content?.find(
                      (zone) => zone.code === district.values.regionCode
                    )
                  : null
              }
              handleChange={(selectedOption) =>
                handleRegionChange(selectedOption, district)
              }
              isSearchable={true}
              placeholder="Select Region"
              isDisabled={params?.id ? true : false}
            />
            {district.touched.regionCode && district.errors.regionCode ? (
              <p className="requiredText">{district.errors.regionCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              City:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="cityCode"
              label="City"
              name="cityCode"
              options={citybyCode || []}
              getOptionLabel={(city) => city.name}
              getOptionValue={(city) => city.code}
              value={
                district.values.cityCode
                  ? citybyCode?.find(
                      (city) => city.code === district.values.cityCode
                    )
                  : null
              }
              handleChange={(selectedOption) =>
                handleCityChange(selectedOption, district)
              }
              isClearable={true}
              isSearchable={true}
              placeholder="Select City"
              isDisabled={params?.id ? true : false}
            />
            {district.touched.cityCode && district.errors.cityCode ? (
              <p className="requiredText">{district.errors.cityCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Name:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Name"
              className="mainInput"
              name="name"
              onChange={(e) => district.setFieldValue("name", e.target.value)}
              onBlur={district.handleBlur}
              value={district.values.name}
            />
            {district.touched.name && district.errors.name ? (
              <p className="requiredText">{district.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Locales:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(1)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Code:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Code"
              className="mainInput"
              name="code"
              onChange={(e) => district.setFieldValue("code", e.target.value)}
              onBlur={district.handleBlur}
              value={district.values.code}
              disabled={params?.id ? true : false}
            />
            {district.touched.code && district.errors.code ? (
              <p className="requiredText">{district.errors.code}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Alaises:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(3)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Postal Code:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Postal Code"
              className="mainInput"
              name="postalCode"
              onChange={(e) =>
                district.setFieldValue("postalCode", e.target.value)
              }
              onBlur={district.handleBlur}
              value={district.values.postalCode}
            />
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Zone:</label>
          </div>
          <div className="col-md-8">
            {showZone ? (
              <AsyncSearchInput
                id="zoneCode"
                label="Zone"
                name="zoneCode"
                value={selectedOption}
                loadMoreOptions={loadMoreOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                  district.setFieldValue(
                    "zoneCode",
                    selectedOption ? selectedOption.value : ""
                  );
                }}
                placeholder="Select Zone"
              />
            ) : (
              <input
                type="text"
                value={data?.zoneCode}
                name="zoneCode"
                className="mainInput"
                onClick={handleInputZoneClick}
              />
            )}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Remote:</label>
          </div>
          <div className="col-md-8">
            <div className="smallCheckbox">
              <input
                type="checkbox"
                id="remote"
                style={{ marginLeft: "5px" }}
                checked={district.values.remote}
                onChange={() => {
                  district.setFieldValue("remote", !district.values.remote);
                  if (!district.values.remote) {
                    district.setFieldValue("remote", true); // Set bagNumber to an empty string
                  }
                }}
              />
              <label className="margintop7" htmlFor="remote"></label>
            </div>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Geometry:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(4)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        {(role?.includes("AONE_CONFIGS.write") ||
          role?.includes("AONE_CONFIGS.admin") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
          <div className="row mb_24">
            <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="submit"
                className="blue_btn"
                onClick={district.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => setAllDefault()}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6" style={{ padding: "12px" }}>
        {minibar == 1 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Locales</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                <SearchInput
                  key={resetKey}
                  id="firstValue"
                  label="firstValue"
                  name="firstValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                <input
                  type="text"
                  placeholder="Value"
                  style={{ marginLeft: "15px" }}
                  value={secondValue || ""}
                  onChange={(e) => setSecondValue(e.target.value)}
                  className="mainInput"
                />
              </div>
              <button className="filterBtn" onClick={() => handleObjectAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Languages</th>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                        <td>FR</td>
                        <td>AUBA AUBA</td>
                        <td><button className='cancel_btn'>Remove</button></td>
                    </tr> */}
                  {Object.keys(locales).map((value, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{value}</td>
                        <td>{locales[value]}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleObjectDelete(value)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                Done
                </button> */}
            </div>
          </div>
        ) : minibar == 2 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Mandatory Fields</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <input
                type="text"
                placeholder="Field"
                style={{ width: "50%" }}
                value={firstValue}
                onChange={(e) => setFirstValue(e.target.value)}
                className="mainInput"
              />
              <button className="filterBtn" onClick={() => handleArrayAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Fields</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mandatoryFields.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleArrayDelete(e)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                Done
                </button> */}
            </div>
          </div>
        ) : minibar == 3 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Alaises</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <input
                type="text"
                placeholder="Values"
                value={firstValue}
                style={{ width: "50%" }}
                onChange={(e) => setFirstValue(e.target.value)}
                className="mainInput"
              />
              <button className="filterBtn" onClick={() => handleArrayAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <td>ABC</td>
                        <td><button className='cancel_btn'>Remove</button></td> */}
                  {alaises.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleArrayDelete(e)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                Done
                </button> */}
            </div>
          </div>
        ) : minibar == 4 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Boundary</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <MapViewWithDraw
                onCreated={handleCreated}
                onEdited={handleEdited}
                onDeleted={handleDeleted}
                boundaryData={data}
                params={params?.id}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Districts;
