import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../../store/AoneStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../../components/Tables/paginationTable";
import OpenModal from "../../../components/modal/openModal";
import * as XLSX from "xlsx";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { AsyncPaginate } from "react-select-async-paginate";
const Hub = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [hub, setHub] = useState("");
  const [zone, setZone] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [state] = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [currencyData, setCurrencyData] = useState([]);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const allowedeventData = useAoneStore((state) => state?.allowedeventData);
  const cityArray = useAoneStore((state) => state?.cityArray);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const [editHandler, setEditHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const [selectedOption, setSelectedOption] = useState(null);
  const exportCountryMap = useAoneStore((state) => state?.exportCountryMap);
  const getHubTypes = useAoneStore((state) => state?.getHubTypes);
  const getHubs = useAoneStore((state) => state?.getHubs);
  const getZone = useAoneStore((state) => state?.getZone);
  const getServiceAreas = useAoneStore((state) => state?.getServiceAreas);
  const setglobalHubCode = useAoneStore((state) => state?.setglobalHubCode);
  const allowedeventLookup = useAoneStore((state) => state?.allowedeventLookup);
  const exportHubs = useAoneStore((state) => state?.exportHubs);
  const getHubTemplate = useAoneStore((state) => state?.getHubTemplate);
  const importHub = useAoneStore((state) => state?.importHub);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const filterRegions = useAoneStore((state) => state?.filterRegions);
  const filterCities = useAoneStore((state) => state?.filterCities);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const [desCityPage, setDescityPage] = useState(-1);
  const hubTypes = useAoneStore((state) => state?.hubTypes);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const handleMenuOpen = () => {
    setReactSelect(true);
  };

  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const loadMoreDesCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : desCityPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDescityPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desCityPage]
  );
  const [selectedOptionDesCity, setSelectedOptionDesCity] = useState(null);
  const downloadTemplate = async () => {
    try {
      const res = await getHubTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Hubs Template.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (code) {
        params += `&code=$${code}$&page=0`;
      }
      if (name) {
        params += `&name=$${name}$`;
      }
      if (alias) {
        params += `&alias=${alias}`;
      }
      if (country) {
        params += `&countryIsoCode3=${country}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      if (city) {
        params += `&cityCode=${city}`;
      }
      const data = await getHubs(params);
      setTotalCount(data?.data?.data?.totalElements);
      setAssetData(data?.data?.data?.content);
    } else {
      if (hub) {
        params += `&hubCode=${hub}`;
      }
      if (zone) {
        params += `&zoneCode=${zone}`;
      }
      const data = await getServiceAreas(params);
      setTotalCount(data?.data?.data?.totalElements);
      setServiceData(data?.data?.data?.content);
    }
  };
  // useEffect(()=>{
  //   setNewPage(0);
  // },[code,name,country,region,city])
  //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            importHub(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  toast.error(errorMessages);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessage = response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (
                  response.data.data.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (code) {
      params += `&code=${code}`;
    }
    if (name) {
      params += `&name=${name}`;
    }
    if (alias) {
      params += `&alias=${alias}`;
    }
    if (country) {
      params += `&countryIsoCode3=${country}`;
    }
    if (region) {
      params += `&regionCode=${region}`;
    }
    if (city) {
      params += `&cityCode=${city}`;
    }
    try {
      const res = await exportHubs(params);
      if (res.status == 200) {
        fileDownload(res.data, `Hubs.xlsx`);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (selectedOption) {
      setZone(selectedOption.value);
    }
  }, [selectedOption]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpens = () => {
    setIsMenuOpen(true);
  };

  const handleMenuCloses = () => {
    setIsMenuOpen(false);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    tab,
    editPage,
    sortField,
    location,
    saveHandler,
    code,
    zone,
    hub,
    alias,
    name,
    country,
    region,
    ispostAdded,
    city,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    let paramsRegionlink = `?page=${0}&size=${30}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.data?.data?.content?.length == undefined
    ) {
      filterCountries(paramslink);
    }
    if (
      regionsData?.data?.data?.content?.length < 1 ||
      regionsData?.data?.data?.content?.length == undefined
    ) {
      filterRegions(paramsRegionlink);
    }
    if (
      citiesData?.data?.data?.content?.length < 1 ||
      citiesData?.data?.data?.content?.length == undefined
    ) {
      filterCities(paramslink);
    }
    if (
      hubTypes?.data?.data?.length < 1 ||
      hubTypes?.data?.data?.length == undefined
    ) {
      getHubTypes();
    }
    if (
      allowedeventData?.data?.data?.length < 1 ||
      allowedeventData?.data?.data?.length == undefined
    ) {
      allowedeventLookup();
    }
  }, []);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;

    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Trigger a state update to increment the page number
        setPage((prevPage) => prevPage + 1);

        // Simulate an API call (replace this with your actual API call)
        const response = await getZone(`?page=${page}&size=20&code=${search}`);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/assets/hubs")}
              >
                Assets
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/assets/hubs");
                  setglobalHubCode("");
                }}
              >
                Hubs
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              {tab === 1 ? (
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Code:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={code}
                        className={
                          code?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          code?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setCode(e.target.value)}
                      />
                      {code && (
                        <button
                          className="cancel_btn clear-btn"
                          type="button"
                          onClick={() => setCode("")}
                        >
                          <span>
                            <img src={removeIcon} alt="Clear" />
                          </span>
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Name:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={name}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setName(e.target.value)}
                      />
                      {name && (
                        <button
                          className="cancel_btn clear-btn"
                          type="button"
                          onClick={() => setName("")}
                        >
                          <span>
                            <img src={removeIcon} alt="Clear" />
                          </span>
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Alias:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={alias}
                        className={
                          alias?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          alias?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setAlias(e.target.value)}
                      />
                      {alias && (
                        <button
                          className="cancel_btn clear-btn"
                          type="button"
                          onClick={() => setAlias("")}
                        >
                          <span>
                            <img src={removeIcon} alt="Clear" />
                          </span>
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        setCountry(
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isReactselectOpen}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      customStyles={{ control: { width: "160px" } }}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Region:
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={regionsData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        setRegion(selectedOption ? selectedOption.code : "");
                      }}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Region"
                      customStyles={{ control: { width: "160px" } }}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      City:
                    </label>
                    <AsyncSearchInput
                      id="city"
                      label="city"
                      name="city"
                      value={selectedOptionDesCity}
                      loadMoreOptions={loadMoreDesCityOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuOpencity}
                      onMenuClose={handleMenuClosecity}
                      menuIsOpen={isReactselectCity}
                      customStyles={{ control: { width: "160px" } }}
                      onChange={(selectedOptionDesCity) => {
                        setSelectedOptionDesCity(selectedOptionDesCity);
                        if (selectedOptionDesCity) {
                          setCity(selectedOptionDesCity.value);
                        } else {
                          setCity(null);
                        }
                      }}
                      placeholder="City"
                    />
                    {/* <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={cityArray?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        setCity(selectedOption ? selectedOption.code : "");
                      }}
                      onMenuOpen={handleMenuOpencity}
                      onMenuClose={handleMenuClosecity}
                      menuIsOpen={isReactselectCity}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="City"
                      customStyles={{ control: { width: "160px" } }}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    /> */}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Hub:
                    </label>
                    <SearchInput
                      id="hub"
                      label="hub"
                      name="hub"
                      options={hubsData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.code}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        setHub(selectedOption ? selectedOption.code : "");
                      }}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isReactselectOpen}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Hub"
                      customStyles={{ control: { width: "160px" } }}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                  <Col className="ml_5" style={{ width: "290px !important" }}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Zone:
                    </label>
                    <div style={{ width: "165px" }}>
                      <AsyncPaginate
                        id="zoneCode"
                        label="Zone"
                        name="zoneCode"
                        value={selectedOption}
                        loadOptions={(search) => loadMoreOptions(search)}
                        shouldLoadMore={(
                          scrollHeight,
                          clientHeight,
                          scrollTop
                        ) =>
                          shouldLoadMore(scrollHeight, clientHeight, scrollTop)
                        }
                        getOptionLabel={(zone) => zone.label}
                        getOptionValue={(zone) => zone.value}
                        onChange={(selectedOption) => {
                          if (selectedOption === null) {
                            setSelectedOption(null);
                            setZone("");
                          } else {
                            setSelectedOption(selectedOption);
                          }
                        }}
                        isSearchable={true}
                        placeholder="Zone"
                        onMenuOpen={handleMenuOpens}
                        onMenuClose={handleMenuCloses}
                        isClearable={true}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#fff2f2", // Option hover color
                            primary: "#ec171c", // Control primary color
                            primary50: "#FCAEAE", // Selected option color
                            neutral20: "#7a869a",
                          },
                        })}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                {(roleHandler(role, "AONE_ASSETS.write") ||
                  roleHandler(role, "AONE_ASSETS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
                  <>
                    {tab === 1 && (
                      <>
                        <button
                          style={{ marginLeft: "10px" }}
                          className="filterBtn plus_icon"
                          title="Hub Template"
                          onClick={downloadTemplate}
                        >
                          <span>
                            <img src={templateIcon} className="h18" />
                          </span>
                        </button>
                        <div title="Import Hub">
                          <label
                            className="filterBtn plus_icon"
                            htmlFor="uploadFile"
                          >
                            <span>
                              <img src={importIcon} className="h18" />
                            </span>
                            <input
                              type="file"
                              id="uploadFile"
                              onInput={(e) => handleFileChange(e)}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              hidden
                            />
                          </label>
                        </div>
                      </>
                    )}
                  </>
                )}
                {tab === 1 && (
                  <button
                    className="filterBtn"
                    title={"Export Hubs"}
                    onClick={() => exportToExcel()}
                  >
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
                )}
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_ASSETS.write") ||
                  roleHandler(role, "AONE_ASSETS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.admin") ||
                  roleHandler(role, "AONE_ASSETS_HUBS.write")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    // onClick={() => setAddModalHandler(!addModalHandler)}
                    onClick={() => navigate("/assets/hubs/add")}
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={tab === 1 ? assetData : serviceData}
            tbl={tab === 1 ? "Hubs" : "Service Areas"}
            title={tab === 1 ? "Edit Hub" : "Edit Service Areas"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            isMenuOpen={isMenuOpen}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
        {addModalHandler && (
          <OpenModal
            modalHandler={addModalHandler}
            setModalHandler={setAddModalHandler}
            title={tab === 1 ? "Add new Hub" : "Create service area"}
            editHandler={editHandler}
            setEditHandler={setEditHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Hub;
