import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../assets/img/template_icon.svg";
import importIcon from "../../assets/img/importIcon.svg";
import downloadicon from "../../assets/img/download_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../store/AoneStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import * as XLSX from "xlsx";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { UserContext } from "../../helper/ProtectedRoute";
const Origin = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const params = useParams();
  const location = useLocation();
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [size, setSize] = useState(20);
  const [sortBy, setSortBy] = useState("createdAt");
  const [totalCount, setTotalCount] = useState(0);
  const [originData, setOriginData] = useState([]);
  const [isDelete, setIsDelete] = useState("");
  const [modalHandler, setModalHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [editHandler, setEditHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const countriesData = useAoneStore((state) => state?.countriesData);
  const cityArray = useAoneStore((state) => state?.cityArray);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const getRegions = useAoneStore((state) => state?.getRegions);
  const getCities = useAoneStore((state) => state?.getCities);
  const getOrigin = useAoneStore((state) => state?.getOrigin);
  const exportOrigin = useAoneStore((state) => state?.exportOrigin);
  const globalproductCode = useAoneStore((state) => state?.globalproductCode);
  const getOriginTemplate = useAoneStore(
    (state) => state?.getOriginTemplate
  );
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const importOrigin = useAoneStore((state) => state?.importOrigin);
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const downloadTemplate = async () => {
    try {
      const res = await getOriginTemplate(params?.id ? params?.id : globalproductCode);
      if (res.status == 200) {
        fileDownload(res.data, `Origins Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            importOrigin(params?.id,formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };

  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (country) {
      params += `&country=${country}`;
    }
    if (region) {
      params += `&region=${region}`;
    }
    if (city) {
      params += `&city=${city}`;
    }
    try {
      const res = await exportOrigin(params?.id ? params?.id : globalproductCode,params);
      if (res.status == 200) {
        fileDownload(res.data, `Origins.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let paramslink = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (country) {
      paramslink += `&country=${country}`;
    }
    if (region) {
      paramslink += `&region=${region}`;
    }
    if (city) {
      paramslink += `&city=${city}`;
    }
    const data = await getOrigin(params?.id ? params?.id : globalproductCode,paramslink);
    setTotalCount(data.data.data.totalElements);
    setOriginData(data.data.data.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    location,
    modalHandler,
    addModalHandler,
    statusHandler,
    saveHandler,
    isDelete,
    country,
    city,
    region,
  ]);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if(countriesData?.data?.data?.content === undefined){
    getCountries(paramslink)
    }
    if(regionsData?.data?.data?.content === undefined){
    getRegions(paramslink)
    }
    if(cityArray?.data?.data?.content === undefined){
    getCities(paramslink)
    }
  }, [])
  return (
    <div>
      <div className="row alignItemCenter">
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Country:
                  </label>
                  <SearchInput
                    id="country"
                    label="country"
                    name="country"
                    options={countriesData?.data?.data?.content || []}
                    isClearable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.isoCode3 : "");
                    }}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    menuIsOpen={isReactselectOpen}
                    isSearchable={true}
                    placeholder="Country"
                    customStyles={{ control: { width: "160px" } }}
                    className={
                      country?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Region:
                  </label>
                  <SearchInput
                    id="region"
                    label="region"
                    name="region"
                    isClearable={true}
                    options={regionsData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.code}
                    handleChange={(selectedOption) => {
                      setRegion(selectedOption ? selectedOption.code : "");
                    }}
                    onMenuOpen={handleMenuOpenregion}
                    onMenuClose={handleMenuCloseregion}
                    menuIsOpen={isReactselectRegion}
                    isSearchable={true}
                    placeholder="Region"
                    customStyles={{ control: { width: "160px" } }}
                    className={
                      region?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    City:
                  </label>
                  <SearchInput
                    id="city"
                    label="city"
                    name="city"
                    options={cityArray?.data?.data?.content || []}
                    isClearable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.code}
                    handleChange={(selectedOption) => {
                      setCity(selectedOption ? selectedOption.code : "");
                    }}
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    menuIsOpen={isReactselectCity}
                    isSearchable={true}
                    placeholder="City"
                    customStyles={{ control: { width: "160px" } }}
                    className={
                      city?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                  <button
                    style={{ marginLeft: "10px" }}
                    className="filterBtn plus_icon ml_10"
                    title={"Download"}
                    onClick={downloadTemplate}
                  >
                    <span>
                      <img src={templateIcon} className="h18" />
                    </span>
                  </button>
                  <div title={"Import"}>
                    <label className="filterBtn plus_icon" htmlFor="uploadFile">
                      <span>
                        <img src={importIcon} className="h18" />
                      </span>
                      <input
                        type="file"
                        id="uploadFile"
                        onInput={(e) => handleFileChange(e)}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden
                      />
                    </label>
                  </div>
                </>
                <button
                  className="filterBtn"
                  title={"Export"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Add"
                  onClick={() => setAddModalHandler(!addModalHandler)}
                  tabIndex="-1"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={originData}
            tbl={"Origins"}
            title={"Edit origin"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Add origin"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />)}
      </div>
    </div>
  );
};

export default Origin;
