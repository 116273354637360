import React from 'react'
import { logout } from '../helper/auth_helper'

const NoContent = () => {
  return (
    <section class="page_404">
        <div>
        <h3 className='textAlignCenter'>No Content Available</h3>
        
        <p className='textAlignCenter'>Look like you don't have any access available right now!</p>
        
        <div className='displayFlex justifyContent_center'>
            <a href="/" onClick={()=>logout()} class="link_404">Back To Login</a>
        </div>
        </div>
    </section>
  )
}

export default NoContent