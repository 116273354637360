import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import PaginationTable from "../../components/Tables/paginationTable";
import syncicon from "../../../src/assets/img/sync.svg";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import downloadicon from "../../assets/img/download_icon.svg";
import { useAoneStore } from "../../store/AoneStore";
import fileDownload from "js-file-download";
import { DateRangePicker } from "rsuite";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const Events = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPage, setNewPage] = useState(0);
  const [editPage, setEditPage] = useState(false);
  const [size, setSize] = useState(20);
  const prevent = useRef(false);
  const [name, setName] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const getEvents = useAoneStore((state) => state?.getEvents);
  const exportEvents = useAoneStore((state) => state?.exportEvents);
  const [dateRange, setDateRange] = useState([]);
  const [dateRangefil, setDateRangefil] = useState({
    start: null,
    end: null,
  });
  const [waybillNum, setWaybillNum] = useState("");
  const [refNum, setRefNum] = useState("");
  const [status, setStatus] = useState("");
  const [carrier, setCarrier] = useState("");
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      setDateRange(value);

      const startMillis = value[0].getTime(); // Convert start date to Milliseconds
      const endMillis = value[1].getTime(); // Convert end date to Milliseconds

      setDateRangefil({
        start: startMillis,
        end: endMillis,
      });
    } else {
      setDateRange([]);
      setDateRangefil({
        start: null,
        end: null,
      });
    }
  };
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (carrier) {
      params += `&carrier=${carrier}`;
    }
    if (refNum) {
      params += `&referenceNumber=${refNum}`;
    }
    if (waybillNum) {
      params += `&waybillNumber=${waybillNum}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (dateRangefil.start !== null) {
      params += `&startTime=${dateRangefil.start}`;
    }
    if (dateRangefil.end !== null) {
      params += `&endTime=${dateRangefil.end}`;
    }
    const data = await getEvents(params);
    setTotalCount(data.data.data.totalElements);
    setAddressData(data.data.data.content);
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (carrier) {
      params += `&carrier=${carrier}`;
    }
    if (refNum) {
      params += `&referenceNumber=${refNum}`;
    }
    if (waybillNum) {
      params += `&waybillNumber=${waybillNum}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (dateRangefil.start !== null) {
      params += `&startTime=${dateRangefil.start}`;
    }
    if (dateRangefil.end !== null) {
      params += `&endTime=${dateRangefil.end}`;
    }
    try {
      const res = await exportEvents(params);
      if (res.status == 200) {
        fileDownload(res.data, `Events.xlsx`);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
    if (prevent.current) {
      const delayFn = setTimeout(() => {
        getData();
      }, 1500);
      return () => clearTimeout(delayFn);
    } else {
      prevent.current = true;
    }
  }, [
    carrier,
    waybillNum,
    refNum,
    dateRangefil.start,
    dateRangefil.end,
    status,
    newPage,
    size,
    sortField,
    deleteHandler
  ]);
  return (
    <div>
      <div className="row alignItemCenter">
        <ul className="page_breadcrumb">
          <li>
            <span className="mainheadlink" style={{ fontWeight: "500" }}>
              Third Party
            </span>
          </li>
          <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
            <span>
              <img
                src={breadArrow}
                className="turnBlack"
                alt="breadcrumbarrow"
              />
            </span>
          </li>
          <li>
            <span
              className="mainheadlink"
              style={{
                fontWeight: "500",
                marginLeft: "-3px",
                fontWeight: "bolder",
              }}
            >
              Events
            </span>
          </li>
        </ul>
      </div>
      {!editPage && (
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_8">
          <Row>
            <div className="displayFlex">
              <Col md={2}>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    placeholder="Carrier"
                    value={carrier}
                    onChange={(e) => setCarrier(e.target.value)}
                    className={
                      carrier?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      carrier?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                  />
                </div>
              </Col>
              <Col md={2}>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    placeholder="Reference Number"
                    value={refNum}
                    onChange={(e) => setRefNum(e.target.value)}
                    className={
                      refNum?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      refNum?.length > 0
                        ? { border: "2px solid #ec171c", marginLeft: "10px" }
                        : { marginLeft: "10px" }
                    }
                  />
                </div>
              </Col>
              <Col md={2}>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    placeholder="Waybill Number"
                    value={waybillNum}
                    onChange={(e) => setWaybillNum(e.target.value)}
                    className={
                      waybillNum?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      waybillNum?.length > 0
                        ? { border: "2px solid #ec171c", marginLeft: "10px" }
                        : { marginLeft: "10px" }
                    }
                  />
                </div>
              </Col>
              <Col md={2} style={{ marginLeft: "10px" }}>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    placeholder="Status"
                    className={
                      status?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      status?.length > 0
                        ? { border: "2px solid #ec171c", marginLeft: "10px" }
                        : { marginLeft: "10px" }
                    }
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={2} style={{ marginLeft: "10px" }}>
                <div className="displayFlex alignItemCenter gap_12">
                  <div className="customDateRange">
                    <DateRangePicker
                      placeholder="Select Date Range"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      placement="auto"
                      style={{ borderRadius: "8px" }}
                      onOpen={handleMenuOpen}
                      onClose={handleMenuClose}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Row>
          <div className="displayFlex gap_12">
            <div>
              <button
                className="filterBtn mr_10p"
                title={"Export Scans"}
                onClick={() => exportToExcel()}
              >
                <span>
                  <img src={downloadicon} className="h18" />
                </span>
              </button>
              <button className="filterBtn" title="Sync">
                <span>
                  <img
                    src={syncicon}
                    className="h18"
                    onClick={() => syncronizeRoles()}
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <PaginationTable
        tableData={addressData}
        tbl="Events"
        modalHandler={editPage}
        setModalHandler={setEditPage}
        deleteHandler={deleteHandler}
        isDropdownselectOpen={isDropdownselectOpen}
        setDeleteHandler={setDeleteHandler}
        newPage={newPage}
        setNewPage={setNewPage}
        size={size}
        setSize={setSize}
        totalCount={totalCount}
        sortField={sortField}
        setSortField={setSortField}
      />
    </div>
  );
};

export default Events;
