import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import deleteIcon from "../../../assets/img/delete_icon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useweightStore } from "../../../store/WeighingMgt/WeighingStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
const VehicleDocumentModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  vehiclebyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const importVehiclesDocuments = usevehicleStore(
    (state) => state?.importVehiclesDocuments
  );
  const postVehicleDocument = usevehicleStore(
    (state) => state?.postVehicleDocument
  );
  const EditVehicleDocument = usevehicleStore(
    (state) => state?.EditVehicleDocument
  );
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const vehicleList = usevehicleStore((state) => state?.vehicleList);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const weightUnitList = useweightStore((state) => state?.weightUnitList);
  const dimensionUnitList = useweightStore((state) => state?.dimensionUnitList);
  const [resetKey, setResetKey] = useState(0);
  const [vehicleCode, setVehicleCode] = useState("");
  const currentDate = new Date();
  const formattDate = (dateString) => {
    if (!dateString) return "";
    const dateObject = new Date(dateString);
    const timestamp = dateObject.getTime();
    return timestamp;
  };
  const addVehicleDoc = useFormik({
    initialValues: {
      rcDoc: "",
      rcDocType: "",
      rcFile: "",
      rcValidityDate: "",
      insuranceDoc: "",
      insuranceDocType: "",
      insuranceFile: "",
      insuranceValidityDate: "",
      pucDoc: "",
      pucDocType: "",
      pucFile: "",
      pucValidityDate: "",
      opsCard: "",
      opsCardType: "",
      opsFile: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const { rcFile, insuranceFile, pucFile, ...apiValues } = values;
      // Format date strings
      const formattedValues = {
        rcDoc: values.rcDoc || null,
        rcDocType: values?.rcDocType,
        rcValidityDate: values.rcValidityDate
          ? formattDate(values.rcValidityDate)
          : null,
        insuranceDoc: values.insuranceDoc || null,
        insuranceDocType: values?.insuranceDocType,
        insuranceValidityDate: values.insuranceValidityDate
          ? formattDate(values.insuranceValidityDate)
          : null,
        pucDoc: values.pucDoc || null,
        pucDocType: values?.pucDocType,
        pucValidityDate: values.pucValidityDate
          ? formattDate(values.pucValidityDate)
          : null,
        opsCard: values.opsCard || null,
        opsCardType: values?.opsCardType,
      };
      if (title === "Edit Vehicle Document") {
        await EditVehicleDocument(vehicleCode, formattedValues).then(
          (response) => {
            if (response?.data?.status == "SUCCESS") {
              toast.success("Vehicle document updated");
              setispostAdded(!ispostAdded);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.errors?.length > 0) {
              const errorMessages = response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.errors?.length > 0) {
              const errorMessages = response?.response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            }
          }
        );
      } else {
        await postVehicleDocument(vehicleCode, formattedValues).then(
          (response) => {
            if (response?.data?.status == "SUCCESS") {
              toast.success("Vehicle document added");
              setispostAdded(!ispostAdded);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.errors?.length > 0) {
              const errorMessages = response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.errors?.length > 0) {
              const errorMessages = response?.response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else {
              const errorMessage = response?.response?.data?.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            }
          }
        );
      }
    },
  });
  useEffect(() => {
    if (title === "Edit Vehicle Document") {
      setFile("");
      setVehicleCode(vehiclebyCode?.vehicleCode);
      const formattedDate = vehiclebyCode?.rcValidityDate?.replace(/\//g, "-");
      setRcValidityDate(formattedDate);
      const formattedDateInsurance =
        vehiclebyCode?.insuranceValidityDate?.replace(/\//g, "-");
      setInsuranceValidityDate(formattedDateInsurance);
      const formattedDatePuc = vehiclebyCode?.pucValidityDate?.replace(
        /\//g,
        "-"
      );
      setPucValidityDate(formattedDatePuc);
      addVehicleDoc.setFieldValue("rcDoc", vehiclebyCode?.rcDocumentUrl);
      addVehicleDoc.setFieldValue("rcFile", "");
      addVehicleDoc.setFieldValue(
        "rcValidityDate",
        vehiclebyCode?.rcValidityDate
      );
      addVehicleDoc.setFieldValue(
        "insuranceDoc",
        vehiclebyCode?.insuranceDocumentUrl
      );
      addVehicleDoc.setFieldValue("insuranceFile", "");
      addVehicleDoc.setFieldValue(
        "insuranceValidityDate",
        vehiclebyCode?.insuranceValidityDate
      );
      addVehicleDoc.setFieldValue("pucDoc", vehiclebyCode?.pucDocumentUrl);
      addVehicleDoc.setFieldValue("pucFile", "");
      addVehicleDoc.setFieldValue(
        "pucValidityDate",
        vehiclebyCode?.pucValidityDate
      );
      addVehicleDoc.setFieldValue("opsCard", vehiclebyCode?.opsCard);
    }
  }, [title, vehiclebyCode]);
  const [file, setFile] = useState(null);
  const [rcValidityDate, setRcValidityDate] = useState(null);
  const [insuranceValidityDate, setInsuranceValidityDate] = useState(null);
  const [pucValidityDate, setPucValidityDate] = useState(null);
  const handleDateChange = (fieldName) => (date) => {
    const formattedDate = formatDate(date); // Format the date
    addVehicleDoc.setFieldValue(fieldName, formattedDate);
    setRcValidityDate(formattedDate);
  };

  function formatDate(dateString) {
    const date = new Date(dateString + " UTC");
    const year = date.getUTCFullYear();
    const month = padZeroes(date.getUTCMonth() + 1);
    const day = padZeroes(date.getUTCDate());
    return `${year}-${month}-${day}`;
  }
  function padZeroes(value) {
    return value.toString().padStart(2, "0");
  }
  function handleFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".png", ".pdf", ".doc", ".docx", ".xlsx"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .xlsx file or .png"
      );
      return; 
    }
    addVehicleDoc.setFieldValue("rcDocType", fileExtension);
    const fileSizeLimit = 1024 * 1024;
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    setFile(file);
    addVehicleDoc.setFieldValue("rcFile", file);

    // Check if the element with the ID "file-name" exists in the document
    const fileNameDisplay = document.getElementById("file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;

      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  function handleInsuranceFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".png", ".pdf", ".doc", ".docx", ".xlsx"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      // Show toast error
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .xlsx file or .png"
      );
      return; 
    }
    addVehicleDoc.setFieldValue("insuranceDocType", fileExtension);
    const fileSizeLimit = 1024 * 1024; // 1MB
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    setFile(file);
    // Assuming addVehicleDoc is a state or reference to Formik's form instance
    addVehicleDoc.setFieldValue("insuranceFile", file);

    const fileInput = event.target;
    const fileNameDisplay = document.getElementById("insurance-file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  function handlePUCFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".png", ".pdf", ".doc", ".docx", ".xlsx"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      // Show toast error
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .xlsx file or .png"
      );
      return; 
    }
    addVehicleDoc.setFieldValue("pucDocType", fileExtension);
    const fileSizeLimit = 1024 * 1024; // 1MB
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    setFile(file);
    // Assuming addVehicleDoc is a state or reference to Formik's form instance
    addVehicleDoc.setFieldValue("pucFile", file);

    const fileInput = event.target;
    const fileNameDisplay = document.getElementById("puc-file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  function handleOpsFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".png", ".pdf", ".doc", ".docx", ".xlsx"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      // Show toast error
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .xlsx file or .png"
      );
      return; 
    }
    addVehicleDoc.setFieldValue("opsCardType", fileExtension);
    const fileSizeLimit = 1024 * 1024; // 1MB
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    setFile(file);
    // Assuming addVehicleDoc is a state or reference to Formik's form instance
    addVehicleDoc.setFieldValue("opsFile", file);
    const fileInput = event.target;
    const fileNameDisplay = document.getElementById("ops-file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  useEffect(() => {
    if (addVehicleDoc?.values?.opsFile && vehicleCode) {
      let formData = new FormData();
      formData.append("file", file);
      importVehiclesDocuments(vehicleCode, "OpsCard", 0, formData)
        .then((response) => {
          addVehicleDoc.setFieldValue("opsCard", response?.data?.data?.uuid);
        })
        .catch((error) => {
          // Handle API error
        });
    }
  }, [addVehicleDoc?.values?.opsFile]);
  useEffect(() => {
    if (file && rcValidityDate && vehicleCode) {
      let formData = new FormData();
      formData.append("file", file);
      // Function to calculate the size of FormData
      importVehiclesDocuments(vehicleCode, "RC", rcValidityDate, formData)
        .then((response) => {
          addVehicleDoc.setFieldValue("rcDoc", response?.data?.data?.uuid);
        })
        .catch((error) => {
          // Handle API error
        });
    }
  }, [rcValidityDate, addVehicleDoc?.values?.rcFile]);
  useEffect(() => {
    if (file && insuranceValidityDate && vehicleCode) {
      let formData = new FormData();
      formData.append("file", file);
      importVehiclesDocuments(
        vehicleCode,
        "Insurance",
        insuranceValidityDate,
        formData
      )
        .then((response) => {
          addVehicleDoc.setFieldValue(
            "insuranceDoc",
            response?.data?.data?.uuid
          );
        })
        .catch((error) => {
          // Handle API error
        });
    }
  }, [insuranceValidityDate, addVehicleDoc?.values?.insuranceFile]);
  useEffect(() => {
    if (file && pucValidityDate && vehicleCode) {
      let formData = new FormData();
      formData.append("file", file);
      importVehiclesDocuments(vehicleCode, "PUC", pucValidityDate, formData)
        .then((response) => {
          addVehicleDoc.setFieldValue("pucDoc", response?.data?.data?.uuid);
        })
        .catch((error) => {
          // Handle API error
        });
    }
  }, [pucValidityDate, addVehicleDoc?.values?.pucFile]);
  const [InsuranceValidityError, setInsuranceValidityDateError] = useState("");
  const [rcValidityError, setRcValidityDateError] = useState("");
  const [PucValidityError, setPucValidityDateError] = useState("");
  const [vehicleErr, setVehicleErr] = useState("");
  const saveVehicleDocs = (initialValues) => {
    if (vehicleCode === "") {
      setVehicleErr("Vehicle is required");
    } else if (
      !addVehicleDoc.values.rcFile &&
      !addVehicleDoc.values.insuranceFile &&
      !addVehicleDoc.values.pucFile &&
      !addVehicleDoc.values.opsCard
    ) {
      // Check if no documents are uploaded
      toast.error("At least one document is required.");
    } else if (
      addVehicleDoc.values.rcFile &&
      !addVehicleDoc.values.rcValidityDate
    ) {
      setRcValidityDateError("RC validity date is required.");
    } else if (
      addVehicleDoc.values.insuranceFile &&
      !addVehicleDoc.values.insuranceValidityDate
    ) {
      setInsuranceValidityDateError("Insurance validity date is required.");
    } else if (
      addVehicleDoc.values.pucFile &&
      !addVehicleDoc.values.pucValidityDate
    ) {
      setPucValidityDateError("Puc validity date is required.");
    } else {
      setVehicleErr("");
      setRcValidityDateError("");
      setInsuranceValidityDateError("");
      setPucValidityDateError("");
      addVehicleDoc?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleDocument">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Vehicle
                    </label>
                    <SearchInput
                      id="vehicleCode"
                      label="vehicleCode"
                      name="vehicleCode"
                      options={vehicleList?.data?.data?.content || []}
                      getOptionLabel={(option) => option.code}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        setVehicleCode(
                          selectedOption ? selectedOption?.code : ""
                        );
                        setVehicleErr("");
                      }}
                      isDisabled={title === "Edit Vehicle Document"}
                      value={
                        vehicleCode
                          ? vehicleList?.data?.data?.content?.find(
                              (vendor) => vendor.code === vehicleCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Vehicle"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {vehicleErr && vehicleErr ? (
                      <p className="requiredText">{vehicleErr}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-5 marginR65">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      RC Document
                    </label>
                    <div className="custom-file-upload">
                      <input
                        type="file"
                        onChange={(e) => {
                          handleFileUpload(e);
                        }}
                        name="rcDocument"
                        className="mainInput"
                        id="upload"
                      />
                      <label htmlFor="upload" className="upload-label">
                        Upload Document
                      </label>
                      {addVehicleDoc?.values?.rcDoc && (
                        <div className="document-already-uploaded">
                          <span className="document-status">
                            Document Status: Uploaded
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="file-info">
                      <p className="file-name">
                        <p> {addVehicleDoc?.values?.rcFile?.name}</p>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12 displayFlex flexDirectionColumn">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      RC Validity Date
                    </label>
                    <div className="col-md-12">
                      <DatePicker
                        placeholderText="RC Validity Date"
                        placement="rightStart"
                        selected={
                          addVehicleDoc.values.rcValidityDate
                            ? new Date(addVehicleDoc.values.rcValidityDate)
                            : null
                        }
                        onChange={(date) => {
                          addVehicleDoc.setFieldValue("rcValidityDate", date);
                          setRcValidityDate(formatDate(date));
                          setRcValidityDateError("");
                        }}
                        onClean={() =>
                          addVehicleDoc.setFieldValue("rcValidityDate", null)
                        }
                        className="mainInput"
                        showYearDropdown
                        minDate={currentDate}
                        disabled={
                          !addVehicleDoc?.values?.rcFile?.name &&
                          title !== "Edit Vehicle Document"
                        }
                      />
                      {rcValidityError && rcValidityError ? (
                        <p className="requiredText mt_45">{rcValidityError}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_5">
              <div className="col-md-5 marginR65">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Insurance Document
                    </label>
                    <div className="custom-file-upload">
                      <input
                        type="file"
                        onChange={(e) => handleInsuranceFileUpload(e)}
                        name="insuranceDocument"
                        className="mainInput"
                        id="insurance-upload" // Ensure unique id for each file input
                      />
                      <label
                        htmlFor="insurance-upload"
                        className="upload-label"
                      >
                        Upload Document
                      </label>
                    </div>
                    {addVehicleDoc?.values?.insuranceDoc && (
                      <div className="document-already-uploaded">
                        <span className="document-status">
                          Document Status: Uploaded
                        </span>
                      </div>
                    )}
                    <div className="file-info">
                      <p className="file-name">
                        <p> {addVehicleDoc?.values?.insuranceFile?.name}</p>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12 displayFlex flexDirectionColumn">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Insurance Validity Date
                    </label>
                    <div className="col-md-12">
                      <DatePicker
                        placeholderText="Insurance Validity Date"
                        placement="rightStart"
                        selected={
                          addVehicleDoc.values.insuranceValidityDate
                            ? new Date(
                                addVehicleDoc.values.insuranceValidityDate
                              )
                            : null
                        }
                        onChange={(date) => {
                          addVehicleDoc.setFieldValue(
                            "insuranceValidityDate",
                            date
                          );
                          setInsuranceValidityDate(formatDate(date));
                          setInsuranceValidityDateError("");
                        }}
                        onClean={() =>
                          addVehicleDoc.setFieldValue(
                            "insuranceValidityDate",
                            null
                          )
                        }
                        className="mainInput"
                        showYearDropdown
                        minDate={currentDate}
                        disabled={
                          !addVehicleDoc?.values?.insuranceFile?.name &&
                          title !== "Edit Vehicle Document"
                        }
                      />
                      {InsuranceValidityError && InsuranceValidityError ? (
                        <p className="requiredText mt_45">
                          {InsuranceValidityError}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_5">
              <div className="col-md-5 marginR65">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Puc Document
                    </label>
                    <div className="custom-file-upload">
                      <input
                        type="file"
                        onChange={(e) => handlePUCFileUpload(e)}
                        name="pucDocument"
                        className="mainInput"
                        id="puc-upload" // Ensure unique id for each file input
                      />
                      <label htmlFor="puc-upload" className="upload-label">
                        Upload Document
                      </label>
                    </div>
                    {addVehicleDoc?.values?.pucDoc && (
                      <div className="document-already-uploaded">
                        <span className="document-status">
                          Document Status: Uploaded
                        </span>
                      </div>
                    )}
                    <div className="file-info">
                      <p className="file-name">
                        {addVehicleDoc?.values?.pucFile?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Puc Validity Date
                    </label>
                    <div className="col-md-12 displayFlex flexDirectionColumn">
                      <DatePicker
                        placeholderText="Puc Validity Date"
                        placement="rightStart"
                        selected={
                          addVehicleDoc.values.pucValidityDate
                            ? new Date(addVehicleDoc.values.pucValidityDate)
                            : null
                        }
                        onChange={(date) => {
                          addVehicleDoc.setFieldValue("pucValidityDate", date);
                          setPucValidityDate(formatDate(date));
                          setPucValidityDateError("");
                        }}
                        onClean={() =>
                          addVehicleDoc.setFieldValue("pucValidityDate", null)
                        }
                        className="mainInput"
                        showYearDropdown
                        minDate={currentDate}
                        disabled={
                          !addVehicleDoc?.values?.pucFile?.name &&
                          title !== "Edit Vehicle Document"
                        }
                      />
                      {PucValidityError && PucValidityError ? (
                        <p className="requiredText mt_45">{PucValidityError}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_5">
              <div className="col-md-5 marginR65">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      OPS Card
                    </label>
                    <div className="custom-file-upload">
                      <input
                        type="file"
                        onChange={(e) => handleOpsFileUpload(e)}
                        name="opsDocument"
                        className="mainInput"
                        id="ops-upload" // Ensure unique id for each file input
                      />
                      <label htmlFor="ops-upload" className="upload-label">
                        Upload Document
                      </label>
                    </div>
                    {addVehicleDoc?.values?.opsCard && (
                      <div className="document-already-uploaded">
                        <span className="document-status">
                          Document Status: Uploaded
                        </span>
                      </div>
                    )}
                    <div className="file-info">
                      <p className="file-name">
                        {addVehicleDoc?.values?.opsFile?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveVehicleDocs}>
            {title === "Edit Vehicle Document" ? "Update" : "Create"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default VehicleDocumentModal;
