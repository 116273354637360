import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get shortaddress
export const getShortAddress = async (params,params2) => {
    try  {
        return await AoneService.get(`addresses/api/v1/short-address/mapping/${params}${params2}`)
    } catch (error) {
        return error;
    }
}
//get short address by id
export const getShortAddressbyId = async (params,params2) => {
   try  {
       return await AoneService.get(`addresses/api/v1/short-address/mapping/${params}/${params2}`)
   } catch (error) {
       return error;
   }
}
//edit short address
export const editShortAddressbyId = async (params,params2,data) => {
   try  {
       return await AoneService.put(`addresses/api/v1/short-address/mapping/${params}/${params2}`,data)
   } catch (error) {
       return error;
   }
}
//delete short address
export const deleteShortAddressbyId = async (params,params2) => {
   try  {
       return await AoneService.delete(`addresses/api/v1/short-address/mapping/${params}/${params2}`)
   } catch (error) {
       return error;
   }
}

//post short address
export const importShortAddress = async (params,data) => {
   try {
     return await AoneService.post(`addresses/api/v1/short-address/mapping/${params}/upload`, data, {
       "Content-Type": "multipart/form-data",
     });
   } catch (error) {
     return error;
   }
 };
  //export short address
  export const exportShortAddress = async (params,params2) => {
   try {
     return await AoneService.get(`addresses/api/v1/short-address/mapping/${params}/export${params2}`, {
       responseType: "blob",
     });
   } catch (error) {
     return error;
   }
 };

  //Short address history
  //get history
  export const getShortAddressHistory = async (params,params2) => {
   try  {
       return await AoneService.get(`addresses/api/v1/short-address/history/${params}/history${params2}`)
   } catch (error) {
       return error;
   }
}
//get history by national address
export const getShortAddressHistorybyNational = async (params,params2,params3) => {
   try  {
       return await AoneService.get(`addresses/api/v1/short-address/history/${params}/history/${params2}${params3}`)
   } catch (error) {
       return error;
   }
}
//get history by geolocation
export const getShortAddressHistorybyGeoLocation = async (params,params2) => {
   try  {
       return await AoneService.get(`addresses/api/v1/short-address/history/${params}/history/${params2}/geo-location`)
   } catch (error) {
       return error;
   }
}
//export history
export const exportShortAddressHistory = async (params,params2) => {
   try {
     return await AoneService.get(`addresses/api/v1/short-address/history/${params}/history/export${params2}`, {
       responseType: "blob",
     });
   } catch (error) {
     return error;
   }
 };

 