import React,{ useState, useEffect } from 'react'
import MainTable from '../../components/Tables/mtable'
import searchicon from "../../assets/img/searchicon.svg"
import assignIcon from "../../assets/img/assign_icon.svg"
import { useNavigate, useParams } from 'react-router-dom'
import { useAoneStore } from '../../store/AoneStore'
import { logout } from '../../helper/auth_helper'
import AssignerModal from '../../components/modal/AssignerModal'

const DetailedUser = () => {

    const navigate = useNavigate();
    const params = useParams();

    const getUserById = useAoneStore((state) => state?.getUsersById)
    const putUsersById = useAoneStore((state) => state?.putUsersById)
    const getAssignedRolesByUserId = useAoneStore((state) => state?.getAssignedRolesUserById)
    const getGroupsByUserId = useAoneStore((state) => state?.getGroupsByUserId)

    const [tab, setTab] = useState(1)
    const [editHandler,setEditHandler] = useState(false)
    const [user, setUser] = useState({})

    const [roles, setRoles] = useState([])
    const [groups, setGroups] = useState([])

    const [modalHandler, setModalHandler] = useState(false)
    const [deleteHandler, setDeleteHandler] = useState(false)

    const getData = async () => {
        const id = params.id
        const data = await getUserById(id)
        if(data.data.data){
            let newData = data?.data?.data
            let defaultUser = {
                username: newData?.username,
                firstName: newData?.firstName,
                lastName: newData?.lastName,
                email: newData?.email,
                active: newData?.active
            }
            setUser(defaultUser)
        }else{
            logout();
        }
    }

    const getAssignedRoles = async () => {
        const data = await getAssignedRolesByUserId(params.id)
        setRoles(data?.data?.data)
    }

    const getGroupsOfUser = async () => {
        const data = await getGroupsByUserId(params?.id)
        setGroups(data?.data?.data);
    }

    const updateUser = async () => {
        await putUsersById(params.id,user)
        setEditHandler(!editHandler)
    }

    const handleChange = (name, value) => {
        console.log("WHY SO",name,value);
        let data = {...user}
        data[name] = value
        setUser(data)
    }

    console.log("USER",user);

    const dummyGroup = [
        {
            group: "Group A",
            path: "/Group A"
        }
    ]

    useEffect(()=>{
        getData();
    },[editHandler])

    useEffect(()=>{
        getAssignedRoles();
        getGroupsOfUser();
    },[modalHandler,deleteHandler])

  return (
    <div>
        <div className='row alignItemCenter'>
            <div className='col-md-7'>
            <h3 className='mainheadtext' >
                <span className='mainheadlink' onClick={()=>navigate("/iam/users")}>Users</span>/ 
                <span className='mainheadlink'>{user?.username}</span> 
            </h3>
            </div>
        </div>

        <div className='displayFlex tabletab_section mt_20 mb_24'>
            <button className={`filterBtn ${tab == 1 && `activeFilterBtn`}`} onClick={()=>setTab(1)}>Details</button>
            <button className={`filterBtn ${tab == 2 && `activeFilterBtn`}`} onClick={()=>setTab(2)}>Roles</button>
            <button className={`filterBtn ${tab == 3 && `activeFilterBtn`}`} onClick={()=>setTab(3)}>Groups</button>
        </div>

        {
            tab == 1 ?
            <>
            <div className='row mb_24'>
                <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                    <label>Username:</label>
                </div>
                <div className='col-md-5'>
                    <input 
                        type='text' placeholder='User Name' className='mainInput' name='username' value={user?.username}
                        onChange={(e)=>handleChange(e.target.name,e.target.value)} disabled={true}
                    />
                </div>
                <div className='col-md-4 displayFlex justifyContent_end'>
                    <button className={`blue_btn`}>Reset Password</button>
                </div>
            </div> 
            {/* <div className='row mb_24'>
                <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                    <label>Password:</label>
                </div>
                <div className='col-md-5'>
                    <input 
                        type='text' placeholder='Password' className='mainInput'
                    />
                </div>
            </div> */}
            <div className='row mb_24'>
                <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                    <label>First Name:</label>
                </div>
                <div className='col-md-5'>
                    <input 
                        type='text' placeholder='First Name' className='mainInput' name='firstName' value={user?.firstName}
                        onChange={(e)=>handleChange(e.target.name,e.target.value)} disabled={!editHandler}
                    />
                </div>
            </div> 
            <div className='row mb_24'>
                <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                    <label>Last Name:</label>
                </div>
                <div className='col-md-5'>
                    <input 
                        type='text' placeholder='Last Name' className='mainInput' name='lastName' value={user?.lastName}
                        onChange={(e)=>handleChange(e.target.name,e.target.value)} disabled={!editHandler}
                    />
                </div>
            </div>
            <div className='row mb_24'>
                <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                    <label>Email:</label>
                </div>
                <div className='col-md-5'>
                    <input 
                        type='text' placeholder='Email' className='mainInput' name='email' value={user?.email}
                        onChange={(e)=>handleChange(e.target.name,e.target.value)} disabled={!editHandler}
                    />
                </div>
            </div>
            <div className='row mb_24'>
                <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                    <label>Locked:</label>
                </div>
                <div className='col-md-5 smallCheckbox'>
                    <input
                        type="checkbox"
                        id="newstatus"
                        checked={user?.active}
                    />
                    <label htmlFor="newstatus" onClick={()=>{editHandler && handleChange("active",!user.active)}}></label>
                </div>
            </div>
            <div className='row mb_24'>
                <div className='col-md-2'>
                </div>
                <div className='col-md-5 displayFlex alignItemCenter justifyContent_end gap_16'>
                    { !editHandler &&
                    <button className='blue_btn' onClick={()=>setEditHandler(!editHandler)}>
                    Edit
                    </button>
                    }
                    { editHandler &&
                    <>
                        <button className='blue_btn' onClick={()=>updateUser()}>
                        Save
                        </button>
                        <button  className='cancel_btn' onClick={()=>setEditHandler(!editHandler)}>
                        Cancel
                        </button>
                    </>
                    }   
                </div>
            </div>                                        
            </> :
            tab == 2 ?
            <div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className='displayFlex justifyContent_end'>
                <div className='displayFlex alignItemCenter InputGroup '>
                    <span className='inputsearch'>
                    <img src={searchicon} className='turnBlack h18'/>
                    </span>
                    <input type='text' placeholder='Search' className='Maininput'/>
                </div>
              </div>
    
              <div className='displayFlex gap_12'> 
                {/* <div className='displayFlex alignItemCenter smallCheckbox mr_16 pt5'>
                    <input
                        type="checkbox"
                        id="newstatus"
                        // checked={addAgents.values.status}
                    />
                    <label htmlFor="newstatus">Hide Inherited Roles</label>
                </div> */}
                <div title='Assign Roles'>
                    <button className='filterBtn' onClick={()=>setModalHandler(!modalHandler)}>
                    <span><img src={assignIcon} className='h18'/></span>
                    </button>
                </div>      
              </div> 
            </div> 
    
            <MainTable tableData={roles} tbl={"Users_Roles"} deleteHandler={deleteHandler} setDeleteHandler={setDeleteHandler}/>             
            </div> :
            tab == 3 &&
            <div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className='displayFlex justifyContent_end'>
                <div className='displayFlex alignItemCenter InputGroup '>
                    <span className='inputsearch'>
                    <img src={searchicon} className='turnBlack h18'/>
                    </span>
                    <input type='text' placeholder='Search' className='Maininput'/>
                </div>
              </div>
    
              <div className='displayFlex gap_12'> 
                <div className='displayFlex alignItemCenter smallCheckbox mr_16 pt5'>
                    <input
                        type="checkbox"
                        id="newstatus"
                        // checked={addAgents.values.status}
                    />
                    <label htmlFor="newstatus">Direct Groups</label>
                </div>
                <div title='Assign Groups'>
                    <button className='filterBtn' onClick={()=>setModalHandler(!modalHandler)}>
                    <span><img src={assignIcon} className='h18'/></span>
                    </button>
                </div>    
              </div> 
            </div> 
    
            <MainTable tableData={groups} tbl={"Users_Groups"} deleteHandler={deleteHandler} setDeleteHandler={setDeleteHandler}/>             
            </div>                       
        }
        {
            modalHandler && <AssignerModal modalHandler={modalHandler} setModalHander={setModalHandler} title={tab == 2 ? "Assign Role" : tab == 3 && "Assign Group"}/>
        }
    </div>
  )
}

export default DetailedUser