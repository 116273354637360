import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get trips
export const getTrips = async (params) => {
    try  {
        return await AoneService.get(`ops/api/v1/trips${params}`)
    } catch (error) {
        return error;
    }
}
//post trips
export const postTrips = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/trips`,data);
    } catch (error) {
      return error;
    }
  };
  //delete trips
export const deleteTrips = async (params) => {
  try {
    return await AoneService.delete(`ops/api/v1/trips/${params}`);
  } catch (error) {
    return error;
  }
};
//get trips by id
export const getTripsbyId = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/trips/${params}`);
    } catch (error) {
      return error;
    }
  };
//edit trips
export const editDrivers = async (params,data) => {
  try {
    return await AoneService.patch(`ops/api/v1/trips/${params}/hubs`,data);
  } catch (error) {
    return error;
  }
};
//edit vehicle in trip
export const editTripVehicle = async (params,param2) => {
  try {
    return await AoneService.patch(`ops/api/v1/trips/${params}/vehicle/${param2}`);
  } catch (error) {
    return error;
  }
};
  //trip events
//get trip events
export const getTripEvents = async (params,params2) => {
  try  {
      return await AoneService.get(`ops/api/v1/trips/${params}/events${params2}`)
  } catch (error) {
      return error;
  }
}
//status lookup
export const getTripStatus = async () => {
  try  {
      return await AoneService.get(`ops/api/v1/lookups/TripEventCode`)
  } catch (error) {
      return error;
  }
}