import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useCollectionStore } from "../../../store/CollectionsMgt/CollectionStore";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import PaginationTable from "../../../components/Tables/paginationTable";
import PaginationCusTable from "../../../components/Tables/CusTable";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { UserContext } from "../../../helper/ProtectedRoute";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import Dropdown from "react-bootstrap/Dropdown";
import editicon from "../../../../src/assets/img/editicon.svg";
import externalLink from "../../../../src/assets/img/externalLink.svg";
import PrintIcon from "../../../assets/img/printorder.svg";
import saveIcon from "../../../../src/assets/img/saveIcon.svg";
import { roleHandler } from "../../../helper/roleHandler";
import SortingIconComponent from "../../../components/sorting/SortingIcon";
const Transactions = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const prevent = useRef(false);
  const paramHeader = useParams();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [employee, setEmployee] = useState("");
  const [cashList, setCashList] = useState("");
  const [trackingId, setTrackingId] = useState("");
  useEffect(() => {
    setTrackingId(sessionStorage.getItem("collectionTracking"));
  }, []);
  const [refNum, setRefNum] = useState("");
  const [transactionSum, setTransactionSum] = useState("");
  const [size, setSize] = useState(20);
  const [dateRange, setDateRange] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [ledgerData, setLedgerData] = useState("");
  const [modalHandler, setModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const getTransactions = useCollectionStore((state) => state?.getTransactions);
  const exportTransactions = useCollectionStore(
    (state) => state?.exportTransactions
  );
  const gridId = "finance.transactions";
  const [column, SetCoulmn] = useState([
    "Employee Code",
    "Tracking Id",
    "Amount",
    "Currency",
    "Mode",
    "Location",
    "Reference Number",
    "Proof",
    "Created at",
    "Created by",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState(["Created at", "Created by"]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(","));
      }
    }
  }, [gridId]);
  const sorting = (name) => {
    const value = sortField?.startsWith("-") ? "" : "-";
    setSortField(value + name);
  };
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(","),
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const sumTransactions = useCollectionStore((state) => state?.sumTransactions);
  const editTransactionMode = useCollectionStore((state) => state?.editTransactionMode);
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const getCollectionMode = useCollectionStore(
    (state) => state?.getCollectionMode
  );
  const downloadTransactionProof = useCollectionStore(
    (state) => state?.downloadTransactionProof
  );
  const collectionModeList = useCollectionStore(
    (state) => state?.collectionModeList
  );
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [state] = useContext(UserContext);
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };
  useEffect(() => {
    if (collectionModeList?.data?.data === undefined) {
      getCollectionMode();
    }
  }, []);
  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const downloadTransacProof = async (id, type) => {
    try {
      const res = await downloadTransactionProof(id);
      if (res?.status == 200) {
        fileDownload(res?.data, `Transaction Proof.${type}`);
      }
    } catch (err) {}
  };
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const [dateRangefil, setDateRangefil] = useState({});
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}`;
    if (cashList) {
      params += `&mode=${cashList}`;
    }
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (refNum) {
      params += `&referenceNumber=${refNum}`;
    }
    try {
      const res = await exportTransactions(params);
      if (res.status == 200) {
        fileDownload(res.data, `Transactions.xlsx`);
      }
    } catch (err) {}
  };
  const handleClick = (latitude, longitude) => {
    if (latitude && longitude) {
      // Construct the Google Maps URL using the latitude and longitude
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      // Open a new tab with the constructed URL
      window.open(googleMapsUrl, "_blank");
    } else {
      console.warn("Latitude and/or longitude are missing.");
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}`;
    if (cashList) {
      params += `&mode=${cashList}`;
    }
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (refNum) {
      params += `&referenceNumber=${refNum}`;
    }
    if (
      dateRangefil.start !== null &&
      dateRangefil.end !== null &&
      !isNaN(dateRangefil.start) &&
      !isNaN(dateRangefil.end) &&
      dateRangefil.start !== dateRangefil.end
    ) {
      params += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
    }
    const data = await getTransactions(params);
    setTotalCount(data?.data?.data?.totalElements);
    setLedgerData(data?.data?.data?.content);
  };
  const sumTransactionData = async () => {
    let paramSum = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}`;
    if (cashList) {
      paramSum += `&mode=${cashList}`;
    }
    if (trackingId) {
      paramSum += `&trackingId=${trackingId}`;
    }
    if (refNum) {
      paramSum += `&referenceNumber=${refNum}`;
    }
    if (
      dateRangefil.start !== null &&
      dateRangefil.end !== null &&
      !isNaN(dateRangefil.start) &&
      !isNaN(dateRangefil.end) &&
      dateRangefil.start !== dateRangefil.end
    ) {
      paramSum += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
    }
    const data = await sumTransactions(paramSum);
    setTransactionSum(data?.data?.data);
  };
  const [editingRowId, setEditingRowId] = useState(null);
  const [selectedMode, setSelectedMode] = useState("");
  const handleEditClick = (id, mode) => {
    setEditingRowId(id);
    setSelectedMode(mode); // Set the current mode as the selected mode
  };
  const handleSaveClick = async () => {
    try {
      // Assuming you have the selectedMode stored in state
      const response = await editTransactionMode(editingRowId, selectedMode);
      if (response?.data?.status === "SUCCESS") {
        // Successfully updated the transaction mode
        toast.success("Transaction mode updated successfully");
        setEditingRowId(null);
        setSelectedMode(null);
        getData();
      } else if (response?.data?.status == "ERROR") {
        const errorMessage = response?.data?.msg;
        toast.error(`${errorMessage}`);
      } else if (response?.response?.data?.errors?.length > 0) {
        const errorMessages = response?.response?.data?.errors
          ?.map((item) => `${item.key}: ${item.msg}`)
          .join(", ");
        toast.error(`${errorMessages}`);
      } else if (response?.response?.data?.status == "ERROR") {
        const errorMessage = response?.response?.data?.msg;
        toast.error(errorMessage);
      } else {
        const errorMessage = response?.data?.msg.split(",")[0];
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error updating transaction mode:", error);
    }
  };
  
  //table
  const tableHeader = (
    <>
      <th onClick={() => sorting("createdAt")}>
        #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
      </th>
      {!checkedCol?.includes("Employee Code") && (
        <th onClick={() => sorting("employeeCode")}>
          Employee Code
          <SortingIconComponent
            sortField={sortField}
            fieldName="employeeCode"
          />
        </th>
      )}
      {!checkedCol?.includes("Tracking Id") && (
        <th onClick={() => sorting("trackingId")}>
          Tracking Id
          <SortingIconComponent sortField={sortField} fieldName="trackingId" />
        </th>
      )}
      {!checkedCol?.includes("Amount") && (
        <th onClick={() => sorting("amount")}>
          Amount
          <SortingIconComponent sortField={sortField} fieldName="amount" />
        </th>
      )}
      {!checkedCol?.includes("Currency") && (
        <th onClick={() => sorting("currency")}>
          Currency
          <SortingIconComponent sortField={sortField} fieldName="currency" />
        </th>
      )}
      {!checkedCol?.includes("Mode") && (
        <th onClick={() => sorting("mode")}>
          Mode
          <SortingIconComponent sortField={sortField} fieldName="mode" />
        </th>
      )}
      {!checkedCol?.includes("Location") && (
        <th onClick={() => sorting("location")}>
          Location
          <SortingIconComponent sortField={sortField} fieldName="location" />
        </th>
      )}
      {!checkedCol?.includes("Reference Number") && (
        <th onClick={() => sorting("referenceNumber")}>
          Reference Number
          <SortingIconComponent
            sortField={sortField}
            fieldName="referenceNumber"
          />
        </th>
      )}
      {!checkedCol?.includes("Proof") && (
        <th onClick={() => sorting("proofUrl")}>
          Proof
          <SortingIconComponent sortField={sortField} fieldName="proofUrl" />
        </th>
      )}
      {!checkedCol?.includes("Created by") && (
        <th onClick={() => sorting("createdBy")}>
          Created By
          <SortingIconComponent sortField={sortField} fieldName="createdBy" />
        </th>
      )}
      {!checkedCol?.includes("Created at") && (
        <th onClick={() => sorting("createdAt")}>
          Created At
          <SortingIconComponent sortField={sortField} fieldName="createdAt" />
        </th>
      )}
      {(roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.mode")) &&
        !checkedCol?.includes("Actions") && <th>Actions</th>}
    </>
  );
  const tableBody = (ledgerData, index) => (
    <>
      <td>{newPage * size + (index)}</td>
      {!checkedCol?.includes("Employee Code") && (
        <td>{ledgerData?.employeeCode}</td>
      )}
      {!checkedCol?.includes("Tracking Id") && (
        <td>{ledgerData?.trackingId}</td>
      )}
      {!checkedCol?.includes("Amount") && <td>{ledgerData?.amount}</td>}
      {!checkedCol?.includes("Currency") && <td>{ledgerData?.currency}</td>}
      {!checkedCol?.includes("Mode") && (
          <td>
            {editingRowId === ledgerData?.id ? (
              <SearchInput
                id="cashList"
                label="cashList"
                name="cashList"
                options={collectionModeList?.data?.data || []}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.key}
                handleChange={(selectedOption) => {
                  setSelectedMode(selectedOption ? selectedOption.key : "");
                }}
                isClearable={true}
                isSearchable={true}
                placeholder="Collection Type"
                customStyles={{ control: { width: "190px" } }}
                className={
                  selectedMode?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
                value={collectionModeList?.data?.data.find(
                  (option) => option.label === selectedMode
                )}
              />
            ) : (
              ledgerData?.mode
            )}
          </td>
        )}
      {!checkedCol?.includes("Location") && (
        <td>
          <div className="col-md-3">
            {(ledgerData?.location?.latitude !== 0.0 && ledgerData?.location?.longitude !== 0.0) && (
              <button className="cancel_btn">
                <span
                  onClick={() =>
                    handleClick(
                      ledgerData?.location?.latitude,
                      ledgerData?.location?.longitude
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img src={externalLink} alt="Link" />
                </span>
              </button>
            )}
          </div>
        </td>
      )}
      {!checkedCol?.includes("Reference Number") && (
        <td>{ledgerData?.referenceNumber}</td>
      )}
      {!checkedCol?.includes("Proof") && (
        <td>
          {ledgerData?.proofUrl && (
            <span
              onClick={() =>
                downloadTransacProof(ledgerData?.id, ledgerData?.proofType)
              }
            >
              <img src={PrintIcon} className="turnBlack" />
            </span>
          )}
        </td>
      )}
      {!checkedCol?.includes("Created by") && <td>{ledgerData?.createdBy}</td>}
      {!checkedCol?.includes("Created at") && (
        <td>
          {ledgerData?.createdAt
            ? new Date(ledgerData?.createdAt).toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
            : null}
        </td>
      )}
       {(roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.mode")) &&
        !checkedCol?.includes("Actions") && (
          <td className="fixedCol col_actions" scope="row">
            <>
            {editingRowId === ledgerData?.id ? (
    // Save Button (shown when the row is being edited)
    <button
      title="Save"
      className="tableBtn"
      onClick={() => handleSaveClick(ledgerData?.id, selectedMode)}
    >
      <span>
        <img src={saveIcon} className="turnBlack" />
      </span>
    </button>
  ) : (
    // Edit Button (shown when the row is not being edited)
    <button
      title="Edit"
      className="tableBtn"
      onClick={() => handleEditClick(ledgerData?.id, ledgerData?.mode)}
    >
      <span>
        <img src={editicon} className="turnBlack" />
      </span>
    </button>
  )}
            </>
          </td>
        )}
    </>
  );
  useEffect(() => {
    getData();
    sumTransactionData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    cashList,
    refNum,
    employee,
    trackingId,
    refNum,
    dateRangefil.start,
    dateRangefil.end,
    ispostAdded,
  ]);
  return (
    <div>
      <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          <div className="displayFlex justifyContent_end mleft_5">
            <Row>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Tracking ID:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={trackingId}
                    className={
                      trackingId?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      trackingId?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                    placeholder="Tracking Id"
                    onChange={(e) => setTrackingId(e.target.value)}
                  />
                  {trackingId && (
                    <button
                      className="cancel_btn clear-btn"
                      type="button"
                      onClick={() => setTrackingId("")}
                    >
                      <span>
                        <img src={removeIcon} alt="Clear" />
                      </span>
                    </button>
                  )}
                </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Collection Type:
                </label>
                <SearchInput
                  id="cashList"
                  label="cashList"
                  name="cashList"
                  options={collectionModeList?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  handleChange={(selectedOption) => {
                    setCashList(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenudesregion}
                  onMenuClose={handleMenuClosedesregion}
                  menuIsOpen={isReactdesRegion}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Collection Type"
                  className={
                    cashList?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Reference Number:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={refNum}
                    className={
                      refNum?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      refNum?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                    placeholder="Reference Number"
                    onChange={(e) => setRefNum(e.target.value)}
                  />
                  {refNum && (
                    <button
                      className="cancel_btn clear-btn"
                      type="button"
                      onClick={() => setRefNum("")}
                    >
                      <span>
                        <img src={removeIcon} alt="Clear" />
                      </span>
                    </button>
                  )}
                </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                  Collection Date:
                </label>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  placeholder="Collection Date"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  placement="auto"
                  minDate={
                    new Date(new Date().setMonth(new Date().getMonth() - 3))
                  }
                  onOpen={handleMenuOpen}
                  onClose={handleMenuClose}
                  showMeridian
                  className="w200"
                />
              </Col>
            </Row>
          </div>
          <div className="margin_top40">
            <div className="displayFlex gap_12">
              <div className="totals totalsTransaction">
                <span className="total-success">
                  Total Amount: {transactionSum}
                </span>
              </div>
              <button
                className="filterBtn"
                title={"Export Transactions"}
                onClick={() => exportToExcel()}
              >
                <span>
                  <img src={downloadicon} className="h18" />
                </span>
              </button>
              <button
                className="filterBtn plus_icon"
                title="Sync"
                onClick={() => syncronizeRoles()}
              >
                <span>
                  <img src={syncicon} className="h18" />
                </span>
              </button>
              <div ref={ref} className="customFilter">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={DotboldIcon} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <ul className="listColumn cursorPointer">
                        {column?.map((d, i) => (
                          <li key={d}>
                            <div className="smallCheckbox mb_4">
                              <input
                                id={`product${i}`}
                                type="checkbox"
                                checked={!checkedCol.includes(d)}
                              />
                              <label
                                htmlFor={`product${i}`}
                                onClick={() => handleSelect(d)}
                              >
                                {d}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <PaginationCusTable
          tableHeader={tableHeader}
          tableBody={tableBody}
          tableData={ledgerData}
          tbl={"Transactions"}
          modalHandler={modalHandler}
          isReactdesRegion={isReactdesRegion}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          isDropdownselectOpen={isDropdownselectOpen}
          newPage={newPage}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          setNewPage={setNewPage}
          size={size}
          setSize={setSize}
          totalCount={totalCount}
          sortField={sortField}
          setSortField={setSortField}
        />
      </div>
    </div>
  );
};

export default Transactions;
