import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerComponent from "./Marker";

const MapView = ({ routesData, selectedEmployeeCode ,setSelectedEmployeeCode}) => {
  return (
    <MapContainer center={[24.9332438, 46.5640864]} zoom={8} scrollWheelZoom={true} style={{ 
        height: "600px",
        width: "500px", 
        border: "2px solid #000",
        borderRadius: "5px"
    }}>
      <TileLayer
        url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
        attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
      />
      <MarkerComponent routesData={routesData} selectedEmployeeCode={selectedEmployeeCode} setSelectedEmployeeCode={setSelectedEmployeeCode}/>
    </MapContainer>
  );
}

export default MapView;
