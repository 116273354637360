import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { useAoneStore } from "../../store/AoneStore";
import { usevehicleStore } from "../../store/VehicleMgt/VehicleStore";
import { usesettingStore } from "../../store/Settings/SettingStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const SystemModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  systembyCode,
  component
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const app = ["AONE", "AIMS", "WMS"];
  const appOptions = app.map(e => ({
    name: e, 
    code: e
  }));
  const postSettings = usesettingStore((state) => state?.postSettings);
  const editSettings = usesettingStore((state) => state?.editSettings);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ModuleList = useAoneStore((state) => state.ModuleList);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const LanguageData = usesettingStore((state) => state.LanguageData);
  const [resetKey, setResetKey] = useState(0);
  const addSystem = useFormik({
    initialValues: {
      app: "",
      module: "",
      systemKey: "",
      value: "",
    },
    validationSchema: Yup.object({
      app: Yup.string().trim().required("App is required"),
      module: Yup.string().trim().required("Module is required"),
      systemKey: Yup.string().required("Systemkey is required")
      .matches(/^[a-zA-Z0-9\s_-]*$/, "Systemkey must not contain special characters"),
      value: Yup.string().required("Value is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title == "Edit System") {
        const updatedValues = { ...values, id: systembyCode?.id };
        await editSettings(component,updatedValues).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("System updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          }else if(response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response.status == 200) {
            toast.success("System updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      } else {
      await postSettings(component,values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("System added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    }
    },
  });
  useEffect(() => {
    addSystem.setFieldValue("app", systembyCode?.app);
    addSystem.setFieldValue("module", systembyCode?.module);
    addSystem.setFieldValue("systemKey", systembyCode?.systemKey);
    addSystem.setFieldValue("value", systembyCode?.value);
}, [title, systembyCode]);
useEffect(() => {
  if(title == "Create new localization"){
    addSystem.setFieldValue("app", "");
    addSystem.setFieldValue("module", "");
    addSystem.setFieldValue("systemKey", "");
    addSystem.setFieldValue("value", "");
  }
}, [title, systembyCode]);
  const [valueErr,setValueErr] = useState("");
  const [keyErr,setKeyErr] = useState("")
  const saveSystem = (initialValues) => {
    if (
      /\s/g.test(addSystem.values.systemKey)
    ) {
      setKeyErr("Key cannot cannot contain leading, trailing, or consecutive spaces");
    }else if (/^\s|\s$/.test(addSystem.values.value)) {
      systembyCode(
          "Value cannot cannot contain leading or trailing spaces"
        );
    } else {
      setValueErr("");
      setKeyErr("");
      addSystem?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleVendors">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
            <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      App
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="app"
                      label="app"
                      name="app"
                      options={appOptions} 
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addSystem.setFieldValue(
                          "app",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addSystem?.values?.app
                          ? LanguageData?.data?.data?.find(
                              (option) => option.code === addSystem?.values?.app
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="App"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addSystem.touched.app &&
                  addSystem.errors.app ? (
                    <p className="requiredText">
                      {addSystem.errors.app}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Module
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="module"
                      label="module"
                      name="module"
                      options={ModuleList?.data?.data || []}
                      getOptionLabel={(module) => module.displayText}
                      getOptionValue={(module) => module.value}
                      handleChange={(selectedOption) => {
                        addSystem.setFieldValue(
                          "module",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addSystem?.values?.module
                          ? LanguageData?.data?.data?.find(
                              (option) => option.value === addSystem?.values?.module
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Module"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addSystem.touched.module &&
                  addSystem.errors.module ? (
                    <p className="requiredText">
                      {addSystem.errors.module}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-12">
                <div className="row alignItemCenter">
                <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Key
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Key"
                      value={addSystem.values.systemKey}
                      onChange={(e) => {
                        addSystem.setFieldValue("systemKey", e.target.value);
                        setKeyErr("");
                      }}
                      name="systemKey"
                      className="mainInput"
                    />
                  </div>
                  {(addSystem.touched.systemKey &&
                  addSystem.errors.systemKey  && keyErr == "") ? (
                    <p className="requiredText">
                      {addSystem.errors.systemKey}
                    </p>
                  ) : null}
                  {keyErr !== "" ? (
                    <p className="requiredText">{keyErr}</p>
                  ) : null}
                </div>
              </div>
                <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Value
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Value"
                      value={addSystem.values.value}
                      onChange={(e) => {
                        addSystem.setFieldValue("value", e.target.value);
                        setValueErr("");
                      }}
                      name="value"
                      className="mainInput"
                    />
                  </div>
                  {(addSystem.touched.value &&
                  addSystem.errors.value  && valueErr == "") ? (
                    <p className="requiredText">
                      {addSystem.errors.value}
                    </p>
                  ) : null}
                  {valueErr !== "" ? (
                    <p className="requiredText">{valueErr}</p>
                  ) : null}
                </div>
              </div>
                </div>
              </div>
              </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveSystem}>
          {title === "Create new system" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default SystemModal;
