import React, { useState, useRef, useEffect, useContext } from "react";
import { useAoneStore } from "../../store/AoneStore";
import { useNavigate, useLocation } from "react-router";
import PaginationTable from "../../components/Tables/paginationTable";
import syncicon from "../../../src/assets/img/sync.svg";
import OpenModal from "../../components/modal/openModal";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import { UserContext } from "../../helper/ProtectedRoute";
const ThirdpartyConfig = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [name, setName] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [editPage, setEditPage] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [statusHandler, setStatusHandler] = useState(false);
  const [carrierCode, setCarrierCode] = useState("");
  const [carrierName, setCarrierName] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [mappingResponse, setMappingResponse] = useState([]);
  const [carrier, setCarrier] = useState("");
  const [carrierValue, setCarrierValue] = useState("");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [ajexAlias, setAjexalias] = useState("");
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const mappingData = useAoneStore((state) => state?.mappingData);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [type, setType] = useState("");
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [isChecked, setIsChecked] = useState(false);
  const getThirdparty = useAoneStore((state) => state?.getThirdparty);
  const getMappingTypes = useAoneStore((state) => state?.getMappingTypes);
  const getMapping = useAoneStore((state) => state?.getMapping);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]); // For Role Based Access
  const prevent = useRef(false);
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setName("");
    setTab(i);
    navigate("/thirdparty/configurations");
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  //set data for lising in tabs
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    let paramsMap = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (carrierCode) {
      params += `&carrierCode=${carrierCode}`;
    }
    if (carrierName) {
      params += `&carrierName=${carrierName}`;
    }
    if (carrier) {
      paramsMap += `&carrier=${carrier}`;
    }
    if (carrierValue) {
      paramsMap += `&carrierValue=${carrierValue}`;
    }
    if (type) {
      paramsMap += `&type=${type}`;
    }
    if (ajexAlias) {
      paramsMap += `&alias=${ajexAlias}`;
    }
    if (tab === 1) {
      const data = await getThirdparty(params);
      setTotalCount(data.data.data.totalElements);
      setAddressData(data.data.data.content);
    } else {
      const data = await getMapping(paramsMap);
      setTotalCount(data.data.data.totalElements);
      setMappingResponse(data.data.data.content);
    }
  };
  useEffect(() => {
    getData();
    getMappingTypes();
  }, [
    newPage,
    size,
    sortField,
    location,
    statusHandler,
    saveHandler,
    name,
    tab,
    carrierName,
    carrierCode,
    isChecked,
    type,
    ajexAlias,
    ispostAdded,
    carrierValue,
    carrier,
  ]);
  return (
    <div>
      <div className="row alignItemCenter">
        <ul className="page_breadcrumb">
          <li>
            <span className="mainheadlink" style={{ fontWeight: "500" }}>
              Third Party
            </span>
          </li>
          <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
            <span>
              <img
                src={breadArrow}
                className="turnBlack"
                alt="breadcrumbarrow"
              />
            </span>
          </li>
          <li>
            <span
              className="mainheadlink"
              style={{
                fontWeight: "500",
                marginLeft: "-3px",
                fontWeight: "bolder",
              }}
            >
              Configurations
            </span>
          </li>
        </ul>
      </div>
      <div className="displayFlex tabletab_section mb_12">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => tabHandler(1)}
        >
          Third Parties
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => tabHandler(2)}
        >
          Mapping
        </button>
      </div>
      {!editPage && tab === 1 && (
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_8">
          <div className="displayFlex justifyContent_end mleft_5">
            <div className="displayFlex alignItemCenter width_200">
              <input
                type="text"
                value={carrierCode}
                placeholder="Carrier Code"
                className={
                  carrierCode?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
                style={
                  carrierCode?.length > 0 ? { border: "2px solid #ec171c" } : {}
                }
                onChange={(e) => setCarrierCode(e.target.value)}
              />
            </div>
            <div className="displayFlex alignItemCenter width_200">
              <input
                type="text"
                value={carrierName}
                placeholder="Carrier Name"
                className={
                  carrierName?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
                style={
                  carrierName?.length > 0 ? { border: "2px solid #ec171c" } : {}
                }
                onChange={(e) => setCarrierName(e.target.value)}
              />
            </div>
          </div>

          <div className="displayFlex gap_12">
            <div>
              <button className="filterBtn" title="Sync">
                <span>
                  <img
                    src={syncicon}
                    className="h18"
                    onClick={() => syncronizeRoles()}
                  />
                </span>
              </button>
            </div>
            {(role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_THIRD-PARTY.write") ||
              role?.includes("AONE_THIRD-PARTY.admin") ||
              role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write") ||
              role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin")) && (
              <div>
                <button
                  className="filterBtn plus_icon"
                  onClick={() => setAddModalHandler(!addModalHandler)}
                  tabIndex="-1"
                  title="Add"
                >
                  +
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {!editPage && tab === 2 && (
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_8">
          <div className="displayFlex justifyContent_end mleft_5">
            <div className="displayFlex alignItemCenter">
              <input
                type="text"
                value={carrier}
                placeholder="Carrier"
                className={
                  carrier?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
                }
                style={
                  carrier?.length > 0
                    ? { border: "2px solid #ec171c", marginLeft: "5px" }
                    : { marginLeft: "5px" }
                }
                onChange={(e) => setCarrier(e.target.value)}
              />
            </div>
            <div className="displayFlex alignItemCenter">
              <input
                type="text"
                value={carrierValue}
                placeholder="Carrier Alias"
                className={
                  carrierValue?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
                style={
                  carrierValue?.length > 0
                    ? { border: "2px solid #ec171c", marginLeft: "5px" }
                    : { marginLeft: "5px" }
                }
                onChange={(e) => setCarrierValue(e.target.value)}
              />
            </div>
            <div className="displayFlex alignItemCenter">
              <input
                type="text"
                value={ajexAlias}
                placeholder="AJEX Value"
                className={
                  ajexAlias?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
                style={
                  ajexAlias?.length > 0
                    ? { border: "2px solid #ec171c", marginLeft: "5px" }
                    : { marginLeft: "5px" }
                }
                onChange={(e) => setAjexalias(e.target.value)}
              />
            </div>
            <div className="displayFlex alignItemCenter">
              <select
                value={type}
                style={{ marginLeft: "5px" }}
                onChange={(e) => setType(e.target.value)}
                className="mainInput"
              >
                <option value="">None</option>
                {mappingData?.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item?.value}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="displayFlex gap_12">
            <div>
              <button className="filterBtn" title="Sync">
                <span>
                  <img
                    src={syncicon}
                    className="h18"
                    onClick={() => syncronizeRoles()}
                  />
                </span>
              </button>
            </div>
            {(role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_THIRD-PARTY.write") ||
              role?.includes("AONE_THIRD-PARTY.admin") ||
              role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.write") ||
              role?.includes("AONE_THIRD-PARTY_CONFIGS_THIRD-PARTY.admin")) && (
              <div>
                <button
                  className="filterBtn plus_icon"
                  onClick={() => setAddModalHandler(!addModalHandler)}
                  tabIndex="-1"
                  title="Add"
                >
                  +
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <PaginationTable
        tableData={tab === 1 ? addressData : mappingResponse}
        tbl={tab === 1 ? "Thirdparties" : tab === 2 ? "Mapping" : ""}
        modalHandler={editPage}
        title={tab === 1 ? "Edit third party configuration" : "Edit Mapping"}
        setModalHandler={setEditPage}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        individualCheckboxesbag={individualCheckboxesbag}
        setIndividualCheckboxesBag={setIndividualCheckboxesBag}
        newPage={newPage}
        setNewPage={setNewPage}
        size={size}
        setSize={setSize}
        totalCount={totalCount}
        sortField={sortField}
        setSortField={setSortField}
      />
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          title={
            tab === 1 ? "Create third party configuration" : "Add New Mapping"
          }
          setModalHandler={setAddModalHandler}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />
      )}
    </div>
  );
};

export default ThirdpartyConfig;
