import { UserManager } from 'oidc-client';

//uncomment scope while using in local env
const settings = {
    authority: process.env.REACT_APP_KEYCLOACK_AUTHORITY_URL,
    client_id: process.env.REACT_APP_KEYCLOACK_CLIENT_ID,
    redirect_uri: window.location.origin + process.env.REACT_APP_KEYCLOACK_CALLBACK_URL,
    response_type: process.env.REACT_APP_KEYCLOACK_RESPONSE,
    //   scope: "openid profile message.read",
    post_logout_redirect_uri: process.env.REACT_APP_KEYCLOACK_POST_LOGOUT
};

export const userManager = new UserManager(settings);

export const getUser = () => {
    return userManager.getUser();
}

export const login = () => {
    return userManager.signinRedirect();
}

export const logout = () => {
    return userManager.signoutRedirect();
}
