import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import Attribute from './Attribute'
import Usermanagement from './Usermanagement'
import { useAoneStore } from "../../store/AoneStore";
import { Tabs, Tab } from '@mui/material';
const UserMgtTab = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(1);
    const globalUserStatus = useAoneStore((state) => state?.globalUserStatus);
    const setglobalUserStatus = useAoneStore((state) => state?.setglobalUserStatus);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
    const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
        // navigate("/crm/customers");
      };
      const handleChange = (event, newValue) => {
        if (params?.id !== undefined || globalEmployeeCode !== '') {
          setTab(newValue + 1);
          tabHandler(newValue + 1);
        }
      };
    useEffect(()=>{
      if(params?.id == undefined && globalEmployeeCode !== ""){
        setglobalUserStatus(false);
      }
    })
  return (
    <div>
       <div className="tabletab_sectionnew">
      <Tabs
       value={tab - 1}
        onChange={handleChange}
        aria-label="user details and attributes tabs"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#EC171C',
          },
          '& .MuiTab-root': {
            color: 'gray',
            fontWeight: 'normal',
            fontFamily: 'Arial, sans-serif',
            textTransform: 'none',
          },
          '& .Mui-selected': {
            color: '#EC171C !important',
            fontWeight: 'bold',
            fontFamily: 'Arial, sans-serif',
            textTransform: 'none',
          },
        }}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="User Details" />
        {(globalUserStatus && globalEmployeeCode !== "") &&
        <Tab label="Attributes" />}
      </Tabs>
    </div>


      <div>
         { (tab == 1 ? (
            <Usermanagement edit={setEditPage} setTab={setTab}/>
          ) :
            <Attribute edit={setEditPage} />)}
      </div>
    </div>
  )
}

export default UserMgtTab