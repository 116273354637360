import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//rules
//get rules
export const getRules = async (params) => {
    try  {
        return await AoneService.get(`notification/api/v1/sms/templates/rules${params}`)
    } catch (error) {
        return error;
    }
}
//post rules
export const postRules = async (data) => {
    try {
      return await AoneService.post(`notification/api/v1/sms/templates/rules`,data);
    } catch (error) {
      return error;
    }
  };
  //delete rules
export const deleteRules = async (params) => {
  try {
    return await AoneService.delete(`notification/api/v1/sms/templates/rules/${params}`);
  } catch (error) {
    return error;
  }
};
//export rules
export const exportRules = async (params) => {
  try {
    return await AoneService.get(`notification/api/v1/sms/templates/rules/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//edit rules
export const editRules = async (params,data) => {
  try {
    return await AoneService.put(`notification/api/v1/sms/templates/rules/${params}`,data);
  } catch (error) {
    return error;
  }
};

//get rules by id
export const getRulesbyId = async (params) => {
    try {
      return await AoneService.get(`notification/api/v1/sms/templates/rules/${params}`);
    } catch (error) {
      return error;
    }
  };

  //sms templates
  export const getTemplates = async (params) => {
    try  {
        return await AoneService.get(`notification/api/v1/sms/templates${params}`)
    } catch (error) {
        return error;
    }
}
//post template
export const postTemplates = async (data) => {
    try {
      return await AoneService.post(`notification/api/v1/sms/templates`,data);
    } catch (error) {
      return error;
    }
  };
  //delete template
export const deleteTemplates = async (params) => {
  try {
    return await AoneService.delete(`notification/api/v1/sms/templates/${params}`);
  } catch (error) {
    return error;
  }
};
//export template
export const exportTemplates = async (params) => {
  try {
    return await AoneService.get(`notification/api/v1/sms/templates/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//edit template
export const editTemplates = async (params,data) => {
  try {
    return await AoneService.put(`notification/api/v1/sms/templates/${params}`,data);
  } catch (error) {
    return error;
  }
};

//History
//get history
export const getHistory = async (params) => {
    try  {
        return await AoneService.get(`notification/api/v1/sms${params}`)
    } catch (error) {
        return error;
    }
}
//export history
export const exportHistory = async (params) => {
    try {
      return await AoneService.get(`notification/api/v1/sms/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//lookups
  export const getDataType = async () => {
    try {
      return await AoneService.get(`notification/api/v1/lookups/DataType`);
    } catch (error) {
      return error;
    }
  };
  export const getField = async () => {
    try {
      return await AoneService.get(`notification/api/v1/lookups/Field`);
    } catch (error) {
      return error;
    }
  };
  export const getOperator = async () => {
    try {
      return await AoneService.get(`notification/api/v1/lookups/Operator`);
    } catch (error) {
      return error;
    }
  };
  export const getRuleStatus = async () => {
    try {
      return await AoneService.get(`notification/api/v1/lookups/RuleStatus`);
    } catch (error) {
      return error;
    }
  };
  export const getConditionLogic = async () => {
    try {
      return await AoneService.get(`notification/api/v1/lookups/ConditionLogic`);
    } catch (error) {
      return error;
    }
  };
  export const getEventStatus = async () => {
    try {
      return await AoneService.get(`notification/api/v1/lookups/EventStatus`);
    } catch (error) {
      return error;
    }
  };
  