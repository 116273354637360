
import {useNavigate,useLocation} from "react-router-dom";
import  { useState, useEffect, useRef,createContext } from "react";
import { getUser,userManager,logout } from "./auth_helper";
import jwt_decode from "jwt-decode";

const UserContext = createContext();

const UseAuthRoute = ({children}) => {
  const [state,setState] = useState({
    token:"",
    user_id:"",
    role:""
  });
  const [isLogin, setLogin] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromParams = urlParams.get("token");
    let token = localStorage.getItem("user_access_token") || tokenFromParams;
    if (tokenFromParams) {
      // Store the token from URL in both localStorage and sessionStorage
      localStorage.setItem("user_access_token", tokenFromParams);
      sessionStorage.setItem("user_access_token", tokenFromParams);
    }
    if (token) {
      const decoded = jwt_decode(token);
      setState({
        token: token,
        user_id: decoded.sub,
        roles: decoded.realm_access.roles,
      });
      setLogin(true);
    } else {
    getUser().then(user => {
        if (user) {
            setLogin(true)
            var decoded = jwt_decode(user?.access_token)
            setState({
              token: user?.access_token,
              user_id: user?.profile?.sid,
              roles:decoded.realm_access.roles
          })
          sessionStorage.setItem("user_access_token", user?.access_token)
          sessionStorage.setItem("user_access_role", decoded.realm_access.roles)
          sessionStorage.setItem("postMerchantToken", JSON.stringify(user))
        } else {
            alert('You are not logged in.');

            // logout()
            navigate('/')
        }
    }).catch((err)=>{
      console.log("err protected----->",err)
    });
  }
}, [location]);

  return (

    isLogin == true ? (
      <UserContext.Provider value={[state , setState]}>
        {children}
      </UserContext.Provider>
      
       ):<h5>unauthorized</h5>
    ) ;
};

export {UserContext,UseAuthRoute};