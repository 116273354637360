import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
const VehicleCategoryModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  vehiclebyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postVehicleCategory = usevehicleStore(
    (state) => state?.postVehicleCategory
  );
  const editVehicleCategory = usevehicleStore(
    (state) => state?.editVehicleCategory
  );
  const countriesData = useAoneStore((state) => state?.countriesData);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [resetKey, setResetKey] = useState(0);
  const addVehicleCategory = useFormik({
    initialValues: {
      code: "",
      name: "",
      description: "",
      countryCode: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .trim()
        .required("Code is required")
        .matches(/^[a-zA-Z0-9\s_-]*$/, "Code must not contain special characters")
        .max(30, "Code must be at most 30 characters long"),
      name: Yup.string().required("Name is required")
      .matches(/^[a-zA-Z0-9\s_-]*$/, "Name must not contain special characters"),
      description: Yup.string().trim().required("Description is required"),
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit vehicle category") {
        await editVehicleCategory(vehiclebyCode.code, values).then(
          (response) => {
            if (response?.data?.status == "SUCCESS") {
              toast.success("Vehicle category updated");
              setispostAdded(!ispostAdded);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.status === "ERROR") {
              const errorMessage = response.data.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.errors?.length > 0) {
              const errorMessages = response?.response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.errorCode == 400) {
              const errorMessages = response?.response?.data?.errors
                .map((item) => `${item.msg}`)
                .join(", ");
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else if (response.status == 200) {
              toast.success("Vehicle category updated");
              setispostAdded(!ispostAdded);
              resetForm();
              setShowModal(false);
            } else {
              const errorMessage = response.response.data.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            }
          }
        );
      } else {
        await postVehicleCategory(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Vehicle category has been added");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      }
    },
  });
  useEffect(() => {
    addVehicleCategory.setFieldValue("code", vehiclebyCode?.code);
    addVehicleCategory.setFieldValue("name", vehiclebyCode?.name);
    addVehicleCategory.setFieldValue("description", vehiclebyCode?.description);
    addVehicleCategory.setFieldValue("countryCode", vehiclebyCode?.countryCode);
  }, [title, vehiclebyCode]);
  useEffect(() => {
    if (title == "Add Vehicle Category") {
      addVehicleCategory.setFieldValue("code", "");
      addVehicleCategory.setFieldValue("name", "");
      addVehicleCategory.setFieldValue("description", "");
      addVehicleCategory.setFieldValue("countryCode", "");
    }
  }, [title, vehiclebyCode]);
  const [vehicleCodeErr, setVehicleCodeErr] = useState("");
  const [vehicleNameErr, setVehicleNameErr] = useState("");
  const saveVehicleCategory = (initialValues) => {
    if (/\s/g.test(addVehicleCategory.values.code)) {
      setVehicleCodeErr(
        "Code cannot cannot contain leading, trailing, or consecutive spaces"
      );
     }else if (/^\s|\s$/.test(addVehicleCategory.values.name)) {
      setVehicleNameErr(
          "Name cannot cannot contain leading or trailing spaces"
        );
    } else {
      setVehicleCodeErr("");
      setVehicleNameErr("");
      addVehicleCategory?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicle">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Code
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Code"
                      value={addVehicleCategory.values.code}
                      onChange={(e) => {
                        addVehicleCategory.setFieldValue(
                          "code",
                          e.target.value
                        );
                        setVehicleCodeErr("");
                      }}
                      disabled={title == "Edit vehicle category"}
                      name="code"
                      className="mainInput"
                    />
                  </div>
                  {addVehicleCategory.touched.code &&
                  addVehicleCategory.errors.code &&
                  vehicleCodeErr == "" ? (
                    <p className="requiredText">
                      {addVehicleCategory.errors.code}
                    </p>
                  ) : null}
                  {vehicleCodeErr !== "" ? (
                    <p className="requiredText">{vehicleCodeErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Name
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={addVehicleCategory.values.name}
                      onChange={(e) => {
                        addVehicleCategory.setFieldValue(
                          "name",
                          e.target.value
                        );
                        setVehicleNameErr("");
                      }}
                      name="name"
                      className="mainInput"
                    />
                  </div>
                  {(addVehicleCategory.touched.name &&
                  addVehicleCategory.errors.name && vehicleNameErr == "") ? (
                    <p className="requiredText">
                      {addVehicleCategory.errors.name}
                    </p>
                  ) : null}
                   {vehicleNameErr !== "" ? (
                    <p className="requiredText">{vehicleNameErr}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
            <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        addVehicleCategory.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                      }}
                      value={
                        addVehicleCategory?.values?.countryCode
                          ? countriesData?.data?.data?.content.find(
                              (option) => option.isoCode3 === addVehicleCategory?.values?.countryCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addVehicleCategory.touched.countryCode &&
                  addVehicleCategory.errors.countryCode ? (
                    <p className="requiredText">
                      {addVehicleCategory.errors.countryCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Description
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Description"
                      value={addVehicleCategory.values.description}
                      onChange={(e) => {
                        addVehicleCategory.setFieldValue(
                          "description",
                          e.target.value
                        );
                      }}
                      name="description"
                      className="mainInput"
                    />
                  </div>
                  {addVehicleCategory.touched.description &&
                  addVehicleCategory.errors.description ? (
                    <p className="requiredText">
                      {addVehicleCategory.errors.description}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button
            className="blue_btn"
            type="button"
            onClick={saveVehicleCategory}
          >
            {title === "Add Vehicle Category" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default VehicleCategoryModal;
