import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../../store/AoneStore";
import { useroutesStore } from "../../../store/RoutesMgt/RoutesStore";
import fileDownload from "js-file-download";
import * as XLSX from "xlsx";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import PaginationTable from "../../../components/Tables/paginationTable";
import CusModal from "../../../components/modal/CusModal";
import editIcon from "../../../assets/img/EditingIcon.svg";
import deleteIcon from "../../../assets/img/deleteingIcon.svg";
import RouteModal from "./RouteModal";
import DeletingModal from "../../../components/modal/deletingModal";
import { UserContext } from "../../../helper/ProtectedRoute";
const VehicleRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [originHub, setOriginHub] = useState("");
  const [destinationHub, setDestionationHub] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(-1);
  const [desPage, setDesPage] = useState(-1);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [state] = useContext(UserContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionHub, setSelectedOptionHub] = useState(null);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [routesData, setRoutesData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const hubsfilterData = useAoneStore((state) => state?.hubsfilterData);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const getHub = useAoneStore((state) => state?.getHub);
  const getRoutes = useroutesStore((state) => state?.getRoutes);
  const exportRoutes = useroutesStore((state) => state?.exportRoutes);
  const routesTemplate = useroutesStore((state) => state?.routesTemplate);
  const importRoutes = useroutesStore((state) => state?.importRoutes);
  const getDistance = useroutesStore((state) => state?.getDistance);
  const getRoutesbyCode = useroutesStore((state) => state?.getRoutesbyCode);
  const getStatus = useroutesStore((state) => state?.getStatus);
  const distanceList = useroutesStore((state) => state?.distanceList);
  const statusList = useroutesStore((state) => state?.statusList);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };

  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const downloadTemplate = async () => {
    try {
      const res = await routesTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Routes Template.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (code) {
      params += `&code=${code}`;
    }
    if (name) {
      params += `&name=${name}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (originHub) {
      params += `&originHubCode=${originHub}`;
    }
    if (destinationHub) {
      params += `&destinationHubCode=${destinationHub}`;
    }
    const data = await getRoutes(params);
    setTotalCount(data?.data?.data?.totalElements);
    setRoutesData(data?.data?.data?.content);
  };
  useEffect(() => {
    if (distanceList?.data?.data === undefined) {
      getDistance();
    }
    if (statusList?.data?.data === undefined) {
      getStatus();
    }
  }, []);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsDesGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : desPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDesPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desPage, prevSearch, setPrevSearch]
  );
  //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            importRoutes(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessage = response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (
                  response?.data?.data?.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (code) {
      params += `&code=${code}`;
    }
    if (name) {
      params += `&name=${name}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (originHub) {
      params += `&originHubCode=${originHub}`;
    }
    if (destinationHub) {
      params += `&destinationHubCode=${destinationHub}`;
    }
    try {
      const res = await exportRoutes(params);
      if (res.status == 200) {
        fileDownload(res.data, `Routes.xlsx`);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    location,
    saveHandler,
    code,
    name,
    status,
    originHub,
    destinationHub,
    ispostAdded,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${60}`;
    if (
      hubsfilterData?.data?.data?.content?.length < 1 ||
      hubsfilterData?.data?.data?.content?.length == undefined
    ) {
      getHub(paramslink);
    }
  }, []);
  const columns = [
    { id: "code", numeric: false, label: "Code" },
    { id: "name", numeric: false, label: "Name" },
    { id: "status", numeric: false, label: "Status" },
    { id: "productCode", numeric: false, label: "Product" },
    {
      id: "originHub",
      numeric: false,
      label: "Origin Hub",
      render: (row) => row.originHub?.name || "N/A", // Handling nested property with render function
    },
    {
      id: "destinationHub",
      numeric: false,
      label: "Destination Hub",
      render: (row) => row.destinationHub?.name || "N/A", // Handling nested property with render function
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
      render: (row) => (
        <>
          <button
            className="tableBtn"
            title="Edit"
            onClick={() => handleEdit(row.code)}
          >
            <img src={editIcon} className="turnBlack" alt="Edit" />
          </button>{" "}
          <button
            className="tableBtn"
            title="Delete"
            onClick={() => handleDelete(row.code, row.code, "Routes")}
          >
            <img src={deleteIcon} className="turnBlack" alt="Delete" />
          </button>
        </>
      ),
    },
  ];
  const [title, setTitle] = useState("Add Route");
  const handleEdit = (code) => {
    setTitle("Edit Route");
    setShowModal(true);
    getRouteonEdit(code);
  };
  const [routebyCode, setRoutebyCode] = useState([]);
  const getRouteonEdit = async (code) => {
    const res = await getRoutesbyCode(code);
    setRoutebyCode(res?.data?.data);
  };
  const [showModal, setShowModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const handleDelete = (id, name, title) => {
    setDeleteParams({
      id: id,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };
  //modal
  const handleSuccess = () => {
    setShowModal(false);
  };

  const handleError = (error) => {};
  const buttonRef = useRef(null); // Reference to the button element

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the "Escape" key (key code 27)
      if (event.keyCode === 27) {
        // Close the modal
        setShowModal(false);
      }
    };

    // Attach event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowModal]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/routes")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/ops/routes");
                }}
              >
                Routes
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Code:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={code}
                      className={
                        code?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setCode(e.target.value)}
                    />
                      {code && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setCode("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Name:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={name}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        name?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setName(e.target.value)}
                    />
                  {name && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setName("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Status:
                  </label>
                  <SearchInput
                    id="status"
                    label="status"
                    name="status"
                    options={statusList?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    handleChange={(selectedOption) => {
                      setStatus(selectedOption ? selectedOption.key : "");
                    }}
                    onMenuOpen={handleMenudescountry}
                    onMenuClose={handleMenuClosedescountry}
                    menuIsOpen={isReactdesCountry}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Status"
                    customStyles={{ control: { width: "160px" } }}
                    className="mainInput"
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Origin Hub:
                  </label>
                   <AsyncSearchInput
                  id="originHub"
                  label="originHub"
                  name="originHub"
                  value={selectedOption}
                  loadMoreOptions={loadMoreOptionsGroups}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    setOriginHub(
                      selectedOption ? selectedOption.value : ""
                    );
                  }}
                  customStyles={{ control: { width: "160px" } }}
                  placeholder="Origin Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Destination Hub:
                  </label>
                    <AsyncSearchInput
                  id="destinationHub"
                  label="destinationHub"
                  name="destinationHub"
                  value={selectedOptionHub}
                  loadMoreOptions={loadMoreOptionsDesGroups}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    menuIsOpen={isReactselectCity}
                  onChange={(selectedOptionHub) => {
                    setSelectedOptionHub(selectedOptionHub);
                    setDestionationHub(
                      selectedOptionHub ? selectedOptionHub.value : ""
                    );
                  }}
                  customStyles={{ control: { width: "160px" } }}
                  placeholder="Destination Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                  <>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon"
                      title="Routes Template"
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div title="Import Routes">
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                </>
                <button
                  className="filterBtn"
                  title={"Export Routes"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  ref={buttonRef}
                  className="filterBtn plus_icon"
                  title="Add"
                  tabIndex="-1" // Prevents button from receiving focus
                  onClick={() => {
                    setShowModal(true);
                    setTitle("Add Route");
                  }}
                  autoFocus // Ensures button receives focus when modal opens
                >
                  +
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
       <PaginationTable
          tbl="Routes"
          tableData={routesData}
          size={size}
          setSize={setSize}
          totalCount={totalCount}
          setNewPage={setNewPage}
          newPage={newPage}
          handleRouteEdit={handleEdit}
          handleRouteDelete={handleDelete}
          sortField={sortField}
          setSortField={setSortField}
        />
      <CusModal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="addnewpopupRoutes"
      >
        <RouteModal
          onSuccess={handleSuccess}
          onError={handleError}
          setShowModal={setShowModal}
          title={title}
          routebyCode={routebyCode}
        />
      </CusModal>
    </div>
  );
};

export default VehicleRoute;
