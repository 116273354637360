import React, { useState, useEffect, useContext } from "react";
import { useAoneStore } from "../../../store/AoneStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import isBefore from "date-fns/isBefore";
import openEye from "../../../assets/img/eye_show.svg";
import closeEye from "../../../assets/img/eye_hide.svg";
import { UserContext } from "../../../helper/ProtectedRoute";
import { roleHandler } from "../../../helper/roleHandler";
const Hubs = ({ setTab }) => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const postHubs = useAoneStore((state) => state.postHubs);
  const editHubs = useAoneStore((state) => state.editHubs);
  const getHubsbyId = useAoneStore((state) => state?.getHubsbyId);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const setglobalHubCode = useAoneStore((state) => state?.setglobalHubCode);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const hubTypes = useAoneStore((state) => state?.hubTypes);
  const [error, setError] = useState(null);
  const getRegionbyCode = useAoneStore((state) => state?.getRegionbyCode);
  const getCitybyCode = useAoneStore((state) => state.getCitybyCode);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const citybyCode = useAoneStore((state) => state?.citybyCode);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const getCities = useAoneStore((state) => state?.getCities);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const [data, setData] = useState([]);
  const today = new Date();
  const setcustomerAccount = useAoneStore((state) => state?.setcustomerAccount);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (countriesData?.data?.data?.content?.length === undefined) {
      getCountries(paramslink);
    }
    if (citiesData?.data?.data?.content?.length === undefined) {
      getCities(paramslink);
    }
  }, []);
  const handleCancelClick = () => {
    setglobalHubCode("")
    navigate("/assets/hubs");
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isHide, isNotHide] = useState(false);
  const addHubs = useFormik({
    initialValues: {
      code: title === "Edit Hub" ? data?.code : "",
      name: title === "Edit Hub" ? data?.name : "",
      regionCode: title === "Edit Hub" ? data?.regionSummary?.code : "",
      cityCode: title === "Edit Hub" ? data?.citySummary?.code : "",
      countryIsoCode3: title === "Edit Hub" ? data?.countryIsoCode3 : "",
      description: title === "Edit Hub" ? data?.description : "",
        type:
          title === "Edit Hub"
            ? hubTypes?.data?.data?.find((item) => item.key === data?.type)
                ?.key || ""
            : "",
      parentHub: title === "Edit Hub" ? data?.parentHub : "",
      latitude: title === "Edit Hub" ? data?.latitude : "",
      longitude: title === "Edit Hub" ? data?.longitude : "",
      alias: title === "Edit Hub" ? data?.alias : "",
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().required("Code is required"),
      name: Yup.string().trim().required("Name is required"),
      countryIsoCode3: Yup.string().required("Country is required"),
      regionCode: Yup.string().required("Region is required"),
      cityCode: Yup.string().required("City is required"),
      description: Yup.string().trim().required("Description is required"),
      type: Yup.string().required("Type is required"),
      latitude: Yup.number()
        .typeError("Latitude must be a number")
        .required("Latitude is required"),
      longitude: Yup.number()
        .typeError("Longitude must be a number")
        .required("Longitude is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setglobalHubCode(values?.code)
      if (title === "Edit Hub") {
        values.id = data.id;
        await editHubs(values).then((response) => {
          if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg
            toast.error(errorMessage);
            setglobalHubCode("")
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Row ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setglobalHubCode("")
          } else {
            toast.success("Hubs Updated");
            setTab(2);
            resetForm();
          }
        });
      } else {
        await postHubs(values).then((response) => {
          if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg
            toast.error(errorMessage);
            setglobalHubCode("")
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Row ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            setglobalHubCode("")
          } else {
            toast.success("Hubs Created");
            setTab(2);
            resetForm();
            setispostAdded(!ispostAdded);
          }
        });
      }
    },
  });
  const [showRegion, setShowRegion] = useState(
    title !== "Edit Hub"
  );
  const handleInputRegionClick = () => {
    if (title === "Edit Hub") {
      setShowRegion(true);
    }
  };
  const [showCity, setShowCity] = useState(
    title !== "Edit Hub"
  );
  const handleInputCityClick = () => {
    if (title === "Edit Hub") {
      setShowCity(true);
    }
  };
const [isparams,setisparams]= useState(false)
useEffect(()=>{
  if(params?.id){
    setTimeout(()=>{
      setisparams(!isparams)
    },300)
  }
},[])
useEffect(()=>{
if(title === "Edit Hub" && data?.countryIsoCode3 !== undefined){
  getRegionCity();
}
},[data])
const getRegionCity = async () =>{
  try {
    const regionData = await getRegionbyCode(
      data?.countryIsoCode3
    );
    const cityData = await getCitybyCode(
      data?.countryIsoCode3,
      data?.regionSummary?.code
    );
  } catch (error) {
    console.error("Error fetching region data:", error);
  }
}
useEffect(()=>{
  addHubs.setFieldValue("regionCode",data?.regionSummary?.code)
  addHubs.setFieldValue("cityCode",data?.citySummary?.code)
},[data,isparams])
const [isOpen, setIsopen] = useState(false);
  useEffect(() => {
    if (title !== "Edit Hub") {
      addHubs.setFieldValue("regionCode", "");
      addHubs.setFieldValue("cityCode", "");
    } else {
      if (isOpen) {
      addHubs.setFieldValue("regionCode", "");
      addHubs.setFieldValue("cityCode", "");
      } else {
        setIsopen(true);
      }
    }
  }, [addHubs.values.countryIsoCode3]);
  // useEffect(() => {
  //   if (title !== "Edit Hub") {
  //     addHubs.setFieldValue("cityCode", "");
  //   } else {
  //     if (isOpen) {
  //       addHubs.setFieldValue("cityCode", "");
  //     } else {
  //       setIsopen(true);
  //     }
  //   }
  // }, [addHubs.values.regionCode]);
  useEffect(() => {
    if (params?.id !== undefined) {
      getData();
      isNotHide(true);
    }
  }, [params?.id]);

  useEffect(() => {
    const mappedFields = Object.keys(addHubs.values).reduce((acc, field) => {
      acc[field] = data?.[field] || "";
      return acc;
    }, {});
    // Set form field values based on data
    addHubs.setValues((prevValues) => ({
      ...prevValues,
      ...mappedFields,
    }));
  }, [data]);

  const getData = async () => {
    const response = await getHubsbyId(params.id);
    setData(response?.data?.data);
  };
  return (
    <div className="row">
      <div className="col-md-4" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
            Hub Code:
            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                value={addHubs.values.code}
                name="code"
                placeholder="Hub Code"
                className="mainInput"
                onChange={(e) => addHubs.setFieldValue("code", e.target.value)}
                disabled={title == "Edit Hub" ?? true}
              />
            </div>
            {addHubs.touched.code && addHubs.errors.code ? (
              <p className="requiredText">{addHubs.errors.code}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
            Alias:
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                value={addHubs.values.alias}
                name="alias"
                placeholder="Alias"
                className="mainInput"
                onChange={(e) => addHubs.setFieldValue("alias", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Parent Hub:
          </label>
          <div className="col-md-8">
            <SearchInput
              id="parentHub"
              label="parentHub"
              name="parentHub"
              isClearable={true}
              options={hubsData?.data?.data?.content || []}
              getOptionLabel={(hub) => hub.name}
              getOptionValue={(hub) => hub.code}
              value={
                addHubs.values.parentHub
                  ? hubsData?.data?.data?.content?.find(
                      (hub) => hub.code === addHubs.values.parentHub
                    )
                  : null
              }
              handleChange={async (selectedOption) => {
                addHubs.setFieldValue(
                  "parentHub",
                  selectedOption ? selectedOption.code : ""
                );
                // Check if the selectedOption is null (i.e., the user cleared the selection)
                if (!selectedOption) {
                  // If type is SUBSTATION, set the error message
                  if (addHubs.values.type === "SUBSTATION") {
                    setError("Parent Hub is required");
                  } else {
                    // Clear any existing error message if the type is not SUBSTATION
                    setError(null);
                  }
                } else {
                  // Clear any existing error message if a selection is made
                  setError(null);
                }
              }}
              isSearchable={true}
              placeholder="Parent Hub"
            />
            {error ? <p className="requiredText">{error}</p> : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">Country: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          <div className="col-md-8">
            <SearchInput
              id="countryIsoCode3"
              label="countryIsoCode3"
              name="countryIsoCode3"
              options={countriesData?.data?.data?.content || []}
              getOptionLabel={(city) => city.name}
              getOptionValue={(city) => city.isoCode3}
              value={
                addHubs.values.countryIsoCode3
                  ? countriesData?.data?.data?.content?.find(
                      (country) =>
                        country.isoCode3 === addHubs.values.countryIsoCode3
                    )
                  : null
              }
              handleChange={async (selectedOption) => {
                addHubs.setFieldValue(
                  "countryIsoCode3",
                  selectedOption ? selectedOption.isoCode3 : ""
                );
                try {
                  const regionData = await getRegionbyCode(
                    selectedOption.isoCode3
                  );
                  const cityData = await getCitybyCode(
                    regionData?.data?.data?.[0]?.countryIsoCode3,
                    regionData?.data?.data?.[0]?.code
                  );
                } catch (error) {
                  console.error("Error fetching region data:", error);
                }
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Country"
            />
            {addHubs.touched.countryIsoCode3 &&
            addHubs.errors.countryIsoCode3 ? (
              <p className="requiredText">{addHubs.errors.countryIsoCode3}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="row">
            <div className="col-md-5 mr_3">
              <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                Latitude:
                <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
              </label>
              <div className="col-md-8">
                <div className="Grpinput">
                  <input
                    type="text"
                    value={addHubs.values.latitude}
                    name="latitude"
                    placeholder="Latitude"
                    className="mainInput"
                    onChange={(e) =>
                      addHubs.setFieldValue("latitude", e.target.value)
                    }
                  />
                </div>
              </div>
              {addHubs.touched.latitude && addHubs.errors.latitude ? (
                    <p className="requiredText">{addHubs.errors.latitude}</p>
                  ) : null}
            </div>
            <div className="col-md-5">
              <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                Longitude:
                <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
              </label>
              <div className="col-md-8">
                <div className="Grpinput">
                  <input
                    type="text"
                    value={addHubs.values.longitude}
                    name="longitude"
                    placeholder="Longitude"
                    className="mainInput"
                    onChange={(e) =>
                      addHubs.setFieldValue("longitude", e.target.value)
                    }
                  />
                </div>
              </div>
              {addHubs.touched.longitude && addHubs.errors.longitude ? (
                    <p className="requiredText">{addHubs.errors.longitude}</p>
                  ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
            Hub Name:
            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                value={addHubs.values.name}
                name="name"
                placeholder="Hub Name"
                className="mainInput"
                onChange={(e) => addHubs.setFieldValue("name", e.target.value)}
              />
            </div>
          </div>
          {addHubs.touched.name && addHubs.errors.name ? (
                <p className="requiredText">{addHubs.errors.name}</p>
              ) : null}
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">Type: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          <div className="col-md-8">
            <SearchInput
              id="type"
              label="type"
              name="type"
              isClearable={true}
              options={hubTypes?.data?.data || []}
              getOptionLabel={(country) => country.label}
              getOptionValue={(country) => country.key}
              handleChange={async (selectedOption) => {
                addHubs?.setFieldValue(
                  "type",
                  selectedOption ? selectedOption.key : ""
                );
                if (
                  selectedOption.key === "SUBSTATION" ||
                  selectedOption.key === "VIRTUAL"
                ) {
                  // Set an error message indicating that SUBSTATION is not allowed
                  if (addHubs?.values?.parentHub === "") {
                    setError("Parent Hub is required");
                  }
                } else {
                  // Clear any existing error message
                  setError(null);
                }
              }}
              value={
                addHubs.values.type
                  ? hubTypes?.data?.data?.find(
                      (hub) => hub.key === addHubs.values.type
                    )
                  : null
              }
              isSearchable={true}
              placeholder="Type"
            />
            {addHubs.touched.type && addHubs.errors.type ? (
              <p className="requiredText">{addHubs.errors.type}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">Region: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          <div className="col-md-8">
            <SearchInput
              id="regionCode"
              label="regionCode"
              name="regionCode"
              options={regionbyCode || []}
              getOptionLabel={(region) => region.name}
              getOptionValue={(region) => region.code}
              value={
                addHubs.values.regionCode
                  ? regionsData?.data?.data?.content?.find(
                      (region) => region.code === addHubs.values.regionCode
                    )
                  : null
              }
              handleChange={async (selectedOption) => {
                addHubs.setFieldValue(
                  "regionCode",
                  selectedOption ? selectedOption.code : ""
                );
                const cityData = await getCitybyCode(
                  selectedOption?.countryIsoCode3,
                  selectedOption?.code
                );
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Region"
            />
            {addHubs.touched.regionCode && addHubs.errors.regionCode ? (
              <p className="requiredText">{addHubs.errors.regionCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">City: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          <div className="col-md-8">
            <SearchInput
              id="cityCode"
              label="cityCode"
              name="cityCode"
              options={citybyCode || []}
              getOptionLabel={(city) => city.name}
              getOptionValue={(city) => city.code}
              value={
                addHubs.values.cityCode
                  ? citybyCode?.find(
                      (city) => city.code === addHubs.values.cityCode
                    )
                  : null
              }
              handleChange={async (selectedOption) => {
                addHubs.setFieldValue(
                  "cityCode",
                  selectedOption ? selectedOption.code : ""
                );
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="City"
            />
            {addHubs.touched.cityCode && addHubs.errors.cityCode ? (
              <p className="requiredText">{addHubs.errors.cityCode}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-md-8" style={{ padding: "12px" }}>
        <div className="col-md-10">
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
            Description:
            <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
          </label>
          <div className="Grpinput">
            <textarea
              className="washMe_button"
              value={addHubs.values.description}
              name="description"
              placeholder="Description"
              style={{ height: "90px", paddingTop: "6px" }}
              onChange={(e) =>
                addHubs.setFieldValue("description", e.target.value)
              }
            ></textarea>
          </div>
          {addHubs.touched.description && addHubs.errors.description ? (
            <p className="requiredText">{addHubs.errors.description}</p>
          ) : null}
        </div>
      </div>
      <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
        <button
          type="button"
          className="blue_btn"
          onClick={addHubs.handleSubmit}
        >
          Save
        </button>
        <button onClick={handleCancelClick} className="cancel_btn">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Hubs;
