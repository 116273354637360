import React, { useEffect, useState, useRef, useContext } from "react";
import downloadicon from "../../../src/assets/img/download_icon.svg";
import removeIcon from "../../../src/assets/img/cancelIcon.svg";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import { useAoneStore } from "../../store/AoneStore";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import fileDownload from "js-file-download";
import { UserContext } from "../../helper/ProtectedRoute";
const Users = () => {
  const getUsers = useAoneStore((state) => state?.getUsers);

  const [users, setUsers] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [editHandler, setEditHandler] = useState(false); // To Update While Delete - Complexity
  const [deleteHandler, setDeleteHandler] = useState(false); // Reload Page While Role Is Deleted
  const exportUsers = useAoneStore((state) => state?.exportUsers);
  const prevent = useRef(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (name) {
      params += `&name=${name}`;
    }
    if (email) {
      params += `&email=${email}`;
    }
    if (firstName) {
      params += `&firstName=${firstName}`;
    }
    if (lastName) {
      params += `&lastName=${lastName}`;
    }
    const data = await getUsers(params);
    setTotalCount(data.data.data.totalElements);
    setUsers(data.data.data.content);
  };
  const exportToExcel = async () => {
    try {
      const res = await exportUsers();
      if (res?.status == 200) {
        fileDownload(res.data, `Users.xlsx`);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, [newPage, size, addModalHandler, editHandler, sortField, deleteHandler,name,email,firstName,lastName]);
  return (
    <div>
      <div className="row alignItemCenter mb_16">
        <ul className="page_breadcrumb">
          <li>
            <span className="mainheadlink" style={{ fontWeight: "500" }}>
              IAM
            </span>
          </li>
          <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
            <span>
              <img
                src={breadArrow}
                className="turnBlack"
                alt="breadcrumbarrow"
              />
            </span>
          </li>
          <li>
            <span
              className="mainheadlink"
              style={{
                fontWeight: "500",
                marginLeft: "-3px",
                fontWeight: "bolder",
              }}
            >
              Users
            </span>
          </li>
        </ul>
      </div>

      <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
        <div className="displayFlex justifyContent_end">
          <div className="mr_15">
          <label className="fontSize14 mb_12 marginR10 mt_10">Username:</label>
          <div className="displayFlex alignItemCenter position-relative">
            <input
              type="text"
              className={
                name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
              }
              value={name}
              style={name?.length > 0 ? { border: "1px solid #ec171c" } : {}}
              onChange={(e) => (setName(e.target.value), setNewPage(0))}
            />
             {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
          </div>
          </div>
          <div className="mr_15">
          <label className="fontSize14 mb_12 marginR10 mt_10">Email:</label>
          <div className="displayFlex alignItemCenter position-relative">
            <input
              type="text"
              className={
                email?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
              }
              value={email}
              style={email?.length > 0 ? { border: "1px solid #ec171c" } : {}}
              onChange={(e) => (setEmail(e.target.value), setNewPage(0))}
            />
             {email && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setEmail("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
          </div>
          </div>
          <div className="mr_15">
          <label className="fontSize14 mb_12 marginR10 mt_10">Firstname:</label>
          <div className="displayFlex alignItemCenter position-relative">
            <input
              type="text"
              className={
                firstName?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
              }
              value={firstName}
              style={firstName?.length > 0 ? { border: "1px solid #ec171c" } : {}}
              onChange={(e) => (setFirstname(e.target.value), setNewPage(0))}
            />
              {firstName && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setFirstname("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
          </div>
          </div>
          <div>
          <label className="fontSize14 mb_12 marginR10 mt_10">Lastname:</label>
          <div className="displayFlex alignItemCenter position-relative">
            <input
              type="text"
              className={
                lastName?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
              }
              value={lastName}
              style={lastName?.length > 0 ? { border: "1px solid #ec171c" } : {}}
              onChange={(e) => (setLastName(e.target.value), setNewPage(0))}
            />
              {lastName && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setLastName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
          </div>
          </div>
        </div>
        <div className="displayFlex gap_12">
          <div>
            <button
              className="filterBtn"
              title="Download"
              onClick={() => exportToExcel()}
            >
              <span>
                <img src={downloadicon} className="h18" />
              </span>
            </button>
          </div>
          {(role?.includes("AONE_ADMIN") ||
            role?.includes("AONE_IAM.write") ||
            role?.includes("AONE_IAM.admin") ||
            role?.includes("AONE_IAM_USERS.write") ||
            role?.includes("AONE_IAM_USERS.admin")) && (
            <div>
              <button
                className="filterBtn plus_icon"
                onClick={() => setAddModalHandler(!addModalHandler)}
                tabIndex="-1"
                title="Add"
              >
                +
              </button>
            </div>
          )}
        </div>
      </div>
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Add Users"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />
      )}

      <PaginationTable
        tableData={users}
        tbl="Users"
        newPage={newPage}
        modalHandler={editHandler}
        setModalHandler={setEditHandler}
        setNewPage={setNewPage}
        size={size}
        setSize={setSize}
        totalCount={totalCount}
        setSortField={setSortField}
        sortField={sortField}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        setIndividualCheckboxesBag={setIndividualCheckboxesBag}
        individualCheckboxesbag={individualCheckboxesbag}
      />
    </div>
  );
};

export default Users;
