import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import fileDownload from "js-file-download";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useAoneStore } from "../../store/AoneStore";
import { useOrderStore } from "../../store/OrderMgt/OrderStore";
import PaginationTable from "../../components/Tables/paginationTable";
import downloadicon from "../../assets/img/download_icon.svg";

const PackageDetails = ({ orderDetails }) => {
  const [tabValue, setTabValue] = useState({});
  const getPackageTrackingId = useAoneStore((state) => state?.getPackageTrackingId);
  const packageTrack = useAoneStore((state) => state?.packageTrack);
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const exportPackageEvents = useOrderStore((state) => state?.exportPackageEvents);
  const handleTabChange = async (index, newValue, packageTrackingId) => {
    setTabValue((prev) => {
      const newTabValue = { ...prev };
      Object.keys(newTabValue).forEach(key => {
        if (key !== String(index)) {
          newTabValue[key] = 0; // Reset other tabs to "Details"
        }
      });
      newTabValue[index] = newValue;
      return newTabValue;
    });

    if (newValue === 1) {
      try {
        const response = await getPackageTrackingId(packageTrackingId);
        setTotalCount(response?.data?.data?.length);
      } catch (error) {
        console.error("Error fetching package tracking data:", error);
      }
    }
  };
  const sortedPackageTrackData = [...(packageTrack?.data?.data || [])].sort(
    (a, b) => b.eventDate - a.eventDate
  );
  const exportToExcel = async (packageTrackingId) => {
    try {
      const res = await exportPackageEvents(packageTrackingId);
      if (res.status === 200) {
        fileDownload(res.data, `${packageTrackingId}.xlsx`);
      }
    } catch (err) {}
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="displayFlex tabletab_section"></div>
      {orderDetails?.packages?.map((packageItem, index) => (
        <Accordion key={index} defaultExpanded={index === 0} className="mt_20">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
            className="customAccordionSummary"
          >
            <Typography variant="h6" fontWeight="bold">
              {packageItem.packageTrackingId}
            </Typography>
            <div className="ml_auto mr_10px">
            <button
                    className="filterBtn"
                    title={"Export Package Tracking"}
                    onClick={() => exportToExcel(packageItem.packageTrackingId)}>
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
                  </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="tabletab_sectionnew">
              <Tabs
                value={tabValue[index] || 0}
                onChange={(event, newValue) => handleTabChange(index, newValue, packageItem.packageTrackingId)}
                aria-label="package details and tracking tabs"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#EC171C',
                  },
                  '& .MuiTab-root': {
                    color: 'gray',
                    fontWeight: 'normal',
                    fontFamily: 'Arial, sans-serif',
                    textTransform: 'none',
                  },
                  '& .Mui-selected': {
                    color: '#EC171C !important',
                    fontWeight: 'bold',
                    fontFamily: 'Arial, sans-serif',
                    textTransform: 'none',
                  },
                }}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Details" />
                <Tab label="Tracking" />
              </Tabs>
            </div>
            <TabPanel value={tabValue[index] || 0} index={0}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        <Typography>Weight Unit:</Typography>
                        <Typography className="greycol">{packageItem?.weightUnit}</Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Weight:</Typography>
                        <Typography className="greycol">{packageItem?.weight}</Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Dimension Unit:</Typography>
                        <Typography className="greycol">{packageItem?.dimensionUnit ? packageItem?.dimensionUnit : "-"}</Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Height</Typography>
                        <Typography className="greycol">{packageItem?.height ? packageItem?.height : "-"}</Typography>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div>
                        <Typography>Width:</Typography>
                        <Typography className="greycol">{packageItem?.width ? packageItem?.width : "-"}</Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Length:</Typography>
                        <Typography className="greycol">{packageItem?.length ? packageItem?.length : "-"}</Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Volumetric Weight:</Typography>
                        <Typography className="greycol">
                          {orderDetails?.volumetricWeight ? orderDetails?.volumetricWeight : "-"}
                        </Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Status:</Typography>
                        <Typography className="greycol">
                          {packageItem?.status ? packageItem?.status : "-"}
                        </Typography>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div>
                        <Typography className="mt_10">Weight updated by:</Typography>
                        <Typography className="greycol">
                          {packageItem?.weightUpdatedBy ? packageItem?.weightUpdatedBy : "-"}
                        </Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Weight updated Date:</Typography>
                        <Typography className="greycol">
                          {packageItem?.weightUpdatedAt
                            ? new Date(packageItem?.weightUpdatedAt).toLocaleString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })
                            : "-"}
                        </Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Dimensions updated by:</Typography>
                        <Typography className="greycol">
                          {packageItem?.dimensionsUpdatedBy ? packageItem?.dimensionsUpdatedBy : "-"}
                        </Typography>
                      </div>
                      <div>
                        <Typography className="mt_10">Dimensions updated Date:</Typography>
                        <Typography className="greycol">
                          {packageItem?.dimensionsUpdatedAt
                            ? new Date(packageItem?.dimensionsUpdatedAt).toLocaleString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })
                            : "-"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabValue[index] || 0} index={1}>
              <div>
                <PaginationTable
                   tableData={sortedPackageTrackData}
                  tbl={"PackageTracking"}
                  newPage={newPage}
                  setNewPage={setNewPage}
                  size={size}
                  setSize={setSize}
                  totalCount={totalCount}
                />
              </div>
            </TabPanel>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default PackageDetails;
