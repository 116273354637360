import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getDeliveryFlow,postDeliveryFlow, getDeliveryflowbyId,editDeliveryflowbyId,
    deleteDeliveryflowbyId} from "../../services/DeliveryFlowMgt/Deliveryflow_services";
const initialValues = {
};
let deliveryFlowStore = (set, get) => ({
  ...initialValues,
  getDeliveryFlow: async (params) => {
        const response = await getDeliveryFlow(params);
        return response;
      },
      postDeliveryFlow: async (data) => {
        const response = await postDeliveryFlow(data);
        return response;
      },
      getDeliveryflowbyId: async (data) => { 
        const response = await getDeliveryflowbyId(data);
        return response;
      },
      editDeliveryflowbyId: async (params,data) => {
        const response = await editDeliveryflowbyId(params,data);
        return response;
      },
      deleteDeliveryflowbyId: async (params) => {
        const response = await deleteDeliveryflowbyId(params);
        return response;
      },
});

deliveryFlowStore = devtools(deliveryFlowStore);

export const useDlFlowStore = create(deliveryFlowStore);