import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  updateSingleCod,
  importCod,
  codUpdateTemplate,
  updateBulkCod,
  getCustomerSequencer,
  postCustomerSequencer,
  activateCustomerSequencer,
  getSequencerStatus,
  exportConsignmentEvents,
  exportPackageEvents
} from "../../services/OrderManagement/Order_services";
const initialValues = {
  customerSeqStatus: [],
};
let orderStore = (set, get) => ({
  ...initialValues,
  updateSingleCod: async (params, data) => {
    const response = await updateSingleCod(params, data);
    return response;
  },
  importCod: async (params) => {
    const response = await importCod(params);
    return response;
  },
  codUpdateTemplate: async (params) => {
    const response = await codUpdateTemplate(params);
    return response;
  },
  getCustomerSequencer: async () => {
    const response = await getCustomerSequencer();
    return response;
  },
  updateBulkCod: async (params) => {
    const response = await updateBulkCod(params);
    return response;
  },
  postCustomerSequencer: async (data) => {
    const response = await postCustomerSequencer(data);
    return response;
  },
  activateCustomerSequencer: async (params, data) => {
    const response = await activateCustomerSequencer(params, data);
    return response;
  },
  exportConsignmentEvents: async (params) => {
    const response = await exportConsignmentEvents(params);
    return response;
  },
  exportPackageEvents: async (params) => {
    const response = await exportPackageEvents(params);
    return response;
  },
  getSequencerStatus: async () => {
    const response = await getSequencerStatus();
    set({ customerSeqStatus: response });
    return response;
  },
});

orderStore = devtools(orderStore);

export const useOrderStore = create(orderStore);
