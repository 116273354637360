import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../store/AoneStore"; 
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
import { getHubs } from "../../services/aone_services";
const Attribute = () => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
  const globalHub = useAoneStore((state) => state?.globalHub);
  const globalPrefferedLanguage = useAoneStore(
    (state) => state?.globalPrefferedLanguage
  );
  const setglobalEmployeeCode = useAoneStore(
    (state) => state?.setglobalEmployeeCode
  );
  const [isHubExist,setIsHubExist] = useState(globalHub == "" || globalHub == undefined)
  const handleInputHubClick = () =>{
    if (title !== globalHub) {
      setIsHubExist(true);
    }
  }
  const postAttributes = useAoneStore((state) => state?.postAttributes);
  const languages = [
    { key: "en", label: "English" },
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "tr", label: "Turkish" },
  ];
  const addAttribute = useFormik({
    initialValues: {
      employeeCode: "",
      preferredLanguage: "",
      hubCode: "",
    },
    validationSchema: Yup.object({
      preferredLanguage: Yup.string().required(
        "Preferred Language is required"
      ),
      hubCode: Yup.string().required("Hub is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.employeeCode = globalEmployeeCode;
      if (globalEmployeeCode) {
        await postAttributes(values).then((response) => {
          if (response.status == "200") {
            toast.success("Employee Attributes added");
            navigate("/crm/employees");
            setglobalEmployeeCode("");
            resetForm();
          } else if (response?.response?.data?.status == 500) {
            const errorMessage = response?.response?.data?.error;
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.response?.data?.msg?.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        toast.error(
          "Employee Account need to be created for resetting the login information"
        );
      }
    },
  });
  useEffect(() => {
    addAttribute.setFieldValue("employeeCode", globalEmployeeCode);
    addAttribute.setFieldValue("preferredLanguage",globalPrefferedLanguage)
    addAttribute.setFieldValue("hubCode",globalHub)
  }, []);
  const [page, setPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [prevSearch, setPrevSearch] = useState("");
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? page + 1 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await getHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
            name: zone.name,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const [isReset, SetIsReset] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <div className="row mb_22">
            <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
              Employee Code:
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="mainInput"
                value={addAttribute.values.employeeCode}
                onChange={(e) =>
                  addAttribute.setFieldValue("employeeCode", e.target.value)
                }
                name="employeeCode"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="row mb_22">
            <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
              Preffered Language:
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
            <div className="col-md-6">
              <SearchInput
                id="preferredLanguage"
                label="preferredLanguage"
                name="preferredLanguage"
                options={languages || []}
                getOptionLabel={(city) => city.label}
                getOptionValue={(city) => city.key}
                handleChange={async (selectedOption) => {
                  addAttribute?.setFieldValue(
                    "preferredLanguage",
                    selectedOption ? selectedOption?.key : ""
                  );
                }}
                value={
                  languages?.find(
                    (lang) => lang.key === addAttribute.values.preferredLanguage
                  ) || null
                }
                isClearable={true}
                isSearchable={true}
                placeholder="Language"
              />
              {addAttribute.touched.preferredLanguage &&
              addAttribute.errors.preferredLanguage ? (
                <p className="requiredText">
                  {addAttribute.errors.preferredLanguage}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="row mb_22">
            <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
              Hub:
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
            <div className="col-md-6">
            {isHubExist ? (
              <AsyncSearchInput
                id="hubCode"
                label="hubCode"
                name="hubCode"
                value={selectedOption}
                loadMoreOptions={loadMoreOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(hub) => hub.label}
                getOptionValue={(hub) => hub.value}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                  addAttribute.setFieldValue(
                    "hubCode",
                    selectedOption ? selectedOption.value : ""
                  );
                }}
                placeholder="Hubs"
              />
            ) : (
              <input
                type="text"
                value={globalHub}
                name="originHubName"
                className="mainInput"
                onClick={handleInputHubClick}
              />
            )}
              {addAttribute.touched.hubCode && addAttribute.errors.hubCode ? (
                <p className="requiredText">{addAttribute.errors.hubCode}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
        <>
          <button
            type="button"
            className="blue_btn"
            onClick={addAttribute?.handleSubmit}
          >
            Save
          </button>
          <button className="cancel_btn">Cancel</button>
        </>
      </div>
    </div>
  );
};

export default Attribute;
