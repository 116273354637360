import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//Orders COD
//update single cod
export const updateSingleCod = async (params,data) => {
  try {
    return await AoneService.put(`orders/api/v1/orders/cod/${params}`,data);
  } catch (error) {
    return error;
  }
};
//import bulk cod
export const importCod = async (data) => {
  try {
    return await AoneService.post(`orders/api/v1/orders/cod/upload`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//template for cod update
export const codUpdateTemplate = async () => {
  try {
    return await AoneService.get(
      `orders/api/v1/orders/cod/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//update bulk cod
export const updateBulkCod = async (data) => {
  try {
    return await AoneService.put(`orders/api/v1/orders/cod`,data);
  } catch (error) {
    return error;
  }
};
//get customer sequencer
export const getCustomerSequencer = async (params) => {
    try {
      return await AoneService.get(`orders/api/v1/customer/sequencers${params}`);
    } catch (error) {
      return error;
    }
  };
//post Customer sequencer
export const postCustomerSequencer = async (data) => {
    try {
      return await AoneService.post("orders/api/v1/customer/sequencers", data);
    } catch (error) {
      return error;
    }
  };
//actiavate/deactivate customer sequence
export const activateCustomerSequencer = async (params,data) => {
    try {
      return await AoneService.patch(`orders/api/v1/customer/sequencers/${params}/status`,data);
    } catch (error) {
      return error;
    }
  };
  //lookup for status
  export const getSequencerStatus = async () => {
    try {
      return await AoneService.get(`orders/api/v1/lookups/CustomerSequencerStatus`);
    } catch (error) {
      return error;
    }
  };

  //export consignment events
  export const exportConsignmentEvents = async (params,params2) => {
    try {
      return await AoneService.get(`ops/api/v1/events/consignment/${params}/export` , {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
  //export package events
  export const exportPackageEvents = async (params,params2) => {
    try {
      return await AoneService.get(`ops/api/v1/events/consignment/${params}/export`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };