import React, { useState, useEffect, useContext } from "react";
import { useAoneStore } from "../../../store/AoneStore";
import { useDriverStore } from "../../../store/DriverMgt/driverStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../helper/ProtectedRoute";
import { roleHandler } from "../../../helper/roleHandler";
import DatePicker from "react-datepicker";
import openEye from "../../../assets/img/eye_show.svg";
import closeEye from "../../../assets/img/eye_hide.svg";
import "react-datepicker/dist/react-datepicker.css";
const DriversEdit = ({ setTab }) => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const currentDate = new Date();
  const getDriversbyId = useDriverStore((state) => state?.getDriversbyId);
  const payrollCompanyList = useDriverStore(
    (state) => state?.payrollCompanyList
  );
  const payrollTypeList = useDriverStore((state) => state?.payrollTypeList);
  const productContentData = useDriverStore(
    (state) => state?.productContentData
  );
  const [nationalHandler, setNationalHandler] = useState(false);
  const [phoneHandler, setPhoneHandler] = useState(false);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const getPayrollCompany = useDriverStore((state) => state?.getPayrollCompany);
  const getPayrollType = useDriverStore((state) => state?.getPayrollType);
  const getCountryById = useAoneStore((state) => state?.getCountryById);
  const setglobalDriverCode = useDriverStore(
    (state) => state?.setglobalDriverCode
  );
  const setglobalDriverFirstName = useDriverStore(
    (state) => state?.setglobalDriverFirstName
  );
  const setglobalDriverLastName = useDriverStore(
    (state) => state?.setglobalDriverLastName
  );
  const setglobalEmployeeCode = useDriverStore(
    (state) => state?.setglobalEmployeeCode
  );
  const setglobalUserStatus = useDriverStore(
    (state) => state?.setglobalUserStatus
  );
  const setglobalDriverActive = useDriverStore(
    (state) => state?.setglobalDriverActive
  );
  const showSensitiveFields = useDriverStore(
    (state) => state?.showSensitiveFields
  );
  const [phoneNumberRegex,setphoneNumberRegex] = useState("")
  const editDrivers = useDriverStore((state) => state?.editDrivers);
  const importDriverDocument = useDriverStore(
    (state) => state?.importDriverDocument
  );
  const vehicleList = usevehicleStore((state) => state?.vehicleList);
  const getVehicles = usevehicleStore((state) => state?.getVehicles);
  const [data, setData] = useState([]);
  const handleCancelClick = () => {
    navigate("/ops/linehauls");
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    getCountries(paramslink);
  }, []);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${30}`;
    if (
      vehicleList?.data?.data?.content?.length < 1 ||
      vehicleList?.length < 1
    ) {
      getVehicles(paramslink);
    }
  }, []);
  const togglePhoneHandler = async () => {
    setPhoneHandler(!phoneHandler);
    // Call getFieldName when the eye icon is clicked
    if (params?.id && !phoneHandler) {
      try {
        const response = await showSensitiveFields(params.id, "phoneNumber");
        addDrivers.setFieldValue("phoneNumber", response?.data?.data);
        isNotHide(false);
      } catch (error) {
        console.error("Error fetching field name:", error);
      }
    }
  };
  const toggleNationalHandler = async () => {
    setNationalHandler(!nationalHandler);
    // Call getFieldName when the eye icon is clicked
    if (params?.id && !nationalHandler) {
      try {
        const response = await showSensitiveFields(params.id, "nationalId");
        addDrivers.setFieldValue("nationalIqamaNumber", response?.data?.data);
      } catch (error) {
        console.error("Error fetching field name:", error);
      }
    }
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isHide, isNotHide] = useState(false);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const dateObject = new Date(dateString);
    const timestamp = dateObject.getTime();
    return timestamp;
  };
  //save account
  const addDrivers = useFormik({
    initialValues: {
      code: "",
      employeeCode: "",
      payrollType: "",
      countryCode: "",
      firstName: "",
      lastName: "",
      tripId: "",
      nationalIqamaNumber: "",
      activeVehicle: "",
      phoneNumber: "",
      payrollCompany: "",
      drivingLicenseUrl: "",
      drivingLicenseExpireDate: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(30, "First Name must be at most 30 characters long"),
      lastName: Yup.string().max(30, "Last Name must be at most 30 characters long")
      .matches(/^[a-zA-Z0-9_\s]*$/, "Last Name must not contain special characters"),
      payrollType: Yup.string().trim().required("Payroll Type is required"),
      drivingLicenseUrl: Yup.string()
        .trim()
        .required("Driving License is required"),
      drivingLicenseExpireDate: Yup.string()
        .trim()
        .required("Driving License Expiry Date is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      const { ...formattedValues } = valuestoSend;
      formattedValues.drivingLicenseExpireDate =
        formattedValues.drivingLicenseExpireDate
          ? formatDate(formattedValues.drivingLicenseExpireDate)
          : null;
      await editDrivers(values?.code, formattedValues).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Driver updated");
          setTab(2);
          resetForm();
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          errorMessages.forEach((errorMessage, index) => {
            toast.error(errorMessage);
          });
          resetForm();
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages);
          resetForm();
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
        }
      });
    },
  });
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [NationalIdErr, setNationalIdErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [payrollCompanyErr, setPayrollCompanyErr] = useState("");
  const handleSaveDriver = (initialValues) => {
    const regex = new RegExp(phoneNumberRegex);
    if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.firstName == "" &&
      addDrivers?.values?.lastName == "" &&
      addDrivers?.values?.nationalIqamaNumber == "" &&
      addDrivers?.values?.phoneNumber == "" &&
      addDrivers?.values?.countryCode == "" &&
      addDrivers?.values?.payrollCompany == ""
    ) {
      setFirstNameErr("First Name is required");
      setLastNameErr("Last Name is required");
      setNationalIdErr("National Id is required");
      setPhoneErr("Phone Number is required");
      setCountryErr("Country is required");
      setPayrollCompanyErr("Payroll Company is required");
    } else if (
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.firstName == ""
    ) {
      setFirstNameErr("First Name is required");
    }else if(/^\s|\s$/.test(addDrivers.values.firstName)){
      setFirstNameErr("First Name cannot contain leading or trailing spaces");
    } else if (
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.lastName == ""
    ) {
      setLastNameErr("Last Name is required");
    }else if(/^\s|\s$/.test(addDrivers.values.lastName)){
      setLastNameErr("Last Name cannot contain leading or trailing spaces");
    } else if (
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.nationalIqamaNumber == ""
    ) {
      setNationalIdErr("National Id is required");
    }else if(/\s/g.test(addDrivers.values.nationalIqamaNumber)){
      setNationalIdErr("National Id contain leading, trailing, or consecutive spaces");
    } else if (
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.phoneNumber == ""
    ) {
      setPhoneErr("Phone Number is required");
    }else if(/\s/g.test(addDrivers.values.phoneNumber)){
      setPhoneErr("Phone Number cannot contain leading or trailing spaces");
    } else if (addDrivers.values.phoneNumber && !regex.test(addDrivers.values.phoneNumber)) {
      // If it doesn't match, set the error message
      setPhoneErr("Invalid phone number format");
    } else if (
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.countryCode == ""
    ) {
      setCountryErr("Country is required");
    } else if (
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.payrollCompany == ""
    ) {
      setPayrollCompanyErr("Payroll Company is required");
    } else {
      setFirstNameErr("");
      setLastNameErr("");
      setNationalIdErr("");
      setPhoneErr("");
      setCountryErr("");
      setPayrollCompanyErr("");
      addDrivers?.handleSubmit();
    }
  };
  useEffect(() => {
    if (params?.id !== undefined) {
      getData();
      isNotHide(true);
    }
  }, [params?.id]);
  const getData = async () => {
    const response = await getDriversbyId(params.id);
    setData(response?.data?.data);
    setglobalDriverCode(response?.data?.data?.code);
    setglobalDriverFirstName(response?.data?.data?.firstName);
    setglobalDriverLastName(response?.data?.data?.lastName);
    setglobalEmployeeCode(response?.data?.data?.employeeCode);
    setglobalUserStatus(response?.data?.data?.userCreated);
    setglobalDriverActive(response?.data?.data?.statusBoolean);
    addDrivers.setFieldValue("code", response?.data?.data?.code);
    addDrivers.setFieldValue(
      "employeeCode",
      response?.data?.data?.employeeCode
    );
    addDrivers.setFieldValue("payrollType", response?.data?.data?.payrollType);
    addDrivers.setFieldValue("countryCode", response?.data?.data?.countryCode);
    const countryDat = await getCountryById(
      response?.data?.data?.countryCode
    );
    setphoneNumberRegex(countryDat?.data?.data?.phoneNumberRegex)
    addDrivers.setFieldValue("firstName", response?.data?.data?.firstName);
    addDrivers.setFieldValue("tripId", response?.data?.data?.tripId);
    addDrivers.setFieldValue("lastName", response?.data?.data?.lastName);
    addDrivers.setFieldValue(
      "nationalIqamaNumber",
      response?.data?.data?.nationalIqamaNumber
    );
    addDrivers.setFieldValue(
      "activeVehicle",
      response?.data?.data?.activeVehicle
    );
    addDrivers.setFieldValue("phoneNumber", response?.data?.data?.phoneNumber);
    addDrivers.setFieldValue(
      "payrollCompany",
      response?.data?.data?.payrollCompany
    );
    addDrivers.setFieldValue(
      "drivingLicenseUrl",
      response?.data?.data?.drivingLicenseUrl
    );
    addDrivers.setFieldValue(
      "drivingLicenseExpireDate",
      response?.data?.data?.drivingLicenseExpireDate
    );
  };
  useEffect(() => {
    if (payrollCompanyList?.data?.data === undefined) {
      getPayrollCompany();
    }
    if (payrollTypeList?.data?.data === undefined) {
      getPayrollType();
    }
  }, []);
  const uploadDoc = async (file) => {
    let res = await importDriverDocument(file);
    if(res?.data?.status == "ERROR"){
      toast.error(res?.data?.msg);
      return;
    }else{
    addDrivers.setFieldValue("drivingLicenseUrl", res?.data?.data);
    }
  };
  const [uploadName, setUploadName] = useState("");
  function handleFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".png", ".pdf", ".doc", ".docx", ".jpg", ".jpeg"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .jpg, .jpeg file or .png"
      );
      return; 
    }
    const fileSizeLimit = 1024 * 1024;
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file); // Append the file to the FormData object
    uploadDoc(formData);
    setUploadName(file);
    // Check if the element with the ID "file-name" exists in the document
    const fileNameDisplay = document.getElementById("file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  return (
    <div className="row">
      <div className="col-md-12" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Code:{" "}
          </label>
          <div className="col-md-2">
            <div className="Grpinput">
              <input
                type="text"
                placeholder="Code"
                className="mainInput"
                name="code"
                value={addDrivers.values.code}
                disabled
                onChange={(e) =>
                  addDrivers.setFieldValue("code", e.target.value)
                }
              />
            </div>
            {addDrivers.touched.code && addDrivers.errors.code ? (
              <p className="requiredText">{addDrivers.errors.code}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="row">
            <div className="col-md-2">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                First Name:
                {addDrivers?.values?.payrollType !== "AJEX" && (
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                )}
              </label>
              <div className="col-md-12">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="mainInput"
                    name="firstName"
                    onChange={(e) => {
                      addDrivers.setFieldValue("firstName", e.target.value);
                      setFirstNameErr("");
                    }}
                    value={addDrivers.values.firstName}
                    disabled={addDrivers?.values?.payrollType == "AJEX"}
                  />
                </div>
                {(addDrivers.touched.firstName &&
                addDrivers.errors.firstName && firstNameErr == "") ? (
                  <p className="requiredText">
                    {addDrivers.errors.firstName}
                  </p>
                ) : null}
                {firstNameErr !== "" ? (
                  <p className="requiredText">{firstNameErr}</p>
                ) : null}
              </div>
            </div>
            <div className="col-md-2 ml_50">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Last Name:
                {addDrivers?.values?.payrollType !== "AJEX" && (
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                )}
              </label>
              <div className="col-md-12">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="mainInput"
                    name="lastName"
                    disabled={addDrivers?.values?.payrollType == "AJEX"}
                    value={addDrivers.values.lastName}
                    onChange={(e) => {
                      addDrivers.setFieldValue("lastName", e.target.value);
                      setLastNameErr("");
                    }}
                  />
                </div>
                {(addDrivers.touched.lastName &&
                addDrivers.errors.lastName && lastNameErr == "") ? (
                  <p className="requiredText">
                    {addDrivers.errors.lastName}
                  </p>
                ) : null}
                {lastNameErr !== "" ? (
                  <p className="requiredText">{lastNameErr}</p>
                ) : null}
              </div>
            </div>
            <div className="col-md-2 ml_50">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Trip Id:
              </label>
              <div className="col-md-12">
                <div className="Grpinput">
                  <input
                    type="text"
                    placeholder="Trip Id"
                    className="mainInput"
                    name="tripId"
                    disabled
                    value={addDrivers.values.tripId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb_22">
          <div className="row">
            <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
              Phone Number:
              {addDrivers?.values?.payrollType !== "AJEX" && (
                <span className="bloodDonortext fontWeight600 fontSize16">
                  *
                </span>
              )}
            </label>
            <div className="col-md-2">
              <div className="col-md-12">
                 <div className="Grpinput">
              {!params?.id ? (
                <input
                  type={"text"}
                  placeholder="Phone Number"
                  className="mainInput"
                  name="phoneNumber"
                  value={addDrivers.values.phoneNumber}
                  onChange={(e) =>
                    addDrivers.setFieldValue("phoneNumber", e.target.value)
                  }
                />
              ) : (
                <div className="Groupinput">
                  <input
                    type={phoneHandler ? "text" : "password"}
                    placeholder="Phone Number"
                    className="mainInput"
                    name="phoneNumber"
                    value={addDrivers.values.phoneNumber}
                    onChange={(e) =>
                      addDrivers.setFieldValue("phoneNumber", e.target.value)
                    }
                  />
                  <div className="inputgroup_append">
                    {roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                    roleHandler(role, "AONE_OPS_DRIVERS.admin") ? (
                      <span
                        className="Groupinput_text"
                        onClick={togglePhoneHandler}
                      >
                        <img src={phoneHandler ? closeEye : openEye} />
                      </span>
                    ) : (
                      <span className="Groupinput_text"></span>
                    )}
                  </div>
                </div>
              )}
            </div>
                {phoneErr !== "" ? (
                  <p className="requiredText">{phoneErr}</p>
                ) : null}
              </div>
            </div>
            <div className="col-md-2 ml_50 mt_-33">
            <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Country
                      {addDrivers?.values?.employeeCode == "" && (
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      )}
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      isDisabled={
                        addDrivers?.values?.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                      }
                      value={
                        addDrivers?.values?.countryCode
                          ? countriesData?.data?.data?.content?.find(
                              (cnt) =>
                                cnt?.isoCode3 === addDrivers?.values?.countryCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) => {
                        addDrivers.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                        setphoneNumberRegex(selectedOption?.phoneNumberRegex)
                        setCountryErr("");
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className={
                        addDrivers.values.employeeCode
                          ? "mainInput disabled"
                          : "mainInput"
                      }
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {countryErr !== "" ? (
                    <p className="requiredText">{countryErr}</p>
                  ) : null}
                </div>
            <div className="col-md-2 ml_50 mt_-42">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Vehicle:
              </label>
              <div className="col-md-12">
                <SearchInput
                  id="activeVehicle"
                  label="activeVehicle"
                  name="activeVehicle"
                  options={vehicleList?.data?.data?.content || []}
                  getOptionLabel={(option) => option.plateNumber}
                  getOptionValue={(option) => option.code}
                  handleChange={(selectedOption) => {
                    addDrivers.setFieldValue(
                      "activeVehicle",
                      selectedOption ? selectedOption?.code : ""
                    );
                  }}
                  value={
                    addDrivers?.values?.activeVehicle
                      ? vehicleList?.data?.data?.content?.find(
                          (vendor) =>
                            vendor.code === addDrivers?.values?.activeVehicle
                        )
                      : null
                  }
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Vehicle"
                  className="mainInput"
                  style={{ whiteSpace: "nowrap" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            National Id:
            {addDrivers?.values?.payrollType !== "AJEX" && (
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            )}
          </label>
          <div className="col-md-2">
             <div className="Grpinput">
              {!params?.id ? (
                <input
                  type={"text"}
                  placeholder="National Id"
                  className="mainInput"
                  name="nationalIqamaNumber"
                  value={addDrivers.values.nationalIqamaNumber}
                  onChange={(e) =>
                    addDrivers.setFieldValue("nationalIqamaNumber", e.target.value)
                  }
                />
              ) : (
                <div className="Groupinput">
                  <input
                    type={nationalHandler ? "text" : "password"}
                    placeholder="National Id"
                    className="mainInput"
                    name="nationalIqamaNumber"
                    value={addDrivers.values.nationalIqamaNumber}
                    onChange={(e) =>
                      addDrivers.setFieldValue("nationalIqamaNumber", e.target.value)
                    }
                  />
                  <div className="inputgroup_append">
                    {roleHandler(role, "AONE_OPS.write") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                    roleHandler(role, "AONE_OPS_DRIVERS.admin") ? (
                      <span
                        className="Groupinput_text"
                        onClick={toggleNationalHandler}
                      >
                        <img src={nationalHandler ? closeEye : openEye} />
                      </span>
                    ) : (
                      <span className="Groupinput_text"></span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {NationalIdErr !== "" ? (
              <p className="requiredText">{NationalIdErr}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="row">
            <div className="col-md-2">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Payroll type:
                <span className="bloodDonortext fontWeight600 fontSize16">
                  *
                </span>
              </label>
              <div className="col-md-12">
                <SearchInput
                  id="payrollType"
                  label="payrollType"
                  name="payrollType"
                  isClearable={true}
                  options={payrollTypeList?.data?.data || []}
                  getOptionLabel={(region) => region.label}
                  getOptionValue={(region) => region.key}
                  handleChange={(selectedOption) => {
                    addDrivers.setFieldValue(
                      "payrollType",
                      selectedOption ? selectedOption.key : ""
                    );
                  }}
                  value={
                    payrollTypeList?.data?.data?.find(
                      (account) => account.key === addDrivers.values.payrollType
                    ) || null
                  }
                  isSearchable={true}
                  placeholder="Payroll type"
                  isDisabled={true}
                />
                {addDrivers.touched.payrollType &&
                addDrivers.errors.payrollType ? (
                  <p className="requiredText">
                    {addDrivers.errors.payrollType}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col-md-2 ml_50">
              <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                Payroll Company:
                <span className="bloodDonortext fontWeight600 fontSize16">
                  *
                </span>
              </label>
              <div className="col-md-12">
                <SearchInput
                  id="payrollCompany"
                  label="payrollCompany"
                  name="payrollCompany"
                  isClearable={true}
                  options={payrollCompanyList?.data?.data || []}
                  getOptionLabel={(region) => region.label}
                  getOptionValue={(region) => region.key}
                  handleChange={(selectedOption) => {
                    addDrivers.setFieldValue(
                      "payrollCompany",
                      selectedOption ? selectedOption.key : ""
                    );
                    setPayrollCompanyErr("");
                  }}
                  isDisabled={addDrivers?.values?.payrollType == "AJEX"}
                  value={
                    payrollCompanyList?.data?.data?.find(
                      (account) =>
                        account.key === addDrivers.values.payrollCompany
                    ) || null
                  }
                  isSearchable={true}
                  placeholder="Payroll Company"
                />
                {payrollCompanyErr !== "" ? (
                  <p className="requiredText">{payrollCompanyErr}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
            Driving License Expiry Date:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-2">
            <DatePicker
              placeholderText="Driving License Expiry Date"
              placement="rightStart"
              selected={
                addDrivers.values.drivingLicenseExpireDate
                  ? new Date(addDrivers.values.drivingLicenseExpireDate)
                  : null
              }
              onChange={(date) => {
                addDrivers.setFieldValue("drivingLicenseExpireDate", date);
              }}
              onClean={() =>
                addDrivers.setFieldValue("drivingLicenseExpireDate", null)
              }
              className="mainInput w213"
              showYearDropdown
              minDate={currentDate}
            />
            {addDrivers.touched.drivingLicenseExpireDate && addDrivers.errors.drivingLicenseExpireDate ? (
              <p className="requiredText">{addDrivers.errors.drivingLicenseExpireDate}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_45">
            Driving License:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="custom-file-upload">
            <input
              type="file"
              onChange={(e) => {
                handleFileUpload(e);
              }}
              name="drivingLicenseUrl"
              className="mainInput"
              id="upload"
            />
            <label htmlFor="upload" className="upload-labeldriver">
              Upload Document
            </label>
            {addDrivers?.values?.drivingLicenseUrl && (
              <div className="document-already-uploaded w20per mb_10">
                <span className="document-status">
                  Document Status: Uploaded
                </span>
              </div>
            )}
          </div>
          <div className="file-info">
            <p className="file-name">
              <p> {uploadName?.name}</p>{" "}
            </p>
          </div>
          {addDrivers.touched.drivingLicenseUrl && addDrivers.errors.drivingLicenseUrl ? (
              <p className="requiredText">{addDrivers.errors.drivingLicenseUrl}</p>
            ) : null}
        </div>
      </div>
      <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
        {(roleHandler(role, "AONE_OPS.write") ||
          roleHandler(role, "AONE_OPS.admin") ||
          roleHandler(role, "AONE_OPS_DRIVERS.write") ||
          roleHandler(role, "AONE_OPS_DRIVERS.admin")) && (
          <button type="button" className="blue_btn" onClick={handleSaveDriver}>
            Save
          </button>
        )}
        <button onClick={handleCancelClick} className="cancel_btn">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DriversEdit;
