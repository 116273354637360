import React, { useState, useEffect, useRef} from 'react'
import searchicon from "../../src/assets/img/searchicon.svg"
import eyeicon from "../../src/assets/img/eye_show.svg";
import { useNavigate } from 'react-router-dom'; 
import { useAoneStore } from '../store/AoneStore';
import PaginationTable from '../components/Tables/paginationTable';

const Express = ({ title }) => {

  const navigate = useNavigate();

  const getInvoices = useAoneStore((state) => state?.getInvoices)

  const [ invoices, setInvoices ] = useState([])

  const [ name, setName ] = useState("")
  const [ status, setStatus ] = useState(null)
  const [ newPage, setNewPage ] = useState(0)
  const [ size, setSize ] = useState(20)
  const [ totalCount, setTotalCount ] = useState(0)

  const prevent = useRef(false)

  const getData = async () => {
    const params = {
      waybillNo: `${name}`,
      invoiceId: "",
      interfaceLineGUID: "",
      status: status,
      bussinessLine: title,
      page: newPage,
      size: size
    }
    const data = await getInvoices(params)
    setInvoices(data.data)
    // setTotalCount(data.data.body.data.data.count)
    // if(data.data.body.data.code == 200){
    //     setRoles(data.data.body.data.data.data)
    // }
  }

  useEffect(() => {
    if(prevent.current){
      const delayFn = setTimeout(() => {
        getData();
      }, 1500)  
      return () => clearTimeout(delayFn)
    }else{
      prevent.current = true
    }
  }, [name])

  useEffect(()=>{
    setName("");
    setStatus(null);
    getData();
  },[title])

  useEffect(()=>{
    getData();
  },[newPage,size,status])   

  return (
    <div>
      <div className=''>
        <div className='row alignItemCenter'>
          <div className='col-md-7'>
            <h3 className='mainheadtext'>
              {title?.replace("_"," ")} INVOICE
            </h3>
          </div>

          <div  className='displayFlex col-md-5 justifyContent_end'>
            <div  className='displayFlex alignItemCenter'>
              <input type='text' placeholder='Search' className='mainInput'
                value={name} onChange={(e)=>setName(e.target.value)} />
            </div>
          </div>
        </div>

        <div className='displayFlex tabletab_section mt_20 '>
          <button className={`filterBtn ${ status == null && "activeFilterBtn"}`} onClick={()=>setStatus(null)}>All</button>
          <button className={`filterBtn ${ status == "APPROVAL_PENDING" && "activeFilterBtn"}`} onClick={()=>setStatus("APPROVAL_PENDING")}>Pending</button>
          <button className={`filterBtn ${ status == "READY_FOR_INVOICE_GENERATION" && "activeFilterBtn"}`} onClick={()=>setStatus("READY_FOR_INVOICE_GENERATION")}>Approved</button>
          <button className={`filterBtn ${ status == "DONE" && "activeFilterBtn"}`} onClick={()=>setStatus("DONE")}>Processed</button>
          <button className='filterBtn'>Rejected</button>
        </div>

        <div className='mt_20'>
          <PaginationTable tableData={invoices} tbl="Invoices" newPage={newPage}
            setNewPage={setNewPage} size={size} setSize={setSize} totalCount={100} />
        </div>

        {/* <div className='table_wrapper overflowScroll mt_20'>
          <table >
            <thead >
              <th>Waybill No</th>
              <th>Customer AccountNo</th>
              <th>Oracle InvoiceNo</th>
              <th>Business Line</th>
              <th>Status</th>
              <th>Action</th>      
            </thead>
            <tbody>
              <tr>
                <td>123</td>
                <td>cust-123</td>
                <td>123456</td>
                <td>Express_Invoice</td>
                <td className=''>
                  <p className='status_inactive'>Rejected</p>
                </td>
                <td>
                  <button className='tableBtn' onClick={()=>navigate("/express/123")}>
                    <span>
                      <img src={eyeicon} />
                    </span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>123</td>
                <td>cust-123</td>
                <td>123456</td>
                <td>Express_Invoice</td> 
                <td className=''>
                  <p className='status_active'>Approved</p>
                </td>
                <td>
                  <button className='tableBtn' onClick={()=>navigate("/express/123")}>
                    <span>
                      <img src={eyeicon} />
                    </span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>123</td>
                <td>cust-123</td>
                <td>123456</td>
                <td>Express_Invoice</td>
                <td className=''>
                  <p className='status_pending'>Pending</p>
                </td>
                <td>
                  <button className='tableBtn' onClick={()=>navigate("/express/123")}>
                    <span>
                      <img src={eyeicon} />
                    </span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>123</td>
                <td>cust-123</td>
                <td>123456</td>
                <td>Express_Invoice</td>
                <td className=''>
                  <p className='status_process'>Processed</p>
                </td>
                <td>
                  <button className='tableBtn' onClick={()=>navigate("/express/123")}>
                    <span>
                      <img src={eyeicon} />
                    </span>
                  </button>
                </td>
              </tr>              
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  )
}

export default Express