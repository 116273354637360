import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  getAcls,
  postAcls,
  editAcls,
  exportAcl,
  importConsignmentsAcl,
  editEventRequest,
  sealAcl,
  assignAcl,
  assignAclDeliver,
  receiveAclEvent,
  getLabelbyAcl,
  exportConsignmentsforAcl,
  AclconsignmentsTemplate,
  getAclEventsbyaclNumber,
  unsealAcl,
  unAssignAcl,
  removeAclConsignments,
  postConsignmentsAcl,
  transferConsignmentAcl,
  queryConsignmentByAcl,
  updateAclInfo,
  getAclTypes,
  getAclStatus,
  getWeightUnit
} from "../../services/AclManagement/Acl_services";
const initialValues = {
  AclTypeList : [],
  AclStatusList: [],
  AclWeightList: [],
};
let aclStore = (set, get) => ({
  ...initialValues,
  getAcls: async (params) => {
    const response = await getAcls(params);
    return response;
  },
  postAcls: async (data) => {
    const response = await postAcls(data);
    return response;
  },
  editAcls: async (params,data) => {
    const response = await editAcls(params,data);
    return response;
  },
  exportAcl: async (data) => {
    const response = await exportAcl(data);
    return response;
  },
  importConsignmentsAcl: async (params, data) => {
    const response = await importConsignmentsAcl(params, data);
    return response;
  },
  editEventRequest: async (params, data) => {
    const response = await editEventRequest(params, data);
    return response;
  },
  sealAcl: async (params, params2) => {
    const response = await sealAcl(params, params2);
    return response;
  },
  assignAcl: async (params, params2) => {
    const response = await assignAcl(params, params2);
    return response;
  },
  assignAclDeliver: async (params) => {
    const response = await assignAclDeliver(params);
    return response;
  },
  receiveAclEvent: async (params) => {
    const response = await receiveAclEvent(params);
    return response;
  },
  getLabelbyAcl: async (params) => {
    const response = await getLabelbyAcl(params);
    return response;
  },
  exportConsignmentsforAcl: async (params) => {
    const response = await exportConsignmentsforAcl(params);
    return response;
  },
  AclconsignmentsTemplate: async () => {
    const response = await AclconsignmentsTemplate();
    return response;
  },
  getAclTypes: async () => {
    const response = await getAclTypes();
    set({AclTypeList: response})
    return response;
  },
  getAclStatus: async () => {
    const response = await getAclStatus();
    set({AclStatusList: response})
    return response;
  },
  getWeightUnit: async () => {
    const response = await getWeightUnit();
    set({AclWeightList: response})
    return response;
  },
  getAclEventsbyaclNumber: async (params, data) => {
    const response = await getAclEventsbyaclNumber(params, data);
    return response;
  },
  unsealAcl: async (params) => {
    const response = await unsealAcl(params);
    return response;
  },
  unAssignAcl: async (params) => {
    const response = await unAssignAcl(params);
    return response;
  },
  removeAclConsignments: async (params, data) => {
    const response = await removeAclConsignments(params, data);
    return response;
  },
  postConsignmentsAcl: async (params, data) => {
    const response = await postConsignmentsAcl(params, data);
    return response;
  },
  transferConsignmentAcl: async (params, data) => {
    const response = await transferConsignmentAcl(params, data);
    return response;
  },
  queryConsignmentByAcl: async (params, data) => {
    const response = await queryConsignmentByAcl(params, data);
    return response;
  },
  updateAclInfo: async (params) => {
    const response = await updateAclInfo(params);
    return response;
  },
});

aclStore = devtools(aclStore);

export const useAclStore = create(aclStore);
