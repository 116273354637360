import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDriverStore } from "../../../store/DriverMgt/driverStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import { employeedepartment } from "../../../services/aone_services";
const DriversModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  weightbyCode,
}) => {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess();
  };
  const postDrivers = useDriverStore((state) => state?.postDrivers);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const payrollTypeList = useDriverStore((state) => state?.payrollTypeList);
  const setglobalDriverCode = useDriverStore(
    (state) => state?.setglobalDriverCode
  );
  const payrollCompanyList = useDriverStore(
    (state) => state?.payrollCompanyList
  );
  const importDriverDocument = useDriverStore(
    (state) => state?.importDriverDocument
  );
  const employeeData = useAoneStore((state) => state?.employeeData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const vehicleList = usevehicleStore((state) => state?.vehicleList);
  const [phoneNumberRegex,setphoneNumberRegex] = useState("")
  const [resetKey, setResetKey] = useState(0);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const dateObject = new Date(dateString);
    const timestamp = dateObject.getTime();
    return timestamp;
  };
  const addDrivers = useFormik({
    initialValues: {
      employeeCode: "",
      payrollType: "",
      countryCode: "",
      firstName: "",
      lastName: "",
      nationalIqamaNumber: "",
      activeVehicle: "",
      phoneNumber: "",
      payrollCompany: "",
      drivingLicenseUrl: "",
      drivingLicenseExpireDate: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(30, "First Name must be at most 30 characters long"),
      lastName: Yup.string().max(30, "Last Name must be at most 30 characters long")
      .matches(/^[a-zA-Z0-9_\s]*$/, "Last Name must not contain special characters"),
      payrollType: Yup.string().trim().required("Payroll Type is required"),
      drivingLicenseUrl: Yup.string()
        .trim()
        .required("Driving License is required"),
      drivingLicenseExpireDate: Yup.string()
        .trim()
        .required("Driving License Expiry Date is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      const { ...formattedValues } = valuestoSend;
      formattedValues.drivingLicenseExpireDate =
        formattedValues.drivingLicenseExpireDate
          ? formatDate(formattedValues.drivingLicenseExpireDate)
          : null;
      await postDrivers(formattedValues).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          setglobalDriverCode(response?.data?.data);
          toast.success("Driver added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
          navigate(`/ops/linehauls/driver/${response?.data?.data}`);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          errorMessages.forEach((errorMessage, index) => {
            toast.error(errorMessage);
          });
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    },
  });
  const currentDate = new Date();
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [NationalIdErr, setNationalIdErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [payrollCompanyErr, setPayrollCompanyErr] = useState("");
  const saveDriver = (initialValues) => {
    const regex = new RegExp(phoneNumberRegex);
    if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.firstName == "" &&
      addDrivers?.values?.lastName == "" &&
      addDrivers?.values?.nationalIqamaNumber == "" &&
      addDrivers?.values?.phoneNumber == "" &&
      addDrivers?.values?.countryCode == "" &&
      addDrivers?.values?.payrollCompany == ""
    ) {
      setFirstNameErr("First Name is required");
      setLastNameErr("Last Name is required");
      setNationalIdErr("National Id is required");
      setPhoneErr("Phone Number is required");
      setCountryErr("Country is required");
      setPayrollCompanyErr("Payroll Company is required");
    } else if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.firstName == ""
    ) {
      setFirstNameErr("First Name is required");
    } else if (/^\s|\s$/.test(addDrivers.values.firstName)) {
      setFirstNameErr("First Name cannot contain leading or trailing spaces");
    } else if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.lastName == ""
    ) {
      setLastNameErr("Last Name is required");
    } else if (/^\s|\s$/.test(addDrivers.values.lastName)) {
      setLastNameErr("Last Name cannot contain leading or trailing spaces");
    } else if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.nationalIqamaNumber == ""
    ) {
      setNationalIdErr("National Id is required");
    } else if (/\s/g.test(addDrivers.values.nationalIqamaNumber)) {
      setNationalIdErr(
        "National Id contain leading, trailing, or consecutive spaces"
      );
    } else if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.phoneNumber == ""
    ) {
      setPhoneErr("Phone Number is required");
    } else if (/\s/g.test(addDrivers.values.phoneNumber)) {
      setPhoneErr("Phone Number cannot contain leading or trailing spaces");
    } else if (addDrivers.values.phoneNumber && !regex.test(addDrivers.values.phoneNumber)) {
      // If it doesn't match, set the error message
      setPhoneErr("Invalid phone number format");
    } else if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.countryCode == ""
    ) {
      setCountryErr("Country is required");
    } else if (
      addDrivers?.values?.employeeCode == "" &&
      addDrivers?.values?.payrollType !== "AJEX" &&
      addDrivers?.values?.payrollCompany == ""
    ) {
      setPayrollCompanyErr("Payroll Company is required");
    } else {
      setFirstNameErr("");
      setLastNameErr("");
      setNationalIdErr("");
      setPhoneErr("");
      setCountryErr("");
      setPayrollCompanyErr("");
      addDrivers?.handleSubmit();
    }
  };
  const uploadDoc = async (file) => {
    let res = await importDriverDocument(file);
    if(res?.data?.status == "ERROR"){
      toast.error(res?.data?.msg);
      return;
    }else{
    addDrivers.setFieldValue("drivingLicenseUrl", res?.data?.data);
    }
  };
  const [uploadName, setUploadName] = useState("");
  function handleFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".png", ".pdf", ".doc", ".docx", ".jpg", ".jpeg"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .jpg, .jpeg file or .png"
      );
      return; 
    }
    const fileSizeLimit = 1024 * 1024;
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file); // Append the file to the FormData object
    uploadDoc(formData);
    setUploadName(file);
    // Check if the element with the ID "file-name" exists in the document
    const fileNameDisplay = document.getElementById("file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollDriver">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Payroll Type
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="payrollType"
                      label="payrollType"
                      name="payrollType"
                      options={payrollTypeList?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        addDrivers.setFieldValue(
                          "payrollType",
                          selectedOption ? selectedOption?.key : ""
                        );
                        if (selectedOption?.key === "OUTSOURCE") {
                          addDrivers.setFieldValue("employeeCode", "");
                          setResetKey((prevKey) => prevKey + 1);
                        }else if(selectedOption?.key === "AJEX") {
                          addDrivers.setFieldValue("firstName", "");
                          addDrivers.setFieldValue("lastName", "");
                          addDrivers.setFieldValue("nationalIqamaNumber", "");
                          addDrivers.setFieldValue("payrollCompany", "");
                          addDrivers.setFieldValue("phoneNumber", "");
                          addDrivers.setFieldValue("countryCode", "");
                          setResetKey((prevKey) => prevKey + 1);
                        }
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Payroll Type"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addDrivers.touched.payrollType &&
                  addDrivers.errors.payrollType ? (
                    <p className="requiredText">
                      {addDrivers.errors.payrollType}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Employee
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="employeeCode"
                      label="employeeCode"
                      name="employeeCode"
                      options={employeeData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.firstName}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addDrivers.setFieldValue(
                          "employeeCode",
                          selectedOption ? selectedOption?.code : ""
                        );
                        setFirstNameErr("");
                        setLastNameErr("");
                        setNationalIdErr("");
                        setPhoneErr("");
                        setCountryErr("");
                        setPayrollCompanyErr("");
                      }}
                      isDisabled={
                        addDrivers?.values?.payrollType === "OUTSOURCE"
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Employee"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addDrivers.touched.dimensionUnit &&
                  addDrivers.errors.dimensionUnit ? (
                    <p className="requiredText">
                      {addDrivers.errors.dimensionUnit}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      First Name
                      {addDrivers?.values?.employeeCode == "" && (
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      value={addDrivers.values.firstName}
                      onChange={(e) => {
                        addDrivers.setFieldValue("firstName", e.target.value);
                        setFirstNameErr("");
                      }}
                      disabled={
                        addDrivers?.values?.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                      }
                      name="firstName"
                      className={
                        addDrivers.values.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                          ? "mainInput disabled"
                          : "mainInput"
                      }
                    />
                  </div>
                  {(addDrivers.touched.firstName &&
                addDrivers.errors.firstName && firstNameErr == "") ? (
                  <p className="requiredText">
                    {addDrivers.errors.firstName}
                  </p>
                ) : null}
                  {firstNameErr !== "" ? (
                    <p className="requiredText">{firstNameErr}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Last Name
                      {addDrivers?.values?.employeeCode == "" && (
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={addDrivers.values.lastName}
                      disabled={
                        addDrivers?.values?.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                      }
                      onChange={(e) => {
                        addDrivers.setFieldValue("lastName", e.target.value);
                        setLastNameErr("");
                      }}
                      name="lastName"
                      className={
                        addDrivers.values.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                          ? "mainInput disabled"
                          : "mainInput"
                      }
                    />
                  </div>
                  {(addDrivers.touched.lastName &&
                addDrivers.errors.lastName && lastNameErr == "") ? (
                  <p className="requiredText">
                    {addDrivers.errors.lastName}
                  </p>
                ) : null}
                  {lastNameErr !== "" ? (
                    <p className="requiredText">{lastNameErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      National/Iqama Number
                      {addDrivers?.values?.employeeCode == "" && (
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      placeholder="National/Iqama Number"
                      value={addDrivers.values.nationalIqamaNumber}
                      disabled={
                        addDrivers?.values?.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                      }
                      onChange={(e) => {
                        addDrivers.setFieldValue(
                          "nationalIqamaNumber",
                          e.target.value
                        );
                        setNationalIdErr("");
                      }}
                      name="nationalIqamaNumber"
                      className={
                        addDrivers.values.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                          ? "mainInput disabled"
                          : "mainInput"
                      }
                    />
                  </div>
                  {NationalIdErr !== "" ? (
                    <p className="requiredText">{NationalIdErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Country
                      {addDrivers?.values?.employeeCode == "" && (
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      )}
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      isDisabled={
                        addDrivers?.values?.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                      }
                      value={
                        addDrivers?.values?.countryCode
                          ? countriesData?.data?.data?.content?.find(
                              (cnt) =>
                                cnt?.code === addDrivers?.values?.countryCode
                            )
                          : null
                      }
                      handleChange={(selectedOption) => {
                        addDrivers.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                        setphoneNumberRegex(selectedOption?.phoneNumberRegex)
                        setCountryErr("");
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className={
                        addDrivers.values.employeeCode
                          ? "mainInput disabled"
                          : "mainInput"
                      }
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {countryErr !== "" ? (
                    <p className="requiredText">{countryErr}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Payroll Company
                      {addDrivers?.values?.employeeCode == "" && (
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      )}
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="payrollCompany"
                      label="payrollCompany"
                      name="payrollCompany"
                      isClearable={true}
                      options={payrollCompanyList?.data?.data || []}
                      getOptionLabel={(region) => region.label}
                      getOptionValue={(region) => region.key}
                      handleChange={(selectedOption) => {
                        addDrivers.setFieldValue(
                          "payrollCompany",
                          selectedOption ? selectedOption.key : ""
                        );
                        setPayrollCompanyErr("");
                      }}
                      isDisabled={
                        addDrivers?.values?.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                      }
                      value={
                        payrollCompanyList?.data?.data?.find(
                          (account) =>
                            account.key === addDrivers.values.payrollCompany
                        ) || null
                      }
                      isSearchable={true}
                      placeholder="Payroll Company"
                    />
                    {payrollCompanyErr !== "" ? (
                      <p className="requiredText">{payrollCompanyErr}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Phone Number
                      {addDrivers?.values?.employeeCode == "" && (
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      value={addDrivers.values.phoneNumber}
                      disabled={
                        addDrivers?.values?.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                      }
                      onChange={(e) => {
                        addDrivers.setFieldValue("phoneNumber", e.target.value);
                        setPhoneErr("");
                      }}
                      name="phoneNumber"
                      className={
                        addDrivers.values.employeeCode ||
                        addDrivers?.values?.payrollType == "AJEX"
                          ? "mainInput disabled"
                          : "mainInput"
                      }
                    />
                  </div>
                  {phoneErr !== "" ? (
                    <p className="requiredText">{phoneErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12 displayFlex flexDirectionColumn">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Driving License Expiry Date
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <div className="col-md-12">
                      <div
                        className="customDateRange"
                        style={{ color: "black", position: "relative" }}
                      >
                        <DatePicker
                          placeholderText="Driving License Expiry Date"
                          placement="rightStart"
                          selected={
                            addDrivers.values.drivingLicenseExpireDate
                              ? new Date(
                                  addDrivers.values.drivingLicenseExpireDate
                                )
                              : null
                          }
                          onChange={(date) => {
                            addDrivers.setFieldValue(
                              "drivingLicenseExpireDate",
                              date
                            );
                          }}
                          onClean={() =>
                            addDrivers.setFieldValue(
                              "drivingLicenseExpireDate",
                              null
                            )
                          }
                          className="mainInput w213"
                          showYearDropdown
                          minDate={currentDate}
                        />
                      </div>
                    </div>
                  </div>
                  {addDrivers.touched.drivingLicenseExpireDate &&
                  addDrivers.errors.drivingLicenseExpireDate ? (
                    <p className="requiredText mt_45">
                      {addDrivers.errors.drivingLicenseExpireDate}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Driving License
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <div className="custom-file-upload">
                      <input
                        type="file"
                        onChange={(e) => {
                          handleFileUpload(e);
                        }}
                        name="drivingLicenseUrl"
                        className="mainInput"
                        id="upload"
                      />
                      <label htmlFor="upload" className="upload-labeldriver">
                        Upload Document
                      </label>
                    </div>
                    <div className="file-info">
                      <p className="file-name">
                        <p> {uploadName?.name}</p>{" "}
                      </p>
                    </div>
                  </div>
                  {addDrivers.touched.drivingLicenseUrl &&
                  addDrivers.errors.drivingLicenseUrl ? (
                    <p className="requiredText">
                      {addDrivers.errors.drivingLicenseUrl}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Active Vehicle
                    </label>
                    <SearchInput
                      id="activeVehicle"
                      label="activeVehicle"
                      name="activeVehicle"
                      options={vehicleList?.data?.data?.content || []}
                      getOptionLabel={(option) => option.plateNumber}
                      getOptionValue={(option) => option.code}
                      handleChange={(selectedOption) => {
                        addDrivers.setFieldValue(
                          "activeVehicle",
                          selectedOption ? selectedOption?.code : ""
                        );
                      }}
                      value={
                        addDrivers?.values?.activeVehicle
                          ? vehicleList?.data?.data?.content?.find(
                              (vendor) =>
                                vendor.code ===
                                addDrivers?.values?.activeVehicle
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Active Vehicle"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveDriver}>
            Create
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DriversModal;
