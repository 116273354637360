import React, { useState, useEffect, useRef ,useContext} from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams } from "react-router";
import { useDriverStore } from "../../store/DriverMgt/driverStore";
import Trips from "../Linehaul/Trip/Trips";
import Drivers from "../Linehaul/Driver/Drivers";
import { roleHandler } from "../../helper/roleHandler";
import { UserContext } from "../../helper/ProtectedRoute";
const Linehauls = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [editPage, setEditPage] = useState(false);
  const [tab, setTab] = useState(1);
  const[isTab,setIsTab] = useState(false)
  const setLineHaulTab = useDriverStore((state) => state?.setLineHaulTab);
  const isDriverClicked = useDriverStore((state) => state?.isDriverClicked);
  const linehaulTab = useDriverStore((state) => state?.linehaulTab);
  const prevent = useRef(false);
  const tabHandler = (i) => {
    setTab(i);
    setIsTab(true)
    prevent.current = false;
    setEditPage(false);
    setLineHaulTab(i);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  useEffect(() => {
    if (isDriverClicked) {
      setLineHaulTab(2);
      setTab(2);
    } else if (
      roleHandler(role, "AONE_OPS.read") ||
      roleHandler(role, "AONE_OPS.write") ||
      roleHandler(role, "AONE_OPS.admin") ||
      roleHandler(role, "AONE_OPS_TRIPS.read") ||
      roleHandler(role, "AONE_OPS_TRIPS.write") ||
      roleHandler(role, "AONE_OPS_TRIPS.admin")
    ) {
      setLineHaulTab(1);
      setTab(1);
    } else {
      setLineHaulTab(2);
      setTab(2);
    }
  }, [role, isDriverClicked]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/linehauls")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/linehauls")}
              >
                Linehaul
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/linehauls")}
              >
                {linehaulTab == 1 ? "Trips" : "Drivers"}
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/linehauls")}
              >
                {params?.id}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
      {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_TRIPS.read") ||
                  roleHandler(role, "AONE_OPS_TRIPS.write") ||
                  roleHandler(role, "AONE_OPS_TRIPS.admin")) && (
        <button
          className={`filterBtn ${linehaulTab == 1 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(1);
          }}
        >
          Trips
        </button>)}
        {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.read") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.write") ||
                  roleHandler(role, "AONE_OPS_DRIVERS.admin")) && (
        <button
          className={`filterBtn ${linehaulTab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            tabHandler(2);
          }}
        >
          Drivers
        </button>)}
      </div>
      <div>
        {linehaulTab == 1 ? (
          <Trips/>
        ) : (
          <Drivers/>
        )}
      </div>
    </div>
  );
};

export default Linehauls;
