import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import SuccessIcon from "../../../assets/img/SuccessIcon.svg";
import WarnIcon from "../../../assets/img/WarnIcon.svg";
import { useRtoStore } from "../../../store/RtoMgt/RtoStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
const RtoViewmodal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  routebyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const submitRtoRequest = useRtoStore((state) => state?.submitRtoRequest);
  const scanRtoPackage = useRtoStore((state) => state?.scanRtoPackage);
  const markPackageException = useRtoStore(
    (state) => state?.markPackageException
  );
  const getRtobyId = useRtoStore((state) => state?.getRtobyId);
  const listWarningType = useRtoStore((state) => state?.listWarningType);
  const rtoWarnList = useRtoStore((state) => state?.rtoWarnList);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const deleteRtoPackage = useRtoStore((state) => state?.deleteRtoPackage);
  const discardRtoScan = useRtoStore((state) => state?.discardRtoScan);
  const printConsignments = useAoneStore((state) => state?.printConsignments);
  const rtoPackagesList = useRtoStore((state) => state?.rtoPackagesList);
  const [scanPackage, setScanPackage] = useState("");
  const [warnType, setWarnType] = useState("");
  const [err, setErr] = useState("");
  const [warnErr, setWarnErr] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [markPackageId, setMarkPackageId] = useState("");
  const [showcourier, setShowcourier] = useState(false);
  const handleShowcourier = () => showcourier(true);
  const openException = (event, pkgid) => {
    event.preventDefault();
    setShowcourier(true);
    setMarkPackageId(pkgid);
  };
  const [showAssign, setShowAssign] = useState(false);
  const handleassignShow = (event, packge) => {
    event.preventDefault();
    setShowAssign(true);
    setSelectedPackage(packge);
  };
  const handleassignClose = () => {
    setShowAssign(false);
  };
  const handleassignCancel = () => {
    setShowAssign(false);
  };
  const handleClosecourier = () => {
    setShowcourier(false);
  };
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };
  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  useEffect(() => {
    if (rtoWarnList?.data?.data === undefined) {
      listWarningType();
    }
  }, []);
  const scanPackages = async (event) => {
    event.preventDefault();
    if (scanPackage?.length == 0) {
      setErr("Package tracking is required");
    } else if (scanPackage?.length !== 18) {
      setErr("Please scan the valid Packagetracking Id");
    } else {
      const res = await scanRtoPackage(
        rtoPackagesList?.data?.data?.id,
        scanPackage
      );
      if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
        setScanPackage("");
      } else if (res?.data?.status == "SUCCESS") {
        toast.success(`${scanPackage} scanned`);
        getRtobyId(rtoPackagesList?.data?.data?.id);
        setScanPackage("");
      }
    }
  };
  const removePackages = async (event) => {
    event.preventDefault();
    const res = await deleteRtoPackage(
      rtoPackagesList?.data?.data?.id,
      selectedPackage
    );
    if (res?.data?.status == "ERROR") {
      toast.error(res?.data?.msg);
      setScanPackage("");
      setShowAssign(false);
    } else if (res?.data?.status == "SUCCESS") {
      toast.success(`${selectedPackage} removed`);
      setShowAssign(false);
      getRtobyId(rtoPackagesList?.data?.data?.id);
      setScanPackage("");
    }
  };
  const markPkgException = async (event, selectedPackage) => {
    event.preventDefault();
    if (warnType == "") {
      setWarnErr("Warning type is required for marking as exception");
    } else {
      const payload = {
        warningType: warnType,
      };
      const res = await markPackageException(
        rtoPackagesList?.data?.data?.id,
        markPackageId,
        payload
      );
      if (res?.data?.status == "ERROR") {
        toast.error(res?.data?.msg);
      } else if (res?.data?.status == "SUCCESS") {
        toast.success(`${markPackageId} marked as exception`);
        getRtobyId(rtoPackagesList?.data?.data?.id);
        setShowcourier(false);
      }
    }
  };
  const printShipmentOrders = async () => {
    try {
      const res = await printConsignments(
        newRtoTrackingId  ? newRtoTrackingId : rtoPackagesList?.data?.data?.rtoTrackingId
      );
      // Create a Blob from the response data
      const blob = new Blob([res.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      // Open the PDF in a new window
      window.open(blobUrl, "_blank");
    } catch (error) {}
  };
  const[newRtoTrackingId,setNewRtoTrackingId] = useState("");
  const submitRtoreq = async (event) => {
    event.preventDefault();
    const res = await submitRtoRequest(rtoPackagesList?.data?.data?.id);
    if (res?.data?.status == "ERROR") {
      toast.error(res?.data?.msg);
    } else if (res?.data?.status == "SUCCESS") {
      setNewRtoTrackingId(res?.data?.data?.rtoTrackingId);
      toast.success(`RTO Request submitted`);
      setispostAdded(!ispostAdded);
    }
  };
  const discardRtoreq = async (event) => {
    event.preventDefault();
    const res = await discardRtoScan(rtoPackagesList?.data?.data?.id);
    if (res?.data?.status == "ERROR") {
      toast.error(res?.data?.msg);
    } else if (res?.data?.status == "SUCCESS") {
      setNewRtoTrackingId(res?.data?.rtoTrackingId);
      getRtobyId(rtoPackagesList?.data?.data?.id);
      setispostAdded(!ispostAdded);
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollAcl">
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-11" style={{ padding: "12px" }}>
                <div className="row mb_24">
                  <div className="col-md-2 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Consignment:</label>
                  </div>
                  <div className="col-md-5">
                    <input
                      type="text"
                      className="mainInput"
                      name="name"
                      disabled
                      value={rtoPackagesList?.data?.data?.trackingId}
                    />
                  </div>
                </div>
                {rtoPackagesList?.data?.data?.status !== "SUBMITTED" && (
                <div className="row mb_24">
                  <div className="col-md-2 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Package Id:</label>
                  </div>
                  <div className="col-md-5">
                    <input
                      type="text"
                      className="mainInput"
                      name="scanPackage"
                      value={scanPackage}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Enter" &&
                          rtoPackagesList?.data?.data?.status !== "SUBMITTED"
                        ) {
                          scanPackages(e);
                        }
                      }}
                      onChange={(e) => {
                        setScanPackage(e.target.value);
                        setErr("");
                      }}
                    />
                    {err !== "" ? <p className="requiredText">{err}</p> : null}
                  </div>
                    <div className="col-md-2">
                      <button
                        className="filterRouteBtn plus_icon"
                        title="Scan shipment to proceed"
                        onClick={(event) => {
                          scanPackages(event);
                        }}
                      >
                        +
                      </button>
                    </div>
                </div>
                  )}
                <div className="row mb_24">
                  <div className="col-md-2 fontSize14 menacingCloudsText fontWeight500 mt_8">
                    <label>Packages:</label>
                  </div>
                  <div className="col-md-10">
                    <div className="mt_15 table_wrapper mb_8 showFiveRows">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Package Id</th>
                            <th>Hub</th>
                            <th>Status</th>
                            {rtoPackagesList?.data?.data?.status ==
                              "NEW" && <th>Actions</th>}
                          </tr>
                        </thead>
                        {rtoPackagesList?.data?.data?.rtoPackageRequests?.map(
                          (listedpackages, index) => (
                            <tr>
                              <td>
                                {listedpackages?.status === "SUCCESS" && (
                                  <button className="tableBtn" type="button">
                                    <img src={SuccessIcon} />
                                  </button>
                                )}
                                {listedpackages?.status === "WARNING" && (
                                  <button type="button" className="tableBtn">
                                    <img src={WarnIcon} />
                                  </button>
                                )}
                              </td>
                              <td>{listedpackages?.packageTrackingId}</td>
                              <td>{listedpackages?.hubCode}</td>
                              <td>{listedpackages?.status}</td>
                              {rtoPackagesList?.data?.data?.status ==
                                "NEW" && (
                                <td>
                                  {listedpackages?.status === "SUCCESS" && (
                                    <button
                                      className="cancel_btnRto"
                                      onClick={(event) => {
                                        handleassignShow(
                                          event,
                                          listedpackages?.packageTrackingId
                                        );
                                      }}
                                    >
                                      Remove
                                    </button>
                                  )}
                                  {listedpackages?.status !== "SUCCESS" &&
                                    listedpackages?.status !== "WARNING" && (
                                      <button
                                        className="cancel_btnRto"
                                        onClick={(event) => {
                                          openException(
                                            event,
                                            listedpackages?.packageTrackingId
                                          );
                                        }}
                                      >
                                        Mark Exception
                                      </button>
                                    )}
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {showcourier && (
            <Modal
              show={showcourier}
              className="addnewservicearea"
              onHide={handleClosecourier}
              backdrop="static"
              centered
              size="lg"
            >
              <Modal.Header closeButton>
                <h4 className="fontSize16 blackText fontWeight500">
                  Mark Package as Exception
                </h4>
              </Modal.Header>
              <div className="modelScrollVehicleVendors">
                <Modal.Body>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Package:
                        </label>
                        <input
                          type="text"
                          className="mainInput"
                          name="markPackageId"
                          value={markPackageId}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                          Warning Type:
                        </label>
                        <SearchInput
                          id="warnType"
                          label="warnType"
                          name="warnType"
                          options={rtoWarnList?.data?.data || []}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.key}
                          handleChange={(selectedOption) => {
                            setWarnType(
                              selectedOption ? selectedOption.key : ""
                            );
                          }}
                          onMenuOpen={handleMenudescountry}
                          onMenuClose={handleMenuClosedescountry}
                          menuIsOpen={isReactdesCountry}
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Warning Type"
                          customStyles={{ control: { width: "180px" } }}
                          className="mainInput"
                        />
                        {warnErr !== "" ? (
                          <p className="requiredText">{warnErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </form>
                  <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
                    <div>
                      <button
                        className="cancel_btn"
                        onClick={() => handleClosecourier()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="blue_btn"
                        onClick={(event) => {
                          markPkgException(event);
                        }}
                      >
                        Mark Exception
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </div>
            </Modal>
          )}
          {showAssign && (
            <Modal
              className="scanConfirmpopup"
              show={showAssign}
              onHide={handleassignClose}
              centered
            >
              <Modal.Body>
                <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
                  Do you want to remove package?
                </h2>
                <>
                  <div className="displayFlex alignItemCenter justifyContent_center">
                    <ul className="emptyUnorderList mb_12">
                      <li className="fontSize14 fontWeight400 blackText">
                        -do you want to remove {selectedPackage} from RTO
                        Request?
                      </li>
                    </ul>
                  </div>
                </>
              </Modal.Body>
              <Modal.Footer style={{ flexWrap: "unset" }}>
                <div>
                  <button
                    className="deletebtn cancelPrimary"
                    onClick={() => handleassignCancel()}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    className="deletebtn confirmPrimary"
                    onClick={(event) => {
                      removePackages(event);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          {(rtoPackagesList?.data?.data?.status == "NEW" && newRtoTrackingId == "") && (
            <button
              className="blue_btn"
              type="button"
              onClick={(event) => {
                submitRtoreq(event);
              }}
            >
              Submit
            </button>
          )}
           {(rtoPackagesList?.data?.data?.status == "SUBMITTED" || (newRtoTrackingId !== "" && newRtoTrackingId !== undefined)) && (
          <button
            className="blue_btn"
            type="button"
            onClick={printShipmentOrders}
          >
            Print
          </button>)}
          {(rtoPackagesList?.data?.data?.status === "NEW" && (newRtoTrackingId == "" || newRtoTrackingId == undefined)) && (
          <button className="cancel_btn"  onClick={discardRtoreq}>
            Discard
          </button>)}
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default RtoViewmodal;
