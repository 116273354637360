import React, { useState, useEffect, useRef, useContext } from "react";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fileDownload from "js-file-download";
import { UserContext } from "../../helper/ProtectedRoute";
import downloadicon from "../../assets/img/download_icon.svg";
import importIcon from "../../assets/img/import_file.svg";
import PrintIcon from "../../assets/img/printorder.svg";
import templateIcon from "../../assets/img/template_icon.svg";
import DeletingModal from "../../components/modal/deletingModal";
import { DateRangePicker } from "rsuite";
import syncicon from "../../../src/assets/img/sync.svg";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
const SortingMachine = () => {
  const navigate = useNavigate();
  const paramsId = useParams();
  const location = useLocation();
  const [tab, setTab] = useState(true);
  const [machinescan, setMachinescan] = useState(false);
  const [configHandler, setConfigHandler] = useState(true);
  const [headerParams, setHeaderParams] = useState("");
  const [isOutscan,setisOutscan] = useState(false)
  const [isReceive,setIsReceive] = useState(false)
  const today = new Date();
  const startOfYesterday = new Date(today);
  startOfYesterday.setDate(today.getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);
  const defaultStartDate = startOfYesterday;
  const defaultEndDate = endOfToday;
  const [dateRange, setDateRange] = useState([defaultStartDate, defaultEndDate]);
  const [customerCode, setCustomerCode] = useState("");
  const [sortingconfigData, setsortingConfigData] = useState([]);
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [editHandler, setEditHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [statusHandler, setStatusHandler] = useState(false);
  const [seleczone, setselectZone] = useState([]);
  const [isAdded,setIsadded] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const prevent = useRef(false);
  const getChuteConfigs = useAoneStore((state) => state?.getChuteConfigs);
  const getChuteConfigsById = useAoneStore(
    (state) => state?.getChuteConfigsById
  );
  const getAgentConfigs = useAoneStore((state) => state?.getAgentConfigs);
  const getMachineScans = useAoneStore((state) => state?.getMachineScans);
  const getZone = useAoneStore((state) => state?.getZone);
  const exportMachineScans = useAoneStore((state) => state?.exportMachineScans);
  const getChuteByIdTemplate = useAoneStore(
    (state) => state?.getChuteByIdTemplate
  );
  const postChuteByIdTemplate = useAoneStore(
    (state) => state?.postChuteByIdTemplate
  );
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const receivemachineScan = useAoneStore((state) => state?.receiveMachineScan);
  const outScanMachineScanById = useAoneStore(
    (state) => state?.outScanMachineScan
  );
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  }
  const handleCancel = () => {
    handleClose();
  };
  const [dateRangefil, setDateRangefil] = useState({
    start: startOfYesterday.getTime(), // Convert to timestamp
    end: endOfToday.getTime(), // Convert to timestamp
  });
  const downloadChuteById = useAoneStore((state) => state?.downloadChuteById);
  const downloadAgents = useAoneStore((state) => state?.downloadAgents);
  const getSortingConfig = useAoneStore((state) => state?.getSortingConfig);
  const [name, setName] = useState("");
  const [zonecode, setZoneCode] = useState("");
  const [chuteNum, setChuteNum] = useState("");
  const [hubName, setHubname] = useState("");
  const [hubCode, setHubCode] = useState("");
  const [agentId, setAgentId] = useState("");
  const [chute, setChute] = useState("");
  const [waybillNum, setWaybillNum] = useState("");
  const [waybillArray, setWaybillArray] = useState([]);
  const [destHub, setDestHub] = useState("");
  const statuses = {
    None: "",
    "Pickup Completed": "pickup_completed",
    "In Scan at Hub": "inscan_at_hub",
    "In Transit to Hub": "intransit_to_hub",
  };
  const [currStatus, setCurrentStatus] = useState("");
  const [currConfig, setCurrentConfig] = useState("");
  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setCurrentStatus(selectedValue);
  };
  const handleConfigChange = (event) => {
    const selectedValue = event.target.value;
    setCurrentConfig(selectedValue);
  };
  const [scanStart, setScanstart] = useState("");
  const [scanEnd, setScanEnd] = useState("");
  const [statusChange, setStatusChange] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [selectAll, setSelectAll] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const [configData, setConfigData] = useState([]);
  const [agentResponse, setAgentResponse] = useState([]);
  const [scanResponse, setScanResponse] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleActions = () => {
    setShowActions(!showActions);
  };
  useEffect(() => {
    if (location.pathname.endsWith("machinescan")) {
      setMachinescan(true);
    }
  });
  const handleWaybillChange = (e) => {
    setWaybillNum(e.target.value)
    const inputText = e.target.value;
    const hasSpacesOrCommas = /\s|,/.test(inputText);

    if (hasSpacesOrCommas) {
      const waybillArray = inputText.split(/[,\s]+/).filter(Boolean);
      setWaybillArray(waybillArray);
    } else {
      setWaybillArray([inputText]);
    }
  };
  // Add a click event listener to the document to close the dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (isDropdownOpen && !event.target.closest(".jira-dropdown")) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Scantime Range Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([])
    }
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleStatusChangeForSelectedZone = async (action) => {
    try {
      if (seleczone.length > 0) {
        const selectedItems = configData.filter(
          (item) =>
            seleczone.includes(item.id) &&
            item.currentStatus === "inscan_at_hub"
        );
        const selectedItemsReceive = configData.filter(
          (item) =>
            seleczone.includes(item.id) &&
            (item.currentStatus === "pickup_completed" ||
              item.currentStatus === null)
        );
        const payload = { ids: selectedItems?.map((item) => item.id) };
        const payloadReceive = {
          ids: selectedItemsReceive?.map((item) => item.id),
        };
        // if (response.status == = "Success") {
        setStatusChange(!statusChange);
        if (action === receivemachineScan) {
          if (payloadReceive?.ids.length > 0) {
            const response = await action(payloadReceive);
            if (response.data.status === "SUCCESS") {
              setIsDropdownOpen(!isDropdownOpen);
              setselectZone([]);
              setSelectAll(false);
              toast.success("Status changed to Inscan at hub");
              setShow(false);
            } else {
              setIsDropdownOpen(!isDropdownOpen);
              setselectZone([]);
              setSelectAll(false);
              setShow(false);
            }
          } else {
            setIsDropdownOpen(!isDropdownOpen);
            setselectZone([]);
            setSelectAll(false);
            toast.error("Only status with pickup completed can be received");
            setShow(false);
          }
        } else if (action === outScanMachineScanById) {
          if (payload.ids.length > 0) {
            const response = await action(payload);
            if (response?.data?.status === "SUCCESS") {
              setIsDropdownOpen(!isDropdownOpen);
              setselectZone([]);
              setSelectAll(false);
              toast.success("Status changed to InTransit to hub");
              setShow(false);
             } else if(response?.response?.data?.status === "ERROR"){
              const errorMessage = response?.response?.data?.msg?.split(/[,!]/)[0];
              toast.error(errorMessage);
              setShow(false);
            } else {
              setShow(false);
              setIsDropdownOpen(!isDropdownOpen);
              setselectZone([]);
              setSelectAll(false);
            }
          } else {
            setIsDropdownOpen(!isDropdownOpen);
            setselectZone([]);
            setSelectAll(false);
            setShow(false);
            toast.error("Only status with inscan at hub can change to outscan");
          }
        }
        // } else {
        // toast.error("Status change failed");
        // }
      }
    } catch (error) {
      // Handle API call errors
      toast.error("An error occurred during the API call");
    }
  };
    const [isDropdownselectOpen, setIsDropdownOpenrange] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpenrange(true);
  };

  const handleMenuClose = () => {
    setIsDropdownOpenrange(false);
  };
  const BulkOutscan = () =>{
    if (seleczone?.length > 0) {
    setisOutscan(true)
    setIsReceive(false)
    setShow(true)
  }else{
    toast.error("Select scans for outscan")
  }
  }
  const BulkReceive = () =>{
    if (seleczone?.length > 0) {
    setisOutscan(false)
    setIsReceive(true)
    setShow(true)
    }else{
      toast.error("Select scans for receive")
    }
  }
  const getData = async () => {
    if (location.pathname.endsWith("config")) {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (name) {
        params += `&name=${name}`;
      }
      const data = await getChuteConfigs(params);
      setConfigData(data.data.data.content);
      setTotalCount(data.data.data.totalElements);
      setTab(true);
      setConfigHandler(true);
    } else if (location.pathname.endsWith("agent")) {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (name) {
        params += `&agentId=${name}`;
      }
      const data = await getAgentConfigs(params);
      setConfigData(data.data.data.content);
      setTotalCount(data.data.data.totalElements);
      setTab(false);
      setConfigHandler(false);
    } else if (location.pathname.endsWith("machinescan")) {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (agentId) {
        params += `&agentId=${agentId}`;
      }
      if (chute) {
        params += `&chute=${chute}`;
      }
      if (waybillNum && waybillArray?.length <= 1) {
        params += `&waybillNumber=${waybillNum}`;
      }
      if(waybillArray?.length > 1){
        params += `&waybillNumbers=${[waybillArray]}`;
      }
      if (customerCode) {
        params += `&customerCode=${customerCode}`;
      }
      if (currStatus) {
        params += `&currentStatus=${currStatus}`;
      }
      if (destHub) {
        params += `&desHubCode=${destHub}`;
      }
      if(currConfig) {
        params += `&sortingConfigId=${currConfig}`
      }
      if (
        dateRangefil.start !== null &&
        dateRangefil.end !== null &&
        !isNaN(dateRangefil.start) &&
        !isNaN(dateRangefil.end) &&
        dateRangefil.start !== dateRangefil.end
      ) {
        params += `&scanStartTime=${dateRangefil.start}&scanEndTime=${dateRangefil.end}`;
      } else if (dateRangefil.start !== null && dateRangefil.end !== null &&  !isNaN(dateRangefil.start) &&
      !isNaN(dateRangefil.end)) {
        const startOfDay = new Date(dateRangefil.start);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(dateRangefil.end);
        endOfDay.setHours(23, 59, 59, 999);

        // Check if dates are the same (today)
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (
          startOfDay.getTime() === endOfDay.getTime() &&
          startOfDay.getTime() === today.getTime()
        ) {
          // Set times to the beginning and end of the day
          startOfDay.setHours(0, 0, 0, 0);
          endOfDay.setHours(23, 59, 59, 999);
        } else {
          // Set times to the provided dates
          startOfDay.setHours(0, 0, 0, 0);
          endOfDay.setHours(23, 59, 59, 999);
        }

        params += `&scanStartTime=${startOfDay.getTime()}&scanEndTime=${endOfDay.getTime()}`;
      }
      const data = await getMachineScans(params);
      setConfigData(data.data.data.content);
      setTotalCount(data.data.data.totalElements);
    } else {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (zonecode) {
        params += `&zoneCode=${zonecode}`;
      }
      if (chuteNum) {
        params += `&chuteNumber=${chuteNum}`;
      }
      if (hubCode) {
        params += `&hubCode=${hubCode}`;
      }
      if (hubName) {
        params += `&hubName=${hubName}`;
      }
      const data = await getChuteConfigsById(paramsId.id, params);
      setConfigData(data.data.data.content);
      setTotalCount(data.data.data.totalElements);
      setTab(true);
      setConfigHandler(false);
    }
  };
  //export machinescan
  const exportMachinescan = async () => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetInHours = offsetInMinutes / 60;
    const timeZone = offsetInHours;
    let params = `page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (agentId) {
      params += `&agentId=${agentId}`;
    }
    if (chute) {
      params += `&chute=${chute}`;
    }
    if (waybillNum && waybillArray?.length <= 1) {
      params += `&waybillNumber=${waybillNum}`;
    }
    if(waybillArray?.length > 1){
      params += `&waybillNumbers=${[waybillArray]}`;
    }
    if(currConfig) {
      params += `&sortingConfigId=${currConfig}`
    }
    if (customerCode) {
      params += `&customerCode=${customerCode}`;
    }
    if (currStatus) {
      params += `&currentStatus=${currStatus}`;
    }   
    if (dateRangefil.start !== null &&  !isNaN(dateRangefil.start)) {
      params += `&scanStartTime=${dateRangefil.start}`;
    }
    if (dateRangefil.end !== null && !isNaN(dateRangefil.end)) {
      params += `&scanEndTime=${dateRangefil.end}`;
    }
    try {
      const res = await exportMachineScans(params);
      if (res.status == 200) {
        fileDownload(res.data, `machinescan.xlsx`);
      } else {
        const errorMessage = res.data.msg.split(",")[0];
        toast.error("Apply any filters before exporting scan results");
      }
    } catch (err) {
      toast.error("");
    }
  };
  const tabHandler = (value) => {
    setMachinescan(false);
    setConfigData([]);
    setSortField("-updatedAt");
    if (value) {
      setTab(true);
      setConfigHandler(true);
      setName("");
      navigate("/middlemile/sortingmachine/config");
    } else {
      setMachinescan(false);
      setTab(false);
      setName("");
      navigate("/middlemile/sortingmachine/agent");
    }
  };
  const machineTablHandler = () => {
    setTab(true);
    setConfigHandler(true);
    setName("");
    navigate("/middlemile/sortingmachine/machinescan");
  };
  // const fileName = configHandler ? "Configs" : "Configs Mapping";
  // const exportType = "csv";

  const exportToExcel = async () => {
    // let data = configData;
    // exportFromJSON({ data, fileName, exportType });
    if (!tab) {
      const res = await downloadAgents();
      fileDownload(res.data, `Agents.xlsx`);
    } else if (!configHandler) {
      const res = await downloadChuteById(paramsId.id);
      fileDownload(res.data, `${headerParams} Mapping.xlsx`);
    }
  };

  const templateDownload = () => {
    getChuteByIdTemplate().then((res) => {
      fileDownload(res.data, `mapping template.xlsx`);
    });
  };
  const deleteParamsSetter = (id, name, title) => {
    setDeleteParams({
      id: id,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            postChuteByIdTemplate(paramsId?.id, formData)
              .then((response) => {
                if (response && response.data.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response.data.data
                    ?.map((item) => `Row ${item.key}: ${item.msg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error uploading the file");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const isMacihinescanPath = window?.location?.pathname?.includes('/middlemile/sortingmachine/machinescan');
  const getSortingConfigs = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if(name){
      params += `&name=${name}`;
    }
    const data = await getSortingConfig(params);
    setsortingConfigData(data?.data?.data?.content);
  };
  useEffect(() => {
    setName("");
  }, [location]);
  useEffect(() => {
    getData();
    if(isMacihinescanPath){
    getSortingConfigs();
    }
  }, [
    newPage,
    size,
    sortField,
    // deleteHandler,
    location,
    // modalHandler,
    saveHandler,
    statusHandler,
    name,
    zonecode,
    isAdded,
    hubCode,
    hubName,
    chuteNum,
    agentId,
    waybillNum,
    waybillArray,
    currStatus,
    destHub,
    customerCode,
    statusChange,
    chute,
    currConfig,
    dateRangefil.start,
    dateRangefil.end,
  ]);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]); // For Role Based Access
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }}>
                Middle Mile
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li style={{ marginLeft: "-10px" }}>
              <span
                className="mainheadlink"
                style={{
                  fontWeight:
                    (!configHandler && tab) ||
                    !tab ||
                    !configHandler ||
                    (configHandler && machinescan)
                      ? "500"
                      : "800",
                }}
              >
                Sorting Machine
              </span>
            </li>
            {((!configHandler && tab) ||
              !tab ||
              !configHandler ||
              (configHandler && machinescan)) && (
              <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                <span>
                  <img
                    src={breadArrow}
                    className="turnBlack"
                    alt="breadcrumbarrow"
                  />
                </span>
              </li>
            )}
            {!configHandler && tab ? (
              <li style={{ marginLeft: "-10px", fontWeight: "bolder" }}>
                <span className="mainheadlink">{headerParams}</span>
              </li>
            ) : !tab || !configHandler ? (
              <li style={{ marginLeft: "-10px", fontWeight: "bolder" }}>
                <span className="mainheadlink">Agents</span>
              </li>
            ) : configHandler && machinescan ? (
              <li style={{ marginLeft: "-10px", fontWeight: "bolder" }}>
                <span className="mainheadlink">Machine Scans</span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>

      <div className="displayFlex tabletab_section mb_12">
        <button
          className={`filterBtn ${tab && !machinescan && `activeFilterBtn`}`}
          onClick={() => tabHandler(true)}
        >
          Configs
        </button>
        <button
          className={`filterBtn ${!tab && !machinescan && `activeFilterBtn`}`}
          onClick={() => tabHandler(false)}
        >
          Agents
        </button>
        <button
          className={`filterBtn ${machinescan && `activeFilterBtn`}`}
          onClick={() => {
            setTab(false);
            setMachinescan(true);
            machineTablHandler();
          }}
        >
          Machine Scans
        </button>
      </div>

      <div>
        <div
          className={
            configHandler && machinescan
              ? "displayFlex alignItemCenter justifyContent_spacebetween"
              : "displayFlex alignItemCenter justifyContent_spacebetween mb_12"
          }
        >
          {configHandler && !machinescan ? (
            <div className="displayFlex justifyContent_end">
              <label className="fontSize14 mb_12 marginR10 mt_10">
                Configuration:
              </label>
              <div className="displayFlex alignItemCenter">
                <input
                  type="text"
                  value={name}
                  className={
                    name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
                  }
                  style={
                    name?.length > 0 ? { border: "1px solid #ec171c" } : {}
                  }
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          ) : !configHandler && tab ? (
            <Row>
              <Col md={2} className="col-25-percent">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Zone Code:
                </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    value={zonecode}
                    className={
                      zonecode?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      zonecode?.length > 0
                        ? { border: "1px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setZoneCode(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={2} className="col-25-percent">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Chute Number:
                </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    value={chuteNum}
                    className={
                      chuteNum?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      chuteNum?.length > 0
                        ? { border: "1px solid #ec171c" }
                        : {}
                    }
                    onChange={(e) => setChuteNum(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={2} className="col-25-percent">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Hub Code:
                </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    value={hubCode}
                    className={
                      hubCode?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      hubCode?.length > 0 ? { border: "1px solid #ec171c" } : {}
                    }
                    onChange={(e) => setHubCode(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={2} className="col-25-percent">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Hub Name:
                </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    value={hubName}
                    className={
                      hubName?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      hubName?.length > 0 ? { border: "1px solid #ec171c" } : {}
                    }
                    onChange={(e) => setHubname(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          ) : configHandler && machinescan ? (
            <div className="mb_12">
              <Row>
                <Col md={2} className="mb-fil col-13-percent">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Agent ID:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={agentId}
                      className={
                        agentId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        agentId?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setAgentId(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={2} className="mb-fil col-13-percent">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Chute:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={chute}
                      className={
                        chute?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        chute?.length > 0 ? { border: "1px solid #ec171c" } : {}
                      }
                      onChange={(e) => setChute(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={2} className="mb-fil col-18-percent">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Customer Code:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={customerCode}
                      className={
                        customerCode?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        customerCode?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setCustomerCode(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={2} className="mb-fil col-18-percent mright_50">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Destination Hub:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={destHub}
                      className={
                        destHub?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        destHub?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setDestHub(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={2} className="col-18-percent">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Current Status:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <select
                      className="mainInput"
                      value={currStatus} // Bind the selected value to the state
                      onChange={handleStatusChange} // Handle the change event
                      style={
                        currStatus?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                    >
                      {Object.entries(statuses)?.map(([label, value]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md={2} className="col-18-percent">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Sorting Config:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <select
                      className="mainInput"
                      value={currConfig} // Bind the selected value to the state
                      onChange={handleConfigChange} // Handle the change event
                      style={
                        currConfig?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                    >
                        <option value="">None</option>
                      {sortingconfigData?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md={2} className={waybillNum?.length < 22 ? "mb-fil col-18-percent" : waybillNum?.length < 44 ? "mb-fil col-28-percent-ms" : waybillNum?.length < 67 ? "mb-fil col-40-percent-ms" : waybillNum?.length < 87 ? "mb-fil col-50-percent-ms" : "mb-fil col-100-percent-ms"}>
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Waybill Number:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <input
                      type="text"
                      value={waybillNum}
                      className={
                        waybillNum?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        waybillNum?.length > 0
                          ? { border: "1px solid #ec171c" }
                          : {}
                      }
                      onChange={handleWaybillChange}
                    />
                  </div>
                </Col>
                <Col md={2} className="col-18-percent">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Scan Time:
                  </label>
                  <div className="displayFlex alignItemCenter">
                    <div className="customDateRange">
                      <DateRangePicker
                       format="yyyy-MM-dd hh:mm aa"
                        placeholder="Select Date Range"
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        placement="auto"
                        onOpen={handleMenuOpen}
                        onClose={handleMenuClose}
                        showMeridian
                        minDate={
                          new Date(
                            new Date().setMonth(new Date().getMonth() - 3)
                          )
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="displayFlex justifyContent_end">
              <label className="fontSize14 mb_12 marginR10 mt_10">
                Agent ID:
              </label>
              <div className="displayFlex alignItemCenter">
                <input
                  type="text"
                  value={name}
                  className={
                    name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
                  }
                  style={
                    name?.length > 0 && name !== "None"
                      ? { border: "1px solid #ec171c" }
                      : {}
                  }
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className="displayFlex gap_12">
            {(!configHandler &&
              tab) &&
              ((role?.includes("AONE_ADMIN")) || (role?.includes("AONE_MM_SORTING-MACHINE.admin")) || (role?.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write")) || (role?.includes("AONE_SORTING-MACHINE_CONFIGS.admin"))) && (
                <>
                  <div>
                    <button
                      className="filterBtn"
                      onClick={() => templateDownload()}
                      title="Download Template"
                    >
                      {/* <span className='mr_8'>Template</span> */}
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                  </div>
                  <div title="Import File">
                    <label
                      className="filterBtn displayInlineFlex alignItemCenter cursorPointer"
                      htmlFor="uploadFile"
                    >
                      <span>
                        <img src={importIcon} className="h18" />
                      </span>
                      <input
                        type="file"
                        id="uploadFile"
                        onInput={(e) => handleFileChange(e)}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden
                      />
                    </label>
                  </div>
                  <div>
                <button
                  className="filterBtn"
                  title="Download As CSV"
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
              </div>
              <div>
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => setAddModalHandler(!addModalHandler)}
                    tabIndex="-1"
                  >
                    +
                  </button>
                  </div>
                </>
              )}
            {(!tab && !configHandler) && (
              <><div>
                <button
                  className="filterBtn"
                  title="Download As CSV"
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
              </div>{((role?.includes("AONE_ADMIN")) || (role?.includes("AONE_MM_SORTING-MACHINE.admin")) || (role?.includes("AONE_MM_SORTING-MACHINE_AGENT.write")) || (role?.includes("AONE_SORTING-MACHINE_AGENT.admin"))) && (<div>
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => setAddModalHandler(!addModalHandler)}
                    tabIndex="-1"
                  >
                    +
                  </button>
                </div>)}</>
            )}
            {configHandler && tab && !machinescan
              && ((role?.includes("AONE_ADMIN")) || (role?.includes("AONE_MM_SORTING-MACHINE.admin")) || (role?.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write"))|| (role?.includes("AONE_SORTING-MACHINE_CONFIGS.admin"))) && (
                  <div>
                    <button
                      className="filterBtn plus_icon"
                      title="Add"
                      onClick={() => setAddModalHandler(!addModalHandler)}
                      tabIndex="-1"
                    >
                      +
                    </button>
                  </div>
                )
              }
          </div>
        </div>
        {(!configHandler && tab) &&
              ((role?.includes("AONE_ADMIN")) || (role?.includes("AONE_MM_SORTING-MACHINE.admin")) || (role?.includes("AONE_MM_SORTING-MACHINE_CONFIGS.write")) || (role?.includes("AONE_SORTING-MACHINE_CONFIGS.admin"))) ? (
          <div className="displayFlex alignItemCenter justifyContent_end gap_16 mb_12">
            <div class="jira-dropdown">
              <button
                class="jira-dropdown-button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Bulk Actions
              </button>
              <ul
                class="jira-dropdown-content"
                style={{
                  display: isDropdownOpen ? "block" : "none",
                  borderRadius: "10px",
                }}
              >
                <li
                  onClick={() => {
                    if (seleczone?.length > 0) {
                      deleteParamsSetter(seleczone, seleczone, "Chutes");
                    }
                  }}
                >
                  <a>Delete</a>
                </li>
              </ul>
            </div>
          </div>
        ) : (configHandler && machinescan) ? (
          <div className="displayFlex alignItemCenter justifyContent_end mb_16">
            <button
              className="filterBtn plus_icon"
              title="Export Scanresults"
              onClick={exportMachinescan}
            >
              <span>
                <img src={PrintIcon} className="h18" />
              </span>
            </button>
            {(((role?.includes("AONE_ADMIN")) || (role?.includes("AONE_MM_SORTING-MACHINE.admin")) || (role?.includes("AONE_MM_SORTING-MACHINE_SCANS.write"))|| (role?.includes("AONE_SORTING-MACHINE_SCANS.admin"))) &&
            <div
              class="jira-dropdown"
              style={{ width: "115px", marginLeft: "20px" }}
            >
              <button
                className="jira-dropdown-button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Bulk Actions
              </button>

              <ul
                className="jira-dropdown-content"
                style={{
                  display: isDropdownOpen ? "block" : "none",
                  borderRadius: "10px",
                }}
              >
                <li>
                  <a
                    onClick={() => {
                      BulkReceive();
                    }}
                  >
                    Receive
                  </a>
                </li>
                <li>
                  <a onClick={() => BulkOutscan()}
                  >
                    Outscan
                  </a>
                </li>
              </ul>
            </div>
            )}
            <button
              className="filterBtn"
              title="Sync"
              style={{ marginLeft: "15px" }}
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
          </div>
        ) : null}
        {/* Delete Box Handler */}
        {deleteHandler && deleteParams?.title && (
          <DeletingModal
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            deleteParams={deleteParams}
            setDeleteParams={setDeleteParams}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            showActions={showActions}
            setShowActions={setShowActions}
            setselectZone={setselectZone}
          />
        )}
        <PaginationTable
          tableData={configData ?? []}
          tbl={
            tab
              ? configHandler && !machinescan
                ? "Configs"
                : machinescan
                ? "Machinescan"
                : "DetailedConfig"
              : "Agents"
          }
          setHeader={setHeaderParams}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          title={
            tab ? (configHandler ? "Edit Chute" : "Edit Unit") : "Edit Agent"
          }
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          statusChange={statusChange}
          setStatusChange={setStatusChange}
          size={size}
          setSize={setSize}
          totalCount={totalCount}
          setSortField={setSortField}
          sortField={sortField}
          statusHandler={statusHandler}
          setStatusHandler={setStatusHandler}
          isDropdownselectOpen={isDropdownselectOpen}
          seleczone={seleczone}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          setselectZone={setselectZone}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          showActions={showActions}
          setShowActions={setShowActions}
        />
      </div>

      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={tab ? (configHandler ? "Add Configuration" : "Add Unit") : "Add Agent"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
          isAdded={isAdded}
          setIsadded={setIsadded}
        />
      )}
       <Modal
          className="scanConfirmpopup"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight600 textAlignCenter blackText mb_8">
              Do you really want to {isOutscan ? "outscan" : "receive"} selected scans?
            </h2>
            {/* <>
              <div className="displayFlex alignItemCenter justifyContent_center">
                <ul className="emptyUnorderList mb_12">
                  {seleczone?.map((item, index) => (
                    <li
                      className="fontSize14 fontWeight400 blackText"
                    >
                      - {seleczone}
                      {index !== seleczone?.length - 1}
                    </li>
                  ))}
                </ul>
              </div>
            </> */}
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <div>
              <button
                className="deletebtn cancelPrimary"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="deletebtn confirmPrimary"
                onClick={() => {
                  isOutscan ? handleStatusChangeForSelectedZone(outScanMachineScanById) : handleStatusChangeForSelectedZone(receivemachineScan);
                }}
              >
               {isOutscan ? "Outscan" : "Receive"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default SortingMachine;
