import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../../assets/img/arrow-dropright_icon.svg";
import { useNavigate, useParams } from "react-router";
import { useDriverStore } from "../../../store/DriverMgt/driverStore";
import ShortAddressMappings from "./Mappings";
import ShortAddressHistory from "./History";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
import { Tabs, Tab } from "@mui/material";
const ShortAddress = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [editPage, setEditPage] = useState(false);
  const [tab, setTab] = useState(1); // Initial state for the main tab
  const [linehaulTab, setLinehaulTab] = useState(1); // Initial state for the button tabs
  const prevent = useRef(false);
  const tabHandler = (i) => {
    setTab(i);
    prevent.current = false;
    setEditPage(false);
    setLinehaulTab(i); // Ensure this line is correctly setting the state
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const hasRole = (role, roleList) => roleList.some(r => roleHandler(role, r));
  const roleListMappings = [
    "AONE_CONFIGS.read",
    "AONE_CONFIGS.write",
    "AONE_CONFIGS.admin",
    "AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.read",
    "AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write",
    "AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin",
  ];
  
  const roleListHistory = [
    "AONE_CONFIGS.read",
    "AONE_CONFIGS.write",
    "AONE_CONFIGS.admin",
    "AONE_CONFIGS_SHORT-ADDRESS_HISTORY.read",
    "AONE_CONFIGS_SHORT-ADDRESS_HISTORY.write",
  ];
  
  // Inside your component
  useEffect(() => {
    if (!hasRole(role, roleListMappings) && hasRole(role, roleListHistory)) {
      setTab(2);
    }
  }, [role])
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb mb_0">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/configs/shortaddress")}
              >
                Configs
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/configs/shortaddress")}
              >
                Short Addresses
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Tabs
        value={0}
        aria-label="user details and attributes tabs"
        sx={{
          marginBottom: "5px",
          "& .MuiTabs-indicator": {
            backgroundColor: "#EC171C",
            bottom: "18px", // Move the indicator line up
          },
          "& .MuiTab-root": {
            color: "gray",
            fontWeight: "normal",
            fontFamily: "Arial, sans-serif",
            textTransform: "none",
            paddingBottom: "10px", // Adjust this value to control the gap between text and indicator line
            minHeight: "24px", // Reduce the minHeight
            height: "32px", // Reduce the height
          },
          "& .Mui-selected": {
            color: "#EC171C !important",
            fontWeight: "bold",
            fontFamily: "Arial, sans-serif",
            textTransform: "none",
          },
          "& .MuiTabs-flexContainer": {
            minHeight: "24px", // Ensure the container also has reduced height
            height: "32px", // Ensure the container also has reduced height
          },
        }}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Saudi Arabia" />
      </Tabs>
      <div className="displayFlex tabletab_section mt_-15">
        {(role?.includes("AONE_CONFIGS.read") ||
          role?.includes("AONE_CONFIGS.write") ||
          role?.includes("AONE_CONFIGS.admin") ||
          role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.read") ||
          role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write") ||
          role?.includes("AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin")) && (
          <button
            className={`filterBtn ${
              linehaulTab === 1 ? "activeFilterBtn" : ""
            }`}
            onClick={() => {
              tabHandler(1);
            }}
            style={{
              marginRight: "8px",
              backgroundColor: linehaulTab === 1 ? "#EC171C" : "#f0f0f0",
              color: linehaulTab === 1 ? "#fff" : "#000",
              textTransform: "none",
              border: "none",
              padding: "8px 16px",
              cursor: "pointer",
              transition: "background-color 0.2s",
              fontSize: "14px",
            }}
          >
            Mappings
          </button>
        )}
        {(role?.includes("AONE_CONFIGS.read") ||
          role?.includes("AONE_CONFIGS.write") ||
          role?.includes("AONE_CONFIGS.admin") ||
          role?.includes("AONE_CONFIGS_SHORT-ADDRESS_HISTORY.read") ||
          role?.includes("AONE_CONFIGS_SHORT-ADDRESS_HISTORY.write")) && (
          <button
            className={`filterBtn ${
              linehaulTab === 2 ? "activeFilterBtn" : ""
            }`}
            onClick={() => {
              tabHandler(2);
            }}
            style={{
              backgroundColor: linehaulTab === 2 ? "#EC171C" : "#f0f0f0",
              color: linehaulTab === 2 ? "#fff" : "#000",
              textTransform: "none",
              border: "none",
              padding: "8px 16px",
              cursor: "pointer",
              transition: "background-color 0.2s",
              fontSize: "14px",
            }}
          >
            History
          </button>
        )}
      </div>

      <div>
        {linehaulTab == 1 ? <ShortAddressMappings /> : <ShortAddressHistory />}
      </div>
    </div>
  );
};

export default ShortAddress;
