import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import DotboldIcon from "../../../assets/img/dotsbold.svg"
import removeIcon from "../../../assets/img/cancelIcon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { roleHandler } from "../../../helper/roleHandler";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import PaginationTable from "../../../components/Tables/paginationTable";
import CusModal from "../../../components/modal/CusModal";
import TripModal from "./TripsModal";
import Dropdown from "react-bootstrap/Dropdown";
import VehicleModal from "./EditVehicleModal";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import { useTripStore } from "../../../store/TripMgt/TripStore";
import DeletingModal from "../../../components/modal/deletingModal";
import { UserContext } from "../../../helper/ProtectedRoute";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Trips = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [VehicleActive,SetVehicleActive] = useState("")
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(-1);
  const [desPage, setDesPage] = useState(-1);
  const [vehiclePage, setVehiclePage] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionHub, setSelectedOptionHub] = useState(null);
  const [selectedOptionVehicle, setSelectedOptionVehicle] = useState(null);
  const [driverbyCode, setDriverbyCode] = useState([]);
  const [isActivated, SetisActivated] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [tripData, setTripData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const getTrips = useTripStore((state) => state?.getTrips);
  const getTripsbyId = useTripStore((state) => state?.getTripsbyId);
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const getVehicles = usevehicleStore((state) => state?.getVehicles);
  const getTripStatus = useTripStore((state) => state?.getTripStatus);
  const tripsStatus = useTripStore((state) => state?.tripsStatus);
  const [loadingMore, setLoadingMore] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const [tripId,setTripId] = useState("")
  const [originHub,setOriginHub] = useState("");
  const [destinationHub,setDestinationHub] = useState("");
  const [status,setStatus] = useState("");
  const [vehicle,setVehicle] = useState("")
   const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };
  const handleMenuClosedescity = () => {
    setReactDescity(false);
  };
  const handleEdit = (code) => {
      setTitle("Edit Trip");
    setShowModal(true);
    getTriponEdit(code);
  };
  const [TripId,setTripsId] = useState("");
  const [vehicleTrip,setVehicleTrip] = useState("");
  const handleVehicleEdit = (trip,vehicle) => {
    setTitle("Edit Vehicle");
    setShowVehicleModal(true);
  setTripsId(trip);
  setVehicleTrip(vehicle);
};
  useEffect(() => {
    if (tripsStatus?.data?.data === undefined) {
      getTripStatus();
    }
  }, []);
  const gridId = "ops.lineHauls.trips";
  const [column, SetCoulmn] = useState([
    "Trip Id",
    "Origin Hub",
    "Destination Hub",
    "Vehicle",
    "Route",
    "Status",
    "Estimated Arrival Date",
    "Total Shipments count",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState(() => {
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        return parsedData.columns.split(',');
      }
    }
    // Return default columns if nothing is in sessionStorage
    return ["Created at", "Created by", "Updated at", "Updated by"];
  });
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const [tripbyCode, setTripbyCode] = useState([]);
  const getTriponEdit = async (code) => {
      const res = await getTripsbyId(code);
      setTripbyCode(res?.data?.data);
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=hubName&fields=driverName`;
    if (originHub) {
      params += `&originHubCode=${originHub}`;
    }
    if (destinationHub) {
      params += `&destinationHubCode=${destinationHub}`;
    }
    if (vehicle) {
      params += `&vehicleCode=${vehicle}`;
    }
    if (status) {
      params += `&statusCode=${status}`;
    }
    if (tripId) {
      params += `&tripId=${tripId}`;
    }
    const data = await getTrips(params);
    setTotalCount(data?.data?.data?.totalElements);
    setTripData(data?.data?.data?.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    tripId,
    originHub,
    destinationHub,
    status,
    vehicle,
    VehicleActive,
    ispostAdded,
    isActivated
  ]);
  const [prevSearch, setPrevSearch] = useState("");
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsDesGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : desPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDesPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desPage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsVehicle = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : vehiclePage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setVehiclePage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await getVehicles(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((driver) => ({
            label: driver.code,
            value: driver.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, vehiclePage, prevSearch, setPrevSearch]
  );
  const [title, setTitle] = useState("Create new trip");
  const [showModal, setShowModal] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleVehicleSuccess = () => {
    setShowVehicleModal(false);
  };
  const handleError = (error) => {
  };
  return (
    <div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Trip ID:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={tripId}
                      className={
                          tripId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                          tripId?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setTripId(e.target.value)}
                    />
                  {tripId && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setTripId("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Origin Hub:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                  <AsyncSearchInput
                  id="originHub"
                  label="originHub"
                  name="originHub"
                  value={selectedOption}
                  loadMoreOptions={loadMoreOptionsGroups}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenregion}
                  onMenuClose={handleMenuCloseregion}
                  menuIsOpen={isReactselectRegion}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    setOriginHub(
                      selectedOption ? selectedOption.value : ""
                    );
                  }}
                  customStyles={{ control: { width: "160px" } }}
                  placeholder="Origin Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Destination Hub:
                  </label>
                  <AsyncSearchInput
                  id="destinationHub"
                  label="destinationHub"
                  name="destinationHub"
                  value={selectedOptionHub}
                  loadMoreOptions={loadMoreOptionsDesGroups}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenuOpenorgcity}
                  onMenuClose={handleMenuCloseorgcity}
                  menuIsOpen={isReactoriginselectCity}
                  onChange={(selectedOptionHub) => {
                    setSelectedOptionHub(selectedOptionHub);
                    setDestinationHub(
                      selectedOptionHub ? selectedOptionHub.value : ""
                    );
                  }}
                  customStyles={{ control: { width: "160px" } }}
                  placeholder="Destination Hub"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
                </Col>
            <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                Vehicle:</label>
                <div className="displayFlex alignItemCenter marginR10">
                <AsyncSearchInput
                  id="vehicle"
                  label="vehicle"
                  name="vehicle"
                  value={selectedOptionVehicle}
                  loadMoreOptions={loadMoreOptionsVehicle}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onMenuOpen={handleMenudescity}
                  onMenuClose={handleMenuClosedescity}
                  menuIsOpen={isReactdesCity}
                  onChange={(selectedOptionVehicle) => {
                    setSelectedOptionVehicle(selectedOptionVehicle);
                    setVehicle(
                      selectedOptionVehicle ? selectedOptionVehicle.value : ""
                    );
                  }}
                  customStyles={{ control: { width: "160px" } }}
                  placeholder="Vehicle"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              </div>
            </Col>
            <Col className="ml_5">
                <label className="fontSize12 mb_12 marginR10 mt_10">
                Status:</label>
                <div className="displayFlex alignItemCenter marginR10">
                <SearchInput
                  id="status"
                  label="status"
                  name="status"
                  options={tripsStatus?.data?.data || []}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.key}
                  handleChange={(selectedOption) => {
                    setStatus(selectedOption ? selectedOption.key : "");
                  }}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  customStyles={{ control: { width: "160px" } }}
                  menuIsOpen={isReactRates}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Status"
                  className={
                    status?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </div>
            </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_OPS.write") ||
                roleHandler(role, "AONE_OPS.admin") ||
                roleHandler(role, "AONE_OPS_TRIPS.write") ||
                roleHandler(role, "AONE_OPS_TRIPS.admin")) && (
                <button
                  className="filterBtn plus_icon"
                  title="Add"
                  tabIndex="-1" 
                  onClick={() => {
                    setShowModal(true);
                    setTitle("Create new trip");
                  }}
                >
                  +
                </button>)}
                <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>
  
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
        <PaginationTable
        tbl="Trips"
        tableData={tripData}
        deleteHandler={deleteHandler}
        restrict={checkedCol}
        setDeleteHandler={setDeleteHandler}
        handleTripEdit={handleEdit}
        handleVehEdit={handleVehicleEdit}
        size={size}
        setSortField={setSortField}
        sortField={sortField}
        setSize={setSize}
        setTripsId={setTripsId}
        setVehicleTrip={setVehicleTrip}
        totalCount={totalCount}
        setNewPage={setNewPage}
        newPage={newPage}
      />
      <CusModal show={(title == "Create new trip" || title == "Edit Trip") ? showModal : showVehicleModal} onHide={() => {(title == "Create new trip" || title == "Edit Trip") ? setShowModal(false) : setShowVehicleModal(false)}} className="addVehicleCateg">
        {(title == "Create new trip" || title == "Edit Trip") ?
      <TripModal
          onSuccess={handleSuccess}
          onError={handleError}
          setShowModal={setShowModal}
          title={title}
          tripbyCode={tripbyCode}
        /> :
        <VehicleModal
          onSuccess={handleVehicleSuccess}
          onError={handleError}
          setShowModal={setShowVehicleModal}
          title={title}
          TripId={TripId}
          setVehicleTrip={setVehicleTrip}
          vehicleTrip={vehicleTrip}
        />}
      </CusModal>
    </div>
  );
};

export default Trips;
