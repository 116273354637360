import React, { useState, useEffect } from "react";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useNavigate } from "react-router";
import { useAclStore } from "../../../store/AclMgt/Aclstore";
import { useAoneStore } from "../../../store/AoneStore";
import { useParams } from "react-router";
import syncicon from "../../../../src/assets/img/sync.svg";
import downloadIcon from "../../../assets/img/download_icon.svg";
import PrintIcon from "../../../assets/img/printorder.svg";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import * as Yup from "yup";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as XLSX from "xlsx";
const Consignments = () => {
  const params = useParams();
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [trackId, setTrackId] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const queryConsignmentByAcl = useAclStore(
    (state) => state?.queryConsignmentByAcl
  );
  const exportConsignmentsforAcl = useAclStore(
    (state) => state?.exportConsignmentsforAcl
  );
  const importConsignmentsAcl = useAclStore(
    (state) => state?.importConsignmentsAcl
  );
  const AclconsignmentsTemplate = useAclStore(
    (state) => state?.AclconsignmentsTemplate
  );
  const getLabelbyAcl = useAclStore((state) => state?.getLabelbyAcl);
  const postConsignmentsAcl = useAclStore(
    (state) => state?.postConsignmentsAcl
  );
  const transferConsignmentAcl = useAclStore(
    (state) => state?.transferConsignmentAcl
  );
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const originHub = sessionStorage.getItem("originHub");
  const destinationHub = sessionStorage.getItem("destinationHub");
  const destinationZone = sessionStorage.getItem("destinationZone");
  const shouldShowZone = destinationZone && destinationZone !== 'null' && destinationZone.trim() !== '';
  const handleCancel = () => {
    handleClose();
  };
  const addAclConsignment = () => {
    setShow(true);
  };
  const handleShow = () => setShow(true);
  const [showTransfer, setShowTransfer] = useState(false);

  const handleTransferClose = () => {
    setShowTransfer(false);
  };
  const handleTransferCancel = () => {
    handleClose();
  };
  const addAclTransfer = () => {
    setShowTransfer(true);
  };
  const handleShowTransfer = (trackId) => {
    setShowTransfer(true);
    moveAclConsignments.setFieldValue("trackingId", trackId);
  };
  const printAcl = async (id) => {
    try {
      const res = await getLabelbyAcl(id);
      // Create a Blob from the response data
      const blob = new Blob([res.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      // Open the PDF in a new window
      window.open(blobUrl, "_blank");
    } catch (error) {}
  };
  const moveAclConsignments = useFormik({
    initialValues: {
      trackingId: "",
      targetACLNumber: "",
    },
    validationSchema: Yup.object({
      targetACLNumber: Yup.string().required("Acl Number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await transferConsignmentAcl(params?.id, values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Consignment moved to ${values?.targetACLNumber}`);
          getData();
          setShowTransfer(false);
          resetForm();
          setTrackId("");
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShowTransfer(false);
          setTrackId("");
          resetForm();
          toast.error(errorMessages);
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg?.split("!")[0];
          toast.error(errorMessage);
          setShowTransfer(false);
          setTrackId("");
          resetForm();
        } else {
          const errorMessage = response?.data?.msg?.split(",")[0];
          toast.error(errorMessage);
          setShowTransfer(false);
          setTrackId("");
          resetForm();
        }
      });
    },
  });
  // Create an audio element
  const playErrorSound = () => {
    const audio = new Audio("/error-sound.mp3");
    audio.play().catch((error) => console.error("Error playing audio:", error));
  };
  const playSuccessSound = () => {
    const audio = new Audio("/success-sound.mp3");
    audio
      .play()
      .catch((error) => console.error("Success playing audio:", error));
  };
  const addAclConsignments = useFormik({
    initialValues: {
      trackingId: trackId,
    },
    validationSchema: Yup.object({
      trackingId: Yup.string().required("Tracking Id is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postConsignmentsAcl(params?.id, values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          playSuccessSound();
          toast.success(`Consignments added in ACL`);
          getData();
          setShow(false);
          resetForm();
          setTrackId("");
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShow(false);
          setTrackId("");
          resetForm();
          toast.error(errorMessages);
          playErrorSound();
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          playErrorSound();
          setShow(false);
          setTrackId("");
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          playErrorSound();
          setShow(false);
          setTrackId("");
          resetForm();
        } else {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          playErrorSound();
          setShow(false);
          setTrackId("");
          resetForm();
        }
      });
    },
  });
  const [consignmentBulk, setConsignmentBulk] = useState("");
  const [consignmentData, setConsignmentData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [isDelete, setIsDelete] = useState("");
  const getData = async () => {
    let para = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (trackingId) {
      para += `&trackingId=${trackingId}`;
    }
    const data = await queryConsignmentByAcl(params?.id, para);
    setTotalCount(data?.data?.data?.data?.totalElements);
    setConsignmentBulk(data?.data?.data);
    setConsignmentData(data?.data?.data?.data?.content);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [newPage, sortBy, size, sortField, trackingId, ispostAdded]);
  const exporttoExcel = async () => {
    try {
      const res = await exportConsignmentsforAcl(params?.id);
      if (res.status == 200) {
        fileDownload(res.data, `Consignments.xlsx`);
      } else if (res?.response?.status === 500) {
        toast.error("Internal Server Error");
      } else {
        toast.error(res.response.statusText);
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            importConsignmentsAcl(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  setispostAdded(!ispostAdded);
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (
                  response?.data?.errorCode !== "" &&
                  response?.data?.errorCode !== undefined
                ) {
                  const errorMessage = response?.data?.msg;
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  fileInput.value = "";
                } else if (response?.data?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(errorMessages);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessage = response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (
                  response?.data?.data?.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  const downloadTemplate = async () => {
    try {
      const res = await AclconsignmentsTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Consignments Template.xlsx`);
      }
    } catch (err) {}
  };
  return (
    <div>
      <div>
        <div className="displayFlex alignItemCenter">
          <input
            type="text"
            value={trackId}
            className={"mainInput mr_15"}
            style={
              trackId?.length > 0
                ? { border: "2px solid #ec171c", width: "auto" }
                : { width: "auto" }
            }
            onChange={(e) => {
              setTrackId(e.target.value);
              addAclConsignments.setFieldValue("trackingId", e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addAclConsignments.handleSubmit();
              }
            }}
          />
          <button
            className="activeFilterBtn"
            onClick={addAclConsignments.handleSubmit}
          >
            Add
          </button>
          <div className="totals">
            <span className="total-count">Total: {consignmentBulk?.total}</span>
            <span className="total-success">
              Total success: {consignmentBulk?.totalSuccess}
            </span>
            <span className="total-failure">
              Total failure: {consignmentBulk?.totalFail}
            </span>
            {!shouldShowZone ? (
            <><span className="total-courier">Origin Hub: {originHub}</span><span className="total-assign">
                Destination Hub: {destinationHub}
              </span></>)
              :
              (<span className="total-courier">
                Destination Zone: {destinationZone}
              </span>
            )}
          </div>
          <div className="ml_auto">
            <label className="fontSize14 mb_12 mr_10p">Tracking Id:</label>
            <input
              type="text"
              value={trackingId}
              className={
                trackingId?.length > 0
                  ? "mainInput"
                  : "mainInput withSearchIcon"
              }
              style={
                trackingId?.length > 0
                  ? { border: "2px solid #ec171c", width: "auto" }
                  : { width: "auto" }
              }
              onChange={(e) => setTrackingId(e.target.value)}
            />
          </div>
        </div>
        <div className="displayFlex alignItemCenter justifyContent_end mt_12">
          <div className="displayFlex alignItemCenter justifyContent_end mb_16">
            <button
              className="filterBtn plus_icon mr_10p"
              title="Print ACL"
              onClick={() => printAcl(params?.id)}
            >
              <span>
                <img src={PrintIcon} className="h18 turnBlack" />
              </span>
            </button>
            <button
              className="filterBtn plus_icon mr_10p"
              title="Package Template"
              onClick={downloadTemplate}
            >
              <span>
                <img src={templateIcon} className="h18" />
              </span>
            </button>
            <div title="Import Package">
              <label className="filterBtn plus_icon" htmlFor="uploadFile">
                <span>
                  <img src={importIcon} className="h18" />
                </span>
                <input
                  type="file"
                  id="uploadFile"
                  onInput={(e) => handleFileChange(e)}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  hidden
                />
              </label>
            </div>
            <button
              className="filterBtn plus_icon mleft_10"
              title="Export"
              onClick={() => exporttoExcel()}
            >
              <span>
                <img src={downloadIcon} className="h18" />
              </span>
            </button>
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
          </div>
        </div>
        <PaginationTable
          tableData={consignmentData}
          tbl={"AclConsignments"}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          handleShowTransfer={handleShowTransfer}
          size={size}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setSize={setSize}
          totalCount={totalCount}
          setSortField={setSortField}
          sortField={sortField}
          statusHandler={statusHandler}
          setStatusHandler={setStatusHandler}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
        <Modal
          show={showTransfer}
          onHide={handleTransferClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Move Consignments
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Package Tracking Id
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <input
                      type="text"
                      value={moveAclConsignments.values.trackingId}
                      className={"mainInput mr_15"}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Acl Number
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <input
                      type="text"
                      className={"mainInput mr_15"}
                      onChange={(e) => {
                        moveAclConsignments.setFieldValue(
                          "targetACLNumber",
                          e.target.value
                        );
                      }}
                    />
                    {moveAclConsignments.touched.targetACLNumber &&
                    moveAclConsignments.errors.targetACLNumber ? (
                      <p className="requiredText">
                        {moveAclConsignments.errors.targetACLNumber}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_60">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={moveAclConsignments.handleSubmit}
                >
                  Move
                </button>
              </div>
              <div>
                <button
                  className="cancel_btn"
                  onClick={() => handleTransferClose()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
      </div>
    </div>
  );
};

export default Consignments;
