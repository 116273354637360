import React, { useEffect, useState, useContext } from "react";
import dotIcon from "../../../assets/img/dotsbold.svg";
import removeIcon from "../../../../src/assets/img/cancelIcon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { UserContext } from "../../../helper/ProtectedRoute";
const Countries = ({ edit }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const postCountries = useAoneStore((state) => state.postCountries);
  const getCountryById = useAoneStore((state) => state.getCountryById);
  const updateCountryById = useAoneStore((state) => state.updateCountryById);
  const getCurrency = useAoneStore((state) => state?.getCurrency);
  const [minibar, setMinibar] = useState(0);
  const [data, setData] = useState({});
  const currencyData = useAoneStore((state) => state?.currencyData);
  const [alaises, setAlaises] = useState([]);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [locales, setLocales] = useState({});

  const setAllDefault = () => {
    setAlaises([]);
    setMandatoryFields([]);
    setLocales({});
    edit(false);
    navigate("/configs/addresses");
  };
  const country = useFormik({
    initialValues: {
      name: params?.id ? data?.name : "",
      isoCode3: params?.id ? data?.isoCode3 : "",
      isoNumeric: params?.id ? data?.isoNumeric : "",
      phoneCodePrefix: params?.id ? data?.phoneCodePrefix : "",
      phoneNumberRegex: params?.id ? data?.phoneNumberRegex : "",
      isoCode2: params?.id ? data?.isoCode2 : "",
      currencyCode: params?.id ? data?.currency?.code : "",
      // postalCode: params?.id ? data?.postalCode : "",
      timezone: params?.id ? data?.timezone : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Name is Required")
        .matches(/\S/, "Name should not contain only spaces"),
      isoCode2: Yup.string()
        .trim()
        .length(2, "Iso Code 2 must be 2 characters")
        .matches(
          /^[^\d\s]+$/,
          "Iso Code 2 should not contain numbers or spaces"
        )
        .required("Iso Code 2 is Required"),
      isoCode3: Yup.string()
        .trim()
        .length(3, "Iso Code 3 must be 3 characters")
        .matches(
          /^[^\d\s]+$/,
          "Iso Code 3 should not contain numbers or spaces"
        )
        .required("Iso Code 3 is Required"),
      phoneCodePrefix: Yup.string()
        .matches(
          /^(00|\+)[0-9]+$/,
          "Phone code prefix must start with '+' or '00' followed by numbers"
        )
        .required("Phone Code is required"),
      isoNumeric: Yup.string()
        .matches(/^[0-9]*$/, "Iso Numeric can only contain numbers")
        .required("Iso Numeric is Required"),
      currencyCode: Yup.string()
        .trim()
        .required("Currency is Required")
        .matches(/\S/, "Currency should not contain only spaces"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values["mandatoryFields"] = mandatoryFields;
      values["aliases"] = alaises;
      values["locales"] = locales;
      if (params?.id) {
        values["id"] = data.id;
        await updateCountryById(params?.id, values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Country Updated");
            setAllDefault();
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else if (response.response.data.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response.data.errors.length > 0) {
            const errorMessages = response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postCountries(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Country Created");
            setAllDefault();
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else if (response.data.errors.length > 0) {
            const errorMessages = response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response.response.data.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  const languages = [
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "en", label: "English" },
    { key: "tr", label: "Turkish" },
  ];
  const [resetKey, setResetKey] = useState(0);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const minibarhandler = (i) => {
    setFirstValue("");
    setSecondValue("");
    if (i == minibar) {
      setMinibar(0);
    } else {
      setMinibar(i);
    }
  };

  const handleObjectDelete = (key) => {
    let newObject = { ...locales };
    delete newObject[key];
    setLocales(newObject);
  };
  const handleObjectAdd = () => {
    if (firstValue.trim() === "" || secondValue.trim() === "") {
      toast.error("Cannot add empty value for locales");
    } else {
      setResetKey((prevKey) => prevKey + 1);
      let newObject = { ...locales };
      newObject[firstValue] = secondValue;
      setLocales(newObject);
      setFirstValue("");
      setSecondValue("");
    }
  };
  const handleArrayDelete = (i) => {
    if (minibar == 3) {
      let newArray = [...alaises];
      newArray.splice(i, 1);
      setAlaises(newArray);
    } else {
      let newArray = [...mandatoryFields];
      newArray.splice(i, 1);
      setMandatoryFields(newArray);
    }
  };

  const handleArrayAdd = () => {
    if (firstValue.trim() === "") {
      toast.error(
        minibar === 3
          ? "Cannot add empty value in Alaises"
          : "Cannot add empty fields"
      );
    } else {
      if (minibar == 3) {
        let newArray = [...alaises];
        newArray.push(firstValue);
        setAlaises(newArray);
        setFirstValue("");
      } else {
        let newArray = [...mandatoryFields];
        newArray.push(firstValue);
        setMandatoryFields(newArray);
        setFirstValue("");
      }
    }
  };
  const getData = async () => {
    const data = await getCountryById(params.id);
    setData(data.data.data);
    setAlaises(data.data.data.aliases ?? []);
    setMandatoryFields(data.data.data.mandatoryFields ?? []);
    setLocales(data.data.data.locales ?? {});
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      currencyData?.data?.data?.content?.length < 1 ||
      currencyData?.data?.data?.content === undefined
    ) {
      getCurrency(paramslink);
    }
  }, []);
  useEffect(() => {
    params?.id && getData();
  }, []);
  return (
    <div className="row">
      <div className="col-md-6" style={{ padding: "12px" }}>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Name:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Name"
              className="mainInput"
              name="name"
              onChange={(e) => country.setFieldValue("name", e.target.value)}
              onBlur={country.handleBlur}
              value={country.values.name}
            />
            {country.touched.name && country.errors.name ? (
              <p className="requiredText">{country.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Locales:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(1)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              ISO Code 2:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Code 2"
              className="mainInput"
              name="isoCode2"
              onChange={(e) =>
                country.setFieldValue("isoCode2", e.target.value)
              }
              onBlur={country.handleBlur}
              value={country.values.isoCode2}
            />
            {country.touched.isoCode2 && country.errors.isoCode2 ? (
              <p className="requiredText">{country.errors.isoCode2}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              ISO Code 3:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Code 3"
              className="mainInput"
              name="isoCode3"
              onChange={(e) =>
                country.setFieldValue("isoCode3", e.target.value)
              }
              onBlur={country.handleBlur}
              value={country.values.isoCode3}
              disabled={params?.id ? true : false}
            />
            {country.touched.isoCode3 && country.errors.isoCode3 ? (
              <p className="requiredText">{country.errors.isoCode3}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Mandatory Fields:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(2)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              ISO Numeric:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Numeric"
              className="mainInput"
              name="isoNumeric"
              onChange={(e) =>
                country.setFieldValue("isoNumeric", e.target.value)
              }
              onBlur={country.handleBlur}
              value={country.values.isoNumeric}
            />
            {country.touched.isoNumeric && country.errors.isoNumeric ? (
              <p className="requiredText">{country.errors.isoNumeric}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Alaises:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(3)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Phone Code Prefix:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Phone Code"
              className="mainInput"
              name="phoneCodePrefix"
              onChange={(e) =>
                country.setFieldValue("phoneCodePrefix", e.target.value)
              }
              onBlur={country.handleBlur}
              value={country.values.phoneCodePrefix}
            />
            {country.touched.phoneCodePrefix &&
            country.errors.phoneCodePrefix ? (
              <p className="requiredText">{country.errors.phoneCodePrefix}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Phone Regex:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              className="mainInput"
              name="phoneNumberRegex"
              onChange={(e) =>
                country.setFieldValue("phoneNumberRegex", e.target.value)
              }
              disabled={true}
              onBlur={country.handleBlur}
              value={country.values.phoneNumberRegex}
            />
          </div>
        </div>
        {/* <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Postal Code:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Postal Code"
              className="mainInput"
              name="postalCode"
              onChange={(e) =>
                country.setFieldValue("postalCode", e.target.value)
              }
              onBlur={country.handleBlur}
              value={country.values.postalCode}
            />
          </div>
        </div> */}
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Currency:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="currencyCode"
              label="currencyCode"
              name="currencyCode"
              options={currencyData?.data?.data?.content || []}
              getOptionLabel={(city) => city.name}
              getOptionValue={(city) => city.code}
              value={
                country.values.currencyCode
                  ? currencyData?.data?.data?.content?.find(
                      (currency) =>
                        currency.code === country.values.currencyCode
                    )
                  : null
              }
              handleChange={async (selectedOption) => {
                // Update form field when an option is selected
                country.setFieldValue(
                  "currencyCode",
                  selectedOption ? selectedOption.code : ""
                );
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Currency"
            />
            {country.touched.currencyCode && country.errors.currencyCode ? (
              <p className="requiredText">{country.errors.currencyCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Time Zone:</label>
          </div>
          <div className="col-md-2">
            <input
              type="text"
              className="mainInput"
              name="timezone"
              value={country.values.timezone}
              disabled={true}
            />
          </div>
        </div>
        {(role?.includes("AONE_CONFIGS.write") ||
          role?.includes("AONE_CONFIGS.admin") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
          <div className="row mb_24">
            <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
              <button className="blue_btn" onClick={country.handleSubmit}>
                Save
              </button>
              <button className="cancel_btn" onClick={() => setAllDefault()}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6" style={{ padding: "12px" }}>
        {minibar == 1 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Locales</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                <SearchInput
                  key={resetKey}
                  id="firstValue"
                  label="firstValue"
                  name="firstValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                <input
                  type="text"
                  placeholder="Value"
                  style={{ marginLeft: "15px" }}
                  value={secondValue || ""}
                  onChange={(e) => setSecondValue(e.target.value)}
                  className="mainInput"
                />
              </div>
              <button className="filterBtn" onClick={() => handleObjectAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Languages</th>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                            <td>FR</td>
                            <td>AUBA AUBA</td>
                            <td><button className='cancel_btn'>Remove</button></td>
                        </tr> */}
                  {Object.keys(locales).map((value, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{value}</td>
                        <td>{locales[value]}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleObjectDelete(value)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : minibar == 2 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Mandatory Fields</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <input
                type="text"
                placeholder="Field"
                style={{ width: "50%" }}
                value={firstValue}
                onChange={(e) => setFirstValue(e.target.value)}
                className="mainInput"
              />
              <button className="filterBtn" onClick={() => handleArrayAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Fields</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mandatoryFields.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleArrayDelete(e)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : minibar == 3 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Alaises</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <input
                type="text"
                placeholder="Values"
                value={firstValue}
                style={{ width: "50%" }}
                onChange={(e) => setFirstValue(e.target.value)}
                className="mainInput"
              />
              <button className="filterBtn" onClick={() => handleArrayAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <td>ABC</td>
                            <td><button className='cancel_btn'>Remove</button></td> */}
                  {alaises.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleArrayDelete(e)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Countries;
