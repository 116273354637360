import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
function AsyncSearchInput({
  loadMoreOptions,
  shouldLoadMore,
  key,
  id,
  options,
  name,
  label,
  value,
  getOptionLabel,
  getOptionValue,
  customStyles,
  onChange,
  onMenuOpen,
  onMenuClose,
  menuIsOpen,
  menuStyle,
  menuPosition,
  menuPlacement,
  placeholder,
  tabIndex
}) {
  const combinedStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      fontSize: "12px",
      ...(customStyles && customStyles.control),
    }),
  };
  return (
    <AsyncPaginate
      label={label}
      key={key}
      id={id}
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      menuStyle={menuStyle}
      tabIndex={tabIndex} 
      loadOptions={(search) => loadMoreOptions(search)}
      shouldLoadMore={(scrollHeight, clientHeight, scrollTop) =>
        shouldLoadMore(scrollHeight, clientHeight, scrollTop)
      }
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#fff2f2",
          primary: "#ec171c",
          primary50: "#FCAEAE",
          neutral20: "#7a869a",
        },
      })}
      isSearchable={true}
      isClearable={true}
      placeholder={placeholder}
      styles={{
        ...combinedStyles,
      }}
    />
  );
}

export default AsyncSearchInput;
