import React, { useState, useEffect, useContext } from "react";
import { useAoneStore } from "../../../store/AoneStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import rightIcon from "../../../assets/img/rightIcon.svg";
import leftIcon from "../../../assets/img/leftIcon.svg";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import { UserContext } from "../../../helper/ProtectedRoute";
import { useDlFlowStore } from "../../../store/DeliveryFlowMgt/DeliveryFlowStore";
const DeliveryFlowAdd = ({ setTab }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const productsData = useAoneStore((state) => state?.productsData);
  const [newPage, setNewPage] = useState(0);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const filterProducts = useAoneStore((state) => state?.filterProducts);
  const getCustomer = useAoneStore((state) => state?.getCustomer);
  const getCustomerbyId = useAoneStore((state) => state?.getCustomerbyId);
  const postDeliveryFlow = useDlFlowStore((state) => state?.postDeliveryFlow);
  const getDeliveryflowbyId = useDlFlowStore(
    (state) => state?.getDeliveryflowbyId
  );
  const editDeliveryflowbyId = useDlFlowStore(
    (state) => state?.editDeliveryflowbyId
  );
  const [data, setData] = useState([]);
  const handleCancelClick = () => {
    navigate("/ops/deliveryflow");
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (countriesData?.data?.data?.content === undefined) {
      getCountries(paramslink);
    }
    if (productsData?.data?.data?.content === undefined) {
      filterProducts(paramslink);
    }
  }, []);
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [isHide, isNotHide] = useState(false);
  //save account
  const addDlFlow = useFormik({
    initialValues: {
      productCode: "",
      countryCode: "",
      requireOTP:  "",
      requireProof: "",
      requireReceiver: "",
      requireSignature: "",
      customers: [],
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (params.id) {
        await editDeliveryflowbyId(params?.id, values).then((response) => {
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (
            response?.data?.status == "ERROR" &&
            response?.data?.errors?.length < 1
          ) {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.data?.errors?.length >= 1) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else if (response?.response?.data?.errors?.length >= 1) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else {
            toast.success("Delivery flow Updated");
            navigate("/ops/deliveryflow");
            resetForm();
          }
        });
      } else {
        await postDeliveryFlow(values).then((response) => {
          if (response?.data?.status == "ERROR") {
           const errorMessage = response?.data?.msg;
           toast.error(errorMessage);
          } else if (
            response?.data?.status == "ERROR" &&
            response?.data?.errors?.length < 1
          ) {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.data?.errors?.length >= 1) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else if (response?.response?.data?.errors?.length >= 1) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else {
            toast.success("Delivery flow Added");
            navigate("/ops/deliveryflow");
            resetForm();
          }
        });
      }
    },
  });
  const [customerErr,setCustomerErr] = useState("");
  const handleSaveDlflow = (initialValues) => {
    addDlFlow?.handleSubmit();
  };
  //customers add
  const [cusSize, setCusSize] = useState(10000);
  const [name, setName] = useState("");
  const customerData = useAoneStore((state) => state?.customerData);
  const [isAddedClick, setIsaddedClick] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectRemovingCustomers, setSelectRemovingCustomers] = useState([]);
  const [addedCustomers, setAddedCustomers] = useState([]);
  const [isaddAll, setIsaddall] = useState(false);
  const [addedName, setAddedName] = useState("");
  const handleCustomerClick = (customer) => {
    setIsaddedClick(true);
    setSelectedCustomers((prevSelected) => {
      const isCustomerSelected = prevSelected.some(
        (selectedCustomer) =>
          selectedCustomer.accountNumber === customer.accountNumber
      );

      if (isCustomerSelected) {
        // If already selected, remove it
        return prevSelected.filter(
          (selectedCustomer) =>
            selectedCustomer.accountNumber !== customer.accountNumber
        );
      } else {
        // If not selected, add it
        return [...prevSelected, customer];
      }
    });
  };
  const handleAdd = () => {
    setIsaddall(false);
    // Combine previously selected customers with newly selected ones
    const updatedCustomers = [...addedCustomers, ...selectedCustomers];
    // Check if each newly selected customer is not already in the addedCustomers
    const uniqueSelectedCustomers = selectedCustomers.filter(
      (customer) =>
        !addedCustomers.some(
          (addedCustomer) =>
            addedCustomer.accountNumber === customer.accountNumber
        )
    );
    const allUpdatedCustomers = [...addedCustomers, ...uniqueSelectedCustomers];
    const addedCustomerNames = allUpdatedCustomers.map(
      (customer) => customer.name
    );
    const addedCustomerAccount = allUpdatedCustomers.map(
      (customer) => customer.accountNumber
    );
      addDlFlow.setValues({
        ...addDlFlow.values,
        customers: addedCustomerAccount,
      });
      setAddedCustomers(allUpdatedCustomers);
    // Clear the selected customers
    setSelectedCustomers([]);
  };
  const handleCusSelection = (customer) => {
    setIsaddedClick(false);
    // Check if the customer is already selected
    const isCustomerSelected = selectedCustomers.some(
      (selectedCustomer) =>
        selectedCustomer.accountNumber === customer.accountNumber
    );

    // If not selected, add to the selectedCustomers array; otherwise, remove it
    setSelectedCustomers((prevSelectedCustomers) =>
      isCustomerSelected
        ? prevSelectedCustomers.filter(
            (selectedCustomer) =>
              selectedCustomer.accountNumber !== customer.accountNumber
          )
        : [...prevSelectedCustomers, customer]
    );
  };
  const handleRemove = () => {
    // Remove selected customers from addedCustomers
    const updatedCustomers = addedCustomers.filter(
      (customer) =>
        !selectedCustomers.some(
          (selectedCustomer) =>
            selectedCustomer.accountNumber === customer.accountNumber
        )
    );
    // Update the state
    setAddedCustomers(updatedCustomers);
    setSelectedCustomers([]); // Clear selected customers
  };
  useEffect(() => {
    if (params?.id !== undefined) {
      getData();
      isNotHide(true);
    }
  }, [params?.id]);
  const getData = async () => {
    const response = await getDeliveryflowbyId(params.id);
    setData(response?.data?.data);
    addDlFlow.setFieldValue("productCode",response?.data?.data?.productCode);
    addDlFlow.setFieldValue("countryCode",response?.data?.data?.countryCode);
    const paramsLink = `?page=${newPage}&size=${cusSize}&country=${response?.data?.data?.countryCode}`;
    getCustomer(paramsLink);
    addDlFlow.setFieldValue("requireOTP",response?.data?.data?.requireOTP);
    addDlFlow.setFieldValue("requireProof",response?.data?.data?.requireProof);
    addDlFlow.setFieldValue("requireReceiver",response?.data?.data?.requireReceiver);
    addDlFlow.setFieldValue("requireSignature",response?.data?.data?.requireSignature);
    addDlFlow.setFieldValue("customers",response?.data?.data?.customers);
    setAddedCustomers(response?.data?.data?.customers);
  };
  useEffect(() => {
    // If params?.id is not undefined, it means we're in edit mode
    if (params?.id && addedCustomers?.every(customer => typeof customer === 'string')) {
      // Fetch full customer details for each code in addedCustomers
      Promise.all(addedCustomers?.map(code => getCustomerbyId(code)))
        .then(fullCustomers => {
          const fullCustomerData = fullCustomers?.map(response => response?.data?.data);
          setAddedCustomers(fullCustomerData);
        })
        .catch(error => {
          console.error('Failed to fetch customer details:', error);
        });
    }

  }, [addDlFlow?.values?.customers]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/deliveryflow")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/ops/deliveryflow");
                }}
              >
                Delivery Flow
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="borderFrame mb_16">
          <div className="borderFrameTitle">New Delivery Flow</div>
          <div className="row mt_-30px">
            <div className="col-md-2" style={{ padding: "12px" }}>
              <div className="row mb_22">
                <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                  Country:
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                </label>
                <div className="col-md-8">
                  <SearchInput
                    id="countryCode"
                    label="countryCode"
                    name="countryCode"
                    options={countriesData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.isoCode3}
                    isClearable={true}
                    handleChange={async (selectedOption) => {
                      addDlFlow.setFieldValue(
                        "countryCode",
                        selectedOption ? selectedOption.isoCode3 : ""
                      );
                      const params = `?page=${newPage}&size=${cusSize}&country=${
                        selectedOption ? selectedOption.isoCode3 : ""
                      }`;
                      getCustomer(params);
                    }}
                    value={
                      addDlFlow?.values?.countryCode
                        ? countriesData?.data?.data?.content?.find(
                            (country) =>
                              country.isoCode3 ===
                              addDlFlow?.values?.countryCode
                          )
                        : null
                    }
                    customStyles={{ control: { width: "195px" } }}
                    isSearchable={true}
                    placeholder="Country"
                    className="mainInput"
                    style={{ whiteSpace: "nowrap" }}
                  />
                  {addDlFlow.touched.countryCode && addDlFlow.errors.countryCode ? (
                    <p className="requiredText">{addDlFlow.errors.countryCode}</p>
                  ) : null}
                </div>
              </div>
              <div className="row mb_22">
              <div className="smallCheckbox">
                    <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                    <input
                      type="checkbox"
                      id="Require OTP"
                      style={{ marginLeft: "5px" }}
                      checked={addDlFlow.values.requireOTP}
                      onChange={() => {
                        addDlFlow.setFieldValue(
                          "requireOTP",
                          !addDlFlow.values.requireOTP
                        );
                        if (!addDlFlow.values.requireOTP) {
                          addDlFlow.setFieldValue(
                            "requireOTP",
                            true
                          );
                        }
                      }}
                    />
                    <label
                      className="margintop7"
                      htmlFor="Require OTP"
                    >
                      Require OTP
                    </label>
                  </div>
              </div>
              <div className="row mb_22">
              <div className="smallCheckbox">
                    <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                    <input
                      type="checkbox"
                      id="Require Proof of Delivery"
                      style={{ marginLeft: "5px" }}
                      checked={addDlFlow.values.requireProof}
                      onChange={() => {
                        addDlFlow.setFieldValue(
                          "requireProof",
                          !addDlFlow.values.requireProof
                        );
                        if (!addDlFlow.values.requireProof) {
                          addDlFlow.setFieldValue(
                            "requireProof",
                            true
                          );
                        }
                      }}
                    />
                    <label
                      className="margintop7"
                      htmlFor="Require Proof of Delivery"
                    >
                      Require Proof of Delivery
                    </label>
                  </div>
              </div>
              <div className="row mb_22">
              <div className="smallCheckbox">
                    <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                    <input
                      type="checkbox"
                      id="Require Receiver Id/Name"
                      style={{ marginLeft: "5px" }}
                      checked={addDlFlow.values.requireReceiver}
                      onChange={() => {
                        addDlFlow.setFieldValue(
                          "requireReceiver",
                          !addDlFlow.values.requireReceiver
                        );
                        if (!addDlFlow.values.requireReceiver) {
                          addDlFlow.setFieldValue(
                            "requireReceiver",
                            true
                          );
                        }
                      }}
                    />
                    <label
                      className="margintop7"
                      htmlFor="Require Receiver Id/Name"
                    >
                      Require Receiver Id/Name
                    </label>
                  </div>
              </div>
              <div className="row mb_22">
              <div className="smallCheckbox">
                    <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                    <input
                      type="checkbox"
                      id="Require Signature"
                      style={{ marginLeft: "5px" }}
                      checked={addDlFlow.values.requireSignature}
                      onChange={() => {
                        addDlFlow.setFieldValue(
                          "requireSignature",
                          !addDlFlow.values.requireSignature
                        );
                        if (!addDlFlow.values.requireSignature) {
                          addDlFlow.setFieldValue(
                            "requireSignature",
                            true
                          );
                        }
                      }}
                    />
                    <label
                      className="margintop7"
                      htmlFor="Require Signature"
                    >
                      Require Signature
                    </label>
                  </div>
              </div>
            </div>
            <div className="col-md-2" style={{ padding: "12px" }}>
              <div className="row mb_22">
                <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                  Product:
                  <span className="bloodDonortext fontWeight600 fontSize16">
                    *
                  </span>
                </label>
                <div className="col-md-7">
                  <SearchInput
                    id="productCode"
                    label="productCode"
                    name="productCode"
                    options={productsData?.data?.data?.content || []}
                    getOptionLabel={(option) =>
                      `${option.code} - ${option.name}`
                    }
                    getOptionValue={(option) => option.code}
                    isClearable={true}
                    handleChange={async (selectedOption) => {
                      addDlFlow.setFieldValue(
                        "productCode",
                        selectedOption ? selectedOption.code : ""
                      );
                    }}
                    value={
                      addDlFlow?.values?.productCode
                        ? productsData?.data?.data?.content?.find(
                            (prod) =>
                              prod.code === addDlFlow?.values?.productCode
                          )
                        : null
                    }
                    isSearchable={true}
                    placeholder="Product"
                    className="mainInput"
                    customStyles={{ control: { width: "195px" } }}
                    style={{ whiteSpace: "nowrap" }}
                  />
                  {addDlFlow.touched.productCode && addDlFlow.errors.productCode ? (
                    <p className="requiredText">{addDlFlow.errors.productCode}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{ padding: "12px" }}>
              <div className="row mb_22">
              <div className="row mb_22">
                <label className="fontSize14 mb_12 menacingCloudsText fontWeight500 marginR10 mt_10">
                  Customers:
                </label>
                <div className="row mb_24">
                    <div className="col-md-5">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Search Customer:
                      </label>
                      <input
                        type="text"
                        placeholder="Search"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                      />
                      <div className="customerContainerFlow">
                        {customerData?.data?.data?.content
                          ?.filter((customer) =>
                            name
                              ? customer.name
                                  .toLowerCase()
                                  .includes(name.toLowerCase())
                              : true
                          )
                          .map((customer) => (
                            <div
                              key={customer.id}
                              // className={`customerContainerItem ${selectedCustomers.some((selectedCustomer) => selectedCustomer?.accountNumber === customer?.accountNumber) ? 'selected' : ''}`}
                              className={`customerContainerItem ${
                                isAddedClick
                                  ? ""
                                  : selectedCustomers.some(
                                      (selectedCustomer) =>
                                        selectedCustomer?.accountNumber ===
                                        customer?.accountNumber
                                    )
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleCusSelection(customer)}
                            >
                              {customer.name}
                            </div>
                          ))}
                        {/* Empty div to ensure customerContainer is always rendered */}
                        {!customerData?.data?.data?.content?.length &&
                          addDlFlow.values.billingCountry === "" && (
                            <div className="customerContainerItem">
                              Select country to view customers.
                            </div>
                          )}
                        {!customerData?.data?.data?.content?.length &&
                          addDlFlow.values.billingCountry !== "" && (
                            <div className="customerContainerItem">
                              No customers found for selected country.
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-2 text-center mt_70">
                      <div className="mb-2">
                        <button
                          type="button"
                          className="cancelinv_btn"
                          onClick={handleAdd}
                        >
                          Add
                        </button>
                        <img loading="rightarrow" src={rightIcon} />
                      </div>
                      <div className="mb-2">
                        <img loading="rightarrow" src={leftIcon} />
                        <button
                          className="cancelinv_btn"
                          type="button"
                          onClick={handleRemove}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                        Search Customer:
                        <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Search"
                        value={addedName}
                        onChange={(e) => setAddedName(e.target.value)}
                        className={
                          addedName?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          addedName?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                      />
                      <div className="customerContainerFlow">
                        {/* {addedCustomers?.map((customer) => ( */}
                        {addedCustomers
                          ?.filter((customer) =>
                            addedName
                              ? customer?.name
                                  .toLowerCase()
                                  .includes(addedName?.toLowerCase())
                              : true
                          )
                          .map((customer) => (
                            <div
                              key={customer?.accountNumber}
                              className={`customerContainerItem ${
                                selectedCustomers.some(
                                  (selectedCustomer) =>
                                    selectedCustomer?.accountNumber ===
                                    customer?.accountNumber
                                )
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleCustomerClick(customer)}
                            >
                              {isaddAll ? customer : customer?.name}
                            </div>
                          ))}
                        {/* Empty div to ensure customerContainer is always rendered */}
                        {!addedCustomers?.length && (
                          <div className="customerContainerItem">
                            No customers Added.
                          </div>
                        )}
                      </div> 
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
              <button
                type="button"
                className="blue_btn"
                onClick={handleSaveDlflow}
              >
                Save
              </button>
              <button onClick={handleCancelClick} className="cancel_btn">
                Cancel
              </button>
            </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default DeliveryFlowAdd;
