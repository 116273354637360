import React, { useState, useEffect } from 'react'
import MainTable from '../../components/Tables/mtable'
import { useAoneStore } from '../../store/AoneStore'
import OpenModal from '../../components/modal/openModal'
import exportFromJSON from "export-from-json";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../assets/img/dotsbold.svg";
import download_icon from "../../assets/img/download_icon.svg";

const ZidMerchants = () => {

    const getZidMerchants = useAoneStore((state) => state?.getZidMerchants)
    const getZidMerchantsByStoreId = useAoneStore((state) => state?.getZidMerchantsByStoreId)

    const [ modalHandler , setModalHandler ] = useState(false)      // For Edit Pop Up
    const [ addModalHandler, setAddModalHandler ] = useState(false) // For Add Pop Up
    const [ editHandler, setEditHandler] = useState(false)

    const [ ZidMerchants, setZidMerchants ] = useState([])
    const [ storeId, setStoreId ] = useState("")
    const [ appType, setAppType ] = useState("")
    const [ customdropdownOne, setCustomdropdownone] = useState("")

    const [column, SetCoulmn] = useState(["storeId","storeName","customerAccount","expressType","pickupMethod","appType"]);
    const [checkedCol, setCheckedCol] = useState([]);

    const fileName = "Zid_Merchants";
    const exportType = "csv";

    const exportToExcel = () => {
        let data = ZidMerchants.map((e) => ({ 
            storeId : e.storeId,
            storeName: e.storeName,
            customerAccount: e.customerAccount,
            expressType: e.expressType,
            pickupMethod: e.pickupMethod
        }))
        exportFromJSON({ data, fileName, exportType });
    };

    const handleSelect = (d) => {
        let val = checkedCol.includes(d);
        if (val === true) {
          // alert("true")
          setCheckedCol(checkedCol.filter((dat) => dat !== d));
        } else {
          setCheckedCol([...checkedCol, d]);
        }
    };

    const getData = async () => {
        const data = await getZidMerchants()
        console.log("Data", data)
        setZidMerchants(data.data ?? [])
    }

    const handleFilter = async () => {
        let params = `${storeId}?appType=${appType}`
        const data = await getZidMerchantsByStoreId(params)
        setZidMerchants(data.data ?? [])
    }

    useEffect(()=>{
        storeId.length == 0 ? getData() : handleFilter(); 
    },[modalHandler,addModalHandler])

  return (
    <div>
        {/* FILTER FRAME */}
        <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Filters</div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className="row alignItemCenter mb_12">
                        <div className='col-md-12 headManage'>
                            <label className="fontSize14">Store Id: </label>
                            <input
                            type="text"
                            placeholder="Search"
                            style={{ width: "auto", marginLeft: "5px"}}
                            value={storeId}
                            onChange={(e)=>setStoreId(e.target.value.replace(/[^0-9]/g, ""))}
                            className="mainInput"/>
                        </div>    
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className="row alignItemCenter mb_12">
                        <div className='col-md-12 headManage'>
                            <label className="fontSize14">App Type: </label>

                            {/* <select
                                placeholder='App Type'
                                style={{ width: "auto", marginLeft: "5px"}}
                                onChange={(e)=>setAppType(e.target.value)}
                                className='mainInput'
                            >
                                <option value="SHIPPING">SHIPPING</option>
                                <option value="SHIPPING AND FULFILLMENT">SHIPPING AND FULFILLMENT</option>
                            </select> */}

                            <div className="positionRelative">
                                <input
                                type="text"
                                placeholder={appType === "" ? "Select App Type" : appType}
                                style={{ width: "auto", marginLeft: "5px"}}
                                className="seletDropdownIcOn cursorPointer"
                                onClick={() => setCustomdropdownone(!customdropdownOne)}
                                readOnly
                                />
                                {customdropdownOne && (
                                <div className="dropdownResult">
                                    { appType != "" &&
                                    <div
                                    className="dropdownResultItem"
                                    onClick={() => {
                                        setAppType("");
                                        setCustomdropdownone(!customdropdownOne);
                                    }}
                                    >
                                    NONE
                                    </div>
                                    }
                                    <div
                                    className="dropdownResultItem"
                                    onClick={() => {
                                        setAppType("SHIPPING");
                                        setCustomdropdownone(!customdropdownOne);
                                    }}
                                    >
                                    SHIPPING
                                    </div>
                                    <div
                                    className="dropdownResultItem"
                                    onClick={(e) => {
                                        setAppType("SHIPPING_FULFILLMENT");
                                        setCustomdropdownone(!customdropdownOne);
                                    }}
                                    >
                                    SHIPPING AND FULFILLMENT
                                    </div>
                                </div>
                                )}
                            </div>                            
                        </div>    
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className="row alignItemCenter mb_12">
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-8">
                            <button className="filterBtn" style={{ width: "40%"}} onClick={handleFilter}>Filter</button> &nbsp;
                            <button className="filterBtn" style={{ width: "40%"}} onClick={()=>(setStoreId(""),setAppType(""))}>Clear</button>
                        </div>     
                    </div>
                </div>    
            </div>
        </div>

        {
        addModalHandler && <OpenModal modalHandler={addModalHandler} setModalHandler={setAddModalHandler} title={"Add Zid Merchant"} editHandler={editHandler} setEditHandler={setEditHandler}/>
        }

        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          <div>
            <button className='filterBtn plus_icon' title={"Export As CSV"} onClick={()=>setAddModalHandler(!addModalHandler)}>
              +
            </button>
          </div>

          <div className='displayFlex gap_16'> 
            <div>
              <button onClick={exportToExcel} className="filterBtn">
                <span><img src={download_icon} className='h18'/></span>
              </button>
            </div>
            <div className="customFilter">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={DotboldIcon}/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ul className="listColumn">
                      {column.map((d, i) => {
                        return (
                          <>
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol?.includes(d)}
                                />

                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {" "}
                                  {d}{" "}
                                </label>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div> 
        </div> 

        {/* TABLE FRAME */}
        <MainTable tableData={ZidMerchants} tbl="ZIM" modalHandler={modalHandler} setModalHandler={setModalHandler} 
            title={"Edit ZID Merchant"} restrict={checkedCol}/>
    </div>
  )
}

export default ZidMerchants