import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import templateIcon from "../../assets/img/template_icon.svg";
import importIcon from "../../assets/img/importIcon.svg";
import downloadicon from "../../assets/img/download_icon.svg";
import removeIcon from "../../assets/img/cancelIcon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { toast } from "react-toastify";
import { useAoneStore } from "../../store/AoneStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import Modal from "react-bootstrap/Modal";
import * as XLSX from "xlsx";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
const Employment = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [hub, SetHub] = useState("");
  const [department, setDepartment] = useState("");
  const [status, setStatus] = useState(true);
  const [size, setSize] = useState(20);
  const [sortBy, setSortBy] = useState("createdAt");
  const [totalCount, setTotalCount] = useState(0);
  const [employeeData, setEmployeeData] = useState([]);
  const [isDelete, setIsDelete] = useState("");
  const [modalHandler, setModalHandler] = useState(false);
  const [page, setPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [editHandler, setEditHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const [importError, setImportError] = useState([]);
  const getEmployee = useAoneStore((state) => state?.getEmployee);
  const exportEmployee = useAoneStore((state) => state?.exportEmployee);
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const deptData = useAoneStore((state) => state?.deptData);
  const employeeDepartments = useAoneStore(
    (state) => state?.employeeDepartments
  );
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const isDropdownselectOpen = useAoneStore(
    (state) => state?.isDropdownselectOpen
  );
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const getEmployeesTemplate = useAoneStore(
    (state) => state?.getEmployeesTemplate
  );
  const importEmployees = useAoneStore((state) => state?.importEmployees);
  const setglobalEmployeeCode = useAoneStore(
    (state) => state?.setglobalEmployeeCode
  );
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const [deptTransformData, setDeptData] = useState([]);
  useEffect(() => {
    const fetchDepartmentLookup = async () => {
      if (deptData?.data?.data === undefined) {
        try {
          const data = await employeeDepartments();
          const transformedData = data?.data?.data?.map((dept) => ({
            key: dept,
            label: dept,
          }));
          setDeptData(transformedData);
        } catch (error) {
          console.error("Error fetching department data:", error);
        }
      } else {
        const transformedData = deptData?.data?.data?.map((dept) => ({
          key: dept,
          label: dept,
        }));
        setDeptData(transformedData);
      }
    };
    fetchDepartmentLookup();
  }, []);
  const downloadTemplate = async () => {
    try {
      const res = await getEmployeesTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Employee Template.xlsx`);
      }
    } catch (err) {}
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            importEmployees(formData)
              .then((response) => {
                if (
                  response &&
                  response?.response?.data?.status === "IMPORT_ERROR"
                ) {
                  setImportError(response?.response?.data?.errors);
                  handleShow();
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };

  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=hubName`;
    if (name) {
      let hashname = `$${name}$`;
      params += `&firstName=${hashname}`;
    }
    if (email) {
      let hashemail = `$${email}$`;
      params += `&email=${hashemail}`;
    }
    if (code) {
      let hashcode = `$${code}$`;
      params += `&code=${hashcode}`;
    }
    if (department) {
      params += `&department=${department}`;
    }
    if (hub) {
      params += `&hubCode=${hub}`;
    }
    // if (status) {
    //   params += `&status=${status}`;
    // } else if(!status) {
    //   params += `&status=false`
    // }
    try {
      const res = await exportEmployee(params);
      if (res.status == 200) {
        fileDownload(res.data, `Employees.xlsx`);
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=hubName`;
    if (name) {
      let hashname = `$${name}$`;
      params += `&name=${hashname}`;
    }
    if (email) {
      let hashemail = `${email}`;
      params += `&email=${hashemail}`;
    }
    if (code) {
      let hashcode = `$${code}$`;
      params += `&code=${hashcode}`;
    }
    if (department) {
      params += `&department=${department}`;
    }
    if (hub) {
      params += `&hubCode=${hub}`;
    }
    // if (status) {
    //   params += `&status=${status}`;
    // }else{
    //   params += `&status=false`
    // }
    const data = await getEmployee(params);
    setTotalCount(data?.data?.data?.totalElements);
    setEmployeeData(data?.data?.data?.content);
  };
  const [prevSearch, setPrevSearch] = useState("");
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=${search}`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    location,
    modalHandler,
    addModalHandler,
    statusHandler,
    saveHandler,
    isDelete,
    name,
    email,
    code,
    hub,
    department,
    status,
  ]);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  setglobalEmployeeCode("");
                  navigate("/crm/employees");
                }}
              >
                CRM
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  setglobalEmployeeCode("");
                  navigate("/crm/employees");
                }}
              >
                Employees
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Code:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={code}
                      className={
                        code?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setCode(e.target.value)}
                    />
                    {code && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setCode("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Name:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={name}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        name?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setName(e.target.value)}
                    />
                    {name && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setName("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5" md={3}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Email:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={email}
                      className={
                        email?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        email?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setEmail(e.target.value)}
                    />
                      {email && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setEmail("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
          </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Department:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <SearchInput
                      id="department"
                      label="department"
                      name="department"
                      options={deptTransformData || []}
                      getOptionLabel={(dept) => dept.label}
                      getOptionValue={(dept) => dept.key}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isDropdownselectOpen}
                      handleChange={(selectedOption) => {
                        setDepartment(selectedOption ? selectedOption.key : "");
                      }}
                      isClearable={true}
                      isSearchable={true}
                      customStyles={{ control: { width: "170px" } }}
                      placeholder="Department"
                      className={
                        department?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Hub:
                  </label>
                  <AsyncSearchInput
                    id="hub"
                    label="hub"
                    name="hub"
                    value={selectedOption}
                    loadMoreOptions={loadMoreOptionsGroups}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(hub) => hub.label}
                    getOptionValue={(hub) => hub.value}
                    onMenuOpen={handleMenuOpenregion}
                    onMenuClose={handleMenuCloseregion}
                    menuIsOpen={isReactselectRegion}
                    onChange={(selectedOption) => {
                      setSelectedOption(selectedOption);
                      SetHub(selectedOption ? selectedOption.value : "");
                    }}
                    customStyles={{ control: { width: "250px" } }}
                    placeholder="Hub"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                </Col>
                {/* <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Status:
                  </label>
                  <div className="col-md-8">
                    <div className="smallCheckbox">
                      <input
                        type="checkbox"
                        id="status"
                        checked={status}
                        style={{ marginLeft: "5px" }}
                        onChange={() => setStatus(!status)}
                      />
                      <label className="margintop7" htmlFor="status"></label>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                {(roleHandler(role, "AONE_CRM.admin") ||
                  roleHandler(role, "AONE_CRM.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.admin")) && (
                  <>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon ml_10"
                      title={"Download"}
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div title={"Import"}>
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                )}
                <button
                  className="filterBtn"
                  title={"Export"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_CRM.admin") ||
                  roleHandler(role, "AONE_CRM.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                  roleHandler(role, "AONE_CRM_EMPLOYEES.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => navigate("/crm/employees/add")}
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={employeeData}
            tbl={"Employee"}
            title={"Employee"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={tab === "Employee"}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />
      </div>
      <Modal
        show={show}
        onHide={() => handleCancel()}
        className="addnewpopup"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">
            Import Exceptions
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="error-message">
            <p>
              <span>!</span>Failed to import below records
            </p>
          </div>
          <form>
            <div className="row">
              <div className="table_wrapper overflowScroll mb_12 showTenRows">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Field</th>
                      <th>Resource Id</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importError?.map((err, id) => (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{err.field}</td>
                        <td>{err.key}</td>
                        <td>{err.msg}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center">
            <div>
              <button className="cancel_btn" onClick={() => handleCancel()}>
                Dismiss
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default Employment;
