import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../../store/AoneStore";
import { usepickupStore } from "../../../store/PickupRequest/pickuprequestStore";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import removeIcon from "../../../../src/assets/img/cancelIcon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as XLSX from "xlsx";
import syncicon from "../../../../src/assets/img/sync.svg";
import downloadIcon from "../../../assets/img/download_icon.svg";
import DotboldIcon from "../../../assets/img/dotsbold.svg";
import templateIcon from "../../../assets/img/template_icon.svg";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import fileDownload from "js-file-download";
import jwt_decode from "jwt-decode";
import { UserContext } from "../../../helper/ProtectedRoute";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import { useDeliveryStore } from "../../../store/DeliveryMgt/DeliveryStore";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { roleHandler } from "../../../helper/roleHandler";
import { DateRangePicker } from "rsuite";
const Consignments = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const paramsId = useParams();
  const prevent = useRef(false);
  const location = useLocation();
  const [editPage, setEditPage] = useState(false);
  const [tab, setTab] = useState(1);
  const [headerParams, setHeaderParams] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [cusPage, setCusPage] = useState(-1);
  const [zonepage, setZonePage] = useState(-1);
  const [isChange, setIsChange] = useState(false);
  const [isRpu, setisRpu] = useState("");
  const [isRto, setisRto] = useState("");
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(-1);
  const [courierPage, setCourierPage] = useState(-1);
  const [originCityPage, setOriginCitypage] = useState(-1);
  const [originDesCityPage, setOriginDesCitypage] = useState(-1);
  const [districtPage, setDistrictpage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pickupData, setPickupData] = useState([]);
  const [consignmentData, setConsignmentData] = useState([]);
  //columns for grid
  const gridId = "ops.consignments";
  const [column, SetCoulmn] = useState([
    "Tracking Id",
    "Shipper Name",
    "Receiver Name",
    "Request Id",
    "Customer Account",
    "Customer Name",
    "Product",
    "Status",
    "Reason",
    "Packages Count",
    "Weight",
    "RTO",
    "RPU",
    "Origin Country",
    "Origin City",
    "Origin District",
    "Origin Hub",
    "Origin Zone",
    "Destination Country",
    "Destination City",
    "Destination District",
    "Destination Hub",
    "Destination Zone",
    "Shipper Address",
    "Shipper Phone",
    "Receiver Phone",
    "Courier Id",
    "COD Amount",
    "Pickup Date",
    "Delivery Date",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "RPU",
    "Reason",
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
  ]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const [dataPickup, setDataPickup] = useState([]);
  const [trackId, setTrackId] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [selectPickup, setSelectPickup] = useState([]);
  const [selectoriginHub, setSelectOriginHub] = useState([]);
  const [selectPickuprequest, setSelectPickuprequest] = useState([]);
  const productsData = useAoneStore((state) => state?.productsData);
  const [pickupTitle, setPickupTitle] = useState("Create new pickup request");
  const [sortField, setSortField] = useState("-updatedAt");
  const filterCustomer = useAoneStore((state) => state?.filterCustomer);
  const filterProducts = useAoneStore((state) => state?.filterProducts);
  const getHoldReasons = useAoneStore((state) => state?.getHoldReasons);
  const holdReasonsData = useAoneStore((state) => state?.holdReasonsData);
  const filterCities = useAoneStore((state) => state?.filterCities);
  const filterDistricts = useAoneStore((state) => state?.filterDistricts);
  const bulkAssignDeliveryTask = useDeliveryStore(
    (state) => state?.bulkAssignDeliveryTask
  );
  const printBulkConsignments = useAoneStore(
    (state) => state?.printBulkConsignments
  );
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  //filters
  const [resetKey, setResetKey] = useState(0);
  const [trackingId, setTrackingId] = useState("");
  const [shipperName, setShipperName] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [customer, setCustomer] = useState("");
  const [product, setProduct] = useState("");
  const [originCountry, setOriginCountry] = useState(null);
  const [originCity, setOriginCity] = useState(null);
  const [destinationCountry, setDestinationCountry] = useState(null);
  const [destinationCity, setDestinationCity] = useState(null);
  const [originDistrict, setOriginDistrict] = useState(null);
  const [courier, setCourier] = useState("");
  const [destinationZone, setDestinationZone] = useState("");
  const [status, setStatus] = useState("");
  const [requestId, setRequestId] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
  const [selOptionCustomer, setOptionCustomer] = useState(null);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [selectedOptionDesCity, setSelectedOptionDesCity] = useState(null);
  const [selectedOptionDistrict, setSelectedOptionDistrict] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [seleczone, setselectZone] = useState([]);
  const [selecwaybill, setSelectWaybill] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const [courierFilterPage, setCourierFilterPage] = useState(-1);
  const [courierFilPage, setCourierFilPage] = useState(-1);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const getConsignments = usepickupStore((state) => state?.getConsignments);
  const importDdu = usepickupStore((state) => state?.importDdu);
  const dduUpdateTemplate = usepickupStore((state) => state?.dduUpdateTemplate);
  const getPickupConsignments = usepickupStore((state) => state?.getPickupConsignments);
  const [selectedOptionCourier, setSelectedOptionCourier] = useState(null);
  const [selectedOptionCouriers, setSelectedOptionCouriers] = useState(null);
  const bulkapplyConsignments = usepickupStore(
    (state) => state?.bulkapplyConsignments
  );
  const revokePickupConsignment = usepickupStore(
    (state) => state?.revokePickupConsignment
  );
  const revokeCancelConsignment = usepickupStore(
    (state) => state?.revokeCancelConsignment
  );
  const revokeDeliveryConsignment = usepickupStore(
    (state) => state?.revokeDeliveryConsignment
  );
  const revokeHoldConsignment = usepickupStore(
    (state) => state?.revokeHoldConsignment
  );
  const [selectedDesOption, setSelectedDesOption] = useState(null);
  const getPickuprequestStatus = usepickupStore(
    (state) => state?.getPickuprequestStatus
  );
  const pickupStatus = usepickupStore((state) => state?.pickupStatus);
  const setReactCurrency = useAoneStore((state) => state?.setReactCurrency);
  const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
  const handleMenuCurrency = () => {
    setReactCurrency(true);
  };
  const handleMenuCloseCurrency = () => {
    setReactCurrency(false);
  };
  const dateFilterOptions = [
    { label: "Created Date", key: "createdAt" },
    { label: "Last Updated Date", key: "updatedAt" },
    { label: "Pickup Date", key: "pickupAt" },
    { label: "Delivery Date", key: "deliveryAt" },
  ];
  useEffect(() => {
    let paramsLink = `?page=${newPage}&size=100&sortBy=${sortField}`;
    getHoldReasons(paramsLink);
  }, []);
  const exportConsignments = usepickupStore(
    (state) => state?.exportConsignments
  );
  const setisPickupRequest = usepickupStore(
    (state) => state?.setisPickupRequest
  );
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [cancelRequestId, setCancelRequestId] = useState("");
  const filterZone = useAoneStore((state) => state.filterZone);
  const [cancelCourierId, setCancelCourierId] = useState("");
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const setReactCharges = useAoneStore((state) => state?.setReactCharges);
  const [dateRangefil, setDateRangefil] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [file, setFile] = useState(null);
  const [fileErr,setFileErr] = useState("");
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      setFilterDate("");
      setResetKey((prevKey) => prevKey + 1);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const tabHandler = (i) => {
    navigate("/ops/pickups");
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };
  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };
  const handleMenuClosedescity = () => {
    setReactDescity(false);
  };
  const handleMenuCharges = () => {
    setReactCharges(true);
  };
  const handleMenuCloseCharges = () => {
    setReactCharges(false);
  };
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const handleMenuState = () => {
    setisReactRatestate(true);
  };
  const handleMenuCloseState = () => {
    setisReactRatestate(false);
  };
  const setisReactRatestate = useAoneStore(
    (state) => state?.setisReactRatestate
  );
  const [trackingIdArray, setTrackingIdArray] = useState([]);
  const handleInputChange = (e) => {
    setTrackingId(e.target.value);
    const inputText = e.target.value;
    const hasSpacesOrCommas = /\s|,/.test(inputText);

    if (hasSpacesOrCommas) {
      const trackingArray = inputText.split(/[,\s]+/).filter(Boolean);
      setTrackingIdArray(trackingArray);
    } else {
      setTrackingIdArray([inputText]);
    }
  };
  const resetFilters = () => {
    setResetKey((prevKey) => prevKey + 1);
    setisRpu(false);
    setisRto(false);
    setCourierFilterPage(-1);
    setCourierFilPage(-1);
    setCusPage(-1);
    setZonePage(-1);
    setCourierPage(-1);
    setOriginCitypage(-1);
    setOriginDesCitypage(-1);
    setDistrictpage(-1);
    setSelectedOptionCustomer(null);
    setSelectedOptionCity(null);
    setSelectedOptionDesCity(null);
    setSelectedDesOption(null);
    setSelectedOptionCourier(null);
    setTrackingId("");
    setTrackingIdArray([]);
    setCustomer("");
    setProduct("");
    setOriginCountry(null);
    setOriginCity(null);
    setDestinationCountry(null);
    setDestinationCity(null);
    setOriginDistrict(null);
    setCourier("");
    setDestinationZone("");
    setStatus("");
    setFilterDate("");
    setDateRangefil({});
    setDateRange([]);
  };

  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.length < 1
    ) {
      filterCountries(paramslink);
    }
    if (
      productsData?.data?.data?.content?.length < 1 ||
      productsData?.length < 1
    ) {
      filterProducts(paramslink);
    }
  }, []);
  useEffect(() => {
    if (pickupStatus?.data?.data === undefined) {
      getPickuprequestStatus();
    }
  }, []);
  const sortedPickupStatus =
    pickupStatus?.data?.data
      ?.filter((pick) => pick?.label !== "Default")
      ?.sort((a, b) => a.value - b.value) || [];
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedOptionCouriers(null);
    setCourierFilPage(-1);
    setCourierPage(-1);
    setCourierFilterPage(-1);
  };
  const handleCancel = () => {
    handleClose();
  };
  //upload ddu
  const [showUpload, setShowUpload] = useState(false);
  const handleCloseUpload = () => {
    setShowUpload(false);
  };
  const openUpload = () => {
    setShowUpload(true);
  };
  function handleDduFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [".xlsx"];
    const fileExtension = fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      // Show toast error
      toast.error(
        "Invalid file format. Please upload a .xlsx file"
      );
      return; 
    }
    const fileSizeLimit = 1024 * 1024; // 1MB
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    setFile(file);
    setFileErr("");
    // Assuming addVehicleDoc is a state or reference to Formik's form instance
    const fileInput = event.target;
    const fileNameDisplay = document.getElementById("insurance-file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  const handleFileChange = async () => {
    if (!file) {
      setFileErr("Please select the file to update ddu for consignments");
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            const localesColumnRange = getColumnRange(sheet, 3);
            importDdu(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  setispostAdded(!ispostAdded)
                  toast.success("DDU Updated successfully!");
                  setFile("");
                  setFileErr("");
                  setShowUpload(false);
                } else if (response?.data?.errorCode !== "" && response?.data?.errorCode !== undefined) {
                  const errorMessage = response?.data?.msg;
                  toast.error(errorMessage);
                  setFile("");
                  setFileErr("");
                } else if (response?.data?.status === "ERROR") {
                  const errorMessages = response?.data?.data
                      .map((item) => {
                          const trackingIdMessage = item.trackingId ? `Tracking ID: ${item.trackingId}: ` : '';
                          const errorMsg = item.errorMsg.join("; ");
                          return `Row ${item.errorRow}: ${trackingIdMessage}${errorMsg}`;
                      })
                      .join(", ");
                  toast.error(errorMessages);
                  setFile("");
                  setFileErr("");
              } else if (response?.data?.data?.status === "ERROR") {
                  const errorMessages = response?.data?.data
                      .map((item) => {
                          const trackingIdMessage = item.trackingId ? `Tracking ID: ${item.trackingId}: ` : '';
                          const errorMsg = item.errorMsg.join("; ");
                          return `Row ${item.errorRow}: ${trackingIdMessage}${errorMsg}`;
                      })
                      .join(", ");
                  toast.error(errorMessages);
                  setFile("");
                  setFileErr("");
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  setFile("");
                  setFileErr("");
                } else if (response?.data?.status === "ERROR") {
                  const errorMessage = response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  setFile("");
                  setFileErr("");
                } else if (
                  response?.data?.data?.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  setFile("");
                  setFileErr("");
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  setFile("");
                  setFileErr("");
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  setFile("");
                  setFileErr("");
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                setFile("");
                setFileErr("");
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            setFile("");
            setFileErr("");
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          setFile("");
          setFileErr("");
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        setFile("");
        setFileErr("");
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  //show modal for bulk
  const [showConfirm, setShowConfirm] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [actionCode, setActionCode] = useState(null);
  const [holdReason,setHoldReason] = useState("");
  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };
  const titles = {
    102: "Do you want to cancel the selected consignments?",
    201: "Do you want to make the selected consignments as Pickup completed?",
    802: "Do you want to Hold the selected consignments?",
    303: "Do you want to make the selected consignments as Receive at Hub?",
    800: "Do you want to mark the selected consignments as Lost?",
    801: "Do you want to mark the selected consignments as Damaged?",
    803: "Do you want to mark the selected consignments as Disposed?",
    804: "Do you want to revoke the pickup for the selected consignments?",
    805: "Do you want to revoke cancel for the selected consignments?",
    806: "Do you want to revoke delivery for the selected consignments?",
    807: "Do you want to revoke Hold for the selected consignments?",
  };
  const openModal = (code) => {
    const title = titles[code];
    setActionCode(code);
    setModalTitle(title);
    setShowConfirm(true);
  };
  const handleCancelConfirm = () => {
    handleClose();
  };

  const textRef = useRef(null);
  const consignmentString = selectPickup?.join("\n");
  useEffect(() => {
    const adjustHeight = () => {
      if (textRef.current) {
        // Reset the height first to recalculate the proper scroll height
        textRef.current.style.height = "auto";
        // Calculate height with 40% increase
        const newHeight = textRef.current.scrollHeight * 2.5;
        textRef.current.style.height = `${newHeight}px`;
      }
    };
    // Use setTimeout to ensure the height calculation happens after rendering
    setTimeout(adjustHeight, 0);
  }, [consignmentString]);
  const handleBulkPrintOrders = async () => {
    if (selectPickup?.length < 1) {
      toast.error("Select Consignments to print");
    } else {
      try {
        const res = await printBulkConsignments(selectPickup);
        // Create a Blob from the response data
        const blob = new Blob([res.data], { type: "application/pdf" });
        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
        // Open the PDF in a new window
        window.open(blobUrl, "_blank");
        setSelectPickup([]);
        setIsChange(!isChange);
        setIsDropdownOpen(false);
      } catch (error) {}
    }
  };
  const downloadTemplate = async () => {
    try {
        const res = await dduUpdateTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `DDU Update Template.xlsx`);
        }
    } catch (err) {}
  };
  const applyPickupOrder = async (
    event,
    trackingId,
    originHub,
    pickupRequestId
  ) => {
    if (selectPickup?.length < 1) {
      toast.error("Select Consignments to perform any operation");
    } else {
      try {
        let events = selectPickup.map((pickup, index) => {
          let eventObject = {
            eventCode: event,
            trackingId: pickup,
            latitude: latitude,
            longitude: longitude,
            hubCode: userHub,
            eventDate: currentTimeMillis,
            eventTimeZone: eventTimeZone,
            sortingMachine: true,
          };
          if (event === 201) {
            eventObject.pickupRequestId = selectPickuprequest[index];
          }
          if (event === 802) {
            eventObject.failureReasonCode = holdReason;
          }
          return eventObject;
        });
        const res = await bulkapplyConsignments(events);
        if (res?.data?.status === "SUCCESS") {
          toast.success("Consignment scanned successfully");
          setIsDropdownOpen(false);
          setispostAdded(!ispostAdded);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.data?.status == "ERROR") {
          const errorMessage = res?.data?.msg;
          toast.error(`${errorMessage}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.errors?.length > 0) {
          const errorMessages = res?.response?.data?.errors
            ?.map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          toast.error(`${errorMessages}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.status == "ERROR") {
          const errorMessage = res?.response?.data?.msg;
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else {
          const errorMessage = res?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        }
      } catch (error) {
        toast.error("Error in applying the consignment.");
      }
    }
  };
  const revokePickup = async () => {
    if (selectPickup?.length < 1) {
      toast.error("Select Consignments to perform any operation");
    } else {
      try {
        let payload = {
          trackingIds: selectPickup,
        };
        const res = await revokePickupConsignment(payload);
        if (res?.data?.status === "SUCCESS") {
          toast.success("Pickup revoked");
          setIsDropdownOpen(false);
          setispostAdded(!ispostAdded);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.data?.status == "ERROR") {
          const errorMessage = res?.data?.msg;
          toast.error(`${errorMessage}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.errors?.length > 0) {
          const errorMessages = res?.response?.data?.errors
            ?.map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          toast.error(`${errorMessages}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.status == "ERROR") {
          const errorMessage = res?.response?.data?.msg;
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else {
          const errorMessage = res?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        }
      } catch (error) {
        toast.error("Error in revoking the consignment.");
      }
    }
  };
  const revokeCancel = async () => {
    if (selectPickup?.length < 1) {
      toast.error("Select Consignments to perform any operation");
    } else {
      try {
        let payload = {
          trackingIds: selectPickup,
        };
        const res = await revokeCancelConsignment(payload);
        if (res?.data?.status === "SUCCESS") {
          toast.success("Cancel revoked");
          setIsDropdownOpen(false);
          setispostAdded(!ispostAdded);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.data?.status == "ERROR") {
          const errorMessage = res?.data?.msg;
          toast.error(`${errorMessage}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.errors?.length > 0) {
          const errorMessages = res?.response?.data?.errors
            ?.map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          toast.error(`${errorMessages}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.status == "ERROR") {
          const errorMessage = res?.response?.data?.msg;
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else {
          const errorMessage = res?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        }
      } catch (error) {
        toast.error("Error in revoking the consignment.");
      }
    }
  };
  const revokeDelivery = async () => {
    if (selectPickup?.length < 1) {
      toast.error("Select Consignments to perform any operation");
    } else {
      try {
        let payload = {
          trackingIds: selectPickup,
        };
        const res = await revokeDeliveryConsignment(payload);
        if (res?.data?.status === "SUCCESS") {
          toast.success("Delivery revoked");
          setIsDropdownOpen(false);
          setispostAdded(!ispostAdded);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.data?.status == "ERROR") {
          const errorMessage = res?.data?.msg;
          toast.error(`${errorMessage}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.errors?.length > 0) {
          const errorMessages = res?.response?.data?.errors
            ?.map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          toast.error(`${errorMessages}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.status == "ERROR") {
          const errorMessage = res?.response?.data?.msg;
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else {
          const errorMessage = res?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        }
      } catch (error) {
        toast.error("Error in revoking the consignment.");
      }
    }
  };
  const revokeHold = async () => {
    if (selectPickup?.length < 1) {
      toast.error("Select Consignments to perform any operation");
    } else {
      try {
        let payload = {
          trackingIds: selectPickup,
        };
        const res = await revokeHoldConsignment(payload);
        if (res?.data?.status === "SUCCESS") {
          toast.success("Hold revoked");
          setIsDropdownOpen(false);
          setispostAdded(!ispostAdded);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.data?.status == "ERROR") {
          const errorMessage = res?.data?.msg;
          toast.error(`${errorMessage}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.errors?.length > 0) {
          const errorMessages = res?.response?.data?.errors
            ?.map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          toast.error(`${errorMessages}`);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else if (res?.response?.data?.status == "ERROR") {
          const errorMessage = res?.response?.data?.msg;
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        } else {
          const errorMessage = res?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setIsDropdownOpen(false);
          setSelectPickup([]);
          setSelectOriginHub([]);
          setIsChange(!isChange);
        }
      } catch (error) {
        toast.error("Error in revoking the consignment.");
      }
    }
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const addDeliveryTask = useFormik({
    initialValues: {
      trackingId: [],
      employeeCode: "",
    },
    validationSchema: Yup.object({
      employeeCode: Yup.string().required("Courier is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await bulkAssignDeliveryTask(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Delivery assigned`);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          getData();
          setIsChange(!isChange);
          setShow(false);
          resetForm();
          setSelectAll(false);
          setIsDropdownOpen(false);
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShow(false);
          resetForm();
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsDropdownOpen(false);
          setIsChange(!isChange);
          toast.error(errorMessages);
          setSelectAll(false);
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsChange(!isChange);
          setIsDropdownOpen(false);
          setSelectAll(false);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsDropdownOpen(false);
          setIsChange(!isChange);
          resetForm();
          setSelectAll(false);
        } else {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilPage(-1);
          setSelectPickup([]);
          setSelectedOptionCouriers(null);
          setIsDropdownOpen(false);
          setIsChange(!isChange);
          setSelectAll(false);
          resetForm();
        }
      });
    },
  });
  const handleAssignCourier = () => {
    if (selectPickup.length < 1) {
      toast.error("Select consignments to assign a courier");
    } else {
      setShow(true);
      setSelectedOptionCourier(null);
      addDeliveryTask.setFieldValue("trackingId", selectPickup);
    }
  };
  const textareaRef = useRef(null);

  const trackingIdsString = addDeliveryTask?.values?.trackingId?.join(", ");

  useEffect(() => {
    const adjustHeight = () => {
      if (textareaRef.current) {
        // Reset the height first to recalculate the proper scroll height
        textareaRef.current.style.height = "auto";

        // Calculate height with 40% increase
        const newHeight = textareaRef.current.scrollHeight * 1.8;
        textareaRef.current.style.height = `${newHeight}px`;
      }
    };

    // Use setTimeout to ensure the height calculation happens after rendering
    setTimeout(adjustHeight, 0);
  }, [trackingIdsString]);
  const [showPrCustomer, SetshowPrCustomer] = useState(false);
  const handleInputCusClick = () => {
    SetshowPrCustomer(true);
  };
  const [prevSearch, setPrevSearch] = useState("");
  //customer async
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          if (search?.startsWith("AJ")) {
            endpoint += `&accountNumber=${hashsearch}`;
          } else {
            endpoint += `&name=${hashsearch}`;
          }
        }
        const response = await filterCustomer(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.accountNumber} - ${option.name}`,
            value: option.accountNumber,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, cusPage]
  );
  const loadMoreCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : originCityPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setOriginCitypage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, originCityPage]
  );
  const loadMoreDesCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : originDesCityPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setOriginDesCitypage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, originDesCityPage]
  );
  const loadMoreDistrictOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : districtPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDistrictpage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${search}`;
        }
        const response = await filterDistricts(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, districtPage]
  );
  const loadMoreZoneOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? zonepage + 1 : zonepage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setZonePage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await filterZone(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
            name: zone.name,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, zonepage, prevSearch, setPrevSearch]
  );
  const [userHub, setUserHub] = useState("");
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  const loadMoreOptionsCourierFilter = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierFilterPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierFilterPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierFilterPage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionCourierFilter = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierFilPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierFilPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierFilPage, prevSearch, setPrevSearch]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown
  // Toggle dropdown
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  // Handle clicks outside the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    // Add event listener for clicks outside of dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=customerName&fields=packagesCount`;
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (shipperName) {
      params += `&shipperName=${shipperName}`;
    }
    if (receiverName) {
      params += `&receiverName=${receiverName}`;
    }
    if (product) {
      params += `&productCode=${product}`;
    }
    if (originCountry) {
      params += `&originCountry=${originCountry}`;
    }
    if (originCity) {
      params += `&originCity=${originCity}`;
    }
    if (originDistrict) {
      params += `&originDistrict=${originDistrict}`;
    }
    if (destinationCountry) {
      params += `&destinationCountry=${destinationCountry}`;
    }
    if (destinationCity) {
      params += `&destinationCity=${destinationCity}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (requestId) {
      params += `&requestId=${requestId}`;
    }
    if (destinationZone) {
      params += `&destinationZone=${destinationZone}`;
    }
    if (isRpu) {
      params += `&rpu=${isRpu}`;
    }
    if (isRto) {
      params += `&rto=${isRto}`;
    }
    if (trackingIdArray?.length > 0) {
      trackingIdArray?.forEach((id) => {
        params += `&trackingIds=${id}`;
      });
    }
    if (filterDate) {
      params += `&filterDate=${filterDate}`;
      if (dateRangefil?.start) {
        params += `&startTime=${dateRangefil?.start}`;
      }
      if (dateRangefil?.end) {
        params += `&endTime=${dateRangefil?.end}`;
      }
    }
    if (courier) {
      params += `&courierId=${courier}`;
    }
    const data = await getConsignments(params);
    setTotalCount(data.data.data.totalElements);
    setConsignmentData(data.data.data.content);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    tab,
    size,
    sortField,
    saveHandler,
    customer,
    product,
    ispostAdded,
    originCountry,
    originCity,
    isRpu,
    isRto,
    destinationCountry,
    destinationCity,
    destinationZone,
    originDistrict,
    courier,
    dateRangefil,
    trackingId,
    shipperName,
    receiverName,
    requestId,
    status,
  ]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [currentTimeMillis, setCurrentTimeMillis] = useState(null);
  const [eventTimeZone, setEventTimeZone] = useState("");
  useEffect(() => {
    // Function to get user's location
    const requestUserLocation = () => {
      // Check if the browser supports Geolocation
      if (navigator.geolocation) {
        // Request user's permission to access their location
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Success: Get the latitude and longitude
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
          },
          (error) => {
            // Handle error (e.g., user denied permission)
            console.error("Error obtaining location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    // Request the user's location when the component mounts
    requestUserLocation();
    // Get the current time in milliseconds
    const currentDate = new Date();
    setCurrentTimeMillis(currentDate.getTime());
    // Determine the user's event time zone based on the browser's timezone offset
    const timeZoneOffset = currentDate.getTimezoneOffset(); // in minutes
    // Convert the offset to hours and reverse the sign
    const offsetHours = -timeZoneOffset / 60;
    // Format the event time zone
    const formattedTimeZone = `GMT${offsetHours >= 0 ? "+" : ""}${offsetHours}`;

    // Example mapping for different offsets to event time zones
    const timeZoneMap = {
      "+3": "KSA +3",
      // Add more mappings for different offsets if needed
    };

    // Set the event time zone based on the offset
    setEventTimeZone(timeZoneMap[formattedTimeZone] || formattedTimeZone);
  }, []);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };

  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };

  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const resetTrackingId = () => {
    setTrackingId("");
    setTrackingIdArray([]);
  };
  const exporttoExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (customer) {
      params += `&customerAccount=${customer}`;
    }
    if (shipperName) {
      params += `&shipperName=${shipperName}`;
    }
    if (receiverName) {
      params += `&receiverName=${receiverName}`;
    }
    if (product) {
      params += `&productCode=${product}`;
    }
    if (originCountry) {
      params += `&originCountry=${originCountry}`;
    }
    if (originCity) {
      params += `&originCity=${originCity}`;
    }
    if (originDistrict) {
      params += `&originDistrict=${originDistrict}`;
    }
    if (destinationCountry) {
      params += `&destinationCountry=${destinationCountry}`;
    }
    if (destinationCity) {
      params += `&destinationCity=${destinationCity}`;
    }
    if (courier) {
      params += `&courierId=${courier}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (destinationZone) {
      params += `&destinationZone=${destinationZone}`;
    }
    if (isRpu) {
      params += `&rpu=${isRpu}`;
    }
    if (isRto) {
      params += `&rto=${isRto}`;
    }
    if (selectPickup && selectPickup.length > 0) {
      const trackingIds = selectPickup.join(",");
      params += `&trackingIds=${trackingIds}`;
    }
    try {
      const res = await exportConsignments(params);
      if (res.status == 200) {
        fileDownload(res.data, `Consignments.xlsx`);
        setSelectPickup([]);
        setIsChange(!isChange);
      } else if (res?.response?.status === 500) {
        toast.error("Internal Server Error");
      } else {
        toast.error(res.response.statusText);
      }
    } catch (err) {}
  };
  const fileInputRef = useRef(null);
  const handleClick = () => {
    fileInputRef.current.click(); // Triggering click on file input
  };
  useEffect(() => {
    const hasOpsPermissions =
      roleHandler(role, "AONE_OPS.read") ||
      roleHandler(role, "AONE_OPS.write") ||
      roleHandler(role, "AONE_OPS.admin") ||
      roleHandler(role, "AONE_OPS_CONSIGNMENTS.read") ||
      roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup");

    const hasPickupPermissions =
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.read") ||
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.write") ||
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.admin") ||
      roleHandler(role, "AONE_OPS_PICKUP-REQUEST.cancel");
    if (!hasOpsPermissions && hasPickupPermissions) {
      setTab(2);
    }
  }, [role]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/consignments")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/consignments")}
              >
                Consignments
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div style={{ position: "relative", width: "100%" }}>
          <Row>
            <Col
              className={
                trackingId?.length < 22
                  ? "col-20-percent"
                  : trackingId?.length < 44
                  ? "col-28-percent"
                  : trackingId?.length < 67
                  ? "col-40-percent"
                  : trackingId?.length < 87
                  ? "col-50-percent"
                  : "col-100-percent"
              }
              md={3}
            >
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Tracking Id:
              </label>
              <div className="displayFlex alignItemCenter position-relative">
                <input
                  type="text"
                  value={trackingId}
                  className={
                    trackingId?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                  style={
                    trackingId?.length > 0
                      ? { border: "2px solid #ec171c" }
                      : {}
                  }
                  onChange={handleInputChange}
                />
                {trackingId && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={resetTrackingId}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Shipper Name:
              </label>
              <div className="displayFlex alignItemCenter position-relative">
                <input
                  type="text"
                  value={shipperName}
                  className={
                    shipperName?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                  style={
                    shipperName?.length > 0
                      ? { border: "2px solid #ec171c" }
                      : {}
                  }
                  onChange={(e) => setShipperName(e?.target?.value)}
                />
                {shipperName && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setShipperName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Receiver Name:
              </label>
              <div className="displayFlex alignItemCenter position-relative">
                <input
                  type="text"
                  value={receiverName}
                  className={
                    receiverName?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                  style={
                    receiverName?.length > 0
                      ? { border: "2px solid #ec171c" }
                      : {}
                  }
                  onChange={(e) => setReceiverName(e?.target?.value)}
                />
                {receiverName && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setReceiverName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Customer:
              </label>
              <AsyncSearchInput
                key={resetKey}
                id="customer"
                label="customer"
                name="customer"
                value={selectedOptionCustomer}
                loadMoreOptions={loadMoreOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                menuIsOpen={isReactselectOpen}
                onChange={(selectedOptionCustomer) => {
                  setSelectedOptionCustomer(selectedOptionCustomer);
                  if (selectedOptionCustomer) {
                    setCustomer(selectedOptionCustomer.value);
                  } else {
                    setCustomer(null);
                  }
                }}
                placeholder="Customer"
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Product:
              </label>
              <SearchInput
                key={resetKey}
                id="product"
                label="product"
                name="product"
                onMenuOpen={handleMenuOpenregion}
                onMenuClose={handleMenuCloseregion}
                menuIsOpen={isReactselectRegion}
                options={productsData?.data?.data?.content || []}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                getOptionValue={(option) => option.code}
                isClearable={true}
                handleChange={(selectedOption) => {
                  if (selectedOption) {
                    setProduct(selectedOption?.code);
                  } else {
                    setProduct(null);
                  }
                }}
                isSearchable={true}
                placeholder="Product"
                className="mainInput"
                style={{ whiteSpace: "nowrap" }}
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Origin Country:
              </label>
              <SearchInput
                key={resetKey}
                id="originCountry"
                label="originCountry"
                name="originCountry"
                options={countriesData?.data?.data?.content || []}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.isoCode3}
                onMenuOpen={handleMenuOpencity}
                onMenuClose={handleMenuClosecity}
                menuIsOpen={isReactselectCity}
                handleChange={(selectedOption) => {
                  setOriginCountry(
                    selectedOption ? selectedOption?.isoCode3 : ""
                  );
                }}
                isClearable={true}
                isSearchable={true}
                placeholder="Origin Country"
                className={
                  originCountry?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Origin City:
              </label>
              <AsyncSearchInput
                key={resetKey}
                id="originCity"
                label="originCity"
                name="originCity"
                value={selectedOptionCity}
                loadMoreOptions={loadMoreCityOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onMenuOpen={handleMenudescountry}
                onMenuClose={handleMenuClosedescountry}
                menuIsOpen={isReactdesCountry}
                onChange={(selectedOptionCity) => {
                  setSelectedOptionCity(selectedOptionCity);
                  if (selectedOptionCity) {
                    setOriginCity(selectedOptionCity.value);
                  } else {
                    setOriginCity(null);
                  }
                }}
                placeholder="Origin City"
              />
            </Col>
            {/* <Col className="col-20-percent" md={3}>
            <label className="fontSize12 mb_12 marginR10 mt_10">
              Origin District:
            </label>
            <AsyncSearchInput
              key={resetKey}
              id="originDistrict"
              label="originDistrict"
              name="originDistrict"
              value={selectedOptionDistrict}
              loadMoreOptions={loadMoreDistrictOptions}
              shouldLoadMore={shouldLoadMore}
              getOptionLabel={(zone) => zone.label}
              getOptionValue={(zone) => zone.value}
              onMenuOpen={handleMenuOpenorgcity}
              onMenuClose={handleMenuCloseorgcity}
              menuIsOpen={isReactoriginselectCity}
              onChange={(selectedOptionDistrict) => {
                setSelectedOptionDistrict(selectedOptionDistrict);
                if (selectedOptionDistrict) {
                  setOriginDistrict(selectedOptionDistrict.value);
                } else {
                  setOriginDistrict(null);
                }
              }}
              placeholder="Origin District"
            />
          </Col> */}
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Destination Country:
              </label>
              <SearchInput
                key={resetKey}
                id="destinationCountry"
                label="destinationCountry"
                name="destinationCountry"
                options={countriesData?.data?.data?.content || []}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.isoCode3}
                onMenuOpen={handleMenuRates}
                onMenuClose={handleMenuCloseRates}
                menuIsOpen={isReactRates}
                handleChange={(selectedOption) => {
                  setDestinationCountry(
                    selectedOption ? selectedOption?.isoCode3 : ""
                  );
                }}
                isClearable={true}
                isSearchable={true}
                placeholder="Destination Country"
                className={
                  originCountry?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Destination City:
              </label>
              <AsyncSearchInput
                key={resetKey}
                id="destinationCity"
                label="destinationCity"
                name="destinationCity"
                value={selectedOptionDesCity}
                loadMoreOptions={loadMoreDesCityOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(city) => city.label}
                getOptionValue={(city) => city.value}
                onMenuOpen={handleMenuCharges}
                onMenuClose={handleMenuCloseCharges}
                menuIsOpen={isReactCharges}
                onChange={(selectedOptionDesCity) => {
                  setSelectedOptionDesCity(selectedOptionDesCity);
                  if (selectedOptionDesCity) {
                    setDestinationCity(selectedOptionDesCity.value);
                  } else {
                    setDestinationCity(null);
                  }
                }}
                placeholder="Destination City"
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Destination Zone:
              </label>
              <AsyncSearchInput
                id="destinationZone"
                label="destinationZone"
                name="destinationZone"
                value={selectedDesOption}
                loadMoreOptions={loadMoreZoneOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onMenuOpen={handleMenuOpenorgcity}
                onMenuClose={handleMenuCloseorgcity}
                menuIsOpen={isReactoriginselectCity}
                onChange={(selectedDesOption) => {
                  setSelectedDesOption(selectedDesOption);
                  setDestinationZone(
                    selectedDesOption ? selectedDesOption.value : ""
                  );
                }}
                placeholder="Destination Zone"
                menuPlacement="auto"
                menuPosition="fixed"
                menuStyle={{ zIndex: 9999 }}
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Courier:
              </label>
              <AsyncSearchInput
                key={resetKey}
                id="courier"
                label="courier"
                name="courier"
                value={selectedOptionCourier?.label}
                loadMoreOptions={loadMoreOptionsCourierFilter}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onChange={(selectedOptionCourier) => {
                  setSelectedOptionCourier(selectedOptionCourier?.label);
                  setCourier(
                    selectedOptionCourier ? selectedOptionCourier.value : ""
                  );
                }}
                onMenuOpen={handleMenudescity}
                onMenuClose={handleMenuClosedescity}
                menuIsOpen={isReactdesCity}
                placeholder="Courier"
                menuPlacement="auto"
                menuPosition="fixed"
                menuStyle={{ zIndex: 9999 }}
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Status:
              </label>
              <SearchInput
                key={resetKey}
                id="event"
                label="event"
                name="event"
                options={sortedPickupStatus}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.key}
                handleChange={(selectedOption) => {
                  setStatus(selectedOption ? selectedOption.key : "");
                }}
                onMenuOpen={handleMenudesregion}
                onMenuClose={handleMenuClosedesregion}
                menuIsOpen={isReactdesRegion}
                isClearable={true}
                isSearchable={true}
                placeholder="Status"
                className={"mainInput-dropdown"}
              />
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">
                Date Filter:
              </label>
              <SearchInput
                key={`dateFilter-${resetKey}`}
                id="dateFilter"
                label="dateFilter"
                name="dateFilter"
                options={dateFilterOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.key}
                onMenuOpen={handleMenuCurrency}
                onMenuClose={handleMenuCloseCurrency}
                menuIsOpen={isReactCurrency}
                handleChange={(selectedOption) => {
                  setFilterDate(selectedOption ? selectedOption.key : "");
                }}
                isClearable={true}
                isSearchable={true}
                placeholder="Select Date Filter"
                className={"mainInput-dropdown"}
              />
            </Col>
            {(!isReactdesCountry && !isReactRates) &&
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">Date:</label>
              <DateRangePicker
                format="yyyy-MM-dd"
                placeholder="Select Date"
                value={dateRange}
                onChange={handleDateRangeChange}
                placement="auto"
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 3))
                }
                onOpen={handleMenuState}
                onClose={handleMenuCloseState}
                showMeridian
                className="w200"
              />
            </Col>}
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">RPU:</label>
              <div className="col-md-8">
                <div className="smallCheckbox">
                  <input
                    type="checkbox"
                    id="isRpu"
                    checked={isRpu}
                    style={{ marginLeft: "5px" }}
                    onChange={() => setisRpu(!isRpu)}
                  />
                  <label className="margintop7" htmlFor="isRpu"></label>
                </div>
              </div>
            </Col>
            <Col className="col-20-percent" md={3}>
              <label className="fontSize12 mb_12 marginR10 mt_10">RTO:</label>
              <div className="col-md-8">
                <div className="smallCheckbox">
                  <input
                    type="checkbox"
                    id="isRto"
                    checked={isRto}
                    style={{ marginLeft: "5px" }}
                    onChange={() => setisRto(!isRto)}
                  />
                  <label className="margintop7" htmlFor="isRto"></label>
                </div>
              </div>
            </Col>
          </Row>
          {/* <button
              className="blue_btn mt_60ops"
              title="Clear Filters"
              onClick={resetFilters}
              style={{
                position: 'absolute',
                right: '0',
                top: '50%',
                transform: 'translateY(-50%)'
              }}
            >
              <span>
                Clear Filters
              </span>
            </button> */}
        </div>
        <div className="displayFlex alignItemCenter justifyContent_end">
          <div className="displayFlex alignItemCenter justifyContent_end mb_16">
            {(roleHandler(role, "AONE_OPS.admin") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.cancel") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.hold") ||
              roleHandler(role, "AONE_OPS.write") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.print") ||
              roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.disposed") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.lost") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_pickup") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_hold") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_delivery") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.revoke_cancel") ||
              roleHandler(role, "AONE_OPS_CONSIGNMENTS.damaged")) && (
              <div
                class="jira-dropdown"
                style={{ marginLeft: "20px" }}
                ref={dropdownRef}
              >
                {!isReactRates && !isReactCharges && !isReactdesCountry &&(
                  <button
                    className="jira-dropdown-button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    Actions
                  </button>
                )}
                <ul
                  className="jira-dropdown-content"
                  style={{
                    display: isDropdownOpen ? "block" : "none",
                    borderRadius: "10px",
                  }}
                >
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.print") ||
                    roleHandler(role, "AONE_OPS.write")) && (
                    <li onClick={() => handleBulkPrintOrders()}>
                      <a>Print</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.cancel")) && (
                    <li onClick={() => openModal(102)}>
                      <a>Cancel</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_OPS.admin")) && (
                    <li onClick={() => openModal(303)}>
                      <a>Receive at hub</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup")) && (
                    <li onClick={() => openModal(201)}>
                      <a style={{ whiteSpace: "nowrap" }}>Apply Pickup</a>
                    </li>
                  )}
                   {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.hold")) && (
                    <li onClick={() => openModal(802)}>
                      <a style={{ whiteSpace: "nowrap" }}>Apply Hold</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.lost")) && (
                    <li onClick={() => openModal(800)}>
                      <a>Apply Lost</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.damaged")) && (
                    <li onClick={() => openModal(801)}>
                      <a>Apply Damaged</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_CONSIGNMENTS.disposed")) && (
                    <li onClick={() => openModal(803)}>
                      <a>Apply Disposed</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_ADMIN") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write")) && (
                    <li onClick={handleAssignCourier}>
                      <a style={{ whiteSpace: "nowrap" }}>
                        Assign for delivery
                      </a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_ADMIN") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(
                      role,
                      "AONE_OPS_CONSIGNMENTS.revoke_pickup"
                    )) && (
                    <li onClick={() => openModal(804)}>
                      <a style={{ whiteSpace: "nowrap" }}>Revoke Pickup</a>
                    </li>
                  )}
                   {(roleHandler(role, "AONE_ADMIN") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(
                      role,
                      "AONE_OPS_CONSIGNMENTS.revoke_hold"
                    )) && (
                    <li onClick={() => openModal(807)}>
                      <a style={{ whiteSpace: "nowrap" }}>Revoke Hold</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_ADMIN") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(
                      role,
                      "AONE_OPS_CONSIGNMENTS.revoke_delivery"
                    )) && (
                    <li onClick={() => openModal(806)}>
                      <a style={{ whiteSpace: "nowrap" }}>Revoke Delivery</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_ADMIN") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(
                      role,
                      "AONE_OPS_CONSIGNMENTS.revoke_cancel"
                    )) && (
                    <li onClick={() => openModal(805)}>
                      <a style={{ whiteSpace: "nowrap" }}>Revoke Cancel</a>
                    </li>
                  )}
                  {(roleHandler(role, "AONE_ADMIN") ||
                    roleHandler(role, "AONE_OPS.admin") ||
                    roleHandler(
                      role,
                      "AONE_CONSIGNMENTS_CONSIGNMENT.edit_ddu_amount"
                    )) && (
                    <li onClick={() => openUpload()}>
                      <a style={{ whiteSpace: "nowrap" }}>Upload DDU</a>
                    </li>
                  )}
                </ul>
              </div>
            )}
            <button
              className="filterBtn plus_icon mleft_10"
              title="Export"
              onClick={() => exporttoExcel()}
            >
              <span>
                <img src={downloadIcon} className="h18" />
              </span>
            </button>
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
            <div ref={ref} className="customFilter mleft_5p">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={DotboldIcon} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ul className="listColumn cursorPointer">
                      {column.map((d, i) => (
                        <li key={d}>
                          <div className="smallCheckbox mb_4">
                            <input
                              id={`product${i}`}
                              type="checkbox"
                              checked={!checkedCol.includes(d)}
                            />
                            <label
                              htmlFor={`product${i}`}
                              onClick={() => handleSelect(d)}
                            >
                              {d}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <PaginationTable
          tableData={consignmentData}
          setHeader={setHeaderParams}
          tbl={"ConsignmentPickup"}
          restrict={checkedCol}
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          isDropdownOpens={isDropdownOpen}
          setTrackId={setTrackId}
          latitude={latitude}
          longitude={longitude}
          currentTimeMillis={currentTimeMillis}
          eventTimeZone={eventTimeZone}
          pickupTitle={pickupTitle}
          setPickupTitle={setPickupTitle}
          setSelectPickup={setSelectPickup}
          setSelectOriginHub={setSelectOriginHub}
          selectPickup={selectPickup}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setSize={setSize}
          seleczone={seleczone}
          setIsChange={setIsChange}
          isChange={isChange}
          SetshowPrCustomer={SetshowPrCustomer}
          setCancelRequestId={setCancelRequestId}
          setCancelCourierId={setCancelCourierId}
          setselectZone={setselectZone}
          setCusPage={setCusPage}
          selecwaybill={selecwaybill}
          setSelectWaybill={setSelectWaybill}
          selectPickuprequest={selectPickuprequest}
          setSelectPickuprequest={setSelectPickuprequest}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
          totalCount={totalCount}
          setSortField={setSortField}
          sortField={sortField}
          statusHandler={statusHandler}
          setStatusHandler={setStatusHandler}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
        <Modal
          show={showConfirm}
          onHide={handleCloseConfirm}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">{modalTitle}</h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-12">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Consignments
                    <span className="bloodDonortext fontWeight600 fontSize16">
                   *
                 </span>
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <textarea
                      ref={textareaRef}
                      className="washMe_btn"
                      style={{ paddingTop: "6px", height: "90px" }}
                      disabled
                      value={consignmentString}
                    />
                  </div>
                </div>
                {actionCode == 802 &&
               <div className="col-md-12">
               <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                 Hold Reason:
               </label>
               <SearchInput
                 key={resetKey}
                 id="reasonCode"
                 label="reasonCode"
                 name="reasonCode"
                 options={holdReasonsData?.data?.data?.content || []}
                 getOptionLabel={(option) => option.message}
                 getOptionValue={(option) => option.code}
                 handleChange={(selectedOption) => {
                  setHoldReason(selectedOption ? selectedOption?.code : null);
                 }}
                 isClearable={true}
                 isSearchable={true}
                 placeholder="Select the Hold reason"
                 className="mainInput"
               />
             </div>}
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={() => {
                    if (actionCode === 804) {
                      revokePickup();
                    } else if (actionCode === 805) {
                      revokeCancel();
                    } else if (actionCode === 806) {
                      revokeDelivery();
                    } else if (actionCode === 807) {
                      revokeHold();
                    } else {
                      applyPickupOrder(actionCode);
                    }
                    handleCloseConfirm();
                  }}
                >
                  Confirm
                </button>
              </div>
              <div>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseConfirm()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
        <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">Upload the document to update DDU for consignments</h4>
          </Modal.Header>
          <Modal.Body>
                <div className="row">
              <div className="col-md-8">
                <div className="custom-file-upload">
                  <input
                    type="file"
                    onChange={(e) => handleDduFileUpload(e)}
                    name="insuranceDocument"
                    className="mainInput"
                    id="insurance-upload" />
                  <label htmlFor="insurance-upload" className="upload-label">
                    Upload File to update DDU for consignments
                  </label>
                  {file?.name &&
                  <><div className="document-already-uploaded">
                        <span className="document-status">
                          Document Status: Uploaded
                        </span>
                      </div><div className="file-info">
                          <p className="file-name">
                            <p>{file?.name}</p>
                          </p>
                        </div></>}
                        {fileErr ? (
                          <p className="requiredText">
                            {fileErr}
                          </p>
                        ) : null}
                </div>
              </div>
              <div className="col-md-4 mt_10">
              <button
                      className="filterBtn plus_icon mr_10 ml_10 mt_5"
                      title={"DDU Update Template"}
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
              </div>
              </div>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={() => {
                    handleFileChange();
                  }}
                >
                  Upload
                </button>
              </div>
              <div>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseUpload()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Assign courier
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Tracking Id
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <textarea
                      ref={textareaRef}
                      className="washMe_btn"
                      style={{ paddingTop: "6px" }}
                      disabled
                      value={trackingIdsString}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Courier
                  </label>
                  <div
                    className="customDateRange"
                    style={{ color: "black", position: "relative" }}
                  >
                    <AsyncSearchInput
                      id="employeeCode"
                      label="employeeCode"
                      name="employeeCode"
                      value={selectedOptionCouriers?.label}
                      loadMoreOptions={loadMoreOptionCourierFilter}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onChange={(selectedOptionCouriers) => {
                        setSelectedOptionCouriers(
                          selectedOptionCouriers?.label
                        );
                        addDeliveryTask.setFieldValue(
                          "employeeCode",
                          selectedOptionCouriers
                            ? selectedOptionCouriers.value
                            : ""
                        );
                      }}
                      placeholder="Courier"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                    {addDeliveryTask.touched.employeeCode &&
                    addDeliveryTask.errors.employeeCode ? (
                      <p className="requiredText">
                        {addDeliveryTask.errors.employeeCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={addDeliveryTask.handleSubmit}
                >
                  Assign
                </button>
              </div>
              <div>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
      </div>
    </div>
  );
};

export default Consignments;
