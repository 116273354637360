import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getVehicles,getVehiclesbyCode,postVehicles,editVehicles,VehiclesTemplate,importVehicles,exportVehicles,deleteVehicles,getDimensionUnit,getVehicleDocumentType,getVehicleType,getWeightUnit,
  getVehicleDocument,getStatus,getVehicleDocumentbycode,deleteVehicleDocuments,activateVehicleDocs,deactivateVehicleDocs,postVehicleDocument,getVehiclesDocsbyCode,importVehiclesDocuments,
  getVehicleCategory,getVehicleCategorybyCode,postVehicleCategory,EditVehicleDocument,editVehicleCategory,VehicleCategoryTemplate,importVehicleCategory,exportVehicleCategory,deleteVehicleCategory,getVehicleVendor,
  getVehicleVendorbyCode,postVehicleVendor,getVehicleStatus,editVehicleVendor,VehicleVendorTemplate,importVehicleVendor,exportVehicleVendor,deleteVehicleVendor,getDriverCourierList,getOdoMeter,postOdoMeter,deleteOdoMeter,
  getGpsvendor,postGpsVendor,deleteGpsvendor,editGpsvendor,GpsvendorTemplate,importGpsvendor,exportGpsvendor,getReplacementVehicles
} from "../../services/VehicleMgt/Vehicle_services";
const initialValues = {
  vehicleList: [],
  vehicleGpsvendorList: [],
  vehicleVendorList: [],
  vehicleCategoryList: [],
  VehicleDimesionUnits: [],
  VehicleDocumentTypes: [],
  VehicleStatusList:[],
  VehicleStatusDoclist: [],
  VehicleTypes: [],
  VehicleWeightunit: [],
  VehicleCouriersList:[],
  vehicleReplacementList:[]
};
let vehicleStore = (set, get) => ({
  ...initialValues,
  getVehicles: async (params) => {
        const response = await getVehicles(params);
        set({ vehicleList: response });
        return response;
      },
      getReplacementVehicles: async (params) => {
        const response = await getReplacementVehicles(params);
        set({ vehicleReplacementList: response });
        return response;
      },
      getVehiclesbyCode: async (params) => {
        const response = await getVehiclesbyCode(params);
        return response;
      },
      postVehicles: async (params) => {
        const response = await postVehicles(params);
        return response;
      },
      editVehicles: async (params,data) => {
        const response = await editVehicles(params,data);
        return response;
      },
      VehiclesTemplate: async () => {
        const response = await VehiclesTemplate();
        return response;
      },
      importVehicles: async (params) => {
        const response = await importVehicles(params);
        return response;
      },
      exportVehicles: async (params) => {
        const response = await exportVehicles(params);
        return response;
      },
      deleteVehicles: async (params) => {
        const response = await deleteVehicles(params);
        return response;
      },
      getGpsvendor: async (params) => {
        const response = await getGpsvendor(params);
        set({ vehicleGpsvendorList: response });
        return response;
      },
      postGpsVendor: async (params) => {
        const response = await postGpsVendor(params);
        return response;
      },
      editGpsvendor: async (params,data) => {
        const response = await editGpsvendor(params,data);
        return response;
      },
      GpsvendorTemplate: async () => {
        const response = await GpsvendorTemplate();
        return response;
      },
      importGpsvendor: async (params) => {
        const response = await importGpsvendor(params);
        return response;
      },
      exportGpsvendor: async (params) => {
        const response = await exportGpsvendor(params);
        return response;
      },
      deleteGpsvendor: async (params) => {
        const response = await deleteGpsvendor(params);
        return response;
      },
      getDimensionUnit: async () => {
        const response = await getDimensionUnit();
        set({ VehicleDimesionUnits: response });
        return response;
      },
      getVehicleStatus: async () => {
        const response = await getVehicleStatus();
        set({ VehicleStatusDoclist: response });
        return response;
      },
      getStatus: async () => {
        const response = await getStatus();
        set({ VehicleStatusList: response });
        return response;
      },
      getVehicleDocumentType: async () => {
        const response = await getVehicleDocumentType();
        set({ VehicleDocumentTypes: response });
        return response;
      },
      getVehicleType: async () => {
        const response = await getVehicleType();
        set({ VehicleTypes: response });
        return response;
      },
      getWeightUnit: async () => {
        const response = await getWeightUnit();
        set({ VehicleWeightunit: response });
        return response;
      },
      getDriverCourierList: async (param1,param2) => {
        const response = await getDriverCourierList(param1,param2);
        set({ VehicleCouriersList: response });
        return response;
      },
      getVehicleDocument: async (params) => {
        const response = await getVehicleDocument(params);
        return response;
      },
      getVehicleDocumentbycode: async (params) => {
        const response = await getVehicleDocumentbycode(params);
        return response;
      },
      deleteVehicleDocuments: async (params) => {
        const response = await deleteVehicleDocuments(params);
        return response;
      },
      activateVehicleDocs: async (params) => {
        const response = await activateVehicleDocs(params);
        return response;
      },
      deactivateVehicleDocs: async (params) => {
        const response = await deactivateVehicleDocs(params);
        return response;
      },
      postVehicleDocument: async (params,data) => {
        const response = await postVehicleDocument(params,data);
        return response;
      },
      EditVehicleDocument: async (params,data) => {
        const response = await EditVehicleDocument(params,data);
        return response;
      },
      getVehiclesDocsbyCode: async (params) => {
        const response = await getVehiclesDocsbyCode(params);
        return response;
      },
      importVehiclesDocuments: async (param1,param2,param3,param4) => {
        const response = await importVehiclesDocuments(param1,param2,param3,param4);
        return response;
      },
      getOdoMeter: async (params) => {
        const response = await getOdoMeter(params);
        return response;
      },
      postOdoMeter: async (params) => {
        const response = await postOdoMeter(params);
        return response;
      },
      deleteOdoMeter: async (params) => {
        const response = await deleteOdoMeter(params);
        return response;
      },
      getVehicleCategory: async (params) => {
        const response = await getVehicleCategory(params);
        set({ vehicleCategoryList: response})
        return response;
      },
      getVehicleCategorybyCode: async (params) => {
        const response = await getVehicleCategorybyCode(params);
        return response;
      },
      postVehicleCategory: async (params) => {
        const response = await postVehicleCategory(params);
        return response;
      },
      editVehicleCategory: async (params,data) => {
        const response = await editVehicleCategory(params,data);
        return response;
      },
      VehicleCategoryTemplate: async () => {
        const response = await VehicleCategoryTemplate();
        return response;
      },
      importVehicleCategory: async (params) => {
        const response = await importVehicleCategory(params);
        return response;
      },
      exportVehicleCategory: async (params) => {
        const response = await exportVehicleCategory(params);
        return response;
      },
      deleteVehicleCategory: async (params) => {
        const response = await deleteVehicleCategory(params);
        return response;
      },
      getVehicleVendor: async (params) => {
        const response = await getVehicleVendor(params);
        set({ vehicleVendorList: response });
        return response;
      },
      getVehicleVendorbyCode: async (params) => {
        const response = await getVehicleVendorbyCode(params);
        return response;
      },
      postVehicleVendor: async (params) => {
        const response = await postVehicleVendor(params);
        return response;
      },
      editVehicleVendor: async (params,data) => {
        const response = await editVehicleVendor(params,data);
        return response;
      },
      VehicleVendorTemplate: async () => {
        const response = await VehicleVendorTemplate();
        return response;
      },
      importVehicleVendor: async (params) => {
        const response = await importVehicleVendor(params);
        return response;
      },
      exportVehicleVendor: async (params) => {
        const response = await exportVehicleVendor(params);
        return response;
      },
      deleteVehicleVendor: async (params) => {
        const response = await deleteVehicleVendor(params);
        return response;
      },
});

vehicleStore = devtools(vehicleStore);

export const usevehicleStore = create(vehicleStore);