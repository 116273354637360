import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Mainlogo from "../../assets/img/logo.svg";
import Arrowleft from "../../assets/img/arrowleft.svg";
import ArrowRight from "../../assets/img/arrowright.svg";
import profile from "../../assets/img/profile_picture.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../../helper/auth_helper";
import jwtDecode from "jwt-decode";

const Headers = ({ setWrapper }) => {
  const [toggleIcon, setToggleIcon] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [userHub, setUserHub] = useState("");
  const [userCode, setUserCode] = useState("");
  const token = sessionStorage.getItem("user_access_token");
  // Decode the token using jwtDecode
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    // Decode the token using jwtDecode
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserHub(decodedToken?.attributes?.hubCode);
        setUserCode(decodedToken?.attributes?.employeeCode);
        const { name } = decodedToken;
        setUsername(name);
      } catch (error) {
        console.error("Error decoding token:", error.message);
      }
    } else {
      console.error("Token not found in session storage");
    }
  }, []);
  return (
    <>
      <div className="topHeader">
        <div className="admin_logo">
          <img src={Mainlogo} />
        </div>
        <div>
          <div className="mobileAdminlogo">
            <img src={Mainlogo} />
          </div>
        </div>
        <div>
          <Dropdown className="userProfile">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="displayFlex alignItem_center cd"
            >
              {username}{" "}
              <img
                src={profile}
                alt="profile"
                style={{ width: "20px", marginLeft: "5px" }}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                transform: "translate(22px, 20px) !important",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                backgroundColor: "#fff",
                padding: "10px 0",
              }}
            >
              <Dropdown.Item
                style={{
                  padding: "5px 10px",
                  transition: "background-color 0.2s, color 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#EC171C";
                  e.target.style.color = "#fff";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#fff";
                  e.target.style.color = "#000";
                }}
              >
                Hub: {userHub}
              </Dropdown.Item>
              <Dropdown.Item
                style={{
                  padding: "5px 10px",
                  transition: "background-color 0.2s, color 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#EC171C";
                  e.target.style.color = "#fff";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#fff";
                  e.target.style.color = "#000";
                }}
              >
                Employee code: {userCode}
              </Dropdown.Item>
              <Dropdown.Item
                style={{
                  padding: "5px 10px",
                  cursor: "pointer",
                  transition: "background-color 0.2s, color 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#EC171C";
                  e.target.style.color = "#fff";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#fff";
                  e.target.style.color = "#000";
                }}
                onClick={() => {
                  sessionStorage.removeItem("user_access_token");
                  sessionStorage.removeItem("user_access_role");
                  sessionStorage.removeItem("SelectedCourier");
                  sessionStorage.removeItem("SelectCourier");
                  sessionStorage.removeItem("ValidationId");
                  sessionStorage.removeItem("DebriefingCode");
                  sessionStorage.removeItem("assignedCount");
                  sessionStorage.removeItem("scannedCount");
                  sessionStorage.removeItem("delivered");
                  sessionStorage.removeItem("deliveredPacakges");
                  sessionStorage.removeItem("assigned");
                  sessionStorage.removeItem("remainingPacakges");
                  sessionStorage.removeItem("assignedPacakges");
                  sessionStorage.removeItem("remaining");
                  sessionStorage.removeItem("isValidated");
                  sessionStorage.removeItem("collectionTracking");
                  sessionStorage.removeItem("EmployeeCurrency");
                  sessionStorage.removeItem("originHub");
                  sessionStorage.removeItem("destinationHub");
                  sessionStorage.removeItem("destinationZone");
                  sessionStorage.removeItem("ops.lineHauls.trips");
                  sessionStorage.removeItem("consignments.orders");
                  sessionStorage.removeItem("finance.transactions");
                  sessionStorage.removeItem("finance.collections");
                  sessionStorage.removeItem("finance.ledger");
                  sessionStorage.removeItem("ops.consignments");
                  sessionStorage.removeItem("ops.pickupRequest");
                  sessionStorage.removeItem("ops.pickupRequest.consignments");
                  sessionStorage.removeItem("finance.settlement");
                  sessionStorage.removeItem("ops.consolidations");
                  sessionStorage.removeItem("ops.weighing");
                  sessionStorage.removeItem("ops.rto");
                  sessionStorage.removeItem("SalesPerson");
                  localStorage.clear();
                  logout();
                }}
              >
                Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Headers;
