import React, { useState, useEffect, useContext } from "react";
import { DatePicker } from "rsuite";
import { useAoneStore } from "../../store/AoneStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
import Modal from "react-bootstrap/Modal";
import isBefore from "date-fns/isBefore";
const Accounts = ({ setTab }) => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const getCustomer = useAoneStore((state) => state?.getCustomer);
  const [data, setData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [salesPerson, setSalesPerson] = useState("");
  const getCustomerbyId = useAoneStore((state) => state?.getCustomerbyId);
  const editSalesPerson = useAoneStore((state) => state?.editSalesPerson);
  const editCustomerbyId = useAoneStore((state) => state?.editCustomerbyId);
  const addCustomer = useAoneStore((state) => state?.addCustomer);
  const customerData = useAoneStore((state) => state?.customerData);
  const getEmployee = useAoneStore((state) => state?.getEmployee);
  const setcustomerArrayData = useAoneStore(
    (state) => state?.setcustomerArrayData
  );
  const customerArrayData = useAoneStore((state) => state?.customerArrayData);
  const customerAccountData = useAoneStore(
    (state) => state?.customerAccountData
  );
  const globalcustomerAccount = useAoneStore(
    (state) => state?.globalcustomerAccount
  );
  const today = new Date();

  const shouldDisableDate = (date) => {
    // Disable dates before yesterday
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return isBefore(date, yesterday);
  };
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleCancel = () => {
    handleClose();
  };
  const setcustomerAccount = useAoneStore((state) => state?.setcustomerAccount);
  const getBillingPeriod = useAoneStore((state) => state?.getBillingPeriod);
  const getCustomerType = useAoneStore((state) => state?.getCustomerType);
  const billingperiodArray = useAoneStore((state) => state?.billingperiodArray);
  const customerType = useAoneStore((state) => state?.customerType);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      customerData?.data?.data?.content?.length === undefined ||
      customerData?.data?.data?.content?.length < 1
    ) {
      getCustomer(paramslink);
    }
    getBillingData();
  }, []);
  const getBillingData = async () => {
    if (billingperiodArray?.length < 1) {
      getBillingPeriod();
    }
    if (customerType?.length < 1) {
      getCustomerType();
    }
  };
  const getSalesEmployee = async () => {
    let params = `?page=0&size=100&department=SALES&status=true`;
    const data = await getEmployee(params);
    setSalesData(data?.data?.data?.content);
  };
  useEffect(() => {
    const savedSalesPerson = sessionStorage.getItem("SalesPerson");
    if (savedSalesPerson) {
      setSalesPerson(savedSalesPerson);
    }
  }, []);
  useEffect(() => {
    getSalesEmployee();
  }, []);
  const statusOptions = [
    { value: "", label: "None" },
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
  ];
  const handleCancelClick = () => {
    setcustomerAccount("");
    navigate("/crm/customers");
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const handleChange = (e) => {
    const newAccountNumber = e.target.value;
    // Update the local form state
    addAccount.setFieldValue("accountNumber", newAccountNumber);
    // Set the accountNumber in the global state
  };
  //save account
  const addAccount = useFormik({
    initialValues: {
      accountNumber: params?.id ? data?.accountNumber : "",
      name: params?.id ? data?.name : "",
      email: params?.id ? data?.email : "",
      type: params?.id ? data?.type : "",
      phone: params?.id ? data?.phone : "",
      status: params?.id ? data?.status : "",
      vatNo: params?.id ? data?.vatNo : "",
      crNo: params?.id ? data?.crNo : "",
      settlementTypes: params?.id ? data?.settlementTypes : "",
      billingPeriod: params?.id ? data?.billingPeriod : "",
      billingPeriodDays: params?.id ? data?.billingPeriodDays : "",
      effectiveTime: params?.id ? data?.effectiveTime : "",
      expireTime: params?.id ? data?.expireTime : "",
      deliveryAttempts: params?.id ? data?.deliveryAttempts : "",
      alias: params?.id ? data?.alias : "",
      parentAccount: params?.id ? data?.parentAccount : "",
      // noRtoFreightPercent: params?.id ? data?.noRtoFreightPercent : "",
      allowInvoice: params?.id ? data?.allowInvoice : true,
      weightRoundOff: params?.id ? data?.weightRoundOff : "",
      chargeOnActualWeight: params?.id
        ? data?.chargeOnActualWeight !== undefined
          ? Boolean(data?.chargeOnActualWeight)
          : false
        : false,
    },
    validationSchema: Yup.object({
      accountNumber: Yup.string()
        .matches(/^[^\s]+$/, "Account number cannot contain spaces")
        .required("Account Number is required")
        .min(1, "Account Number is required"),
      name: Yup.string()
        .matches(
          /^\S+(?: \S+)*$/,
          "Name cannot contain leading or trailing spaces"
        )
        .required("Name is required")
        .min(1, "Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
      phone: Yup.string()
        .required("Phone is required")
        .matches(/^[0-9]+$/, "Invalid phone number. Please enter only numbers.")
        .max(12, "Phone number cannot exceed 12 digits"),
      status: Yup.string().required("Status is required"),
      vatNo: Yup.string()
        .trim()
        .required("VAT Number is required")
        .min(1, "VAT Number is required"),
      crNo: Yup.string()
        .trim()
        .required("CR Number is required")
        .min(1, "CR Number is required"),
      billingPeriod: Yup.string().required("Billing Period is required"),
      deliveryAttempts: Yup.number()
        .required("Delivery Attempts is required")
        .typeError("Delivery Attempts must be a number")
        .max(5, "Delivery Attempts must be less than or equal to 5"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setcustomerAccount(values?.accountNumber);
      const formattedValues = {
        ...values,
        effectiveTime:
          values.effectiveTime instanceof Date
            ? values.effectiveTime.getTime()
            : 0,
        expireTime:
          values.expireTime instanceof Date ? values.expireTime.getTime() : 0,
        allowInvoice: values.allowInvoice === "" ? false : values.allowInvoice,
        chargeOnActualWeight:
          values.chargeOnActualWeight === ""
            ? false
            : values.chargeOnActualWeight,
        // noRtoFreightPercent: values.noRtoFreightPercent === "" ? null : values.noRtoFreightPercent,
      };

      const changedValues = Object.keys(values).reduce((result, key) => {
        if (key !== "billingPeriodDays" && values[key] !== data[key]) {
          result[key] = values[key];
        }
        return result;
      }, {});
      changedValues.effectiveTime =
        changedValues.effectiveTime instanceof Date
          ? changedValues.effectiveTime.getTime()
          : values?.effectiveTime;
      changedValues.expireTime =
        changedValues.expireTime instanceof Date
          ? changedValues.expireTime.getTime()
          : values?.expireTime;
      if (
        "allowInvoice" in changedValues &&
        changedValues.allowInvoice === ""
      ) {
        changedValues.allowInvoice = false;
      }

      if (
        "chargeOnActualWeight" in changedValues &&
        changedValues.chargeOnActualWeight === ""
      ) {
        changedValues.chargeOnActualWeight = false;
      }
      // if (changedValues.noRtoFreightPercent === "") {
      //   changedValues.noRtoFreightPercent = null;
      // }
      if (params.id) {
        if (Object.keys(changedValues)?.length > 0) {
          await editCustomerbyId(globalcustomerAccount, changedValues).then(
            (response) => {
              if (response?.data?.status == "ERROR") {
                const errorMessage = response?.data?.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.data?.status == "ERROR") {
                const errorMessage =
                  response?.response?.data?.msg.split(",")[0];
                toast.error(errorMessage);
              } else if (response?.response?.status === 500) {
                toast.error(response?.response?.statusText);
              } else if (response?.response?.data?.errors?.length > 0) {
                const errorMessages = response?.response?.data?.errors
                  .map((item) => `${item.key}: ${item.msg}`)
                  .join(", ");
                toast.error(`${errorMessages}`);
              } else {
                toast.success("Customer Account Updated");
                setTab(2);
                resetForm();
              }
            }
          );
        } else {
          toast.error("No changes made to update");
        }
      } else {
        await addCustomer(formattedValues).then((response) => {
          if (response?.data?.status == "ERROR") {
            const errorMessage = response.data.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key} ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.status === 500) {
            toast.error(response?.response?.statusText);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else {
            toast.success("Account Added");
            setTab(2);
            resetForm();
          }
        });
      }
    },
  });
  const [istouched, Setistouched] = useState(false);
  const handleSave = (initialValues) => {
    // Check if billingPeriod is TEN_DAYS and billingDays is empty
    if (
      addAccount.values.billingPeriod === "DAYS" &&
      !addAccount.values.billingPeriodDays
    ) {
      const existingErrors = { ...addAccount.errors };
      // Set the new error message for billingDays
      const newErrors = {
        ...existingErrors,
        billingPeriodDays: "Billing Days is required",
      };
      // Update the errors
      addAccount?.setErrors(newErrors);
      setError("Billing Days is required");
      Setistouched(true);
    } else {
      setError("");
      Setistouched(false);
      // Proceed with form submission or other actions
      addAccount?.handleSubmit();
    }
  };
  // Generate an array of values from 0.1 to 1.0
  const weightOptions = Array.from({ length: 10 }, (v, i) => {
    const value = (i + 1) / 10;
    return { label: value.toFixed(1), value: value.toFixed(1) };
  });
  const maxFreightOptions = Array.from({ length: 100 }, (v, i) => {
    const value = i + 1;
    return { label: value.toString(), value: value.toString() };
  });
  useEffect(() => {
    params?.id && getData();
    setcustomerAccount(params?.id);
  }, [params?.id]);
  useEffect(() => {
    if (params?.id) {
      const mappedFields = Object.keys(addAccount.values).reduce(
        (acc, field) => {
          acc[field] = data?.[field] || "";
          return acc;
        },
        {}
      );
      // Set form field values based on data
      addAccount.setValues((prevValues) => ({
        ...prevValues,
        ...mappedFields,
      }));
    }
  }, [data]);

  const getData = async () => {
    if (customerArrayData.length === 0) {
      const response = await getCustomerbyId(params.id);
      const customerData = response?.data?.data;

      if (customerData) {
        setcustomerArrayData(customerData);
        setData(customerData);

        if (customerData.allowInvoice !== undefined) {
          addAccount.setFieldValue(
            "allowInvoice",
            Boolean(customerData.allowInvoice)
          );
        }
        if (customerData.chargeOnActualWeight !== undefined) {
          addAccount.setFieldValue(
            "chargeOnActualWeight",
            Boolean(customerData.chargeOnActualWeight)
          );
        }
      }
    } else {
      setData(customerArrayData);
      if (customerArrayData.allowInvoice !== undefined) {
        addAccount.setFieldValue(
          "allowInvoice",
          Boolean(customerArrayData.allowInvoice)
        );
      }
      if (customerArrayData.chargeOnActualWeight !== undefined) {
        addAccount.setFieldValue(
          "chargeOnActualWeight",
          Boolean(customerArrayData.chargeOnActualWeight)
        );
      }
    }
  };
  const statusOption = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
  ];
  const [err, setErr] = useState("");
  const handleAssignSalesPerson = async () => {
    if (salesPerson == "") {
      setErr("Select the sales person to update");
    } else {
        let res = await editSalesPerson(params?.id, salesPerson);
        if (res?.status == 200 && res?.data?.data?.accountNumber !== "") {
          toast.success("Sales person assigned");
          setShow(false);
        } else if (res?.status == "ERROR") {
          const errorMessage = res?.data?.msg;
          toast.error(`${errorMessage}`);
        } else if (res?.response?.data?.errors?.length > 0) {
          const errorMessages = res?.response?.data?.errors
            ?.map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          toast.error(`${errorMessages}`);
        } else if (res?.response?.data?.status == "ERROR") {
          const errorMessage = res?.response?.data?.msg;
          toast.error(errorMessage);
        } else {
          const errorMessage = res?.data?.msg.split(",")[0];
          toast.error(errorMessage);
        }
    }
  };
  return (
    <div className="row">
      <div className="col-md-6" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Account Number:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Account Number"
              className="mainInput"
              name="accountNumber"
              value={addAccount.values.accountNumber}
              onChange={handleChange}
              disabled={params?.id !== undefined ?? true}
            />
            {(addAccount.touched.accountNumber &&
              addAccount.errors.accountNumber) ||
            (istouched && addAccount.errors.accountNumber) ? (
              <p className="requiredText">{addAccount.errors.accountNumber}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Name:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Name"
              className="mainInput"
              name="name"
              value={addAccount.values.name}
              onChange={(e) => addAccount.setFieldValue("name", e.target.value)}
            />
            {(addAccount.touched.name && addAccount.errors.name) ||
            (istouched && addAccount.errors.name) ? (
              <p className="requiredText">{addAccount.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Email:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Email"
              className="mainInput"
              name="email"
              value={addAccount.values.email}
              onChange={(e) =>
                addAccount.setFieldValue("email", e.target.value)
              }
            />
            {(addAccount.touched.email && addAccount.errors.email) ||
            (istouched && addAccount.errors.email) ? (
              <p className="requiredText">{addAccount.errors.email}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Type:</label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="type"
              label="type"
              name="type"
              isClearable={true}
              options={customerType || []}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.key}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "type",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                customerType?.find(
                  (option) => option.key === addAccount.values.type
                ) || null
              }
              isSearchable={true}
              placeholder="Select Type"
            />
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Phone:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Phone"
              className="mainInput"
              name="phone"
              value={addAccount.values.phone}
              onChange={(e) =>
                addAccount.setFieldValue("phone", e.target.value)
              }
            />
            {addAccount.touched.phone && addAccount.errors.phone ? (
              <p className="requiredText">{addAccount.errors.phone}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Vat Number:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Vat Number"
              className="mainInput"
              name="vatNo"
              value={addAccount.values.vatNo}
              onChange={(e) =>
                addAccount.setFieldValue("vatNo", e.target.value)
              }
            />
            {(addAccount.touched.vatNo && addAccount.errors.vatNo) ||
            (istouched && addAccount.errors.vatNo) ? (
              <p className="requiredText">{addAccount.errors.vatNo}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              CR Number:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="CR Number"
              className="mainInput"
              name="crNo"
              value={addAccount.values.crNo}
              onChange={(e) => addAccount.setFieldValue("crNo", e.target.value)}
            />
            {(addAccount.touched.crNo && addAccount.errors.crNo) ||
            (istouched && addAccount.errors.crNo) ? (
              <p className="requiredText">{addAccount.errors.crNo}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Settlement Types:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Settlement Types"
              className="mainInput"
              name="settlementTypes"
              value={addAccount.values.settlementTypes}
              onChange={(e) =>
                addAccount.setFieldValue("settlementTypes", e.target.value)
              }
            />
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Billing Period:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="billingPeriod"
              label="billingPeriod"
              name="billingPeriod"
              isClearable={true}
              options={billingperiodArray || []}
              getOptionLabel={(option) => option.key}
              getOptionValue={(option) => option.key}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "billingPeriod",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                billingperiodArray?.find(
                  (option) => option.key === addAccount.values.billingPeriod
                ) || null
              }
              isSearchable={true}
              placeholder="Select Billing Period"
            />

            {(addAccount.touched.billingPeriod &&
              addAccount.errors.billingPeriod) ||
            (istouched && addAccount.errors.billingPeriod) ? (
              <p className="requiredText">{addAccount.errors.billingPeriod}</p>
            ) : null}
          </div>
        </div>
        {addAccount?.values.billingPeriod === "DAYS" && (
          <div className="row mb_22">
            <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
              <label>Billing Days:</label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                placeholder="Billing Days"
                className="mainInput"
                name="billingPeriodDays"
                value={addAccount.values.billingPeriodDays}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Check if the input consists only of numeric characters
                  if (/^\d*$/.test(inputValue)) {
                    addAccount.setFieldValue("billingPeriodDays", inputValue);
                    setError("");
                  }
                }}
              />
              {error ? <p className="requiredText">{error}</p> : null}
            </div>
          </div>
        )}
        <div className="row mb_22 positionRelative">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Parent Account:</label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="parentAccount"
              label="parentAccount"
              name="parentAccount"
              isClearable={true}
              options={customerData?.data?.data?.content || []}
              getOptionLabel={(region) => region.name}
              getOptionValue={(region) => region.accountNumber}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "parentAccount",
                  selectedOption ? selectedOption.accountNumber : ""
                )
              }
              value={
                customerData?.data?.data?.content?.find(
                  (account) =>
                    account.accountNumber === addAccount.values.parentAccount
                ) || null
              }
              isSearchable={true}
              placeholder="Select Parent Account"
            />
          </div>
        </div>
        {(roleHandler(role, "AONE_CRM.write") ||
          roleHandler(role, "AONE_CRM.admin") ||
          roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
          roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
          roleHandler(role, "AONE_CONFIGS.write") ||
          roleHandler(role, "AONE_CONFIGS.admin") ||
          roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
          roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) && (
          <div className="row mb_22">
            <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
              <button type="button" className="blue_btn" onClick={handleSave}>
                Save
              </button>
              <button onClick={handleCancelClick} className="cancel_btn">
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Effective Time:</label>
          </div>
          <div className="col-md-8" style={{ color: "black" }}>
            <DatePicker
              placeholder="Effective Time"
              placement="rightStart"
              onChange={(date) => {
                addAccount.setFieldValue("effectiveTime", date);
              }}
              onClean={() => addAccount.setFieldValue("effectiveTime", null)}
              value={
                addAccount.values.effectiveTime
                  ? new Date(addAccount.values.effectiveTime)
                  : null
              }
              className="higher-z-index"
            />
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Expire Time:</label>
          </div>
          <div className="col-md-8" style={{ color: "black" }}>
            <DatePicker
              placeholder="Expire Time"
              placement="rightStart"
              onChange={(date) => {
                addAccount.setFieldValue("expireTime", date);
              }}
              value={
                addAccount.values.expireTime
                  ? new Date(addAccount.values.expireTime)
                  : null
              }
              onClean={() => addAccount.setFieldValue("expireTime", null)}
              defaultValue={new Date()}
              shouldDisableDate={shouldDisableDate}
            />
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Delivery Attempts:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Delivery Attempts"
              className="mainInput"
              name="deliveryAttempts"
              value={addAccount.values.deliveryAttempts}
              onChange={(e) =>
                addAccount.setFieldValue("deliveryAttempts", e.target.value)
              }
            />
            {(addAccount.touched.deliveryAttempts &&
              addAccount.errors.deliveryAttempts) ||
            (istouched && addAccount.errors.deliveryAttempts) ? (
              <p className="requiredText">
                {addAccount.errors.deliveryAttempts}
              </p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>
              Status:{" "}
              <span className="bloodDonortext fontWeight600 fontSize16">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="status"
              label="status"
              name="status"
              isClearable={true}
              options={statusOption}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "status",
                  selectedOption ? selectedOption.value : ""
                )
              }
              onBlur={addAccount.handleBlur}
              value={
                statusOption.find(
                  (option) => option.value === addAccount.values.status
                ) || null
              }
              isSearchable={false}
              placeholder="Select Status"
            />

            {(addAccount.touched.status && addAccount.errors.status) ||
            (istouched && addAccount.errors.status) ? (
              <p className="requiredText">{addAccount.errors.status}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Alias:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Alias"
              className="mainInput"
              name="alias"
              value={addAccount.values.alias}
              onChange={(e) =>
                addAccount.setFieldValue("alias", e.target.value)
              }
            />
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label htmlFor="allowInvoice">Allow Invoice:</label>
          </div>
          <div className="col-md-8">
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="allowInvoice"
                className="mt_5 ml_5 br5"
                checked={addAccount.values.allowInvoice}
                onChange={() => {
                  addAccount.setFieldValue(
                    "allowInvoice",
                    !addAccount.values.allowInvoice
                  );
                  if (!addAccount.values.allowInvoice) {
                    addAccount.setFieldValue("allowInvoice", true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Weight Round off:</label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="weightRoundOff"
              label="Weight Round Off"
              name="weightRoundOff"
              options={weightOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={
                addAccount.values.weightRoundOff
                  ? weightOptions.find(
                      (option) =>
                        option.value == addAccount.values.weightRoundOff
                    )
                  : null
              }
              handleChange={(selectedOption) => {
                // Update form field when an option is selected
                addAccount.setFieldValue(
                  "weightRoundOff",
                  selectedOption ? selectedOption.value : ""
                );
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="Weight Round Off"
            />
          </div>
        </div>
        <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label htmlFor="chargeOnActualWeight">
              Charge on actual weight:
            </label>
          </div>
          <div className="col-md-8">
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="allowInvoice"
                className="mt_5 ml_5 br5"
                checked={addAccount.values.chargeOnActualWeight}
                onChange={() => {
                  addAccount.setFieldValue(
                    "chargeOnActualWeight",
                    !addAccount.values.chargeOnActualWeight
                  );
                }}
              />
            </div>
          </div>
        </div>
        {roleHandler(role, "AONE_CRM_CUSTOMERS.salesperson") && (
          <div className="row mb_22">
            <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
              <label>Associates SalesPerson:</label>
            </div>
            <div className="col-md-8">
              <SearchInput
                id="salesperson"
                label="salesperson"
                name="salesperson"
                isClearable={true}
                options={salesData || []}
                getOptionLabel={(option) =>
                  `${option.firstName} - ${option.lastName}`
                }
                getOptionValue={(region) => region.code}
                handleChange={(selectedOption) => {
                  setSalesPerson(selectedOption ? selectedOption.code : "");
                  if (selectedOption?.id) {
                    setErr("");
                    setShow(true);
                  }
                }}
                value={
                  salesData?.find((account) => account.code === salesPerson) ||
                  null
                }
                isSearchable={true}
                placeholder="Associates SalesPerson"
              />
            </div>
          </div>
        )}
        {/* <div className="row mb_22">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>No RTO Freight Percent:</label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="noRtoFreightPercent"
              name="noRtoFreightPercent"
              options={maxFreightOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={
                addAccount.values.noRtoFreightPercent
                  ? maxFreightOptions.find(
                      (option) =>
                        option.value == addAccount.values.noRtoFreightPercent
                    )
                  : null
              }
              handleChange={(selectedOption) => {
                // Update form field when an option is selected
                addAccount.setFieldValue(
                  "noRtoFreightPercent",
                  selectedOption ? selectedOption.value : ""
                );
              }}
              isClearable={true}
              isSearchable={true}
              placeholder="No RTO Freight Percent"
            />
          </div>
        </div> */}
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">
            Update Associates salesperson for customer
          </h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  Associates SalesPerson
                </label>
                <SearchInput
                  id="salesperson"
                  label="salesperson"
                  name="salesperson"
                  isClearable={true}
                  options={salesData || []}
                  getOptionLabel={(option) =>
                    `${option.firstName} - ${option.lastName}`
                  }
                  getOptionValue={(region) => region.code}
                  handleChange={(selectedOption) => {
                    setSalesPerson(selectedOption ? selectedOption.code : "");
                    setErr("");
                  }}
                  value={
                    salesData?.find(
                      (account) => account.code === salesPerson
                    ) || null
                  }
                  isSearchable={true}
                  placeholder="Associates SalesPerson"
                />
                {err ? <p className="requiredText">{err}</p> : null}
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
            <div>
              <button
                type="button"
                className="blue_btn"
                onClick={handleAssignSalesPerson}
              >
                Update
              </button>
            </div>
            <div>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default Accounts;
