import React, { useState, useEffect, useRef } from 'react'
import MainTable from '../../components/Tables/mtable'
import PaginationTable from '../../components/Tables/paginationTable';
import { useAoneStore } from '../../store/AoneStore'
import exportFromJSON from "export-from-json";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../assets/img/dotsbold.svg";
import download_icon from "../../assets/img/download_icon.svg";
import jwtDecode from 'jwt-decode';

const ZidProducts = () => {

    const getZidProducts = useAoneStore((state) => state?.getZidProducts)
    const syncZidProduct = useAoneStore((state) => state?.syncZidProduct)
    const getZidProductsByStoreId = useAoneStore((state) => state?.getZidProductsByStoreId)
    const syncZidProductByStoreId = useAoneStore((state) => state?.syncZidProductByStoreId)

    const [ modalHandler , setModalHandler ] = useState(false)

    const [ ZidProducts, setZidProducts ] = useState([])

    const [ customerCode, setCustomerCode ] = useState("")
    const [ storeId, setStoreId ] = useState("")
    const [ newPage, setNewPage ] = useState(0)
    const [ size, setSize ] = useState(20)
    const [ totalCount, setTotalCount] = useState(0)

    const disabler = useRef(false)

    const [column, SetCoulmn] = useState(["storeId","productName","sku","quantity","parentProduct"]);
    const [checkedCol, setCheckedCol] = useState([]);

    const fileName = "Zid_Products";
    const exportType = "csv";

    const exportToExcel = () => {
        let data = ZidProducts.map((e) => ({ 
            storeId : e.storeId,
            productName: e.productName.en,
            SKU: e.sku,
            quantity: e.quantity
        }))
        exportFromJSON({ data, fileName, exportType });
    };

    const handleSelect = (d) => {
        let val = checkedCol.includes(d);
        if (val === true) {
          // alert("true")
          setCheckedCol(checkedCol.filter((dat) => dat !== d));
        } else {
          setCheckedCol([...checkedCol, d]);
        }
    };

    const getData = async () => {
        const params = `page=${newPage}&size=${size}` + 
          (customerCode.toString().length > 0 ? `&customerCode=${customerCode}` : `` ) + 
          (disabler.current ? `&storeId=${fixedStoreId()}` : storeId.toString().length > 0 ? `&storeId=${storeId}` : ``)
        const data = await getZidProducts(params)
        setTotalCount(data.data.totalElements)
        if(data.data.content.length > 0){
            setZidProducts(data.data.content)
        }else{
            setZidProducts([])
        }
    }

    const syncHandler = async () => {
      if(storeId.length > 0) {
        await syncZidProductByStoreId(storeId);
        getData();
      }else{
        await syncZidProduct();
        getData();
      }  
    }

    const filterDisabler = () => {
      if(window.sessionStorage.getItem("user_access_token")){
      let token = jwtDecode(window.sessionStorage.getItem("user_access_token"))
      if(token.zid_store_id){
        setStoreId(token.zid_store_id.toString())
        disabler.current = true
      }
      }
    }

    const fixedStoreId = () => {
      if(window.sessionStorage.getItem("user_access_token")){
        let token = jwtDecode(window.sessionStorage.getItem("user_access_token"))
        if(token.zid_store_id){
          return token.zid_store_id.toString()
        }
      }
    }

    const clearAll = () => {
      if(disabler.current){
        setCustomerCode("")
        setNewPage(0)
      }else{
        setStoreId("")
        setCustomerCode("")
        setNewPage(0)
      }
    }    

    useEffect(()=>{
        filterDisabler();
        getData();
    },[modalHandler,newPage,size])

  return (
    <div>
        {/* FILTER FRAME */}
        <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Filters</div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className="row alignItemCenter mb_12">
                        <div className='col-md-12'>
                            <label className="fontSize14">Customer code: </label>
                            <input
                            type="text"
                            className={customerCode?.length > 0 ? "mainInput" : "mainInput withSearchIcon"}
                            style={
                              customerCode?.length > 0
                                ? { border: '1px solid #ec171c',width: "auto", marginLeft: "5px" }
                                :{ width: "auto", marginLeft: "5px"}
                            }
                            value={customerCode}
                            onChange={(e)=>setCustomerCode(e.target.value)}
                            />
                        </div>    
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className="row alignItemCenter mb_12">
                        <div className='col-md-12'>
                            <label className="fontSize14">Store Id: </label>
                            <input
                            type="text"
                            className={storeId?.length > 0 ? "mainInput" : "mainInput withSearchIcon"}
                            style={
                              storeId?.length > 0
                                ? { border: '1px solid #ec171c',width: "auto", marginLeft: "5px" }
                                :{ width: "auto", marginLeft: "5px"}
                            }
                            value={storeId}
                            onChange={(e)=>setStoreId(e.target.value.replace(/[^0-9]/g, ""))}
                            disabled={disabler.current}/>
                        </div>    
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className="row alignItemCenter mb_12">
                        <div className="col-md-12">
                            <button className="filterBtn" style={{ width: "30%"}} onClick={()=>(setNewPage(0),getData())}>Filter</button> &nbsp;
                            <button className="filterBtn" style={{ width: "30%"}} onClick={()=>clearAll()}>Clear</button> &nbsp;
                            <button className="filterBtn" style={{ width: "30%"}} onClick={()=>syncHandler()}>Sync</button>
                        </div>     
                    </div>
                </div>    
            </div>
        </div>

        <div className="displayFlex alignItemCenter justifyContent_end gap_16 mb_16">
          <div>
            <button onClick={exportToExcel} className="filterBtn">
              <span><img src={download_icon} className='h18'/></span>
            </button>
          </div>
          <div className="customFilter">
            <Dropdown autoClose="outside">
              <Dropdown.Toggle id="dropdown-basic">
                <img src={DotboldIcon} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <ul className="listColumn">
                    {column.map((d, i) => {
                      return (
                        <>
                          <li key={d}>
                            <div className="smallCheckbox mb_4">
                              <input
                                id={`product${i}`}
                                type="checkbox"
                                checked={!checkedCol?.includes(d)}
                              />

                              <label
                                htmlFor={`product${i}`}
                                onClick={() => handleSelect(d)}
                              >
                                {" "}
                                {d}{" "}
                              </label>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>        

        {/* TABLE FRAME */}
        <PaginationTable tableData={ZidProducts} tbl="ZIA" modalHandler={modalHandler} setModalHandler={setModalHandler} 
            title={"Edit ZID Product"} restrict={checkedCol} newPage={newPage} setNewPage={setNewPage} size={size} setSize={setSize} totalCount={totalCount}/>
    </div>
  )
}

export default ZidProducts