import React, { useEffect, useState, useRef } from "react";
import  delete_icon from "../../../assets/img/delete_icon.svg" 

const AddSlabtn = ({
  title,
  handleClose,
  handleCancel,
  onSub,
  setEnableEdit,
  initialData,
  handleDelete
}) => {
  const [edit, setEdit] = useState(false);
  const [editCancel, setEditCancel] = useState(false);

  const editable = () => {
    setEdit(!edit);
    setEnableEdit(!edit);
    setEditCancel(!editCancel);
  };
  const subfun = (e) => {
    e.preventDefault();
    onSub(e);
  };
  return (
    <>
    <div>
    { title == "View SLA" &&   <img src={delete_icon} onClick={()=>handleDelete(initialData.id)}/>}
    </div>
      <div className="displayFlex alignItemCenter justifyContent_end gap_16">
        {title == "Add New SLA" && (
          <>
            <button
              type="reset"
              className="empty_btn textDecoration_underline menacingCloudsText"
              onClick={editCancel == true ? handleCancel : handleClose}
            >
              Cancel
            </button>
            <button
              className="Mainbutton  "
              onClick={subfun}
              disabled={
                initialData.serviceType.length === 0 &&
                initialData.slaMinutes === 0 &&
                initialData.country.length === 0 &&
                initialData.originCity.length === 0 &&
                initialData.destinationCity.length === 0
              }
            >
              Submit
            </button>
          </>
        )}
        {title == "View SLA" && edit == true && (
          <>
            <button
              type="reset"
              className="empty_btn textDecoration_underline menacingCloudsText"
              onClick={editCancel == true ? handleCancel : handleClose}
            >
              Cancel
            </button>
            <button className="Mainbutton  " onClick={subfun}>
              Save Changes
            </button>
          </>
        )}

        {title == "View SLA" && edit == false && (
          <>
            <button
              type="reset"
              className="empty_btn textDecoration_underline menacingCloudsText"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="Mainbutton  " onClick={editable}>
              Edit
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default AddSlabtn;
