import React, { useState, useEffect, useRef, useContext } from "react";
import syncicon from "../../../src/assets/img/sync.svg";
import MainTable from "../../components/Tables/mtable";
import OpenModal from "../../components/modal/openModal";
import { useAoneStore } from "../../store/AoneStore";
import arrowRight from "../../assets/img/arrowright.svg";
import removeIcon from "../../assets/img/cancelIcon.svg";
import arrowDown from "../../assets/img/arrowdown.svg";
import { Tree } from "react-arborist";
import assignIcon from "../../assets/img/assign_icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import doticon from "../../assets/img/dot_menu.svg";
import { useParams, useNavigate } from "react-router-dom";
import AssignerModal from "../../components/modal/AssignerModal";
import DeletingModal from "../../components/modal/deletingModal";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UserContext } from "../../helper/ProtectedRoute";
const Groups = () => {
  const getGroups = useAoneStore((state) => state?.getGroups);
  const getGroupsTree = useAoneStore((state) => state?.getGroupsTree);
  const getSubGroups = useAoneStore((state) => state?.getSubGroups);
  const syncGroups = useAoneStore((state) => state?.syncGroups);
  const renameGroups = useAoneStore((state) => state?.renameGroups);
  const getUsersByGroupId = useAoneStore((state) => state?.getUsersByGroupId);
  const getAssignedRolesByGroupId = useAoneStore(
    (state) => state?.getAssignedRolesByGroupId
  );
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const group = useRef([]);
  const prevent = useRef(false);

  const params = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [subName, setSubName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [highlightId, setHighlightId] = useState("");
  const [title, setTitle] = useState("Add Group");
  const [groups, setGroups] = useState([]);
  const [filteredGroup, setFilteredGroup] = useState([]);
  const [subGroups, setSubGroups] = useState([]);
  const [active, setActive] = useState(false);
  const [renameId, setRenameId] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState([]);
  const [filteredRole, setFilteredRole] = useState([]);
  const [roles, setRoles] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [assignerHandler, setAssignerHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false); // To Update While Delete - Complexity
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [subDeleteHandler, setSubDeleteHandler] = useState(false);

  const [subTab, setSubTab] = useState(1);

  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const deleteParamsSetter = (id, name, title) => {
    setDeleteParams({
      id: id,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };
  const Node = ({ node, tree }) => {
    return (
      active && (
        <div
          style={{
            color: `${highlightId == node.data.keycloakGroupId && "#EC171C"}`,
          }}
          className="cursorPointer displayFlex justifyContent_spacebetween alignItemCenter"
        >
          <div>
            <span style={{ marginLeft: `${node.level * 15}px` }}> </span>
            {node.children ? (
              <img
                src={node.isOpen ? arrowDown : arrowRight}
                alt="arrow"
                onClick={() => node.toggle()}
              />
            ) : (
              <span style={{ marginLeft: "20px" }}> </span>
            )}
            <span onClick={() => SubGroupHandler(node, tree)}>
              {node.data.name}
            </span>
          </div>
          {node.level == 0 && (
            <div>
              {(role?.includes("AONE_ADMIN") ||
                role?.includes("AONE_IAM.write") ||
                role?.includes("AONE_IAM.admin") ||
                role?.includes("AONE_IAM_GROUPS.write") ||
                role?.includes("AONE_IAM_GROUPS.admin")) && (
                <Dropdown className="groupHeadButton">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="displayFlex alignItem_center"
                  >
                    <img
                      src={doticon}
                      alt="profile"
                      style={{ width: "20px" }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      transform: 'translate("22px", "20px") !important',
                    }}
                  >
                    <Dropdown.Item
                      onClick={() =>
                        renameGroup(node?.data?.id, node?.data?.name)
                      }
                    >
                      Rename
                    </Dropdown.Item>
                    {(role?.includes("AONE_ADMIN") ||
                      role?.includes("AONE_IAM.admin") ||
                      role?.includes("AONE_IAM_GROUPS.admin")) && (
                      <Dropdown.Item
                        onClick={() =>
                          deleteParamsSetter(
                            node?.data?.id,
                            node?.data?.name,
                            "Group"
                          )
                        }
                      >
                        Delete
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
        </div>
      )
    );
  };

  const treeData = async () => {
    const data = await getGroupsTree();
    if (data?.data?.data?.length > 0) {
      let values = data.data.data;
      group.current = values;
      setGroups(values);
      setFilteredGroup(values);
      !highlightId && setSubGroups(values);
      setSubName("");
      setActive(true);
    }
  };
  useEffect(() => {
    if (name?.length > 0) {
      const filtered = filteredGroup.filter((group) =>
        group?.name?.toLowerCase()?.includes(name?.toLowerCase())
      );
      setGroups(filtered);
    } else {
      setGroups(filteredGroup);
    }
  }, [name]);
  const syncronizeGroups = async () => {
    await syncGroups();
    treeData();
  };
  const SubGroupHandler = (node, tree) => {
    tree.closeAll();
    setSubTab(1);
    setHighlightId(node?.data?.keycloakGroupId);
    setSubGroups(node?.data?.subGroups === null ? [] : node?.data?.subGroups);
    navigate(`/iam/groups/${node?.data?.id}`);
  };

  const getSubGroupsData = async () => {
    let data = await getSubGroups(highlightId);
    let values = data?.data?.data;
    let modifyValues =
      values?.length > 0 ? values?.map((e) => ({ id: e.id, name: e.name })) : [];
    setSubGroups(modifyValues);
    treeData();
  };

  const subSearch = (data) => {
    if (subName?.length == 0) {
      return data;
    } else {
      let subData = data?.filter((e) =>
        e?.name?.toLowerCase()?.includes(subName?.toLowerCase())
      );
      return subData;
    }
  };

  const subTabHandler = async (i) => {
    setSubName("");
    if (i == 2) {
      const data = await getUsersByGroupId(params?.id);
      setUsers(data?.data?.data);
      setFilteredUser(data?.data?.data);
      // setTotalCount(data?.data?.data?.totalElements)
    } else if (i == 3) {
      const data = await getAssignedRolesByGroupId(params?.id);
      setRoles(data?.data?.data ?? []);
      setFilteredRole(data?.data?.data);
    }
    setSubTab(i);
  };
  useEffect(() => {
    if (subTab === 2) {
      if (subName?.length > 0) {
        const filtered = filteredUser?.filter((user) =>
          user?.username?.toLowerCase()?.includes(subName?.toLowerCase())
        );
        setUsers(filtered);
      } else {
        setUsers(filteredUser);
      }
    } else if (subTab === 3) {
      if (subName?.length > 0) {
        const filtered = filteredRole?.filter((roles) =>
          roles?.name?.toLowerCase()?.includes(subName?.toLowerCase())
        );
        setRoles(filtered);
      } else {
        setRoles(filteredRole);
      }
    }
  }, [subName]);

  useEffect(() => {
    highlightId ? getSubGroupsData() : treeData();
  }, [addModalHandler, deleteHandler]);
  useEffect(() => {
    subTabHandler(subTab);
  }, [assignerHandler, subDeleteHandler]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleCancel = () => {
    handleClose();
  };
  const renameGroup = (id, name) => {
    setRenameId(id);
    changeGroupname.setFieldValue("groupName", name);
    setShow(true);
  };
  const changeGroupname = useFormik({
    initialValues: {
      groupName: "",
    },
    validationSchema: Yup.object({
      groupName: Yup.string().trim().required("Group Name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await renameGroups(renameId, values).then((response) => {
        handleClose();
        if (response?.status == "200") {
          toast.success("Group Renamed");
          treeData();
          resetForm();
          setRenameId("");
        } else {
          const errorMessage = response.response.data.msg.split(",")[0];
          toast.error(errorMessage);
          setRenameId("");
          treeData();
          resetForm();
        }
      });
    },
  });
  return (
    <>
      <div className="row alignItemCenter mb_16">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }}>
                IAM
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
                Groups
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
        <div className="displayFlex justifyContent_start">
        <div className="displayFlex alignItemCenter position-relative">
            <input
              type="text"
              value={name}
              className={
                name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
              }
              style={
                name?.length > 0 && name !== "None"
                  ? { border: "1px solid #ec171c" }
                  : {}
              }
              onChange={(e) => setName(e.target.value)}
            />
              {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
          </div>
        </div>
      </div>
      <div className="customTabGroup">
        <div>
          <div className="displayFlex mb_16">
            <div className="displayFlex gap_12">
              {(role?.includes("AONE_ADMIN") ||
                role?.includes("AONE_IAM.write") ||
                role?.includes("AONE_IAM.admin") ||
                role?.includes("AONE_IAM_GROUPS.write") ||
                role?.includes("AONE_IAM_GROUPS.admin")) && (
                <div>
                  <button
                    className="filterBtn plus_icon"
                    title="Add Group"
                    onClick={() => (
                      setTitle("Add Group"),
                      setAddModalHandler(!addModalHandler)
                    )}
                  >
                    +
                  </button>
                </div>
              )}
              {(role?.includes("AONE_ADMIN") ||
                role?.includes("AONE_IAM.admin") ||
                role?.includes("AONE_IAM_GROUPS.admin")) && (
                <div>
                  <button className="filterBtn" title="Synaaac">
                    <span>
                      <img
                        src={syncicon}
                        className="h18"
                        onClick={() => syncronizeGroups()}
                      />
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="customTabGroupSidebar ">
            {active && (
              <div className="treeGroup">
                <Tree
                  data={groups}
                  openByDefault={false}
                  idAccessor={(d) => d?.keycloakGroupId}
                  childrenAccessor={(d) => d?.subGroups}
                  rowHeight={30}
                >
                  {Node}
                </Tree>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex tabletab_section">
              <button
                className={`filterBtn ${subTab == 1 && "activeFilterBtn"}`}
                onClick={() => subTabHandler(1)}
              >
                Child Groups
              </button>
              {highlightId && (
                <>
                  <button
                    className={`filterBtn ${subTab == 2 && "activeFilterBtn"}`}
                    onClick={() => subTabHandler(2)}
                  >
                    Members
                  </button>
                  <button
                    className={`filterBtn ${subTab == 3 && "activeFilterBtn"}`}
                    onClick={() => subTabHandler(3)}
                  >
                    Assigned Roles
                  </button>
                </>
              )}
            </div>
            {highlightId && (
              <div className="displayFlex gap_12">
                <div>
                  {subTab == 1 ? (
                    <button
                      className="filterBtn plus_icon"
                      title="Add Child Groups"
                      onClick={() => (
                        setTitle("Add Child Group"),
                        setAddModalHandler(!addModalHandler)
                      )}
                    >
                      +
                    </button>
                  ) : (
                    <button
                      className="filterBtn"
                      title={subTab == 2 ? "Assign User" : "Assign Role"}
                      onClick={() => setAssignerHandler(!assignerHandler)}
                    >
                      <span>
                        <img src={assignIcon} />
                      </span>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_start">
            <div className="displayFlex alignItemCenter position-relative">
                <input
                  type="text"
                  value={subName}
                  className={
                    subName?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                  style={
                    subName?.length > 0 ? { border: "1px solid #ec171c" } : {}
                  }
                  onChange={(e) => setSubName(e.target.value)}
                />
                  {subName && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setSubName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>

          <MainTable
            tableData={
              subTab == 1
                ? subSearch(subGroups)
                : subTab == 2
                ? users
                : subTab == 3 && roles
            }
            tbl={
              subTab == 1
                ? "Child_Groups"
                : subTab == 2
                ? "Groups_Users"
                : subTab == 3 && "Users_Roles"
            }
            deleteHandler={subTab == 1 ? deleteHandler : subDeleteHandler}
            setDeleteHandler={
              subTab == 1 ? setDeleteHandler : setSubDeleteHandler
            }
          />

          {addModalHandler && subTab == 1 && (
            <OpenModal
              modalHandler={addModalHandler}
              setModalHandler={setAddModalHandler}
              title={title}
              editHandler={editHandler}
              setEditHandler={setEditHandler}
              data={highlightId}
            />
          )}

          {assignerHandler && subTab !== 1 && (
            <AssignerModal
              modalHandler={assignerHandler}
              setModalHander={setAssignerHandler}
              title={subTab == 2 ? "Assign User" : "Assign Role"}
            />
          )}

          {/* Delete Box Handler */}
          {deleteHandler && deleteParams?.title && (
            <DeletingModal
              deleteHandler={deleteHandler}
              setDeleteHandler={setDeleteHandler}
              deleteParams={deleteParams}
              setDeleteParams={setDeleteParams}
            />
          )}
        </div>
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">Rename Group</h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Group Name:
                    <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
                  </label>
                  <input
                    type="text"
                    value={changeGroupname.values.groupName}
                    placeholder="Group Name"
                    onChange={(e) =>
                      changeGroupname.setFieldValue("groupName", e.target.value)
                    }
                    className={"mainInput"}
                    style={
                      groupName?.length > 0
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                  />
                  {changeGroupname.touched.groupName &&
                  changeGroupname.errors.groupName ? (
                    <p className="requiredText">
                      {changeGroupname.errors.groupName}
                    </p>
                  ) : null}
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_20">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={changeGroupname.handleSubmit}
                >
                  Save
                </button>
              </div>
              <div>
                <button className="cancel_btn" onClick={() => handleClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Groups;
