import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get couriers
export const getCouriers = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/couriers${params}`);
    } catch (error) {
      return error;
    }
  };
  //filter couriers
  export const filterCouriers = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/couriers/filter${params}`);
    } catch (error) {
      return error;
    }
  };
//export couriers
export const exportCouriers = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/couriers/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//get task list for couriers
  export const getCourierTasks = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/couriers/tasks/${params}`);
    } catch (error) {
      return error;
    }
  };
//configure hubs to couriers
export const configureCourierHubs = async (params,data) => {
  try {
    return await AoneService.patch(`ops/api/v1/couriers/${params}/hub`,data);
  } catch (error) {
    return error;
  }
};
//export task list for couriers
  export const exportTasks = async (params) => {
    try {
      return await AoneService.get("ops/api/v1/couriers/tasks/export" + params, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };

  //status lookup
  export const getConsignmentStatus = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/CourierTaskStatus`);
    } catch (error) {
      return error;
    }
  };

  //tasktype lookup
  export const getConsignmentTaskType = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/CourierTaskType`);
    } catch (error) {
      return error;
    }
  };