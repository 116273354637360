import React, { useEffect, useState, useRef, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserContext } from "../helper/ProtectedRoute";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useAoneStore } from "../store/AoneStore";
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { roleHandler } from "../helper/roleHandler";
import templateIcon from "../../src/assets/img/template_icon.svg"
const Consigments = ({ }) => {
  const getOrder = useAoneStore((state) => state?.getOrder);
  const printBill = useAoneStore((state) => state?.printBill)
  const printBulkBill = useAoneStore((state) => state?.printBulkBill)
  const downloadSamplebulk = useAoneStore((state) => state?.downloadSamplebulk)
  const uploadBulkprint = useAoneStore((state) => state?.uploadBulkprint)
  const [nationalId, setNationalId] = useState("");
  const [wayBill, setWaybill] = useState("");
  const [receiverNum, setReceiverNum] = useState("")
  const [orderData, setOrderData] = useState([])
  const [wayBillN, setWaybillN] = useState("");
  const [wayBillArr, setWaybillArr] = useState([])
  const waybillref = useRef("")
  const [selectedFile, setSelectedFile] = useState(null);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);


  const handleSearch = async () => {
    try {
      const params = `waybillNumber=${wayBill}&receiverNid=${nationalId}&receiverPhone=${receiverNum}`
      const data = await getOrder(params)
      if (data.data.code == 200) {
        setOrderData(data.data.data)
        toast.success('fetched data sucessfully')
      }
    } catch (err) {
    }
  }

  const onChnageway = async (val) => {
    try {
      let a = val
      const params = `waybillNumber=${a}&receiverNid=&receiverPhone=`
      const data = await getOrder(params)
      if (data.data.code == 200) {
        setOrderData(data.data.data)
        toast.success('fetched data sucessfully')
      }
    } catch (err) {
    }
  }

  const onChnagePhone = async (val) => {
    try {
      let a = val
      const params = `waybillNumber=&receiverNid=&receiverPhone=${a}`
      const data = await getOrder(params)
      if (data.data.code == 200) {
        setOrderData(data.data.data)
        toast.success('fetched data sucessfully')
      }
    } catch (err) {
    }
  }

  const handleClear = () => {
    setWaybill("")
    setReceiverNum("")
    setNationalId("")
  }

  const downloadbill = async (inx, name) => {
    try {

      var data = []
      if (name == "all") {
        orderData?.map((e) => {
          data.push({
            "waybillNo": e?.waybillNumber,
            "from": e?.originCity,
            "to": e?.destinationCity,
            // "phone": e?.receiverPhone,
            "phone": e?.shipperPhone,
            "companyName": orderData[inx]?.consigneeName,
            "receiverName": e?.shipperName
          })
        })
      } else if (name == "particular") {
        data.push({
          "waybillNo": orderData[inx]?.waybillNumber,
          "from": orderData[inx]?.originCity,
          "to": orderData[inx]?.destinationCity,
          // "phone": orderData[inx]?.receiverPhone,
          "phone": orderData[inx]?.shipperPhone,
          "companyName": orderData[inx]?.consigneeName,
          "receiverName": orderData[inx]?.shipperName
        })
      }
      if( data.length === 0){
        toast.warning("No Printed Information Was Found")
      }else{
      const res = await printBill(data)
      if (res.status == 200) {
        // fileDownload(res.data, `hajbill.pdf`);
        window.open(res.data.fileUrl, "_blank", "noreferrer")
        toast.success('Downloaded sucessfully')
      }}
    } catch (err) {
    }
  }

  const handleAdd = () => {
    setWaybillArr((perv) => [...perv, wayBillN.trim()])
    setWaybillN("")
  }

  const downloadbulkbill = async () => {
    try {
      let data = {
        waybillNos: wayBillArr
      }
      const res = await printBulkBill(data)
      if (res?.data?.responseCode == 100) {
        // fileDownload(res.data, `bulkprint.pdf`);
        window.open(res.data.fileUrl, "_blank", "noreferrer")
        toast.success('Downloaded sucessfully')
      }else{
        toast.error(res?.data?.responseMessage)
      }
    } catch (err) {
    }
  }

  const downloadSamp = async () => {
    try {
      const res = await downloadSamplebulk()
      if (res.status == 200) {
        fileDownload(res.data, `template.xlsx`);
        toast.success('Downloaded sucessfully')
      }
    } catch (err) {
    }
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleImage = async (e) => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const res = await uploadBulkprint(formData)
      if (res.data.responseCode === "100") {
        window.open(res.data.fileUrl, "_blank", "noreferrer")
        // let data = res.data.data
        // let ddata = []
        // for(let i = 0; i < data.length; i++) {
        //   ddata.push(data[i])
        // }
        // setWaybillArr(ddata);
        // setSelectedFile(null)
        // toast.success('uploaded sucessfully')
      }else {
        toast.error(res?.data?.responseMessage)
      }
    } catch (err) {
    }
  }
  return (
    <>
      <div className="customTab">
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
          <Tab eventKey={1} title="Single">
            <div>
              <div className="mb_24">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-2">
                    <label className="fontSize14">Waybill Number: </label>
                  </div>
                  <div className="col-md-3">
                      <div className="displayFlex alignItemCenter">
                    <input
                       type="text"
                       placeholder="Search"
                       value={wayBill}
                       ref={waybillref}
                       onChange={(e) => {
                         setWaybill(e.target.value);
                       }}
                      className={
                        wayBill?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        wayBill?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                    />
                  </div>
                  </div>
                </div>
                <div className="row alignItemCenter mb_12" hidden="true">
                  <div className="col-md-2">
                    <label className="fontSize14">National Id:</label>
                  </div>
                  <div className="col-md-3">
                    <div className="smallGroupInputcon">
                      <div className="smallGroup-prepent">
                        <span className="smallGroupText">
                          <i
                            className="fa fa-search"
                            style={{ color: "#EC171C" }}
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Search"
                        value={nationalId}
                        onChange={(e) => {
                          setNationalId(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row alignItemCenter">
                  <div className="col-md-2">
                    <label className="fontSize14">Phone:</label>
                  </div>

                  <div className="col-md-3">
                  <div className="displayFlex alignItemCenter">
                    <input
                       type="text"
                       placeholder="Search"
                       value={receiverNum}
                       onChange={(e) => {
                         setReceiverNum(e.target.value);
                       }}
                      className={
                        receiverNum?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        receiverNum?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                    />
                  </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="activeFilterBtn mr_25" onClick={handleSearch}>Search</button>
                <button className="filterBtn" onClick={handleClear}>Clear</button>

              </div>
              <div className="orderDetailsTitle">
                <p>Order Details</p>
              </div>
             
              <div className="borderConsignment">
              {(roleHandler(role, "AONE_HAJ_CONSIGNMENTS.write") ||
                  roleHandler(role, "AONE_HAJ_CONSIGNMENTS.print")) && (
                <div className="consignmentend">
                  <button className="activeFilterBtn" onClick={() => downloadbill(0, "all")}>Print All</button>
                </div>)}

                {orderData.length > 0 && (
                  <div className="orderDetail_scroll">
                    {orderData?.map((e, i) => {
                      return (
                        <div className="spaceConsignment borderConsignment">
                            {(roleHandler(role, "AONE_HAJ_CONSIGNMENTS.write") ||
                  roleHandler(role, "AONE_HAJ_CONSIGNMENTS.print")) && (
                          <div className="consignmentend">
                            <button className="activeFilterBtn" onClick={() => downloadbill(i, "particular")}>Print</button>
                          </div>)}
                          <Row>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Waybill Number :<span> {e?.waybillNumber}</span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Name (Consignee):
                                  <span> {e?.consigneeName}</span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Company Name (Shipper):
                                  <span> {e?.shipperName}</span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Origin City :<span> {e?.originCity}</span>
                                </label>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Destination City :<span> {e?.destinationCity}</span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Origin Address :<span> {e?.originAddress}</span>
                                </label>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Destination Address :<span> {e?.destinationAddress}</span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                          {/* <hr className="hrconsign" /> */}
                          <Row>
                            <Col>
                              <div>
                                <label className="fontSize14 mb_12">
                                  Weight :<span> {e?.weight}</span>
                                </label>
                              </div>
                            </Col>
                          </Row>

                        </div>
                      )
                    })}
                  </div>

                )}

                {false && (
                  <>
                    <div className="spaceConsignment">
                      <Row>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Waybill Number :<span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Name:
                              <span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Shipper Name:
                              <span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Origin City :<span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Destination City :<span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Origin Address :<span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Destination Address :<span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      {/* <hr className="hrconsign" /> */}
                      <Row>
                        <Col>
                          <div>
                            <label className="fontSize14 mb_12">
                              Weight :<span> No Data Found</span>
                            </label>
                          </div>
                        </Col>
                      </Row>

                    </div>
                  </>
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey={2} title="Bulk">
            <div className="displayFlex alignItemCenter gap_16 mb_24">
              <div>
                {/* <button className="filterBtn">Upload Receivers</button> */}
                <label className="filterBtn displayInlineFlex alignItemCenter cursorPointer" htmlFor="uploadFile">
                  Upload waybills
                  <input type="file" id="uploadFile" onChange={handleFileChange} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
                </label>
              </div>
              <div>
                <button className="filterBtn" onClick={downloadSamp}  title="Download Template"> <span>
                        <img src={templateIcon} className="h18" />
                      </span></button>
              </div>
            </div>
            <div>
              {selectedFile &&
                <>
                  <div className="row">
                    <div className="col-md-8 mb_12">
                      <div className="fileItem displayInlineFlex alignItemCenter gap_12">
                        {selectedFile?.name}
                        <span onClick={() => {
                          setSelectedFile(null)
                        }}>X</span></div>
                    </div>
                    <div className="col-md-8">
                      <button className="activeFilterBtn mb_12" onClick={handleImage}>
                        Upload File and print
                      </button>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="row">
              <div className="col-md-3">
                <h6 className="mb_12">Waybill Number</h6>
                <div className="displayFlex alignItemCenter gap_16 mb_24">
                  <div>
                    <input
                      type="text"
                      placeholder="Enter the Value"
                      className="mainInput"
                      value={wayBillN}
                      onChange={(e) => setWaybillN(e.target.value)}
                    />
                  </div>
                  <div>
                    <button className="activeFilterBtn" onClick={handleAdd}>Add</button>
                  </div>
                </div>
                <div className="receiverListDiv mb_24">

                  {(wayBillArr?.map((e) => {
                    return (
                      <>
                        <p>{e}</p>
                      </>
                    )
                  }))}

                </div>
                <div>
                  <button className="activeFilterBtn mb_12" onClick={() => setWaybillArr([])}>Clear</button>
                </div>
              </div>
            </div>
            {(roleHandler(role, "AONE_HAJ_CONSIGNMENTS.write") ||
                  roleHandler(role, "AONE_HAJ_CONSIGNMENTS.print")) && (
            <div className="consignmentcenter">
              <button className="activeFilterBtn" onClick={downloadbulkbill}>Print</button>
            </div>)}
          </Tab>
        </Tabs>
      </div>


    </>
  );
};

export default Consigments;
