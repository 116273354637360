import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLinehaulStore } from "../../../store/ReasonsMgt/LinehaulStore";
import removeIcon from "../../../assets/img/cancelIcon.svg";
const LinehaulReasonModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  linehaulsCode,
  linehaulsbyCode,
  setLinehaulsbyCode
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postLinehaulReasons = useLinehaulStore(
    (state) => state?.postLinehaulReasons
  );
  const editLinehaulReasons = useLinehaulStore(
    (state) => state?.editLinehaulReasons
  );
  const getLinehaulReasonsbyId = useLinehaulStore(
    (state) => state?.getLinehaulReasonsbyId
  );
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const OpsEventData = useAoneStore((state) => state.OpsEventData);
  const [resetKey, setResetKey] = useState(0);
  const [locales, setLocales] = useState({});
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const handleObjectAdd = (event) => {
    event.preventDefault();
    if (firstValue?.trim() === "" || secondValue?.trim() === "") {
      toast.error("Cannot add empty value for message locals");
    } else {
      let newObject = { ...locales };
      setResetKey((prevKey) => prevKey + 1);
      newObject[firstValue] = secondValue;
      setLocales(newObject);
      setFirstValue("");
      setSecondValue("");
    }
  };
  const handleObjectDelete = (event,key) => {
    event.preventDefault();
    let newObject = { ...locales };
    delete newObject[key];
    setLocales(newObject);
  };
  const [extlocales, setextLocales] = useState({});
  const [firstextValue, setFirstextValue] = useState("");
  const [secondextValue, setSecondextValue] = useState("");
  const handleObjectextAdd = (event) => {
    event?.preventDefault();
    if (firstextValue?.trim() === "" || secondextValue?.trim() === "") {
      toast.error("Cannot add empty value for external message locals");
    } else {
      let newObject = { ...extlocales };
      if (firstextValue?.trim() === "" || secondextValue?.trim() === "") {
        toast.error("Cannot add empty value for message locals");
      } else {
        setResetKey((prevKey) => prevKey + 1);
        let newObject = { ...extlocales };
        newObject[firstextValue] = secondextValue;
        setextLocales(newObject);
        setFirstextValue("");
        setSecondextValue("");
      }
    }
  };
  const handleObjectextDelete = (event,key) => {
    event?.preventDefault();
    let newObject = { ...extlocales };
    delete newObject[key];
    setextLocales(newObject);
  };
  const languages = [
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "en", label: "English" },
    { key: "tr", label: "Turkish" },
  ];
  const addLinehaulReasons = useFormik({
    initialValues: {
      code: "",
      message: "",
      externalMessage: "",
      internalOnly: "",
      requireEstimateDelay: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      code: Yup.string()
        .trim()
        .required("Code is Required")
        .matches(/^[a-zA-Z0-9_\s]*$/, "Code must not contain special characters")
        .max(30, "Code must not be more than 30 characters"),
      message: Yup.string()
        .trim()
        .max(200, "Message must not be more than 200 characters")
        .required("Message is Required"),
      externalMessage: Yup.string()
        .trim()
        .max(200, "External Message must not be more than 200 characters")
        .required("External Message is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const localsList = [];
      Object.entries(locales).forEach(([language, message]) => {
        // Construct an object for each language
        const localEntry = {
          reasonCode: values.code,
          language: language,
          message: message,
          external: false,
        };
        // Add the local entry to localsList array
        localsList.push(localEntry);
      });
      // Iterate over extlocales object
      Object.entries(extlocales).forEach(([language, message]) => {
        // Construct an object for each language with external set to true
        const extLocalEntry = {
          reasonCode: values.code,
          language: language,
          message: message,
          external: true,
        };
        // Add the external local entry to localsList array
        localsList.push(extLocalEntry);
      });
      // Add localsList to the values object
      values["localesList"] = localsList;
      if (title === "Edit Linehauls") {
        await editLinehaulReasons(linehaulsCode,values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Linehaul reasons Updated");
            setispostAdded(!ispostAdded);
            setShowModal(false);
            resetForm();
          } else if(response?.data?.errors?.length > 0){
            const errorMessage = response?.data?.errors?.map((item) => `${item?.key}: ${item?.msg}`).join(', ');
            toast.error(errorMessage);
          } else if (
            response?.response?.data?.status === "ERROR" &&
            response?.response?.data.errors?.length < 1
          ) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postLinehaulReasons(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Linehaul reasons created");
            setispostAdded(!ispostAdded);
            setShowModal(false);
            resetForm();
          } else if(response?.data?.errors?.length > 0){
            const errorMessage = response?.data?.errors?.map((item) => `${item?.key}: ${item?.msg}`).join(', ');
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response.response.data.status === "ERROR") {
            const errorMessages = response.response.data.errors
              .map((item) => `Error in ${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
    },
  });
  const getLinehaulonEdit = async () => {
    const res = await getLinehaulReasonsbyId(linehaulsCode);
    setLinehaulsbyCode(res?.data?.data);
    addLinehaulReasons.setFieldValue("code", res?.data?.data?.code);
    addLinehaulReasons.setFieldValue("message", res?.data?.data?.message);
    addLinehaulReasons.setFieldValue("externalMessage", res?.data?.data?.externalMessage);
    addLinehaulReasons.setFieldValue("internalOnly", res?.data?.data?.internalOnly);
    addLinehaulReasons.setFieldValue("requireEstimateDelay", res?.data?.data?.requireEstimateDelay);
    const messageLocalsList = res?.data?.data?.messageLocalesList ?? {};
    const locales = messageLocalsList?.reduce((acc, item) => {
      acc[item.language] = item.message;
      return acc;
    }, {});
    setLocales(locales);
    const externalMessageLocalsList =
    res?.data?.data?.externalMessageLocalesList ?? {};
    const extlocales = externalMessageLocalsList?.reduce((acc, item) => {
      acc[item.language] = item.message;
      return acc;
    }, {});
    setextLocales(extlocales);
};
  useEffect(()=>{
    if(title === "Edit Linehauls"){
      getLinehaulonEdit();
    }
  },[])
  const [lineHaulcode, SetLineHaulCode] = useState("");
  const [messageLinehaulcode, SetMessageLinehaulCode] = useState("");
  const [extMessageLineHaulcode, SetExternalMsgLineCode] = useState("");
  const saveLinehaulReasons = async () => {
    if (/\s/g.test(addLinehaulReasons.values.code)) {
      SetLineHaulCode( "Code cannot contain leading, trailing, or consecutive spaces");
    } else if (/^\s+|\s+$/.test(addLinehaulReasons.values.message)) {
      SetMessageLinehaulCode(
        "Message cannot cannot contain leading or trailing spaces"
      );
    } else if (/^\s+|\s+$/.test(addLinehaulReasons.values.externalMessage)) {
      SetExternalMsgLineCode(
        "External Message cannot cannot contain leading or trailing spaces"
      );
    } else {
      SetLineHaulCode("");
      SetMessageLinehaulCode("");
      SetExternalMsgLineCode("");
    addLinehaulReasons?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollWeight">
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-5">
                <div className="col-md-6">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Code:
                    <span className="bloodDonortext fontWeight600 fontSize16">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    value={addLinehaulReasons.values.code}
                    name="code"
                    placeholder="Code"
                    className="mainInput"
                    onChange={(e) => {
                      addLinehaulReasons.setFieldValue("code", e.target.value);
                      SetLineHaulCode("");
                    }}
                    disabled={title === "Edit Linehauls" ? true : false}
                  />
                  {addLinehaulReasons.touched.code &&
                  addLinehaulReasons.errors.code &&
                  lineHaulcode === "" ? (
                    <p className="requiredText">
                      {addLinehaulReasons.errors.code}
                    </p>
                  ) : null}
                  {lineHaulcode !== "" ? (
                    <p className="requiredText">{lineHaulcode}</p>
                  ) : null}
                </div>
                <div className="col-md-12 mt_10">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Message:
                    <span className="bloodDonortext fontWeight600 fontSize16">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    value={addLinehaulReasons.values.message}
                    name="message"
                    placeholder="Message"
                    className="mainInput"
                    onChange={(e) => {
                      addLinehaulReasons.setFieldValue(
                        "message",
                        e.target.value
                      );
                      SetMessageLinehaulCode("");
                    }}
                  />
                  {addLinehaulReasons.touched.message &&
                  addLinehaulReasons.errors.message &&
                  messageLinehaulcode === "" ? (
                    <p className="requiredText">
                      {addLinehaulReasons.errors.message}
                    </p>
                  ) : null}
                  {messageLinehaulcode !== "" ? (
                    <p className="requiredText">{messageLinehaulcode}</p>
                  ) : null}
                </div>
                <div className="col-md-12 mt_10">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    External Message:
                    <span className="bloodDonortext fontWeight600 fontSize16">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    value={addLinehaulReasons.values.externalMessage}
                    name="externalMessage"
                    placeholder="External Message"
                    className="mainInput"
                    onChange={(e) => {
                      addLinehaulReasons.setFieldValue(
                        "externalMessage",
                        e.target.value
                      );
                      SetExternalMsgLineCode("");
                    }}
                  />
                  {addLinehaulReasons.touched.externalMessage &&
                  addLinehaulReasons.errors.externalMessage &&
                  extMessageLineHaulcode === "" ? (
                    <p className="requiredText">
                      {addLinehaulReasons.errors.externalMessage}
                    </p>
                  ) : null}
                  {extMessageLineHaulcode !== "" ? (
                    <p className="requiredText">{extMessageLineHaulcode}</p>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <div className="smallCheckbox">
                    <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                    <input
                      type="checkbox"
                      id="Require Estimate Delay"
                      style={{ marginLeft: "5px" }}
                      checked={addLinehaulReasons.values.requireEstimateDelay}
                      onChange={() => {
                        addLinehaulReasons.setFieldValue(
                          "requireEstimateDelay",
                          !addLinehaulReasons.values.requireEstimateDelay
                        );
                        if (!addLinehaulReasons.values.requireEstimateDelay) {
                          addLinehaulReasons.setFieldValue(
                            "requireEstimateDelay",
                            true
                          );
                        }
                      }}
                    />
                    <label
                      className="margintop7"
                      htmlFor="Require Estimate Delay"
                    >
                      Require Estimate Delay
                    </label>
                  </div>
                </div>
                <div className="col-md-12 mt_10">
                  <div className="smallCheckbox">
                    <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8"></div>
                    <input
                      type="checkbox"
                      id="Internal Only"
                      style={{ marginLeft: "5px" }}
                      checked={addLinehaulReasons.values.internalOnly}
                      onChange={() => {
                        addLinehaulReasons.setFieldValue(
                          "internalOnly",
                          !addLinehaulReasons.values.internalOnly
                        );
                        if (!addLinehaulReasons.values.internalOnly) {
                          addLinehaulReasons.setFieldValue(
                            "internalOnly",
                            true
                          );
                        }
                      }}
                    />
                    <label className="margintop7" htmlFor="Internal Only">
                      Internal Only
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">Message Locals</div>
                  <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                    <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                      <SearchInput
                        key={resetKey}
                        id="firstValue"
                        label="firstValue"
                        name="firstValue"
                        options={languages || []}
                        getOptionLabel={(city) => city.label}
                        getOptionValue={(city) => city.label}
                        handleChange={async (selectedOption) => {
                          setFirstValue(selectedOption?.label);
                        }}
                        isClearable={true}
                        customStyles={{ control: { width: "180px" } }}
                        isSearchable={true}
                        placeholder="Language"
                      />
                      <input
                        type="text"
                        placeholder="Message"
                        style={{ marginLeft: "15px" }}
                        value={secondValue}
                        onChange={(e) => setSecondValue(e.target.value)}
                        className="mainInput"
                      />
                    </div>
                    <button
                      className="filterBtn"
                      onClick={(e) => handleObjectAdd(e)}
                    >
                      +
                    </button>
                  </div>
                  <div className="table_wrapper mb_16 showFiveRows">
                    <table>
                      <thead>
                        <tr>
                          <th>Language</th>
                          <th>Message</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(locales).map((value, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{value}</td>
                              <td>{locales[value]}</td>
                              <td>
                                <button
                                  className="cancel_btn"
                                  onClick={(event) => handleObjectDelete(event,value)}
                                >
                                  <span>
                                    <img src={removeIcon} alt="Remove" />
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="displayFlex alignItemCenter justifyContent_end mb_16"></div>
                </div>
                <div className="borderFrame mb_16">
                  <div className="borderFrameTitle">
                    External Message Locals
                  </div>
                  <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
                    <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                      <SearchInput
                        key={resetKey}
                        id="firstextValue"
                        label="firstextValue"
                        name="firstextValue"
                        options={languages || []}
                        getOptionLabel={(city) => city.label}
                        getOptionValue={(city) => city.label}
                        handleChange={async (selectedOption) => {
                          setFirstextValue(selectedOption?.label);
                        }}
                        isClearable={true}
                        customStyles={{ control: { width: "180px" } }}
                        isSearchable={true}
                        placeholder="Language"
                      />
                      <input
                        type="text"
                        placeholder="Message"
                        style={{ marginLeft: "15px" }}
                        value={secondextValue}
                        onChange={(e) => setSecondextValue(e.target.value)}
                        className="mainInput"
                      />
                    </div>
                    <button
                      className="filterBtn"
                      onClick={(e) => handleObjectextAdd(e)}
                    >
                      +
                    </button>
                  </div>
                  <div className="table_wrapper mb_16 showFiveRows">
                    <table>
                      <thead>
                        <tr>
                          <th>Language</th>
                          <th>Message</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(extlocales).map((value, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{value}</td>
                              <td>{extlocales[value]}</td>
                              <td>
                                <button
                                  className="cancel_btn"
                                  onClick={(e) => handleObjectextDelete(e,value)}
                                >
                                  <span>
                                    <img src={removeIcon} alt="Remove" />
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="displayFlex alignItemCenter justifyContent_end mb_16"></div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button
            className="blue_btn"
            type="button"
            onClick={saveLinehaulReasons}
          >
            Save
          </button>
          <button
            className="cancel_btn"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default LinehaulReasonModal;
