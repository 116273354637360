import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import deleteIcon from "../../../assets/img/delete_icon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useroutesStore } from "../../../store/RoutesMgt/RoutesStore";
const RouteModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  routebyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const distanceList = useroutesStore((state) => state?.distanceList);
  const statusList = useroutesStore((state) => state?.statusList);
  const postRoutes = useroutesStore((state) => state?.postRoutes);
  const editRoutes = useroutesStore((state) => state?.editRoutes);
  const hubsfilterData = useAoneStore((state) => state?.hubsfilterData);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const getProducts = useAoneStore((state) => state?.getProducts);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({
    fromHub: { name: "", code: "" },
    toHub: { name: "", code: "" },
    distance: "",
    transitTime: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewRow({ ...newRow, [name]: value });
  };
  const handleFromHubChange = (selectedOption) => {
    setNewRow((currentNewRow) => ({
      ...currentNewRow,
      fromHub: selectedOption
        ? { name: selectedOption.name, code: selectedOption.code }
        : { name: "", code: "" },
    }));
  };

  // Handler for the To Hub SearchInput
  const handleToHubChange = (selectedOption) => {
    setNewRow((currentNewRow) => ({
      ...currentNewRow,
      toHub: selectedOption
        ? { name: selectedOption.name, code: selectedOption.code }
        : { name: "", code: "" },
    }));
  };
  const [resetKey, setResetKey] = useState(0);
  const [totDistance,setTotalDistance] = useState("")
  const [totTransTime,setTotalTransitTime] = useState("")
  const calculateTotals = () => {
    // Calculate the sum of distances
    const totalDistance = rows.reduce((acc, row) => {
      return acc + Number(row.distance);
    }, 0);
    setTotalDistance(totalDistance);
  
    // Calculate the sum of transit times
    const totalTransitTime = rows.reduce((acc, row) => {
      return acc + Number(row.transitTime);
    }, 0);
    setTotalTransitTime(totalTransitTime);
  };
  useEffect(() => {
    calculateTotals();
  }, [rows]);
  const handleAddRow = (e) => {
    e.preventDefault();
    if (
      !newRow.fromHub ||
      !newRow.toHub ||
      !newRow.distance ||
      !newRow.transitTime
    ) {
      toast.error("All fields are required to add a node");
      return;
    }
    const transitTime = parseInt(newRow.transitTime);
    if (!Number.isInteger(transitTime)) {
      toast.error("Transit time should be an integer,Hours should be passed");
      return;
    }
    if (/\s/g.test(newRow.transitTime)) {
      toast.error(
        "Transit time should not contain leading, trailing, or consecutive spaces."
      );
      return;
    }
    if (!newRow.distance) {
      toast.error("Distance is required");
      return;
    }
    if (/\s/g.test(newRow.distance)) {
      toast.error(
        "Distance should not contain leading, trailing, or consecutive spaces."
      );
      return;
    }
    const lastRowIndex = rows.length - 1;
    const newRowFromHubCode = newRow.fromHub.code;
      const newRowToHubCode = newRow.toHub.code;
    // Check if there are existing rows
    if (lastRowIndex >= 0) {
      const lastToHubCode = rows[lastRowIndex]?.toHub?.code;
      if (lastToHubCode !== newRow.fromHub.code) {
        toast.error("Route should be continuous. Please ensure the 'To Hub' of the previous node matches the 'From Hub' of the new node.");
        return;
      }else if(rows.some((row) => row.fromHub?.code === newRowFromHubCode && row.toHub?.code === newRowToHubCode)){
        toast.error("Hub pair already exists in the route. Please choose unique hubs.");
        return;
      }
    }
    const newRowWithId = { ...newRow, id: Date.now() };
    const updatedRows = [...rows, newRowWithId];
    setRows(updatedRows);
    setResetKey((prevKey) => prevKey + 1);
    setNewRow({ fromHub: "", toHub: "", distance: "", transitTime: "" });
    addRoutes.setFieldValue(
      "routeNodes",
      updatedRows.map((row) => ({
        fromHubCode: row.fromHub.code,
        toHubCode: row.toHub.code,
        distance: row.distance,
        transitTime: row.transitTime,
      }))
    );
  };
  const handleDeleteRow = (e, id) => {
    e.preventDefault();
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    addRoutes.setFieldValue(
      "routeNodes",
      updatedRows.map((row) => ({
        fromHubCode: row.fromHub?.code ? row.fromHub?.code : row.fromHub,
        toHubCode: row.toHub?.code ? row.toHub?.code : row.toHub,
        distance: row.distance,
        transitTime: row.transitTime,
      }))
    );
  };
  const addRoutes = useFormik({
    initialValues: {
      code: title === "Edit Route" ? routebyCode?.code : "",
      name: title === "Edit Route" ? routebyCode?.name : "",
      status: title === "Edit Route" ? routebyCode?.status : "",
      productCode: title === "Edit Route" ? routebyCode?.productCode : "",
      originHubCode: title === "Edit Route" ? routebyCode?.originHubCode : "",
      destinationHubCode:
        title === "Edit Route" ? routebyCode?.destinationHubCode : "",
      startTime: title === "Edit Route" ? routebyCode?.startTime : "",
      distanceUnit: title === "Edit Route" ? routebyCode?.distanceUnit : "",
      totalDistance: title === "Edit Route" ? routebyCode?.totalDistance : "",
      totalTransitTime:
        title === "Edit Route" ? routebyCode?.totalTransitTime : "",
      routeNodes:
        title === "Edit Route" && routebyCode?.routeNodes
          ? routebyCode.routeNodes.map((node) => ({
              fromHubCode: node.fromHubCode,
              toHubCode: node.toHubCode,
              distance: node.distance,
              transitTime: node.transitTime,
            }))
          : rows.map((row) => ({
              fromHubCode: row.fromHub,
              toHubCode: row.toHub,
              distance: row.distance,
              transitTime: row.transitTime,
            })),
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().required("Code is required")
      .matches(/^[a-zA-Z0-9_\s]*$/, "Code must not contain special characters")
      .max(50, "Code must be at most 50 characters long"),
      name: Yup.string().trim().required("Name is required")
      .matches(/^[a-zA-Z0-9_\s]*$/, "Name must not contain special characters")
      .max(50, "Name must be at most 50 characters long"),
      status: Yup.string().trim().required("Status is required"),
      originHubCode: Yup.string().required("Origin Hub is required"),
      destinationHubCode: Yup.string()
        .required("Destination Hub is required")
        .test(
          "is-different-from-origin",
          "Destination and Origin Hubs cannot be the same",
          function (value) {
            return this.parent.originHubCode !== value;
          }
        ),
      startTime: Yup.string()
        .required("Start time is required")
        .matches(
          /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
          "Start time must be in hh:mm format"
        ),
      distanceUnit: Yup.string().trim().required("Distance is required"),
      routeNodes: Yup.array()
        .min(1, "At least one route node is required")
        .required("At least one route node is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { totalDistance, totalTransitTime, ...postData } = values;
      if (title === "Edit Route") {
        postData.id = routebyCode.id;
        await editRoutes(postData).then((response) => {
          if (response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response.status == 200) {
            toast.success("Routes Updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      } else {
        await postRoutes(postData).then((response) => {
          if (response?.status == "201") {
            toast.success("Route Created");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status === "ERROR") {
            const errorMessages = response?.data?.msg;
            toast.error(errorMessages);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      }
    },
  });
  useEffect(() => {
    if (title === "Edit Route" && routebyCode?.routeNodes) {
      addRoutes.setFieldValue("code", routebyCode?.code);
      addRoutes.setFieldValue("name", routebyCode?.name);
      addRoutes.setFieldValue("status", routebyCode?.status);
      addRoutes.setFieldValue("productCode", routebyCode?.productCode);
      addRoutes.setFieldValue("originHubCode", routebyCode?.originHub?.code);
      addRoutes.setFieldValue(
        "destinationHubCode",
        routebyCode?.destinationHub?.code
      );
      addRoutes.setFieldValue("startTime", routebyCode?.startTime);
      addRoutes.setFieldValue("distanceUnit", routebyCode?.distanceUnit);
      addRoutes.setFieldValue("totalDistance", routebyCode?.totalDistance);
      addRoutes.setFieldValue(
        "totalTransitTime",
        routebyCode?.totalTransitTime
      );
      addRoutes.setFieldValue(
        "routeNodes",
        routebyCode.routeNodes.map((node) => ({
          fromHubCode: node.fromHub?.code,
          toHubCode: node.toHub?.code,
          distance: node.distance,
          transitTime: node.transitTime,
        }))
      );
      const mappedRows = routebyCode?.routeNodes?.map((node, index) => ({
        fromHub: node.fromHub,
        toHub: node.toHub,
        distance: node.distance,
        transitTime: node.transitTime,
        id: index + 1,
      }));
      setRows(mappedRows);
    }
    // Optionally, handle other title cases or reset rows here
  }, [title, routebyCode]);
  const [showRouteProduct, SetshowshowRouteProduct] = useState(
    title !== "Edit Route"
  );
  const handleInputProductClick = () => {
    if (title === "Edit Route") {
      SetshowshowRouteProduct(true);
    }
  };
  const [routeCodeErr,setRouteCodeErr] = useState("")
  const [routeNameErr,setRouteNameErr] = useState("")
  const saveRoutes = async () => {
    const values = addRoutes.values;
    if (addRoutes.values.routeNodes.length > 0) {
      const firstNode = values.routeNodes[0];
      const lastNode = values.routeNodes[values.routeNodes.length - 1];
      // Check if fromHubCode matches originHubCode
      if (
        firstNode?.fromHubCode !== values?.originHubCode &&
        firstNode?.fromHubCode?.code !== values?.originHubCode
      ) {
        toast.error("From Hub of the first node does not match the origin Hub");
        return;
      }
      // Check if toHubCode matches destinationHubCode
      if (
        lastNode?.toHubCode !== values?.destinationHubCode &&
        lastNode?.toHubCode?.code !== values?.destinationHubCode
      ) {
        toast.error(
          "To Hub of the last node does not match the destination Hub"
        );
        return;
      }
    }
    if(/\s/g.test(addRoutes.values.code)){
      setRouteCodeErr(
        "Code cannot contain leading, trailing, or consecutive spaces"
      );
    }else if(/^\s|\s$/.test(addRoutes.values.name)){
      setRouteNameErr(
          "Name cannot contain leading or trailing spaces"
        );
    }else{
      setRouteCodeErr("");
      setRouteNameErr("");
      addRoutes?.handleSubmit();
    }
  };
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreProductsOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20&productCategory=DOMESTIC,DOMESTIC_WITH_CLEARANCE`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await getProducts(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollRoute">
        <Modal.Body>
          <form>
            <div className="borderFrame mb_16">
              <div className="borderFrameTitle">Details</div>
              <div className="row margin-top-10">
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Code{" "}
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Code"
                        value={addRoutes.values.code}
                        onChange={(e) =>{
                          addRoutes.setFieldValue("code", e.target.value);
                          setRouteCodeErr("");
                        }}
                        name="code"
                        disabled={title == "Edit Route"}
                        className="mainInput"
                      />
                    </div>
                    {(addRoutes.touched.code && addRoutes.errors.code && routeCodeErr === "") ? (
                      <p className="requiredText">{addRoutes.errors.code}</p>
                    ) : null}
                    {routeCodeErr !== "" ? (
                      <p className="requiredText">{routeCodeErr}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Name{" "}
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Name"
                        value={addRoutes.values.name}
                        onChange={(e) =>{
                          addRoutes.setFieldValue("name", e.target.value);
                          setRouteNameErr("");
                        }}
                        name="name"
                        className="mainInput"
                      />
                    </div>
                    {(addRoutes.touched.name && addRoutes.errors.name && routeNameErr === "") ? (
                      <p className="requiredText">{addRoutes.errors.name}</p>
                    ) : null}
                     {routeNameErr !== "" ? (
                      <p className="requiredText">{routeNameErr}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Status{" "}
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      <SearchInput
                        id="status"
                        label="status"
                        name="status"
                        options={statusList?.data?.data || []}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.key}
                        handleChange={(selectedOption) => {
                          addRoutes.setFieldValue(
                            "status",
                            selectedOption ? selectedOption.key : ""
                          );
                        }}
                        value={
                          addRoutes?.values?.status
                            ? statusList?.data?.data?.find(
                                (status) =>
                                  status.key === addRoutes?.values?.status
                              )
                            : null
                        }
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Status"
                        className="mainInput"
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </div>
                    {addRoutes.touched.status && addRoutes.errors.status ? (
                      <p className="requiredText">{addRoutes.errors.status}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Product{" "}
                      </label>
                      {showRouteProduct ? (
                        <AsyncSearchInput
                          id="productCode"
                          label="product"
                          name="productCode"
                          value={selectedOption}
                          loadMoreOptions={loadMoreProductsOptions}
                          shouldLoadMore={shouldLoadMore}
                          getOptionLabel={(zone) => zone.label}
                          getOptionValue={(zone) => zone.value}
                          onChange={(selectedOption) => {
                            setSelectedOption(selectedOption);
                            addRoutes.setFieldValue(
                              "productCode",
                              selectedOption ? selectedOption.value : ""
                            );
                          }}
                          placeholder="Product"
                        />
                      ) : (
                        <input
                          type="text"
                          value={routebyCode?.productCode}
                          name="productCode"
                          className="mainInput"
                          style={{ width: "200px" }}
                          onClick={handleInputProductClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt_20">
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Origin Hub{" "}
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      <SearchInput
                        id="originHubCode"
                        label="originHubCode"
                        name="originHubCode"
                        options={hubsfilterData?.data?.data?.content || []}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.name}`
                        }
                        getOptionValue={(option) => option.code}
                        handleChange={(selectedOption) => {
                          addRoutes.setFieldValue(
                            "originHubCode",
                            selectedOption ? selectedOption?.code : ""
                          );
                        }}
                        value={
                          addRoutes?.values?.originHubCode
                            ? hubsfilterData?.data?.data?.content?.find(
                                (hub) =>
                                  hub.code === addRoutes?.values?.originHubCode
                              )
                            : null
                        }
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Origin Hub"
                        className="mainInput"
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </div>
                    {addRoutes.touched.originHubCode &&
                    addRoutes.errors.originHubCode ? (
                      <p className="requiredText">
                        {addRoutes.errors.originHubCode}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Destination Hub
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      <SearchInput
                        id="destinationHubCode"
                        label="destinationHubCode"
                        name="destinationHubCode"
                        options={hubsfilterData?.data?.data?.content || []}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.name}`
                        }
                        getOptionValue={(option) => option.code}
                        handleChange={(selectedOption) => {
                          addRoutes.setFieldValue(
                            "destinationHubCode",
                            selectedOption ? selectedOption?.code : ""
                          );
                        }}
                        value={
                          addRoutes?.values?.destinationHubCode
                            ? hubsfilterData?.data?.data?.content?.find(
                                (hub) =>
                                  hub.code ===
                                  addRoutes?.values?.destinationHubCode
                              )
                            : null
                        }
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Destination Hub"
                        className="mainInput"
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </div>
                    {addRoutes.touched.destinationHubCode &&
                    addRoutes.errors.destinationHubCode ? (
                      <p className="requiredText">
                        {addRoutes.errors.destinationHubCode}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Distance{" "}
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      <SearchInput
                        id="distanceUnit"
                        label="distanceUnit"
                        name="distanceUnit"
                        options={distanceList?.data?.data || []}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.key}
                        handleChange={(selectedOption) => {
                          addRoutes.setFieldValue(
                            "distanceUnit",
                            selectedOption ? selectedOption?.key : ""
                          );
                        }}
                        value={
                          addRoutes?.values?.distanceUnit
                            ? distanceList?.data?.data?.find(
                                (hub) =>
                                  hub.key === addRoutes?.values?.distanceUnit
                              )
                            : null
                        }
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Distance"
                        className="mainInput"
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </div>
                    {addRoutes.touched.distanceUnit &&
                    addRoutes.errors.distanceUnit ? (
                      <p className="requiredText">
                        {addRoutes.errors.distanceUnit}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Start Time{" "}
                        <span className="bloodDonortext fontWeight600 fontSize16">
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Start time"
                        value={addRoutes.values.startTime}
                        onChange={(e) =>
                          addRoutes.setFieldValue("startTime", e?.target?.value)
                        }
                        name="code"
                        className="mainInput"
                      />
                    </div>
                    {addRoutes.touched.startTime &&
                    addRoutes.errors.startTime ? (
                      <p className="requiredText">
                        {addRoutes.errors.startTime}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row mt_20">
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Total Distance
                      </label>
                      <p className="mainPara" aria-disabled>
                        {addRoutes?.values?.totalDistance
                          ? addRoutes?.values?.totalDistance
                          : totDistance}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row alignItemCenter mb_12">
                    <div className="col-md-12">
                      <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                        Total Time
                      </label>
                      <p className="mainPara">
                        {addRoutes?.values?.totalTransitTime
                          ? addRoutes?.values?.totalTransitTime
                          : totTransTime}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="borderFrame mb_16">
              <div className="borderFrameTitle">Nodes</div>
              <div className="row margin-top-10">
                <form>
                  <div className="modal_table_wrapper overflowScroll mb_12 showTenRows">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>From Hub</th>
                          <th>To Hub</th>
                          <th>Distance</th>
                          <th>Transit Time</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row?.fromHub?.name}</td>
                            <td>{row?.toHub?.name}</td>
                            <td>{row.distance}</td>
                            <td>{row.transitTime}</td>
                            <td>
                              <button
                                className="tableBtn"
                                title="Delete"
                                onClick={(e) => handleDeleteRow(e, row.id)}
                              >
                                <span>
                                  <img
                                    src={deleteIcon}
                                    className="h18 turnBlack"
                                  />
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td>{rows?.length + 1}</td>
                          <td>
                            <SearchInput
                              key={resetKey}
                              id="fromhub"
                              name="fromHub"
                              options={hubsfilterData?.data?.data?.content || []}
                              getOptionLabel={(option) =>
                                `${option.code} - ${option.name}`
                              }
                              getOptionValue={(option) => option.code}
                              isClearable={true}
                              isSearchable={true}
                              placeholder="From Hub"
                              className="mainInput"
                              // value={
                              //   newRow?.fromHub
                              //     ? hubsfilterData?.data?.data?.content?.find(
                              //         (hub) =>
                              //         hub.code ===
                              //         newRow?.fromHub?.code
                              //       )
                              //     : null
                              // }
                              style={{ whiteSpace: "nowrap" }}
                              customStyles={{ control: { width: "170px" } }}
                              handleChange={handleFromHubChange}
                            />
                          </td>
                          <td>
                            {" "}
                            <SearchInput
                              key={resetKey}
                              id="toHub"
                              name="toHub"
                              options={hubsfilterData?.data?.data?.content || []}
                              getOptionLabel={(option) =>
                                `${option.code} - ${option.name}`
                              }
                              getOptionValue={(option) => option.code}
                              isClearable={true}
                              isSearchable={true}
                              placeholder="To Hub"
                              className="mainInput"
                              style={{ whiteSpace: "nowrap" }}
                              customStyles={{ control: { width: "170px" } }}
                              handleChange={handleToHubChange}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="distance"
                              value={newRow.distance}
                              onChange={handleChange}
                              className="mainInput"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="transitTime"
                              value={newRow.transitTime}
                              onChange={handleChange}
                              className="mainInput"
                            />
                          </td>
                          <td>
                            <button
                              className="filterRouteBtn plus_icon"
                              title="Add"
                              onClick={handleAddRow}
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {addRoutes.touched.routeNodes &&
                    addRoutes.errors.routeNodes ? (
                      <p className="requiredText">
                        {addRoutes.errors.routeNodes}
                      </p>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveRoutes}>
            Save
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default RouteModal;
