import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { roleHandler } from "../../../helper/roleHandler";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useDeliveryStore } from "../../../store/DeliveryMgt/DeliveryStore";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import DeletingModal from "../../../components/modal/deletingModal";
import { UserContext } from "../../../helper/ProtectedRoute";
import Modal from "react-bootstrap/Modal";
import downloadicon from "../../../assets/img/download_icon.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import { toast } from "react-toastify";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import jwt_decode from "jwt-decode";
import { useFormik } from "formik";
import fileDownload from "js-file-download";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const DeliveryTasks = () => {
  const navigate = useNavigate();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [VehicleActive, SetVehicleActive] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(-1);
  const [courierPage, setCourierPage] = useState(-1);
  const [courierFilterPage, setCourierFilterPage] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCourier, setSelectedOptionCourier] = useState(null);
  const [selectedOptionEmployee, setSelectedOptionEmployee] = useState(null);
  const [isActivated, SetisActivated] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [deliveryData, setDeliveryData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const [delivPackage, setDelivPackage] = useState("");
  const [delivCourier, setDelivCourier] = useState("");
  const [title, setTitle] = useState("Add Delivery Task");
  const getDeliveryTask = useDeliveryStore((state) => state?.getDeliveryTask);
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const postDeliveryTask = useDeliveryStore((state) => state?.postDeliveryTask);
  const exportDeliveryTasks = useDeliveryStore(
    (state) => state?.exportDeliveryTasks
  );
  const importDeliveryTasks = useDeliveryStore(
    (state) => state?.importDeliveryTasks
  );
  const getdeliveryStatus = useDeliveryStore(
    (state) => state?.getdeliveryStatus
  );
  const deliveryStatusList = useDeliveryStore(
    (state) => state?.deliveryStatusList
  );
  const DeliveryTaskTemplate = useDeliveryStore(
    (state) => state?.DeliveryTaskTemplate
  );
  const removeDeliveryTask = useDeliveryStore(
    (state) => state?.removeDeliveryTask
  );
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const [loadingMore, setLoadingMore] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const isReactoriginselectCity = useAoneStore(
    (state) => state?.isReactoriginselectCity
  );
  const [trackingId, setTrackingId] = useState("");
  const [status, setStatus] = useState("");
  const [employee, setEmployee] = useState("");
  const [hubCode, setHubCode] = useState("");
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const setReactDescity = useAoneStore((state) => state?.setReactDescity);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const setReactSelectorigincity = useAoneStore(
    (state) => state?.setReactSelectorigincity
  );
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };
  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenudescity = () => {
    setReactDescity(true);
  };
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  useEffect(() => {
    if (deliveryStatusList?.data?.data === undefined) {
      getdeliveryStatus();
    }
  }, []);
  const addDeliveryTask = useFormik({
    initialValues: {
      trackingId: "",
      employeeCode: "",
    },
    validationSchema: Yup.object({
      trackingId: Yup.string().required("Tracking id is required"),
      employeeCode: Yup.string().required("Courier is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postDeliveryTask(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Delivery task added`);
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          getData();
          setShow(false);
          resetForm();
        } else if (
          response?.data?.errors?.length > 0 &&
          response?.data?.status === "ERROR"
        ) {
          const errorMessages = response?.data?.errors
            .map((item) => `${item.key}: ${item.msg}`)
            .join(", ");
          setShow(false);
          resetForm();
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          toast.error(errorMessages);
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilterPage(-1);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          setShow(false);
          setCourierFilterPage(-1);
          setSelectedOptionCourier(null);
          resetForm();
        }
      });
    },
  });
  const [deliveryId, SetDeliveryId] = useState("");
  const unAssignCourier = async () => {
    const res = await removeDeliveryTask(deliveryId);
    if (res?.data?.status == "ERROR") {
      toast.error(res?.data?.msg);
      setShowAssign(false);
    } else if (res?.data?.status == "SUCCESS") {
      toast.success(`Courier unassigned from the delivery task`);
      setShowAssign(false);
      getData();
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (employee) {
      params += `&employeeCode=${employee}`;
    }
    if (hubCode) {
      params += `&hubCode=${hubCode}`;
    }
    const data = await getDeliveryTask(params);
    setTotalCount(data?.data?.data?.totalElements);
    setDeliveryData(data?.data?.data?.content);
  };
  const exporttoExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (status) {
      params += `&status=${status}`;
    }
    if (employee) {
      params += `&employeeCode=${employee}`;
    }
    if (hubCode) {
      params += `&hubCode=${hubCode}`;
    }
    try {
      const res = await exportDeliveryTasks(params);
      if (res.status == 200) {
        fileDownload(res.data, `Delivery Task.xlsx`);
      } else if (res?.response?.status === 500) {
        toast.error("Internal Server Error");
      } else {
        toast.error(res.response.statusText);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    editPage,
    sortField,
    saveHandler,
    trackingId,
    status,
    employee,
    hubCode,
    VehicleActive,
    ispostAdded,
    isActivated,
  ]);
  const downloadTemplate = async () => {
    try {
        const res = await DeliveryTaskTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Delivery tasks Template.xlsx`);
      }
    } catch (err) {}
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            importDeliveryTasks(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (
                  response?.data?.status === "ERROR" &&
                  (response?.data?.data?.length == 0 ||
                    response?.data?.data?.length === undefined)
                ) {
                  // Handle error response from the server
                  const errorMessage = response?.data?.msg;
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.data?.length > 0) {
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (
                  response.data.data.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  const [prevSearch, setPrevSearch] = useState("");
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [showAssign, setShowAssign] = useState(false);
  const handleassignShow = () => setShowAssign(true);
  const handleassignClose = () => {
    setShowAssign(false);
  };
  const handleassignCancel = () => {
    setShowAssign(false);
  };
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await filterHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const [userHub, setUserHub] = useState("");
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      console.log("decoded", decoded);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  const loadMoreOptionsCourier = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierPage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsCourierFilter = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : courierFilterPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCourierFilterPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=${20}&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((employee) => ({
            label: `${employee.employeeCode} - ${employee.employeeName}`,
            value: employee.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [userHub, loadingMore, courierFilterPage, prevSearch, setPrevSearch]
  );
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedOptionCourier(null);
    setCourierPage(-1);
    setCourierFilterPage(-1);
  };
  const handleCancel = () => {
    handleClose();
  };
  return (
    <div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Tracking ID:
                  </label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={trackingId}
                      className={
                        trackingId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        trackingId?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setTrackingId(e.target.value)}
                    />
                    {trackingId && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setTrackingId("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Status:
                  </label>
                  <SearchInput
                    id="status"
                    label="status"
                    name="status"
                    options={deliveryStatusList?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    handleChange={(selectedOption) => {
                      setStatus(selectedOption ? selectedOption.key : "");
                    }}
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    customStyles={{ control: { width: "155px" } }}
                    menuIsOpen={isReactselectCity}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Status"
                    className={
                      status?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Courier:
                  </label>
                  <AsyncSearchInput
                    id="employee"
                    label="employee"
                    name="employee"
                    value={selectedOptionEmployee}
                    loadMoreOptions={loadMoreOptionsCourier}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuOpenorgcity}
                    onMenuClose={handleMenuCloseorgcity}
                    menuIsOpen={isReactoriginselectCity}
                    onChange={(selectedOptionEmployee) => {
                      setSelectedOptionEmployee(selectedOptionEmployee);
                      setEmployee(
                        selectedOptionEmployee
                          ? selectedOptionEmployee.value
                          : ""
                      );
                    }}
                    customStyles={{ control: { width: "160px" } }}
                    placeholder="Courier"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Hub:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10">
                    <AsyncSearchInput
                      id="hubCode"
                      label="hubCode"
                      name="hubCode"
                      value={selectedOption}
                      loadMoreOptions={loadMoreOptionsGroups}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        setHubCode(selectedOption ? selectedOption.value : "");
                      }}
                      customStyles={{ control: { width: "160px" } }}
                      placeholder="Hub"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuStyle={{ zIndex: 9999 }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                {(roleHandler(role, "AONE_OPS.write") ||
                 roleHandler(role, "AONE_OPS.admin") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write")) && (
                  <>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon mr_10 ml_10"
                      title={"Delivery tasks template"}
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div
                      title={"Import Delivery tasks"}
                      className="ml_10"
                    >
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                )}
                <button
                  className="filterBtn plus_icon mleft_10"
                  title="Export Delivery Tasks"
                  onClick={() => exporttoExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_OPS.write") ||
                 roleHandler(role, "AONE_OPS.admin") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                 roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write")) && (
                <button
                  className="filterBtn plus_icon"
                  title="Add"
                  tabIndex="-1"
                  onClick={() => {
                    setShow(true);
                    setTitle("Add Delivery task");
                    setSelectedOptionCourier(null);
                    addDeliveryTask?.setFieldValue("trackingId", "");
                  }}
                >
                  +
                </button>)}
                </>
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
      <PaginationTable
        tbl="DeliveryTasks"
        tableData={deliveryData}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        size={size}
        addDeliveryTask={addDeliveryTask}
        setSize={setSize}
        setSortField={setSortField}
        sortField={sortField}
        setShowUnAssignCourier={setShowAssign}
        setShowAssignCourier={setShow}
        SetDeliveryId={SetDeliveryId}
        setDelivPackage={setDelivPackage}
        setDelivCourier={setDelivCourier}
        setDelivTitle={setTitle}
        totalCount={totalCount}
        setNewPage={setNewPage}
        newPage={newPage}
      />
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  Tracking Id
                </label>
                <div
                  className="customDateRange"
                  style={{ color: "black", position: "relative" }}
                >
                  <input
                    type="text"
                    value={addDeliveryTask?.values?.trackingId}
                    className={"mainInput mr_15"}
                    onChange={(e) => {
                      addDeliveryTask.setFieldValue(
                        "trackingId",
                        e.target.value
                      );
                    }}
                    disabled={title !== "Add Delivery task"}
                  />
                  {addDeliveryTask.touched.trackingId &&
                  addDeliveryTask.errors.trackingId ? (
                    <p className="requiredText">
                      {addDeliveryTask.errors.trackingId}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  Courier
                </label>
                <div
                  className="customDateRange"
                  style={{ color: "black", position: "relative" }}
                >
                  <AsyncSearchInput
                    id="employeeCode"
                    label="employeeCode"
                    name="employeeCode"
                    value={selectedOptionCourier?.label}
                    loadMoreOptions={loadMoreOptionsCourierFilter}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onChange={(selectedOptionCourier) => {
                      setSelectedOptionCourier(selectedOptionCourier?.label);
                      addDeliveryTask.setFieldValue(
                        "employeeCode",
                        selectedOptionCourier ? selectedOptionCourier.value : ""
                      );
                    }}
                    placeholder="Courier"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    menuStyle={{ zIndex: 9999 }}
                  />
                  {addDeliveryTask.touched.employeeCode &&
                  addDeliveryTask.errors.employeeCode ? (
                    <p className="requiredText">
                      {addDeliveryTask.errors.employeeCode}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_24">
            <div>
              <button
                type="button"
                className="blue_btn"
                onClick={addDeliveryTask.handleSubmit}
              >
                {title == "Add Delivery Task" ? "Add" : "Assign"}
              </button>
            </div>
            <div>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>
      {showAssign && (
        <Modal
          className="scanConfirmpopup"
          show={showAssign}
          onHide={handleassignClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
              Unassign the courier from the Consignment?
            </h2>
            <>
              <div className="displayFlex alignItemCenter justifyContent_center">
                <ul className="emptyUnorderList mb_12">
                  <li className="fontSize14 fontWeight400 blackText">
                    -do you want to unassign {delivCourier} from {delivPackage}?
                  </li>
                </ul>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <div>
              <button
                className="deletebtn cancelPrimary"
                onClick={() => handleassignCancel()}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="deletebtn confirmPrimary"
                onClick={unAssignCourier}
              >
                Unassign
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default DeliveryTasks;
