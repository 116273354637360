import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useAoneStore } from "../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRatesStore } from "../../store/Rates/RateStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const GpaModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  ratesbyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postGpa = useRatesStore((state) => state?.postGpa);
  const editGpa = useRatesStore((state) => state?.editGpa);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const productsData = useAoneStore((state) => state?.productsData);
  const getOrigin = useAoneStore((state) => state?.getOrigin);
  const setOriginData = useAoneStore((state) => state?.setOriginData);
  const getDestination = useAoneStore((state) => state?.getDestination);
  const getProductsbyId = useAoneStore((state) => state?.getProductsbyId);
  const [prodWeightUnit, setProdWeightUnit] = useState("");
  const [prodmaxWeight, setProdMaxWeight] = useState("");
  const setDestinationData = useAoneStore((state) => state?.setDestinationData);
  const [destinationCountries, setDestinationCountries] = useState([]);
  const productWeightData = useAoneStore((state) => state?.productWeightData);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [originCountries, setOriginCounties] = useState([]);
  const [selectedOptionProduct, setSelectedOptionProduct] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const [data, setData] = useState("");
  const currentDate = new Date();
  const getOriginCountry = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(productCode, paramslink);
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString + " UTC");
    const year = date.getUTCFullYear();
    const month = padZeroes(date.getUTCMonth() + 1);
    const day = padZeroes(date.getUTCDate());
    return `${year}-${month}-${day}`;
  }
  function padZeroes(value) {
    return value.toString().padStart(2, "0");
  }
  const addGpa = useFormik({
    initialValues: {
      productCode: title === "Edit GPA Rate" ? data?.productCode : "",
      originCountryCode:
        title === "Edit GPA Rate" ? data?.originCountryCode : "",
      destinationCountryCode:
        title === "Edit GPA Rate" ? data?.destinationCountryCode : "",
      percent: title === "Edit GPA Rate" ? data?.percent : "",
      effectiveDate: title === "Edit GPA Rate" ? data?.effectiveDate : "",
      minWeight: title === "Edit GPA Rate" ? data?.minWeight : "",
      minValue: title === "Edit GPA Rate" ? data?.minValue : "",
      maxWeight: title === "Edit GPA Rate" ? data?.maxWeight : "",
      weightUnit: title === "Edit GPA Rate" ? data?.weightUnit : "",
      remark: title === "Edit GPA Rate" ? data?.remark : "",
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      percent: Yup.number()
        .required("Percent is required")
        .typeError("Percent must be a number")
        .min(0, "Percent must be greater than or equal to 0"),
        minValue: Yup.number()
        .typeError("Minimum value must be a number")
        .min(0, "Minimum value must be greater than or equal to 0"),
        minWeight: Yup.number()
        .required("Minimum Weight is required")
        .typeError("Minimum Weight must be a number")
        .min(0, "Minimum Weight must be greater than or equal to 0"),
        maxWeight: Yup.number()
        .required("Maximum weight is required")
        .typeError("Maximum weight must be a number")
        .min(1, "Minimum Weight must be greater than or equal to 1")
        .when(["minWeight"], (minWeight, schema) =>
          schema.test({
            test: function (maxWeight) {
              if (minWeight && maxWeight) {
                return maxWeight > minWeight;
              }
              return true;
            },
            message: "Maximum weight must be greater than Minimum weight",
          })
        ),
        weightUnit: Yup.string().required("Weight unit is required")
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      if (title === "Edit GPA Rate") {
        await editGpa(ratesbyCode?.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
            toast.success("GPA Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setShowModal(false);
          } else if (response?.data?.errors?.length >= 1) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response?.data?.status == "SUCCESS") {
            toast.success("GPA Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.data?.errorCode !== "") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errors?.length >= 1) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postGpa(formattedValues).then((response) => {
          if (response.status == "201") {
            toast.success("GPA Rate Added");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status == "SUCCESS") {
            toast.success("GPA Rate Added");
            resetForm();
            setispostAdded(!ispostAdded);
          } else if (response?.data?.errors?.length >= 1) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  useEffect(() => {
    if (title == "Edit GPA Rate"){
    addGpa.setFieldValue("productCode", ratesbyCode?.productCode);
    addGpa.setFieldValue("originCountryCode", ratesbyCode?.originCountryCode);
    addGpa.setFieldValue("destinationCountryCode", ratesbyCode?.destinationCountryCode);
    addGpa.setFieldValue("percent", ratesbyCode?.percent);
    addGpa.setFieldValue("effectiveDate", ratesbyCode?.effectiveDate);
    addGpa.setFieldValue("minWeight", ratesbyCode?.minWeight);
    addGpa.setFieldValue("minValue", ratesbyCode?.minValue);
    addGpa.setFieldValue("maxWeight", ratesbyCode?.maxWeight);
    addGpa.setFieldValue("weightUnit", ratesbyCode?.weightUnit);
    addGpa.setFieldValue("remark", ratesbyCode?.remark);
    }
  }, [title, ratesbyCode]);
  const getOriginCountryonEdit = async () => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addGpa.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addGpa.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  const getProductsonRateEdit = async () => {
    const resp = await getProductsbyId(ratesbyCode?.productCode);
    setProdMaxWeight(resp?.data?.data?.maxWeight);
    setProdWeightUnit(resp?.data?.data?.weightUnit);
  };
  useEffect(() => {
    if (title == "Edit GPA Rate" && ratesbyCode?.productCode !== undefined) {
      getOriginCountryonEdit();
      getProductsonRateEdit();
    }
  }, [addGpa.values.productCode]);
  const [flatGpapercentError, setFlatGpaPercentError] = useState("");
  const [minValueError, setMinValueError] = useState("");
  const [minWeightError, setMinWeightError] = useState("");
  const [maxWeightError, setMaxWeightError] = useState("");
  const [convertedWeight, SetConvertedWeight] = useState("");
  useEffect(() => {
    // Define conversion functions
    const convertGramToKg = (weight) => weight / 1000;
    const convertKgToGram = (weight) => weight * 1000;
    const convertPoundToKg = (weight) => weight / 2.2;
    const convertKgToPound = (weight) => weight * 2.2;

    // Define the converted weight variable
    let convertedWeight = prodmaxWeight;

    // Handle conversions
    if (prodWeightUnit === addGpa?.values?.weightUnit) {
      // No conversion needed
      SetConvertedWeight(prodmaxWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addGpa?.values?.weightUnit === "KG"
    ) {
      convertedWeight = convertGramToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "GRAM" &&
      addGpa?.values?.weightUnit === "POUNDS"
    ) {
      // Convert from GRAM to KG, then from KG to POUNDS
      convertedWeight = convertKgToPound(convertGramToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addGpa?.values?.weightUnit === "GRAM"
    ) {
      convertedWeight = convertKgToGram(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "KG" &&
      addGpa?.values?.weightUnit === "POUNDS"
    ) {
      convertedWeight = convertKgToPound(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addGpa?.values?.weightUnit === "KG"
    ) {
      convertedWeight = convertPoundToKg(prodmaxWeight);
      SetConvertedWeight(convertedWeight);
    } else if (
      prodWeightUnit === "POUNDS" &&
      addGpa?.values?.weightUnit === "GRAM"
    ) {
      // Convert from POUNDS to KG, then from KG to GRAM
      convertedWeight = convertKgToGram(convertPoundToKg(prodmaxWeight));
      SetConvertedWeight(convertedWeight);
    }
  }, [
    prodmaxWeight,
    prodWeightUnit,
    addGpa?.values?.weightUnit,
    SetConvertedWeight,
  ]);
  const handleSaveVatRates = (initialValues) => {
    if (addGpa.values.maxWeight > convertedWeight) {
      setMaxWeightError(
        `Maximum Weight should be less than or equal to ${convertedWeight}`
      );
  }else if (/\s/g.test(addGpa.values.percent)) {
      setFlatGpaPercentError(
        "Percent cannot contain leading, trailing, or consecutive spaces"
      );
    } else if(/\s/g.test(addGpa.values.minWeight)) {
      setMinWeightError(
        "Minimum Weight cannot contain leading, trailing, or consecutive spaces"
      );
    } else if(/\s/g.test(addGpa.values.maxWeight)) {
      setMaxWeightError(
        "Maximum Weight cannot contain leading, trailing, or consecutive spaces"
      );
    } else if(/\s/g.test(addGpa.values.minValue)) {
      setMinValueError(
        "Minimum Value cannot contain leading, trailing, or consecutive spaces"
      );
    } else if (
      addGpa.values.weightUnit === "GRAM" &&
      addGpa.values.minWeight > 69999
    ) {
      setMinWeightError("Minimum Weight should not be greater than 69999");
    } else if (
      addGpa.values.weightUnit === "KG" &&
      addGpa.values.minWeight > 69
    ) {
      setMinWeightError("Minimum Weight should not be greater than 69");
    } else if (
      addGpa.values.weightUnit === "POUNDS" &&
      addGpa.values.minWeight > 153
    ) {
      setMinWeightError("Minimum Weight should not be greater than 153");
    } else {
      setFlatGpaPercentError("");
      setMinValueError("");
      setMinWeightError("");
      setMaxWeightError("");
      addGpa?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollGpa">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Product{" "}
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="productCode"
                      label="productCode"
                      name="productCode"
                      options={productsData?.data?.data?.content || []}
                      getOptionLabel={(option) =>
                        `${option.code} - ${option.name}`
                      }
                      getOptionValue={(option) => option.code}
                      isClearable={true}
                      value={
                        addGpa?.values?.productCode
                          ? productsData?.data?.data?.content?.find(
                              (prod) =>
                                prod.code === addGpa?.values?.productCode
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        setSelectedOptionProduct(selectedOption);
                        addGpa.setFieldValue(
                          "productCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        const resp = await getProductsbyId(
                          selectedOption?.code
                        );
                        getOriginCountry(selectedOption?.code);
                        setProdMaxWeight(resp?.data?.data?.maxWeight);
                        setProdWeightUnit(resp?.data?.data?.weightUnit);
                      }}
                      isSearchable={true}
                      placeholder="Product"
                      customStyles={{ control: { width: "208px" } }}
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addGpa.touched.productCode && addGpa.errors.productCode ? (
                      <p className="requiredText">
                        {addGpa.errors.productCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Origin Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="originCountryCode"
                      label="originCountryCode"
                      name="originCountryCode"
                      options={originCountries || []}
                      getOptionLabel={(option) => option.countryName}
                      getOptionValue={(option) => option.countryCode}
                      isClearable={true}
                      handleChange={async (selectedOption) => {
                        addGpa.setFieldValue(
                          "originCountryCode",
                          selectedOption ? selectedOption.countryCode : ""
                        );
                      }}
                      value={
                        addGpa?.values?.originCountryCode
                          ? originCountries?.find(
                              (country) =>
                                country.countryCode ===
                                addGpa?.values?.originCountryCode
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Origin Country"
                      customStyles={{ control: { width: "208px" } }}
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addGpa.touched.originCountryCode &&
                    addGpa.errors.originCountryCode ? (
                      <p className="requiredText">
                        {addGpa.errors.originCountryCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Destination Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="destinationCountryCode"
                      label="destinationCountryCode"
                      name="destinationCountryCode"
                      options={destinationCountries || []}
                      getOptionLabel={(option) => option.countryName}
                      getOptionValue={(option) => option.countryCode}
                      isClearable={true}
                      handleChange={async (selectedOption) => {
                        addGpa.setFieldValue(
                          "destinationCountryCode",
                          selectedOption ? selectedOption.countryCode : ""
                        );
                      }}
                      value={
                        addGpa?.values?.destinationCountryCode
                          ? destinationCountries?.find(
                              (country) =>
                                country.countryCode ===
                                addGpa?.values?.destinationCountryCode
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Destination Country"
                      customStyles={{ control: { width: "208px" } }}
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addGpa.touched.destinationCountryCode &&
                    addGpa.errors.destinationCountryCode ? (
                      <p className="requiredText">
                        {addGpa.errors.destinationCountryCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
            <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Weight Unit
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="weightUnit"
                      label="weightUnit"
                      name="weightUnit"
                      options={productWeightData?.data?.data || []}
                      getOptionLabel={(region) => region.label}
                      getOptionValue={(region) => region.key}
                      isClearable={true}
                      handleChange={async (selectedOption) => {
                        addGpa.setFieldValue(
                          "weightUnit",
                          selectedOption ? selectedOption.key : ""
                        );
                        setMaxWeightError("");
                      }}
                      value={
                        addGpa?.values?.weightUnit
                          ? productWeightData?.data?.data?.find(
                              (prodWt) =>
                                prodWt?.key ===
                                addGpa?.values?.weightUnit
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Weight Unit"
                      customStyles={{ control: { width: "208px" } }}
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addGpa.touched.weightUnit &&
                    addGpa.errors.weightUnit ? (
                      <p className="requiredText">
                        {addGpa.errors.weightUnit}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Min Weight{" "}
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="minWeight"
                      value={addGpa.values.minWeight}
                      onChange={(e) => {
                        addGpa.setFieldValue("minWeight", e.target.value);
                        setMinWeightError("");
                      }}
                      placeholder="Minimum Weight"
                      className="mainInput"
                      onBlur={addGpa.handleBlur}
                    />
                    {addGpa.touched.minWeight &&
                    addGpa.errors.minWeight &&
                    minWeightError === "" ? (
                      <p className="requiredText">{addGpa.errors.minWeight}</p>
                    ) : null}
                    {minWeightError !== "" ? (
                      <p className="requiredText">{minWeightError}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Max Weight{" "}
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="maxWeight"
                      value={addGpa.values.maxWeight}
                      onChange={(e) => {
                        addGpa.setFieldValue("maxWeight", e.target.value);
                        setMaxWeightError("");
                      }}
                      placeholder="Max Weight"
                      className="mainInput"
                      onBlur={addGpa.handleBlur}
                    />
                    {addGpa.touched.maxWeight &&
                    addGpa.errors.maxWeight &&
                    maxWeightError === "" ? (
                      <p className="requiredText">{addGpa.errors.maxWeight}</p>
                    ) : null}
                    {maxWeightError !== "" ? (
                      <p className="requiredText">{maxWeightError}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-4 mt_3">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Effective Date
                    </label>
                    <div
                      className="customDateRange"
                      style={{ color: "black", position: "relative" }}
                    >
                      <DatePicker
                        placeholderText="Effective Date"
                        placement="rightStart"
                        selected={
                          addGpa.values.effectiveDate
                            ? new Date(addGpa.values.effectiveDate)
                            : null
                        }
                        onChange={(date) => {
                          addGpa.setFieldValue("effectiveDate", date);
                        }}
                        onClean={() =>
                          addGpa.setFieldValue("effectiveDate", null)
                        }
                        className="mainInput width208"
                        showYearDropdown
                        minDate={currentDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Min Value{" "}
                    </label>
                    <input
                      type="text"
                      name="minValue"
                      value={addGpa.values.minValue}
                      onChange={(e) => {
                        addGpa.setFieldValue("minValue", e.target.value);
                        setMinValueError("");
                      }}
                      placeholder="Minimum Value"
                      className="mainInput"
                      onBlur={addGpa.handleBlur}
                    />
                        {addGpa.touched.minValue &&
                    addGpa.errors.minValue ? (
                      <p className="requiredText">{addGpa.errors.minValue}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Percent{" "}
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="percent"
                      value={addGpa.values.percent}
                      onChange={(e) => {
                        addGpa.setFieldValue("percent", e.target.value);
                        setFlatGpaPercentError("");
                      }}
                      placeholder="Percent"
                      className="mainInput"
                      onBlur={addGpa.handleBlur}
                    />
                    {addGpa.touched.percent &&
                    addGpa.errors.percent &&
                    flatGpapercentError === "" ? (
                      <p className="requiredText">{addGpa.errors.percent}</p>
                    ) : null}
                    {flatGpapercentError !== "" ? (
                      <p className="requiredText">{flatGpapercentError}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-12">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Remark
                    </label>
                    <textarea
                      className="washMe_btn"
                      placeholder="Remark"
                      name="description"
                      onChange={(e) =>
                        addGpa.setFieldValue("description", e.target.value)
                      }
                      onBlur={addGpa.handleBlur}
                      value={addGpa.values.description}
                      style={{ height: "90px", paddingTop: "6px" }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button
            className="blue_btn"
            type="button"
            onClick={handleSaveVatRates}
          >
            {title === "Create new GPA Rate" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default GpaModal;
