import "./style.scss";
import { Routes } from "./components/routes/Routes";
import { Toaster } from "react-hot-toast";
function App() {

  return (
    <>
      <Toaster position="top-right" reverseOrder={false}/>
      <Routes/>
    </>
  );
}

export default App;
