import React from 'react';
import SortingIcon from "../../assets/img/SortingIcon.svg";
import SortIcon from "../../assets/img/SortdownIcon.svg"
import SortUpIcon from "../../assets/img/SortUpIcon.svg"

const SortingIconComponent = ({ sortField, fieldName }) => {
  let icon = SortIcon; // Default icon
  if (sortField === fieldName) {
    icon = SortUpIcon;
  } else if (sortField === '-' + fieldName) {
    icon = SortIcon;
  }
  return (
    <span>
      <img src={icon} className="h18 turnBlack" alt="Sorting Icon" />
    </span>
  );
};

export default SortingIconComponent;
