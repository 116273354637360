import React, { useEffect, useState } from 'react'
import PaginationTable from '../../components/Tables/paginationTable'
import searchicon from "../../assets/img/searchicon.svg"
import assignIcon from "../../assets/img/assign_icon.svg"
import downloadicon from "../../assets/img/download_icon.svg"
import { useParams, useNavigate } from 'react-router'
import { useAoneStore } from '../../store/AoneStore'
import { logout } from '../../helper/auth_helper'
import fileDownload from 'js-file-download'
import AssignerModal from '../../components/modal/AssignerModal'

const DetailedRole = () => {

    const params = useParams();
    const navigate = useNavigate();

    const getRoleById = useAoneStore((state) => state?.getRoleById)
    const updateRoleById = useAoneStore((state) => state?.updateRoleById)
    const getUsersByRoleId = useAoneStore((state) => state?.getUsersByRoleIdOrGroupId)
    const downloadUsersByRoleId = useAoneStore((state) => state?.downloadUsersByRoleId)

    const [tab, setTab] = useState(true)
    const [role, setRole] = useState({})

    const [users, setUsers] = useState([])

    const [ newPage, setNewPage ] = useState(0)
    const [ size, setSize ] = useState(20)
    const [ totalCount, setTotalCount] = useState(0)

    const [modalHandler, setModalHandler] = useState(false)
    const [deleteHandler, setDeleteHandler] = useState(false)

    const getData = async () => {
        const id = params.id
        const data = await getRoleById(id)
        if(data.data.data){
            setRole(data?.data?.data)
        }else{
            logout();
        }
    }

    const getAssignedUsers = async () => {
        const rolesParams = `?page=${newPage}&size=${size}&roleId=${params.id}`
        const data = await getUsersByRoleId(rolesParams)
        setUsers(data?.data?.data?.content)
        setTotalCount(data?.data?.data?.totalElements)
        console.log("USERS",data?.data?.data);
    }

    const updateRole = async () => {
        await updateRoleById(params?.id,role)
        setEditHandler(!editHandler)
    }

    const downloadUsers = () => {
        const roleId = `?roleId=${params?.id}`
        downloadUsersByRoleId(roleId).then((res)=>{
          fileDownload(res.data, `Users.xlsx`);
        })
      }

    const [editHandler,setEditHandler] = useState(false)

    const app = ["AONE","AIMS","WMS"]
    const permission = ["read","write","admin","assign","print"]

    const [dropdownOne, setDropdownOne] = useState(false)
    const [dropdownTwo, setDropdownTwo] = useState(false)

    const handleChange = (name, value) => {
        let data = {...role}
        data[name] = value
        setRole(data)
    }

    useEffect(()=>{
        getData();
    },[editHandler])

    useEffect(()=>{
        getAssignedUsers();
    },[deleteHandler,modalHandler])

  return (
    <div>
        <div className='row alignItemCenter'>
          <div className='col-md-7'>
            <h3 className='mainheadtext' >
              <span className='mainheadlink' onClick={()=>navigate("/iam/roles")} >Roles</span>/ 
              <span className='mainheadlink'>{role?.name}</span> 
            </h3>
          </div>
        </div>

        <div className='displayFlex tabletab_section mt_20 mb_24'>
          <button className={`filterBtn ${tab && `activeFilterBtn`}`} onClick={()=>setTab(true)}>Details</button>
          <button className={`filterBtn ${!tab && `activeFilterBtn`}`} onClick={()=>setTab(false)}>Users In Role</button>
        </div>

        { tab ? 
        <>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Role Name:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' placeholder='Role Name' className='mainInput'
                    value={role?.name} disabled={true}
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>App:</label>
            </div>
            <div className='col-md-5'>
               <div className="positionRelative">
                    <input
                    type="text"
                    className={`seletDropdownIcOn ${dropdownOne && "activeBorderColor"}`}
                    value={role?.app}
                    // onClick={() => editHandler && setDropdownOne(!dropdownOne)}
                    disabled={true}
                    />
                    {dropdownOne && (
                    <div className="dropdownResult">
                        { app.map((e,i) => {
                            return <div key={i}
                            className="dropdownResultItem"
                            onClick={() => {
                                setDropdownOne(!dropdownOne);
                                handleChange("app",e);
                            }}
                            >
                            {e}
                            </div>
                        }) }
                    </div>
                    )}
                </div>
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Module:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' name="module" placeholder='' 
                    value={role?.module} 
                    // onChange={(e)=>handleChange(e.target.name,e.target.value)}
                    className='washMe_btn p12_washme' disabled={true}
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Sub Module:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' name='submodule' placeholder='' 
                    value={role?.subModule}
                    // onChange={(e)=>handleChange(e.target.name,e.target.value)}
                    className='washMe_btn p12_washme' disabled={true}
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Entity/Model:</label>
            </div>
            <div className='col-md-5'>
                <input 
                    type='text' name='model' placeholder='' 
                    value={role?.model}
                    // onChange={(e)=>handleChange(e.target.name,e.target.value)}
                    className='washMe_btn p12_washme' disabled={true}
                />
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Permission:</label>
            </div>
            <div className='col-md-5'>
                <div className="positionRelative">
                    <input
                    type="text"
                    className={`seletDropdownIcOn ${dropdownTwo && "activeBorderColor"}`}
                    value={role?.permission}
                    onClick={() => editHandler && setDropdownTwo(!dropdownTwo)}
                    readOnly
                    />
                    {dropdownTwo && (
                    <div className="dropdownResult">
                        { permission.map((e,i) => {
                            return <div key={i}
                            className="dropdownResultItem"
                            onClick={() => {
                                setDropdownTwo(!dropdownTwo);
                                handleChange("permission",e)
                            }}
                            >
                            {e}
                            </div>
                        }) }
                    </div>
                    )}
                </div>
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2 fontSize14 menacingCloudsText fontWeight500'>
                <label>Description:</label>
            </div>
            <div className='col-md-5'>
                <textarea className='washMe_btn p12_washme' name='description' value={role?.description} style={{ height: "100px", paddingTop: "6px"}}
                onChange={(e)=>handleChange(e.target.name,e.target.value)} disabled={!editHandler}></textarea>
            </div>
        </div>
        <div className='row mb_24'>
            <div className='col-md-2'>
            </div>
            <div className='col-md-5 displayFlex alignItemCenter justifyContent_end gap_16'>
                { !editHandler &&
                <button className='blue_btn' onClick={()=>setEditHandler(!editHandler)}>
                Edit
                </button>
                }
                { editHandler &&
                <>
                    <button className='blue_btn' onClick={()=>updateRole()}>
                    Save
                    </button>
                    <button  className='cancel_btn' onClick={()=>setEditHandler(!editHandler)}>
                    Cancel
                    </button>
                </>
                }   
            </div>
        </div>
        </>
        :
        <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
          <div className='displayFlex justifyContent_end'>
            <div className='displayFlex alignItemCenter InputGroup '>
                <span className='inputsearch'>
                <img src={searchicon} className='turnBlack h18'/>
                </span>
                <input type='text' placeholder='Search' className='Maininput'/>
            </div>
          </div>

          <div className='displayFlex gap_12'> 
            <div title='Download Users'>
              <button className="filterBtn" onClick={()=>downloadUsers()}>
                <span><img src={downloadicon} className='h18'/></span>
              </button>
            </div>
            <div title='Assign Users'>
              <button className='filterBtn' onClick={()=>setModalHandler(!modalHandler)}>
                <span><img src={assignIcon} className='h18'/></span>
              </button>
            </div>    
          </div> 
        </div> 

        <PaginationTable tableData={users} tbl="Roles_Users" deleteHandler={deleteHandler} setDeleteHandler={setDeleteHandler} 
            newPage={newPage} setNewPage={setNewPage} size={size} setSize={setSize} totalCount={totalCount}/>             
        </div>
        }
        {
            modalHandler && <AssignerModal modalHandler={modalHandler} setModalHander={setModalHandler} title={"Assign User"}/>
        }
    </div>
  )
}

export default DetailedRole