import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate, useParams } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import { useCollectionStore } from "../../../store/CollectionsMgt/CollectionStore";
import { UserContext } from "../../../helper/ProtectedRoute";
import { Tabs, Tab } from "@mui/material";
import { DateRangePicker } from "rsuite";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PaginationTable from "../../../components/Tables/paginationTable";
const Settlement = () => {
  const navigate = useNavigate();
  const paramHeader = useParams();
  const [ledgerData, setLedgerData] = useState("");
  const [sortField, setSortField] = useState("-updatedAt");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [dateRange, setDateRange] = useState([]);
  const [cashList, setCashList] = useState("CASH");
  const [pendingCash, setPendingCash] = useState("");
  const [currency,setCurrency] = useState("");
  const [collectionTracking,setCollectionTracking] = useState("");
  const [proofUrl,setProofUrl] = useState("");
  const [proofErr,setProofErr] = useState("");
  const [ischangeTable,setIsChangeTable] = useState(false);
  useEffect(()=>{
    setCurrency( sessionStorage.getItem("EmployeeCurrency"));
  },[])
  useEffect(()=>{
    setCollectionTracking( sessionStorage.getItem("collectionTracking"));
  },[])
  const [pendingPos, setPendingPos] = useState("");
  const [pendingSoftPos, setPendingSoftPos] = useState("");
  const [pendingTotal, setPendingTotal] = useState("");
  const [settleCheckbox, setSettleCheckboxes] = useState([]);
  const [totalSettled, setTotalSettled] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectCollections, setSelectCollections] = useState([]);
  const [collectionSum, setCollectionSum] = useState([]);
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const exportCollections = useCollectionStore(
    (state) => state?.exportCollections
  );
  const postSettlement = useCollectionStore((state) => state?.postSettlement);
  const uploadProof = useCollectionStore((state) => state?.uploadProof);
  const getUnsettledCollection = useCollectionStore(
    (state) => state?.getUnsettledCollection
  );
  const getValues = async () => {
    const res = await getUnsettledCollection(
      paramHeader?.id,
      currency
    );
    setPendingCash(res?.data?.data?.pendingCash);
    setPendingPos(res?.data?.data?.pendingPos);
    setPendingSoftPos(res?.data?.data?.pendingSoftPos);
    setPendingTotal(res?.data?.data?.totalPending);
  };
  const [showAssign, setShowAssign] = useState(false);
  const handleassignShow = () => setShowAssign(true);
  const handleassignClose = () => {
    setShowAssign(false);
  };
  const handleassignCancel = () => {
    setShowAssign(false);
  };
  useEffect(() => {
    if(currency){
    getValues();
    }
  }, [currency]);
  useEffect(() => {
    const total = collectionSum?.reduce((acc, curr) => acc + curr, 0);
    setTotalSettled(total);
  }, [collectionSum]);
  const [state] = useContext(UserContext);
  const [tab, setTab] = useState(1);
  const tabHandler = (i) => {
    setIsChangeTable(!ischangeTable);
    setSelectAll(false);
    setSelectCollections([]);
    if (i == 1) {
      setCashList("CASH");
    } else if (i == 2) {
      setCashList("POS");
    } else if (i == 3) {
      setCashList("SOFT_POS");
    } else {
      setCashList("CASH_POS");
    }
    setTab(i);
  };
  const [uploadName, setUploadName] = useState("");
  function handleFileUpload(event) {
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileNameParts = fileName?.split(".");
    const allowedExtensions = [
      ".png",
      ".pdf",
      ".doc",
      ".docx",
      ".jpg",
      ".jpeg",
    ];
    const fileExtension =
      fileNameParts[fileNameParts?.length - 1]?.toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      toast.error(
        "Invalid file format. Please upload a .pdf, .doc, .docx, .jpg, .jpeg file or .png"
      );
      return;
    }
    const fileSizeLimit = 1024 * 1024;
    if (file?.size > fileSizeLimit) {
      toast.error("File size should not exceed 1MB.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file); // Append the file to the FormData object
    uploadProofDoc(formData)
    setUploadName(file);
    // Check if the element with the ID "file-name" exists in the document
    const fileNameDisplay = document.getElementById("file-name");
    if (fileNameDisplay) {
      const fileInput = event.target;
      if (fileInput?.files?.length > 0) {
        fileNameDisplay.textContent = `Selected file: ${fileInput?.files[0]?.name}`;
      } else {
        fileNameDisplay.textContent = "";
      }
    }
  }
  const uploadProofDoc = async (formData)=>{
    const res = await uploadProof(formData);
    setProofUrl(res?.data?.data);
    setProofErr("");
  }
  const [settleId,setSettleId] = useState("")
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}`;
    if (settleId) {
      params += `&settlementId=${settleId}`;
    }
    try {
      const res = await exportCollections(params);
      if (res.status == 200) {
        fileDownload(res.data, `Settlement.xlsx`);
        navigate("/finance/cashdebriefing");
      }
    } catch (err) {}
  };
  const getCollections = useCollectionStore((state) => state?.getCollections);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const handleChange = (event, newValue) => {
    setTab(newValue + 1);
    tabHandler(newValue + 1);
  };
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const [dateRangefil, setDateRangefil] = useState({});
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      const maxAllowedDays = 90; // Maximum allowed range in days
      // Calculate the difference in days
      const dayDifference = Math.abs(
        Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
      );
      // Check if the selected range is within the allowed range
      if (dayDifference <= maxAllowedDays) {
        setDateRange(value);
        const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
        const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
        setDateRangefil({
          start: startMillis,
          end: endMillis,
        });
      } else {
        toast.error("Daterange Should not exceed more than 3 months");
      }
    } else {
      // Reset date range
      setDateRange([]);
      // Set time range for the whole day
      setDateRangefil([]);
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&employeeCode=${paramHeader?.id}&status=UNSETTLED`;
    if (cashList) {
      params += `&mode=${cashList}`;
    }
    if (collectionTracking) {
      params += `&trackingId=${collectionTracking}`;
    }
    if (
      dateRangefil.start !== null &&
      dateRangefil.end !== null &&
      !isNaN(dateRangefil.start) &&
      !isNaN(dateRangefil.end) &&
      dateRangefil.start !== dateRangefil.end
    ) {
      params += `&startTime=${dateRangefil.start}&endTime=${dateRangefil.end}`;
    }
    const data = await getCollections(params);
    setTotalCount(data.data.data.totalElements);
    setLedgerData(data.data.data.content);
  };
  useEffect(() => {
    if(sessionStorage.getItem.collectionTracking && collectionTracking){
    getData();
    }else{
      getData();
    }
  }, [
    newPage,
    size,
    sortField,
    cashList,
    collectionTracking,
    dateRangefil.start,
    dateRangefil.end,
  ]);
  const settleCashDebriefing = async () =>{
    const payload = {
      employeeCode: paramHeader?.id,
      currency: currency,
      collectionIdList: selectCollections,
      proofUrl: proofUrl
    }
    if(selectCollections?.length < 1){
      toast.error("Please select collections to settle")
    }else if(proofUrl !== ""){
      const res= await postSettlement(payload);
      if(res?.data?.status == "SUCCESS"){
        setSettleId(res?.data?.data);
        handleassignShow();
        setSelectAll(false);
        setSelectCollections([]);
        setTimeout(() => {
        navigate("/finance/cashdebriefing");
        }, 5000);
      }else{
        toast.error(res?.data?.msg)
      }
    }else{
      setProofErr("Please upload the proof for settlement")
    }
  }
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  navigate("/finance/cashdebriefing");
                }}
              >
                Finance
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  navigate("/finance/cashdebriefing");
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                }}
              >
                Cash Debriefing
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
                onClick={() => {
                  navigate("/finance/cashdebriefing");
                }}
              >
                {paramHeader?.id}
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
                Settlement
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="borderFrame mb_16">
        <div className="borderFrameTitle">Settlement</div>
        <div className="row mt_-30px">
          <div className="col-md-4" style={{ padding: "12px" }}>
            <label className="fontSize12 marginR10">Interval:</label>
            <DateRangePicker
              format="yyyy-MM-dd"
              placeholder="Select Interval"
              value={dateRange}
              onChange={handleDateRangeChange}
              placement="auto"
              minDate={new Date(new Date().setMonth(new Date().getMonth() - 3))}
              onOpen={handleMenuOpen}
              onClose={handleMenuClose}
              showMeridian
              className="w200"
            />
          </div>
          <div className="col-md-4 p18">
            <label className="fontSize12 marginR10">Currency:</label>
            <span className="total-success mt_5 fw600">
              {currency}
            </span>
          </div>
        </div>
        <div className="borderFrame mb_16">
          <div className="borderFrameTitle">Group Name</div>
          <div className="row mt_-30px">
            <div className="col-md-4 p12">
              <label className="fontSize12 marginR10">Pending Cash:</label>
              <span className="mt_5 fw600">{pendingCash}</span>
            </div>
            <div className="col-md-4 p12">
              <label className="fontSize12 marginR10">Pending POS:</label>
              <span className="mt_5 fw600">{pendingPos}</span>
            </div>
            <div className="col-md-4 p12">
              <label className="fontSize12 marginR10">Pending SoftPOS:</label>
              <span className="mt_5 fw600">{pendingSoftPos}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 fw12">
              <label className="fontSize12 marginR10">Total Pending:</label>
              <span className="mt_5 fw600">{pendingTotal}</span>
            </div>
          </div>
        </div>
        <div className="tabletab_sectionnew">
          <Tabs
            value={tab - 1}
            onChange={handleChange}
            aria-label="user details and attributes tabs"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#EC171C",
              },
              "& .MuiTab-root": {
                color: "gray",
                fontWeight: "normal",
                fontFamily: "Arial, sans-serif",
                textTransform: "none",
              },
              "& .Mui-selected": {
                color: "#EC171C !important",
                fontWeight: "bold",
                fontFamily: "Arial, sans-serif",
                textTransform: "none",
              },
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Cash" />
            <Tab label="POS" />
            <Tab label="SoftPOS" />
            <Tab label="CashPOS" />
          </Tabs>
        </div>
        <PaginationTable
          tableData={ledgerData}
          tbl={"Settle"}
          newPage={newPage}
          setNewPage={setNewPage}
          setSelectAllCollections={setSelectAll}
          selectAllCollections={selectAll}
          setSelectCollections={setSelectCollections}
          ischangeTable={ischangeTable}
          selectCollections={selectCollections}
          setCollectionSum={setCollectionSum}
          collectionSum={collectionSum}
          setSettleCheckboxes={setSettleCheckboxes}
          settleCheckbox={settleCheckbox}
          size={size}
          setCollectionsTab={setTab}
          setSize={setSize}
          totalCount={totalCount}
          sortField={sortField}
          setSortField={setSortField}
        />
      </div>
      <div className="totals totalsTransaction">
        <span className="total-success mt_5">
          To be settled: {totalSettled}
        </span>
      </div>
      <div className="totals totalsTransaction">
        <div className="custom-file-upload">
          <span className="mt_5">Settlement Proofs:</span>
          <input
            type="file"
            onChange={(e) => {
              handleFileUpload(e);
            }}
            name="drivingLicenseUrl"
            className="mainInput"
            id="upload"
          />
          <label htmlFor="upload" className="upload-labeldriver">
            Upload Proof
          </label>
        </div>
        <div className="file-info">
          <p className="file-name">
            <p> {uploadName?.name}</p>{" "}
          </p>
        </div>
      </div>
      {proofErr ? (
                          <p className="requiredText">
                            {proofErr}
                          </p>
                        ) : null}
      <div className="row mt_20">
        <div className="col-md-6">
          <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
            <button type="button" className="blue_btn" onClick={settleCashDebriefing}>
              Submit
            </button>
            <button
              className="cancel_btn"
              onClick={() => {
                navigate("/finance/cashdebriefing");
              }}
            >
              Discard
            </button>
          </div>
        </div>
      </div>
      {showAssign &&
      <Modal
          show={showAssign}
          onHide={handleassignClose}
          centered
        >
          <Modal.Body>
            <h2 className="fontSize20 fontWeight500 textAlignCenter blackText mb_8">
             Amount has been settled successfully
            </h2>
            <>
            </>
          </Modal.Body>
          <Modal.Footer style={{ flexWrap: "unset" }}>
            <div>
              <button
                className="confirmPrimary br5 mr190"
                onClick={() => exportToExcel()}
              >
                Print Receipt
              </button>
            </div>
          </Modal.Footer>
        </Modal>}
    </div>
  );
};

export default Settlement;
