import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getLocalization,postLocalization, editLocalization,getLocalizationbyId,
   getLocalizationbyComponent,localizationTemplate,importLocalization,exportLocalization,getLanguage,
   deleteLocalization,getSettings,postSettings,editSettings,getSettingsbyId,getSettingsbyComponent,settingsTemplate,importSettings,
   exportSettings,deleteSettings} from "../../services/Settings/Settings_Services"
const initialValues = {
  distanceList: [],
  statusList: [],
  LanguageData: []
};
let settingStore = (set, get) => ({
  ...initialValues,
  getLocalization: async (params) => {
        const response = await getLocalization(params);
        return response;
      },
      postLocalization: async (params,data) => {
        const response = await postLocalization(params,data);
        return response;
      },
      editLocalization: async (params,data) => {
        const response = await editLocalization(params,data);
        return response;
      },
      getLocalizationbyId: async (params) => {
        const response = await getLocalizationbyId(params);
        return response;
      },
      getLocalizationbyComponent: async (params,params2) => {
        const response = await getLocalizationbyComponent(params,params2);
        return response;
      },
      localizationTemplate: async () => {
        const response = await localizationTemplate();
        return response;
      },
      importLocalization: async (params,data) => {
        const response = await importLocalization(params,data);
        return response;
      },
      exportLocalization: async (params) => {
        const response = await exportLocalization(params);
        return response;
      },
      deleteLocalization: async (params) => {
        const response = await deleteLocalization(params);
        return response;
      },
      getLanguage: async (params) => {
        const response = await getLanguage(params);
        set({ LanguageData: response });
        return response;
      },
      getSettings: async (params) => {
        const response = await getSettings(params);
        return response;
      },
      postSettings: async (params,data) => {
        const response = await postSettings(params,data);
        return response;
      },
      editSettings: async (params,data) => {
        const response = await editSettings(params,data);
        return response;
      },
      getSettingsbyId: async (params) => {
        const response = await getSettingsbyId(params);
        return response;
      },
      getSettingsbyComponent: async (params,params2) => {
        const response = await getSettingsbyComponent(params,params2);
        return response;
      },
      settingsTemplate: async () => {
        const response = await settingsTemplate();
        return response;
      },
      importSettings: async (params,data) => {
        const response = await importSettings(params,data);
        return response;
      },
      exportSettings: async (params) => {
        const response = await exportSettings(params);
        return response;
      },
      deleteSettings: async (params) => {
        const response = await deleteSettings(params);
        return response;
      },
});

settingStore = devtools(settingStore);

export const usesettingStore = create(settingStore);