import React, { useEffect, useState, useRef, useContext } from "react";
import { useAoneStore } from "../store/AoneStore";
import { UserContext } from "../helper/ProtectedRoute";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";

const Sendsms = ({ }) => {
  const [wayBill, setWaybill] = useState("");
  const [wayBillArr, setWaybillArr] = useState([]);
  const [msgTemp, setMsgTemp] = useState(
    "Hello {{shipperName}}, AJEX has created a shipment request {{waybillNumber}} to your residency in Mina. You can track your shipment on (link). May Allah accept your obedience."
  );
  const postMsg = useAoneStore((state) => state?.postMsg);
  const downloadSampleSms = useAoneStore((state) => state?.downloadSampleSms)
  const uploadReciver = useAoneStore((state) => state?.uploadReciver)
  const getSmsType = useAoneStore((state) => state?.getSmsType)
  const [customdropdownOne, setCustomdropdownone] = useState(null);
  const [customdropdowntwo, setCustomdropdowtwo] = useState(null);
  const [customdropdownthree, setCustomdropdowthree] = useState(null);
  const [statusFil, setStatusFil] = useState("");
  const [statusFilN, setStatusFilN] = useState("");
  const [statusFilLoc, setStatusFilLoc] = useState("");


  const [selEngTemp] = useState([
    {
      key: "AWB Creation",
      message: "Hello {{shipperName}}, AJEX has created a shipment request {{waybillNumber}} from {{originCity}} to {{destinationCity}}. You can track your shipment on https://ajex.phptest.aj-ex.com/tracking?tracking_number={{waybillNumber}}. May Allah accept your obedience.",
    },
    {
      key: "PU Scan",
      message: "Your shipment {{waybillNumber}} has been received by AJEX and will be shipped to {{destinationCity}}. We will notify you once the shipment is received at destination."
    },
    {
      key: "Notify for  PU",
      message: "Hi {{consigneeName}}, your shipment {{waybillNumber}} has been received in {{destinationCity}} and is ready for pickup at "
    },
    {
      key: "Delivery Scan",
      message: "Hi {{shipperName}}, your shipment {{waybillNumber}} is delivered. AJEX wishes you an accepted Hajj. "
    }
  ])

  const [selArbTemp] = useState([
    {
      key: "AWB Creation",
      message: "مرحباً {{shipperName}}، تم إنشاء طلب إرسال شحنة بواسطة ايجكس برقم {{waybillNumber}} من {{originCity}} إلى {{destinationCity}}. يمكنك تتبع الشحنة على https://ajex.phptest.aj-ex.com/tracking?tracking_number={{waybillNumber}}. تقبل الله طاعتكم.",
    },
    {
      key: "PU Scan",
      message: "تم استلام شحنتك رقم {{waybillNumber}} بواسطة ايجكس و سيتم شحنها إلى {{destinationCity}}. سيتم إعلامك عند وصول الشحنة."
    },
    {
      key: "Notify for  PU",
      message: "مرحباً {{consigneeName}}، تم استلام شحنتك رقم {{waybillNumber}}  في {{destinationCity}} وجاهزة للاستلام في "
    },
    {
      key: "Delivery Scan",
      message: "مرحباً {{shipperName}}، تم توصيل شحنتك رقم {{waybillNumber}}. ايجكس تتمنى لكم حجاً مبروراً و سعياً مشكوراً."
    }
  ])

  const [location] = useState([
    {
      key: "AJEX Al Olaya service center",
      value: "https://goo.gl/maps/eTz9yZeUDR2ZhXfMA"
    },
    {
      key: "AJEX Logistics Services",
      value: "https://goo.gl/maps/Zq2nE9oHwLMETHxg7"
    }
  ])

  const [dropList, setDropList] = useState(null)
  const [smsType, setSmsType] = useState([])
  const [smsCode, setSmsCode] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);


  const handleAdd = () => {
    setWaybillArr((perv) => [...perv, wayBill]);
    setWaybill("");
  };

  const setSmsTyp = (name) => {

    const data = smsType.filter((data) => {
      return data.name === name;
    })
    console.log("fil name---->", data)
    setSmsCode(data[0]?.code)
  }

  const handleMsg = async () => {
    try {
      let data = {
        numbers: wayBillArr,
        template: msgTemp,
        receiveType: Number(smsCode)
      };
      const res = await postMsg(data);
      if (res.data.code === 200) {
        toast.success("Message Sent Sucessfully");
        setWaybillArr([]);
        setWaybill("");
        setMsgTemp("")
        setStatusFil("")
        setStatusFil("")
      } else if (res.data.code === 301) {
        let err = res.data.errorResult
        for (let i = 0; i < err.length; i++) {
          toast.error(res.data.errorResult[i].msg)
        }
      }
    } catch (err) {
      console.log("err---->", err)
    }
  };

  const downloadSamp = async () => {
    try {
      const res = await downloadSampleSms()
      if (res.status == 200) {
        fileDownload(res.data, `template.xlsx`);
        toast.success('Downloaded sucessfully')
      }
    } catch (err) {
      console.log("err---->", err)
    }
  }
  const handleFileChange = (event) => {
    console.log(event.target.files[0])
    setSelectedFile(event.target.files[0]);
  };
  const handleImage = async (e) => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const res = await uploadReciver(formData)
      if (res.data.code === 200) {
        let data = res.data.data
        let ddata = []
        for (let i = 0; i < data.length; i++) {
          ddata.push(data[i])
        }
        setWaybillArr(ddata);
        setSelectedFile(null)
        toast.success('uploaded sucessfully')
      }
    } catch (err) {
      console.log("err---->", err)
    }
  }

  const getSmsTyp = async () => {
    try {
      const data = await getSmsType()
      if (data.data.code == 200) {
        setSmsType(data.data.data)
      }
    } catch (err) {
      console.log("err---->", err)
    }
  }

  useEffect(() => {
    getSmsTyp()
  }, [])
  return (
    <>
      <div className="displayFlex alignItemCenter gap_16 mb_24">
        <div>
          {/* <button className="filterBtn">Upload Receivers</button> */}
          <label className="filterBtn displayInlineFlex alignItemCenter cursorPointer" htmlFor="uploadFile">
            Upload Receivers
            <input type="file" id="uploadFile" onChange={handleFileChange} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
          </label>
        </div>
        <div>
          <button className="filterBtn" onClick={downloadSamp}>Download Template</button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <h6 className="mb_12">Receivers</h6>
          <div className="displayFlex alignItemCenter gap_16 mb_24">
            <div>
              <input
                type="text"
                placeholder="Enter the Value"
                className="mainInput"
                value={wayBill}
                onChange={(e) => setWaybill(e.target.value)}
              />
            </div>
            <div>
              <button className="filterBtn" onClick={handleAdd}>
                add
              </button>
            </div>
          </div>
          <div className="receiverListDiv mb_24">
            {wayBillArr?.map((e) => {
              return (
                <>
                  <p>{e}</p>
                </>
              );
            })}
          </div>
          <div>
            <button className="filterBtn" onClick={() => setWaybillArr([])}>
              Clear
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <h6 className="mb_12">Message/Template</h6>
          <div class="row mb_24">
            <div className="positionRelative col-md-6">
              <input
                type="text"
                readOnly
                placeholder={statusFil === "" ? "Select Language" : statusFil}
                className="seletDropdownIcOn"
                onClick={() => setCustomdropdownone(!customdropdownOne)}
              />
              {customdropdownOne && (
                <div className="dropdownResult">
                  <div
                    className="dropdownResultItem"
                    onClick={() => {
                      setMsgTemp("")
                      setStatusFilN("")
                      setStatusFilLoc("")
                      setDropList(selEngTemp)
                      setStatusFil("English")
                      setCustomdropdownone(!customdropdownOne);
                    }}
                  >
                    English
                  </div>
                  <div
                    className="dropdownResultItem"
                    onClick={() => {
                      setMsgTemp("")
                      setStatusFilN("")
                      setStatusFilLoc("")
                      setDropList(selArbTemp)
                      setStatusFil("Arabic")
                      setCustomdropdownone(!customdropdownOne);
                    }}
                  >
                    Arabic
                  </div>
                </div>

              )}
            </div>
            <div className="positionRelative col-md-6">
              <input
                type="text"
                readOnly
                placeholder={statusFilN === "" ? "Select Template" : statusFilN}
                className="seletDropdownIcOn"
                onClick={() => setCustomdropdowtwo(!customdropdowntwo)}
              />
              {customdropdowntwo && (

                <div className="dropdownResult">
                  {dropList ?
                    dropList?.map((e) => {
                      return (
                        <div
                          className="dropdownResultItem"
                          onClick={() => {
                            setStatusFilLoc("")
                            setMsgTemp(e.message)
                            setStatusFilN(e.key)
                            setSmsTyp(e.key)
                            setCustomdropdowtwo(!customdropdowntwo);
                          }}
                        >
                          {e.key}
                        </div>
                      )
                    }) : <div className="dropdownResultItem">Select Language</div>}
                </div>
              )}
            </div>
          </div>
          <div>


          </div>
          <textarea
            className="messageArea mb_12"
            id="messagetext"
            name="write text"
            rows="10"
            cols="80"
            placeholder="Enter Message/Template"
            value={msgTemp}
            onChange={(e) => setMsgTemp(e.target.value)}
            disabled
          />
          <button className="filterBtn mb_12" onClick={handleMsg}>
            Send SMS
          </button>
          <div class="row mb_24">
            <div className="positionRelative col-md-10">
              <input
                type="text"
                readOnly
                placeholder={statusFilLoc === "" ? "Select Service Location" : statusFilLoc}
                className="seletDropdownIcOn"
                onClick={() => setCustomdropdowthree(!customdropdownthree)}
              />
              {customdropdownthree && (

                <div className="dropdownResult">
                  {location?.map((e) => {
                    return (
                      <div
                        className="dropdownResultItem"
                        onClick={() => {
                          setMsgTemp(msgTemp.split(" https://goo.gl/maps/")[0] + ` ${e.value}`)
                          setStatusFilLoc(e.key)
                          setCustomdropdowthree(!customdropdownthree);
                        }}
                      >
                        {e.key}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {selectedFile &&
              <>
                <div className="col-md-8 mb_12">
                  <div className="fileItem displayInlineFlex alignItemCenter gap_12">
                    {selectedFile?.name}
                    <span onClick={() => {
                      setSelectedFile(null)
                      setWaybillArr([])
                    }}>X</span></div>
                </div>
                <div className="col-md-8">
                  <button className="filterBtn" onClick={handleImage}>
                    Upload File
                  </button>
                </div>

              </>
            }


          </div>
        </div>

      </div>
    </>
  );
};

export default Sendsms;
