import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useAoneStore } from "../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router";
import * as Yup from "yup";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import PasswordIcon from "../../../src/assets/img/PasswordGearIcon.svg"
const ResetPasswordModal = ({ onSuccess, onError, setShowModal, title }) => {
  const navigate = useNavigate();
  const resetPasswordEmployee = useAoneStore(
    (state) => state?.resetPasswordEmployee
  );
  const setglobalEmployeeCode = useAoneStore(
    (state) => state?.setglobalEmployeeCode
  );
  const generatePassword = useAoneStore((state) => state?.generatePassword);
  const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
  const resetLoginInfoPassword = useFormik({
    initialValues: {
      username: "",
      password: "",
      temporary: false
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(25, "Password must not exceed 25 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.!@#$%^&*()+?><]).{6,25}$/,
          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character from -.!@#$%^&*()+?><"
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.username = globalEmployeeCode;
      if (globalEmployeeCode) {
        await resetPasswordEmployee(values).then((response) => {
          if (response.status == "200") {
            toast.success("Password Reset Successfully");
            navigate("/crm/employees");
            setglobalEmployeeCode("");
            resetForm();
          } else {
            const errorMessage = response?.response?.data?.msg?.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        toast.error(
          "Employee Account need to be created for resetting the login information"
        );
      }
    },
  });
  const generatepassword = async () => {
    const data = await generatePassword();
    resetLoginInfoPassword.setFieldValue("password", data?.data?.data);
  };

  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">Reset Password!</h4>
      </Modal.Header>
      <div className="modelScrollReset">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Password
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                type="text"
                placeholder="Password"
                className="mainInput"
                name="password"
                value={resetLoginInfoPassword.values.password}
                onChange={(e) => {
                    resetLoginInfoPassword.setFieldValue("password", e.target.value);
                }}
              />
                  </div>
                    {resetLoginInfoPassword.touched.password  &&
              resetLoginInfoPassword.errors.password
                ? (
                <p className="requiredText">
                  {resetLoginInfoPassword?.errors?.password?.includes(
                    "required"
                  )  && <p>Password is required</p>}
                  <ul className="requiredText">
                    {resetLoginInfoPassword?.errors?.password?.includes(
                      "characters"
                    ) && <li>Password must be at least 6 characters</li>}
                    {resetLoginInfoPassword?.errors?.password?.includes(
                      "not exceed"
                    ) && <li>Password must not exceed 25 characters</li>}
                    {resetLoginInfoPassword?.errors?.password?.includes(
                      "lowercase letter"
                    ) && (
                      <li>
                        Password must contain at least one lowercase letter
                      </li>
                    )}
                    {resetLoginInfoPassword?.errors?.password?.includes(
                      "uppercase letter"
                    ) && (
                      <li>
                        Password must contain at least one uppercase letter
                      </li>
                    )}
                    {resetLoginInfoPassword?.errors?.password?.includes(
                      "digit"
                    ) && <li>Password must contain at least one digit</li>}
                    {resetLoginInfoPassword?.errors?.password?.includes(
                      "special character"
                    ) && (
                      <li>
                        Password must contain at least one special character
                        from -.!@#$%^&*()+?
                      </li>
                    )}
                  </ul>
                </p>
              ) : null}
                </div>
              </div>
              <div className="col-md-6 mt_45">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                  <div className="col-md-4">
                 <span>
                  <img src={PasswordIcon} className="h18 cursorPointer" onClick={generatepassword}/>
                </span>
            </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
            <div className="smallCheckbox col-md-12">
            <input
              type="checkbox"
              id="tempPassword"
              style={{ marginLeft: "5px" }}
              checked={resetLoginInfoPassword.values.temporary}
              onChange={() => {
                resetLoginInfoPassword.setFieldValue(
                  "temporary",
                  !resetLoginInfoPassword.values.temporary
                );
              }}
            />
            <label htmlFor="tempPassword">Temperary Password</label>
          </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button
            className="blue_btn"
            type="button"
            onClick={resetLoginInfoPassword?.handleSubmit}
          >
            Save
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ResetPasswordModal;
