import React, { useRef, useState, useEffect } from "react";
import { MapContainer, TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { useAoneStore } from "../../store/AoneStore";
import editicon from "../../../src/assets/img/editicon.svg";
import saveIcon from "../../../src/assets/img/saveIcon.svg";

const MapViewWithDraw = ({ onCreated, onEdited, onDeleted, boundaryData, params }) => {
  const featureGroupRef = useRef(null);
  const mapRef = useRef(null); // Add a ref for MapContainer
  const [geometry, setGeometry] = useState(null); // Store the geometry data
  const [isEditing, setIsEditing] = useState(false); // Manage the editing state
  const color = "#00FF00"; // Define the color of the geometry
  const editDistrictGeometry = useAoneStore((state) => state.editDistrictGeometry);
  const geometryData = useAoneStore((state) => state.geometryData);

  // Function to convert the geometryData structure to coordinates
  const getCoordinatesFromGeometryData = () => {
    if (geometryData?.data?.data?.geometry) {
      // Inspect the structure of the geometry data
      console.log("Geometry Data:", geometryData.data.data.geometry);
  
      // Check if geometryData.data.data.geometry.coordinates is an array
      const coordinatesData = geometryData.data.data.geometry.coordinates;
      if (Array.isArray(coordinatesData)) {
        return coordinatesData.map((item) => {
          // Ensure item is an array before mapping
          if (Array.isArray(item.coordinates)) {
            return item.coordinates.map((point) => [point.y, point.x]); // Convert {x, y} to [lat, lng]
          }
          console.warn("Expected item.coordinates to be an array, but it is not:", item);
          return [];
        });
      } else {
        console.warn("Expected geometryData.data.data.geometry.coordinates to be an array, but it is not:", coordinatesData);
      }
    }
    return [];
  };
  
  
  const boundaries = getCoordinatesFromGeometryData();

  useEffect(() => {
    if (boundaries.length > 0 && mapRef.current) {
      // Calculate the bounds of the polygon
      const bounds = boundaries[0];
      if (bounds.length > 0) {
        mapRef.current.fitBounds(bounds); // Zoom and center the map to fit the bounds
      }
    }
  }, [boundaries]);

  const handleCreated = (e) => {
    const layer = e.layer;
    let coordinates = layer.getLatLngs()[0].map((latlng) => [
      latlng.lng,
      latlng.lat,
    ]);

    // Ensure the first and last points are the same
    if (coordinates.length > 0 && (coordinates[0][0] !== coordinates[coordinates.length - 1][0] || coordinates[0][1] !== coordinates[coordinates.length - 1][1])) {
      coordinates.push(coordinates[0]);
    }

    const newGeometry = {
      type: "Polygon",
      coordinates: [coordinates],
      properties: {
        color: color, // Include the color property
      },
    };

    console.log("Formatted Geometry:", newGeometry);
    setGeometry(newGeometry); // Store the geometry in state

    // Call the onCreated callback if needed
    if (onCreated) {
      onCreated(e);
    }
  };

  const handleSave = async () => {
    if (params && geometry) {
      const payload = {
        geometry: geometry,
      };
      const res = await editDistrictGeometry(params, payload);
      console.log("res--->", res);
    } else {
      console.log("No geometry data available to save.");
    }
    setIsEditing(false); // Hide drawing controls after saving
  };

  return (
    <div>
      {/* Edit button to toggle editing mode */}
      {!isEditing && (
        <button
          title="Edit"
          className="tableBtn mb_5"
          onClick={() => setIsEditing(true)}
        >
          <span>
            <img src={editicon} className="turnBlack" alt="Edit" />
          </span>
        </button>
      )}

      {/* Save button shown only when in editing mode */}
      {isEditing && (
        <button
          title="Save"
          className="tableBtn mb_5"
          onClick={handleSave}
        >
          <span>
            <img src={saveIcon} className="turnBlack" alt="Save" />
          </span>
        </button>
      )}

      <MapContainer
        ref={mapRef} // Attach the ref to MapContainer
        center={[24.9332438, 46.5640864]}
        zoom={8}
        scrollWheelZoom={true}
        style={{
          height: "600px",
          width: "500px",
          border: "2px solid #000",
          borderRadius: "5px",
        }}
      >
        <TileLayer
          url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
          attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
        />

        <FeatureGroup ref={featureGroupRef}>
          {/* Render boundaries as polygons */}
          {boundaries.map((coords, index) => (
            <Polygon key={index} positions={coords} pathOptions={{ color: color }} />
          ))}

          {isEditing && (
            <EditControl
              position="topright"
              onCreated={handleCreated}
              onEdited={onEdited}
              onDeleted={onDeleted}
              draw={{
                polygon: {
                  shapeOptions: {
                    color: color, // Set the color of the drawn polygon
                  },
                },
                rectangle: false,
                polyline: false,
                circle: false,
                circlemarker: false,
                marker: false,
              }}
            />
          )}
        </FeatureGroup>
      </MapContainer>
    </div>
  );
};

export default MapViewWithDraw;
