import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useAoneStore } from "../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNotificationStore } from "../../store/NotificationsMgt/NotificationStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
const TemplateModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  templatebyCode,
}) => {
    console.log("templatebyCode",templatebyCode)
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postTemplates = useNotificationStore(
    (state) => state?.postTemplates
  );
  const editTemplates = useNotificationStore(
    (state) => state?.editTemplates
  );
  const languages = [
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "en", label: "English" },
    { key: "tr", label: "Turkish" },
  ];
  const countriesData = useAoneStore((state) => state?.countriesData);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [resetKey, setResetKey] = useState(0);
  const addTemplate = useFormik({
    initialValues: {
      code: "",
      message: "",
      language: "",
      countryCode: "",
    },
    validationSchema: Yup.object({
        code: Yup.string()
        .trim()
        .required("Code is required")
        .matches(/^[a-zA-Z0-9\s_-]*$/, "Code must not contain special characters")
        .max(30, "Code must be at most 30 characters long"),
      message: Yup.string().trim().required("Message is required"),
      language: Yup.string().trim().required("Language is required"),
      countryCode: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (title === "Edit SMS template") {
        await editTemplates(templatebyCode.id, values).then(
          (response) => {
            if (response?.data?.status == "SUCCESS") {
              toast.success("SMS Template updated");
              setispostAdded(!ispostAdded);
              resetForm();
              setShowModal(false);
            } else if (response?.data?.status === "ERROR") {
              const errorMessage = response.data.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.errors?.length > 0) {
              const errorMessages = response?.response?.data?.errors?.map(
                (item) => `${item?.key} ${item?.msg}`
              );
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else if (response?.response?.data?.errorCode == 400) {
              const errorMessages = response?.response?.data?.errors
                .map((item) => `${item.msg}`)
                .join(", ");
              toast.error(errorMessages[0]);
              resetForm();
              setShowModal(false);
            } else if (response.status == 200) {
              toast.success("SMS Template updated");
              setispostAdded(!ispostAdded);
              resetForm();
              setShowModal(false);
            } else {
              const errorMessage = response.response.data.msg;
              toast.error(errorMessage);
              resetForm();
              setShowModal(false);
            }
          }
        );
      } else {
        await postTemplates(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("SMS Template has been added");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.errors?.length > 0) {
            const errorMessages = response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      }
    },
  });
  useEffect(() => {
    addTemplate.setFieldValue("code", templatebyCode?.code);
    addTemplate.setFieldValue("message", templatebyCode?.message);
    addTemplate.setFieldValue("language", templatebyCode?.language);
    addTemplate.setFieldValue("countryCode", templatebyCode?.countryCode);
  }, [title, templatebyCode]);
  useEffect(() => {
    if (title == "Create new SMS template") {
      addTemplate.setFieldValue("code", "");
      addTemplate.setFieldValue("message", "");
      addTemplate.setFieldValue("language", "");
      addTemplate.setFieldValue("countryCode", "");
    }
  }, [title, templatebyCode]);
  const [vehicleCodeErr, setVehicleCodeErr] = useState("");
  const [vehicleNameErr, setVehicleNameErr] = useState("");
  const saveTemplate = (initialValues) => {
    if (/\s/g.test(addTemplate.values.code)) {
      setVehicleCodeErr(
        "Code cannot cannot contain leading, trailing, or consecutive spaces"
      );
    }else if (/^\s|\s$/.test(addTemplate.values.message)) {
      setVehicleNameErr(
          "Message cannot cannot contain leading or trailing spaces"
        );
    } else {
      setVehicleCodeErr("");
      setVehicleNameErr("");
      addTemplate?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicle">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Code
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Code"
                      value={addTemplate.values.code}
                      onChange={(e) => {
                        addTemplate.setFieldValue(
                          "code",
                          e.target.value
                        );
                        setVehicleCodeErr("");
                      }}
                      disabled={title == "Edit SMS template"}
                      name="code"
                      className="mainInput"
                    />
                  </div>
                  {addTemplate.touched.code &&
                  addTemplate.errors.code &&
                  vehicleCodeErr == "" ? (
                    <p className="requiredText">
                      {addTemplate.errors.code}
                    </p>
                  ) : null}
                  {vehicleCodeErr !== "" ? (
                    <p className="requiredText">{vehicleCodeErr}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryCode"
                      options={countriesData?.data?.data?.content}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        addTemplate.setFieldValue(
                          "countryCode",
                          selectedOption ? selectedOption?.isoCode3 : ""
                        );
                      }}
                      value={
                        addTemplate?.values?.countryCode
                          ? countriesData?.data?.data?.content.find(
                              (option) => option.isoCode3 === addTemplate?.values?.countryCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                  {addTemplate.touched.countryCode &&
                  addTemplate.errors.countryCode ? (
                    <p className="requiredText">
                      {addTemplate.errors.countryCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Language
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                  key={resetKey}
                  id="language"
                  label="language"
                  name="language"
                  options={languages || []}
                  value={
                    addTemplate?.values?.language
                      ? languages.find(
                          (option) => option.label === addTemplate?.values?.language
                        )
                      : null
                  }
                  getOptionLabel={(language) => language.label}
                  getOptionValue={(language) => language.label}
                  handleChange={async (selectedOption) => {
                    addTemplate.setFieldValue(
                      "language",
                      selectedOption ? selectedOption.label : null
                    );
                  }}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Language"
                />
                  </div>
                  {(addTemplate.touched.language &&
                  addTemplate.errors.language) ? (
                    <p className="requiredText">
                      {addTemplate.errors.language}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
            <div className="col-md-12">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Message
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <textarea
                        className="washMe_btn"
                        placeholder="Message"
                        name="description"
                        onBlur={addTemplate.handleBlur}
                        value={addTemplate.values.message}
                        onChange={(e) => {
                            addTemplate.setFieldValue(
                              "message",
                              e.target.value
                            );
                            setVehicleNameErr("");
                          }}
                        style={{ height: "90px", paddingTop: "6px" }}
                      ></textarea>
                  </div>
                  {(addTemplate.touched.message &&
                  addTemplate.errors.message && vehicleNameErr == "") ? (
                    <p className="requiredText">
                      {addTemplate.errors.message}
                    </p>
                  ) : null}
                   {(vehicleNameErr !== "") ? (
                    <p className="requiredText">
                      {vehicleNameErr}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button
            className="blue_btn"
            type="button"
            onClick={saveTemplate}
          >
            {title === "Create new SMS template" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default TemplateModal;
