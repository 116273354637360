import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getShortAddress,getShortAddressbyId,editShortAddressbyId,deleteShortAddressbyId, importShortAddress,exportShortAddress,getShortAddressHistory,getShortAddressHistorybyNational,
   getShortAddressHistorybyGeoLocation,exportShortAddressHistory } from "../../services/ShortAddress/ShortAddress_Services";
const initialValues = {
};
let shortAddressStore = (set, get) => ({
  ...initialValues,
  getShortAddress: async (params,params2) => {
        const response = await getShortAddress(params,params2);
        return response;
      },
      getShortAddressbyId: async (params,params2) => {
        const response = await getShortAddressbyId(params,params2);
        return response;
      },
      editShortAddressbyId: async (params,params2,data) => {
        const response = await editShortAddressbyId(params,params2,data);
        return response;
      },
      deleteShortAddressbyId: async (params,params2) => {
        const response = await deleteShortAddressbyId(params,params2);
        return response;
      },
      importShortAddress: async (params,data) => {
        const response = await importShortAddress(params,data);
        return response;
      },
      exportShortAddress: async (params,params2) => {
        const response = await exportShortAddress(params,params2);
        return response;
      },
      getShortAddressHistory: async (params,params2) => { 
        const response = await getShortAddressHistory(params,params2);
        return response;
      },
      getShortAddressHistorybyNational: async (params,params2,params3) => {
        const response = await getShortAddressHistorybyNational(params,params2,params3);
        return response;
      },
      getShortAddressHistorybyGeoLocation: async (params,params2) => {
        const response = await getShortAddressHistorybyGeoLocation(params,params2);
        return response;
      },
      exportShortAddressHistory: async (params,params2) => {
        const response = await exportShortAddressHistory(params,params2);
        return response;
      },
});

shortAddressStore = devtools(shortAddressStore);

export const useShortAddressStore = create(shortAddressStore);