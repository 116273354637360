import React, {
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../assets/img/download_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../store/AoneStore";
import { useNotificationStore } from "../../store/NotificationsMgt/NotificationStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import { roleHandler } from "../../helper/roleHandler";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../../src/assets/img/dotsbold.svg";
import { UserContext } from "../../helper/ProtectedRoute";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import CusModal from "../../components/modal/CusModal";
import TemplateModal from "./TemplateModal";
const SmsTemplate = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const prevent = useRef(false);
  const [title, setTitle] = useState("");
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code, setCode] = useState("");
  const [country, setCountry] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const getTemplates = useNotificationStore((state) => state?.getTemplates);
  const exportTemplates = useNotificationStore((state) => state?.exportTemplates);
  const setglobalHubCode = useAoneStore((state) => state?.setglobalHubCode);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [column, SetCoulmn] = useState([
    "Code",
    "Message",
    "Country",
    "Language",
    "Created by",
    "Created at",
    "Updated by",
    "Updated at",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "Created at",
    "Created by",
    "Updated at",
    "Updated by",
  ]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const handleEdit = (template) => {
      setTitle("Edit SMS template");
      setShowModal(true);
      getTemplateonEdit(template);
  };
  const [templatebyCode, setTemplatebyCode] = useState([]);
  const getTemplateonEdit = async (template) => {
    setTemplatebyCode(template);
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
      if (code) {
        params += `&code=${code}`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      const data = await getTemplates(params);
      setTotalCount(data?.data?.data?.totalElements);
      setTemplateData(data?.data?.data?.content);
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (code) {
      params += `&code=${code}`;
    }
    if (country) {
      params += `&countryCode=${country}`;
    }
    try {
      const res = await exportTemplates(params);
      if (res.status == 200) {
        fileDownload(res.data, `SMS Template.xlsx`);
      }
    } catch (err) {}
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    tab,
    editPage,
    sortField,
    location,
    saveHandler,
    code,
    country,
    ispostAdded,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.data?.data?.content?.length == undefined
    ) {
      filterCountries(paramslink);
    }
  }, []);
  return (
    <div>
      <div>
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Code:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10">
                      <input
                        type="text"
                        value={code}
                        className={
                          code?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          code?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        setCountry(
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isReactselectOpen}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      customStyles={{ control: { width: "170px" } }}
                      className={
                        country?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                  <button
                    className="filterBtn"
                    title={"Export Template"}
                    onClick={() => exportToExcel()}
                  >
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.read") ||
                  roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.write") ||
                  roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => {
                      setShowModal(true);
                      setTitle("Create new SMS template");
                    }} 
                  >
                    +
                  </button>)}
                  <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <PaginationTable
            tableData={templateData}
            tbl={"MessageTemplate"}
            modalHandler={modalHandler}
            restrict={checkedCol}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            handleTemplateEdit={handleEdit}
            size={size}
            isMenuOpen={isMenuOpen}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
            <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={"addnewpopup"}
        >
            <TemplateModal
              onSuccess={handleSuccess}
              onError={handleError}
              showModal={showModal}
              setShowModal={setShowModal}
              title={title}
              templatebyCode={templatebyCode}
            />
        </CusModal>
      </div>
    </div>
  );
};

export default SmsTemplate;
