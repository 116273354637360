import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import syncicon from "../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../store/AoneStore";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import { useParams } from "react-router-dom";
import { UserContext } from "../../helper/ProtectedRoute";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../components/dropdown/autoCompleteAsyncComponent";
const Tierodpair = () => {
  const navigate = useNavigate();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [desRegion, setdesRegion] = useState("");
  const [desCity, setDesCity] = useState("");
  const [originRegion, setoriginRegion] = useState("");
  const [originCity, setoriginCity] = useState("");
  const params = useParams();
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(-1);
  const [pagedes, setPageDes] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
const [deleteHandler, setDeleteHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [tiersData,setTiersData] = useState([])
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactSelectorigincity = useAoneStore((state) => state?.setReactSelectorigincity);
  const isReactselectRegion = useAoneStore((state) => state?.isReactselectRegion);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const isReactoriginselectCity = useAoneStore((state) => state?.isReactoriginselectCity);
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const [modalHandler, setModalHandler] = useState(false);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const getOdTier = useAoneStore((state) => state?.getOdTier);
  const getRegions = useAoneStore((state) => state?.getRegions);
  const filterCities = useAoneStore((state) => state?.filterCities);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const cityArray = useAoneStore((state) => state?.cityArray);
  const [sortField, setSortField] = useState("createdAt");
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const urlParams = new URLSearchParams(window.location.search);
const tier = urlParams.get('tiername');
const queryString = window.location.search;
const param = new URLSearchParams(queryString);
const countryName = param.get('countryName');
const countryId = param.get('countryId');
  const getData = async () => {
    let paramslink = `?page=${newPage}&size=${size}&sortBy=${sortField}&tierCode=${params?.id}`;
    if (originRegion) {
      paramslink += `&originRegionCode=${originRegion}`;
    }
    if (originCity) {
      paramslink += `&originCityCode=${originCity}`;
    }
    if (desCity) {
      paramslink += `&destinationCityCode=${desCity}`;
    }
    if (desRegion) {
      paramslink += `&destinationRegionCode=${desRegion}`;
    }
      const data = await getOdTier(paramslink);
      setTotalCount(data.data.data.totalElements);
      setTiersData(data.data.data.content);
  }
  useEffect(() => {
    getData();
  }, [
    newPage,
    size,
    editPage,
    sortField,
    saveHandler,
    ispostAdded,
    desRegion,
    originCity,
    desCity,
    originRegion
  ]);
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const handleMenuOpenorgcity = () => {
    setReactSelectorigincity(true);
  };

  const handleMenuCloseorgcity = () => {
    setReactSelectorigincity(false);
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}&countryIsoCode3=${countryId}`;
    getRegions(paramslink);
    filterCities(paramslink);
  }, []);
  const loadMoreCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${search}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  const loadMoreDescityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : pagedes + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPageDes(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${search}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  return (
    <div>
       <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/configs/tiers")}
              >
                Configs
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/configs/tiers")}
              >
                Tiers
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
              >
                {params?.id}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
      {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Destination Region:
                    </label>
                    <SearchInput
                      id="desRegion"
                      label="desRegion"
                      name="desRegion"
                      options={regionsData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      handleChange={(selectedOption) => {
                        setdesRegion(selectedOption ? selectedOption.code : "");
                      }}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isReactselectOpen}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Destination Region"
                      customStyles={{ control: { width: "160px" } }}
                      className={"mainInput"}
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Destination City:
                    </label>
                    <AsyncSearchInput
                      id="desCity"
                      label="desCity"
                      name="desCity"
                      loadMoreOptions={loadMoreCityOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onChange={(selectedOption) => {
                        setDesCity(
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Destination City"
                      customStyles={{ control: { width: "160px" } }}
                      className={"mainInput"}
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                    Origin Region:
                    </label>
                    <SearchInput
                      id="originRegion"
                      label="originRegion"
                      name="originRegion"
                      options={regionsData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      handleChange={(selectedOption) => {
                        setoriginRegion(selectedOption ? selectedOption.code : "");
                      }}
                      onMenuOpen={handleMenuOpencity}
                      onMenuClose={handleMenuClosecity}
                      menuIsOpen={isReactselectCity}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Origin Region"
                      customStyles={{ control: { width: "160px" } }}
                      className={"mainInput"}
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                    Origin City:
                    </label>
                    <AsyncSearchInput
                      id="originCity"
                      label="originCity"
                      name="originCity"
                      loadMoreOptions={loadMoreDescityOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onChange={(selectedOption) => {
                        setoriginCity(
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpenorgcity}
                      onMenuClose={handleMenuCloseorgcity}
                      menuIsOpen={isReactoriginselectCity}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Origin City"
                      customStyles={{ control: { width: "160px" } }}
                      className={"mainInput"}
                    />
                  </Col>
                </Row>
              </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => setAddModalHandler(!addModalHandler)}
                    tabIndex="-1"
                  >
                    +
                  </button>)}
              </div>
            </div>
            </div>
      )}
      {!editPage && (
          <PaginationTable
            tableData={tiersData}
            tbl={"OdTiers"}
            title={"Edit OD Pair"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            countryNameparam={countryName}
            tier={tier}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
         {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Create OD Pair"}
          countryName={countryName}
          tier={params?.id}
          editHandler={editHandler}
          setEditHandler={setEditHandler}
        />)}
          </div>
    </div>
  )
}

export default Tierodpair