import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get routes
export const getRoutes = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/routes${params}`);
    } catch (error) {
      return error;
    }
  };
  //get routes by id
  export const getRoutesbyCode = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/routes/${params}`);
    } catch (error) {
      return error;
    }
  };
//post routes
export const postRoutes = async (data) => {
    try {
      return await AoneService.post("ops/api/v1/routes", data);
    } catch (error) {
      return error;
    }
  };
//edit routes
export const editRoutes = async (data) => {
    try {
      return await AoneService.put(`ops/api/v1/routes`, data);
    } catch (error) {
      return error;
    }
  };
//template for routes
export const routesTemplate = async () => {
  try {
    return await AoneService.get(
      `ops/api/v1/routes/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//upload routes
export const importRoutes = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/routes/upload`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };
//export routes
export const exportRoutes = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/routes/download${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//delete routes
export const deleteRoutes = async (params) => {
    try {
      return await AoneService.delete(`ops/api/v1/routes/${params}`);
    } catch (error) {
      return error;
    }
  };
  //routes lookups
  export const getDistance = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/LengthUnit`);
    } catch (error) {
      return error;
    }
  };
  export const getStatus = async () => {
    try {
      return await AoneService.get(`ops/api/v1/lookups/State`);
    } catch (error) {
      return error;
    }
  };