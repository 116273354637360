import React, { useEffect, useState, useRef, useContext } from "react";
import CusModal from "../components/modal/modal";
import { useAoneStore } from "../store/AoneStore";
import { UserContext } from "../helper/ProtectedRoute";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";

const AdditionalInfo = ({}) => {
  const postOrders = useAoneStore((state) => state?.postOrders);
  const postBulkOrder = useAoneStore((state) => state?.postBulkOrder);
  const downloadSample = useAoneStore((state) => state?.downloadSample);
  const [state, setState] = useContext(UserContext);
  const [nationalId, setNationalId] = useState("");
  const [wayBill, setWaybill] = useState("");
  const [receiverNum, setReceiverNum] = useState("");
  const [service, setService] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const waybillref = useRef("");
  const receiverPhoneref = useRef("");
  const [err, setErr] = useState({
    required: false,
    length: false,
  });
  const [recerr, setRecErr] = useState({
    required: false,
    length: false,
  });
  const bulkUpload = useFormik({
    initialValues: {
      awb: "",
      receiverPhone: "",
    },
    validationSchema: Yup.object({
      awb: Yup.string().required("Waybill Required"),
      receiverPhone: Yup.string().required("Receivers Number Required"),
    }),
    onSubmit: async (values) => {
      const res = await postOrders(values);
      if (res.data.code == 200) {
        setNationalId("");
        setWaybill("");
        setReceiverNum("");
        setService("");
        toast.success("Posted Successfully");
      } else {
        toast.error(res?.data?.msg);
      }
    },
  });
  const handleSave = async () => {
    try {
      if (
        waybillref.current.value.length == 0 &&
        receiverPhoneref.current.value.length == 0
      ) {
        setRecErr((prevState) => ({
          ...prevState,
          required: true,
        }));
        setErr((prevState) => ({
          ...prevState,
          required: true,
        }));
      } else {
        let data = {
          awb: wayBill,
          // "receiverNid": nationalId,
          receiverPhone: receiverNum,
          // "serviceCenter": service
        };
        const res = await postOrders(data);
        if (res.data.code == 200) {
          setNationalId("");
          setWaybill("");
          setReceiverNum("");
          setService("");
          toast.success("posted sucessfully");
          bulkUpload.resetForm();
        }
      }
    } catch (err) {}
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        const res = await postBulkOrder(formData);
        if (res.data.code === 200) {
          setSelectedFile(null);
          toast.success("Uploaded sucessfully");
          return 200;
        } else {
          const errorMessages = res?.data?.data
            .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
            .join(", ");
          toast.error(errorMessages);
        }
      }
    } catch (err) {}
  };
  const downloadblob = async () => {
    try {
      const res = await downloadSample();
      if (res.status == 200) {
        fileDownload(res.data, `sample-upload.xlsx`);
        toast.success("downloaded sucessfully");
      }
    } catch (err) {}
  };
  return (
    <>
      <div className="borderFrame">
        <div className="borderFrameTitle">Add Consignee Info</div>

        <div className="row">
          <div className="col-md-6">
            <form>
              <div className="row alignItemCenter mb_12">
                <div className="col-md-4">
                  <label className="fontSize14">Waybill Number </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    placeholder="Enter the Waybill Number"
                    className="mainInput"
                    // value={wayBill}
                    // ref={waybillref}
                    // onChange={(e)=>{
                    //   setWaybill(e.target.value)
                    //   if (waybillref.current.value.length > 1) {
                    //     setErr(prevState => ({
                    //       ...prevState,
                    //       required : false
                    //     }))
                    //   }
                    // }}
                    name="awb"
                    onChange={(e) =>
                      bulkUpload.setFieldValue("awb", e.target.value)
                    }
                    onBlur={bulkUpload.handleBlur}
                    value={bulkUpload.values.awb}
                    // onBlur={()=>{
                    //   if (waybillref.current.value.length == 0) {
                    //     setErr(prevState => ({
                    //       ...prevState,
                    //       required : true
                    //     }))
                    //   }
                    // }}
                  />
                  {/* {
              err.required == true && (
                <p className="requiredText"> Waybill Required</p>
              )
            } */}
                  {bulkUpload.touched.awb && bulkUpload.errors.awb ? (
                    <p className="requiredText">{bulkUpload.errors.awb}</p>
                  ) : null}
                </div>
              </div>
              {/* <div className="row alignItemCenter mb_12">
              <div className="col-md-4">
                <label className="fontSize14">
                  National id <span>*</span>{" "}
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  placeholder="Enter the National id"
                  className="mainInput"
                  value={nationalId}
                  onChange={(e)=>setNationalId(e.target.value)}
                />
              </div>
            </div> */}
              <div className="row alignItemCenter mb_24">
                <div className="col-md-4">
                  <label className="fontSize14">Phone Number </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    placeholder="Enter the Phone Number"
                    className="mainInput"
                    name="receiverPhone"
                    value={bulkUpload.values.receiverPhone}
                    onChange={(e) =>
                      bulkUpload.setFieldValue("receiverPhone", e.target.value)
                    }
                    onBlur={bulkUpload.handleBlur}
                    // value={receiverNum}
                    // ref={receiverPhoneref}
                    // onChange={(e)=>{setReceiverNum(e.target.value)
                    //   if (receiverPhoneref.current.value.length > 1) {
                    //     setRecErr(prevState => ({
                    //       ...prevState,
                    //       required : false
                    //     }))
                    //   }
                    // }}
                    // onBlur={()=>{
                    //   if (receiverPhoneref.current.value.length == 0) {
                    //     setRecErr(prevState => ({
                    //       ...prevState,
                    //       required : true
                    //     }))
                    //   }
                    // }}
                  />
                  {/* {
              recerr.required == true && (
                <p className="requiredText">Receivers Number Required</p>
              )
            } */}
                  {bulkUpload.touched.receiverPhone &&
                  bulkUpload.errors.receiverPhone ? (
                    <p className="requiredText">
                      {bulkUpload.errors.receiverPhone}
                    </p>
                  ) : null}
                </div>
              </div>
            </form>

            {/* <div className="row alignItemCenter mb_24">
              <div className="col-md-4">
                <label className="fontSize14">Service Center </label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  placeholder="Enter the Service Center"
                  className="mainInput"
                  value={service}
                  onChange={(e)=>setService(e.target.value)}
                />
              </div>
            </div> */}

            <div className="displayFlex alignItemCenter justifyContent_end gap_16">
              <div>
                <button
                  className="activeFilterBtn"
                  onClick={bulkUpload.handleSubmit}
                >
                  Save
                </button>
              </div>
              <div>
                {/* <button className="filterBtn">Bulk Upload</button> */}
                <CusModal
                  btnName="Bulk Upload"
                  title="Bulk Upload"
                  handleImage={handleFileChange}
                  submit={handleSubmit}
                  initialData={selectedFile}
                  setStateData={setSelectedFile}
                  downloadblob={downloadblob}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalInfo;
