import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import downloadicon from "../../../assets/img/download_icon.svg";
import Countries from "./Countries";
import Regions from "./Regions";
import Cities from "./Cities";
import District from "./Districts";
import { useAoneStore } from "../../../store/AoneStore";
import { useNavigate, useLocation } from "react-router";
import PaginationTable from "../../../components/Tables/paginationTable";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import fileDownload from "js-file-download";
import syncicon from "../../../../src/assets/img/sync.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useParams } from "react-router";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Addresses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [editPage, setEditPage] = useState(false);
  const prevent = useRef(false);
  const params = useParams();
  const [resetKey, setResetKey] = useState(0);
  const [regionResetKey, setRegionResetKey] = useState(0);
  const [cityResetKey, setCityResetKey] = useState(0);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  //address changes
  const getCountries = useAoneStore((state) => state?.getCountries);
  const getRegions = useAoneStore((state) => state?.getRegions);
  const getRegion = useAoneStore((state) => state?.getRegion);
  const getRegionbyCode = useAoneStore((state) => state.getRegionbyCode);
  const getDistricts = useAoneStore((state) => state?.getDistricts);
  const getCities = useAoneStore((state) => state?.getCities);
  const filterCities = useAoneStore((state) => state?.filterCities);
  const filterZone = useAoneStore((state) => state?.filterZone);
  const getCountryTemplate = useAoneStore((state) => state?.getCountryTemplate);
  const getRegionTemplate = useAoneStore((state) => state?.getRegionTemplate);
  const getDistrictTemplate = useAoneStore(
    (state) => state?.getDistrictTemplate
  );
  const getCitiesTemplate = useAoneStore((state) => state?.getCitiesTemplate);
  const exportCountries = useAoneStore((state) => state?.exportCountries);
  const exportRegion = useAoneStore((state) => state?.exportRegion);
  const exportCities = useAoneStore((state) => state?.exportCities);
  const exportDistrict = useAoneStore((state) => state?.exportDistrict);
  const importCountry = useAoneStore((state) => state?.importCountry);
  const importRegion = useAoneStore((state) => state?.importRegion);
  const importCity = useAoneStore((state) => state?.importCity);
  const importDistrict = useAoneStore((state) => state?.importDistrict);
  const countryData = useAoneStore((state) => state?.countryData);
  const regionData = useAoneStore((state) => state?.regionData);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const cityData = useAoneStore((state) => state?.cityData);
  const districtData = useAoneStore((state) => state?.districtData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const citiesData = useAoneStore((state) => state?.countriesData);
  const zoneData = useAoneStore((state) => state?.zoneData);
  const cityArray = useAoneStore((state) => state?.cityArray);
  const districtDatas = useAoneStore((state) => state?.districtDatas);
  const [name, setName] = useState("");
  const [isRemote, setIsRemote] = useState(false);
  const [isocode, setIsocode] = useState("");
  const [cityCode, setcityCode] = useState("");
  const [cityName, setcityName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [regionCode, setRegionCode] = useState("");
  const [districtCode, setDistrictCode] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [zoneCode, setZonecode] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [originCityPage, setOriginCitypage] = useState(-1);
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactCharges = useAoneStore((state) => state?.setReactCharges);
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const handleMenuCharges = () => {
    setReactCharges(true);
  };
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleMenuCloseCharges = () => {
    setReactCharges(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const [addressData, setAddressData] = useState([]);
  const [regionresponse, setRegionData] = useState([]);
  const [cityResponse, setCityData] = useState([]);
  const [districtResponse, setDistrictData] = useState([]);
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setName("");
    setTab(i);
    navigate("/configs/addresses");
  };
  const downloadTemplate = async () => {
    try {
      if (tab === 1) {
        const res = await getCountryTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Country Template.xlsx`);
        }
      } else if (tab === 2) {
        const res = await getRegionTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Region Template.xlsx`);
        }
      } else if (tab === 3) {
        const res = await getCitiesTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `City Template.xlsx`);
        }
      } else {
        const res = await getDistrictTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `District Template.xlsx`);
        }
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&fields=regionName`;
    if (tab === 1) {
      if (name) {
        params += `&name=$${name}$`;
      }
      if (isocode) {
        params += `&isoCode3=${isocode}`;
      }
      const data = await getCountries(params);
      setTotalCount(data?.data?.data?.totalElements);
      setAddressData(data?.data?.data?.content);
    } else if (tab === 2) {
      if (name) {
        params += `&name=$${name}$`;
      }
      if (regionCode) {
        params += `&code=${regionCode}`;
      }
      if (countryCode) {
        params += `&countryIsoCode3=${countryCode}`;
      }
      const data = await getRegion(params);
      setTotalCount(data?.data?.data?.totalElements);
      setRegionData(data?.data?.data?.content);
    } else if (tab === 3) {
      if (cityCode) {
        params += `&code=${cityCode}`;
      }
      if (cityName) {
        params += `&name=$${cityName}$`;
      }
      if (countryCode) {
        params += `&countryIsoCode3=${countryCode}`;
      }
      if (regionCode) {
        params += `&regionCode=${regionCode}`;
      }
      const data = await getCities(params);
      setTotalCount(data?.data?.data?.totalElements);
      setCityData(data?.data?.data?.content);
    } else {
      let paramslink = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (districtCode) {
        paramslink += `&code=${districtCode}`;
      }
      if (districtName) {
        paramslink += `&name=$${districtName}$`;
      }
      if (cityCode) {
        paramslink += `&cityCode=${cityCode}`;
      }
      if (regionCode) {
        paramslink += `&regionCode=${regionCode}`;
      }
      if (countryCode) {
        paramslink += `&countryIsoCode3=${countryCode}`;
      }
      if (zoneCode) {
        paramslink += `&zoneCode=${zoneCode}`;
      }
      if (isRemote) {
        paramslink += `&remote=true`;
      }
      const data = await getDistricts(paramslink);
      setTotalCount(data?.data?.data?.totalElements);
      setDistrictData(data?.data?.data?.content);
    }
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (name) {
        params += `&name=${name}`;
      }
      if (isocode) {
        params += `&isoCode3=${isocode}`;
      }
      try {
        const res = await exportCountries(params);
        if (res.status == 200) {
          fileDownload(res.data, `Country.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 2) {
      if (name) {
        params += `&name=${name}`;
      }
      if (regionCode) {
        params += `&code=${regionCode}`;
      }
      if (countryCode) {
        params += `&countryIsoCode3=${countryCode}`;
      }
      try {
        const res = await exportRegion(params);
        if (res.status == 200) {
          fileDownload(res.data, `Region.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 3) {
      if (cityCode) {
        params += `&code=${cityCode}`;
      }
      if (cityName) {
        params += `&name=${cityName}`;
      }
      if (countryCode) {
        params += `&countryIsoCode3=${countryCode}`;
      }
      if (regionCode) {
        params += `&regionCode=${regionCode}`;
      }
      try {
        const res = await exportCities(params);
        if (res.status == 200) {
          fileDownload(res.data, `City.xlsx`);
        }
      } catch (err) {}
    } else {
      if (districtCode) {
        params += `&code=${districtCode}`;
      }
      if (districtName) {
        params += `&name=${districtName}`;
      }
      if (cityCode) {
        params += `&cityCode=${cityCode}`;
      }
      if (regionCode) {
        params += `&regionCode=${regionCode}`;
      }
      if (countryCode) {
        params += `&countryIsoCode3=${countryCode}`;
      }
      if (zoneCode) {
        params += `&zoneCode=${zoneCode}`;
      }
      try {
        const res = await exportDistrict(params);
        if (res.status == 200) {
          fileDownload(res.data, `District.xlsx`);
        }
      } catch (err) {}
    }
  };
  //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            let importFunction;
            switch (tab) {
              case 1:
                importFunction = importCountry;
                break;
              case 2:
                importFunction = importRegion;
                break;
              case 3:
                importFunction = importCity;
                break;
              default:
                importFunction = importDistrict;
            }
            importFunction(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (
                  response.data.data.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  // Function to validate the format of Locales Name values
  function validateLocalesFormat(columnRange) {
    const localesRegex = /^[^=]*=[^=]*$/; // Any characters or empty, followed by '=', then any characters or empty
    return columnRange.every(
      (cell) => cell === undefined || cell.v === "" || localesRegex.test(cell.v)
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }

  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };

  useEffect(() => {
    !editPage && getData();
  }, [
    newPage,
    size,
    sortField,
    tab,
    editPage,
    deleteHandler,
    name,
    isocode,
    regionCode,
    countryCode,
    isRemote,
    cityCode,
    cityName,
    districtCode,
    districtName,
    cityName,
    zoneCode,
  ]);

  useEffect(() => {
    if (location.pathname.endsWith("addresses")) {
      setEditPage(false);
    } else {
      setEditPage(true);
    }
  }, [location]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${60}`;
    getRegions(paramslink);
    // getCities(paramslink);
    // getDistricts(paramslink);
  }, []);

  //Asyncpaginate
  const [page, setPage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await filterZone(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: option.code,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }
      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page]
  );
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [prevSearch, setPrevSearch] = useState("");
  const loadMoreCityOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? originCityPage + 1 : originCityPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setOriginCitypage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if(countryCode){
          endpoint +=  `&countryIsoCode3=${countryCode}`;
        }
        if(regionCode){
          endpoint +=  `&regionCode=${regionCode}`;
        }
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCities(endpoint);
        const options = response.data.data.content || [];
        setLoadingMore(false);
        return {
          options: options.map((option) => ({
            label: `${option.name}`,
            value: option.code,
          })),
          hasMore: options.length > 0,
        };
      }
      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, prevSearch, setPrevSearch, originCityPage,regionCode,countryCode]
  );
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/configs/addresses")}
              >
                Configs
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/configs/addresses")}
              >
                Addresses
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight:
                    editPage && params?.id === undefined ? "bolder" : "525",
                }}
                onClick={() => navigate("/configs/addresses")}
              >
                {tab == 1
                  ? "Countries"
                  : tab == 2
                  ? "Regions"
                  : tab == 3
                  ? "Cities"
                  : "Districts"}
              </span>
            </li>
            {editPage && params?.id !== undefined && (
              <>
                <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                  <span>
                    <img
                      src={breadArrow}
                      className="turnBlack"
                      alt="breadcrumbarrow"
                    />
                  </span>
                </li>
                <li>
                  <span
                    className="mainheadlink"
                    style={{
                      fontWeight: "500",
                      marginLeft: "-3px",
                      fontWeight: "bolder",
                    }}
                  >
                    {tab == 1
                      ? countryData?.data?.data?.name
                      : tab == 2
                      ? regionData?.data?.data?.name
                      : tab == 3
                      ? cityData?.data?.data?.name
                      : districtData?.data?.data?.name}
                  </span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      <div className="displayFlex tabletab_section">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => {
            setNewPage(0);
            tabHandler(1);
            setOriginCitypage(-1);
            setSelectedOptionCity(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setcityCode("");
            setCountryCode("");
            setRegionCode("");
            setDistrictCode("");
            setSelectedOption(null);
            setPage(-1);
            setZonecode("");
            setSortField("-updatedAt");
          }}
        >
          Countries
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            setNewPage(0);
            tabHandler(2);
            setOriginCitypage(-1);
            setSelectedOptionCity(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setCountryCode("");
            setRegionCode("");
            setDistrictCode("");
            setcityCode("");
            setSelectedOption(null);
            setPage(-1);
            setZonecode("");
            setSortField("-updatedAt");
          }}
        >
          Regions
        </button>
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
            setNewPage(0);
            tabHandler(3);
            setOriginCitypage(-1);
            setSelectedOptionCity(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setcityCode("");
            setCountryCode("");
            setRegionCode("");
            setDistrictCode("");
            setSelectedOption(null);
            setPage(-1);
            setZonecode("");
            setSortField("-updatedAt");
          }}
        >
          Cities
        </button>
        <button
          className={`filterBtn ${tab == 4 && `activeFilterBtn`}`}
          onClick={() => {
            setNewPage(0);
            tabHandler(4);
            setOriginCitypage(-1);
            setSelectedOptionCity(null);
            setcityCode("");
            setCountryCode("");
            setRegionCode("");
            setDistrictCode("");
            setSelectedOption(null);
            setResetKey((prevKey) => prevKey + 1);
            setRegionResetKey((prevKey) => prevKey + 1);
            setCityResetKey((prevKey) => prevKey + 1);
            setPage(-1);
            setZonecode("");
            setSortField("-updatedAt");
          }}
        >
          Districts
        </button>
      </div>

      <div>
        {!editPage && (
         <><div style={{ position: "relative", width: "100%" }}>
            {tab === 1 ? (
                <Row>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize14 mb_12 marginR10 mt_10">
                      Name:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                      <input
                        type="text"
                        value={name}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setName(e.target.value)}
                      />
                   {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
                  </Col>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize14 mb_12 marginR10 mt_10">
                      ISO Code 3:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                      <input
                        type="text"
                        value={isocode}
                        className={
                          isocode?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          isocode?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setIsocode(e.target.value)}
                      />
                       {isocode && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setIsocode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                </Row>
            ) : tab === 2 ? (
                <Row>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize14 mb_12 marginR10 mt_10">
                      Region Name:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={name}
                        className={
                          name?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          name?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setName(e.target.value)}
                      />
                         {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col  className="col-20-percent" md={3}>
                    <label className="fontSize14 mb_12 marginR10 mt_10">
                      Region Code:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={regionCode}
                        className={
                          regionCode?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          regionCode?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setRegionCode(e.target.value)}
                      />
                     {regionCode && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setRegionCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col className="col-20-percent" md={3}>
                    <label className="fontSize14 mb_12 marginR10 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      handleChange={(selectedOption) => {
                        setCountryCode(
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      onMenuOpen={handleMenudesregion}
                      onMenuClose={handleMenuClosedesregion}
                      menuIsOpen={isReactdesRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                </Row>
            ) : tab == 3 ? (
              <Row>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">City Code:</label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={cityCode}
                      className={
                        cityCode?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onChange={(e) => setcityCode(e.target.value)}
                      style={
                        cityCode?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                    />
                   {cityCode && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setcityCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">City Name:</label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={cityName}
                      className={
                        cityName?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        cityName?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setcityName(e.target.value)}
                    />
                   {cityName && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setcityName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">Country:</label>
                  <SearchInput
                    key={resetKey}
                    id="countryIsoCode3"
                    label="Country Name"
                    name="countryIsoCode3"
                    options={countriesData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={async (selectedOption) => {
                      setCountryCode(
                        selectedOption ? selectedOption.isoCode3 : ""
                      );
                      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&fields=regionName`;
                      if (selectedOption) {
                        params += `&countryIsoCode3=${selectedOption.isoCode3}`;
                      }
                      const regionData = await getRegions(params);
                      setRegionCode("");
                      setRegionResetKey((prevKey) => prevKey + 1);
                    }}
                    onMenuOpen={handleMenudesregion}
                    onMenuClose={handleMenuClosedesregion}
                    menuIsOpen={isReactdesRegion}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Country"
                    className={
                      countriesData?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">Region:</label>
                  <SearchInput
                    key={regionResetKey}
                    id="regionCode"
                    label="Region Name"
                    name="regionCode"
                    options={regionsData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={(selectedOption) => {
                      setRegionCode(selectedOption ? selectedOption.code : "");
                    }}
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    menuIsOpen={isReactselectCity}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Region"
                    className={
                      regionCode?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">District Code:</label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={districtCode}
                      className={
                        districtCode?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      onChange={(e) => setDistrictCode(e.target.value)}
                      style={
                        districtCode?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                    />
                     {districtCode && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setDistrictCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                  </div>
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">District Name:</label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={districtName}
                      className={
                        districtName?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        districtName?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setDistrictName(e.target.value)}
                    />
                    {districtName && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setDistrictName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                  </div>
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">Country:</label>
                  <SearchInput
                    key={resetKey}
                    id="countryIsoCode3"
                    label="Country Name"
                    name="countryIsoCode3"
                    options={countriesData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={async(selectedOption) => {
                      setCountryCode(
                        selectedOption ? selectedOption.isoCode3 : ""
                      );
                      setOriginCitypage(-1);
                      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName&fields=regionName`;
                      if (selectedOption) {
                        params += `&countryIsoCode3=${selectedOption.isoCode3}`;
                      }
                      const regionData = await getRegions(params);
                      setRegionCode("");
                      setRegionResetKey((prevKey) => prevKey + 1);
                      setcityCode("");
                      setSelectedOptionCity(null);
                      setOriginCitypage(-1);
                      setCityResetKey((prevKey) => prevKey + 1);
                    }}
                    onMenuOpen={handleMenudesregion}
                    onMenuClose={handleMenuClosedesregion}
                    menuIsOpen={isReactdesRegion}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Country"
                    className={
                      countriesData?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">Region:</label>
                  <SearchInput
                    key={regionResetKey}
                    id="regionCode"
                    label="Region Name"
                    name="regionCode"
                    options={regionsData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    handleChange={(selectedOption) => {
                      setRegionCode(selectedOption ? selectedOption.code : "");
                      setcityCode("");
                      setSelectedOptionCity(null);
                      setOriginCitypage(-1);
                      setCityResetKey((prevKey) => prevKey + 1);
                    }}
                    onMenuOpen={handleMenuCharges}
                    onMenuClose={handleMenuCloseCharges}
                    menuIsOpen={isReactCharges}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Region"
                    className={
                      regionCode?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">City:</label>
                  <AsyncSearchInput
                    key={cityResetKey}
                    id="cityCode"
                    label="City Name"
                    name="cityCode"
                    value={selectedOptionCity}
                    loadMoreOptions={loadMoreCityOptions}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuRates}
                    onMenuClose={handleMenuCloseRates}
                    menuIsOpen={isReactRates}
                    onChange={(selectedOptionCity) => {
                      setSelectedOptionCity(selectedOptionCity);
                      if (selectedOptionCity) {
                        setcityCode(selectedOptionCity.value);
                      } else {
                        setcityCode(null);
                      }
                    }}
                    placeholder="City"
                  />
                </Col>
                <Col className="col-20-percent" md={3}>
                  <label className="fontSize14 mb_12">Zone Code:</label>
                  <AsyncSearchInput
                    key={resetKey}
                    id="zoneCode"
                    label="zoneCode"
                    name="zoneCode"
                    value={selectedOption}
                    loadMoreOptions={loadMoreOptions}
                    shouldLoadMore={shouldLoadMore}
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    menuIsOpen={isReactselectCity}
                    onChange={(selectedOption) => {
                      setSelectedOption(selectedOption);
                      if (selectedOption) {
                        setZonecode(selectedOption.value);
                      } else {
                        setZonecode("");
                      }
                    }}
                    placeholder="Zone code"
                  />
                </Col>
                <Col className="col-20-percent marginL12" md={3}>
                  <label className="fontSize14 mb_12">Remote:</label>
                  <div className="col-md-8">
                    <div className="smallCheckbox">
                      <input
                        type="checkbox"
                        id="remote"
                        checked={isRemote}
                        style={{ marginLeft: "5px" }}
                        onChange={() => setIsRemote(!isRemote)}
                      />
                      <label className="margintop7" htmlFor="remote"></label>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            </div>
              <div className="displayFlex alignItemCenter justifyContent_end mb_8 mt_-24">
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                {(role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                  <>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon mr_10 ml_10"
                      title={
                        tab === 1
                          ? "Country Template"
                          : tab === 2
                          ? "Region Template"
                          : tab === 3
                          ? "City Template"
                          : "District Template"
                      }
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div
                      title={
                        tab === 1
                          ? "Import Country"
                          : tab === 2
                          ? "Import Region"
                          : tab === 3
                          ? "Import City"
                          : "Import District"
                      }
                      className="ml_10"
                    >
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                )}
                <button
                  className="filterBtn"
                  title={
                    tab === 1
                      ? "Export Country"
                      : tab === 2
                      ? "Export Region"
                      : tab === 3
                      ? "Export City"
                      : "Export District"
                  }
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(role?.includes("AONE_CONFIGS.write") ||
                  role?.includes("AONE_CONFIGS.admin") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
                  role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    onClick={() => (
                      setEditPage(true), navigate("/configs/addresses/add")
                    )}
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div></>
        )}
        {!editPage && (
          <PaginationTable
            tableData={
              tab == 1
                ? addressData
                : tab === 2
                ? regionresponse
                : tab === 3
                ? cityResponse
                : districtResponse
            }
            tbl={
              tab == 1
                ? "Countries"
                : tab === 2
                ? "Regions"
                : tab === 3
                ? "Cities"
                : "Districts"
            }
            modalHandler={editPage}
            setModalHandler={setEditPage}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
          />
        )}
      </div>

      <div>
        {editPage &&
          (tab == 1 ? (
            <Countries edit={setEditPage} />
          ) : tab == 2 ? (
            <Regions edit={setEditPage} />
          ) : tab == 3 ? (
            <Cities edit={setEditPage} />
          ) : (
            <District edit={setEditPage} />
          ))}
      </div>
    </div>
  );
};

export default Addresses;
