import React, { useState, useEffect, useContext } from "react";
import PaginationTable from "../../components/Tables/paginationTable";
import OpenModal from "../../components/modal/openModal";
import syncicon from "../../assets/img/sync.svg";
import downloadIcon from "../../assets/img/download_icon.svg";
import { useFormik } from "formik";
import { useAoneStore } from "../../store/AoneStore";
import { roleHandler } from "../../helper/roleHandler";
import { UserContext } from "../../helper/ProtectedRoute";
import { useNavigate, useParams, useLocation } from "react-router";
const Pickupaddress = ({ setTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const params = useParams();
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [modalHandler, setModalHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [statusHandler, setStatusHandler] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [pickupData, setPickupData] = useState([]);
  const [sortBy, setSortBy] = useState("createdAt");
  const [headerParams, setHeaderParams] = useState("");
  const [state] = useContext(UserContext);
  const getDistrictbyCode = useAoneStore((state) => state.getDistrictbyCode);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  const getCities = useAoneStore((state) => state.getCities);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const getDistricts = useAoneStore((state) => state.getDistricts);
  const getpickupaddressbyId = useAoneStore(
    (state) => state.getpickupaddressbyId
  );
  const countriesData = useAoneStore((state) => state.countriesData);
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const getData = async () => {
    let paramsquery = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    const data = await getpickupaddressbyId(params?.id, paramsquery);
    setPickupData(data?.data?.data?.content);
    setTotalCount(data?.data?.data?.totalElements);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    deleteHandler,
    location,
    modalHandler,
    addModalHandler,
    statusHandler,
    saveHandler,
    isDelete,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length === undefined ||
      countriesData?.data?.data?.content?.length < 1
    ) {
      getCountries(paramslink);
    }
  }, []);
  return (
    <div>
      <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
        <div className="displayFlex justifyContent_end"></div>
        <div className="displayFlex alignItemCenter justifyContent_end mb_16">
          {/* <button
              className="filterBtn plus_icon mleft_10"
              title="Export"
            >
              <span>
                <img src={downloadIcon} className="h18" />
              </span>
            </button> */}
          <button
            className="filterBtn plus_icon mleft_10"
            title="Sync"
            onClick={() => syncronizeRoles()}
          >
            <span>
              <img src={syncicon} className="h18" />
            </span>
          </button>
          {(roleHandler(role, "AONE_CRM.write") ||
            roleHandler(role, "AONE_CRM.admin") ||
            roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
            roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
            roleHandler(role, "AONE_CONFIGS.write") ||
            roleHandler(role, "AONE_CONFIGS.admin") ||
            roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
            roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) && (
            <button
              className="filterBtn plus_icon mleft_10"
              title="Add"
              onClick={() => setAddModalHandler(!addModalHandler)}
              tabIndex="-1"
            >
              +
            </button>
          )}
        </div>
      </div>
      <PaginationTable
        tableData={pickupData}
        setHeader={setHeaderParams}
        tbl={"Pickupaddress"}
        modalHandler={modalHandler}
        title={"Edit Pickup Address"}
        setModalHandler={setModalHandler}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        newPage={newPage}
        setNewPage={setNewPage}
        size={size}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setSize={setSize}
        setIndividualCheckboxesBag={setIndividualCheckboxesBag}
        individualCheckboxesbag={individualCheckboxesbag}
        totalCount={totalCount}
        setSortField={setSortField}
        sortField={sortField}
        statusHandler={statusHandler}
        setStatusHandler={setStatusHandler}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
      />
      {addModalHandler && (
        <OpenModal
          modalHandler={addModalHandler}
          setModalHandler={setAddModalHandler}
          title={"Add Pickup Address"}
          editHandler={editHandler}
          cusAccount={params?.id}
          setTab={setTab}
          setEditHandler={setEditHandler}
        />
      )}
    </div>
  );
};

export default Pickupaddress;
