import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    useContext,
  } from "react";
  import { useNavigate } from "react-router";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import Dropdown from "react-bootstrap/Dropdown";
  import DotboldIcon from "../../../../src/assets/img/dotsbold.svg";
  import syncicon from "../../../../src/assets/img/sync.svg";
  import removeIcon from "../../../assets/img/cancelIcon.svg";
  import downloadicon from "../../../assets/img/download_icon.svg";
  import { toast } from "react-toastify";
  import { useAoneStore } from "../../../store/AoneStore";
  import fileDownload from "js-file-download";
  import PaginationTable from "../../../components/Tables/paginationTable";
  import { useCollectionStore } from "../../../store/CollectionsMgt/CollectionStore";
  import { useShortAddressStore } from "../../../store/ShortAddress/ShortAddressStore";
  import { DateRangePicker } from "rsuite";
  import jwtDecode from "jwt-decode";
  import { UserContext } from "../../../helper/ProtectedRoute";
  const ShortAddressHistory = () => {
    const ref = useRef();
    const navigate = useNavigate();
    const [newPage, setNewPage] = useState(0);
    const ispostAdded = useAoneStore((state) => state?.ispostAdded);
    const saveHandler = useAoneStore((state) => state?.saveHandler);
    const [dateRangefil, setDateRangefil] = useState({});
    const exportLedger = useCollectionStore((state) => state?.exportLedger);
    const [shortAddress, setShortAddress] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [dateRange, setDateRange] = useState([]);
    const [size, setSize] = useState(20);
    const [totalCount, setTotalCount] = useState(0);
    const [deleteHandler, setDeleteHandler] = useState(false);
    const [paramCountry,setParamsCountry] = useState("SAU");
    const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
    const [shortAddressData, setShortAddressData] = useState([]);
    const [modalHandler, setModalHandler] = useState(false);
    const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
    const getShortAddressHistory = useShortAddressStore((state) => state?.getShortAddressHistory);
    const exportShortAddressHistory = useShortAddressStore((state) => state?.exportShortAddressHistory);
    const handleMenuOpen = () => {
      setIsDropdownOpen(true);
    };
    const handleMenuClose = () => {
      setIsDropdownOpen(false);
    };
    const [column, SetCoulmn] = useState([
      "Short address",
      "Region",
      "City",
      "District",
      "SPL Region",
      "SPL Governorate",
      "SPL City",
      "SPL District",
      "Additional number",
      "Created at",
      "Created by",
      "Updated at",
      "Updated by",
      "Actions",
    ]);
    const [checkedCol, setCheckedCol] = useState([
      "Created at",
     "Created by",
     "Updated at",
     "Updated by",
    ]);
    const handleSelect = (d) => {
      let val = checkedCol.includes(d);
      if (val) {
        setCheckedCol(checkedCol.filter((dat) => dat !== d));
      } else {
        setCheckedCol([...checkedCol, d]);
      }
    };
    const [username, setUsername] = useState("");
    // Decode the token using jwtDecode
    useEffect(() => {
      const token = sessionStorage.getItem("user_access_token");
      // Decode the token using jwtDecode
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const { name } = decodedToken;
          setUsername(name);
        } catch (error) {
          console.error("Error decoding token:", error.message);
        }
      } else {
        console.error("Token not found in session storage");
      }
    }, []);
    const [sortField, setSortField] = useState("-updatedAt");
    const [state] = useContext(UserContext);
    const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
    useEffect(() => {
      setRole(state.roles);
    }, [state.roles]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectOrders, setSelectOrders] = useState([]);
    const getConsignmentOrders = useAoneStore(
      (state) => state?.getConsignmentOrders
    );
    const handleDateRangeChange = (value) => {
      if (value !== null) {
        const maxAllowedDays = 90; // Maximum allowed range in days
        // Calculate the difference in days
        const dayDifference = Math.abs(
          Math.round((value[1] - value[0]) / (24 * 60 * 60 * 1000))
        );
        // Check if the selected range is within the allowed range
        if (dayDifference <= maxAllowedDays) {
          setDateRange(value);
          const startMillis = value[0].setSeconds(0, 0); // Set time to 00:00:00
          const endMillis = value[1].setSeconds(59, 999); // Set time to 23:59:59
          setDateRangefil({
            start: startMillis,
            end: endMillis,
          });
        } else {
          toast.error("Daterange Should not exceed more than 3 months");
        }
      } else {
        // Reset date range
        setDateRange([]);
        // Set time range for the whole day
        setDateRangefil([]);
      }
    };
    const syncronizeRoles = async () => {
      setNewPage(0);
      getData();
    };
    const getData = async () => {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
      if (shortAddress) {
        params += `&shortAddress=${shortAddress}`;
      }
      if (dateRangefil?.start) {
        params += `&fromDate=${dateRangefil.start}`;
      }
      if (dateRangefil?.end) {
        params += `&toDate=${dateRangefil.end}`;
      }
      const data = await getShortAddressHistory(paramCountry,params);
      setTotalCount("");
      setShortAddressData(data.data.data.content);
    };
    const exportToExcel = async () => {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
        try {
          const res = await exportShortAddressHistory(paramCountry,params);
          if (res.status == 200) {
            fileDownload(res.data, `Shortaddress History.xlsx`);
          }
        } catch (err) {}
  }
    useEffect(() => {
      getData();
    }, [
      newPage,
      size,
      sortField,
      saveHandler,
      ispostAdded,
      shortAddress,
      createdDate,
      dateRangefil
    ]);
    return (
      <div>
        <div>
            <>
                <Row>
                  <Col  className="col-20-percent" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10 ws_nowrap">
                      Short Address:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                      <input
                        type="text"
                        value={shortAddress}
                        className={shortAddress?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"}
                        style={shortAddress?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}}
                        onChange={(e) => setShortAddress(e.target.value)} />
                         {shortAddress && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setShortAddress("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col className="col-20-percent mtDate" md={3}>
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                     Date:
                    </label>
                    <DateRangePicker
                      format="yyyy-MM-dd"
                      placeholder="Select Date"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      placement="auto"
                      minDate={
                        new Date(new Date().setMonth(new Date().getMonth() - 3))
                      }
                      onOpen={handleMenuOpen}
                      onClose={handleMenuClose}
                      showMeridian
                      className="w200"
                    />
                  </Col>
                </Row>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
                  <div className="displayFlex gap_12">
                  <button
                  className="filterBtn"
                  title={"Export History"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                    <button
                      className="filterBtn plus_icon"
                      title="Sync"
                      onClick={() => syncronizeRoles()}
                    >
                      <span>
                        <img src={syncicon} className="h18" />
                      </span>
                    </button>
                    <div ref={ref} className="customFilter">
                      <Dropdown autoClose="outside">
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={DotboldIcon} />
                        </Dropdown.Toggle>
  
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <ul className="listColumn cursorPointer">
                              {column.map((d, i) => (
                                <li key={d}>
                                  <div className="smallCheckbox mb_4">
                                    <input
                                      id={`product${i}`}
                                      type="checkbox"
                                      checked={!checkedCol.includes(d)} />
                                    <label
                                      htmlFor={`product${i}`}
                                      onClick={() => handleSelect(d)}
                                    >
                                      {d}
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
              </div></>
            <PaginationTable
              tableData={shortAddressData}
              restrict={checkedCol}
              tbl={"ShortAddressHistory"}
              modalHandler={modalHandler}
              setModalHandler={setModalHandler}
              deleteHandler={deleteHandler}
              setDeleteHandler={setDeleteHandler}
              newPage={newPage}
              setSelectOrders={setSelectOrders}
              selectOrders={selectOrders}
              setSelectAll={setSelectAll}
              selectAll={selectAll}
              setIndividualCheckboxesBag={setIndividualCheckboxesBag}
              individualCheckboxesbag={individualCheckboxesbag}
              isDropdownselectOpen={isDropdownselectOpen}
              setNewPage={setNewPage}
              size={size}
              setSize={setSize}
              totalCount={totalCount}
              sortField={sortField}
              setSortField={setSortField}
            />
        </div>
      </div>
    );
  };
  
  export default ShortAddressHistory;
  