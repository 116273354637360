import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useAoneStore } from "../../../store/AoneStore";
import { useDriverStore } from "../../../store/DriverMgt/driverStore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../../helper/ProtectedRoute";
import { roleHandler } from "../../../helper/roleHandler";
import CusModal from "../../../components/modal/CusModal";
import ResetPasswordModal from "./ResetPasswordModal";
import PasswordIcon from "../../../../src/assets/img/PasswordGearIcon.svg"
const UserManagement = () => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const globalDriverCode = useDriverStore((state) => state?.globalDriverCode);
  const globalDriverFirstName = useDriverStore((state) => state?.globalDriverFirstName);
  const globalDriverLastName = useDriverStore((state) => state?.globalDriverLastName);
  const globaldriverActive = useDriverStore((state) => state?.globaldriverActive);
  const setglobalDriverCode = useDriverStore((state) => state?.setglobalDriverCode);
  const setglobalDriverFirstName = useDriverStore((state) => state?.setglobalDriverFirstName);
  const setglobalDriverLastName = useDriverStore((state) => state?.setglobalDriverLastName);
  const setglobalEmployeeCode = useDriverStore((state) => state?.setglobalEmployeeCode);
  const setglobalUserStatus = useDriverStore((state) => state?.setglobalUserStatus);
  const setLineHaulTab = useDriverStore((state) => state?.setLineHaulTab);
  const postLoginInfoDriver = useDriverStore((state) => state?.postLoginInfoDriver);
  const resetPasswordDriver = useDriverStore((state) => state?.resetPasswordDriver);
  const generatePassword = useDriverStore((state) => state?.generatePassword);
  const toggleDriverstatus = useDriverStore((state) => state?.toggleDriverstatus);
  const globalEmployeeStatus = useAoneStore(
    (state) => state?.globalEmployeeStatus
  );
  const globaldriverStatus = useDriverStore((state) => state?.globaldriverStatus);
  const [isEnabled, setIsEnabled] = useState(globaldriverActive);
  const toggleStatus = async () => {
    const updatedIsEnabled = !isEnabled; // Toggle the isEnabled state
    setIsEnabled(updatedIsEnabled); // Update the state
    // Call the API with the updated isEnabled value
    const res = await toggleDriverstatus(globalDriverCode, updatedIsEnabled);
    if(res?.data?.status == "SUCCESS"){
      toast.success(res?.data?.status)
    }else{
      toast.error(res?.data?.status)
    }
  };
  useEffect(() => {
    addLoginInformation.setFieldValue("username", globalDriverCode);
    addLoginInformation.setFieldValue("firstName", globalDriverFirstName);
    addLoginInformation.setFieldValue("lastName", globalDriverLastName);
    addLoginInformation.setFieldValue("email", `${globalDriverCode}ajex@gmail.com`);
  }, []);
  const addLoginInformation = useFormik({
    initialValues: {
      password: "", 
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
    }),
    onSubmit: async (values, { resetForm }) => {
      SetIsReset(false);
      if (globalDriverCode) {
        await postLoginInfoDriver(globalDriverCode,values).then((response) => {
          if (response.status == "201" && response?.data?.status !== "ERROR") {
            toast.success("Login Information Added");
            setLineHaulTab(2);
            navigate("/ops/linehauls");
            setglobalDriverCode("");
            setglobalDriverFirstName("");
            setglobalDriverLastName("");
            setglobalEmployeeCode("");
            setglobalUserStatus("");
            resetForm();
          } else if(response?.data?.status === "ERROR"){
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.status === "ERROR" && response?.response?.data?.msg) {
            // Handle error response from the server
            toast.error(`${response?.response?.data?.msg}`);
          } else if (response?.response?.data?.status === "ERROR") {
            // Handle error response from the server
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(`${errorMessages}`);
          } else {
            const errorMessage = response?.response?.data?.msg?.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        toast.error(
          "Driver account need to be created for adding login information"
        );
      }
    },
  });
  const generatepassword = async () => {
    const data = await generatePassword();
    addLoginInformation.setFieldValue("password", data?.data?.data);
  };
  const [isReset, SetIsReset] = useState(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  return (
    <div>
      <div className="borderFrame mb_16">
        <div className="borderFrameTitle">Login Information</div>
        <div className="row">
          <div className="col-md-4">
            <div className="row mb_22">
              <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                Username:
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  placeholder="Username"
                  className="mainInput"
                  value={addLoginInformation.values.username}
                  onChange={(e) =>
                    addLoginInformation.setFieldValue(
                      "username",
                      e.target.value
                    )
                  }
                  name="username"
                  disabled={true}
                />
              </div>
            </div>
          </div>
          {(roleHandler(role, "AONE_OPS.write") ||
        roleHandler(role, "AONE_OPS.admin") ||
            roleHandler(role, "AONE_OPS_DRIVERS.write") ||
            roleHandler(role, "AONE_OPS_DRIVERS.admin")) &&
            globaldriverStatus &&
            params?.id && (
              <div className="row mt_reset">
                <div className="row justify-content-end">
                  <div className="col-md-4">
                    <div className="toggle-switch-container">
                      <label className="toggle-switch">
                        <input
                          type="checkbox"
                          checked={isEnabled}
                          onChange={toggleStatus}
                        />
                        <span className="slider round"></span>
                      </label>
                      <span className="toggle-label">
                        {isEnabled ? "Enabled" : "Disabled"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col-md-4">
                    <button
                      type="button"
                      className="blue_btn"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
        {!globaldriverStatus &&
        <div className="row">
          <div className="col-md-4 mb_60">
            <div className="row mb_22">
              <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
                Password:
                <span className='bloodDonortext fontWeight600 fontSize16'>*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  placeholder="Password"
                  className="mainInput"
                  name="password"
                  value={addLoginInformation.values.password}
                  onChange={(e) => {
                    addLoginInformation.setFieldValue(
                      "password",
                      e.target.value
                    );
                  }}
                />
                {addLoginInformation.touched.password &&
                addLoginInformation.errors.password ? (
                  <p className="requiredText">
                    {addLoginInformation?.errors?.password?.includes(
                      "required"
                    ) &&
                      !isReset && <p>Password is required</p>}
                    <ul className="requiredText">
                      {addLoginInformation?.errors?.password?.includes(
                        "characters"
                      ) && <li>Password must be at least 6 characters</li>}
                      {addLoginInformation?.errors?.password?.includes(
                        "not exceed"
                      ) && <li>Password must not exceed 25 characters</li>}
                      {addLoginInformation?.errors?.password?.includes(
                        "lowercase letter"
                      ) && (
                        <li>
                          Password must contain at least one lowercase letter
                        </li>
                      )}
                      {addLoginInformation?.errors?.password?.includes(
                        "uppercase letter"
                      ) && (
                        <li>
                          Password must contain at least one uppercase letter
                        </li>
                      )}
                      {addLoginInformation?.errors?.password?.includes(
                        "digit"
                      ) && <li>Password must contain at least one digit</li>}
                      {addLoginInformation?.errors?.password?.includes(
                        "special character"
                      ) && (
                        <li>
                          Password must contain at least one special character
                          from -.!@#$%^&*()+?
                        </li>
                      )}
                    </ul>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb_150">
            <div className="row mb_22 generatePassword">
              <div className="col-md-4 mt_8">
              <span>
                  <img src={PasswordIcon} className="h18 cursorPointer"  onClick={generatepassword}/>
                </span>
              </div>
            </div>
          </div>
        </div>
        }
      </div>

      <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
        {params?.id ? (
           (roleHandler(role, "AONE_OPS.write") ||
           roleHandler(role, "AONE_OPS.admin") ||
               roleHandler(role, "AONE_OPS_DRIVERS.write") ||
               roleHandler(role, "AONE_OPS_DRIVERS.admin")) &&
               !globaldriverStatus ? (
            <>
              <button
                type="button"
                className="blue_btn"
                onClick={addLoginInformation?.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn">Cancel</button>
            </>
          ) : null
        ) : roleHandler(role, "AONE_OPS.write") ||
        roleHandler(role, "AONE_OPS.admin") ||
            roleHandler(role, "AONE_OPS_DRIVERS.write") ||
            roleHandler(role, "AONE_OPS_DRIVERS.admin")? (
          <>
            <button
              type="button"
              className="blue_btn"
              onClick={addLoginInformation?.handleSubmit}
            >
              Save
            </button>
            <button className="cancel_btn">Cancel</button>
          </>
        ) : null}
      </div>
      <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={"addVehicleCateg"}
        >
            <ResetPasswordModal
              onSuccess={handleSuccess}
              onError={handleError}
              showModal={showModal}
              setShowModal={setShowModal}
            />
        </CusModal>
    </div>
  );
};

export default UserManagement;
