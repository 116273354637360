import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import Collections from "./Collections"
import Transaction from "./Transactions"
import SettlementHistory from "./SettlementHistory";
import { useAoneStore } from "../../../store/AoneStore";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
const CashTabs = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
    const setglobalEmployeeCode = useAoneStore((state) => state?.setglobalEmployeeCode);
    const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
      };
      const [state] = useContext(UserContext);
      const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
      useEffect(() => {
        setRole(state.roles);
      }, [state.roles]);
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }} onClick={() => {
                  navigate("/finance/cashdebriefing");
                }}>
               Finance
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  navigate("/finance/cashdebriefing");
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                }}
              >
                Cash Debriefing
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
                onClick={() => {
                  navigate("/finance/cashdebriefing");
                }}
              >
                {params?.id}
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
               {tab == 1 ? "Collections" : tab == 2 ? "Transactions" : "Settlements history"}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section  mb_12">
      {(roleHandler(role, "AONE_FINANCE.read") ||
                  roleHandler(role, "AONE_FINANCE.write") ||
                  roleHandler(role, "AONE_FINANCE.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.read") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.settle") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.read") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.settle")) && (
        <button
          className={`filterBtn ${tab == 1 &&  `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(1);
              sessionStorage.removeItem("collectionTracking");
          }}
        >
          Collections
        </button>)}
        {(roleHandler(role, "AONE_FINANCE.read") ||
                  roleHandler(role, "AONE_FINANCE.write") ||
                  roleHandler(role, "AONE_FINANCE.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.read") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.settle") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.mode") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.read")) && (
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(2);
              sessionStorage.removeItem("collectionTracking");
          }}
        >
          Transactions
        </button>)}
        {(roleHandler(role, "AONE_FINANCE.read") ||
                  roleHandler(role, "AONE_FINANCE.write") ||
                  roleHandler(role, "AONE_FINANCE.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.read") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.write") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.admin") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS.settle") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.read") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_COLLECTIONS.settle") ||
                  roleHandler(role, "AONE_FINANCE_LEDGERS_TRANSACTIONS.read")) && (
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(3);
          }}
        >
          Settlement History
        </button>)}
      </div>
      <div>
         { (tab == 1 ? (
            <Collections edit={setEditPage} setTab={setTab}/>
          ) : tab == 2 ? (
            <Transaction edit={setEditPage} setTab={setTab}/>
          ) :
            <SettlementHistory edit={setEditPage} />)}
      </div>
    </div>
  )
}

export default CashTabs