import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import templateIcon from "../../assets/img/template_icon.svg";
import importIcon from "../../assets/img/importIcon.svg";
import downloadicon from "../../assets/img/download_icon.svg";
import removeIcon from "../../assets/img/cancelIcon.svg";
import editicon from "../../assets/img/editicon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import PaginationCusTable from "../../components/Tables/CusTable";
import DeleteIcon from "../../assets/img/delete_icon.svg";
import { useParams } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserContext } from "../../helper/ProtectedRoute";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { usesettingStore } from "../../store/Settings/SettingStore";
import SortingIconComponent from "../../components/sorting/SortingIcon";
import DeletingModal from "../../components/modal/deletingModal";
import CusModal from "../../components/modal/CusModal";
import SystemModal from "./SystemModal";
const Systems = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const prevent = useRef(false);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [editPage, setEditPage] = useState(false);
  const [zonecode, setZoneCode] = useState("");
  const [component, setComponent] = useState("WEB");
  const [alias, setAlias] = useState("");
  const [title, setTitle] = useState("");
  const [keyVal, setKeyVal] = useState("");
  const [appdata, setAppData] = useState("");
  const [module, setModule] = useState("");
  const [lang, setLang] = useState("");
  const [country, setCountry] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [localizatioNData, setLocalizationData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [isDelete, setIsDelete] = useState("");
  const [key, setKey] = useState(0);
  const getSettings = usesettingStore((state) => state.getSettings);
  const getSettingsbyId = usesettingStore(
    (state) => state.getSettingsbyId
  );
  const getSettingsbyComponent = usesettingStore(
    (state) => state.getSettingsbyComponent
  );
  const settingsTemplate = usesettingStore(
    (state) => state.settingsTemplate
  );
  const importSettings = usesettingStore(
    (state) => state.importSettings
  );
  const getLanguage = usesettingStore((state) => state.getLanguage);
  const LanguageData = usesettingStore((state) => state.LanguageData);
  const ModuleList = useAoneStore((state) => state.ModuleList);
  const getAllModules = useAoneStore((state) => state.getAllModules);
  const exportSettings = usesettingStore(
    (state) => state.exportSettings
  );
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };
  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  useEffect(() => {
    if (
      ModuleList?.data?.data?.length < 0 ||
      ModuleList?.data?.data?.length === undefined
    ) {
      getAllModules();
    }
  }, []);
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setZoneCode("");
    setTab(i);
    navigate("/settings/localization");
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&component=${component}`;
    if (appdata) {
      params += `&app=${appdata}`;
    }
    if (module) {
      params += `&module=${module}`;
    }
    if (keyVal) {
      params += `&systemKey=${keyVal}`;
    }
    const data = await getSettings(params);
    setTotalCount(data.data.data.totalElements);
    setLocalizationData(data.data.data.content);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    tab,
    editPage,
    module,
    appdata,
    keyVal,
    location,
    country,
    statusHandler,
    isDelete,
    ispostAdded,
    alias,
    lang,
    keyVal,
    zonecode,
    component,
    groupCode,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${30}`;
    if (
      LanguageData?.data?.data?.length < 1 ||
      LanguageData?.data?.data?.length == undefined
    ) {
      getLanguage(paramslink);
    }
  }, []);
  const app = ["AONE", "AIMS", "WMS"];
  const appOptions = app.map(e => ({
    name: e, 
    code: e
  }));
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const [deleteParamsId, setDeleteParamsId] = useState({
    idOne: "",
    idTwo: "",
    name: "",
    title: "",
  });
  const deleteParamsSetter = (id, name, title) => {
    setDeleteParams({
      id: id,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };
  const handleEdit = (code) => {
    setTitle("Edit System");
    setShowModal(true);
    getSystemonEdit(code);
  };
  const [systembyId, setSystembyId] = useState([]);
  const getSystemonEdit = async (code) => {
    const res = await getSettingsbyId(code);
    setSystembyId(res?.data?.data);
  };
  const downloadTemplate = async () => {
    try {
      const res = await settingsTemplate();
      if (res.status == 200) {
        fileDownload(res.data, `Settings Template.xlsx`);
      }
    } catch (err) {}
  };
  //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);
          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            importSettings(component, formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  if (response?.data?.data === undefined) {
                    const errorMessage = response?.data?.msg?.split(",")[0];
                    toast.error(errorMessage);
                    fileInput.value = "";
                  } else {
                    const errorMessages = response?.data?.data
                      .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                      .join(", ");
                    toast.error(`Error uploading the file: ${errorMessages}`);
                    const errorMessage =
                      response?.response?.data?.msg?.split(",")[0];
                    toast.error(errorMessage);
                    fileInput.value = "";
                  }
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  // API call was successful
                  getData();
                  // Show success toast here
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (groupCode) {
      params += `&code=${groupCode}`;
    }
    try {
      const res = await exportSettings(component, params);
      if (res.status == 200) {
        fileDownload(res.data, `Settings.xlsx`);
      }
    } catch (err) {}
  };
  const sorting = (name) => {
    const value = sortField?.startsWith("-") ? "" : "-";
    setSortField(value + name);
  };
  //table
  const tableHeader = (
    <>
      <th onClick={() => sorting("createdAt")}>
        #<SortingIconComponent sortField={sortField} fieldName="createdAt" />
      </th>
      <th onClick={() => sorting("languageCode")}>
        App
        <SortingIconComponent sortField={sortField} fieldName="languageCode" />
      </th>
      <th onClick={() => sorting("languageCode")}>
        Module
        <SortingIconComponent sortField={sortField} fieldName="languageCode" />
      </th>
      <th onClick={() => sorting("localizationKey")}>
        Key
        <SortingIconComponent
          sortField={sortField}
          fieldName="localizationKey"
        />
      </th>
      <th onClick={() => sorting("value")}>
        Value
        <SortingIconComponent sortField={sortField} fieldName="value" />
      </th>
      <th>Action</th>
    </>
  );

  const tableBody = (localizatioNData, index) => (
    <>
      <td>{index}</td>
      <td>{localizatioNData?.app}</td>
      <td>{localizatioNData?.module}</td>
      <td>{localizatioNData?.systemKey}</td>
      <td>{localizatioNData?.value}</td>
      <td>
        <button
          className="tableBtn"
          title="Edit"
          onClick={() => handleEdit(localizatioNData.id)}
        >
          <span>
            <img src={editicon} className="turnBlack" />
          </span>
        </button>{" "}
        &nbsp;
        <button
          className="tableBtn"
          title="Delete"
          onClick={() =>
            deleteParamsSetter(
              localizatioNData?.id,
              localizatioNData?.localizationKey,
              "Localization"
            )
          }
        >
          <span>
            <img src={DeleteIcon} className="h18 turnBlack" />
          </span>
        </button>
      </td>
    </>
  );
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }}>
                Settings
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
              >
                System
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
              <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Key:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={keyVal}
                      className={
                        keyVal?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        keyVal?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setKeyVal(e.target.value)}
                    />
                    {keyVal && (
                      <button
                        className="cancel_btn clear-btn"
                        type="button"
                        onClick={() => setKeyVal("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    App:
                  </label>
                  <SearchInput
                    id="appdata"
                    label="appdata"
                    name="appdata"
                    options={appOptions} 
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.code}
                    handleChange={(selectedOption) => {
                      setAppData(selectedOption ? selectedOption.code : "");
                    }}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    menuIsOpen={isReactselectOpen}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="App"
                    customStyles={{ control: { width: "160px" } }}
                    className={
                      appdata?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Module:
                  </label>
                  <SearchInput
                    id="module"
                    label="module"
                    name="module"
                    options={ModuleList?.data?.data || []}
                    getOptionLabel={(module) => module.displayText}
                    getOptionValue={(module) => module.value}
                    handleChange={(selectedOption) => {
                      setModule(selectedOption ? selectedOption.value : "");
                    }}
                    onMenuOpen={handleMenuOpenregion}
                onMenuClose={handleMenuCloseregion}
                menuIsOpen={isReactselectRegion}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Module"
                    customStyles={{ control: { width: "160px" } }}
                    className={
                      module?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <>
                  <>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon"
                      title="System Template"
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div title="Import System">
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                </>
                <button
                  className="filterBtn"
                  title={"Export System"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Add"
                  onClick={() => {
                    setShowModal(true);
                    setTitle("Create new system");
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
        <PaginationCusTable
          tableHeader={tableHeader}
          tableBody={tableBody}
          tableData={localizatioNData}
          tbl={"Localizations"}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          isReactselectOpen={isReactselectOpen}
          isReactselectRegion={isReactselectRegion}
          setSize={setSize}
          totalCount={totalCount}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
        />
        <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={"addVehicleCateg"}
        >
          <SystemModal
            showModal={showModal}
            onSuccess={handleSuccess}
            onError={handleError}
            setShowModal={setShowModal}
            title={title}
            systembyCode={systembyId}
            component={component}
          />
        </CusModal>
        {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
          deleteParamsId={deleteParamsId}
          setDeleteParamsId={setDeleteParamsId}
        />
      )}
      </div>
    </div>
  );
};

export default Systems;
