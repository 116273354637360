import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getDeliveryTask,postDeliveryTask,bulkAssignDeliveryTask,exportDeliveryTasks,importDeliveryTasks,DeliveryTaskTemplate,validateDeliveryTask,countEmployee,removeDeliveryTask,getdeliveryStatus,getdeliveryValidation,postdeliveryValidation,validatePackageTrackingId,submitDeliveryValidation,
  makeOfd,countTasks,exportDeliveryValidation,removeDeliveryValidation,deleteDeliveryValidation,startCourierDebreif,countCourierDebreif,
  addDebreifing,submitDebreifing,deleteDebreifing,getDebreifHistory,exportDebreifingHistory,getDebreif
} from "../../services/DeliveryMgt/Delivery_services";
const initialValues = {
  deliveryStatusList: []
};
let deliveryStore = (set, get) => ({
  ...initialValues,
  getDeliveryTask: async (params) => {
    const response = await getDeliveryTask(params);
    return response;
  },
  postDeliveryTask: async (data) => {
    const response = await postDeliveryTask(data);
    return response;
  },
  bulkAssignDeliveryTask: async (data) => {
    const response = await bulkAssignDeliveryTask(data);
    return response;
  },
  exportDeliveryTasks: async (data) => {
    const response = await exportDeliveryTasks(data);
    return response;
  },
  importDeliveryTasks: async (data) => {
    const response = await importDeliveryTasks(data);
    return response;
  },
  DeliveryTaskTemplate: async (data) => {
    const response = await DeliveryTaskTemplate(data);
    return response;
  },
  validateDeliveryTask: async (params) => {
    const response = await validateDeliveryTask(params);
    return response;
  },
  countEmployee: async (params) => {
    const response = await countEmployee(params);
    return response;
  },
  removeDeliveryTask: async (params) => {
    const response = await removeDeliveryTask(params);
    return response;
  },
  getdeliveryStatus: async (params) => {
    const response = await getdeliveryStatus(params);
    set({ deliveryStatusList: response });
    return response;
  },
  getdeliveryValidation: async (params) => {
    const response = await getdeliveryValidation(params);
    return response;
  },
  postdeliveryValidation: async (params) => {
    const response = await postdeliveryValidation(params);
    return response;
  },
  makeOfd: async (params) => {
    const response = await makeOfd(params);
    return response;
  },
  countTasks: async (params) => {
    const response = await countTasks(params);
    return response;
  },
  validatePackageTrackingId: async (params,data) => {
    const response = await validatePackageTrackingId(params,data);
    return response;
  },
  submitDeliveryValidation: async (params) => {
    const response = await submitDeliveryValidation(params);
    return response;
  },
  exportDeliveryValidation: async (params) => {
    const response = await exportDeliveryValidation(params);
    return response;
  },
  deleteDeliveryValidation: async (params) => {
    const response = await deleteDeliveryValidation(params);
    return response;
  },
  removeDeliveryValidation: async (params) => {
    const response = await removeDeliveryValidation(params);
    return response;
  },
  startCourierDebreif: async (params) => {
    const response = await startCourierDebreif(params);
    return response;
  },
  countCourierDebreif: async (params) => {
    const response = await countCourierDebreif(params);
    return response;
  },
  getDebreif: async (params) => {
    const response = await getDebreif(params);
    return response;
  },
  addDebreifing: async (params,params2) => {
    const response = await addDebreifing(params,params2);
    return response;
  },
  submitDebreifing: async (params) => {
    const response = await submitDebreifing(params);
    return response;
  },
  deleteDebreifing: async (params) => {
    const response = await deleteDebreifing(params);
    return response;
  },
  getDebreifHistory: async (params) => {
    const response = await getDebreifHistory(params);
    return response;
  },
  exportDebreifingHistory: async (params) => {
    const response = await exportDebreifingHistory(params);
    return response;
  },
});

deliveryStore = devtools(deliveryStore);

export const useDeliveryStore = create(deliveryStore);
