import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../../store/AoneStore";
import { useDriverStore } from "../../../store/DriverMgt/driverStore";
import DriversEdit from "./DriverEdit";
import UserManagement from "./UserManagement";
const DriverAdd = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
    const globalEmployeeCode = useAoneStore((state) => state?.globalEmployeeCode);
    const setLineHaulTab = useDriverStore((state) => state?.setLineHaulTab);
    const setDriverClick = useDriverStore((state) => state?.setDriverClick);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
        // navigate("/crm/customers");
      };
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
        <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() =>{ navigate("/ops/linehauls"); setLineHaulTab(2);setDriverClick(true);}}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() =>{ navigate("/ops/linehauls"); setLineHaulTab(2);setDriverClick(true);}}
              >
                Linehaul
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() =>{ navigate("/ops/linehauls"); setLineHaulTab(2);setDriverClick(true);}}
              >
               Drivers
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
              >
                {params?.id}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section  mb_12">
        <button
          className={`filterBtn ${tab == 1 &&  `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalEmployeeCode !== '') {
              tabHandler(1);
            }
          }}
        >
          Account
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(2);
          }}
        >
          User Management
        </button>
      </div>
      <div>
         { (tab == 1 ? (
            <DriversEdit edit={setEditPage} setTab={setTab}/>
          ) :
            <UserManagement edit={setEditPage} setTab={setTab}/>)}
      </div>
    </div>
  )
}

export default DriverAdd