import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getRules,postRules,deleteRules,exportRules,editRules,getRulesbyId,getTemplates,postTemplates,deleteTemplates,
    exportTemplates,editTemplates,getHistory,exportHistory,getDataType,getField,
    getOperator,getRuleStatus,getConditionLogic,getEventStatus
} from "../../services/NotificationsMgt/Notification_Services";
const initialValues = {
  dataTypeList: [],
  fieldList: [],
  operatorList: [],
  ruleStatusList: [],
  conditionLogicList: [],
  eventStatusList: [],
  templateList: []
};
let notificationStore = (set, get) => ({
  ...initialValues,
  getRules: async (params) => {
    const response = await getRules(params);
    return response;
  },
  postRules: async (data) => {
    const response = await postRules(data);
    return response;
  },
  deleteRules: async (params) => {
    const response = await deleteRules(params);
    return response;
  },
  exportRules: async (params) => {
    const response = await exportRules(params);
    return response;
  },
  editRules: async (params,data) => {
    const response = await editRules(params,data);
    return response;
  },
  getRulesbyId: async (params) => {
    const response = await getRulesbyId(params);
    return response;
  },
  getTemplates: async (params) => {
    const response = await getTemplates(params);
    set({ templateList: response });
    return response;
  },
  postTemplates: async (params) => {
    const response = await postTemplates(params);
    return response;
  },
  deleteTemplates: async (params) => {
    const response = await deleteTemplates(params);
    return response;
  },
  exportTemplates: async (params) => {
    const response = await exportTemplates(params);
    return response;
  },
  editTemplates: async (params,data) => {
    const response = await editTemplates(params,data);
    return response;
  },
  getHistory: async (params) => {
    const response = await getHistory(params);
    return response;
  },
  exportHistory: async (params) => {
    const response = await exportHistory(params);
    return response;
  },
  getDataType: async () => {
    const response = await getDataType();
    set({ dataTypeList: response });
    return response;
  },
  getField: async () => {
    const response = await getField();
    set({ fieldList: response });
    return response;
  },
  getOperator: async () => {
    const response = await getOperator();
    set({ operatorList: response });
    return response;
  },
  getRuleStatus: async () => {
    const response = await getRuleStatus();
    set({ ruleStatusList: response });
    return response;
  },
  getConditionLogic: async () => {
    const response = await getConditionLogic();
    set({ conditionLogicList: response });
    return response;
  },
  getEventStatus: async () => {
    const response = await getEventStatus();
    set({ eventStatusList: response });
    return response;
  },
});

notificationStore = devtools(notificationStore);

export const useNotificationStore = create(notificationStore);
