import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../../assets/img/download_icon.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useLinehaulStore } from "../../../store/ReasonsMgt/LinehaulStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../../components/Tables/paginationTable";
import OpenModal from "../../../components/modal/openModal";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
import LinehaulReasonModal from "./LinehaulModal";
import CusModal from "../../../components/modal/CusModal"
const Reasons = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [event, setEvent] = useState("");
  const [category, setCategory] = useState("");
  const [size, setSize] = useState(20);
  const [title, setTitle] = useState("Create New Linehaul Reason");
  const [isedited, SetIsEdited] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  const [editHandler, setEditHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [nprData, setNprData] = useState([]);
  const [ndrData, setNdrData] = useState([]);
  const [holdData, setHoldData] = useState([]);
  const [lineHauls, setLineHaulsData] = useState([]);
  const [modalHandler, setModalHandler] = useState(false);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const getPickupReasons = useAoneStore((state) => state?.getPickupReasons);
  const getLinehaulReasons = useLinehaulStore((state) => state?.getLinehaulReasons);
  const getLinehaulReasonsbyId = useLinehaulStore((state) => state?.getLinehaulReasonsbyId);
  const exportLinehaulReasons = useLinehaulStore((state) => state?.exportLinehaulReasons);
  const getDeliveryReasons = useAoneStore((state) => state?.getDeliveryReasons);
  const getHoldReasons = useAoneStore((state) => state?.getHoldReasons);
  const getPickupEventCategory = useAoneStore(
    (state) => state?.getPickupEventCategory
  );
  const getDeliveryEventCategory = useAoneStore(
    (state) => state?.getDeliveryEventCategory
  );
  const OpsDeliveryEventData = useAoneStore(
    (state) => state?.OpsDeliveryEventData
  );
  const getOpsCategory = useAoneStore((state) => state?.getOpsCategory);
  const OpsEventData = useAoneStore((state) => state?.OpsEventData);
  const OpsCategoryData = useAoneStore((state) => state?.OpsCategoryData);
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const exportPickupReasons = useAoneStore(
    (state) => state?.exportPickupReasons
  );
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const exportDeliveryReasons = useAoneStore(
    (state) => state?.exportDeliveryReasons
  );
  const exportHoldReasons = useAoneStore((state) => state?.exportHoldReasons);
  const getPickupCategory = useAoneStore((state) => state?.getPickupCategory);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  useEffect(() => {
    if (OpsEventData?.data?.data === undefined) {
      getPickupEventCategory();
    }
    if (OpsDeliveryEventData?.data?.data === undefined) {
      getDeliveryEventCategory();
    }
    if (OpsCategoryData?.data?.data === undefined) {
      getOpsCategory();
    }
  }, []);
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [linehaulsbyCode, setLinehaulsbyCode] = useState([]);
  const [linehaulsCode, setLinehaulsCode] = useState([]);
  const handleEdit = (code) => {
      setTitle("Edit Linehauls")
    setShowModal(true);
    setLinehaulsCode(code)
  };
  const getLinehaulonEdit = async (code) => {
      const res = await getLinehaulReasonsbyId(code);
      setLinehaulsbyCode(res?.data?.data);
  };
  const clearFilters = () =>{
    setCode("");
    setMessage("");
    setEvent("");
    }
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      if (event) {
        params += `&eventCategory=${event}`;
      }
      if (category) {
        params += `&category=${category}`;
      }
      try {
        const res = await exportPickupReasons(params);
        if (res.status == 200) {
          fileDownload(res.data, `NPR.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 2) {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      if (event) {
        params += `&eventCategory=${event}`;
      }
      try {
        const res = await exportDeliveryReasons(params);
        if (res.status == 200) {
          fileDownload(res.data, `NDR.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 2) {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      try {
        const res = await exportHoldReasons(params);
        if (res.status == 200) {
          fileDownload(res.data, `Hold Reasons.xlsx`);
        }
      } catch (err) {}
    } else {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      try {
        const res = await exportLinehaulReasons(params);
        if (res.status == 200) {
          fileDownload(res.data, `Linehaul Reasons.xlsx`);
        }
      } catch (err) {}
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      if (event) {
        params += `&events=${event}`;
      }
      if (category) {
        params += `&category=${category}`;
      }
      const data = await getPickupReasons(params);
      setTotalCount(data.data.data.totalElements);
      setNprData(data.data.data.content);
    } else if (tab === 2) {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      if (event) {
        params += `&events=${event}`;
      }
      const data = await getDeliveryReasons(params);
      setTotalCount(data.data.data.totalElements);
      setNdrData(data.data.data.content);
    } else if (tab === 3) {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      const data = await getHoldReasons(params);
      setTotalCount(data.data.data.totalElements);
      setHoldData(data.data.data.content);
    } else {
      if (code) {
        params += `&code=${code}`;
      }
      if (message) {
        params += `&message=${message}`;
      }
      const data = await getLinehaulReasons(params);
      setTotalCount(data.data.data.totalElements);
      setLineHaulsData(data.data.data.content);
    }
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    tab,
    editPage,
    sortField,
    saveHandler,
    code,
    message,
    category,
    event,
    ispostAdded,
  ]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/configs/currency")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/configs/currency")}
              >
                Reasons
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section mr_15 mb_12">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => tabHandler(1)}
        >
          NPR
        </button>
        {(roleHandler(role, "AONE_OPS.write") ||
          roleHandler(role, "AONE_OPS.admin") ||
          roleHandler(role, "AONE_OPS_REASONS_NDR.read") ||
          roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
          roleHandler(role, "AONE_OPS_REASONS_NDR.admin")) && (
          <button
            className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
            onClick={() => {tabHandler(2); clearFilters()}}
          >
            NDR
          </button>
        )}
        {(roleHandler(role, "AONE_OPS.write") ||
          roleHandler(role, "AONE_OPS.admin") ||
          roleHandler(role, "AONE_OPS_REASONS_HOLD.read") ||
          roleHandler(role, "AONE_OPS_REASONS_HOLD.write") ||
          roleHandler(role, "AONE_OPS_REASONS_HOLD.admin")) && (
          <button
            className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
            onClick={() => {tabHandler(3); clearFilters()}}
          >
            Hold Reasons
          </button>
        )}
        {(roleHandler(role, "AONE_OPS.write") ||
          roleHandler(role, "AONE_OPS.admin") ||
          roleHandler(role, "AONE_OPS_REASONS_HOLD.read") ||
          roleHandler(role, "AONE_OPS_REASONS_HOLD.write") ||
          roleHandler(role, "AONE_OPS_REASONS_HOLD.admin")) && (
          <button
            className={`filterBtn ${tab == 4 && `activeFilterBtn`}`}
            onClick={() => { tabHandler(4); clearFilters()}}
          >
            Linehaul Reasons
          </button>
        )}
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            {tab === 1 ? (
              <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Code:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={code}
                        className={
                          code?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          code?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setCode(e.target.value)}
                      />
                         {code && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setCode("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Message:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10 position-relative">
                      <input
                        type="text"
                        value={message}
                        className={
                          message?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          message?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setMessage(e.target.value)}
                      />
                        {message && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setMessage("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Event:
                    </label>
                    <SearchInput
                      id="event"
                      label="event"
                      name="event"
                      options={OpsEventData?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        setEvent(selectedOption ? selectedOption.key : "");
                      }}
                      isClearable={true}
                      isSearchable={true}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isDropdownselectOpen}
                      placeholder="Event"
                      className={
                        event?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Category:
                    </label>
                    <SearchInput
                      id="category"
                      label="category"
                      name="category"
                      options={OpsCategoryData?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      onMenuOpen={handleMenuOpencity}
                      onMenuClose={handleMenuClosecity}
                      menuIsOpen={isReactselectCity}
                      handleChange={(selectedOption) => {
                        setCategory(selectedOption ? selectedOption.key : "");
                      }}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Category"
                      className={
                        category?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                </Row>
              </div>
            ) : tab === 2 ? (
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Code:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={code}
                      className={
                        code?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setCode(e.target.value)}
                    />
                      {code && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setCode("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Message:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={message}
                      className={
                        message?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        message?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setMessage(e.target.value)}
                    />
                     {message && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setMessage("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Event:
                  </label>
                  <SearchInput
                    id="event"
                    label="event"
                    name="event"
                    options={OpsDeliveryEventData?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    handleChange={(selectedOption) => {
                      setEvent(selectedOption ? selectedOption.key : "");
                    }}
                    isClearable={true}
                    isSearchable={true}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    menuIsOpen={isDropdownselectOpen}
                    placeholder="Event"
                    className={
                      event?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Code:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={code}
                      className={
                        code?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setCode(e.target.value)}
                    />
                    {code && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setCode("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Message:
                  </label>
                  <div className="displayFlex alignItemCenter marginR10 position-relative">
                    <input
                      type="text"
                      value={message}
                      className={
                        message?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        message?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    {message && (
                      <button
                        className="cancel_btn clear-btn mr_10"
                        type="button"
                        onClick={() => setMessage("")}
                      >
                        <span>
                          <img src={removeIcon} alt="Clear" />
                        </span>
                      </button>
                    )}
                    </div>
                </Col>
              </Row>
            )}
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <button
                  className="filterBtn"
                  title={
                    tab === 1
                      ? "Export NPR"
                      : tab === 2
                      ? "Export NDR"
                      : tab === 3
                      ? "Export Hold Reasons"
                      : "Export Linehaul Reasons"
                  }
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                {(roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_REASONS_NPR.write") ||
                  roleHandler(role, "AONE_OPS_REASONS_NPR.admin") ||
                  roleHandler(role, "AONE_OPS_REASONS_NDR.write") ||
                  roleHandler(role, "AONE_OPS_REASONS_NDR.admin") ||
                  roleHandler(role, "AONE_OPS_REASONS_HOLD.write") ||
                  roleHandler(role, "AONE_OPS_REASONS_HOLD.admin")) && (
                  <button
                    className="filterBtn plus_icon"
                    title="Add"
                    onClick={() => {
                      if (tab === 4) {
                        setShowModal(true);
                        setTitle("Create New Linehaul Reason");
                      } else {
                        setAddModalHandler(!addModalHandler);
                      }
                    }}
                    tabIndex="-1"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {!editPage && (
          <PaginationTable
            tableData={tab == 1 ? nprData : tab == 2 ? ndrData : tab == 3 ? holdData : lineHauls}
            tbl={
              tab == 1
                ? "NPR"
                : tab === 2
                ? "NDR"
                : tab === 3
                ? "HOLD"
                : "Linehauls"
            }
            title={
              tab === 1
                ? "Edit NPR"
                : tab === 2
                ? "Edit NDR"
                : tab === 3
                ? "Edit Hold reason"
                : "Edit Linehauls"
            }
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            isDropdownselectOpen={isDropdownselectOpen}
            newPage={newPage}
            handleLinehaulsEdit={handleEdit}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
        <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={"addnewvehcilepop"}
        >
          <LinehaulReasonModal onSuccess={handleSuccess}
              onError={handleError}
              showModal={showModal}
              setShowModal={setShowModal}
              title={title}
              linehaulsbyCode={linehaulsbyCode}
              linehaulsCode={linehaulsCode}
              setLinehaulsbyCode={setLinehaulsbyCode}
              isedited={isedited}
              SetIsEdited={SetIsEdited}/>
        </CusModal>
        {(addModalHandler && tab !== 4) && (
          <OpenModal
            modalHandler={addModalHandler}
            setModalHandler={setAddModalHandler}
            title={
              tab == 1
                ? "Create New NPR"
                : tab === 2
                ? "Create New NDR"
                : tab === 3
                ? "Create New Hold reason"
                : ""
            }
            editHandler={editHandler}
            setEditHandler={setEditHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Reasons;
