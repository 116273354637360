import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../../assets/img/download_icon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { usecourierStore } from "../../../store/CourierMgt/courierStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import fileDownload from "js-file-download";
import Modal from "react-bootstrap/Modal";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import PaginationTable from "../../../components/Tables/paginationTable";
import CusModal from "../../../components/modal/CusModal";
import CourierModal from "./CourierModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeletingModal from "../../../components/modal/deletingModal";
import { UserContext } from "../../../helper/ProtectedRoute";
import MapView from "../../../components/Map/Map";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Couriers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [tripId, setTripId] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [state] = useContext(UserContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cusPage, setCusPage] = useState(-1);

  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [selectedOptionEmployee, setSelectedOptionEmployee] = useState(null);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [routesData, setRoutesData] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [sortField, setSortField] = useState("-updatedAt");
  const [resetKey, setResetKey] = useState(0);
  const getCouriers = usecourierStore((state) => state?.getCouriers);
  const filterCouriers = usecourierStore((state) => state?.filterCouriers);
  const getEmployee = usecourierStore((state) => state?.getEmployee);
  const exportCouriers = usecourierStore((state) => state?.exportCouriers);
  const setReactDesCountry = useAoneStore((state) => state?.setReactDesCountry);
  const isReactdesCountry = useAoneStore((state) => state?.isReactdesCountry);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const filterHubs = useAoneStore((state) => state?.filterHubs);
  const hubsData = useAoneStore((state) => state?.hubsData);
  const courierStatus = usecourierStore((state) => state?.courierStatus);
  const couriertaskType = usecourierStore((state) => state?.couriertaskType);
  const selectedCourierCode = usecourierStore(
    (state) => state?.selectedCourierCode
  );
  const getVehicles = usevehicleStore((state) => state?.getVehicles);
  const configureCourierHubs = usecourierStore(
    (state) => state?.configureCourierHubs
  );
  const vehicleList = usevehicleStore((state) => state?.vehicleList);
  const handleMenudescountry = () => {
    setReactDesCountry(true);
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${50}`;
    if (hubsData?.data?.data?.content?.length < 1 || hubsData?.length < 1) {
      filterHubs(paramslink);
    }
  }, []);
  const [courierName, setCourierName] = useState("");
  const assignHubCourier = useFormik({
    initialValues: {
      hubCode: "",
    },
    validationSchema: Yup.object({
      hubCode: Yup.string().required(
        "Select a Hub for configuring it to a courier"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        hubCode: values.hubCode,
      };
      await configureCourierHubs(selectedCourierCode, data).then((response) => {
        if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setShoworder(false);
          setCourierName("");
          resetForm();
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setShoworder(false);
          setCourierName("");
          resetForm();
        } else if (response?.response?.data?.status == "405") {
          const errorMessage = response?.response?.data?.error;
          toast.error(errorMessage);
          setShoworder(false);
          setCourierName("");
          resetForm();
        } else {
          toast.success("Hub Assigned to courier");
          getData();
          setCourierName("");
          setShoworder(false);
          resetForm();
        }
      });
    },
  });
  const handleMenuClosedescountry = () => {
    setReactDesCountry(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [showorder, setShoworder] = useState(false);
  const handleShoworder = () => setShoworder(true);
  const handleCloseorder = () => {
    setShoworder(false);
  };
  const assignModal = (hub) => {
    setShoworder(true);
    assignHubCourier.setFieldValue("hubCode",hub)
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      vehicleList?.data?.data?.content?.length < 1 ||
      vehicleList?.length < 1
    ) {
      getVehicles(paramslink);
    }
  }, []);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };
  const [selectedEmployeeCode, setSelectedEmployeeCode] = useState(null);
  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const [userHub,setUserHub] = useState("")
  useEffect(() => {
    const token = sessionStorage.getItem("user_access_token");
    if (token) {
      const decoded = jwt_decode(token);
      setUserHub(decoded?.attributes?.hubCode || ""); // Fallback to empty string if hubCode is undefined
    }
  }, []);
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        // Initialize the page number based on whether there's a search or not
        let nextPage = search ? 0 : cusPage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setCusPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20&fields=employeeName&hubCode=${userHub}`;
        if (search) {
          let hashsearch = `$${search}$`;
          endpoint += `&name=${hashsearch}`;
        }
        const response = await filterCouriers(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((option) => ({
            label: `${option.employeeCode} - ${option.employeeName}`,
            value: option.employeeCode,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore,cusPage,userHub]
  );
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=employeeName&hubCode=${userHub}`;
    if (code) {
      params += `&code=$${code}$`;
    }
    const data = await getCouriers(params);
    setTotalCount(data?.data?.data?.totalElements);
    setRoutesData(data?.data?.data?.content);
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=employeeName&hubCode=${userHub}`;
    if (code) {
      params += `&code=${code}`;
    }
    try {
      const res = await exportCouriers(params);
      if (res.status == 200) {
        fileDownload(res.data, `Couriers.xlsx`);
      } else if (res?.response?.data?.errorCode == 500) {
        toast?.error(res?.response?.data?.msg);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (userHub) {
    getData();
    }
  }, [
    newPage,
    userHub,
    sortBy,
    size,
    editPage,
    sortField,
    location,
    saveHandler,
    code,
    name,
    tripId,
    ispostAdded,
  ]);
  const [courierCode, setCourierCode] = useState("");
  const handleShowTasks = (employeecode) => {
    setCourierCode(employeecode);
    setShowModal(true);
  };
  const [showModal, setShowModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    id: "",
    name: "",
    title: "",
  });
  const handleDelete = (id, name, title) => {
    setDeleteParams({
      id: id,
      name: name,
      title: title,
    });
    setDeleteHandler(!deleteHandler);
  };
  //modal
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/assets/routes")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/ops/couriers");
                }}
              >
                Couriers
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!editPage && (
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col md={12}>
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Employee:
                  </label>
                  <AsyncSearchInput
                    id="code"
                    label="code"
                    name="code"
                    value={selectedOptionEmployee}
                    loadMoreOptions={loadMoreOptions}
                    shouldLoadMore={shouldLoadMore}
                    getOptionLabel={(zone) => zone.label}
                    getOptionValue={(zone) => zone.value}
                    onMenuOpen={handleMenuOpenregion}
                    onMenuClose={handleMenuCloseregion}
                    menuIsOpen={isReactselectRegion}
                    customStyles={{ control: { width: "185px" } }}
                    onChange={(selectedOptionEmployee) => {
                      setSelectedOptionEmployee(selectedOptionEmployee);
                      if (selectedOptionEmployee) {
                        setCode(selectedOptionEmployee.value);
                      } else {
                        setCode(null);
                      }
                    }}
                    placeholder="Employee"
                  />
                </Col>
              </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                <></>
                <button
                  className="filterBtn"
                  title={"Export Couriers"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteHandler && deleteParams?.title && (
        <DeletingModal
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
        />
      )}
      <div className="row">
      <div className={role?.includes("AONE_OPS_COURIERS.map") ? "col-md-7" : "col-md-12"}>
          <PaginationTable
            tbl="Courier"
            tableData={routesData}
            size={size}
            setSize={setSize}
            totalCount={totalCount}
            setNewPage={setNewPage}
            assignCourierModal={assignModal}
            setCourierName={setCourierName}
            newPage={newPage}
            sortField={sortField}
            setSortField={setSortField}
            setShowModal={setShowModal}
            setCourierCode={setCourierCode}
            selectedEmployeeCode={selectedEmployeeCode}
            setSelectedEmployeeCode={setSelectedEmployeeCode}
          />
        </div>
        {/* {role?.includes("AONE_OPS_COURIERS.map") && ( */}
        <div className="col-md-5">
          <MapView
            routesData={routesData}
            selectedEmployeeCode={selectedEmployeeCode}
            setSelectedEmployeeCode={setSelectedEmployeeCode}
          />
        </div>
      </div>
      <CusModal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="addnewpopupNpr"
      >
        <CourierModal
          onSuccess={handleSuccess}
          onError={handleError}
          setShowModal={setShowModal}
          courierCode={courierCode}
        />
      </CusModal>
      {showorder && (
        <Modal
          show={showorder}
          className="addnnewWeighing"
          onHide={handleCloseorder}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Assign Hub to courier
            </h4>
          </Modal.Header>
          <div className="modelScrollVehicleVendors">
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Employee Name:
                    </label>
                    <input
                      type="text"
                      className="mainInput"
                      name="courierName"
                      value={selectedCourierCode}
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                      Hubs:
                    </label>
                    <SearchInput
                      key={resetKey}
                      id="hubCode"
                      label="hubCode"
                      name="hubCode"
                      options={hubsData?.data?.data?.content || []}
                      getOptionLabel={(option) =>
                        `${option.code} - ${option.name}`
                      }
                      getOptionValue={(option) => option.code}
                      handleChange={async (selectedOption) => {
                        assignHubCourier.setFieldValue(
                          "hubCode",
                          selectedOption ? selectedOption.code : ""
                        );
                      }}
                      value={
                        assignHubCourier?.values?.hubCode
                          ? hubsData?.data?.data?.content?.find(
                              (vendor) =>
                                vendor.code ===
                              assignHubCourier?.values?.hubCode
                            )
                          : null
                      }
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Hub"
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {assignHubCourier.touched.hubCode &&
                    assignHubCourier.errors.hubCode ? (
                      <p className="requiredText">
                        {assignHubCourier.errors.hubCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
              <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
                <div>
                  <button
                    className="cancel_btn"
                    onClick={() => handleCloseorder()}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="blue_btn"
                    onClick={assignHubCourier.handleSubmit}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Couriers;
