import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import { roleHandler } from "../../helper/roleHandler";
import { UserContext } from "../../helper/ProtectedRoute";
import SmsHistory from "./SmsHistory";
import SmsTemplate from "./SmsTemplate";
import SmsRules from "./SmsRules";
const SmsView = () => {
    const navigate = useNavigate();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
      };
      useEffect(() => {
        const sessionValidationId = sessionStorage.getItem("ValidationId");
        if (sessionValidationId) {
          setTab(2);
        }
      }, []);
      useEffect(() => {
        const sessionValidationId = sessionStorage.getItem("DebriefingCode");
        if (sessionValidationId) {
          setTab(3);
        }
      }, []);
      const [state] = useContext(UserContext);
      const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
      useEffect(() => {
        setRole(state.roles);
      }, [state.roles]);
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/notifications/sms")}

              >
                Notifications
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/notifications/sms");
                }}
              >
                {tab == 1 ? "Templates" : tab == 2 ? "Rules" : "History"}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
      {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.read") ||
                  roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.write") ||
                  roleHandler(role, "AONE_NOTIFICATIONS_SMS_TEMPLATES.admin")) && (
        <button
          className={`filterBtn ${tab == 1 &&  `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(1);
          }}
        >
        Templates
        </button>
                  )}
        {( roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.read") ||
                  roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.write") ||
                  roleHandler(role, "AONE_NOTIFICATIONS_SMS_RULES.admin")) && (
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(2);
          }}
        >
         Rules
        </button>
       )}
         {(roleHandler(role, "AONE_NOTIFICATIONS_SMS_HISTORY.read")) && (
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(3);
          }}
        >
          History
        </button>
      )}
      </div>
      <div>
         { (tab == 1 ? (
            <SmsTemplate edit={setEditPage} setTab={setTab}/>
         )  :tab == 2 ? (
          <SmsRules edit={setEditPage} setTab={setTab}/>
          ) :
            <SmsHistory edit={setEditPage} setTab={setTab}/>)}
      </div>
    </div>
  )
}

export default SmsView;