import React, { useState, useEffect, useRef, useContext } from "react";
import PaginationTable from "../../components/Tables/paginationTable";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../store/AoneStore";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import OpenModal from "../../components/modal/openModal";
import syncicon from "../../../src/assets/img/sync.svg";
import removeIcon from "../../assets/img/cancelIcon.svg";
import downloadIcon from "../../assets/img/download_icon.svg";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fileDownload from "js-file-download";
import { UserContext } from "../../helper/ProtectedRoute";
import Modal from "react-bootstrap/Modal";
// import { DatePicker } from "rsuite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { roleHandler } from "../../helper/roleHandler";
const Customers = () => {
  const navigate = useNavigate();
  const paramsId = useParams();
  const location = useLocation();
  const [headerParams, setHeaderParams] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [isPush, setIsPush] = useState(false);
  const [sortField, setSortField] = useState("-updatedAt");
  const [modalHandler, setModalHandler] = useState(false);
  const [addModalHandler, setAddModalHandler] = useState(false);
  const [editHandler, setEditHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const setbillingArrayData = useAoneStore((state) => state.setbillingArrayData);
  const [sortBy, setSortBy] = useState("createdAt");
  const [seleczone, setselectZone] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const [name, setName] = useState("");
  const [accountNum, setAccountNum] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const getCustomer = useAoneStore((state) => state?.getCustomer);
  const exportCustomer = useAoneStore((state) => state?.exportCustomer);
  const syncCustomer = useAoneStore((state) => state?.syncCustomer);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const setcustomerArrayData = useAoneStore((state) => state?.setcustomerArrayData);
  const setcustomerAccount = useAoneStore((state) => state?.setcustomerAccount);
  const setbankArrayData = useAoneStore((state) => state?.setbankArrayData);
  const customerArrayData = useAoneStore((state) => state?.customerArrayData);
  const billingAddressData = useAoneStore((state) => state?.billingAddressData);
  const today = new Date();
  const startOfYesterday = new Date(today);
  startOfYesterday.setDate(today.getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);
  const defaultStartDate = startOfYesterday;
  const defaultEndDate = endOfToday;
  const prevent = useRef(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleCancel = () => {
    handleClose();
  };
  const synccrmCustomer = () => {
    setShow(true);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const syncCus = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      fromDate: Yup.string().required("From Date is required"),
      toDate: Yup.string().required("To Date is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formattedFromDate = formatDate(values.fromDate);
      const formattedToDate = formatDate(values.toDate);
      // Create the payload with formatted dates
      const payload = {
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      };
      await syncCustomer(payload).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success(`Customers Synced`);
          setShow(false);
          resetForm();
        } else if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg?.split("!")[0];
          toast.error(errorMessage);
          setShow(false);
          resetForm();
        } else {
          const errorMessage = response?.data?.msg?.split(",")[0];
          toast.error(errorMessage);
          setShow(false);
          resetForm();
        }
      });
    },
  });
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (name) {
      params += `&name=$${name}$`;
    }
    if (accountNum) {
      params += `&accountNumber=$${accountNum}$`;
    }
    if (salesPerson) {
      params += `&salesperson=${salesPerson}`;
    }
    const data = await getCustomer(params);
    setCustomerData(data?.data?.data?.content);
    setTotalCount(data?.data?.data?.totalElements);
  };
  const exportcrmCustomer = async () => {
    let params = `size=${size}&page=${newPage}&sortBy=${sortField}`;
    if (name) {
      params += `&name=$${name}$`;
    }
    if (accountNum) {
      params += `&accountNumber=${accountNum}`;
    }
    if (salesPerson) {
      params += `&salesperson=${salesPerson}`;
    }
    try {
      const res = await exportCustomer(params);
      if (res.status == 200) {
        fileDownload(res.data, `Customer.xlsx`);
      } else {
        toast.error(res.response.statusText);
      }
    } catch (err) {}
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };

  useEffect(() => {
    getData();
    setcustomerArrayData([]);
    setbankArrayData([]);
    setbillingArrayData([]);
  }, [
    newPage,
    sortBy,
    size,
    sortField,
    location,
    modalHandler,
    addModalHandler,
    statusHandler,
    salesPerson,
    saveHandler,
    isPush,
    isDelete,
    name,
    accountNum,
  ]);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  setcustomerAccount("")
                  navigate("/crm/customers");
                  setSelectAll(false);
                }}
              >
                CRM
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  setcustomerAccount("")
                  navigate("/crm/customers");
                  setSelectAll(false);
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
                Customers
              </span>
            </li>
          </ul>
        </div>
        {(roleHandler(role, "AONE_CRM.admin") ||
          roleHandler(role, "AONE_CRM_CUSTOMERS.admin")) && (
          <div className="col-md-5 text-right">
            <button
              className="blue_btn"
              title="Sync Customer"
              onClick={synccrmCustomer}
            >
              <span>Sync Customers</span>
            </button>
          </div>
        )}
      </div>
      <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_8">
          <div className="displayFlex justifyContent_end">
          <Row>
          <Col className="ml_5">
          <label className="fontSize12 mb_12 marginR10 mt_10">
              Account Number:
            </label>
                 <div className="displayFlex alignItemCenter position-relative" style={{ marginRight: "10px" }}>
              <input
                type="text"
                value={accountNum}
                className={
                  accountNum?.length > 0
                    ? "mainInput"
                    : "mainInput withSearchIcon"
                }
                onChange={(e) => setAccountNum(e.target.value)}
                style={
                  accountNum?.length > 0 ? { border: "2px solid #ec171c" } : {}
                }
              />
            {accountNum && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setAccountNum("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
          </div>
          </Col>
          <Col className="ml_5">
          <label className="fontSize12 mb_12 marginR10 mt_10">
              Name:
            </label>
            <div className="displayFlex alignItemCenter position-relative">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={
                  name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
                }
                style={name?.length > 0 ? { border: "2px solid #ec171c" } : {}}
              />
                {name && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setName("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
            </div>
            </Col>
            <Col className="ml_5">
          <label className="fontSize12 mb_12 marginR10 mt_10">
              Sales Person:
            </label>
            <div className="displayFlex alignItemCenter position-relative">
              <input
                type="text"
                value={salesPerson}
                onChange={(e) => setSalesPerson(e.target.value)}
                className={
                  salesPerson?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
                }
                style={salesPerson?.length > 0 ? { border: "2px solid #ec171c" } : {}}
              />
                {salesPerson && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setSalesPerson("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
            </div>
            </Col>
            </Row>
          </div>
          <div className="displayFlex alignItemCenter justifyContent_end mt_45">
            <button
              className="filterBtn plus_icon mleft_10"
              title="Export"
              onClick={exportcrmCustomer}
            >
              <span>
                <img src={downloadIcon} className="h18" />
              </span>
            </button>
            <button
              className="filterBtn plus_icon mleft_10"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
            {(roleHandler(role, "AONE_CRM.write") ||
              roleHandler(role, "AONE_CRM.admin") ||
              roleHandler(role, "AONE_CRM_CUSTOMERS.admin") ||
              roleHandler(role, "AONE_CRM_CUSTOMERS.write") ||
              roleHandler(role, "AONE_CONFIGS.write") ||
              roleHandler(role, "AONE_CONFIGS.admin") ||
              roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.admin") ||
              roleHandler(role, "AONE_CONFIGS_ADDRESS-MAPPINGS.write")) && (
              <button
                className="filterBtn plus_icon mleft_10"
                title="Add"
                onClick={() => navigate("/crm/customers/add")}
              >
                +
              </button>
            )}
          </div>
        </div>
      </div>
      <PaginationTable
        tableData={customerData}
        setHeader={setHeaderParams}
        tbl={"Crmcustomers"}
        modalHandler={modalHandler}
        title={"Crmcustomers"}
        setModalHandler={setModalHandler}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        newPage={newPage}
        isPush={isPush}
        setIsPush={setIsPush}
        setNewPage={setNewPage}
        size={size}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setSize={setSize}
        seleczone={seleczone}
        setselectZone={setselectZone}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        setIndividualCheckboxesBag={setIndividualCheckboxesBag}
        individualCheckboxesbag={individualCheckboxesbag}
        totalCount={totalCount}
        setSortField={setSortField}
        sortField={sortField}
        statusHandler={statusHandler}
        setStatusHandler={setStatusHandler}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
      />
      <OpenModal
        modalHandler={addModalHandler}
        setModalHandler={setAddModalHandler}
        title={"Add Customers"}
        editHandler={editHandler}
        setEditHandler={setEditHandler}
      />
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">Sync Customers</h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  From
                </label>
                <div className="customDateRange" style={{ color: "black", position: "relative" }}>
                  <DatePicker
                    selected={syncCus.values.fromDate}
                    onChange={(date) => syncCus.setFieldValue("fromDate", date)}
                    onBlur={syncCus.handleBlur}
                    placeholderText="From"
                    className="custom-date-picker"
                    showYearDropdown
                  />
                  {syncCus.touched.fromDate && syncCus.errors.fromDate ? (
                    <p className="requiredText" style={{ position: "absolute", top: "100%", left: 0, marginTop: "45px" }}>{syncCus.errors.fromDate}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  To
                </label>
                <div className="customDateRange" style={{ color: "black" , position: "relative"}}>
                  <DatePicker
                    selected={syncCus.values.toDate}
                    onChange={(date) => syncCus.setFieldValue("toDate", date)}
                    onBlur={syncCus.handleBlur}
                    placeholderText="To"
                    className="custom-date-picker"
                    showYearDropdown
                  />
                  {syncCus.touched.toDate && syncCus.errors.toDate ? (
                    <p className="requiredText" style={{ position: "absolute", top: "100%", left: 0 , marginTop: "45px"}}>{syncCus.errors.toDate}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_60">
            <div>
              <button
                type="button"
                className="blue_btn"
                onClick={syncCus.handleSubmit}
              >
                Sync
              </button>
            </div>
            <div>
              <button className="cancel_btn" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default Customers;
