import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//ledger
//get ledger
export const getLedger = async (params) => {
    try  {
        return await AoneService.get(`collections/api/v1/ledgers${params}`)
    } catch (error) {
        return error;
    }
}
//export ledger
export const exportLedger = async (params) => {
  try {
    return await AoneService.get(`collections/api/v1/ledgers/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//transactions
//get transactions
export const getTransactions = async (params) => {
    try  {
        return await AoneService.get(`collections/api/v1/transactions${params}`)
    } catch (error) {
        return error;
    }
}
//edit mode
export const editTransactionMode = async (params,params2) => {
  try  {
      return await AoneService.put(`collections/api/v1/transactions/${params}/mode/${params2}`)
  } catch (error) {
      return error;
  }
}
//download proof
export const downloadTransactionProof = async (params) => {
  try {
    return await AoneService.get(`collections/api/v1/transactions/${params}/download`,
    {
     responseType: "blob"
    }
  );
  } catch (error) {
    return error;
  }
};
//download settlement proof
export const downloadSettlementProof = async (params) => {
  try {
    return await AoneService.get(`collections/api/v1/settlements/${params}/download`,
    {
     responseType: "blob"
    }
  );
  } catch (error) {
    return error;
  }
};
//export transactions
export const exportTransactions = async (params) => {
  try {
    return await AoneService.get(`collections/api/v1/transactions/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//sum transaction amount
export const sumTransactions = async (params) => {
    try  {
        return await AoneService.get(`collections/api/v1/transactions/total${params}`)
    } catch (error) {
        return error;
    }
}
//settlement history
export const getSettlement = async (params) => {
    try  {
        return await AoneService.get(`collections/api/v1/settlements${params}`)
    } catch (error) {
        return error;
    }
}
//export ledger
export const exportSettlements = async (params) => {
  try {
    return await AoneService.get(`collections/api/v1/settlements/export${params}`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//collections
//get
export const getCollections = async (params) => {
    try  {
        return await AoneService.get(`collections/api/v1/collections${params}`)
    } catch (error) {
        return error;
    }
}
//post
export const postCollections = async (data) => {
    try  {
        return await AoneService.post(`collections/api/v1/collections`,data)
    } catch (error) {
        return error;
    }
}
//upload proof
export const uploadProof = async (data) => {
    try {
      return await AoneService.post(`collections/api/v1/collections/proof`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };
  export const sumCollections = async (params) => {
    try  {
        return await AoneService.get(`collections/api/v1/collections/total${params}`)
    } catch (error) {
        return error;
    }
}
//settlement
export const postSettlement = async (data) => {
    try  {
        return await AoneService.post(`collections/api/v1/settlements`,data)
    } catch (error) {
        return error;
    }
}
//export collection
export const exportCollections = async (params) => {
    try {
      return await AoneService.get(`collections/api/v1/collections/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
//count unsettlement collection
export const getUnsettledCollection = async (param,param2) => {
    try  {
        return await AoneService.get(`collections/api/v1/collections/${param}/total?currency=${param2}&settled=false`)
    } catch (error) {
        return error;
    }
}
  //lookups
  export const getCollectionMode = async () => {
    try {
      return await AoneService.get(`collections/api/v1/lookups/CollectionMode`);
    } catch (error) {
      return error;
    }
  };
  export const getSettlementStatus = async () => {
    try {
      return await AoneService.get(`collections/api/v1/lookups/SettlementStatus`);
    } catch (error) {
      return error;
    }
  };