import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import downloadicon from "../../../assets/img/download_icon.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import { useNavigate, useLocation } from "react-router";
import PaginationTable from "../../../components/Tables/paginationTable";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import templateIcon from "../../../assets/img/template_icon.svg";
import importIcon from "../../../assets/img/importIcon.svg";
import removeIcon from "../../../assets/img/cancelIcon.svg";
import fileDownload from "js-file-download";
import syncicon from "../../../../src/assets/img/sync.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useParams } from "react-router";
import CusModal from "../../../components/modal/CusModal";
import VehicleModal from "./VehicleModal";
import VehicleCategoryModal from "./VehicleCategoryModal";
import VehicleVendorModal from "./VehicleVendorModal";
import VehicleDocumentModal from "./VehicleDocumentModal";
import VehicleGpsModal from "./VehicleGpsModal";
import VehicleOdometerModal from "./VehicleOdometer";
import { UserContext } from "../../../helper/ProtectedRoute";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import { roleHandler } from "../../../helper/roleHandler";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Vehicles = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [editPage, setEditPage] = useState(false);
  const prevent = useRef(false);
  const params = useParams();
  const [state] = useContext(UserContext);
  const [title, setTitle] = useState("");
  const [isActivated, SetisActivated] = useState(false);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const getVehicles = usevehicleStore((state) => state?.getVehicles);
  const getReplacementVehicles = usevehicleStore(
    (state) => state?.getReplacementVehicles
  );
  const vehicleReplacementList = usevehicleStore(
    (state) => state?.vehicleReplacementList
  );
  const getGpsvendor = usevehicleStore((state) => state?.getGpsvendor);
  const getVehicleDocument = usevehicleStore(
    (state) => state?.getVehicleDocument
  );
  const getVehicleCategory = usevehicleStore(
    (state) => state?.getVehicleCategory
  );
  const getVehicleVendor = usevehicleStore((state) => state?.getVehicleVendor);
  const VehiclesTemplate = usevehicleStore((state) => state?.VehiclesTemplate);
  const GpsvendorTemplate = usevehicleStore(
    (state) => state?.GpsvendorTemplate
  );
  const VehicleCategoryTemplate = usevehicleStore(
    (state) => state?.VehicleCategoryTemplate
  );
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const VehicleVendorTemplate = usevehicleStore(
    (state) => state?.VehicleVendorTemplate
  );
  const exportVehicles = usevehicleStore((state) => state?.exportVehicles);
  const exportGpsvendor = usevehicleStore((state) => state?.exportGpsvendor);
  const getVehicleCategorybyCode = usevehicleStore(
    (state) => state?.getVehicleCategorybyCode
  );
  const getVehicleVendorbyCode = usevehicleStore(
    (state) => state?.getVehicleVendorbyCode
  );
  const getVehicleStatus = usevehicleStore((state) => state?.getVehicleStatus);
  const VehicleStatusDoclist = usevehicleStore(
    (state) => state?.VehicleStatusDoclist
  );
  const exportVehicleCategory = usevehicleStore(
    (state) => state?.exportVehicleCategory
  );
  const exportVehicleVendor = usevehicleStore(
    (state) => state?.exportVehicleVendor
  );
  const importVehicles = usevehicleStore((state) => state?.importVehicles);
  const importVehiclesDocuments = usevehicleStore(
    (state) => state?.importVehiclesDocuments
  );
  const importGpsvendor = usevehicleStore((state) => state?.importGpsvendor);
  const importVehicleCategory = usevehicleStore(
    (state) => state?.importVehicleCategory
  );
  const importVehicleVendor = usevehicleStore(
    (state) => state?.importVehicleVendor
  );
  const vehicleVendorList = usevehicleStore(
    (state) => state?.vehicleVendorList
  );
  const vehicleCategoryList = usevehicleStore(
    (state) => state?.vehicleCategoryList
  );
  const getDriverCourierList = usevehicleStore(
    (state) => state?.getDriverCourierList
  );
  const getOdoMeter = usevehicleStore((state) => state?.getOdoMeter);
  const vehicleGpsvendorList = usevehicleStore(
    (state) => state?.vehicleGpsvendorList
  );
  const hubsData = useAoneStore((state) => state?.hubsData);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const getHubs = useAoneStore((state) => state?.getHubs);
  const VehicleDimesionUnits = usevehicleStore(
    (state) => state?.VehicleDimesionUnits
  );
  const VehicleDocumentTypes = usevehicleStore(
    (state) => state?.VehicleDocumentTypes
  );
  const getVehiclesbyCode = usevehicleStore(
    (state) => state?.getVehiclesbyCode
  );
  const getVehiclesDocsbyCode = usevehicleStore(
    (state) => state?.getVehiclesDocsbyCode
  );
  const VehicleTypes = usevehicleStore((state) => state?.VehicleTypes);
  const VehicleWeightunit = usevehicleStore(
    (state) => state?.VehicleWeightunit
  );
  const vehicleList = usevehicleStore((state) => state?.vehicleList);
  const getDimensionUnit = usevehicleStore((state) => state?.getDimensionUnit);
  const getStatus = usevehicleStore((state) => state?.getStatus);
  const VehicleStatusList = usevehicleStore(
    (state) => state?.VehicleStatusList
  );
  const VehicleCouriersList = usevehicleStore(
    (state) => state?.VehicleCouriersList
  );
  const getVehicleDocumentType = usevehicleStore(
    (state) => state?.getVehicleDocumentType
  );
  const getVehicleType = usevehicleStore((state) => state?.getVehicleType);
  const getWeightUnit = usevehicleStore((state) => state?.getWeightUnit);
  const [code, setCode] = useState("");
  const [plateNumber, setPlateNumber] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [hub, setHub] = useState("");
  const [name, setName] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [country, setCountry] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  useEffect(() => {
    let paramslink = `?page=${0}&size=${30}`;
    if (hubsData?.data?.data?.content?.length < 1 || hubsData?.length < 1) {
      getHubs(paramslink);
    }
  }, []);
  useEffect(() => {
    let paramslink = `page=${0}&size=${100}`;
    if (
      VehicleCouriersList?.data?.data?.content?.length < 1 ||
      VehicleCouriersList?.length < 1
    ) {
      getDriverCourierList(true, paramslink);
    }
    if (
      vehicleReplacementList?.data?.data?.length < 1 ||
      vehicleReplacementList?.length < 1
    ) {
      getReplacementVehicles();
    }
  }, []);
  const handleEdit = (code) => {
    if (tab == 1) {
      setTitle("Edit Vehicle");
    } else if (tab == 2) {
      setTitle("Edit vehicle category");
    } else if (tab == 3) {
      setTitle("Edit vehicle vendor");
    } else if (tab == 4) {
      setTitle("Edit Gps vendor");
    } else if (tab == 5) {
      setTitle("Edit Vehicle Document");
    }
    setShowModal(true);
    getVehicleonEdit(code);
  };
  const [vehiclebyCode, setVehiclebyCode] = useState([]);
  const getVehicleonEdit = async (code) => {
    if (tab == 1) {
      const res = await getVehiclesbyCode(code);
      setVehiclebyCode(res?.data?.data);
    } else if (tab === 2) {
      const res = await getVehicleCategorybyCode(code);
      setVehiclebyCode(res?.data?.data);
    } else if (tab === 3) {
      const res = await getVehicleVendorbyCode(code);
      setVehiclebyCode(res?.data?.data);
    } else if (tab === 4) {
      setVehiclebyCode(code);
    } else if (tab === 5) {
      const res = await getVehiclesDocsbyCode(code);
      setVehiclebyCode(res?.data?.data);
    }
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    filterCountries(paramslink);
  }, []);
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const setReactRates = useAoneStore((state) => state?.setReactRates);
  const isReactRates = useAoneStore((state) => state?.isReactRates);
  const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
  const isReactselectCity = useAoneStore((state) => state?.isReactselectCity);
  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleError = (error) => {};
  const handleMenuRates = () => {
    setReactRates(true);
  };
  const handleMenuCloseRates = () => {
    setReactRates(false);
  };
  const setReactSelectcity = useAoneStore((state) => state?.setReactSelectcity);
  const setReactCharges = useAoneStore((state) => state?.setReactCharges);
  const isReactCharges = useAoneStore((state) => state?.isReactCharges);
  const handleMenuCharges = () => {
    setReactCharges(true);
  };
  const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
  const handleMenudesregion = () => {
    setReactDesregion(true);
  };

  const handleMenuClosedesregion = () => {
    setReactDesregion(false);
  };
  const handleMenuCloseCharges = () => {
    setReactCharges(false);
  };
  const handleMenuOpencity = () => {
    setReactSelectcity(true);
  };

  const handleMenuClosecity = () => {
    setReactSelectcity(false);
  };
  const clearStates = () => {
    setSortField("-updatedAt");
    setCode("");
    setPlateNumber("");
    setStatus("");
    setType("");
    setHub();
    setName("");
    setVehicle("");
    setCountry("");
  };
  useEffect(() => {
    if (VehicleDimesionUnits?.data?.data === undefined) {
      getDimensionUnit();
    }
    if (VehicleDocumentTypes?.data?.data === undefined) {
      getVehicleDocumentType();
    }
    if (VehicleStatusDoclist?.data?.data === undefined) {
      getVehicleStatus();
    }
    if (VehicleStatusList?.data?.data === undefined) {
      getStatus();
    }
    if (VehicleTypes?.data?.data === undefined) {
      getVehicleType();
    }
    if (VehicleWeightunit?.data?.data === undefined) {
      getWeightUnit();
    }
  }, []);
  const [vehiclesData, SetvehiclesData] = useState([]);
  const [vehicleCategoryData, SetVehicleCategoryData] = useState([]);
  const [vendorsData, SetvendorsData] = useState([]);
  const [vehicleDocumentData, SetvehicleDocumentData] = useState([]);
  const [gpsVendorData, SetGpsvendorsData] = useState([]);
  const [odometerData, SetOdometerData] = useState([]);
  const[isTab,setIsTab] = useState(false)
  const tabHandler = (i) => {
    prevent.current = false;
    setEditPage(false);
    setTab(i);
    setIsTab(true)
    navigate("/assets/vehicles");
  };
  const downloadTemplate = async () => {
    try {
      if (tab === 1) {
        const res = await VehiclesTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Vehicles Template.xlsx`);
        }
      } else if (tab === 2) {
        const res = await VehicleCategoryTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Vehicle Category Template.xlsx`);
        }
      } else if (tab === 3) {
        const res = await VehicleVendorTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Vendors Template.xlsx`);
        }
      } else if (tab === 4) {
        const res = await GpsvendorTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `GPS Vendor Template.xlsx`);
        }
      }
    } catch (err) {}
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (tab === 1) {
      if (code) {
        params += `&code=${code}`;
      }
      if (plateNumber) {
        params += `&plateNumber=$${plateNumber}$`;
      }
      if (status) {
        params += `&status=${status}`;
      }
      if (hub) {
        params += `&hubCode=${hub}`;
      }
      if (type) {
        params += `&type=${type}`;
      }
      const data = await getVehicles(params);
      setTotalCount(data?.data?.data?.totalElements);
      SetvehiclesData(data?.data?.data?.content);
    } else if (tab === 2) {
      if (name) {
        params += `&name=$${name}$`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      const data = await getVehicleCategory(params);
      setTotalCount(data?.data?.data?.totalElements);
      SetVehicleCategoryData(data?.data?.data?.content);
    } else if (tab === 3) {
      if (code) {
        params += `&code=${code}`;
      }
      if (name) {
        params += `&name=$${name}$`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      const data = await getVehicleVendor(params);
      setTotalCount(data?.data?.data?.totalElements);
      SetvendorsData(data?.data?.data?.content);
    } else if (tab === 4) {
      if (code) {
        params += `&code=${code}`;
      }
      if (name) {
        params += `&name=${name}`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      const data = await getGpsvendor(params);
      setTotalCount(data?.data?.data?.totalElements);
      SetGpsvendorsData(data?.data?.data?.content);
    } else if (tab === 5) {
      if (vehicle) {
        params += `&vehicleCode=${vehicle}`;
      }
      const data = await getVehicleDocument(params);
      setTotalCount(data?.data?.data?.totalElements);
      SetvehicleDocumentData(data?.data?.data?.content);
    } else {
      if (vehicle) {
        params += `&vehicleCode=${vehicle}`;
      }
      const data = await getOdoMeter(params);
      setTotalCount(data?.data?.data?.totalElements);
      SetOdometerData(data?.data?.data?.content);
    }
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (tab === 1) {
      if (code) {
        params += `&code=${code}`;
      }
      if (plateNumber) {
        params += `&plateNumber=${plateNumber}`;
      }
      if (status) {
        params += `&status=${status}`;
      }
      if (hub) {
        params += `&hubCode=${hub}`;
      }
      if (type) {
        params += `&type=${type}`;
      }
      try {
        const res = await exportVehicles(params);
        if (res.status == 200) {
          fileDownload(res.data, `Vehicles.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 2) {
      if (name) {
        params += `&name=${name}`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      try {
        const res = await exportVehicleCategory(params);
        if (res.status == 200) {
          fileDownload(res.data, `Vehicle Category.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 3) {
      if (code) {
        params += `&code=${code}`;
      }
      if (name) {
        params += `&name=${name}`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      try {
        const res = await exportVehicleVendor(params);
        if (res.status == 200) {
          fileDownload(res.data, `Vehicle vendors.xlsx`);
        }
      } catch (err) {}
    } else if (tab === 4) {
      if (code) {
        params += `&code=${code}`;
      }
      if (name) {
        params += `&name=${name}`;
      }
      if (country) {
        params += `&countryCode=${country}`;
      }
      try {
        const res = await exportGpsvendor(params);
        if (res.status == 200) {
          fileDownload(res.data, `Gps vendors.xlsx`);
        }
      } catch (err) {}
    } else {
    }
  };
  //bulk import
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);

          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            const localesColumnRange = getColumnRange(sheet, 3);
            let importFunction;
            switch (tab) {
              case 1:
                importFunction = importVehicles;
                break;
              case 2:
                importFunction = importVehicleCategory;
                break;
              case 3:
                importFunction = importVehicleVendor;
                break;
              case 4:
                importFunction = importGpsvendor;
                break;
              default:
                importFunction = importVehicles;
            }
            importFunction(formData)
              .then((response) => {
                if (response?.data?.status === "SUCCESS") {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                } else if (
                  response?.data?.status === "ERROR" &&
                  (response?.data?.data?.length == 0 ||
                    response?.data?.data?.length === undefined)
                ) {
                  // Handle error response from the server
                  const errorMessage = response?.data?.msg;
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.data?.length > 0) {
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (
                  response &&
                  response?.response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.data?.status === "ERROR") {
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.errorRow}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (
                  response.data.data.length > 0 &&
                  response?.data?.status === "ERROR"
                ) {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  getData();
                  toast.success("File uploaded successfully!");
                  fileInput.value = "";
                }
              })
              .catch((error) => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  // Helper function to check if the sheet has data
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  function getColumnRange(sheet, columnNumber) {
    const columnLetter = XLSX.utils.encode_col(columnNumber - 1);
    const range = sheet["!ref"].split(":");
    return range.map((cell) => sheet[columnLetter + cell.match(/[0-9]+/)[0]]);
  }
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [isedited, SetIsEdited] = useState(false);
  useEffect(() => {
    getData();
  }, [
    newPage,
    size,
    sortField,
    tab,
    code,
    plateNumber,
    status,
    ispostAdded,
    isActivated,
    isedited,
    type,
    hub,
    name,
    vehicle,
    country,
    editPage,
    deleteHandler,
  ]);
  useEffect(() => {
    if (location.pathname.endsWith("addresses")) {
      setEditPage(false);
    } else {
      setEditPage(true);
    }
  }, [location]);
//set tab based on roles
useEffect(() => {
  if(!isTab){
  if (
    roleHandler(role, "AONE_ASSETS.read") ||
    roleHandler(role, "AONE_ASSETS.write") ||
    roleHandler(role, "AONE_ASSETS.admin") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.read") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.write") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.admin")
  ) {
    setTab(1);
  } else if (
    roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.read") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.write") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.admin")
  ) {
    setTab(2);
  } else if (
    roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.read") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.write") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.admin")
  ) {
    setTab(3);
  } else if (
    roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.read") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.write") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin")
  ){
    setTab(4)
  } else if(
    roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.read") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.write") ||
    roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.admin")
  ) {
    setTab(5)
  } else {
    setTab(6)
  }
}
}, [role]);
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/assets/vehicles")}
              >
                Assets
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/assets/vehicles")}
              >
                Vehicles
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight:
                    editPage && params?.id === undefined ? "bolder" : "525",
                }}
                onClick={() => navigate("/assets/vehicles")}
              >
                {tab == 1
                  ? "Vehicles"
                  : tab == 2
                  ? "Vehicle Category"
                  : tab == 3
                  ? "Vendors"
                  : tab == 4
                  ? "GPS Vendors"
                  : tab == 5
                  ? "Vehicle Documents"
                  : "Vehicle Odometer"}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="displayFlex tabletab_section mb_12">
        {(roleHandler(role, "AONE_ASSETS.read") ||
          roleHandler(role, "AONE_ASSETS.write") ||
          roleHandler(role, "AONE_ASSETS.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.admin")) && (
          <button
            className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
            onClick={() => {
              tabHandler(1);
              clearStates();
            }}
          >
            Vehicles
          </button>
        )}
        {(roleHandler(role, "AONE_ASSETS.read") ||
          roleHandler(role, "AONE_ASSETS.write") ||
          roleHandler(role, "AONE_ASSETS.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.admin")) && (
          <button
            className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
            onClick={() => {
              tabHandler(2);
              clearStates();
            }}
          >
            Vehicle Category
          </button>
        )}
        {(roleHandler(role, "AONE_ASSETS.read") ||
          roleHandler(role, "AONE_ASSETS.write") ||
          roleHandler(role, "AONE_ASSETS.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.admin")) && (
          <button
            className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
            onClick={() => {
              tabHandler(3);
              clearStates();
            }}
          >
            Vendors
          </button>
        )}
        {(roleHandler(role, "AONE_ASSETS.read") ||
          roleHandler(role, "AONE_ASSETS.write") ||
          roleHandler(role, "AONE_ASSETS.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin")) && (
          <button
            className={`filterBtn ${tab == 4 && `activeFilterBtn`}`}
            onClick={() => {
              tabHandler(4);
              clearStates();
            }}
          >
            GPS Vendors
          </button>
        )}
        {(roleHandler(role, "AONE_ASSETS.read") ||
          roleHandler(role, "AONE_ASSETS.write") ||
          roleHandler(role, "AONE_ASSETS.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.admin")) && (
          <button
            className={`filterBtn ${tab == 5 && `activeFilterBtn`}`}
            onClick={() => {
              tabHandler(5);
              clearStates();
            }}
          >
            Vehicle Documents
          </button>
        )}
        {(roleHandler(role, "AONE_ASSETS.read") ||
          roleHandler(role, "AONE_ASSETS.write") ||
          roleHandler(role, "AONE_ASSETS.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.read") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.write") ||
          roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.admin")) && (
          <button
            className={`filterBtn ${tab == 6 && `activeFilterBtn`}`}
            onClick={() => {
              tabHandler(6);
              clearStates();
            }}
          >
            Odometer Audit
          </button>
        )}
      </div>

      <div>
        <div className="displayFlex alignItemCenter justifyContent_spacebetween">
          {tab === 1 ? (
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5" md={2}>
                  <label className="fontSize14 mb_12">Code:</label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={code}
                      className={
                        code?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setCode(e.target.value)}
                    />
                {code && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
                </Col>
                <Col className="ml_5" md={2}>
                  <label className="fontSize14 mb_12">Plate Number:</label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={plateNumber}
                      className={
                        plateNumber?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        plateNumber?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setPlateNumber(e.target.value)}
                    />
                  {plateNumber && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setPlateNumber("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
                </Col>
                <Col className="ml_5 marginR10" md={2}>
                  <label className="fontSize14 mb_12">Active:</label>
                  <SearchInput
                    id="status"
                    label="status"
                    name="status"
                    options={VehicleStatusList?.data?.data || []}
                    getOptionLabel={(city) => city.label}
                    getOptionValue={(city) => city.key}
                    handleChange={async (selectedOption) => {
                      setStatus(selectedOption?.key);
                    }}
                    onMenuOpen={handleMenudesregion}
                    onMenuClose={handleMenuClosedesregion}
                    customStyles={{ control: { width: "155px" } }}
                    menuIsOpen={isReactdesRegion}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Active"
                  />
                </Col>
                <Col className="ml_5 marginR10" md={2}>
                  <label className="fontSize14 mb_12">Type:</label>
                  <SearchInput
                    id="type"
                    label="type"
                    name="type"
                    options={VehicleTypes?.data?.data || []}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    handleChange={(selectedOption) => {
                      setType(selectedOption ? selectedOption.key : "");
                    }}
                    onMenuOpen={handleMenuOpencity}
                    onMenuClose={handleMenuClosecity}
                    customStyles={{ control: { width: "155px" } }}
                    menuIsOpen={isReactselectCity}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Type"
                    className={
                      type?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                  />
                </Col>
                <Col className="ml_5" md={2}>
                  <label className="fontSize14 mb_12">Hub:</label>
                  <SearchInput
                    id="hub"
                    label="hub"
                    name="hub"
                    options={hubsData?.data?.data?.content || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.code}
                    handleChange={(selectedOption) => {
                      setHub(selectedOption ? selectedOption.code : "");
                    }}
                    onMenuOpen={handleMenuRates}
                    onMenuClose={handleMenuCloseRates}
                    customStyles={{ control: { width: "155px" } }}
                    menuIsOpen={isReactRates}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Hub"
                    className={
                      hub?.length > 0 ? "mainInput" : "mainInput withSearchIcon"
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : tab === 2 ? (
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize14 mb_12 marginR10 mt_10">
                    Name:
                  </label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={name}
                      className={
                        name?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        name?.length > 0 ? { border: "2px solid #ec171c" } : {}
                      }
                      onChange={(e) => setName(e.target.value)}
                    />
                   {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
                </Col>
                <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">Country:</label>
                <SearchInput
                  id="country"
                  label="country"
                  name="country"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  handleChange={(selectedOption) => {
                    setCountry(selectedOption ? selectedOption.isoCode3 : "");
                  }}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  customStyles={{ control: { width: "195px" } }}
                  menuIsOpen={isReactRates}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Country"
                  className={
                    country?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
              </Row>
            </div>
          ) : tab == 3 ? (
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Code:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={code}
                    className={
                      code?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                    onChange={(e) => setCode(e.target.value)}
                  />
                 {code && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Name:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={name}
                    className={
                      name?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      name?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                    onChange={(e) => setName(e.target.value)}
                  />
                {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">Country:</label>
                <SearchInput
                  id="country"
                  label="country"
                  name="country"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  handleChange={(selectedOption) => {
                    setCountry(selectedOption ? selectedOption.isoCode3 : "");
                  }}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  customStyles={{ control: { width: "195px" } }}
                  menuIsOpen={isReactRates}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Country"
                  className={
                    country?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
            </Row>
          ) : tab == 4 ? (
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Code:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={code}
                    className={
                      code?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      code?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                    onChange={(e) => setCode(e.target.value)}
                  />
                  {code && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setCode("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">
                  Name:
                </label>
                <div className="displayFlex alignItemCenter position-relative">
                  <input
                    type="text"
                    value={name}
                    className={
                      name?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      name?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                    onChange={(e) => setName(e.target.value)}
                  />
                 {name && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setName("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
              </div>
              </Col>
              <Col className="ml_5">
                <label className="fontSize14 mb_12 marginR10 mt_10">Country:</label>
                <SearchInput
                  id="country"
                  label="country"
                  name="country"
                  options={countriesData?.data?.data?.content || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.isoCode3}
                  handleChange={(selectedOption) => {
                    setCountry(selectedOption ? selectedOption.isoCode3 : "");
                  }}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  customStyles={{ control: { width: "195px" } }}
                  menuIsOpen={isReactRates}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Country"
                  className={
                    country?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="ml_5">
                <label className="fontSize14 mb_12">Vehicle:</label>
                <SearchInput
                  id="vehicle"
                  label="vehicle"
                  name="vehicle"
                  options={vehicleList?.data?.data?.content || []}
                  getOptionLabel={(option) => option.code}
                  getOptionValue={(option) => option.code}
                  handleChange={(selectedOption) => {
                    setVehicle(selectedOption ? selectedOption.code : "");
                  }}
                  onMenuOpen={handleMenuRates}
                  onMenuClose={handleMenuCloseRates}
                  customStyles={{ control: { width: "165px" } }}
                  menuIsOpen={isReactRates}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Vehicle"
                  className={
                    vehicle?.length > 0
                      ? "mainInput"
                      : "mainInput withSearchIcon"
                  }
                />
              </Col>
            </Row>
          )}
        </div>
        <div className="displayFlex alignItemCenter justifyContent_end mb_12">
          <div className="displayFlex gap_12">
            {tab !== 5 && tab !== 6 && (
              <>
                {(roleHandler(role, "AONE_ASSETS.write") ||
                  roleHandler(role, "AONE_ASSETS.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.admin") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.write") ||
                  roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.admin") ||
                  roleHandler(
                    role,
                    "AONE_ASSETS_VEHICLES_GPS-VENDORS.write"
                  )) && (
                  <>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="filterBtn plus_icon mr_10 ml_10"
                      title={
                        tab === 1
                          ? "Vehicles Template"
                          : tab === 2
                          ? "Vehicle Category Template"
                          : tab === 3
                          ? "Vendors Template"
                          : "GPS Vendors Template"
                      }
                      onClick={downloadTemplate}
                    >
                      <span>
                        <img src={templateIcon} className="h18" />
                      </span>
                    </button>
                    <div
                      title={
                        tab === 1
                          ? "Import Vehicles"
                          : tab === 2
                          ? "Import Vehicle Category"
                          : tab === 3
                          ? "Import Vendors"
                          : "Import Vehicle Documents"
                      }
                      className="ml_10"
                    >
                      <label
                        className="filterBtn plus_icon"
                        htmlFor="uploadFile"
                      >
                        <span>
                          <img src={importIcon} className="h18" />
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          onInput={(e) => handleFileChange(e)}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          hidden
                        />
                      </label>
                    </div>
                  </>
                )}
              </>
            )}
            {tab !== 5 && tab !== 6 && (
              <button
                className="filterBtn"
                title={
                  tab === 1
                    ? "Export Vehicles"
                    : tab === 2
                    ? "Export Vehicle Category"
                    : tab === 3
                    ? "Export Vendors"
                    : "Export Vehicle Documents"
                }
                onClick={() => exportToExcel()}
              >
                <span>
                  <img src={downloadicon} className="h18" />
                </span>
              </button>
            )}
            <button
              className="filterBtn plus_icon"
              title="Sync"
              onClick={() => syncronizeRoles()}
            >
              <span>
                <img src={syncicon} className="h18" />
              </span>
            </button>
            {(roleHandler(role, "AONE_ASSETS.write") ||
              roleHandler(role, "AONE_ASSETS.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES.write") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.write") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_VEHICLES.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.write") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_CATEGORIES.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.write") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_VENDORS.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.write") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_DOCUMENTS.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.write") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_ODOMETER.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.admin") ||
              roleHandler(role, "AONE_ASSETS_VEHICLES_GPS-VENDORS.write")) && (
              <button
                className="filterBtn plus_icon"
                title="Add"
                tabIndex="-1"
                onClick={() => {
                  setShowModal(true);
                  setTitle(
                    tab == 1
                      ? "Add Vehicle"
                      : tab == 2
                      ? "Add Vehicle Category"
                      : tab == 3
                      ? "Add Vehicle Vendor"
                      : tab == 4
                      ? "Add Gps Vendor"
                      : tab == 5
                      ? "Add Vehicle Document"
                      : "Add Vehicle Odometer"
                  );
                }}
              >
                +
              </button>
            )}
          </div>
        </div>
        <PaginationTable
          tableData={
            tab == 1
              ? vehiclesData
              : tab === 2
              ? vehicleCategoryData
              : tab === 3
              ? vendorsData
              : tab === 4
              ? gpsVendorData
              : tab === 5
              ? vehicleDocumentData
              : odometerData
          }
          tbl={
            tab == 1
              ? "Vehicles"
              : tab === 2
              ? "Vehicle Category"
              : tab === 3
              ? "Vendors"
              : tab === 4
              ? "GpsVendors"
              : tab === 5
              ? "Vehicle Documents"
              : "Odometer"
          }
          modalHandler={editPage}
          setModalHandler={setEditPage}
          deleteHandler={deleteHandler}
          setDeleteHandler={setDeleteHandler}
          newPage={newPage}
          setNewPage={setNewPage}
          size={size}
          setSize={setSize}
          totalCount={totalCount}
          isActivated={isActivated}
          SetisActivated={SetisActivated}
          handleVehicleEdit={handleEdit}
          sortField={sortField}
          setSortField={setSortField}
          setIndividualCheckboxesBag={setIndividualCheckboxesBag}
          individualCheckboxesbag={individualCheckboxesbag}
        />
        <CusModal
          show={showModal}
          onHide={() => setShowModal(false)}
          className={
            tab == 1
              ? "addnewvehcilepop"
              : tab == 2
              ? "addVehicleCateg"
              : tab == 3
              ? "addVehicleCateg"
              : tab == 4
              ? "addVehicleCateg"
              : tab == 5
              ? "addVehicleDoc"
              : "addnnewWeighing"
          }
        >
          {tab == 1 ? (
            <VehicleModal
              onSuccess={handleSuccess}
              onError={handleError}
              showModal={showModal}
              setShowModal={setShowModal}
              title={title}
              vehiclebyCode={vehiclebyCode}
              isedited={isedited}
              SetIsEdited={SetIsEdited}
            />
          ) : tab == 2 ? (
            <VehicleCategoryModal
              showModal={showModal}
              onSuccess={handleSuccess}
              onError={handleError}
              setShowModal={setShowModal}
              title={title}
              vehiclebyCode={vehiclebyCode}
            />
          ) : tab == 3 ? (
            <VehicleVendorModal
              showModal={showModal}
              onSuccess={handleSuccess}
              onError={handleError}
              setShowModal={setShowModal}
              title={title}
              vehiclebyCode={vehiclebyCode}
            />
          ) : tab == 4 ? (
            <VehicleGpsModal
              showModal={showModal}
              onSuccess={handleSuccess}
              onError={handleError}
              setShowModal={setShowModal}
              title={title}
              vehiclebyCode={vehiclebyCode}
            />
          ) : tab == 5 ? (
            <VehicleDocumentModal
              onSuccess={handleSuccess}
              onError={handleError}
              setShowModal={setShowModal}
              title={title}
              vehiclebyCode={vehiclebyCode}
            />
          ) : (
            <VehicleOdometerModal
              showModal={showModal}
              onSuccess={handleSuccess}
              onError={handleError}
              setShowModal={setShowModal}
              title={title}
              vehiclebyCode={vehiclebyCode}
            />
          )}
        </CusModal>
      </div>
    </div>
  );
};

export default Vehicles;
