import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useAoneStore } from "../../store/AoneStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRatesStore } from "../../store/Rates/RateStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const VatModal = ({ onSuccess, onError, setShowModal, title, ratesbyCode }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postVat = useRatesStore((state) => state?.postVat);
  const editVat = useRatesStore((state) => state?.editVat);
  const postInsurance = useAoneStore((state) => state?.postInsurance);
  const editInsurance = useAoneStore((state) => state?.editInsurance);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const productsData = useAoneStore((state) => state?.productsData);
  const getOrigin = useAoneStore((state) => state?.getOrigin);
  const setOriginData = useAoneStore((state) => state?.setOriginData);
  const getDestination = useAoneStore((state) => state?.getDestination);
  const setDestinationData = useAoneStore((state) => state?.setDestinationData);
  const [destinationCountries, setDestinationCountries] = useState([]);
  const currentDate = new Date();
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const [originCountries, setOriginCounties] = useState([]);
  const [selectedOptionProduct, setSelectedOptionProduct] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const [data, setData] = useState("");
  const getOriginCountry = async (productCode) => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(productCode, paramslink);
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString + " UTC");
    const year = date.getUTCFullYear();
    const month = padZeroes(date.getUTCMonth() + 1);
    const day = padZeroes(date.getUTCDate());
    return `${year}-${month}-${day}`;
  }
  function padZeroes(value) {
    return value.toString().padStart(2, "0");
  }
  const addVat = useFormik({
    initialValues: {
      productCode: title === "Edit VAT Rate" ? data?.productCode : "",
      originCountryCode:
        title === "Edit VAT Rate" ? data?.originCountryCode : "",
      destinationCountryCode:
        title === "Edit VAT Rate" ? data?.destinationCountryCode : "",
      percent: title === "Edit VAT Rate" ? data?.percent : "",
      effectiveDate: title === "Edit VAT Rate" ? data?.effectiveDate : "",
      remark: title === "Edit VAT Rate" ? data?.remark : "",
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("Product is required"),
      originCountryCode: Yup.string().required("Origin Country is required"),
      destinationCountryCode: Yup.string().required(
        "Destination Country is required"
      ),
      percent: Yup.number()
        .required("Percent is required")
        .typeError("Percent must be a number")
        .min(0, "Percent must be greater than or equal to 0"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuestoSend = { ...values };
      const { state, ...formattedValues } = valuestoSend;
      formattedValues.effectiveDate = formattedValues.effectiveDate
        ? formatDate(formattedValues.effectiveDate)
        : null;
      if (title === "Edit VAT Rate") {
        await editVat(ratesbyCode?.id, formattedValues).then((response) => {
          if (response.status == 200 && response?.data?.status !== "ERROR") {
            toast.success("VAT Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setShowModal(false);
          } else if (response?.data?.status == "SUCCESS") {
            toast.success("VAT Rate Updated");
            resetForm();
            setispostAdded(!ispostAdded);
            setShowModal(false);
          } else if (response?.data?.errors?.length >= 1) {
            const errorMessages = response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.data?.errorCode !== "") {
            const errorMessage = response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errors?.length >= 1) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else if (response?.response?.data?.status === "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      } else {
        await postVat(formattedValues).then((response) => {
          if (response.status == "201") {
            toast.success("VAT Rate Added");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else if (response?.data?.status == "SUCCESS") {
            toast.success("VAT Rate Added");
            resetForm();
            setispostAdded(!ispostAdded);
            setShowModal(false);
          } else if (response?.data?.status == "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.key}: ${item.msg}`)
              .join(", ");
            toast.error(errorMessages);
            resetForm();
          } else {
            const errorMessage = response.response.data.msg.split(",")[0];
            toast.error(errorMessage);
            resetForm();
          }
        });
      }
    },
  });
  useEffect(() => {
    if (title == "Edit VAT Rate") {
      addVat.setFieldValue("productCode", ratesbyCode?.productCode);
      addVat.setFieldValue("originCountryCode", ratesbyCode?.originCountryCode);
      addVat.setFieldValue(
        "destinationCountryCode",
        ratesbyCode?.destinationCountryCode
      );
      addVat.setFieldValue("percent", ratesbyCode?.percent);
      addVat.setFieldValue("effectiveDate", ratesbyCode?.effectiveDate);
      addVat.setFieldValue("remark", ratesbyCode?.remark);
    }
  }, [title, ratesbyCode]);
  const getOriginCountryonEdit = async () => {
    let paramslink = `?page=${0}&size=${20}`;
    const data = await getOrigin(addVat.values.productCode, paramslink);
    const originContent = data?.data?.data?.content;
    if (originContent) {
      // Set origin data and filter unique countries
      await setOriginData(originContent);
      const uniqueCountries = originContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setOriginCounties(uniqueCountries);
    }
    // Fetch destination data
    const response = await getDestination(
      addVat.values.productCode,
      paramslink
    );
    const destinationContent = response?.data?.data?.content;
    if (destinationContent) {
      // Set destination data and filter unique countries
      await setDestinationData(destinationContent);
      const uniqueDesCountries = destinationContent.filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.countryCode === country.countryCode)
      );
      setDestinationCountries(uniqueDesCountries);
    }
  };
  useEffect(() => {
    if (title == "Edit VAT Rate" && addVat?.values?.productCode !== undefined) {
      getOriginCountryonEdit();
    }
  }, [addVat.values.productCode]);
  const [flatVatpercentError, setFlatVatPercentError] = useState("");
  const handleSaveVatRates = (initialValues) => {
    if (/\s/g.test(addVat.values.percent)) {
      setFlatVatPercentError(
        "Percent cannot contain leading, trailing, or consecutive spaces"
      );
    } else {
      setFlatVatPercentError("");
      addVat?.handleSubmit();
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVat">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Product{" "}
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="productCode"
                      label="productCode"
                      name="productCode"
                      options={productsData?.data?.data?.content || []}
                      getOptionLabel={(option) =>
                        `${option.code} - ${option.name}`
                      }
                      getOptionValue={(option) => option.code}
                      isClearable={true}
                      value={
                        addVat?.values?.productCode
                          ? productsData?.data?.data?.content?.find(
                              (prod) =>
                                prod.code === addVat?.values?.productCode
                            )
                          : null
                      }
                      handleChange={async (selectedOption) => {
                        setSelectedOptionProduct(selectedOption);
                        addVat.setFieldValue(
                          "productCode",
                          selectedOption ? selectedOption.code : ""
                        );
                        getOriginCountry(selectedOption?.code);
                      }}
                      isSearchable={true}
                      placeholder="Product"
                      customStyles={{ control: { width: "237px" } }}
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addVat.touched.productCode && addVat.errors.productCode ? (
                      <p className="requiredText">
                        {addVat.errors.productCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Origin Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="originCountryCode"
                      label="originCountryCode"
                      name="originCountryCode"
                      options={originCountries || []}
                      getOptionLabel={(option) => option.countryName}
                      getOptionValue={(option) => option.countryCode}
                      isClearable={true}
                      handleChange={async (selectedOption) => {
                        addVat.setFieldValue(
                          "originCountryCode",
                          selectedOption ? selectedOption.countryCode : ""
                        );
                      }}
                      value={
                        addVat?.values?.originCountryCode
                          ? originCountries?.find(
                              (country) =>
                                country.countryCode ===
                                addVat?.values?.originCountryCode
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Origin Country"
                      customStyles={{ control: { width: "237px" } }}
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addVat.touched.originCountryCode &&
                    addVat.errors.originCountryCode ? (
                      <p className="requiredText">
                        {addVat.errors.originCountryCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Percent{" "}
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="percent"
                      value={addVat.values.percent}
                      onChange={(e) => {
                        addVat.setFieldValue("percent", e.target.value);
                        setFlatVatPercentError("");
                      }}
                      placeholder="Percent"
                      className="mainInput"
                      onBlur={addVat.handleBlur}
                    />
                    {addVat.touched.percent &&
                    addVat.errors.percent &&
                    flatVatpercentError === "" ? (
                      <p className="requiredText">{addVat.errors.percent}</p>
                    ) : null}
                    {flatVatpercentError !== "" ? (
                      <p className="requiredText">{flatVatpercentError}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Destination Country
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    <SearchInput
                      id="destinationCountryCode"
                      label="destinationCountryCode"
                      name="destinationCountryCode"
                      options={destinationCountries || []}
                      getOptionLabel={(option) => option.countryName}
                      getOptionValue={(option) => option.countryCode}
                      isClearable={true}
                      handleChange={async (selectedOption) => {
                        addVat.setFieldValue(
                          "destinationCountryCode",
                          selectedOption ? selectedOption.countryCode : ""
                        );
                      }}
                      value={
                        addVat?.values?.destinationCountryCode
                          ? destinationCountries?.find(
                              (country) =>
                                country.countryCode ===
                                addVat?.values?.destinationCountryCode
                            )
                          : null
                      }
                      isSearchable={true}
                      placeholder="Destination Country"
                      customStyles={{ control: { width: "237px" } }}
                      className="mainInput"
                      style={{ whiteSpace: "nowrap" }}
                    />
                    {addVat.touched.destinationCountryCode &&
                    addVat.errors.destinationCountryCode ? (
                      <p className="requiredText">
                        {addVat.errors.destinationCountryCode}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt_20">
              <div className="col-md-12">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Effective Date
                    </label>
                    <div
                      className="customDateRange"
                      style={{ color: "black", position: "relative" }}
                    >
                      <DatePicker
                        placeholderText="Effective Date"
                        placement="rightStart"
                        selected={
                          addVat.values.effectiveDate
                            ? new Date(addVat.values.effectiveDate)
                            : null
                        }
                        onChange={(date) => {
                          addVat.setFieldValue("effectiveDate", date);
                        }}
                        onClean={() =>
                          addVat.setFieldValue("effectiveDate", null)
                        }
                        className="mainInput width243"
                        showYearDropdown
                        minDate={currentDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row marginTop40">
              <div className="col-md-12">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Remark
                    </label>
                    <textarea
                      className="washMe_btn"
                      placeholder="Remark"
                      name="remark"
                      onChange={(e) =>
                        addVat.setFieldValue("remark", e.target.value)
                      }
                      onBlur={addVat.handleBlur}
                      value={addVat.values.remark}
                      style={{ height: "90px", paddingTop: "6px" }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button
            className="blue_btn"
            type="button"
            onClick={handleSaveVatRates}
          >
            {title === "Create new VAT Rate" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default VatModal;
