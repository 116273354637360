import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import DeliveryTasks from "./DeliveryTasks";
import DeliveryValidation from "./DeliveryValidation";
import { roleHandler } from "../../../helper/roleHandler";
import { UserContext } from "../../../helper/ProtectedRoute";
import Debriefing from "./Debriefing";
import DebriefHistory from "./DebriefingHistory";
const LastmileView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
      };
      useEffect(() => {
        const sessionValidationId = sessionStorage.getItem("ValidationId");
        if (sessionValidationId) {
          setTab(2);
        }
      }, []);
      useEffect(() => {
        const sessionValidationId = sessionStorage.getItem("DebriefingCode");
        if (sessionValidationId) {
          setTab(3);
        }
      }, []);
      const [state] = useContext(UserContext);
      const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
      useEffect(() => {
        setRole(state.roles);
      }, [state.roles]);
      const hasRole = (role, roleList) => roleList.some(r => roleHandler(role, r));
      const roleListDeliveryTasks = [
        "AONE_OPS.read",
        "AONE_OPS.write",
        "AONE_OPS.admin",
        "AONE_OPS_LAST-MILE.write",
        "AONE_OPS_LAST-MILE.read",
        "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write",
        "AONE_OPS_LAST-MILE_DELIVERY-TASKS.read"
      ];
      
      const roleListDeliveryValidation = [
        "AONE_OPS.read",
        "AONE_OPS.write",
        "AONE_OPS.admin",
        "AONE_OPS_LAST-MILE.write",
        "AONE_OPS_LAST-MILE.read",
        "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.read",
        "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write"
      ];
      
      const roleListDebriefing = [
        "AONE_OPS.read",
        "AONE_OPS.write",
        "AONE_OPS.admin",
        "AONE_OPS_LAST-MILE.write",
        "AONE_OPS_LAST-MILE.read",
        "AONE_OPS_LAST-MILE_DEBRIEFING.read",
        "AONE_OPS_LAST-MILE_DEBRIEFING.write"
      ];
      
      const roleListDebriefingHistory = [
        "AONE_OPS.read",
        "AONE_OPS.write",
        "AONE_OPS.admin",
        "AONE_OPS_LAST-MILE.write",
        "AONE_OPS_LAST-MILE.read",
        "AONE_OPS_LAST-MILE_DEBRIEFING-HISTORY.read"
      ];
      
      useEffect(() => {
        if (!hasRole(role, roleListDeliveryTasks) && hasRole(role, roleListDeliveryValidation)) {
          setTab(2);
        } else if (!hasRole(role, roleListDeliveryTasks) && !hasRole(role, roleListDeliveryValidation) && hasRole(role, roleListDebriefing)) {
          setTab(3);
        } else if (!hasRole(role, roleListDeliveryTasks) && !hasRole(role, roleListDeliveryValidation) && !hasRole(role, roleListDebriefing) && hasRole(role, roleListDebriefingHistory)) {
          setTab(4);
        }
      }, [role]);
      
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
        <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => navigate("/ops/lastmile")}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => navigate("/ops/lastmile")}
              >
                Last Mile
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section">
      {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.read") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.write") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-TASKS.read")) && (
        <button
          className={`filterBtn ${tab == 1 &&  `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(1);
          }}
        >
          Delivery Tasks
        </button>)}
        {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.read") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.read") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DELIVERY-VALIDATION.write")) && (
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(2);
          }}
        >
          Delivery Validation
        </button>)}
        {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.read") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING.read") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING.write")) && (
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(3);
          }}
        >
          Debriefing
        </button>)}
        {(roleHandler(role, "AONE_OPS.read") ||
                  roleHandler(role, "AONE_OPS.write") ||
                  roleHandler(role, "AONE_OPS.admin") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.write") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE.read") ||
                  roleHandler(role, "AONE_OPS_LAST-MILE_DEBRIEFING-HISTORY.read")) && (
        <button
          className={`filterBtn ${tab == 4 && `activeFilterBtn`}`}
          onClick={() => {
              tabHandler(4);
          }}
        >
         Debriefing History
        </button>)}
      </div>
      <div>
         { (tab == 1 ? (
            <DeliveryTasks edit={setEditPage} setTab={setTab}/>
         )  :tab == 2 ? (
          <DeliveryValidation edit={setEditPage} setTab={setTab}/>
         ) : tab == 3? (
          <Debriefing edit={setEditPage} setTab={setTab}/>
          ) :
            <DebriefHistory edit={setEditPage} setTab={setTab}/>)}
      </div>
    </div>
  )
}

export default LastmileView;