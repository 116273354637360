import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//VAT
//get VAT
export const getVat = async (params) => {
    try {
      return await AoneService.get("masters/api/v1/vat" + params);
    } catch (error) {
      return error;
    }
  };
  //GET VAT by id
  export const getVatbyId = async (params) => {
    try {
      return await AoneService.get("masters/api/v1/vat/" + params);
    } catch (error) {
      return error;
    }
  };
  //post VAT
  export const postVat = async (data) => {
    try {
      return await AoneService.post("masters/api/v1/vat", data);
    } catch (error) {
      return error;
    }
  };
  //edit VAT
  export const editVat = async (params, data) => {
    try {
      return await AoneService.put(
        `masters/api/v1/vat/${params}`,
        data
      );
    } catch (error) {
      return error;
    }
  };
  //state update
  export const editVatState = async (params, data) => {
    try {
      return await AoneService.patch(
        `masters/api/v1/vat/state/${params}`,
        data
      );
    } catch (error) {
      return error;
    }
  };
  //delete VAT
  export const deleteVat = async (params) => {
    try {
      return await AoneService.delete("masters/api/v1/vat/" + params);
    } catch (error) {
      return error;
    }
  };
  export const bulkVatDelete = async (data) => {
    try {
      return await AoneService.post("masters/api/v1/vat/delete", data);
    } catch (error) {
      return error;
    }
  };
  //exportVAT
  export const exportrateVat = async (params) => {
    try {
      return await AoneService.get(
        "masters/api/v1/vat/export" + params,
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };
  //VAT template
  export const getVatTemplate = async () => {
    try {
      return await AoneService.get(
        "masters/api/v1/vat/template/download",
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };
  //VAT import
  export const importVat = async (data) => {
    try {
      return await AoneService.post(`masters/api/v1/vat/import`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };

  //GPA
//get GPA
export const getGpa = async (params) => {
    try {
      return await AoneService.get("masters/api/v1/gpa" + params);
    } catch (error) {
      return error;
    }
  };
  //GET GPA by id
  export const getGpabyId = async (params) => {
    try {
      return await AoneService.get("masters/api/v1/gpa/" + params);
    } catch (error) {
      return error;
    }
  };
  //post GPA
  export const postGpa = async (data) => {
    try {
      return await AoneService.post("masters/api/v1/gpa", data);
    } catch (error) {
      return error;
    }
  };
  //edit GPA
  export const editGpa = async (params, data) => {
    try {
      return await AoneService.put(
        `masters/api/v1/gpa/${params}`,
        data
      );
    } catch (error) {
      return error;
    }
  };
  //patch GPA
  export const editGpaState = async (params, data) => {
    try {
      return await AoneService.patch(
        `masters/api/v1/gpa/state/${params}`,
        data
      );
    } catch (error) {
      return error;
    }
  };
  //delete GPA
  export const deleteGpa = async (params) => {
    try {
      return await AoneService.delete("masters/api/v1/gpa/" + params);
    } catch (error) {
      return error;
    }
  };
  export const bulkGpaDelete = async (data) => {
    try {
      return await AoneService.post("masters/api/v1/gpa/delete", data);
    } catch (error) {
      return error;
    }
  };
  //export GPA
  export const exportrateGpa = async (params) => {
    try {
      return await AoneService.get(
        "masters/api/v1/gpa/export" + params,
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };
  //GPA template
  export const getGpaTemplate = async () => {
    try {
      return await AoneService.get(
        "masters/api/v1/gpa/template/download",
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      return error;
    }
  };
  //GPA import
  export const importGpa = async (data) => {
    try {
      return await AoneService.post(`masters/api/v1/gpa/import`, data, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      return error;
    }
  };