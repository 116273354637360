import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//get delivery flow
export const getDeliveryFlow = async (params) => {
    try  {
        return await AoneService.get(`ops/api/v1/delivery/flow${params}`)
    } catch (error) {
        return error;
    }
}
//post delivery flow
export const postDeliveryFlow = async (data) => {
    try {
      return await AoneService.post(`ops/api/v1/delivery/flow`,data);
    } catch (error) {
      return error;
    }
  };

//get deliveryflow by id
export const getDeliveryflowbyId = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/delivery/flow/${params}`);
    } catch (error) {
      return error;
    }
  };

  //edit deliveryflow
  export const editDeliveryflowbyId = async (params,data) => {
    try {
      return await AoneService.put(`ops/api/v1/delivery/flow/${params}`,data);
    } catch (error) {
      return error;
    }
  };

  //delete deliveryflow
  export const deleteDeliveryflowbyId = async (params) => {
    try {
      return await AoneService.delete(`ops/api/v1/delivery/flow/${params}`);
    } catch (error) {
      return error;
    }
  };