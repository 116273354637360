import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import { useTripStore } from "../../../store/TripMgt/TripStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const VehicleModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  vehicleTrip,
  TripId,
  setVehicleTrip
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const editTripVehicle = useTripStore((state) => state?.editTripVehicle);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const getVehicles = usevehicleStore((state) => state?.getVehicles);
  const [selectedOptionVehicle, setSelectedOptionVehicle] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const editVehicle = async () =>{
    const response = await editTripVehicle(TripId, vehicleTrip);
    if (response?.data?.status == "SUCCESS") {
      toast.success("Vehicle updated for the trip");
      setispostAdded(!ispostAdded);
      setShowModal(false);
    }else if(response?.data?.status === "ERROR") {
      const errorMessage = response.data.msg;
      toast.error(errorMessage);
      setShowModal(false);
    } else if (response?.response?.data?.errors?.length > 0) {
      const errorMessages = response?.response?.data?.errors?.map(
        (item) => `${item?.key} ${item?.msg}`
      );
      toast.error(errorMessages[0]);
      setShowModal(false);
    } else if (response?.response?.data?.errorCode == 400) {
      const errorMessages = response?.response?.data?.errors
        .map((item) => `${item.msg}`)
        .join(", ");
      toast.error(errorMessages[0]);
      setShowModal(false);
    } else if (response.status == 200) {
      toast.success("Trip updated");
      setispostAdded(!ispostAdded);
      setShowModal(false);
    } else {
      const errorMessage = response.response.data.msg;
      toast.error(errorMessage);
      setShowModal(false);
    }
  }
  const [showVehicle, setShowVehicle] = useState(title !== "Edit Vehicle");
  const handleInputVehicleClick = () => {
    if (title === "Edit Vehicle") {
      setShowVehicle(true);
    }
  };
  const [vehiclePage, setVehiclePage] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsVehicle = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : vehiclePage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setVehiclePage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20&status=ACTIVE`;
        if (search) {
          endpoint += `&plateNumber=$${search}$`;
        }
        const response = await getVehicles(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((driver) => ({
            label: driver.plateNumber,
            value: driver.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, vehiclePage, prevSearch, setPrevSearch]
  );
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleVendors">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
            <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Trip
                    </label>
                <input
                  type="text"
                  value={TripId}
                  name="TripId"
                  className="mainInput"
                  disabled
                />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Vehicle
                    </label>
                    {showVehicle ? (
                    <AsyncSearchInput
                  id="vehicle"
                  label="vehicle"
                  name="vehicle"
                  value={selectedOptionVehicle}
                  loadMoreOptions={loadMoreOptionsVehicle}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onChange={(selectedOptionVehicle) => {
                    setSelectedOptionVehicle(selectedOptionVehicle);
                    setVehicleTrip(selectedOptionVehicle
                        ? selectedOptionVehicle.value
                        : "");
                  }}
                  placeholder="Vehicle"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              ) : (
                <input
                  type="text"
                  value={vehicleTrip}
                  name="vehicleCode"
                  className="mainInput"
                  onClick={handleInputVehicleClick}
                />
              )}
                  </div>
                </div>
              </div>
              </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={editVehicle}>
          {title === "Create new trip" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default VehicleModal;
