import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    useContext,
  } from "react";
  import { useNavigate } from "react-router";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import importIcon from "../../../assets/img/importIcon.svg";
  import Dropdown from "react-bootstrap/Dropdown";
  import DotboldIcon from "../../../../src/assets/img/dotsbold.svg";
  import syncicon from "../../../../src/assets/img/sync.svg";
  import removeIcon from "../../../assets/img/cancelIcon.svg";
  import { toast } from "react-toastify";
  import { useAoneStore } from "../../../store/AoneStore";
  import downloadicon from "../../../assets/img/download_icon.svg";
  import fileDownload from "js-file-download";
  import PaginationTable from "../../../components/Tables/paginationTable";
  import { useShortAddressStore } from "../../../store/ShortAddress/ShortAddressStore";
  import * as XLSX from "xlsx";
  import jwtDecode from "jwt-decode";
  import { roleHandler } from "../../../helper/roleHandler";
  import { UserContext } from "../../../helper/ProtectedRoute";
  import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
  import CusModal from "../../../components/modal/CusModal";
  import ShortAddressModal from "./AddressMapModal";
  import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
  const ShortAddressMapping = () => {
    const ref = useRef();
    const navigate = useNavigate();
    const prevent = useRef(false);
    const buttonRef = useRef(null);
    const [editPage, setEditPage] = useState(false);
    const [newPage, setNewPage] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [originCityPage, setOriginCitypage] = useState(-1);
    const [splCityPage, setCitypage] = useState(-1);
    const [districtPage, setDistrictPage] = useState(-1);
    const [spldistrictPage, setSplDistrictPage] = useState(-1);
    const ispostAdded = useAoneStore((state) => state?.ispostAdded);
    const saveHandler = useAoneStore((state) => state?.saveHandler);
    const [splRegion, setSplRegion] = useState("");
    const [splGovernorate, setSplGovernorate] = useState("");
    const [splCity, setSplCity] = useState("");
    const [splDistrict, setSplDistrict] = useState("");
    const [region, setRegion] = useState("");
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [mapped, setMapped] = useState("");
    const [size, setSize] = useState(20);
    const [paramCountry,setParamsCountry] = useState("SAU");
    const [totalCount, setTotalCount] = useState(0);
    const [deleteHandler, setDeleteHandler] = useState(false);
    const [editHandler, setEditHandler] = useState(false);
    const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
    const [shortAddressData, setShortAddressData] = useState([]);
    const [modalHandler, setModalHandler] = useState(false);
    const [addModalHandler, setAddModalHandler] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [column, SetCoulmn] = useState([
      "SPL Region",
      "SPL Governorate",
      "SPL City",
      "SPL District",
      "Region",
      "City",
      "District",
      "Created at",
      "Created by",
      "Updated at",
      "Updated by",
      "Actions",
    ]);
    const [checkedCol, setCheckedCol] = useState([
     "Created at",
     "Created by",
     "Updated at",
     "Updated by",
    ]);
    const handleSelect = (d) => {
      let val = checkedCol.includes(d);
      if (val) {
        setCheckedCol(checkedCol.filter((dat) => dat !== d));
      } else {
        setCheckedCol([...checkedCol, d]);
      }
    };
    const [username, setUsername] = useState("");
    // Decode the token using jwtDecode
    useEffect(() => {
      const token = sessionStorage.getItem("user_access_token");
      // Decode the token using jwtDecode
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const { name } = decodedToken;
          setUsername(name);
        } catch (error) {
          console.error("Error decoding token:", error.message);
        }
      } else {
        console.error("Token not found in session storage");
      }
    }, []);
    const [sortField, setSortField] = useState("-updatedAt");
    const [state] = useContext(UserContext);
    const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
    useEffect(() => {
      setRole(state.roles);
    }, [state.roles]);
    const getShortAddress = useShortAddressStore((state) => state?.getShortAddress);
    const getShortAddressbyId = useShortAddressStore((state) => state?.getShortAddressbyId);
    const importShortAddress = useShortAddressStore((state) => state?.importShortAddress);
    const exportShortAddress = useShortAddressStore((state) => state?.exportShortAddress);
    const regionsData = useAoneStore((state) => state?.regionsData);
    const getRegions = useAoneStore((state) => state?.getRegions);
    const getCities = useAoneStore((state) => state?.getCities);
    const getDistricts = useAoneStore((state) => state?.getDistricts);
    const [selectAll, setSelectAll] = useState(false);
    const [selectOrders, setSelectOrders] = useState([]);
    const getConsignmentOrdersTemplate = useAoneStore(
      (state) => state?.getConsignmentOrdersTemplate
    );
    const setReactDescity = useAoneStore((state) => state?.setReactDescity);
    const setReactDesregion = useAoneStore((state) => state?.setReactDesregion);
    const setReactRates = useAoneStore((state) => state?.setReactRates);
    const setReactCharges = useAoneStore((state) => state?.setReactCharges);
    const setReactCurrency = useAoneStore((state) => state?.setReactCurrency);
    const isReactdesRegion = useAoneStore((state) => state?.isReactdesRegion);
    const isReactCharges = useAoneStore((state) => state?.isReactCharges);
    const isReactCurrency = useAoneStore((state) => state?.isReactCurrency);
    const isReactRates = useAoneStore((state) => state?.isReactRates);
    const isReactdesCity = useAoneStore((state) => state?.isReactdesCity);
    const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
      const bottomBorder = (scrollHeight - clientHeight) / 2;
      // Add a flag to prevent multiple consecutive updates
      return !loadingMore && bottomBorder < scrollTop;
    };
    const [showModal, setShowModal] = useState(false);
    const handleSuccess = () => {
      setShowModal(false);
    };
    const handleError = (error) => {};
    const [addressbyCode, setAddressbyCode] = useState([]);
    const [selectedOptionCity, setSelectedOptionCity] = useState(null);
    const [selectedOptionDesCity, setSelectedOptionDesCity] = useState(null);
    const [selectedOptionDistrict, setSelectedOptionDistrict] = useState(null);
    const [selectedOptionSplDistrict, setSelectedOptionSplDistrict] = useState(null);
    const [prevSearch, setPrevSearch] = useState("");
    const handleEdit = (id) => {
      setShowModal(true);
      getAddressonEdit(id);
    };
    const getAddressonEdit = async (id) => {
      const res = await getShortAddressbyId(paramCountry,id);
      setAddressbyCode(res?.data?.data);
    };
    const loadMoreCityOptions = useCallback(
      async (search) => {
        if (!loadingMore) {
          setLoadingMore(true);
          // Initialize the page number based on whether there's a search or not
          let nextPage = search ? 0 : originCityPage + 1;
          if (prevSearch !== search) {
            nextPage = 0; // Reset page to 0 when search term changes
            setPrevSearch(search); // Update previous search term
          }
          if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
          setOriginCitypage(nextPage);
          // Construct the endpoint URL
          let endpoint = `?page=${nextPage}&size=20`;
          if (search) {
            let hashsearch = `$${search}$`;
            endpoint += `&name=${hashsearch}`;
          }
          const response = await getCities(endpoint);
          const options = response.data.data.content || [];
          setLoadingMore(false);
          return {
            options: options.map((option) => ({
              label: `${option.name}`,
              value: option.code,
            })),
            hasMore: options.length > 0,
          };
        }
  
        return {
          options: [],
          hasMore: false,
        };
      },
      [loadingMore, originCityPage, prevSearch, setPrevSearch]
    );
    const [prevdesSearch, setPrevDesSearch] = useState("");
    const loadMoreDesCityOptions = useCallback(
      async (search) => {
        if (!loadingMore) {
          setLoadingMore(true);
          // Initialize the page number based on whether there's a search or not
          let nextPage = search ? 0 : splCityPage + 1;
          if (prevdesSearch !== search) {
            nextPage = 0; // Reset page to 0 when search term changes
            setPrevDesSearch(search); // Update previous search term
          }
          if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
          setCitypage(nextPage);
          // Construct the endpoint URL
          let endpoint = `?page=${nextPage}&size=20&countryIsoCode3=${paramCountry}`;
          if (search) {
            let hashsearch = `$${search}$`;
            endpoint += `&name=${hashsearch}`;
          }
          const response = await getCities(endpoint);
          const options = response.data.data.content || [];
          setLoadingMore(false);
          return {
            options: options.map((option) => ({
              label: `${option.name}`,
              value: option.code,
            })),
            hasMore: options.length > 0,
          };
        }
  
        return {
          options: [],
          hasMore: false,
        };
      },
      [loadingMore, splCityPage, setPrevDesSearch, prevdesSearch]
    );
    const loadMoreSplDistrictsOptions = useCallback(
      async (search) => {
        if (!loadingMore) {
          setLoadingMore(true);
          // Initialize the page number based on whether there's a search or not
          let nextPage = search ? 0 : spldistrictPage + 1;
          if (prevdesSearch !== search) {
            nextPage = 0; // Reset page to 0 when search term changes
            setPrevDesSearch(search); // Update previous search term
          }
          if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
          setSplDistrictPage(nextPage);
          // Construct the endpoint URL
          let endpoint = `?page=${nextPage}&size=20`;
          if (search) {
            let hashsearch = `$${search}$`;
            endpoint += `&name=${hashsearch}`;
          }
          const response = await getDistricts(endpoint);
          const options = response.data.data.content || [];
          setLoadingMore(false);
          return {
            options: options.map((option) => ({
              label: `${option.name}`,
              value: option.code,
            })),
            hasMore: options.length > 0,
          };
        }
  
        return {
          options: [],
          hasMore: false,
        };
      },
      [loadingMore, spldistrictPage, setPrevDesSearch, prevdesSearch]
    );
    const loadMoreDistrictsOptions = useCallback(
      async (search) => {
        if (!loadingMore) {
          setLoadingMore(true);
          // Initialize the page number based on whether there's a search or not
          let nextPage = search ? 0 : districtPage + 1;
          if (prevdesSearch !== search) {
            nextPage = 0; // Reset page to 0 when search term changes
            setPrevDesSearch(search); // Update previous search term
          }
          if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
          setDistrictPage(nextPage);
          // Construct the endpoint URL
          let endpoint = `?page=${nextPage}&size=20&countryIsoCode3=${paramCountry}`;
          if (search) {
            let hashsearch = `$${search}$`;
            endpoint += `&name=${hashsearch}`;
          }
          const response = await getDistricts(endpoint);
          const options = response.data.data.content || [];
          setLoadingMore(false);
          return {
            options: options.map((option) => ({
              label: `${option.name}`,
              value: option.code,
            })),
            hasMore: options.length > 0,
          };
        }
  
        return {
          options: [],
          hasMore: false,
        };
      },
      [loadingMore, districtPage, setPrevDesSearch, prevdesSearch]
    );
    const exportToExcel = async () => {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=cityName&fields=regionName&fields=districtName`;
      if (splRegion) {
        params += `&splRegionName=${splRegion}`;
      }
      if (splGovernorate) {
        params += `&splGovernorate=${splGovernorate}`;
      }
      if (splCity) {
        params += `&splCityName=${splCity}`;
      }
      if (splDistrict) {
        params += `&splDistrictName=${splDistrict}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      if (city) {
        params += `&cityCode=${city}`;
      }
      if (district) {
        params += `&districtCode=${district}`;
      }
      if (mapped) {
        params += `&isMapped=${mapped}`;
      }
        try {
          const res = await exportShortAddress(paramCountry,params);
          if (res.status == 200) {
            fileDownload(res.data, `Shortaddress Mapping.xlsx`);
          }
        } catch (err) {}
  }
    const downloadTemplate = async () => {
      try {
        const res = await getConsignmentOrdersTemplate();
        if (res.status == 200) {
          fileDownload(res.data, `Mappings Template.xlsx`);
        }
      } catch (err) {}
    };
  
    const handleFileChange = async (event) => {
      const fileInput = event.target;
      const files = fileInput.files;
  
      if (!files || files.length === 0) {
        return; // No file selected, handle accordingly
      }
  
      // Prepare to read the file
      const file = files[0];
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          let formData = new FormData();
          formData.append("file", file);
  
          // Collect data for all sheets
          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
              const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              formData.append(sheetName, JSON.stringify(sheetData));
            }
          });
          importShortAddress(paramCountry,formData)
            .then((response) => {
              if (response && response?.response?.data?.status === "ERROR") {
                // Handle error response from the server
                const errorMessage = response?.response?.data?.msg?.split(",")[0];
                toast.error(errorMessage);
                fileInput.value = "";
              } else if (response?.data?.status === "ERROR") {
                toast.error(response.data.msg);
                fileInput.value = "";
              } else if (response?.response?.status === "ERROR") {
                toast.error(response.response.statusText);
                fileInput.value = "";
              } else if (response?.response?.status == "500") {
                toast.error(response.response.statusText);
                fileInput.value = "";
              } else if (response && response?.data?.status === "ERROR") {
                // Handle error response from the server
                const errorMessages = response?.data?.data
                  .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                  .join(", ");
                toast.error(`Error uploading the file: ${errorMessages}`);
                const errorMessage = response?.response?.data?.msg?.split(",")[0];
                toast.error(errorMessage);
                fileInput.value = "";
              } else if (response?.response?.data?.status === "ERROR") {
                const errorMessage = response?.response?.data?.msg?.split("!")[0];
                toast.error(errorMessage);
                fileInput.value = "";
              } else if (response?.data?.data?.length > 0) {
                const errorMessages = response?.data?.data
                  .map(
                    (item) =>
                      `Sheet ${item?.errorSheetNumber + 1}: row ${
                        item?.errorRow
                      } ${item.errorMsg}`
                  )
                  .join(", ");
                toast.error(errorMessages);
                fileInput.value = "";
              } else if (response?.data?.status == "IMPORT_ERROR") {
                toast.error(response?.data?.status);
                fileInput.value = "";
              } else {
                // API call was successful
                getData();
                // Show success toast here
                toast.success("File uploaded successfully!");
                fileInput.value = "";
              }
            })
            .catch(() => {
              toast.error("Error reading the file. Please try again.");
              fileInput.value = "";
            });
        } catch (error) {
          // Handle any errors during file reading
          toast.error("Error reading the file. Please try again.");
          fileInput.value = "";
        }
      };
      // Read the file as array buffer
      reader.readAsArrayBuffer(file);
    };
    function hasDataInSheet(sheet) {
      return Object.keys(sheet).some(
        (cell) => cell !== "!ref" && sheet[cell].v !== undefined
      );
    }
    const syncronizeRoles = async () => {
      setNewPage(0);
      getData();
    };
  
    const getData = async () => {
      let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=cityName&fields=regionName&fields=districtName`;
      if (splRegion) {
        params += `&splRegionName=${splRegion}`;
      }
      if (splGovernorate) {
        params += `&splGovernorate=${splGovernorate}`;
      }
      if (splCity) {
        params += `&splCityName=${splCity}`;
      }
      if (splDistrict) {
        params += `&splDistrictName=${splDistrict}`;
      }
      if (region) {
        params += `&regionCode=${region}`;
      }
      if (city) {
        params += `&cityCode=${city}`;
      }
      if (district) {
        params += `&districtCode=${district}`;
      }
      if (mapped) {
        params += `&isMapped=${mapped}`;
      }
      const data = await getShortAddress(paramCountry,params);
      setTotalCount(data?.data?.data?.totalElements);
      setShortAddressData(data.data.data.content);
    };
    useEffect(() => {
      getData();
    }, [
      newPage,
      size,
      editPage,
      sortField,
      saveHandler,
      ispostAdded,
      splRegion,
      splGovernorate,
      splCity,
      splDistrict,
      region,
      city,
      district,
      mapped,
    ]);
    useEffect(() => {
      let paramslink = `?page=${0}&size=${20}&countryIsoCode3=${paramCountry}`;
      if (
        regionsData?.data?.data?.content?.length < 1 ||
        regionsData?.data?.data?.content?.length == undefined
      ) {
        getRegions(paramslink);
      }
    }, []);
    const handleMenudescity = () => {
      setReactDescity(true);
    };
  
    const handleMenuClosedescity = () => {
      setReactDescity(false);
    };
    const handleMenudesregion = () => {
      setReactDesregion(true);
    };
  
    const handleMenuClosedesregion = () => {
      setReactDesregion(false);
    };
    const handleMenuRates = () => {
      setReactRates(true);
    };
  
    const handleMenuCloseRates = () => {
      setReactRates(false);
    };
    const handleMenuCharges = () => {
      setReactCharges(true);
    };
  
    const handleMenuCloseCharges = () => {
      setReactCharges(false);
    };
    const handleMenuCurrency = () => {
      setReactCurrency(true);
    };
  
    const handleMenuCloseCurrency = () => {
      setReactCurrency(false);
    };
    return (
      <div>
        <div>
          {!editPage && (
            <>
                <Row>
                  <Col  className="col-20-percent" md={2}>
                    <label className="fontSize12 mb_8 marginR10 ws_nowrap">
                      SPL Region:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                      <input
                        type="text"
                        value={splRegion}
                        className={splRegion?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"}
                        style={splRegion?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}}
                        onChange={(e) => setSplRegion(e.target.value)} />
                           {splRegion && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setSplRegion("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col  className="col-20-percent" md={2}>
                    <label className="fontSize12 mb_8 marginR10 ws_nowrap">
                    SPL Governorate:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                      <input
                        type="text"
                        value={splGovernorate}
                        className={splGovernorate?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"}
                        style={splGovernorate?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}}
                        onChange={(e) => setSplGovernorate(e.target.value)} />
                         {splGovernorate && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setSplGovernorate("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                    </div>
                  </Col>
                  <Col  className="col-20-percent" md={2}>
                    <label className="fontSize12 mb_8 marginR10">
                      SPL City:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                    <input
                        type="text"
                        value={splCity}
                        className={splCity?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"}
                        style={splCity?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}}
                        onChange={(e) => setSplCity(e.target.value)} />
                         {splCity && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setSplCity("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                </div>
                  </Col>
                  <Col  className="col-20-percent" md={2}>
                    <label className="fontSize12 mb_8 marginR10">
                      SPL District:
                    </label>
                    <div className="displayFlex alignItemCenter position-relative">
                    <input
                        type="text"
                        value={splDistrict}
                        className={splDistrict?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"}
                        style={splDistrict?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}}
                        onChange={(e) => setSplDistrict(e.target.value)} />
                         {splDistrict && (
                  <button
                    className="cancel_btn clear-btn"
                    type="button"
                    onClick={() => setSplDistrict("")}
                  >
                    <span>
                      <img src={removeIcon} alt="Clear" />
                    </span>
                  </button>
                )}
                </div>
                  </Col>
                  <Col  className="col-20-percent" md={2}>
                    <label className="fontSize12 mb_8 marginR10">
                      Region:
                    </label>
                    <SearchInput
                      id="region"
                      label="region"
                      name="region"
                      options={regionsData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      handleChange={(selectedOption) => {
                        setRegion(
                          selectedOption ? selectedOption.code : ""
                        );
                      } }
                      onMenuOpen={handleMenudescity}
                      onMenuClose={handleMenuClosedescity}
                      menuIsOpen={isReactdesCity}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Region"
                      className={region?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"} />
                  </Col>
                  <Col  className="col-20-percent" md={2}>
                    <label className="fontSize12 mb_8 marginR10">
                      City:
                    </label>
                    <AsyncSearchInput
                      id="city"
                      label="city"
                      name="city"
                      value={selectedOptionDesCity}
                      loadMoreOptions={loadMoreDesCityOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(zone) => zone.label}
                      getOptionValue={(zone) => zone.value}
                      onMenuOpen={handleMenuCharges}
                      onMenuClose={handleMenuCloseCharges}
                      menuIsOpen={isReactCharges}
                      onChange={(selectedOptionDesCity) => {
                        setSelectedOptionDesCity(selectedOptionDesCity);
                        if (selectedOptionDesCity) {
                            setCity(selectedOptionDesCity.value);
                        } else {
                            setCity(null);
                        }
                      } }
                      placeholder="City" />
                  </Col>
                  <Col  className="col-20-percent" md={2}>
                    <label className="fontSize12 mb_8 marginR10">
                      District:
                    </label>
                    <AsyncSearchInput
                      id="district"
                      label="district"
                      name="district"
                      value={selectedOptionDistrict}
                      loadMoreOptions={loadMoreDistrictsOptions}
                      shouldLoadMore={shouldLoadMore}
                      getOptionLabel={(district) => district.label}
                      getOptionValue={(district) => district.value}
                      onMenuOpen={handleMenuCurrency}
                      onMenuClose={handleMenuCloseCurrency}
                      menuIsOpen={isReactCurrency}
                      onChange={(selectedOptionDistrict) => {
                        setSelectedOptionDistrict(selectedOptionDistrict);
                        if (selectedOptionDistrict) {
                            setDistrict(selectedOptionDistrict.value);
                        } else {
                            setDistrict(null);
                        }
                      } }
                      placeholder="District" />
                  </Col>
                  <Col  className="col-20-percent" md={2}>
                  <label className="fontSize14">Mapped:</label>
                  <div className="col-md-8">
                    <div className="smallCheckbox">
                      <input
                        type="checkbox"
                        id="mapped"
                        checked={mapped}
                        style={{ marginLeft: "5px" }}
                        onChange={() => setMapped(!mapped)}
                      />
                      <label className="margintop7" htmlFor="mapped"></label>
                    </div>
                  </div>
                  </Col>
                </Row>
            <div className="displayFlex alignItemCenter justifyContent_end mb_8">
                  <div className="displayFlex gap_12">
                  <button
                  className="filterBtn"
                  title={"Export Mappings"}
                  onClick={() => exportToExcel()}
                >
                  <span>
                    <img src={downloadicon} className="h18" />
                  </span>
                </button>
                    {(roleHandler(role, "AONE_CONFIGS.write") ||
                      roleHandler(role, "AONE_CONFIGS.admin") ||
                      roleHandler(role, "AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.write") ||
                      roleHandler(role, "AONE_CONFIGS_SHORT-ADDRESS_MAPPINGS.admin")
                    ) && (
                        <>
                          {/* <button
                            className="filterBtn plus_icon"
                            title={"Download Mapping template"}
                            onClick={downloadTemplate}
                          >
                            <span>
                              <img src={templateIcon} className="h18" />
                            </span>
                          </button> */}
                          <div title={"Import Mapping template"}>
                            <label
                              className="filterBtn plus_icon"
                              htmlFor="uploadFile"
                            >
                              <span>
                                <img src={importIcon} className="h18" />
                              </span>
                              <input
                                type="file"
                                id="uploadFile"
                                onInput={(e) => handleFileChange(e)}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                hidden />
                            </label>
                          </div>
                        </>
                      )}
                    <button
                      className="filterBtn plus_icon"
                      title="Sync"
                      onClick={() => syncronizeRoles()}
                    >
                      <span>
                        <img src={syncicon} className="h18" />
                      </span>
                    </button>
                    <div ref={ref} className="customFilter">
                      <Dropdown autoClose="outside">
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={DotboldIcon} />
                        </Dropdown.Toggle>
  
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <ul className="listColumn cursorPointer">
                              {column.map((d, i) => (
                                <li key={d}>
                                  <div className="smallCheckbox mb_4">
                                    <input
                                      id={`product${i}`}
                                      type="checkbox"
                                      checked={!checkedCol.includes(d)} />
                                    <label
                                      htmlFor={`product${i}`}
                                      onClick={() => handleSelect(d)}
                                    >
                                      {d}
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
              </div></>
          )}
          {!editPage && (
            <PaginationTable
              tableData={shortAddressData}
              restrict={checkedCol}
              tbl={"ShortAddressMapping"}
              modalHandler={modalHandler}
              setModalHandler={setModalHandler}
              deleteHandler={deleteHandler}
              setDeleteHandler={setDeleteHandler}
              newPage={newPage}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              setSelectOrders={setSelectOrders}
              selectOrders={selectOrders}
              setSelectAll={setSelectAll}
              handleShortEdit={handleEdit}
              selectAll={selectAll}
              getAddressonEdit={getAddressonEdit} 
              setIndividualCheckboxesBag={setIndividualCheckboxesBag}
              individualCheckboxesbag={individualCheckboxesbag}
              setNewPage={setNewPage}
              size={size}
              setSize={setSize}
              totalCount={totalCount}
              sortField={sortField}
              setSortField={setSortField}
            />
          )}
            <CusModal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="addVehicleDoc"
      >
        <ShortAddressModal
          onSuccess={handleSuccess}
          onError={handleError}
          setShowModal={setShowModal}
          addressbyCode={addressbyCode}
          paramCountry={paramCountry}
        />
      </CusModal>
        </div>
      </div>
    );
  };
  
  export default ShortAddressMapping;
  