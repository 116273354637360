import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getWeights,postWeight, getWeightbyId,weightTemplate,
    importWeight,getWeightTool,getWeightUnit,getDimensionUnit} from "../../services/WeighingMgt/Weighing_services";
const initialValues = {
  weightToolList: [],
  weightUnitList: [],
  dimensionUnitList: []
};
let weightStore = (set, get) => ({
  ...initialValues,
  getWeights: async (params) => {
        const response = await getWeights(params);
        return response;
      },
      postWeight: async (data) => {
        const response = await postWeight(data);
        return response;
      },
      getWeightbyId: async (data) => { 
        const response = await getWeightbyId(data);
        return response;
      },
      weightTemplate: async (params) => {
        const response = await weightTemplate(params);
        return response;
      },
      importWeight: async (params) => {
        const response = await importWeight(params);
        return response;
      },
      getWeightTool: async () => {
        const response = await getWeightTool();
        set({ weightToolList: response });
        return response;
      },
      getWeightUnit: async () => {
        const response = await getWeightUnit();
        set({ weightUnitList: response });
        return response;
      },
      getDimensionUnit: async () => {
        const response = await getDimensionUnit();
        set({ dimensionUnitList: response });
        return response;
      },
});

weightStore = devtools(weightStore);

export const useweightStore = create(weightStore);