import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import AddnewSla from "./bodyContent/Addsla";
import AddSlabtn from "./footerContent/AddSlabtn";
import { async } from "q";

const CusModal = ({
  children,
  title,
  btnName,
  setStateData,
  initialData,
  submit,
  cancleChange,
  handleDelete,
  handleImage,
  downloadblob
}) => {
  const [show, setShow] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
 

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setEnableEdit(false);
  };

  const handleCancel = () => {
    handleClose();
    cancleChange();
    setEnableEdit(false);
  };

  const handleInputChange = (key, e, id) => {
    const newName = e.target.value;
    if (title === "Add New SLA") {
      setStateData(key, newName);
    } else {
      setStateData(key, newName, id);
      // setStateData(initialData)
    }
  };

  const onSub = (e) => {
    submit(e, initialData.id);
    handleClose();
  };

  const handleDeleteModal = (id) => {
    handleDelete(id);
  };


  const bulUpload = async(e)=>{
    const res = await submit(e)
    if(res==200){
      setStateData(null)
      handleClose();
    }
  }


  return (
    <>
      {btnName == "" ? (
        <tr onClick={handleShow}>{children}</tr>
      ) : (
        <div>
          <button onClick={handleShow} className="filterBtn">
            {btnName}
          </button>
        </div>
      )}
      <>
        {title === "Bulk Upload" ? (
          <>
            <Modal
              show={show}
              onHide={handleClose}
              // className="addnewpopup"
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
              </Modal.Header>
              {/* <div className="modelScrollTwo"> */}
              <Modal.Body>
                <div className="mb_12"> 
                  <button className="blue_btn" onClick={downloadblob}>Download Sample</button>
                </div>

                <div className="mb_24">

                  <div className="displayFlex alignItemCenter gap_12">
                  <label className="filterBtn displayInlineFlex alignItemCenter cursorPointer" htmlFor="uploadFile">
                  Upload
                <input type="file" id="uploadFile" onChange={handleImage} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
                </label>
               {initialData && <div className="fileItem displayInlineFlex alignItemCenter gap_12">{initialData?.name}<span onClick={()=>setStateData(null)}>X</span></div>}
                  </div>
                
                  
                </div>

                <div className="displayFlex alignItemCenter gap_16 justifyContent_center">
                  <div>
                    <button className="cancel_btn" onClick={handleClose}>
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className="blue_btn" onClick={bulUpload}>Save</button>
                  </div>
                </div>
              </Modal.Body>
              {/* </div> */}
            </Modal>
          </>
        ) : (
          <>
            <Modal
              show={show}
              onHide={handleClose}
              className="addnewpopup"
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
              </Modal.Header>
              <div className="modelScroll">
                <Modal.Body>
                  <div>
                    {title === "Add New SLA" || title === "View SLA" ? (
                      <AddnewSla
                        initialData={initialData}
                        handleInputChange={handleInputChange}
                        onSub={onSub}
                        handleClose={handleClose}
                        handleCancel={handleCancel}
                        title={title}
                        enableEdit={enableEdit}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Modal.Body>
              </div>
              <Modal.Footer>
                {title === "Add New SLA" || title === "View SLA" ? (
                  <AddSlabtn
                    title={title}
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                    onSub={onSub}
                    setEnableEdit={setEnableEdit}
                    initialData={initialData}
                    handleDelete={handleDeleteModal}
                  />
                ) : (
                  <></>
                )}
              </Modal.Footer>
            </Modal>
          </>
        )}
      </>
    </>
  );
};

export default CusModal;
