import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
import { useAoneStore } from "../../../store/AoneStore";
import { usevehicleStore } from "../../../store/VehicleMgt/VehicleStore";
import { useTripStore } from "../../../store/TripMgt/TripStore";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const TripModal = ({
  onSuccess,
  onError,
  setShowModal,
  title,
  tripbyCode,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSuccess(); // Assuming the form submission is successful
  };
  const postTrips = useTripStore((state) => state?.postTrips);
  const editDrivers = useTripStore((state) => state?.editDrivers);
  const setispostAdded = useAoneStore((state) => state?.setispostAdded);
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const getHubs = useAoneStore((state) => state?.getHubs);
  const getVehicles = usevehicleStore((state) => state?.getVehicles);
  const [selectedHubOption, setSelectedHubOption] = useState(null);
  const [selectedDesHubOption, setSelectedDesHubOption] = useState(null);
  const [selectedOptionVehicle, setSelectedOptionVehicle] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const addTrip = useFormik({
    initialValues: {
        originHubCode: "",
        destinationHubCode: "",
        vehicleCode: "",
    },
    validationSchema: Yup.object({
        originHubCode: Yup.string().trim().required("Origin Hub is required"),
        destinationHubCode: Yup.string().required("Destination Hub is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { vehicleCode, ...hubs } = values; 
      if (title === "Edit Trip") {
        await editDrivers(tripbyCode?.tripId, hubs ).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("Trip updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          }else if(response?.data?.status === "ERROR") {
            const errorMessage = response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errors?.length > 0) {
            const errorMessages = response?.response?.data?.errors?.map(
              (item) => `${item?.key} ${item?.msg}`
            );
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response?.response?.data?.errorCode == 400) {
            const errorMessages = response?.response?.data?.errors
              .map((item) => `${item.msg}`)
              .join(", ");
            toast.error(errorMessages[0]);
            resetForm();
            setShowModal(false);
          } else if (response.status == 200) {
            toast.success("Trip updated");
            setispostAdded(!ispostAdded);
            resetForm();
            setShowModal(false);
          } else {
            const errorMessage = response.response.data.msg;
            toast.error(errorMessage);
            resetForm();
            setShowModal(false);
          }
        });
      } else {
      await postTrips(values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Trip Added");
          setispostAdded(!ispostAdded);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.errors?.length > 0) {
          const errorMessages = response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else if (response?.data?.status == "ERROR") {
          const errorMessage = response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        } else if (response?.response?.data?.errors?.length > 0) {
          const errorMessages = response?.response?.data?.errors?.map(
            (item) => `${item?.key} ${item?.msg}`
          );
          toast.error(errorMessages[0]);
          resetForm();
          setShowModal(false);
        } else {
          const errorMessage = response?.response?.data?.msg;
          toast.error(errorMessage);
          resetForm();
          setShowModal(false);
        }
      });
    }
    },
  });
  useEffect(() => {
    addTrip.setFieldValue("originHubCode", tripbyCode?.originHubCode);
    addTrip.setFieldValue("destinationHubCode", tripbyCode?.destinationHubCode);
    addTrip.setFieldValue("vehicleCode", tripbyCode?.vehicleCode);
}, [title, tripbyCode]);
useEffect(() => {
  if(title == "Create new trip"){
    addTrip.setFieldValue("originHubCode", "");
    addTrip.setFieldValue("destinationHubCode", "");
    addTrip.setFieldValue("vehicleCode", "");
  }
}, [title, tripbyCode]);
  const [showOriginHub, setShowOriginHub] = useState(title !== "Edit Trip");
  const [showDesHub, setShowDesHub] = useState(title !== "Edit Trip");
  const [showVehicle, setShowVehicle] = useState(title !== "Edit Trip");
  const handleInputOriginHubClick = () => {
    if (title === "Edit Trip") {
      setShowOriginHub(true);
    }
  };
  const handleInputDesHubClick = () => {
    if (title === "Edit Trip") {
      setShowDesHub(true);
    }
  };
  const handleInputVehicleClick = () => {
    if (title === "Edit Trip") {
      setShowVehicle(true);
    }
  };
  const [page, setPage] = useState(-1);
  const [desPage, setDesPage] = useState(-1);
  const [vehiclePage, setVehiclePage] = useState(-1);
  const [prevSearch, setPrevSearch] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  const loadMoreOptionsGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await getHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsDesGroups = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : desPage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setDesPage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20`;
        if (search) {
          endpoint += `&name=$${search}$`;
        }
        const response = await getHubs(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((hub) => ({
            label: hub.name,
            value: hub.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, desPage, prevSearch, setPrevSearch]
  );
  const loadMoreOptionsVehicle = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : vehiclePage + 1;
        if (prevSearch !== search) {
          nextPage = 0; // Reset page to 0 when search term changes
          setPrevSearch(search); // Update previous search term
        }
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setVehiclePage(nextPage);
        // Construct the endpoint URL
        let endpoint = `?page=${nextPage}&size=20&status=ACTIVE`;
        if (search) {
          endpoint += `&plateNumber=$${search}$`;
        }
        const response = await getVehicles(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((driver) => ({
            label: driver.plateNumber,
            value: driver.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, vehiclePage, prevSearch, setPrevSearch]
  );
  const saveTrip = (initialValues) => {
      addTrip?.handleSubmit();
  };
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">{title}</h4>
      </Modal.Header>
      <div className="modelScrollVehicleVendors">
        <Modal.Body>
          <form>
            <div className="row margin-top-10">
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Origin Hub
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    {showOriginHub ? (
                          <AsyncSearchInput
                            id="originHubCode"
                            label="originHubCode"
                            name="originHubCode"
                            value={selectedHubOption}
                            loadMoreOptions={loadMoreOptionsGroups}
                            shouldLoadMore={shouldLoadMore}
                            getOptionLabel={(zone) => zone.label}
                            getOptionValue={(zone) => zone.value}
                            onChange={(selectedHubOption) => {
                              setSelectedHubOption(selectedHubOption);
                              addTrip.setFieldValue(
                                "originHubCode",
                                selectedHubOption ? selectedHubOption.value : ""
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                            placeholder="Origin Hub"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuStyle={{ zIndex: 9999 }}
                          />
                        ) : (
                          <input
                            type="text"
                            value={tripbyCode?.originHubName}
                            name="originHubName"
                            className="mainInput"
                            onClick={handleInputOriginHubClick}
                          />
                        )}
                  </div>
                  {addTrip.touched.originHubCode &&
                  addTrip.errors.originHubCode ? (
                    <p className="requiredText">
                      {addTrip.errors.originHubCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Destination Hub
                      <span className="bloodDonortext fontWeight600 fontSize16">
                        *
                      </span>
                    </label>
                    {showDesHub ? (
                          <AsyncSearchInput
                            id="destinationHubCode"
                            label="destinationHubCode"
                            name="destinationHubCode"
                            value={selectedDesHubOption}
                            loadMoreOptions={loadMoreOptionsDesGroups}
                            shouldLoadMore={shouldLoadMore}
                            getOptionLabel={(zone) => zone.label}
                            getOptionValue={(zone) => zone.value}
                            onChange={(selectedDesHubOption) => {
                              setSelectedDesHubOption(selectedDesHubOption);
                              addTrip.setFieldValue(
                                "destinationHubCode",
                                selectedDesHubOption
                                  ? selectedDesHubOption.value
                                  : ""
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                            placeholder="Destination Hub"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuStyle={{ zIndex: 9999 }}
                          />
                        ) : (
                          <input
                            type="text"
                            value={tripbyCode?.destinationHubName}
                            name="destinationHubName"
                            className="mainInput"
                            onClick={handleInputDesHubClick}
                          />
                        )}
                  </div>
                  {addTrip.touched.destinationHubCode &&
                  addTrip.errors.destinationHubCode ? (
                    <p className="requiredText">
                      {addTrip.errors.destinationHubCode}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt_20">
            <div className="col-md-6">
                <div className="row alignItemCenter mb_12">
                  <div className="col-md-12">
                    <label className="fontSize14 mb_12 menacingCloudsText fontWeight500">
                      Vehicle
                    </label>
                    {showVehicle ? (
                    <AsyncSearchInput
                  id="vehicle"
                  label="vehicle"
                  name="vehicle"
                  value={selectedOptionVehicle}
                  loadMoreOptions={loadMoreOptionsVehicle}
                  shouldLoadMore={shouldLoadMore}
                  getOptionLabel={(zone) => zone.label}
                  getOptionValue={(zone) => zone.value}
                  onChange={(selectedOptionVehicle) => {
                    setSelectedOptionVehicle(selectedOptionVehicle);
                    addTrip.setFieldValue(
                      "vehicleCode",
                      selectedOptionVehicle
                        ? selectedOptionVehicle.value
                        : ""
                    );
                  }}
                  placeholder="Vehicle"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuStyle={{ zIndex: 9999 }}
                />
              ) : (
                <input
                  type="text"
                  value={tripbyCode?.vehicleCode}
                  name="vehicleCode"
                  className="mainInput"
                  disabled
                  onClick={handleInputVehicleClick}
                />
              )}
                  </div>
                </div>
              </div>
              </div>
          </form>
        </Modal.Body>
      </div>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="displayFlex alignItemCenter justifyContent_end gap_16">
          <button className="blue_btn" type="button" onClick={saveTrip}>
          {title === "Create new trip" ? "Create" : "Update"}
          </button>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default TripModal;
