import React, { useState, useEffect, useRef, useContext } from "react";
import PaginationTable from "../../../components/Tables/paginationTable";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAoneStore } from "../../../store/AoneStore";
import { usepickupStore } from "../../../store/PickupRequest/pickuprequestStore";
import breadArrow from "../../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../../../src/assets/img/dotsbold.svg";
import removeIcon from "../../../../src/assets/img/cancelIcon.svg";
import syncicon from "../../../../src/assets/img/sync.svg";
import { UserContext } from "../../../helper/ProtectedRoute";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import { roleHandler } from "../../../helper/roleHandler";
const ConsignmentsPickup = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const paramsId = useParams();
  const prevent = useRef(false);
  const [tab, setTab] = useState(1);
  const [newPage, setNewPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("createdAt");
  const [pickupData, setPickupData] = useState([]);
  const [editPage, setEditPage] = useState(false);
  const [modalHandler, setModalHandler] = useState(false); // For Edit Pop Up
  const [addModalHandler, setAddModalHandler] = useState(false); // For Add Pop Up
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [statusHandler, setStatusHandler] = useState(false);
  //filters
  const [trackingId, setTrackingId] = useState("");
  const [referenceNum, setRefereceNum] = useState("");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [consignmentData, setConsignmentData] = useState([]);
  const setisPickupRequest = usepickupStore(
    (state) => state?.setisPickupRequest
  );
  const getPickupConsignments = usepickupStore(
    (state) => state?.getPickupConsignments
  );
  const [seleczone, setselectZone] = useState([]);
  const [selecwaybill, setSelectWaybill] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const postPickupConsignment = usepickupStore(
    (state) => state?.postPickupConsignment
  );
  const importPickupRequests = usepickupStore(
    (state) => state?.importPickupRequests
  );
  const gridId = "ops.pickupRequest.consignments";
  //customizable columns
  const [column, SetCoulmn] = useState([
    "Tracking Id",
    "Customer Account",
    "Product",
    "Packages Count",
    "RPU",
    "Status",
    "Content",
    "Reason",
    "Weight Unit",
    "Weight",
    "Updated at",
    "Updated by",
    "Actions",
  ]);
  const [checkedCol, setCheckedCol] = useState([
    "Reason",
    "Updated at",
    "Updated by",
  ]);
  useEffect(() => {
    // Retrieve the stored checked columns from sessionStorage for this grid ID
    const savedGrid = sessionStorage.getItem(gridId);
    if (savedGrid) {
      const parsedData = JSON.parse(savedGrid);
      if (parsedData.columns) {
        setCheckedCol(parsedData.columns.split(','));
      }
    }
  }, [gridId]);
  useEffect(() => {
    // Save the checked columns in sessionStorage with the grid ID
    const gridData = {
      grid_id: gridId,
      columns: checkedCol.join(',')
    };
    sessionStorage.setItem(gridId, JSON.stringify(gridData));
  }, [checkedCol, gridId]);
  const handleSelect = (d) => {
    let val = checkedCol.includes(d);
    if (val) {
      setCheckedCol(checkedCol.filter((dat) => dat !== d));
    } else {
      setCheckedCol([...checkedCol, d]);
    }
  };
  const [importError,setImportError] = useState([])
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setShow(false);
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=customerName&fields=packagesCount`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (referenceNum) {
      params += `&referenceNumber=${referenceNum}`;
    }
    const data = await getPickupConsignments(paramsId?.id, params);
    setTotalCount(data.data.data.totalElements);
    setConsignmentData(data.data.data.content);
  };
  const addConsignment = useFormik({
    initialValues: {
      consignments: [],
    },
    validationSchema: Yup.object({
      consignments: Yup.array()
        .of(Yup.string().required("Consignment is required"))
        .min(1, "At least one consignment is required,upload the consignments"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await postPickupConsignment(paramsId?.id, values).then((response) => {
        if (response?.data?.status == "SUCCESS") {
          toast.success("Consignment Added");
          getData();
          setShowConsignments(false);
          resetForm();
        } else if (response && response?.data?.status === "IMPORT_ERROR") {
            setImportError(response?.data?.errors)
            setShowConsignments(false);
            handleShow();
        } else if (response?.data?.status === "ERROR") {
          setShowConsignments(false);
          const errorMessage = response.data.msg;
          toast.error(errorMessage);
          resetForm();
        } else {
          setShowConsignments(false);
          const errorMessage = response.response.data.msg;
          toast.error(errorMessage);
          resetForm();
        }
      });
    },
  });
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  useEffect(() => {
    getData();
  }, [newPage, sortBy, size, sortField, saveHandler, trackingId, referenceNum]);
  const tabHandler = (i) => {
    navigate("/ops/pickups");
    prevent.current = false;
    setEditPage(false);
    setTab(i);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [showConsignments, setShowConsignments] = useState(false);
  const handleShowConsignments = () => setShowConsignments(true);
  const handleCloseConsignments = () => {
    setShowConsignments(false);
  };
  const addConsignments = () => {
    setShowConsignments(true);
  };
  function hasDataInSheet(sheet) {
    return Object.keys(sheet).some(
      (cell) => cell !== "!ref" && sheet[cell].v !== undefined
    );
  }
  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Read the file using FileReader
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assume the first sheet is the one you're interested in
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Check if the sheet has data
        if (sheet && sheet["!ref"] && hasDataInSheet(sheet)) {
          let formData = new FormData();
          formData.append("file", file);
          // Check if the sheet has at least one row of data
          if (sheet["!ref"].split(":")[1].match(/\d+/)[0] > 1) {
            // Get the range of the Locales Name column (assuming it's the third column)
            importPickupRequests(formData)
              .then((response) => {
                if (response && response?.response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.status === "ERROR") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response?.response?.status == "500") {
                  toast.error(response.response.statusText);
                  fileInput.value = "";
                } else if (response && response?.data?.status === "ERROR") {
                  // Handle error response from the server
                  const errorMessages = response?.data?.data
                    .map((item) => `Row ${item.rows}: ${item.errorMsg}`)
                    .join(", ");
                  toast.error(`Error uploading the file: ${errorMessages}`);
                  const errorMessage =
                    response?.response?.data?.msg?.split(",")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else if (response?.response?.data?.status === "ERROR") {
                  const errorMessage =
                    response?.response?.data?.msg?.split("!")[0];
                  toast.error(errorMessage);
                  fileInput.value = "";
                } else {
                  addConsignment.setFieldValue(
                    "consignments",
                    response?.data?.data
                  );
                }
              })
              .catch(() => {
                toast.error("Error reading the file. Please try again.");
                fileInput.value = "";
              });
          } else {
            // Show error toast for empty sheet
            toast.error(
              "Error: The sheet is empty. Please provide valid data."
            );
            fileInput.value = "";
          }
        } else {
          // Show error toast for invalid sheet
          toast.error(
            "Error: Invalid sheet. Please provide a valid Excel file."
          );
          fileInput.value = "";
        }
      } catch (error) {
        // Handle any errors during file reading
        toast.error("Error reading the file. Please try again.");
        fileInput.value = "";
      }
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  const fileInputRef = useRef(null);
  const handleClick = () => {
    fileInputRef.current.click(); // Triggering click on file input
  };
  const handleConsignmentsChange = (event) => {
    const newConsignments = event.target.value;
    // Splitting the string by commas to create an array of consignments
    const consignmentsArray = newConsignments
      .split(",")
      .map((item) => item.trim()); // trimming to remove any extra spaces
    addConsignment.setFieldValue("consignments", consignmentsArray);
  };
  return (
    <div>
      <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  navigate("/ops/pickups");
                  setisPickupRequest(false);
                }}
              >
                OPS
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{ fontWeight: "500", marginLeft: "-3px" }}
                onClick={() => {
                  navigate("/ops/pickups");
                  setisPickupRequest(false);
                }}
              >
                Pickup Requests
              </span>
            </li>
            {consignmentData[0]?.customerAccount &&
            <><li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                <span>
                  <img
                    src={breadArrow}
                    className="turnBlack"
                    alt="breadcrumbarrow" />
                </span>
              </li><li>
                  <span
                    className="mainheadlink"
                    style={{ fontWeight: "500", marginLeft: "-3px" }}
                    onClick={() => {
                      navigate("/ops/pickups");
                      setisPickupRequest(false);
                    }}
                  >
                    {paramsId?.id}
                  </span>
                </li></>}
                <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                <span>
                  <img
                    src={breadArrow}
                    className="turnBlack"
                    alt="breadcrumbarrow" />
                </span>
              </li><li>
                  <span
                    className="mainheadlink"
                    style={{ fontWeight: "500", marginLeft: "-3px" }}
                  >
                    consignments
                  </span>
                </li>
          </ul>
        </div>
      </div>
      <div>
          <div className="displayFlex alignItemCenter justifyContent_spacebetween">
            <div className="displayFlex justifyContent_end mleft_5">
              <Row>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Tracking Id:
                  </label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={trackingId}
                      className={
                        trackingId?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        trackingId?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setTrackingId(e.target.value)}
                    />
                      {trackingId && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setTrackingId("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
                  </div>
                </Col>
                <Col className="ml_5">
                  <label className="fontSize12 mb_12 marginR10 mt_10">
                    Reference Number:
                  </label>
                  <div className="displayFlex alignItemCenter position-relative">
                    <input
                      type="text"
                      value={referenceNum}
                      className={
                        referenceNum?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                      style={
                        referenceNum?.length > 0
                          ? { border: "2px solid #ec171c" }
                          : {}
                      }
                      onChange={(e) => setRefereceNum(e.target.value)}
                    />
                   {referenceNum && (
            <button
              className="cancel_btn clear-btn"
              type="button"
              onClick={()=>setRefereceNum("")}
            >
              <span>
                <img src={removeIcon} alt="Clear" />
              </span>
            </button>
          )}
                  </div>
                </Col>
              </Row>
            </div>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_5">
              <div className="smallGroupInput mleft_10">
                {paramsId?.id !== undefined && (
                  <div className="displayFlex alignItemCenter justifyContent_end mb_16">
                    <button
                      className="filterBtn plus_icon mleft_10"
                      title="Sync"
                      onClick={() => syncronizeRoles()}
                    >
                      <span>
                        <img src={syncicon} className="h18" />
                      </span>
                    </button>
                    {(roleHandler(role, "AONE_OPS.write") ||
                      roleHandler(role, "AONE_OPS.admin") ||
                      roleHandler(role, "AONE_OPS_CONSIGNMENTS.write") ||
                      roleHandler(role, "AONE_OPS_CONSIGNMENTS.admin") ||
                      roleHandler(role, "AONE_OPS_CONSIGNMENTS.pickup")) && (
                      <button
                        className="filterBtn plus_icon mleft_10"
                        title="Add"
                        onClick={addConsignments}
                      >
                        +
                      </button>
                    )}
                      <div ref={ref} className="customFilter mleft_5p">
      <Dropdown autoClose="outside">
        <Dropdown.Toggle id="dropdown-basic">
          <img src={DotboldIcon} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>
            <ul className="listColumn cursorPointer">
              {column.map((d, i) => (
                <li key={d}>
                  <div className="smallCheckbox mb_4">
                    <input
                      id={`product${i}`}
                      type="checkbox"
                      checked={!checkedCol.includes(d)}
                    />
                    <label
                      htmlFor={`product${i}`}
                      onClick={() => handleSelect(d)}
                    >
                      {d}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
                  </div>
                )}
              </div>
          </div>
          <PaginationTable
            tableData={consignmentData}
            restrict={checkedCol}
            tbl={"PickupConsignment"}
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setNewPage={setNewPage}
            size={size}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSize={setSize}
            seleczone={seleczone}
            pickupData={pickupData}
            setPickupData={setPickupData}
            setselectZone={setselectZone}
            selecwaybill={selecwaybill}
            setSelectWaybill={setSelectWaybill}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            totalCount={totalCount}
            setSortField={setSortField}
            sortField={sortField}
            statusHandler={statusHandler}
            setStatusHandler={setStatusHandler}
            isDelete={isDelete}
            setIsDelete={setIsDelete}
          />
        </div>
      {showConsignments && (
        <Modal
          show={showConsignments}
          onHide={handleCloseConsignments}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <h4 className="fontSize16 blackText fontWeight500">
              Add Consignments
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-12 mt_10">
                  <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                    Consignments:
                  </label>
                  <textarea
                    className="washMe_btn"
                    name="consignments"
                    placeholder="Upload consignments from excel by clicking below button"
                    value={addConsignment.values.consignments}
                    onChange={handleConsignmentsChange}
                    style={{ height: "150px", paddingTop: "6px" }}
                  />
                  {addConsignment.touched.consignments &&
                  addConsignment.errors.consignments ? (
                    <p className="requiredText">
                      {addConsignment.errors.consignments}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div title="Upload File" className="mleft_10">
                    <button
                      type="button"
                      className="blue_btn mt_10"
                      onClick={handleClick}
                    >
                      Upload File
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={(e) => handleFileChange(e)}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{ display: "none" }} // Hide the file input
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
              <div>
                <button
                  type="button"
                  className="blue_btn"
                  onClick={addConsignment.handleSubmit}
                >
                  Create
                </button>
                <button
                  className="cancel_btn"
                  onClick={() => handleCloseConsignments()}
                >
                  Cancel
                </button>
              </div>
              <div></div>
            </div>
          </Modal.Body>
          {/* </div> */}
        </Modal>
      )}
      <Modal
        show={show}
        onHide={() => handleCancel()}
        className="addnewpopup"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">
            Import Exceptions
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="error-message">
          <p><span>!</span>Failed to import below records</p>
          </div>
          <form>
            <div className="row">
              <div className="table_wrapper overflowScroll mb_12 showTenRows">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Field</th>
                      <th>Resource Id</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importError?.map(
                      (err, id) => (
                        <tr
                          key={id}
                        >
                          <td>{id + 1}</td>
                          <td>{err.field}</td>
                          <td>{err.key}</td>
                          <td>{err.msg}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center">
            <div>
              <button className="cancel_btn" onClick={() => handleCancel()}>
                Dismiss
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default ConsignmentsPickup;
