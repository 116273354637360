import React, { useState, useRef, useEffect, useContext } from "react";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import { useNavigate, useLocation } from "react-router";
import PaginationTable from "../../components/Tables/paginationTable";
import syncicon from "../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../store/AoneStore";
import { DateRangePicker } from "rsuite";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import assignIcon from "../../../src/assets/img/assignorder_icon.svg";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { UserContext } from "../../helper/ProtectedRoute";
const Orders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPage, setNewPage] = useState(0);
  const [editPage, setEditPage] = useState(false);
  const [size, setSize] = useState(20);
  const [name, setName] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const prevent = useRef(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortField, setSortField] = useState("-updatedAt");
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [isassign, setIsassign] = useState(false);
  const [dateRangefil, setDateRangefil] = useState({
    start: null,
    end: null,
  });
  const [waybillNum, setWaybillNum] = useState("");
  const [refNum, setRefNum] = useState("");
  const [status, setStatus] = useState("None");
  const thirdpartyCouriers = useAoneStore((state) => state?.thirdpartyCouriers);
  const assignOrders = useAoneStore((state) => state?.assignOrders);
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const [showorder, setShoworder] = useState(false);
  const handleShoworder = () => setShoworder(true);
  const handleCloseorder = () => {
    setShoworder(false);
  };
  const assignModal = () => {
    setShoworder(true);
  };
  const [isDropdownselectOpen, setIsDropdownOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const [waybillNumbersInput, setWaybillNumbersInput] = useState("");
  const assignThirdpartyOrder = useFormik({
    initialValues: {
      waybillNumbers: [],
      thirdPartyId: "",
    },
    validationSchema: Yup.object({
      waybillNumbers: Yup.array()
        .of(Yup.string().required("Waybill number required"))
        .min(1, "At least one waybill number is required")
        .max(25, "At most only 25 waybill numbers can be assigned"),
      thirdPartyId: Yup.string().required("Select a courier to assign order"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await assignOrders(values).then((response) => {
        if (response?.response?.data?.status === "ERROR") {
          const errorMessage = response?.response?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setShoworder(false);
          setWaybillNumbersInput("");
          resetForm();
        } else if (response?.data?.status === "ERROR") {
          const errorMessage = response?.data?.msg.split(",")[0];
          toast.error(errorMessage);
          setShoworder(false);
          setWaybillNumbersInput("");
          resetForm();
        } else {
          toast.success("Order Assigned");
          setIsassign(!isassign);
          setWaybillNumbersInput("");
          setShoworder(false);
          resetForm();
        }
      });
    },
  });
  const extractValidWaybillNumbers = (text) => {
    const numbers = text.trim().split(/\s*,\s*|\s+/);
    const validatedNumbers = numbers.filter((number) =>
      Yup.string()
        .validate(number)
        .then(() => true)
        .catch(() => false)
    );
    return validatedNumbers;
  };
  useEffect(() => {
    const validatedNumbers = extractValidWaybillNumbers(waybillNumbersInput);
    assignThirdpartyOrder.setFieldValue("waybillNumbers", validatedNumbers);
  }, [waybillNumbersInput]);
  const getThirdparty = useAoneStore((state) => state?.getThirdparty);
  const [carrier, setCarrier] = useState("");
  const getOrders = useAoneStore((state) => state?.getOrders);
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      setDateRange(value);

      const startMillis = value[0].getTime(); // Convert start date to Milliseconds
      const endMillis = value[1].getTime(); // Convert end date to Milliseconds

      setDateRangefil({
        start: startMillis,
        end: endMillis,
      });
    } else {
      setDateRange([]);
      setDateRangefil({
        start: null,
        end: null,
      });
    }
  };
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}`;
    if (carrier) {
      params += `&carrier=${carrier}`;
    }
    if (refNum) {
      params += `&referenceNo=${refNum}`;
    }
    if (waybillNum) {
      params += `&waybillNumber=${waybillNum}`;
    }
    if (status && status !== "None") {
      params += `&status=${status}`;
    }
    if (dateRangefil.start !== null) {
      params += `&startTime=${dateRangefil.start}`;
    }
    if (dateRangefil.end !== null) {
      params += `&endTime=${dateRangefil.end}`;
    }
    const data = await getOrders(params);
    setTotalCount(data.data.data.totalElements);
    setAddressData(data.data.data.content);
  };
  useEffect(() => {
    getData();
  }, [
    newPage,
    size,
    sortField,
    deleteHandler,
    carrier,
    waybillNum,
    refNum,
    dateRangefil.start,
    dateRangefil.end,
    status,
    isassign,
  ]);
  useEffect(() => {
    let paramslink = `?page=${0}&size=${50}`;
    getThirdparty(paramslink);
  }, []);
  return (
    <div>
      <div className="row alignItemCenter">
        <ul className="page_breadcrumb">
          <li>
            <span className="mainheadlink" style={{ fontWeight: "500" }}>
              Third Party
            </span>
          </li>
          <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
            <span>
              <img
                src={breadArrow}
                className="turnBlack"
                alt="breadcrumbarrow"
              />
            </span>
          </li>
          <li>
            <span
              className="mainheadlink"
              style={{
                fontWeight: "500",
                marginLeft: "-3px",
                fontWeight: "bolder",
              }}
            >
              Orders
            </span>
          </li>
        </ul>
      </div>
      {!editPage && (
        <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_8">
          <Row>
            <div className="displayFlex">
            <Col className="ml_5">
              <label className="fontSize12 mb_12 marginR10 mt_10">
              Carrier:
                    </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    value={carrier}
                    onChange={(e) => setCarrier(e.target.value)}
                    className={
                      carrier?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      carrier?.length > 0 ? { border: "2px solid #ec171c" } : {}
                    }
                  />
                </div>
              </Col>
              <Col className="ml_5">
              <label className="fontSize12 mb_12 marginR10 mt_10 ml_10">
              Reference Number:
                    </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    placeholder=""
                    value={refNum}
                    onChange={(e) => setRefNum(e.target.value)}
                    className={
                      refNum?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      refNum?.length > 0
                        ? { border: "2px solid #ec171c", marginLeft: "10px" }
                        : { marginLeft: "10px" }
                    }
                  />
                </div>
              </Col>
              <Col className="ml_5">
              <label className="fontSize12 mb_12 marginR10 mt_10 ml_10">
              Waybill Number:
                    </label>
                <div className="displayFlex alignItemCenter">
                  <input
                    type="text"
                    placeholder=""
                    value={waybillNum}
                    onChange={(e) => setWaybillNum(e.target.value)}
                    className={
                      waybillNum?.length > 0
                        ? "mainInput"
                        : "mainInput withSearchIcon"
                    }
                    style={
                      waybillNum?.length > 0
                        ? { border: "2px solid #ec171c", marginLeft: "10px" }
                        : { marginLeft: "10px" }
                    }
                  />
                </div>
              </Col>
              <Col className="ml_5">
              <label className="fontSize12 mb_12 marginR10 mt_10 ml_10">
              Status:
                    </label>
                <div className="displayFlex alignItemCenter">
                  <select
                    className="mainInput"
                    value={status}
                    onChange={handleStatusChange}
                    style={
                      status?.length > 0 && status !== "None"
                        ? { border: "2px solid #ec171c" }
                        : {}
                    }
                  >
                    <option value="">None</option>
                    <option value="MANIFESTED">Manifested</option>
                    <option value="ERROR">Error</option>
                    <option value="NOT_ASSIGN">Not Assign</option>
                    <option value="CANCELLED">Cancelled</option>
                    <option value="PICKEDUP">Picked Up</option>
                  </select>
                </div>
              </Col>
              <Col className="ml_5">
              <label className="fontSize12 mb_12 marginR10 mt_10 ml_10">
              Select Date Range:
                    </label>
                <div className="displayFlex alignItemCenter gap_12">
                  <div className="customDateRange">
                    <DateRangePicker
                      placeholder=""
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      placement="auto"
                      style={{ borderRadius: "8px" }}
                      onOpen={handleMenuOpen}
                      onClose={handleMenuClose}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Row>
          <div className="displayFlex gap_12 mt_45">
            {(role?.includes("AONE_ADMIN") ||
              role?.includes("AONE_THIRD-PARTY.admin") ||
              role?.includes("AONE_THIRD-PARTY_ORDERS.admin") ||
              role?.includes("AONE_THIRD-PARTY_ORDERS.assign")) && (
              <div>
                <button
                  className="filterBtn"
                  title="Bulk Assign"
                  tabIndex="-1"
                  onClick={() => assignModal()}
                >
                  <span>
                    <img src={assignIcon} className="h18" />
                  </span>
                </button>
              </div>
            )}
            <div>
              <button
                className="filterBtn"
                title="Sync"
                onClick={() => syncronizeRoles()}
              >
                <span>
                  <img src={syncicon} className="h18" />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <PaginationTable
        tableData={addressData}
        tbl="Orders"
        isassign={isassign}
        setIsassign={setIsassign}
        modalHandler={editPage}
        setModalHandler={setEditPage}
        isDropdownselectOpen={isDropdownselectOpen}
        deleteHandler={deleteHandler}
        setDeleteHandler={setDeleteHandler}
        newPage={newPage}
        setNewPage={setNewPage}
        size={size}
        setSize={setSize}
        totalCount={totalCount}
        sortField={sortField}
        setSortField={setSortField}
      />
       {showorder && (
      <Modal
        show={showorder}
        onHide={handleCloseorder}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <h4 className="fontSize16 blackText fontWeight500">
            Assign to courier
          </h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                  Waybill Numbers:
                </label>
                <textarea
                  className="washMe_btn"
                  placeholder="Waybill Numbers (separated by comma or space)"
                  name="waybillNumbers"
                  style={{ height: "150px", paddingTop: "6px" }}
                  value={waybillNumbersInput}
                  onChange={(event) =>
                    setWaybillNumbersInput(event.target.value)
                  }
                />
                {assignThirdpartyOrder.touched.waybillNumbers &&
                assignThirdpartyOrder.errors.waybillNumbers ? (
                  <p className="requiredText">
                    {assignThirdpartyOrder.errors.waybillNumbers}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <label className="fontSize14 menacingCloudsText fontWeight500 mb_12">
                Carrier:
                </label>
                <SearchInput
                  id="thirdPartyId"
                  label="thirdPartyId"
                  name="thirdPartyId"
                  isClearable={true}
                  options={(thirdpartyCouriers || [])?.filter(carrier => carrier?.status === "ACTIVE")}
                  getOptionLabel={(carrier) => carrier.code}
                  getOptionValue={(carrier) => carrier.id}
                  handleChange={async (selectedOption) => {
                    assignThirdpartyOrder.setFieldValue(
                      "thirdPartyId",
                      selectedOption ? selectedOption.id : ""
                    );
                  }}
                  isSearchable={true}
                  placeholder="Carrier"
                />
                {assignThirdpartyOrder.touched.thirdPartyId &&
                assignThirdpartyOrder.errors.thirdPartyId ? (
                  <p className="requiredText">
                    {assignThirdpartyOrder.errors.thirdPartyId}
                  </p>
                ) : null}
              </div>
            </div>
          </form>
          <div className="displayFlex alignItemCenter gap_16 justifyContent_center mt_25">
            <div>
              <button
                type="button"
                className="blue_btn"
                onClick={assignThirdpartyOrder.handleSubmit}
              >
                Assign
              </button>
              <button className="cancel_btn" onClick={() => handleCloseorder()}>
                Cancel
              </button>
            </div>
            <div></div>
          </div>
        </Modal.Body>
        {/* </div> */}
      </Modal>)}
    </div>
  );
};

export default Orders;
