import React, { useEffect, useState, useContext, useCallback } from "react";
import dotIcon from "../../../assets/img/dotsbold.svg";
import { useAoneStore } from "../../../store/AoneStore";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UserContext } from "../../../helper/ProtectedRoute";
import removeIcon from "../../../../src/assets/img/cancelIcon.svg";
import SearchInput from "../../../components/dropdown/autoCompleteInputComponent";
import AsyncSearchInput from "../../../components/dropdown/autoCompleteAsyncComponent";
const Cities = ({ edit }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const filterRegions = useAoneStore((state) => state.filterRegions);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const getZone = useAoneStore((state) => state.getZone);
  const getCityById = useAoneStore((state) => state.getCityById);
  const postCities = useAoneStore((state) => state.postCities);
  const updateCityById = useAoneStore((state) => state.updateCityById);
  const getRegionbyCode = useAoneStore((state) => state.getRegionbyCode);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const regionbyCode = useAoneStore((state) => state?.regionbyCode);
  const zoneData = useAoneStore((state) => state?.zoneData);
  const regionsData = useAoneStore((state) => state?.regionsData);
  const getTiers = useAoneStore((state) => state?.getTiers);
  const tiersData = useAoneStore((state) => state?.tiersData);
  const [minibar, setMinibar] = useState(0);
  const [data, setData] = useState({});
  const [alaises, setAlaises] = useState([]);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [locales, setLocales] = useState({});
  const [page, setPage] = useState(-1);
  const [zonepage, setZonePage] = useState(-1);
  const [loadingMore, setLoadingMore] = useState(false);
  const setAllDefault = () => {
    setAlaises([]);
    setMandatoryFields([]);
    setLocales({});
    edit(false);
    navigate("/configs/addresses");
  };
  const city = useFormik({
    initialValues: {
      name: params?.id ? data?.name : "",
      countryIsoCode3: params?.id ? data?.countryIsoCode3 : "",
      regionCode: params?.id ? data?.regionCode : "",
      code: params?.id ? data?.code : "",
      zoneCode: params?.id ? data?.zoneCode : "",
      fallbackZoneCode: params?.id ? data?.fallbackZoneCode : "",
      tierCode: params?.id ? data?.tierCode : "",
      postalCode: params?.id ? data?.postalCode : "",
      remote: params?.id ? (data?.remote ? true : false) : false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .matches(/\S/, "City Name should not contain only spaces")
        .required("City Name is Required"),
      countryIsoCode3: Yup.string().required("Country is Required"),
      regionCode: Yup.string().required("Region is Required"),
      fallbackZoneCode: Yup.string().required("Fallback zone is Required"),
      code: Yup.string()
        .trim()
        .matches(/\S/, "Code should not contain only spaces")
        .required("Code is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values["aliases"] = alaises;
      values["locales"] = locales;
      if (params?.id) {
        values["id"] = data.id;
        await updateCityById(params?.id, values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("City Updated");
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status === "ERROR") {
            response?.response?.data?.errors?.forEach((error) => {
              toast.error(error.msg);
            });
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      } else {
        await postCities(values).then((response) => {
          if (response?.data?.status == "SUCCESS") {
            toast.success("City Created");
            resetForm();
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg;
            toast.error(errorMessage);
          } else if (response?.response?.data?.errorCode == 409) {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status === "ERROR") {
            response?.response?.data?.errors?.forEach((error) => {
              toast.error(error.msg);
            });
          } else {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          }
        });
      }
      setAllDefault();
    },
  });
  const [showZone, setShowZone] = useState(params?.id == undefined);
  const [showfbZone, setShowFbZone] = useState(params?.id == undefined);
  const handleInputZoneClick = () => {
    if (params?.id != undefined) {
      setShowZone(true);
    }
  };
  const handleInputFbZoneClick = () => {
    if (params?.id != undefined) {
      setShowFbZone(true);
    }
  };
  useEffect(() => {
    if (!params.id) {
      city.setFieldValue("regionCode", "");
    }
  }, [city.values.countryIsoCode3]);
  const languages = [
    { key: "ar", label: "Arabic" },
    { key: "zh", label: "Chinese" },
    { key: "en", label: "English" },
    { key: "tr", label: "Turkish" },
  ];
  const [resetKey, setResetKey] = useState(0);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");

  const minibarhandler = (i) => {
    setFirstValue("");
    setSecondValue("");
    if (i == minibar) {
      setMinibar(0);
    } else {
      setMinibar(i);
    }
  };

  const handleObjectDelete = (key) => {
    let newObject = { ...locales };
    delete newObject[key];
    setLocales(newObject);
  };
  const handleObjectAdd = () => {
    if (firstValue.trim() === "" || secondValue.trim() === "") {
      toast.error("Cannot add empty value for locales");
    } else {
      setResetKey((prevKey) => prevKey + 1);
      let newObject = { ...locales };
      newObject[firstValue] = secondValue;
      setLocales(newObject);
      setFirstValue("");
      setSecondValue("");
    }
  };
  const handleArrayDelete = (i) => {
    if (minibar == 3) {
      let newArray = [...alaises];
      newArray.splice(i, 1);
      setAlaises(newArray);
    } else {
      let newArray = [...mandatoryFields];
      newArray.splice(i, 1);
      setMandatoryFields(newArray);
    }
  };

  const handleArrayAdd = () => {
    if (firstValue.trim() === "") {
      toast.error("Cannot add empty value in Alaises");
    } else {
      let newArray = [...alaises];
      newArray.push(firstValue);
      setAlaises(newArray);
      setFirstValue("");
    }
  };
  const getData = async () => {
    const data = await getCityById(params.id);
    setData(data.data.data);
    setCountryCde(data.data.data.countryIsoCode3);
    let paramslink = `?page=${0}&size=${30}&countryCode=${data?.data?.data?.countryIsoCode3}`;
    getTiers(paramslink);
    setAlaises(data.data.data.aliases ?? []);
    setMandatoryFields(data.data.data.mandatoryFields ?? []);
    setLocales(data.data.data.locales ?? {});
  };

  useEffect(() => {
    let paramslink = `?page=${0}&size=${30}`;
    filterCountries(paramslink);
    filterRegions(paramslink);
    params?.id && getData();
  }, []);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionZone, setSelectedOptionZone] = useState(null);
  const [countryCde, setCountryCde] = useState("");
  useEffect(() => {
      setCountryCde(city.values.countryIsoCode3);
  }, [city.values.countryIsoCode3]);
  const loadMoreOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : page + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setPage(nextPage);
        let endpoint = `?page=${nextPage}&size=20&countryCode=${countryCde}`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await getZone(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, page,city.values.countryIsoCode3,countryCde]
  );
  const loadMoreFbOptions = useCallback(
    async (search) => {
      if (!loadingMore) {
        setLoadingMore(true);
        let nextPage = search ? 0 : zonepage + 1;
        if (nextPage === -1) nextPage = 0; // Ensure nextPage is not negative
        setZonePage(nextPage);
        // Simulate an API call (replace this with your actual API call) 
       let endpoint = `?page=${nextPage}&size=20&countryCode=${countryCde}`;
        if (search) {
          endpoint += `&code=${search}`;
        }
        const response = await getZone(endpoint);
        const options = response.data.data.content || [];

        setLoadingMore(false);

        return {
          options: options.map((zone) => ({
            label: zone.code,
            value: zone.code,
          })),
          hasMore: options.length > 0,
        };
      }

      return {
        options: [],
        hasMore: false,
      };
    },
    [loadingMore, zonepage, city.values.countryIsoCode3,countryCde]
  );
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    // Add a flag to prevent multiple consecutive updates
    return !loadingMore && bottomBorder < scrollTop;
  };
  return (
    <div className="row">
      <div className="col-md-6" style={{ padding: "12px" }}>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Country: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="countryIsoCode3"
              label="Country"
              name="countryIsoCode3"
              isClearable={true}
              value={countriesData?.data?.data?.content?.find(
                (country) => country.isoCode3 === city.values.countryIsoCode3
              )}
              options={countriesData?.data?.data?.content || []}
              getOptionLabel={(country) => country.name}
              getOptionValue={(country) => country.isoCode3}
              handleChange={async (selectedOption) => {
                // Update form field when an option is selected
                city.setFieldValue(
                  "countryIsoCode3",
                  selectedOption ? selectedOption.isoCode3 : ""
                );
               await setCountryCde( selectedOption ? selectedOption.isoCode3 : "");
                // Call your API with isoCode3
                try {
                  const regionData = await getRegionbyCode(
                    selectedOption.isoCode3
                  );
                  let paramslink = `?page=${0}&size=${30}&countryCode=${selectedOption?.isoCode3}`;
                  getTiers(paramslink)
                } catch (error) {
                  console.error("Error fetching region data:", error);
                }
              }}
              isSearchable={true}
              placeholder="Select Country"
              isDisabled={params?.id ? true : false}
            />
            {city.touched.countryIsoCode3 && city.errors.countryIsoCode3 ? (
              <p className="requiredText">{city.errors.countryIsoCode3}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Region: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <SearchInput
              id="regionCode"
              className="MainInput"
              label="Region"
              name="regionCode"
              options={regionbyCode || []}
              getOptionLabel={(region) => region.name}
              getOptionValue={(region) => region.code}
              handleChange={(selectedOption) => {
                city.setFieldValue(
                  "regionCode",
                  selectedOption ? selectedOption.code : ""
                );
              }}
              value={
                city.values.regionCode
                  ? regionsData?.data?.data?.content?.find(
                      (zone) => zone.code === city.values.regionCode
                    )
                  : null
              }
              isClearable={true}
              isSearchable={true}
              placeholder="Select Region"
              isDisabled={params?.id ? true : false}
            />
            {city.touched.regionCode && city.errors.regionCode ? (
              <p className="requiredText">{city.errors.regionCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Name: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Name"
              className="mainInput"
              name="name"
              onChange={(e) => city.setFieldValue("name", e.target.value)}
              onBlur={city.handleBlur}
              value={city.values.name}
            />
            {city.touched.name && city.errors.name ? (
              <p className="requiredText">{city.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Locales:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(1)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Code: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Code"
              className="mainInput"
              name="code"
              onChange={(e) => city.setFieldValue("code", e.target.value)}
              onBlur={city.handleBlur}
              value={city.values.code}
              disabled={params?.id ? true : false}
            />
            {city.touched.code && city.errors.code ? (
              <p className="requiredText">{city.errors.code}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_6">
            <label>Alaises:</label>
          </div>
          <div className="col-md-8">
            <button className="filterBtn" onClick={() => minibarhandler(3)}>
              <span>
                <img src={dotIcon} />
              </span>
            </button>
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Zone:</label>
          </div>
          <div className="col-md-8">
            {showZone ? (
              <AsyncSearchInput
                id="zoneCode"
                label="Zone"
                name="zoneCode"
                value={selectedOption}
                loadMoreOptions={loadMoreOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                  city.setFieldValue(
                    "zoneCode",
                    selectedOption ? selectedOption.value : ""
                  );
                }}
                placeholder="Select Zone"
              />
            ) : (
              <input
                type="text"
                value={data?.zoneCode}
                name="zoneCode"
                className="mainInput"
                onClick={handleInputZoneClick}
              />
            )}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Fallback Zone: <span className='bloodDonortext fontWeight600 fontSize16'>*</span></label>
          </div>
          <div className="col-md-8">
            {showfbZone ? (
              <AsyncSearchInput
                id="fallbackZoneCode"
                label="fallbackZoneCode"
                name="fallbackZoneCode"
                value={selectedOptionZone}
                loadMoreOptions={loadMoreFbOptions}
                shouldLoadMore={shouldLoadMore}
                getOptionLabel={(zone) => zone.label}
                getOptionValue={(zone) => zone.value}
                onChange={(selectedOptionZone) => {
                  setSelectedOptionZone(selectedOptionZone);
                  city.setFieldValue(
                    "fallbackZoneCode",
                    selectedOptionZone ? selectedOptionZone.value : ""
                  );
                }}
                placeholder="Select Fallback Zone"
              />
            ) : (
              <input
                type="text"
                value={data?.fallbackZoneCode}
                name="fallbackZoneCode"
                className="mainInput"
                onClick={handleInputFbZoneClick}
              />
            )}
             {city.touched.fallbackZoneCode && city.errors.fallbackZoneCode ? (
              <p className="requiredText">{city.errors.fallbackZoneCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Tiers:</label>
          </div>
          <div className="col-md-8">
          <SearchInput
             id="tierCode"
             label="tierCode"
             name="tierCode"
             options={tiersData?.data?.data?.content || []}
             getOptionLabel={(tier) => tier.name}
             getOptionValue={(tier) => tier.code}
             value={
               city.values.tierCode
                 ? tiersData?.data?.data?.content?.find(
                     (zone) => zone.code === city.values.tierCode
                   )
                 : null
             }
             handleChange={(selectedOption) =>
               city.setFieldValue("tierCode",selectedOption ? selectedOption?.code : null)
             }
             isClearable={true}
             isSearchable={true}
             placeholder="Select Tier"
            />
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Postal Code:</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              placeholder="Postal Code"
              className="mainInput"
              name="postalCode"
              onChange={(e) => city.setFieldValue("postalCode", e.target.value)}
              onBlur={city.handleBlur}
              value={city.values.postalCode}
            />
          </div>
        </div>
        <div className="row mb_24">
          <div className="col-md-4 fontSize14 menacingCloudsText fontWeight500 mt_8">
            <label>Remote:</label>
          </div>
          <div className="col-md-8">
            <div className="smallCheckbox">
              <input
                type="checkbox"
                id="remote"
                style={{ marginLeft: "5px" }}
                checked={city.values.remote}
                onChange={() => {
                  city.setFieldValue("remote", !city.values.remote);
                  if (!city.values.remote) {
                    city.setFieldValue("remote", true); // Set bagNumber to an empty string
                  }
                }}
              />
              <label className="margintop7" htmlFor="remote"></label>
            </div>
          </div>
        </div>
        {(role?.includes("AONE_CONFIGS.write") ||
          role?.includes("AONE_CONFIGS.admin") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.write") ||
          role?.includes("AONE_CONFIGS_ADDRESSES.admin")) && (
          <div className="row mb_24">
            <div className="col-md-12 displayFlex alignItemCenter justifyContent_end gap_16">
              <button
                type="submit"
                className="blue_btn"
                onClick={city.handleSubmit}
              >
                Save
              </button>
              <button className="cancel_btn" onClick={() => setAllDefault()}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6" style={{ padding: "12px" }}>
        {minibar == 1 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Locales</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <div className="displayFlex alignItemCenter justifyContent_spacebetween">
                <SearchInput
                  key={resetKey}
                  id="firstValue"
                  label="firstValue"
                  name="firstValue"
                  options={languages || []}
                  getOptionLabel={(city) => city.label}
                  getOptionValue={(city) => city.label}
                  handleChange={async (selectedOption) => {
                    setFirstValue(selectedOption?.label);
                  }}
                  isClearable={true}
                  customStyles={{ control: { width: "180px" } }}
                  isSearchable={true}
                  placeholder="Language"
                />
                <input
                  type="text"
                  placeholder="Value"
                  style={{ marginLeft: "15px" }}
                  value={secondValue || ""}
                  onChange={(e) => setSecondValue(e.target.value)}
                  className="mainInput"
                />
              </div>
              <button className="filterBtn" onClick={() => handleObjectAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Languages</th>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                            <td>FR</td>
                            <td>AUBA AUBA</td>
                            <td><button className='cancel_btn'>Remove</button></td>
                        </tr> */}
                  {Object.keys(locales).map((value, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{value}</td>
                        <td>{locales[value]}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleObjectDelete(value)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : minibar == 2 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Mandatory Fields</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <input
                type="text"
                placeholder="Field"
                style={{ width: "50%" }}
                value={firstValue}
                onChange={(e) => setFirstValue(e.target.value)}
                className="mainInput"
              />
              <button className="filterBtn" onClick={() => handleArrayAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Fields</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mandatoryFields.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e}</td>
                        <td>
                          <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleArrayDelete(e)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : minibar == 3 ? (
          <div className="borderFrame mb_16">
            <div className="borderFrameTitle">Alaises</div>
            <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
              <input
                type="text"
                placeholder="Values"
                value={firstValue}
                style={{ width: "50%" }}
                onChange={(e) => setFirstValue(e.target.value)}
                className="mainInput"
              />
              <button className="filterBtn" onClick={() => handleArrayAdd()}>
                +
              </button>
            </div>
            <div className="table_wrapper mb_16 showFiveRows">
              <table>
                <thead>
                  <tr>
                    <th>Values</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <td>ABC</td>
                            <td><button className='cancel_btn'>Remove</button></td> */}
                  {alaises.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e}</td>
                        <td>
                        <button
                            className="cancel_btn"
                            type="button"
                            onClick={() => handleArrayDelete(e)}
                          >
                            <span>
                              <img src={removeIcon} alt="Remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="displayFlex alignItemCenter justifyContent_end mb_16">
              {/* <button className="blue_btn">
                    Done
                    </button> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Cities;
