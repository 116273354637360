import React, { useState, useEffect, useRef, useContext } from "react";
import breadArrow from "../../assets/img/arrow-dropright_icon.svg"
import { useNavigate, useParams, useLocation } from "react-router";
import Accounts from "./Accounts";
import BillingAddress from "./BillingAddress";
import BankDetails from "./BankDetails";
import Pickupaddress from "./Pickupaddress";
import Rto from "./Rto";
import { roleHandler } from "../../helper/roleHandler";
import { UserContext } from "../../helper/ProtectedRoute";
import Booking from "./Booking";
import { useAoneStore } from "../../store/AoneStore"
const CustomerAdd = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(1);
    const prevent = useRef(false);
    const [editPage, setEditPage] = useState(false);
  const toggleIsTabChanging = useAoneStore((state) => state?.toggleIsTabChanging);
  const globalcustomerAccount = useAoneStore((state) => state?.globalcustomerAccount);
  const setcustomerAccount = useAoneStore((state) => state?.setcustomerAccount);
  const setBankState = useAoneStore((state) => state?.setBankState);
  const formState = useAoneStore((state) => state?.formState);
  const setFormState = useAoneStore((state) => state?.setFormState);
    const tabHandler = (i) => {
        prevent.current = false;
        setEditPage(false);
        setTab(i);
        // toggleIsTabChanging()
        // setBankState(formState)
      };
      const [state] = useContext(UserContext);
      const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
      useEffect(() => {
        setRole(state.roles);
      }, [state.roles]);
  return (
    <div>
         <div className="row alignItemCenter">
        <div className="col-md-7">
          <ul className="page_breadcrumb">
            <li>
              <span className="mainheadlink" style={{ fontWeight: "500" }}  onClick={() => {
                  setcustomerAccount("")
                  navigate("/crm/customers");
                }}>
                CRM
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                onClick={() => {
                  setcustomerAccount("")
                  navigate("/crm/customers");
                }}
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                }}
              >
                Customers
              </span>
            </li>
            <li style={{ marginLeft: "-10px", marginBottom: "5px" }}>
              <span>
                <img
                  src={breadArrow}
                  className="turnBlack"
                  alt="breadcrumbarrow"
                />
              </span>
            </li>
            <li>
              <span
                className="mainheadlink"
                style={{
                  fontWeight: "500",
                  marginLeft: "-3px",
                  fontWeight: "bolder",
                }}
              >
                {!params?.id ? "Create" : params?.id}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="displayFlex tabletab_section mb_12">
        <button
          className={`filterBtn ${tab == 1 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalcustomerAccount !== undefined) {
              tabHandler(1);
            }
          }}
        >
          Account
        </button>
        <button
          className={`filterBtn ${tab == 2 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalcustomerAccount !== undefined) {
              tabHandler(2);
            }
          }}
        >
          Billing Address
        </button>
        <button
          className={`filterBtn ${tab == 3 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalcustomerAccount !== undefined) {
              tabHandler(3);
            }
          }}
        >
          Bank Address
        </button>
        <button
          className={`filterBtn ${tab == 4 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalcustomerAccount !== undefined) {
              tabHandler(4);
            }
          }}
        >
          Pickup Addresses
        </button>
        <button
          className={`filterBtn ${tab == 5 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalcustomerAccount !== undefined) {
              tabHandler(5);
            }
          }}
        >
          RTO
        </button>
        {(roleHandler(role, "AONE_CRM.write") ||
                  roleHandler(role, "AONE_CRM.admin") ||
                  roleHandler(role, "AONE_CRM.read") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.read") ||
                  roleHandler(role, "AONE_CRM_CUSTOMERS_BOOKING-SETTINGS.write")) && (
        <button
          className={`filterBtn ${tab == 6 && `activeFilterBtn`}`}
          onClick={() => {
            if (params?.id !== undefined || globalcustomerAccount !== undefined) {
              tabHandler(6);
            }
          }}
        >
          Booking Settings
        </button>)}
      </div>
      <div>
        {/* {editPage && */}
         { (tab == 1 ? (
            <Accounts edit={setEditPage} setTab={setTab}/>
          ) : tab == 2 ? (
            <BillingAddress edit={setEditPage} setTab={setTab}/>
          ) : tab == 3 ? (
            <BankDetails edit={setEditPage} setTab={setTab}/>
          ) : tab == 4 ? (
            <Pickupaddress edit={setEditPage} setTab={setTab}/>
          ) : tab == 5 ? (
            <Rto edit={setEditPage}/>
          ) :
            <Booking edit={setEditPage} />)}
          {/* )} */}
      </div>
    </div>
  )
}

export default CustomerAdd