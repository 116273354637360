import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router";
import breadArrow from "../../../src/assets/img/arrow-dropright_icon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import downloadicon from "../../assets/img/download_icon.svg";
import syncicon from "../../../src/assets/img/sync.svg";
import { useAoneStore } from "../../store/AoneStore";
import { useNotificationStore } from "../../store/NotificationsMgt/NotificationStore";
import fileDownload from "js-file-download";
import PaginationTable from "../../components/Tables/paginationTable";
import { roleHandler } from "../../helper/roleHandler";
import { UserContext } from "../../helper/ProtectedRoute";
import Dropdown from "react-bootstrap/Dropdown";
import DotboldIcon from "../../../src/assets/img/dotsbold.svg";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
const SmsHistory = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const prevent = useRef(false);
  const [editPage, setEditPage] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [country, setCountry] = useState("");
  const [event, setEvent] = useState("");
  const [trackingId, setTracckingId] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const saveHandler = useAoneStore((state) => state?.saveHandler);
  const [modalHandler, setModalHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const [sortBy, setSortBy] = useState("-updatedAt");
  const [individualCheckboxesbag, setIndividualCheckboxesBag] = useState([]);
  const [sortField, setSortField] = useState("createdAt");
  const getHistory = useNotificationStore((state) => state?.getHistory);
  const exportHistory = useNotificationStore((state) => state?.exportHistory);
  const setglobalHubCode = useAoneStore((state) => state?.setglobalHubCode);
  const eventStatusList = useNotificationStore((state) => state?.eventStatusList);
  const getEventStatus = useNotificationStore((state) => state?.exportRules);
  const filterCountries = useAoneStore((state) => state?.filterCountries);
  const setReactSelect = useAoneStore((state) => state?.setReactSelect);
  const setReactSelectregion = useAoneStore(
    (state) => state?.setReactSelectregion
  );
  const isReactselectOpen = useAoneStore((state) => state?.isReactselectOpen);
  const isReactselectRegion = useAoneStore(
    (state) => state?.isReactselectRegion
  );
  const ispostAdded = useAoneStore((state) => state?.ispostAdded);
  const handleMenuOpen = () => {
    setReactSelect(true);
  };
  const handleMenuClose = () => {
    setReactSelect(false);
  };
  const handleMenuOpenregion = () => {
    setReactSelectregion(true);
  };

  const handleMenuCloseregion = () => {
    setReactSelectregion(false);
  };
  const syncronizeRoles = async () => {
    setNewPage(0);
    getData();
  };
  const [column, SetCoulmn] = useState([
    "Tracking Id",
    "Event",
    "Country",
    "Message",
    "Receiver phone number",
    "Created by",
    "Created at",
  ]);
const [checkedCol, setCheckedCol] = useState([
  "Created at",
  "Created by",
]);
const handleSelect = (d) => {
  let val = checkedCol.includes(d);
  if (val) {
    setCheckedCol(checkedCol.filter((dat) => dat !== d));
  } else {
    setCheckedCol([...checkedCol, d]);
  }
};
  const getData = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (event) {
      params += `&eventCode=${event}`;
    }
    if (phoneNum) {
      params += `&receiverPhoneNumber=${phoneNum}`;
    }
    if (country) {
      params += `&countryCode=${country}`;
    }
      const data = await getHistory(params);
      setTotalCount(data?.data?.data?.totalElements);
      setTemplateData(data?.data?.data?.content);
  };
  const exportToExcel = async () => {
    let params = `?page=${newPage}&size=${size}&sortBy=${sortField}&fields=countryName`;
    if (trackingId) {
      params += `&trackingId=${trackingId}`;
    }
    if (event) {
      params += `&eventCode=${event}`;
    }
    if (phoneNum) {
      params += `&receiverPhoneNumber=${phoneNum}`;
    }
    if (country) {
      params += `&countryCode=${country}`;
    }
    try {
      const res = await exportHistory(params);
      if (res.status == 200) {
        fileDownload(res.data, `SMS History.xlsx`);
      }
    } catch (err) {}
  };
  useEffect(() => {
    let paramslink = `?page=${0}&size=${20}`;
    if (
      countriesData?.data?.data?.content?.length < 1 ||
      countriesData?.data?.data?.content?.length == undefined
    ) {
      filterCountries(paramslink);
    }
    if (
      eventStatusList?.data?.data?.length < 1 ||
      eventStatusList?.data?.data?.length == undefined
    ) {
      getEventStatus();
    }
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    getData();
  }, [
    newPage,
    sortBy,
    size,
    tab,
    editPage,
    sortField,
    location,
    saveHandler,
    trackingId,
    country,
    phoneNum,
    event,
    ispostAdded,
  ]);
  return (
    <div>
      <div>
          <div className="displayFlex alignItemCenter justifyContent_spacebetween mb_16">
            <div className="displayFlex justifyContent_end mleft_5">
                <Row>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Tracking Id:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10">
                      <input
                        type="text"
                        value={trackingId}
                        className={
                          trackingId?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          trackingId?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setTracckingId(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Event:
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={eventStatusList?.data?.data || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      handleChange={(selectedOption) => {
                        setEvent(
                          selectedOption ? selectedOption.key : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpenregion}
                      onMenuClose={handleMenuCloseregion}
                      menuIsOpen={isReactselectRegion}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Event"
                      customStyles={{ control: { width: "190px" } }}
                      className={
                        event?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Country:
                    </label>
                    <SearchInput
                      id="countryCode"
                      label="countryCode"
                      name="countryName"
                      options={countriesData?.data?.data?.content || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode3}
                      handleChange={(selectedOption) => {
                        setCountry(
                          selectedOption ? selectedOption.isoCode3 : ""
                        );
                      }}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={isReactselectOpen}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Country"
                      customStyles={{ control: { width: "190px" } }}
                      className={
                        country?.length > 0
                          ? "mainInput"
                          : "mainInput withSearchIcon"
                      }
                    />
                  </Col>
                  <Col className="ml_5">
                    <label className="fontSize12 mb_12 marginR10 mt_10">
                      Receiver phone number:
                    </label>
                    <div className="displayFlex alignItemCenter marginR10">
                      <input
                        type="text"
                        value={phoneNum}
                        className={
                          phoneNum?.length > 0
                            ? "mainInput"
                            : "mainInput withSearchIcon"
                        }
                        style={
                          phoneNum?.length > 0
                            ? { border: "2px solid #ec171c" }
                            : {}
                        }
                        onChange={(e) => setPhoneNum(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
            </div>
            <div className="margin_top40">
              <div className="displayFlex gap_12">
                  <button
                    className="filterBtn"
                    title={"Export Template"}
                    onClick={() => exportToExcel()}
                  >
                    <span>
                      <img src={downloadicon} className="h18" />
                    </span>
                  </button>
                <button
                  className="filterBtn plus_icon"
                  title="Sync"
                  onClick={() => syncronizeRoles()}
                >
                  <span>
                    <img src={syncicon} className="h18" />
                  </span>
                </button>
                <div ref={ref} className="customFilter">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={DotboldIcon} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ul className="listColumn cursorPointer">
                          {column.map((d, i) => (
                            <li key={d}>
                              <div className="smallCheckbox mb_4">
                                <input
                                  id={`product${i}`}
                                  type="checkbox"
                                  checked={!checkedCol.includes(d)}
                                />
                                <label
                                  htmlFor={`product${i}`}
                                  onClick={() => handleSelect(d)}
                                >
                                  {d}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        {!editPage && (
          <PaginationTable
            tableData={templateData}
            tbl={"MessageHistory"}
            modalHandler={modalHandler}
            restrict={checkedCol}
            setModalHandler={setModalHandler}
            deleteHandler={deleteHandler}
            setDeleteHandler={setDeleteHandler}
            newPage={newPage}
            setIndividualCheckboxesBag={setIndividualCheckboxesBag}
            individualCheckboxesbag={individualCheckboxesbag}
            setNewPage={setNewPage}
            size={size}
            isMenuOpen={isMenuOpen}
            setSize={setSize}
            totalCount={totalCount}
            sortField={sortField}
            setSortField={setSortField}
          />
        )}
      </div>
    </div>
  );
};

export default SmsHistory;
