import AoneService from "../axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

//RTO Request
//get RTO Request
export const getRtoRequest = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/rto/request${params}`);
    } catch (error) {
      return error;
    }
  };
//get rto by id
export const getRtobyId = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/rto/request/${params}`);
  } catch (error) {
    return error;
  }
};
//get rto by packageid
export const getRtobyPackageId = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/rto/request/package/${params}`);
  } catch (error) {
    return error;
  }
};
//submit single rto request
export const submitSingleRtoRequest = async (params) => {
  try {
    return await AoneService.patch(`ops/api/v1/rto/request/package/${params}`);
  } catch (error) {
    return error;
  }
};
//submit rto request
export const submitRtoRequest = async (params) => {
    try {
      return await AoneService.patch(`ops/api/v1/rto/request/${params}`);
    } catch (error) {
      return error;
    }
  };
//export pickup request
export const exportRtoRequest = async (params) => {
    try {
      return await AoneService.get(`ops/api/v1/rto/request/export${params}`, {
        responseType: "blob",
      });
    } catch (error) {
      return error;
    }
  };
  
  //RTO Packagerequest
  //scan rto package
  export const scanRtoPackage = async (params,params2) => {
    try {
      return await AoneService.patch(`ops/api/v1/rto/${params}/package/${params2}`);
    } catch (error) {
      return error;
    }
  };
  //mark rto package exception
  export const markPackageException = async (params,params2,data) => {
    try {
      return await AoneService.patch(`ops/api/v1/rto/${params}/package/${params2}/exception`,data);
    } catch (error) {
      return error;
    }
  };
  //remove rto package
  export const deleteRtoPackage = async (params,params2) => {
    try {
      return await AoneService.delete(`ops/api/v1/rto/${params}/package/${params2}`);
    } catch (error) {
      return error;
    }
  }
    //discard rto scan
    export const discardRtoScan = async (params) => {
      try {
        return await AoneService.patch(`ops/api/v1/rto/request/${params}/discard`);
      } catch (error) {
        return error;
      }
    }
  //cancel rto
  export const cancelRto = async (params) => {
    try {
      return await AoneService.patch(`/ops/api/v1/rto/request/${params}/cancel`);
    } catch (error) {
      return error;
    }
  }
    //warning Type
    export const listWarningType = async () => {
      try {
        return await AoneService.get(`ops/api/v1/lookups/PackageExceptionType`);
      } catch (error) {
        return error;
      }
    };
    export const listRtoStatus = async () => {
      try {
        return await AoneService.get(`ops/api/v1/lookups/RTORequestStatus`);
      } catch (error) {
        return error;
      }
    };

