import React, { useState, useEffect, useContext } from "react";
import { useAoneStore } from "../../store/AoneStore";
import SearchInput from "../../components/dropdown/autoCompleteInputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import isBefore from "date-fns/isBefore";
import openEye from "../../assets/img/eye_show.svg";
import closeEye from "../../assets/img/eye_hide.svg";
import { UserContext } from "../../helper/ProtectedRoute";
import { roleHandler } from "../../helper/roleHandler";
const Account = ({ setTab }) => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const [passwordHandler, setPasswordHandler] = useState(false);
  const [nationalHandler, setNationalHandler] = useState(false);
  const [phoneHandler, setPhoneHandler] = useState(false);
  const [addressHandler, setAddressHandler] = useState(false);
  const postEmployee = useAoneStore((state) => state?.postEmployee);
  const getEmployeebyId = useAoneStore((state) => state?.getEmployeebyId);
  const editEmployees = useAoneStore((state) => state?.editEmployees);
  const getCountries = useAoneStore((state) => state?.getCountries);
  const setglobalEmail = useAoneStore((state) => state?.setglobalEmail);
  const getCountryArray = useAoneStore((state) => state?.getCountryArray);
  const setglobalFirstname = useAoneStore((state) => state?.setglobalFirstname);
  const setglobalLastname = useAoneStore((state) => state?.setglobalLastname);
  const setglobalDepartment = useAoneStore(
    (state) => state?.setglobalDepartment
  );
  const setglobalHub = useAoneStore((state) => state?.setglobalHub);
  const setglobalPrefferedLanguage = useAoneStore(
    (state) => state?.setglobalPrefferedLanguage
  );
  const employeeAccountData = useAoneStore(
    (state) => state?.employeeAccountData
  );
  const setglobalEmployeeStatus = useAoneStore(
    (state) => state?.setglobalEmployeeStatus
  );
  const setglobalUserStatus = useAoneStore(
    (state) => state?.setglobalUserStatus
  );
  const getCountryByCity = useAoneStore((state) => state?.getCountryByCity);
  const citybyCountyData = useAoneStore((state) => state?.citybyCountyData);
  const setglobalEmployeeCode = useAoneStore(
    (state) => state?.setglobalEmployeeCode
  );
  const setglobalJobtitle = useAoneStore((state) => state?.setglobalJobtitle);
  const getCities = useAoneStore((state) => state?.getCities);
  const getFieldName = useAoneStore((state) => state?.getFieldName);
  const employeePayrolltype = useAoneStore(
    (state) => state?.employeePayrolltype
  );
  const employeePayrollCompany = useAoneStore(
    (state) => state?.employeePayrollCompany
  );
  const employeeLegacySystem = useAoneStore(
    (state) => state?.employeeLegacySystem
  );
  const employeeDepartments = useAoneStore(
    (state) => state?.employeeDepartments
  );
  const getJobtitlebyDept = useAoneStore((state) => state?.getJobtitlebyDept);
  const jobTitleData = useAoneStore((state) => state?.jobTitleData);
  const countriesData = useAoneStore((state) => state?.countriesData);
  const deptData = useAoneStore((state) => state?.deptData);
  const citiesData = useAoneStore((state) => state?.citiesData);
  const jobsData = useAoneStore((state) => state?.jobsData);
  const departmentData = useAoneStore((state) => state?.departmentData);
  const payrolltypeData = useAoneStore((state) => state?.payrolltypeData);
  const payrollcompanyData = useAoneStore((state) => state?.payrollcompanyData);
  const legacyData = useAoneStore((state) => state?.legacyData);
  const [phoneNumberRegex, setphoneNumberRegex] = useState("");
  const [data, setData] = useState([]);
  const today = new Date();
  const shouldDisableDate = (date) => {
    // Disable dates before yesterday
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    return isBefore(date, yesterday);
  };
  const [deptTransformData, setDeptData] = useState([]);
  const [jobTransformData, setJobData] = useState([]);
  const setcustomerAccount = useAoneStore((state) => state?.setcustomerAccount);
  useEffect(() => {
    if (payrolltypeData?.data?.data === undefined) {
      employeePayrolltype();
    }
    if (payrollcompanyData?.data?.data === undefined) {
      employeePayrollCompany();
    }
    if (legacyData?.data?.data === undefined) {
      employeeLegacySystem();
    }
    let paramslink = `?page=${0}&size=${20}`;
    if (countriesData?.data?.data?.content?.length === undefined) {
      getCountries(paramslink);
    }
    if (citiesData?.data?.data?.content?.length === undefined) {
      getCities(paramslink);
    }
  }, []);
  useEffect(() => {
    const fetchDepartmentLookup = async () => {
      if (deptData?.data?.data === undefined) {
        try {
          const data = await employeeDepartments();
          const transformedData = data?.data?.data?.map((dept) => ({
            key: dept,
            label: dept,
          }));
          setDeptData(transformedData);
        } catch (error) {
          console.error("Error fetching department data:", error);
        }
      } else {
        const transformedData = deptData?.data?.data?.map((dept) => ({
          key: dept,
          label: dept,
        }));
        setDeptData(transformedData);
      }
    };
    fetchDepartmentLookup();
  }, []);
  const statusOptions = [
    { value: "", label: "None" },
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
  ];
  const handleCancelClick = () => {
    setglobalEmployeeCode("");
    navigate("/crm/employees");
  };
  const [state] = useContext(UserContext);
  const [role, setRole] = useState(sessionStorage.getItem("user_access_role"));
  useEffect(() => {
    setRole(state.roles);
  }, [state.roles]);
  const handleChange = (e) => {
    const newAccountNumber = e.target.value;
    // Update the local form state
    addAccount.setFieldValue("accountNumber", newAccountNumber);
    // Set the accountNumber in the global state
    setcustomerAccount(newAccountNumber);
  };
  const [isHide, isNotHide] = useState(false);
  //save account
  const addAccount = useFormik({
    initialValues: {
      code: params?.id ? data?.code : "",
      firstName: params?.id ? data?.firstName : "",
      lastName: params?.id ? data?.lastName : "",
      countryCode: params?.id ? data?.countryCode : "",
      cityCode: params?.id ? data?.cityCode : "",
      phoneNumber: params?.id ? data?.phoneNumber : "",
      department: params?.id ? data?.department : "",
      jobTitle: params?.id ? data?.jobTitle : "",
      payrollType: params?.id ? data?.payrollType : "",
      payrollCompany: params?.id ? data?.payrollCompany : "",
      detailedAddress: params?.id ? data?.detailedAddress : "",
      legacySystem: params?.id ? data?.legacySystem : "",
      legacyUserName: params?.id ? data?.legacyUserName : "",
      email: params?.id ? data?.email : "",
      nationalId: params?.id ? data?.nationalId : "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(/^[^\s]+$/, "Code cannot contain spaces")
        .min(1, "Code is required"),
      firstName: Yup.string()
        .required("First Name is required")
        .min(2, "First Name must be at least 2 characters long")
        .max(20, "First Name must be at most 20 characters long"),
      lastName: Yup.string()
      .matches(
        /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-'`]+$/,
        "Last Name must contain at least one non-numeric character"
      )
        .required("Last Name is required")
        .min(2, "Last Name must be at least 2 characters long")
        .max(20, "Last Name must be at most 20 characters long"),
      email: Yup.string()
        .matches(/^[^\s]+$/, "Email cannot contain spaces")
        .email("Invalid email address")
        .required("Email is required"),
      countryCode: Yup.string().required("Country is required"),
      cityCode: Yup.string().required("City is required"),
      phoneNumber: Yup.string()
        .required("Phone is required")
        .matches(/^[^\s]+$/, "Phone number cannot contain spaces")
        .matches(/^[0-9+]+$/, "Phone number must contain only numbers")
        .min(1, "Phone is required"),
      department: Yup.string().required("Department is required"),
      payrollType: Yup.string().required("Payroll type is required"),
      payrollCompany: Yup.string().required("Payroll company is required"),
      jobTitle: Yup.string().required("Jobtitle is required"),
      nationalId: Yup.string()
        .trim()
        .required("National ID is required")
        .min(1, "National ID is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setglobalJobtitle(values.jobTitle);
      setglobalEmployeeCode(values.code);
      setglobalEmail(values.email);
      setglobalFirstname(values.firstName);
      setglobalLastname(values.lastName);
      setglobalDepartment(values.department);
      const changedValues = Object.keys(values).reduce((result, key) => {
        if (values[key] !== data[key]) {
          result[key] = values[key];
        }
        return result;
      }, {});
      if (params.id) {
        const valuestoSend = { ...changedValues };
        valuestoSend.phoneNumber =
          addAccount.values.phonecodeprefix + addAccount.values.phoneNumber;
        const updatedValues = {
          ...valuestoSend,
        };
        if (Object.keys(changedValues).length === 0) {
          toast.error("Make changes to update");
        } else {
          await editEmployees(params?.id, updatedValues).then((response) => {
            if (response?.data?.status == "ERROR") {
              const errorMessage = response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else if (response?.response?.data?.status == "ERROR") {
              const errorMessage = response?.response?.data?.msg.split(",")[0];
              toast.error(errorMessage);
            } else {
              toast.success("Employee Updated");
              setTab(2);
              resetForm();
            }
          });
        }
      } else {
        const valuetoSend = { ...values };
        valuetoSend.phoneNumber =
          addAccount.values.phonecodeprefix + addAccount.values.phoneNumber;
        const updatedValue = {
          ...valuetoSend,
        };
        await postEmployee(updatedValue).then((response) => {
          if (response?.data?.status == "ERROR") {
            const errorMessage = response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else if (response?.response?.data?.status == "ERROR") {
            const errorMessage = response?.response?.data?.msg.split(",")[0];
            toast.error(errorMessage);
          } else {
            toast.success("Employee Added");
            setglobalUserStatus(false);
            setTab(2);
            resetForm();
          }
        });
      }
    },
  });
  const [error, setError] = useState(null);
  useEffect(() => {
    const { phonecodeprefix, phoneNumber } = addAccount.values;

    if (phonecodeprefix && phoneNumber.startsWith(phonecodeprefix)) {
      const updatedPhone = phoneNumber.replace(phonecodeprefix, "");
      addAccount.setFieldValue("phoneNumber", updatedPhone);
    }
  }, [addAccount.values.phonecodeprefix, addAccount.values.phoneNumber]);
  useEffect(() => {
    if (phoneNumberRegex === null) {
      setError("");
      return;
    }
    const regex = new RegExp(phoneNumberRegex);
    // Check if addAccount.values.phoneNumber matches the regex pattern
    if (
      addAccount.values.phoneNumber &&
      !regex.test(
        addAccount.values.phonecodeprefix + addAccount.values.phoneNumber
      )
    ) {
      // If it doesn't match, set the error message
      setError("Invalid phone number format");
    } else {
      // If it matches, clear the error
      setError("");
    }
  }, [addAccount.values.phoneNumber, phoneNumberRegex]);
  useEffect(() => {
    const mappedFields = Object.keys(addAccount.values).reduce((acc, field) => {
      acc[field] = data?.[field] || "";
      return acc;
    }, {});
    // Set form field values based on data
    addAccount.setValues((prevValues) => ({
      ...prevValues,
      ...mappedFields,
    }));
  }, [data]);
  useEffect(() => {
    if (params?.id !== undefined) {
      getData();
      isNotHide(true);
    }
  }, [params?.id]);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (params?.id) {
      setTimeout(() => {
        setIsEdit(true);
      }, 1000);
    }
  }, []);
  useEffect(() => {
    if (!params.id) {
      addAccount.setFieldValue("cityCode", "");
    }
  }, [addAccount?.values?.countryCode]);
  useEffect(() => {
    if (params.id && isEdit) {
      addAccount.setFieldValue("cityCode", "");
    }
  }, [addAccount?.values?.countryCode]);
  const getData = async () => {
    const response = await getEmployeebyId(params.id);
    const newData = response?.data?.data;
    // Check if legacySystem is null and set it to ""
    if (newData && newData.legacySystem === null) {
      newData.legacySystem = "";
    }
    if (newData && newData.legacyUserName === null) {
      newData.legacyUserName = "";
    }
    setData(newData);
    let resp = await getCountryArray(response?.data?.data.countryCode);
    addAccount.setFieldValue(
      "phonecodeprefix",
      resp?.data?.data?.phoneCodePrefix ? resp?.data?.data?.phoneCodePrefix : ""
    );
    const res = await getCountryByCity(
      response?.data?.data.countryCode
    );
    setphoneNumberRegex(resp?.data?.data?.phoneNumberRegex);
    setglobalEmployeeCode(response?.data?.data?.code);
    setglobalDepartment(response?.data?.data?.department);
    setglobalEmail(response?.data?.data?.email);
    setglobalFirstname(response?.data?.data?.firstName);
    setglobalLastname(response?.data?.data?.lastName);
    setglobalJobtitle(response?.data?.data?.jobTitle);
    setglobalEmployeeStatus(response?.data?.data?.status);
    setglobalUserStatus(response?.data?.data?.userCreated);
    setglobalHub(response?.data?.data?.hubCode);
    setglobalPrefferedLanguage(response?.data?.data?.preferredLanguage);
    const jobData = await getJobtitlebyDept(response?.data?.data?.department);
    const transformedJobData = jobData?.data?.data?.map((dept) => ({
      key: dept,
      label: dept,
    }));
    setJobData(transformedJobData);
  };
  const togglePasswordHandler = async () => {
    setPasswordHandler(!passwordHandler);
    // Call getFieldName when the eye icon is clicked
    if (params?.id && !passwordHandler) {
      try {
        const response = await getFieldName(params.id, "email");
        addAccount.setFieldValue("email", response?.data?.data);
      } catch (error) {
        console.error("Error fetching field name:", error);
      }
    }
  };
  const togglePhoneHandler = async () => {
    setPhoneHandler(!phoneHandler);
    // Call getFieldName when the eye icon is clicked
    if (params?.id && !phoneHandler) {
      try {
        const response = await getFieldName(params.id, "phoneNumber");
        addAccount.setFieldValue("phoneNumber", response?.data?.data);
        isNotHide(false);
      } catch (error) {
        console.error("Error fetching field name:", error);
      }
    }
  };
  const toggleNationalHandler = async () => {
    setNationalHandler(!nationalHandler);
    // Call getFieldName when the eye icon is clicked
    if (params?.id && !nationalHandler) {
      try {
        const response = await getFieldName(params.id, "nationalId");
        addAccount.setFieldValue("nationalId", response?.data?.data);
      } catch (error) {
        console.error("Error fetching field name:", error);
      }
    }
  };
  const toggleAddressHandler = async () => {
    setAddressHandler(!addressHandler);
    // Call getFieldName when the eye icon is clicked
    if (params?.id && !addressHandler) {
      try {
        const response = await getFieldName(params.id, "detailedAddress");
        addAccount.setFieldValue("detailedAddress", response?.data?.data);
      } catch (error) {
        console.error("Error fetching field name:", error);
      }
    }
  };
  const [firstNameErr,setFirstNameErr] = useState("")
  const [lastNameErr,setLastNameErr] = useState("")
  const handleSave = () => {
    if (!error) {
      if(/^\s|\s$/.test(addAccount.values.firstName)){
        setFirstNameErr(
          "First Name cannot contain leading or trailing spaces"
        );
      }else if(/^\s|\s$/.test(addAccount.values.lastName)){
        setLastNameErr(
            "Last Name cannot contain leading or trailing spaces"
          );
      }else{
        setFirstNameErr("");
        setLastNameErr("");
        addAccount.handleSubmit();
      }
    } else {
    }
  };
  return (
    <div className="row">
      <div className="col-md-4" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Code:{" "}
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                placeholder="Code"
                className="mainInput"
                name="code"
                value={addAccount.values.code}
                disabled={params?.id !== undefined ?? true}
                onChange={(e) =>
                  addAccount.setFieldValue("code", e.target.value)
                }
                tabIndex="1"
              />
            </div>
            {addAccount.touched.code && addAccount.errors.code ? (
              <p className="requiredText">{addAccount.errors.code}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Email:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              {!params?.id ? (
                <input
                  type={"text"}
                  placeholder="Email"
                  className="mainInput"
                  name="email"
                  value={addAccount.values.email}
                  autoComplete="off"
                  onChange={(e) =>
                    addAccount.setFieldValue("email", e.target.value)
                  }
                  tabIndex="4"
                />
              ) : (
                <div className="Groupinput">
                  <input
                    type={passwordHandler ? "text" : "password"}
                    placeholder="Email"
                    className="mainInput"
                    name="email"
                    value={addAccount.values.email}
                    autoComplete="off"
                    onChange={(e) =>
                      addAccount.setFieldValue("email", e.target.value)
                    }
                    tabIndex="4"
                  />
                  <div className="inputgroup_append">
                    {roleHandler(role, "AONE_CRM.write") ||
                    roleHandler(role, "AONE_CRM.admin") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                    roleHandler(
                      role,
                      "AONE_CRM_EMPLOYEES.view_sensitive_data"
                    ) ? (
                      <span
                        className="Groupinput_text"
                        onClick={togglePasswordHandler}
                      >
                        <img src={passwordHandler ? closeEye : openEye} />
                      </span>
                    ) : (
                      <span className="Groupinput_text"></span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {addAccount.touched.email && addAccount.errors.email ? (
              <p className="requiredText">{addAccount.errors.email}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Phone Number:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-2">
            <div className="Grpinput">
              <input
                className="mainInput"
                name="phonecodeprefix"
                value={addAccount.values.phonecodeprefix}
                autoComplete="off"
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="Grpinput">
              {!params?.id ? (
                <input
                  type={"text"}
                  placeholder="Phone Number"
                  className="mainInput"
                  name="phoneNumber"
                  value={addAccount.values.phoneNumber}
                  onChange={(e) =>
                    addAccount.setFieldValue("phoneNumber", e.target.value)
                  }
                  tabIndex="7"
                />
              ) : (
                <div className="Groupinput">
                  <input
                    type={phoneHandler ? "text" : "password"}
                    placeholder="Phone Number"
                    className="mainInput"
                    name="phoneNumber"
                    value={addAccount.values.phoneNumber}
                    onChange={(e) =>
                      addAccount.setFieldValue("phoneNumber", e.target.value)
                    }
                    tabIndex="7"
                  />
                  <div className="inputgroup_append">
                    {roleHandler(role, "AONE_CRM.write") ||
                    roleHandler(role, "AONE_CRM.admin") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                    roleHandler(
                      role,
                      "AONE_CRM_EMPLOYEES.view_sensitive_data"
                    ) ? (
                      <span
                        className="Groupinput_text"
                        onClick={togglePhoneHandler}
                      >
                        <img src={phoneHandler ? closeEye : openEye} />
                      </span>
                    ) : (
                      <span className="Groupinput_text"></span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {addAccount.touched.phoneNumber && addAccount.errors.phoneNumber ? (
              <p className="requiredText">{addAccount.errors.phoneNumber}</p>
            ) : null}
            {error && <p className="requiredText">{error}</p>}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Payroll Type:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <SearchInput
              id="payrollType"
              label="payrollType"
              name="payrollType"
              isClearable={true}
              options={payrolltypeData?.data?.data || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "payrollType",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                payrolltypeData?.data?.data?.find(
                  (account) => account.key === addAccount.values.payrollType
                ) || null
              }
              isSearchable={true}
              placeholder="Payroll Type"
              tabIndex={10}
            />
              {addAccount.touched.payrollType && addAccount.errors.payrollType ? (
              <p className="requiredText">{addAccount.errors.payrollType}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Legacy System:
          </label>
          <div className="col-md-8">
            <SearchInput
              id="legacySystem"
              label="legacySystem"
              name="legacySystem"
              isClearable={true}
              options={legacyData?.data?.data || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "legacySystem",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                legacyData?.data?.data?.find(
                  (account) => account.key === addAccount.values.legacySystem
                ) || null
              }
              isSearchable={true}
              placeholder="Legacy System"
              tabIndex={13}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            First Name:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                placeholder="First Name"
                className="mainInput"
                name="firstName"
                value={addAccount.values.firstName}
                onChange={(e) =>{
                  addAccount.setFieldValue("firstName", e.target.value);
                  setFirstNameErr("");
                }
                }
                autoComplete="off"
                tabIndex="2"
              />
            </div>
            {(addAccount.touched.firstName && addAccount.errors.firstName && firstNameErr !== "") ? (
              <p className="requiredText">{addAccount.errors.firstName}</p>
            ) : null}
             {firstNameErr !== "" ? (
              <p className="requiredText">{firstNameErr}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Country:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <SearchInput
              id="countryCode"
              label="countryCode"
              name="countryCode"
              isClearable={true}
              options={countriesData?.data?.data?.content || []}
              getOptionLabel={(region) => region.name}
              getOptionValue={(region) => region.isoCode3}
              handleChange={async (selectedOption) => {
                addAccount.setFieldValue(
                  "countryCode",
                  selectedOption ? selectedOption.isoCode3 : ""
                );
                addAccount.setFieldValue(
                  "phonecodeprefix",
                  selectedOption.phoneCodePrefix
                    ? selectedOption?.phoneCodePrefix
                    : ""
                );
                setphoneNumberRegex(selectedOption?.phoneNumberRegex);
                // Check if a country is selected before making the API call
                if (selectedOption) {
                  try {
                    const response = await getCountryByCity(
                      selectedOption.isoCode3
                    );
                  } catch (error) {}
                }
              }}
              value={
                countriesData?.data?.data?.content?.find(
                  (country) =>
                    country.isoCode3 === addAccount.values.countryCode
                ) || null
              }
              isSearchable={true}
              placeholder="Select Country"
              tabIndex="5"
            />
            {addAccount.touched.countryCode && addAccount.errors.countryCode ? (
              <p className="requiredText">{addAccount.errors.countryCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Department:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <SearchInput
              id="department"
              label="department"
              name="department"
              isClearable={true}
              options={deptTransformData || []}
              getOptionLabel={(dept) => dept.label}
              getOptionValue={(dept) => dept.key}
              handleChange={async (selectedOption) => {
                addAccount.setFieldValue(
                  "department",
                  selectedOption ? selectedOption.key : ""
                );
                try {
                  const jobData = await getJobtitlebyDept(selectedOption.key);
                  const transformedJobData = jobData?.data?.data?.map(
                    (dept) => ({
                      key: dept,
                      label: dept,
                    })
                  );
                  setJobData(transformedJobData);
                } catch (error) {
                  console.error("Error fetching job data:", error);
                }
              }}
              value={
                deptTransformData?.find(
                  (account) => account.key === addAccount.values.department
                ) || null
              }
              isSearchable={true}
              placeholder="Department"
              tabIndex={7}
            />
            {addAccount.touched.department && addAccount.errors.department ? (
              <p className="requiredText">{addAccount.errors.department}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Payroll Company:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <SearchInput
              id="payrollCompany"
              label="payrollCompany"
              name="payrollCompany"
              isClearable={true}
              options={payrollcompanyData?.data?.data || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "payrollCompany",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                payrollcompanyData?.data?.data?.find(
                  (account) => account.key === addAccount.values.payrollCompany
                ) || null
              }
              isSearchable={true}
              placeholder="Payroll Company"
              tabIndex={11}
            />
              {addAccount.touched.payrollCompany && addAccount.errors.payrollCompany ? (
              <p className="requiredText">{addAccount.errors.payrollCompany}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Legacy username:
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                placeholder="Legacy username"
                className="mainInput"
                name="legacyUserName"
                value={addAccount.values.legacyUserName}
                onChange={(e) =>
                  addAccount.setFieldValue("legacyUserName", e.target.value)
                }
                autoComplete="off"
                tabIndex={14}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4" style={{ padding: "12px" }}>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Last Name:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              <input
                type="text"
                placeholder="Last Name"
                className="mainInput"
                name="lastName"
                value={addAccount.values.lastName}
                onChange={(e) =>{
                  addAccount.setFieldValue("lastName", e.target.value);
                  setLastNameErr("");
                }}
                autoComplete="off"
                tabIndex="3"
              />
            </div>
            {(addAccount.touched.lastName && addAccount.errors.lastName && lastNameErr == "") ? (
              <p className="requiredText">{addAccount.errors.lastName}</p>
            ) : null}
             {lastNameErr !== "" ? (
              <p className="requiredText">{lastNameErr}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            City:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <SearchInput
              id="cityCode"
              label="cityCode"
              name="cityCode"
              isClearable={true}
              options={citybyCountyData?.data?.data || []}
              getOptionLabel={(region) => region.name}
              getOptionValue={(region) => region.code}
              handleChange={(selectedOption) => {
                addAccount.setFieldValue(
                  "cityCode",
                  selectedOption ? selectedOption.code : ""
                );
              }}
              value={
                citybyCountyData?.data?.data?.find(
                  (city) => city.code === addAccount.values.cityCode
                ) || null
              }
              isSearchable={true}
              placeholder="Select City"
              tabIndex="6"
            />
            {addAccount.touched.cityCode && addAccount.errors.cityCode ? (
              <p className="requiredText">{addAccount.errors.cityCode}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Job Title:{" "}
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <SearchInput
              id="jobTitle"
              label="jobTitle"
              name="jobTitle"
              isClearable={true}
              options={jobTransformData || []}
              getOptionLabel={(region) => region.label}
              getOptionValue={(region) => region.key}
              handleChange={(selectedOption) =>
                addAccount.setFieldValue(
                  "jobTitle",
                  selectedOption ? selectedOption.key : ""
                )
              }
              value={
                jobTransformData?.find(
                  (account) => account.key === addAccount.values.jobTitle
                ) || null
              }
              isSearchable={true}
              placeholder="Job Title"
              tabIndex={9}
            />
            {addAccount.touched.jobTitle && addAccount.errors.jobTitle ? (
              <p className="requiredText">{addAccount.errors.jobTitle}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            National Id:
            <span className="bloodDonortext fontWeight600 fontSize16">*</span>
          </label>
          <div className="col-md-8">
            <div className="Grpinput">
              {!params?.id ? (
                <input
                  type={"text"}
                  placeholder="National Id"
                  className="mainInput"
                  name="nationalId"
                  value={addAccount.values.nationalId}
                  onChange={(e) =>
                    addAccount.setFieldValue("nationalId", e.target.value)
                  }
                  tabIndex={12}
                />
              ) : (
                <div className="Groupinput">
                  <input
                    type={nationalHandler ? "text" : "password"}
                    placeholder="National Id"
                    className="mainInput"
                    name="nationalId"
                    value={addAccount.values.nationalId}
                    onChange={(e) =>
                      addAccount.setFieldValue("nationalId", e.target.value)
                    }
                    tabIndex={12}
                  />
                  <div className="inputgroup_append">
                    {roleHandler(role, "AONE_CRM.write") ||
                    roleHandler(role, "AONE_CRM.admin") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                    roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                    roleHandler(
                      role,
                      "AONE_CRM_EMPLOYEES.view_sensitive_data"
                    ) ? (
                      <span
                        className="Groupinput_text"
                        onClick={toggleNationalHandler}
                      >
                        <img src={nationalHandler ? closeEye : openEye} />
                      </span>
                    ) : (
                      <span className="Groupinput_text"></span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {addAccount.touched.nationalId && addAccount.errors.nationalId ? (
              <p className="requiredText">{addAccount.errors.nationalId}</p>
            ) : null}
          </div>
        </div>
        <div className="row mb_22">
          <label className="fontSize14 mb_12 marginR10 mt_10 menacingCloudsText fontWeight500">
            Detailed Address:
          </label>
          <div className="col-md-8">
            {!params?.id ? (
              <textarea
                className="washMe_button"
                placeholder="Detailed Address"
                name="detailedAddress"
                onChange={(e) =>
                  addAccount.setFieldValue("detailedAddress", e.target.value)
                }
                onBlur={addAccount.handleBlur}
                value={addAccount.values.detailedAddress}
                style={{ height: "90px", paddingTop: "6px" }}
                tabIndex={15}
                onKeyDown={(e)=> {
                  if(e?.key === "Tab") {
                    e.preventDefault();
                  }
                }}
              ></textarea>
            ) : (
              <div className="Groupinput">
                <textarea
                  className="washMe_button"
                  placeholder="Detailed Address"
                  name="detailedAddress"
                  onChange={(e) =>
                    addAccount.setFieldValue("detailedAddress", e.target.value)
                  }
                  onBlur={addAccount.handleBlur}
                  value={
                    addressHandler
                      ? addAccount.values.detailedAddress
                      : addAccount.values.detailedAddress?.replace(
                          /./g,
                          "●\u2004"
                        )
                  }
                  style={{
                    fontFamily: "monospace",
                    height: "90px",
                    paddingTop: addressHandler ? "8px" : "16px",
                    paddingBottom: addressHandler ? "8px" : "16px",
                    fontSize: addressHandler ? "0.85em" : "0.35em",
                    lineHeight: addressHandler ? "1.5" : "1",
                    letterSpacing: addressHandler ? "0em" : "0.001em",
                  }}
                  disabled={!addressHandler}
                  tabIndex={15}
                  onKeyDown={(e)=> {
                    if(e?.key === "Tab") {
                      e.preventDefault();
                    }
                  }}
                ></textarea>
                {roleHandler(role, "AONE_CRM.write") ||
                roleHandler(role, "AONE_CRM.admin") ||
                roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
                roleHandler(role, "AONE_CRM_EMPLOYEES.admin") ||
                roleHandler(role, "AONE_CRM_EMPLOYEES.view_sensitive_data") ? (
                  <span
                    className="eye-icon"
                    onClick={toggleAddressHandler}
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={addressHandler ? closeEye : openEye}
                      alt="Toggle visibility"
                    />
                  </span>
                ) : (
                  <span
                    className="eye-icon"
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                  ></span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12 displayFlex alignItemCenter justifyContent_center gap_16">
        {(roleHandler(role, "AONE_CRM_EMPLOYEES.write") ||
          roleHandler(role, "AONE_CRM.admin") ||
          roleHandler(role, "AONE_CRM.write") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES.view_sensitive_data") ||
          roleHandler(role, "AONE_CRM_EMPLOYEES.admin")) && (
          <button type="button" className="blue_btn" onClick={handleSave}>
            Save
          </button>
        )}
        <button onClick={handleCancelClick} className="cancel_btn">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Account;
