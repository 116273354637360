import React ,{ useState, useRef, useEffect }  from 'react'
import searchicon from "../../src/assets/img/searchicon.svg";
import editicon from "../../src/assets/img/editicon.svg";
import Modal from 'react-bootstrap/Modal';
import { useAoneStore } from '../store/AoneStore';
import PaginationTable from '../components/Tables/paginationTable';

const Management = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCustomers = useAoneStore((state) => state?.getCustomers)

  const [ customers, setCustomers ] = useState([])

  const [ name, setName ] = useState("")
  const [ newPage, setNewPage ] = useState(0)
  const [ size, setSize ] = useState(20)
  const [ totalCount, setTotalCount ] = useState(0)

  const prevent = useRef(false)

  const getData = async () => {
    const params = `?page=${newPage}&size=${size}&mainAccount=${name}`
    const data = await getCustomers(params)
    setCustomers(data?.data)
    // setTotalCount(data.data.body.data.data.count)
    // if(data.data.body.data.code == 200){
    //     setRoles(data.data.body.data.data.data)
    // }
  }

  useEffect(() => {
    if(prevent?.current){
      const delayFn = setTimeout(() => {
        getData();
      }, 1500)  
      return () => clearTimeout(delayFn)
    }else{
      prevent.current = true
    }
  }, [name])

  useEffect(()=>{
    getData();
  },[newPage,size,show])  

  return (
    <div>
      <div>

      <div className='row alignItemCenter'>
        <div className='col-md-7'>
          <h3 className='mainheadtext'>
          Customer Management
          </h3>
        </div>

        <div className='displayFlex col-md-5 justifyContent_end'>
          <div className='displayFlex alignItemCenter'>
            <input type='text' value={name} className={name?.length > 0 ? "mainInput" : "mainInput withSearchIcon"}
                      style={
                        name?.length > 0
                          ? { border: '1px solid #ec171c' }
                          : {}
                      } onChange={(e)=>setName(e.target.value)}/>
          </div>
        </div>
      </div>
  
      {/* <div className='table_wrapper overflowScroll mt_20'>
        <table >
          <thead >
            <th>CR Number</th>
            <th>Name</th>
            <th>Billing Country</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr>
              <td>AJX1234</td>
              <td>Name</td>
              <td>KSA</td>
              <td>abc@gmail.com</td>
              <td>0345xxxx</td>
              <td className=''>
                <p className='status_active'>Active</p>
              </td>
              <td>
                <button onClick={handleShow} className='tableBtn'>
                  <span><img src={editicon} className='turnBlack'/></span>
                </button>
              </td>
            </tr>

            <tr>
              <td>AJX1234</td>
              <td>Name</td>
              <td>KSA</td>
              <td>abc@gmail.com</td>
              <td>0345xxxx</td>
              <td className=''>
                <p className='status_inactive'>InActive</p>
              </td>
              <td>
                <button onClick={handleShow} className='tableBtn'>
                  <span><img src={editicon} className='turnBlack'/></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div className='mt_20'>
        <PaginationTable tableData={customers} tbl="Customers" newPage={newPage} modalHandler={show} setModalHandler={setShow}
          setNewPage={setNewPage} size={size} setSize={setSize} totalCount={100} />
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='row'>
        {/* <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Customer Code :</label>
          <input type='text' placeholder='Customer code' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">CRN :</label>
          <input type='text' placeholder='CRN' className='Maininput mb_24'/>
        </div> */}

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Name :</label>      
          <input type='text' placeholder='Name' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Phone Number :</label>
          <input type='number' placeholder='Phone Number' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">City :</label>      
          <input type='text' placeholder='City' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">State :</label>
          <input type='text' placeholder='State' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Country Code :</label>
          <input type='number' placeholder='Country Code' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">PO Box :</label>
          <input type='number' placeholder='PO Box' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Zip Code :</label>
          <input type='number' placeholder='Zip Code' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Currency :</label>
          <input type='text' placeholder='Currency' className='Maininput mb_24'/>
        </div>

        <div className='col-md-12'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Address Line 1 :</label>
          <input type='text' placeholder='Address Line 1' className='Maininput mb_24'/>
        </div>

        <div className='col-md-12'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Address Line 2 :</label>
          <input type='text' placeholder='AddressLine2' className='Maininput mb_24'/>
        </div>

        {/* <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">VAT Number :</label>
          <input type='text' placeholder='VAT Number' className='Maininput mb_24'/>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Customer Accounts :</label>
          <select class="Maininput mb_24" aria-label="Default select example" >
            <option selected>Customer Accounts</option>
            <option value="1">Account 1</option>
            <option value="1">Account 2</option>
          </select>
        </div>

        <div className='col-md-6'>
          <label className="fontSize14 menacingCloudsText fontWeight500 mb_8">Customer Configuration :</label>  
          <select class="Maininput mb_24" aria-label="Default select example" >
            <option selected>Customer Confg</option>
            <option value="1">Auto generate</option>
            <option value="1">Billing Cycle</option>
          </select>
        </div> */}
      </div>     

      </Modal.Body>
        <Modal.Footer>
          <div className="displayFlex alignItemCenter justifyContent_end gap_16">
            <button className='blue_btn'  onClick={handleClose}>
              Save
            </button>
            <button  className='cancel_btn' onClick={handleClose}>
              Cancel
            </button>
          </div>  
        </Modal.Footer>
      </Modal>

      </div>
    </div>
  )
}

export default Management