import AoneService from "./axiosbase";

const url = process.env.REACT_APP_BASE_API_URL;

export const postSla = async (data) => {
  try {
    return await AoneService.post("products/api/v1/products/", data);
  } catch (error) {
    return error;
  }
};

export const editSla = async (data) => {
  try {
    return await AoneService.put("products/api/v1/products/", data);
  } catch (error) {
    return error;
  }
};

export const getSla = async () => {
  try {
    return await AoneService.get("products/api/v1/products");
  } catch (error) {
    return error;
  }
};

export const deleteSla = async (id) => {
  try {
    return await AoneService.delete(`products/api/v1/products/${id}`);
  } catch (error) {
    return error;
  }
};

export const getOrder = async (params) => {
  try {
    return await AoneService.get("orders-adapter/api/v1/order?" + params);
  } catch (error) {
    return error;
  }
  };

export const postOrders = async (data) => {
  try {
    return await AoneService.post("orders-adapter/api/v1/order", data);
  } catch (error) {
    return error;
  }
};

export const postBulkOrder = async (data) => {
  try {
    return await AoneService.post(
      "orders-adapter/api/v1/order/bulk-upload",
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

export const downloadSample = async () => {
  try {
    return await AoneService.get(
      "orders-adapter/api/v1/order/download/upload-sample",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

export const printBill = async (data) => {
  try {
    return await AoneService.post(
      "print/waybill/print?url=true",
      data
      // {
      //   responseType:'blob'
      // }
    );
  } catch (error) {
    return error;
  }
};

export const postMsg = async (data) => {
  try {
    return await AoneService.post("notification/v1/api/sms/bulk", data);
  } catch (error) {
    return error;
  }
};

export const printBulkBill = async (data) => {
  try {
    return await AoneService.post(
      "print/waybill/v2/print?url=true",
      data
      // {
      //   responseType:'blob'
      // }
    );
  } catch (error) {
    return error;
  }
};

export const downloadSampleSms = async () => {
  try {
    return await AoneService.get("notification/v1/api/sms/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};

export const uploadReciver = async (data) => {
  try {
    return await AoneService.post("notification/v1/api/sms/upload", data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const getSmsType = async () => {
  try {
    return await AoneService.get("notification/v1/api/sms/type");
  } catch (error) {
    return error;
  }
};

export const downloadSamplebulk = async () => {
  try {
    return await AoneService.get("print/waybill/v2/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};

export const uploadBulkprint = async (data) => {
  try {
    return await AoneService.post("print/waybill/v2/upload?url=true", data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const getZidProducts = async (params) => {
  try {
    return await AoneService.get("zid/api/v1/products/?" + params);
  } catch (error) {
    return error;
  }
};

export const syncZidProduct = async () => {
  try {
    return await AoneService.get("zid/api/v1/products/sync/all");
  } catch (error) {
    return error;
  }
};

export const putZidProduct = async (params, data) => {
  try {
    return await AoneService.patch("zid/api/v1/products/" + params, data);
  } catch (error) {
    return error;
  }
};

export const getZidProductsByStoreId = async (params) => {
  try {
    return await AoneService.get("zid/api/v1/products/" + params);
  } catch (error) {
    return error;
  }
};

export const syncZidProductByStoreId = async (params) => {
  try {
    return await AoneService.get("zid/api/v1/products/sync/" + params);
  } catch (error) {
    return error;
  }
};

export const getZidMerchants = async () => {
  try {
    return await AoneService.get("zid/api/v1/merchants/");
  } catch (error) {
    return error;
  }
};

export const getZidMerchantsByStoreId = async (params) => {
  try {
    return await AoneService.get("zid/api/v1/merchants/" + params);
  } catch (error) {
    return error;
  }
};

export const postZidMerchants = async (data) => {
  try {
    return await AoneService.post("zid/api/v1/merchants", data);
  } catch (error) {
    return error;
  }
};

export const putZidMerchants = async (params, data) => {
  try {
    return await AoneService.put("zid/api/v1/merchants/" + params, data);
  } catch (error) {
    return error;
  }
};

export const getRoles = async (params) => {
  try {
    return await AoneService.get("user-management/api/v1/roles/" + params);
  } catch (error) {
    return error;
  }
};

export const postRoles = async (data) => {
  try {
    return await AoneService.post("user-management/api/v1/roles/", data);
  } catch (error) {
    return error;
  }
};

export const getRoleById = async (params) => {
  try {
    return await AoneService.get("user-management/api/v1/roles/" + params);
  } catch (error) {
    return error;
  }
};

export const updateRoleById = async (params, data) => {
  try {
    return await AoneService.put(
      `user-management/api/v1/roles/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteRoleById = async (params) => {
  try {
    return await AoneService.delete("user-management/api/v1/roles/" + params);
  } catch (error) {
    return error;
  }
};

export const syncRoles = async () => {
  try {
    return await AoneService.get("user-management/api/v1/roles/sync");
  } catch (error) {
    return error;
  }
};

export const getUsersByRoleIdOrGroupId = async (params) => {
  try {
    return await AoneService.get("user-management/api/v1/users" + params);
  } catch (error) {
    return error;
  }
};

export const getUsersByGroupId = async (params) => {
  try {
    return await AoneService.get(
      "user-management/api/v1/users/groups/" + params
    );
  } catch (error) {
    return error;
  }
};

export const downloadUsersByRoleId = async (params) => {
  try {
    return await AoneService.get(
      `user-management/api/v1/users/role/download` + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

export const getGroups = async (params) => {
  try {
    return await AoneService.get("user-management/api/v1/groups/" + params);
  } catch (error) {
    return error;
  }
};

export const getGroupsTree = async () => {
  try {
    return await AoneService.get("user-management/api/v1/groups/tree");
  } catch (error) {
    return error;
  }
};

export const syncGroups = async () => {
  try {
    return await AoneService.get("user-management/api/v1/groups/sync");
  } catch (error) {
    return error;
  }
};

export const postGroups = async (data) => {
  try {
    return await AoneService.post("user-management/api/v1/groups/", data);
  } catch (error) {
    return error;
  }
};

export const postChildGroups = async (params, data) => {
  try {
    return await AoneService.post(
      `user-management/api/v1/groups/${params}/subgroups`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteGroupById = async (params) => {
  try {
    return await AoneService.delete("user-management/api/v1/groups/" + params);
  } catch (error) {
    return error;
  }
};

export const getGroupsByUserId = async (params) => {
  try {
    return await AoneService.get(
      `user-management/api/v1/users/${params}/groups`
    );
  } catch (error) {
    return error;
  }
};

export const getSubGroups = async (params) => {
  try {
    return await AoneService.get(
      `user-management/api/v1/groups/${params}/subgroups`
    );
  } catch (error) {
    return error;
  }
};

export const getUsers = async (params) => {
  try {
    return await AoneService.get("user-management/api/v1/users/" + params);
  } catch (error) {
    return error;
  }
};

export const postUsers = async (data) => {
  try {
    return await AoneService.post("user-management/api/v1/users/", data);
  } catch (error) {
    return error;
  }
};
export const resetUsersPassword = async (data) => {
  try {
    return await AoneService.post("user-management/api/v1/users/reset-password", data);
  } catch (error) {
    return error;
  }
};
export const putUsersById = async (params, data) => {
  try {
    return await AoneService.put(
      `user-management/api/v1/users/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const getUsersById = async (params) => {
  try {
    return await AoneService.get("user-management/api/v1/users/" + params);
  } catch (error) {
    return error;
  }
};

export const deleteUserById = async (params) => {
  try {
    return await AoneService.delete("user-management/api/v1/users/" + params);
  } catch (error) {
    return error;
  }
};

export const getAssignedRolesByUserId = async (params) => {
  try {
    return await AoneService.get(
      `user-management/api/v1/users/${params}/roles`
    );
  } catch (error) {
    return error;
  }
};

export const assignRolesToUserId = async (params1, params2) => {
  try {
    return await AoneService.post(
      `user-management/api/v1/users/${params1}/role/${params2}`
    );
  } catch (error) {
    return error;
  }
};

export const assignGroupsToUserId = async (params1, params2) => {
  try {
    return await AoneService.post(
      `user-management/api/v1/users/${params1}/group/${params2}`
    );
  } catch (error) {
    return error;
  }
};

export const unassignRolesOfUserId = async (params1, params2) => {
  try {
    return await AoneService.delete(
      `user-management/api/v1/users/${params1}/role/${params2}`
    );
  } catch (error) {
    return error;
  }
};

export const unassignGroupsOfUserId = async (params1, params2) => {
  try {
    return await AoneService.delete(
      `user-management/api/v1/users/${params1}/group/${params2}`
    );
  } catch (error) {
    return error;
  }
};

export const assignRolesToGroupId = async (params1, params2) => {
  try {
    return await AoneService.post(
      `user-management/api/v1/groups/${params1}/role/${params2}`
    );
  } catch (error) {
    return error;
  }
};

export const unassignRolesOfGroupId = async (params1, params2) => {
  try {
    return await AoneService.delete(
      `user-management/api/v1/groups/${params1}/role/${params2}`
    );
  } catch (error) {
    return error;
  }
};

export const getAssignedRolesByGroupId = async (params) => {
  try {
    return await AoneService.get(
      `user-management/api/v1/groups/${params}/roles`
    );
  } catch (error) {
    return error;
  }
};

//get all modules
export const getAllModules = async () => {
  try {
    return await AoneService.get(`user-management/api/v1/module/all`);
  } catch (error) {
    return error;
  }
};
//get submodule
export const getSubModules = async (params) => {
  try {
    return await AoneService.get(`user-management/api/v1/subModule?${params}`);
  } catch (error) {
    return error;
  }
};

//get model
export const getModels = async (params) => {
  try {
    return await AoneService.get(`user-management/api/v1/model?${params}`);
  } catch (error) {
    return error;
  }
};

//get permissions
export const getPermissions = async (params) => {
  try {
    return await AoneService.get(`user-management/api/v1/permission?${params}`);
  } catch (error) {
    return error;
  }
};
//rename groups
export const renameGroups = async (params, data) => {
  try {
    return await AoneService.patch(
      `user-management/api/v1/groups/${params}/name`,
      data
    );
  } catch (error) {
    return error;
  }
};

//export users
export const exportUsers = async () => {
  try {
    return await AoneService.get("user-management/api/v1/users/export", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//crm customers
//get
export const getCustomer = async (params) => {
  try {
    return await AoneService.get(`customers/api/v1/customers/${params}`);
  } catch (error) {
    return error;
  }
};
//filter customer
export const filterCustomer = async (params) => {
  try {
    return await AoneService.get(`customers/api/v1/customers/filter${params}`);
  } catch (error) {
    return error;
  }
};
//export
export const exportCustomer = async (params) => {
  try {
    return await AoneService.get(
      "customers/api/v1/customers/export?" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//sync
export const syncCustomer = async (data) => {
  try {
    return await AoneService.post("customers/api/v1/customers/sync", data);
  } catch (error) {
    return error;
  }
};
//delete
export const deleteCustomer = async (params) => {
  try {
    return await AoneService.delete(`customers/api/v1/customers/${params}`);
  } catch (error) {
    return error;
  }
};
//add customers
export const addCustomer = async (data) => {
  try {
    return await AoneService.post("customers/api/v1/customers/", data);
  } catch (error) {
    return error;
  }
};
//get by id
export const getCustomerbyId = async (params) => {
  try {
    return await AoneService.get(
      `customers/api/v1/customers/${params}/account`
    );
  } catch (error) {
    return error;
  }
};
//push customers
export const pushOracleCustomer = async (params) => {
  try {
    return await AoneService.post(`customers/api/v1/customers/${params}/kafka`);
  } catch (error) {
    return error;
  }
};
//edit account
export const editCustomerbyId = async (params, data) => {
  try {
    return await AoneService.patch(
      `customers/api/v1/customers/${params}/account`,
      data
    );
  } catch (error) {
    return error;
  }
};
//update sales person for customer
export const editSalesPerson = async (params, params2) => {
  try {
    return await AoneService.patch(`customers/api/v1/customers/${params}/account/${params2}`);
  } catch (error) {
    return error;
  }
};
//get billing address
export const getBillingbyId = async (params) => {
  try {
    return await AoneService.get(
      `customers/api/v1/customers/${params}/billing-address`
    );
  } catch (error) {
    return error;
  }
};
//post billing address
export const postBillingAddress = async (params, data) => {
  try {
    return await AoneService.post(
      `customers/api/v1/customers/${params}/billing-address`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit billing address
export const editBillingAddress = async (params, data) => {
  try {
    return await AoneService.put(
      `customers/api/v1/customers/${params}/billing-address`,
      data
    );
  } catch (error) {
    return error;
  }
};
//get bank details
export const getBankdetailbyId = async (params) => {
  try {
    return await AoneService.get(
      `customers/api/v1/customers/${params}/bank-details`
    );
  } catch (error) {
    return error;
  }
};
//post bank details
export const postBankAddress = async (params, data) => {
  try {
    return await AoneService.post(
      `customers/api/v1/customers/${params}/bank-details`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit billing address
export const editBankAddress = async (params, data) => {
  try {
    return await AoneService.put(
      `customers/api/v1/customers/${params}/bank-details`,
      data
    );
  } catch (error) {
    return error;
  }
};
//get booking system
export const getBookingbyId = async (params) => {
  try {
    return await AoneService.get(
      `customers/api/v1/customer/${params}/booking-settings`
    );
  } catch (error) {
    return error;
  }
};
//edit booking system
export const editBookingSystem = async (params, params2) => {
  try {
    return await AoneService.put(
      `customers/api/v1/customer/${params}/booking-settings/${params2}`);
  } catch (error) {
    return error;
  }
};
//get pickup address
export const getpickupaddressbyId = async (params, params2) => {
  try {
    return await AoneService.get(
      `customers/api/v1/customers/${params}/pickup-address${params2}`
    );
  } catch (error) {
    return error;
  }
};
//post pickup address
export const postpikcupAddress = async (params, data) => {
  try {
    return await AoneService.post(
      `customers/api/v1/customers/${params}/pickup-address`,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete pickup address
export const deletepickupaddressbyId = async (params) => {
  try {
    return await AoneService.delete(
      `customers/api/v1/customers/pickup-address/${params}`
    );
  } catch (error) {
    return error;
  }
};
//edit pickup address
export const editpickupaddress = async (params, params2, data) => {
  try {
    return await AoneService.put(
      `customers/api/v1/customers/${params}/pickup-address/${params2}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete pickup address
export const deletepickupaddress = async (params) => {
  try {
    return await AoneService.delete(
      `customers/api/v1/customers/pickup-address/${params}`
    );
  } catch (error) {
    return error;
  }
};
//get pickup address
export const getRtoaddressbyId = async (params) => {
  try {
    return await AoneService.get(
      `customers/api/v1/customers/${params}/rto-address`
    );
  } catch (error) {
    return error;
  }
};
//post pickup address
export const postRtoAddress = async (params, data) => {
  try {
    return await AoneService.post(
      `customers/api/v1/customers/${params}/rto-address`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit pickup address
export const editRtoaddress = async (params, data) => {
  try {
    return await AoneService.put(
      `customers/api/v1/customers/${params}/rto-address`,
      data
    );
  } catch (error) {
    return error;
  }
};
//get invoice request
export const getInvoiceRequest = async (params) => {
  try {
    return await AoneService.get("invoice/api/v1/invoice-request" + params);
  } catch (error) {
    return error;
  }
};

//download invoice request
export const downloadInvoiceRequest = async (params) => {
  try {
    return await AoneService.get(
      `invoice/api/v1/invoice-request/${params}/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//cancel invoice request
export const CancelInvoiceRequest = async (params) => {
  try {
    return await AoneService.post(
      `invoice/api/v1/invoice-request/${params}/cancel`
    );
  } catch (error) {
    return error;
  }
};
//get invoice request template
export const getInvoiceRequestTemplate = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/invoice-request-template" + params
    );
  } catch (error) {
    return error;
  }
};
//post invoice request template
export const postInvoiceRequestTemplate = async (data) => {
  try {
    return await AoneService.post(
      "invoice/api/v1/invoice-request-template/",
      data
    );
  } catch (error) {
    return error;
  }
};
//get invoice request template by id
export const getInvoiceRequestTemplatebyId = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/invoice-request-template/" + params
    );
  } catch (error) {
    return error;
  }
};
//download consignments
export const downloadConsignments = async (params) => {
  try {
    return await AoneService.get(
      `invoice/api/v1/consignments/${params}/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//fetchDeatails
export const fetchConsignments = async (params) => {
  try {
    return await AoneService.get(
      `invoice/api/v1/invoice/consignment?waybillNo=${params}`
    );
  } catch (error) {
    return error;
  }
};
export const getCodCharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/cod${params}`);
  } catch (error) {
    return error;
  }
};

export const getFreightCharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/freight${params}`);
  } catch (error) {
    return error;
  }
};

export const getFuelCharge = async (params) => {
  try {
    return await AoneService.get(
      `invoice/api/v1/charges/fuel/percentage${params}`
    );
  } catch (error) {
    return error;
  }
};

export const getFuelkgcharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/fuel/kg${params}`);
  } catch (error) {
    return error;
  }
};

export const getLowvaluecharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/lval${params}`);
  } catch (error) {
    return error;
  }
};

export const getHighvaluecharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/hval${params}`);
  } catch (error) {
    return error;
  }
};

export const getInusranceCharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/insurance${params}`);
  } catch (error) {
    return error;
  }
};

export const getOdacharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/oda${params}`);
  } catch (error) {
    return error;
  }
};

export const getRtocharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/rto${params}`);
  } catch (error) {
    return error;
  }
};

export const getVatCharge = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/charges/vat${params}`);
  } catch (error) {
    return error;
  }
};
//post invoice request
export const postInvoiceRequest = async (data) => {
  try {
    return await AoneService.post("invoice/api/v1/invoice-request/", data);
  } catch (error) {
    return error;
  }
};
export const pushInvoicebyId = async (params) => {
  try {
    return await AoneService.get(
      `invoice/api/v1/invoice/invoice-request/${params}`
    );
  } catch (error) {
    return error;
  }
};
export const exportPickupInvoice = async (params) => {
  try {
    return await AoneService.post(
      `invoice/api/v1/invoice-request/${params}/process`
    );
  } catch (error) {
    return error;
  }
};
//get consignments
export const getConsignments = async (params) => {
  try {
    return await AoneService.get("invoice/api/v1/consignments" + params);
  } catch (error) {
    return error;
  }
};

export const postCodTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/cod`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postFreightTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/freight`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postFuelTemplate = async (data) => {
  try {
    return await AoneService.post(
      `invoice/api/v1/charges/fuel/percentage`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

export const postFuelperkgTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/fuel/kg`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postLowvalueTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/lval`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postHighvalueTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/hval`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postInsuranceTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/insurance`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postOdaTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/oda`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postRtoTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/rto`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const postVatTemplate = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/charges/vat`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//export charges
export const exportCod = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/cod/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportFreight = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/freight/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportFuel = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/fuel/percentage/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportFuelperkg = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/fuel/kg/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportLowvalue = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/lval/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportHighvalue = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/hval/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportInsurance = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/insurance/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportOda = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/oda/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportRto = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/rto/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportVat = async (params) => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/vat/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

//import charges
export const getCodTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/cod/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getFreightTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/freight/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getFuelTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/fuel/percentage/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getFuelperkgTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/fuel/kg/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getLowvalueTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/lval/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getHighvalueTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/hval/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getInsuranceTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/insurance/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getOdaTempalte = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/oda/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getRtoTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/rto/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getVatTemplate = async () => {
  try {
    return await AoneService.get(
      "invoice/api/v1/charges/vat/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//consignment orders
//get
export const getConsignmentOrders = async (params) => {
  try {
    return await AoneService.get("orders/api/v1/consignments" + params);
  } catch (error) {
    return error;
  }
};
//bulk import of invoice

export const postCustomerClassification = async (data) => {
  try {
    return await AoneService.post(`invoice/api/v1/customer-classifications`, data, 
    {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
export const postHighValue = async (data) => {
  try {
    return await AoneService.post(
      `invoice/api/v1/high-value`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

export const postTiersClassification = async (data) => {
  try {
    return await AoneService.post(
      `invoice/api/v1/tiers`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//post orders
export const postConsignmentOrders = async (data) => {
  try {
    return await AoneService.post("orders/api/v1/orders", data);
  } catch (error) {
    return error;
  }
};
export const printConsignments = async (params) => {
  try {
    return await AoneService.get(`orders/api/v1/orders/print/${params}?url=false`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
export const cancelConsignments = async (data) => {
  try {
    return await AoneService.post("orders/api/v1/orders/cancel", data);
  } catch (error) {
    return error;
  }
};
export const printBulkConsignments = async (data) => {
  try {
    return await AoneService.post('orders/api/v1/orders/print/bulk?url=false',data, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//Tracking status for consignment
export const getConsignmentStatus = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/events${params}`);
  } catch (error) {
    return error;
  }
};
//put orders
export const editConsignmentOrders = async (data) => {
  try {
    return await AoneService.put("orders/api/v1/consignments", data);
  } catch (error) {
    return error;
  }
};
//get orders detail
export const getConsignmentOrdersbyId = async (params) => {
  try {
    return await AoneService.get(`orders/api/v1/consignments/${params}`);
  } catch (error) {
    return error;
  }
};
// import consignment orders
export const importConsignmentOrders = async (data) => {
  try {
    return await AoneService.post(`orders/api/v1/orders/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

//consignment orders template
export const getConsignmentOrdersTemplate = async () => {
  try {
    return await AoneService.get(
      "orders/api/v1/orders/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getOrderAddressType = async () => {
  try {
    return await AoneService.get(`orders/api/v1/lookups/AddressType`);
  } catch (error) {
    return error;
  }
};
export const getOrderContentType = async () => {
  try {
    return await AoneService.get(`orders/api/v1/lookups/ContentType`);
  } catch (error) {
    return error;
  }
};
export const getOrderEventStatus = async () => {
  try {
    return await AoneService.get(`orders/api/v1/lookups/EventStatus`);
  } catch (error) {
    return error;
  }
};
export const getOrderDimensionUnit = async () => {
  try {
    return await AoneService.get(`orders/api/v1/lookups/DimensionUnit`);
  } catch (error) {
    return error;
  }
};
export const getOrderIncoterms = async () => {
  try {
    return await AoneService.get(`orders/api/v1/lookups/Incoterms`);
  } catch (error) {
    return error;
  }
};
export const getOrderPickupMethod = async () => {
  try {
    return await AoneService.get(`orders/api/v1/lookups/PickupMethod`);
  } catch (error) {
    return error;
  }
};
export const getOrderWeightUnit = async () => {
  try {
    return await AoneService.get(`orders/api/v1/lookups/WeightUnit`);
  } catch (error) {
    return error;
  }
};
//order details-edit incoterms,ddu
export const editOrderIncoterms = async (params,data) => {
  try {
    return await AoneService.patch(`orders/api/v1/consignments/${params}/incoterms`, data);
  } catch (error) {
    return error;
  }
};
export const editOrderDdu = async (params,data) => {
  try {
    return await AoneService.patch(`orders/api/v1/consignments/${params}/ddu`, data);
  } catch (error) {
    return error;
  }
};
//edit destination address of consignment
export const editOrderDesitiantion = async (params,data) => {
  try {
    return await AoneService.patch(`orders/api/v1/consignments/${params}/destination-address`, data);
  } catch (error) {
    return error;
  }
};
//get package tracking
export const getPackageTrackingId = async (params) => {
  try {
    return await AoneService.get(`ops/api/v1/events/package/${params}`);
  } catch (error) {
    return error;
  }
};
export const getCountries = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/countries/" + params);
  } catch (error) {
    return error;
  }
};
//filter countries
export const filterCountries = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/countries/filter" + params);
  } catch (error) {
    return error;
  }
};
//export countries
export const exportCountries = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/countries/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//export region
export const exportRegion = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/regions/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//export cities
export const exportCities = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/cities/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//export district
export const exportDistrict = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/districts/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
export const postCountries = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/countries/", data);
  } catch (error) {
    return error;
  }
};

export const getCountryById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/countries/" + params);
  } catch (error) {
    return error;
  }
};

export const getCountryByCity = async (params) => {
  try {
    return await AoneService.get(`addresses/api/v1/countries/${params}/cities`);
  } catch (error) {
    return error;
  }
};

export const getDesCountryByCity = async (params) => {
  try {
    return await AoneService.get(`addresses/api/v1/countries/${params}/cities`);
  } catch (error) {
    return error;
  }
};
export const getCityById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/cities/" + params);
  } catch (error) {
    return error;
  }
};

export const getDistrictById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/districts/" + params);
  } catch (error) {
    return error;
  }
};
//get district geometry
export const getDistrictGeometry = async (params) => {
  try {
    return await AoneService.get(`addresses/api/v1/districts/${params}/geometry`);
  } catch (error) {
    return error;
  }
};
//edit district geometry
export const editDistrictGeometry = async (params,data) => {
  try {
    return await AoneService.put(`addresses/api/v1/districts/${params}/geometry`,data);
  } catch (error) {
    return error;
  }
};
//download country template
export const getCountryTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/countries/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//download region template
export const getRegionTemplate = async () => {
  try {
    return await AoneService.get("addresses/api/v1/regions/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//download city template
export const getCitiesTemplate = async () => {
  try {
    return await AoneService.get("addresses/api/v1/cities/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//post cities
export const postCities = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/cities/", data);
  } catch (error) {
    return error;
  }
};
//download district template
export const getDistrictTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/districts/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//post district
export const postDistrict = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/districts/", data);
  } catch (error) {
    return error;
  }
};
//country import
export const importCountry = async (data) => {
  try {
    return await AoneService.post(`addresses/api/v1/countries/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//region import
export const importRegion = async (data) => {
  try {
    return await AoneService.post(`addresses/api/v1/regions/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//city import
export const importCity = async (data) => {
  try {
    return await AoneService.post(`addresses/api/v1/cities/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//district import
export const importDistrict = async (data) => {
  try {
    return await AoneService.post(`addresses/api/v1/districts/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

export const updateCountryById = async (params, data) => {
  try {
    return await AoneService.put("addresses/api/v1/countries/" + params, data);
  } catch (error) {
    return error;
  }
};

export const updateRegionById = async (params, data) => {
  try {
    return await AoneService.put("addresses/api/v1/regions/" + params, data);
  } catch (error) {
    return error;
  }
};

export const updateCityById = async (params, data) => {
  try {
    return await AoneService.put("addresses/api/v1/cities/" + params, data);
  } catch (error) {
    return error;
  }
};

export const updateDistrictById = async (params, data) => {
  try {
    return await AoneService.put("addresses/api/v1/districts/" + params, data);
  } catch (error) {
    return error;
  }
};
export const deleteCountryById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/countries/" + params);
  } catch (error) {
    return error;
  }
};

export const deleteCityById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/cities/" + params);
  } catch (error) {
    return error;
  }
};
export const deleteDistrictById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/districts/" + params);
  } catch (error) {
    return error;
  }
};
export const getRegions = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/regions/" + params);
  } catch (error) {
    return error;
  }
};
export const getRegion = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/regions/" + params);
  } catch (error) {
    return error;
  }
};
export const filterRegions = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/regions/filter" + params);
  } catch (error) {
    return error;
  }
};
export const postRegions = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/regions/", data);
  } catch (error) {
    return error;
  }
};

export const getRegionById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/regions/" + params);
  } catch (error) {
    return error;
  }
};

export const deleteRegionById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/regions/" + params);
  } catch (error) {
    return error;
  }
};

export const getDistricts = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/districts/" + params);
  } catch (error) {
    return error;
  }
};
export const filterDistricts = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/districts/filter" + params);
  } catch (error) {
    return error;
  }
};
export const getCities = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/cities/" + params);
  } catch (error) {
    return error;
  }
};
export const filterCities = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/cities/filter" + params);
  } catch (error) {
    return error;
  }
};
//get region by country id
export const getRegionbyCode = async (params) => {
  try {
    return await AoneService.get(
      `addresses/api/v1/countries/${params}/regions`
    );
  } catch (error) {
    return error;
  }
};
export const getRegionbyCodeDes = async (params) => {
  try {
    return await AoneService.get(
      `addresses/api/v1/countries/${params}/regions`
    );
  } catch (error) {
    return error;
  }
};
//get city by country id and region id
export const getCitybyCode = async (params, params2) => {
  try {
    return await AoneService.get(
      `addresses/api/v1/countries/${params}/regions/${params2}/cities`
    );
  } catch (error) {
    return error;
  }
};
export const getCitybyCodeDes = async (params, params2) => {
  try {
    return await AoneService.get(
      `addresses/api/v1/countries/${params}/regions/${params2}/cities`
    );
  } catch (error) {
    return error;
  }
};
//get district by country id, region id,city id
export const getDistrictbyCode = async (params, params2, params3) => {
  try {
    return await AoneService.get(
      `addresses/api/v1/countries/${params}/regions/${params2}/cities/${params3}/districts`
    );
  } catch (error) {
    return error;
  }
};
export const getDistrictDesbyCode = async (params, params2, params3) => {
  try {
    return await AoneService.get(
      `addresses/api/v1/countries/${params}/regions/${params2}/cities/${params3}/districts`
    );
  } catch (error) {
    return error;
  }
};
//get tiers
export const getTiers = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/tiers" + params);
  } catch (error) {
    return error;
  }
};
//post tiers
export const postTiers = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/tiers", data);
  } catch (error) {
    return error;
  }
};
//get tiers by id
export const getTiersbyId = async (params) => {
  try {
    return await AoneService.get(`addresses/api/v1/tiers/${params}`);
  } catch (error) {
    return error;
  }
};
//edit tiers
export const updateTiersById = async (params, data) => {
  try {
    return await AoneService.put(`addresses/api/v1/tiers/${params}`, data);
  } catch (error) {
    return error;
  }
};
//delete tiers
export const deleteTiers = async (params) => {
  try {
    return await AoneService.delete(`addresses/api/v1/tiers/${params}`);
  } catch (error) {
    return error;
  }
};
//get od-pair
export const getOdTier = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/od-pair" + params);
  } catch (error) {
    return error;
  }
};
//post od-pair
export const postOdTier = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/od-pair", data);
  } catch (error) {
    return error;
  }
};
//validate od pair
export const validateOdpair = async (params) => {
  try {
    return await AoneService.get(`addresses/api/v1/od-pair/validate/${params}`);
  } catch (error) {
    return error;
  }
};
//edit od-pair
export const updateOdTierById = async (params, data) => {
  try {
    return await AoneService.put(`addresses/api/v1/od-pair/${params}`, data);
  } catch (error) {
    return error;
  }
};
//delete od-pair
export const deleteOdTier = async (params) => {
  try {
    return await AoneService.delete(`addresses/api/v1/od-pair/${params}`);
  } catch (error) {
    return error;
  }
};
//get country mapping
export const getCountryMapping = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/countries/mapping" + params);
  } catch (error) {
    return error;
  }
};
//post country mapping
export const postCountryMapping = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/countries/mapping", data);
  } catch (error) {
    return error;
  }
};
//delete country mapping
export const deleteCountryMappingById = async (params) => {
  try {
    return await AoneService.delete(
      "addresses/api/v1/countries/mapping/" + params
    );
  } catch (error) {
    return error;
  }
};
//get country mapping by id
export const getCountryMappingById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/countries/mapping" + params);
  } catch (error) {
    return error;
  }
};
//put country mapping
export const updateCountryMappingById = async (params, data) => {
  try {
    return await AoneService.put(
      "addresses/api/v1/countries/mapping/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};
//get region mapping
export const getRegionMapping = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/region/mapping" + params);
  } catch (error) {
    return error;
  }
};
//post region mapping
export const postRegionMapping = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/region/mapping", data);
  } catch (error) {
    return error;
  }
};
//delete region mapping
export const deleteRegionMappingById = async (params) => {
  try {
    return await AoneService.delete(
      "addresses/api/v1/region/mapping/" + params
    );
  } catch (error) {
    return error;
  }
};
//get region mapping by id
export const getRegionMappingById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/region/mapping" + params);
  } catch (error) {
    return error;
  }
};
//put region mapping
export const updateRegionMappingById = async (params, data) => {
  try {
    return await AoneService.put(
      "addresses/api/v1/region/mapping/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};
//get city mapping
export const getCityMapping = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/city/mappings" + params);
  } catch (error) {
    return error;
  }
};
//post city mapping
export const postCityMapping = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/city/mappings", data);
  } catch (error) {
    return error;
  }
};
//delete city mapping
export const deleteCityMappingById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/city/mappings/" + params);
  } catch (error) {
    return error;
  }
};
//get city mapping by id
export const getCityMappingById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/city/mappings" + params);
  } catch (error) {
    return error;
  }
};
//put city mapping
export const updateCityMappingById = async (params, data) => {
  try {
    return await AoneService.put(
      "addresses/api/v1/city/mappings/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};
//get district mapping
export const getDistrictMapping = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/district/mapping" + params);
  } catch (error) {
    return error;
  }
};
//post district mapping
export const postDistrictMapping = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/district/mapping", data);
  } catch (error) {
    return error;
  }
};
//delete district mapping
export const deleteDistrictMappingById = async (params) => {
  try {
    return await AoneService.delete(
      "addresses/api/v1/district/mapping/" + params
    );
  } catch (error) {
    return error;
  }
};
//get district mapping by id
export const getDistrictMappingById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/district/mapping" + params);
  } catch (error) {
    return error;
  }
};
//put district mapping
export const updateDistrictMappingById = async (params, data) => {
  try {
    return await AoneService.put(
      "addresses/api/v1/district/mapping/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};

//mapping templates
//download country template
export const getCountryMapTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/countries/mapping/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

export const getRegionMapTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/region/mapping/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getCityMapTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/city/mapping/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getDistrictMapTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/district/mapping/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//export mapping template
export const exportDistrictMap = async (params) => {
  try {
    return await AoneService.get(
      "addresses/api/v1/district/mapping/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportCountryMap = async (params) => {
  try {
    return await AoneService.get(
      "addresses/api/v1/countries/mapping/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportRegionMap = async (params) => {
  try {
    return await AoneService.get(
      "addresses/api/v1/region/mapping/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const exportCityMap = async (params) => {
  try {
    return await AoneService.get(
      "addresses/api/v1/city/mapping/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const importCountryMap = async (data) => {
  try {
    return await AoneService.post(
      `addresses/api/v1/countries/mapping/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
export const importRegionMap = async (data) => {
  try {
    return await AoneService.post(
      `addresses/api/v1/region/mapping/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
export const importCityMap = async (data) => {
  try {
    return await AoneService.post(
      `addresses/api/v1/city/mapping/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
export const importDistrictMap = async (data) => {
  try {
    return await AoneService.post(
      `addresses/api/v1/district/mapping/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//get hubs
export const getHubs = async (params) => {
  try {
    return await AoneService.get("assets/api/v1/hubs" + params);
  } catch (error) {
    return error;
  }
};
export const getHub = async (params) => {
  try {
    return await AoneService.get("assets/api/v1/hubs" + params);
  } catch (error) {
    return error;
  }
};
//filter hubs
export const filterHubs = async (params) => {
  try {
    return await AoneService.get("assets/api/v1/hubs/filter" + params);
  } catch (error) {
    return error;
  }
};
//gethubs by id
export const getHubsbyId = async (params) => {
  try {
    return await AoneService.get("assets/api/v1/hubs/" + params);
  } catch (error) {
    return error;
  }
};
//post hubs
export const postHubs = async (data) => {
  try {
    return await AoneService.post("assets/api/v1/hubs", data);
  } catch (error) {
    return error;
  }
};
//edit hubs
export const editHubs = async (data) => {
  try {
    return await AoneService.put("assets/api/v1/hubs", data);
  } catch (error) {
    return error;
  }
};
//patch hubs
export const editHubsbypatch = async (params, data) => {
  try {
    return await AoneService.patch(`assets/api/v1/hubs/${params}`, data);
  } catch (error) {
    return error;
  }
};
//retreive hub type
export const getHubTypes = async () => {
  try {
    return await AoneService.get("assets/api/v1/lookups/HubType");
  } catch (error) {
    return error;
  }
};
//delete hubs
export const deleteHubs = async (params) => {
  try {
    return await AoneService.delete("assets/api/v1/hubs/" + params);
  } catch (error) {
    return error;
  }
};
//export hubs
export const exportHubs = async (params) => {
  try {
    return await AoneService.get("assets/api/v1/hubs/download" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//hub template
export const getHubTemplate = async () => {
  try {
    return await AoneService.get("assets/api/v1/hubs/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//hub import
export const importHub = async (data) => {
  try {
    return await AoneService.post(`assets/api/v1/hubs/upload`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

//Products
//get products
export const getProducts = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/products/" + params);
  } catch (error) {
    return error;
  }
};
//filter products
export const filterProducts = async (params) => {
  try {
    return await AoneService.get("/masters/api/v1/products/filter" + params);
  } catch (error) {
    return error;
  }
};
//GET productsbyid
export const getProductsbyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/products/" + params);
  } catch (error) {
    return error;
  }
};
//post products
export const postProducts = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/products/", data);
  } catch (error) {
    return error;
  }
};
//edit products
export const editProducts = async (params, data) => {
  try {
    return await AoneService.put(`masters/api/v1/products/${params}`, data);
  } catch (error) {
    return error;
  }
};
//patch Products
export const editProductsbypatch = async (params, data) => {
  try {
    return await AoneService.patch(`masters/api/v1/products/${params}`, data);
  } catch (error) {
    return error;
  }
};
//retreive Products DimensionUnit
export const getProductsDimension = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/DimensionUnit");
  } catch (error) {
    return error;
  }
};
//retreive Products ChangeProductEvent
export const getProductsEvent = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/ChangeProductEvent");
  } catch (error) {
    return error;
  }
};
//retreive Products WeightUnit
export const getProductsWeight = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/WeightUnit");
  } catch (error) {
    return error;
  }
};
//retreive Products ProductCategory
export const getProductCategory = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/ProductCategory");
  } catch (error) {
    return error;
  }
};
//retreive Products ContentType
export const getProductsContent = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/ContentType");
  } catch (error) {
    return error;
  }
};
//delete hubs
export const deleteProducts = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/products/" + params);
  } catch (error) {
    return error;
  }
};
//exportProducts
export const exportProducts = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/products/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//Products template
export const getProductsTemplate = async () => {
  try {
    return await AoneService.get("masters/api/v1/products/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//Products import
export const importProducts = async (data) => {
  try {
    return await AoneService.post(`masters/api/v1/products/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//rates-freight
//get freight
export const getFreight = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/freight" + params);
  } catch (error) {
    return error;
  }
};
//GET freightbyif
export const getFreightbyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/freight/" + params);
  } catch (error) {
    return error;
  }
};
//post freight
export const postFreight = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/freight", data);
  } catch (error) {
    return error;
  }
};
//edit freight
export const editFreight = async (params, data) => {
  try {
    return await AoneService.put(
      `masters/api/v1/rates/freight/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//patch freight
export const editFreightsbypatch = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/freight/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete freight
export const deleteFreight = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/rates/freight/" + params);
  } catch (error) {
    return error;
  }
};
//delete freight by bulk
export const bulkFreightDelete = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/freight/delete", data);
  } catch (error) {
    return error;
  }
};
//update freight rate state
export const editFreightState = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/freight/state/${params}`,data);
  } catch (error) {
    return error;
  }
};
//exportfreight
export const exportrateFreight = async (params) => {
  try {
    return await AoneService.get(
      "masters/api/v1/rates/freight/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//freight template
export const getFreightsTemplate = async () => {
  try {
    return await AoneService.get(
      "masters/api/v1/rates/freight/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//freight import
export const importFreight = async (data) => {
  try {
    return await AoneService.post(`masters/api/v1/rates/freight/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//retreive freight CalculationType
export const getCalType = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/CalculationType");
  } catch (error) {
    return error;
  }
};
//retreive freight CollectionMethod
export const getCollectionMethod = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/CollectionMethod");
  } catch (error) {
    return error;
  }
};
//retreive freight ContentType
export const getContentType = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/ContentType");
  } catch (error) {
    return error;
  }
};
//retreive rates state
export const getStateRates = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/State");
  } catch (error) {
    return error;
  }
};
//retreive freight DeliveryStatus
export const getDeliveryStatus = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/DeliveryStatus");
  } catch (error) {
    return error;
  }
};
//retreive freight PaymentType
export const getPaymentType = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/PaymentType");
  } catch (error) {
    return error;
  }
};
//retreive freight State
export const getState = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/State");
  } catch (error) {
    return error;
  }
};
//retreive freight Structure
export const getStructure = async () => {
  try {
    return await AoneService.get("masters/api/v1/lookups/Structure");
  } catch (error) {
    return error;
  }
};
//rates-rto
//get Rto
export const getRto = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/rto" + params);
  } catch (error) {
    return error;
  }
};
//GET freightbyif
export const getRtobyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/rto/" + params);
  } catch (error) {
    return error;
  }
};
//post freight
export const postRto = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/rto", data);
  } catch (error) {
    return error;
  }
};
//edit freight
export const editRto = async (params, data) => {
  try {
    return await AoneService.put(`masters/api/v1/rates/rto/${params}`, data);
  } catch (error) {
    return error;
  }
};
//patch freight
export const editRtobypatch = async (params, data) => {
  try {
    return await AoneService.patch(`masters/api/v1/rates/rto/${params}`, data);
  } catch (error) {
    return error;
  }
};
//update rto rate state
export const editRtoState = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/rto/state/${params}`,data);
  } catch (error) {
    return error;
  }
};
//delete freight
export const deleteRto = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/rates/rto/" + params);
  } catch (error) {
    return error;
  }
};
export const bulkRtoDelete = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/rto/delete", data);
  } catch (error) {
    return error;
  }
};
//exportfreight
export const exportrateRto = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/rto/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//freight template
export const getRtosTemplate = async () => {
  try {
    return await AoneService.get("masters/api/v1/rates/rto/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//freight import
export const importRto = async (data) => {
  try {
    return await AoneService.post(`masters/api/v1/rates/rto/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//cod
export const getCod = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/cod" + params);
  } catch (error) {
    return error;
  }
};
export const getCodbyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/cod/" + params);
  } catch (error) {
    return error;
  }
};
export const postCod = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/cod", data);
  } catch (error) {
    return error;
  }
};
export const editCod = async (params, data) => {
  try {
    return await AoneService.put(`masters/api/v1/rates/cod/${params}`, data);
  } catch (error) {
    return error;
  }
};
export const editCodbypatch = async (params, data) => {
  try {
    return await AoneService.patch(`masters/api/v1/rates/cod/${params}`, data);
  } catch (error) {
    return error;
  }
};
export const editCodState = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/cod/state/${params}`,data);
  } catch (error) {
    return error;
  }
};
export const deleteCod = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/rates/cod/" + params);
  } catch (error) {
    return error;
  }
};
export const bulkCodDelete = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/cod/delete", data);
  } catch (error) {
    return error;
  }
};
export const exportrateCod = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/cod/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
export const getCodrateTemplate = async () => {
  try {
    return await AoneService.get("masters/api/v1/rates/cod/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
export const importCod = async (data) => {
  try {
    return await AoneService.post(`masters/api/v1/rates/cod/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//oda
export const getOda = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/oda" + params);
  } catch (error) {
    return error;
  }
};
export const getOdabyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/oda/" + params);
  } catch (error) {
    return error;
  }
};
export const postOda = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/oda", data);
  } catch (error) {
    return error;
  }
};
export const editOda = async (params, data) => {
  try {
    return await AoneService.put(`masters/api/v1/rates/oda/${params}`, data);
  } catch (error) {
    return error;
  }
};
export const editOdabypatch = async (params, data) => {
  try {
    return await AoneService.patch(`masters/api/v1/rates/oda/${params}`, data);
  } catch (error) {
    return error;
  }
};
export const editOdaState = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/oda/state/${params}`,data);
  } catch (error) {
    return error;
  }
};
export const deleteOda = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/rates/oda/" + params);
  } catch (error) {
    return error;
  }
};
export const bulkOdaDelete = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/oda/delete", data);
  } catch (error) {
    return error;
  }
};
export const exportrateOda = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/oda/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
export const getOdarateTemplate = async () => {
  try {
    return await AoneService.get("masters/api/v1/rates/oda/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
export const importOda = async (data) => {
  try {
    return await AoneService.post(`masters/api/v1/rates/oda/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//fuel
export const getFuel = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/fuel" + params);
  } catch (error) {
    return error;
  }
};
export const getFuelbyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/fuel/" + params);
  } catch (error) {
    return error;
  }
};
export const postFuel = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/fuel", data);
  } catch (error) {
    return error;
  }
};
export const editFuel = async (params, data) => {
  try {
    return await AoneService.put(`masters/api/v1/rates/fuel/${params}`, data);
  } catch (error) {
    return error;
  }
};
export const editFuelbypatch = async (params, data) => {
  try {
    return await AoneService.patch(`masters/api/v1/rates/fuel/${params}`, data);
  } catch (error) {
    return error;
  }
};
export const editFuelState = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/fuel/state/${params}`,data);
  } catch (error) {
    return error;
  }
};
export const deleteFuel = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/rates/fuel/" + params);
  } catch (error) {
    return error;
  }
};
export const bulkFuelDelete = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/fuel/delete", data);
  } catch (error) {
    return error;
  }
};
export const exportrateFuel = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/fuel/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
export const getFuelrateTemplate = async () => {
  try {
    return await AoneService.get(
      "masters/api/v1/rates/fuel/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const importFuel = async (data) => {
  try {
    return await AoneService.post(`masters/api/v1/rates/fuel/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//insurance
export const getInsurance = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/insurance" + params);
  } catch (error) {
    return error;
  }
};
export const getInsurancebyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/insurance/" + params);
  } catch (error) {
    return error;
  }
};
export const postInsurance = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/insurance", data);
  } catch (error) {
    return error;
  }
};
export const editInsurance = async (params, data) => {
  try {
    return await AoneService.put(
      `masters/api/v1/rates/insurance/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};
export const editInsurancebypatch = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/insurance/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};
export const editInsuranceState = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/insurance/state/${params}`,data);
  } catch (error) {
    return error;
  }
};
export const deleteInsurance = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/rates/insurance/" + params);
  } catch (error) {
    return error;
  }
};
export const bulkInsuranceDelete = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/insurance/delete", data);
  } catch (error) {
    return error;
  }
};
export const exportrateInsurance = async (params) => {
  try {
    return await AoneService.get(
      "masters/api/v1/rates/insurance/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getInsurancerateTemplate = async () => {
  try {
    return await AoneService.get(
      "masters/api/v1/rates/insurance/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const importInsurance = async (data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/rates/insurance/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//attempts
export const getAttempts = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/attempts" + params);
  } catch (error) {
    return error;
  }
};
export const getAttemptsbyId = async (params) => {
  try {
    return await AoneService.get("masters/api/v1/rates/attempts/" + params);
  } catch (error) {
    return error;
  }
};
export const postAttempts = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/attempts", data);
  } catch (error) {
    return error;
  }
};
export const editAttempts = async (params, data) => {
  try {
    return await AoneService.put(
      `masters/api/v1/rates/attempts/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};
export const editAttemptsbypatch = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/attempts/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};
export const editAttemptsState = async (params, data) => {
  try {
    return await AoneService.patch(
      `masters/api/v1/rates/attempts/state/${params}`,data);
  } catch (error) {
    return error;
  }
};
export const deleteAttempts = async (params) => {
  try {
    return await AoneService.delete("masters/api/v1/rates/attempts/" + params);
  } catch (error) {
    return error;
  }
};
export const bulkAttemptsDelete = async (data) => {
  try {
    return await AoneService.post("masters/api/v1/rates/attempts/delete", data);
  } catch (error) {
    return error;
  }
};
export const exportrateAttempts = async (params) => {
  try {
    return await AoneService.get(
      "masters/api/v1/rates/attempts/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getAttemptsrateTemplate = async () => {
  try {
    return await AoneService.get(
      "masters/api/v1/rates/attempts/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
export const importAttempts = async (data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/rates/attempts/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//consignment orders
//retreive Incoterms
export const getIncoterms = async () => {
  try {
    return await AoneService.get("orders/api/v1/lookups/Incoterms");
  } catch (error) {
    return error;
  }
};
//origins
//get Origin
export const getOrigin = async (params1, params2) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params1}/origins` + params2
    );
  } catch (error) {
    return error;
  }
};
//post Origin
export const postOrigin = async (params, data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/products/${params}/origins`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit Origin
export const editOrigin = async (params, params2, data) => {
  try {
    return await AoneService.put(
      `masters/api/v1/products/${params}/origins/${params2}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete Origin
export const deleteOrigin = async (params1, params, data) => {
  try {
    return await AoneService.delete(
      `masters/api/v1/products/${params1}/origins/${params}` + data
    );
  } catch (error) {
    return error;
  }
};
//export Origin
export const exportOrigin = async (params1, params) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params1}/origins/export` + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//Origin template
export const getOriginTemplate = async (params) => {
  try {
    return await AoneService.get(
    `masters/api/v1/products/${params}/origin/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//Origin import
export const importOrigin = async (params, data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/products/${params}/origins/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

//get Destination
export const getDestination = async (params1, params2) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params1}/destinations/` + params2
    );
  } catch (error) {
    return error;
  }
};
//post Destination
export const postDestination = async (params, data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/products/${params}/destinations`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit Destination
export const editDestination = async (params, params2, data) => {
  try {
    return await AoneService.put(
      `masters/api/v1/products/${params}/destinations/${params2}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete Destination
export const deleteDestination = async (params1, params, data) => {
  try {
    return await AoneService.delete(
      `masters/api/v1/products/${params1}/destinations/${params}` + data
    );
  } catch (error) {
    return error;
  }
};
//export Destination
export const exportDestination = async (params1, params) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params1}/destinations/export` + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//Destination template
export const getDestinationTemplate = async (params) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params}/destinations/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//Destination import
export const importDestination = async (params, data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/products/${params}/destinations/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

//get transittime
export const getTransittime = async (params1, params2) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params1}/transit-time/` + params2
    );
  } catch (error) {
    return error;
  }
};
//post transittime
export const postTransittime = async (params, data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/products/${params}/transit-time/`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit transittime
export const editTransittime = async (params, params1, data) => {
  try {
    return await AoneService.put(
      `masters/api/v1/products/${params}/transit-time/${params1}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete transittime
export const deleteTransittime = async (params1, params) => {
  try {
    return await AoneService.delete(
      `masters/api/v1/products/${params1}/transit-time/` + params
    );
  } catch (error) {
    return error;
  }
};
//export transittime
export const exportTransittime = async (params1, params) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params1}/transit-time/export` + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//transittime template
export const getTransittimeTemplate = async (params) => {
  try {
    return await AoneService.get(
      `masters/api/v1/products/${params}/transit-time/template/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//transittime import
export const importTransittime = async (params, data) => {
  try {
    return await AoneService.post(
      `masters/api/v1/products/${params}/transit-time/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

//get employees
export const getEmployee = async (params) => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees" + params
    );
  } catch (error) {
    return error;
  }
};
//get employeebyid
export const getEmployeebyId = async (params) => {
  try {
    return await AoneService.get(
      `employees/api/v1/employees/${params}`
    );
  } catch (error) {
    return error;
  }
};
//post employees
export const postEmployee = async (data) => {
  try {
    return await AoneService.post("employees/api/v1/employees", data);
  } catch (error) {
    return error;
  }
};
//edit employees
export const editEmployees = async (params, data) => {
  try {
    return await AoneService.patch(
      `employees/api/v1/employees/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//get employee field name
export const getFieldName = async (params, data) => {
  try {
    return await AoneService.get(
      `employees/api/v1/employees/${params}/view/${data}`
    );
  } catch (error) {
    return error;
  }
};
//delete employees
export const deleteEmployees = async (params) => {
  try {
    return await AoneService.delete(
      "employees/api/v1/employees/" + params
    );
  } catch (error) {
    return error;
  }
};
//export employees
export const exportEmployee = async (params) => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//employee template
export const getEmployeesTemplate = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees/template",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//employee import
export const importEmployees = async (data) => {
  try {
    return await AoneService.post(
      `employees/api/v1/employees/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

//Lookups-jobtitle
export const employeeJobtitle = async () => {
  try {
    return await AoneService.get("employees/api/v1/lookups/JobTitle");
  } catch (error) {
    return error;
  }
};
//department
export const employeedepartment = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/lookups/Department"
    );
  } catch (error) {
    return error;
  }
};
//payrolltype
export const employeePayrolltype = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/lookups/PayrollType"
    );
  } catch (error) {
    return error;
  }
};
//payroll company
export const employeePayrollCompany = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/lookups/PayrollCompany"
    );
  } catch (error) {
    return error;
  }
};
//legacy system
export const employeeLegacySystem = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/lookups/LegacySystem"
    );
  } catch (error) {
    return error;
  }
};
export const employeeDepartments = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/lookups/departments"
    );
  } catch (error) {
    return error;
  }
};
export const getJobtitlebyDept = async (params) => {
  try {
    return await AoneService.get(
      `employees/api/v1/lookups/department/${params}`
    );
  } catch (error) {
    return error;
  }
};
//areas
//get areas
export const getAreas = async (params, params1) => {
  try {
    return await AoneService.get(
      `employees/api/v1/employees/${params}/areas` + params1
    );
  } catch (error) {
    return error;
  }
};
//post areas
export const postAreas = async (params, data) => {
  try {
    return await AoneService.post(
      `employees/api/v1/employees/${params}/areas`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit areas
export const editAreas = async (params, params2, data) => {
  try {
    return await AoneService.patch(
      `employees/api/v1/employees/${params}/areas/${params2}`,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete areas
export const deleteAreas = async (params) => {
  try {
    return await AoneService.delete(
      "employees/api/v1/employees/areas/" + params
    );
  } catch (error) {
    return error;
  }
};
//export areas
export const exportAreas = async (params1, params) => {
  try {
    return await AoneService.get(
      `employees/api/v1/employees/${params1}/areas/export` + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//areas template
export const getAreasTemplate = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees/areas/template",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//areas import
export const importAreas = async (params, data) => {
  try {
    return await AoneService.post(
      `employees/api/v1/employees/${params}/areas/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//login-information
export const postLoginInfoemployee = async (params,data) => {
  try {
    return await AoneService.post(
      `employees/api/v1/employees/${params}/user-management`,
      data
    );
  } catch (error) {
    return error;
  }
};
//save attributes
export const postAttributes = async (data) => {
  try {
    return await AoneService.post(
      `employees/api/v1/employees/attributes`,
      data
    );
  } catch (error) {
    return error;
  }
};
//reset password
export const resetPasswordEmployee = async (data) => {
  try {
    return await AoneService.post(
      "employees/api/v1/employees/user-management/reset-password",
      data
    );
  } catch (error) {
    return error;
  }
};
//toggle user status
export const toggleUserstatus = async (param1, param2) => {
  try {
    return await AoneService.get(
      `employees/api/v1/employees/user-management/toggle-user-status/${param1}/${param2}`
    );
  } catch (error) {
    return error;
  }
};
//generate password
export const generatePassword = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees/user-management/generate-password"
    );
  } catch (error) {
    return error;
  }
};
//get serviceareas
export const getServiceAreas = async (params) => {
  try {
    return await AoneService.get("assets/api/v1/areas" + params);
  } catch (error) {
    return error;
  }
};
//get service zones by hubs
export const getAllAreasbyHub = async (params) => {
  try {
    return await AoneService.get(`assets/api/v1/areas/hub/${params}`);
  } catch (error) {
    return error;
  }
};
//get service zone groups by hubs
export const getAllZonegroupbyHub = async (params) => {
  try {
    return await AoneService.get(`addresses/api/v1/zones/groups/hubs/${params}`);
  } catch (error) {
    return error;
  }
};
//post serviceareas
export const postServiceAreas = async (data) => {
  try {
    return await AoneService.post("assets/api/v1/areas", data);
  } catch (error) {
    return error;
  }
};
//edit serviceareas
export const editServiceAreas = async (data) => {
  try {
    return await AoneService.put("assets/api/v1/areas", data);
  } catch (error) {
    return error;
  }
};
//delete serviceareas
export const deleteServiceareas = async (params) => {
  try {
    return await AoneService.delete("assets/api/v1/areas/" + params);
  } catch (error) {
    return error;
  }
};
//allowedevent in service areas
export const allowedeventLookup = async () => {
  try {
    return await AoneService.get("assets/api/v1/lookups/AllowedEvent");
  } catch (error) {
    return error;
  }
};
//template
export const getServiceAreasTemplate = async () => {
  try {
    return await AoneService.get("assets/api/v1/areas/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//export
export const exportServiceAreas = async (params) => {
  try {
    return await AoneService.get("assets/api/v1/areas/download" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//import
export const importServiceAreas = async (params, data) => {
  try {
    return await AoneService.post(
      `assets/api/v1/areas/${params}/upload`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//get currency
export const getCurrency = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/currency" + params);
  } catch (error) {
    return error;
  }
};
//post currency
export const postCurrency = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/currency", data);
  } catch (error) {
    return error;
  }
};
//delete currency
export const deleteCurrencyById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/currency/" + params);
  } catch (error) {
    return error;
  }
};
//put currency
export const updateCurrencyById = async (params, data) => {
  try {
    return await AoneService.put("addresses/api/v1/currency/" + params, data);
  } catch (error) {
    return error;
  }
};
//currency template
export const getCurrencyTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/currency/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//export currency
export const exportCurrency = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/currency/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//import currency
export const importCurrency = async (data) => {
  try {
    return await AoneService.post(`addresses/api/v1/currency/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};

//get currency conversions
export const getCurrencyConversion = async (params) => {
  try {
    return await AoneService.get(
      "addresses/api/v1/currency/conversion" + params
    );
  } catch (error) {
    return error;
  }
};
//post currency
export const postCurrencyConversion = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/currency/conversion", data);
  } catch (error) {
    return error;
  }
};
//delete currency
export const deleteCurrencyConversionById = async (params) => {
  try {
    return await AoneService.delete(
      "addresses/api/v1/currency/conversion/" + params
    );
  } catch (error) {
    return error;
  }
};
//put currency
export const updateCurrencyConversionById = async (params, data) => {
  try {
    return await AoneService.put(
      "addresses/api/v1/currency/conversion/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};
//currency template
export const getCurrencyConversionTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/currency/conversion/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//export currency
export const exportCurrencyConversion = async (params) => {
  try {
    return await AoneService.get(
      "addresses/api/v1/currency/conversion/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//import currency
export const importCurrencyConversion = async (data) => {
  try {
    return await AoneService.post(
      `addresses/api/v1/currency/conversion/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//get pickup reasons
export const getPickupReasons = async (params) => {
  try {
    return await AoneService.get("ops/api/v1/reasons/pickup" + params);
  } catch (error) {
    return error;
  }
};
//post pickup reasons
export const postPickupReasons = async (data) => {
  try {
    return await AoneService.post("ops/api/v1/reasons/pickup", data);
  } catch (error) {
    return error;
  }
};
//edit pickup reasons
export const updatePickupReasonsById = async (params, data) => {
  try {
    return await AoneService.put("ops/api/v1/reasons/pickup/" + params, data);
  } catch (error) {
    return error;
  }
};
//delete pickup reasons
export const deletePickupReasonsById = async (params) => {
  try {
    return await AoneService.delete("ops/api/v1/reasons/pickup/" + params);
  } catch (error) {
    return error;
  }
};
//getbyId pickup reasons
export const getPickupReasonsById = async (params) => {
  try {
    return await AoneService.get("ops/api/v1/reasons/pickup/" + params);
  } catch (error) {
    return error;
  }
};
export const getPickupCategory = async () => {
  try {
    return await AoneService.get("ops/api/v1/reasons/pickup/event/category");
  } catch (error) {
    return error;
  }
};
//get pickup event category
export const getPickupEventCategory = async () => {
  try {
    return await AoneService.get("ops/api/v1/lookups/PickupEventCategory");
  } catch (error) {
    return error;
  }
};
export const getDeliveryEventCategory = async () => {
  try {
    return await AoneService.get("ops/api/v1/lookups/DeliveryEventCategory");
  } catch (error) {
    return error;
  }
};
//get pickup category
export const getOpsCategory = async () => {
  try {
    return await AoneService.get("ops/api/v1/lookups/Category");
  } catch (error) {
    return error;
  }
};
//export pickup reasons
export const exportPickupReasons = async (params) => {
  try {
    return await AoneService.get("ops/api/v1/reasons/pickup/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//get delivery reasons
export const getDeliveryReasons = async (params) => {
  try {
    return await AoneService.get("ops/api/v1/reasons/delivery" + params);
  } catch (error) {
    return error;
  }
};
//post delivery reasons
export const postDeliveryReasons = async (data) => {
  try {
    return await AoneService.post("ops/api/v1/reasons/delivery", data);
  } catch (error) {
    return error;
  }
};
//edit delivery reasons
export const updateDeliveryReasonsById = async (params, data) => {
  try {
    return await AoneService.put("ops/api/v1/reasons/delivery/" + params, data);
  } catch (error) {
    return error;
  }
};
//delete delivery reasons
export const deleteDeliveryReasonsById = async (params) => {
  try {
    return await AoneService.delete("ops/api/v1/reasons/delivery/" + params);
  } catch (error) {
    return error;
  }
};
//getbyId delivery reasons
export const getDeliveryReasonsById = async (params) => {
  try {
    return await AoneService.get("ops/api/v1/reasons/delivery/" + params);
  } catch (error) {
    return error;
  }
};
//export delivery reasons
export const exportDeliveryReasons = async (params) => {
  try {
    return await AoneService.get(
      "ops/api/v1/reasons/delivery/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//get Hold reasons
export const getHoldReasons = async (params) => {
  try {
    return await AoneService.get("ops/api/v1/reasons/hold" + params);
  } catch (error) {
    return error;
  }
};
//post Hold reasons
export const postHoldReasons = async (data) => {
  try {
    return await AoneService.post("ops/api/v1/reasons/hold", data);
  } catch (error) {
    return error;
  }
};
//edit Hold reasons
export const updateHoldReasonsById = async (params, data) => {
  try {
    return await AoneService.put("ops/api/v1/reasons/hold/" + params, data);
  } catch (error) {
    return error;
  }
};
//delete Hold reasons
export const deleteHoldReasonsById = async (params) => {
  try {
    return await AoneService.delete("ops/api/v1/reasons/hold/" + params);
  } catch (error) {
    return error;
  }
};
//getbyId Hold reasons
export const getHoldReasonsById = async (params) => {
  try {
    return await AoneService.get("ops/api/v1/reasons/hold/" + params);
  } catch (error) {
    return error;
  }
};
//export Hold reasons
export const exportHoldReasons = async (params) => {
  try {
    return await AoneService.get(
      "ops/api/v1/reasons/hold/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//get zone
export const getZone = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/zones" + params);
  } catch (error) {
    return error;
  }
};
//filter Zone
export const filterZone = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/zones/filter" + params);
  } catch (error) {
    return error;
  }
};
//getbyId-zone
export const getZoneById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/zones/" + params);
  } catch (error) {
    return error;
  }
};
//post zones
export const postZones = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/zones", data);
  } catch (error) {
    return error;
  }
};
//update zones
export const updateZoneById = async (params, data) => {
  try {
    return await AoneService.put("addresses/api/v1/zones/" + params, data);
  } catch (error) {
    return error;
  }
};
//delete zones
export const deleteZoneById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/zones/" + params);
  } catch (error) {
    return error;
  }
};
//zone template
export const getZoneTemplate = async () => {
  try {
    return await AoneService.get("addresses/api/v1/zones/template/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//zone export
export const exportZones = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/zones/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//zone import
export const importZones = async (data) => {
  try {
    return await AoneService.post(`addresses/api/v1/zones/import`, data, {
      "Content-Type": "multipart/form-data",
    });
  } catch (error) {
    return error;
  }
};
//zone groups
export const getZoneGroups = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/zones/groups" + params);
  } catch (error) {
    return error;
  }
};
//getbyId-zone group
export const getZoneGroupById = async (params) => {
  try {
    return await AoneService.get("addresses/api/v1/zones/groups/" + params);
  } catch (error) {
    return error;
  }
};
//post zonegroup
export const postZonegroup = async (data) => {
  try {
    return await AoneService.post("addresses/api/v1/zones/groups", data);
  } catch (error) {
    return error;
  }
};
//update zonegroup
export const updateZonegroupById = async (params, data) => {
  try {
    return await AoneService.put(
      "addresses/api/v1/zones/groups/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};
//delete zonegroup
export const deleteZonegroupById = async (params) => {
  try {
    return await AoneService.delete("addresses/api/v1/zones/groups/" + params);
  } catch (error) {
    return error;
  }
};
//zone group template
export const getZonegroupTemplate = async () => {
  try {
    return await AoneService.get(
      "addresses/api/v1/zones/groups/template/download",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//zone group export
export const exportZonegroup = async (params) => {
  try {
    return await AoneService.get(
      "addresses/api/v1/zones/groups/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
//zone group import
export const importZonegroup = async (data) => {
  try {
    return await AoneService.post(
      `addresses/api/v1/zones/groups/import`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
export const getCustomers = async (params) => {
  try {
    return await AoneService.get(
      "invoice-management/api/v1/customers" + params
    );
  } catch (error) {
    return error;
  }
};

export const updateSubAccounts = async (params1, params2, data) => {
  try {
    return await AoneService.put(
      `invoice-management/api/v1/customers/${params1}/sub-accounts/${params2}`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const updateCustomer = async (data) => {
  try {
    return await AoneService.put("invoice-management/api/v1/customers", data);
  } catch (error) {
    return error;
  }
};

export const getInvoices = async (params) => {
  try {
    return await AoneService.post(
      "invoice-management/api/v1/invoice/detail",
      params
    );
  } catch (error) {
    return error;
  }
};

export const updateInvoiceApproveStatus = async (data) => {
  try {
    return await AoneService.post(
      "invoice-management/api/v1/invoice/approval/approve",
      data
    );
  } catch (error) {
    return error;
  }
};

export const updateInvoiceRejectStatus = async (data) => {
  try {
    return await AoneService.post(
      "invoice-management/api/v1/invoice/approval/remarks",
      data
    );
  } catch (error) {
    return error;
  }
};
// getbags
export const getBags = async (params) => {
  try {
    return await AoneService.get("sorting-panel/v1/api/bags" + params);
  } catch (error) {
    return error;
  }
};
//outscan bulk bag
export const outScanBulkBag = async (data) => {
  try {
    return await AoneService.post("sorting-panel/v1/api/bags/out-scan", data);
  } catch (error) {
    return error;
  }
};
//export bulk bag
export const exportBulkBag = async (data) => {
  try {
    return await AoneService.post("sorting-panel/v1/api/bags/export", data, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//outscan download
export const outScanBag = async (params) => {
  try {
    return await AoneService.post(
      `sorting-panel/v1/api/bags/${params}/out-scan`
    );
  } catch (error) {
    return error;
  }
};

//inscan download
export const inScanBag = async (params) => {
  try {
    return await AoneService.post(
      `sorting-panel/v1/api/bags/${params}/in-scan`
    );
  } catch (error) {
    return error;
  }
};

//move consignments
export const moveConsignment = async (params, data) => {
  try {
    return await AoneService.put(
      `sorting-panel/v1/api/bags/${params}/consignment`,
      data
    );
  } catch (error) {
    return error;
  }
};
//label download
export const labelBag = async (params) => {
  try {
    return await AoneService.get(`sorting-panel/v1/api/bags/${params}/label`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};

//consignment download
export const consignmentBag = async (params) => {
  try {
    return await AoneService.get(
      `sorting-panel/v1/api/bags/${params}/consignment/export`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

//template download for consignment upload
export const templateConsigment = async () => {
  try {
    return await AoneService.get(
      `sorting-panel/v1/api/bags/consignment/template`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

export const getChuteConfigs = async (params) => {
  try {
    return await AoneService.get(
      "sorting-panel/v1/api/sorting-configs" + params
    );
  } catch (error) {
    return error;
  }
};

export const postChuteConfigs = async (data) => {
  try {
    return await AoneService.post("sorting-panel/v1/api/sorting-configs", data);
  } catch (error) {
    return error;
  }
};

export const putChuteConfigs = async (params, data) => {
  try {
    return await AoneService.put(
      `sorting-panel/v1/api/sorting-configs/${params}`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteChuteConfigs = async (params) => {
  try {
    return await AoneService.delete(
      "sorting-panel/v1/api/sorting-configs/" + params
    );
  } catch (error) {
    return error;
  }
};

export const deleteThirdparty = async (params) => {
  try {
    return await AoneService.delete("third-party/api/v1/third-party/" + params);
  } catch (error) {
    return error;
  }
};

export const deleteMapping = async (params) => {
  try {
    return await AoneService.delete("third-party/api/v1/mappings/" + params);
  } catch (error) {
    return error;
  }
};

export const getChuteByIdTemplate = async () => {
  try {
    return await AoneService.get(
      "sorting-panel/v1/api/sorting-configs/mapping/template",
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

export const postChuteByIdTemplate = async (params, data) => {
  try {
    return await AoneService.post(
      `sorting-panel/v1/api/sorting-configs/${params}/mapping/template`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};

export const downloadChuteById = async (params) => {
  try {
    return await AoneService.get(
      `sorting-panel/v1/api/sorting-configs/${params}/mapping/download`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

export const getChuteConfigsById = async (params1, params2) => {
  try {
    return await AoneService.get(
      `sorting-panel/v1/api/sorting-configs/${params1}/mapping/` + params2
    );
  } catch (error) {
    return error;
  }
};
//create bags
export const postBags = async (data) => {
  try {
    return await AoneService.post("sorting-panel/v1/api/bags/bag", data);
  } catch (error) {
    return error;
  }
};

//create consignment
export const postWaybill = async (orderId, data) => {
  try {
    return await AoneService.post(
      `sorting-panel/v1/api/bags/${orderId}/order`,
      data
    );
  } catch (error) {
    return error;
  }
};
//edit bags
export const editBags = async (params, data) => {
  try {
    return await AoneService.put(`sorting-panel/v1/api/bags/${params}`, data);
  } catch (error) {
    return error;
  }
};

//edit waybills under bags
export const editWaybillsbyBagId = async (bagId, orderId, params) => {
  try {
    return await AoneService.put(
      `sorting-panel/v1/api/bags/${bagId}/order/${orderId}`,
      params
    );
  } catch (error) {
    return error;
  }
};

//get bags by id
export const getBagsById = async (params1, params2) => {
  try {
    return await AoneService.get(
      `sorting-panel/v1/api/bags/${params1}/consignment` + params2
    );
  } catch (error) {
    return error;
  }
};

//import waybills
export const postWaybillByTemplate = async (params, data) => {
  try {
    return await AoneService.post(
      `sorting-panel/v1/api/bags/${params}/consignment/upload`,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  } catch (error) {
    return error;
  }
};
//delete bags
export const deleteBagById = async (params) => {
  try {
    return await AoneService.delete("sorting-panel/v1/api/bags/" + params);
  } catch (error) {
    return error;
  }
};

//delete waybill in bags
export const deleteWaybillByBagId = async (bagId, orderId) => {
  try {
    return await AoneService.delete(
      `sorting-panel/v1/api/bags/${bagId}/order/${orderId}`
    );
  } catch (error) {
    return error;
  }
};

//printBag
export const printBag = async (params) => {
  try {
    return await AoneService.get(`sorting-panel/v1/api/bags/${params}/label`, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};

export const postChuteConfigsById = async (params, data) => {
  try {
    return await AoneService.post(
      `sorting-panel/v1/api/sorting-configs/${params}/mapping/`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const putChuteConfigsById = async (params, data) => {
  try {
    return await AoneService.put(
      `sorting-panel/v1/api/sorting-configs/${params}/mapping/`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteChuteConfigsById = async (params1, params2) => {
  try {
    return await AoneService.delete(
      `sorting-panel/v1/api/sorting-configs/${params1}/mapping?` + params2
    );
  } catch (error) {
    return error;
  }
};

export const deleteChuteConfigsBulkById = async (params1, data) => {
  try {
    return await AoneService.post(
      `sorting-panel/v1/api/sorting-configs/${params1}/mappings`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const getAgentConfigs = async (params) => {
  try {
    return await AoneService.get("sorting-panel/v1/api/agents" + params);
  } catch (error) {
    return error;
  }
};

//get by machine scans
export const getMachineScans = async (params) => {
  try {
    return await AoneService.get(
      "sorting-panel/v1/api/scans/chute-request" + params
    );
  } catch (error) {
    return error;
  }
};
//get by sorting configs
export const getSortingConfig = async (params) => {
  try {
    return await AoneService.get(
      "sorting-panel/v1/api/sorting-configs" + params
    );
  } catch (error) {
    return error;
  }
};
//export machine scans
export const exportMachineScans = async (params) => {
  try {
    return await AoneService.get(
      "sorting-panel/v1/api/scans/export?" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};

//get third party
export const getThirdparty = async (params) => {
  try {
    return await AoneService.get("third-party/api/v1/third-party" + params);
  } catch (error) {
    return error;
  }
};
//get mappings-thirdparty
export const getMapping = async (params) => {
  try {
    return await AoneService.get("third-party/api/v1/mappings" + params);
  } catch (error) {
    return error;
  }
};

//get mapping types
export const getMappingTypes = async () => {
  try {
    return await AoneService.get("third-party/api/v1/lookup/MappingType");
  } catch (error) {
    return error;
  }
};
//get mapping types
export const getOperationCodes = async () => {
  try {
    return await AoneService.get("invoice/api/v1/lookups/OperationCode");
  } catch (error) {
    return error;
  }
};
export const getCalculationType = async () => {
  try {
    return await AoneService.get("invoice/api/v1/lookups/CalculationType");
  } catch (error) {
    return error;
  }
};
export const getCustomerType = async () => {
  try {
    return await AoneService.get("customers/api/v1/lookups/CustomersType");
  } catch (error) {
    return error;
  }
};
export const getBillingPeriod = async () => {
  try {
    return await AoneService.get("customers/api/v1/lookups/BillingPeriod");
  } catch (error) {
    return error;
  }
};
export const getLookups = async (params) => {
  try {
    return await AoneService.get(`invoice/api/v1/lookups/${params}`);
  } catch (error) {
    return error;
  }
};
//receive-machinescans
export const receiveMachineScan = async (id) => {
  try {
    return await AoneService.post(`sorting-panel/v1/api/events/receive`, id);
  } catch (error) {
    return error;
  }
};
//outscan-machinescans
export const outScanMachineScan = async (id) => {
  try {
    return await AoneService.post(`sorting-panel/v1/api/events/out-scan`, id);
  } catch (error) {
    return error;
  }
};
export const postAgentConfigs = async (data) => {
  try {
    return await AoneService.post("sorting-panel/v1/api/agents", data);
  } catch (error) {
    return error;
  }
};

export const putAgentConfigs = async (params, data) => {
  try {
    return await AoneService.put(`sorting-panel/v1/api/agents/${params}`, data);
  } catch (error) {
    return error;
  }
};

export const deleteAgentConfigs = async (params) => {
  try {
    return await AoneService.delete("sorting-panel/v1/api/agents/" + params);
  } catch (error) {
    return error;
  }
};

export const deactivateAgentConfigs = async (params, data) => {
  try {
    return await AoneService.patch(
      `sorting-panel/v1/api/agents/${params}/status`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const downloadAgents = async () => {
  try {
    return await AoneService.get("sorting-panel/v1/api/agents/download", {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};

export const getJobOrders = async (params) => {
  try {
    return await AoneService.get("job-order/api/v1/orders" + params);
  } catch (error) {
    return error;
  }
};

export const postJobOrders = async (data) => {
  try {
    return await AoneService.post("job-order/api/v1/orders", data);
  } catch (error) {
    return error;
  }
};

export const getJobOrderById = async (params) => {
  try {
    return await AoneService.get("job-order/api/v1/orders/" + params);
  } catch (error) {
    return error;
  }
};

export const putJobOrderById = async (params, data) => {
  try {
    return await AoneService.put("job-order/api/v1/orders/" + params, data);
  } catch (error) {
    return error;
  }
};

export const deleteJobOrderById = async (params) => {
  try {
    return await AoneService.delete("job-order/api/v1/orders/" + params);
  } catch (error) {
    return error;
  }
};

export const postJobOrdersItemById = async (params, data) => {
  try {
    return await AoneService.post(
      `job-order/api/v1/orders/${params}/item`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const putJobOrderItemById = async (params1, params2, data) => {
  try {
    return await AoneService.put(
      `job-order/api/v1/orders/${params1}/item/${params2}`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteJobOrderItemById = async (params1, params2) => {
  try {
    return await AoneService.delete(
      `job-order/api/v1/orders/${params1}/item/${params2}`
    );
  } catch (error) {
    return error;
  }
};

export const downloadJobOrder = async (params) => {
  try {
    return await AoneService.get("job-order/api/v1/orders/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};

export const printJobOrderQR = async (params) => {
  try {
    return await AoneService.get("job-order/api/v1/orders/qrCode/" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};

export const getEmployeeDepartments = async () => {
  try {
    return await AoneService.get(
      "employees/api/v1/lookups/departments"
    );
  } catch (error) {
    return error;
  }
};

export const postEmployees = async (data) => {
  try {
    return await AoneService.post("employees/api/v1/employees", data);
  } catch (error) {
    return error;
  }
};

//create third party
export const postThirdParty = async (data) => {
  try {
    return await AoneService.post("third-party/api/v1/third-party/", data);
  } catch (error) {
    return error;
  }
};

//edit third party
export const EditThirdparty = async (params, data) => {
  try {
    return await AoneService.put(
      "third-party/api/v1/third-party/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};

//mapping
export const postMapping = async (data) => {
  try {
    return await AoneService.post("third-party/api/v1/mappings/", data);
  } catch (error) {
    return error;
  }
};

//edit mapping
export const EditMapping = async (params, data) => {
  try {
    return await AoneService.patch(
      "third-party/api/v1/mappings/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};

//get events
export const getEvents = async (params) => {
  try {
    return await AoneService.get("third-party/api/v1/scans" + params);
  } catch (error) {
    return error;
  }
};
export const exportEvents = async (params) => {
  try {
    return await AoneService.get("third-party/api/v1/scans/export" + params, {
      responseType: "blob",
    });
  } catch (error) {
    return error;
  }
};
//get orders
export const getOrders = async (params) => {
  try {
    return await AoneService.get("third-party/api/v1/orders" + params);
  } catch (error) {
    return error;
  }
};

//assign orders
export const assignOrders = async (data) => {
  try {
    return await AoneService.post("third-party/api/v1/orders/assign", data);
  } catch (error) {
    return error;
  }
};

//cancel orders
export const cancelOrders = async (data) => {
  try {
    return await AoneService.post("third-party/api/v1/orders/cancel", data);
  } catch (error) {
    return error;
  }
};

//print orders
export const printOrders = async (params) => {
  try {
    return await AoneService.get(`third-party/api/v1/orders/print/${params}`);
  } catch (error) {
    return error;
  }
};

export const getEmployees = async (params) => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees" + params
    );
  } catch (error) {
    return error;
  }
};

export const getEmployeeById = async (params) => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees/" + params
    );
  } catch (error) {
    return error;
  }
};

export const putEmployeeById = async (params, data) => {
  try {
    return await AoneService.put(
      "employees/api/v1/employees/" + params,
      data
    );
  } catch (error) {
    return error;
  }
};

export const exportEmployees = async (params) => {
  try {
    return await AoneService.get(
      "employees/api/v1/employees/export" + params,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    return error;
  }
};
