import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {getVat,getVatbyId,postVat,editVat,editVatState,deleteVat,bulkVatDelete,
  exportrateVat,getVatTemplate,importVat,
    getGpa,getGpabyId,postGpa,editGpa,editGpaState,deleteGpa,bulkGpaDelete,exportrateGpa,
    getGpaTemplate,importGpa} from "../../services/Rates/Rate_services";
const initialValues = {
};
let ratesStore = (set, get) => ({
  ...initialValues,
  getVat: async (params) => {
        const response = await getVat(params);
        return response;
      },
      getVatbyId: async (params) => {
        const response = await getVatbyId(params);
        return response;
      },
      postVat: async (params) => {
        const response = await postVat(params);
        return response;
      },
      editVat: async (params,data) => {
        const response = await editVat(params,data);
        return response;
      },
      editVatState: async (params,data) => {
        const response = await editVatState(params,data);
        return response;
      },
      deleteVat: async (params) => {
        const response = await deleteVat(params);
        return response;
      },
      bulkVatDelete: async (data) => {
        const response = await bulkVatDelete(data);
        return response;
      },
      exportrateVat: async (params) => {
        const response = await exportrateVat(params);
        return response;
      },
      getVatTemplate: async () => {
        const response = await getVatTemplate();
        return response;
      },
      importVat: async (params) => {
        const response = await importVat(params);
        return response;
      },
      getGpa: async (params) => {
        const response = await getGpa(params);
        return response;
      },
      getGpabyId: async (params) => {
        const response = await getGpabyId(params);
        return response;
      },
      postGpa: async (params) => {
        const response = await postGpa(params);
        return response;
      },
      editGpa: async (params,data) => {
        const response = await editGpa(params,data);
        return response;
      },
      editGpaState: async (params,data) => {
        const response = await editGpaState(params,data);
        return response;
      },
      bulkGpaDelete: async (data) => {
        const response = await bulkGpaDelete(data);
        return response;
      },
      deleteGpa: async (params) => {
        const response = await deleteGpa(params);
        return response;
      },
      exportrateGpa: async (params) => {
        const response = await exportrateGpa(params);
        return response;
      },
      getGpaTemplate: async (params) => {
        const response = await getGpaTemplate(params);
        return response;
      },
      importGpa: async (params) => {
        const response = await importGpa(params);
        return response;
      }
});

ratesStore = devtools(ratesStore);

export const useRatesStore = create(ratesStore);