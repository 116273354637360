import React from 'react'
import { Modal } from 'react-bootstrap';
const UploadProgressModal = ({show, handleClose}) => {
  return (
    <Modal show={show} onHide={handleClose} className='multipleShipmentpopup'>
      <Modal.Body>
        <div className='mb_24 textAlignCenter'>
          <img src='/images/uploading_now.svg' />
        </div>
        <h2 className='fontSize18 fontWeight600 satinDeepblackText textAlignCenter mb_12'>
          Just a Minute
        </h2>
        <p className='sharkFinText fontSize14 fontWeight500 textAlignCenter mb_16'>
          Your Upload will complete in a minute
        </p>

        <div className='progressSec'>
          <div className='progressBar mb_34'>
            <div className='progressStatus'/>
          </div>
        </div>
        <div className='displayFlex justifycontent_Center'>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UploadProgressModal
